import { combineReducers } from "redux";
import addonTypeReducer from "./addonType/reducer";
import adminUserReducer from "./adminUser/reducer";
import bookingReducer from "./bookings/reducer";
import calendarEventReducer from "./calendar-events.reducer";
import customerReducer from "./customer/reducer";
import vehicleDamageReducer from "./damageVehicle/reducer";
import fleetReducer from "./fleet/reducer";
import insurancePolicyReducer from "./insurance/reducer";
import invoiceReducer from "./invoices/reducer";
import navbarReducer from "./navbar.reducer";
import organisationReducer from "./organisation/reducer";
import priceRuleReducer from "./priceRule/reducer";
import tableStateReducer from "./tableState/reducer";
import userReducer from "./user/reducer";
import vehiclePriceGroupReducer from "./vehiclePriceGroups/reducer";
import authReducer from "./auth/reducer"

function lastAction(state: any, action: any) {
  return action;
}

export default combineReducers({
  adminUserReducer,
  bookingReducer,
  calendarEventReducer,
  customerReducer,
  vehicleDamageReducer,
  fleetReducer,
  insurancePolicyReducer,
  invoiceReducer,
  navbarReducer,
  organisationReducer,
  priceRuleReducer,
  vehiclePriceGroupReducer,
  userReducer,
  addonTypeReducer,
  tableStateReducer,
  lastAction,
  authReducer
});
