import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from 'formik';
import { DeFleetDocumentType, IAddress, IDeFleetCheckList, IVehicleDefleet } from "../../../../reducers/fleet/types";
import * as Yup from 'yup';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, MenuItem, Paper, Typography } from "@mui/material";
import { TextField } from "formik-mui";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { checkDeleteOrDownloadFileType, formatGraphQLErrorMessage, uploadFileExtensionAndContentType } from "../../../common/utils";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { FilePicker } from "../../../common/FilePicker/FilePicker";
import { DeFleetReasons, deFleetReasons } from "../utils";
import { v4 as uuidv4 } from "uuid";
import { IAppState } from "../../../../store";
import { useSelector } from "react-redux";
import { captureErrorException } from "../../../../utils/sentry";
import { UPDATE_DEFLEET_VEHICLE } from "../../../../graphql/Defleet/updateVehicleDefleet";
import { FloatInput } from "../../../common/FloatInput/FloatInput";
import { useNavigate } from "react-router-dom";
import { GET_DEFLEET_VEHICLE } from "../../../../graphql/Defleet/getDeFleetVehicle";
import { DEFLEET_VEHICLE } from "../../../../graphql/Defleet/deFleetVehicle";
import AddressPicker from "../../CustomerManagement/NewCustomer/AddressPicker/AddressPicker";
import { getSignedUrl } from "../../../../utils/getSignedUrl";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { VehicleInfoSource } from "../../Fleet/utils";

export const initialDeFleetCheckList: IDeFleetCheckList = {
  exteriorPaint: '',
  wheelRims: '',
  windscreensAndWindows: '',
  mirrors: '',
  bumper: '',
  headlamps: '',
  interiorFabric: '',
  locksAndDoorHandles: '',
  bothKeys: '',
  telematicsDeviceRemoved: '',
  pcn: '',
  licencePlateCondition: '',
  registrationDocumentUrl: "",
  motDocumentUrl: "",
  loanDocumentUrl: "",
  loanStatus: "",
  currentLoanStatus: "",
  pcnTotalAmount: 0
};

export const initialVehicleDefleet: IVehicleDefleet = {
  id: '',
  licencePlate: '',
  deFleetReason: '',
  deFleetPrice: 0,
  residualValue: 0,
  namaGrading: "",
  purchaserName: "",
  purchaserAddress: {
    city: '',
    country: '',
    fullAddress: '',
    state: '',
    street: '',
    zipcode: '',
  },
  deFleetCheckList: initialDeFleetCheckList
};

export const DefleetVehicle: React.FC = () => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [values, setValues] = useState<IVehicleDefleet>(initialVehicleDefleet);
  const [registrationDocUploadLoading, setRegistrationDocUploadLoading] = useState<boolean>(false);
  const [motDocUploadLoading, setMotDocUploadLoading] = useState<boolean>(false);
  const [loanDocUploadLoading, setLoanDocUploadLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isDvlaVehicle, setIsDvlaVehicle] = useState<boolean>(false);


  const [getVehicle] = useLazyQuery(GET_DEFLEET_VEHICLE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.getDeFleetVehicle) {
        if (data.getDeFleetVehicle?.infoSource === VehicleInfoSource.DVLA) {
          setIsDvlaVehicle(true)
        }
        const { infoSource, ...rest } = data?.getDeFleetVehicle
        setValues(preValues => ({
          ...preValues,
          ...rest,
          deFleetCheckList: {
            ...preValues.deFleetCheckList,
            ...rest?.deFleetCheckList
          }
        }));
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR,
      });
    },
  });

  const [updateDefleetVehicle, { loading: updateLoading }] = useMutation(UPDATE_DEFLEET_VEHICLE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.updateVehicleDeFleet) {
        setValues(prevValues => ({
          ...prevValues,
          ...data.updateVehicleDeFleet,
          deFleetCheckList: {
            ...prevValues.deFleetCheckList,
            ...data.updateVehicleDeFleet.deFleetCheckList
          }
        }));
        navigate('/vehicle-defleet')
        snackbar({
          message: "De-Fleet data saved.",
          variant: SnackBarVariant.SUCCESS
        })
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: error.message,
        variant: SnackBarVariant.ERROR,
      });
    }
  })

  const [deFleetVehicle, { loading: deFleetLoading }] = useMutation(DEFLEET_VEHICLE, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      snackbar({
        message: "Vehicle De-Fleeted successfully.",
        variant: SnackBarVariant.SUCCESS
      })
      navigate("/de-fleet")
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: error.message,
        variant: SnackBarVariant.ERROR,
      });
    }
  })

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const vehicleId = params.get("id");
      if (vehicleId) {
        getVehicle({
          variables: {
            vehicleId
          }
        });
      }
    }
  }, [location]);


  const vehicleDefleetValidationSchema = Yup.object().shape({
    deFleetReason: Yup.string().required('De-fleet Reason is required'),
    deFleetPrice: Yup.number().required('De-fleet Price is required'),
    residualValue: Yup.number().required('Residual Value is required'),
    deFleetCheckList: Yup.object().shape({
      bothKeys: Yup.string().required('Both Keys status is required'),
    })
  });

  const checkListFields = [
    { name: 'exteriorPaint', label: 'Exterior paint', options: ['Good Condition', 'Bad Condition'] },
    { name: 'wheelRims', label: 'Wheel rims', options: ['Good Condition', 'Bad Condition'] },
    { name: 'windscreensAndWindows', label: 'Windscreens and windows', options: ['Good Condition', 'Bad Condition'] },
    { name: 'mirrors', label: 'Mirrors', options: ['Good Condition', 'Bad Condition'] },
    { name: 'bumper', label: 'Bumper', options: ['Good Condition', 'Bad Condition'] },
    { name: 'headlamps', label: 'Headlamps', options: ['Good Condition', 'Bad Condition'] },
    { name: 'interiorFabric', label: 'Interior fabric', options: ['Good Condition', 'Bad Condition'] },
    { name: 'locksAndDoorHandles', label: 'Locks and door handles', options: ['Good Condition', 'Bad Condition'] },
    { name: 'bothKeys', label: 'Keys', options: ['1', '2'] },
    { name: 'telematicsDeviceRemoved', label: 'Telematics device removed(if installed)', options: ['Yes', 'No'] },
    { name: 'pcn', label: 'PCN', options: ['Yes', 'No'] },
    { name: 'licencePlateCondition', label: 'Licence Plate Status', options: ['Good Condition', 'Bad Condition'] }
  ];

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const isNumericField = (fieldName: string) => {
    const numericFields = [
      'deFleetPrice',
      'residualValue',
    ];
    return numericFields.includes(fieldName);
  };

  const handleFieldChange = (fieldName: string, fieldValue: any) => {
    setValues(prevValues => ({
      ...prevValues,
      [fieldName]: isNumericField(fieldName) ? parseFloat(fieldValue) : fieldValue
    }));
  };

  const handleCheckListChange = (fieldName: string, fieldValue: any) => {
    setValues(prevValues => {
      const updatedCheckList = {
        ...prevValues.deFleetCheckList,
        [fieldName]: fieldValue
      };
      if (fieldName === 'loanStatus' && fieldValue === 'No') {
        updatedCheckList.currentLoanStatus = "";
      }

      if (fieldName === 'pcn') {
        if (fieldValue === 'No') {
          updatedCheckList.pcnTotalAmount = 0;
        } else {
          updatedCheckList.pcnTotalAmount = updatedCheckList.pcnTotalAmount || 0;
        }
      }

      return {
        ...prevValues,
        deFleetCheckList: updatedCheckList
      };
    });
  };

  async function uploadRegistrationDoc(file: any) {
    const uniqueId = uuidv4();
    const fileToUpload = file[0]
    const MAX_FILE_SIZE = 5242880;
    const ALLOWED_FILE_TYPE = "application/pdf";
    try {
      if (fileToUpload.type !== ALLOWED_FILE_TYPE) {
        throw new Error(`Invalid file type. Only PDF files are allowed. You uploaded: ${fileToUpload.type}`);
      }

      if (fileToUpload.size > MAX_FILE_SIZE) {
        throw new Error("File size exceeded limit of 5MB");
      }

      if (fileToUpload.type === ALLOWED_FILE_TYPE) {
        const { fileExtension, contentType } = uploadFileExtensionAndContentType(
          fileToUpload.type
        );
        const key = `${userReducer.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          setRegistrationDocUploadLoading(true);
          await _e_
            .add({
              name: key,
              file: fileToUpload,
              complete: async () => {
                handleCheckListChange('registrationDocumentUrl', key)
                snackbar({
                  message: "Registration document uploaded",
                  variant: SnackBarVariant.SUCCESS
                })
              }
            })
          setRegistrationDocUploadLoading(false)
        }
      } else {
        setRegistrationDocUploadLoading(false)
        return snackbar({
          message: "Please only upload PDF file types",
          variant: SnackBarVariant.ERROR
        });
      }


    } catch (err) {
      captureErrorException(err);
      if (err instanceof Error) {
        snackbar({ message: err.message, variant: SnackBarVariant.ERROR });
      }
    }
  }

  async function uploadMotDoc(file: any) {
    const uniqueId = uuidv4();
    const fileToUpload = file[0]
    const MAX_FILE_SIZE = 5242880;
    const ALLOWED_FILE_TYPE = "application/pdf";
    try {
      if (fileToUpload.type !== ALLOWED_FILE_TYPE) {
        throw new Error(`Invalid file type. Only PDF files are allowed. You uploaded: ${fileToUpload.type}`);
      }

      if (fileToUpload.size > MAX_FILE_SIZE) {
        throw new Error("File size exceeded limit of 5MB");
      }

      if (fileToUpload.type === ALLOWED_FILE_TYPE) {
        const { fileExtension, contentType } = uploadFileExtensionAndContentType(
          fileToUpload.type
        );
        const key = `${userReducer.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          setMotDocUploadLoading(true);
          await _e_
            .add({
              name: key,
              file: fileToUpload,
              complete: async () => {
                handleCheckListChange('motDocumentUrl', key)
                snackbar({
                  message: "MOT document uploaded",
                  variant: SnackBarVariant.SUCCESS
                })
              }
            })
          setMotDocUploadLoading(false)
        }
      } else {
        setMotDocUploadLoading(false)
        return snackbar({
          message: "Please only upload PDF file types",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err) {
      captureErrorException(err);
      if (err instanceof Error) {
        snackbar({ message: err.message, variant: SnackBarVariant.ERROR });
      }
    }
  }

  async function uploadLoanDoc(file: any) {
    const uniqueId = uuidv4();
    const fileToUpload = file[0]
    const MAX_FILE_SIZE = 5242880;
    const ALLOWED_FILE_TYPE = "application/pdf";
    try {
      if (fileToUpload.type !== ALLOWED_FILE_TYPE) {
        throw new Error(`Invalid file type. Only PDF files are allowed. You uploaded: ${fileToUpload.type}`);
      }

      if (fileToUpload.size > MAX_FILE_SIZE) {
        throw new Error("File size exceeded limit of 5MB");
      }

      if (fileToUpload.type === ALLOWED_FILE_TYPE) {
        const { fileExtension, contentType } = uploadFileExtensionAndContentType(
          fileToUpload.type
        );
        const key = `${userReducer.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          setLoanDocUploadLoading(true);
          await _e_
            .add({
              name: key,
              file: fileToUpload,
              complete: async () => {
                handleCheckListChange('loanDocumentUrl', key)
                snackbar({
                  message: "Loan document uploaded",
                  variant: SnackBarVariant.SUCCESS
                })
              }
            })
          setLoanDocUploadLoading(false)
        }
      } else {
        setLoanDocUploadLoading(false)
        return snackbar({
          message: "Please only upload PDF file types",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err) {
      captureErrorException(err);
      if (err instanceof Error) {
        snackbar({ message: err.message, variant: SnackBarVariant.ERROR });
      }
    }
  }


  async function downloadDocuments(docUrl: string, type: string) {
    const { fileExtension, contentType } = checkDeleteOrDownloadFileType(
      docUrl
    );
    let fileName = "document";
    if (type === DeFleetDocumentType.REGISTRATION) {
      fileName = `Vehicle_Registration.${fileExtension}`;
    } else if (type === DeFleetDocumentType.MOT) {
      fileName = `MOT_Certificate.${fileExtension}`;
    } else if (type === DeFleetDocumentType.LOAN_CERTIFICATE) {
      fileName = `Loan_Clearance_Certificate.${fileExtension}`;
    }

    const file = await getSignedUrl(docUrl);
    const url: any = file;

    fetch(url, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const objectUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  const handleFormSubmit = (values: IVehicleDefleet) => {
    const { licencePlate, ...rest } = values;
    if (!rest.deFleetCheckList.registrationDocumentUrl) {
      return snackbar({
        message: "Vehicle Registration Document is required.",
        variant: SnackBarVariant.ERROR
      });
    }

    if (isDvlaVehicle && !rest.deFleetCheckList.motDocumentUrl) {
      return snackbar({
        message: "MOT Certificate is required.",
        variant: SnackBarVariant.ERROR
      });
    }

    if (rest.deFleetCheckList.loanStatus === "Yes" && !rest.deFleetCheckList.loanDocumentUrl) {
      return snackbar({
        message: "Loan Document is required.",
        variant: SnackBarVariant.ERROR
      });
    }

    if (rest.deFleetCheckList.pcn === "Yes" && isNaN(rest.deFleetCheckList.pcnTotalAmount)) {
      return snackbar({
        message: "Total PCN Amount is required.",
        variant: SnackBarVariant.ERROR
      });
    }

    updateDefleetVehicle({
      variables: { vehicleDefleetInput: rest }
    })
  };

  const handleDefleet = () => {
    const { licencePlate, ...rest } = values;
    closeDialog()
    if (!rest.deFleetCheckList.registrationDocumentUrl) {
      return snackbar({
        message: "Vehicle Registration Document is required.",
        variant: SnackBarVariant.ERROR
      });
    }

    if (isDvlaVehicle && !rest.deFleetCheckList.motDocumentUrl) {
      return snackbar({
        message: "MOT Certificate is required.",
        variant: SnackBarVariant.ERROR
      });
    }

    if (rest.deFleetCheckList.loanStatus === "Yes" && !rest.deFleetCheckList.loanDocumentUrl) {
      return snackbar({
        message: "Loan Document is required.",
        variant: SnackBarVariant.ERROR
      });
    }

    if (rest.deFleetCheckList.pcn === "Yes" && isNaN(rest.deFleetCheckList.pcnTotalAmount)) {
      return snackbar({
        message: "Total PCN Amount is required.",
        variant: SnackBarVariant.ERROR
      });
    }

    deFleetVehicle({
      variables: { vehicleDefleetInput: rest }
    })

  }
  function handleReset() {
    setValues(initialVehicleDefleet)
    navigate("/vehicle-defleet")
  }

  const renderPcnTotalAmountField = () => {
    if (values.deFleetCheckList.pcn === 'Yes') {
      return (
        <Grid item xs={12} sm={6} md={3}>
          <Field
            component={TextField}
            label="Total PCN Amount"
            name="deFleetCheckList.pcnTotalAmount"
            value={values.deFleetCheckList.pcnTotalAmount}
            onChange={(e: any) => handleCheckListChange('pcnTotalAmount', e.target.value)}
            InputProps={{
              inputComponent: FloatInput as any
            }}
            inputProps={{
              hasCurrencyPrefix: true,
              allowNegative: false
            }}
            fullWidth
          />
        </Grid>
      );
    }
    return null;
  };

  const renderChecklistFields = () => {
    return checkListFields.map(({ name, label, options }) => (
      <Grid item xs={12} sm={6} md={3} key={name}>
        <Field
          component={TextField}
          select
          placeholder={label}
          label={label}
          name={`deFleetCheckList.${name}`}
          value={values.deFleetCheckList[name as keyof IDeFleetCheckList]}
          onChange={(e: any) => handleCheckListChange(name, e.target.value)}
          required={name === 'bothKeys'}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Field>
      </Grid>
    ));
  };

  return (
    <>
      <Grid container item xs={12}>
        <Paper style={{ padding: '20px', width: '100%' }}>
          <Formik
            enableReinitialize
            initialValues={values}
            validationSchema={vehicleDefleetValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleFormSubmit(values);
              setSubmitting(false);
            }}
          >
            {(formikProps) => (
              <Form style={{ width: '100%' }}>
                <Grid container item xs={12} alignItems="center" spacing={2}>
                  <Grid item xs={12} sm={6} md={8}>
                    <Typography variant="h2">De-Fleet Details</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12} sm={6} md={4}
                    container
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  >
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="Defleet"
                      className="defleetButton"
                      onClick={openDialog}
                      disabled={
                        !values.id ||
                        !values.licencePlate ||
                        !values.deFleetPrice ||
                        !values.deFleetReason ||
                        !values.residualValue ||
                        !values.deFleetCheckList.bothKeys ||
                        !values.deFleetCheckList.registrationDocumentUrl ||
                        isDvlaVehicle && !values.deFleetCheckList.motDocumentUrl ||
                        (values.deFleetCheckList.loanStatus === 'Yes' && !values.deFleetCheckList.loanDocumentUrl)
                      }
                    >{deFleetLoading && <CircularProgress size={20} style={{ color: "white", marginRight: "10px" }} />}
                      De-Fleet
                    </Fab>
                  </Grid>
                </Grid>
                <Grid container item spacing={2} xs={12} style={{ marginTop: '16px' }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Field
                      component={TextField}
                      placeholder="Licence Plate"
                      label="Licence Plate"
                      name="licencePlate"
                      value={values.licencePlate}
                      onChange={(e: any) => handleFieldChange('licencePlate', e.target.value)}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Field
                      component={TextField}
                      select
                      placeholder="De-fleet Reason"
                      label="De-fleet Reason"
                      name="deFleetReason"
                      value={values.deFleetReason}
                      onChange={(e: any) => handleFieldChange('deFleetReason', e.target.value)}
                      fullWidth
                      required
                    >
                      {deFleetReasons.map(reason => (
                        <MenuItem key={reason.value} value={reason.value}>
                          {reason.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  {(values.deFleetReason === DeFleetReasons.STOLEN || values.deFleetReason === DeFleetReasons.NOT_ROAD_WORTHY) ? (
                    null
                  ) : (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <Field
                          component={TextField}
                          label="Purchaser Name"
                          placeholder="Purchaser Name"
                          name="purchaserName"
                          value={values.purchaserName}
                          onChange={(e: any) => handleFieldChange('purchaserName', e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <AddressPicker
                          fieldName={"purchaserAddress"}
                          onChange={(address: IAddress | any) => {
                            if (address) {
                              handleFieldChange('purchaserAddress', address);
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={6} md={3}>
                    <Field
                      component={TextField}
                      label="De-fleet Price"
                      name="deFleetPrice"
                      value={values.deFleetPrice || 0}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange('deFleetPrice', e.target.value)}
                      InputProps={{
                        inputComponent: FloatInput as any,
                      }}
                      inputProps={{
                        hasCurrencyPrefix: true,
                        allowNegative: false,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Field
                      component={TextField}
                      label="Residual Value"
                      name="residualValue"
                      InputProps={{
                        inputComponent: FloatInput as any,
                      }}
                      inputProps={{
                        hasCurrencyPrefix: true,
                        allowNegative: false,
                      }}
                      value={values.residualValue || 0}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFieldChange('residualValue', e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      fullWidth
                    ></Field>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Field
                      component={TextField}
                      select
                      placeholder="Nama Grading"
                      label="Nama Grading"
                      name="namaGrading"
                      fullWidth
                      onChange={(e: any) => handleFieldChange('namaGrading', e.target.value)}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="U">U</MenuItem>
                    </Field>
                  </Grid>
                </Grid>
                <Grid container item spacing={2} xs={12} style={{ marginTop: '16px' }}>
                  <Grid item xs={12}>
                    <Typography variant="h2">De-Fleet Checklist</Typography>
                  </Grid>
                  <Grid container item xs={12} spacing={2} style={{ marginTop: '16px' }}>
                    {renderChecklistFields()}
                    {renderPcnTotalAmountField()}
                  </Grid>
                </Grid>
                <Grid container item spacing={2} xs={12} style={{ marginTop: '16px' }}>
                  <Grid item xs={12}>
                    <Typography variant="h2">Upload Documents</Typography>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h3">Vehicle Registration Documents*</Typography>
                      <Box mt={2}>
                        <FilePicker onFilePick={(e: any) => uploadRegistrationDoc(e.target.files)} accept="application/pdf" title="UPLOAD" />
                        <Fab
                          size="small"
                          color="primary"
                          aria-label="registration document download"
                          style={{ marginLeft: '10px' }}
                          onClick={() => {
                            downloadDocuments(values.deFleetCheckList.registrationDocumentUrl, DeFleetDocumentType.REGISTRATION)
                          }}
                          disabled={!values.deFleetCheckList.registrationDocumentUrl}
                        >
                          <CloudDownloadIcon />
                        </Fab>
                      </Box>
                      {registrationDocUploadLoading && <CircularProgress size={20} style={{ color: "white", marginRight: "10px" }} />}
                      <Typography variant="h4">Allowed document type is pdf, less than 5 MB</Typography>
                    </Grid>

                    {isDvlaVehicle &&
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h3">MOT Certificate*</Typography>
                        <Box mt={2}>
                          <FilePicker onFilePick={(e: any) => uploadMotDoc(e.target.files)} accept="application/pdf" title="UPLOAD" />
                          <Fab
                            size="small"
                            color="primary"
                            aria-label="mot document download"
                            style={{ marginLeft: '10px' }}
                            disabled={!values.deFleetCheckList.motDocumentUrl}
                            onClick={() => {
                              downloadDocuments(values.deFleetCheckList.motDocumentUrl, DeFleetDocumentType.MOT)
                            }}
                          >
                            <CloudDownloadIcon />
                          </Fab>
                        </Box>
                        {motDocUploadLoading && <CircularProgress size={20} style={{ color: "white", marginRight: "10px" }} />}
                        <Typography variant="h4">Allowed document type is pdf, less than 5 MB</Typography>
                      </Grid>}
                  </Grid>
                </Grid>
                <Grid container item spacing={2} xs={12} style={{ marginTop: '16px' }}>
                  <Grid item xs={12}>
                    <Typography variant="h3">Loan Documents</Typography>
                  </Grid>
                  <Grid container item xs={12} spacing={2} >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h4" style={{ marginBottom: '8px' }}>Do you have a loan against this vehicle?</Typography>
                      <Field
                        component={TextField}
                        select
                        label="Loan Status"
                        name="deFleetCheckList.loanStatus"
                        fullWidth
                        onChange={(e: any) => handleCheckListChange('loanStatus', e.target.value)}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Field>
                    </Grid>

                    {values.deFleetCheckList.loanStatus === "Yes" && (
                      <Grid item xs={12} sm={3}>
                        <Typography variant="h4" style={{ marginBottom: '8px' }}>What is the current status of the loan?</Typography>
                        <Field
                          component={TextField}
                          select
                          label="Current Loan Status"
                          name="deFleetCheckList.currentLoanStatus"
                          fullWidth
                          onChange={(e: any) => handleCheckListChange('currentLoanStatus', e.target.value)}
                        >
                          <MenuItem value="PAID_IN_FULL">Paid in full</MenuItem>
                          <MenuItem value="ONGOING">On-going</MenuItem>
                        </Field>
                      </Grid>
                    )}
                    {values.deFleetCheckList.loanStatus === "Yes" && (
                      <Grid item xs={12} sm={6}>
                        <Box mt={2}>
                          <FilePicker onFilePick={(e: any) => uploadLoanDoc(e.target.files)} accept="application/pdf" title="UPLOAD LOAN DOCUMENTS" />
                          <Fab
                            size="small"
                            color="primary"
                            aria-label="loan document download"
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              downloadDocuments(values.deFleetCheckList.loanDocumentUrl, DeFleetDocumentType.LOAN_CERTIFICATE)
                            }}
                            disabled={!values.deFleetCheckList.loanDocumentUrl}
                          >
                            <CloudDownloadIcon />
                          </Fab>
                        </Box>
                        {loanDocUploadLoading && <CircularProgress size={20} style={{ color: "white", marginRight: "10px" }} />}
                        <Typography variant="h4">Allowed document type is pdf, less than 5 MB</Typography>
                      </Grid>)}
                  </Grid>
                </Grid>
                <Grid item container xs={12} style={{ marginTop: '32px' }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="Defleet Multiple"
                    className="blackBackButton"
                    onClick={handleReset}
                  >
                    Cancel
                  </Fab>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="add"
                    style={{ marginLeft: 10 }}
                    type="submit"
                    disabled={
                      updateLoading
                    }
                  >
                    {updateLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                    Save
                  </Fab>

                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
      <Dialog open={dialogOpen} onClose={closeDialog} aria-labelledby="defleet-dialog-title">
        <DialogTitle id="defleet-dialog-title"><Typography variant="h1" color="primary">Confirm De-fleet</Typography></DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to De-fleet this vehicle? This action is irreversible and will remove the vehicle from your active fleet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Fab
            variant="extended"
            size="medium"
            aria-label="Cancel"
            className="blackBackButton"
            color="secondary"
            onClick={closeDialog}
          >
            Cancel
          </Fab>
          <Fab
            variant="extended"
            size="medium"
            aria-label="Confirm-Defleet"
            style={{ marginLeft: 10 }}
            color="primary"
            onClick={handleDefleet}
          >
            Confirm De-Fleet
          </Fab>
        </DialogActions>
      </Dialog>
    </>
  );
};
