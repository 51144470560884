import { Grid, TextField as InputField, MenuItem } from '@mui/material';
import { DateTime as d } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { IAppState } from "../../../store";
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";
import FlatPickerBar from "../FlatPicker";
import { DATE_TYPE } from "../utils";

interface IProps {
  date: string;
  dateTitle: string;
  timeTitle: string;
  handleChange(date: IDateTime): void;
  name: string;
  minDate?: string;
  maxDate?: string;
  timeToShow?: "before" | "after";
  disabled?: boolean;
  label?: string;
  now?: string;
  required?: boolean;
  classId?: string;
}

interface IDateTime {
  date: string;
  time: number;
}

export const SimpleDateTimePicker: React.FC<IProps> = (props) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const {
    date,
    dateTitle,
    timeTitle,
    handleChange,
    name,
    minDate,
    maxDate,
    disabled,
    required,
    now
  } = props;
  const [time, setTime] = useState<string>("");
  const timeRange = useRef<{ fromTime: number[]; toTime: number[] }>({
    fromTime: [0, 23.45],
    toTime: [0, 23.45]
  });
  const setTimeOnDate = (date: d, hour: number) => {
    const formattedDate = date.toFormat("dd/MM/yyyy");

    return d.fromFormat(
      `${formattedDate} ${numberToHour(hour)}`,
      "dd/MM/yyyy h:m"
    );
  };

  const numberToHour = (opHr: number) => {
    const minute = parseInt(
      Math.round(opHr * 100)
        .toString()
        .slice(-2)
    );
    const hour = Math.floor(opHr);
    return `${hour > 9 ? hour : `0${hour}`}:${minute > 9 ? minute : `0${minute}`
      }`;
  };

  const hourToNumber = (hour: string) => {
    const splitted = hour.split(":");
    return parseInt(splitted[0]) + parseInt(splitted[1]) / 100;
  };

  const getTimesArray = (range: number[]) => {
    let arr = [];
    const maxDateD = maxDate && d.fromISO(maxDate);
    const minDateD = minDate && d.fromISO(minDate);
    const dateD = d.fromISO(date);
    for (let i = range[0]; i <= range[1]; i) {
      const upperRangeCondition =
        !maxDate ||
        (maxDateD && dateD < maxDateD.startOf("day")) ||
        (maxDateD &&
          dateD.hasSame(maxDateD, "day") &&
          i <= hourToNumber(maxDateD.toFormat("HH:mm")));

      const lowerRangeCondition =
        !minDate ||
        (minDateD && dateD > minDateD.endOf("day")) ||
        (minDateD &&
          dateD.hasSame(minDateD, "day") &&
          i >= hourToNumber(minDateD.toFormat("HH:mm")));
      if (lowerRangeCondition && upperRangeCondition) {
        arr.push(numberToHour(i));
      }
      const minute = parseInt(
        Math.round(i * 100)
          .toString()
          .slice(-2)
      );

      if (minute >= 45) i = Math.floor(i) + 1;
      else i += 0.15;
    }
    return arr;
  };

  useEffect(() => {
    if (date) {
      const timeToSet = d.fromISO(date).toFormat("HH:mm");
      const timeArray = getTimesArray(timeRange.current.fromTime);
      if (timeArray.find((formattedTime) => formattedTime === timeToSet)) {
        setTime(timeToSet);
        handleChange({ date: setTimeOnDate(d.fromISO(date), hourToNumber(timeToSet)).toUTC().toISO(), time: 0 })
      } else {
        setTime(timeArray[0]);
        if (timeArray[0]) {
          handleChange({ date: setTimeOnDate(d.fromISO(date), hourToNumber(timeArray[0])).toUTC().toISO(), time: 0 })
        }
      }
    }
  }, [date]);

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({
      time: 0,
      date: setTimeOnDate(d.fromISO(date), hourToNumber(event.target.value))
        .toUTC()
        .toISO()
    });
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Grid item xs={12}>
          <FlatPickerBar
            handleDateChange={(value: Date) => {
              handleChange({
                date: d
                  .fromJSDate(value)
                  .toUTC()
                  .toISO(),
                time: 0
              });
            }}
            label={dateTitle ? dateTitle : ""}
            identifier={name}
            name={name}
            now={now}
            disabled={disabled}
            placeholderValue={"Select Date"}
            value={getLocalizedDateFormat(country, date, DATE_TYPE.CONDENSED)}
            minDate={
              minDate &&
              getLocalizedDateFormat(country, minDate, DATE_TYPE.CONDENSED)
            }
            maxDate={
              maxDate &&
              getLocalizedDateFormat(country, maxDate, DATE_TYPE.CONDENSED)
            }
            enableTime={false}
            type={"simple-time-picker"}
            required={required}
            country={country}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <InputField
          required
          select
          label={timeTitle}
          value={time}
          InputProps={{
            onChange: handleTimeChange
          }}
          disabled={disabled || !date}
          fullWidth
        >
          {getTimesArray(timeRange.current.fromTime).map((hour) => {
            return (
              <MenuItem value={hour} key={hour}>
                {d.fromFormat(hour, "HH:mm").toFormat("hh:mm a")}
              </MenuItem>
            );
          })}
        </InputField>
      </Grid>
    </Grid>
  );
};
