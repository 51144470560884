import { gql } from "@apollo/client";

export const UPLOAD_BOOKING = gql`
  mutation uploadBooking($bookings: [UploadBookingInput]!) {
    uploadBooking(bookings: $bookings) {
      errors {
        index
        message
      }
      bookings {
        id
      }
    }
  }
`;