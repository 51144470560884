import { Dialog, DialogContent, Fab, Grid, TextField, Theme, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { IJobSheet } from '../../../../../reducers/fleet/types';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_SERVICE_TASK_ITEMS } from '../../../../../graphql/fleet/vehicleServices/getAllServiceTaskListQuery';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import _ from 'lodash';
import { Field } from 'formik';
import { FloatInput } from '../../../../common/FloatInput/FloatInput';
import { WithStyles, createStyles, withStyles } from '@mui/styles';

interface IProps {
  open: boolean,
  handleClose: () => void;
  inProgress: boolean;
  handleSubmit: (jobSheet: IJobSheet) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}


const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const AddNewServiceTaskDialog: FC<IProps> = ({ handleClose, open, handleSubmit }) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [values, setValues] = useState<IJobSheet>({
    id: "",
    name: "",
    description: "",
    cost: 0
  });
  const [serviceTasks, setServiceTasks] = useState<IJobSheet[]>([]);
  const [selectedTask, setSelectedTask] = useState<string>("");

  const [loadServiceTasks, { loading: lodingServiceTasks, data: serviceTaskData }
  ] = useLazyQuery(
    GET_ALL_SERVICE_TASK_ITEMS, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (userState.tenancy) {
      loadServiceTasks();
    }
  }, [userState.tenancy])

  useEffect(() => {
    if (serviceTaskData && serviceTaskData.getAllVehicleServiceTask) {
      const allServiceTasks = [
        ...serviceTaskData.getAllVehicleServiceTask.customTasks,
        ...serviceTaskData.getAllVehicleServiceTask.defaultTasks
      ];
      setServiceTasks(allServiceTasks);
    }
  }, [serviceTaskData]);

  const handleAutoCompleteInputChange = _.debounce((value: string, reason: any) => {
    if (reason === "input" || reason === "reset") {
      const resourcesToFilter = [...serviceTasks];
      if (value) {
        const matchedTask = resourcesToFilter.find((task) => task.name === value);
        if (matchedTask) {
          setValues({
            ...values,
            id: matchedTask.id ? matchedTask.id : "",
            name: matchedTask.name,
            description: matchedTask.description ? matchedTask.description : ""
          });
        }
      }
    }
    if (reason === 'clear') {
      setValues({
        ...values,
        name: "",
        description: ""
      });
      setSelectedTask("")
    }
  }, 200);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      className={"select-holidays-dialog"}
    >
      <React.Fragment>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p style={{ fontSize: 18, color: "var(--theme-accent)", padding: 0, margin: 0 }}>
            ADD NEW TASK
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} style={{ marginTop: 10 }}>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                options={serviceTasks}
                getOptionLabel={(option: any) =>
                  option.name
                }
                onInputChange={(event: any, value: string, reason: string) => {
                  handleAutoCompleteInputChange(value, reason);
                  setSelectedTask(value);
                }}
                inputValue={selectedTask}
                renderInput={(params) => (
                  <TextField {...params}
                    name={"name"}
                    label="Name"
                    placeholder="Name"
                    required
                    margin="normal"
                    variant="outlined"
                    style={{ backgroundColor: "white" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} style={{ marginTop: 10 }}>
              <TextField
                name="description"
                label="Description"
                placeholder="Description"
                value={values.description}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e && e.target && e.target.value) {
                    setValues({
                      ...values,
                      description: e.target.value
                    })
                  } else {
                    setValues({
                      ...values,
                      description: ""
                    })
                  }
                }}
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={6} md={4} style={{ marginTop: 10 }}>
              <Field
                component={TextField}
                name={"cost"}
                placeholder={"e.g 100"}
                InputProps={{
                  value: values.cost,
                  inputComponent: FloatInput as any,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value) {
                      setValues({
                        ...values,
                        cost: parseInt(event.target.value)
                      })
                    }
                  }
                }}
                inputProps={{
                  hasCurrencyPrefix: true,
                  allowNegative: false
                }}
                fullWidth
              ></Field>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Fab
                variant="extended"
                size="medium"
                aria-label="submit"
                disabled={!values.name || !values.cost}
                onClick={() => {
                  handleSubmit(values)
                }}
              >
                Add
              </Fab>
            </Grid>
          </Grid>
        </DialogContent>
      </React.Fragment>
    </Dialog >
  )
}

export default AddNewServiceTaskDialog