import { gql } from "@apollo/client";

export const GET_ADDON_TYPES = gql`
  {
    addonTypes {
      id
      name
      category
      displayName
      imageUrl
      hasFixedRate
      hasQuantity
      fixedRate
      description
      recurringRate {
        rateTypeName
        rateTypeDuration
        rate
        longTerm
      }
      totalQuantity
      availableQuantity
      isActivated
    }
  }
`;
