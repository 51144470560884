import {
  Fab,
  Grid,
  Paper
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  resetAuthorisedSignatory,
  setAuthorisedSignatory
} from "../../../../../../actions/customer/actions";
import { IBusinessCustomerCommonInput } from "../../../../../../reducers/customer/types";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { resetBranch } from "../../../../../../actions/organisation/actions";

export const columns = [
  {
    label: "First Name",
    name: "firstName"
  },
  {
    label: "Last Name",
    name: "lastName"
  },
  {
    label: "Email",
    name: "email"
  }
];
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 20,
      padding: 8
    }
  })
);
export const AuthorisedSignatoriesList = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selection, setSelection] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [updateButtonVisible, setUpdateButtonVisible] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (selection.length === 1) {
      setUpdateButtonVisible(true);
    } else {
      setUpdateButtonVisible(false);
      dispatch(resetAuthorisedSignatory());
    }
  }, [selection, dispatch]);

  useEffect(() => {
    if (props.authorizedSignatories) {
      setRows(props.authorizedSignatories);
      setSelection([]);
    }
  }, [props.authorizedSignatories]);

  return (
    <Grid container className={classes.root}>
      <Grid item container xs={12} justifyContent="flex-end" style={{ paddingBottom: "20px" }}>
        <Fab
          variant="extended"
          size="medium"
          aria-label="Update"
          disabled={!updateButtonVisible}
          className={
            updateButtonVisible ? "deleteButton" : "deleteButtonDisable"
          }
          onClick={() => {
            dispatch(
              setAuthorisedSignatory({
                ...props.authorizedSignatories.filter(
                  (element: IBusinessCustomerCommonInput) =>
                    element.id === selection[0].id
                )[0]
              })
            );
          }}
        >
          Update
        </Fab>
      </Grid>
      <Grid item container xs={12}>
        <Paper variant="outlined" style={{ width: "73vw" }}>
          <NuvvenTable
            title={""}
            rows={rows}
            columns={columns}
            setSelection={setSelection}
            options={
              {
                responsive: "vertical",
                elevation: 0
              }
            }
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
