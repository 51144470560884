import { gql } from "@apollo/client";

export const UPDATE_VEHICLE_TAX_AMOUNT = gql`
  mutation updateVehicleTaxAmount($licencePlate: String!, $taxAmount: Float!) {
    updateVehicleTaxAmount(licencePlate: $licencePlate, taxAmount: $taxAmount) {
      success
      message
    }
  }
`

export const UPDATE_TRAILERS = gql`
  mutation updateTrailers($vehicleId: String!, $updateVehicleTestReport: UpdateVehicleTestReportInput!) {
    updateTrailers(vehicleId: $vehicleId, updateVehicleTestReport: $updateVehicleTestReport) {
    id
    vehicleTestReports {
        testDate
        expiryDate
        testResult
        odometerReading
        odometerUnit
        motTestNumber
        advisoryItems
        minorItems
        failureItems
        vehicleTestDueDate
      }
    }
  }
`;