import { useLazyQuery, useMutation } from "@apollo/client";
import { Grid, Theme, CircularProgress } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ApolloError } from '@apollo/client';
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import _ from 'lodash';

import { updateUser } from "../../../actions/user/actions";
import { UPDATE_ADMIN_USER } from '../../../graphql/adminUser/adminUser';
import { IBranch, IOrganisation, ITenancy } from "../../../reducers/user/types";
import { IAppState } from "../../../store";
import {
  NullBranchManager,
  NullOrganisatioOwner,
  UserRoles
} from "../../hoc/Authorization";
import { useSnackBar } from '../SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../SnackbarWrapper/SnackbarWrapper';
import { formatGraphQLErrorMessage } from '../utils';
import { BranchSelector } from "./BranchSelector";
import { OrganisationSelector } from "./OrganisationSelector";
import { TenancySelector } from "./TenancySelector";
import { GET_APP_VEW_TENANCIES, GET_APP_VIEW_BRANCHES, GET_APP_VIEW_ORGANISATIONS, GET_APP_VIEW_ORGANISATION_BY_TENANTID } from "../../../graphql/SwitchAppViews/SwitchAppViewsQueries";
import { captureErrorException } from "../../../utils/sentry";

const OrganisationSelectorAdmin = NullOrganisatioOwner(OrganisationSelector);
const BranchSelectorOrganisationOwner = NullBranchManager(BranchSelector);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: "10px"
    },
    section: {
      marginBottom: "8px"
    },
    formControl: {
      minWidth: 140
    },
    buttonSave: {
      backgroundColor: "#faaf40",
      color: "#fff",
      padding: "5px 9px !important",
      width: "70px",
      border: "1px solid #faaf40",
      borderRadius: "4px",
      fontSize: "12px",
      outline: "none !important",
      cursor: "pointer"
    },
    formStyle: {
      flexGrow: 1,
      width: "100%"
    }
  })
);

interface ISwitchAppViewProps {
  history?: any;
}

export const SwitchAppView = (props: ISwitchAppViewProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const [organisations, setOrganisations] = useState<IOrganisation[]>([]);
  const [branches, setBranches] = useState<IBranch[]>([]);
  const [tenancies, setTenancies] = useState<ITenancy[]>([]);
  const [values, setValues] = useState({
    tenancyId: "",
    organisationId: "",
    branchId: ""
  });
  const userState = useSelector((state: IAppState) => state.userReducer);

  const [getTenancies, { loading: tenanciesLoading, data: tenanciesData }] = useLazyQuery(GET_APP_VEW_TENANCIES, {
    fetchPolicy: "network-only"
  });

  const [getOrganisationByTenantId, { loading: tenanciesOrganisationLoading, data: tenantOrganisationData }] =
    useLazyQuery(GET_APP_VIEW_ORGANISATION_BY_TENANTID, {
      fetchPolicy: "network-only"
    });

  const [getOrganisations, { loading: organisationLoading, data: organisationsData }] = useLazyQuery(GET_APP_VIEW_ORGANISATIONS, {
    fetchPolicy: "network-only"
  });

  const [getOrganisatoinBranches, { loading: branchLoading, data: branchData }] = useLazyQuery(GET_APP_VIEW_BRANCHES, {
    fetchPolicy: "network-only"
  });

  const [updateAdminUser] = useMutation(UPDATE_ADMIN_USER, {
    onCompleted: () => {
      window.location.reload();
      localStorage.removeItem("tablesState");
    }
  });

  useEffect(() => {
    if (userState) {
      if (userState.currentTenancy && userState.role === UserRoles.SUPER_ADMIN) {
        getTenancies();
        handleTenancySelection(userState.currentTenancy.id);
      }
      else {
        getOrganisations();
        handleOrganisationSelection(userState.currentOrganisation.id, userState.currentTenancy.id);
      }
      setValues({
        ...values,
        tenancyId: userState.currentTenancy?.id,
        organisationId: userState.currentOrganisation.id,
        branchId: userState.currentBranch.id
      });
    }
  }, [userState]);

  useEffect(() => {
    if (tenanciesData && tenanciesData.tenancies) {
      setTenancies(_.sortBy(tenanciesData.tenancies, tenancies => tenancies.name.toLowerCase()));
    }
  }, [tenanciesData]);

  useEffect(() => {
    if (organisationsData && organisationsData.organisations) {
      setOrganisations(_.sortBy(organisationsData.organisations, organisations => organisations.name.toLowerCase()));
    }
  }, [organisationsData]);

  useEffect(() => {
    if (branchData && branchData.branches) {
      setBranches(_.sortBy(branchData.branches, branches => branches.name.toLowerCase()));
    }
  }, [branchData]);

  useEffect(() => {
    if (tenantOrganisationData && tenantOrganisationData.tenancyOrganisations) {
      setOrganisations(_.sortBy(tenantOrganisationData.tenancyOrganisations, organisations => organisations.name.toLowerCase()));
    }
  }, [tenantOrganisationData]);

  const handleTenancySelection = (tenancyId: string) => {
    setValues({ ...values, tenancyId });
    getOrganisationByTenantId({
      variables: {
        tenancyId
      }
    });
    getOrganisatoinBranches({
      variables: {
        tenancyId: tenancyId,
        organisationId: userState.currentOrganisation.id || values.organisationId
      }
    });
  }

  const handleOrganisationSelection = (organisationId: string, tenancyId?: string) => {
    setValues({ ...values, organisationId });
    getOrganisatoinBranches({
      variables: {
        tenancyId: tenancyId || values.tenancyId,
        organisationId
      }
    });
  };

  const onSubmitHandler = (values: any) => {
    const { branchId, organisationId, tenancyId } = values;
    const selectedTenancy = tenancies.find((tenancy: ITenancy) => tenancy.id === tenancyId);
    const selectedOrganisation = organisations.find((org) => org.id === organisationId);
    const selectedBranch = branches.find((branch: IBranch) => branch.id === branchId);
    if (selectedOrganisation && selectedBranch) {
      updateAdminUser({
        variables: {
          userId: userState.id,
          user: {
            firstName: userState.firstName ? userState.firstName : "",
            lastName: userState.lastName ? userState.lastName : "",
            phoneNumber: userState.phoneNumber ? userState.phoneNumber : "",
            organisationId: userState.organisation.id,
            branchId: userState.branch.id,
            currentOrganisationId: selectedOrganisation.id,
            currentBranchId: selectedBranch.id,
            role: userState.role,
            active: userState.active,
            currentTenantId: userState.role === UserRoles.SUPER_ADMIN ? selectedTenancy?.id : userState.tenancy.id
          }
        }
      }).then(() => {
        dispatch(
          updateUser({
            currentTenancy: selectedTenancy,
            currentOrganisation: selectedOrganisation,
            currentBranch: selectedBranch
          })
        );
      }).catch((error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      });
    }
  };

  const switchAppViewSchema = Yup.object().shape({});

  if (tenanciesLoading || tenanciesOrganisationLoading || organisationLoading || branchLoading) {
    return <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></span>
  }

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={switchAppViewSchema}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmitHandler(values);
        }}
      >
        {(props) => (
          <Form noValidate className={classes.formStyle}>
            <Grid container spacing={2}>
              {userState && userState.role === UserRoles.SUPER_ADMIN && (
                <TenancySelector
                  tenancies={tenancies}
                  tenancyId={props.values.tenancyId}
                  handleTenancySelection={handleTenancySelection}
                />
              )}
              <OrganisationSelectorAdmin
                organisationId={props.values.organisationId}
                organisations={organisations}
                handleOrganisationSelection={handleOrganisationSelection}
              />
              <BranchSelectorOrganisationOwner
                branchId={props.values.branchId}
                branches={branches}
                onChange={(event: any) => {
                  setValues({
                    ...props.values,
                    branchId: event.target.value
                  });
                }}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
