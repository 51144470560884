import { useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { CREATE_PAYMENT_FOR_EXTENSiON } from "../../../../../graphql/invoices/createPaymentForExtensionMutation";
import {
  IPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  PaymentGateway,
  formatGraphQLErrorMessage
} from "../../../../common/utils";

interface IFormValues {
  amount: number;
}
interface IProps {
  bookingId: string;
  extensionId: string;
  amount: number;
}

const SepaTransactionExtension: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency, stripeAccountId, sepaEnabled } = userState.currentOrganisation;
  const snackbar = useSnackBar();
  const [values, setValues] = useState<IFormValues>({
    amount: props.amount,
  });
  const [paymentInput, setPaymentInput] = useState<IPaymentInput>();

  // Make sure to call `loadStripe` outside of a component's render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise: any = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
    {
      stripeAccount: stripeAccountId
    }
  );

  const [createPaymentForExtension] = useMutation(CREATE_PAYMENT_FOR_EXTENSiON, {
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: async (data) => {
      if (data?.createPaymentForExtension?.payment?.id) {
        // Get Stripe.js instance
        const stripe = await stripePromise;
        // When the customer clicks on the button, redirect them to Checkout.
        if (stripe) {
          setLoading(false);
          navigate(`/payment?booking=${props.bookingId}&payment=${data.createPaymentForExtension.payment.id}`)
        }
      }
    }
  });

  const submitStripeForm = () => {
    setLoading(true);
    const payment: IPaymentInput = {
      amount: values.amount,
      booking: props.bookingId,
      bookingExtension: props.extensionId,
      currency,
      description: PaymentMode.SEPA_BANK_DEBIT,
      expireBy: "",
      invoice: "",
      paymentMode: PaymentMode.SEPA_BANK_DEBIT,
      paymentType: "INWARD",
      paymentGateway: PaymentGateway.STRIPE,
      successUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.bookingId}&status=success`,
      cancelUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.bookingId}&status=failure`
    };
    setPaymentInput(payment);
    createPaymentForExtension({
      variables: {
        payment
      }
    });
  };

  const AMOUNT_TOO_HIGH = `Amount must be less than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format(props.amount / 100)}`;

  const stripeFormSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan(0)
      .max(props.amount, AMOUNT_TOO_HIGH)
  });

  return (
    <Grid container style={{ padding: 30 }}>
      {!sepaEnabled ?
        <Grid container>
          <Typography variant="h4">
            To use this payment mode please enable sepa bank debit in Payment and Integration Settings.
          </Typography>
        </Grid> :
        <>
          {stripeAccountId ?
            <Grid item container xs={12}>
              <Formik
                enableReinitialize
                initialValues={values}
                validationSchema={stripeFormSchema}
                onSubmit={(values, { setSubmitting }) => {
                  submitStripeForm();
                  setSubmitting(false);
                }}
              >
                {(formikProps) => (
                  <Form>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        placeholder="Enter Amount"
                        label="Enter Amount"
                        name={"amount"}
                        disabled
                        fullWidth
                        required
                        InputProps={{
                          onChange: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...values,
                              amount: parseInt(e.target.value)
                            });
                          },
                          value: values.amount,
                          inputComponent: FloatInput as any
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item container>
                        <Box mt={2}></Box>
                      </Grid>
                      <Grid item container>
                        <Fab
                          variant="extended"
                          size="medium"
                          aria-label="add"
                          type="submit"
                          disabled={loading}
                        >
                          {loading && (
                            <CircularProgress
                              size={14}
                              style={{ color: "white", marginRight: "10px" }}
                            />
                          )}
                          Proceed To Pay
                        </Fab>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
            :
            <Grid container xs={12}>
              <Typography style={{ margin: "1rem" }}>
                Please add Stripe Account Id in organisation settings to use this
                feature.
              </Typography>
            </Grid>
          }
        </>
      }


    </Grid>
  );
};

export default SepaTransactionExtension;