import _ from "lodash";

import {
  BookingAction,
  CLEAR_BOOKING,
  SET_CREATE_BOOKING_INPUT,
  SET_VEHICLE_SELECTION_VIEW,
  UPDATE_BOOKING,
  UPDATE_BOOKING_INVOICE,
  UPDATE_VEHICLE_CHECKS
} from "../../actions/bookings/types";
import { bookingInitialState } from "./consts";
import { IBookingState } from "./types";

export default function (
  state: IBookingState = bookingInitialState,
  action: BookingAction
): IBookingState {
  switch (action.type) {
    case UPDATE_BOOKING:
      let type;
      if (action.payload.__type) {
        type = action.payload.__type;
        delete action.payload.__type;
      }

      return {
        ...state,
        booking: {
          ...state.booking,
          ...action.payload
        }
      };
    case CLEAR_BOOKING:
      return {
        ...state,
        ...bookingInitialState
      };
    case UPDATE_BOOKING_INVOICE:
      return {
        ...state,
        invoiceId: action.payload
      };
    case SET_VEHICLE_SELECTION_VIEW:
      return {
        ...state,
        vehicleSelectionView: action.payload
      };
    case UPDATE_VEHICLE_CHECKS:
      return {
        ...state,
        checkInfo: {
          ...state.checkInfo,
          ...action.payload
        }
      };
    case SET_CREATE_BOOKING_INPUT:
      return {
        ...state,
        createBookingInput: action.payload
      }
    default:
      return state;
  }
}
