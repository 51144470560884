import { gql } from "@apollo/client";

export const GET_ALL_SERVICE_TASK_ITEMS = gql`
  query getAllVehicleServiceTask {
    getAllVehicleServiceTask {
      id
      defaultTasks {
        id
        name
        taskType
        description
      }
      customTasks {
        id
        name
        taskType
        description
      }
      createdAt
      updatedAt
    }
  }
`;