import Evaporate from 'evaporate';
import { IAuthState } from '../../reducers/auth/types';
import {
  UPDATE_AUTH_STATE,
  AuthAction,
  SET_AUTH_EVAPORATE_CLIENT
} from "./types";

export function updateAuthStateAction(payload: IAuthState): AuthAction {
  return {
    payload,
    type: UPDATE_AUTH_STATE
  };
}


export function setAuthEvaporateClientAction(payload: { _e_: Evaporate }): AuthAction {
  return {
    type: SET_AUTH_EVAPORATE_CLIENT,
    payload
  }
}
