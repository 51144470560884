import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  FormControl,
  Grid,
  Typography,
  Box,
  Paper,
  MenuItem,
  Theme,
  FormControlLabel,
  Switch,
  TextField as InputField
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "formik-mui";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import * as Yup from "yup";
import { DateTime as d } from "luxon"

import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  formatGraphQLErrorMessage
} from "../../../common/utils";
import AddressPicker from "../../CustomerManagement/NewCustomer/AddressPicker/AddressPicker";
import { IAddress } from "../../../../reducers/user/types";
import { useSelector } from "react-redux";
import { CREATE_ASSET_SERVICE, UPDATE_ASSET_SERVICE, UPDATE_ASSET_SERVICE_STATUS } from "../Graphql/assetServiceMutations";
import { GET_ASSET_SERVICE, SEARCH_ALL_ASSETS } from "../Graphql/assetServiceQueries";
import { AssetServiceInitialValue, IAsset, IAssetDamage, IAssetService, IUpdateAssetServiceStatusInput } from "../asset.types";
import { IAppState } from "../../../../store";
import { VehicleServiceStatus } from "../../../../reducers/fleet/types";
import _ from "lodash";
import { ServiceType, serviceTypes } from "../../Fleet/ServicesAndRepair/constants";
import { IDateTime } from "../../Fleet/ServicesAndRepair/NewServicesAndRepair";
import { SimpleDateTimePicker } from "../../../common/SimpleDateTimePicker";
import { FloatInput } from "../../../common/FloatInput/FloatInput";
import { ConfirmationDialog } from "../../../common/ConfirmationDialog/ConfirmationDialog";
import { ConfirmationModel } from "../../Fleet/ServicesAndRepair/NewServicesAndRepair/ConfirmationModel";
import { GET_ASSET_ACTIVE_DAMAGES } from "../Graphql/assetDamageQueries";
import { DamagesTable } from "../../Fleet/ServicesAndRepair/NewServicesAndRepair/DamagesTable";
import { IVehicleDamage } from "../../../../reducers/damageVehicle/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    form: {
      flexGrow: 1
    }
  })
);

export const NewAssetService: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const [values, setValues] = useState<IAssetService>(AssetServiceInitialValue);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [assetTags, setAssetTags] = useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [openMarkInService, setOpenMarkInService] = useState(false);
  const [openCancelledDialog, setOpenCancelledDialog] = useState(false);
  const [assetActiveDamages, setAssetActiveDamages] = useState<Partial<IAssetDamage[]>>([]);
  const [selectedDamageIds, setSelectedDamageIds] = useState<string[]>([]);

  const [searchAssetByAssetTag, {
    loading: searchAssetByAssetTagLoading,
    data: searchAssetByAssetTagData
  }] = useLazyQuery(SEARCH_ALL_ASSETS, {
    fetchPolicy: "network-only"
  });

  const [getAssetService, { loading: loadingGetAssertService, data: assetServiceData }] = useLazyQuery(GET_ASSET_SERVICE, {
    fetchPolicy: "network-only",
    onCompleted: ({ getAssetService }) => {
      if (!getAssetService) {
        navigate("/asset-services");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
      navigate("/asset-services");
    }
  });

  const [getAssetActiveDamages, { loading: loadingAssetActiveDamages, data: assetActiveDamagesData }] = useLazyQuery(GET_ASSET_ACTIVE_DAMAGES, {
    fetchPolicy: "network-only",
    onCompleted: ({ getAssetActiveDamages }) => {},
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
      navigate("/asset-services");
    }
  });

  const [createAssetService, { loading: createAssetServiceLoading }] = useMutation(CREATE_ASSET_SERVICE, {
    onCompleted: () => {
      snackbar({
        message: formatGraphQLErrorMessage("Asset Service created"),
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/asset-services");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  });

  const [updateAssetService, { loading: updateAssetServiceLoading }] = useMutation(UPDATE_ASSET_SERVICE, {
    onCompleted: () => {
      snackbar({
        message: formatGraphQLErrorMessage("Asset Service updated"),
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  });

  const [updateAssetServiceStatus, { loading: updateAssetServiceStatusLoading }] = useMutation(UPDATE_ASSET_SERVICE_STATUS, {
    onCompleted: ({ updateAssetServiceStatus }) => {
      snackbar({
        message: formatGraphQLErrorMessage("Asset service status updated"),
        variant: SnackBarVariant.SUCCESS
      });
      if (updateAssetServiceStatus.status === VehicleServiceStatus.IN_SERVICE) {
        setOpenMarkInService(false);
        setValues({
          ...values,
          status: updateAssetServiceStatus.status
        })
      }
      if (updateAssetServiceStatus.status === VehicleServiceStatus.CANCELLED) {
        setOpenCancelledDialog(false);
        setValues({
          ...values,
          status: updateAssetServiceStatus.status
        })
      }
      if (updateAssetServiceStatus.status === VehicleServiceStatus.COMPLETED) {
        setOpenConfirmationDialog(false);
        setValues({
          ...values,
          status: updateAssetServiceStatus.status,
          completionDate: updateAssetServiceStatus.completionDate
        })
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const assetServiceId = params.get("id");
      if (assetServiceId) {
        setIsUpdate(true);
        getAssetService({
          variables: {
            assetServiceId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (searchAssetByAssetTagData && searchAssetByAssetTagData.searchAssetByAssetTag) {
      const assets = searchAssetByAssetTagData.searchAssetByAssetTag.filter((asset: Partial<IAsset>) => !asset.isActive);
      setAssetTags(assets);
    }
  }, [searchAssetByAssetTagData]);

  useEffect(() => {
    if (assetServiceData && assetServiceData.getAssetService) {
      setValues({
        ...assetServiceData.getAssetService,
        assetTag: assetServiceData.getAssetService.asset.assetTag || values.assetTag
      });
      getAssetActiveDamages({
        variables: {
          assetId: assetServiceData.getAssetService.asset.id
        }
      })
      const damagesId: string[] = [];
      if (assetServiceData && assetServiceData.getAssetService && assetServiceData.getAssetService.damages) {
        assetServiceData.getAssetService.damages.forEach((damage: IAssetDamage) => {
          if (damage && damage.id) {
            damagesId.push(damage.id);
          }
        });
        setSelectedDamageIds([...selectedDamageIds, ...damagesId]);
      }
    }
  }, [assetServiceData]);

  useEffect(() => {
    if (query) {
      handleSearch(query)
    } else {
      setAssetTags([])
    }
  }, [query]);

  useEffect(() => {
    if (assetActiveDamagesData && assetActiveDamagesData.getAssetActiveDamages) {
      setAssetActiveDamages(assetActiveDamagesData.getAssetActiveDamages);
    }
  }, [assetActiveDamagesData]);

  const handleChange = _.debounce((searchText: string) => {
    setQuery(searchText);
  }, 500)

  const handleSearch = (searchText: string) => {
    if (searchText.trim().length > 2) {
      searchAssetByAssetTag({
        variables: {
          q: searchText.trim()
        }
      })
    } else {
      setAssetTags([])
    }
  }

  // const detailsFormSchema = Yup.object().shape({
  //   name: Yup.string().required("Name is required."),
  //   operator: Yup.string().required("Please select Rule Effect."),
  // });

  const handleFormSubmit = (values: IAssetService) => {
    const { id, assetTag, referenceNumber, ...rest } = values
    if (!rest.status) {
      rest.status = VehicleServiceStatus.SCHEDULED
    }
    if (rest.asset && typeof rest.asset !== 'string') {
      rest.asset = rest.asset.id
    }
    rest.damages = [...selectedDamageIds];
    if (values.id) {
      updateAssetService({
        variables: {
          assetServiceId: values.id,
          assetService: rest
        }
      });
    } else {
      createAssetService({
        variables: {
          assetService: rest
        }
      });
    }
  }

  const handleUpdateAssetServiceStatus = (
    assetServiceId: string | undefined,
    updateAssetServiceStatusValue: IUpdateAssetServiceStatusInput
  ) => {
    if (assetServiceId) {
      updateAssetServiceStatus({
        variables: {
          assetServiceId,
          updateAssetServiceStatus: updateAssetServiceStatusValue
        }
      });
    }
  }

  const damagesSelectionHandler = (selectedDamageIndices: number[]) => {
    if (selectedDamageIndices && selectedDamageIndices.length) {
      let _selectedDamageIds = [...selectedDamageIds];
      for (let index = 0; index < selectedDamageIndices.length; index++) {
        const damageIndex = selectedDamageIndices[index];
        const matchedDamage: IAssetDamage | undefined = assetActiveDamages[damageIndex];
        if (matchedDamage && matchedDamage.id) {
          if (_selectedDamageIds.includes(matchedDamage.id)) {
            _selectedDamageIds = _selectedDamageIds.filter((id) => id !== matchedDamage.id);
          } else {
            _selectedDamageIds.push(matchedDamage.id);
          }
        }
      }
      setSelectedDamageIds(_selectedDamageIds);
    } else {
      setSelectedDamageIds([]);
    }
  };

  if (loadingGetAssertService) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          Asset Service{"  "}
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>
        {!isUpdate ? (
          <Typography variant="h1" color="primary">
            New
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Asset Service
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            // validationSchema={partnerValidationSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              handleFormSubmit(values);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid container item xs={values.id ? 2 : 12} style={{ maxWidth: '70%' }}>
                    <Typography variant="h2">SERVICE DETAILS</Typography>
                  </Grid>
                  {
                    values.id &&
                    <Grid container item xs={10} justifyContent="flex-end">
                      {values.status === VehicleServiceStatus.IN_SERVICE && (
                        <Grid item>
                          <Fab
                            variant="extended"
                            size="medium"
                            color="primary"
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                              setOpenConfirmationDialog(true);
                            }}
                          >
                            Complete Service
                          </Fab>
                        </Grid>
                      )}
                      {values.status !== VehicleServiceStatus.CANCELLED && values.status !== VehicleServiceStatus.IN_SERVICE &&
                        values.status !== VehicleServiceStatus.COMPLETED && values.serviceType === ServiceType.REGULAR && (
                          <Grid item>
                            <Fab
                              variant="extended"
                              size="medium"
                              color="primary"
                              style={{ marginRight: '10px' }}
                              onClick={() => {
                                setOpenCancelledDialog(true);
                              }}
                            >
                              Cancel Service
                            </Fab>
                          </Grid>
                        )}
                      <Grid item style={{ marginLeft: '10px' }}>
                        {values.status !== VehicleServiceStatus.CANCELLED && <FormControlLabel
                          control={
                            <Switch
                              checked={
                                values.status === VehicleServiceStatus.IN_SERVICE ? true : false
                              }
                              onChange={() => {
                                if (props.values.status === VehicleServiceStatus.DUE || props.values.status === VehicleServiceStatus.SCHEDULED) {
                                  setOpenMarkInService(true);
                                }
                              }}
                              name="inService"
                              color="primary"
                            />
                          }
                          label={<Typography variant="body1">{values.status === VehicleServiceStatus.COMPLETED ? 'Completed' : 'In Service'}</Typography>}
                        />}
                      </Grid>
                    </Grid>
                  }
                  <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        name={"serviceType"}
                        fullWidth
                        type="text"
                        select
                        required
                        label="Service Type"
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...props.values,
                              serviceType: event.target.value
                            });
                          },
                          value: props.values.serviceType
                        }}
                      >
                        {serviceTypes.map((item: any, index: number) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      placeholder="Service Provider Name"
                      label="Service Provider Name"
                      name={"serviceProviderName"}
                      value={props.values.serviceProviderName}
                      onChange={props.handleChange}
                      fullWidth
                      required
                    ></Field>
                  </Grid>
                  <Grid item container xs={4}>
                    <AddressPicker
                      fieldName="serviceProviderAddress"
                      required
                      onChange={(address: IAddress) => {
                        if (address) {
                          setValues({ ...props.values, serviceProviderAddress: address })
                        }
                      }}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} >
                    <SimpleDateTimePicker
                      date={props.values.appointmentDate}
                      handleChange={(date: IDateTime) => {
                        props.setValues({
                          ...props.values,
                          appointmentDate: d.fromISO(date.date).toUTC().toISO()
                        });
                      }}
                      required={true}
                      disabled={false}
                      name={"appointmentDate"}
                      dateTitle={"Appointment Date"}
                      timeTitle={"Appointment Time"}
                      minDate={values.appointmentDate && values.appointmentDate < d.now().toUTC().toISO() ? values.appointmentDate : d.now().toUTC().toISO()}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <SimpleDateTimePicker
                      date={props.values.completionDate}
                      handleChange={(date: IDateTime) => {
                        setValues({
                          ...props.values,
                          completionDate: d.fromISO(date.date).toUTC().toISO()
                        })
                      }}
                      required={true}
                      disabled={!props.values.appointmentDate}
                      name={"completionDate"}
                      dateTitle={"Estd. Completion Date"}
                      timeTitle={"Estd. Completion Time"}
                      minDate={d.fromISO(props.values.appointmentDate).toUTC().toISO()}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      id="combo-box-asset-vehicle-id"
                      onChange={(
                        _: React.ChangeEvent<{}>,
                        newVal: any
                      ) => {
                        if (newVal && newVal.id) {
                          setValues({
                            ...props.values,
                            asset: newVal.id,
                            assetTag: newVal.assetTag
                          });
                          getAssetActiveDamages({
                            variables: {
                              assetId: newVal.id
                            }
                          })
                        } else {
                          setValues({
                            ...props.values,
                            asset: "",
                            assetTag: ""
                          });
                        }
                      }}
                      disabled={values.id ? true : false}
                      loading={searchAssetByAssetTagLoading}
                      loadingText={"Loading vehicles..."}
                      noOptionsText={query.length > 2 && !assetTags.length ?
                        "No asset found with search criteria" :
                        "Type to search assets"
                      }
                      options={assetTags ?? []}
                      getOptionLabel={(option: any) => {
                        return option.assetTag;
                      }
                      }
                      value={values}
                      style={{ width: "100%" }}
                      renderInput={(params: any) => (
                        <InputField
                          {...params}
                          label={"Search for asset"}
                          variant="outlined"
                          fullWidth
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
                          value={values.assetTag}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Field
                      component={TextField}
                      label="Total Expense"
                      name={"totalExpense"}
                      placeholder={"e.g 100"}
                      InputProps={{
                        inputComponent: FloatInput as any
                      }}
                      inputProps={{
                        hasCurrencyPrefix: true,
                        allowNegative: false
                      }}
                      value={props.values.totalExpense}
                      onChange={props.handleChange}
                      fullWidth
                    ></Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label={"Description"}
                      name={"description"}
                      onChange={props.handleChange}
                      value={props.values.description}
                      inputProps={{ maxLength: 500 }}
                      fullWidth
                      multiline
                      rows={"3"}
                    />
                  </Grid>
                  {assetActiveDamages && assetActiveDamages.length > 0 && (
                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <Grid item container xs={12}>
                        <Typography variant="h3" gutterBottom>
                          DAMAGES
                        </Typography>
                      </Grid>
                      <DamagesTable
                        vehicleDamages={assetActiveDamages && assetActiveDamages.length ? assetActiveDamages : []}
                        damagesSelectionHandler={damagesSelectionHandler}
                        selectedDamages={props.values.damages}
                      />
                    </Grid>
                  )}
                  <Grid item container xs={12}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                      disabled={values.status === VehicleServiceStatus.CANCELLED || values.status === VehicleServiceStatus.COMPLETED}
                    >
                      {(createAssetServiceLoading || updateAssetServiceLoading) && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                      Save
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
        <ConfirmationModel
          open={openConfirmationDialog}
          handleconfirm={(completionDate) => {
            handleUpdateAssetServiceStatus(values.id, {
              status: VehicleServiceStatus.COMPLETED,
              completionDate
            })
          }}
          handleClose={() => setOpenConfirmationDialog(false)}
          completionDate={values.completionDate}
          appointmentDate={values.appointmentDate}
        />
        {openMarkInService && (
          <ConfirmationDialog
            isOpen={openMarkInService}
            title={"Mark Asset In-Service"}
            description={"Are you sure, you want to mark this asset In-Service"}
            onCancel={() => setOpenMarkInService(false)}
            onConfirm={() => {
              handleUpdateAssetServiceStatus(values.id, { status: VehicleServiceStatus.IN_SERVICE });
            }}
          />
        )}
        {openCancelledDialog && (
          <ConfirmationDialog
            isOpen={openCancelledDialog}
            title={"Cancel Asset Service"}
            description={"Are you sure, you want to cancel this asset service?"}
            onCancel={() => setOpenCancelledDialog(false)}
            onConfirm={() => {
              handleUpdateAssetServiceStatus(values.id, { status: VehicleServiceStatus.CANCELLED })
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}