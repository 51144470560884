import { Value } from "react-phone-number-input";

import { bookingInitialState } from "../bookings/consts";
import { IInvoicesState } from "./types";

export const initialState: IInvoicesState = {
  invoice: {
    id: "",
    booking: bookingInitialState.booking,
    damageAmount: 0,
    dateCreated: "",
    otherRefunds: 0,
    dateUpdated: "",
    finesAmount: 0,
    invoiceRef: "",
    status: "",
    invoiceType: "FIRST",
    payments: [],
    startDate: "",
    valetAmount: 0,
    customer: {
      email: "",
      firstName: "",
      id: "",
      lastName: "",
      phoneNumber: {
        phone: "" as Value,
        country: ""
      },
      location: {
        city: "",
        country: "",
        fullAddress: "",
        state: "",
        street: "",
        zipcode: ""
      }
    },
    createdBy: "",
    updatedBy: "",
    discount: bookingInitialState.booking.discount,
    locked: false,
    refunds: [],
    endDate: "",
    tax: [],
    otherCharges: [],
    totalPayableAmount: 0,
    anyPaidAmount: 0,
    confirmedPaidAmount: 0,
    anyRefundAmount: 0,
    confirmedRefundAmount: 0,
    depositRefund: 0,
    balanceDueFromPreviousInvoice: 0,
    taxAmount: 0,
    rentalAmount: 0,
    insuranceAmount: 0,
    discountAmount: 0,
    addonsAmount: 0,
    rateTypeName: "daily",
    rateTypeDuration: 0,
    customerType: "individual",
    invoiceItems: [],
    sageAccountingInvoiceId: "",
    sageAccountingError: "",
    dueAmount: 0,
    dueDate: ""
  }
};
