import { Select as MUISelect, Checkbox as MUICheckBox, Dialog, DialogContent, DialogTitle, Fab, FormControl, Grid, MenuItem, TextareaAutosize, Theme, Typography, TableContainer, Table, TableRow, TableCell, TableHead, Paper, TableBody, InputLabel, Input, ListItemText } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { createStyles, makeStyles } from "@mui/styles";
import { TextField } from 'formik-mui'
import React, { useEffect, useState } from 'react'
import FlatPickerBar from "../../../../common/FlatPicker";
import { IPartner, IProcurementRequestItem, IProposalRequest, ProcurementItemStatuses } from '../../../../../reducers/Procurement/types'
import { DateTime as d } from "luxon"
import { NuvvenDivider } from '../../../ReservationManagement/Summary/Components/Divider'
import { useSelector } from 'react-redux'
import { IAppState } from '../../../../../store'
import { GET_PARTNERS_LIST } from '../../../../../graphql/Procurement/getPartnersListQuery'
import { useLazyQuery } from '@apollo/client'
import { DocumentDialog } from '../../../CustomerManagement/CustomerDetails/DocumentDialog'
import { v4 as uuidv4 } from "uuid";
import { DATE_TYPE, checkUploadFileFormat, uploadFileExtensionAndContentType } from '../../../../common/utils'
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext'
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper'
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { captureErrorException } from '../../../../../utils/sentry';

export const proposalRequestInitials: IProposalRequest = {
  date: "",
  items: [],
  note: {
    createdAt: "",
    createdBy: "",
    description: ""
  },
  validUntilDate: "",
  partnerIds: []
}

interface IProps {
  proposalRequest?: IProposalRequest;
  open: boolean;
  items: IProcurementRequestItem[];
  onClose(): void;
  handelFormSubmit(proposalRequest: IProposalRequest): void;
  procurementExpectedDate: string;
  proposalRequests: IProposalRequest[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      background: "#dee2e6"
    },
    formPaper: {
      padding: theme.spacing(3)
    },
    form: {
      flexGrow: 1
    }
  })
);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const NewProposalRequestModel: React.FC<IProps> = (props) => {

  const { handelFormSubmit, procurementExpectedDate } = props;
  const classes = useStyles();
  const snackbar = useSnackBar();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [values, setValues] = useState<IProposalRequest>(proposalRequestInitials)
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [items, setItems] = useState<IProcurementRequestItem[]>([])
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<IPartner[]>([])
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [itemIndex, setItemIndex] = useState<number>(-1)

  const [
    loadPartners,
    { data }
  ] = useLazyQuery(GET_PARTNERS_LIST, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (userReducer.tenancy) {
      loadPartners()
    }
  }, [userReducer.tenancy])

  useEffect(() => {
    if (data && data.partners) {
      const activePartners = data.partners.filter((partner: IPartner) => partner.isActive);
      let prevPartners: string[] = []
      if (props.proposalRequests && props.proposalRequests.length > 0) {
        props.proposalRequests.forEach((pRequest) => {
          if (pRequest.partner) {
            prevPartners.push(pRequest.partner?.id)
          }
        })
      }
      if (prevPartners && activePartners && activePartners.length) {
        const updatedPartners = activePartners.filter((aPartner: IPartner) => {
          return prevPartners.indexOf(aPartner.id) < 0
        })
        setPartners(updatedPartners)
      } else {
        setPartners(activePartners);
      }

    }
  }, [data])

  useEffect(() => {
    if (props.proposalRequest?.id) {
      setIsUpdate(true)
      setValues(props.proposalRequest)
    } else {
      setValues(proposalRequestInitials)
    }
  }, [props.proposalRequest])

  useEffect(() => {
    if (props.items) {
      const products = props.items.filter((item) => (item.status === ProcurementItemStatuses.APPROVED))
      setItems(products)
    }
  }, [props.items])

  async function uploadDocument(
    file: File,
    title: string,
    documentType: string,
    reminderBefore: number,
    expiryDate: string,
  ) {
    try {
      if (!userReducer.tenancy?.id) {
        return;
      }
      // 5MB limit exceeded
      if (file.size > 5000000) {
        throw new Error("File size exceeded limit of 5MB");
      }
      setUploadInProgress(true);
      if (checkUploadFileFormat(file.type)) {
        const {
          fileExtension
        } = uploadFileExtensionAndContentType(file.type);
        const uniqueId = uuidv4();
        const key = `${userReducer.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                if (itemIndex >= 0) {
                  values.items[itemIndex].documents = [...values.items[itemIndex].documents, key]
                }
                setValues(values)
                snackbar({
                  message: "Document Added",
                  variant: SnackBarVariant.SUCCESS
                });
                setUploadInProgress(false);
                setDocumentDialogVisible(false);
              }
            });
        }
      } else {
        setDocumentDialogVisible(false);
        setUploadInProgress(false);
        return snackbar({
          message: "Please only upload .pdf!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose()
        }}
        maxWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container item xs={12}>
            <Typography variant="h2">{isUpdate ? "Update Proposal Request" : "New Proposal Request"}</Typography>
          </Grid>
          <NuvvenDivider noMargin />
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Formik
              enableReinitialize
              // validationSchema={procurementValidationSchema}
              initialValues={values}
              onSubmit={(values, { setSubmitting }) => {
                // handleAddItem(values)
              }}
            >
              {(props) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h3'>RFP Information</Typography>
                    </Grid>
                    {
                      props.values.id ? (
                        <Grid item xs={3}>
                          <Field
                            component={TextField}
                            placeholder="e.g TEST"
                            label="Partner"
                            name={"partnerName"}
                            value={props.values.partner?.partnerName}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            disabled
                          ></Field>
                        </Grid>
                      ) : (
                        <Grid item xs={3}>
                          <FormControl fullWidth variant='outlined'>
                            <InputLabel id="mutiple-checkbox-label-partner">Select Partner</InputLabel>
                            <MUISelect
                              labelId="mutiple-checkbox-label-partner"
                              id="mutiple-checkbox-label-partner"
                              multiple
                              variant='outlined'
                              value={selectedPartner}
                              onChange={(e: any) => {
                                setSelectedPartner(e.target.value)
                              }}
                              required
                              input={<Input required />}
                              renderValue={(selectedPartner: any) => selectedPartner.map((partner: any) => partner.partnerName).join(", ")}
                              MenuProps={MenuProps}
                            >
                              {
                                partners &&
                                partners.map((partner) => (
                                  <MenuItem key={partner.id} value={partner as any}>
                                    <MUICheckBox
                                      checked={
                                        selectedPartner.find((sPartner: IPartner) => sPartner.id === partner.id) ? true : false
                                      }
                                    />
                                    <ListItemText primary={partner.partnerName} />
                                  </MenuItem>
                                ))
                              }
                            </MUISelect>
                          </FormControl>
                        </Grid>
                      )
                    }
                    <Grid item xs={3}>
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          setValues({
                            ...props.values,
                            date: d.fromJSDate(value).toUTC().toISO()
                          })
                        }}
                        label={"RFP Date"}
                        identifier={"date"}
                        value={getLocalizedDateFormat(country, props.values.date, DATE_TYPE.CONDENSED)}
                        minDate={getLocalizedDateFormat(country, procurementExpectedDate, DATE_TYPE.CONDENSED)}
                        required
                        country={country}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          setValues({
                            ...props.values,
                            validUntilDate: d.fromJSDate(value).toUTC().toISO()
                          })
                        }}
                        label={"Valid Untill"}
                        identifier={"validUntilDate"}
                        value={getLocalizedDateFormat(country, props.values.validUntilDate, DATE_TYPE.CONDENSED)}
                        minDate={getLocalizedDateFormat(country, props.values.date, DATE_TYPE.CONDENSED)}
                        required
                        country={country}
                      />
                    </Grid>
                    <Grid item xs={12} container justifyContent='space-between'>
                      <Typography variant='h3'>Product Information</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell className={"tableCellHead"}>No. of Vehicles</TableCell>
                              <TableCell className={"tableCellHead"} align="left">Make</TableCell>
                              <TableCell className={"tableCellHead"} align="left">Model</TableCell>
                              <TableCell className={"tableCellHead"} align="left">Variant</TableCell>
                              <TableCell className={"tableCellHead"} align="left">Fuel</TableCell>
                              <TableCell className={"tableCellHead"} align="left">Transmission</TableCell>
                              <TableCell className={"tableCellHead"} align="left">Condition</TableCell>
                              <TableCell className={"tableCellHead"} align="left">Expected by</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              items &&
                              items.map((row: IProcurementRequestItem, index: number) => (
                                <TableRow
                                  key={row.requestId}
                                >
                                  <TableCell align="left">{row.quantity}</TableCell>
                                  <TableCell align="left">{row.make}</TableCell>
                                  <TableCell align="left">{row.model}</TableCell>
                                  <TableCell align="left">{row.variant}</TableCell>
                                  <TableCell align="left">{row.fuel}</TableCell>
                                  <TableCell align="left">{row.transmission}</TableCell>
                                  <TableCell align="left">{row.condition}</TableCell>
                                  <TableCell align="left">{d.fromISO(row.expectedByDate).toFormat('dd/MM/yyyy')}</TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    <Grid item xs={12}><Typography variant={"h3"}>Add Notes</Typography></Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextareaAutosize
                          maxRows={10}
                          minRows={4}
                          placeholder="add your note here..."
                          value={props.values.note.description}
                          onChange={(e: any) => {
                            setValues({
                              ...props.values,
                              note: {
                                ...props.values.note,
                                description: e.target.value,
                                createdBy: userReducer.id || "",
                                createdAt: d.now().toUTC().toISO()
                              }
                            })
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-start">
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        onClick={() => {
                          let partners = []
                          for (let i = 0; i < selectedPartner.length; i++) {
                            partners.push(selectedPartner[i].id)
                          }
                          handelFormSubmit({ ...values, partnerIds: partners })
                        }}
                        disabled={((isUpdate ? props.values.id : props.values.partnerIds) && props.values.date && props.values.validUntilDate) ? false : true}
                      >
                        Save
                      </Fab>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <DocumentDialog
            open={documentDialogVisible}
            handleClose={() => setDocumentDialogVisible(false)}
            uploadInProgress={uploadInProgress}
            onSubmit={uploadDocument}
            accept="application/zip"
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
