import "./index.scss";

import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import { ICustomerDetails } from "../../../../../../reducers/bookings/types";


interface IProps {
  dataOptions: any;
  isDriver: boolean;
  values?: ICustomerDetails;
  disabled?: boolean;
  updateData: (data: any) => void;
}

export const SearchForm = (props: IProps) => {
  return (
    <div>
      <Autocomplete
        id="combo-box-demo"
        onChange={(event: any, newValue: any) => {
          if (newValue) {
            props.updateData(newValue);
          }
        }}
        options={props.dataOptions}
        disabled={props.disabled}
        getOptionLabel={(option: any) =>
          `${option.firstName} ${option.lastName} ${option.email}`
        }
        value={props.values}
        style={{ width: "100%" }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={props.isDriver ? "Search for Driver" : "Search for Customer"}
            variant="outlined"
            value={props.values ?
              `${props.values.firstName} ${props.values.lastName} ${props.values.email}` :
              ""
            }
          />
        )}
      />
      <Box mt={2}></Box>
    </div>
  );
};
