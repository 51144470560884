import { gql } from "@apollo/client";

export const CREATE_ASSET_SERVICE = gql`
  mutation createAssetService($assetService: AssetServiceInput!) {
    createAssetService(assetService: $assetService) {
      id
      serviceType
      serviceProviderName
      serviceProviderAddress{
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      description
      totalExpense
      status
      asset{
        id
        assetTag
      }
      appointmentDate
      completionDate
    }
  }
`;

export const UPDATE_ASSET_SERVICE = gql`
  mutation updateAssetService($assetServiceId: String!, $assetService: AssetServiceInput!) {
    updateAssetService(assetServiceId: $assetServiceId, assetService: $assetService) {
      id
      serviceType
      serviceProviderName
      serviceProviderAddress{
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      damages{
        id
        referenceNumber
        circumstance
        incidentDate
        status
      }
      description
      totalExpense
      status
      asset{
        id
        assetTag
      }
      appointmentDate
      completionDate
    }
  }
`;

export const UPDATE_ASSET_SERVICE_STATUS = gql`
  mutation updateAssetServiceStatus($assetServiceId: String!, $updateAssetServiceStatus: UpdateAssetServiceStatusInput!) {
    updateAssetServiceStatus(assetServiceId: $assetServiceId, updateAssetServiceStatus: $updateAssetServiceStatus) {
      id
      serviceType
      serviceProviderName
      serviceProviderAddress{
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      damages{
        id
        referenceNumber
        circumstance
        incidentDate
        status
      }
      description
      totalExpense
      status
      asset{
        id
        assetTag
      }
      appointmentDate
      completionDate
    }
  }
`;