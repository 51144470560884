import { gql } from "@apollo/client";

export const GENERATE_INVOICES_CSV = gql`
  query generateInvoicesCsv($fromDate: String!, $toDate: String!, $isCustom: Boolean!, $templateId: String) {
    generateInvoicesCsv(fromDate: $fromDate, toDate: $toDate, isCustom: $isCustom, templateId: $templateId) {
      message
      key
    }
  }
`;
