import { gql } from "@apollo/client";

export const GET_ALL_EMAIL_TEMPLATES = gql`
  query getAllEmailTemplates {
    getAllEmailTemplates {
      templateType
      bodyHtmlString
      designJsonString
      subject
      isDefault
      variables
        {
        name
        value
        key
      }
      updatedAt
      updatedBy 
      {
        firstName
        lastName
      }
    }
  }`