export const SettingsMap: any = {
    "Customer Details": "customerDetails",
    "Extension Details": "bookingExtension",
    "Current Vehicle Details": "vehicleDetails",
    "Add-on Details": "addonDetails",
    "Insurance Details": "insuranceDetails",
    "Payment Information": "paymentInfo",
    "Previous Vehicle Details": "oldVehicleDetails",
    "Subscription Details": "subscriptionDetails",
    "Driver Details":"driverDetails"
  };
  
  export const RENTAL_COMPANY_DETAILS_LIST = [
    "logoUrl",
    "name",
    "address",
    "phoneNumber",
    "emailId",
    "orgVatNumber",
    "orgRegNumber"
  ];
  
  export const CUSTOMER_INFO_LIST = [
    "name",
    "phoneNumber",
    "dateOfBirth",
    "email",
    "businessName",
    "address",
    "licenseExpiry",
    "insuranceNumber",
    "licenseNumber",
  ];
  
  export const SUBSCRIPTION_DETAILS = [
    "subscription",
    "pickup",
    "subscriptionDate",
    "dropoff",
    "poNumber",
    "subscriptionPrice",
    "duration"
  ]
  
  export const SUBSCRIPTION_EXTENSION_DETAILS_LIST = [
    "extensionNumber",
    "invoiceRef",
    "referenceNumber",
    "dropOffLocation",
    "extensionPeriod",
  ];

  export const SUBSCRIPTION_VEHICLE_DETAILS_LIST = [
    "makeModel",
    "color",
    "pcoNumber",
    "numberOfDoors",
    "registrationNumber",
    "year",
    "numberOfSeats",
    "vin",
    "mileageLimit",
    "transmission",
  ];
  
  export const SUBSCRIPTION_OLD_VEHICLE_DETAILS = [
    "makeModel",
    "color",
    "pcoNumber",
    "numberOfDoors",
    "registrationNumber",
    "year",
    "numberOfSeats",
    "vin",
    "mileageLimit",
    "transmission",
  ];

  export const SUBSCRIPTION_DRIVER_DETAILS_LIST = [
    "name",
    "dateOfBirth",
    "phoneNumber",
    "address",
    "email",
    "licenseNumber",
    "licenseExpiry",
    "licenseIssuedBy",
    "customerPCONumber"
  ];
  
  export const SUBSCRIPTION_PAYMENT_INFO_LIST = [
    "addonAmount",
    "taxAmount",
    "totalPayableAmount",
    "amountPaid",
    "amountDue",
    "discountAmount",
    "securityDeposit",
    "insuranceAmount"
  ];
  
  export const ADDON_DETAILS_LIST = ["addonName", "description", "quantity"];
  
  export const INSURANCE_DETAILS_LIST = ["insuranceName", "description", "price"];
  
  export const SubscriptionAgreementSettingsMap: any = {
    customerDetails: {
      name: "Name",
      phoneNumber: "Phone",
      dateOfBirth: "DOB",
      email: "Email",
      businessName: "Business Name",
      address: "Address",
      licenseExpiry: "Driving Licence Expiry Date",
      insuranceNumber: "National Insurance No.",
      licenseNumber: "Driving Licence No",
    },
    subscriptionDetails: {
      subscription: "Subscription",
      pickup: "Pickup details",
      subscriptionDate: "Subscription Date",
      dropoff: "Drop-off details",
      poNumber: "PO Number",
      subscriptionPrice: "Subscription Price",
      duration: "Duration"
    },
    bookingExtension: {
      extensionNumber: "Extension No",
      invoiceRef: "Invoice No",
      referenceNumber: "ID",
      dropOffLocation: "New drop-off location",
      extensionPeriod: "Extension Period",
    },
    vehicleDetails: {
      makeModel: "Make/ Model",
      color: "Color",
      pcoNumber: "PCO",
      numberOfDoors: "Number of Doors",
      registrationNumber: "Reg No.",
      year: "Year",
      numberOfSeats: "Number of seats",
      vin: "VIN No.",
      mileageLimit: "Mileage Limit",
      transmission: "Transmission Type",
    },
    oldVehicleDetails: {
      makeModel: "Make/ Model",
      color: "Color",
      pcoNumber: "PCO",
      numberOfDoors: "Number of Doors",
      registrationNumber: "Reg No.",
      year: "Year",
      numberOfSeats: "Number of seats",
      vin: "VIN No.",
      mileageLimit: "Mileage Limit",
      transmission: "Transmission Type",
    },
    insuranceDetails: {
      insuranceName: "Insurance Name",
      description: "Description",
      price: "Price"
    },
    paymentInfo: {
      addonAmount: "Add-on price",
      taxAmount: "VAT/TAX",
      totalPayableAmount: "Grand total",
      amountPaid: "Amount paid",
      amountDue: "Amount due",
      discountAmount: "Discount",
      securityDeposit: "Security Deposit",
      insuranceAmount: "Insurance Price"
    },
    addonDetails: {
      addonName: "Add-on Name",
      description: "Description",
      quantity: "Quantity"
    },
    driverDetails: {
      name: "Name",
      dateOfBirth: "DOB",
      phoneNumber: "Phone",
      address: "Address",
      email: "Email",
      licenseNumber: "Driving Licence No",
      licenseExpiry: "Driving Licence Expiry Date",
      licenseIssuedBy: "Driving Licence Issued by",
      customerPCONumber: "PCO Private Hires no."
    },
  };