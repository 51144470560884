import React, { useState } from 'react';
import { IBooking } from '../../../../reducers/bookings/types';
import VehicleReplacementForConfirmedBooking from '../../Fleet/VehicleScheduler/SchedulerModels/VehicleReplacementForConfirmedBooking';
import { Autocomplete, Dialog, Divider, Grid, IconButton, TextField, Theme, Typography } from '@mui/material';
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { WithStyles, createStyles, withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
interface IProps {
  booking: IBooking;
  vehicleGroups: any[];
  vehicles: any[];
  open: boolean;
  handleClose(): void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h2">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const VehicleReplacementDialog: React.FC<IProps> = ({ booking, vehicleGroups, vehicles, open, handleClose }) => {
  const [selectedVehicle, setSelectedVehicle] = useState<any | null>(null);
  const [bookingScheduleData, setBookingScheduleData] = useState<any | null>(null)


  const handleDialogClose = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title" >
        <DialogTitle id="form-dialog-title" onClose={handleDialogClose}>
          Vehicle Replacement
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item container xs={12} alignItems='center'>
              <Grid item xs={6}>
                <Typography variant="body1">Select vehicle to replace:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  options={vehicles}
                  getOptionLabel={(vehicle) => vehicle.licencePlate}
                  value={selectedVehicle}
                  onChange={(event, newValue) => {
                    setBookingScheduleData(null)
                    setSelectedVehicle(null)
                    const selectedVehicleId = newValue?.id;
                    const selectedVehicleGroup: any = booking.vehicleGroups.find(vehicleGroup =>
                      vehicleGroup.vehicleIds.includes(selectedVehicleId)
                    );

                    if (selectedVehicleGroup) {
                      const selectedSchedule = selectedVehicleGroup?.activeVehicleSchedules.find(
                        (schedule: any) => schedule.vehicle.id === selectedVehicleId
                      );

                      if (selectedSchedule) {
                        const scheduleData = {
                          id: selectedSchedule.id,
                          booking: booking,
                          vehicle: selectedSchedule.vehicle,
                          vehicleStatus: selectedSchedule.vehicleStatus,
                          startDate: booking.pickupDateTime,
                          endDate: booking.dropoffDateTime,
                          vehicleGroup: selectedVehicleGroup?.vehicleGroup?.id
                        };
                        setBookingScheduleData(scheduleData);
                      }
                    }
                    setSelectedVehicle(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Licence Plate"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {selectedVehicle && bookingScheduleData &&
                <VehicleReplacementForConfirmedBooking
                  scheduleDetails={bookingScheduleData}
                  booking={booking}
                  handleClose={handleDialogClose}
                />
              }
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default VehicleReplacementDialog;
