import axios from "axios";

import HttpHeader from "../../../../header";

const httpHeader = HttpHeader.getInstance();

const instance = axios.create({
  baseURL: process.env.REACT_APP_REPORTS_API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

instance.interceptors.request.use(async function(config: any) {
  config.headers = {
    ...config.headers,
    ...httpHeader.headers,
    authorization: `Bearer ${httpHeader.headers.authorization}`
  };
  return config;
});

export default {
  getTelematicsData: (data: object) =>
    instance.post(`/query/telematics/NoMapData`, data),
  getVehicleGroupUtilDataYearly: (data: object) =>
    instance.post(`query/utilisation/vehicleGroup/yearly`, data),
  getVehicleGroupUtilDataAllTime: (data: object) =>
    instance.post(`query/utilisation/vehicleGroup/allTime`, data),
  getBookingHoursYearly: (data: object) =>
    instance.post(`query/BookingDaysHours/fleet/yearly`, data),
  getBookingHoursAll: (data: object) =>
    instance.post(`query/BookingDaysHours/fleet/alltime`, data),
  getFinanceDataAll: (data: object) =>
    instance.post(`query/financeInformation/fleet/allTime`, data),
  getFinanceDataVehicle: (data: object) =>
    instance.post(`query/financeInformation`, data),
  getFinanceDataYearly: (data: object) =>
    instance.post(`query/financeInformation/fleet/yearly`, data),
  getVehicles: (data: object) => instance.post(`query/vehicles`, data),
  getCancellationsAll: (data: object) =>
    instance.post(`query/bookings/cancellations`, data),
  getCancellationsMonthly: (data: object) =>
    instance.post(`query/bookings/cancellations/monthly`, data),
  getVehicleInfo: (data: object) => instance.post(`query/telematics`, data),
  getUtilisation: (data: object) => instance.post(`query/booking`, data),
  getVehicleHealthData: (data: object) =>
    instance.post(`/query/vehicleHealth`, data),
  getTrackingData: (data: object) => {
    return instance.post(`/query/telematics`, data);
  },
  getTelematicsMapData: (data: object) => {
    return instance.post(`/fleetDashboard/telematics`, data);
  },
  createReportRequest: (data: object) => {
    return instance.post(`/report/request`, data);
  },
  getReportRequests: (params: object) => {
    return instance.get(`/report/request`, { params });
  },
  getReportRequest: (requestId: string) => {
    return instance.get(`/report/request/${requestId}`);
  }
};
