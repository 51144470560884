import { gql } from "@apollo/client";

export const GET_PROCUREMENTS = gql`
  {
    procurementRequests {
      id
      referenceNumber
      createdBy {
        firstName
        lastName
      }
      createdAt
      updatedAt
      items {
        quantity
      }
      status
    }
  }
`;
