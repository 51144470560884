import { gql } from "@apollo/client";

export const GET_INSURANCE_POLICIES_FOR_SUBSCRIPTION = gql`
  query insurancePolicies($organisationId: String!, $fetchActiveOnly: Boolean) {
    insurancePolicies(
      organisationId: $organisationId
      fetchActiveOnly: $fetchActiveOnly
    ) {
      id
      name
      isActivated
    }
  }
`;
