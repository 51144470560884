
import React, { useEffect, useState } from "react";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../../store";
import { IBusinessCustomer, ICustomer } from "../../../../../../reducers/customer/types";
import dayjs from "dayjs";
import { GET_BUSINESS_CREDIT_SCORE } from "../../../../../../graphql/customers/getBusinessCreditscoreMutation";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../../../common/utils";
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import { CircularProgress, Dialog, DialogContent, Fab, Grid, IconButton, Theme, Typography } from "@mui/material";
import { useMutation } from "@apollo/client/react/hooks";
import { ApolloError } from "@apollo/client";

interface IDialogProps {
  open: boolean,
  handleClose: () => void;
  businessCustomer: IBusinessCustomer;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const BusinessCreditCheckDialog: React.FC<IDialogProps> = (props) => {
  const { open, handleClose, businessCustomer } = props;
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [creditScore, setCreditScore] = useState<number>(businessCustomer.businessCreditData?.creditScore || 0);
  const [creditDescription, setCreditDescription] = useState<string>(businessCustomer.businessCreditData?.creditDescription || "");
  const [lastChecked, setLastChecked] = useState<string>(businessCustomer.businessCreditData?.lastChecked || "");
  const snackbar = useSnackBar();
  const { country } = userState.currentOrganisation.address;

  const [getCreditScoreForBusinessCustomer, { loading: scoreLoading }] = useMutation(GET_BUSINESS_CREDIT_SCORE, {
    onCompleted: (data) => {
      if (!data.getBusinessCreditScore.businessCreditData || !data.getBusinessCreditScore.businessCreditData.creditScore) {
        snackbar({
          message: 'No valid credit score found.',
          variant: SnackBarVariant.ERROR
        })
      } else {
        setCreditScore(data.getBusinessCreditScore.businessCreditData.creditScore)
        setCreditDescription(data.getBusinessCreditScore.businessCreditData.creditDescription)
      }
      setLastChecked(data.getBusinessCreditScore.businessCreditData.lastChecked)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: 'No valid credit score found for this business customer.',
        variant: SnackBarVariant.ERROR
      })
  });

  const getCreditScore = () => {
    getCreditScoreForBusinessCustomer({
      variables: {
        businessCustomerId: businessCustomer.id,
      }
    })
  }

  useEffect(() => {
    setCreditScore(businessCustomer.businessCreditData?.creditScore || 0)
    setLastChecked(businessCustomer.businessCreditData?.lastChecked || "")
  }, [businessCustomer])

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      className={"select-holidays-dialog"}
    >
      <React.Fragment>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p style={{ fontSize: 18, color: "rgba(0, 0, 0, 0.54)", padding: 0, margin: 0 }}>
            BUSINESS CREDIT SCORE
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container xs={12} spacing={1}>
            <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
              <Typography variant="h4">Credit Score:  {scoreLoading ? <CircularProgress size={12} /> : (creditScore ? creditScore : "N/A")} </Typography>
            </Grid>
            <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
              <Typography variant="h4">Credit Description:  {scoreLoading ? <CircularProgress size={12} /> : (creditDescription ? creditDescription : "N/A")} </Typography>
            </Grid>
            <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
              <Typography variant="h4"> Last Checked: {scoreLoading ? <CircularProgress size={12} /> :
                (
                  lastChecked ?
                    getLocalizedDateFormat(
                      country,
                      lastChecked,
                      DATE_TYPE.EXPANDED
                    ) : "N/A"
                )
              }</Typography>
            </Grid>

            <Fab
              variant="extended"
              size="medium"
              style={{ color: "white", position: "relative", left: "25%", marginTop: "10px" }}
              onClick={() => {
                getCreditScore()
              }}
              disabled={scoreLoading}
            >
              {scoreLoading && (
                <CircularProgress
                  size={14}
                  style={{ color: "white" }}
                />
              )}
              RE-CHECK CREDIT SCORE
            </Fab>
          </Grid>
        </DialogContent>
      </React.Fragment>
    </Dialog >
  )
}




