import { NavBarAction, NavBarActionType } from "./../actions/navbar.actions";

export interface INavbarState {
  navbarItem: null | string;
  isNavbarOpen: boolean;
  listExpanded: string;
}

const INITIAL_STATE: INavbarState = {
  isNavbarOpen: true,
  listExpanded: '',
  navbarItem: null
};

const navbarReducer = (
  state: INavbarState = INITIAL_STATE,
  action: NavBarAction
): INavbarState => {
  switch (action.type) {
    case NavBarActionType.GET_NAVBAR_ITEM:
      return {
        ...state,
        navbarItem: action.payload
      };
    case NavBarActionType.IS_NAVBAR_OPEN:
      return {
        ...state,
        isNavbarOpen: action.payload
      };
    case NavBarActionType.UPDATE_LIST_EXPANDED:
      return {
        ...state,
        listExpanded: action.payload
      };
    default:
      return state;
  }
};
export default navbarReducer;
