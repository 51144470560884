import { gql } from "@apollo/client";

export const ADD_PROCUREMENT_DELIVERY_INFO = gql`
  mutation addProcurementDeliveryInfo($id: ID!, $deliveryInfo: ProcurementDeliveryInformationInput!) {
    addProcurementDeliveryInfo(id: $id, deliveryInfo: $deliveryInfo) {
      id
      deliveryNoteId
      deliveryNoteDate
      partnerId
      purchaseOrderId
      productReceivedDate
      receivedProductNumber
      documents
    }
  }
`;