import { AppBar, Paper, Tab, Tabs, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BuildIcon from "@mui/icons-material/Build";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import MoneyIcon from "@mui/icons-material/MoneyRounded";
import ReportIcon from "@mui/icons-material/Report";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ExpensesIcon from "@mui/icons-material/PaymentTwoTone";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { IAsset } from "../../asset.types";
import { IAppState } from "../../../../../store";
import { TabPanel } from "../../../../common/TabPannel/TabPannel";
import { ViewAssetDetails } from "./ViewAssetDetails";
import { ViewAssetServices } from "./ViewAssetServices";
import { ViewAssetDamages } from "./ViewAssetDamages";
import { ViewAssetDocuments } from "./ViewAssetDocuments";
import { NewAsset } from "../NewAsset";
import InventoryIcon from '@mui/icons-material/Inventory';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 120,
      },
      flexGrow: 1
    },
    paper: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 120,
      },
      [theme.breakpoints.down('md')]: {
        width: "calc(100vw - 45px)",
      },
      [theme.breakpoints.up('sm')]: {
        width: "calc(100vw - 245px)",
      },

    },
  })
);
interface IProps {
  asset: IAsset | undefined
}

let isCountryUsa = false;

export const AssetTabView: FC<IProps> = ({ asset }) => {
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [value, setValue] = useState<number>(0);

  if (country === "United States") {
    isCountryUsa = true;
  }
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };
  return (
    <Paper className={classes.paper}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons
          aria-label="Scrollable Vehicle Info Tabs"
        >
          <Tab label="Details" icon={<InventoryIcon />} />
          <Tab label="Services" icon={<ReportProblemIcon />} />
          <Tab label="Damages" icon={<ReportIcon />} />
          <Tab label="Documents" icon={<AttachFileIcon />} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <NewAsset asset={asset} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <ViewAssetServices asset={asset} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <ViewAssetDamages asset={asset} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ViewAssetDocuments asset={asset} />
      </TabPanel>
    </Paper>
  );
}