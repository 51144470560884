import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from 'react'
import { BOOKING_TYPE, ICustomerSetting, IOrganisation } from '../../../../../reducers/organisation/types';
import Skeleton from '@mui/material/Skeleton';
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { getLocalizedBookingSyntex, getLocalizedInsuranceNumberSyntex, getLocalizedRASyntex, getLocalizedTaxSyntex } from '../../../../../utils/localized.syntex';
import { NuvvenDivider } from '../../../ReservationManagement/Summary/Components/Divider';
import { SettingsMap, SubscriptionAgreementSettingsMap } from './utils';
import { captureErrorException } from '../../../../../utils/sentry';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "& .MuiTableCell-body": {
        border: "0.5px solid lightgray"
      }
    },
    commonPadding: {
      padding: "10px 15px"
    },
    paper: {
      padding: 15
    },
    table: {
      minWidth: 659
    },
    orgSection: {
      border: "1px solid lightgray",
      padding: 8
    },
    alignTitleCenter: {
      textAlign: "center"
    },
    contentPaper: {
      flexGrow: 1,
      paddingLeft: 20,
      paddingRight: 20
    },
    listContent: {
      display: "flex",
      justifyContent: "space-between"
    },
    skeltonStyle: {
      marginLeft: 10,
      width: "15%"
    },
    listItem: {
      paddingLeft: 0,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5
    },
    itemTitleFontWeight: {
      fontWeight: 600,
      wordWrap: "break-word",
      wordBreak: "break-word"
    }
  })
);

interface IProps {
  open: boolean;
  onClose: () => void;
  customerType: string;
  selectedBranch?: ICustomerSetting;
  organisation?: IOrganisation;
  customSettings?: any;
}

const PreviewSubscriptionAgreement: React.FC<IProps> = (props) => {

  const classes = useStyles();
  const { open, onClose, organisation } = props;
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [logoUrl, setLogoUrl] = useState<any>()
  const displayListItem = (listItem: string, itemName: string) => {
    const selectedItems = SubscriptionAgreementSettingsMap[SettingsMap[itemName]];
    if (props.customSettings && props.customSettings[SettingsMap[itemName]]) {
      return props.customSettings[SettingsMap[itemName]][listItem];
    } else if (selectedItems[listItem] === "VAT/TAX") {
      return getLocalizedTaxSyntex(country);
    } else if (selectedItems[listItem] === "National Insurance No.") {
      return getLocalizedInsuranceNumberSyntex(country)
    } else {
      return selectedItems[listItem];
    }
  }

  useEffect(() => {
    if (props.organisation && props.organisation.logoUrl) {
      const logoUrl = getOrgLogoImage(props.organisation.logoUrl)
      setLogoUrl(logoUrl)
    }
  }, [props.organisation])

  const getOrgLogoImage = async (url: string) => {
    if (url) {
      try {
        const file = await getSignedUrl(url)
        setLogoUrl(file)
      } catch (error) {
        captureErrorException(error)
      }
    }
  }

  const renderPreview = (listDetails: string[], listItemType: string,) => {
    let title = ""
    const detailTitles = Object.keys(SettingsMap)
    const titleIndex = detailTitles.findIndex(item => item === listItemType)
    if (titleIndex > -1) {
      title = detailTitles[titleIndex]
    }

    return (
      <Grid item xs={12}>
        <Box mt={1}></Box>
        <Typography className={classes.itemTitleFontWeight} variant='h3'>{title}</Typography>
        <List className={classes.root}>
          <Grid container>
            {
              listDetails.map(listItem => {
                return (
                  <Grid item xs={4}>
                    <ListItem key={listItem} className={classes.listItem} alignItems="flex-start">
                      <ListItemText
                        primary={
                          <Typography variant={"h4"} className={classes.listContent}>
                            {displayListItem(listItem, listItemType)} :
                            <Skeleton variant="text" className={classes.skeltonStyle} animation={false} />
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Grid>
                )
              })
            }
          </Grid>
        </List>
      </Grid>
    )
  }

  return (
    <Dialog
      fullWidth
      className={`${classes.root} rental-agreement-setting`}
      onClose={onClose} aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth={"lg"}
    >
      <DialogTitle className={classes.commonPadding} id="rental-agreement-preview">
        <Typography className={classes.alignTitleCenter} variant={"h3"}>{`Preview ${getLocalizedRASyntex(country, BOOKING_TYPE.SUBSCRIPTION)} Format`}</Typography>
      </DialogTitle>
      <DialogContent className={classes.commonPadding}>
        {
          props.selectedBranch && (
            <Grid container item spacing={2}>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={2}>
                  {
                    logoUrl ? (
                      <img src={logoUrl} style={{ height: "auto", width: "60px" }} alt="org-log"></img>
                    ) :
                      <CircularProgress size="small" />
                  }
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h4">{organisation?.name}</Typography>
                  <Typography variant="body1">{organisation?.address.fullAddress}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1">{`${country === "United States" ? "Tax No" : "Vat No"}`}: {organisation?.orgVatNumber}</Typography>
                  <Typography variant="body1">Co Reg. No: {organisation?.orgRegNumber}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1">Tel. No: {organisation?.phoneNumber.phone}</Typography>
                  <Typography variant="body1">Email: {organisation?.emailId}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}><NuvvenDivider noMargin /></Grid>
              <Grid item xs={12}>
                <Paper className={classes.contentPaper} elevation={0}>
                  <>
                    {
                      props.selectedBranch.customerDetails?.length > 0 && (
                        <>
                          {renderPreview(props.selectedBranch.customerDetails, "Customer Details")}
                        </>
                      )
                    }
                    {
                      props.selectedBranch.subscriptionDetails?.length > 0 && (
                        <>
                          {renderPreview(props.selectedBranch.subscriptionDetails, "Subscription Details")}
                        </>
                      )
                    }
                    {
                      props.selectedBranch.vehicleDetails?.length > 0 && (
                        <>
                          {renderPreview(props.selectedBranch.vehicleDetails, "Current Vehicle Details")}
                        </>
                      )
                    }
                    {
                      props.selectedBranch.oldVehicleDetails?.length > 0 && (
                        <>
                          {renderPreview(props.selectedBranch.oldVehicleDetails, "Previous Vehicle Details")}
                        </>
                      )
                    }
                    {
                      props.selectedBranch.bookingExtension?.length > 0 && (
                        <>
                          {renderPreview(props.selectedBranch.bookingExtension, "Extension Details")}
                        </>
                      )
                    }
                    {
                      props.selectedBranch.addonDetails?.length > 0 && (
                        <>
                          {renderPreview(props.selectedBranch.addonDetails, "Add-on Details")}
                        </>
                      )
                    }
                    {
                      props.selectedBranch.insuranceDetails?.length > 0 && (
                        <>
                          {renderPreview(props.selectedBranch.insuranceDetails, "Insurance Details")}
                        </>
                      )
                    }
                    {
                      props.selectedBranch.driverDetails?.length > 0 && (
                        <>
                          {renderPreview(props.selectedBranch.driverDetails, "Driver Details")}
                        </>
                      )
                    }
                    {
                      props.selectedBranch.paymentInfo?.length > 0 && (
                        <>
                          {renderPreview(props.selectedBranch.paymentInfo, "Payment Information")}
                        </>
                      )
                    }
                  </>
                </Paper>
              </Grid>
              {
                props.selectedBranch.securityDeposit && (
                  <Grid item xs={12}>
                    <Paper className={classes.contentPaper} elevation={0}>
                      <Grid container alignItems='baseline'>
                        <Typography className={classes.itemTitleFontWeight} variant={"h3"}>Security Deposit:</Typography>
                        <Typography variant="body1" style={{ marginLeft: 10 }}>
                          This booking contains refundable security deposit which is payable at the time of vehicle collection.
                        </Typography>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              }
              {
                props.selectedBranch.tnc && (
                  <Grid item xs={12}>
                    <Paper className={classes.contentPaper} elevation={0}>
                      <Grid container alignItems='baseline'>
                        <Typography className={classes.itemTitleFontWeight} variant={"h3"}>Key Terms & Conditions:
                          <Typography component={"span"} variant="body1" style={{ marginLeft: 10 }}>
                            {props.selectedBranch.tnc}
                          </Typography>
                        </Typography>
                        <Grid item xs={12}><Box mt={2}></Box></Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography className={`${classes.itemTitleFontWeight} ${classes.listContent}`} variant={"h3"}>Customer Signature: <Skeleton variant="text" className={classes.skeltonStyle} animation={false} /></Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={`${classes.itemTitleFontWeight} ${classes.listContent}`} variant={"h3"}>Date & Time: <Skeleton variant="text" className={classes.skeltonStyle} animation={false} /></Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              }
              {
                props.selectedBranch.insuranceDeclaration && (
                  <Grid item xs={12}>
                    <Paper className={classes.contentPaper} elevation={0}>
                      <Grid container alignItems='baseline'>
                        <Typography className={classes.itemTitleFontWeight} variant={"h3"}>Insurance Declaration:
                          <Typography component={"span"} variant="body1" style={{ marginLeft: 10 }}>
                            {props.selectedBranch.insuranceDeclaration}
                          </Typography>
                        </Typography>
                        <Grid item xs={12}><Box mt={2}></Box></Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography className={`${classes.itemTitleFontWeight} ${classes.listContent}`} variant={"h3"}>Customer Signature: <Skeleton variant="text" className={classes.skeltonStyle} animation={false} /></Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={`${classes.itemTitleFontWeight} ${classes.listContent}`} variant={"h3"}>Date & Time: <Skeleton variant="text" className={classes.skeltonStyle} animation={false} /></Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              }
              {
                props.selectedBranch.cancellationPolicy && (
                  <Grid item xs={12}>
                    <Paper className={classes.contentPaper} elevation={0}>
                      <Grid container alignItems='baseline'>
                        <Typography className={classes.itemTitleFontWeight} variant={"h3"}>Cancellation Policy:
                          <Typography component={"span"} variant="body1" style={{ marginLeft: 10 }}>
                            {props.selectedBranch.cancellationPolicy}
                          </Typography>
                        </Typography>
                        <Grid item xs={12}><Box mt={2}></Box></Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography className={`${classes.itemTitleFontWeight} ${classes.listContent}`} variant={"h3"}>Customer Signature: <Skeleton variant="text" className={classes.skeltonStyle} animation={false} /></Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={`${classes.itemTitleFontWeight} ${classes.listContent}`} variant={"h3"}>Date & Time: <Skeleton variant="text" className={classes.skeltonStyle} animation={false} /></Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              }
              {
                props.selectedBranch.preBookingQuestions && (
                  <Grid item xs={12}>
                    <Paper className={classes.contentPaper} elevation={0}>
                      <Grid container alignItems='baseline'>
                        <Typography className={classes.itemTitleFontWeight} variant={"h3"}>{`${getLocalizedBookingSyntex(country)} Questions`}:</Typography>
                        <Grid item xs={12}>
                          <Typography><Skeleton variant="text" height={40} animation={false} style={{ marginBottom: 10 }} /></Typography>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography className={`${classes.itemTitleFontWeight} ${classes.listContent}`} variant={"h3"}>Customer Signature: <Skeleton variant="text" className={classes.skeltonStyle} animation={false} /></Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={`${classes.itemTitleFontWeight} ${classes.listContent}`} variant={"h3"}>Date & Time: <Skeleton variant="text" className={classes.skeltonStyle} animation={false} /></Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              }
              {
                props.selectedBranch.additionalDriverQuestions && (
                  <Grid item xs={12}>
                    <Paper className={classes.contentPaper} elevation={0}>
                      <Grid container alignItems='baseline'>
                        <Typography className={classes.itemTitleFontWeight} variant={"h3"}>{'Additional Driver Questions'}</Typography>
                        <Grid item xs={12}><Box mt={2}></Box></Grid>
                        <Typography className={classes.itemTitleFontWeight} variant={"h3"}>{'Additional Driver Signature'}:</Typography>
                        <Grid item xs={12}>
                          <Typography><Skeleton variant="text" height={40} animation={false} style={{ marginBottom: 10 }} /></Typography>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography className={`${classes.itemTitleFontWeight} ${classes.listContent}`} variant={"h3"}>Customer Signature: <Skeleton variant="text" className={classes.skeltonStyle} animation={false} /></Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={`${classes.itemTitleFontWeight} ${classes.listContent}`} variant={"h3"}>Date & Time: <Skeleton variant="text" className={classes.skeltonStyle} animation={false} /></Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              }
              {
                props.selectedBranch.deliveryQuestions && (
                  <Grid item xs={12}>
                    <Paper className={classes.contentPaper} elevation={0}>
                      <Typography component={"span"} className={classes.itemTitleFontWeight} variant={"h3"}>Vehicle Handover Questions:</Typography>
                      <Typography><Skeleton variant="text" height={40} animation={false} /></Typography>
                    </Paper>
                  </Grid>
                )
              }
              {
                props.selectedBranch.additionalInfo && (
                  <Grid item xs={12}>
                    <Paper className={classes.contentPaper} elevation={0}>
                      <Typography className={classes.itemTitleFontWeight} variant={"h3"}>Additional Customer Information:</Typography>
                      <Typography><Skeleton variant="text" height={40} animation={false} /></Typography>
                    </Paper>
                  </Grid>
                )
              }
              {
                props.selectedBranch.additonalTnc && (
                  <Grid item xs={12}>
                    <Paper className={classes.contentPaper} elevation={0}>
                      <Typography className={classes.itemTitleFontWeight} variant={"h3"}>Additional T&C:</Typography>
                      <Typography><Skeleton variant="text" height={40} animation={false} /></Typography>
                    </Paper>
                  </Grid>
                )
              }
              <Grid item xs={12}><Box mt={1}></Box></Grid>
            </Grid>
          )
        }
      </DialogContent>
    </Dialog>
  )
}

export default PreviewSubscriptionAgreement;