import { useMutation } from "@apollo/client";
import {
  Grid,
  Paper,
  Theme,
  Typography,
  Fab,
  Switch
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { ADD_TRANSFER360_RESPONSIBLE_PARTY } from "../../../../../graphql/fleet/addTransfer360PartyMutation";
import { IAppState } from "../../../../../store";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      marginTop: "50px"
    },
    form: {
      marginTop: "20px"
    },
    field: {
      marginBottom: "20px"
    },
    header: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3),
      width: '100%',
      height: '100%'
    }
  })
);

export const Integrations = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);


  const [addResponsibleParty] = useMutation(ADD_TRANSFER360_RESPONSIBLE_PARTY, {
    onCompleted: (data: any) => {
      snackbar({
        message: "Account successfully integrated with Transfer360",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  return (
    <Grid container spacing={2} style={{marginLeft: "0.5rem"}}>
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          Transfer360 Configuration
        </Typography>
      </Grid>
      <Grid container item xs={12} style={{width: '100%'}}>
        <Paper className={classes.root}>
          {userState.currentTenancy.transfer360Integrated ? 
            <Typography>
              Transfer360 already integrated!
            </Typography> : 
            <Fab
              variant="extended"
              size="medium"
              aria-label="add"
              onClick={() => setIsOpen(true)}
              >
                Integrate Transfer360
          </Fab>
          }
          
        </Paper>
      </Grid>
      {isOpen && (
        <ConfirmationDialog
          isOpen={isOpen}
          onCancel={() => setIsOpen(false)}
          onConfirm={() => {
            addResponsibleParty()
          }}
          title=""
          description={"Are you sure, you want to integrate Transfer360 for this account?. This would share information of all your active fleet with Transfer360"}
        />
      )}
    </Grid>
  );
};
