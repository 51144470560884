import { gql } from "@apollo/client";

export const UPDATE_INSURANCE_POLICY = gql`
  mutation updateInsurancePolicy(
    $insurancePolicy: InsurancePolicyUpdateInput!
  ) {
    updateInsurancePolicy(insurancePolicy: $insurancePolicy) {
      id
      name
      description
      excess
      documents{
        title
        documentType
        documentUrl
        documentName
        expiryDate
        createdAt
		  }
      insuranceRate {
        rateName
        rateDuration
        rate
        longTerm
      }
      organisationId
      isActivated
    }
  }
`;
