import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import UpcomingEvents, { IUpcomingEvents, IReminder } from './UpcomingEvents';
import DailyBalanceCard, { IDailyBalance } from './DailyBalanceCard';
import PickupReturnCard, { IPickupReturnData } from './PickupReturnCard';
import VehicleAvailablityCard, { IVehicleAvailabilityData } from './VehicleAvailabilityCard';
import { GET_DASHBOARD_DATA } from '../../../graphql/dashboard/getDashboardData';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import BookingsByDateCard from './BookingsByDateCard';
import { Typography, IconButton, CircularProgress, Theme, Hidden, Grid, SwipeableDrawer } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import EventIcon from '@mui/icons-material/Event';
import { createStyles, makeStyles } from "@mui/styles";
import VirtualVehicleBookingsByDateCard from './VirtaulVehicleBookingsByDateCard';
import ExternalVehicleBookingCard, { IExternalVehicleBooking } from './ExternalVehicleBookingCard';

interface IDashboardData {
  vehiclePickUpReturnData: IPickupReturnData;
  vehicleAvailabilityData: IVehicleAvailabilityData;
  upcomingEvents: IUpcomingEvents;
  bookingsByDates: number[][];
  complianceReminders: IReminder[];
  dailyBalance: IDailyBalance;
  externalVehicleBookings: IExternalVehicleBooking[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      position: "fixed",
      [theme.breakpoints.up('md')]: {
        left: 238,
      },
      [theme.breakpoints.down('md')]: {
        left: 70,
      },
      [theme.breakpoints.down('sm')]: {
        left: 60,
      },
      top: 5,
      zIndex: 1299,
      display: "flex",
      alignItems: "center"
    },
    drawer: {
      zIndex: 1300,
    },
    drawerPaper: {
      zIndex: 1300,
      maxWidth: '90vw',
      height: "100%",
    },
    event_button: {
      position: "fixed",
      left: 'auto',
      right: 128,
      top: 0,
      zIndex: 1299,
      display: "flex",
      alignItems: "center"
    },
    popoverMenu: {
      minWidth: 600,
      width: "50%",
      "& .MuiPopover-paper": {
        width: "35%",
        padding: theme.spacing(2),
        marginRight: 10,
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
      }
    },
  })
);

const Dashboard = () => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [data, setData] = useState<IDashboardData>()
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const container = window !== undefined ? () => document.body : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [
    getDashboardData,
    { loading }
  ] = useLazyQuery(GET_DASHBOARD_DATA, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      setData(data.dashboardData)
    },
  });

  useEffect(() => {

    setData(undefined);
    getDashboardData();

  }, []);

  const refresh = () => {
    setData(undefined);
    getDashboardData();
  }

  const title = () => {
    return <>
      <Hidden smDown>
        <Typography variant="h2" className="bold">Dashboard</Typography>
      </Hidden>
      <Hidden smUp>
        <Typography variant="h3" className="bold">Dashboard</Typography>
      </Hidden>
      <IconButton aria-label="settings" onClick={() => refresh()} size="large">
        {loading ? <CircularProgress style={{ height: 20, width: 20 }} /> : (<RefreshIcon />)}
      </IconButton>
    </>;
  }

  const iOS = (typeof window !== 'undefined') && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <div className={styles.container}>
      <div className={classes.title}>
        {title()}
      </div>
      {data &&
        <>
          <Hidden mdDown>
            <div className={styles.leftColumn}>
              <div className={styles.row}>
                <VehicleAvailablityCard data={data?.vehicleAvailabilityData} />
              </div>
              <div className={styles.row}>
                <DailyBalanceCard data={data?.dailyBalance} />
                <PickupReturnCard data={data?.vehiclePickUpReturnData} />
              </div>
              <div className={styles.row}>
                <div style={{ border: "1px solid #f0f0f0", margin: "0px 10px", borderRadius: 10, width: 350 }}>
                  <BookingsByDateCard data={data?.bookingsByDates} />
                </div>
                {userState.currentBranch.overbookingAllowed && (
                  <div style={{ border: "1px solid #f0f0f0", margin: "0px 10px", borderRadius: 10, width: 350 }}>
                    <VirtualVehicleBookingsByDateCard data={data?.bookingsByDates} />
                  </div>)}
                {/* On org haveing single branch vehicle sharing is not possible, no point of showing external vehicle booking card */}
                {userState.currentOrganisation.branches.length > 1 && (
                  <div style={{ width: 220 }}>
                    <ExternalVehicleBookingCard data={data.externalVehicleBookings || []} />
                  </div>
                )}
                {/* <ComplianceRemindersCard data={data?.complianceReminders} /> */}
                {/* <div style={{ border: "1px solid #f0f0f0", margin: "0px 10px", borderRadius: 10, width: 200 }}>
                  <FinanceAllChart compact />
                </div> */}
              </div>
            </div>
            <UpcomingEvents events={data?.upcomingEvents} reminders={data?.complianceReminders} action='pendingAction' />
          </Hidden>
          <Hidden mdUp>
            <div className={classes.event_button}>
              <IconButton
                edge="end"
                aria-label="Upcoming Events"
                onClick={(event: any) => handleClick(event)}
                size="large">
                <EventIcon fontSize={"large"} />
              </IconButton>
            </div>
            <div className={classes.drawer} aria-label="Upcoming Events Drawer">
              <SwipeableDrawer
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                container={container}
                variant="temporary"
                anchor='right'
                onOpen={() => setOpen(true)}
                open={open}
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <UpcomingEvents events={data?.upcomingEvents} reminders={data?.complianceReminders} action='pendingAction' />
              </SwipeableDrawer>
            </div>
            <Grid container item xs={12} style={{ marginTop: 40 }}>
              <Grid item xs={12}>
                <VehicleAvailablityCard data={data?.vehicleAvailabilityData} />
              </Grid>
              <Grid item xs={12}>
                <DailyBalanceCard data={data?.dailyBalance} />
              </Grid>
              <Grid item xs={12}>
                <PickupReturnCard data={data?.vehiclePickUpReturnData} />
              </Grid>
              <Grid container item xs={12} justifyContent='center' >
                <Grid item>
                  <div style={{ border: "1px solid #f0f0f0", margin: "0px 10px", borderRadius: 10, width: 350 }}>
                    <BookingsByDateCard data={data?.bookingsByDates} />
                  </div>
                </Grid>
              </Grid>
              {/* <ComplianceRemindersCard data={data?.complianceReminders} /> */}
              <Grid item xs={12} style={{ border: "1px solid #f0f0f0", margin: "0px 10px", borderRadius: 10, width: 200 }}>
                {/* <FinanceAllChart compact /> */}
              </Grid>
            </Grid>
          </Hidden>
        </>
      }
    </div>
  );
}

export default Dashboard

