import { gql } from "@apollo/client";

export const GET_PCN_BY_ID = gql`
  query getPCNById($id: String!) {
    getPCNById(id: $id) {
        id
        referenceNumber
        noticeRef
        receivedAt
        noticeType
        vehicle {
            id
            licencePlate
        }
        location
        noticeToKeeper {
          url
        }
        contraventionType
        contraventionDate
        liabilityTransferred
        totalDue
        paymentUrl
        appealUrl
        dateCreated
        status
        isPaid
  }
}
`;
