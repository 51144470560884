import { Dialog, DialogContent, DialogTitle, Fab, FormControl, Grid, MenuItem, TextareaAutosize, Theme, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { createStyles, makeStyles } from "@mui/styles";
import { TextField } from 'formik-mui'
import * as Yup from "yup";
import _ from "lodash";
import React, { useEffect, useState } from 'react'
import { assetTypes, conditionTypes, fuelTypes, transmissionTypes } from '../../const'
import FlatPickerBar from "../../../../common/FlatPicker";
import { IProcurementRequestItem } from '../../../../../reducers/Procurement/types'
import { DateTime as d } from "luxon"
import { procurementRequestInitialState } from '../../../../../reducers/Procurement/const'
import { NuvvenDivider } from '../../../ReservationManagement/Summary/Components/Divider'
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { DATE_TYPE } from '../../../../common/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      background: "#dee2e6"
    },
    formPaper: {
      padding: theme.spacing(3)
    },
    form: {
      flexGrow: 1,
      marginTop: 10
    }
  })
);

interface IProps {
  procurementItem?: IProcurementRequestItem;
  open: boolean;
  onClose(): void;
  handleAddItem(procurementItem: IProcurementRequestItem): void;
}

export const NewProcurementItemRequestModel: React.FC<IProps> = (props) => {

  const { handleAddItem } = props;

  const classes = useStyles();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address

  const [values, setValues] = useState<IProcurementRequestItem>(_.cloneDeep(procurementRequestInitialState))
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (props.procurementItem?.id) {
      setIsUpdate(true)
      setValues(props.procurementItem)
      setDescription(props.procurementItem.note.description)
    } else {
      setValues(_.cloneDeep(procurementRequestInitialState))
    }
  }, [props.procurementItem])

  const procurementValidationSchema = Yup.object().shape({
    assetType: Yup.string().required("Please select Asset type."),
    condition: Yup.string().required("Please select Condition type."),
    fuel: Yup.string().required("Please select Fuel type."),
    make: Yup.string().required("Make is a required field."),
    model: Yup.string().required("Model is a required field"),
    quantity: Yup.number().positive("Must be more than 0").integer("Must be more than 0").max(10).required("Quantity is a required field"),
    transmission: Yup.string().required("Please select Transmission type."),
    variant: Yup.string().required("Variant is a required field.")
  });

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose()
        }}
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container item xs={12}>
            <Typography variant="h2">{isUpdate ? "Update Request Item" : "New Request Item"}</Typography>
          </Grid>
          <NuvvenDivider noMargin />
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Formik
              enableReinitialize
              validationSchema={procurementValidationSchema}
              initialValues={values}
              onSubmit={(values, { setSubmitting }) => {
                handleAddItem({
                  ...values,
                  note: {
                    ...values.note,
                    description
                  }
                })
                setValues(_.cloneDeep(procurementRequestInitialState))
                setSubmitting(false)
                setDescription("")
              }}
            >
              {(props) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    {
                      props.values.id && (
                        <Grid item xs={3}>
                          <Field
                            component={TextField}
                            placeholder="e.g TEST"
                            label="Request ID"
                            name={"requestId"}
                            value={props.values.requestId}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            disabled
                          ></Field>
                        </Grid>
                      )
                    }
                    <Grid item xs={3}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          placeholder="e.g TEST123 "
                          label="Asset Type"
                          name={"assetType"}
                          select
                          inputProps={{
                            onChange: (event: any) => {
                              setValues({
                                ...props.values,
                                assetType: event.target.value
                              })
                            },
                            value: values.assetType
                          }}
                          fullWidth
                          required
                        >
                          {
                            assetTypes &&
                            assetTypes.map(
                              (item) => {
                                return (
                                  <MenuItem value={item.value} key={item.name}>
                                    {item.value}
                                  </MenuItem>
                                );
                              }
                            )
                          }
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g 10"
                        label="Quantity Required"
                        name={"quantity"}
                        type="number"
                        value={props.values.quantity}
                        onChange={props.handleChange}
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g KIA"
                        label="Make"
                        name={"make"}
                        value={props.values.make}
                        onChange={props.handleChange}
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g 2022"
                        label="Model"
                        name={"model"}
                        value={props.values.model}
                        onChange={props.handleChange}
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g SX"
                        label="Variant"
                        name={"variant"}
                        value={props.values.variant}
                        onChange={props.handleChange}
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g petrol"
                        label="Fuel"
                        name={"fuel"}
                        select
                        inputProps={{
                          onChange: (event: any) => {
                            setValues({
                              ...props.values,
                              fuel: event.target.value
                            })
                          },
                          value: values.fuel
                        }}
                        required
                        fullWidth
                      >
                        {
                          fuelTypes &&
                          fuelTypes.map(
                            (item) => {
                              return (
                                <MenuItem value={item.value} key={item.name}>
                                  {item.value}
                                </MenuItem>
                              );
                            }
                          )
                        }
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g auto"
                        label="Transmission"
                        name={"transmission"}
                        select
                        inputProps={{
                          onChange: (event: any) => {
                            setValues({
                              ...props.values,
                              transmission: event.target.value
                            })
                          },
                          value: values.transmission
                        }}
                        fullWidth
                        required
                      >
                        {
                          transmissionTypes &&
                          transmissionTypes.map(
                            (item) => {
                              return (
                                <MenuItem value={item.value} key={item.name}>
                                  {item.value}
                                </MenuItem>
                              );
                            }
                          )
                        }
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g new"
                        label="Condition"
                        name={"condition"}
                        select
                        inputProps={{
                          onChange: (event: any) => {
                            setValues({
                              ...props.values,
                              condition: event.target.value
                            })
                          },
                          value: values.condition
                        }}
                        fullWidth
                        required
                      >
                        {
                          conditionTypes &&
                          conditionTypes.map(
                            (item) => {
                              return (
                                <MenuItem value={item.value} key={item.name}>
                                  {item.value}
                                </MenuItem>
                              );
                            }
                          )
                        }
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl variant="outlined" fullWidth>
                        <FlatPickerBar
                          enableTime={false}
                          handleDateChange={(value: Date) => {
                            setValues({
                              ...props.values,
                              expectedByDate: d.fromJSDate(value).toUTC().toISO()
                            })
                          }}
                          label={"Expected By"}
                          identifier={"expectedByDate"}
                          value={getLocalizedDateFormat(country, props.values.expectedByDate, DATE_TYPE.CONDENSED)}
                          minDate={getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}
                          required
                          country={country}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextareaAutosize
                          maxRows={10}
                          minRows={6}
                          placeholder="add your note here..."
                          value={description}
                          onChange={(e: any) => {
                            setDescription(e.target.value)
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-start">
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type="submit"
                        disabled={!props.values.expectedByDate}
                      >
                        {isUpdate ? "Save" : "Add"}
                      </Fab>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}
