import { gql } from "@apollo/client";

export const ADD_CUSTOMER_NOTE_MUTATION = gql`
  mutation addCustomerNote($customerId: ID! $note:CustomerNoteInput!) {
    addCustomerNote(customerId: $customerId,note:$note) {
      id
      description
      createdBy
      createdDate
    }
  }
`;