import { gql } from "@apollo/client";

export const GET_EXTERNAL_VEHICLE_GROUP_CRITERIA = gql`
  query externalVehicleGroupCriteria {
    externalVehicleGroupCriteria {
      type
      values
    }
  }
`;
