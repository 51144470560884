import { gql } from "@apollo/client";

export const ADD_BUSINESS_AUTHORISEDSIGNATORY = gql`
  mutation addBusinessAuthorizedSignatory(
    $businessCustomerId: ID!
    $authorizedSignatory: BusinessCustomerCommonInput!
  ) {
    addBusinessAuthorizedSignatory(
      businessCustomerId: $businessCustomerId
      authorizedSignatory: $authorizedSignatory
    ) {
      id
      businessName
      registrationNumber
      VAT
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone 
          country
        }
        email
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
    }
  }
`;

export const UPDATE_BUSINESS_AUTHORISEDSIGNATORY = gql`
  mutation updateBusinessAuthorizedSignatory(
    $businessCustomerId: ID!
    $authorizedSignatoryId: ID!
    $authorizedSignatory: BusinessCustomerCommonInput!
  ) {
    updateBusinessAuthorizedSignatory(
      businessCustomerId: $businessCustomerId
      authorizedSignatoryId: $authorizedSignatoryId
      authorizedSignatory: $authorizedSignatory
    ) {
      id
      businessName
      registrationNumber
      VAT
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone
          country
        }
        email
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
    }
  }
`;

export const DELETE_BUSINESS_AUTHORISEDSIGNATORY = gql`
  mutation deleteBusinessAuthorizedSignatory(
    $businessCustomerId: ID!
    $authorizedSignatoryId: ID!
  ) {
    deleteBusinessAuthorizedSignatory(
      businessCustomerId: $businessCustomerId
      authorizedSignatoryId: $authorizedSignatoryId
    ) {
      id
      businessName
      registrationNumber
      VAT
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone 
          country
        }
        email
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
    }
  }
`;
