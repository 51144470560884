import {
  Fab,
  Grid,
  IconButton,
  Theme,
  Typography,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  IVehicleDocument
} from "../../../../../reducers/fleet/types";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  checkDeleteOrDownloadFileType,
  checkUploadFileFormat,
  uploadFileExtensionAndContentType, returnSortedDocuments, DATE_TYPE, isImageFileType,
  formatGraphQLErrorMessage
} from "../../../../common/utils";
import { DocumentDialog } from "../../../CustomerManagement/CustomerDetails/DocumentDialog";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { UserRoles } from "../../../../hoc/Authorization";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { ApolloError, useMutation } from "@apollo/client";
import { captureErrorException } from "../../../../../utils/sentry";
import { IAppState } from "../../../../../store";
import { IAsset } from "../../asset.types";
import { ADD_ASSET_DOCUMENT, DELETE_ASSET_DOCUMENT } from "../../Graphql/assetMutations";

interface IProps {
  asset: IAsset | undefined
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3)
    },
  })
);

export const ViewAssetDocuments: FC<IProps> = React.memo(
  ({ asset }) => {
    const userState = useSelector((state: IAppState) => state.userReducer);
    const { country } = userState.currentOrganisation.address;
    const classes = useStyles();
    const snackbar = useSnackBar();
    const [documents, setDocuments] = useState<IVehicleDocument[]>([]);
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const [documentDialogVisible, setDocumentDialogVisible] = React.useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
    const [deletedDocumentUrl, setDeletedDocumentUrl] = useState("");
    const { _e_ } = useSelector((state: IAppState) => state.authReducer);

    const [addVehicleDocument] = useMutation(ADD_ASSET_DOCUMENT, {
      onCompleted: ({ addAssetDocument }) => {
        snackbar({
          message: "Document Added",
          variant: SnackBarVariant.SUCCESS
        });
        setUploadInProgress(false);
        setDocumentDialogVisible(false);
        setDocuments([...documents, addAssetDocument]);
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        setUploadInProgress(false);
        setDocumentDialogVisible(false);
      }
    });

    const [deleteAssetDocument] = useMutation(DELETE_ASSET_DOCUMENT, {
      onCompleted: ({ deleteAssetDocument }) => {
        snackbar({
          message: "Document deleted",
          variant: SnackBarVariant.SUCCESS
        });
        setDeleteInProgress(false);
        setOpenDeleteDialog(false);
        setDocuments(documents.filter((document) => document.url !== deletedDocumentUrl));
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        setDeleteInProgress(false);
        setOpenDeleteDialog(false);
      }
    });

    useEffect(() => {
      if (asset && asset.documents && asset.documents.length) {
        setDocuments(asset?.documents);
      }
    }, [asset]);

    // Download the document from s3
    async function downloadDocument(documentUrl: string, documentName: string) {
      if (!userState.tenancy?.id) {
        return;
      }
      const { fileExtension } = checkDeleteOrDownloadFileType(documentUrl);
      const file = await getSignedUrl(documentUrl)
      const url: any = file;

      fetch(url, {
        method: "GET"
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${documentName}.${fileExtension}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    }

    // Upload the document to s3
    async function uploadDocument(
      file: File,
      title: string,
      documentType: string,
      reminderBefore: number,
      expiryDate: string,
    ) {
      try {
        if (!userState.tenancy?.id) {
          return;
        }
        // 50MB limit exceeded
        if (file.type === "application/pdf" && file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        if (isImageFileType(file.type) && file.size > 5242880) {
          throw new Error("File size exceeded limit of 5MB");
        }
        setUploadInProgress(true);
        const fileName = file.name.split(".")[0];
        if (checkUploadFileFormat(file.type)) {
          const {
            fileExtension,
          } = uploadFileExtensionAndContentType(file.type);
          const uniqueId = uuidv4();
          const key = `${userState.tenancy.id}/${uniqueId}-asset.${fileExtension}`;

          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  const newDocument: IVehicleDocument = {
                    url: key,
                    title,
                    documentName: fileName,
                    expiryDate,
                    documentType,
                    reminderBefore
                  }
                  if (asset?.id) {
                    const assetId = asset?.id;
                    addVehicleDocument({
                      variables: { assetId, document: newDocument }
                    })
                  }
                }
              });
          }
        } else {
          setDocumentDialogVisible(false);
          setUploadInProgress(false);
          return snackbar({
            message: "Please only upload PDF, PNG, JPG or JPEG files",
            variant: SnackBarVariant.ERROR
          });
        }
      } catch (err: any) {
        captureErrorException(err)
        snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
      }
    }

    // Remove the document
    function deleteDocument(url: string) {
      deleteAssetDocument({
        variables: {
          assetId: asset?.id,
          documentUrl: url
        }
      })
    }

    const columns = [
      {
        label: "Document Type",
        name: "documentType",
      },
      {
        label: "Document Name/Title",
        name: "title"
      },
      {
        label: "Expiry Date",
        name: "expiryDate",
        options: {
          customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED) : "N/A"
        }
      },
      {
        label: "Actions",
        name: "actions",
        options: {
          filter: false,
          customBodyRender: (value: any) => {
            return (
              <React.Fragment>
                {(userState.role === UserRoles.ADMIN || userState.role === UserRoles.SUPER_ADMIN || userState.role === UserRoles.ORGANISATION_OWNER || userState.role === UserRoles.BRANCH_MANAGER) && (
                  <div>
                    <IconButton
                      edge="end"
                      aria-label="download"
                      onClick={() => {
                        downloadDocument(value.url, value.name);
                      }}
                      size="large">
                      <CloudDownloadIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        // deleteDocument(value.url, value.index)
                        setDeletedDocumentUrl(value.url);
                        setOpenDeleteDialog(true);
                      }}
                      size="large">
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
              </React.Fragment>
            );
          }
        }
      }
    ];

    const getRows = (vehicleDocument: IVehicleDocument[]) => {
      if (!vehicleDocument || vehicleDocument === null) {
        return [];
      }
      const docs = [...vehicleDocument]
      const sortedDocuments = returnSortedDocuments(docs)
      if (sortedDocuments && sortedDocuments.length > 0) {
        return sortedDocuments.map((customerDocument: IVehicleDocument) => {
          return {
            ...customerDocument,
            actions: { url: customerDocument.url, name: customerDocument.documentName },
            title: customerDocument.title || customerDocument.documentName,
            expiryDate: customerDocument.expiryDate,
            documentType: customerDocument.documentType,
            url: customerDocument.url
          };
        });
      } else {
        return []
      }
    };

    return (
      <Grid container className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10} lg={10} xl={10}>
            <Typography variant="h2">Add Asset Documents</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <Fab
              className="blackBackButton"
              variant="extended"
              size="medium"
              aria-label="Update"
              onClick={() => {
                setDocumentDialogVisible(true);
              }}
            >
              ADD DOCUMENT
            </Fab>
          </Grid>
          <Grid xs={12}>
            <Paper variant="outlined" style={{ margin: 20 }}>
              <NuvvenTable
                key={new Date().getMilliseconds()}
                title={""}
                rows={getRows(documents)}
                columns={columns}
                setSelection={() => { }}
                options={{
                  selectableRows: "none",
                  download: false,
                  filter: false,
                  print: false,
                  search: false,
                  viewColumns: false,
                  elevation: 0
                }}
              />
            </Paper>
            {
              openDeleteDialog && (
                <ConfirmationDialog
                  isOpen={openDeleteDialog}
                  onCancel={() => setOpenDeleteDialog(false)}
                  onConfirm={() => {
                    if (deletedDocumentUrl) {
                      deleteDocument(deletedDocumentUrl);
                    }
                  }}
                  title="Delete document"
                  description={"Are you sure, you want to delete this document?"}
                />
              )
            }
          </Grid>
          <div>
            <DocumentDialog
              open={documentDialogVisible}
              handleClose={() => setDocumentDialogVisible(false)}
              uploadInProgress={uploadInProgress}
              onSubmit={uploadDocument}
              accept="image/jpg, image/jpeg, image/png, application/pdf"
            />
          </div>
        </Grid>
      </Grid>
    );
  }
);