import "./index.scss";

import {
  Grid,
  Typography,
  ButtonBase,
  Card,
  CardContent,
  Hidden
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  setBranch,
} from "../../../../../actions/organisation/actions";
import {
  IBranch,
} from "../../../../../reducers/organisation/types";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";

const useStyles = makeStyles(() =>
  createStyles({
    cardStyle: {
      minWidth: 'calc(100vw - 120px)',
      maxWidth: 'calc(100vw - 120px)',
      padding: "5px",
      height: "162px",
      borderRadius: "12px",
    },
  })
);


interface IProps {
  branches: IBranch[]
}

export const columns = [
  {
    label: "Name",
    name: "name"
  },
  {
    label: "Address",
    name: "address",
    options: {
      customBodyRender: (value: any) => value.fullAddress || value.street
    }
  },
  {
    label: "Phone",
    name: "phoneNumber.phone"
  },
  {
    label: "Email",
    name: "emailId"
  },
  {
    label: "Manager",
    name: "owner.email"
  },
  {
    label: "Time Zone",
    name: "timeZone"
  }
];

const MobileBranchCards = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (props.branches) {
      setRows(props.branches);
    }
  }, [props.branches]);


  return <>
    <Grid container item xs={12}>
      <Typography variant="h2">Branch List</Typography>
    </Grid>
    <Grid container item xs={12}>
      <Typography variant="h4">Tap Branch to Edit</Typography>
    </Grid>
    <Grid container item xs={12}>
      {rows.map((branch: IBranch) => {
        return (
          <Grid style={{ marginBottom: '8px' }} item xs={12}>
            <ButtonBase
              onClick={() => {
                dispatch(
                  setBranch({
                    ...props.branches.filter(
                      (element: IBranch) => element.id === branch.id
                    )[0]
                  })
                );
                window.scrollTo(0, 0);
              }}
            >
              <Card className={classes.cardStyle}>
                <CardContent>
                  <Grid container item xs={12}>
                    <Grid container item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography style={{ fontSize: "10px" }}>
                          Name:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                          {branch.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={6}>
                      <Grid item xs={12}>
                        <Typography style={{ fontSize: "10px" }}>
                          Email:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                          {branch.emailId}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Hidden smDown>
                      <Grid container item xs={12} sm={6}>
                        <Grid item xs={12}>
                          <Typography style={{ fontSize: "10px" }}>
                            Phone:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                            {branch.phoneNumber.phone}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} sm={6}>
                        <Grid item xs={12}>
                          <Typography style={{ fontSize: "10px" }}>
                            Manager:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                            {branch.owner.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Hidden>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography style={{ fontSize: "10px" }}>
                          Time Zone:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                          {branch.timeZone}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </ButtonBase>
          </Grid>
        );
      })}
    </Grid >
  </>;
};

export default MobileBranchCards;
