import { gql } from "@apollo/client";

export const GET_BOOKING_BY_PCN = gql`
  query fetchBookingByPCN($vehicleId: String, $pcnDate: String) {
    fetchBookingByPCN(vehicleId: $vehicleId, pcnDate: $pcnDate) {
        referenceNumber
        rentalAgreementUrl
        customer {
            id
            firstName
            lastName
            email
            nationalInsuranceNumber
            phoneNumber {
              phone
              country
            }
            dateOfBirth
            customerPCONumber
            location {
              street
              city
              state
              country
              zipcode
              fullAddress
            }
            license {
              dateOfExpiry
              licenseNumber
              authority
            }
          }
          currentBookingSchedules {
            id
            startDate
            endDate
            status
            vehicleStatus
            vehicle {
              id
              licencePlate
              imageUrl
              pcoNumber
              vin
              make
              model
              features
              activeVehicleDamages {
                id
                damages {
                  title
                  damageType
                  damageKind
                  damageArea
                  descriptions
                  location {
                    x
                    y
                  }
                }
              }
              vehicleState {
                type
                odometerReading
                generalChecks {
                  name
                  status
                  images
                }
                defaultAccesories {
                  name
                  status
                  images
                }
              }
            }
            dropoffCheck {
              customerInfo {
                signature
              }
              vehicleStatePdfUrl
            }
          }
          businessCustomer {
            id
            businessName
            registrationNumber
            VAT
            registeredAddress {
              street
              city
              state
              zipcode
              country
              fullAddress
            }
            tradingAddressSame
            tradingAddress {
              street
              city
              state
              zipcode
              country
              fullAddress
            }
            approvedDrivers {
              id
              status
              firstName
              lastName
              phoneNumber {
                phone
                country
              }
              email
            }
            authorizedSignatories {
              id
              firstName
              lastName
              phoneNumber {
                phone
                country
              }
              email
            }
            billing {
              phoneNumber {
                phone
                country
              }
              email
            }
            contact {
              firstName
              lastName
              phoneNumber {
                phone
                country
              }
              email
            }
            documents {
              id
              name
              title
              documentType
              expiryDate
              url
            }
          }
  }
}
`;
