export interface IPriceRuleState {
  priceRule: IPriceRule;
}

export interface IPriceRuleInput extends IPriceRule {
  businessCustomerId?: string;
}

export interface IBusinessCustomer {
  id: string;
  businessName: string;
}

export interface IPriceRuleChange {
  rateTypeName: string;
  rateTypeDuration: number;
  operator: string;
  amount: number;
}

export type PriceRulePayloadType =
  | IRuleDetails
  | IRuleTriggers
  | IPriceSection
  | IPriceRule
  | undefined;

export interface IRuleDetails {
  name: string;
  description: string;
  isGlobal: boolean;
  businessCustomer?: IBusinessCustomer;
  isActivated: boolean;
  isFleetWide: boolean;
  vehicleGroup?: any;
  priceChanges: IPriceRuleChange[];
}

export interface IRuleTriggers {
  isTariffStyle: boolean;
  startDate?: string;
  endDate?: string;
  minNumberOfBookingDays?: number;
  bookingStartDay?: number[];
}

export interface IPriceSection {
  priceChanges: IPriceRuleChange[];
}

export interface IPriceRule {
  id?: string;
  name: string;
  vehicleGroups: string[];
  customers: string[];
  serviceLocations: string[];
  operator: string;
  rules: IRule[];
  isActive: boolean;
}

export interface IRule {
  rateType: string;
  value: number;
  taxable?: boolean;
  dateRangeOptIn?: boolean;
  durationOptIn?: boolean;
  timeRangeOptIn?: boolean;
  pickupDropoffDaysOptIn?: boolean;
  maximumBookingDuration?: number;
  minimumBookingDuration?: number;
  ruleEffectiveFromDate?: string;
  ruleEffectiveToDate?: string;
  valueType: string;
  mileageLimitOptIn?: boolean;
  unlimitedMileage?: boolean;
  mileageLimit?: number;
  pricePerExtraMile?: number;
  ruleEffectiveFromTime?: string;
  ruleEffectiveToTime?: string;
  pickupDays?: string[];
  dropoffDays?: string[];
}

export interface ISubTriggers {
  triggerType: string;
  days?: string[];
  months: string[];
  ruleEffectiveFromDate?: string;
  ruleEffectiveToDate?: string;
  minimumBookingDuration?: number;
  maximumBookingDuration?: number;
}

export interface ILocationSurchargeRules {
  id: string;
  name: string;
  value: number;
  taxable: boolean;
  serviceLocations?: string[];
}

export enum ValueType {
  FIXED = "FIXED",
  VARIABLE = "VARIABLE"
}

export enum CustomerType {
  INDIVIDUAL = "individual",
  ALL_CUSTOMER = "all customers"
}

export enum PriceRuleOperator {
  "SURGE" = "SURGE",
  "DISCOUNT" = "DISCOUNT"
}
