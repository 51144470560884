import { gql } from "@apollo/client";

export const CREATE_ASSET_DAMAGE = gql`
  mutation createAssetDamage($assetDamage: AssetDamageInput!) {
    createAssetDamage(assetDamage: $assetDamage) {
      id
      referenceNumber
      asset{
        id
        assetTag
      }
      bookingRef
      circumstance
      incidentDate
      estimatedRepairDate
      reportDate
      totalExpense
      status
      damages{
        title
        damageType
        damageKind
        damageArea
        images
        descriptions
      }
    }
  }
`;


export const UPDATE_ASSET_DAMAGE = gql`
  mutation updateAssetDamage($assetDamageId: String!, $assetDamage: AssetDamageInput!) {
    updateAssetDamage(assetDamageId:$assetDamageId, assetDamage: $assetDamage) {
      id
      referenceNumber
      asset{
        id
        assetTag
      }
      bookingRef
      circumstance
      incidentDate
      estimatedRepairDate
      reportDate
      totalExpense
      status
      damages{
        title
        damageType
        damageKind
        damageArea
        images
        descriptions
      }
    }
  }
`;