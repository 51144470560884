import {
  Fab,
  Grid,
  IconButton,
  Theme,
  Typography,
  Hidden,
  Paper,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  IVehicle,
  IVehicleDocument
} from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  checkDeleteOrDownloadFileType,
  checkUploadFileFormat,
  uploadFileExtensionAndContentType, returnSortedDocuments, DATE_TYPE, isImageFileType
} from "../../../../../common/utils";
import { DocumentDialog } from "../../../../CustomerManagement/CustomerDetails/DocumentDialog";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { UserRoles } from "../../../../../hoc/Authorization";
import { ConfirmationDialog } from "../../../../../common/ConfirmationDialog/ConfirmationDialog";
import { getSignedUrl } from '../../../../../../utils/getSignedUrl';
import { MobileDocumentCard } from "./MobileDocumentCard";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { UPLOAD_VEHICLE_DOCUMENT } from "../../../../../../graphql/fleet/uploadVehicleDocument";
import { useMutation } from "@apollo/client";
import { captureErrorException } from "../../../../../../utils/sentry";

interface IVehicleDocumentProps {
  vehicle: IVehicle;
  updateFields(payload: any): void;
  updateVehicle(payload: any): void;
}

interface IViewDocumentsProps {
  isOpen: boolean;
  deleteInProgress: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  vehicleDocuments: IVehicleDocument[];
  downloadDocument(url: string, documentName: string): void;
  deleteDocument(url: string, index: number | undefined): void;
  deFleetStatus: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px",
      width: "100%"
    },
    delete: {
      marginLeft: "10px"
    },
    fileName: {
      marginLeft: "20px",
      fontSize: 12,
      color: "black"
    },
    documents: {
      alignItems: "center",
      display: "flex"
    },
    download: {
      marginLeft: "10px"
    },
    picker: {
      width: "200px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    },
    uploadButton: {
      backgroundColor: "#faaf40",
      border: 0,
      borderRadius: 26,
      color: "white",
      fontSize: "14px",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    warning: {
      color: theme.palette.primary.dark
    },
    label: {
      color: "grey",
      fontSize: 12,
      fontWeight: 100,
      marginLeft: 20
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    docPaper: {
      flexGrow: 1,
      minHeight: 340,
      overflow: "auto"
    }
  })
);

export const VehicleDocuments = React.memo(
  (props: IVehicleDocumentProps) => {
    // Get tenancy details
    const userState = useSelector((state: IAppState) => state.userReducer);
    const classes = useStyles();
    const snackbar = useSnackBar();
    const [documents, setDocuments] = useState<IVehicleDocument[]>([]);
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const [documentDialogVisible, setDocumentDialogVisible] = React.useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
    const { _e_ } = useSelector((state: IAppState) => state.authReducer);

    const [uploadVehicleDocument] = useMutation(UPLOAD_VEHICLE_DOCUMENT)

    useEffect(() => {
      setDocuments(props.vehicle.vehicleDocuments);
    }, [props.vehicle]);

    // Download the document from s3
    async function downloadDocument(documentUrl: string, documentName: string) {
      if (!userState.tenancy?.id) {
        return;
      }
      const { fileExtension } = checkDeleteOrDownloadFileType(documentUrl);
      const file = await getSignedUrl(documentUrl)
      const url: any = file;

      fetch(url, {
        method: "GET"
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${documentName}.${fileExtension}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    }

    // Upload the document to s3
    async function uploadDocument(
      file: File,
      title: string,
      documentType: string,
      reminderBefore: number,
      expiryDate: string,
    ) {
      try {
        if (!userState.tenancy?.id) {
          return;
        }
        // 50MB limit exceeded
        if (file.type === "application/pdf" && file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        if (isImageFileType(file.type) && file.size > 5242880) {
          throw new Error("File size exceeded limit of 5MB");
        }
        setUploadInProgress(true);
        const fileName = file.name.split(".")[0];
        if (checkUploadFileFormat(file.type)) {
          const {
            fileExtension,
          } = uploadFileExtensionAndContentType(file.type);
          const uniqueId = uuidv4();
          const key = `${userState.tenancy.id}/${uniqueId}.${fileExtension}`;

          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  let docs = [...documents];
                  const newDocument: IVehicleDocument = {
                    url: key,
                    title,
                    documentName: fileName,
                    expiryDate,
                    documentType,
                    reminderBefore
                  }
                  docs.push(newDocument);
                  setDocuments(docs);
                  if (props.vehicle.id) {
                    props.updateFields({ ...props.vehicle, vehicleDocuments: docs });
                    const vehicleId = props.vehicle.id;
                    uploadVehicleDocument({
                      variables: { vehicleId, document: newDocument }
                    })
                  }
                  else {
                    props.updateFields({ vehicleDocuments: docs });
                  }
                  snackbar({
                    message: "Document Added",
                    variant: SnackBarVariant.SUCCESS
                  });
                  setUploadInProgress(false);
                  setDocumentDialogVisible(false);
                }
              });
          }
        } else {
          setDocumentDialogVisible(false);
          setUploadInProgress(false);
          return snackbar({
            message: "Please only upload PDF, PNG, JPG or JPEG files",
            variant: SnackBarVariant.ERROR
          });
        }
      } catch (err: any) {
        captureErrorException(err)
        snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
      }
    }

    // Remove the document
    function deleteDocument(url: string) {
      const { fileExtension } = checkDeleteOrDownloadFileType(url);
      setDeleteInProgress(true)
      // TODO: implement file deleteion from s3
      // remove from documents through submit button
      const docs = documents.filter((d) => d.url !== url);
      setDocuments(docs);
      props.updateFields({ ...props.vehicle, vehicleDocuments: docs });
      if (props.vehicle.id !== "") {
        props.updateVehicle({ ...props.vehicle, vehicleDocuments: docs });
      }
      snackbar({
        message: "Document Removed",
        variant: SnackBarVariant.SUCCESS
      });
      setDeleteInProgress(false);
      handleDialogClose();
    }

    const handleDialogOpen = () => {
      setOpenDeleteDialog(true)
    }

    const handleDialogClose = () => {
      setOpenDeleteDialog(false);
    }

    return (
      <Grid container className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={10} lg={10} xl={10}>
            <Typography variant="h2">Add Vehicle Documents</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <Fab
              className="blackBackButton"
              variant="extended"
              size="medium"
              aria-label="Update"
              onClick={() => {
                setDocumentDialogVisible(true);
              }}
              disabled={documents && documents.length === 7}
            >
              ADD DOCUMENT
            </Fab>
            <Typography variant={"h4"}>Only a maximum of 7 documents can be uploaded for a vehicle.</Typography>
          </Grid>
          <div>
            <DocumentDialog
              open={documentDialogVisible}
              handleClose={() => setDocumentDialogVisible(false)}
              uploadInProgress={uploadInProgress}
              onSubmit={uploadDocument}
              accept="image/jpg, image/jpeg, image/png, application/pdf"
            />
          </div>
          <ViewDocuments
            isOpen={openDeleteDialog}
            deleteInProgress={deleteInProgress}
            handleOpen={handleDialogOpen}
            handleClose={handleDialogClose}
            vehicleDocuments={documents}
            downloadDocument={downloadDocument}
            deleteDocument={deleteDocument}
            deFleetStatus={props.vehicle?.deFleetStatus || ""}
          />
        </Grid>
      </Grid>
    );
  },
  (prevProps: IVehicleDocumentProps, nextProps: IVehicleDocumentProps) =>
    prevProps === nextProps
);

const ViewDocuments = (props: IViewDocumentsProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [deleteDocumentUrl, setDeleteDocumentUrl] = useState<string>("");
  const [documentIndex, setDocumentIndex] = useState<number>();

  const deleteButtonHandle = (url: string, index: number) => {
    setDeleteDocumentUrl(url);
    setDocumentIndex(index);
    props.handleOpen();
  }

  const columns = [
    {
      label: "Document Type",
      name: "documentType",
    },
    {
      label: "Document Name/Title",
      name: "title"
    },
    {
      label: "Expiry Date",
      name: "expiryDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED) : "N/A"
      }
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <React.Fragment>
              {(userState.role === UserRoles.ADMIN || userState.role === UserRoles.SUPER_ADMIN || userState.role === UserRoles.ORGANISATION_OWNER || userState.role === UserRoles.BRANCH_MANAGER) && (
                <div>
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() =>
                      props.downloadDocument(value.url, value.name)
                    }
                    size="large">
                    <CloudDownloadIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => { deleteButtonHandle(value.url, value.index) }}
                    disabled={props.deFleetStatus === "COMPLETED"}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
            </React.Fragment>
          );
        }
      }
    }
  ];

  const getRows = (vehicleDocument: IVehicleDocument[]) => {
    if (!vehicleDocument || vehicleDocument === null) {
      return [];
    }
    const docs = [...vehicleDocument]
    const sortedDocuments = returnSortedDocuments(docs)
    if (sortedDocuments && sortedDocuments.length > 0) {
      return sortedDocuments.map((customerDocument: IVehicleDocument) => {
        return {
          ...customerDocument,
          actions: { url: customerDocument.url, name: customerDocument.documentName },
          title: customerDocument.title || customerDocument.documentName,
          expiryDate: customerDocument.expiryDate,
          documentType: customerDocument.documentType,
          url: customerDocument.url
        };
      });
    } else {
      return []
    }
  };

  return (
    <Grid item xs={12}>
      <Hidden mdDown>
        <Paper variant="outlined">
          <NuvvenTable
            key={new Date().getMilliseconds()}
            title={""}
            rows={getRows(props.vehicleDocuments)}
            columns={columns}
            setSelection={() => { }}
            options={{
              selectableRows: "none",
              download: false,
              filter: false,
              print: false,
              search: false,
              viewColumns: false,
              elevation: 0
            }}
          />
        </Paper>
      </Hidden>
      <Hidden mdUp>
        {getRows(props.vehicleDocuments) && getRows(props.vehicleDocuments).length > 0 && getRows(props.vehicleDocuments).map((document: IVehicleDocument, index: number) => {
          return (
            <MobileDocumentCard row={document} index={index} downloadHandle={props.downloadDocument} deleteHandle={deleteButtonHandle} />
          )
        })}
      </Hidden>
      {
        props.isOpen && (
          <ConfirmationDialog
            isOpen={props.isOpen}
            onCancel={props.handleClose}
            onConfirm={() => {
              props.deleteDocument(deleteDocumentUrl, documentIndex);
            }}
            isInProgress={props.deleteInProgress}
            title=""
            description={"Are you sure, you want to delete this document?"}
          />
        )
      }
    </Grid>
  );
};