import React from 'react';
import Grid from '@mui/material/Grid';
import { EmissionChart } from './EmissionsChart';
interface IProps {
  active: boolean
}

export const EmissionModule: React.FC<IProps> = ({ active }) => {

  return (
    <div style={{ display: active ? 'block' : 'none' }}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <EmissionChart />
        </Grid>
      </Grid>
    </div>
  )
}