import {
  Dialog,
  DialogContent,
  Typography,
  Theme,
  Grid,
  Fab,
  TextField,
  DialogTitle,
  Switch,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState } from "react";
import { Formik, Form, Field, FieldProps } from 'formik';
import * as Yup from "yup";
import { CircularProgress } from "@mui/material";
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import { CustomerType } from "../../../ReservationManagement/utils";
export interface IDocumentType {
  id?: string;
  typeName: string;
  hasExpiry: boolean;
  description?: string;
  reminderBefore?: number;
  isMandatory?: boolean;
  applicableFor?: string[]; 
}

interface IDialogProps {
  open: boolean;
  data: IDocumentType;
  handleClose: () => void;
  handleSubmit: (document: IDocumentType) => void;
  setButtonDisabled?: boolean
}

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 34,
      height: 22,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 4,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: 'var(--theme-primary)',
          borderColor: 'var(--theme-primary)',
        },
      },
    },
    thumb: {
      width: 14,
      height: 14,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[800]}`,
      borderRadius: 22 / 2,
      height: 22,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}


export const AddDocumentTypeDialog: React.FC<IDialogProps> = (props) => {
  const { open, handleClose, handleSubmit, data } = props;
  const validationSchema = Yup.object().shape({
    typeName: Yup.string().required(),
    reminderBefore: Yup.number().when('hasExpiry', {
      is: true,
      then: schema => schema.required('Required').min(1),
    }),
    applicableFor: Yup.array().of(Yup.string()).nullable().when('isMandatory', {
      is: true,
      then: (schema) => schema.min(1, "At least one option must be selected"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <DialogTitle>
              <Typography variant="h4" style={{ fontWeight: 700, fontSize: 16 }}>
                {data.id ? "Update" : "Create"} Document Type</Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2} style={{ width: 400 }}>
                <Grid item xs={12}>
                  <Field
                    name="typeName"
                  >
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <TextField
                        {...field}
                        label="Document type name"
                        placeholder="Enter document type name"
                        fullWidth
                        error={touched && Boolean(error)}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: 'flex' }} >
                    <Field
                      name="hasExpiry"
                    >
                      {({ field }: FieldProps) => (
                        <AntSwitch checked={field.value} {...field} />
                      )}
                    </Field>
                    <Typography variant="h6" style={{ fontWeight: 700, marginLeft: 10 }}>Document has expiry date</Typography>
                  </div>
                </Grid>
                {values.hasExpiry && <Grid item xs={12}>
                  <div className="flex cross-center" >
                    <Typography variant="h6" style={{ fontWeight: 700, marginRight: 10 }}>Reminder before</Typography>
                    <div style={{ width: 80 }}>
                      <Field
                        name="reminderBefore"
                      >
                        {({ field, meta: { touched, error }, }: FieldProps) => (
                          <TextField
                            {...field}
                            label=""
                            type="number"
                            placeholder=""
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                        )}
                      </Field>
                    </div>
                    <Typography variant="h6" style={{ fontWeight: 700, marginLeft: 10 }}>days</Typography>
                    {errors.reminderBefore && <Typography variant="h6" className="flex fill main-end" style={{ color: "#D0021B" }}>Minimum 1 day</Typography>}
                  </div>
                </Grid>}
                <Grid item xs={12}>
                  <div style={{ display: 'flex' }}>
                  <AntSwitch
                      checked={values.isMandatory}
                      onChange={(e:any) => {
                        setFieldValue('isMandatory', e.target.checked)
                        if (values?.isMandatory=== true) {
                          setFieldValue("applicableFor", []);
                        }
                      }}
                    />
                    <Typography variant="h6" style={{ fontWeight: 700, marginLeft: 10 }}>Is Mandatory</Typography>
                  </div>
                </Grid>
                {values.isMandatory && (
                  <>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.applicableFor?.includes(CustomerType.INDIVIDUAL)}
                            onChange={(e) => {
                              const value = CustomerType.INDIVIDUAL;
                              let newArray = [...(values.applicableFor || [])];
                              if (e.target.checked) {
                                newArray.push(value);
                              } else {
                                newArray = newArray.filter(v => v !== value);
                              }
                              setFieldValue('applicableFor', newArray);
                            }}
                          />
                        }
                        label="Individual Customer"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.applicableFor?.includes(CustomerType.BUSINESS)}
                            onChange={(e) => {
                              const value = CustomerType.BUSINESS;
                              let newArray = [...(values.applicableFor || [])];
                              if (e.target.checked) {
                                newArray.push(value);
                              } else {
                                newArray = newArray.filter(v => v !== value);
                              }
                              setFieldValue('applicableFor', newArray);
                            }}
                          />
                        }
                        label="Business Customer"
                      />
                    </Grid>
                    {touched.applicableFor && errors.applicableFor && (
                      <Typography color="error" variant="body2" style={{marginLeft:20}}>
                        {errors.applicableFor}
                      </Typography>
                    )}
                  </>
                )}
                <Grid item xs={12}>
                  <Field
                    name="description"
                  >
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <TextField
                        {...field}
                        label="Description"
                        placeholder="Add description"
                        fullWidth
                        error={touched && Boolean(error)}
                        multiline
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Fab
                className="blackBackButton"
                variant="extended"
                size="small"
                onClick={handleClose}
              >
                Cancel
              </Fab>
              <Fab
                variant="extended"
                size="small"
                type="submit"
                disabled={props.setButtonDisabled}
              >
                {props.setButtonDisabled && (
                  <CircularProgress
                    size={14}
                    style={{ color: "white", marginRight: "10px" }}
                  />
                )}
                <strong>{data.id ? "Update" : "Create"}</strong>
              </Fab>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}



