import { useMutation } from '@apollo/client';
import { Fab, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ApolloError } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ADD_PROCUREMENT_FINANCE_ORDER } from '../../../../graphql/Procurement/addProcurementFinanceInfoMutation';
import { UPDATE_PROCUREMENT_FINANCE_INFO } from '../../../../graphql/Procurement/updateProcurementFinanceInfoMutation';
import { IFinanceInformation, IProcurementRequest } from '../../../../reducers/Procurement/types';
import { useSnackBar } from '../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../common/SnackbarWrapper/SnackbarWrapper';
import { DATE_TYPE, checkDeleteOrDownloadFileType, formatGraphQLErrorMessage, toCurrency } from '../../../common/utils';
import { DateTime as d } from "luxon"
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { NewFinanceInfoModel } from './Models/NewFinanceInfoModel';
import { UserRoles } from '../../../hoc/Authorization';
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { DocumentsDialog } from './Models/DocumentsDialog';
import { getLocalizedDateFormat } from '../../../../utils/localized.syntex';

export const financeInfoInitials: IFinanceInformation = {
	advancedPayment: 0,
	expectedArrivalDate: "",
	documents: [],
	purchaseOrderDate: "",
	invoiceDate: "",
	invoiceId: "",
	partnerId: "",
	paymentSource: "",
	purchaseOrderId: "",
	totalAmount: 0,
}

interface IProps {
	procurement: IProcurementRequest;
	onNextStep(data: any): void;
	onPreviousStep(): void;
	setProcurementRequest(data: IProcurementRequest): void;
}

export const FinanceInformation: React.FC<IProps> = (props) => {

	const snackbar = useSnackBar();
	const userState = useSelector((state: IAppState) => state.userReducer);
	const { country } = userState.currentOrganisation.address
	const { locale, currency } = userState.currentOrganisation;
	const [financeInfoItems, setFinanceInfoItems] = useState<IFinanceInformation[]>(props.procurement.financeInformation)
	const [financeInfo, setFinanceInfo] = useState<IFinanceInformation>()
	const [open, setOpen] = useState<boolean>(false)
	const [openDocumentDialog, setOpenDocumentDialog] = useState<boolean>(false);
	const [documents, setDocuments] = useState<string[]>([])
	const [isNext, setIsNext] = useState<boolean>(true);

	useEffect(() => {
		if (props.procurement && props.procurement.financeInformation) {
			setFinanceInfoItems(props.procurement.financeInformation)
			const finaceInfoData = props.procurement.financeInformation[0]
			if (finaceInfoData.invoiceDate && finaceInfoData.invoiceId && finaceInfoData.totalAmount && finaceInfoData.expectedArrivalDate) {
				setIsNext(false)
			}
		}
	}, [props.procurement])

	const [addProcurementFinanceInfo] = useMutation(ADD_PROCUREMENT_FINANCE_ORDER, {
		onCompleted: (data) => {
			snackbar({
				message: "Finance info added successfully",
				variant: SnackBarVariant.SUCCESS
			})
			if (data && data.addProcurementFinanceInfo) {
				setFinanceInfoItems([
					...(financeInfoItems ?? []), data.addProcurementFinanceInfo
				])
				setFinanceInfo(financeInfoInitials)
				setOpen(false)
			}
		},
		onError: (error: ApolloError) =>
			snackbar({
				message: formatGraphQLErrorMessage(error.message),
				variant: SnackBarVariant.ERROR
			})
	});

	const [updateProcurementFinanceInfo] = useMutation(UPDATE_PROCUREMENT_FINANCE_INFO, {
		onCompleted: (data) => {
			snackbar({
				message: "Finance info updated successfully",
				variant: SnackBarVariant.SUCCESS
			})
			if (data && data.updateProcurementFinanceInfo) {
				props.setProcurementRequest({
					...props.procurement,
					financeInformation: [data.updateProcurementFinanceInfo.financeInformation],
					deliveryInformation: data.updateProcurementFinanceInfo.deliveryInfo
				})
			}
			setOpen(false)
			setFinanceInfo(financeInfoInitials)
		},
		onError: (error: ApolloError) =>
			snackbar({
				message: formatGraphQLErrorMessage(error.message),
				variant: SnackBarVariant.ERROR
			})
	});

	const handleOnCloseModel = () => {
		setFinanceInfo(financeInfoInitials)
		setOpen(false)
	}

	const handelFormSubmit = (finance: IFinanceInformation) => {
		const { id, partner, ...rest } = finance
		if (finance.id) {
			updateProcurementFinanceInfo({
				variables: {
					id: props.procurement.id,
					financeInformationId: finance.id,
					financeInformation: rest
				}
			})
		} else {
			addProcurementFinanceInfo({
				variables: {
					id: props.procurement.id,
					financeInformation: rest
				}
			})
		}
	}

	async function downloadDocument(documentKey: string, title: string) {
		if (!userState.tenancy?.id) {
			return;
		}
		const { fileExtension } = checkDeleteOrDownloadFileType(
			documentKey
		);
		const file = await getSignedUrl(documentKey)
		const url: any = file;

		fetch(url, {
			method: "GET"
		})
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `${title}.${fileExtension}`;
				document.body.appendChild(a);
				a.click();
				a.remove();
			});
	}

	return (
        <Grid container item xs={12} spacing={2}>
			<Grid item xs={12}>
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell className={"tableCellHead"} align="left">PO Date</TableCell>
								<TableCell className={"tableCellHead"} align="left">Invoice ID</TableCell>
								<TableCell className={"tableCellHead"} align="left">Invoice Date</TableCell>
								<TableCell className={"tableCellHead"} align="left">Invoice Total</TableCell>
								<TableCell className={"tableCellHead"} align="left">ETA</TableCell>
								<TableCell className={"tableCellHead"} align="left">Advance Payment</TableCell>
								<TableCell className={"tableCellHead"} align="left">Payment Source</TableCell>
								<TableCell className={"tableCellHead"} align="left">Documents</TableCell>
								<TableCell className={"tableCellHead"} align="left">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								financeInfoItems &&
								financeInfoItems.map((row: IFinanceInformation, index: number) => (
									<TableRow
										key={index}
									>
										<TableCell align="left">{row.purchaseOrderDate ? getLocalizedDateFormat(country, row.purchaseOrderDate, DATE_TYPE.CONDENSED) : "N/A"}</TableCell>
										<TableCell align="left">{row.invoiceId || "N/A"}</TableCell>
										<TableCell align="left">{row.invoiceDate ? getLocalizedDateFormat(country, row.invoiceDate, DATE_TYPE.CONDENSED) : "N/A"}</TableCell>
										<TableCell align="left">{toCurrency(row.totalAmount, currency, locale)}</TableCell>
										<TableCell align="left">{row.expectedArrivalDate ? getLocalizedDateFormat(country, row.expectedArrivalDate, DATE_TYPE.CONDENSED) : "N/A"}</TableCell>
										<TableCell align="left">{toCurrency(row.advancedPayment, currency, locale)}</TableCell>
										<TableCell align="left">{row.paymentSource}</TableCell>
										<TableCell align="left">
											<IconButton
                                                onClick={() => {
													setDocuments(row.documents)
													setOpenDocumentDialog(true)
												}}
                                                disabled={row.documents.length > 0 ? false : true}
                                                aria-label="documents"
                                                size="large">
												<VisibilityIcon />
											</IconButton>
										</TableCell>
										<TableCell align="right">
											<Grid item xs={12} container>
												<Tooltip title="Edit">
													<IconButton
                                                        onClick={() => {
															const financeData = financeInfoItems[index]
															setFinanceInfo(financeData)
															setOpen(true)
														}}
                                                        aria-label="edit"
                                                        disabled={userState.role === UserRoles.FINANCE_MANAGER ? false : true}
                                                        size="large">
														<EditIcon />
													</IconButton>
												</Tooltip>
											</Grid>
										</TableCell>
									</TableRow>
								))
							}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			{
				openDocumentDialog && (
					<DocumentsDialog
						open={openDocumentDialog}
						documents={documents}
						download={downloadDocument}
						onClose={() => setOpenDocumentDialog(false)}
					/>
				)
			}
			{
				open && (
					<NewFinanceInfoModel
						handelFormSubmit={handelFormSubmit}
						onClose={() => handleOnCloseModel()}
						open={open}
						fiancne={financeInfo}
						purchaseOrders={props.procurement.purchaseOrders}
						procurementExpectedDate={props.procurement.createdAt}
					/>
				)
			}
			<Grid item container xs={12} justifyContent="space-between">
				<Fab
					className="blackBackButton"
					variant="extended"
					size="medium"
					aria-label="prev"
					onClick={() => {
						props.onPreviousStep()
					}}
				>
					Previous
				</Fab>
				<Fab
					variant="extended"
					size="medium"
					aria-label="next"
					disabled={isNext}
					onClick={() => {
						props.onNextStep(financeInfo)
					}}
				>
					Next
				</Fab>
			</Grid>
		</Grid>
    );
}
