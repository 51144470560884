import {
  UPDATE_AUTH_STATE,
  AuthAction,
  SET_AUTH_EVAPORATE_CLIENT
} from "../../actions/auth/types";
import { initialState } from "./const";
import { IAuthState } from "./types";

export default function (
  state: IAuthState = initialState,
  action: AuthAction
): IAuthState {
  switch (action.type) {
    case UPDATE_AUTH_STATE:
      return action.payload;
    case SET_AUTH_EVAPORATE_CLIENT:
      return {
        ...state,
        _e_: action.payload._e_
      }
    default:
      return state;
  }
}
