import "../../index.scss";

import {
  Box,
  CssBaseline,
  Grid,
  Typography,
  Hidden
} from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IBusinessCustomer } from "../../../../../reducers/customer/types";
import { IAppState } from "../../../../../store";
import StepperHeader from "../../../../common/StepperHeader/StepperHeader";
import { NewApprovedDrivers } from "./Components/NewApprovedDrivers";
import { NewAutorisedSignatory } from "./Components/NewAuthorisedSignatory";
import { NewBusinessDocuments } from "./Components/NewBusinessDocuments";
import { NewCustomerInfo } from "./Components/NewCustomerInfo";


export const NewBusinessCustomer = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const loading = false;
  const businessCustomer: IBusinessCustomer = useSelector(
    (state: IAppState) => state.customerReducer.businessCustomer
  );

  function nextStep() {
    setCurrentStep(currentStep + 1);
  }

  function previousStep() {
    setCurrentStep(currentStep - 1);
  }

  const stepMapper: any = {
    0: (
      <NewCustomerInfo
        businessCustomer={businessCustomer}
        onSubmit={nextStep}
      />
    ),
    1: <NewAutorisedSignatory onSubmit={nextStep} onPrevious={previousStep} />,
    2: <NewApprovedDrivers onSubmit={nextStep} onPrevious={previousStep} />,
    3: <NewBusinessDocuments onPrevious={previousStep} />
  };

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid item container xs={12} sm={12}>
        <Hidden smDown>
          <Grid container item xs={5} alignItems="center">
            <Typography variant="h1" color="primary">
              Customers
            </Typography>
            <Box color="white" sx={{ pr: 1 }}></Box>
            <DoubleArrowIcon />
            <Box color="white" sx={{ pl: 1 }}></Box>
            <Typography variant="h1" color="primary">
              &nbsp;New Business Customer
            </Typography>
          </Grid>
          <Grid item container xs={7} alignContent="flex-end">
            <StepperHeader
              currentStep={2}
              steps={[
                "COMPANY INFORMATION",
                "AUTHORISED SIGNATORIES",
                "APPROVED DRIVERS",
                "UPLOAD DOCUMENT"
              ]}
            />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid container item xs={12} alignItems="center">
            <Typography variant="h1" color="primary">
              Customers
            </Typography>
            <Box color="white" sx={{ pr: 1 }}></Box>
            <DoubleArrowIcon />
            <Box color="white" sx={{ pl: 1 }}></Box>
            <Typography variant="h1" color="primary">
              &nbsp;New Business Customer
            </Typography>
          </Grid>
          <Grid item container xs={12} alignContent="flex-end">
            <StepperHeader
              currentStep={2}
              steps={[
                "COMPANY INFORMATION",
                "AUTHORISED SIGNATORIES",
                "APPROVED DRIVERS",
                "UPLOAD DOCUMENT"
              ]}
            />
          </Grid>
        </Hidden>
      </Grid>
      <Grid container item xs={12}>
        {!loading && stepMapper[currentStep]}
      </Grid>
    </Grid>
  );
};
