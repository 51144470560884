import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import RoundIcon from '@mui/icons-material/FiberManualRecord';
import SpeedIcon from '@mui/icons-material/Speed';
import { Typography, Checkbox, Collapse, CircularProgress } from '@mui/material';
import { IVehicle } from '../../../../../reducers/fleet/types';
import { DateTime as d } from "luxon"

export interface IVehicleGroupData {
  id: string
  name: string;
  vehicles: IVehicle[]
}

export interface ISelectedVehicle {
  licencePlate: string,
  deviceEsn: string
}

export interface IVehicleTelematics {
  lat?: number;
  lng?: number;
  status?: string;
  esn: string;
  id: string;
  licencePlate?: string;
  lastLocation: string;
}

interface ICardProps {
  vehicleGroup: IVehicleGroupData;
  selectedVehicles: ISelectedVehicle[];
  handleSelection: (vehicles: ISelectedVehicle[], unselect: boolean) => void
}

enum SelectionState {
  ALL = "ALL",
  NONE = "NONE",
  SOME = "SOME"
}

const VehicleGroupCard: React.FC<ICardProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectionState, setSelectionState] = useState<SelectionState>(SelectionState.NONE);

  useEffect(() => {
    const _vehicles = [...props.selectedVehicles];
    const numSelected = _vehicles.filter(v => props.vehicleGroup.vehicles.findIndex(vehicle => vehicle.licencePlate === v.licencePlate) >= 0).length;
    if (numSelected === props.vehicleGroup.vehicles.length) {
      setSelectionState(SelectionState.ALL);
    } else if (numSelected === 0) {
      setSelectionState(SelectionState.NONE);
    } else {
      setSelectionState(SelectionState.SOME)
    }
  }, [props.selectedVehicles])

  return (
    <div className={styles.card}>
      <div className={styles.vgRow} role="button" onClick={() => setExpanded(prev => !prev)}>
        <div style={{ margin: "-8px 0 0 -8px", fontSize: 16 }}>
          <Checkbox
            size="small"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 16 }} />}
            checkedIcon={<CheckBoxIcon style={{ fontSize: 16 }} />}
            indeterminateIcon={<IndeterminateCheckBoxIcon style={{ fontSize: 16 }} />}
            indeterminate={selectionState === SelectionState.SOME}
            checked={selectionState === SelectionState.ALL || selectionState === SelectionState.SOME}
            onClick={(e) => {
              e.stopPropagation();
              let unselect = false;
              let _vehicles: ISelectedVehicle[] = props.vehicleGroup.vehicles.map((vehicle) => {
                return {
                  licencePlate: vehicle.licencePlate,
                  deviceEsn: vehicle.telemetryDeviceId
                }
              });
              if (selectionState === SelectionState.ALL) {
                unselect = true;
                setSelectionState(SelectionState.NONE)
              } else if (selectionState === SelectionState.SOME) {
                unselect = true;
                setSelectionState(SelectionState.NONE)
              } else {
                setSelectionState(SelectionState.ALL)
              }
              props.handleSelection(_vehicles, unselect);
            }}
          />
        </div>
        <div className="flex fill col-flex">
          <div className="flex">
            <Typography variant="body1" className="flex fill bold cross-center">{props.vehicleGroup.name}</Typography>
            <ExpandMoreIcon style={{ fontSize: 16, transform: expanded ? "rotate(180deg)" : "none" }} />
          </div>
          <span className="flex cross-start" style={{ marginTop: 5 }}>
            <DriveEtaIcon style={{ fontSize: 16, marginRight: 3 }} />
            <span>{props.vehicleGroup.vehicles.length}</span>
          </span>
        </div>
      </div>
      <Collapse in={expanded}>
        {props.vehicleGroup.vehicles.map((vehicle, index) => {
          if (vehicle.licencePlate) {
            const selected = props.selectedVehicles.findIndex((item) => item.licencePlate === vehicle.licencePlate);
            const running = vehicle.telematicCoreData?.speed && vehicle.telematicCoreData.eventTime && (d.fromISO(vehicle.telematicCoreData.eventTime).plus({ minutes: 2 }) > d.now())
            return (
              <div
                className={`${styles.vehicleRow} ${selected >= 0 ? styles.selected : ""}`}
                onClick={() => {
                  props.handleSelection([{
                    licencePlate: vehicle.licencePlate,
                    deviceEsn: vehicle.telemetryDeviceId
                  }], selected >= 0 ? true : false)
                }}
                key={index}
              >
                <Typography variant="body1" className="flex fill bold">{vehicle.licencePlate}</Typography>
                {vehicle.telematicCoreData && <div className="flex cross-center" style={{ marginTop: 5 }}>
                  <span className="flex cross-start" style={{ color: running ? "green" : "red" }}>
                    <RoundIcon color="inherit" style={{ fontSize: 16, marginLeft: -3, color: "green !important" }} />
                    {running ? <span>{parseFloat(`${vehicle.telematicCoreData.speed / 1.609344}`).toFixed(2)}mph</span> : <span className="margin-left margin-right" />}
                  </span>
                  <span className="flex cross-start fill">
                    <SpeedIcon style={{ fontSize: 16, margin: "0 3px 0 10px", color: "green !important" }} />
                    <span> {parseFloat(`${(vehicle.telematicCoreData.odometer / 1000) * 0.621371}`).toFixed(2)}miles</span>
                  </span>
                </div>}
                {vehicle.telematicCoreData?.eventTime && <div className="flex cross-center" style={{ marginTop: 5 }}>
                  <span>Last event: {d.fromISO(vehicle.telematicCoreData.eventTime).toFormat("dd/MM/yyyy hh:mm a")}</span>
                </div>}
              </div>
            )
          }
        })}
      </Collapse>
    </div >
  )
}


interface IProps {
  selectedVehicles: ISelectedVehicle[];
  setVehicles: (vehicles: ISelectedVehicle[]) => void;
  vehicleGroups: IVehicleGroupData[];
}


const VehicleSelection: React.FC<IProps> = ({ setVehicles, selectedVehicles, vehicleGroups }) => {

  const handleToggleSelection = (vehicles: ISelectedVehicle[], unselect: boolean) => {
    let _vehicles = [...selectedVehicles];
    if (unselect) {
      _vehicles = _vehicles.filter((v) => vehicles.findIndex((vehicle) => vehicle.licencePlate === v.licencePlate) < 0);
    }
    else {
      _vehicles = [..._vehicles, ...vehicles.filter((vehicle) => _vehicles.findIndex((v) => v.licencePlate === vehicle.licencePlate) < 0)]
    }
    setVehicles(_vehicles);
  }
  return (
    <>
      {(!vehicleGroups.length) ?
        <div className="flex main-center cross-center" style={{ height: 100, backgroundColor: "#fff", borderRadius: 10, padding: "0 15px" }}>
          {!vehicleGroups.length ? <CircularProgress size={20} thickness={5} /> : <span> No vehicles available with telematics enabled</span>}
        </div>
        : vehicleGroups.map((vg, index) => (
          <VehicleGroupCard key={index} vehicleGroup={vg} selectedVehicles={selectedVehicles} handleSelection={handleToggleSelection} />
        ))}
    </>
  );
}

export default VehicleSelection

