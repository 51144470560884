import { gql } from "@apollo/client";

export const GET_INVOICE_CSV_TEMPLATES = gql`
{
  csvTemplates {
    id
    title
    fields {
      key
      label
    }
  }
}
`;