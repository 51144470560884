import { gql } from "@apollo/client";

export const UPLOAD_VEHICLE_DOCUMENT = gql`
  mutation addVehicleDocument($vehicleId: ID!, $document: VehicleDocumentInput!){
    addVehicleDocument(vehicleId: $vehicleId, document: $document){
      id
      title
      documentName
      documentType
      expiryDate
      url
      createdAt
      createdBy
    }
  }
 `   