import { useLazyQuery } from '@apollo/client';
import { Box, CircularProgress, Fab, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DateTime as d } from "luxon";
import { GET_AVAILABLE_VEHICLE_GROUPS } from '../../../../../graphql/bookings/getAvailableVehicleGroups';
import { GET_CURRENT_BOOKING_SCHEDULE } from '../../../../../graphql/bookings/getCurrentBookingScheduleQuery'
import { IBookingSchedule, IBookingVehicleGroup } from '../../../../../reducers/bookings/types';
import { IVehicle } from '../../../../../reducers/fleet/types';
import { DATE_TYPE } from '../../../../common/utils';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';

const initialValues: any = {
  customerType: "individual",
  pickupServiceLocation: "",
  dropoffServiceLocation: "",
  pickupDateTime: "",
  dropoffDateTime: "",
  flightNumber: "",
  longTermBooking: false,
  rateTypeName: "daily",
  rateTypeDuration: 1440,
  vehicleGroups: [],
  insuranceRate: 0,
  excess: 0,
  addonRequirements: [],
  approvedDrivers: []
};

interface IUpdateBookingSchedule {
  startDate: string;
  endDate: string;
  bookingId: string;
  bookingSchedules: string[]
}

interface IDialogData {
  startDate?: string | undefined;
  endDate?: string;
  bookingId?: string;
}
interface IProps {
  type: string;
  handleClose(): void;
  dialogData: IDialogData;
  updateBookingSchedules(values: IUpdateBookingSchedule): void;
}
const initialSchedule = {
  startDate: "",
  endDate: "",
  bookingId: "",
  bookingSchedules: []
}
export const ConfirmBookingUpdate: React.FC<IProps> = (props) => {

  const [bookingData, setBookingData] = useState(initialValues)
  const [vehicleGroups, setVehicleGroups] = useState<string[]>([])
  const [values, setValues] = useState<IUpdateBookingSchedule>(initialSchedule)
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { dialogData, handleClose, updateBookingSchedules } = props

  const [getCurrentBookingSchedule, { loading: currentBookingScheduleLoading, data: currentBookingSchedule }] = useLazyQuery(
    GET_CURRENT_BOOKING_SCHEDULE, {
    fetchPolicy: "network-only"
  })
  const [getAvailableVehicleGroups, { loading: availableVehicleGroupsLoading, data: availableVehicleGroups }] = useLazyQuery(GET_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "network-only"
  })

  useState(() => {
    if (dialogData && dialogData.bookingId) {
      getCurrentBookingSchedule({
        variables: {
          id: dialogData.bookingId
        }
      })
    }
  })

  useEffect(() => {
    if (currentBookingSchedule && currentBookingSchedule.booking) {
      setBookingData(currentBookingSchedule.booking)
      setValues({
        startDate: currentBookingSchedule.booking.dropoffDateTime,
        endDate: dialogData.endDate || "",
        bookingId: currentBookingSchedule.booking.id,
        bookingSchedules: [currentBookingSchedule.booking.currentBookingSchedules[0].id]
      })
      getAvailableVehicleGroups({
        variables: {
          args: {
            endDate: d.fromISO(dialogData.endDate || "").toUTC().toISO(),
            startDate: d.fromISO(currentBookingSchedule.booking.dropoffDateTime).plus({ day: 1 }).toUTC().toISO(),
            longTerm: false
          }
        }
      })
    }
  }, [currentBookingSchedule])

  useEffect(() => {
    if (availableVehicleGroups && availableVehicleGroups.vehicleGroups) {
      const aVg: string[] = []
      availableVehicleGroups.vehicleGroups.forEach((vg: IBookingVehicleGroup) => {
        if (vg.vehicles) {
          vg.vehicles.map((vehicle: IVehicle) => {
            if (vehicle.id) {
              aVg.push(vehicle.id)
            }
          })
        }
      });
      setVehicleGroups(aVg)
    }
  }, [availableVehicleGroups])

  if (currentBookingScheduleLoading) {
    return <CircularProgress />
  }

  return (
    <Grid container item xs={12} spacing={2} style={{ marginBottom: 15 }}>
      <Grid item xs={12}>
        <Typography variant={"body1"}>
          This booking {`( ${bookingData?.referenceNumber} )`} has following {bookingData?.currentBookingSchedules?.length} vehicles associated
        </Typography>
        <Box mt={1}></Box>
        <Typography variant={"body1"}>
          Select the vehicle{"(s)"} you wish to increase the booking from {getLocalizedDateFormat(country, dialogData.startDate || "", DATE_TYPE.EXPANDED)} to {getLocalizedDateFormat(country, dialogData.endDate || "", DATE_TYPE.EXPANDED)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size={"small"} >
            <TableHead>
              <TableRow>
                <TableCell align="left"><Typography variant={"h4"}>Licence Plate</Typography></TableCell>
                <TableCell align="left"><Typography variant={"h4"}>Extended Date</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {
                bookingData &&
                bookingData.currentBookingSchedules &&
                bookingData.currentBookingSchedules.length > 0 &&
                bookingData.currentBookingSchedules.map((bs: IBookingSchedule) => {
                  return (
                    <TableRow>
                      <TableCell><Typography variant="h4">{bs.vehicle.licencePlate}</Typography></TableCell>
                      <TableCell><Typography variant="h4">{getLocalizedDateFormat(country, dialogData.endDate || "", DATE_TYPE.EXPANDED)}</Typography></TableCell>
                      {/* this part not completed due to more clarification on drag and drop booking from one vehicle to other vehicle  */}
                      {/* <TableCell>
                        <Typography variant="h4">
                          {bs.vehicle.id && !vehicleGroups.includes(bs.vehicle.id) ?
                            (
                              <Typography variant="h3" style={{ color: "red" }}>{"Not Available"}</Typography>
                            )
                            : (
                              <FormControl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.bookingSchedules.includes(bs.id)}
                                      onChange={() => {
                                        if (bookingData.id && dialogData.endDate, values?.bookingSchedules) {
                                          const scheduleIndex = values.bookingSchedules.indexOf(bs.id)
                                          if (scheduleIndex >= 0) {
                                            const bookingSchedules = [...values.bookingSchedules]
                                            bookingSchedules.splice(scheduleIndex, 1)
                                            setValues({
                                              ...values,
                                              bookingSchedules
                                            })
                                          }
                                          else {
                                            setValues({
                                              startDate: bookingData.dropoffDateTime,
                                              endDate: dialogData.endDate || "",
                                              bookingId: bookingData.id,
                                              bookingSchedules: [...values?.bookingSchedules, bs.id]
                                            })
                                          }
                                        }
                                      }}
                                      color="secondary"
                                    />
                                  }
                                  label=""
                                />
                              </FormControl>
                            )
                          }
                        </Typography></TableCell> */}
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container item xs={12}>
        {
          values && (
            <Fab
              size="medium"
              variant="extended"
              onClick={() => {
                updateBookingSchedules(values)
                handleClose()
              }}
            >
              Confirm
            </Fab>
          )
        }
        <Box pl={2}></Box>
        <Fab
          size="medium"
          variant="extended"
          className="blackBackButton"
          onClick={() => {
            handleClose()
          }}
        >
          Cancel
        </Fab>
      </Grid>
    </Grid>
  )
}
