import { gql } from "@apollo/client";

export const GET_CREDIT_NOTES = gql`
  query creditNotes($limit: Int!, $offset: Int!){
    creditNotes(limit: $limit, offset: $offset) {
      id
      creditNoteRef
      creditNoteType
      totalAmount
      availableAmount
      refundedAmount
      createdAt
      versionNumber
      sentToXero
      paymentsLinked {
        amount
        id
        status
      }
      invoice {
        invoiceRef
        id
      }
      booking {
        referenceNumber
        id
      }
      customer {
        firstName
        lastName
        id
      }
      businessCustomer {
        businessName
        id
      }
    }
  }
`