import React, { useEffect, useState } from 'react';
import { formatGraphQLErrorMessage } from '../../../../common/utils';
import { IBookingSchedule } from './BookingSummary';
import { GET_AVAILABLE_VEHICLE_GROUPS } from '../../../../../graphql/bookings/getAvailableVehicleGroups';
import { GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS } from '../../../../../graphql/bookings/getExternalAvailableVehicleGroups';
import { IVehiclePriceGroup } from '../../../../../reducers/vehiclePriceGroups/types';
import { IVehicle } from '../../../../../reducers/fleet/types';
import { DateTime as d } from 'luxon';
import { GET_VEHICLE_GROUP } from '../../../../../graphql/vehiclePriceGroup/getVehicleGroupQuery';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { REPLACE_VEHICLE_FOR_CONFIMED_BOOKING } from '../../../../../graphql/fleet/vehicleScheduler/replaceInProgressBookingVehicle';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { useNavigate } from 'react-router-dom';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Grid, FormControl, FormControlLabel, Radio, Typography, CircularProgress, Fab, RadioGroup, Autocomplete, TextField } from '@mui/material';
import { IBooking } from '../../../../../reducers/bookings/types';


interface IProps {
  scheduleDetails: IBookingSchedule;
  booking: IBooking;
  handleClose(): void;
  reloadScheduler?(): void;
}

enum VehicleGroupType {
  OWN = "OWN",
  OTHER = "OTHER"
}

interface IVg {
  id: string;
  name: string;
}

const initialVgValue: IVg = {
  id: "",
  name: ""
}

interface ISelectedVehicle {
  id: string;
  licencePlate: string;
}

const initialSelectedVehicleValue: ISelectedVehicle = {
  id: "",
  licencePlate: ""
}

const ReplaceVehicleForConfirmedBooking: React.FC<IProps> = ({ scheduleDetails, booking, handleClose, reloadScheduler }) => {
  const { id, startDate, endDate, vehicleGroup, vehicle } = scheduleDetails;
  const snackbar = useSnackBar();
  const [vehicleGroupType, setVehicleGroupType] = useState(VehicleGroupType.OWN);
  const [vehicleGroups, setVehicleGroups] = useState<IVehiclePriceGroup[]>([])
  const [ownVehicleGroup, setOwnVehicleGroup] = useState<IVehiclePriceGroup | null>();
  const [otherVehicleGroups, setOtherVehicleGroups] = useState<IVehiclePriceGroup[]>([])
  const [ownVehicles, setOwnVehicles] = useState<IVehicle[]>([])
  const [otherGroupVehicles, setOtherGroupVehicles] = useState<IVehicle[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<ISelectedVehicle>(initialSelectedVehicleValue)
  const [selectedOtherVehicleGroup, setSelectedOtherVehicleGroup] = useState<IVg>(initialVgValue);

  const [
    loadVehicleGroups,
    { loading: vehicleGroupsLoading, data: availableVehicleGroupsData }
  ] = useLazyQuery(GET_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "no-cache"
  });

  const [
    loadExternalVehicleGroups,
    { loading: externalVehicleGroupsLoading, data: externalAvailableVehicleGroupsData }
  ] = useLazyQuery(GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "no-cache"
  });

  const [
    loadVehicleGroup,
    { loading: vehicleGroupLoading, data: vehicleGroupData }
  ] = useLazyQuery(GET_VEHICLE_GROUP, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.vehicleGroup) {
        setOwnVehicleGroup(data.vehicleGroup)
      }
    }
  });

  const [replaceVirtualVehicle, { loading: replaceVirtualVehicleLoading }] = useMutation(REPLACE_VEHICLE_FOR_CONFIMED_BOOKING, {
    onCompleted: (data) => {
      snackbar({
        message: "Vehicle replaced successfully.",
        variant: SnackBarVariant.SUCCESS
      });
      if (reloadScheduler) {
        reloadScheduler();
      }
      handleClose();
    },
    onError: (error: ApolloError) => {
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  })

  useEffect(() => {
    setSelectedOtherVehicleGroup(initialVgValue)
    setSelectedVehicle(initialSelectedVehicleValue)
    setOwnVehicleGroup(null)
    loadVehicleGroup({
      variables: {
        id: vehicleGroup
      }
    })
    if (scheduleDetails) {
      loadVehicleGroups({
        variables: {
          args: {
            endDate: d.fromISO(endDate || "").toUTC().toISO(),
            startDate: d.fromISO(startDate || "").toUTC().toISO(),
            rateTypeDuration: booking?.rateTypeDuration,
            longTerm: booking?.longTermBooking,
            businessCustomer: booking?.businessCustomer?.id || "",
            bookingId: "",
            pcoNumber: booking?.pcoNumber,
            smartCarVehicle: booking?.smartCarVehicle,
            serviceLocation: booking?.pickupServiceLocation?.id
          }
        }
      });
      loadExternalVehicleGroups({
        variables: {
          args: {
            endDate: d.fromISO(endDate || "").toUTC().toISO(),
            startDate: d.fromISO(startDate || "").toUTC().toISO(),
            rateTypeDuration: booking?.rateTypeDuration,
            longTerm: booking.longTermBooking,
            businessCustomer: booking?.businessCustomer?.id || "",
            bookingId: "",
            pcoNumber: booking.pcoNumber,
            smartCarVehicle: booking.smartCarVehicle,
            serviceLocation: booking?.pickupServiceLocation?.id
          }
        }
      });
    }
  }, [scheduleDetails.id])

  useEffect(() => {
    if (availableVehicleGroupsData &&
      availableVehicleGroupsData.availableVehicleGroups &&
      externalAvailableVehicleGroupsData &&
      externalAvailableVehicleGroupsData.externalAvailableVehicleGroups
    ) {
      const availableVehicleGroups: any[] = []
      if (availableVehicleGroupsData && availableVehicleGroupsData.availableVehicleGroups.vehicleGroups.length > 0) {
        const vgArr = availableVehicleGroupsData.availableVehicleGroups.vehicleGroups
        for (let i = 0; i < vgArr.length; i++) {
          const vg = vgArr[i];
          const idx = availableVehicleGroups.findIndex((item) => item.id === vg.id);
          if (idx > -1) {
            vg.vehicles.forEach((vehicle: IVehicle) => {
              if (!availableVehicleGroups[idx].vehicles.some((v: any) => v.id === vehicle.id)) {
                availableVehicleGroups[idx].vehicles.push(vehicle)
              }
            })
          } else {
            availableVehicleGroups.push(vg)
          }
        }
      }
      if (
        externalAvailableVehicleGroupsData &&
        externalAvailableVehicleGroupsData.externalAvailableVehicleGroups
      ) {
        if (externalAvailableVehicleGroupsData.externalAvailableVehicleGroups.vehicleGroups.length > 0) {
          const eVgArr = externalAvailableVehicleGroupsData.externalAvailableVehicleGroups.vehicleGroups
          for (let i = 0; i < eVgArr.length; i++) {
            const eVg = eVgArr[i];
            const idx = availableVehicleGroups.findIndex((item: any) => item.id === eVg.id);
            if (idx > -1) {
              eVg.vehicles.forEach((vehicle: IVehicle) => {
                if (!availableVehicleGroups[idx].vehicles.some((v: any) => v.id === vehicle.id)) {
                  availableVehicleGroups[idx].vehicles.push({
                    ...vehicle,
                    shared: true
                  })
                }
              })
            } else {
              eVg.vehicles = eVg.vehicles.map((ele: IVehicle) => {
                return {
                  ...ele,
                  shared: true
                }
              })
              availableVehicleGroups.push(eVg)
            }
          }
        }
      }
      setVehicleGroups(availableVehicleGroups)
      const ownVg = availableVehicleGroups.find(vg => vg.id === vehicleGroup)
      if (ownVg) {
        const filteredVehicles = ownVg.vehicles.filter((vehicleObj: IVehicle) =>
          !vehicleObj.isGhostVehicle && String(vehicleObj.id) !== String(vehicle)
        );
        setOwnVehicles(filteredVehicles)
      }
      const filteredArr = availableVehicleGroups.filter(group => {
        return group.id !== vehicleGroup && group.vehicles.filter((vehicle: IVehicle) => !vehicle.isGhostVehicle).length > 0;
      });

      if (filteredArr) {
        setOtherVehicleGroups(filteredArr)
      }
    }
  }, [availableVehicleGroupsData, externalAvailableVehicleGroupsData])

  const handleSelectOwnVehicle = (selectedValue: IVehicle) => {
    setSelectedVehicle({
      id: selectedValue?.id || "",
      licencePlate: selectedValue?.licencePlate || ""
    });
    setSelectedOtherVehicleGroup(initialVgValue)
  }

  const handleSelectOtherVg = (selectedVg: IVehiclePriceGroup) => {
    setSelectedOtherVehicleGroup({
      id: selectedVg?.id ?? "",
      name: selectedVg?.name ?? ""
    });

    const filteredVehicles = selectedVg?.vehicles?.filter((vehicle: IVehicle) => !vehicle.isGhostVehicle) ?? [];
    setOtherGroupVehicles(filteredVehicles);

    setSelectedVehicle(initialSelectedVehicleValue);
  };


  const handleOthervehicle = (selectedVehicle: IVehicle) => {
    setSelectedVehicle({
      id: selectedVehicle?.id || "",
      licencePlate: selectedVehicle?.licencePlate || ""
    })
  }

  const handleVgTypeSelection = (value: any) => {
    setSelectedOtherVehicleGroup(initialVgValue)
    setSelectedVehicle(initialSelectedVehicleValue)
    setVehicleGroupType(value)
  }

  return (
    <>
      <Grid container sm={12}>
        <Grid item xs={12}>
          <FormControl>
            <RadioGroup
              row
              name="groupType"
              value={vehicleGroupType}
              onChange={(event: any) => {
                handleVgTypeSelection(event.target.value)
              }}
            >
              <FormControlLabel
                value={VehicleGroupType.OWN}
                control={<Radio />}
                label={<Typography variant="body1">Same vehicle group - ({ownVehicleGroup?.name})</Typography>}
              />
              <FormControlLabel
                value={VehicleGroupType.OTHER}
                control={<Radio />}
                label={<Typography variant="body1">Other vehicle group</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {vehicleGroupType === VehicleGroupType.OWN && (
          <>
            {vehicleGroupsLoading || externalVehicleGroupsLoading ? (
              <Grid item container xs={12} justifyContent='center'>
                <CircularProgress />
              </Grid>
            ) : ownVehicles.length > 0 ? (
              <Grid item container xs={12} spacing={2} style={{ padding: "20px" }}>
                <Grid item xs={12} sm={6} xl={6}>
                  <Autocomplete
                    id="combo-box-own-vehicle"
                    options={ownVehicles}
                    getOptionLabel={(option) => option.licencePlate.toUpperCase()}
                    onChange={(event: any, newValue: any) => handleSelectOwnVehicle(newValue)}
                    onInputChange={(event, value, reason) => {
                      if (reason === "clear") {
                        setSelectedVehicle(initialSelectedVehicleValue)
                      }
                      if ( reason === "reset") {
                        const matchedVehicle = ownVehicles.find((vehicle) => vehicle.licencePlate === value)
                        if (matchedVehicle && matchedVehicle?.id && matchedVehicle?.licencePlate) {
                          setSelectedVehicle({
                            id: matchedVehicle.id,
                            licencePlate: matchedVehicle.licencePlate
                          });
                        }
                      }
                    }}
                    style={{ width: '100%' }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Select vehicle"
                          variant="outlined"
                          value={selectedVehicle.licencePlate}
                          onChange={(e) => {
                            setSelectedVehicle({
                              id: "",
                              licencePlate: e.target.value
                            })
                          }}
                        />
                      )
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid item container xs={12} justifyContent="center">
                <Typography variant="h4" color="error">No vehicle found for replacement.</Typography>
              </Grid>
            )}
          </>
        )}

        {vehicleGroupType === VehicleGroupType.OTHER && (
          <>
            {vehicleGroupsLoading || externalVehicleGroupsLoading ? (
              <Grid item container xs={12} justifyContent='center'>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item container style={{ padding: "20px" }}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} sm={6} xl={6}>
                    {vehicleGroups && vehicleGroups.length ? (
                      <Autocomplete
                        id='combo-box-other-vehicle-group'
                        options={otherVehicleGroups}
                        getOptionLabel={(option) => option.name.toUpperCase()}
                        onChange={(event: any, newValue: any) => handleSelectOtherVg(newValue)}
                        onInputChange={(event, value, reason) => {
                          if (reason === "clear") {
                            setSelectedVehicle(initialSelectedVehicleValue);
                          }
                        }}
                        style={{ width: '100%' }}
                        renderInput={(params) => <TextField 
                          {...params} 
                          label="Select other vehicle group" 
                          variant="outlined" 
                          />
                        }
                      />
                    ) : (
                      <Typography variant="h4" color="error">No vehicle group found.</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} xl={6}>
                    {!vehicleGroupsLoading && !externalVehicleGroupsLoading ? (
                      <Autocomplete
                        id='combo-box-other-vehicle'
                        options={otherGroupVehicles}
                        getOptionLabel={(option) => option.licencePlate.toUpperCase()}
                        onChange={(event: any, newValue: any) => handleOthervehicle(newValue)}
                        onInputChange={(event: any, value: any, reason: any) => {
                          if (reason === "clear") {
                            setSelectedVehicle(initialSelectedVehicleValue)
                          }
                          if (reason === "reset") {
                            const selectedVehicleObj = otherGroupVehicles.find((vehicle) => vehicle.licencePlate === value)
                            if (selectedVehicleObj && selectedVehicleObj?.id && selectedVehicleObj?.licencePlate) {
                              setSelectedVehicle({
                                id: selectedVehicleObj.id,
                                licencePlate: selectedVehicleObj.licencePlate
                              });
                            }
                          }
                        }}
                        style={{ width: '100%' }}
                        renderInput={(params: any) => <TextField
                              {...params}
                              label="Select other vehicle"
                              variant="outlined"
                              placeholder="Search vehicle"
                              value={selectedVehicle.licencePlate}
                              onChange={(e) => {
                                setSelectedVehicle({ id: "", licencePlate: e.target.value })
                              }}
                            />
                          }
                      />
                    ) : (
                      <Typography variant="h4" color="error">No vehicle available in this group.</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12} sm={4} xl={4}>
          <Fab
            variant="extended"
            size="medium"
            aria-label="Create"
            disabled={!selectedVehicle?.licencePlate ? true : false}
            onClick={() => {
              const args = {
                bookingId: booking.id,
                bookingScheduleId: id,
                oldVehicleGroupId: ownVehicleGroup?.id,
                oldVehicleId: vehicle.id,
                newVehicleId: selectedVehicle?.id,
                newVehicleGroupId: selectedOtherVehicleGroup?.id
              }
              const isOwnVehicleGroupMissing = vehicleGroupType === VehicleGroupType.OWN && !ownVehicleGroup?.id;
              const isOtherVehicleGroupMissing = vehicleGroupType === VehicleGroupType.OTHER && !selectedOtherVehicleGroup?.id;

              if (isOwnVehicleGroupMissing || isOtherVehicleGroupMissing) {
                return snackbar({
                  message: "Vehicle group is not selected.",
                  variant: SnackBarVariant.ERROR
                });
              }

              if (!selectedVehicle?.id) {
                return snackbar({
                  message: "Vehicle is not selected.",
                  variant: SnackBarVariant.ERROR
                })
              }

              replaceVirtualVehicle({
                variables: {
                  replaceVehicleForConfirmedBookingInput: args
                }
              })
            }}
          >{replaceVirtualVehicleLoading &&
            <CircularProgress size={14} style={{ color: "white", margin: "20px" }} />
            }
            Assign
          </Fab>
        </Grid>
      </Grid >
    </>
  )
}

export default ReplaceVehicleForConfirmedBooking;