import { gql } from "@apollo/client";

export const UPDATE_ADDON_TYPE_STATUS = gql`
  mutation updateAddonTypeStatus($id: ID!, $isActivated: Boolean!) {
    updateAddonTypeStatus(id: $id, isActivated: $isActivated) {
      id
      name
      category
      displayName
      imageUrl
      hasFixedRate
      hasQuantity
      fixedRate
      description
      recurringRate {
        rateTypeName
        rateTypeDuration
        rate
        longTerm
      }
      totalQuantity
      availableQuantity
      isActivated
      rules {
        from
        to
        amount
      }
    }
  }
`;
