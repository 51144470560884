import { gql } from "@apollo/client";

export const CHANGE_PRICE_RULE_STATUS = gql`
  mutation changePriceRuleStatus($id: ID!, $isActive: Boolean!) {
    changePriceRuleStatus(id: $id, isActive: $isActive) {
      id
      isActive
    }
  }
`;
