import { gql } from "@apollo/client";

export const VERIFY_TELEMETRY_DEVICE = gql`
  query deviceExist($device: String!, $vehicleId: String!) {
    deviceExist(device: $device, vehicleId: $vehicleId) {
      exists
      details {
        manufacturer
        messages_ttl 
        protocol_id 
        device_type_id 
        id 
        media_ttl 
        name
        configuration {
          ident
          phone
          settings_polling
        }
        cid 
        media_rotate 
        messages_rotate
      }
    }
  }
`;