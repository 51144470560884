import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface IExternalVehicleBooking {
  bookingId: string;
  movementId: string;
}

const ExternalVehicleBookingCard = ({ data }: { data: IExternalVehicleBooking[] }) => {
  const navigate = useNavigate();
  const [bookingIds, setBookingIds] = useState<string[]>([]);
  useEffect(() => {
    if (data && data.length) {
      const bookingIds = data.map(d => d.bookingId);
      setBookingIds(bookingIds);
    }
  }, [data]);
  return (
    <div className={styles.remindersCard} style={{ backgroundColor: "#EFF4FE" }}>
      <Typography variant="h4">External Vehicle Bookings</Typography>
      {data && <>
        <div>
          <div style={{ cursor: data.length > 0 ? "pointer" : "not-allowed", marginTop: 20 }} onClick={() => {
            if (data.length) {
              navigate(`/bookings?day=today&type=externalVehicleBookings`, { state: bookingIds });
            }
          }}>
            <Typography variant="body1">Today's Total</Typography>
            <Typography variant="h2" className="bold" style={{ color: "#167AD6" }}>{data ? data.length : "-"}</Typography>
          </div>
        </div>
      </>}
    </div>
  );
}

export default ExternalVehicleBookingCard

