import { gql } from "@apollo/client";

export const UPDATE_VEHICLE_SUBSCRIPTION_PRICINGS = gql`
  mutation updateVehicleSubscriptionPricing(
    $vehicleId: ID!
    $subscriptionId: ID!
    $pricings: [SubscriptionPricingInput]
    $deposit: Float
  ) {
    updateVehicleSubscriptionPricing(
      vehicleId: $vehicleId
      subscriptionId: $subscriptionId
      pricings: $pricings
      deposit: $deposit
    ) {
      id
    }
  }
`;


export const REMOVE_VEHICLE_SUBSCRIPTION_PRICING = gql`
  mutation removeVehicleSubscriptionPricing($vehicleId: ID!, $subscriptionId: ID!) {
    removeVehicleSubscriptionPricing(vehicleId: $vehicleId, subscriptionId: $subscriptionId) {
      id
    }
  }
`;
