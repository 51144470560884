import { gql } from "@apollo/client";

export const ADD_DRIVERS_TO_BOOKING = gql`
  mutation addDriversToBooking($bookingId: String! $drivers:[String!]!) {
    addDriversToBooking(bookingId: $bookingId,drivers:$drivers) {
      id
      rentalAgreementUrl
      approvedDrivers {
        id
        firstName
        lastName
        email
        license {
          authority
          licenseNumber
          dateOfExpiry
          images
        }
        phoneNumber {
          phone
          country
        }
        location{
          state
          city
          country
          fullAddress
          zipcode
          street
        }
      }
    }
  }
`;

export const REMOVE_DRIVERS_FROM_BOOKING = gql`
  mutation removeDriverFromBooking($bookingId: String! $driverId:String!) {
    removeDriverFromBooking(bookingId: $bookingId,driverId:$driverId) {
      id
      rentalAgreementUrl
      approvedDrivers {
        id
        firstName
        lastName
        email
        license {
          authority
          licenseNumber
          dateOfExpiry
          images
        }
        phoneNumber {
          phone
          country
        }
      }
    }
  }
`;