import {
  Dialog,
  DialogContent,
  Typography,
  Theme,
  Grid,
  Fab,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { WithStyles, withStyles, createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface IDialogProps {
  open: boolean;
  saveInProgress: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    }
  });


const useStyles = makeStyles(() =>
  createStyles({
    h3: {
      padding: 0,
      margin: 0,
      color: "red"
    },
    p: {
      padding: 0,
      margin: 0,
      fontSize: 15,
      lineHeight: 1.5
    },
    li: {
      fontSize: 14,
      marginBottom: 6
    }
  })
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const VehicleTransferDialog: React.FC<IDialogProps> = (props) => {
  const classes = useStyles();
  const { open, handleClose, handleSubmit, saveInProgress } = props;
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);

  useEffect(() => {
    if (!open) {
      setCheckboxValue(false);
    }
  }, [open]);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <React.Fragment>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p style={{ fontSize: 18, color: "var(--theme-accent)", padding: 0, margin: 0, textAlign: "center" }}>
            Vehicle Transfer Alert
          </p>
        </DialogTitle>
        <DialogContent dividers style={{ paddingTop: 2 }}>
          <Grid container>
            <p className={classes.p}>
              You are about to transfer the selected vehicle(s) ownership to another branch. We want to inform you about what will change/happen after selecting the checkbox and clicking on Confirm button.
            </p>
            <div style={{ marginTop: 10 }}>
              <span style={{ fontSize: 14 }}> What will happen: </span>
              <ul>
                <li className={classes.li}>
                  The data/information that will move from source to destination branch would be vehicle information, vehicle state, and any vehicle-specific document uploaded in the past.
                </li>
                <li className={classes.li}>
                  The destination branch will show open vehicle damage(s) (if any) against a vehicle. The service you create against the respective vehicle will include the details to get them fixed via the service you will make.
                </li>
                <li className={classes.li}>
                  Once you click on Confirm button, any existing future services for these vehicles in the source branch will be cancelled, closed, and not reflected in the system. Later on, you need to create new services(one-time or recurring) in the destination branch. Services if any are in progress then Vehicle transfer will not happen. Transfer can be initiated once service gets complete.
                </li>
                <li className={classes.li}>
                  You can not transfer vehicle(s) with existing confirmed/in-progress/future booking(s). If you want to make such a transfer, complete/cancel such booking(s) in source branch to initiate the transfer.
                </li>
                <li className={classes.li}>
                  You can not transfer the previous booking/invoice/payments records to the destination branch. They will remain in your current branch. You can still go ahead and create sundry invoices against old booking and take payments etc.
                </li>
                <li className={classes.li}>
                  Once vehicle(s) transfer is initiated, future booking will not be allowed in the source branch for such vehicle(s). You can create booking in the destination branch once the moving process is complete.
                </li>
                <li className={classes.li}>
                  Once the vehicle transfer is completed, the vehicle will move away from the vehicle group of the source branch. It will get associated with the applicable vehicle group in the destination branch.
                </li>
                <li className={classes.li}>
                  A deactivated vehicle is not eligible for vehicle transfer and will not show in the list.
                </li>
              </ul>
            </div>
            <FormControlLabel
              control={<Checkbox
                value={checkboxValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCheckboxValue(event.target.checked);
                }}
              />}
              label={"By selecting the checkbox and clicking confirm, you agree that you have read and agree with the above mentioned points."}
            />
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Fab
                variant="extended"
                size="medium"
                aria-label="submit"
                onClick={() => {
                  handleSubmit()
                }}
                disabled={!checkboxValue}
              >
                {saveInProgress ? <CircularProgress /> : "Confirm"}
              </Fab>
            </Grid>
          </Grid>
        </DialogContent>
      </React.Fragment>
    </Dialog >
  )
}
