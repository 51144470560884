import { Snackbar, ThemeProvider } from "@mui/material";
import React, { SyntheticEvent, useEffect } from "react";
import theme from "../../common/Theme";

import {
  SnackBarVariant,
  SnackbarWrapper
} from "../SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../utils";

interface IProps {
  message: string | undefined;
  variant: SnackBarVariant;
}

export const SnackBar = (props: IProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const message = props.message;
  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  return (
      <ThemeProvider theme={theme} >
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top"
          }}
          open={open}
          onClose={() => handleClose}
        >
          <div>
            <SnackbarWrapper
              onClose={handleClose}
              variant={props.variant}
              className={props.variant}
              message={props.message && formatGraphQLErrorMessage(props.message)}
            />
          </div>
        </Snackbar>
      </ThemeProvider>
  );
};
