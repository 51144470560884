import {
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    cardStyle: {
      padding: "5px",
      height: "162px",
      minWidth: "calc(100vw - 60px)",
      maxWidth: "calc(100vw - 60px)",
      borderRadius: "12px",

    },
    itemPosition: {
      paddingBottom: "3px"
    }

  }));

export const MobileBusinessCustomerCard = ({ id, businessName, contactName, contactEmail, contactNumber, VAT }: {
  id: string, businessName: string, contactName?: string, contactEmail?: string, contactNumber?: string, VAT: string
}) => {
  const classes = useStyle();
  const navigate = useNavigate();

  function routeTo() {
    if (id) {
      navigate(`/update-business-customer?businesscustomer=${id}`);
    }
  }

  return (
    <ButtonBase onClick={() => routeTo()}>
      <Card className={classes.cardStyle}>
        <CardContent>
          <Grid container item xs={12}>
            <Grid className={classes.itemPosition} item xs={12}>
              <Typography style={{ fontWeight: "bold", fontSize: "20px", paddingBottom: "2px" }}>{businessName}</Typography>
            </Grid>
            <Grid className={classes.itemPosition} item xs={12}>
              <Typography>{contactName}</Typography>
            </Grid>
            <Grid className={classes.itemPosition} item xs={12}>
              <Typography>{contactEmail}</Typography>
            </Grid>
            <Grid className={classes.itemPosition} item xs={12}>
              <Typography>{contactNumber}</Typography>
            </Grid>
            <Grid className={classes.itemPosition} item xs={12}>
              <Typography>{VAT}</Typography>
            </Grid>
          </Grid>

        </CardContent>
      </Card>
    </ButtonBase>
  );
};

export default MobileBusinessCustomerCard;