import { InvoicesPayloadType } from "../../reducers/invoices/types";
import { InvoicesAction, UPDATE_INVOICE } from "./types";

export function updateInvoiceAction(
  payload: InvoicesPayloadType
): InvoicesAction {
  return {
    payload,
    type: UPDATE_INVOICE
  };
}
