import { useMutation } from "@apollo/client";
import {
  Fab,
  Grid,
  IconButton,
  Typography,
  Hidden,
  FormControl,
  CircularProgress,
  Paper
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { setBusinessCustomer } from "../../../../../../actions/customer/actions";
import {
  ADD_BUSINESS_CUSTOMER_DOCUMENTS,
  DELETE_BUSINESS_CUSTOMER_DOCUMENTS
} from "../../../../../../graphql/businessCustomers/businessCustomerDocuments";
import {
  IBusinessCustomer,
  IBusinessCustomerDocumentInput
} from "../../../../../../reducers/customer/types";
import { IAppState } from "../../../../../../store";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import FlatPickerBar from "../../../../../common/FlatPicker";
import {
  checkDeleteOrDownloadFileType,
  checkUploadFileFormat,
  formatGraphQLErrorMessage,
  uploadFileExtensionAndContentType,
  returnSortedDocuments, DATE_TYPE, isImageFileType
} from "../../../../../common/utils";
import { DocumentDialog } from "../../../CustomerDetails/DocumentDialog";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { UserRoles } from "../../../../../hoc/Authorization";
import { ConfirmationDialog } from "../../../../../common/ConfirmationDialog/ConfirmationDialog";
import { getSignedUrl } from '../../../../../../utils/getSignedUrl';
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { IcoiDetails } from "../../../../../../reducers/bookings/types";
import { DateTime as d } from "luxon";
import { FilePicker } from "../../../../../common/FilePicker/FilePicker";
import * as Yup from "yup";
import { ADD_BUSINESS_CUSTOMER_COI_DETAILS } from "../../../../../../graphql/businessCustomers/addBusinessCustomerCoiDetails";
import { captureErrorException } from "../../../../../../utils/sentry";

interface IViewDocumentsProps {
  isOpen: boolean;
  deleteInProgress: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  businessCustomerDocuments: IBusinessCustomerDocumentInput[];
  downloadDocument(
    url: string, name: string
  ): void;
  deleteDocument(key: any, url: string): void;
}

export const Documents = React.memo(() => {
  // Get tenancy details
  const userState = useSelector((state: IAppState) => state.userReducer);
  // Fetch css classes
  const snackbar = useSnackBar();
  const dispatch = useDispatch();
  const businessCustomer: IBusinessCustomer = useSelector(
    (state: IAppState) => state.customerReducer.businessCustomer
  );
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [documentDialogVisible, setDocumentDialogVisible] = React.useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);

  const [addBusinessCustomerDocument] = useMutation(
    ADD_BUSINESS_CUSTOMER_DOCUMENTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        snackbar({
          message: "Business customer document added.",
          variant: SnackBarVariant.SUCCESS
        });
        dispatch(setBusinessCustomer(data.addBusinessCustomerDocument));
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const [deleteBusinessCustomerDocument] = useMutation(
    DELETE_BUSINESS_CUSTOMER_DOCUMENTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        snackbar({
          message: "Business customer document deleted.",
          variant: SnackBarVariant.SUCCESS
        });
        dispatch(setBusinessCustomer(data.deleteBusinessCustomerDocument));
        setDeleteInProgress(false);
        handleDialogClose();
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  // Download the document from s3
  async function downloadDocument(documentUrl: string, name: string) {
    if (!documentUrl) {
      return;
    }
    const { fileExtension } = checkDeleteOrDownloadFileType(documentUrl);
    const file = await getSignedUrl(documentUrl)
    const url: any = file;
    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}.${fileExtension}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  // Upload the document to s3
  async function uploadDocument(
    file: File,
    title: string,
    documentType: string,
    reminderBefore: number,
    expiryDate: string | undefined,
  ) {
    try {
      if (!userState.tenancy?.id) {
        return;
      }
      // 50MB limit exceeded
      if (file.type === "application/pdf" && file.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      if (isImageFileType(file.type) && file.size > 5242880) {
        throw new Error("File size exceeded limit of 5MB");
      }
      const fileName = file.name.split(".")[0];
      setUploadInProgress(true);
      if (checkUploadFileFormat(file.type)) {
        const { fileExtension } = uploadFileExtensionAndContentType(
          file.type
        );
        const uniqueId = uuidv4();
        const key = `${userState.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                const doc = {
                  name: fileName,
                  url: key,
                  title,
                  documentType,
                  reminderBefore,
                  expiryDate
                };
                if (businessCustomer && businessCustomer.id) {
                  addBusinessCustomerDocument({
                    variables: {
                      businessCustomerId: businessCustomer.id,
                      businessDocument: doc
                    }
                  });
                }
                snackbar({
                  message: "Document Uploaded Successfully",
                  variant: SnackBarVariant.SUCCESS
                });
                setDocumentDialogVisible(false);
                setUploadInProgress(false);
              }
            });
        }
      } else {
        setDocumentDialogVisible(false);
        setUploadInProgress(false);
        return snackbar({
          message: "Please only upload PDF, PNG, JPG or JPEG files",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  // Remove the document
  function deleteDocument(id: string, url: string) {
    const { contentType } = checkDeleteOrDownloadFileType(url);
    setDeleteInProgress(true);
    const key = url;
    // TODO: implement file deleteion from s3
    deleteBusinessCustomerDocument({
      variables: {
        businessCustomerId: businessCustomer.id,
        businessDocumentId: id
      }
    });
  }

  const handleDialogOpen = () => {
    setOpenDeleteDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDeleteDialog(false);
  }


  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
          <Typography variant="h2">ADD BUSINESS CUSTOMER DOCUMENTS</Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Fab
              className="blackBackButton"
              variant="extended"
              size="medium"
              aria-label="Update"
              onClick={() => {
                setDocumentDialogVisible(true);
              }}
            >
              ADD DOCUMENT
            </Fab>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid container xs={6} sm={6} md={2} lg={2} xl={2}>
            <Fab
              className="blackBackButton"
              variant="extended"
              size="medium"
              aria-label="Update"
              onClick={() => {
                setDocumentDialogVisible(true);
              }}
            >
              ADD DOCUMENT
            </Fab>
          </Grid>
        </Hidden>
        <div>
          <DocumentDialog
            open={documentDialogVisible}
            handleClose={() => setDocumentDialogVisible(false)}
            uploadInProgress={uploadInProgress}
            onSubmit={uploadDocument}
            accept="image/jpg, image/jpeg, image/png, application/pdf"
          />
        </div>
        <ViewDocuments
          isOpen={openDeleteDialog}
          deleteInProgress={deleteInProgress}
          handleOpen={handleDialogOpen}
          handleClose={handleDialogClose}
          businessCustomerDocuments={businessCustomer.documents}
          downloadDocument={downloadDocument}
          deleteDocument={deleteDocument}
        />
        <AddCoiDetails />
      </Grid>
    </React.Fragment>
  );
});

const AddCoiDetails = () => {
  const businessCustomer: IBusinessCustomer = useSelector(
    (state: IAppState) => state.customerReducer.businessCustomer
  );
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const [coiDetails, setcoiDetails] = useState<IcoiDetails>({
    policyName: businessCustomer.coiDetails?.policyName || "",
    policyNumber: businessCustomer.coiDetails?.policyNumber || "",
    provider: businessCustomer.coiDetails?.provider || "",
    expiryDate: businessCustomer.coiDetails?.expiryDate || "",
    documentUrl: businessCustomer.coiDetails?.documentUrl || ""
  });
  const [fileValue, setFileValue] = useState<File | undefined>(undefined);
  const [coiDetailsAvailable, setCoiDetailsAvailable] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [saveUploadInProgress, setSaveUploadInProgress] = useState<boolean>(false);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const snackbar = useSnackBar();
  const [addBusinessCustomerCoiDetails] = useMutation(
    ADD_BUSINESS_CUSTOMER_COI_DETAILS,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        snackbar({
          message: "Business customer Coi Details added.",
          variant: SnackBarVariant.SUCCESS
        });
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  async function downloadDocument(documentUrl: string, name: string) {
    if (!documentUrl) {
      return;
    }
    const { fileExtension } = checkDeleteOrDownloadFileType(documentUrl);
    const file = await getSignedUrl(documentUrl)
    const url: any = file;
    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}.${fileExtension}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  const handleDocumentSave = async () => {
    if (!fileValue) {
      return snackbar({
        message: "Please upload policy document.",
        variant: SnackBarVariant.ERROR
      });
    }
    try {
      // 50MB limit exceeded
      if (fileValue.type === "application/pdf" && fileValue?.size && fileValue?.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      if (isImageFileType(fileValue.type) && fileValue?.size && fileValue?.size > 5242880) {
        throw new Error("File size exceeded limit of 5MB");
      }
      setUploadInProgress(true);
      setCoiDetailsAvailable(true);
      setSaveUploadInProgress(true);
      if (fileValue && checkUploadFileFormat(fileValue.type)) {
        const uniqueId = uuidv4();
        const {
          fileExtension
        } = uploadFileExtensionAndContentType(fileValue.type);
        const key = `${userState.tenancy.id}/${uniqueId}-insurance-policy-document.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: fileValue,
              complete: async () => {
                setcoiDetails({
                  ...coiDetails,
                  documentUrl: key
                })
                snackbar({
                  message: "Document uploaded successfully.",
                  variant: SnackBarVariant.SUCCESS
                });
              }
            });
          setUploadInProgress(false);
          setSaveUploadInProgress(false);
        }
      } else {
        setUploadInProgress(false);
        setCoiDetailsAvailable(false);
        return snackbar({
          message: "Please only upload .pdf!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  const onSelectDocument = (event: any) => {
    const file = event.target.files[0];
    if (!checkUploadFileFormat(file.type)) {
      return snackbar({
        message: "Invalid File Format. Supported formats : PDF, PNG, JPG or JPEG",
        variant: SnackBarVariant.ERROR
      });
    }
    else {
      setFileValue(file);
    }
  }

  useEffect(() => {
    if (fileValue) {
      handleDocumentSave();
    }
  }, [fileValue])

  const detailsFormSchema = Yup.object().shape({
    name: Yup.string()
      .min(1)
      .required("Policy name is required.")
  });

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={detailsFormSchema}
        initialValues={coiDetails}
        onSubmit={() => {
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h2" style={{ marginTop: '20px', marginBottom: '20px' }}>
            ADD COI DETAILS
          </Typography>
          <Form>
            <Grid container xs={12} spacing={2}>
              <Grid item container xs={3} sm={2} >
                <FormControl variant="outlined" fullWidth>
                  <Field
                    component={TextField}
                    placeholder="Policy Name"
                    label="Policy Name"
                    name={"policyName"}
                    value={coiDetails?.policyName}
                    onChange={(e: any) => setcoiDetails({ ...coiDetails, policyName: e.target.value })}
                    fullWidth
                    required
                  ></Field>
                </FormControl>
              </Grid>
              <Grid item container xs={3} sm={2}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    component={TextField}
                    placeholder="Provider"
                    label="Provider"
                    name={"provider"}
                    value={coiDetails?.provider}
                    onChange={(e: any) => setcoiDetails({ ...coiDetails, provider: e.target.value })}
                    fullWidth
                    required
                  ></Field>
                </FormControl>
              </Grid>
              <Grid item container xs={3} sm={2}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    component={TextField}
                    placeholder="Policy Number"
                    label="Policy Number"
                    name={"policyNumber"}
                    value={coiDetails?.policyNumber}
                    onChange={(e: any) => setcoiDetails({ ...coiDetails, policyNumber: e.target.value })}
                    fullWidth
                    required
                  ></Field>
                </FormControl>
              </Grid>
              <Grid item container xs={3} sm={2}>
                <FormControl variant="outlined" fullWidth>
                  <FlatPickerBar
                    enableTime={false}
                    handleDateChange={(value: Date) => {
                      setcoiDetails({
                        ...coiDetails,
                        expiryDate: d.fromJSDate(value).toUTC().toISO()
                      })
                    }}
                    label={"Expiry Date"}
                    identifier={"dateOfExpiry"}
                    placeholderValue={"Document Expiry Date"}
                    value={getLocalizedDateFormat(country, coiDetails?.expiryDate, DATE_TYPE.CONDENSED)}
                    minDate={getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}
                    required
                    country={country}
                  />
                </FormControl>
              </Grid>
              <Grid item >
                <FilePicker
                  onFilePick={(e: any) => {
                    onSelectDocument(e);
                  }}
                  title="Upload Policy Document"
                  accept="image/jpg, image/jpeg, image/png, application/pdf"
                />
                {uploadInProgress && (
                  <CircularProgress
                    size={14}
                    style={{ color: "white", marginLeft: "10px" }}
                  />
                )}
              </Grid>
              <Grid item>
                {coiDetails.documentUrl ? (
                  <>

                    <Fab
                      variant="extended"
                      size="small"
                      type="submit"
                      onClick={() => {
                        downloadDocument(coiDetails.documentUrl, "COI_POLICY")
                      }}
                    >
                      Download
                    </Fab>
                  </>) : (
                  <>
                    {businessCustomer.coiDetails?.documentUrl && (
                      <Fab
                        variant="extended"
                        size="small"
                        type="submit"
                        onClick={() => {
                          downloadDocument(businessCustomer.coiDetails?.documentUrl ? businessCustomer.coiDetails.documentUrl : "", "COI_POLICY")
                        }}
                      >
                        Download
                      </Fab>
                    )}
                  </>
                )}

              </Grid>
              <Grid item container xs={12}>
                <Fab
                  variant="extended"
                  size="medium"
                  type="submit"
                  onClick={() => {
                    if (!coiDetails.documentUrl) {
                      snackbar({
                        message: "Please upload policy document.",
                        variant: SnackBarVariant.ERROR
                      });
                    } else {
                      addBusinessCustomerCoiDetails({
                        variables: {
                          businessCustomerId: businessCustomer.id,
                          coiDetails
                        }
                      })
                    }
                  }}
                  disabled={saveUploadInProgress || !coiDetails.policyName || !coiDetails.provider || !coiDetails.policyNumber || !coiDetails.expiryDate}
                >
                  {saveUploadInProgress && (
                    <CircularProgress
                      size={14}
                      style={{ color: "white", marginRight: "10px" }}
                    />
                  )}
                  Save
                </Fab>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Formik>
    </React.Fragment>
  )
}

const ViewDocuments = (props: IViewDocumentsProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [deleteDocumentUrl, setDeleteDocumentUrl] = useState<string>("");
  const [documentId, setDocumentId] = useState<string>("");

  const columns = [
    {
      label: "Document Type",
      name: "documentType"
    },
    {
      label: "Document Name/Title",
      name: "title"
    },
    {
      label: "Expiry Date",
      name: "expiryDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED) : "N/A"
      }
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <React.Fragment>
              <div>
                <IconButton
                  edge="end"
                  aria-label="download"
                  onClick={() =>
                    props.downloadDocument(value.url, value.name)
                  }
                  size="large">
                  <CloudDownloadIcon />
                </IconButton>
                {(userState.role === UserRoles.SUPER_ADMIN || userState.role === UserRoles.ADMIN) && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      setDeleteDocumentUrl(value.url);
                      setDocumentId(value.id);
                      props.handleOpen();
                    }}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </React.Fragment>
          );
        }
      }
    }
  ];

  const getRows = (customerDocuments: IBusinessCustomerDocumentInput[]) => {
    if (!customerDocuments || customerDocuments === null) {
      return [];
    }
    const docs = [...customerDocuments]
    const sortedDocuments = returnSortedDocuments(docs);
    if (sortedDocuments && sortedDocuments.length > 0) {
      return sortedDocuments.map((customerDocument: IBusinessCustomerDocumentInput) => {
        return {
          ...customerDocument,
          actions: { id: customerDocument.id, url: customerDocument.url, name: customerDocument.name },
          title: customerDocument.title || customerDocument.name,
          expiryDate: customerDocument.expiryDate,
          documentType: customerDocument.documentType
        };
      });
    } else {
      return []
    }

  };

  return (
    <Grid item xs={12}>
      <Paper variant="outlined">
        <NuvvenTable
          key={new Date().getMilliseconds()}
          title={""}
          rows={getRows(props.businessCustomerDocuments)}
          columns={columns}
          setSelection={() => { }}
          options={{
            selectableRows: "none",
            download: false,
            filter: false,
            print: false,
            search: false,
            viewColumns: false,
            responsive: "vertical",
            elevation: 0
          }}
        />
      </Paper>
      {props.isOpen && (
        <ConfirmationDialog
          isOpen={props.isOpen}
          onCancel={props.handleClose}
          onConfirm={() => {
            props.deleteDocument(documentId, deleteDocumentUrl);
          }}
          isInProgress={props.deleteInProgress}
          title=""
          description={"Are you sure, you want to delete this document?"}
        />
      )}
    </Grid>
  );
};


