import React from "react";

export const flatbedTrailerSvg = {
  interior: {
    width: 174.6,
    height: 500.9,
    svg: (
      <>
        <rect
          x="16.3"
          y="11.5"
          width="142.5"
          height="332.9"
          fill="none"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
      </>
    )
  },
  exterior: {
    width: 359.2,
    height: 535.4,
    svg: (
      <>
        <g id="Layer_1" data-name="Layer 1">
          <g>
            <line
              x1="27"
              y1="378"
              x2="27"
              y2="381.2"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <line
              x1="27.2"
              y1="341.7"
              x2="27.2"
              y2="344.9"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <polyline
              points="36.7 411.6 44.9 418.2 44.9 304.8 35.7 311.9"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <path
              d="M36.2,411.3"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <path
              d="M27,403.8"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="-117.4"
              y="278.1"
              width="332.9"
              height="7.6"
              transform="translate(-232.8 330.9) rotate(-90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="31.7"
              y="434.7"
              width="9.6"
              height="17.5"
              transform="translate(-407 480) rotate(-90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="66"
              y="102.2"
              width="5.4"
              height="31.8"
              transform="translate(-49.4 186.9) rotate(-90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <g>
              <circle
                cx="27.2"
                cy="397.8"
                r="16.1"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <circle
                cx="27.2"
                cy="397.8"
                r="6"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <g>
              <circle
                cx="26.9"
                cy="361.4"
                r="16.1"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <circle
                cx="26.9"
                cy="361.4"
                r="6"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <g>
              <circle
                cx="27.2"
                cy="325.8"
                r="16.1"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <circle
                cx="27.2"
                cy="325.8"
                r="6"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <rect
              x="1.8"
              y="245.6"
              width="79.3"
              height="7.8"
              transform="translate(-208 291) rotate(-90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="-7.3"
              y="244.1"
              width="79.3"
              height="10.8"
              transform="translate(-217.1 281.9) rotate(-90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <g>
              <g>
                <g>
                  <line
                    x1="44.8"
                    y1="173.9"
                    x2="28.8"
                    y2="173.9"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="44.9"
                    y1="175.4"
                    x2="32.8"
                    y2="175.4"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="32.9"
                    y1="175.4"
                    x2="31.1"
                    y2="173.9"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="29"
                    y1="173.9"
                    x2="27.1"
                    y2="175.4"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="27.2"
                    y1="175.2"
                    x2="27.2"
                    y2="176.7"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="27.4"
                    y1="176.5"
                    x2="25.5"
                    y2="176.5"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="25.4"
                    y1="176.7"
                    x2="25.4"
                    y2="171.8"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="28.9"
                    y1="173.9"
                    x2="28.9"
                    y2="171.6"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="31.3"
                    y1="173.9"
                    x2="31.3"
                    y2="171.6"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                </g>
                <g>
                  <line
                    x1="44.8"
                    y1="169.6"
                    x2="28.8"
                    y2="169.6"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="44.9"
                    y1="168.2"
                    x2="32.8"
                    y2="168.2"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="32.9"
                    y1="168.1"
                    x2="31.1"
                    y2="169.6"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="29"
                    y1="169.7"
                    x2="27.1"
                    y2="168.2"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="27.2"
                    y1="168.3"
                    x2="27.2"
                    y2="166.8"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="27.4"
                    y1="167.1"
                    x2="25.5"
                    y2="167.1"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="25.4"
                    y1="166.8"
                    x2="25.4"
                    y2="171.9"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="28.9"
                    y1="169.6"
                    x2="28.9"
                    y2="172"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="31.3"
                    y1="169.6"
                    x2="31.3"
                    y2="172"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                </g>
              </g>
              <line
                x1="43.7"
                y1="175.4"
                x2="44.9"
                y2="179.2"
                fill="none"
                stroke="#010101"
                stroke-miterlimit="10"
                stroke-width=".5"
              />
              <line
                x1="43.7"
                y1="168.1"
                x2="44.9"
                y2="164.2"
                fill="none"
                stroke="#010101"
                stroke-miterlimit="10"
                stroke-width=".5"
              />
            </g>
          </g>
          <g>
            <line
              x1="326.4"
              y1="378"
              x2="326.4"
              y2="381.2"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <line
              x1="326.1"
              y1="341.7"
              x2="326.1"
              y2="344.9"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <polyline
              points="316.6 411.6 308.4 418.2 308.4 304.8 317.6 311.9"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <path
              d="M317.1,411.3"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <path
              d="M326.4,403.8"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="137.8"
              y="278.1"
              width="332.9"
              height="7.6"
              transform="translate(22.4 586.1) rotate(-90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="312"
              y="434.7"
              width="9.6"
              height="17.5"
              transform="translate(-126.7 760.3) rotate(-90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="281.8"
              y="102.2"
              width="5.4"
              height="31.8"
              transform="translate(166.4 402.7) rotate(-90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <g>
              <circle
                cx="326.1"
                cy="397.8"
                r="16.1"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <circle
                cx="326.1"
                cy="397.8"
                r="6"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <g>
              <circle
                cx="326.4"
                cy="361.4"
                r="16.1"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <path
                d="M326.4,355.3c3.3,0,6,2.7,6,6,0,3.3-2.7,6-6,6-3.3,0-6-2.7-6-6s2.7-6,6-6Z"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <g>
              <circle
                cx="326.1"
                cy="325.8"
                r="16.1"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
              <path
                d="M326.1,319.7c3.3,0,6,2.7,6,6,0,3.3-2.7,6-6,6s-6-2.7-6-6c0-3.3,2.7-6,6-6Z"
                fill="none"
                stroke="#231f20"
                stroke-miterlimit="10"
              />
            </g>
            <rect
              x="272.1"
              y="245.6"
              width="79.3"
              height="7.8"
              transform="translate(62.3 561.3) rotate(-90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <rect
              x="281.2"
              y="244.1"
              width="79.3"
              height="10.8"
              transform="translate(71.4 570.4) rotate(-90)"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <g>
              <g>
                <g>
                  <line
                    x1="308.5"
                    y1="173.9"
                    x2="324.5"
                    y2="173.9"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="308.4"
                    y1="175.4"
                    x2="320.5"
                    y2="175.4"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="320.4"
                    y1="175.4"
                    x2="322.2"
                    y2="173.9"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="324.3"
                    y1="173.9"
                    x2="326.2"
                    y2="175.4"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="326.1"
                    y1="175.2"
                    x2="326.1"
                    y2="176.7"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="325.9"
                    y1="176.5"
                    x2="327.8"
                    y2="176.5"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="327.9"
                    y1="176.7"
                    x2="327.9"
                    y2="171.8"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="324.4"
                    y1="173.9"
                    x2="324.4"
                    y2="171.6"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="322"
                    y1="173.9"
                    x2="322"
                    y2="171.6"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                </g>
                <g>
                  <line
                    x1="308.5"
                    y1="169.6"
                    x2="324.5"
                    y2="169.6"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="308.4"
                    y1="168.2"
                    x2="320.5"
                    y2="168.2"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="320.4"
                    y1="168.1"
                    x2="322.2"
                    y2="169.6"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="324.3"
                    y1="169.7"
                    x2="326.2"
                    y2="168.2"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="326.1"
                    y1="168.3"
                    x2="326.1"
                    y2="166.8"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="325.9"
                    y1="167.1"
                    x2="327.8"
                    y2="167.1"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="327.9"
                    y1="166.8"
                    x2="327.9"
                    y2="171.9"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="324.4"
                    y1="169.6"
                    x2="324.4"
                    y2="172"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                  <line
                    x1="322"
                    y1="169.6"
                    x2="322"
                    y2="172"
                    fill="none"
                    stroke="#010101"
                    stroke-miterlimit="10"
                    stroke-width=".5"
                  />
                </g>
              </g>
              <line
                x1="309.6"
                y1="175.4"
                x2="308.4"
                y2="179.2"
                fill="none"
                stroke="#010101"
                stroke-miterlimit="10"
                stroke-width=".5"
              />
              <line
                x1="309.6"
                y1="168.1"
                x2="308.4"
                y2="164.2"
                fill="none"
                stroke="#010101"
                stroke-miterlimit="10"
                stroke-width=".5"
              />
            </g>
          </g>
        </g>
        <g id="Layer_2" data-name="Layer 2">
          <rect
            x="105.2"
            y="115.4"
            width="142.5"
            height="332.9"
            fill="none"
            stroke="#231f20"
            stroke-miterlimit="10"
          />
          <g>
            <rect
              x="108.8"
              y="10.4"
              width="144.1"
              height="55.8"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <path
              d="M125.2,66.2v16.4c0,4.1-3.3,7.4-7.4,7.4h-1.5c-4.1,0-7.4-3.3-7.4-7.4v-16.4"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
            <path
              d="M252.9,66.2v16.4c0,4.1-3.3,7.4-7.4,7.4h-1.5c-4.1,0-7.4-3.3-7.4-7.4v-16.4"
              fill="none"
              stroke="#231f20"
              stroke-miterlimit="10"
            />
          </g>
          <rect
            x="105.2"
            y="479.6"
            width="142.5"
            height="12.9"
            fill="none"
            stroke="#231f20"
            stroke-miterlimit="10"
          />
          <polygon
            points="247 517.7 105.2 517.7 105.2 502.8 157.2 502.8 157.3 492.5 196.4 492.5 196.4 502.8 247 502.8 247 517.7"
            fill="none"
            stroke="#231f20"
            stroke-miterlimit="10"
          />
        </g>
      </>
    )
  }
};
