import { gql } from "@apollo/client";

export const GET_VEHICLE_DAMAGE = gql`
  query damageVehicle($damageId: String!) {
    damageVehicle(damageId: $damageId) {
      id
      vehicleId
      branchId
      circumstance
      bookingRef
      reportDate
      incidentDate
      reportedBy
      approvalStatus
      frogDiagram
      status
      referenceNumber
      damageSeverity
      estimatedRepairDate
      estimatedCost
      licencePlate
      bookingReferenceNumber
      damages {
        title
        descriptions
        damageType
        damageKind
        damageArea
        images
        location {
          x
          y
        }
      }
    }
  }
`;
