import { ISubscriptionInput } from "./types";

export const subscriptionInitialState: ISubscriptionInput = {
  addons: [],
  features: [],
  id: "",
  rateTypeName: "monthly",
  insurancePolicy: "",
  name: "",
  serviceIncluded: false,
  serviceTnC: "",
  subscriptionTnC: "",
  swapsTnC: "",
  vehicles: [],
  isActive: true
};
