import { Store, legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from '@redux-devtools/extension';
import { ADD_TABLE_STATE } from "./actions/tableState/types";
import { IAddonTypeState } from "./reducers/addonType/types";
import { IAdminUserState } from "./reducers/adminUser/types";
import { IBookingState } from "./reducers/bookings/types";
import { ICalendarEventTypeState } from "./reducers/calendar-events.reducer";
import { ICustomerState } from "./reducers/customer/types";
import { IVehicleDamageState } from "./reducers/damageVehicle/types";
import { IFleetState } from "./reducers/fleet/types";
import { IInsurancePolicyState } from "./reducers/insurance/types";
import { IInvoicesState } from "./reducers/invoices/types";
import { INavbarState } from "./reducers/navbar.reducer";
import { IOrganisationState } from "./reducers/organisation/types";
import { IPriceRuleState } from "./reducers/priceRule/types";
import rootReducer from "./reducers/root.reducer";
import { ITableState } from "./reducers/tableState/types";
import { IUserState } from "./reducers/user/types";
import { IVehiclePriceGroupState } from "./reducers/vehiclePriceGroups/types";
import { IAuthState } from "./reducers/auth/types"

export interface IAction {
  type: string;
}

export interface IAppState {
  adminUserReducer: IAdminUserState;
  bookingReducer: IBookingState;
  calendarEventReducer: ICalendarEventTypeState;
  customerReducer: ICustomerState;
  fleetReducer: IFleetState;
  invoiceReducer: IInvoicesState;
  navbarReducer: INavbarState;
  organisationReducer: IOrganisationState;
  priceRuleReducer: IPriceRuleState;
  addonTypeReducer: IAddonTypeState;
  userReducer: IUserState;
  vehicleDamageReducer: IVehicleDamageState;
  insurancePolicyReducer: IInsurancePolicyState;
  tableStateReducer: ITableState;
  vehiclePriceGroupReducer: IVehiclePriceGroupState;
  authReducer: IAuthState
}

// TODO: [NPD-419] Disable composeWithDeveTools in production
const composeEnhancers = composeWithDevTools({});

export default function configureStore(): Store<IAppState, any> {
  const store = createStore(rootReducer, undefined, composeEnhancers());
  store.subscribe(() => {
    const { tableStateReducer, lastAction } = store.getState();
    if (lastAction && lastAction.type === ADD_TABLE_STATE) {
      localStorage.setItem("tablesState", JSON.stringify(tableStateReducer));
    }
  });
  return store;
}
