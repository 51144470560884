import { ITable } from "../../reducers/tableState/types";

export const ADD_TABLE_STATE = "ADD_TABLE_STATE";
export const SET_TABLE_STATE = "SET_TABLE_STATE";

interface IAddTableState {
    type: typeof ADD_TABLE_STATE;
    payload: ITable;
}

interface ISetTableState {
    type: typeof SET_TABLE_STATE;
    payload: any;
}

export type TableStateAction =
    | ISetTableState
    | IAddTableState;
