import { Box, CardMedia, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Theme, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DateTime as d } from "luxon";
import { createStyles, makeStyles } from '@mui/styles';
import { ISubscriptionInfo, IVehicle } from '../../../../../reducers/fleet/types'
import { DATE_TYPE, getFeatureTypeIcon, getVehicleTypeIcon, toCurrency } from '../../../../common/utils';
import { capitalizeFirstLetter } from '../../utils';
import { IAddons, ISubscriptionPricing } from '../../../../../reducers/Subscription/types';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import FlatPicker from '../../../../common/FlatPicker';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { ISubscriptionArgs } from '../../../../../reducers/bookings/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      objectFit: "fill"
    },
    cardHeader: {
      background: "#55c2da",
      color: "#fff",
      textAlign: "center"
    },
    actionBtn: {
      backgroundImage: "linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)"
    },
    subButn: {
      background: "none",
      border: "none",
      boxShadow: "none",
      fontSize: 16,
      color: "#fff",
      width: "100%",
      height: 0,
      "&:hover": {
        background: "linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)",
      }
    },
    inclusionItemBox: {
      padding: 5,
      textAlign: "center",
      background: "#ececec",
      borderRadius: 6
    }
  }),
);
interface IProps {
  selectedVehicle: IVehicle;
  onSelectSubscription: (subscription: ISubscriptionArgs, vehicle: IVehicle, startDate: string) => void;
}

const CustomizeContract: React.FC<IProps> = (props) => {

  const classes = useStyles();

  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { locale, currency } = userState.currentOrganisation;

  const { selectedVehicle } = props

  const [selectedPlan, setSelectedPlan] = useState<ISubscriptionInfo>()
  const [selectedPricing, setSelectedPricing] = useState<ISubscriptionPricing>()
  const [startDate, setStartDate] = useState<string>('')

  useEffect(() => {
    if (selectedVehicle) {
      setSelectedPlan(undefined)
      setSelectedPricing(undefined)
      const updatedStartDate = selectedVehicle.availableFrom ? selectedVehicle.availableFrom : ''
      setStartDate(updatedStartDate)
    }
  }, [selectedVehicle])

  return (
    <Grid item xs={12} container alignItems={"center"} justifyContent={"center"} >
      <Paper elevation={1} style={{ marginTop: 15, marginBottom: 15, flexGrow: 1, padding: 25 }}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}><Typography style={{ fontWeight: 600, textAlign: "center", fontSize: 22 }} color='primary' variant='h4'>Customize Contract</Typography></Grid>
          <Grid item xs={6} container spacing={2}>
            <Grid item xs={12}>
              <CardMedia
                className={classes.media}
                component="img"
                height="300"
                style={{ borderRadius: 6, marginTop: 20 }}
                image={`${selectedVehicle?.imageUrl || getVehicleTypeIcon(capitalizeFirstLetter(selectedVehicle?.bodyType || ""))}`}
                alt="vehicle"
              />
            </Grid>
            <Grid container item sm={12} spacing={2}>
              <Grid item sm={3}>
                <img
                  style={{
                    height: "18px",
                    marginRight: "10px",
                    width: "18px",
                  }}
                  src={getFeatureTypeIcon("numberOfDoors")}
                  alt={"numberOfDoors"}
                />
                <span>{`${selectedVehicle?.numberOfDoors} Doors`}</span>
              </Grid>
              <Grid item sm={3}>
                <img
                  style={{
                    height: "18px",
                    marginRight: "10px",
                    width: "18px",
                  }}
                  src={getFeatureTypeIcon("numberOfSeats")}
                  alt={"numberOfSeats"}
                />
                <span>{`${selectedVehicle?.numberOfSeats} Seats`}</span>
              </Grid>
              <Grid item sm={3}>
                <img
                  style={{
                    height: "18px",
                    marginRight: "10px",
                    width: "18px",
                  }}
                  src={getFeatureTypeIcon("transmission")}
                  alt={"transmission"}
                />
                <span>{selectedVehicle?.transmission}</span>
              </Grid>
              <Grid item sm={3}>
                <img
                  style={{
                    height: "18px",
                    marginRight: "10px",
                    width: "18px",
                  }}
                  src={getFeatureTypeIcon("fuelType")}
                  alt={"fuelType"}
                />
                <span>{selectedVehicle?.fuelType}</span>
              </Grid>
            </Grid>
            {selectedPlan && (selectedPlan.subscription.addons?.length || selectedPlan.subscription.insurancePolicy) && (
              <>
                <Grid item xs={12}>
                  <Typography variant={'h4'} style={{ fontSize: 16 }} align='center'>Plan Inclusion</Typography>
                </Grid>
                {selectedPlan.subscription.addons?.map((addon: IAddons, index: number) => (
                  <Grid key={index} item xs={4}>
                    <Box className={classes.inclusionItemBox}>
                      <Typography variant='h4'>{addon.displayName}</Typography>
                    </Box>
                  </Grid>
                ))}

                {selectedPlan.subscription.insurancePolicy && (
                  <Grid item xs={4}>
                    <Box className={classes.inclusionItemBox}>
                      <Typography variant='h4'>{selectedPlan.subscription.insurancePolicy.name}</Typography>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={6} spacing={2}>
            <Grid item xs={12}>
              <Box style={{ background: "#fff", padding: 20, borderRadius: 10 }}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={{ fontSize: 22, textTransform: "capitalize" }} variant='h2'>{`${selectedVehicle?.make} ${selectedVehicle?.model}`}</Typography>
                  </Grid>
                </Grid>
                <Box mt={2}></Box>
                {
                  selectedPricing && (
                    <Grid item xs={12} container>
                      <Grid item xs={12} container alignItems='baseline'>
                        <Typography style={{ fontSize: 26 }} variant='h4'>{toCurrency(selectedPricing?.subscriptionAmount, currency, locale)} </Typography>
                        <Typography variant='caption' style={{ marginLeft: 5 }}> {selectedPlan?.subscription.rateTypeName === "monthly" ? "month" : "week"} + (taxes)</Typography>
                      </Grid>
                      <Grid item xs={12} >
                        <Typography variant={"h5"}>{"Enrollment Fee"} {toCurrency(selectedPricing.enrollmentAmount, currency, locale)}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}><Box mt={2}></Box></Grid>
                      </Grid>
                    </Grid>
                  )
                }
                {
                  (
                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel id="demo-simple-select-contract-plan-label">
                          <Typography style={{ fontSize: 16, color: "#000" }} variant='h3'>Select Plan</Typography>
                        </InputLabel>
                        <Box mt={0.5}></Box>
                        <FormControl fullWidth variant='outlined'>
                          <Select
                            labelId="demo-simple-select-contract-plan-label"
                            id="demo-simple-select-contract-plan-label-select"
                            variant='outlined'
                            value={selectedPlan?.subscription.id || ""}
                            onChange={(event: any) => {
                              const plan = selectedVehicle?.subscriptions.find(sub => sub.subscription.id === event.target.value)
                              if (plan) {
                                setSelectedPricing(undefined)
                                setSelectedPlan(plan)
                              }
                            }}
                          >
                            {
                              selectedVehicle?.subscriptions.map((subscription, index) => (
                                <MenuItem
                                  value={subscription.subscription.id}
                                  key={subscription.subscription.id}
                                >
                                  {subscription.subscription.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel id="demo-simple-select-contract-plan-label">
                          <Typography style={{ fontSize: 16, color: "#000" }} variant='h3'>Select Tenure</Typography>
                        </InputLabel>
                        <Box mt={0.5}></Box>
                        <FormControl fullWidth variant='outlined'>
                          <Select
                            labelId="demo-simple-select-contract-period-label"
                            id="demo-simple-select-contract-plan-period-select"
                            value={selectedPricing?.duration || ""}
                            onChange={(event: any) => {
                              const pricing = selectedPlan?.pricings.find(pricing => pricing.duration === event.target.value)
                              if (pricing && selectedPlan) {
                                setSelectedPricing(pricing)
                              }
                            }}

                          >
                            {
                              selectedPlan?.pricings.map((pricing, index) => (
                                <MenuItem
                                  value={pricing.duration}
                                  key={index}
                                >
                                  {`${pricing.duration} ${selectedPlan.subscription.rateTypeName === "monthly" ? "Month's" : "Week's"} (${toCurrency(pricing.subscriptionAmount, currency, locale)}/${selectedPlan.subscription.rateTypeName === "monthly" ? "Month" : "Week"})`}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant={"h3"} >Available From</Typography>
                        <Box mt={0.5}></Box>
                        <Typography variant='h3'>{getLocalizedDateFormat(country, selectedVehicle?.availableFrom || "", DATE_TYPE.CONDENSED)}</Typography>
                      </Grid>
                      {
                        selectedPricing && (
                          <Grid item xs={6} >
                            <Typography variant={"h3"} style={{ fontSize: 16, color: "#000" }}>Rolling Contract</Typography>
                            <Box mt={0.5}></Box>
                            <Typography variant='h3' style={{ fontSize: 16 }} color={"primary"}>{selectedPricing?.duration} {selectedPlan?.subscription.rateTypeName === "monthly" ? "month's" : "week's"}</Typography>
                          </Grid>
                        )
                      }
                      <Grid item xs={12}><Typography variant='h3' style={{ fontSize: 16, color: "#000" }}>Tentative start date</Typography>
                        <Box mt={0.5}></Box>
                        <Grid item xs={12}>
                          <FlatPicker
                            handleDateChange={(value: Date) => {
                              if (value) {
                                setStartDate(d.fromJSDate(value).toUTC().toISO())
                              }
                            }}
                            identifier={"availabilityDate"}
                            name={"subscriptionDate"}
                            placeholderValue={"Select Date.."}
                            value={getLocalizedDateFormat(country, startDate || "", DATE_TYPE.CONDENSED)}
                            minDate={getLocalizedDateFormat(country, d.fromISO(selectedVehicle?.availableFrom || "").startOf('day').toUTC().toISO(), DATE_TYPE.CONDENSED)}
                            enableTime={false}
                            type={"availability-time-picker"}
                            country={country}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }
                <Box mt={3}></Box>
                {
                  (selectedPlan && selectedPricing && startDate) && (
                    <>
                      <Box mt={2.5}></Box>
                      <Grid item xs={12} container justifyContent={"flex-end"}>
                        <Fab
                          variant="extended"
                          size="medium"
                          aria-label="continue"
                          disabled={(!selectedPlan && !selectedPricing && !startDate)}
                          onClick={() => {
                            if (selectedVehicle) {
                              props.onSelectSubscription({ pricing: selectedPricing, subscription: selectedPlan.subscription as any, deposit: selectedPlan.deposit }, selectedVehicle, startDate)
                            }
                          }}
                        >
                          Continue
                        </Fab>
                      </Grid>
                    </>
                  )
                }
              </Box>
            </Grid>
          </Grid >
        </Grid>
      </Paper>
    </Grid>
  )
}

export default CustomizeContract
