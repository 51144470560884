import {
  AppBar,
  Grid,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { DamageTypes } from "../../../../../../reducers/damageVehicle/types";
import { getSignedUrl } from '../../../../../../utils/getSignedUrl';

import ImageGallery from "../../../../../common/ImageGallery";
import { TabPanel } from "../../../../../common/TabPannel/TabPannel";
import { captureErrorException } from "../../../../../../utils/sentry";

interface IProps {
  row: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageStyle: {
      borderRadius: 5,
      height: 70,
      marginRight: 20,
      width: 70
    },
    root: {
      flexGrow: 1
    },
    container: {
      padding: theme.spacing(3)
    }
  })
);

export const DamageRowDetail = (props: IProps) => {
  const styles = useStyles();
  const [value, setValue] = useState<number>(0);
  const [exteriorImages, setExteriorImages] = useState<any>();
  const [interiorImages, setInteriorImages] = useState<any>([]);
  const [titleFlag, setTitleFlag] = useState("");

  useEffect(() => {
    if (
      props.row &&
      props.row.damages &&
      props.row.damages.length
    ) {
      props.row.damages.map((damage: any) => {
        if (damage.damageType === DamageTypes.EXTERIOR && damage.images && damage.images.length) {
          const modfiyImages: any = [];
          const promises: any[] = [];
          damage.images.map(
            (id: string, index: number) => {
              promises.push(
                (async () => {
                  try {
                    const data: any = await getImages(id);
                    modfiyImages[index] = data;
                  } catch (error) {
                    captureErrorException(error)
                  }
                })()
              );
            });
          Promise.all(promises).then(() => {
            setExteriorImages((oldImages: any) => [...oldImages, ...modfiyImages]);
          });
        }
        if (damage.damageType === DamageTypes.EXTERIOR && damage.images && damage.images.length) {
          const modfiyImages: any = [];
          const promises: any[] = [];
          damage.images.map(
            (id: string, index: number) => {
              promises.push(
                (async () => {
                  try {
                    const data: any = await getImages(id);
                    modfiyImages[index] = data;
                  } catch (error) {
                    captureErrorException(error)
                  }
                })()
              );
            });
          Promise.all(promises).then(() => {
            setInteriorImages((oldImages: any) => [...oldImages, ...modfiyImages]);
          });
        }
      });
    }
  }, [props.row]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  const getImages = async (key: string) => {
    const file = await getSignedUrl(key);
    return file;
  };

  const handleOpen = (title: string) => {
    if (title === "interiorImages") {
      setTitleFlag(title);
    } else {
      Object.keys(exteriorImages).map((key: string) => {
        if (title === key) {
          setTitleFlag(title);
        }
      });
    }
  };

  const handleClose = () => {
    setTitleFlag("");
  };

  return (
    <Paper className={styles.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="EXTERIOR DAMAGE" />
          &rbrace; /&gt;
          <Tab label="INTERIOR DAMAGE" />
          &rbrace; /&gt;
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={2} className={styles.container}>
          {exteriorImages && exteriorImages.length > 0 ? (
            exteriorImages.map((img: string) => {
              return (
                <React.Fragment>
                  <p>Total: {exteriorImages.length}</p>
                  <button
                    type="button"
                    onClick={() => handleOpen("ExteriorImages")}
                  >
                    <img src={img} className={styles.imageStyle} alt="" />
                  </button>
                  <ImageGallery
                    title={"Vehicle Exterior Damage Images"}
                    open={titleFlag === "exteriorImages" ? true : false}
                    handleClose={() => {
                      handleClose();
                    }}
                    images={exteriorImages}
                  />
                </React.Fragment>
              );
            })
          ) : (
            <Grid container item xs={12} className={styles.container}>
              <Typography variant="h1" color="primary">
                No Exterior Damage Found
              </Typography>
            </Grid>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={2} className={styles.container}>
          {interiorImages && interiorImages.length > 0 ? (
            interiorImages.map((img: string) => {
              return (
                <React.Fragment>
                  <p>Total: {interiorImages.length}</p>
                  <button
                    type="button"
                    onClick={() => handleOpen("interiorImages")}
                  >
                    <img src={img} className={styles.imageStyle} alt="" />
                  </button>
                  <ImageGallery
                    title={"Vehicle Interior Damage Images"}
                    open={titleFlag === "interiorImages" ? true : false}
                    handleClose={() => {
                      handleClose();
                    }}
                    images={interiorImages}
                  />
                </React.Fragment>
              );
            })
          ) : (
            <Grid container item xs={12} className={styles.container}>
              <Typography variant="h1" color="primary">
                No Interior Damage Found
              </Typography>
            </Grid>
          )}
        </Grid>
      </TabPanel>
    </Paper>
  );
};
