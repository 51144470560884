import { ITable, ITableState } from "../../reducers/tableState/types";
import { ADD_TABLE_STATE, SET_TABLE_STATE, TableStateAction } from "./types";

export function addTableState(payload: ITable, restoreState?: ITableState): TableStateAction {
    const initialState = restoreState || {};
    return {
        payload: { ...initialState, ...payload },
        type: ADD_TABLE_STATE
    };
}

export function setTableState(payload: ITableState): TableStateAction {
    return {
        payload,
        type: SET_TABLE_STATE
    };
}

export function saveTableStateToLocalStorage(state: ITable) {
  localStorage.setItem('tablesState', JSON.stringify(state));
}
export const loadTableStateFromLocalStorage = (): ITableState | null => {
  const savedState = localStorage.getItem('tablesState');
  if (savedState) {
    return JSON.parse(savedState) as ITableState; 
  }
  return null;
};