import { gql } from "@apollo/client";

export const CREATE_PRICE_RULE = gql`
  mutation createPriceRule($priceRule: CreatePriceRuleInput!) {
    createPriceRule(priceRule: $priceRule) {
      id
      name
    }
  }
`;
