import { Box, Grid, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import ImageGallery from "../../../../common/ImageGallery";
import _ from "lodash";
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { captureErrorException } from "../../../../../utils/sentry";

interface IImageProps {
  data: any
}
const useStyles = makeStyles(() =>
  createStyles({
    imageStyle: {
      height: 70,
      width: 70,
      borderRadius: 5
    }
  })
);
export const NewImageView = (props: IImageProps) => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);
  const [damages, setDamages] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    if (props.data) {
      const damageData = _.cloneDeep(props.data);
      setDamages([])
      setCount(0);
      setImages([]);
      damageData.map((damage: any) => {
        if (damage.images && damage.images.length > 0) {
          const promises: any[] = [];
          damage.images.map(
            (id: string, index: number) => {
              promises.push(
                (async () => {
                  try {
                    const file = await getSignedUrl(id);
                    damage.images[index] = file;
                  } catch (error) {
                    captureErrorException(error)
                  }
                })()
              );
            });
          setCount((oldCount: number) => oldCount + damage.images.length)
          Promise.all(promises).then(() => {
            setDamages((oldDamage: any) => [...oldDamage, damage]);
            setImages(() => [...damage.images]);
          });

        }
      })
    }
  }, [props.data]);
  return (
    <React.Fragment>
      <Box mt={1}></Box>
      <Grid container spacing={2}>
        {count > 0 &&
          damages.map((damage: any, index: number) => (

            <React.Fragment key={index}>
              {damage.images && damage.images.length > 0 && damage.images.map((img: any, index1: number) => (
                <React.Fragment key={`inner-${index1}`}>
                  <button
                    type="button"
                    style={{ background: "none", border: "none" }}
                    onClick={() => setOpen(true)}
                  >
                    <img src={img} className={styles.imageStyle} alt="" />
                    <br />
                    <Typography variant="h4" > {`${damage.id}.${index1 + 1}`}</Typography>
                  </button>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))
        }
        {images && images.length > 0 && (<ImageGallery
          title={"Vehicle Damage Images"}
          open={open}
          handleClose={() => setOpen(false)}
          images={images}
        />)}
      </Grid>
    </React.Fragment>
  );
};

interface SingleImageProp {
  image: string
}
export const SingleImageView = ({ image }: SingleImageProp) => {
  const styles = useStyles();
  const [imageUrl, setImageUrl] = useState<string>('');

  const getImageUrl = async (image: string) => {
    const url = await getSignedUrl(image)
    setImageUrl(url);
  }

  useEffect(() => {
    getImageUrl(image);
  }, [image])

  return (
    <img src={imageUrl} className={styles.imageStyle} />
  )
}