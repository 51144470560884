import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact from "google-map-react";
import styles from './index.module.css';
import { EventMarker, EndPointMarker } from "./Marker";
import CircularProgress from '@mui/material/CircularProgress';
import { ITelematicsData, TelematicStartStopEventJourney } from '../types';


const initialLocationLatLng = {
  lng: -4.3728844,
  lat: 55.855573
};

export const eventKeysMap: { [key: string]: { display: string, color: string } } = {
  "speed": {
    display: "Speeding",
    color: "#0EB000"
  },
  "hardCornering": {
    display: "Hard Corner",
    color: "#7300FF"
  },
  "harshAcceleration": {
    display: "Harsh Acceleration",
    color: "#CF9F00"
  },
  "harshBraking": {
    display: "Harsh Braking",
    color: "#F05821"
  },
  "lostGSM": {
    display: "Lost GSM",
    color: "#747474"
  },
  "lostGPS": {
    display: "Lost GPS",
    color: "teal"
  }
}

interface IProps {
  trip?: TelematicStartStopEventJourney
}

const MapContainer = ({ trip }: IProps) => {
  const mapRef = useRef<{ map: any, maps: any }>();
  const routePloylineRef = useRef<any>();
  const [telematicsData, setTelematicsData] = useState<ITelematicsData | undefined>();
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (trip) {
      const formattedData: ITelematicsData = {
        milesDriven: trip.tripEventDetails.milesDriven || "",
        engineHours: trip.tripEventDetails.engineHours || "",
        fuelUsage: trip.tripEventDetails.fuelUsage || "",
        stoppageHours: trip.tripEventDetails.stoppageHours || "",
        mapFlagsData: {
          eventTime: trip.tripEventDetails.mapFlagsData?.eventTime || [],
          speedlimit: trip.tripEventDetails.mapFlagsData["speedlimit"] || [],
          magnitude: trip.tripEventDetails.mapFlagsData?.magnitude || [],
          Key: trip.tripEventDetails.mapFlagsData?.Key || [],
          latlong: trip.tripEventDetails.mapFlagsData["latlong"] || [],
        },
        mapRoutesData: {
          eventTime: trip.tripEventDetails.mapRoutesData?.eventTime || [],
          latlong: trip.tripEventDetails.mapRoutesData["latlong"] || []
        },
        tripTimesData: {
          tripStart: trip.tripEventDetails.tripTimesData?.tripStart || [],
          tripEnd: trip.tripEventDetails.tripTimesData?.tripEnd || []
        }
      }
      setTelematicsData(formattedData)
    } else {
      setTelematicsData(undefined);
    }
  }, [trip])

  const getCoords = () => {
    let tempcoords: any[] = [];
    if (telematicsData?.mapRoutesData) {
      const data = telematicsData.mapRoutesData
      data.latlong.map((latlong, i) => {
        const latlongArr = latlong.split(",")
        tempcoords.push({ lat: parseFloat(latlongArr[0]), lng: parseFloat(latlongArr[1]) })
      })
    }
    return tempcoords
  }

  const renderEventMarkers = () => {
    if (telematicsData?.mapFlagsData) {
      return telematicsData.mapFlagsData.latlong.map((latlongString, i) => {
        const latlongArr = latlongString.split(",");
        const eventName: string = telematicsData.mapFlagsData.Key[i];
        const eventTime = telematicsData.mapFlagsData.eventTime[i];
        if (selectedEvents.length && !selectedEvents.includes(eventName)) {
          return
        }
        return (
          eventKeysMap[eventName] &&
          (
            <EventMarker
              data={{
                time: eventTime,
                name: eventKeysMap[eventName].display,
                magnitude: telematicsData.mapFlagsData.magnitude[i],
                speedlimit: telematicsData.mapFlagsData.speedlimit[i]
              }}
              lat={parseFloat(latlongArr[0])}
              lng={parseFloat(latlongArr[1])}
              color={eventKeysMap[eventName].color}
              onClick={() => { }}
            />
          )
        )
      })
    }
  }

  const renderRouteEndPoints = () => {
    if (telematicsData) {
      const start = telematicsData.mapRoutesData.latlong[0].split(",")
      const end = telematicsData.mapRoutesData.latlong[telematicsData.mapRoutesData.latlong.length - 1].split(",");
      const endPoints = [{ point: "START", color: "#0980FD", coords: start }, { point: "END", color: "#171717", coords: end }];
      return endPoints.map(point => <EndPointMarker
        lat={parseFloat(point.coords[0])}
        lng={parseFloat(point.coords[1])}
        point={point.point}
      />)
    }
  }

  const setPolylineData = (map: any, maps: any) => {
    if (telematicsData?.mapRoutesData) {
      const trailCoords = getCoords();
      let geodesicPolyline = new maps.Polyline({
        path: trailCoords,
        geodesic: true,
        strokeColor: '#000',
        strokeOpacity: 1.0,
        strokeWeight: 2
      })
      routePloylineRef.current = geodesicPolyline
      geodesicPolyline.setMap(map);
      if (trailCoords.length) {
        const bounds = new maps.LatLngBounds()
        for (let coords of trailCoords) {
          bounds.extend(
            new maps.LatLng(coords.lat, coords.lng)
          )
        }
        map.fitBounds(bounds)
      }
    }
  }

  useEffect(() => {
    if (mapRef.current) {
      if (mapRef.current.map && mapRef.current.maps) {
        if (telematicsData) {
          if (routePloylineRef.current) {
            routePloylineRef.current.setMap(null);
          }
          setPolylineData(mapRef.current.map, mapRef.current.maps);
        }
        else if (!telematicsData && routePloylineRef.current) {
          routePloylineRef.current.setMap(null);
        }
      }
    }
  }, [telematicsData]);

  return (
    <div className={styles.mapModule}>
      {loading && <div className={styles.loadingOverlay} >
        <CircularProgress size={26} thickness={5} />
      </div>}
      <div className={styles.mapContainer}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""
          }}
          options={{
            zoomControlOptions: {
              position: 6,
            },
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
          }}
          zoom={8}
          defaultCenter={initialLocationLatLng}
          center={initialLocationLatLng}
          defaultZoom={8}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => mapRef.current = ({ map, maps })}
        >
          {telematicsData && renderRouteEndPoints()}
          {telematicsData && renderEventMarkers()}
        </GoogleMapReact>
      </div>
    </div>
  )
}
export const MapModule = MapContainer