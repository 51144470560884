import {
  CssBaseline,
  Grid,
  Typography,
  Hidden
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IAppState } from "../../../../../store";
import { getLocalizedOrganisationSyntex } from "../../../../../utils/localized.syntex";
import TabView from "./TabView/TabView";

const NewOrgnisationStructureTab: React.FC = () => {
  const location = useLocation();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address;
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const organisationId = params.get("organisation");
      if (organisationId) {
        setIsUpdate(true)
      }
    }
  }, [location]);

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Hidden mdDown>
        <Grid container item xs={6} alignItems="center">
          <Typography variant="h1" color="primary">
            &nbsp;{isUpdate ? "Update" : "New"} {`${getLocalizedOrganisationSyntex(country)} Structure`}
          </Typography>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container item xs={12} alignItems="center" justifyContent="center">
          <Typography variant="h3" color="primary">
            &nbsp;{isUpdate ? "Update" : "New"} {`${getLocalizedOrganisationSyntex(country)} Structure`}
          </Typography>
        </Grid>
      </Hidden>
      <Grid container item xs={12}>
        <TabView isUpdate={isUpdate} />
      </Grid>
    </Grid>
  );
};

export default NewOrgnisationStructureTab;
