export default {
  styleOverrides: {
    root: {
      "&.MuiButton-contained": {
        boxShadow: "none",
        color: "#fff",
        fontWeight: 500,
        letterSpacing: 1.25,
        padding: "10px 16px",
        fontSize: 14,
        "&.Mui-disabled.Mui-disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.18)",
          color: "rgba(0, 0, 0, 0.26)"
        }
      },
      borderRadius: "4px !important"
    }
  }
};
