import {
  Grid,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { IAppState } from "../../../../store";
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { NuvvenDivider } from "../../../views/ReservationManagement/Summary/Components/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: "150px",
      height: "100px",
      objectFit: "contain"
    },
    name: {
      fontSize: "18px",
      fontWeight: "bold"
    }
  })
);

interface Props {
  type: string;
}

export const CompanyInfo: React.FC<Props> = (props) => {
  const styles = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const organisation = useSelector(
    (state: IAppState) => state.userReducer.currentOrganisation
  );
  const address = `${organisation!.address.street}, ${organisation!.address.city
    }, ${organisation!.address.zipcode}`;

  const [logoUrl, setLogoUrl] = useState<any>("");

  useEffect(() => {
    const getLogoUpload = async () => {
      if (!organisation) {
        return;
      }
      const file = await getSignedUrl(organisation.logoUrl)
      setLogoUrl(file);
    };
    if (organisation && organisation.logoUrl) {
      getLogoUpload();
    }
  }, [organisation]);

  if (!organisation) {
    return (
      <Grid item xs={12}>
        Unable to load company details
      </Grid>
    );
  }

  return (
    <>
      <Grid container item xs={12}>
        <Grid container item xs={4} spacing={1}>
          <Grid item xs={12}>
            {
              logoUrl && (
                <img src={logoUrl} className={styles.image} alt="logo" />
              )
            }
          </Grid>
        </Grid>
        <Grid item xs={3}>
          {props.type !== "Quote" && <Typography variant="h2">
            {props.type.toUpperCase()}
          </Typography>}
        </Grid>
        <Grid container item xs={5}>
          <Grid container item xs={12} justifyContent="flex-end">
            <Typography variant={"h4"}>{organisation!.name}</Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Typography variant={"body1"}>
              {address}
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Typography variant="body1">
              Tel. No.: {organisation.phoneNumber.phone} {"|"} Email: {organisation.emailId}
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <Typography variant="body1">
              {country === "United States" ? "Tax No." : "VAT No."}: {organisation.orgVatNumber} {"|"} Co Reg. No.:  {organisation.orgRegNumber}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {
        props.type !== "Rental Agreement" && (
          <Grid container item xs={12}>
            <NuvvenDivider noMargin={true} />
          </Grid>
        )
      }
    </>
  );
};
