import { gql } from "@apollo/client";

export const DOWNLOAD_GROUP_VEHICLE_UTITLIZATION_REPORT = gql`
  query downloadVehicleUtilizationReport(
    $startDate: String!
    $endDate: String!
    $quarter: String
  ) {
    downloadVehicleUtilizationReport(
        startDate: $startDate
        endDate: $endDate
        quarter: $quarter
      ) {
      success
      key
      message
    }
  }
`;