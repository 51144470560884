import { gql } from "@apollo/client";

export const GET_AVAILABLE_SUBSCRIPTION_VEHICLES = gql`
  query getSubscriptionCatalogue {
    getSubscriptionCatalogue {
      id
      bodyType
      make
      model
      year
      licencePlate
      numberOfDoors
      numberOfSeats
      transmission
      fuelType
      imageUrl
      subscriptions {
        deposit
        subscription {
          isActive
          id
          name
          rateTypeName
          addons {
            id
            name
            displayName
          }
          insurancePolicy {
            id
            name
          }
        }
        pricings {
          duration
          enrollmentAmount
          mileage
          subscriptionAmount
          pricePerExtraMile
          unlimitedMileage
          swapsAllowed
        }
      }
      availableFrom
      currentServiceLocation {
        id
        isDisabled
      }
    }
  }
`;
