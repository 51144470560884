import { gql } from "@apollo/client";

export const GET_INSURANCE_POLICY = gql`
  query insurancePolicy($id: String!) {
  	insurancePolicy(id: $id) {
		id
		name
		description
		excess
		documents{
			title
			documentType
			documentUrl
			documentName
			expiryDate
			createdAt
		}
		organisationId
		insuranceRate {
			rateName
			rateDuration
			rate
			longTerm
		}
		isActivated
  	}
  }
`;
