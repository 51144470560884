import { gql } from "@apollo/client";

export const UPDATE_BOOKING_DEPOSIT_MUTATION = gql`
  mutation updateBookingDeposit($bookingId: ID!, $depositAmount: Float!) {
    updateBookingDeposit(bookingId: $bookingId, depositAmount: $depositAmount) {
      id
      bookingId
      dateStarted
      pcoNumber
      dateReturned
      taxExempted
      organisationId
      additionalTnc
      quoteUrl
      rentalAgreementUrl
      vehicleConditionReportUrls
      quoteUrl
      bookingConfirmationUrl
      isSubscription
      activeSubscription {
        startDate
        endDate
        subscription {
          id
          name
          insurancePolicy {
            name
          }
          addons {
            displayName
          }
          rateTypeName
        }
        vehicle {
          licencePlate
          make
          model
          vin
          id
        }
        duration
        subscriptionAmount
        enrollmentAmount
        mileageLimit
        unlimitedMileage
        pricePerExtraMile
      }
      depositDetails {
        amount
        amountAdjusted
        payment {
          id
          status
          paymentMode
          paymentGateway
          dateCreated
          amount
          remainingAmount
        }
        adjustedAgainst {
          id
          invoiceRef
        }
        refund {
          amount
          refundRef
          status
          mode
          currency
          id
          paymentIntent
          paymentId
        }
      }
      lastEventTime
      lastEventType
      lastEventBy {
        id
        firstName
        lastName
        email
      }
      events {
        eventType
        eventTime
        user
        previousEndDate
        newEndDate
      }
      creditNotes {
        id
        creditNoteRef
        creditNoteType
        createdAt
        invoice {
          invoiceRef
          confirmedPaidAmount
        }
        totalAmount
        refundedAmount
        availableAmount
        creditNoteItems {
          type
          description
          originalPrice
          quantity
          unitPrice
          totalAmount
          duration
        }
        refunds {
          mode
          amount
          status
          id
          refundRef
          currency
          paymentIntent
          paymentGateway
        }
        allocations {
          amount
          allocatedAt
          invoice {
            invoiceRef
          }
        }
      }
      latestExtension {
        extensionNumber
        referenceNumber
        startDate
        endDate
        status
        id
      }
      extensions {
        extensionNumber
        referenceNumber
        startDate
        endDate
        status
        id
        invoice {
          invoiceRef
        }
        dropoffServiceLocation {
          name
        }
      }
      firstInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        invoiceItems {
          type
          description
          quantity
          unitPrice
          totalAmount
        }
        dueAmount
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
        payments {
          id
          dateCreated
          lastUpdated
          createdBy
          updatedBy
          note
          paymentMode
          paymentType
          emailNotify
          expireBy
          amount
          currency
          description
          smsNotify
          status
        }
      }
      currentInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        invoiceItems {
          type
          description
          quantity
          unitPrice
          totalAmount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
        payments {
          id
          dateCreated
          lastUpdated
          createdBy
          updatedBy
          note
          paymentMode
          paymentType
          emailNotify
          expireBy
          amount
          currency
          description
          smsNotify
          status
        }
      }
      finalInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        invoiceItems {
          type
          description
          quantity
          unitPrice
          totalAmount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        payments {
          id
          dateCreated
          lastUpdated
          createdBy
          updatedBy
          note
          paymentMode
          paymentType
          emailNotify
          expireBy
          amount
          currency
          description
          smsNotify
          status
        }
      }
      referenceNumber
      dateCreated
      dateUpdated
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
      pickupServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      pickupOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      pickupDateTime
      dropoffServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      dropoffOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      dropoffDateTime
      extendedDropoffDateTime
      initialDropoffDateTime
      longTermBooking
      flightNumber
      insuranceRate
      excess
      customerType
      discount {
        appliedBy
        appliedDate
        isPercent
        code
        amount
      }
      tax {
        appliedBy
        appliedDate
        code
        amount
        payableAmount
        type
        rate
        subdivisionOptIn
        subdivisionValue
        subdivisionType
        subdivisionRate
      }
      invoices {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        invoiceItems {
          type
          description
          quantity
          unitPrice
          totalAmount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
      }
      activeInvoices {
        id
        dateCreated
        invoiceRef
        status
        startDate
        endDate
        invoiceType
        totalPayableAmount
        confirmedPaidAmount
        otherCharges {
          amount
          name
        }
      }
      rateTypeName
      rateTypeDuration
      billingCycleName
      billingCycleDuration
      isRecurringBilling
      billingFrequency
      status
      addonRequirements {
        addon {
          id
          name
          category
          imageUrl
          hasFixedRate
          hasQuantity
          fixedRate
          description
          displayName
          recurringRate {
            rateTypeName
            rateTypeDuration
            rate
            longTerm
          }
          totalQuantity
          availableQuantity
          isActivated
        }
        rate
        quantity
        name
        hasFixedRate
        displayName
        payableAmount
        tax {
          title
          value
        }
      }
      customer {
        id
        firstName
        lastName
        email
        nationalInsuranceNumber
        phoneNumber {
          phone
          country
        }
        dateOfBirth
        customerPCONumber
        location {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        license {
          dateOfExpiry
          licenseNumber
          authority
        }
      }
      currentBookingSchedules {
        id
        startDate
        endDate
        status
        vehicleStatus
        vehicle {
          id
          licencePlate
          imageUrl
          pcoNumber
          vin
          make
          model
          features
          activeVehicleDamages {
            id
            damages {
              title
              damageType
              damageKind
              damageArea
              descriptions
              location {
                x
                y
              }
            }
          }
          vehicleState {
            type
            odometerReading
            generalChecks {
              name
              status
              images
            }
            defaultAccesories {
              name
              status
              images
            }
          }
        }
        dropoffCheck {
          customerInfo {
            signature
          }
          vehicleStatePdfUrl
        }
      }
      businessCustomer {
        id
        businessName
        registrationNumber
        VAT
        registeredAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        tradingAddressSame
        tradingAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        approvedDrivers {
          id
          status
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        authorizedSignatories {
          id
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        billing {
          phoneNumber {
            phone
            country
          }
          email
        }
        contact {
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        documents {
          id
          name
          title
          documentType
          expiryDate
          url
        }
      }
      authorizedSignatory
      insurancePolicy {
        id
        name
        description
      }
      paymentDetails {
        rentalAmount
        startDate
        endDate
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        totalPayableAmount
        rentalTime
        isProrated
        standardBillingDays
        proratedBillingDays
      }
      vehicleGroups {
        activeVehicleSchedules {
          id
          vehicleStatus
          vehicle {
            id
            licencePlate
            make
            model
            features
            colour
            pcoNumber
            vin
            branch
            branchDetails {
              id
              name
            }
          }
          dropoffCheck {
            vehicleStatePdfUrl
          }
          pickupCheck {
            vehicleStatePdfUrl
          }
        }
        vehicleGroup {
          basePrices {
            rateTypeName
            mileageLimit
            unlimitedMileage
          }
          name
          id
        }
        name
        vehicleIds
        baseRate
        priceRuleOperator
        priceRuleValueType
        priceRuleTriggerType
        priceRuleAmount
        priceRuleName
        deposit
        mileageLimit
        pricePerExtraMile
        unlimitedMileage
        count
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
        location {
          street
          state
          country
          fullAddress
        }
        license {
          authority
          licenseNumber
          dateOfExpiry
          images
        }
      }
      notes {
        id
        description
        createdBy {
          id
          firstName
          lastName
        }
        createdDate
      }
      additionalInformation {
        description
      }
      noteFromWebsite {
        description
        createdAt
      }
      preBookingQuestions {
        question
        answer
        answerType
      }
      deliveryQuestions {
        question
        answer
        answerType
      }
      locationSurcharges {
        id
        name
        value
        rate
        type
        tax {
          title
          value
        }
        payableAmount
        payableTaxAmount
      }
      source
      poNumber
      smartCarVehicle
      previousBookingSchedules {
        id
      }
      vehicleReplacement {
        # vehicleGroups {
        activeVehicleSchedules {
          id
          vehicleStatus
          vehicle {
            id
            licencePlate
            make
            model
            features
            colour
            pcoNumber
            vin
            branch
            branchDetails {
              id
              name
            }
          }
          dropoffCheck {
            vehicleStatePdfUrl
          }
          pickupCheck {
            vehicleStatePdfUrl
          }
        }
        vehicleGroup {
          basePrices {
            rateTypeName
            mileageLimit
            unlimitedMileage
          }
          name
          id
        }
        vehicleIds
        baseRate
        priceRuleOperator
        priceRuleValueType
        priceRuleTriggerType
        priceRuleAmount
        priceRuleName
        deposit
        mileageLimit
        pricePerExtraMile
        unlimitedMileage
        count
      }
      # }
    }
  }
`;
