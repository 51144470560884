import { IBranch } from "../../../reducers/user/types";

export enum AssetType {
  VEHICLE = "VEHICLE",
  ADDON = "ADDON"
}

export const AssetTypeValue: {
  [key in AssetType]: string
} = {
  VEHICLE: "Vehicle",
  ADDON: "Addon"
}

export enum ExpenseType {
  FUEL = "FUEL",
  FINE = "FINE",
  MAINTENANCE_AND_VALET = "MAINTENANCE_AND_VALET",
  INSURANCE = "INSURANCE",
  OTHER = "OTHER"
}

export const ExpenseTypeValue: {
  [key in ExpenseType]: string;
} = {
  FUEL: "Fuel",
  FINE: "Fine",
  MAINTENANCE_AND_VALET: "Maintenance & Valet",
  INSURANCE: "Insurance",
  OTHER: "Other"
};

export enum ExpenseAssetType {
  VEHICLE = "VEHICLE",
  ADDON = "ADDON"
}

export const ExpenseAssetTypeValue: {
  [key in ExpenseAssetType]: string
} = {
  VEHICLE: 'Vehicle',
  ADDON: 'Addon'
}

export interface IExpense {
  id?: "",
  assetType: string;
  expenseType: string;
  vehicle: string;
  addon: string;
  expenseDate: string;
  fuelType: string;
  vendor: string;
  odometerReading: number;
  fuelQuantity: number;
  fuelCardNumber: string;
  reference: string;
  authority: string;
  itemType: string;
  unit: number;
  amountPerUnit: number;
  cycle: number;
  insuranceExpiryDate: string;
  amount: number;
  document: IDocument[];
  driver: string;
  description: string;
  branch?: Partial<IBranch>;
}

export interface IDateTime {
  date: string;
  time: number;
}

export interface IDocument {
  title: string;
  url: string;
  documentName: string;
  expiryDate: string;
  documentType: string;
  reminderBefore?: number;
}

export const initialValue: IExpense = {
  assetType: AssetType.VEHICLE,
  expenseType: "",
  vehicle: "",
  addon: "",
  expenseDate: "",
  fuelType: "",
  vendor: "",
  odometerReading: 0,
  fuelQuantity: 0,
  fuelCardNumber: "",
  authority: "",
  itemType: "",
  unit: 0,
  amountPerUnit: 0,
  cycle: 0,
  insuranceExpiryDate: "",
  amount: 0,
  reference: "",
  document: [],
  driver: "",
  description: ""
}

export const VehicleFuelType: any = [
  {
    value: "DIESEL",
    label: "Diesel"
  },
  {
    value: "PETROL",
    label: "Petrol"
  },
  {
    value: "HYBRID ELECTRIC",
    label: "Hybrid Electric (HEV)"
  },
  {
    value: "ELECTRICITY",
    label: "Electricity"
  },
  {
    value: "ELECTRIC DIESEL",
    label: "Electric Diesel"
  },
  {
    value: "GAS BI-FUEL",
    label: "Gas Bi Fuel"
  },
  {
    value: "HYBRID ELECTRIC (CLEAN)",
    label: "Hybrid Electric (Clean)"
  },
  {
    value: "ELECTRIC",
    label: "Electric (EV)"
  },
  {
    value: "GASOLINE",
    label: "Gasoline"
  },
];

export const AssetTypes = [
  {
    label: "Vehicle",
    value: AssetType.VEHICLE
  },
  {
    label: "Addon",
    value: AssetType.ADDON
  },
]

export const ExpenseTypes = [
  {
    label: "Fuel",
    value: ExpenseType.FUEL
  },
  {
    label: "Fine",
    value: ExpenseType.FINE
  },
  {
    label: "Maintenance & Valet",
    value: ExpenseType.MAINTENANCE_AND_VALET
  },
  {
    label: "Insurance",
    value: ExpenseType.INSURANCE
  },
  {
    label: "Other",
    value: ExpenseType.OTHER
  }
]

export const InsuranceCycle = [
  {
    label: "Every month",
    value: 1
  },
  {
    label: "Every 3 month",
    value: 3
  },
  {
    label: "Every 6 month",
    value: 6
  },
  {
    label: "Every year",
    value: 12
  },
  {
    label: "Every 2 year",
    value: 24
  }
]