import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, CircularProgress, Fab, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { ApolloError } from "@apollo/client";
import { DateTime as d } from "luxon"
import React, { useEffect, useState } from "react";
import { GET_BUSINESS_CUSTOMER } from "../../../../../../graphql/businessCustomers/businessCustomer";
import { ADD_DRIVERS } from "../../../../../../graphql/customers/createCustomerMutation";
import {
  ICustomerDetails
} from "../../../../../../reducers/bookings/types";
import { initialState } from "../../../../../../reducers/customer/const";
import { IBusinessCustomer, ICustomer } from "../../../../../../reducers/customer/types";
import FlatPickerBar from "../../../../../common/FlatPicker";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage
} from "../../../../../common/utils";
import { validateName } from "../../../../../common/ValidationRules";
import { SearchForm } from "./SearchForm";
import * as Yup from 'yup';
import { GET_CUSTOMER } from '../../../../../../graphql/customers/getCustomerQuery';
import Autocomplete from "@mui/material/Autocomplete";
import { SEARCH_CUSTOMER } from "../../../../../../graphql/customers/searchCustomerQuery";
import _ from "lodash";
import { IAppState } from "../../../../../../store";
import { useSelector } from "react-redux";
import ConfirmCustomerWithOffences from "./ConfirmCustomerWithOffences";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { IDriverRow } from "../../../../../../reducers/user/types";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { createStyles, makeStyles } from "@mui/styles";
import AddDrivers, { defaultValues } from "../../../Summary/Components/AddDrivers";

const useStyles = makeStyles(() =>
  createStyles({
    tableContainer: {
      border: "solid 1px rgba(224, 224, 224, 1)",
      marginTop: "1rem"
    }
  })
);

interface IProps {
  businessCustomer?: string;
  driverCount: number;
  customer?: string;
  approvedDrivers: string[];
  customerType: string;
  isUpdate: boolean;
  licenseMinDate: string;
  updateCustomer: (data: {
    customer?: string;
    businessCustomer?: string;
    approvedDrivers?: string[];
    customerData?: ICustomer;
  }) => void;
  updateBusinessCustomerData: (data: IBusinessCustomer) => void;
  loadingCustomer: (loading: boolean) => void;
  setCustomerData: (customer: ICustomer) => void;
  newAddedDrivers: (data: IDriverRow[]) => void;
  addedDrivers?: IDriverRow[];
}

const remainder = 15 - (d.now().minute % 15);
const minDateTime = d.now()
  .plus({ minutes: remainder })
  .toJSDate();
minDateTime.setMilliseconds(0);
minDateTime.setSeconds(0);


const ExistingCustomer: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer)
  const { country } = userState.currentOrganisation.address;
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [selectedDrivers, setSelectedDrivers] = useState<IDriverRow[]>([]);
  const [values, setValues] = useState<ICustomerDetails>(initialState.customer);
  const [businessCustomer, setBusinessCustomer] = useState<
    IBusinessCustomer | undefined
  >();
  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const [offenceDialog, setOffenceDialog] = useState<boolean>(false);
  const [offenceDialogData, setOffenceDialogData] = useState<any>();
  const [query, setQuery] = useState<string>("");
  const [addDriver, setAddDriver] = useState<boolean>(false);
  const [newlyAddedDrivers, setNewlyAddedDrivers] = useState<IDriverRow[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<IDriverRow | null>(null);
  const [driverCount, setDriverCount] = useState(0)
  const [enableEdit, setEnableEdit] = useState<boolean>(false);

  const [loadBusinessCustomer, { data: businessCustomerData }] = useLazyQuery(
    GET_BUSINESS_CUSTOMER,
    {
      fetchPolicy: "network-only"
    }
  );

  const [loadCustomer, { data: customerData }] = useLazyQuery(
    GET_CUSTOMER,
    {
      fetchPolicy: "network-only"
    }
  );

  const [searchCustomer, { loading: searchCustomerLoading, data: searchCustomerData }] = useLazyQuery(
    SEARCH_CUSTOMER,
    {
      fetchPolicy: "network-only"
    }
  );

  const [addDrivers, { loading: addDriversLoading }] = useMutation(ADD_DRIVERS, {
    onCompleted: (data) => {
      if (data && data.addDrivers) {
        const driverIds = data.addDrivers.map((driver: any) => driver.id);
        const existingIds = newlyAddedDrivers.filter(val => val.id).map(driver => driver.id);

        const updatedApprovedDrivers = [...props.approvedDrivers, ...driverIds, ...existingIds];
        if (props.customer) {
          props.updateCustomer({
            approvedDrivers: updatedApprovedDrivers,
            customer: props.customer
          });
          const existingDrivers = newlyAddedDrivers.filter((val) => val.id);
          const updatedDriversArr = [...existingDrivers, ...data.addDrivers]
          setNewlyAddedDrivers(updatedDriversArr)
          props.newAddedDrivers(updatedDriversArr)
        }
      }
      snackbar({
        message: "Customer saved successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setUpdateInProgress(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (searchCustomerData && searchCustomerData.searchCustomer) {
      setCustomers(searchCustomerData.searchCustomer);
    }
  }, [searchCustomerData])

  useEffect(() => {
    if (props.addedDrivers?.length) {
      setNewlyAddedDrivers(props.addedDrivers)
      setDriverCount(props.addedDrivers?.length)
    }
  }, [props.addedDrivers])

  useEffect(() => {
    if (query) {
      handleSearch(query)
    } else {
      setCustomers([])
    }
  }, [query])

  useEffect(() => {
    if (props.businessCustomer) {
      loadBusinessCustomer({
        variables: {
          businessCustomerId: props.businessCustomer
        }
      });
      props.loadingCustomer(true)
    }
  }, [props.businessCustomer]);

  useEffect(() => {
    if (props.customer) {
      loadCustomer({
        variables: {
          customerId: props.customer
        }
      });
      props.loadingCustomer(true)
    }
  }, [props.customer]);

  useEffect(() => {
    if (businessCustomerData && businessCustomerData.businessCustomer) {
      props.loadingCustomer(false)
      setBusinessCustomer(businessCustomerData.businessCustomer);
      props.updateBusinessCustomerData(businessCustomerData.businessCustomer);
    }
  }, [businessCustomerData]);

  useEffect(() => {
    if (customerData && customerData.customer) {
      props.loadingCustomer(false)
      if (!customers.some((item: ICustomer) => item.id === customerData.customer.id)) {
        setCustomers([...customers, customerData.customer]);
      }
      setValues(customerData.customer);
      props.setCustomerData(customerData.customer);
    }
  }, [customerData]);

  const handleSearch = (searchText: string) => {
    if (searchText.trim().length > 2) {
      searchCustomer({
        variables: {
          q: searchText.trim()
        }
      })
    } else {
      setCustomers([])
    }
  }

  const selectCustomer = (data: any) => {
    if (data) {
      if (userState.currentBranch.useThresholds &&
        userState.currentBranch.pointThreshold && userState.currentBranch.offenceThreshold &&
        data.license && data.license.offences && data.license.points &&
        (data.license.points >= userState.currentBranch.pointThreshold ||
          data.license.offences >= userState.currentBranch.offenceThreshold)
      ) {
        setOffenceDialog(true)
        setOffenceDialogData(data)
      }
      else {
        setValues(data);
        props.updateCustomer({ customer: data.id, customerData: data });
      }
    }
    setSelectedDrivers([]);
  };

  const addExistingDriver = (data: any) => {
    const isDriverAlreadyAdded = newlyAddedDrivers.some(driver => driver.email === data.email);

    if (isDriverAlreadyAdded) {
      return snackbar({
        message: "Selected driver already added.",
        variant: SnackBarVariant.ERROR
      });
    }

    if ((props.customer || props.businessCustomer && driverCount < props.driverCount)) {
      setNewlyAddedDrivers(prevDrivers => {
        const newDrivers = [...prevDrivers, data];
        props.newAddedDrivers(newDrivers);
        return newDrivers;
      });
      setDriverCount(prevCount => prevCount + 1);
      setUpdateInProgress(false)
    } else {
      return snackbar({
        message: "Additional Drivers cannot be more than Addon quantity",
        variant: SnackBarVariant.ERROR
      });
    }
  };


  const handleChange = _.debounce((searchText: string) => {
    setQuery(searchText);
  }, 500)

  const handleEditDriver = (driver: IDriverRow) => {
    const restDrivers = newlyAddedDrivers.filter((item) => item.email !== driver.email)
    const { verificationMethod, ...rest } = driver
    setSelectedDriver(rest);
    setAddDriver(true)
    setDriverCount(prevCount => prevCount - 1);
    setEnableEdit(true)
    setNewlyAddedDrivers(restDrivers)
  };
  const handleRemove = (addedDriver: any) => {
    const updatedDrivers = newlyAddedDrivers.filter((driver) => driver.email !== addedDriver.email)
    setNewlyAddedDrivers(updatedDrivers)
    setDriverCount(prevCount => prevCount - 1);
    props.newAddedDrivers(updatedDrivers);
    const updatedDriverIds: any[] = updatedDrivers.filter((obj) => obj.id).map(val => val.id)
    const approvedDriversId = updatedDriverIds?.length ? updatedDriverIds : [];
    const customer = values?.id ? props?.customer : props?.businessCustomer;
    props.updateCustomer({
      approvedDrivers: approvedDriversId,
      customer: customer
    });
    if (values && values?.id && values?.approvedDrivers?.length) {
      const existingDriver = values.approvedDrivers.some((val: any) => val.id === addedDriver?.id)
      const currentApprovedDrivers: any[] = values.approvedDrivers || [];
      if (!existingDriver) {
        setValues((prevValues) => ({
          ...prevValues,
          approvedDrivers: [...currentApprovedDrivers, addedDriver]
        }));
      }
    }
  }

  const handleSubmit = (newDriver: IDriverRow) => {
    const oldDrivers = values.id ? values.approvedDrivers : businessCustomer?.approvedDrivers
    let existingApprovedDriver = false;
    if (oldDrivers?.length) {
      existingApprovedDriver = oldDrivers.some((oldDriver: any) => oldDriver.email === newDriver.email)
    }
    if (existingApprovedDriver) {
      return snackbar({
        message: "Driver with this email is already added. Please select from existing drivers.",
        variant: SnackBarVariant.ERROR
      });
    }
    const existingDriver = newlyAddedDrivers.find((driver) => driver.email === newDriver.email)
    if (existingDriver) {
      return snackbar({
        message: "Selected driver already added.",
        variant: SnackBarVariant.ERROR
      });
    }
    setSelectedDriver(null)
    setAddDriver(false)
    setDriverCount(prevCount => prevCount + 1);
    setEnableEdit(false)
    setUpdateInProgress(false)
    setNewlyAddedDrivers((prevDrivers) => {
      const newDrivers = [...prevDrivers, newDriver];
      props.newAddedDrivers(newDrivers);
      return newDrivers;
    });
  };

  const addAllDrivers = () => {
    if (newlyAddedDrivers.length) {
      setUpdateInProgress(true)
      setAddDriver(false)
      if (props && props.businessCustomer) {
        const existingIds: any[] = newlyAddedDrivers.map((driver) => driver.id);
        if (existingIds.length) {
          props.updateCustomer({
            approvedDrivers: existingIds,
            businessCustomer: props.businessCustomer
          });
          snackbar({
            message: "Customer saved successfully",
            variant: SnackBarVariant.SUCCESS
          });
        }
      } else {
        if (props.customer) {
          const filteredArr = newlyAddedDrivers.filter((val: any) => !val.id)
          if (filteredArr.length) {
            const modifiedArr = filteredArr.map((row) => {
              row.verificationMethod = "MANUAL";
              return row;
            });
            if (!values.id) {
              return snackbar({
                message: "Please select customer to add driver.",
                variant: SnackBarVariant.ERROR
              });
            }

            if (values.id) {
              addDrivers({
                variables: {
                  customerId: values.id,
                  drivers: modifiedArr
                }
              });
            }
          } else {
            const existingIds: any[] = newlyAddedDrivers.filter((val) => val.id).map((driver) => driver.id);
            if (existingIds.length) {
              props.updateCustomer({
                approvedDrivers: existingIds,
                customer: props.customer
              });
              snackbar({
                message: "Customer saved successfully",
                variant: SnackBarVariant.SUCCESS
              });
            }
          }
        }
      }
    }
  }

  return (
    <Grid container>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} sm={5}>
          {props.customerType === "individual" ? (
            props.isUpdate ? (
              customers && customers.length > 0 ? (
                <TextField
                  value={`${values.firstName} ${values.lastName}`}
                  label="Selected Customer"
                  variant="outlined"
                  disabled
                  fullWidth
                />
              ) : (
                <CircularProgress />
              )
            ) : (
              customers ?
                (
                  <div>
                    <Autocomplete
                      id="combo-box-demo"
                      onChange={(event: any, newValue: any, reason: string) => {
                        if (newValue) {
                          selectCustomer(newValue);
                          setDriverCount(0)
                          setNewlyAddedDrivers(prevDrivers => {
                            const newDrivers: any[] = [];
                            props.newAddedDrivers(newDrivers);
                            return newDrivers;
                          });
                        }
                      }}
                      loading={searchCustomerLoading}
                      loadingText={"Loading customers..."}
                      noOptionsText={query.length > 2 && !customers.length ?
                        "No Customer found with search criteria" :
                        "Type to search customers"
                      }
                      options={customers}
                      getOptionLabel={(option: any) =>
                        `${option.firstName} ${option.lastName} ${option.email}`
                      }
                      value={values}
                      style={{ width: "100%" }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label={"Search for Customer"}
                          variant="outlined"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
                          value={values ?
                            `${values.firstName} ${values.lastName} ${values.email}` :
                            ""
                          }
                        />
                      )}
                    />
                    <Box mt={2}></Box>
                  </div>
                ) : (
                  <CircularProgress />
                )
            )
          ) : (
            businessCustomer && businessCustomer.contact &&
              businessCustomer.contact.email ? (
              <TextField
                value={
                  businessCustomer && businessCustomer.contact
                    ? businessCustomer.contact.email
                    : ""
                }
                label={"Selected Business Customer"}
                variant="outlined"
                disabled
                fullWidth
              />
            ) : (
              <CircularProgress />
            )
          )}
        </Grid>
        {!businessCustomer
          ? values?.approvedDrivers && props.driverCount > 0 &&
          values.approvedDrivers.length > 0 && (
            <Grid item xs={12} sm={4}>
              <SearchForm
                updateData={addExistingDriver}
                dataOptions={values.approvedDrivers}
                isDriver={true}
              />
            </Grid>
          )
          : businessCustomer.approvedDrivers && props.driverCount > 0 &&
          businessCustomer.approvedDrivers.length > 0 && (
            <Grid item xs={12} sm={4}>
              <SearchForm
                updateData={addExistingDriver}
                dataOptions={businessCustomer.approvedDrivers}
                isDriver={true}
              />
            </Grid>
          )}
      </Grid>
      <Grid item container xs={12}>
        {selectedDrivers.length > 0 && (
          <Grid item container xs={6}>
            <Typography variant={"subtitle1"}>Additional Drivers</Typography>
          </Grid>
        )}
        {(!businessCustomer && props.driverCount > 0) && (
          <Grid item container xs={6}>
            <Typography
              variant={"subtitle1"}
              onClick={() => {
                if (props.customerType === "individual" && driverCount >= props.driverCount) {
                  return snackbar({
                    message: "Additional Drivers cannot be more than Addon quantity",
                    variant: SnackBarVariant.ERROR
                  });
                }

                setAddDriver(true);
                setSelectedDriver(_.cloneDeep(defaultValues))
              }}
              style={{ cursor: "pointer" }}
            >
              + Add New Driver
            </Typography>
          </Grid>
        )}
        {addDriver && (
          <Grid item container xs={12}>
            <AddDrivers
              saveDriverLoading={false}
              handleSubmit={handleSubmit}
              openForm={() => setAddDriver(false)}
              selectedDriver={selectedDriver}
            />
          </Grid>
        )}
        {newlyAddedDrivers.length ? (<>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h4">Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4">Email</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4">Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newlyAddedDrivers.map((driver: IDriverRow) => (
                  <TableRow key={driver.id}>
                    <TableCell>{`${driver.firstName} ${driver.lastName}`}</TableCell>
                    <TableCell>{driver.email}</TableCell>
                    <TableCell>
                      {!driver.id && (
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          onClick={() => handleEditDriver(driver)}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                      <IconButton
                        color="secondary"
                        aria-label="delete"
                        disabled={enableEdit}
                        onClick={() => handleRemove(driver)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item container xs={12}>
            <Fab
              variant="extended"
              size="medium"
              style={{ margin: 10 }}
              disabled={updateInProgress || addDriversLoading}
              onClick={() => {
                addAllDrivers()
              }}
            >
              {addDriversLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
              Save Drivers
            </Fab>
          </Grid>
        </>) : <></>}
        {
          offenceDialog && (
            <ConfirmCustomerWithOffences
              open={offenceDialog}
              onCancel={() => {
                props.updateCustomer({ customer: "" });
                setValues(initialState.customer)
                setOffenceDialog(false)
              }}
              onConfirm={() => {
                setValues(offenceDialogData);
                props.updateCustomer({ customer: offenceDialogData.id });
                setOffenceDialog(false)
              }}
              offences={offenceDialogData.license.offences}
              pointThreshold={offenceDialogData.license.points}
            />
          )
        }
      </Grid>
    </Grid>
  );
};

export default ExistingCustomer;
