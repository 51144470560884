import { Box, Fab, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IVehiclePriceGroup } from '../../../../../reducers/vehiclePriceGroups/types'
import { IAppState } from '../../../../../store'
import { toCurrency } from '../../../../common/utils'

interface IDropEvent {
  startDate: string;
  endDate: string;
  bookingId: string;
  bookingScheduleId: string;
  vehicleGroupId: string;
  previousVehicle: string;
  currentVehicle: string;
  rateTypeName?: string;
  rateTypeDuration?: number;
  vehicleGropups?: IVehiclePriceGroup[];
}

interface IProps {
  dropEventData?: IDropEvent;
  handleClose(type?: boolean): void;
  shiftBookingSchedule(values: IDropEvent): void
}

const initialValues: IDropEvent = {
  rateTypeDuration: 0,
  rateTypeName: "",
  startDate: "",
  endDate: "",
  bookingId: "",
  bookingScheduleId: "",
  vehicleGroupId: "",
  previousVehicle: "",
  currentVehicle: "",
  vehicleGropups: []
}
export const DragAndDropVehicleGroups: React.FC<IProps> = (props) => {

  const { dropEventData, handleClose, shiftBookingSchedule } = props

  const [values, setValues] = useState<IDropEvent>(initialValues)

  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;

  useEffect(() => {
    if (dropEventData) {
      setValues({ ...values, ...dropEventData })
    }
  }, [dropEventData])

  return (
    <Grid container item xs={12} style={{ margin: "15px 0px" }}>
      {
        dropEventData && dropEventData?.vehicleGropups && dropEventData?.vehicleGropups?.length > 0 ?
          (
            <Formik
              enableReinitialize
              initialValues={values}
              onSubmit={(values) => {
                shiftBookingSchedule(values)
                handleClose()
              }}
            >
              {() => (
                <Form>
                  <Grid container item xs={12} spacing={2}>
                    <Grid container item xs={6}>
                      {
                        dropEventData.rateTypeName && (
                          <Field
                            component={TextField}
                            name={"vehicleGroup"}
                            fullWidth
                            required
                            type="text"
                            select
                            label="Vehicle Group"
                            inputProps={{
                              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                const selectedVehicleGroup = dropEventData.vehicleGropups && dropEventData.vehicleGropups.find((vg) => {
                                  return vg.id === event.target.value
                                })
                                if (selectedVehicleGroup) {
                                  const basePrice = selectedVehicleGroup.basePrices.find((bp) => bp.rateTypeDuration === values.rateTypeDuration)
                                  if (basePrice && values.currentVehicle && selectedVehicleGroup.id) {
                                    setValues({
                                      ...values,
                                      vehicleGroupId: selectedVehicleGroup.id

                                    })
                                  }
                                }
                              }
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                            {dropEventData.vehicleGropups && dropEventData.vehicleGropups.map(
                              (vehicleGroup, index: number) => {
                                const basePrice = vehicleGroup.basePrices.find((bs) => bs.rateTypeDuration === values.rateTypeDuration)
                                if (basePrice) {
                                  return (
                                    <MenuItem key={index} value={vehicleGroup.id}>
                                      {vehicleGroup.name} - Rate{" "}
                                      {toCurrency(basePrice.rate, currency, locale)}
                                    </MenuItem>)
                                }
                              }
                            )}
                          </Field>
                        )
                      }
                    </Grid>
                    <Grid container item xs={12}>
                      <Fab
                        variant={"extended"}
                        size={"medium"}
                        type="submit"
                      >
                        Confirm
                      </Fab>
                      <Box pl={2}></Box>
                      <Fab
                        variant={"extended"}
                        size={"medium"}
                        className={"blackBackButton"}
                        onClick={() => handleClose(true)}
                      >
                        Cancel
                      </Fab>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          ) : (
            <Grid>
              <Typography variant={"h3"}>No Vehicle Group found</Typography>
            </Grid>
          )
      }
    </Grid>
  )
}
