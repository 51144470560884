import { IBooking } from '../../../reducers/bookings/types';
import { IVehicle } from '../../../reducers/fleet/types';
import { IBranch } from '../../../reducers/organisation/types';
import { IServiceLocation, IUserState } from '../../../reducers/user/types';
import { getLocalizedBookingSyntex } from '../../../utils/localized.syntex';

export interface IBranchConnection {
  id?: string;
  externalBranches: IExternalBranch[];
  oneWayRentalFee: IOneWayRentalFee;
  sharedFleetData: any[];
  vehicleIds?: string[];
  isActive: boolean
}

export interface ISharedFleetData {
  vehicleGroup: {
    id: string,
    name: string
  },
  vehicles: IVehicle[]
}

export interface IExternalBranch {
  branchId: string;
  branch?: IBranch;
  transitTime: number;
  rentalCostType: RENTAL_COST_TYPE
  fuelCost: number
  driverCost: number
}

export interface IOneWayRentalFee {
  amount: number;
  taxable: boolean;
  tax: ITax
}

export interface ITax {
  title: string;
  type: string;
  value: number;
}

export enum RENTAL_COST_TYPE {
  ZERO_COST = "ZERO_COST"
}

export interface IFleetMovement {
  id?: string;
  bookingId: string;
  booking?: Partial<IBooking>;
  branch: Partial<IBranch>;
  branchId: string;
  driver: IUserState;
  estimatedCheckout: string;
  estimatedDelivery: string;
  eventStart: string;
  externalBranch: Partial<IBranch>;
  externalBranchId: string;
  movementSource: FleetMovementSource;
  referenceNumber: string;
  status: FleetMovementStatus
  vehicleId: string;
  vehicle: Partial<IVehicle>;
  vehicleServiceId: string;
  transitTime: number;
  source: Partial<IServiceLocation>
  destination: Partial<IServiceLocation>
  jobType?: string;
  taskDate?: string;
}

export enum FleetMovementSource {
  BOOKING = 'BOOKING',
  SERVICE = 'SERVICE',
  FLEET_TRANSFER = 'FLEET_TRANSFER'
}

export const FleetMovementSourceValue: {
  [key in FleetMovementSource]: string;
} = {
  BOOKING: "Booking",
  SERVICE: "service",
  FLEET_TRANSFER: "Fleet Transfer"
};

export const getLocalizedFleetMovementSourceValue = (key: FleetMovementSource, country: string): string => {
  const circumstances: {
    [key in FleetMovementSource]: string;
  } = {
    BOOKING: `${getLocalizedBookingSyntex(country)}`,
    SERVICE: "service",
    FLEET_TRANSFER: "Fleet Transfer"
  }
  return circumstances[key];
}

export enum FleetMovementStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  CHECKOUT_COMPLETE = "CHECKOUT_COMPLETE",
  CHECKIN_COMPLETE = "CHECKIN_COMPLETE",
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED"
}

export const FleetMovementStatuseValue: {
  [key in FleetMovementStatus]: string;
} = {
  PENDING: "Pending",
  CONFIRMED: "Confirmed",
  CHECKOUT_COMPLETE: "Checkout Complete",
  CHECKIN_COMPLETE: "Checkin Complete",
  IN_TRANSIT: "In Transit",
  DELIVERED: "Delivered",
  CANCELLED: "Cancelled"
};

interface IMovementLocation {
  id: string;
  name: string;
}

export interface IConfirmedMovementUpdateArgs {
  driver: IUserState;
  estimatedCheckout: string;
  source: IMovementLocation;
  destination: IMovementLocation;
  estimatedDelivery: string;
  jobType?: string;
  taskDate?: string;
}