import { gql } from "@apollo/client";

export const CREATE_PROCESS_FOR_IDV_CHECK = gql`
  mutation createNewProcessForIDV($customerId: String!) {
    createNewProcessForIDV(customerId: $customerId){
        id
        status
        firstName
        lastName
        taxExempted
        gender
        verifiedDate
        phoneNumber {
          phone
          country
        }
        secondaryPhoneNumber {
          phone
          country
        }
        email
        address
        secondaryEmail
        dateOfBirth
        location {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        license {
          licenseNumber
          dateOfExpiry
          authority
          endorsement
          images
          offences
          points
          dvlaVerificationStatus
        }
        verified
        verificationMethod
        profilePicture
        nationalInsuranceNumber
        profession
        deleted
        documents {
          id
          documentKey
          documentName
          title
          expiryDate
          documentType
          createdAt
        }
        approvedDrivers {
          id
          firstName
          lastName
          email
          status
          phoneNumber {
            phone
            country
          }
          license {
            licenseNumber
            dateOfExpiry
            authority
            images
          }
          phoneNumber {
            phone
            country
          }
        }
        customerPCONumber
        notes {
          id
          description
          createdBy
          createdDate
        }
        accountNumber
        credasIdentityVerification {
          entityId
          processId
          lastUpdated
          verificationMethod
          verificationStatus
        }
        creditSafeData {
          creditScore
          lastChecked
        }
    }
  }
`;