import List from "@mui/material/List";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import coastrLogo from "../../../assets/icons/coastr_logo.svg";
import { IAppState } from "../../../store";
import { Authorization, Null } from "../../hoc/Authorization";
import AppMenuItem from "./AppMenuItem";
import { NavBarModules } from "./constants";
import { getSignedUrl } from '../../../utils/getSignedUrl';

const AppMenu = () => {
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [logoURL, setLogoURL] = useState<any>("");
  const [menuList, setMenuList] = useState<any>([]);

  useEffect(()=>{

    const disabledRoutes: string[] = [];
    if(userState.role !== "SUPER_ADMIN"){
      disabledRoutes.push("Management Reports");
    }

    const filteredNavBarModules = NavBarModules.filter((item: any)=> disabledRoutes.includes(item.name) ? false : true );

    const filteredNavBarMenuList = filteredNavBarModules.map((item: any, index: number) => {
      const NullAppMenuItem = Authorization(item.allowed)(Null)(AppMenuItem);
      return <NullAppMenuItem
        {...item}
        key={index}
      />;
    });

    setMenuList(filteredNavBarMenuList);
  }, [userState]);

  useEffect(() => {
    if (userState.currentTenancy.appLogo) {
      getLogoUpload(userState.currentTenancy.appLogo)
    }
  }, [userState.currentTenancy.appLogo])

  const getLogoUpload = async (logoUrl: string) => {
    const config = { contentType: "image/*", level: "public" };
    const file = await getSignedUrl(logoUrl);
    setLogoURL(file);
  };

  return (
    <>
      <div className={"user-section"}>
        {userState.currentTenancy.appLogo ? logoURL ? <img
          className={classes.iconStyle}
          src={logoURL}
          height="auto"
          width={160}
          alt={"Logo"}
        ></img> : <div style={{ height: 80 }} /> : <img
          className={classes.iconStyle}
          src={coastrLogo}
          height={60}
          width={160}
          alt={"coastr-logo"}
        ></img>
        }
      </div>
      <List component="nav" className={classes.appMenu} disablePadding>
        {menuList}
        <div style={{ height: 100 }}></div>
      </List>
    </>
  );
};

const drawerWidth = 200;

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: "100%"
    },
    iconStyle: {
      "&:hover": {
        color: "#fff"
      }
    },
    navList: {
      width: drawerWidth
    },
  })
);

export default AppMenu;
