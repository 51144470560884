import { gql } from "@apollo/client";

export const UPLOAD_BUSINESS_CUSTOMER = gql`
  mutation uploadBusinessCustomer(
    $businessCustomers: [BusinessCustomerUploadInput]!
  ) {
    uploadBusinessCustomer(businessCustomers: $businessCustomers) {
      errors {
        index
        message
      }
      businessCustomers {
        id
        businessName
        registrationNumber
        VAT
        registeredAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        tradingAddressSame
        tradingAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        approvedDrivers {
          id
          status
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        authorizedSignatories {
          id
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        billing {
          phoneNumber {
            phone
            country
          }
          email
        }
        contact {
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        documents {
          id
          name
          title
          documentType
          expiryDate
          url
          createdAt
        }
        notes {
          id
          description
          createdBy
          createdDate
        }
      }
    }
  }
`;
