import "./index.scss";
import {
  Grid,
  Theme,
  Typography,
  Hidden,
  Paper
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../../../../common/NuvvenTable/types";
import { IVehicle } from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { viewColumns } from "./constants";
import { DATE_TYPE, toCurrency } from '../../../../../common/utils';
import { INewVehicleDamage } from "../../../Issues&Damages/NewDamage";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { MobileDamageCard } from "./MobileDamageCard";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";

interface IProps {
  vehicle: IVehicle;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    green: {
      color: "#00ff00"
    },
    header: {
      color: "rgb(45, 157, 184)",
      fontSize: 20
    },
    label: {
      color: "grey",
      fontSize: 12,
      fontWeight: 100,
      marginLeft: 20
    },
    paper: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      textAlign: "center"
    },
    red: {
      color: "#ff0000"
    },
    root: {
      padding: theme.spacing(3)
    }
  })
);

export const DamageView = (props: IProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const classes = useStyles();
  const [vehicleDamages, setVehicleDamages] = useState<INewVehicleDamage[]>([]);

  useEffect(() => {
    if (props.vehicle && props.vehicle.damages) {
      const vehicleDamages = props.vehicle.damages.map((damages: any) => {
        return {
          ...damages,
          reportDate: getLocalizedDateFormat(country, damages.reportDate, DATE_TYPE.EXPANDED),
          incidentDate: getLocalizedDateFormat(country, damages.incidentDate, DATE_TYPE.EXPANDED),
          estimatedCost: toCurrency(parseInt(damages.estimatedCost), currency, locale)
        };
      });
      setVehicleDamages(vehicleDamages);
    }
  }, [props.vehicle]);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    elevation: 0,
    onRowClick: (rowData: string[], rowMeta: { dataIndex: number }) => {
      if (rowData && rowData.length && props?.vehicle?.damages) {
        const damageClicked = props.vehicle.damages[rowMeta.dataIndex];
        if (damageClicked) {
          window.open(`/update-damage?damage=${damageClicked.id}`, "_blank");
        }
      }
    },
  }

  return (
    <Grid container className={classes.root}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">DAMAGE HISTORY</Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <NuvvenTable
                title={""}
                rows={vehicleDamages}
                columns={viewColumns}
                options={options}
              />
            </Paper>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          {(vehicleDamages.length === 0) && (
            <Grid xs={12} item>
              <Typography variant="h4">
                No Damage Data
              </Typography>
            </Grid>
          )}
          {vehicleDamages && vehicleDamages.map((vehicleDamage: INewVehicleDamage, index: number) => {

            return (
              <Grid container item xs={12} justifyContent='center'>
                <Grid item>
                  <MobileDamageCard row={vehicleDamage} index={index} />
                </Grid>
              </Grid>
            )
          })}
        </Hidden>
      </Grid>
    </Grid>
  );
};
