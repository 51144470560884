import { gql } from "@apollo/client";

export const GET_VEHICLE_GROUP_CRITERIA = gql`
  query vehicleGroupCriteria {
    vehicleGroupCriteria {
      type
      values
    }
  }
`;
