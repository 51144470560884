import "./index.scss";
import React from "react";

interface IProps {
  history: any;
}

export default class VehicleStatus extends React.Component<any> {
  public render() {
    return (
      <div className="vehicle-status-container">
        <main className="content">
          <h1> Vehicle Status</h1>
        </main>
      </div>
    );
  }
}
