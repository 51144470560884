import { Value } from "react-phone-number-input";

import { BusinessCustomerStatus, ICustomerState } from "./types";

export const VERIFICATION_METHODS = {
  MANUAL: "MANUAL",
  PASSBASE: "PASSBASE"
};

export const initialState: ICustomerState = {
  customer: {
    dateOfBirth: "",
    deleted: false,
    documents: [],
    email: "",
    address: "",
    firstName: "",
    gender: "",
    id: "",
    lastName: "",
    location: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    notes: [],
    phoneNumber: { phone: "" as Value, country: "GB" },
    secondaryEmail: "",
    secondaryPhoneNumber: { phone: "" as Value, country: "GB" },
    status: "",
    verified: false,
    verificationMethod: VERIFICATION_METHODS.MANUAL,
    license: {
      authority: "",
      dateOfExpiry: "",
      dateOfIssue: "",
      endorsement: "",
      licenseNumber: "",
      images: []
    },
    credasIdentityVerification: {
      entityId: "",
      processId: "",
      lastUpdated: "",
      verificationMethod: "",
      verificationStatus: ""
    },
    overrideInvoiceDate: false,
    overrideWeeklyInvoiceDate: false,
    invoicingDay: undefined,
    weeklyInvoicingDay: undefined
  },
  businessCustomer: {
    id: "",
    businessName: "",
    accountManager: {
      id: "",
      firstName: "",
      lastName: ""
    },
    registrationNumber: "",
    VAT: "",
    registeredAddress: {
      city: "",
      country: "",
      fullAddress: "",
      street: "",
      state: "",
      zipcode: ""
    },
    tradingAddressSame: false,
    tradingAddress: {
      city: "",
      country: "",
      fullAddress: "",
      street: "",
      state: "",
      zipcode: ""
    },
    approvedDrivers: [],
    authorizedSignatories: [],
    billing: {
      phoneNumber: {
        phone: "" as Value,
        country: ""
      },
      email: "",
      accountNumber: ""
    },
    contact: {
      firstName: "",
      lastName: "",
      phoneNumber: {
        phone: "" as Value,
        country: ""
      },
      email: ""
    },
    documents: [],
    notes: [],
    overrideNetPaymentTerms: false,
    netPaymentTerms: 30,
    status: BusinessCustomerStatus.PENDING,
    declinedReason: "",
    overrideInvoiceDate: false,
    overrideWeeklyInvoiceDate: false,
    invoicingDay: undefined,
    weeklyInvoicingDay: undefined
  },
  authorisedSignatory: {
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: {
      phone: "" as Value,
      country: ""
    },
    email: "",
    status: ""
  },
  approvedDriver: {
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: {
      phone: "" as Value,
      country: ""
    },
    email: "",
    nationalInsuranceNumber: "",
    profession: "",
    status: "",
    license: {
      authority: "",
      licenseNumber: "",
      dateOfExpiry: "",
      dateOfIssue: "",
      endorsement: "",
      images: []
    },
    location: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    }
  }
};

export enum DvlaVerificationStatus {
  PENDING = "pending",
  SUCCEEDED = "succeeded",
  FAILED = "failed"
}
