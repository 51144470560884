import { gql } from "@apollo/client";

export const UPDATE_VEHICLE_DAMAGE = gql`
  mutation updateVehicleDamage(
    $vehicleId: String!
    $damageId: String!
    $vehicledamage: UpdateVehicleDamageInput!
  ) {
    updateVehicleDamage(
      vehicleId: $vehicleId
      damageId: $damageId
      vehicledamage: $vehicledamage
    ) {
      id
      vehicleId
      circumstance
      bookingRef
      reportDate
      incidentDate
      reportedBy
      frogDiagram
      approvalStatus
      estimatedCost
      status
      damageSeverity
      estimatedRepairDate
      damages {
        title
        descriptions
        damageType
        damageKind
        damageArea
        images
        location {
          x
          y
        }
      }
    }
  }
`;
