import { ApolloError, useMutation } from "@apollo/client";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { loadStripe } from "@stripe/stripe-js";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CHARGE_CARD_AGAINST_INVOICE } from "../../../graphql/invoices/chargeCardAgainstInvoiceMutation";
import { CREATE_PAYMENT } from "../../../graphql/invoices/createPaymentMutation";
import { IPaymentInput, PaymentMode } from "../../../reducers/invoices/types";
import { IAppState } from "../../../store";
import { loadConvergeUS } from "../../../utils/converge";
import { CREATE_PAYMENT_MESSAGE } from "../../views/Invoices/UpdateInvoice/messages";
import { Payment } from "../../views/ReservationManagement/VehicleTransaction/VehicleChecks/common/Payment";
import { useSnackBar } from "../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../SnackbarWrapper/SnackbarWrapper";
import {
  PaymentGateway,
  formatGraphQLErrorMessage,
  toCurrency
} from "../utils";

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: "relative",
    minWidth: "600px",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "25px",
    paddingRight: "25px",
    overflowX: "hidden"
  },
  dialog: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  summary: {
    display: "flex",
    paddingTop: "10px",
    paddingBottom: "20px"
  },
  title: {
    fontSize: 12
  },
  pos: {
    marginBottom: 12
  },
  loadingGrid: {
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    margin: theme.spacing(1)
  }
}));

interface IProps {
  open: boolean;
  dueAmount: number;
  invoiceId: string;
  invoiceRef: string;
  bookingId: string;
  setOpen: (open: boolean) => void;
  refreshInvoice: (invoiceId: string) => void;
}

interface IPaymentModeOptions {
  label: string;
  paymentMode: PaymentMode;
}

interface IPaymentGatewayOptions {
  label: string;
  paymentGateway: PaymentGateway;
}

const newCardInitialValues = {
  paymentMode: PaymentMode.CARD,
  paymentGateway: ""
};

export const AutoChargePaymentOptionsDialog: React.FC<IProps> = ({
  open,
  dueAmount,
  invoiceId,
  bookingId,
  invoiceRef,
  setOpen,
  refreshInvoice
}) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const {
    locale,
    currency,
    stripeAccountId,
    sepaEnabled,
    bacsEnabled,
    cardEnabled,
    convergeEnabled,
    convergeAutoChargeAvailable 
  } = userState.currentOrganisation;
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [showOptions, setOptionsView] = useState<boolean>(true);
  const [showMethods, setMethodsView] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>(
    "Payment Method for Auto Charge"
  );
  const [paymentModes, setPaymentModes] = useState<IPaymentModeOptions[]>([]);
  const [paymentGateways, setPaymentGateways] = useState<
    IPaymentGatewayOptions[]
  >([]);
  const [paymentGateway, setPaymentGateway] = useState<PaymentGateway>(
    PaymentGateway.STRIPE
  );

  const stripePromise: any = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
    {
      stripeAccount: stripeAccountId
    }
  );

  const [chargeCardAgainstInvoice] = useMutation(CHARGE_CARD_AGAINST_INVOICE, {
    onCompleted: (data) => {
      if (data?.chargeCardAgainstInvoice) {
        setLoading(false);
        setOpen(false);
        refreshInvoice(invoiceId);
      }
      snackBar({
        message: CREATE_PAYMENT_MESSAGE,
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      setOpen(false);
      snackBar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createPayment] = useMutation(CREATE_PAYMENT, {
    onCompleted: async (data) => {
      if (data.createPayment.payment.stripeCheckoutSessionId) {
        // Get Stripe.js instance
        const stripe = await stripePromise;
        // When the customer clicks on the button, redirect them to Checkout.
        if (stripe) {
          setLoading(false);
          const result = await stripe.redirectToCheckout({
            sessionId: data.createPayment?.payment?.stripeCheckoutSessionId
          });
          if (result.error && result.error.message) {
            snackBar({
              message: result.error.message,
              variant: SnackBarVariant.ERROR
            });
          }
        }
      }
      if (
        data.createPayment.payment.id &&
        paymentGateway === PaymentGateway.STRIPE
      ) {
        const stripe = await stripePromise;
        if (stripe) {
          navigate(
            `/payment?invoice=${invoiceId}&payment=${data.createPayment.payment.id}`
          );
        }
      } else if (data.createPayment.payment.convergeUSSessionToken) {
        loadConvergeUS({
          paymentSessionToken: data.createPayment.payment.convergeUSSessionToken,
          onloadFunction() {
            setLoading(false);
          },
          redirectFunction() {
            navigate(`/update-billing?invoice=${invoiceId}`)
          }}
        );
        setOpen(false);
      }
    },
    onError: (error: ApolloError) =>
      snackBar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const handleDialogClose = () => {
    setLoading(false);
    setOptionsView(true);
    setMethodsView(false);
    setOpen(false);
  };

  const handleSavedCardMethod = () => {
    setLoading(true);
    setOptionsView(false);
    setDialogTitle("Processing Payment");
    chargeCardAgainstInvoice({
      variables: {
        invoiceId: invoiceId
      }
    });
  };

  const handleNewCardMethod = () => {
    let availablePaymentModes = [];
    let availablePaymentGateways = [];
    if (cardEnabled) {
      availablePaymentModes.push({
        label: "Card",
        paymentMode: PaymentMode.CARD
      });
    }
    if (bacsEnabled && currency === "GBP") {
      availablePaymentModes.push({
        label: "BACS Direct Debit",
        paymentMode: PaymentMode.BACS_BANK_DEBIT
      });
    }
    if (sepaEnabled && currency == "EUR") {
      availablePaymentModes.push({
        label: "SEPA Bank Debit",
        paymentMode: PaymentMode.SEPA_BANK_DEBIT
      });
    }
    if (convergeEnabled && convergeAutoChargeAvailable) {
      availablePaymentGateways.push({
        label: "Converge",
        paymentGateway: PaymentGateway.CONVERGE
      });
    }
    if (stripeAccountId) {
      availablePaymentGateways.push({
        label: "Stripe",
        paymentGateway: PaymentGateway.STRIPE
      });
    }
    setPaymentGateways(availablePaymentGateways);
    setPaymentModes(availablePaymentModes);
    setDialogTitle("Choose Payment Mode");
    setOptionsView(false);
    setMethodsView(true);
  };

  const handleSubmit = (values: any) => {
    setMethodsView(false);
    setLoading(true);
    setPaymentGateway(values.paymentGateway);
    const payment: IPaymentInput = {
      paymentMode: values.paymentMode,
      paymentType: "INWARD",
      amount: dueAmount,
      currency: currency,
      description: "",
      expireBy: "",
      invoice: invoiceId,
      note: "",
      booking: bookingId,
      paymentGateway: values.paymentGateway,
      successUrl: `${window.location.protocol}//${window.location.host}/update-billing?invoice=${invoiceId}&status=success`,
      cancelUrl: `${window.location.protocol}//${window.location.host}/update-billing?invoice=${invoiceId}&status=failure`
    };
    createPayment({
      variables: {
        payment,
        saveDetails: true
      }
    });
  };

  return (
    <Dialog
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="auto-charge-payment-method-dialog"
    >
      <div className={`${classes.root} mobile`}>
        <div className={classes.dialog}>
          <Typography variant="h2" className="bold">
            {dialogTitle}
          </Typography>

          <IconButton
            onClick={() => handleDialogClose()}
            aria-label="auto-charge-payments-dialog-close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.summary}>
          <Grid container direction="row" spacing={3} alignItems="flex-start">
            <Grid item>
              <Typography variant="h4" style={{ fontWeight: "initial" }}>
                Total payable amount
              </Typography>
              <Typography variant="h2" className="bold text-accent">
                {toCurrency(dueAmount, currency, locale)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        {loading && (
          <div className={classes.loadingGrid}>
            <CircularProgress />
          </div>
        )}
        {showMethods && (
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Formik
              initialValues={newCardInitialValues}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
                setSubmitting(false);
              }}
            >
              {(formikProps) => (
                <Form style={{ width: "100%" }}>
                  <Grid item container spacing={2}>
                    <Grid item container xs={12} direction={"row"}>
                      <Grid container item xs={12}>
                        <Grid item xs={12}>
                          <Typography variant="h3" component="h3">
                            Select Payment Mode
                          </Typography>
                        </Grid>
                        <Grid item container xs={12}>
                          <RadioGroup
                            row
                            aria-label="paymentMode"
                            name="paymentMode"
                            onChange={(event) => {
                              formikProps.setValues({
                                paymentGateway: PaymentGateway.STRIPE,
                                paymentMode: event.target.value as PaymentMode
                              });
                            }}
                            value={formikProps.values.paymentMode}
                          >
                            {paymentModes.map((element) => (
                              <FormControlLabel
                                value={element.paymentMode}
                                control={<Radio />}
                                label={element.label}
                              />
                            ))}
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      {formikProps.values.paymentMode === PaymentMode.CARD && (
                        <Grid container item xs={12}>
                          <Grid item xs={12}>
                            <Typography variant="h3" component="h3">
                              Select Payment Gateway
                            </Typography>
                          </Grid>
                          <Grid item container xs={12}>
                            <RadioGroup
                              row
                              aria-label="paymentGateway"
                              name="paymentGateway"
                              onChange={formikProps.handleChange}
                              value={formikProps.values.paymentGateway}
                            >
                              {paymentGateways.map((element) => (
                                <FormControlLabel
                                  value={element.paymentGateway}
                                  control={<Radio />}
                                  label={element.label}
                                />
                              ))}
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid container item xs={12} direction={"row"}>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          endIcon={
                            <ArrowForwardIcon style={{ fontSize: 24 }} />
                          }
                        >
                          Proceed with Payment
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <FormHelperText>
                          Selected Payment mode will be used for future auto
                          charges.
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        )}
        {showOptions && (
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="flex-start"
          >
            <Grid item style={{ width: "100%" }}>
              <Card onClick={handleSavedCardMethod}>
                <CardActionArea>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography variant="h2" component="h2">
                          Charge against Saved Card
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ArrowForwardIcon style={{ fontSize: 24 }} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item style={{ width: "100%" }}>
              <Card onClick={handleNewCardMethod}>
                <CardActionArea>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography variant="h2" component="h2">
                          Charge against New Card
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          New card will be saved for future auto charges.
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ArrowForwardIcon style={{ fontSize: 24 }} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        )}
      </div>
    </Dialog>
  );
};
