import { gql } from "@apollo/client";

export const RESENT_INVOICE_TO_SAGE = gql`
  mutation resendInvoiceToSage($invoiceId: String!) {
    resendInvoiceToSage(invoiceId: $invoiceId){
        success
        message
    }
  }
`;

export const SEND_INVOICE_TO_XERO = gql`
  mutation sendInvoiceToXero($invoiceId: String!) {
    sendInvoiceToXero(invoiceId: $invoiceId){
        success
        message
    }
  }
`;

export const SEND_CREDIT_NOTE_TO_XERO = gql`
  mutation createXeroCreditNote($creditNoteId: String!) {
    createXeroCreditNote(creditNoteId: $creditNoteId){
        success
        message
    }
  }
`;
