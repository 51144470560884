import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, CircularProgress, Fab, Grid, IconButton, Paper, TextField, Theme, Tooltip, Typography } from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ChangeEvent, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-mui";
import { useLocation } from "react-router";
import { GET_BOOKING } from "../../../../graphql/bookings/getBookingQuery";
import { IBooking } from "../../../../reducers/bookings/types";
import { SummaryField } from "../UpdateInvoice/InvoiceComponents/SummaryField";
import { IInvoiceItemCharge, IInvoiceItemType } from "../utils";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { CREATE_SUNDRY_INVOICE } from "../../../../graphql/invoices/createSundryInvoice";
import { useNavigate } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import {
  checkDeleteOrDownloadFileType,
  checkUploadFileFormat,
  formatGraphQLErrorMessage,
  uploadFileExtensionAndContentType,
  returnSortedDocuments,
  DATE_TYPE
} from "../../../common/utils";
import { FloatInput } from "../../../common/FloatInput/FloatInput";
import { DocumentDialog } from "../../CustomerManagement/CustomerDetails/DocumentDialog";
import { v4 as uuidv4 } from "uuid";
import { IInvoiceDocument } from "../../../../reducers/invoices/types";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { UserRoles } from "../../../hoc/Authorization";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { ConfirmationDialog } from "../../../common/ConfirmationDialog/ConfirmationDialog";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { getLocalizedBookingSyntex, getLocalizedDateFormat, getLocalizedTaxSyntex } from "../../../../utils/localized.syntex";
import { captureErrorException } from "../../../../utils/sentry";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3)
    },
    tableContainer: {
      border: "solid 1px rgba(224, 224, 224, 1)",
      marginTop: "1rem"
    }
  })
);


interface IViewDocumentProps {
  documents: IInvoiceDocument[];
  downloadDocument(documentUrl: string, documentName: string): void;
  deleteDocument(url: string, index: number | undefined): void;
}

const NewSundryInvoice: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const taxRate = 0;
  const [booking, setBooking] = useState<IBooking>();
  const [charges, setCharges] = useState<IInvoiceItemCharge[]>([{
    type: IInvoiceItemType.OTHER_CHARGE,
    description: "",
    unitPrice: 0,
    quantity: 1,
    totalAmount: 0,
    taxRate,
    taxAmount: 0
  }]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isBusinessCustomerBooking, setIsBusinessCustomerBooking] = useState<boolean>();
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [documents, setDocuments] = useState<IInvoiceDocument[]>([]);
  const [invoiceNote, setinvoiceNote] = useState("");
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [
    loadBooking,
    { loading: bookingLoading, data: bookingInfoData }
  ] = useLazyQuery(GET_BOOKING, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.booking) {
        navigate("/invoices");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createSundryInvoice] = useMutation(CREATE_SUNDRY_INVOICE, {
    onCompleted: (data) => {
      if (data && data.createSundryInvoice && data.createSundryInvoice.id) {
        setLoading(false);
        snackbar({
          message: "Sundry Invoice created successfully",
          variant: SnackBarVariant.SUCCESS
        });
        navigate(`/update-billing?invoice=${data.createSundryInvoice.id}`);
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  })

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const bookingId = params.get("booking");
      if (bookingId) {
        loadBooking({
          variables: {
            id: bookingId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (bookingInfoData && bookingInfoData.booking) {
      setBooking(bookingInfoData.booking);
      const isBusinessCustomerBooking =
        bookingInfoData.booking.customerType === "business" &&
          bookingInfoData.booking.businessCustomer &&
          bookingInfoData.booking.businessCustomer.id !== ""
          ? true
          : false;
      setIsBusinessCustomerBooking(isBusinessCustomerBooking);
    }
  }, [bookingInfoData]);

  const addRows = () => {
    const rowsArr = [...charges];
    rowsArr.push({
      type: IInvoiceItemType.OTHER_CHARGE,
      description: "",
      unitPrice: 0,
      quantity: 1,
      totalAmount: 0,
      taxRate,
      taxAmount: 0
    });
    setCharges(rowsArr);
  };

  async function handleDocumentSave(file: File, title: string, documentType: string, reminderBefore: number, expiryDate: string) {
    try {
      // 50MB limit exceeded
      if (file.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      const fileName = file.name.split(".")[0];
      setUploadInProgress(true);
      if (checkUploadFileFormat(file.type)) {
        const uniqueId = uuidv4();
        const {
          fileExtension,
          contentType
        } = uploadFileExtensionAndContentType(file.type);
        const key = `${uniqueId}-sundry-invoice-document.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                setDocuments([{
                  documentUrl: key,
                  title,
                  documentName: fileName,
                  expiryDate,
                  documentType,
                  reminderBefore
                }, ...documents]);
                setDocumentDialogVisible(false);
                setUploadInProgress(false);
              }
            });
        }
      } else {
        setDocumentDialogVisible(false);
        setUploadInProgress(false);
        return snackbar({
          message: "Please only upload .pdf!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  const downloadDocument = async (
    documentUrl: string,
    documentName: string
  ) => {
    const { fileExtension, contentType } = checkDeleteOrDownloadFileType(
      documentUrl
    );
    const config = { contentType, level: "public" };
    const file = await getSignedUrl(documentUrl);
    fetch(file, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${documentName}.${fileExtension}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const deleteDocument = (
    url: string,
    index: number,
  ) => {
    const { contentType } = checkDeleteOrDownloadFileType(url);
    // TODO: implement file deleteion from s3
    if (index >= 0) {
      documents?.splice(index, 1);
    }
    snackbar({
      message: "Document removed sucessfully!.",
      variant: SnackBarVariant.SUCCESS
    });
  };

  const handleSubmit = () => {
    if (charges.some(charge => !charge.description)) {
      return snackbar({
        message: "Invoice item description cannot be empty!",
        variant: SnackBarVariant.ERROR
      });
    }
    if (charges.some(charge => !charge.unitPrice)) {
      return snackbar({
        message: "Invoice item price/rate should be greater than 0",
        variant: SnackBarVariant.ERROR
      });
    }
    const _charges = charges.map((charge) => {
      return {
        ...charge,
        totalAmount: charge.unitPrice * charge.quantity
      }
    });
    if (booking) {
      setLoading(true)
      createSundryInvoice({
        variables: {
          bookingId: booking.id,
          invoiceItems: _charges,
          documents,
          notes: invoiceNote
        }
      })
    }
  };

  if (bookingLoading || !booking) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12}>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h1" color="primary">
            Invoice
          </Typography>
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon />
          <Box color="white" sx={{ pl: 1 }}></Box>
          <Typography variant="h1" color="primary">
            {`Create Sundry Invoice`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Paper className={classes.root}>
          <Grid container xs={12}>
            <Grid container item xs={4} spacing={1}>
              <Grid item xs={12}>
                <SummaryField
                  label={`${getLocalizedBookingSyntex(country)} Reference`}
                  value={booking.referenceNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <SummaryField
                  label={`${getLocalizedBookingSyntex(country)} Start Date`}
                  value={getLocalizedDateFormat(country, booking.pickupDateTime || "", DATE_TYPE.EXPANDED)}
                />
              </Grid>
              <Grid item xs={12}>
                <SummaryField
                  label={`${getLocalizedBookingSyntex(country)} End Date`}
                  value={getLocalizedDateFormat(country, booking.dropoffDateTime || "", DATE_TYPE.EXPANDED)}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} spacing={1}>
              <Grid item xs={12}>
                <SummaryField
                  label="Customer Name"
                  value={
                    isBusinessCustomerBooking
                      ? booking.businessCustomer
                        ? booking.businessCustomer.businessName
                        : ""
                      : booking.customer
                        ? booking.customer.firstName + " " + booking.customer.lastName
                        : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SummaryField
                  label="Customer Email"
                  value={
                    isBusinessCustomerBooking
                      ? booking.businessCustomer
                        ? booking.businessCustomer.billing.email
                        : ""
                      : booking.customer
                        ? booking.customer.email
                        : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SummaryField
                  label="Customer Phone Number"
                  value={
                    isBusinessCustomerBooking
                      ? booking.businessCustomer
                        ? booking.businessCustomer.billing.phoneNumber.phone
                        : ""
                      : booking.customer
                        ? booking.customer.phoneNumber.phone
                        : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Box mt={3}></Box>
          <Grid item container xs={12} style={{ marginTop: 20 }}>
            <Grid item container xs={10}>
              <Typography variant="h3" gutterBottom>
                ADDITIONAL DOCUMENTS
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Fab
                className="blackBackButton"
                style={{ marginLeft: 28 }}
                variant="extended"
                size="medium"
                aria-label="Update"
                onClick={() => {
                  setDocumentDialogVisible(true);
                }}
              >
                ADD DOCUMENT
              </Fab>
            </Grid>
          </Grid>
          <Grid item container xs={12} style={{ marginTop: 10 }}>
            <ViewDocuments
              documents={documents}
              downloadDocument={downloadDocument}
              deleteDocument={deleteDocument}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <TextField
              style={{ width: "100%" }}
              label="Add Notes"
              placeholder={"Additional TnCs, etc."}
              name={"invoiceNote"}
              multiline
              rows={1}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setinvoiceNote(event.target.value);
              }}
              variant="outlined"
              value={invoiceNote}
            />
          </Grid>
          {/* <Grid item xs={4} style={{ marginTop: 20 }}>
            <Fab
              className="blackBackButton"
              variant="extended"
              size="medium"
              disabled={!invoiceNote}
              onClick={() => {
                addInvoiceNote({
                  variables: {
                    bookingId: booking.id,
                    note: invoiceNote
                  }
                })
              }}
            >
              Save
            </Fab>
          </Grid> */}
          <Grid item container xs={12} style={{ marginTop: 20 }}>
            <Grid item xs={11}>
              <Typography variant="subtitle1">
                Invoice Items
              </Typography>
            </Grid>
            <Grid item container xs={1}>
              <Button
                onClick={addRows}
                style={{ cursor: "pointer" }}
              >
                + Add New
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Formik
              initialValues={booking}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit();
                setSubmitting(false);
              }}
            >
              {(formikProps) => (
                <Form style={{ width: "100%" }}>
                  <Grid item container xs={12} spacing={2}>
                    {charges.map((row: IInvoiceItemCharge, idx: number) => {
                      return (
                        <React.Fragment key={idx}>
                          <Grid
                            item
                            container
                            xs={11}
                            spacing={2}
                            alignItems="baseline"
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              xs={12}
                              md={3}
                              lg={3}
                            >
                              <TextField
                                placeholder="Charge Description"
                                label="Charge Description"
                                name={"description"}
                                inputProps={{
                                  value: row.description,
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const item = charges[idx];
                                    const rowsArr = [...charges];
                                    item.description = e.target.value;
                                    rowsArr.splice(idx, 1, item);
                                    setCharges(rowsArr);
                                  }
                                }}
                                fullWidth
                              ></TextField>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              lg={3}
                            >
                              <TextField
                                placeholder="Unit"
                                label="Unit"
                                name={"unit"}
                                type="number"
                                inputProps={{
                                  value: row.quantity,
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (e.target.value) {
                                      const item = charges[idx];
                                      const rowsArr = [...charges];
                                      item.quantity = parseInt(e.target.value);
                                      rowsArr.splice(idx, 1, item);
                                      setCharges(rowsArr);
                                    }
                                  }
                                }}
                                fullWidth
                                disabled
                              ></TextField>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              lg={3}
                            >
                              <Field
                                component={InputField}
                                fullWidth
                                placeholder="Price/Rate"
                                label="Price/Rate"
                                name={"unitPrice"}
                                InputProps={{
                                  value: row.unitPrice,
                                  inputComponent: FloatInput as any,
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const val = e.target.value || "0";
                                    const item: IInvoiceItemCharge = charges[idx];
                                    const rowsArr = [...charges];
                                    item.unitPrice = parseInt(val);
                                    if (!item.unitPrice) {
                                      item.taxAmount = 0;
                                      item.totalAmount = 0;
                                    }
                                    item.taxAmount = Math.round((item.unitPrice * item.taxRate) / 100);
                                    item.totalAmount = item.unitPrice + item.taxAmount;
                                    rowsArr.splice(idx, 1, item);
                                    setCharges(rowsArr);
                                  }
                                }}
                                inputProps={{
                                  hasCurrencyPrefix: true,
                                  allowNegative: false
                                }}
                              ></Field>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              lg={3}
                            >
                              <Field
                                component={InputField}
                                placeholder={`${getLocalizedTaxSyntex(country)} (%)`}
                                label={`${getLocalizedTaxSyntex(country)} (%)`}
                                name={"taxRate"}
                                InputProps={{
                                  value: row.taxRate * 100, //multiply by 100 to get value like 2000,5000 etc
                                  inputComponent: FloatInput as any,
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const val = parseFloat(e.target.value) ? parseFloat(e.target.value) : 0;
                                    if (val > 10000) {
                                      snackbar({
                                        message:
                                          "Tax value can't be more than 100%",
                                        variant: SnackBarVariant.ERROR
                                      });
                                    }
                                    else if (val < 0) {
                                      snackbar({
                                        message:
                                          "Tax value can't be less than 0%",
                                        variant: SnackBarVariant.ERROR
                                      });
                                    }
                                    else {
                                      const item: IInvoiceItemCharge = charges[idx];
                                      const rowsArr = [...charges];
                                      item.taxRate = val / 100;
                                      item.taxAmount = Math.round((item.unitPrice * item.taxRate) / 100);
                                      item.totalAmount = item.unitPrice + item.taxAmount;
                                      rowsArr.splice(idx, 1, item);
                                      setCharges(rowsArr);
                                    }
                                  }
                                }}
                                inputProps={{
                                  allowNegative: false
                                }}
                                fullWidth
                              ></Field>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              lg={3}
                            >
                              <TextField
                                placeholder="Amount"
                                label="Amount"
                                name={"totalAmount"}
                                disabled={true}
                                InputProps={{
                                  value: row.totalAmount,
                                  inputComponent: FloatInput as any
                                }}
                                inputProps={{
                                  hasCurrencyPrefix: true,
                                  allowNegative: false
                                }}
                                fullWidth
                              ></TextField>
                            </Grid>
                            <Grid
                              item
                              container
                              xs={1}
                              style={{ marginTop: "7px", padding: "10px" }}
                            >
                              <Typography
                                variant={"subtitle1"}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  const rowsArr = [...charges];
                                  rowsArr.splice(idx, 1);
                                  setCharges(rowsArr);
                                }}
                              >
                                X
                              </Typography>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    })}

                    {charges.length > 0 && (
                      <Grid item container xs={12} style={{ marginTop: "20px" }} justifyContent="flex-end">
                        <Fab
                          className="blackBackButton"
                          style={{ marginRight: "20px" }}
                          variant="extended"
                          disabled={!charges.length}
                          size="medium"
                          onClick={() => navigate(-1)}
                        >
                          {"Cancel"}
                        </Fab>
                        <Fab
                          variant="extended"
                          disabled={!charges.length}
                          size="medium"
                          type="submit"
                        >
                          {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                          {"Save"}
                        </Fab>
                      </Grid>
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          {
            documentDialogVisible && (
              <DocumentDialog
                open={documentDialogVisible}
                handleClose={() => setDocumentDialogVisible(false)}
                uploadInProgress={uploadInProgress}
                onSubmit={handleDocumentSave}
                accept="application/pdf"
              />
            )
          }
        </Paper>
      </Grid >
    </Grid >
  )
};

export default NewSundryInvoice;

const ViewDocuments = (props: IViewDocumentProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [deleteDocumentUrl, setDeleteDocumentUrl] = useState<string>("");
  const [documentIndex, setDocumentIndex] = useState<number>();

  const columns = [
    {
      label: "Document Type",
      name: "documentType",
    },
    {
      label: "Document Name/Title",
      name: "title"
    },
    {
      label: "Expiry Date",
      name: "expiryDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED) : "N/A"
      }
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <React.Fragment>
              {(userState.role === UserRoles.ADMIN || userState.role === UserRoles.SUPER_ADMIN) && (
                <div>
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() =>
                      props.downloadDocument(value.url, value.name)
                    }
                    size="large">
                    <CloudDownloadIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      setOpenConfirmDialog(true);
                      setDeleteDocumentUrl(value.url);
                      setDocumentIndex(value.index);
                    }}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
            </React.Fragment>
          );
        }
      }
    }
  ];

  const getRows = (invoiceDocuments: IInvoiceDocument[]) => {
    if (!invoiceDocuments || invoiceDocuments === null) {
      return [];
    }
    const docs = [...invoiceDocuments]
    const sortedDocs = returnSortedDocuments(docs)
    if (sortedDocs && sortedDocs.length > 0) {
      return sortedDocs.map((invoiceDocuments: IInvoiceDocument, index: number) => {
        return {
          ...invoiceDocuments,
          actions: { url: invoiceDocuments.documentUrl, name: invoiceDocuments.documentName, index },
          title: invoiceDocuments.title || invoiceDocuments.documentName,
          expiryDate: invoiceDocuments.expiryDate,
          documentType: invoiceDocuments.documentType
        };
      });
    } else {
      return []
    }
  };

  return (
    <Grid item xs={12} style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '10px' }}>
      <NuvvenTable
        key={new Date().getMilliseconds()}
        title={""}
        rows={getRows(props.documents)}
        columns={columns}
        options={{
          selectableRows: "none",
          download: false,
          filter: false,
          print: false,
          search: false,
          viewColumns: false,
          elevation: 0,
        }}
      />
      {openConfirmDialog && (
        <ConfirmationDialog
          isOpen={openConfirmDialog}
          onCancel={() => setOpenConfirmDialog(false)}
          onConfirm={() => {
            props.deleteDocument(deleteDocumentUrl, documentIndex);
            setOpenConfirmDialog(false);
            setDeleteDocumentUrl("");
            setDocumentIndex(undefined);
          }}
          title=""
          description={"Are you sure, you want to delete this document?"}
        />
      )}
    </Grid>
  );
};