import React, { useEffect, useState } from 'react'
import { Grid, CssBaseline, Typography, Box, Fab, CircularProgress } from '@mui/material';
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { NuvvenTable } from '../../common/NuvvenTable/NuvvenTable';
import { MUIDataTableOptions } from 'mui-datatables';
import { useNavigate } from "react-router-dom";
import { ApolloError, useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { SelectableRows } from '../../common/NuvvenTable/types';
import { DATE_TYPE, formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from '../../common/utils';
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from '../../../utils/localized.syntex';
import _ from 'lodash';
import { GET_JOBS, GET_JOB_COUNT, SEARCH_USER_JOBS } from '../../../graphql/jobSchedular/getJobsQuery';
import { IUserJob, JobCategoryStatus, JobCategoryStatusStatusText, JobStatus } from '../../../reducers/jobSchedular/types';
import { addTableState } from '../../../actions/tableState/actions';
import { TableNames, ITable } from '../../../reducers/tableState/types';
import { DateTime as d } from 'luxon';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';

export const Jobs: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const [rows, setRows] = useState<any[]>([]);
  const [jobsTable, setJobsTable] = useState<ITable>();
  const [limit, setLimit] = useState(10);
  const [totalJobCount, setTotalJobCount] = useState<number>(0);

  const jobsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.userJobs
  );
  const [sortOrder, setSortOrder] = useState<string | undefined>();

  const handleQueryError = (error: ApolloError) => {
    snackbar({
      message: formatGraphQLErrorMessage(error.message),
      variant: SnackBarVariant.ERROR,
    });
  };

  const [loadJobs, { loading: jobLoading, data: loadJobsData }] = useLazyQuery(GET_JOBS, {
    fetchPolicy: "network-only",
    onCompleted: (loadJobsData) => {
      if (loadJobsData && loadJobsData.userJobs) {       
        const newJob = shapeUserJobs(loadJobsData.userJobs);       
        const oldJob = [...rows]
        newJob.forEach((expense: any) => {
          oldJob.push(expense)
        });
        setRows(oldJob)
      }
    },
    onError: handleQueryError
  });

  const [getJobCount, { data: jobCountData }] = useLazyQuery(GET_JOB_COUNT, {
    fetchPolicy: "network-only",
    onCompleted: (jobCountData) => {setTotalJobCount(jobCountData.jobCount)},
    onError: handleQueryError
  }
  );

  const [searchUserJobs, { loading: searchUserJobsLoading, data: searchUserJobsData }] = useLazyQuery(
    SEARCH_USER_JOBS,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setRows([])
        if (data.searchUserJobs.length) {
          const userJobs = shapeUserJobs(data.searchUserJobs);
          userJobs.sort((a, b )=> {
            return d.fromFormat(b.dateCreated, "dd/MM/yyyy, hh:mm a").toMillis() - d.fromFormat(a.dateCreated, "dd/MM/yyyy, hh:mm a").toMillis()})
          
          setTotalJobCount(userJobs.length);
          setRows(userJobs);
        }
      },
      onError: handleQueryError
    }
  );

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchUserJobs({
        variables: {
          q: searchText
        }
      })
    }
  }, 1000);

  const shapeUserJobs = (data: IUserJob[]) => {
    return data.map((job: IUserJob) => ({
      subCategory: job.subCategory,
      status: job.status,
      fullName: `${job.assignedTo?.firstName} ${job.assignedTo?.lastName}`,
      jobDate: getLocalizedDateFormat(country, job.jobDate, DATE_TYPE.EXPANDED),
      licencePlate: job.vehicle?.licencePlate || "NA",
      booking: job.booking?.referenceNumber || "NA",
      dateCreated: getLocalizedDateFormat(country, job.dateCreated, DATE_TYPE.EXPANDED),
      id: job.id
    }));
  };

  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;

  useEffect(() => {
    if (userState.tenancy) {
      getJobCount();
      loadJobs({
        variables: {
          limit: jobsTableState?.rowsPerPage ? jobsTableState.rowsPerPage : limit,
          offset: 0
        }
      })
    }
  }, [userState])

  useEffect(() => {
    if (jobsTableState) {
      setJobsTable(jobsTableState);
      let sortedOrder = returnSortedOrder(jobsTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, jobsTableState, sortedOrder)
      }
    }
  }, [jobsTableState]);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: totalJobCount,
    rowsPerPageOptions: [10, 20, 100],
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return;
          }
          const { page, rowsPerPage } = tableState
          loadJobs({
            variables: {
              limit: rowsPerPage,
              offset: page * rowsPerPage
            }
          });
          break;
        case "changeRowsPerPage":
          setRows([])
          loadJobs({
            variables: {
              offset: 0,
              limit: jobsTable?.rowsPerPage || tableState.rowsPerPage
            }
          });
          break;
        default:
          break;
      }
    },
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: jobLoading || searchUserJobsLoading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: IUserJob = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/user-job?jobId=${row.id}`);
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.USER_JOBS,
          rowsPerPage: numberOfRows
        })
      );
    },
    onTableInit: (action: string) => {
      if (jobsTable && sortOrder) {
        setTableSortOrder(columns, jobsTable, sortOrder);
      }
      if (jobsTable && jobsTable.rowsPerPage) {
        setLimit(jobsTable.rowsPerPage);
      }
    },
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText)
      } else {
        setRows([])
        getJobCount()
        loadJobs({
          variables: {
            limit: limit,
            offset: 0
          }
        })
      }
    },
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.USER_JOBS,
          columnName: changedColumn,
          direction
        })
      );
    },
    rowsPerPage:
      jobsTable && jobsTable.rowsPerPage
        ? jobsTable.rowsPerPage
        : limit,
  };

  const columns: any = [
    {
      label: "Task Type",
      name: "subCategory",
      options: {
        customBodyRender: (value: JobCategoryStatus) =>
          JobCategoryStatusStatusText[value]
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value: string) => JobStatus(value)
      }
    },
    {
      label: "Assigned To",
      name: "fullName",
      options: {
        customBodyRender: (value: any) => value
      }
    },
    {
      label: "Job Date",
      name: "jobDate",
      options: {
        customBodyRender: (value: string) => value
      }
    },
    {
      label: "VRN",
      name: "licencePlate",
      options: {
        customBodyRender: (value: any) => value || "NA"
      }
    },
    {
      label: `${getLocalizedBookingSyntex(country)} Ref no`,
      name: "booking",
      options: {
        customBodyRender: (value: any) => value || "NA"
      }
    },
    {
      label: "Created On",
      name: "dateCreated",
      options: {
        customBodyRender: (value: string) => value
      }
    },
  ];

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6} alignItems="center">
        <Typography variant="h1" color="primary">
          Task Manager
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>

        <Typography variant="h1" color="primary">
          Tasks
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="add"
          className="addButton"
          onClick={() => {
            navigate("/user-job")
          }}
        >
          Create
        </Fab>
      </Grid>
      {
        !(jobLoading && !rows.length) ? (
          <Grid container item xs={12}>
            <NuvvenTable
              title=""
              rows={rows}
              columns={columns}
              options={options}
              setSelection={() => { }}
            />
          </Grid>
        )
          :
          <CircularProgress />
      }
    </Grid>
  )
}

export default Jobs;