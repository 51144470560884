import { useMutation } from "@apollo/client";
import {
  Checkbox,
  CheckboxProps,
  Fab,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, withStyles, makeStyles } from "@mui/styles";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SAVE_POST_BOOKING_QUESTIONS } from "../../../../../graphql/bookings/savePostBookingQuestion";
import { ANSWER_TYPES, IBooking, IBookingQuestionInput } from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { IBookingQuestion } from "../../../AppSettings/types";
import styles from './index.module.css';
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { ApolloError } from "@apollo/client";

const StyledInput = withStyles(() =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-input': {
        padding: '14px 14px'
      },
      '& .MuiOutlinedInput-multiline': {
        padding: '0px 4px'
      },
      '& .MuiOutlinedInput-root fieldset': {
        borderColor: '#e2e2e2',
        borderRadius: 8
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
      },
      '& .MuiSelect-select': {
        padding: '10px 14px'
      },
      '& .MuiSelect-select ~ fieldset': {
        border: 0
      },
    },
  }),
)(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margins: {
      [theme.breakpoints.up('sm')]: {
        marginRight: 10
      },
      [theme.breakpoints.down('sm')]: {
      },
    }
  })
);

const ColoredCheckbox = withStyles({
  root: {
    color: 'var(--theme-primary)',
    '&$checked': {
      color: 'var(--theme-primary)',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface IBookingQuestionsProps {
  booking: IBooking;
}

export const DeliveryQuestions: React.FC<IBookingQuestionsProps> = (props) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const { currentBranch } = useSelector((state: IAppState) => state.userReducer);
  const [isQustionSaved, setIsQustionSaved] = useState<boolean>(false);
  const [savePostBookingQuestionMutation] = useMutation(SAVE_POST_BOOKING_QUESTIONS, {
    onCompleted: ({ savePostBookingQuestions }) => {
      if (savePostBookingQuestions.success) {
        snackbar({
          message: "Vehicle handover questions saved successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setIsQustionSaved(true);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const getFormattedBookingQuestions = () => {
    let _questions: IBookingQuestionInput[] = [];
    if (currentBranch && currentBranch.deliveryQuestions && currentBranch.deliveryQuestions.length) {
      currentBranch.deliveryQuestions.forEach((preBookingQuestion, index) => {
        _questions.push({
          question: preBookingQuestion.question,
          answerType: preBookingQuestion.answerType,
          answer: props.booking && props.booking.deliveryQuestions &&
            props.booking.deliveryQuestions[index]?.answer !== "false" ? props.booking.deliveryQuestions[index]?.answer : ""
        })
        if (props.booking && props.booking.deliveryQuestions && props.booking.deliveryQuestions[index]?.answer) {
          setIsQustionSaved(true);
        }
      })
    }
    return _questions
  }

  let initialValues = useMemo(() => {
    return {
      ...props.booking,
      deliveryQuestions: getFormattedBookingQuestions()
    }
  }, []);

  const validateAnswer = (value: any, required: boolean, expected?: string) => {
    let error;
    if (required) {
      if (expected === "TRUE" && !value) {
        error = "Error"
      }
      if (expected === "FALSE" && value) {
        error = "Error"
      }
      if (expected === "TEXT" && !value) {
        error = "Error"
      }
      if (expected === "YES" && value !== "YES") {
        error = "Error"
      }
      if (expected === "NO" && value !== "NO") {
        error = "Error"
      }
    }
    return error;
  }

  const deliveryQuestions = currentBranch && currentBranch.deliveryQuestions || [];

  return (
    <>
      {deliveryQuestions.length > 0 && (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              validateOnMount
              onSubmit={(values) => {
                const postBookingQuestionData: IBookingQuestionInput[] = [];
                values.deliveryQuestions.filter((bq: IBookingQuestionInput) => {
                  postBookingQuestionData.push({
                    question: bq.question,
                    answerType: bq.answerType,
                    answer: bq.answer?.toString() || ""
                  })
                });
                savePostBookingQuestionMutation({
                  variables: {
                    bookingId: props.booking.id,
                    postBookingQuestionsInput: postBookingQuestionData
                  }
                });
              }}
            >
              {(formikProps) => (
                <Form>
                  {deliveryQuestions.length > 0 && (
                    <Grid container>
                      {deliveryQuestions.map((deliveryQuestion: IBookingQuestion, idx: number) => {
                        switch (deliveryQuestion.answerType) {
                          case ANSWER_TYPES.CHECKBOX:
                            return (
                              <Grid container xs={12} sm={6} spacing={2} className={classes.margins}>
                                <Grid item xs={12}>
                                  <Field
                                    name={`deliveryQuestions[${idx}].answer`}
                                    validate={(value: any) => validateAnswer(value, deliveryQuestion.required, 'TRUE')} >
                                    {({ field, meta: { touched, error }, }: FieldProps) => (
                                      <div className={`${styles.validation} ${(touched && Boolean(error)) ? styles.error : ''}`} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        <FormControlLabel
                                          control={
                                            <ColoredCheckbox
                                              {...field}
                                              checked={field.value}
                                              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                formikProps.setFieldValue(`deliveryQuestions[${idx}].answer`, event.target.checked);
                                              }}
                                              size="small"
                                              color="primary"
                                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                          }
                                          label={`${deliveryQuestion.question} ${deliveryQuestion.required ? "*" : ""}`}
                                          disabled={isQustionSaved && formikProps.values.deliveryQuestions[idx]?.answer ? Boolean(formikProps.values.deliveryQuestions[idx]?.answer) : false}
                                        />
                                        {touched && Boolean(error) && <Typography variant="body1" style={{ color: '#D13135' }}>{deliveryQuestion.errorMessage}</Typography>}
                                      </div>
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>
                            )
                            break;
                          case ANSWER_TYPES.DROP_DOWN:
                            return (
                              <Grid container xs={12} sm={6} spacing={2} className={classes.margins}>
                                <Grid item xs={12}>
                                  <Field name={`deliveryQuestions[${idx}].answer`} validate={(value: any) => validateAnswer(value, deliveryQuestion.required, "TEXT")}>
                                    {({ field, meta: { touched, error }, }: FieldProps) => (
                                      <>
                                        <div className={`${styles.bookingQuestion} ${touched && (Boolean(error)) ? styles.error : ''}`}>
                                          <div className={styles.rowFlex}>
                                            <div style={{ display: 'flex', flex: 1, paddingRight: 15 }}>
                                              <Typography>{`${deliveryQuestion.question} ${deliveryQuestion.required ? "*" : ""}`}</Typography>
                                            </div>
                                            <div className={styles.dropdownWrap}>
                                              <StyledInput
                                                select
                                                {...field}
                                                fullWidth
                                                variant="outlined"
                                                error={touched && Boolean(error)}
                                                disabled={isQustionSaved && formikProps.values.deliveryQuestions[idx]?.answer ? Boolean(formikProps.values.deliveryQuestions[idx]?.answer) : false}
                                              >
                                                {deliveryQuestion.options && deliveryQuestion.options.map((option) => (
                                                  <MenuItem key={option} value={option}>
                                                    {option}
                                                  </MenuItem>
                                                ))}
                                              </StyledInput>
                                            </div>
                                          </div>
                                          {touched && Boolean(error) && <Typography variant="body1" style={{ color: '#D13135' }}>{deliveryQuestion.errorMessage}</Typography>}
                                        </div>
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>
                            )
                            break;
                          case ANSWER_TYPES.YES_NO:
                            return (
                              <Grid container xs={12} sm={6} spacing={2} className={classes.margins}>
                                <Grid item xs={12}>
                                  <Field
                                    name={`deliveryQuestions[${idx}].answer`}
                                    validate={(value: any) => validateAnswer(value, deliveryQuestion.required, deliveryQuestion.expectedAnswer)}
                                  >
                                    {({ field, meta: { touched, error }, }: FieldProps) => (
                                      <RadioGroup {...field} onClick={field.onBlur}>
                                        <div className={`${styles.bookingQuestion} ${touched && (Boolean(error)) ? styles.error : ''}`}>
                                          <div className={styles.rowFlex}>
                                            <div style={{ display: 'flex', flex: 1, paddingRight: 15 }}>
                                              <Typography>{`${deliveryQuestion.question} ${deliveryQuestion.required ? "*" : ""}`}</Typography>
                                            </div>
                                            <div className={styles.yesnoWrap}>
                                                <FormControlLabel 
                                                  value="YES" 
                                                  control={<Radio style={{display: 'none'}}/>} 
                                                  label="YES" 
                                                  className={`${styles.yesnoButton} ${styles.left} ${field.value === "YES" ? styles.active : ''}`}
                                                  disabled={isQustionSaved && formikProps.values.deliveryQuestions[idx]?.answer ? Boolean(formikProps.values.deliveryQuestions[idx]?.answer) : false}
                                                />
                                              <FormControlLabel 
                                                  value="NO" 
                                                  control={<Radio style={{display: 'none'}}/>} 
                                                  label="NO" 
                                                  className={`${styles.yesnoButton} ${styles.right} ${field.value === "NO" ? styles.active : ''}`}
                                                  disabled={isQustionSaved && formikProps.values.deliveryQuestions[idx]?.answer ? Boolean(formikProps.values.deliveryQuestions[idx]?.answer) : false}
                                                />
                                            </div>
                                          </div>
                                          {touched && Boolean(error) && <Typography variant="body1" style={{ color: '#D13135' }}>{deliveryQuestion.errorMessage}</Typography>}
                                        </div>
                                      </RadioGroup>
                                    )
                                    }
                                  </Field>
                                </Grid>
                              </Grid>
                            )
                            break;
                          case ANSWER_TYPES.TEXT:
                            return (
                              <Grid container xs={12} sm={6} spacing={2} className={classes.margins}>
                                <Grid item xs={12}>
                                  <Field name={`deliveryQuestions[${idx}].answer`} validate={(value: any) => validateAnswer(value, deliveryQuestion.required, 'TEXT')}>
                                    {({ field, meta: { touched, error }, }: FieldProps) => (
                                      <>
                                        <Typography variant="body1">{`${deliveryQuestion.question} ${deliveryQuestion.required ? "*" : ""}`}</Typography>
                                        <StyledInput
                                          variant="outlined"
                                          {...field}
                                          multiline
                                          fullWidth
                                          error={touched && Boolean(error)}
                                          disabled={isQustionSaved && formikProps.values.deliveryQuestions[idx]?.answer ? Boolean(formikProps.values.deliveryQuestions[idx]?.answer) : false}
                                        />
                                        <Typography variant="body1" style={{ color: '#D13135', padding: '5px 15px 5px 15px' }}>{touched && Boolean(error) && deliveryQuestion.errorMessage}</Typography>
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>
                            )
                        }
                      })}
                      <Grid item xs={12} style={{ marginTop: 10 }}>
                        <Fab
                          variant="extended"
                          size="medium"
                          type="submit"
                          disabled={isQustionSaved}
                        >
                          Save
                        </Fab>
                      </Grid>
                    </Grid>
                  )}
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )}
    </>
  )
}
