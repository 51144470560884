
import React, { useEffect, useState } from 'react';
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { DateTime as d } from "luxon"
import * as Yup from "yup";
import { IBooking, IcoiDetails } from '../../../../../reducers/bookings/types';
import { v4 as uuidv4 } from "uuid";
import { DATE_TYPE, checkUploadFileFormat, isImageFileType, uploadFileExtensionAndContentType } from '../../../../common/utils';
import UUID from "uuid";
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { Field, Form, Formik } from 'formik';
import FlatPickerBar from "../../../../common/FlatPicker";
import { FilePicker } from '../../../../common/FilePicker/FilePicker';
import { ADD_COI_DETAILS } from '../../../../../graphql/bookings/addBookingNoteMutation';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { CircularProgress, Fab, FormControl, Grid } from '@mui/material';
import { TextField } from 'formik-mui';
import { useMutation } from '@apollo/client';
import { captureErrorException } from '../../../../../utils/sentry';

const intialCoiDetails: IcoiDetails = {
  policyName: "",
  policyNumber: "",
  provider: "",
  expiryDate: "",
  documentUrl: ""
}
interface IProps {
  booking: IBooking;
}

const AddCoiDetails: React.FC<IProps> = (props) => {
  const userState = useSelector((state: IAppState) => state.userReducer)
  const { country } = userState.currentOrganisation.address;

  const [coiDetails, setcoiDetails] = useState<IcoiDetails>(intialCoiDetails);
  const [coiDetailsAvailable, setCoiDetailsAvailable] = useState<boolean>(false);
  const [fileValue, setFileValue] = useState<File | undefined>(undefined);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [booking, setBooking] = useState<IBooking>(props.booking)

  const snackbar = useSnackBar();

  useEffect(() => {
    if (props.booking?.coiDetails) {
      setcoiDetails(props.booking.coiDetails);
    }
  }, [props.booking])

  useEffect(() => {
    if (fileValue) {
      handleDocumentSave();
    }
  }, [fileValue]);


  const onSelectDocument = (event: any) => {
    const file = event.target.files[0];
    if (!checkUploadFileFormat(file.type)) {
      return snackbar({
        message: "Invalid File Format. Supported formats PDF, PNG, JPG and JPEG",
        variant: SnackBarVariant.ERROR
      });
    }
    setFileValue(file);
  }

  const [addCoiDetails, { loading: saveUploadInProgress }] = useMutation(ADD_COI_DETAILS, {
    onCompleted: () => {
      snackbar({
        message: "COI Details added successfully",
        variant: SnackBarVariant.SUCCESS
      });
    }
  });

  const handleDocumentSave = async () => {
    if (!fileValue) {
      return snackbar({
        message: "Please upload policy document.",
        variant: SnackBarVariant.ERROR
      });
    }
    try {
      // 50MB limit exceeded
      if (fileValue.type === "application/pdf" && fileValue?.size && fileValue?.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      if (isImageFileType(fileValue.type) && fileValue?.size && fileValue?.size > 5242880) {
        throw new Error("File size exceeded limit of 5MB");
      }
      setUploadInProgress(true);
      setCoiDetailsAvailable(true);
      if (fileValue && checkUploadFileFormat(fileValue.type)) {
        const uniqueId = uuidv4();
        const {
          fileExtension
        } = uploadFileExtensionAndContentType(fileValue.type);
        const key = `${userState.tenancy.id}/${uniqueId}-insurance-policy-document.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: fileValue,
              complete: async () => {
                setcoiDetails({
                  ...coiDetails,
                  documentUrl: key
                })
                snackbar({
                  message: "Document uploaded successfully.",
                  variant: SnackBarVariant.SUCCESS
                });
              }
            });
          setUploadInProgress(false);
        }
      } else {
        setUploadInProgress(false);
        setCoiDetailsAvailable(false);
        return snackbar({
          message: "Please only upload .pdf!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  const detailsFormSchema = Yup.object().shape({
    name: Yup.string()
      .min(1)
      .required("Policy name is required.")
  });

  return (
    <Grid container item xs={12} style={{ marginTop: "20px" }}>
      <React.Fragment>
        <Formik
          enableReinitialize
          validationSchema={detailsFormSchema}
          initialValues={coiDetails}
          onSubmit={() => { }}
        >
          {(props) => (
            <Form>
              <Grid container xs={12} spacing={2}>
                <Grid item container xs={3} sm={2} >
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      placeholder="Policy Name"
                      label="Policy Name"
                      name={"policyName"}
                      value={props.values.policyName}
                      onChange={(e: any) => {
                        setcoiDetails({ ...props.values, policyName: e.target.value })
                      }}
                      fullWidth
                      required
                    ></Field>
                  </FormControl>
                </Grid>
                <Grid item container xs={3} sm={2}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      placeholder="Provider"
                      label="Provider"
                      name={"provider"}
                      value={props.values.provider}
                      onChange={(e: any) => setcoiDetails({ ...props.values, provider: e.target.value })}
                      fullWidth
                      required
                    ></Field>
                  </FormControl>
                </Grid>
                <Grid item container xs={3} sm={2}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      placeholder="Policy Number"
                      label="Policy Number"
                      name={"policyNumber"}
                      value={props.values.policyNumber}
                      onChange={(e: any) => setcoiDetails({ ...props.values, policyNumber: e.target.value })}
                      fullWidth
                      required
                    ></Field>
                  </FormControl>
                </Grid>
                <Grid item container xs={3} sm={2}>
                  <FormControl variant="outlined" fullWidth>
                    <FlatPickerBar
                      enableTime={false}
                      handleDateChange={(value: Date) => {
                        setcoiDetails({
                          ...props.values,
                          expiryDate: d.fromJSDate(value).plus({ hours: 1 }).toUTC().toISO()
                        })
                      }}
                      label={"Expiry Date"}
                      identifier={"dateOfExpiry"}
                      placeholderValue={"Document Expiry Date"}
                      value={getLocalizedDateFormat(country, props.values.expiryDate, DATE_TYPE.CONDENSED)}
                      minDate={getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}
                      required
                      country={country}
                    />
                  </FormControl>
                </Grid>
                <Grid item >
                  <FilePicker
                    onFilePick={(e: any) => {
                      onSelectDocument(e);
                    }}
                    title="Upload Policy Document"
                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                  />
                  {uploadInProgress && (
                    <CircularProgress
                      size={14}
                      style={{ color: "white", marginLeft: "10px" }}
                    />
                  )}
                </Grid>
                <Grid item container xs={12}>
                  <Fab
                    variant="extended"
                    size="medium"
                    type="submit"
                    onClick={() => {
                      if (!coiDetails.documentUrl) {
                        snackbar({
                          message: "Please upload policy document.",
                          variant: SnackBarVariant.ERROR
                        });
                      }
                      else {
                        addCoiDetails({
                          variables: {
                            bookingId: booking?.id || "",
                            coiDetails: coiDetails
                          }
                        })
                      }
                    }}
                    disabled={saveUploadInProgress || !coiDetails.policyName || !coiDetails.provider || !coiDetails.policyNumber || !coiDetails.expiryDate || uploadInProgress}
                  >
                    {saveUploadInProgress && (
                      <CircularProgress
                        size={14}
                        style={{ color: "white", marginRight: "10px" }}
                      />
                    )}
                    Save
                  </Fab>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </React.Fragment>

    </Grid>
  )

}


export default AddCoiDetails;

