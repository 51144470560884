import React from 'react';
import styles from './index.module.css';
import { Typography, Tooltip, PopperProps, Popper, Hidden, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { IAppState } from '../../../store';
import { useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';

export interface IVehicleAvailabilityData {
  activeVehicles: number;
  booked: number;
  onHold: number;
  onService: number;
  outOnHire: number;
}


export enum VehicleStatus {
  ACTIVE = "Active",
  AVAILABLE_FOR_HIRE = "Available_for_hire",
  DEACTIVATED = "Deactivated",
  ON_HOLD = "on_hold",
  ON_HIRE = "on_hire",
  ON_SERVICE = "on_service"
}

const VehicleAvailablityCard = ({ data }: { data?: IVehicleAvailabilityData }) => {
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);

  const StyledToolTip = withStyles({
    tooltip: {
      color: "#000",
      backgroundColor: "#fff",
      border: '1px solid lightgrey',
      padding: 10,
      borderRadius: 10
    }
  })(Tooltip);

  return (
    <div className={styles.availabilityCard}>
      <div className="flex fill col-flex">
        <Grid container item xs={12}>
          <Grid item xs={6} sm={12}>
            <Typography variant="h4">Vehicle Availability</Typography>
          </Grid>
          <Hidden smUp>
            <Grid container item xs={6} sm={12} justifyContent='flex-end' onClick={() => {
              navigate(`/vehicle-inventory?status=${VehicleStatus.ACTIVE}`);
            }}>
              <Grid item>
                <Typography variant="body1" display="inline">Active vehicles </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className="bold" display="inline">{data ? data.activeVehicles : "-"}</Typography>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <div className="flex" style={{ marginTop: 15 }}>
          <Hidden smDown>
            <div className={styles.col} onClick={() => {
              if (userState.role !== "FINANCE_MANAGER") {
                navigate(`/vehicle-inventory?status=${VehicleStatus.ACTIVE}`);
              }
            }}>
              <Typography variant="body1">Active vehicles</Typography>
              <Typography variant="h2" className="bold">{data ? data.activeVehicles : "-"}</Typography>
            </div>
          </Hidden>
          <div className={styles.col} style={{ color: "#BE8400" }} onClick={() => {
            if (userState.role !== "FINANCE_MANAGER") {
              navigate(`/vehicle-inventory?status=${VehicleStatus.ON_HIRE}`);
            }
          }}>
            <Typography variant="body1">On hire</Typography>
            {data && <Typography variant="h2" className="flex cross-center bold">{data.outOnHire} + {data.booked - data.outOnHire > 0 ? data.booked - data.outOnHire : 0}
              <span className="flex margin-left">
                <StyledToolTip placement="bottom" arrow
                  title={
                    <Grid container >
                      <Typography variant="body1" >{data.outOnHire} Vehicle{data.outOnHire > 1 ? "s" : ""} - Out on hire.</Typography>
                      <Typography>{data.booked - data.outOnHire} Vehicle{(data.booked - data.outOnHire) > 1 ? "s" : ""} - Confirmed Booking.</Typography>
                      <Typography variant="body1" ></Typography>
                    </Grid>
                  }>
                  <HelpOutlineOutlinedIcon fontSize="small" />
                </StyledToolTip>
              </span>
            </Typography>}
          </div>
          <div className={styles.col} style={{ color: "#019D79" }} onClick={() => {
            if (userState.role !== "FINANCE_MANAGER") {
              navigate(`/vehicle-inventory?status=${VehicleStatus.AVAILABLE_FOR_HIRE}`);
            }
          }}>
            <Typography variant="body1">Available for hire</Typography>
            {data && <Typography variant="h2" className="bold">{data.activeVehicles - (data.booked + data.onHold + data.onService)}</Typography>}
          </div>
          <div className={styles.col} style={{ color: "#983737" }} onClick={() => {
            if (userState.role !== "FINANCE_MANAGER") {
              navigate(`/vehicle-inventory?status=${VehicleStatus.ON_HOLD}`);
            }
          }}>
            <Typography variant="body1">On hold</Typography>
            {data && <Typography variant="h2" className="bold">{data.onHold}</Typography>}
          </div>
          <div className={styles.col} style={{ color: "#434343", borderRight: "none" }} onClick={() => {
            if (userState.role !== "FINANCE_MANAGER") {
              navigate(`/vehicle-inventory?status=${VehicleStatus.ON_SERVICE}`);
            }
          }}>
            <Typography variant="body1">In service</Typography>
            {data && <Typography variant="h2" className="bold">{data.onService}</Typography>}
          </div>
        </div>
      </div>
      {/* <div className={styles.chart}>
        <ResponsivePie
          data={chartData}
          margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
          innerRadius={0.5}
          padAngle={1}
          cornerRadius={3}
          colors={({ id, data }) => data[`color`]}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          enableArcLinkLabels={false}
          arcLabelsTextColor={{ from: 'color', modifiers: [['brighter', 3]] }}
          arcLabelsSkipAngle={10}
          tooltip={point => {
            return <div
              key={point.datum.id}
              style={{
                background: "#fff",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                border: "1px solid #e2e2e2"
              }}
            >
              <div style={{ background: point.datum.color, height: "8px", width: "8px", marginRight: 5 }} />{point.datum.id} : <strong>{point.datum.value}</strong>
            </div>
          }}
        />
      </div> */}
    </div>
  );
}

export default VehicleAvailablityCard

