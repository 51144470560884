import { initialState } from "../../../../../../reducers/fleet/const";
import { ICheckInfoBooking, ICheckInfoInvoice, ICheckInfoVehicle } from "./types";

export const fuelStatus = [
  {
    value: "e",
    label: "E"
  },
  {
    value: "1/4",
    label: "1/4"
  },
  {
    value: "1/2",
    label: "1/2"
  },
  {
    value: "3/4",
    label: "3/4"
  },
  {
    value: "f",
    label: "F"
  }
];

export const paymentMethods = [
  { value: "cash", label: "CASH" },
  { value: "card", label: "CARD" },
  { value: "payByLink", label: "PAY_BY_LINK" },
  { value: "payOnCollection", label: "PAY ON COLLECTION" }
];

export const CurrentInvoice: ICheckInfoInvoice = {
  deposit: 0,
  totalPayableAmount: 0,
  confirmedPaidAmount: 0,
  confirmedRefundAmount: 0
};

export const CheckInfoBooking: ICheckInfoBooking = {
  id: "",
  dateCreated: "",
  referenceNumber: "",
  dropoffDateTime: "",
  pickupDateTime: "",
  status: "",
  currentInvoice: CurrentInvoice,
  addonRequirements: [],
  customer: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: "",
    location: {
      fullAddress: ""
    }
  }
};

export const CheckInfoVehicle: ICheckInfoVehicle = {
  id: "",
  make: "",
  model: "",
  licencePlate: "",
  vehicleState: initialState.vehicle.vehicleState
}