import { gql } from "@apollo/client";

export const GET_VEHICLES = gql`
  {
    vehicles {
      id
      licencePlate
    }
  }
`;
