import { CircularProgress, Fab, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { IPaymentInput, PaymentMode } from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";

interface IProps {
  amount: number;
  onSubmit(data: any): void;
  bookingId: string;
  extensionId: string;
}

const PayOnReturnExtension: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { payOnCollectionEnabled, currency } = userState.currentOrganisation;

  const handleSubmit = () => {
    const payment: IPaymentInput = {
      amount: 0,
      currency,
      description: "",
      emailNotify: true,
      expireBy: "",
      invoice: "",
      booking: props.bookingId,
      bookingExtension: props.extensionId,
      paymentType: "INWARD",
      paymentMode: PaymentMode.PAY_BY_COLLECTION
    };
    props.onSubmit(payment);
  }
  
  return (
    <Grid container style={{ padding: 30 }}>
      {
        !payOnCollectionEnabled ?
          <Grid container>
            <Typography variant="h4">
              To use this payment mode please enable pay on collection in Payment and Integration Settings.
            </Typography>
          </Grid> :
          (
            <Grid item container xs={12} spacing={1}>
              <Grid item container xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  You can take and add payment to the invoice under 'Billing' later
                  on.
                </Typography>
              </Grid>
              <Grid item container xs={12} justifyContent="flex-start">
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="add"
                  type="button"
                  onClick={() => {
                    setLoading(true);
                    handleSubmit();
                  }}
                  disabled={loading}
                >
                  {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                  {PaymentMode.PAY_BY_COLLECTION}
                </Fab>
              </Grid>
            </Grid>
          )
      }
    </Grid>
  );
};

export default PayOnReturnExtension;