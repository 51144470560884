import { useLazyQuery } from '@apollo/client';
import { CircularProgress, CssBaseline, Fab, Grid, Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IPartner } from '../../../../../reducers/partners/types';
import { IAppState } from '../../../../../store';
import { NuvvenTable } from '../../../../common/NuvvenTable/NuvvenTable';
import { DATE_TYPE, formatGraphQLErrorMessage } from '../../../../common/utils';
import { SelectableRows } from "../../../../common/NuvvenTable/types";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { GET_ASSET_TYPES } from '../../../Assets/Graphql/assetTypeQueries';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';

export const AssetTypes: FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(10);

  const [
    loadAssetTypes,
    { loading, data }
  ] = useLazyQuery(GET_ASSET_TYPES, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (userState.tenancy) {
      loadAssetTypes({
        variables: {
          limit,
          offset: 0
        }
      })
    }
  }, [userState.currentOrganisation])

  useEffect(() => {
    if (data && data.assetTypes) {
      const reShapeRows = data.assetTypes.map((assetType: any) => {
        const reshapePartner = {
          ...assetType
        }
        return reshapePartner
      })
      setRows(reShapeRows)
    }
  }, [data]);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: IPartner = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/update-asset-type?id=${row.id}`);
      }
    },
    rowsPerPage: 10
  };

  const assetTypesColumn: any = [
    {
      label: "Asset Name",
      name: "name"
    },
    {
      label: "No of Input fields",
      name: "customFields.length"
    },
    {
      label: "Created Date",
      name: "dateCreated",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Status",
      name: "isActive",
      options: {
        customBodyRender: (isActive: string) => isActive ? "Active" : "De-Active"
      }
    }
  ];

  if (loading) {
    return <CircularProgress />
  }


  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Asset Types
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          className="createButton"
          onClick={() => {
            navigate("/new-asset-type");
          }}
        >
          Create
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        <NuvvenTable
          title=""
          rows={rows}
          columns={assetTypesColumn}
          options={options}
          setSelection={() => { }}
        />
      </Grid>
    </Grid>
  )
}