import { useLazyQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Grid,
  Hidden,
  Theme,
  Typography,
  Paper
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET_CUSTOMER_CREDIT_NOTES } from "../../../../graphql/bookings/getCustomerCreditNoteHistoryQuery";
import { CreditNoteTypes, ICreditNote } from "../../../../reducers/bookings/types";
import { IAppState } from "../../../../store";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { toCurrency } from "../../../common/utils";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newPaper: {
      [theme.breakpoints.down('md')]: {
        width: `calc(100vw - 45px)`,
      },
      [theme.breakpoints.up('sm')]: {
        width: `calc(100vw - 245px)`,
      }
    },
    expansionPanel: {
      marginTop: 8,
      [theme.breakpoints.down('md')]: {
        minWidth: `calc(100vw - 105px)`,
      },
      [theme.breakpoints.up('sm')]: {
        minWidth: `calc(100vw - 305px)`,
      },
    },
    summaryLeftMargin: {
      marginLeft: 5
    },
    linkText: {
      color: theme.palette.primary.main,
      marginLeft: "1rem",
      cursor: "pointer"
    }
  })
);

interface IProps {
  customerId: string;
  updateCreditNoteBalance: (balance: number) => void;
}

interface IRowNote {
  creditNoteRef: string;
  invoiceRef: string;
  totalAmount: string;
  formattedAvailableAmount: string;
  creditNoteType: string;

}

const columns = [
  {
    label: "ID",
    name: "creditNoteRef"
  },
  {
    label: "Invoice Ref",
    name: "invoiceRef"
  },
  {
    label: "Total Amount",
    name: "totalAmount"
  },
  {
    label: "Remaining Amount",
    name: "formattedAvailableAmount"
  },
  {
    label: "Type",
    name: "creditNoteType"
  }
];

const CustomerCreditNoteHistory: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const [rows, setRows] = useState([]);
  const [creditNotes, setCreditNotes] = useState<ICreditNote[]>([])

  const [loadCreditNotes, { loading, data }] = useLazyQuery(
    GET_CUSTOMER_CREDIT_NOTES,
    {
      fetchPolicy: "network-only"
    }
  );
  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    elevation: 0,
    onRowClick: (rowData: string[]) => {
      if (rowData && rowData.length) {
        // FIXME: Idially the rows should change based on sorting and then find data based on rowindex
        const creditNoteClicked = creditNotes.find(
          (row: any) => row.creditNoteRef === rowData[0]
        );
        if (creditNoteClicked) {
          navigate(`/credit-note?creditNoteId=${creditNoteClicked.id}`)
        }
      }
    },
  }

  useEffect(() => {
    if (userState.tenancy) {
      loadCreditNotes({
        variables: {
          id: props.customerId
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    if (data && data.creditNotesByCustomerId?.length) {
      let availableAmount = 0;
      setCreditNotes(data.creditNotesByCustomerId)
      setRows(
        data.creditNotesByCustomerId.map(
          (creditNote: ICreditNote) => {
            if (creditNote.creditNoteType === CreditNoteTypes.ADJUSTABLE) {
              availableAmount += creditNote.availableAmount;
            }
            return reshapeCreditNoteIntoRow(creditNote)
          }
        )
      );
      props.updateCreditNoteBalance(availableAmount)
    }
  }, [data]);

  const reshapeCreditNoteIntoRow = (creditNote: ICreditNote) => {
    let data: any = { ...creditNote }
    data.invoiceRef = creditNote.invoice?.invoiceRef || "";
    data.totalAmount = toCurrency(creditNote.totalAmount, currency, locale);
    data.formattedAvailableAmount = toCurrency(creditNote.availableAmount, currency, locale);
    data.creditNoteType = creditNote.creditNoteType
    return data
  }

  return <>
    {!loading ? (
      <>
        <Hidden mdDown>
          <Grid container spacing={2}>
            <Grid item container xs={12}>
              <Typography variant={"h3"}>CREDIT NOTES HISTORY</Typography>
            </Grid>
            <Grid container item xs={12}>
              <Paper variant='outlined' className={classes.newPaper}>
                <NuvvenTable
                  title={""}
                  rows={rows}
                  columns={columns}
                  options={options}
                />
              </Paper>
            </Grid>
          </Grid>
        </Hidden>
        {/* New mobile friendly expansion panels below */}
        <Hidden mdUp>
          <Box className={classes.expansionPanel} >
            {rows.length > 0 &&
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h3">Credit Note History</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container xs={12} spacing={1}>
                    {rows.map((creditNote: IRowNote, index: number) => {
                      return (
                        <Grid item xs={12}>
                          <Grid container
                            key={index}
                            style={{
                              background: "#f5f5f5",
                              borderRadius: 5,
                              padding: 20
                            }}>
                            <Grid container item xs={12}>
                              <Grid item>
                                <Typography variant="h6">
                                  ID:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">
                                  {creditNote.creditNoteRef}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Invoice Ref:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">{creditNote.invoiceRef}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Total Amount:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">
                                  {creditNote.totalAmount}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Remaining Amount:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">
                                  {creditNote.formattedAvailableAmount}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Type:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">
                                  {creditNote.creditNoteType}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            }
          </Box>
        </Hidden>
      </>
    ) : (
      <CircularProgress />
    )}
  </>;
};

export default CustomerCreditNoteHistory;