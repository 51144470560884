import { gql } from "@apollo/client";

export const UPDATE_EXPENSE = gql`
  mutation updateExpense($expenseId: String!, $expense: ExpenseInput!) {
    updateExpense(expenseId: $expenseId, expense: $expense) {
      vehicle {
        id
        licencePlate
      }
      addon{
        id
        name
        displayName
      }
      expenseType
      authority
      itemType
      unit
      amountPerUnit
      cycle
      insuranceExpiryDate
      expenseDate
      fuelType
      vendor
      odometerReading
      fuelQuantity
      reference
      amount
      driver
      document {
        documentName
        url
      }
    }
    }`;