import {
  Box,
  Fab,
  Grid,
  Paper,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field, FieldProps, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useState } from "react";
import PhoneInput, { Value, isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  setBusinessAuthorisedSignatory,
  setBusinessCustomer
} from "../../../../../../actions/customer/actions";
import {
  IBusinessCustomer,
  IBusinessCustomerCommonInput
} from "../../../../../../reducers/customer/types";
import { IAppState } from "../../../../../../store";
import { getDefaultCountryCode } from "../../../../../../utils/localized.syntex";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  validateName
} from "../../../../../common/ValidationRules";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxLabel: {
      fontSize: "16px !important"
    },
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    },
    authorsationTable: {
      display: "flex",
      width: "100%",
      border: "1px solid lightgrey",
      padding: 10,
      alignItems: "center",
      borderRadius: 4
    },
    backButton: {
      backgroundColor: "black",
      color: "white",
      marginRight: "20px"
    }
  })
);

interface IProps {
  onPrevious(): void;
  onSubmit(): void;
}

export const NewAutorisedSignatory = (props: IProps) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const businessCustomer: IBusinessCustomer = useSelector(
    (state: IAppState) => state.customerReducer.businessCustomer
  );
  const dispatch = useDispatch();
  const [values, setValues] = useState<IBusinessCustomerCommonInput>({
    firstName: "",
    lastName: "",
    phoneNumber: {
      phone: "" as Value,
      country: ""
    },
    email: ""
  });
  const [countryCode, setCountryCode] = useState<any>(getDefaultCountryCode(country));
  const [phoneError, setPhoneError] = useState<string>("");

  const submitFormHandler = (values: any) => {
    const authorisedSignatory = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email
    };
    dispatch(setBusinessAuthorisedSignatory(authorisedSignatory));
    setValues({
      ...values,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: {
        phone: "" as Value,
        country: ""
      }
    });
  };

  const proceedHandler = () => {
    if (businessCustomer.authorizedSignatories.length > 0) {
      props.onSubmit();
    } else {
      return snackbar({
        message: "Atleast one authorised signatory must be added",
        variant: SnackBarVariant.ERROR
      });
    }
  };

  const handlePrevious = () => {
    props.onPrevious();
  };

  const removeSignatoryHandler = (index: number) => {
    businessCustomer.authorizedSignatories.splice(index, 1);
    dispatch(setBusinessCustomer(businessCustomer));
  };
  const authorisedSignatorySchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required.")
    }),
    email: Yup.string()
      .required("Email is required.")
      .email("Please enter a valid email address.")
  });
  return (
    <Paper className={classes.root}>
      <Formik
        enableReinitialize
        validationSchema={authorisedSignatorySchema}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          const updateValues = {
            ...values,
            phoneNumber: {
              ...values.phoneNumber,
              country: countryCode
            }
          };
          submitFormHandler(updateValues);
          setSubmitting(false);
        }}
      >
        {(props) => (
          <Form noValidate>
            <Grid container>
              <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12}>
                  <Typography variant="h2" gutterBottom>
                    AUTHORISED SIGNATORIES
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="firstName"
                    placeholder="First Name"
                    label="First Name"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          firstName: e.target.value
                        })
                      },
                      value: props.values.firstName,
                      maxLength: 50
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="lastName"
                    placeholder="Last Name"
                    label="Last Name"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          lastName: e.target.value
                        })
                      },
                      value: props.values.lastName,
                      maxLength: 50
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name={"phoneNumber.phone"}
                    required
                  >
                    {({ form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                      return (
                        <div className={`phone-input-container`}>
                          <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                            Phone Number <sup className={"phone-input-required"}>*</sup>
                          </label>
                          <PhoneInput
                            international={false}
                            defaultCountry={countryCode}
                            placeholder="Enter phone number"
                            className={phoneError ? "phone-input-error" : ""}
                            value={props.values.phoneNumber.phone}
                            name={"phoneNumber.phone"}
                            required
                            onChange={(val: any) => {
                              if (val && isValidPhoneNumber(val)) {
                                setPhoneError("");
                              }
                              setValues({
                                ...props.values,
                                phoneNumber: {
                                  ...props.values.phoneNumber,
                                  phone: val
                                }
                              });
                            }}
                            onBlur={() => {
                              if (!props.values.phoneNumber.phone) {
                                setPhoneError("Phone number is required.");
                              } else if (!isValidPhoneNumber(props.values.phoneNumber.phone)) {
                                setPhoneError("Enter a valid phone number.");
                              } else {
                                setPhoneError("");
                              }
                            }}
                            onCountryChange={(val) => {
                              setCountryCode(val);
                            }}
                          />
                          {
                            phoneError !== "" ?
                              <span className={"phone-error-message"}>{phoneError}</span>
                              : (meta.touched && meta.error) &&
                              <span className={"phone-error-message"}>
                                {phoneError || "Phone number is required."}
                              </span>
                          }
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="email"
                    placeholder="Email Address"
                    label="Email Address"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          email: e.target.value
                        })
                      },
                      value: props.values.email,
                      maxLength: 50
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid container item xs={12}>
                  <Fab
                    className="blackBackButton"
                    variant="extended"
                    size="medium"
                    type="submit"
                    aria-label="add"
                  >
                    ADD
                  </Fab>
                </Grid>
                {businessCustomer.authorizedSignatories.length > 0 &&
                  businessCustomer.authorizedSignatories.map(
                    (signatory: any, index: number) => (
                      <Grid item container xs={12} key={index}>
                        <div className={classes.authorsationTable}>
                          <Grid container item xs={12} md={6} lg={6} xl={6}>
                            <Typography variant="body1">
                              {" "}
                              {signatory.firstName} {signatory.lastName}{" "}
                            </Typography>
                          </Grid>
                          <Grid container item xs={12} md={6} lg={6} xl={6} justifyContent="flex-end">
                            <Typography>
                              {" "}
                              <Fab
                                variant="extended"
                                size="medium"
                                onClick={() => removeSignatoryHandler(index)}
                              >
                                <DeleteIcon />
                              </Fab>{" "}
                            </Typography>
                          </Grid>
                        </div>
                      </Grid>
                    )
                  )}
                <Grid container item xs={12}>
                  <Fab
                    className="blackBackButton"
                    variant="extended"
                    size="medium"
                    onClick={handlePrevious}
                  >
                    BACK
                  </Fab>
                  <Box color="white" sx={{ pr: 2 }}></Box>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="add"
                    onClick={proceedHandler}
                  >
                    PROCEED
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};
