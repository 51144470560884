import { gql } from "@apollo/client";

export const GET_BUSINESS_CUSTOMERS = gql`
  query businessCustomers {
    businessCustomers {
      id
      businessName
      VAT
      accountManager {
        id
        firstName
        lastName
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
    }
  }
`;

export const GET_LIMIT_BUSINESS_CUSTOMERS = gql`
  query limitBusinessCustomers($limit: Int!, $offset: Int!) {
    limitBusinessCustomers(limit: $limit, offset: $offset) {
      id
      businessName
      VAT
      status
      contact {
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
    }
  }
`;

export const GET_BUSINESS_CUSTOMER = gql`
  query businessCustomer($businessCustomerId: ID!) {
    businessCustomer(businessCustomerId: $businessCustomerId) {
      id
      businessName
      registrationNumber
      VAT
      accountManager {
        id
        firstName
        lastName
      }
      taxExempted
      overrideNetPaymentTerms
      requiresBookingApproval
      netPaymentTerms
      dailyBillingCycle
      weeklyBillingCycle
      monthlyBillingCycle
      overrideInvoiceDate
      invoicingDay
      credentialSent
      status
      credentialSentOn
      isClientExists
      declinedReason
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
        license {
          licenseNumber
          dateOfExpiry
          authority
          images
        }
        nationalInsuranceNumber
        profession
        location {
          street
          country
          state
          fullAddress
          zipcode
          city
        }
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone 
          country
        }
        email
        accountNumber
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
      notes{
        id
        description
        createdBy
        createdDate
      }
      coiDetails {
        policyName
        policyNumber
        provider
        expiryDate
        documentUrl
      }
      businessCreditData {
        creditScore
        creditDescription
        lastChecked
      }
      isRingFenced
      vehicleGroups {
        id
        name
      }
      tags
      creditLimitEnabled
      creditLimit
      overrideWeeklyInvoiceDate
      weeklyInvoicingDay
      overrideServiceScheduleByMonth
      autoPayDisabled
    }
  }
`;

export const CREATE_BUSINESS_CUSTOMER = gql`
  mutation createBusinessCustomer(
    $businessCustomer: BusinessCustomerCreateInput!
  ) {
    createBusinessCustomer(businessCustomer: $businessCustomer) {
      id
      businessName
      registrationNumber
      VAT
      accountManager {
        id
        firstName
        lastName
      }
      overrideNetPaymentTerms
      requiresBookingApproval
      netPaymentTerms
      dailyBillingCycle
      weeklyBillingCycle
      monthlyBillingCycle
      overrideInvoiceDate
      invoicingDay
      credentialSent
      credentialSentOn
      isClientExists
      status
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
        license {
          licenseNumber
          dateOfExpiry
          authority
          images
        }
        nationalInsuranceNumber
        profession
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone 
          country
        }
        email
        accountNumber
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
      notes{
        id
        description
        createdBy
        createdDate
      }
      creditLimitEnabled
      creditLimit
      overrideWeeklyInvoiceDate
      weeklyInvoicingDay
      overrideServiceScheduleByMonth
    }
  }
`;

export const UPDATE_BUSINESS_CUSTOMER = gql`
  mutation updateBusinessCustomer(
    $businessCustomerId: ID!
    $businessCustomer: BusinessCustomerUpdateInput!
  ) {
    updateBusinessCustomer(
      businessCustomerId: $businessCustomerId
      businessCustomer: $businessCustomer
    ) {
      id
      accountManager {
        id
        firstName
        lastName
      }
      businessName
      registrationNumber
      VAT
      taxExempted
      overrideNetPaymentTerms
      requiresBookingApproval
      netPaymentTerms
      dailyBillingCycle
      weeklyBillingCycle
      monthlyBillingCycle
      overrideInvoiceDate
      invoicingDay
      credentialSent
      credentialSentOn
      isClientExists
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
        license {
          licenseNumber
          dateOfExpiry
          authority
          images
        }
        nationalInsuranceNumber
        profession
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone 
          country
        }
        email
        accountNumber
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
      notes {
        id
        description
        createdBy
        createdDate
      }
      coiDetails {
        policyName
        policyNumber
        provider
        expiryDate
        documentUrl
      }
      isRingFenced
      vehicleGroups {
        id
        name
      }
      tags
      creditLimitEnabled
      creditLimit
      overrideWeeklyInvoiceDate
      weeklyInvoicingDay
      overrideServiceScheduleByMonth
      autoPayDisabled
    }
  }
`;

export const REMOVE_BUSINESS_CUSTOMER_TAG = gql`
  mutation removeBusinessCustomerTag($tag: String!) {
    removeBusinessCustomerTag(tag: $tag) {
      success
      message
    }
  }
`
