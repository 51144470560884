import "react-phone-number-input/style.css";
import "./Custome.scss";
import { useMutation } from "@apollo/client";
import {
  Fab,
  FormControl,
  Grid,
  TextField as InputField,
  MenuItem,
  Theme,
  Typography,
  FormControlLabel,
  Checkbox,
  Switch
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, FieldProps, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { DateTime as d } from "luxon"
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { UPDATE_CUSTOMER } from "../../../../graphql/customers/updateCustomerMutation";
import {
  Gender,
  ICustomer
} from "../../../../reducers/customer/types";
import { IAppState } from "../../../../store";
import AddressPicker from "../NewCustomer/AddressPicker/AddressPicker";
import FlatPickerBar from "../../../common/FlatPicker";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  monthDates,
  weekdays,
} from "../../../common/utils";
import { ApolloError } from "@apollo/client";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { IAddress } from "../../../../reducers/user/types";
import { CircularProgress } from "@mui/material";
import DragDropImages from "../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";
import { getDefaultCountryCode, getLocalizedBookingSyntex, getLocalizedDateFormat, getLocalizedInsuranceNumberSyntex, getLocalizedTaxSyntex } from "../../../../utils/localized.syntex";
import { ICostCenter } from "../../../../reducers/organisation/types";
import NuvvenAutoCompleteTags from "../../../common/NuvvenAutoCompleteTags";
/** @type {{active: React.CSSProperties}} */
/** @type {{inactive: React.CSSProperties}} */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%",

      borderRadius: "10px"
    },
    content: {
      height: "auto"
    },
    phonenumber: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    root: {
      borderBlock: "10px",
      borderColor: "f4f4fa",
      padding: theme.spacing(5)
    },
    section: {
      marginBottom: "25px"
    },
    selectRoot: {
      direction: "rtl",
      textAlign: "left"
    }
  })
);

interface ICustomerProps {
  customer: ICustomer;
  onUpdate(customer: ICustomer): void;
  uploadDocument(files: File[]): Promise<void>;
  documentDialogVisible: boolean;
  uploadInProgress: boolean;
  filesLimit: number;
  handleClose(): void;
  handleOpen(): void;
}

const CustomerUpdate = (props: ICustomerProps) => {
  const classes = useStyles();
  const { handleOpen, handleClose, uploadDocument, documentDialogVisible, filesLimit, onUpdate } = props;
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const organisation = userState.currentOrganisation;
  const [integrations] = useState<string[]>([]);
  const [values, setValues] = useState(props.customer);
  const [phoneError, setPhoneError] = useState<string>("");
  const [secPhoneError, setSecPhoneError] = useState<string>("");
  const [countryCode, setCountry] = useState<any>({
    primary: getDefaultCountryCode(country),
    secondary: getDefaultCountryCode(country)
  });
  const [minDate, setMinDate] = useState<string>(d.now().toUTC().toISO());
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackBar();
  const [availableCostCenters, setAvailableCostCenters] = useState<ICostCenter[]>([])
  const [costCenters, setCostCenters] = useState<ICostCenter[]>([{
    name: "",
    projectIds: []
  }]);

  useEffect(() => {
    if (props.customer) {
      setValues(props.customer)
    }
  }, [props.customer])

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: (data) => {
      if (data.customer) {
        setValues(data.customer)
      }
      snackbar({
        message: "Customer updated successfully!",
        variant: SnackBarVariant.SUCCESS
      });
      setLoading(false);
    },
    onError: (error: ApolloError) => {
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      // setLoading(false);
    },
  });

  useEffect(() => {
    if (props.customer) {
      setValues(props.customer);
      if (props.customer.license.dateOfExpiry && d.fromISO(minDate) >= d.fromISO(props.customer.license.dateOfExpiry)) {
        setMinDate(props.customer.license.dateOfExpiry)
      }
      if (props.customer.costCenters) {
        setCostCenters(props.customer.costCenters)
      }
    }
  }, [props.customer]);

  useEffect(() => {
    if (organisation && organisation.enableCostCenter && organisation.costCenters) {
      setAvailableCostCenters(organisation.costCenters)
    }
  }, [organisation])

  const addRows = () => {
    const rowsArr = [...costCenters];
    rowsArr.push({
      name: "",
      projectIds: []
    });
    setCostCenters(rowsArr);
  };

  const getFeatures = (ccName: string) => {
    const ele = availableCostCenters.find((cc) => cc.name === ccName);
    if (ele) {
      return ele.projectIds
    }
    return []
  }

  // FIXME: add required types for values
  function handleFormSubmit(values: any) {
    const customerId = props.customer.id;
    delete values.identityProvider;
    delete values.costCenterName;
    if (organisation.enableCostCenter) {
      if (costCenters.length) {
        if (costCenters.some((cc) => !cc.name)) {
          snackbar({
            message: "Cost Center Details can't be empty! Please remove empty rows.",
            variant: SnackBarVariant.ERROR
          })
          return
        }
        values.costCenters = costCenters
      } else {
        snackbar({
          message: "Cost Center Details can't be empty!",
          variant: SnackBarVariant.ERROR
        })
        return
      }
    }
    const customer: ICustomer = {
      ...values,
      address: values.location && values.location.fullAddress
    };
    const { id, notes, verified, deleted, documents, approvedDrivers, verifiedDate, creditSafeData, credentialSent, credentialSentOn, ...rest } = customer;
    updateCustomer({
      variables: {
        customer: rest,
        customerId
      }
    }).then((res) => props.onUpdate(res.data.updateCustomer));
    setLoading(true);
  }

  const eighteenYearsOldDate = getLocalizedDateFormat(country, d.now().minus({ year: 18 }).toUTC().toISO(), DATE_TYPE.CONDENSED)
  const hyearsold = getLocalizedDateFormat(country, d.now().minus({ year: 95 }).toUTC().toISO(), DATE_TYPE.CONDENSED)

  const customerSchema = Yup.object().shape({
    dateOfBirth: Yup.date(),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" })
          }
          return true;
        })
        .required("Phone number is required.")
    }),
    email: Yup.string()
      .required("Email is required.")
      .email("Please enter a valid email address."),
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    location: Yup.object().shape({
      fullAddress: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      street: Yup.string().required("Street is required"),
    }).required("Address is required"),
    weeklyInvoicingDay: Yup.string().when('overrideWeeklyInvoiceDate', {
      is: true,
      then: schema => schema.required("Weekly Invoicing Day is required.").nullable(),
      otherwise: schema => schema.notRequired().nullable()
    }),
    invoicingDay: Yup.string().when('overrideInvoiceDate', {
      is: true,
      then: schema => schema.required("Monthly Invoicing Date is required.").nullable(),
      otherwise: schema => schema.notRequired().nullable()
    }),
  });

  return (
    <Grid container>
      <Formik
        enableReinitialize
        validationSchema={customerSchema}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          const updateValues = {
            ...values,
            phoneNumber: {
              ...values.phoneNumber,
              country: countryCode.primary
            },
            secondaryPhoneNumber: {
              ...values.secondaryPhoneNumber,
              country: countryCode.secondary
            }
          };
          handleFormSubmit(updateValues);
          setSubmitting(false);
        }}
      >
        {(props) => (
          <Form>
            <Grid container>
              <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="First Name"
                    label="First Name"
                    name={"firstName"}
                    value={props.values.firstName}
                    onChange={props.handleChange}
                    fullWidth
                    required
                  ></Field>
                </Grid>

                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Last Name"
                    label="Last Name"
                    name={"lastName"}
                    value={props.values.lastName}
                    onChange={props.handleChange}
                    fullWidth
                    required
                  ></Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name={"phoneNumber.phone"}
                    required
                  >
                    {({ form: { setFieldValue, setTouched, touched, errors } }: FieldProps) => {
                      return (
                        <div className={`phone-input-container`}>
                          <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                            Phone Number <sup className={"phone-input-required"}>*</sup>
                          </label>
                          <PhoneInput
                            international={false}
                            country={countryCode.primary}
                            placeholder="Enter phone number"
                            className={phoneError ? "phone-input-error" : ""}
                            value={props.values.phoneNumber.phone}
                            name={"phoneNumber.phone"}
                            required
                            onChange={(val: any) => {
                              if (val && isValidPhoneNumber(val)) {
                                setPhoneError("");
                              }
                              setValues({
                                ...props.values,
                                phoneNumber: {
                                  ...props.values.phoneNumber,
                                  phone: val
                                }
                              });
                            }}
                            onBlur={() => {
                              if (!props.values.phoneNumber.phone) {
                                setPhoneError("Phone number is required.");
                              } else if (!isValidPhoneNumber(props.values.phoneNumber.phone)) {
                                setPhoneError("Enter a valid phone number.");
                              } else {
                                setPhoneError("");
                              }
                            }}
                            onCountryChange={(val) => {
                              setCountry({
                                ...countryCode,
                                primary: val
                              });
                            }}
                          />
                          {
                            phoneError &&
                            <span className={"phone-error-message"}>{phoneError}</span>
                          }
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name={"secondaryPhoneNumber.phone"}
                  >
                    {({ form: { setFieldValue, setTouched, touched, errors } }: FieldProps) => {
                      return (
                        <div className={`phone-input-container`}>
                          <label className={`${secPhoneError ? "input-error-label" : ""} phone-input-label`}>
                            Secondary Phone Number
                          </label>
                          <PhoneInput
                            international={false}
                            defaultCountry={countryCode.secondary}
                            placeholder="Enter secondary phone number"
                            value={props.values.secondaryPhoneNumber?.phone}
                            name={"secondaryPhoneNumber.phone"}
                            onChange={(val: any) => {
                              if (val) {
                                if (val && isValidPhoneNumber(val)) {
                                  setSecPhoneError("");
                                } else if (val === undefined) {
                                  setSecPhoneError("");
                                } else {
                                  setSecPhoneError("Enter a valid phone number.");
                                }
                                setValues({
                                  ...props.values,
                                  secondaryPhoneNumber: {
                                    ...props.values.secondaryPhoneNumber,
                                    phone: val
                                  }
                                });
                              }
                            }}
                            onCountryChange={(val) => {
                              setCountry({
                                ...countryCode,
                                secondary: val
                              });
                            }}
                          />
                          {
                            secPhoneError &&
                            <span className={"phone-error-message"}>{secPhoneError}</span>
                          }
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Email Address"
                    label="Email Address"
                    name={"email"}
                    value={props.values.email}
                    onChange={props.handleChange}
                    fullWidth
                    required
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Secondary Email Address"
                    label="Secondary Email Address"
                    name={"secondaryEmail"}
                    value={props.values.secondaryEmail}
                    onChange={props.handleChange}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={InputField}
                      name={"gender"}
                      fullWidth
                      type="text"
                      select
                      label="Gender"
                      value={props.values.gender}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        onChange: (event: any) => {
                          setValues({
                            ...props.values,
                            gender: event.target.value
                          });
                        }
                      }}
                      onChange={props.handleChange}
                      className={classes.selectRoot}
                    >
                      {Object.values(Gender).map(
                        (element: string, key: number) => {
                          if (
                            element === Gender.MALE ||
                            element === Gender.FEMALE ||
                            element === Gender.OTHER ||
                            element === Gender.NOTTODISCLOSE ||
                            integrations.includes(element)
                          ) {
                            return (
                              <MenuItem value={element} key={key}>
                                {element.replace(/_/g, " ")}
                              </MenuItem>
                            );
                          }
                        }
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Profession"
                    label="Profession"
                    name={"profession"}
                    value={props.values?.profession}
                    onChange={props.handleChange}
                    inputProps={{ maxLength: 30 }}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Driving Licence Number"
                    label="Driving Licence Number"
                    name={"license.licenseNumber"}
                    value={props.values?.license?.licenseNumber}
                    onChange={props.handleChange}
                    inputProps={{ maxLength: 20 }}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder={getLocalizedInsuranceNumberSyntex(country)}
                    label={getLocalizedInsuranceNumberSyntex(country)}
                    name={"nationalInsuranceNumber"}
                    value={props.values?.nationalInsuranceNumber}
                    onChange={props.handleChange}
                    inputProps={{ maxLength: 20 }}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Private Hires No"
                    label="PCO/Private Hire DL No"
                    name={"customerPCONumber"}
                    value={props.values.customerPCONumber}
                    onChange={props.handleChange}
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Licence Issued By"
                    label="Licence Issued By"
                    name={"license.authority"}
                    value={props.values?.license?.authority}
                    onChange={props.handleChange}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <FormControl variant="outlined" fullWidth>
                    <FlatPickerBar
                      enableTime={false}
                      handleDateChange={(value: Date) => {
                        const license = {
                          ...props.values.license,
                          dateOfExpiry: d.fromJSDate(value).toUTC().toISO()
                        };
                        props.setFieldValue("license", license);
                      }}
                      label={"Driving Licence Expiry Date"}
                      identifier={"dateOfExpiry"}
                      placeholderValue={"Driving Licence Expiry Date"}
                      value={d.fromISO(props.values?.license?.dateOfExpiry).toUTC().toISO()}
                      minDate={"today"}
                      country={country}
                    />
                  </FormControl>
                </Grid>
                <Grid item container xs={12} sm={12} md={4}>
                  <FormControl variant="outlined" fullWidth>
                    <FlatPickerBar
                      enableTime={false}
                      handleDateChange={(value: Date) =>
                        props.setFieldValue(
                          "dateOfBirth",
                          d.fromJSDate(value).startOf("day").toUTC().toISO()
                        )
                      }
                      label={"Date of Birth"}
                      identifier={"dateOfBirth"}
                      placeholderValue={"Date of Birth"}
                      value={getLocalizedDateFormat(country, props.values.dateOfBirth, DATE_TYPE.CONDENSED)}
                      minDate={hyearsold}
                      maxDate={eighteenYearsOldDate}
                      country={country}
                    />
                  </FormControl>
                </Grid>
                <Grid item container xs={12} sm={12} md={4}>
                  <AddressPicker
                    fieldName="location"
                    required
                    onChange={(address: IAddress) => {
                      if (address) {
                        setValues({ ...props.values, location: address })
                      }
                    }}
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="accountNumber"
                    placeholder="Account Number"
                    label="Account Number"
                    value={props.values.accountNumber || ""}
                    onChange={props.handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <FormControl variant="outlined" fullWidth>
                    <FlatPickerBar
                      enableTime={false}
                      handleDateChange={(value: Date) => {
                        const license = {
                          ...props.values.license,
                          dateOfIssue: d.fromJSDate(value).toUTC().toISO()
                        };
                        props.setFieldValue("license", license);
                      }}
                      label={"Driving Licence Issue Date"}
                      identifier={"dateOfIssue"}
                      placeholderValue={"Driving Licence Issue Date"}
                      now={d.fromISO(props.values?.license?.dateOfIssue).toUTC().toISO()}
                      value={d.fromISO(props.values?.license?.dateOfIssue).toUTC().toISO()}
                      maxDate={d.now().toUTC().toISO()}
                      country={country}
                    />
                  </FormControl>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.taxExempted}
                          onChange={() => {
                            setValues({
                              ...props.values,
                              taxExempted:
                                !props.values.taxExempted
                            });
                          }}
                          value="taxExempted"
                          color="secondary"
                        />
                      }
                      label={
                        <Typography variant={"h5"}>
                          {/* {"Check this for VAT/TAX Exempt Customer"} */}
                          {`Check this for ${getLocalizedTaxSyntex(country)} Exempt Customer`}
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                  <Fab
                    className="blackBackButton"
                    variant="extended"
                    size="medium"
                    aria-label="Update"
                    onClick={() => {
                      onUpdate(props.values)
                      handleOpen()
                    }}
                  >
                    UPLOAD LICENCE IMAGE
                  </Fab>
                  <Grid item>
                    <Typography variant="h4">
                      Upload front and back image of Licence. Supported file format: .png,
                      .jpeg, .jpg!.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Typography variant={"h2"}>Invoicing Settings</Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.values.overrideInvoiceDate}
                          onClick={props.handleChange}
                          value="overrideInvoiceDate"
                          name="overrideInvoiceDate"
                        />
                      }
                      label={`Preferred Invoicing Date for Monthly ${getLocalizedBookingSyntex(country)}s`}
                    />
                  </Grid>
                  {props.values.overrideInvoiceDate && (
                    <Grid item xs={4}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={InputField}
                          name={"invoicingDay"}
                          fullWidth
                          type="number"
                          select
                          label="Select Date"
                          required={props.values.overrideInvoiceDate}
                          value={props.values.invoicingDay || ''}
                          error={props.errors.invoicingDay}
                          inputProps={{
                            name: "invoicingDay",
                            value: props.values.invoicingDay,
                            onChange: props.handleChange,
                          }}
                          helperText={props.errors.invoicingDay}
                          placeholder="Select Date"
                        >
                          {monthDates.map((item: number) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.values.overrideWeeklyInvoiceDate}
                          onClick={props.handleChange}
                          name="overrideWeeklyInvoiceDate"
                          value="overrideWeeklyInvoiceDate"
                        />
                      }
                      label={`Preferred Invoicing Day for Weekly ${getLocalizedBookingSyntex(country)}s`}
                    />
                  </Grid>
                  {props.values.overrideWeeklyInvoiceDate && (
                    <Grid item xs={4}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={InputField}
                          name={"weeklyInvoicingDay"}
                          fullWidth
                          error={props.errors.weeklyInvoicingDay}
                          type="number"
                          select
                          label="Select Day"
                          value={props.values.weeklyInvoicingDay || ''}
                          required={props.values.overrideWeeklyInvoiceDate}
                          inputProps={{
                            name: "weeklyInvoicingDay",
                            value: props.values.weeklyInvoicingDay,
                            onChange: props.handleChange
                          }}
                          placeholder="Select Day"
                          helperText={props.errors.weeklyInvoicingDay}
                        >
                          {weekdays.map((item: string, index: number) => (
                            <MenuItem key={item} value={index + 1}>
                              {item}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                {organisation.enableCostCenter && availableCostCenters.length > 0 &&
                  <Grid container xs={12} style={{ margin: "0.8rem" }}>
                    <Grid container xs={12}>
                      <Grid item xs={11}>
                        <Typography
                          variant={"subtitle1"}
                        >
                          Cost Center Details
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          variant={"subtitle1"}
                          onClick={addRows}
                          style={{ cursor: "pointer" }}
                        >
                          + Add New
                        </Typography>
                      </Grid>
                    </Grid>
                    {costCenters.map((item, idx) => {
                      return (
                        <Grid container xs={12} style={{ marginTop: "1rem" }}>
                          <Grid container xs={11} spacing={2}>
                            <Grid item xs={12} md={4}>
                              <Field
                                component={TextField}
                                required
                                label={"Cost Center"}
                                select
                                inputProps={{
                                  onChange: (event: any) => {
                                    if (costCenters.some((cc) => cc.name === event.target.value)) {
                                      return snackbar({
                                        message: "Cost center already selected!",
                                        variant: SnackBarVariant.ERROR
                                      })
                                    }
                                    const item = costCenters[idx];
                                    const rowsArr = [...costCenters];
                                    const ele = availableCostCenters.find((element) => element.name === event.target.value)
                                    if (ele) {
                                      item.name = ele.name;
                                    }
                                    rowsArr.splice(idx, 1, item);
                                    setCostCenters(rowsArr);
                                  },
                                  value: item.name
                                }}
                                name={"costCenterName"}
                                fullWidth
                              >
                                {availableCostCenters.map((cc) => (
                                  <MenuItem
                                    key={cc.name}
                                    value={cc.name}
                                  >
                                    {cc.name}
                                  </MenuItem>
                                ))}
                              </Field>
                              {/* <FormControl variant="outlined" fullWidth>
                                <Autocomplete
                                  id="select-cost-center"
                                  options={availableCostCenters}
                                  getOptionLabel={(option: ICostCenter) => {
                                    console.log(option)
                                    if (option) {
                                      return option.name;
                                    }
                                    return ""
                                  }}
                                  onInputChange={(e, newval, reason) => console.log(newval)}
                                  style={{ width: "100%" }}
                                  renderInput={(params: any) => (
                                    <InputField
                                      {...params}
                                      value={item ? item.name : ""}
                                      label={"Cost Center"}
                                      variant="outlined"
                                      onChange={(val: any) => {console.log(val)}}
                                      // required
                                    />
                                  )}
                                  // value={item.name}
                                  // disabled={!!values.id}
                                  // onChange={(event: any, newValue: any) => {
                                  //   console.log(newValue)
                                  //   // setRequiredFieldUpdated(true);
                                  //   // if (newValue && newValue.id && businessCustomers) {
                                  //   //   const businessCustomerData = businessCustomers.find(
                                  //   //     (customer: IBookingBusinessCustomer) => {
                                  //   //       return customer.id === newValue.id;
                                  //   //     }
                                  //   //   );
                                  //   //   if (businessCustomerData) {
                                  //   //     setBusinessCustomer(businessCustomerData);
                                  //   //     setValues({
                                  //   //       ...values,
                                  //   //       businessCustomer: businessCustomerData.id
                                  //   //     });
                                  //   //   }
                                  //   // }
                                  // }}
                                />
                              </FormControl> */}
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <NuvvenAutoCompleteTags
                                label="Project Ids"
                                placeHolder="Project Ids"
                                singleLine={true}
                                freeSoloDisabled={true}
                                handleTags={(tags: string[]) => {
                                  const item = costCenters[idx];
                                  const rowsArr = [...costCenters];
                                  item.projectIds = tags;
                                  rowsArr.splice(idx, 1, item);
                                  setCostCenters(rowsArr);
                                }}
                                customTags={true}
                                values={item.projectIds}
                                features={
                                  getFeatures(item.name)
                                }
                                columnLarge={12}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              variant={"subtitle1"}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const rowsArr = [...costCenters];
                                rowsArr.splice(idx, 1);
                                setCostCenters(rowsArr);
                              }}
                            >
                              X
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                }
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                      disabled={loading}
                    >
                      Save
                    </Fab>
                  </Grid>
                  <Grid item>
                    {loading && (
                      <CircularProgress size={20} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <div>
        <DragDropImages
          title={"Upload Licence Images"}
          open={documentDialogVisible}
          handleCloseDragDrop={handleClose}
          handleSave={uploadDocument}
          filesLimit={filesLimit}
        />
      </div>
    </Grid>
  );
};

export default CustomerUpdate;
