export enum TableNames {
  USERS = "users",
  PRICE_RULES = "priceRules",
  ORGANISATIONS = "organisations",
  INSURANCE_POLICY = "insurancePolycies",
  ADDONS = "addOns",
  BOOKINGS = "bookings",
  INVOICES = "invoices",
  CONSOLIDATED_INVOICES = "consolidatedInvoices",
  VEHICLE_PRICE_GROUPS = "vehiclePriceGroups",
  VEHICLE_INVENTROY = "vehicleInventory",
  SERVICE_AND_REPAIR = "serviceAndRepair",
  VEHICLE_GROUPS = "vehicleGroups",
  VEHICLE_DAMAGES = "vehicleDamages",
  BUSINESS_CUSTOMERS = "businessCustomers",
  ASSET_SERVICE = "assetService",
  CUSTOMERS = "customers",
  PARTNERS = "partner",
  SUBSCRIPTIONS = "subscriptions",
  FUEL_EXPENSES = "fuelExpense",
  CREDIT_NOTES = "creditNotes",
  FLEET_MOVEMENT = "fleetMovement",
  USER_JOBS = "userJobs",
  DEPOSITS = "deposits",
  PCN = "pcn",
  POLICIES = "policies",
  ROLES = "roles",
  PROCUREMENTS = "procurements",
  EMAIL_SETTINGS = "emailSettings",
  SERVICE_TASK_ITEMS = "serviceTaskItems",
  DOCUMENTS = "documents",
  DAMAGE_MATRIX = "damageMatrix",
}

export type ITableState = {
  [key in TableNames]?: ITable;
};

export interface ITable {
  tableName: TableNames;
  columnName?: string;
  direction?: string;
  rowsPerPage?: number;
  filters?: any[];
  viewColumns?: { [key: string]: String }; 
}