import { IAddress } from "../organisation/types";

export interface IProcurementDocumentInput {
	name: string;
	documentType: string;
	expiryDate?: string;
	url: string
}

export interface INoteInput {
	description: string;
	createdAt: string;
	createdBy: string;
}
export interface IProcurementRequest {
	id: string;
	referenceNumber: string;
	status: string;
	createdAt: string;
	createdBy: IUser;
	updatedAt: string;
	updatedBy: string;
	note: INote;
	items: IProcurementRequestItem[];
	proposalRequests: IProposalRequest[];
	quotes: IQuote[];
	purchaseOrders: IPurchaseOrder[];
	financeInformation: IFinanceInformation[];
	deliveryInformation: IDeliveryInformation[];
}


export interface IProcurementRequestItemInput {
	requestId: string;
	assetType: string;
	quantity: number;
	make: string;
	model: string;
	variant: string;
	fuel: string;
	transmission: string;
	condition: string;
	expectedByDate: string;
	note: INote;
	status?: string;
}

export interface IProcurementRequestItem {
	id?: string
	requestId?: string;
	assetType: string;
	quantity: number;
	make: string;
	model: string;
	variant: string;
	fuel: string;
	transmission: string;
	condition: string;
	expectedByDate: string;
	note: INote;
	status?: string;
}

export interface IProposalRequest {
	id?: string;
	proposalRequestId?: string;
	partnerIds: string[];
	date: string;
	partner?: IPartner;
	validUntilDate: string;
	note: INote;
	items: ICommonItemFields[];
	lastEmailSent?: string;
}

export interface IQuote {
	id?: string;
	quoteId: string;
	date: string;
	partnerId: string;
	partner?: IPartner;
	validUntilDate: string;
	lastEmailSent: String
	note: INote;
	amount: number
	proposalRequestId: string;
	documents: string[];
	status: String;
	revisions?: IRevision[];
}

export interface IRevision {
	quoteId: string;
	amount: number;
	date: string;
	validUntilDate: string;
	updatedAt: string;
	documents: string[];
}

export interface IPurchaseOrder {
	id?: string;
	purchaseOrderId: string; //Purchase order Id
	date: string;
	partnerId: string;
	partner?: IPartner;
	quoteId: string;
	validUntilDate: string;
	lastEmailSent: string;
	note: INote;
	items: ICommonItemFields[]
}
export interface IFinanceInformation {
	id?: string;
	purchaseOrderId: string //Purchase order id
	purchaseOrderDate: string;
	partnerId: string;
	invoiceId: string;
	invoiceDate: string;
	totalAmount: number;
	expectedArrivalDate: string;
	advancedPayment: number;
	paymentSource: string;
	documents: string[];
	partner?: IPartner;
}

export interface IDeliveryInformation {
	id?: string;
	deliveryNoteId: string;
	deliveryNoteDate: string;
	partnerId: string;
	purchaseOrderId: string;
	productReceivedDate: string;
	receivedProductNumber: number;
	documents: string[];
	partner?: IPartner;
	status?: String;
}

export interface ICommonItemFields {
	requestId?: string;
	vehicleMake: string;
	vehicleModel: string;
	vehicleVarient: string;
	vehicleFuel: string;
	vehicleTransmission: string;
	vehicleCondition: string;
	vehicleCount: number;
	vehicleExpectedByDate: string;
	documents: string[];
}

export interface ICommonProcurementFields {
	id?: string;
	referenceNumber: string; // FRP Id
	date: string;
	partnerId: string;
	validUntilDate: string;
	lastEmailSent?: string;
	note: INote;
}

export interface INote {
	description: string;
	createdAt: string;
	createdBy: string | IUser;
}

export interface IUser {
	id: string;
	firstName: string;
	lastName: string;
}

export interface IPartner {
	id: string;
	isActive: boolean;
	partnerId: string;
	partnerName: string;
	partnerType: string;
	address: IAddress;
	email: string;
	VAT: String;
	phoneNumber: IPhoneNumber;
	companyRegistrationNo: string;
	documents: [Document];
	tenantId: string;
	organisationId: string;
	branchId: string;
	createdAt: String;
	createdBy: String;
	updatedAt: String;
	updatedBy: String;
}
interface IPhoneNumber {
	phone: string;
	country: string;
}
export enum ProcurementRequestStatuses {
	"PENDING" = "PENDING",
	"APPROVED" = "APPROVED",
	"REJECTED" = "REJECTED",
	"PARTIALLY_REJECTED" = "PARTIALLY_REJECTED",
	"COMPLETED" = "COMPLETED",
	"PARTIALLY_APPROVED" = "PARTIALLY_APPROVED"
}

export enum ProcurementItemStatuses {
	APPROVED = "APPROVED",
	REJECTED = "REJECTED",
	PENDING = "PENDING",
	RECEIVED = "RECEIVED"
}

export const ProcurementStatusValue: {
	[key in ProcurementRequestStatuses]: string;
} = {
	APPROVED: "Approved",
	PENDING: "Pending",
	COMPLETED: "Completed",
	PARTIALLY_REJECTED: "Partially Rejected",
	REJECTED: "Rejected",
	PARTIALLY_APPROVED: "Partially Approved"
};