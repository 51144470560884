import { useLazyQuery } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import { addTableState } from "../../../../../actions/tableState/actions";
import { GET_INSURANCE_POLICIES } from "../../../../../graphql/insurancePolicy/getInsurancePolicies";
import { ITable, TableNames } from "../../../../../reducers/tableState/types";
import { IAppState } from "../../../../../store";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder, toCurrency } from "../../../../common/utils";
import { SelectableRows } from "../../../../common/NuvvenTable/types";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";


const columns: any = [
  {
    label: "Policy Name",
    name: "name"
  },
  {
    label: "Hourly",
    name: "hourly"
  },
  {
    label: "Daily",
    name: "daily"
  },
  {
    label: "Weekly",
    name: "weekly"
  },
  {
    label: "Monthly",
    name: "monthly"
  },
  {
    label: "Excess",
    name: "excess"
  }
];

const InsuranceSettings = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const [rows, setRows] = useState([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [insurancePolyciesTable, setInsurancePolyciesTable] = useState<ITable>();
  const [getInsurancePolicies, { loading, data }] = useLazyQuery(
    GET_INSURANCE_POLICIES,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const insurancePoliciesTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.insurancePolycies
  );

  useEffect(() => {
    if (insurancePoliciesTableState) {
      setInsurancePolyciesTable(insurancePoliciesTableState);
      let sortedOrder = returnSortedOrder(insurancePoliciesTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, insurancePoliciesTableState, sortedOrder)
      }
    }
  }, [insurancePoliciesTableState]);

  useEffect(() => {
    getInsurancePolicies({
      variables: { organisationId: String(userState.currentOrganisation.id) }
    });
  }, [userState]);

  useEffect(() => {
    if (data && data.insurancePolicies) {
      const insurancePolicies = _.cloneDeep(data.insurancePolicies);
      const updateRows = insurancePolicies.map(
        (item: any) => {
          item.excess = toCurrency(item.excess, currency, locale);
          return Object.keys(item.insuranceRate)
            .map((row: any, rowIndex: number) => {
              return {
                [item.insuranceRate[rowIndex].rateName]:
                  toCurrency(item.insuranceRate[rowIndex].rate, currency, locale)
              };
            })
            .reduce((pre, nex) => {
              const newRow = Object.assign(pre, nex);
              return Object.assign(item, newRow);
            });
        }
      );
      setRows(updateRows);
    }
  }, [data]);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row = data.insurancePolicies[rowMeta.dataIndex];
      const insuranceId = row.id
      if (insuranceId) {
        navigate(`/update-insurance?insuranceId=${insuranceId}`);
      }

    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      dispatch(
        addTableState({
          tableName: TableNames.INSURANCE_POLICY,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      insurancePolyciesTable && insurancePolyciesTable.rowsPerPage
        ? insurancePolyciesTable.rowsPerPage
        : 10,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.INSURANCE_POLICY,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: () => {
      if (insurancePolyciesTable && sortOrder) {
        setTableSortOrder(columns, insurancePolyciesTable, sortOrder);
      }
    }
  };

  const disabledRoles = ["FINANCE_MANAGER", "FLEET_MANAGER"];

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6} alignItems="center">
        <Typography variant="h1" color="primary">
          Insurance{"  "}
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          onClick={() => {
            navigate(`/new-insurance`);
          }}
          disabled={disabledRoles.includes(userState.role)}
        >
          Create
        </Fab>
      </Grid>
      <Grid item container xs={12}>
        {!loading ? (
          <NuvvenTable
            title={""}
            rows={rows}
            columns={columns}
            options={options}
            setSelection={(item: any) => item}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
};

export default InsuranceSettings;
