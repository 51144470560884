import { gql } from "@apollo/client";

export const CANCEL_QUOTE = gql`
  mutation cancelQuote($id: String!, $cancellationReason: String) {
    cancelQuote(id: $id, cancellationReason: $cancellationReason) {
      id
      status
    }
  }
`;
