import { gql } from "@apollo/client";

export const CREATE_INVOICE_FINANCE_HISTORY = gql`
  mutation createDownloadHistoryDocument($history: InvoiceFinaceHistoryInput!) {
    createDownloadHistoryDocument(history: $history) {
      id
      userName
      email
      dowloadDate
      fields
      fileName
      branchId
      organisationId
      tenantId
      templateName
    }
  }
`;