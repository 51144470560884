import { Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Typography } from '@mui/material';
import React from 'react'

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isActive: boolean;
}
const ConfirmTogglePriceRuleDialog: React.FC<IProps> = (props) => {

  const { open, onClose, onConfirm, isActive } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{ padding: 15 }}><Typography variant={"h3"}>CONFIRM ACTION</Typography></DialogTitle>
      <DialogContent style={{ overflowY: "hidden" }}>
        {
          isActive ? (
            <Grid item xs={12}>
              <Typography variant={"h3"}>Are you sure you want to deactivate this price rule?</Typography>
              <Typography variant={"body1"}>PLEASE NOTE: If this price rule was previously applied to any booking that is now Confirmed or In Progress, the price for such booking(s) will not change as a result of this deactivation.</Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant={"h3"}>Are you sure you want to activate this price rule?</Typography>
              <Typography variant={"body1"}>PLEASE NOTE: Upon activating this price rule, you may need to review the rate matrix and click on the SAVE button if you make any changes.</Typography>
            </Grid>
          )
        }
      </DialogContent>
      <DialogActions>
        <Fab
          size="medium"
          variant="extended"
          className="blackBackButton"
          onClick={onClose}
        >
          Cancel
        </Fab>
        <Fab
          size="medium"
          variant="extended"
          onClick={onConfirm}
        >
          Confirm
        </Fab>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmTogglePriceRuleDialog
