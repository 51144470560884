import { AddonTypes, IAddonTypeState } from "./types";

export const addonInitialState: IAddonTypeState = {
  addonType: {
    id: "",
    name: "",
    category: AddonTypes.PRODUCT,
    displayName: "",
    imageUrl: "",
    hasFixedRate: false,
    hasQuantity: false,
    description: "",
    isActivated: true,
    recurringRate: [
      {
        rateTypeName: "hourly",
        rateTypeDuration: 60,
        rate: 0,
        longTerm: false
      },
      {
        rateTypeName: "daily",
        rateTypeDuration: 1440,
        rate: 0,
        longTerm: false
      },
      {
        rateTypeName: "weekly",
        rateTypeDuration: 10080,
        rate: 0,
        longTerm: false
      },
      {
        rateTypeName: "monthly",
        rateTypeDuration: 43200,
        rate: 0,
        longTerm: false
      }
    ],
    taxable: false,
    tax: {
      title: "",
      type: "",
      value: 0
    },
    rules: []
  }
};
