import { gql } from "@apollo/client";

export const UPDATE_PROCUREMENT_QUOTE_STATUS = gql`
  mutation updateProcurementQuoteStatus($id: ID!, $procurementQuoteId: ID!, $status: String!) {
    updateProcurementQuoteStatus(id: $id, procurementQuoteId: $procurementQuoteId, status: $status) {
      id
      purchaseOrderId
      date
      partnerId
      quoteId
      validUntilDate
      lastEmailSent
      partner {
        id 
        partnerName
        email
      }
      items {
        requestId
        vehicleMake
        vehicleModel
        vehicleVarient
        vehicleFuel
        vehicleTransmission
        vehicleCondition
        vehicleCount
        vehicleExpectedByDate
      }
    }
  }
`;