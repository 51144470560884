import {
  Box, Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { IVehicle, IVehicleState, IVehicleStateCheck, VehicleTypes, VehicleFuelType } from "../../../reducers/fleet/types";
import { DamagesApprovalStatus, IFrogDiagram } from "../VehicleDamagePreview/frogDiagram";
import { markDamages } from "../VehicleDamagePreview/damages";
import { useNavigate, useLocation } from "react-router-dom";
import { IDamage } from "../../views/Fleet/Issues&Damages/NewDamage/AddDamage";
import { DamgeImageView } from "./DamageImageview";
import { useLazyQuery, ApolloError } from "@apollo/client";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../common/utils";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { GET_EXTERNAL_VEHICLE } from "../../../graphql/fleet/getExternalVehicle";

export enum DamageTypes {
  EXTERIOR = 'exterior damage',
  INTERIOR = 'interior damage',
  OTHER = 'other damage',
}

interface IDamageCoordinates {
  exterior: string[],
  interior: string[]
}

interface IFormatedChecks {
  name: string;
  checked: boolean;
  missing: boolean;
  images: string[]
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: "30px"
    },
    table: {
      // minWidth: 650,
    },
    checkBox: {},
    checkBoxTitle: {
      fontSize: "14px",
      fontWeight: "normal",
      margin: "auto"
    },
    defaultFont: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "normal"
    },
    title: {
      fontWeight: 600
    },
    button: {
      paddingTop: "30px"
    },
    disabledCursor: {
      cursor: "default"
    }
  })
);
const svgHeight = 400;

export const ShareFleetVehicleState = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [values, setValues] = useState<IVehicleState>();
  const [vehicle, setVehicle] = useState<IVehicle>();
  const [svg, setSvg] = useState<IFrogDiagram>();
  const [damageCoordinates, setDamageCoordinates] = useState<IDamageCoordinates>({
    exterior: [],
    interior: []
  });
  const [exteriorDamages, setExteriorDamages] = useState<IDamage[]>([]);
  const [interiorDamages, setInteriorDamages] = useState<IDamage[]>([]);
  const [isFourWheeler, setIsFourWheeler] = useState<boolean>(false);

  const [getVehicle, { loading: loadingVehicle, data: vehicleData }] = useLazyQuery(GET_EXTERNAL_VEHICLE, {
    fetchPolicy: "network-only",
    onCompleted: ({ getExternalVehicle }) => {
      if (!getExternalVehicle) {
        navigate("/dashboard");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (vehicle?.vehicleType) {
      setIsFourWheeler(![VehicleTypes.TWO_WHEELER, VehicleTypes.ATV].includes(vehicle?.vehicleType))
    }
  }, [vehicle?.vehicleType])

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const vehicleId = params.get("vehicleId");
      const paramState: any = location.state;
      if (vehicleId && paramState.bookingId) {
        getVehicle({
          variables: {
            id: vehicleId,
            bookingId: paramState.bookingId
          }
        });
      }
    }
  }, [location, userState]);

  useEffect(() => {
    if (vehicleData && vehicleData.getExternalVehicle && vehicleData.getExternalVehicle.vehicleState) {
      setValues({ ...vehicleData.getExternalVehicle.vehicleState });
    }
    if (vehicleData && vehicleData.getExternalVehicle) {
      setVehicle(vehicleData.getExternalVehicle);
    }
  }, [vehicleData]);

  useEffect(() => {
    if (vehicleData && vehicleData.getExternalVehicle && vehicleData.getExternalVehicle.activeVehicleDamages) {
      const activeVehicleDamages = vehicleData.getExternalVehicle.activeVehicleDamages;
      if (activeVehicleDamages) {
        let exteriorDamagesData: any[] = [];
        let interiorDamagesData: any[] = [];
        for (const damage of activeVehicleDamages) {
          if (damage.approvalStatus !== DamagesApprovalStatus.PENDING) {
            damage.damages.filter((item: IDamage) => {
              if (item.damageType === "exterior damage") {
                exteriorDamagesData.push({ ...item, frogDiagram: damage.frogDiagram, referenceNumber: damage.referenceNumber ? damage.referenceNumber : "", damageId: damage.id, branchId: damage.branchId })
              } else if (item.damageType === "interior damage") {
                interiorDamagesData.push({ ...item, frogDiagram: damage.frogDiagram, referenceNumber: damage.referenceNumber, damageId: damage.id, branchId: damage.branchId })
              }
            });
          }
        }
        exteriorDamagesData = exteriorDamagesData.map((value: IDamage, index: number) => ({ ...value, id: index + 1 }));
        interiorDamagesData = interiorDamagesData.map((value: IDamage, index: number) => ({ ...value, id: index + 1 }));
        setExteriorDamages(exteriorDamagesData);
        setInteriorDamages(interiorDamagesData);

        const markData = markDamages([...exteriorDamagesData, ...interiorDamagesData], vehicleData.getExternalVehicle.frogDiagram);
        if (markData) {
          setSvg(markData.svg);
          setDamageCoordinates({
            exterior: markData.exterior,
            interior: markData.interior
          })
        }
      }
    }
  }, [vehicleData]);

  const handlerDamageClick = (vehicleDamageId: string | undefined) => {
    if (vehicleDamageId) {
      navigate(`/update-damage?damage=${vehicleDamageId}`);
    }
  }

  return (
    <Grid container className={classes.root} style={{ padding: 0 }}>
      {loadingVehicle ? <CircularProgress /> : (
        <React.Fragment>
          <Grid container item xs={6}>
            <Typography variant="h1" color="primary">
              Vehicle State
            </Typography>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: 10 }}>
            <Formik
              enableReinitialize
              initialValues={{}}
              onSubmit={(values, { setSubmitting }) => { }}
            >
              {(FormikProps) => (
                <Form>
                  <Grid container item xs={12} spacing={2}>
                    <Grid container item xs={12} >
                      <Paper className={classes.root} style={{ width: "100%" }}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="h3">
                              Vehicle Details
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12} style={{ marginTop: 10 }}>
                          <Grid item xs={3}>
                            <Grid container item xs={12}>
                              <Grid item>
                                <Typography variant="h6">
                                  Registration Number:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="h6" style={{ marginLeft: 5 }}>
                                  {vehicle && vehicle.licencePlate.toUpperCase()}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={3}>
                            <Grid container item xs={12}>
                              <Grid item>
                                <Typography variant="h6">
                                  Make:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="h6" style={{ marginLeft: 5 }}>
                                  {vehicle && vehicle.make}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={3}>
                            <Grid container item xs={12}>
                              <Grid item>
                                <Typography variant="h6">
                                  Model:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="h6" style={{ marginLeft: 5 }}>
                                  {vehicle && vehicle.model}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={3}>
                            <Grid container item xs={12}>
                              <Grid item>
                                <Typography variant="h6">
                                  Year:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="h6" style={{ marginLeft: 5 }}>
                                  {vehicle && vehicle.year}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          {
                            vehicle && (
                              <Grid container item xs={12} style={{ marginTop: 8 }}>
                                {(vehicle.fuelType === VehicleFuelType.PETROL ||
                                  vehicle.fuelType === VehicleFuelType.DIESEL ||
                                  vehicle.fuelType ===
                                  VehicleFuelType.GAS_BI_FUEL) && (
                                    <Grid item xs={3}>
                                      <Typography variant="h6">
                                        Fuel Status:{" "}
                                        {values && values.fuelStatus ? values.fuelStatus : "NA"}
                                      </Typography>
                                    </Grid>
                                  )}
                                {
                                  vehicle.fuelType === VehicleFuelType.GASOLINE && (
                                    <Grid item xs={3}>
                                      <Typography variant="h6">
                                        Fuel Status:{" "}
                                        {values && values.fuelStatus ? values.fuelStatus : "NA"}
                                      </Typography>
                                    </Grid>
                                  )
                                }
                                {(vehicle.fuelType ===
                                  VehicleFuelType.HYBRID_ELECTRIC ||
                                  vehicle.fuelType ===
                                  VehicleFuelType.HYBRID_ELECTRIC_CLEAN ||
                                  vehicle.fuelType ===
                                  VehicleFuelType.ELECTRIC_DIESEL) && (
                                    <>
                                      <Grid item xs={3}>
                                        <Typography variant="h6">
                                          Fuel Status:{" "}
                                          {values && values.fuelStatus ? values.fuelStatus : "NA"}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Typography variant="h6">
                                          Battery Status:{" "}
                                          {values && values.batteryStatus
                                            ? `${values.batteryStatus}%`
                                            : "NA"}
                                        </Typography>
                                      </Grid>
                                    </>
                                  )}
                                {(vehicle.fuelType === VehicleFuelType.ELECTRICITY ||
                                  vehicle.fuelType === VehicleFuelType.ELECTRIC) && (
                                    <Grid item xs={3}>
                                      <Typography variant="h6">
                                        Battery Status:{" "}
                                        {values && values.batteryStatus
                                          ? `${values.batteryStatus}%`
                                          : "NA"}
                                      </Typography>
                                    </Grid>
                                  )}
                              </Grid>
                            )
                          }
                          <Grid item xs={3} style={{ marginTop: 8 }}>
                            <Grid container item xs={12}>
                              <Grid item>
                                <Typography variant="h6">
                                  Odometer Reading:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="h6" style={{ marginLeft: 5 }}>
                                  {values && values.odometerReading ? values.odometerReading : "NA"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    <Grid container item xs={12} >
                      <Paper elevation={3} style={{ padding: "20px", width: "100%" }}>
                        <Grid container item xs={12} style={{ textAlign: "center" }}>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" style={{ margin: "10px 0px" }}>
                              Exterior Damages
                            </Typography>
                            {svg?.exterior.svg &&
                              (<svg xmlns="http://www.w3.org/2000/svg"
                                style={{ height: `${svgHeight}px`, marginBottom: "20px" }}
                                viewBox={`0 0 ${svg?.exterior.width} ${svg?.exterior.height}`}>
                                {svg?.exterior.svg}
                                {damageCoordinates.exterior.length > 0 && damageCoordinates.exterior}
                              </svg>)}
                          </Grid>
                          {isFourWheeler && <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" style={{ margin: "10px 0px" }}>
                              Interior Damages
                            </Typography>
                            {svg?.interior.svg && (
                              <svg xmlns="http://www.w3.org/2000/svg"
                                style={{ height: `${svgHeight}px`, marginBottom: "20px" }}
                                viewBox={`0 0 ${svg?.interior.width} ${svg?.interior.height}`}>
                                {svg?.interior.svg}
                                {damageCoordinates.interior.length > 0 && damageCoordinates.interior}
                              </svg>)}
                          </Grid>}
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={6}>
                            {exteriorDamages && exteriorDamages.length > 0 && (
                              <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>ID</TableCell>
                                      <TableCell>Reference No</TableCell>
                                      <TableCell>Type</TableCell>
                                      {/* <TableCell>Description</TableCell> */}
                                      <TableCell>Images</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {exteriorDamages.map((damageTableItem: any, index: number) => (
                                      <React.Fragment key={index}>
                                        <TableRow key={damageTableItem.id}>
                                          <TableCell component="th" scope="row">
                                            {damageTableItem.id}
                                          </TableCell>
                                          <TableCell >{damageTableItem.referenceNumber ? (
                                            <a
                                              onClick={damageTableItem.branchId === userState.currentBranch.id ? () => handlerDamageClick(damageTableItem.damageId) : undefined}
                                              title={damageTableItem.branchId !== userState.currentBranch.id ? "external branch damage is not clickable" : ""}
                                              style={{
                                                color: "rgb(24, 117, 210)",
                                                textDecoration: "none",
                                                cursor: damageTableItem.branchId === userState.currentBranch.id ? "pointer" : "not-allowed"
                                              }}> {damageTableItem.referenceNumber} </a>

                                          ) : "N/A"}</TableCell>
                                          <TableCell>{damageTableItem.damageKind || "N/A"}</TableCell>
                                          {/* <TableCell>{damageTableItem.descriptions || "N/A"}</TableCell> */}
                                          <TableCell>
                                            {damageTableItem.images && damageTableItem.images.length > 0 ? (
                                              <Grid item xs={12}>
                                                <DamgeImageView damageImages={damageTableItem.images} />
                                              </Grid>
                                            ) : "NA"}
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            {isFourWheeler && interiorDamages && interiorDamages.length > 0 && (
                              <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>ID</TableCell>
                                      <TableCell>Reference Number</TableCell>
                                      <TableCell>Type</TableCell>
                                      <TableCell>Images</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {interiorDamages.map((damageTableItem: any, index: number) => (
                                      <React.Fragment>
                                        <TableRow key={damageTableItem.id}>
                                          <TableCell component="th" scope="row">
                                            {damageTableItem.id}
                                          </TableCell>
                                          <TableCell >{damageTableItem.referenceNumber ? (<a
                                            onClick={damageTableItem.branchId === userState.currentBranch.id ? () => handlerDamageClick(damageTableItem.damageId) : undefined}
                                            title={damageTableItem.branchId !== userState.currentBranch.id ? "external branch damage is not clickable" : ""}
                                            style={{
                                              color: "rgb(24, 117, 210)",
                                              textDecoration: "none",
                                              cursor: damageTableItem.branchId === userState.currentBranch.id ? "pointer" : "not-allowed"
                                            }}> {damageTableItem.referenceNumber} </a>) : "N/A"}</TableCell>
                                          <TableCell >{damageTableItem.damageKind || "N/A"}</TableCell>
                                          <TableCell >
                                            {damageTableItem.images && damageTableItem.images.length > 0 ? (
                                              <Grid item xs={12}>
                                                <DamgeImageView damageImages={damageTableItem.images} />
                                              </Grid>
                                            ) : "NA"}
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </Grid>
                        </Grid>
                      </Paper>
                      <Grid container item sm={12}>
                        <Box mt={1}></Box>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                          General checks
                        </Typography>
                        {values && values.generalChecks && values.generalChecks.length > 0 ? (
                          <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Status</TableCell>
                                  <TableCell>Images</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  values.generalChecks.map((accessory: IVehicleStateCheck) => (
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        {accessory.name}
                                      </TableCell>
                                      <TableCell >{accessory.status ? accessory.status : "NA"}</TableCell>
                                      <TableCell >
                                        {accessory.images && accessory.images.length > 0 ? (
                                          <Grid item xs={12}>
                                            <DamgeImageView damageImages={accessory.images} title={"Vehicle General Check Images"} />
                                          </Grid>
                                        ) : "NA"}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          <Typography variant="h5" style={{ fontWeight: "normal", fontSize: 15, paddingTop: 6 }}>
                            No data available
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                          Accessories checks
                        </Typography>
                        {values && values.defaultAccesories && values.defaultAccesories.length > 0 ? (
                          <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Status</TableCell>
                                  <TableCell>Images</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  values.defaultAccesories.map((accessory: IVehicleStateCheck) => (
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        {accessory.name}
                                      </TableCell>
                                      <TableCell >{accessory.status ? accessory.status : "NA"}</TableCell>
                                      <TableCell >
                                        {accessory.images && accessory.images.length > 0 ? (
                                          <Grid item xs={12}>
                                            <DamgeImageView damageImages={accessory.images} title={"Vehicle Accessories Images"} />
                                          </Grid>
                                        ) : "NA"}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          <Typography variant="h5" style={{ fontWeight: "normal", fontSize: 15, paddingTop: 6 }}>
                            No data available
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}
