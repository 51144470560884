import { Dialog, DialogContent, DialogTitle, Grid, Theme, Typography, Fab } from '@mui/material'
import { Field, Form, Formik } from 'formik';
import { createStyles, makeStyles } from "@mui/styles";
import { TextField } from 'formik-mui';
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { IDeliveryInformation, IPartner, IPurchaseOrder } from '../../../../../reducers/Procurement/types'
import { NuvvenDivider } from '../../../ReservationManagement/Summary/Components/Divider';
import FlatPickerBar from "../../../../common/FlatPicker";
import { DateTime as d } from "luxon"
import { GET_PARTNERS_LIST } from '../../../../../graphql/Procurement/getPartnersListQuery';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { DATE_TYPE, checkUploadFileFormat, uploadFileExtensionAndContentType } from '../../../../common/utils';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { DocumentDialog } from '../../../CustomerManagement/CustomerDetails/DocumentDialog';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { captureErrorException } from '../../../../../utils/sentry';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      background: "#dee2e6"
    },
    formPaper: {
      padding: theme.spacing(3)
    },
    form: {
      flexGrow: 1
    }
  })
);

export const deliveryInfoInitials: IDeliveryInformation = {
  deliveryNoteDate: "",
  deliveryNoteId: "",
  documents: [],
  partnerId: "",
  productReceivedDate: "",
  purchaseOrderId: "",
  receivedProductNumber: 0
}

interface IProps {
  deliveryInfo?: IDeliveryInformation;
  open: boolean;
  onClose(): void;
  handelFormSubmit(deliveryInfo: IDeliveryInformation): void;
  purchaseOrders: IPurchaseOrder[];
  procurementExpectedDate: string;
}


export const NewDeliveryInfoModel: React.FC<IProps> = (props) => {

  const { handelFormSubmit, procurementExpectedDate } = props
  const classes = useStyles();
  const snackbar = useSnackBar();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [values, setValues] = useState<IDeliveryInformation>(deliveryInfoInitials);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [partner, setPartner] = useState<IPartner>()
  const [purchaseOrders, setPurchaseOrders] = useState<IPurchaseOrder[]>(props.purchaseOrders)
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);

  const [
    loadPartners,
    { data: partnersList }
  ] = useLazyQuery(GET_PARTNERS_LIST, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (userReducer.tenancy) {
      loadPartners()
    }
  }, [userReducer.tenancy])

  useEffect(() => {
    if (props.deliveryInfo && props.deliveryInfo.id) {
      setIsUpdate(true)
      setValues(props.deliveryInfo)
    }
  }, [props.deliveryInfo])

  useEffect(() => {
    if (props.purchaseOrders) {
      setPurchaseOrders(props.purchaseOrders)
    }
  }, [props.purchaseOrders])

  useEffect(() => {
    if (partnersList && partnersList.partners) {
      setPartners(partnersList.partners)
      const partner = partnersList.partners.find((partner: IPartner) => {
        return partner.id === values.partnerId
      })
      setPartner(partner)
    }
  }, [partnersList])

  async function uploadDocument(
    file: File,
  ) {
    try {
      if (!userReducer.tenancy?.id) {
        return;
      }
      // 5MB limit exceeded
      if (file.size > 5000000) {
        throw new Error("File size exceeded limit of 5MB");
      }
      setUploadInProgress(true);
      if (checkUploadFileFormat(file.type)) {
        const {
          fileExtension,
          contentType
        } = uploadFileExtensionAndContentType(file.type);
        const uniqueId = uuidv4();
        const key = `${userReducer.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                const updatedValues = {
                  ...values, documents: [...values.documents, key]
                }
                setValues(updatedValues)
                snackbar({
                  message: "Document Added",
                  variant: SnackBarVariant.SUCCESS
                });
                setUploadInProgress(false);
                setDocumentDialogVisible(false);
              }
            });
        }
      } else {
        setDocumentDialogVisible(false);
        setUploadInProgress(false);
        return snackbar({
          message: "Please only upload .pdf!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  const procurementValidationSchema = Yup.object().shape({
    deliveryNoteId: Yup.string().required("Delivery Note ID is a required field"),
    deliveryNoteDate: Yup.string().required("Delivery not date is required."),
    productReceivedDate: Yup.string().required("Product received date is required."),
  });

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose()
        }}
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container item xs={12}>
            <Typography variant="h2">{isUpdate ? "Update Status" : "New Status"}</Typography>
          </Grid>
          <NuvvenDivider noMargin />
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} mt={1}>
            <Formik
              enableReinitialize
              validationSchema={procurementValidationSchema}
              initialValues={values}
              onSubmit={(values, { setSubmitting }) => {
                handelFormSubmit(values)
                setSubmitting(false)
              }}
            >
              {(props) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g TEST"
                        label="Delivery Note ID"
                        name={"deliveryNoteId"}
                        value={props.values.deliveryNoteId}
                        onChange={props.handleChange}
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    <Grid item xs={3}>
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          setValues({
                            ...props.values,
                            deliveryNoteDate: d.fromJSDate(value).toUTC().toISO()
                          })
                        }}
                        label={"Delivery Note Date"}
                        identifier={"deliveryNoteDate"}
                        value={getLocalizedDateFormat(country, props.values.deliveryNoteDate, DATE_TYPE.CONDENSED)}
                        minDate={getLocalizedDateFormat(country, procurementExpectedDate, DATE_TYPE.CONDENSED)}
                        required
                        country={country}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          setValues({
                            ...props.values,
                            productReceivedDate: d.fromJSDate(value).toUTC().toISO()
                          })
                        }}
                        name="productReceivedDate"
                        label={"Product Received Date"}
                        identifier={"productReceivedDate"}
                        value={getLocalizedDateFormat(country, props.values.productReceivedDate, DATE_TYPE.CONDENSED)}
                        minDate={getLocalizedDateFormat(country, props.values.deliveryNoteDate, DATE_TYPE.CONDENSED)}
                        required
                        country={country}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Fab
                        className="blackBackButton"
                        variant="extended"
                        size="medium"
                        aria-label="Update"
                        onClick={() => {
                          setDocumentDialogVisible(true);
                        }}
                      >
                        Upload Documents
                      </Fab>
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-start">
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type={"submit"}
                        disabled={props.values.deliveryNoteDate && props.values.deliveryNoteId && props.values.productReceivedDate ? false : true}
                      >
                        Save
                      </Fab>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
        <DocumentDialog
          open={documentDialogVisible}
          handleClose={() => setDocumentDialogVisible(false)}
          uploadInProgress={uploadInProgress}
          onSubmit={uploadDocument}
          accept="application/zip"
        />
      </Dialog>
    </>
  )
}
