import { gql } from "@apollo/client";

export const UPDATE_PROCUREMENT_FINANCE_INFO = gql`
  mutation updateProcurementFinanceInfo($id: ID!, $financeInformationId: ID!, $financeInformation: ProcurementFinanceInfoInput!) {
    updateProcurementFinanceInfo(id: $id, financeInformationId: $financeInformationId, financeInformation: $financeInformation) {
      financeInformation {
        id
        purchaseOrderId
        purchaseOrderDate
        partnerId
        invoiceId
        invoiceDate
        totalAmount
        expectedArrivalDate
        advancedPayment
        paymentSource
        partner {
          id
          partnerName
          email
        }
        documents
      }
      deliveryInfo {
        id
        deliveryNoteId
        deliveryNoteDate
        partnerId
        purchaseOrderId
        productReceivedDate
        receivedProductNumber
        documents
        status
        partner {
          id 
          partnerName
          email
        }
      }
    }
  }
`;