
import { Avatar, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Fab, Grid, IconButton, Paper, Slide, TextField, Tooltip, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import React, { useEffect, useState } from 'react'
import { IBooking, IGenericSwapInput, INotesInput, ISwapRequest, ISwapRequestEvent, SwapRequestStatus } from '../../../../../reducers/bookings/types';
import { TransitionProps } from '@mui/material/transitions/transition';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import { DateTime as d } from 'luxon';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  open: boolean;
  onClose: () => void;
  onAddNote: (notesInput: INotesInput) => void;
  swapRequest?: ISwapRequest;
  customerName: string;
  onApproveSwapRequest: (approveRequestInput: IGenericSwapInput) => void;
  onDeclineSwapRequest: (approveRequestInput: IGenericSwapInput) => void;
  onCompleteSwapRequest: (approveRequestInput: IGenericSwapInput) => void;
  booking: IBooking;
  loadingAddNotes: boolean;
  loadingApproveRequest: boolean;
  loadingDeclineRequest: boolean;
  loadingCompleteRequest: boolean;
}

export const WhiteCircularProgress = withStyles(() =>
  createStyles({
    root: {
      color: '#fff !important',
      width: 20,
      height: 20
    },
  }),
)(CircularProgress);

const notesInitial: INotesInput = {
  bookingId: "",
  notes: "",
  swapRequestId: ""
}
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    iconStyle: {
      padding: 2
    },
    avatarStyle: {
      background: "var(--theme-accent)",
      height: 25,
      width: 25
    },
    paperStyle: {
      flexGrow: 1
    },
    transparentInput: {
      border: 0,
      outline: "none",
      cursor: "pointer",
      width: 136,
      opacity: 0
    },
    swapNoteBox: {
      background: "#ececec",
      padding: "10px",
      borderTopRightRadius: "15px",
      borderRottomLeftRadius: "15px"
    },
    buttonRoot: {

    }
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const UpdateSwapRequest: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState<INotesInput>(notesInitial)
  const [events, setEvents] = useState<ISwapRequestEvent[]>([])

  useEffect(() => {
    if (props.swapRequest && props.swapRequest.events && props.swapRequest.events.length > 0) {
      setEvents(props.swapRequest.events)
    }
  }, [props.swapRequest])


  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
      aria-describedby="pricing-edit-fields"
      maxWidth={"md"}
      style={{ flexGrow: 1 }}
      classes={{
        paper: classes.paperStyle
      }}
    >
      <DialogTitle id="simple-dialog-title">
        <Grid container>
          <Grid item xs={4}>
            <Typography variant='h3'>Update Swap Request</Typography>
          </Grid>
          <Grid item xs={8} container justifyContent='flex-end'>
            {
              props?.swapRequest?.status === SwapRequestStatus.PENDING && (
                <>
                  <Fab
                    variant="extended"
                    size="small"
                    onClick={() => {
                      props.onDeclineSwapRequest({
                        bookingId: props.booking.id,
                        swapRequestId: props.swapRequest?.id || ""
                      })
                    }}
                    disabled={props.loadingDeclineRequest}
                  >
                    {
                      props.loadingDeclineRequest && <WhiteCircularProgress size={20} style={{ marginRight: 5 }} thickness={5} />
                    }
                    {"Decline Request"}
                  </Fab>
                </>
              )
            }
            {
              props?.swapRequest?.status === SwapRequestStatus.PENDING && (
                <>
                  <Box pl={1}></Box>
                  <Fab
                    variant="extended"
                    size="small"
                    onClick={() => {
                      props.onApproveSwapRequest({
                        bookingId: props.booking.id,
                        swapRequestId: props.swapRequest?.id || ""
                      })
                    }}
                    disabled={props.loadingApproveRequest || props?.swapRequest?.status !== SwapRequestStatus.PENDING}
                  >
                    {props.loadingApproveRequest && <WhiteCircularProgress size={20} style={{ marginRight: 5 }} thickness={5} />}
                    {"Approve Request"}
                  </Fab>
                </>
              )
            }
            {
              props?.swapRequest?.status === SwapRequestStatus.APPROVED && (
                <>
                  <Box pl={1}></Box>
                  <Fab
                    variant="extended"
                    size="small"
                    onClick={() => {
                      props.onCompleteSwapRequest({
                        bookingId: props.booking.id,
                        swapRequestId: props.swapRequest?.id || ""
                      })
                    }}
                    disabled={!d.fromISO(props.swapRequest?.dateForSwap || "").startOf("day").equals(d.now().startOf("day")) || props.loadingCompleteRequest}
                  >
                    {props.loadingCompleteRequest && <WhiteCircularProgress size={20} style={{ marginRight: 5 }} thickness={5} />}
                    {"Complete Request"}
                  </Fab>
                </>
              )
            }
            <Tooltip title={<Typography variant='h4'>Close</Typography>}>
              <IconButton
                onClick={props.onClose}
                style={{ padding: "0px 0px 0px 12px" }}
                size="large">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Paper elevation={0} className={classes.root}>
          <Formik
            enableReinitialize
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)

            }}
          >
            {(formikProps) => (
              <Form>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant='h4' >Swap Request Date: {d.fromISO(props.swapRequest?.dateForSwap || "").toFormat('dd/MM/yyyy')}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='h4' >Status: {props.swapRequest?.status}</Typography>
                  </Grid>
                  {
                    <Grid item xs={4}>
                      <Typography variant='h4' >Allocated Vehicle: {props.swapRequest?.vehicleAllocated?.licencePlate}</Typography>
                    </Grid>
                  }
                </Grid>
                {
                  props.swapRequest?.status === SwapRequestStatus.PENDING && (
                    <>
                      <Grid container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <Box mt={1}></Box>
                          <Typography variant='h4' >Add Note</Typography>
                          <Box mt={1}></Box>
                          <Field
                            component={TextField}
                            name={`note`}
                            placeholder="Write your note here"
                            fullWidth
                            required
                            rows={4}
                            multiline
                            inputProps={{
                              value: formikProps.values.notes,
                              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                setValues({
                                  ...values,
                                  notes: e.target.value
                                })
                              }
                            }}
                          ></Field>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} spacing={2} style={{ marginTop: 20, marginBottom: 30 }}>
                        <Box pl={1}></Box>
                        <Fab
                          variant="extended"
                          size="small"
                          onClick={() => {
                            if (props.swapRequest?.id) {
                              values.swapRequestId = props.swapRequest.id
                            }
                            props.onAddNote(values)
                          }}
                          disabled={!values.notes || props.loadingAddNotes}
                        >
                          {props.loadingAddNotes && <WhiteCircularProgress size={20} style={{ marginRight: 5 }} thickness={5} />}
                          {"Save Note"}
                        </Fab>
                      </Grid>
                    </>
                  )
                }
                <Grid container xs={12} style={{ marginTop: 20, marginBottom: 30 }}>
                  {
                    events && events.length > 0 &&
                    <>
                      <Typography variant='h2'>Notes</Typography>
                      {
                        [...events].sort((a: any, b: any) => b.eventTime > a.eventTime ? 1 : -1).map(event => {
                          return (
                            <>
                              {
                                event.userType === "operator" ?
                                  (
                                    <Grid item xs={12} container spacing={2} justifyContent='flex-end'>
                                      <Grid item>
                                        <Typography variant={"h3"}><Avatar><PersonSharpIcon fontSize='small' ></PersonSharpIcon></Avatar></Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography variant='h4'><span style={{ fontSize: 15 }}>You</span></Typography>
                                        <div className={classes.swapNoteBox}>
                                          <Typography variant={"h3"}><span style={{ fontSize: 14 }}>{event.notes}</span></Typography>
                                          <Typography variant={"caption"}>{d.fromISO(event.eventTime).toFormat("dd/MM/yyyy, hh:mm a")}</Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  ) :
                                  (
                                    <Grid item xs={12} container spacing={2}>
                                      <Grid item>
                                        <Typography variant={"h3"}><Avatar><PersonSharpIcon fontSize='small' ></PersonSharpIcon></Avatar></Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography variant='h4'><span style={{ fontSize: 15 }}>{props.customerName}</span></Typography>
                                        <div className={classes.swapNoteBox}>
                                          <Typography variant={"h3"}><span style={{ fontSize: 14 }}>{event.notes}</span></Typography>
                                          <Typography variant={"caption"}>{d.fromISO(event.eventTime).toFormat("dd/MM/yyyy, hh:mm a")}</Typography>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  )
                              }
                            </>
                          )
                        })
                      }
                    </>
                  }
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateSwapRequest
