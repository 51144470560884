import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, TextField, Typography } from '@mui/material';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { FC } from 'react'
import { IServiceTaskInput, TaskType } from './ServiceTaskItems';
import * as Yup from "yup";

interface IDialogProps {
  open: boolean;
  data: IServiceTaskInput;
  handleClose: () => void;
  handleSubmit: (serviceTask: IServiceTaskInput) => void;
}

export const AddServiceTaskItemDialog: FC<IDialogProps> = ({ open, data, handleClose, handleSubmit }) => {

  const validationSchema = Yup.object().shape({
    name: Yup.string().required()
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {dialogFormprops => (
          <Form>
            <DialogTitle>
              <Typography variant="h4" style={{ fontWeight: 700, fontSize: 16 }}>
                {data.id ? "Update" : "Create"} Service Task List</Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2} style={{ width: 400 }}>
                <Grid item xs={12}>
                  <Field
                    name="name"
                  >
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <TextField
                        {...field}
                        label="Name"
                        placeholder="Enter name"
                        fullWidth
                        disabled={data.taskType === TaskType.DEFAULT ? true : false}
                        error={touched && Boolean(error)}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="description"
                  >
                    {({ field, meta: { touched, error }, }: FieldProps) => (
                      <TextField
                        {...field}
                        label="Description"
                        placeholder="Add description"
                        fullWidth
                        error={touched && Boolean(error)}
                        multiline
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>

            </DialogContent>
            <DialogActions>
              <div style={{ display: 'flex', flex: 1 }}>
              </div>
              <Fab
                className="blackBackButton"
                variant="extended"
                size="small"
                onClick={handleClose}
              >
                Cancel
              </Fab>
              <Fab
                variant="extended"
                size="small"
                type="submit"
                disabled={dialogFormprops.isSubmitting}
              >
                {dialogFormprops.isSubmitting && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                <strong>{data && data.id ? "Update" : "create"}</strong>
              </Fab>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}