import { useMutation } from "@apollo/client";
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage, toCurrency } from "../../../../common/utils";
import { ICreditNote, IRefund } from "../../../../../reducers/bookings/types";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { RefundDialog } from "../../../ReservationManagement/Summary/Components/RefundDialog";
import { UPDATE_REFUND } from "../../../../../graphql/bookings/updateRefundMutation";
import { defaultConfirmationDialogData, IConfirmationDialogData } from "../../../ReservationManagement/Summary/const";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    content: {
      marginTop: "50px"
    },
    tableGrid: {
      marginTop: "25px"
    },
    addButtonPayment: {
      backgroundColor: "#faaf40",
      color: "#fff",
      border: "none",
      width: "124px",
      height: "40px",
      fontSize: "14px",
      cursor: "pointer"
    },
    addButtonPaymentDisabled: {
      backgroundColor: "#fafafa",
      color: "#fff",
      border: "none",
      width: "124px",
      height: "40px",
      fontSize: "14px",
      cursor: "pointer"
    },
    linkText: {
      color: "rgb(24, 117, 210)",
      textDecoration: "none",
      cursor: "pointer"
    }
  })
);

interface IProps {
  creditNotes: ICreditNote[];
  updateCreditNote: (creditNote: ICreditNote) => void;
}

const CreditNotesComponent: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [creditNotes, setCreditNotes] = useState<ICreditNote[]>([]);
  const [refundDialogOpen, setRefundDialogOpen] = useState<boolean>(false);
  const [selecteCreditNote, setSelectedCreditNote] = useState<ICreditNote>();
  const [refundLoading, setRefundLoading] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const organisation = userState.currentOrganisation;
  const { locale, currency } = organisation;
  const { country } = userState.currentOrganisation.address;
  const [confirmationDialogData, setConfirmationDialogData] = useState<
    IConfirmationDialogData
  >(defaultConfirmationDialogData);

  useEffect(() => {
    if (props.creditNotes.length) {
      setCreditNotes(props.creditNotes)
    }
  }, [props.creditNotes]);

  const [updateRefundMutation] = useMutation(UPDATE_REFUND, {
    onCompleted: (data) => {
      if (data && data.updateRefund) {
        snackbar({
          message: "Refund Updated Successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setSelectedCreditNote(data.updateRefund);
        setRefundLoading(false);
        props.updateCreditNote(data.updateRefund)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  })

  const updateRefund = (refund: IRefund, creditNote: ICreditNote) => {
    if (refund) {
      setRefundLoading(true);
      updateRefundMutation({
        variables: {
          creditNoteId: creditNote.id,
          refundId: refund.id,
          status: refund.status
        }
      })
    }
  }

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Typography variant="h2">Credit Notes</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Box pt={2}></Box>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: 0 }}>
        <Grid container item xs={12}>
          <TableContainer>
            <Table aria-label="spanning table">
              <TableHead>
                <TableCell
                  align="left"
                >
                  <Typography>Refund Ref</Typography>
                </TableCell>
                <TableCell
                  align="left"
                >
                  <Typography>Date Created</Typography>
                </TableCell>
                <TableCell
                  align="left"
                >
                  <Typography>Amount</Typography>
                </TableCell>
              </TableHead>
              <TableBody>
                {creditNotes.map((creditNote: ICreditNote) => {
                  return <TableRow>
                    <TableCell>
                      <Typography
                        className={classes.linkText}
                        onClick={() => {
                          navigate(`/credit-note?creditNoteId=${creditNote.id}`)
                          setSelectedCreditNote(creditNote);
                          setRefundDialogOpen(true);
                        }}
                        style={{ cursor: "pointer", color: "#1875d2" }}
                      >
                        {creditNote.creditNoteRef}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {getLocalizedDateFormat(country, creditNote.createdAt, DATE_TYPE.CONDENSED)}
                    </TableCell>
                    <TableCell>
                      {toCurrency(creditNote.totalAmount, currency, locale)}
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ConfirmationDialog {...confirmationDialogData} />
      {
        selecteCreditNote &&
        <RefundDialog
          isOpen={refundDialogOpen}
          refundLoading={refundLoading}
          onCancel={() => setRefundDialogOpen(false)}
          creditNote={selecteCreditNote}
          updateRefund={updateRefund}
        />
      }
    </Grid>
  );
}

export default CreditNotesComponent;
