import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GET_INVOICE } from "../../../../graphql/invoices/getInvoiceQuery";
import { UPDATE_SUNDRY_INVOICE } from "../../../../graphql/invoices/updateSundryINvoice";
import { IInvoice } from "../../../../reducers/invoices/types";
import { FloatInput } from "../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../../common/utils";
import { SummaryField } from "../UpdateInvoice/InvoiceComponents/SummaryField";
import { IInvoiceItemCharge, IInvoiceItemType } from "../utils";
import { getLocalizedDateFormat, getLocalizedTaxSyntex } from "../../../../utils/localized.syntex";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3)
    },
    tableContainer: {
      border: "solid 1px rgba(224, 224, 224, 1)",
      marginTop: "1rem"
    }
  })
);

const UpdateSundryInvoice: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [invoice, setInvoice] = useState<IInvoice>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isBusinessCustomerBooking, setIsBusinessCustomerBooking] = useState<boolean>(false);
  const [charges, setCharges] = useState<IInvoiceItemCharge[]>([])
  const [
    loadInvoice,
    { loading: invoiceLoading, data: invoiceData }
  ] = useLazyQuery(GET_INVOICE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.invoice) {
        navigate(-1);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [updateSundryInvoice] = useMutation(UPDATE_SUNDRY_INVOICE, {
    onCompleted: (data) => {
      if (data && data.updateSundryInvoice && data.updateSundryInvoice.id) {
        setLoading(false);
        snackbar({
          message: "Sundry Invoice updated successfully",
          variant: SnackBarVariant.SUCCESS
        });
        navigate(`/update-billing?invoice=${data.updateSundryInvoice.id}`);
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  })

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const invoiceId = params.get("invoice");
      if (invoiceId) {
        loadInvoice({
          variables: {
            id: invoiceId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (invoiceData && invoiceData.invoice) {
      setInvoice(invoiceData.invoice);
      const formattedInvoiceItems = invoiceData.invoice.invoiceItems.map((item: any) => {
        return {
          ...item,
          taxRate: item.taxRate > 100 ? item.taxRate / 100 : item.taxRate,
          totalAmount: item.totalAmount + item.taxAmount
        }
      })
      setCharges(formattedInvoiceItems);
      const isBusinessCustomerBooking =
        invoiceData.invoice.businessCustomer &&
          invoiceData.invoice.businessCustomer.id !== ""
          ? true
          : false;
      setIsBusinessCustomerBooking(isBusinessCustomerBooking);
    }
  }, [invoiceData]);

  const addRows = () => {
    const rowsArr = [...charges];
    rowsArr.push({
      type: IInvoiceItemType.OTHER_CHARGE,
      description: "",
      unitPrice: 0,
      quantity: 1,
      totalAmount: 0,
      taxRate: 0,
      taxAmount: 0
    });
    setCharges(rowsArr);
  };

  const handleSubmit = () => {
    if (charges.some(charge => !charge.description)) {
      return snackbar({
        message: "Invoice item description cannot be empty!",
        variant: SnackBarVariant.ERROR
      });
    }
    if (charges.some(charge => !charge.unitPrice)) {
      return snackbar({
        message: "Invoice item price/rate should be greater than 0",
        variant: SnackBarVariant.ERROR
      });
    }
    const chargesArr: IInvoiceItemCharge[] = [];
    charges.forEach((charge: any) => {
      delete charge.isProrated
      delete charge.proratedBillingDays
      delete charge.taxDescription
      charge.totalAmount = charge.totalAmount - charge.taxAmount;
      chargesArr.push(charge)
    })
    if (invoice) {
      setLoading(true)
      updateSundryInvoice({
        variables: {
          invoiceId: invoice.id,
          invoiceItems: chargesArr
        }
      })
    }
  };

  if (invoiceLoading || !invoice) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12}>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h1" color="primary">
            Invoice
          </Typography>
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon />
          <Box color="white" sx={{ pl: 1 }}></Box>
          <Typography variant="h1" color="primary">
            Update Sundry Invoice
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Paper className={classes.root}>
          <Grid container xs={12} item spacing={2}>
            <Grid item xs={4}>
              <SummaryField
                label="Booking Reference"
                value={invoice.booking.referenceNumber}
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryField
                label="Customer Name"
                value={
                  isBusinessCustomerBooking
                    ? invoice.businessCustomer
                      ? invoice.businessCustomer.businessName
                      : ""
                    : invoice.customer
                      ? invoice.customer.firstName + " " + invoice.customer.lastName
                      : ""
                }
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryField
                label="Sundry Invoice Reference"
                value={invoice.invoiceRef}
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryField
                label="Booking Start Date"
                value={getLocalizedDateFormat(country, invoice.booking.pickupDateTime, DATE_TYPE.CONDENSED)}
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryField
                label="Customer Email"
                value={
                  isBusinessCustomerBooking
                    ? invoice.businessCustomer
                      ? invoice.businessCustomer.billing.email
                      : ""
                    : invoice.customer
                      ? invoice.customer.email
                      : ""
                }
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryField
                label="Sundry Invoice Date"
                value={getLocalizedDateFormat(country, invoice.dateCreated, DATE_TYPE.EXPANDED)}
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryField
                label="Booking End Date"
                value={getLocalizedDateFormat(country, invoice.booking.dropoffDateTime || "", DATE_TYPE.CONDENSED)}
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryField
                label="Customer Phone Number"
                value={
                  isBusinessCustomerBooking
                    ? invoice.businessCustomer
                      ? invoice.businessCustomer.billing.phoneNumber.phone
                      : ""
                    : invoice.customer
                      ? invoice.customer.phoneNumber.phone
                      : ""
                }
              />
            </Grid>
            <Grid item xs={4}>
              <SummaryField
                label="Sundry Invoice Status"
                value={invoice.status === "PAID" ?
                  "PAID" :
                  "UNPAID"
                }
                color={invoice.status === "PAID" ? "#00bcb5" : "#c54740"}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid xs={12} container spacing={2} >
                <Grid item xs={11}>
                  <Typography variant="subtitle1">
                    Invoice Items
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant={"subtitle1"}
                    onClick={addRows}
                    style={{ cursor: "pointer" }}
                  >
                    + Add New
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Formik
                initialValues={invoice}
                onSubmit={(values, { setSubmitting }) => {
                  handleSubmit();
                  setSubmitting(false);
                }}
              >
                {(formikProps) => (
                  <Form style={{ width: "100%" }}>
                    <Grid item container xs={12} spacing={2}>
                      {charges.map((row: IInvoiceItemCharge, idx: number) => {
                        return (
                          <React.Fragment key={idx}>
                            <Grid
                              item
                              container
                              xs={11}
                              spacing={2}
                              alignItems="baseline"
                              justifyContent="space-between"
                            >
                              <Grid
                                item
                                xs={12}
                                md={3}
                                lg={3}
                              >
                                <TextField
                                  placeholder="Charge Description"
                                  label="Charge Description"
                                  name={"description"}
                                  inputProps={{
                                    value: row.description,
                                    onChange: (
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const item = charges[idx];
                                      const rowsArr = [...charges];
                                      item.description = e.target.value;
                                      rowsArr.splice(idx, 1, item);
                                      setCharges(rowsArr);
                                    }
                                  }}
                                  fullWidth
                                ></TextField>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                lg={3}
                              >
                                <TextField
                                  placeholder="Unit"
                                  label="Unit"
                                  name={"unit"}
                                  type="number"
                                  inputProps={{
                                    value: row.quantity
                                  }}
                                  fullWidth
                                  disabled
                                ></TextField>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                lg={3}
                              >
                                <Field
                                  component={InputField}
                                  fullWidth
                                  placeholder="Price/Rate"
                                  label="Price/Rate"
                                  name={"unitPrice"}
                                  InputProps={{
                                    value: row.unitPrice,
                                    inputComponent: FloatInput as any,
                                    onChange: (
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const val = e.target.value || "0";
                                      const item: IInvoiceItemCharge = charges[idx];
                                      const rowsArr = [...charges];
                                      item.unitPrice = parseInt(val);
                                      if (!item.unitPrice) {
                                        item.taxAmount = 0;
                                        item.totalAmount = 0;
                                      }
                                      item.taxAmount = Math.round(((item.unitPrice * item.quantity) * item.taxRate) / 100);
                                      item.totalAmount = (item.unitPrice * item.quantity) + item.taxAmount;
                                      rowsArr.splice(idx, 1, item);
                                      setCharges(rowsArr);
                                    }
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                ></Field>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                lg={3}
                              >
                                <Field
                                  component={InputField}
                                  placeholder={`${getLocalizedTaxSyntex(country)} (%)`}
                                  label={`${getLocalizedTaxSyntex(country)} (%)`}
                                  name={"taxRate"}
                                  InputProps={{
                                    value: row.taxRate > 100 ? row.taxRate : row.taxRate * 100,
                                    inputComponent: FloatInput as any,
                                    onChange: (
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const val = parseFloat(e.target.value) ? parseFloat(e.target.value) : 0;
                                      if (val > 10000) {
                                        snackbar({
                                          message:
                                            "Tax value can't be more than 100%",
                                          variant: SnackBarVariant.ERROR
                                        });
                                      }
                                      else if (val < 0) {
                                        snackbar({
                                          message:
                                            "Tax value can't be less than 0%",
                                          variant: SnackBarVariant.ERROR
                                        });
                                      }
                                      else {
                                        const item: IInvoiceItemCharge = charges[idx];
                                        const rowsArr = [...charges];
                                        item.taxRate = val / 100;
                                        item.taxAmount = Math.round(((item.unitPrice * item.quantity) * item.taxRate) / 100);
                                        item.totalAmount = (item.unitPrice * item.quantity) + item.taxAmount;
                                        rowsArr.splice(idx, 1, item);
                                        setCharges(rowsArr);
                                      }
                                    }
                                  }}
                                  inputProps={{
                                    allowNegative: false
                                  }}
                                  fullWidth
                                ></Field>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                lg={3}
                              >
                                <TextField
                                  placeholder="Amount"
                                  label="Amount"
                                  name={"totalAmount"}
                                  disabled={true}
                                  InputProps={{
                                    value: (row.unitPrice * row.quantity) + row.taxAmount,
                                    inputComponent: FloatInput as any
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                  fullWidth
                                ></TextField>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={1}
                                style={{ marginTop: "7px", padding: "10px" }}
                              >
                                <Typography
                                  variant={"subtitle1"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const rowsArr = [...charges];
                                    rowsArr.splice(idx, 1);
                                    setCharges(rowsArr);
                                  }}
                                >
                                  X
                                </Typography>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                      {charges.length > 0 && (
                        <Grid item container xs={12} style={{ marginTop: "20px" }} justifyContent="flex-end">
                          <Fab
                            className="blackBackButton"
                            style={{ marginRight: "20px" }}
                            variant="extended"
                            disabled={!charges.length}
                            size="medium"
                            onClick={() => navigate(-1)}
                          >
                            {"Cancel"}
                          </Fab>
                          <Fab
                            variant="extended"
                            disabled={!charges.length}
                            size="medium"
                            type="submit"
                          >
                            {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                            {"Save"}
                          </Fab>
                        </Grid>
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UpdateSundryInvoice;