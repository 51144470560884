import { gql } from "@apollo/client";

export const GET_LOCATIONS = gql`
  query locations($organisationId: ID!) {
    locations(organisationId: $organisationId) {
      id
      name
      branch {
        id
        name
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      addSurchargesOptIn
      isDisabled
      surcharges {
        id
        title
        rate
        type
        value
        taxable
        tax {
          title
          value
        }
      }
    }
  }
`;
