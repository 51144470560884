import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styles from './index.module.css';
import { ChartsModule } from './vehicle/ChartsModule';
import BookingReports from "./booking/BookingDashboard"
import CssBaseline from '@mui/material/CssBaseline';
import FinanceReports from './finances/FinanceReports';
import VehicleTrips from './Trips';
import Alerts from './Alerts';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import CrashReport from './CrashReport';
import BankingReport from './finances/BankingReport';
import { EmissionModule } from './Emission';
import { getLocalizedBookingSyntex } from '../../../utils/localized.syntex';
import { UserRoles } from '../../hoc/Authorization';
import { useLazyQuery } from '@apollo/client';
import { GET_VEHICLE_GROUPS_TRACKING } from '../../../graphql/fleet/getVehicleGroupTrackingQuery';
import { IVehicle } from '../../../reducers/fleet/types';
import { IVehicleGroupData } from './Trips/VehicleSelection';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';
import { ApolloError } from '@apollo/client';
import { formatGraphQLErrorMessage } from '../../common/utils';

const StyledTabs = withStyles({
  root: {
    backgroundColor: 'transparent',
  },
  indicator: {
    height: 3,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: 'var(--theme-primary)',
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    textTransform: 'none',
    minWidth: '80px !important',
    color: '#333',
    '&:hover': {
      color: 'var(--theme-primary)',
      opacity: 1,
    },
    '&$selected': {
      color: 'var(--theme-primary)',
    },
  },
  selected: {},
})(Tab);

const Reports = () => {
  const snackbar = useSnackBar();
  const [tab, setTab] = React.useState<number>(0);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [refreshFlag, setRefreshFlag] = useState<boolean>(false);
  const [vehicleGroups, setVehicleGroups] = useState<IVehicleGroupData[]>([]);
  const [vehicles, setVehicles] = useState<IVehicle[]>([])


  const [loadVehicleGroups,
    { loading: loadingVehicleGroups, data: vehicleGroupsData }
  ] = useLazyQuery(
    GET_VEHICLE_GROUPS_TRACKING, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  useEffect(() => {
    if (userState.tenancy) {
      loadVehicleGroups();
    }
  }, [userState]);

  useEffect(() => {
    if (vehicleGroupsData && vehicleGroupsData.vehicleGroups) {
      const sortedGroups: IVehicleGroupData[] = [...vehicleGroupsData.vehicleGroups].sort((firstGroup: IVehicleGroupData, secondGroup: IVehicleGroupData) => {
        return firstGroup.name.toLowerCase().localeCompare(secondGroup.name.toLowerCase());
      });
      let _vehicleGroups: IVehicleGroupData[] = [];
      let _vehicles: IVehicle[] = [];
      sortedGroups.map((vg) => {
        let vgPushed: boolean = false
        vg.vehicles.map((vehicle) => {
          if (vehicle.telematicCoreData) {
            _vehicles.push(vehicle)
            if (!vgPushed) {
              _vehicleGroups.push({
                ...vg,
                vehicles: [vehicle]
              });
              vgPushed = true;
            }
            else {
              _vehicleGroups[_vehicleGroups.length - 1].vehicles.push(vehicle)
            }
          }
        })
      })
      setVehicles(_vehicles)
      setVehicleGroups(_vehicleGroups);
    }
  }, [vehicleGroupsData]);

  const handleChange = (event: any, newValue: any) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (!refreshFlag) {
      setRefreshFlag(true)
    }
  }, [refreshFlag])

  useEffect(() => {
    if (refreshFlag) {
      setRefreshFlag(false)
    }
  }, [userState])

  if (!refreshFlag) {
    return <div></div>
  }

  return (
    <>
      {userState.role === UserRoles.FINANCE_MANAGER ?
        <>
          <div className={styles.tabsWrap}>
            <CssBaseline />
            <h2>Reports</h2>
            <div>
              <StyledTabs value={tab} onChange={handleChange} aria-label="ant example">
                <StyledTab disableRipple label="Finances" />
                <StyledTab disableRipple label="Vehicles" />
                <StyledTab disableRipple label="EOD Banking" />
              </StyledTabs>
            </div>
          </div>
          <div className={styles.modulesWrap}>
            <FinanceReports active={tab === 0} />
          </div>
          <div className={styles.modulesWrap}>
            <ChartsModule active={tab === 1} />
          </div>
          <div className={styles.modulesWrap}>
            <BankingReport active={tab === 2} />
          </div>

        </> :
        <>{userState.role === UserRoles.FLEET_MANAGER ?
          <>
            <div className={styles.tabsWrap}>
              <CssBaseline />
              <h2>Reports</h2>
              <div>
                <StyledTabs value={tab} onChange={handleChange} aria-label="ant example">
                  <StyledTab disableRipple label="Vehicles" />
                  <StyledTab disableRipple label="Finances" />
                  <StyledTab disableRipple label="Tracking" />
                  <StyledTab disableRipple label="Alerts" />
                  <StyledTab disableRipple label="Crash Report" />
                  <StyledTab disableRipple label="Co2 Emission" />
                </StyledTabs>
              </div>
            </div>
            <div className={styles.modulesWrap}>
              <ChartsModule active={tab === 0} />
            </div>
            <div className={styles.modulesWrap}>
              <FinanceReports active={tab === 1} />
            </div>
            <div className={styles.modulesWrap}>
              {tab === 2 && <VehicleTrips
                active={tab === 2}
                loadingVehicleGroups={loadingVehicleGroups}
                vehicles={vehicles}
                vehicleGroups={vehicleGroups}
              />}
            </div>
            <div className={styles.modulesWrap}>
              {tab === 3 && <Alerts
                active={tab === 3}
                loadingVehicleGroups={loadingVehicleGroups}
                vehicles={vehicles}
                vehicleGroups={vehicleGroups}
              />}
            </div>
            <div className={styles.modulesWrap}>
              {tab === 4 && <CrashReport
                active={tab === 4}
                loadingVehicleGroups={loadingVehicleGroups}
                vehicles={vehicles}
                vehicleGroups={vehicleGroups}
              />}
            </div>
            <div className={styles.modulesWrap}>
              {tab === 5 && <EmissionModule active={tab === 5} />}
            </div>
          </>
          :
          <>
            {userState.role === UserRoles.BOOKING_AGENT ?
              <>
                <div className={styles.tabsWrap}>
                  <CssBaseline />
                  <h2>Reports</h2>
                  <div>
                    <StyledTabs value={tab} onChange={handleChange} aria-label="ant example">
                      <StyledTab disableRipple label={`${getLocalizedBookingSyntex(country)}s`} />
                    </StyledTabs>
                  </div>
                </div>
                <div className={styles.modulesWrap}>
                  <BookingReports active={tab === 0} />
                </div>
              </>
              :
              <>
                <div className={styles.tabsWrap}>
                  <CssBaseline />
                  <h2>Reports</h2>
                  <div>
                    <StyledTabs value={tab} onChange={handleChange} aria-label="ant example">
                      <StyledTab disableRipple label="Vehicles" />
                      <StyledTab disableRipple label={`${getLocalizedBookingSyntex(country)}s`} />
                      <StyledTab disableRipple label="Tracking" />
                      <StyledTab disableRipple label="Alerts" />
                      <StyledTab disableRipple label="Crash Report" />
                      <StyledTab disableRipple label="Finances" />
                      <StyledTab disableRipple label="EOD Banking" />
                      <StyledTab disableRipple label="Co2 Emission" />
                    </StyledTabs>
                  </div>
                </div>
                <div className={styles.modulesWrap}>
                </div>
                <div className={styles.modulesWrap}>
                  {tab === 0 && <ChartsModule active={tab === 0} />}
                </div>
                <div className={styles.modulesWrap}>
                  {tab === 1 && <BookingReports active={tab === 1} />}
                </div>
                <div className={styles.modulesWrap}>
                  {tab === 2 && <VehicleTrips
                    active={tab === 2}
                    loadingVehicleGroups={loadingVehicleGroups}
                    vehicles={vehicles}
                    vehicleGroups={vehicleGroups}
                  />}
                </div>
                <div className={styles.modulesWrap}>
                  {tab === 3 && <Alerts
                    active={tab === 3}
                    loadingVehicleGroups={loadingVehicleGroups}
                    vehicles={vehicles}
                    vehicleGroups={vehicleGroups}
                  />}
                </div>
                <div className={styles.modulesWrap}>
                  {tab === 4 && <CrashReport
                    active={tab === 4}
                    loadingVehicleGroups={loadingVehicleGroups}
                    vehicles={vehicles}
                    vehicleGroups={vehicleGroups}
                  />}
                </div>
                <div className={styles.modulesWrap}>
                  {tab === 5 && <FinanceReports active={tab === 5} />}
                </div>
                <div className={styles.modulesWrap}>
                  {tab === 6 && <BankingReport active={tab === 6} />}
                </div>
                <div className={styles.modulesWrap}>
                  {tab === 7 && <EmissionModule active={tab === 7} />}
                </div>
              </>
            }
          </>
        }</>
      }
    </>
  );
}

export default Reports

