import { Grid, Typography } from '@mui/material'
import React from 'react'

export const ExtendMultipleEventError: React.FC = () => {
  return (
    <Grid container item xs={12} style={{ marginBottom: 20 }}>
      <Typography variant="h4">
        You can only extend single vehicle Bookings.
      </Typography>
    </Grid>
  )
}
