import { useLazyQuery } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography,
} from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  clearAddonType
} from "../../../../../actions/addonTypes/actions";
import { addTableState } from "../../../../../actions/tableState/actions";
import { GET_ADDON_TYPES } from "../../../../../graphql/addOns/getAddOnsQuery";
import {
  AddonTypes,
  DefaultAddons,
  IAddonType
} from "../../../../../reducers/addonType/types";
import { ITable, TableNames } from "../../../../../reducers/tableState/types";
import { IAppState } from "../../../../../store";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import {
  formatGraphQLErrorMessage,
  returnSortedOrder,
  setTableSortOrder,
  toCurrency
} from "../../../../common/utils";
import { columns, getRateType } from "./utils";
import { SelectableRows } from "../../../../common/NuvvenTable/types";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";

const AddonsTable: React.FC = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const snackbar = useSnackBar();

  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;

  const [rows, setRows] = useState([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [addOnsTable, setAddOnsTable] = useState<ITable>();

  const [getAddons, { loading, data }] = useLazyQuery(GET_ADDON_TYPES, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    getAddons();
  }, [userState]);

  const addOnsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.addOns
  );

  useEffect(() => {
    if (addOnsTableState) {
      setAddOnsTable(addOnsTableState);
      let sortedOrder = returnSortedOrder(addOnsTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, addOnsTableState, sortedOrder)
      }
    }
  }, [addOnsTableState]);

  useEffect(() => {
    dispatch(clearAddonType());
  }, []);

  useEffect(() => {
    if (data && data.addonTypes) {
      setRows(
        data.addonTypes.map((addonType: IAddonType) =>
          reshapeAddonsIntoRows(addonType)
        )
      );
    }
  }, [data]);
  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row = data.addonTypes[rowMeta.dataIndex]
      const addonId = row.id
      if (addonId) {
        navigate(`/update-addon?addon=${addonId}`)
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      dispatch(
        addTableState({
          tableName: TableNames.ADDONS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      addOnsTable && addOnsTable.rowsPerPage ? addOnsTable.rowsPerPage : 10,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.ADDONS,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: (action: string, tableState: any) => {
      if (addOnsTable && sortOrder) {
        setTableSortOrder(columns, addOnsTable, sortOrder);
      }
    }
  };

  function reshapeAddonsIntoRows(addonType: IAddonType) {
    return {
      name: addonType.displayName || addonType.name.toUpperCase(),
      fixedRate:
        addonType.hasFixedRate && addonType.name !== DefaultAddons.VALET
          ? addonType.fixedRate
            ? toCurrency(addonType.fixedRate, currency, locale)
            : toCurrency(0, currency, locale)
          : "-",
      hourly: addonType.category === AddonTypes.SERVICE ? "-" :
        !addonType.hasFixedRate
          ? toCurrency(getRateType("hourly", addonType), currency, locale)
          : "-",
      daily: addonType.category === AddonTypes.SERVICE ? "-" :
        !addonType.hasFixedRate
          ? toCurrency(getRateType("daily", addonType), currency, locale)
          : "-",
      weekly: addonType.category === AddonTypes.SERVICE ? "-" :
        !addonType.hasFixedRate
          ? toCurrency(getRateType("weekly", addonType), currency, locale)
          : "-",
      monthly: addonType.category === AddonTypes.SERVICE ? "-" :
        !addonType.hasFixedRate
          ? toCurrency(getRateType("monthly", addonType), currency, locale)
          : "-",
      totalQuantity:
        addonType.category === AddonTypes.SERVICE ? "-" : addonType.totalQuantity,
      availableQuantity:
        addonType.availableQuantity !== null ? addonType.availableQuantity : "-"
    };
  }

  const disabledRoles = ["FINANCE_MANAGER", "FLEET_MANAGER"];


  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Add-ons{"  "}
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          className="createButton"
          onClick={() => {
            navigate("/new-addon");
          }}
          disabled={disabledRoles.includes(userState.role)}
        >
          Create
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <NuvvenTable
            title={""}
            rows={rows}
            columns={columns}
            setSelection={(item: any) => item}
            options={options}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AddonsTable;
