import "./index.scss";
import { SelectableRows } from "./../../../common/NuvvenTable/types";
import { useLazyQuery } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import { Display, MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  resetVehicleDamage,
} from "../../../../actions/fleet/vehicle/actions";
import { addTableState } from "../../../../actions/tableState/actions";
import { GET_VEHICLE_DAMAGES } from "../../../../graphql/damageVehicle/getDamageVehicles";
import { IVehicleDamage, VehicleDamageStatus, VehicleDamageStatusValue, getLocalizedVehicleDamageCircumstancesValue } from "../../../../reducers/damageVehicle/types";
import { ITable, TableNames } from "../../../../reducers/tableState/types";
import { IAppState } from "../../../../store";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { DATE_TYPE, formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from "../../../common/utils";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { VehicleDamageCircumstance, _formatNearestDateTime } from "./constants";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";

interface IFilter {
  key: string;
  value: string;
}

export const VehicleDamages: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [rows, setRows] = useState([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [vehicleDamagesTable, setVehicleDamagesTable] = useState<ITable>();
  const location = useLocation();
  const [filterList, setFilterList] = useState<IFilter[]>([]);
  const [filtering, setIsFiltering] = useState<boolean>(false);
  const [limit, setLimit] = useState(10);



  const [vehicleDamages, { loading, data }] = useLazyQuery(
    GET_VEHICLE_DAMAGES,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
      }
    }
  );

  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const vehicleDamagesTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.vehicleDamages
  );

  useEffect(() => {
    if (vehicleDamagesTableState) {
      setVehicleDamagesTable(vehicleDamagesTableState);
      let sortedOrder = returnSortedOrder(vehicleDamagesTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(damageTableColoumn, vehicleDamagesTableState, sortedOrder);
      }
    }
  }, [vehicleDamagesTableState])

  useEffect(() => {
    if (userState.tenancy && location) {
      const params = new URLSearchParams(location.search);
      const paramsStatus = params.get("status");
      if (paramsStatus) {
        vehicleDamages({
          variables: {
            status: paramsStatus
          }
        });
      } else {
        vehicleDamages();
      }
    }
  }, [userState, location]);

  useEffect(() => {
    if (
      data &&
      data.vehicleDamages
    ) {
      if (data && data.vehicleDamages) {
        setRows(data.vehicleDamages);
      }
      dispatch(resetVehicleDamage());
    }
  }, [data]);
  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: IVehicleDamage = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/update-damage?damage=${row.id}`);
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows);
      dispatch(
        addTableState({
          tableName: TableNames.VEHICLE_DAMAGES,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      vehicleDamagesTable && vehicleDamagesTable.rowsPerPage
        ? vehicleDamagesTable.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.VEHICLE_DAMAGES,
          columnName: changedColumn,
          direction
        })
      );
    },
    onFilterChange(changedColumn, filterList, type, changedColumnIndex, displayData) {
      dispatch(
        addTableState({
          tableName: TableNames.VEHICLE_DAMAGES,
          filters: filterList
        })
      );
      let filterColumns: IFilter[] = [];
      filterList.forEach((value, index) => {
        if (value.length) {
          filterColumns.push({
            key: damageTableColoumn[index].name,
            value: value[0]
          });
        }
      });
      if (filterList && filterList.length > 0) {
        setFilterList(filterColumns);
        setIsFiltering(true);
        vehicleDamages({
          variables: {
            filter: filterColumns
          }
        });
      } else {
        setIsFiltering(false);
      }
    },
    onColumnViewChange: (changedColumn, action) => {
      const updatedViewColumns = { ...vehicleDamagesTableState?.viewColumns, [changedColumn]: String(action === 'add') };
      dispatch(
        addTableState({
          tableName: TableNames.VEHICLE_DAMAGES,
          viewColumns: updatedViewColumns
        })
      );
    },
    onTableInit: (_: string, tableState: MUIDataTableState) => {
      if (vehicleDamagesTableState && vehicleDamagesTableState.filters) {
        let filterColumns: IFilter[] = [];
        vehicleDamagesTableState.filters.forEach((value, index) => {
          if (value.length) {
            filterColumns.push({
              key: damageTableColoumn[index].name,
              value: value[0]
            });
          }
        });
        tableState.filterList = vehicleDamagesTableState.filters;
      }
      if (vehicleDamagesTableState && vehicleDamagesTableState.viewColumns) {
        for (const [key, value] of Object.entries(vehicleDamagesTableState.viewColumns)) {
          let columnIndex = tableState.columns.findIndex(column => column.name === key);
          if (columnIndex > -1) {
            tableState.columns[columnIndex].display = value as Display;
          }
        }
      }
      if (vehicleDamagesTable && sortOrder) {
        setTableSortOrder(damageTableColoumn, vehicleDamagesTable, sortOrder);
      }
    }
  };

  const damageTableColoumn = [
    {
      label: "Registration Number",
      name: "licencePlate",
    },
    {
      label: "Incident Date",
      name: "incidentDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, _formatNearestDateTime(value), DATE_TYPE.EXPANDED) : ''
      }
    },
    {
      label: "Reported Date",
      name: "reportDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, _formatNearestDateTime(value), DATE_TYPE.EXPANDED) : ''
      }
    },
    {
      label: `${getLocalizedBookingSyntex(country)} Ref.`,
      name: "bookingReferenceNumber"
    },
    {
      label: "Reference No.",
      name: "referenceNumber"
    },
    {
      label: "Circumstance",
      name: "circumstance",
      options: {
        customBodyRender: (value: VehicleDamageCircumstance) => getLocalizedVehicleDamageCircumstancesValue(value, country)
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value: VehicleDamageStatus) => VehicleDamageStatusValue[value]
      }
    }
  ];
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Damage List
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          className="createButton"
          onClick={() => {
            navigate("/new-damage");
          }}
        >
          Create
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        {!loading ? (
          <NuvvenTable
            title=""
            rows={rows}
            columns={damageTableColoumn}
            options={options}
            setSelection={() => { }}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
};
