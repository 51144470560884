import MenuItem from '@mui/material/MenuItem';
import { DateTime as d } from 'luxon';
import React, { useState, useEffect } from 'react';
import { getYears, SelectButton } from '../Reports/utils';
import styles from '../Reports/booking/index.module.css';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_BOOKINGS_BY_DATES } from '../../../graphql/dashboard/getDashboardData';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { getLocalizedBookingSyntex } from '../../../utils/localized.syntex';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
const weekDaysCompact = ["S", "M", "T", "W", "T", "F", "S"];

interface IProps {
  data?: number[][];
}

const BookingsByDateCard: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const timeZone = userState.currentBranch.timeZone
  const [filters, setfilters] = useState<{ month: number, year: string }>({ month: d.now().get("month"), year: `${d.now().get("year")}` });

  const [data, setData] = useState<number[][]>([]);

  const [
    fetchData,
    { loading, data: fetchedData }
  ] = useLazyQuery(GET_BOOKINGS_BY_DATES, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    setData([]);
    fetchData({
      variables: {
        year: parseInt(filters.year)
      }
    });
  }, [filters.year])

  useEffect(() => {
    if (fetchedData && fetchedData.getBookingsByDates) {
      setData(fetchedData.getBookingsByDates)
    }
  }, [fetchedData])

  const thisMonth = d.fromFormat(`${filters.year}-${filters.month}-01`, "yyyy-M-d")
  const startDay = thisMonth.weekday;
  const daysInMonth = thisMonth.daysInMonth;
  const getHighestBooking = () => {
    let hightestBooking = 0;
    if (data.length) {
      for (let i = 0; i < data[filters.month - 1].length; i++) {
        if (data[filters.month - 1][i] > hightestBooking) {
          hightestBooking = data[filters.month - 1][i]
        }
      }
    }
    return hightestBooking
  }

  const getDateCells = () => {
    const dayCellElements = [];
    const highestBooking = getHighestBooking();
    for (let i = 0; i < startDay; i++) {
      dayCellElements.push(
        <div className={styles.cellWrap}>
          <div className={styles.dateCellBlank}>
            {i < 7 ? <span className={styles.day}>{weekDaysCompact[i]}</span> : null}
          </div>
        </div>
      )
    }
    for (let i = 0; i < daysInMonth; i++) {
      const noOfBookings = data.length ? data[filters.month - 1][i] : 0;
      const date = d.fromFormat(`${filters.year}-${filters.month}-${i + 1}`, "yyyy-M-d").setZone(userState.currentBranch.timeZone).toUTC().toISO()
      dayCellElements.push(
        <div className={styles.cellWrap}>
          <div
            className={styles.dateCell}
            onClick={() => {
              navigate(`/bookings?day=${date}&type=by-date`);
            }}
            style={{ paddingTop: 8, backgroundColor: noOfBookings > 0 ? `rgba(124,178,133,${noOfBookings / (highestBooking || 1)})` : "#f9f9f9" }}
          >
            {i + startDay < 7 ? <span className={styles.day}>{weekDaysCompact[i + startDay]}</span> : null}
            <span className={styles.date} style={{ fontSize: 9 }}>{i + 1}</span>
            {noOfBookings || "-"}
          </div>
        </div>
      )
    }
    const noOfCells: number = dayCellElements.length > 35 ? 42 : 35;
    for (var i = daysInMonth + startDay; i < noOfCells; i++) {
      dayCellElements.push(
        <div className={styles.cellWrap}>
          <div className={styles.dateCellBlank}>
          </div>
        </div>
      )
    }
    return dayCellElements;
  }

  return (
    <div className={styles.module} style={{ height: 300, padding: "10px 15px" }}>
      <div className={styles.chartHeadline} style={{ left: 26, right: 24 }}>
        <span className="font-medium bold ">{getLocalizedBookingSyntex(country)}s by Date</span>
        <div className="flex">
          {loading ? <div className={`flex cross-center`}>
            <CircularProgress size={20} thickness={5} />
          </div> : null}
          <SelectButton
            onChange={(selected) => {
              setfilters({ ...filters, month: parseInt(selected) + 1 })
            }}
            id="MONTHLY_BOOKING_MONTH_FILTER_REAL"
            initialValue={`${d.now().get("month") - 1}`}
            renderVelue={(val) => months[parseInt(val)]}
          >
            {months.map((month, idx) => (
              <MenuItem key={idx} value={idx}>
                {month}
              </MenuItem>
            ))}
          </SelectButton>
          <SelectButton
            onChange={(selected) => setfilters({ ...filters, year: selected })}
            id="MONTHLY_BOOKING_YEAR_FILTER_REAL"
            initialValue={`${d.now().get("year")}`}
          >
            {getYears().map((year, idx) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </SelectButton>
        </div>
      </div>
      <div className={styles.moduleItem}>
        <div className={styles.monthTable}>
          {getDateCells()}
        </div>
      </div>
    </div>
  )
}

export default BookingsByDateCard