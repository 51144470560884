import { gql } from "@apollo/client";

export const GET_AVAILABLE_VEHICLE_GROUPS = gql`
  query availableVehicleGroups($args: AvailableVehicleGroupsInput!) {
    availableVehicleGroups(args: $args) {
      vehicleGroups {
        id
        fleetType
        name
        criteria {
          type
          values
        }
        images {
          idx
          url
          default
        }
        vehicles {
          isGhostVehicle
          id
          branchDetails {
            name
          }
          bodyType
          make
          model
          year
          licencePlate
          transmission
          colour
          fuelType
          features
          numberOfSeats
          numberOfDoors
          imageUrl
          isCarShare
          subscriptions {
            deposit
            subscription {
              id
              name
              rateTypeName
              isActive
              addons {
                id
                name
                displayName
              }
              insurancePolicy {
                id
                name
              }
            }
            pricings {
              duration
              enrollmentAmount
              mileage
              subscriptionAmount
              pricePerExtraMile
              unlimitedMileage
              swapsAllowed
            }
          }
        }
        deposit
        basePrices {
          rateTypeName
          rateTypeDuration
          rate
          longTerm
          mileageLimit
          pricePerExtraMile
          applicableAmount
          priceRuleAmount
          priceRuleName
          priceRuleOperator
          priceRuleValueType
          priceRuleTriggerType
          priceRule
          unlimitedMileage
        }
        isActivated
        imageUrl
      }
      locationSurcharges {
        id
        title
        value
        type
        rate
        taxable
        tax {
          title
          value
          type
        }
      }
      vehiclesDistance {
        vehicleId
        distance
      }
      oneWayRentalFee{
        amount
        taxable
        tax{
          title
          type
          value
        }
      }
    }
  }
`;
