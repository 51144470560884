
import {
  Typography,
  Grid,
  TextField,
  FormControl,
  Fab
} from '@mui/material';
import React, { useState, useEffect } from 'react'
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  getUploadedImageByKey, returnfileContentTypeAndExtention
} from "../../../../common/utils";
import { v4 as uuid } from "uuid";
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import FlatPickerBar from '../../../../common/FlatPicker';
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { DateTime as d } from "luxon";
import * as Yup from "yup";
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import PhoneInput, { Value, isValidPhoneNumber } from "react-phone-number-input";
import { getDefaultCountryCode, getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import AddressPicker from '../../../CustomerManagement/NewCustomer/AddressPicker/AddressPicker';
import { IAddress, IDriverRow } from '../../../../../reducers/user/types';
import DragDropImages from '../../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages';


export const defaultValues: IDriverRow = {
  firstName: "",
  lastName: "",
  email: "",
  license: {
    authority: "",
    licenseNumber: "",
    dateOfExpiry: "",
    images: []
  },
  phoneNumber: {
    phone: "" as Value,
    country: ""
  },
  location: {
    city: "",
    country: "",
    fullAddress: "",
    state: "",
    street: "",
    zipcode: ""
  },
}

interface IAddDriversProps {
  saveDriverLoading: boolean;
  handleSubmit: (data: IDriverRow) => void;
  openForm: () => void;
  selectedDriver: IDriverRow | null
}

const AddNewDriver: React.FC<IAddDriversProps> = (props) => {

  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer)
  const { country } = userState.currentOrganisation.address;
  const [newDriver, setNewDriver] = useState<IDriverRow>(_.cloneDeep(defaultValues));
  const [phoneError, setPhoneError] = useState<string>("");
  const [countryCode, setCountryCode] = useState<any>(getDefaultCountryCode('United Kingdom'));
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(
    false
  );
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);


  useEffect(() => {
    if (props && props.selectedDriver) {
      setNewDriver(props.selectedDriver)
    }
  }, [props.selectedDriver])

  const driverSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required.")
    }),
    license: Yup.object().shape({
      authority: Yup.string().required("Authority is required"),
      licenseNumber: Yup.string().required("Licence Number is required"),
      dateOfExpiry: Yup.date().required("Licence Expiry Date is required")
    }),
  });



  const uploadDocument = async (
    files: File[]
  ) => {
    try {
      if (uploadInProgress) {
        return snackbar({
          message: "Please wait image upload in progress!",
          variant: SnackBarVariant.ERROR
        });
      }
      const licenceImageArray: any[] = [];
      const showUploadedDriverImages: any[] = [];
      const item = newDriver.license
      if (item && item.images && item.images.length >= 2) {
        return snackbar({
          message: "Maximum two images can be uploaded for licence!",
          variant: SnackBarVariant.ERROR
        });
      }
      setUploadInProgress(true);
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        if (file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
          const { fileExtension } = returnfileContentTypeAndExtention(file);
          const uniqueId = uuid();
          const key = `${uniqueId}.${fileExtension}`;
          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  licenceImageArray.push(key);
                  snackbar({
                    message: "Licence Image Uploaded Successfully",
                    variant: SnackBarVariant.SUCCESS
                  });
                  const uploadedFile = await getUploadedImageByKey(key);
                  if (uploadedFile) {
                    showUploadedDriverImages.push(uploadedFile);
                  }
                  setUploadInProgress(false);
                  setDocumentDialogVisible(false);
                }
              });
          }
        } else {
          setDocumentDialogVisible(true);
          setUploadInProgress(false);
          setTimeout(() => {
            return snackbar({
              message: "Please only upload .jpeg/.jpg/.png image format!",
              variant: SnackBarVariant.ERROR
            });
          }, 2000);
        }
      }
      item.images = [...licenceImageArray];
      setNewDriver({
        ...newDriver,
        license: item
      });
    } catch (err: any) {
      snackbar({
        message: formatGraphQLErrorMessage(err?.message),
        variant: SnackBarVariant.ERROR
      });
    }
  };

  const onDeleteDriverImage = () => { }

  return (
    <Grid item container xs={12} style={{ padding: "20px 0" }}>
      <Formik
        initialValues={newDriver}
        enableReinitialize={true}
        validationSchema={driverSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          if (newDriver.license && !newDriver.license.images.length) {
            snackbar({
              message: "At least upload one licence image.",
              variant: SnackBarVariant.ERROR
            });
            setSubmitting(false);
            return;
          }
          data = {
            ...data,
            license: {
              ...data.license,
              images: newDriver.license.images
            }
          }
          setNewDriver(prevDriver => ({
            ...prevDriver,
            license: {
              ...prevDriver.license,
              images: []
            }
          }));
          props.handleSubmit(data)
          resetForm();
          setSubmitting(false)
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Form>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Field name="firstName"
                >
                  {({ field, meta: { touched, error }, }: FieldProps) => (
                    <>
                      <TextField
                        {...field}
                        error={touched && Boolean(error)}
                        placeholder="First Name"
                        label="First Name"
                        value={field.value}
                        onChange={(e: { target: { value: string; }; }) => {
                          setFieldValue("firstName", e.target.value)
                          setNewDriver({ ...values, firstName: e.target.value })
                        }}
                        required
                        fullWidth
                      >
                      </TextField>
                      <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Field
                  name="lastName"
                >
                  {({ field, meta: { touched, error }, }: FieldProps) => (
                    <>
                      <TextField
                        {...field}
                        error={touched && Boolean(error)}
                        placeholder="Last Name"
                        label="Last Name"
                        onChange={(e: { target: { value: string; }; }) => {
                          setFieldValue("lastName", e.target.value)
                          setNewDriver({ ...values, lastName: e.target.value })
                        }}
                        required
                        fullWidth
                      ></TextField>
                      <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Field
                  name="email"
                >
                  {({ field, meta: { touched, error }, }: FieldProps) => (
                    <>
                      <TextField
                        {...field}
                        error={touched && Boolean(error)}
                        placeholder="Email Address"
                        label="Email Address"
                        onChange={(e: { target: { value: string; }; }) => {
                          setFieldValue("email", e.target.value)
                          setNewDriver({ ...values, email: e.target.value })
                        }}
                        fullWidth
                        required
                      ></TextField>
                      <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Field
                  name="license.licenseNumber"
                >
                  {({ field, meta: { touched, error }, }: FieldProps) => (
                    <>
                      <TextField
                        {...field}
                        error={touched && Boolean(error)}
                        placeholder="Licence Number"
                        label="Licence Number"
                        onChange={(e: { target: { value: string; }; }) => {
                          setFieldValue("license.licenseNumber", e.target.value)
                          setNewDriver({
                            ...values, license: {
                              ...values.license,
                              licenseNumber: e.target.value
                            }
                          })
                        }}
                        inputProps={{ maxLength: 20 }}
                        fullWidth
                        required
                      ></TextField>
                      <Typography variant="body2" color="error">{touched && Boolean(error) && error}</Typography>
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl variant="outlined" fullWidth>
                  <FlatPickerBar
                    name="license.dateOfExpiry"
                    handleDateChange={(value: Date) => {
                      if (value) {
                        const dateOfExpiry = d.fromJSDate(value).toUTC().toISO();
                        setFieldValue("license.dateOfExpiry", dateOfExpiry)
                        setNewDriver({
                          ...values, license: {
                            ...values.license,
                            dateOfExpiry: dateOfExpiry
                          }
                        })
                      }
                    }}
                    enableTime={false}
                    label={"Driving Licence Expiry Date"}
                    identifier={"dateOfExpiry"}
                    placeholderValue={"Driving Licence Expiry Date"}
                    value={getLocalizedDateFormat(country, values.license.dateOfExpiry, DATE_TYPE.CONDENSED)}
                    minDate={"today"}
                    required
                    country={country}
                  />
                  <ErrorMessage
                    name="license.dateOfExpiry"
                    component="div"
                    className="field-error"
                  >
                    {msg => <div className={"field-error-message"}>{msg}</div>}
                  </ErrorMessage>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Field
                  name="license.authority"
                >
                  {({ field, meta: { touched, error }, }: FieldProps) => (
                    <>
                      <TextField
                        {...field}
                        error={touched && Boolean(error)}
                        placeholder="Authority"
                        label="Authority"
                        onChange={(e: { target: { value: string; }; }) => {
                          setFieldValue("license.authority", e.target.value)
                          setNewDriver({
                            ...values, license: {
                              ...values.license,
                              authority: e.target.value
                            }
                          })
                        }}
                        inputProps={{ maxLength: 20 }}
                        fullWidth
                        required
                      ></TextField>
                      <ErrorMessage
                        name="license.authority"
                        component="div"
                        className="field-error"
                      >
                        {msg => <div className={"field-error-message"}  >{msg}</div>}
                      </ErrorMessage>
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <Field
                  component={TextField}
                  name={"phoneNumber.phone"}
                  required
                >
                  {({ form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                    return (
                      <div className={`phone-input-container`}>
                        <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                          Phone Number <sup className={"phone-input-required"}>*</sup>
                        </label>
                        <PhoneInput
                          international={false}
                          defaultCountry={countryCode}
                          placeholder="Enter phone number *"
                          className={phoneError ? "phone-input-error" : ""}
                          value={values.phoneNumber.phone}
                          name={"drivers.${index}.phoneNumber.phone"}
                          required
                          onChange={(val: any) => {
                            if (val && isValidPhoneNumber(val)) {
                              setPhoneError("");
                            }
                            setFieldValue("phoneNumber.phone", val)
                          }}
                          onBlur={() => {
                            if (!values.phoneNumber.phone) {
                              setPhoneError("Phone number is required.");
                            } else if (!isValidPhoneNumber(values.phoneNumber.phone)) {
                              setPhoneError("Enter a valid phone number.");
                            } else {
                              setPhoneError("");
                            }
                          }}
                          onCountryChange={(val) => {
                            setCountryCode(val);
                            if (val) {
                              setFieldValue("phoneNumber.country", val)
                              setNewDriver({
                                ...values, phoneNumber: {
                                  ...values.phoneNumber,
                                  country: val
                                }
                              })
                            }
                          }}
                        />
                        {
                          phoneError !== "" ?
                            <span className={"phone-error-message"}>{phoneError}</span>
                            : (meta.touched && meta.error) &&
                            <span className={"phone-error-message"}>
                              {phoneError || "Phone number is required."}
                            </span>
                        }
                      </div>
                    );
                  }}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <AddressPicker
                  fieldName="location"
                  onChange={(address: IAddress) => {
                    if (address) {
                      setFieldValue("location", address)
                      setNewDriver({ ...values, location: address })
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Fab
                  className="blackBackButton"
                  variant="extended"
                  size="medium"
                  aria-label="Update"
                  onClick={(e) => {
                    setDocumentDialogVisible(true);
                    setNewDriver({ ...values })
                  }}
                >
                  UPLOAD LICENCE IMAGE
                </Fab>
                <Grid item>
                  <Typography variant="h4">
                    Upload front and back image of Licence. Supported file format:
                    .png, .jpeg, .jpg!
                  </Typography>
                </Grid>
                <ErrorMessage
                  name="license.images"
                  component="div"
                >
                  {msg => <div className={"field-error-message"}>{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} justifyContent='flex-end' sx={{ display: "flex", alignItems: "center" }}>
                <Fab
                  variant="extended"
                  size="medium"
                  type="submit"
                  style={{ marginRight: 20 }}
                  onClick={(e) => {
                    e.preventDefault();
                    submitForm();
                  }}
                  disabled={props.saveDriverLoading}
                >
                  Add
                </Fab>
                <Fab
                  variant="extended"
                  className="blackBackButton"
                  size="medium"
                  type="button"
                  onClick={(e) => props.openForm && props.openForm()}
                >
                  Cancel

                </Fab>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <div>
        <DragDropImages
          open={documentDialogVisible}
          handleCloseDragDrop={() => setDocumentDialogVisible(false)}
          handleSave={uploadDocument}
          filesLimit={2}
          title={"Upload Licence Images"}
        />
      </div>
    </Grid>
  )
}

export default AddNewDriver
