import { gql } from "@apollo/client";

export const DEACTIVATE_VEHICLE = gql`
  mutation deactivateVehicle($id: String!, $deactivate: Boolean!) {
    deactivateVehicle(id: $id, deactivate: $deactivate){
      id
      status
    }
  }
`;
