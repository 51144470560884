import { Grid, Typography } from "@mui/material";
import React from "react";
import { useVirtualizer } from '@tanstack/react-virtual';
import { ICustomer } from "../../../../../reducers/customer/types";
import MobileCustomerCard from "../../../../common/MobileCustomerCard";


export const CustomerVirtualScroller = ({ data }: { data: ICustomer[] }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 110
  })

  if (!data.length) {
    return <Typography>Sorry no matching record found</Typography>
  }

  return (
    <>
      {/* Scrollable Parent List  */}
      <Grid item xs={12}
        ref={parentRef}
        className="List"
        style={{
          height: `calc(100vh - 250px)`,
          width: 'calc(100vw - 40px)',
          overflow: 'auto',
        }}
      >
        {/* Large inner element for items */}
        <Grid container item xs={12} spacing={2}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            top: 10,
            left: 10,
            position: 'relative',
          }}
        >
          {/* Virtualized Items */}
          {rowVirtualizer.getVirtualItems().map((virtualItem) => {
            const name = data[virtualItem.index].firstName + ' ' + data[virtualItem.index].lastName;
            return (
              <Grid container item xs={12} spacing={2}
                key={virtualItem.key}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
              >
                <MobileCustomerCard
                  id={data[virtualItem.index].id}
                  name={name}
                  phone={data[virtualItem.index].phoneNumber}
                  email={data[virtualItem.index].email}
                  imgKey={data[virtualItem.index].profilePicture}
                />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerVirtualScroller;