import { gql } from "@apollo/client";

export const UPLOAD_DAMAGE_MATRIX = gql`
  mutation uploadDamageMatrix($damageMatrices: [DamageMatrixInput]) {
    uploadDamageMatrix(damageMatrices: $damageMatrices) {
      success
      message
    }
  }
`;
