import { gql } from "@apollo/client";

export const GET_ASSET_TYPES = gql`
  query assetTypes($limit: Int!, $offset: Int!) {
    assetTypes(limit: $limit, offset: $offset) {
      id
      name
      isActive
      customFields{
        label
        value
      }
      dateCreated
    }
  }
`;

export const GET_ASSET_TYPE = gql`
  query assetTypeById($id: String!) {
    assetTypeById(id: $id) {
      id
      name
      isActive
      customFields{
        label
        value
      }
    }
  }
`;