import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { FinanceVehicleChart } from './charts/FinanceVehicle';
import { UtilisationChart } from './charts/UtilisationChart';
import styles from "./index.module.css";
import api from "../api/Reports"
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { useSnackBar } from '../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../common/SnackbarWrapper/SnackbarWrapper';
import { VehiclesDamagesReport } from './VehiclesDamagesReport';
import { allowdRolesForVehicleReports } from '../utils';
import { captureErrorException } from '../../../../utils/sentry';

interface IVehicleData {
  licencePlate: string[];
  telemetryDeviceId: string[]
}
const VehicleAnalytics = () => {
  const snackbar = useSnackBar()
  const userState = useSelector((state: IAppState) => state.userReducer);
  const tenantId = userState && userState.currentTenancy ? userState.currentTenancy?.id : userState.tenancy.id;
  const [filters, setfilters] = useState<{ licensePlate: string, esn: string }>({ licensePlate: "", esn: "" });
  const [vehicles, setVehicles] = useState<IVehicleData>({ licencePlate: [], telemetryDeviceId: [] })

  const fetchAllVehicles = async () => {
    try {
      const { data: axiosData } = await api.getVehicles({
        tenantID: tenantId,
        organisationID: userState.currentOrganisation.id,
        branchID: userState.currentBranch.id,
      });
      if (axiosData?.licenceData) {
        setVehicles(axiosData.licenceData);
        setfilters({
          licensePlate: axiosData.licenceData?.licencePlate[0] || "",
          esn: axiosData.licenceData?.telemetryDeviceId[0] || ""
        })
      }
    }
    catch (error: any) {
      captureErrorException(error)
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading vehicle data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  useEffect(() => {
    if (allowdRolesForVehicleReports.includes(userState.role)) {
      fetchAllVehicles();
    }
  }, [userState, allowdRolesForVehicleReports])

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ display: "flex" }} alignItems="center">
          <Typography>
            <p style={{ margin: "0 40px 0 0" }} className={`font-regular bold ${styles.chartHeadline}`}>Vehicle Information</p>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        {allowdRolesForVehicleReports.includes(userState.role) && (
          <>
            <Grid item xs={9} sm={9} md={9} lg={9}>
              <UtilisationChart licensePlate={filters.licensePlate} />
            </Grid>
            <Grid item xs={3} lg={3}>
              <FinanceVehicleChart licensePlate={filters.licensePlate} />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <VehiclesDamagesReport />
        </Grid>
      </Grid>
    </>
  )
}

export default VehicleAnalytics
