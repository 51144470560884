import { useMutation, ApolloError } from "@apollo/client";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Fab,
  Grid,
  Theme,
  Typography
} from "@mui/material";
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { GET_CREDIT_SCORE } from "../../../../graphql/customers/getCreditScoreMutation";
import { ICustomer } from "../../../../reducers/customer/types";
import { IAppState } from "../../../../store";
import {
  getLocalizedDateFormat
} from "../../../../utils/localized.syntex";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE } from "../../../common/utils";

interface IDialogProps {
  open: boolean;
  handleClose: () => void;
  customer: ICustomer;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const CreditCheckDialog: React.FC<IDialogProps> = (props) => {
  const { open, handleClose, customer } = props;
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [creditScore, setCreditScore] = useState<number>(
    customer.creditSafeData?.creditScore || 0
  );
  const [lastChecked, setLastChecked] = useState<string>(
    customer.creditSafeData?.lastChecked || ""
  );
  const snackbar = useSnackBar();

  const [getCreditScoreForCustomer, { loading: scoreLoading }] = useMutation(
    GET_CREDIT_SCORE,
    {
      onCompleted: (data) => {
        if (!data.getCreditScore.creditSafeData.creditScore) {
          snackbar({
            message:
              "No valid credit score found. This may be due to over exceeded credit checks limit",
            variant: SnackBarVariant.ERROR
          });
        } else {
          setCreditScore(data.getCreditScore.creditSafeData.creditScore);
        }
        setLastChecked(data.getCreditScore.creditSafeData.lastChecked);
      },
      onError: (error: ApolloError) =>
        snackbar({
          message:
            "No valid credit score found. This may be due to over exceeded credit checks limit",
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const getCreditScore = () => {
    getCreditScoreForCustomer({
      variables: {
        customerId: customer.id
      }
    });
  };

  useEffect(() => {
    setCreditScore(customer.creditSafeData?.creditScore || 0);
    setLastChecked(customer.creditSafeData?.lastChecked || "");
  }, [customer]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      className={"select-holidays-dialog"}
    >
      <React.Fragment>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p
            style={{
              fontSize: 18,
              color: "rgba(0, 0, 0, 0.54)",
              padding: 0,
              margin: 0
            }}
          >
            CHECK CREDIT SCORE
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container xs={12} spacing={1}>
            <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
              <Typography variant="h4">
                Credit Score:{" "}
                {creditScore === 0 ? (
                  <CircularProgress size={12} />
                ) : (
                  creditScore
                )}{" "}
              </Typography>
            </Grid>
            <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
              <Typography variant="h4">
                {" "}
                Last Checked:{" "}
                {lastChecked === "" ? (
                  <CircularProgress size={12} />
                ) : (
                  getLocalizedDateFormat(
                    country,
                    lastChecked,
                    DATE_TYPE.EXPANDED
                  )
                )}
              </Typography>
            </Grid>

            <Fab
              variant="extended"
              size="medium"
              style={{
                color: "white",
                position: "relative",
                left: "25%",
                marginTop: "10px"
              }}
              onClick={() => {
                getCreditScore();
              }}
              disabled={scoreLoading}
            >
              {scoreLoading && (
                <CircularProgress size={14} style={{ color: "white" }} />
              )}
              RE-CHECK CREDIT SCORE
            </Fab>
          </Grid>
        </DialogContent>
      </React.Fragment>
    </Dialog>
  );
};
