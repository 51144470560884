import { gql } from "@apollo/client";

export const CREATE_DOCUMENT_TYPE = gql`
  mutation createDocumentType(
    $documentType: DocumentTypeInput!
  ) {
    createDocumentType (documentType: $documentType) {
      id
      typeName
      hasExpiry
      description
      reminderBefore
      isMandatory
      applicableFor
  }
}
`;

export const UPDATE_DOCUMENT_TYPE = gql`
  mutation updateDocumentType(
    $documentTypeId:ID!
    $documentType: DocumentTypeInput!
  ) {
    updateDocumentType (documentTypeId:$documentTypeId,documentType: $documentType) {
      id
      typeName
      hasExpiry
      description
      reminderBefore
      isMandatory
      applicableFor
  }
}
`;