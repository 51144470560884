import "../index.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  TextField as InputField,
  MenuItem,
  Paper,
  Switch,
  Theme,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Autocomplete from "@mui/material/Autocomplete";
import CreateIcon from "@mui/icons-material/Create";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { DateTime as d } from 'luxon';
import _ from "lodash";
import { resetVehicleDamage } from "../../../../../actions/fleet/vehicle/actions";
import { CREATE_VEHICLE_DAMAGE_INFO } from "../../../../../graphql/damageVehicle/createDamageVehicleMutation";
import { GET_VEHICLE_BOOKINGS } from "../../../../../graphql/damageVehicle/getVehicleBookings";
import { GET_VEHICLE_DAMAGE } from "../../../../../graphql/damageVehicle/getVehicleDamage";
import { UPDATE_VEHICLE_DAMAGE } from "../../../../../graphql/damageVehicle/updateDamageVehicleMutation";
import { GET_VEHICLES_LICENCE_PLATE } from "../../../../../graphql/fleet/getVehiclesLicencePlate";
import {
  DamageTypes,
  IVehicleDamage,
  VehicleDamageStatus
} from "../../../../../reducers/damageVehicle/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { SimpleDateTimePicker } from "../../../../common/SimpleDateTimePicker";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { coordinateOffset, formatGraphQLErrorMessage } from "../../../../common/utils";
import { getVehicleDamageCircumstances } from "../constants";
import { DamagesApprovalStatus, getFrogDiagram, IFrogDiagram } from "../../../../common/VehicleDamagePreview/frogDiagram";
import { markDamages, successLoadDamage } from "../../../../common/VehicleDamagePreview/damages";
import { AddDamage, IDamage } from "./AddDamage";
import { NewImageView } from "./NewImageView";
import { getSignedUrl } from "../../../../../utils/getSignedUrl";
import { getLocalizedBookingSyntex } from "../../../../../utils/localized.syntex";
import { captureErrorException } from "../../../../../utils/sentry";
import { GET_BOOKING_BY_ID } from "../../../../../graphql/bookings/getBookingById";
import { IVehicle, VehicleTypes } from "../../../../../reducers/fleet/types";
import { GET_VEHICLE } from "../../../../../graphql/fleet/getVehicleQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    iconButton: {
      padding: 10
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    title: {
      color: "skyblue",
      fontSize: 18,
      fontWeight: 500,
      textTransform: "uppercase"
    },
    table: {
      minWidth: 650,
    },
    button: {
      backgroundColor: "#faaf40",
      border: 0,
      borderRadius: 26,
      color: "white",
      fontSize: "14px",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    }
  })
);

interface IVehicleBookings {
  bookingId: string;
  initialPickupDateTime: string;
  initialDropoffDateTime: string;
  bookingReferenceNumber: string;
  licencePlate: string;
  vehicleId: string;
}

interface IDateTime {
  date: string;
  time: number;
}
interface IDefaultDate {
  incidentDate: string;
  estimatedRepairDate: string;
}
export interface IDamageSeverity {
  label: string;
  value: string;
}
export interface INewVehicleDamage {
  circumstance: string;
  bookingRef: string;
  reportDate?: string;
  incidentDate: string;
  reportedBy: string;
  frogDiagram: string;
  estimatedCost: number,
  status?: string;
  approvalStatus?: string;
  estimatedRepairDate: string;
  vehicleId: string;
  referenceNumber?: string;
  id?: string;
  type?: string;
  tenantId?: string;
  organisationId?: string;
  branchId?: string;
  damages: IDamage[];
  licencePlate?: string;
  bookingReferenceNumber?: string
}
const initialVehicleDamage = {
  circumstance: "",
  bookingRef: "",
  incidentDate: "",
  reportedBy: "",
  frogDiagram: "",
  estimatedCost: 0,
  status: VehicleDamageStatus.DUE,
  estimatedRepairDate: "",
  vehicleId: "",
  damages: []
}
export const svgHeight = 400;
export const NewDamage = () => {
  const styles = useStyles();
  const snackbar = useSnackBar();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [addDamage, setAddDamage] = useState<boolean>(false);
  const [damageData, setDamageData] = useState<any>([]);
  const [damageValues, setDamageValues] = useState<any>(null);
  const [index, setIndex] = useState<number | null>(null);
  const [damagesValues, setValues] = useState<INewVehicleDamage>(
    initialVehicleDamage
  );
  const [selectedVehicle ,setSelectedVehicle] = useState<IVehicle>();
  const [regNoError, setRegNoError] = useState<boolean>(false)
  const [regNoErrorText, setRegNoErrorText] = useState<string>("")
  const [licencePlate, setLicencePlate] = useState<string[]>([]);
  const [bookingRef, setBookingRef] = useState<string[]>([]);
  const [vehicleBookings, setVehicleBookings] = useState<IVehicleBookings[]>([]);
  const [searchedInputValue, setSearchedInputValue] = useState<string>("");
  const prevBranchRef = useRef(userState.currentBranch);
  const [images, setImages] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState({
    minDate: d.now().startOf("day").minus({ week: 1 }).toUTC().toISO(),
    maxDate: d.now().toUTC().toISO()
  });
  const [estRepairMinDate, setEstRepairMinDate] = useState<string>("")
  const [dateDataLoading, setDateDataLoading] = useState<boolean>(false);
  const [svg, setSvg] = useState<IFrogDiagram>();
  const [imagesUrl, setImagesUrl] = useState<{ id: number; imageUrl: string }[]>([]);
  const { country } = userState.currentOrganisation.address;
  const circumstances = getVehicleDamageCircumstances(country);
  const [damageCoordinates, setDamageCoordinates] = useState<any>({
    exterior: [],
    interior: []
  });
  const [damageCoordinate, setDamageCoordinate] = useState<any>({
    type: "",
    coordinate: ""
  });
  const exteriorRef = useRef<any>(null);
  const interiorRef = useRef<any>(null);
  const vehicleDamage = useSelector(
    (state: IAppState) => state.fleetReducer.vehicleDamage
  );
  const [
    loadVehiclesLicencePlate,
    { loading: loadingLicenceNumbers, data: vehiclesData }
  ] = useLazyQuery(GET_VEHICLES_LICENCE_PLATE, {
    fetchPolicy: "network-only"
  });

  const [
    getVehicleBookings,
    { data: vehicleBookingsData }
  ] = useLazyQuery(GET_VEHICLE_BOOKINGS, {
    fetchPolicy: "network-only"
  });

  const [
    getVehicleBooking
  ] = useLazyQuery(GET_BOOKING_BY_ID, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.bookingById) {
        setDateRange({
          minDate: data.bookingById.initialPickupDateTime,
          maxDate: data.bookingById.initialDropoffDateTime
        });
      }
      setDateDataLoading(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    getVehicleDamage,
    { loading: loadingVehicleDamge, data: vehicleDamageData }
  ] = useLazyQuery(GET_VEHICLE_DAMAGE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.damageVehicle) {
        navigate("/vehicle-damage");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });
  const [createDamage, { loading: createDamageLoading }] = useMutation(CREATE_VEHICLE_DAMAGE_INFO, {
    onCompleted: () => {
      snackbar({
        message: "Damage Information saved successfully",
        variant: SnackBarVariant.SUCCESS
      });
      dispatch(resetVehicleDamage());
      navigate("/vehicle-damage");
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });
  const [updateVehicleDamageMutaion] = useMutation(UPDATE_VEHICLE_DAMAGE, {
    onCompleted: () => {
      snackbar({
        message: "Damage Updated Information saved successfully",
        variant: SnackBarVariant.SUCCESS
      });
      dispatch(resetVehicleDamage());
      navigate("/vehicle-damage");
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [
    getVehicle,
    { data: vehicleData }
  ] = useLazyQuery(GET_VEHICLE, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (vehicleData?.vehicle) {
      setSelectedVehicle(vehicleData.vehicle)
    }
  }, [vehicleData])

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const damageId = params.get("damage");
      if (damageId) {
        getVehicleDamage({
          variables: { damageId }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    loadVehiclesLicencePlate();
  }, [vehicleDamage]);

  useEffect(() => {
    if (vehicleBookingsData && vehicleBookingsData.getVehicleBookings) {
      setVehicleBookings(vehicleBookingsData.getVehicleBookings);
    }
  }, [vehicleBookingsData])

  useEffect(() => {
    if (prevBranchRef.current !== userState.currentBranch) {
      navigate("/vehicle-damage");
      prevBranchRef.current = userState.currentBranch;
    }
  }, [userState.currentBranch]);

  useEffect(() => {
    if (
      vehicleDamageData &&
      vehicleDamageData.damageVehicle
    ) {
      const { isFourWheeler, ...rest } = vehicleDamageData.damageVehicle
      setValues({
        ...damagesValues,
        ...rest
      });
      setLicencePlate(vehicleDamageData.damageVehicle.licencePlate);
      if (vehicleDamageData.damageVehicle && vehicleDamageData.damageVehicle.bookingReferenceNumber) {
        setDateDataLoading(true);
        setBookingRef(vehicleDamageData.damageVehicle.bookingReferenceNumber.toUpperCase());
        getVehicleBooking({
          variables: {
            bookingId: vehicleDamageData.damageVehicle.bookingRef
          }
        });
      }
      let damages: any = _.cloneDeep([...vehicleDamageData.damageVehicle.damages]);
      damages = damages.map((value: any, index: number) => ({ ...value, id: index + 1, frogDiagram: vehicleDamageData.damageVehicle.frogDiagram, referenceNumber: vehicleDamageData.damageVehicle.referenceNumber }));
      setDamageData(damages);
      const markData = markDamages(damages, vehicleDamageData.damageVehicle.frogDiagram);
      if (markData) {
        setSvg(markData.svg);
        setDamageCoordinates({
          exterior: markData.exterior,
          interior: markData.interior
        })
      }
      getVehicle({
        variables: {
          id: vehicleDamageData.damageVehicle.vehicleId
        }
      })
    }
  }, [vehicleDamageData]);

  useEffect(() => {
    if (
      vehicleDamageData &&
      vehicleDamageData.damageVehicle
    ) {
      const damageData = _.cloneDeep(vehicleDamageData.damageVehicle.damages);
      const updatedDamageImageData: { id: number; imageUrl: string }[] = [];
      damageData.map((damage: any, idx: number) => {
        if (damage.images && damage.images.length > 0) {
          const promises: any[] = [];
          damage.images.map(
            (id: string) => {
              promises.push(
                (async () => {
                  try {
                    const file = await getSignedUrl(id);
                    if (file) {
                      updatedDamageImageData.push({
                        id: idx + 1,
                        imageUrl: file
                      })
                    }
                  } catch (error) {
                    captureErrorException(error)
                  }
                })()
              );
            });
          Promise.all(promises).then(() => {
            setImagesUrl(updatedDamageImageData);
          });
        }
      })
    }
  }, [vehicleDamageData]);


  const detailsFormSchema = Yup.object().shape({
    circumstance: Yup.string().required(
      "Please select circumstance."
    ),
  });

  const handleFormSubmit = (values: INewVehicleDamage) => {
    let updatedVehicleDamage = _.cloneDeep({ ...values });
    const { id, referenceNumber, licencePlate, bookingReferenceNumber, branchId, ...restUpdatedVehicleDamage } = updatedVehicleDamage;
    restUpdatedVehicleDamage.damages.map((damage: any, index) => {
      const { id, referenceNumber, frogDiagram, ...rest } = damage;
      restUpdatedVehicleDamage.damages[index] = rest;
    })
    if (values.id) {
      updateVehicleDamageMutaion({
        variables: {
          vehicleId: values.vehicleId,
          damageId: values.id,
          vehicledamage: restUpdatedVehicleDamage
        }
      });
    } else {
      createDamage({
        variables: {
          vehicleId: values.vehicleId,
          vehicledamage: restUpdatedVehicleDamage,
          isRO: true
        }
      });
    }
  };

  const downloadImages = async (urls: string[]) => {
    // Create and append a link
    let link = document.createElement("a");
    document.documentElement.append(link);

    for (let i = 0; i < urls.length; i++) {
      await fetch(urls[i])
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {

          let objectURL = URL.createObjectURL(blob);

          // Set the download name and href
          link.setAttribute("download", `image_${i + 1}.jpg`);
          link.href = objectURL;

          // Auto click the link
          link.click();
        })
    }
  };

  const isUpdateScreen = () => {
    return vehicleDamageData && vehicleDamageData.damageVehicle.id
      ? true
      : false;
  };
  const loadDamages = async (data: any) => {
    const interiorCoordinates: any = [];
    const exteriorCoordinates: any = [];
    data.map((damage: any) => {
      if (damage.damageType === DamageTypes.EXTERIOR && svg) {
        const damageData = successLoadDamage(damage, damage.id, svg?.exterior.markerRadius, svg?.exterior.markerFont, (damagesValues.referenceNumber ? damagesValues.referenceNumber : ""))
        exteriorCoordinates.push(damageData.DamageCoordinateItem)
      }
      if (damage.damageType === DamageTypes.INTERIOR && svg) {
        const damageData = successLoadDamage(damage, damage.id, svg?.interior.markerRadius, svg?.interior.markerFont, (damagesValues.referenceNumber ? damagesValues.referenceNumber : ""))
        interiorCoordinates.push(damageData.DamageCoordinateItem)
      }
    });
    setDamageCoordinates({
      exterior: exteriorCoordinates,
      interior: interiorCoordinates
    })

  };
  const addMark = (damage: any, markerRadius: number, markerFont: number, type: string) => {
    const xVal = parseInt(damage.x);
    const yVal = parseInt(damage.y);
    setDamageValues({
      title: "",
      descriptions: "",
      damageType: type,
      damageKind: "",
      damageArea: "",
      images: [],
      location: {
        x: xVal - coordinateOffset,
        y: yVal - coordinateOffset
      }
    });
    setDamageCoordinate({
      coordinate: <g id={`damage-mark`} style={{ cursor: "pointer" }}>
        <circle cx={`${xVal}`} cy={`${yVal}`} r={`${markerRadius}`} fill="red" stroke-width="0.5" />
        <text text-anchor="middle" x={`${xVal}`} y={`${yVal}`} dy="0.30em" font-size={`${markerFont}em`} stroke-width="0.8" stroke="white" fill="white">
        </text>
      </g>,
      type: type
    });
  }
  if (loadingLicenceNumbers || loadingVehicleDamge || (vehicleDamageData?.damageVehicle.id && !selectedVehicle)) {
    return <CircularProgress />;
  }
  return (
    <Grid container item xs={12} >
      <Grid container item sm={12} alignItems="center">
        <Typography variant="h1" color="primary">
          Damage
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>
        {isUpdateScreen() ? (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            New
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Damage
        </Typography>
      </Grid>
      <Grid container item sm={12}>
        <Paper className={styles.root}>
          <Formik
            enableReinitialize
            validationSchema={detailsFormSchema}
            initialValues={damagesValues}
            onSubmit={(values, { setSubmitting }) => {
              if (values.circumstance === 'BOOKING' && !values.bookingRef) {
                return snackbar({
                  message: `${getLocalizedBookingSyntex(country)} reference number is required.`,
                  variant: SnackBarVariant.ERROR
                })
              }
              if (!values.id) {
                values.reportDate = d.now().toUTC().toISO();
              }
              handleFormSubmit(values);
              setSubmitting(false);
            }}
          >
            {(props) => {
              return (
                <React.Fragment>
                  <Form>
                    <Grid item container spacing={2} sm={12}>
                      <Grid item sm={8}>
                        <Typography variant="h2">DAMAGE DETAILS</Typography>
                      </Grid>

                      {isUpdateScreen() && damagesValues.approvalStatus && (
                        <Grid item sm={2}>
                          <FormControlLabel
                            style={{ marginLeft: "105px" }}
                            disabled={damagesValues.branchId && userState.currentBranch.id !== damagesValues.branchId ? true : false}
                            control={
                              <Switch
                                checked={
                                  damagesValues.approvalStatus === DamagesApprovalStatus.PENDING
                                    ? false
                                    : true
                                }
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  setValues({
                                    ...damagesValues,
                                    approvalStatus: event.target.checked
                                      ? DamagesApprovalStatus.APPROVED
                                      : DamagesApprovalStatus.PENDING
                                  });
                                }}
                                name="approvalStatus"
                                color="primary"
                              />
                            }
                            label={
                              <Typography variant="body1">
                                {DamagesApprovalStatus.APPROVED}
                              </Typography>
                            }
                          />
                        </Grid>
                      ) || (<Grid item sm={2}></Grid>)}
                      {isUpdateScreen() && (
                        <Grid item sm={2}>
                          <FormControlLabel
                            style={{ marginLeft: "105px" }}
                            control={
                              <Switch
                                checked={
                                  damagesValues.status === VehicleDamageStatus.FIXED
                                    ? false
                                    : true
                                }
                                disabled={damagesValues.branchId && userState.currentBranch.id !== damagesValues.branchId ? true : false}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  setValues({
                                    ...damagesValues,
                                    status: event.target.checked
                                      ? VehicleDamageStatus.DUE
                                      : VehicleDamageStatus.FIXED
                                  });
                                }}
                                name="status"
                                color="primary"
                              />
                            }
                            label={
                              <Typography variant="body1">
                                {damagesValues.status === VehicleDamageStatus.FIXED
                                  ? "Fixed"
                                  : "Due"}
                              </Typography>
                            }
                          />
                        </Grid>
                      )}
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                          >
                            <Field
                              component={TextField}
                              name={"circumstance"}
                              fullWidth
                              required
                              type="text"
                              select
                              label="Circumstance"
                              disabled={props.values && props.values.id ? true : false}
                              inputProps={{
                                onChange: props.handleChange,
                                value: props.values.circumstance
                              }} 
                            >
                              {circumstances.map((item: any, index: number) => {
                                return (
                                  <MenuItem key={index} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          {damagesValues.id ? (
                            <Field
                              component={InputField}
                              value={licencePlate}
                              fullWidth
                              disabled
                              label="Registration Number"
                            />
                          ) : (
                            vehiclesData &&
                            vehiclesData.getOwnedAndExternalVehicles && (
                              <Autocomplete
                                id="combo-box-demo"
                                options={vehiclesData.getOwnedAndExternalVehicles}
                                getOptionLabel={(option: any) => {
                                  return userState.currentBranch.id === option && option.branchDetails && option.branchDetails.id ?
                                    `${option.licencePlate}` : `${option.licencePlate} (${option.branchDetails.name})`
                                }}
                                fullWidth
                                onChange={(event: any, newVal: any | null) => {
                                  if (newVal && newVal.id) {
                                    getVehicleBookings({
                                      variables: {
                                        licencePlate: newVal.licencePlate
                                      }
                                    });
                                    setSelectedVehicle(newVal)
                                    setRegNoError(false);
                                    setRegNoErrorText("");
                                    setValues({
                                      ...props.values,
                                      vehicleId: newVal.id,
                                      frogDiagram: newVal.frogDiagram,
                                      incidentDate: "",
                                      estimatedRepairDate: ""
                                    });
                                    const { interior, exterior } = getFrogDiagram(newVal.frogDiagram);
                                    setSvg({ interior, exterior });
                                    setDateRange({
                                      minDate: d.now().startOf("day").minus({ week: 1 }).toUTC().toISO(),
                                      maxDate: d.now().toUTC().toISO()
                                    })
                                  } else {
                                    props.setValues({
                                      ...props.values,
                                      vehicleId: ""
                                    });
                                    setDateRange({
                                      minDate: d.now().startOf("day").minus({ week: 1 }).toUTC().toISO(),
                                      maxDate: d.now().toUTC().toISO()
                                    })
                                  }
                                }}
                                onInputChange={() => {
                                  setSearchedInputValue("");
                                }}
                                renderInput={(params) => (
                                  <InputField
                                    {...params}
                                    value={props.values.vehicleId}
                                    label="Registration Number"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="vehicleId"
                                    error={regNoError}
                                    helperText={regNoErrorText}
                                    onBlur={(e) => {
                                      if (e.target.value === "") {
                                        setRegNoError(true)
                                        setRegNoErrorText("Registration number is required")
                                      }
                                      else {
                                        setRegNoError(false)
                                        setRegNoErrorText("")
                                      }
                                    }}
                                  />
                                )}
                              />
                            )
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Field
                            component={TextField}
                            label="Estimated Cost"
                            name={"estimatedCost"}
                            value={props.values.estimatedCost}
                            onChange={props.handleChange}
                            fullWidth
                            InputProps={{
                              inputComponent: FloatInput as any
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                          ></Field>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          {damagesValues.id ? (
                            <Field
                              component={InputField}
                              value={bookingRef}
                              fullWidth
                              disabled
                              label="Reference Number"
                            />
                          ) : (
                            (
                              <Autocomplete
                                id="combo-box-reference-number"
                                options={vehicleBookings}
                                getOptionLabel={(option: IVehicleBookings) =>
                                  option.bookingReferenceNumber
                                }
                                fullWidth
                                onChange={(event: any, newVal: any | null) => {
                                  props.setValues({
                                    ...props.values,
                                    bookingRef: newVal.booking,
                                    incidentDate: "",
                                    estimatedRepairDate: ""
                                  });
                                  if (newVal) {
                                    setDateRange({
                                      minDate: newVal.initialPickupDateTime,
                                      maxDate: newVal.initialDropoffDateTime
                                    });
                                    setSearchedInputValue(newVal.bookingReferenceNumber);
                                  } else {
                                    setDateRange({
                                      minDate: d.now().startOf("day").minus({ week: 1 }).toUTC().toISO(),
                                      maxDate: d.now().toUTC().toISO()
                                    })
                                    setSearchedInputValue("");
                                  }
                                }}
                                inputValue={searchedInputValue}
                                renderInput={(params) => (
                                  <InputField
                                    {...params}
                                    value={props.values.bookingRef}
                                    label={`${getLocalizedBookingSyntex(country)} Reference Number`}
                                    variant="outlined"
                                    fullWidth
                                    name="bookingRef"
                                    onChange={(e) => setSearchedInputValue(e.target.value)}
                                  />
                                )}
                              />
                            )
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          {!dateDataLoading && <SimpleDateTimePicker
                            key={dateRange.minDate}
                            date={props.values.incidentDate}
                            handleChange={(date: IDateTime) => {
                              if (date && date.date) {
                                props.setValues({
                                  ...props.values,
                                  incidentDate: date.date,
                                  estimatedRepairDate: ""
                                });
                                setEstRepairMinDate(date.date)
                              }
                            }}
                            required={true}
                            name={"incidentDate"}
                            dateTitle={"Incident Date"}
                            timeTitle={"Incident Time"}
                            timeToShow={"before"}
                            minDate={dateRange.minDate}
                            maxDate={dateRange.maxDate}
                            now={dateRange.minDate}
                          />}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          {!dateDataLoading && <SimpleDateTimePicker
                            key={dateRange.minDate}
                            date={props.values.estimatedRepairDate}
                            handleChange={(date: IDateTime) => {
                              if (date && date.date) {
                                props.setValues({
                                  ...props.values,
                                  estimatedRepairDate: date.date
                                })
                              }
                            }}
                            required={true}
                            name={"estimateRepairDate"}
                            dateTitle={"Estd. Repair Date"}
                            timeTitle={"Estd. Repair Time"}
                            minDate={estRepairMinDate ?
                              d.fromISO(estRepairMinDate).toUTC().toISO()
                              : dateRange.minDate}
                            now={props.values.estimatedRepairDate ?
                              d.fromISO(props.values.estimatedRepairDate).toUTC().toISO()
                              : dateRange.minDate}
                          />}
                        </Grid>
                      </Grid>
                      <Grid container item xs={3}></Grid>
                      {addDamage && (
                        <AddDamage
                          title={"Mark Damage"}
                          open={addDamage}
                          handleClose={() => {
                            setDamageValues(null);
                            setIndex(null);
                            setAddDamage(false);
                            setDamageCoordinate({
                              coordinate: "",
                              type: ""
                            });
                          }}
                          handleSubmit={(data: any, index?: number) => {
                            let damages = [];
                            const updatedDamageImageData: { id: number; imageUrl: string }[] = [];
                            if (index != null || index !== undefined) {
                              damageData[index] = data;
                              damages = _.cloneDeep([...damageData])
                            } else {
                              damages = _.cloneDeep([...damageData, data]);
                            }
                            damages = damages.map((value: any, index: number) => ({ ...value, id: index + 1 }));
                            setDamageData(damages);
                            loadDamages(damages);
                            props.setValues({
                              ...props.values,
                              damages: damages
                            });
                            setDamageValues(null);
                            setIndex(null);
                            setAddDamage(false);
                            setDamageCoordinate({
                              coordinate: "",
                              type: ""
                            });
                            damages.map((damage: any) => {
                              if (damage.images && damage.images.length > 0) {
                                const promises: any[] = [];
                                damage.images.map(
                                  (id: string, index: number) => {
                                    promises.push(
                                      (async () => {
                                        try {
                                          const file = await getSignedUrl(id);
                                          if (file) {
                                            updatedDamageImageData.push({
                                              id: damage.id,
                                              imageUrl: file
                                            })
                                          }
                                        } catch (error) {
                                          captureErrorException(error)
                                        }
                                      })()
                                    );
                                  });
                                Promise.all(promises).then(() => {
                                  setImagesUrl([...updatedDamageImageData]);
                                });
                              }
                            })
                          }}
                          images={(img: any) => setImages([...images, img])}
                          svg={svg}
                          values={damageValues}
                          index={index}
                          damageBranchId={damagesValues.branchId}
                        />)}
                      <Grid container item xs={12}>
                        {
                          damagesValues.frogDiagram && (
                            <Grid container item xs={12} >
                              <Grid item xs={10} style={{ marginBottom: "20px" }}>
                                <Typography variant="h2">FROG DIAGRAM</Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Paper elevation={0} style={{ padding: "20px", textAlign: "center", border: "1px solid #ddd" }}>
                                  {svg?.exterior.svg &&
                                    (<svg xmlns="http://www.w3.org/2000/svg"
                                      style={{ height: `${svgHeight}px`, marginBottom: "20px" }}
                                      ref={exteriorRef}
                                      onClick={event => {
                                        const rect = exteriorRef.current.getBoundingClientRect();
                                        const location = { x: (event.clientX - rect.left) * svg?.exterior.width / rect.width, y: (event.clientY - rect.top) * svg?.exterior.height / svgHeight };
                                        const spots = damagesValues.damages
                                        const markerRadius = svg?.exterior.markerRadius
                                        const currentSpot = { x: location.x - coordinateOffset, y: location.y - coordinateOffset };
                                        let spotIndex: number = -1;
                                        if (spots.length > 0) {
                                          for (let index = 0; index < spots.length; index++) {
                                            const spt = spots[index];
                                            // find difference between existing marker and selected mark
                                            const d = Math.sqrt(Math.pow(currentSpot.x - spt.location.x, 2) + Math.pow(currentSpot.y - spt.location.y, 2));
                                            if (d <= markerRadius) {
                                              spotIndex = index
                                            }
                                          }
                                        }
                                        if (spotIndex >= 0) {
                                          setDamageValues(spots[spotIndex])
                                          setIndex(spotIndex)
                                          setAddDamage(true)
                                        } else {
                                          addMark(location, svg?.exterior.markerRadius, svg?.exterior.markerFont, DamageTypes.EXTERIOR)
                                          setAddDamage(true)
                                        }
                                      }}
                                      viewBox={`0 0 ${svg?.exterior.width} ${svg?.exterior.height}`}>
                                      {svg?.exterior.svg}
                                      {damageCoordinates.exterior.length > 0 && damageCoordinates.exterior}
                                      {damageCoordinate.type === DamageTypes.EXTERIOR && damageCoordinate.coordinate}
                                    </svg>)}
                                  <Typography variant="h5">Click on the frog diagram to mark damages</Typography>
                                </Paper>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                {(selectedVehicle?.vehicleType === VehicleTypes.DEFAULT_VEHICLE.toString() ||
                                  selectedVehicle?.vehicleType === VehicleTypes.TRAILER.toString()) &&
                                  (<Paper elevation={0} style={{ padding: "20px", textAlign: "center", border: "1px solid #ddd" }}>
                                    {svg?.interior.svg && (
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                        style={{ height: `${svgHeight}px`, marginBottom: "20px" }}
                                        ref={interiorRef}
                                        onClick={event => {
                                          const rect = interiorRef.current.getBoundingClientRect();
                                          const location = { x: (event.clientX - rect.left) * svg?.interior.width / rect.width, y: (event.clientY - rect.top) * svg?.interior.height / svgHeight };
                                          const spots = damagesValues.damages
                                          const markerRadius = svg?.interior.markerRadius
                                          const currentSpot = { x: location.x - coordinateOffset, y: location.y - coordinateOffset };
                                          let spotIndex: number = -1;
                                          if (spots.length > 0) {
                                            for (let index = 0; index < spots.length; index++) {
                                              const spt = spots[index];
                                              // find difference between existing marker and selected mark
                                              const d = Math.sqrt(Math.pow(currentSpot.x - spt.location.x, 2) + Math.pow(currentSpot.y - spt.location.y, 2));
                                              if (d <= markerRadius) {
                                                spotIndex = index
                                              }
                                            }
                                          }
                                          if (spotIndex >= 0) {
                                            setDamageValues(spots[spotIndex])
                                            setIndex(spotIndex)
                                            setAddDamage(true)
                                          } else {
                                            addMark(location, svg?.interior.markerRadius, svg?.interior.markerFont, DamageTypes.INTERIOR)
                                            setAddDamage(true)
                                          }
                                        }}
                                        viewBox={`0 0 ${svg?.interior.width} ${svg?.interior.height}`}>
                                        {svg?.interior.svg}
                                        {damageCoordinates.interior.length > 0 && damageCoordinates.interior}
                                        {damageCoordinate.type === DamageTypes.INTERIOR && damageCoordinate.coordinate}
                                      </svg>)}
                                    <Typography variant="h5">Click on the frog diagram to mark damages</Typography>
                                  </Paper>)}
                              </Grid>
                              <Grid>
                              </Grid>
                              <Grid container item sm={12}>
                                <Box mt={1}></Box>
                              </Grid>
                              {damageData && damageData.length > 0 && (
                                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                  <NewImageView data={damageData} />
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                {damageData && damageData.length > 0 && (
                                  <Paper variant="outlined">
                                    <TableContainer>
                                      <Table className={styles.table} aria-label="simple table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell align="right">Type</TableCell>
                                            <TableCell align="right">Kind</TableCell>
                                            <TableCell align="right">Area</TableCell>
                                            <TableCell align="right">Description</TableCell>
                                            <TableCell align="right">Image</TableCell>
                                            <TableCell align="right">Modify</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {damageData.map((damageTableItem: any, index: number) => {
                                            const filteredDamageImageUrl = imagesUrl.filter((d: { id: number; imageUrl: string }) => d.id === damageTableItem.id);
                                            const multipleUrls: string[] = [];
                                            if (filteredDamageImageUrl.length > 1) {
                                              filteredDamageImageUrl.map((d: { id: number; imageUrl: string }) => {
                                                multipleUrls.push(d.imageUrl)
                                              })
                                            }
                                            return (

                                              <TableRow key={damageTableItem.id}>
                                                <TableCell component="th" scope="row">
                                                  {damageTableItem.id}
                                                </TableCell>
                                                <TableCell align="right" style={{ textTransform: "capitalize" }}>{damageTableItem.damageType || "N/A"}</TableCell>
                                                <TableCell align="right">{damageTableItem.damageKind || "N/A"}</TableCell>
                                                <TableCell align="right">{damageTableItem.damageArea || "N/A"}</TableCell>
                                                <TableCell align="right">{damageTableItem.descriptions || "N/A"}</TableCell>
                                                <TableCell align="right">
                                                  {filteredDamageImageUrl?.length === 1 &&
                                                    filteredDamageImageUrl[0].imageUrl ? (
                                                    <a href={filteredDamageImageUrl ? filteredDamageImageUrl[0].imageUrl : ""} style={{ color: "#F78500" }}>
                                                      <VisibilityIcon />
                                                    </a>
                                                  ) :
                                                    multipleUrls?.length ? (
                                                      <a onClick={() => downloadImages(multipleUrls)} style={{ color: "#F78500" }}>
                                                        <VisibilityIcon style={{ color: "#F78500" }} />
                                                      </a>
                                                    )
                                                      :
                                                      "N/A"}
                                                </TableCell>
                                                <TableCell align="right">
                                                  <IconButton
                                                    color="primary"
                                                    className={styles.iconButton}
                                                    aria-label="edit"
                                                    onClick={() => {
                                                      setDamageValues(damageTableItem);
                                                      setIndex(index);
                                                      setAddDamage(true)
                                                    }}
                                                  >
                                                    <CreateIcon />
                                                  </IconButton>
                                                </TableCell>
                                              </TableRow>
                                            )
                                          })}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Paper>
                                )}
                              </Grid>

                            </Grid>
                          )
                        }
                      </Grid>
                      <Grid item sm={12}>
                        <Fab
                          variant="extended"
                          size="medium"
                          type="submit"
                          disabled={
                            !props.values.incidentDate ||
                            !props.values.estimatedRepairDate ||
                            props.values.damages.length === 0 ||
                            (damagesValues.branchId && userState.currentBranch.id !== damagesValues.branchId) ||
                            createDamageLoading
                          }
                        >
                          {createDamageLoading && (
                            <CircularProgress
                              size={14}
                              style={{ color: "white", marginRight: "10px" }}
                            />
                          )}
                          Save
                        </Fab>
                      </Grid>
                    </Grid>
                  </Form>
                </React.Fragment>
              );
            }}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default NewDamage;
