import { gql } from "@apollo/client";

export const UPDATE_RATE_CARD = gql`
  mutation updateRateCard($id: String!, $businessCustomers: [String]) {
    updateRateCard(id: $id, businessCustomers: $businessCustomers) {
      id
      name
      isActive
      isDefault
      displayName
      dateUpdated
      lastUpdatedBy {
        firstName
        lastName
      }
      businessCustomers {
        businessName
        id
      }
      vehicleGroups {
        name
        unlimitedMileage
        mileageLimit
        pricePerExtraMile
        rateTypeName
        rateTypeDuration
        pricing {
          rangeStart
          rangeEnd
          unitPrice
        }
        vehicleGroup {
          id
          name
        }
      }
    }
  }
`;
