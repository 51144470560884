import {
  Grid,
  Card,
  Box,
  Typography,
  CardActions,
  IconButton
} from "@mui/material";
import CardContent from '@mui/material/CardContent';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { useSelector } from "react-redux";
import { IVehicleDocument } from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { UserRoles } from "../../../../../hoc/Authorization";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../../../common/utils";

interface IProps {
  row: IVehicleDocument;
  index: number;
  downloadHandle(url: string, documentName: string): void;
  deleteHandle(url: string, index: number): void;
}

export const MobileDocumentCard = (props: IProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const borderColour = "rgba(0, 0, 0, 1)"

  return (
    <Card style={{
      marginTop: 8,
      marginBottom: 8,
      width: 'calc(100vw - 120px)',
      border: "2px solid",
      borderColor: `${borderColour}`,
      borderRadius: "3%",
    }}>
      <CardContent>
        <Grid container item xs={12} justifyContent='center'>
          <Grid container item xs={12} justifyContent='center'>
            <Grid item>
              <Typography>Document Title:</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent='center'>
            <Grid item>
              <Typography>{props.row.title}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid container item xs={6} justifyContent='center'>
            <Grid item>
              <Typography>Document Type:</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={6} justifyContent='center'>
            <Grid item>
              <Typography>Expiry Date:</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={6} justifyContent='center'>
            <Grid item>
              <Typography>{props.row.documentType}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={6} justifyContent='center'>
            <Grid item>
              <Typography>{getLocalizedDateFormat(country, props.row.expiryDate, DATE_TYPE.CONDENSED)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      {(userState.role === UserRoles.ADMIN || userState.role === UserRoles.SUPER_ADMIN) && (
        <CardActions >
          <IconButton
            edge="end"
            aria-label="download"
            onClick={() => {
              props.downloadHandle(props.row.url, props.row.documentName);
            }}
            size="large">
            <CloudDownloadIcon />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
              props.deleteHandle(props.row.url, props.index);
            }}
            size="large">
            <DeleteIcon />
          </IconButton>
        </CardActions>
      )
      }
    </Card >
  );
};
