import {
  Fab,
  FormControl,
  Grid,
  MenuItem,
  TextField
} from "@mui/material";
import { Field } from "formik";
import React from "react";
import { IBranch } from "../../../reducers/user/types";

interface Props {
  branchId: string;
  branches: IBranch[];
  onChange: (e: React.ChangeEvent) => void;
}

export const BranchSelector = (props: Props) => {
  return (
    <>
      <Grid container item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Field
            component={TextField}
            name={"branchId"}
            fullWidth
            type="text"
            select
            label="Branch"
            value={props.branchId}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              onChange: props.onChange
            }}
            placeholder="Select Branch"
          >
            {props.branches &&
              props.branches.map((branch: IBranch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.name}
                </MenuItem>
              ))}
          </Field>
        </FormControl>
      </Grid>
      <Grid item container xs={6} >
        <Fab
          variant="extended"
          size="medium"
          type="submit"
          aria-label="add"
        >
          SAVE
        </Fab>
      </Grid>
    </>
  );
};
