import { gql } from "@apollo/client";

export const GET_CHECK_INFO_BOOKING = gql`
  query bookingSchedule($id: String!) {
    bookingSchedule(id: $id) {
      vehicleStatus
      booking {
        id
        dateCreated
        referenceNumber
        dropoffDateTime
        pickupDateTime
        status
        currentInvoice {
          totalPayableAmount
          confirmedPaidAmount
          confirmedRefundAmount
        }
        addonRequirements {
          addon {
            id
            name
            displayName
            description
            recurringRate {
              rateTypeName
            }
            totalQuantity
            availableQuantity
            isActivated
          }
          rate
          quantity
          name
          hasFixedRate
          payableAmount
        }
        customer {
          id
          firstName
          lastName
          email
          phoneNumber {
            phone
            country
          }
          dateOfBirth
          gender
          location {
            fullAddress
          }
        }
      }
      vehicle {
        id
        model
        licencePlate
        vehicleState {
          exterior {
            front {
              description
              images
            }
            rear {
              description
              images
            }
            top {
              description
              images
            }
            right {
              description
              images
            }
            left {
              description
              images
            }
          }
          interior {
            frontSeat {
              clean
              controls
              seatBelt
            }
            rearSeat {
              clean
              controls
              seatBelt
            }
            lockAndSwitch {
              frontDoor
              rearDoor
              frontWindow
              rearWindow
              childLock
            }
            images
          }
          generalChecks{
            name
            status
            images
          }
          defaultAccesories{
            name
            status
            images
          }
          bookedAccesories {
            name
            type
            quantity
            description
          }
          tyre {
            frontLeft {
              condition
              pressure
              description
            }
            frontRight {
              condition
              pressure
              description
            }
            rearLeft {
              condition
              pressure
              description
            }
            rearRight {
              condition
              pressure
              description
            }
          }
          odometerReading
          fuelStatus
        }
      }
    }
  }
`;
