import { gql } from "@apollo/client";

export const SEARCH_CUSTOMER = gql`
  query searchCustomer($q: String!) {
    searchCustomer(q: $q) {
      firstName
      lastName
      email
      costCenters {
        name
        projectIds
      }
      phoneNumber{
        country
        phone
      }
      status
      id
      license {
        licenseNumber
        points
        offences
      }
      credasIdentityVerification {
        entityId
        processId
        verificationStatus
        verificationMethod
        lastUpdated
      }
    }
  }
`;