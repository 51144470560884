import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { CircularProgress, Grid } from '@mui/material';
import CancellationMonthlyChart from './charts/CencellationMonthly';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import api from "../api/Reports"
import { useSnackBar } from '../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../common/SnackbarWrapper/SnackbarWrapper';
import { getLocalizedBookingSyntex } from '../../../../utils/localized.syntex';
import { captureErrorException } from '../../../../utils/sentry';

interface IProps {
  active: boolean
}

const BookingReports: React.FC<IProps> = ({ active }) => {
  const snackbar = useSnackBar()
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const tenantId = userState && userState.currentTenancy ? userState.currentTenancy?.id : userState.tenancy.id;
  const [tileData, setTileData] = useState<{ bookings: string, cancellations: string }>({ bookings: "", cancellations: "" });
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true)
      const { data: axiosData } = await api.getCancellationsAll({
        tenantID: tenantId,
        organisationID: userState.currentOrganisation.id,
        branchID: userState.currentBranch.id,
      });
      setTileData({
        bookings: `${axiosData.total}`,
        cancellations: `${axiosData.cancelled}`
      });
      setLoading(false);
    }
    catch (error: any) {
      captureErrorException(error)
      setLoading(false);
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  useEffect(() => {
    if (userState) {
      fetchData();
    }
  }, [userState])

  return (
    <div style={{ display: active ? 'block' : 'none' }}>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <div className={styles.module}>
            <div className={styles.chartHeadline}>
              <span className="font-medium ">
                <strong> {`${getLocalizedBookingSyntex(country)}s`} </strong>
                <span className="opacity50">
                  (Life Time)
                </span>
              </span>
              <span style={{ height: 26, display: 'flex', alignItems: "center" }}>
                {tileData.bookings ? null : loading ? <CircularProgress size={20} thickness={5} /> : ""}
              </span>
            </div>
            <div className={`${styles.moduleItem} flex col-flex font-medium`}>
              <div className="flex fill col-flex main-center cross-center margin-bottom" style={{ border: "1px solid #f0f0f0", borderRadius: 10 }}>
                <div style={{ fontSize: 34, fontWeight: 700 }} >{tileData.bookings === 'undefined' ? 0 : tileData.bookings}</div>
                <p style={{ margin: 0 }}>{`Total ${getLocalizedBookingSyntex(country)}s`}</p>
              </div>
              <div className="flex fill col-flex main-center cross-center" style={{ border: "1px solid #f0f0f0", borderRadius: 10 }}>
                <div style={{ fontSize: 34, fontWeight: 700 }} >{tileData.cancellations === 'undefined' ? 0 : tileData.cancellations}</div>
                <p style={{ margin: 0 }}>Total Cancellations</p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={4} style={{ paddingRight: 0 }}>
          <CancellationMonthlyChart />
        </Grid>
      </Grid>
      <div style={{ margin: "40px -30px 20px -30px", padding: "20px 30px", background: "#fff" }}>
      </div>
    </div>
  )
}

export default BookingReports