import { gql } from "@apollo/client";

export const GET_BOOKING_BY_ID = gql`
  query bookingById($bookingId: String!) {
    bookingById(bookingId: $bookingId) {
      id
      initialPickupDateTime
      initialDropoffDateTime
    }
  }
`;
