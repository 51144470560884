import { gql } from "@apollo/client";

export const GET_APP_VEW_TENANCIES = gql`
  query tenancies {
    tenancies {
      id
      name
    }
  }
`;

export const GET_APP_VIEW_ORGANISATION_BY_TENANTID = gql`
  query tenancyOrganisations($tenancyId: ID!) {
    tenancyOrganisations(tenancyId: $tenancyId) {
      id
      name
      logoUrl
      currency
      stripeAccountId
      requiresCustomerVerification
      crossLocationBookingEnabled
      xeroEnabled
      branches {
        id
        name
        timeZone
      }
    }
  }
`;

export const GET_APP_VIEW_ORGANISATIONS = gql`
  query Organisations {
    organisations {
      id
      name
      logoUrl
      currency
      stripeAccountId
      requiresCustomerVerification
      allowCarSharing
      disableMapForCarShareVehicles
      inspectionCheckEnabled
      crossLocationBookingEnabled
      xeroEnabled
      branches {
        id
        name
        timeZone
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      emailId
      phoneNumber {
        phone
        country
      }
      orgVatNumber
      orgRegNumber
      sageAccounting {
        active
      }
    }
  }
`;

export const GET_APP_VIEW_BRANCHES = gql`
  query branches($tenancyId: ID!, $organisationId: ID!) {
    branches(tenancyId: $tenancyId, organisationId: $organisationId) {
      id
      name
      timeZone
      overbookingAllowed
      preBookingQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
      isAdditionalDriverQuestionsEnabled   
      additionalDriverQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
      }
      deliveryQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
      }
      downloadHistorySettings {
        invoiceHistory
      }
      holidays{
        id
        name
        startDate
        endDate
        isActive
      }
      operatingHours{
        day
        dayIndex
        pickupFromTime
        pickupToTime
        returnFromTime
        returnToTime
        closed
      }
      locations{
        id
        name
        address {
          street
          city
          fullAddress
          state
          country
          zipcode
          geometry {
            type
            latitude
            longitude
          }
        }
      }
      taxes{
        id
        title
        value
        rate
        type
      }
      durationBasedPricingEnabled
    }
  }
`;
