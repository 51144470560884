import React from 'react';
import { VehicleUsageCharts } from "./charts/VehicleUsage"
import Grid from '@mui/material/Grid';
import PickupFrequencyChart from './charts/PickupFrequencyChart';
import VehicleAnalytics from './VehicleAnalytics';
import { UserRoles } from '../../../hoc/Authorization';
import { IAppState } from '../../../../store';
import { useSelector } from 'react-redux';
import { allowdRolesForVehicleReports } from '../utils';
interface IProps {
  active: boolean
}

export const ChartsModule: React.FC<IProps> = ({ active }) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  
  return (
    <div style={{ display: active ? 'block' : 'none' }}>
      {allowdRolesForVehicleReports.includes(userState.role) && (
        <Grid container spacing={2}>
          <Grid item md={12}>
            <VehicleUsageCharts />
          </Grid>
          <Grid item md={12}>
            <div>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <PickupFrequencyChart chartFor="PICKUP" />
                </Grid>
                <Grid item md={6}>
                  <PickupFrequencyChart chartFor="DROPOFF" />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
      <div style={{ margin: "40px -30px 20px -30px", padding: "20px 30px", background: "#fff" }}>
        <VehicleAnalytics />
      </div>
    </div>
  )
}