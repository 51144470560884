import { getLocalizedBookingSyntex } from "../../../../../utils/localized.syntex";

export const SettingsMap: any = {
  "Customer Details": "customerDetails",
  "Booking Details": "bookingDetails",
  "Reservation Details": "bookingDetails",
  "Extension Details": "bookingExtension",
  "Driver Details": "driverDetails",
  "Current Vehicle Details": "vehicleDetails",
  "Add-on Details": "addonDetails",
  "Insurance Details": "insuranceDetails",
  "Payment Information": "paymentInfo",
  "Previous Vehicle Details": "oldVehicleDetails",
};

export const RENTAL_COMPANY_DETAILS_LIST = [
  "logoUrl",
  "name",
  "address",
  "phoneNumber",
  "emailId",
  "orgVatNumber",
  "orgRegNumber"
];

export const CUSTOMER_INFO_LIST = [
  "registrationNumber",
  "name",
  "address",
  "phoneNumber",
  "email",
  "businessName",
  "insuranceNumber",
  "dateOfBirth",
  "licenseNumber",
  "licenseExpiry",
  "licenseIssue",
  "licenseIssuedBy",
  "profession"
];

export const BOOKING_DETAILS_LIST = [
  "pickup",
  "dropoff",
  "additionalDrivers",
  "poNumber",
  "referenceNumber",
  "tba"
];

export const VEHICLE_DETAILS_LIST = [
  "makeModel",
  "features",
  "registrationNumber",
  "vin",
  "pcoNumber",
  "mileageLimit",
  "addons",
  "color",
  "year",
  "transmission",
  "numberOfDoors",
  "numberOfSeats",
  "assetTag",
  "vehicleGroup",
  "vehiclePickup",
  "vehicleDropoff"
];

export const DRIVER_DETAILS_LIST = [
  "name",
  "dateOfBirth",
  "phoneNumber",
  "address",
  "email",
  "licenseNumber",
  "licenseExpiry",
  "licenseIssue",
  "licenseIssuedBy",
  "customerPCONumber"
];

export const BOOKING_EXTENSION_DETAILS_LIST = [
  "extensionNumber",
  "referenceNumber",
  "extensionPeriod",
  "invoiceRef",
  "dropOffLocation"
];

export const PAYMENT_INFO_LIST = [
  "rentalAmount",
  "addonAmount",
  "subtotal",
  "taxAmount",
  "totalPayableAmount",
  "amountPaid",
  "amountDue",
  "discountAmount",
  "insuranceAmount"
];

export const OLD_VEHICLE_DETAILS = [
  "makeModel",
  "features",
  "registrationNumber",
  "vin",
  "pcoNumber",
  "mileageLimit",
  "color",
  "year",
  "transmission",
  "numberOfDoors",
  "numberOfSeats",
  "assetTag",
  "vehicleGroup",
  "vehiclePickup",
  "vehicleDropoff"
];

export const ADDON_DETAILS_LIST = ["addonName", "description", "quantity"];

export const INSURANCE_DETAILS_LIST = ["insuranceName", "description", "price"];

export const RentalAgreementSettingsMap: any = (country : string, tba : boolean) => {  
  return {
  customerDetails: {
    registrationNumber: "Account Number",
    name: "Name",
    address: "Address",
    phoneNumber: "Phone",
    email: "Email",
    businessName: "Business Name",
    insuranceNumber: "National Insurance No.",
    dateOfBirth: "DOB",
    licenseNumber: "Driving Licence No",
    licenseExpiry: "Driving Licence Expiry Date",
    licenseIssue: "Driving Licence Issue Date",
    licenseIssuedBy: "Driving Licence Issued by",
    profession: "Profession"
  },
  bookingDetails: {
    pickup: "Pickup details",
    dropoff: tba ? "Renewal details" : "Drop-off details",
    additionalDrivers: "No of additional drivers",
    poNumber: "PO Number",
    referenceNumber: `${getLocalizedBookingSyntex(country)} Ref. No.`,
    tba: "TBA"
  },
  vehicleDetails: {
    makeModel: "Make/ Model",
    features: "Features",
    registrationNumber: "Reg No.",
    vin: "VIN No.",
    pcoNumber: "PCO",
    mileageLimit: "Mileage Limit",
    addons: "Add-ons details",
    color: "Color",
    year: "Year",
    transmission: "Transmission Type",
    numberOfDoors: "Number of Doors",
    numberOfSeats: "Number of seats",
    assetTag: "Asset Tag",
    vehicleGroup: "Vehicle Group",
    vehiclePickup: "Vehicle Pickup date",
    vehicleDropoff: "Vehicle Drop-off date"
  },
  driverDetails: {
    name: "Name",
    dateOfBirth: "DOB",
    phoneNumber: "Phone",
    address: "Address",
    email: "Email",
    licenseNumber: "Driving Licence No",
    licenseExpiry: "Driving Licence Expiry Date",
    licenseIssue: "Driving Licence Issue Date",
    licenseIssuedBy: "Driving Licence Issued by",
    customerPCONumber: "PCO Private Hires no."
  },
  bookingExtension: {
    extensionNumber: "Extension No",
    referenceNumber: "ID",
    extensionPeriod: "Extension Period",
    invoiceRef: "Invoice No",
    dropOffLocation: "New drop-off location"
  },
  paymentInfo: {
    rentalAmount: "Total rental price",
    addonAmount: "Add-on price",
    subtotal: "Subtotal",
    taxAmount: "VAT/TAX",
    totalPayableAmount: "Grand total",
    amountPaid: "Amount paid",
    amountDue: "Amount due",
    discountAmount: "Discount",
    insuranceAmount: "Insurance Price",
  },
  addonDetails: {
    addonName: "Add-on Name",
    description: "Description",
    quantity: "Quantity"
  },
  insuranceDetails: {
    insuranceName: "Insurance Name",
    description: "Description",
    price: "Price"
  },
  oldVehicleDetails: {
    makeModel: "Make/ Model",
    features: "Features",
    registrationNumber: "Reg No.",
    vin: "VIN No.",
    pcoNumber: "PCO",
    mileageLimit: "Mileage Limit",
    color: "Color",
    year: "Year",
    transmission: "Transmission Type",
    numberOfDoors: "Number of Doors",
    numberOfSeats: "Number of seats",
    assetTag: "Asset Tag",
    vehicleGroup: "Vehicle Group",
    vehiclePickup: "Vehicle Pickup date",
    vehicleDropoff: "Vehicle Drop-off date"
  }
}
};