import "./index.scss"
import { useLazyQuery } from "@apollo/client";
import {
  AppBar,
  Button,
  CircularProgress,
  CssBaseline,
  Fab,
  alpha,
  Grid,
  Hidden,
  InputBase,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import SearchIcon from '@mui/icons-material/Search';
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearPriceRule
} from "../../../../../actions/pricerule/actions";
import { GET_PRICE_RULES, GET_PRICE_RULES_COUNT, SEARCH_PRICE_RULES } from "../../../../../graphql/priceRules/getPriceRulesQuery";
import { IPriceRule, PriceRuleOperator } from "../../../../../reducers/priceRule/types";
import { IAppState } from "../../../../../store";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from "../../../../common/utils";
import { addTableState } from "../../../../../actions/tableState/actions";
import { ITable, TableNames } from "../../../../../reducers/tableState/types";
import { SelectableRows } from "../../../../common/NuvvenTable/types";
import PriceRulesVirtualScroller from "./PriceRulesVirtualScroller";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import _ from "lodash";

interface IRow {
  id: string;
  name: string;
  operator: string;
  customers: number;
  vehicleGroups: number;
  status: string;
}

export const columns: any = [
  {
    label: "ID",
    name: "id",
    options: {
      display: false,
      viewColumns: false,
      filter: false
    }
  },
  {
    label: "Rule Name",
    name: "name"
  },
  {
    label: "Price Effect",
    name: "operator"
  },
  {
    label: "Customers",
    name: "customers"
  },
  {
    label: "Vehicle Groups",
    name: "vehicleGroups"
  },
  {
    label: "Status",
    name: "status"
  }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerMargin: {
      marginBottom: "120px"
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(100vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
      marginBottom: theme.spacing(1)
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    }
  })
);

export const PriceRules: React.FC = () => {

  const classes = useStyles();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const [rows, setRows] = useState<IRow[]>([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [priceRulesTable, setPriceRulesTable] = useState<ITable>();
  const priceRulesTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.priceRules
  );
  const [limit, setLimit] = useState<number>(10);
  const [totalPriceRulesCount, setTotalPriceRulesCount] = useState<number>(0);

  const [loadPriceRules, { loading, data }] = useLazyQuery(GET_PRICE_RULES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.priceRules && data.priceRules.length) {
        let oldPriceRules = [...rows]
        let filteredPriceRules = data.priceRules
        if (filteredPriceRules && filteredPriceRules.length) {
          const reshapedPriceRulesData = filteredPriceRules.map((priceRule: IPriceRule) =>
            reshapePriceRuleIntoRows(priceRule)
          )
          reshapedPriceRulesData.forEach((priceRule: any) => {
            if (!oldPriceRules.some((item) => item.id === priceRule.id)) {
              oldPriceRules.push(priceRule)
            }
          });
          setRows(oldPriceRules)
        }
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [searchPriceRules, { loading: searchPriceRulesLoading, data: searchPriceRulesData }] = useLazyQuery(SEARCH_PRICE_RULES, {
    fetchPolicy: "network-only",
    onCompleted: (searchPriceRulesData) => {
      if (searchPriceRulesData && searchPriceRulesData.searchPriceRules) {
        let filteredPriceRules = searchPriceRulesData.searchPriceRules
        if (filteredPriceRules && filteredPriceRules.length) {
          const reshapedPriceRulesData = filteredPriceRules.map((priceRule: IPriceRule) =>
            reshapePriceRuleIntoRows(priceRule)
          )
          setTotalPriceRulesCount(reshapedPriceRulesData.length)
          setRows(reshapedPriceRulesData)
        }
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [priceRulesCount, { data: priceRulesCountData }] = useLazyQuery(GET_PRICE_RULES_COUNT, {
    fetchPolicy: "network-only",
    onCompleted: (priceRulesCountData) => setTotalPriceRulesCount(priceRulesCountData.priceRulesCount),
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (priceRulesTableState) {
      setPriceRulesTable(priceRulesTableState);
      let sortedOrder = returnSortedOrder(priceRulesTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, priceRulesTableState, sortedOrder)
      }
      loadPriceRules({
        variables: {
          limit: priceRulesTableState.rowsPerPage || limit,
          offset: 0
        }
      });
    }
  }, [priceRulesTableState]);

  useEffect(() => {
    if (userState.tenancy) {
      priceRulesCount();
      loadPriceRules({
        variables: {
          limit: priceRulesTableState?.rowsPerPage || limit,
          offset: 0
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    dispatch(clearPriceRule());
  }, []);

  const reshapePriceRuleIntoRows = (priceRule: IPriceRule) => {
    return {
      ...priceRule,
      name: priceRule.name,
      operator: priceRule.operator === PriceRuleOperator.SURGE ? "Increase" : "Decrease",
      customers: priceRule.customers.length || "All",
      vehicleGroups: priceRule.vehicleGroups.length || "All",
      status: priceRule.isActive ? "Active" : "In-Active"
    };
  }

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchPriceRules({
        variables: {
          q: searchText
        }
      })
    }
  }, 1000);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: totalPriceRulesCount,
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: loading || searchPriceRulesLoading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (rowData: string[]) => {
      if (rowData && rowData.length) {
        const rule: any = rows.find(
          (row: IRow) => row.id === rowData[0]
        );
        if (rule && rule.id) {
          navigate(`/update-price-rule?id=${rule.id}`);
        }
      }
    },
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText)
      } else {
        setRows([])
        priceRulesCount()
        loadPriceRules({
          variables: {
            limit,
            offset: 0
          }
        })
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.PRICE_RULES,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      priceRulesTable && priceRulesTable.rowsPerPage ? priceRulesTable.rowsPerPage : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.PRICE_RULES,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: (action: string, tableState: any) => {
      if (priceRulesTable && sortOrder) {
        setTableSortOrder(columns, priceRulesTable, sortOrder);
      }
    },
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return; // Skip executing changePage if there is a search text
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length) {
            loadPriceRules({
              variables: {
                offset: page * rowsPerPage,
                limit: rowsPerPage
              }
            });
          }
          break;
        case "changeRowsPerPage":
          setLimit(tableState.rowsPerPage)
          setRows([])
          loadPriceRules({
            variables: {
              offset: 0,
              limit: priceRulesTable?.rowsPerPage || tableState.rowsPerPage
            }
          });
          break;
        default:
          break;
      }
    },
  };
  const disabledRoles = ["FINANCE_MANAGER", "FLEET_MANAGER"]

  const onChange = (event: { target: { value: any; }; }) => {
    if (event.target.value) {
      handleSearch(event.target.value);
      if (searchPriceRulesData && searchPriceRulesData.searchPriceRules) {
        let filteredPriceRules = searchPriceRulesData.searchPriceRules
        if (filteredPriceRules && filteredPriceRules.length) {
          const reshapedPriceRulesData = filteredPriceRules.map((priceRule: IPriceRule) =>
            reshapePriceRuleIntoRows(priceRule)
          )
          setTotalPriceRulesCount(reshapedPriceRulesData.length)
          setRows(reshapedPriceRulesData)
        }
      }
      if (!event.target.value) {
        setRows([])
        loadPriceRules({
          variables: {
            limit: 10,
            offset: 0
          }
        })
      }
    };
  }
  const handleScroll = (e: any) => {
    const threshold = 15000
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < threshold
    ) {
      loadPriceRules({
        variables: {
          offset: rows.length,
          limit: rows.length
        }
      })
    }
  };

  return <>
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} sm={6}>
        <Typography variant="h1" color={"primary"}>Price Rules{"  "}</Typography>
      </Grid>
      <Hidden smDown>
        <Grid container item xs={6} justifyContent="flex-end">
          <Fab
            variant="extended"
            size="medium"
            aria-label="Create"
            className="createButton"
            onClick={() => {
              dispatch(clearPriceRule());
              navigate("/new-price-rule");
            }}
            disabled={disabledRoles.includes(userState.role)}
          >
            Create
          </Fab>
        </Grid>
      </Hidden>
      <Grid container item xs={12}>
        {!(loading && !rows.length) ? (
          <>
            <Hidden smDown>
              <NuvvenTable
                title={""}
                rows={rows}
                columns={columns}
                options={options}
              />
            </Hidden>
            <Hidden smUp>
              {searchPriceRulesLoading ? (<CircularProgress />) : (
                <div onScroll={handleScroll}>
                  <PriceRulesVirtualScroller data={rows} />
                </div>
              )}
            </Hidden>
          </>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
    <Hidden smUp>
      <AppBar position="fixed" className={classes.appBar}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Button
              className={classes.bottomButton}
              variant="contained"
              aria-label="Create"
              onClick={() => {
                dispatch(clearPriceRule());
                navigate("/new-price-rule");
              }}
            >
              Create
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={onChange}
              />
            </div>
          </Grid>
        </Grid>
      </AppBar>
    </Hidden>
  </>;
}
