import { Grid } from "@mui/material";
import React from "react";
import { useVirtualizer } from '@tanstack/react-virtual';
import MobileBookingCard from "../../../../common/MobileBookingCard";
import { IRows } from "../Bookings";


export const BookingVirtualScroller = ({ data }: { data: IRows[] }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 320
  })

  if (!data.length) {
    return (
      <div>
        <p>No matching record(s) found!</p>
      </div>
    )
  }

  return (
    <>
      {/* Scrollable Parent List  */}
      <Grid item xs={12}
        ref={parentRef}
        className="List"
        style={{
          height: `calc(100vh - 250px)`,
          width: 'calc(100vw - 40px)',
          overflow: 'auto',
        }}
      >
        {/* Large inner element for items */}
        <Grid container item xs={12} spacing={2}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {/* Virtualized Items */}
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <Grid container item xs={12} spacing={2}
              key={virtualItem.key}
              style={{
                position: 'absolute',
                width: '100%',
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <MobileBookingCard
                id={data[virtualItem.index].id}
                referenceNumber={data[virtualItem.index].referenceNumber}
                bookingStatus={data[virtualItem.index].status}
                customerName={data[virtualItem.index].customer}
                pickupDateTime={data[virtualItem.index].pickupDateTime}
                dropoffDateTime={data[virtualItem.index].dropoffDateTime}
                location={data[virtualItem.index].pickupServiceLocation}
                source={data[virtualItem.index].source}
                licencePlateDetails={data[virtualItem.index].licencePlateDetails}
                imgUrls={data[virtualItem.index].imgUrls}
                bodyTypes={data[virtualItem.index].bodyTypes}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default BookingVirtualScroller;