import {
  Grid,
  Theme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Fab,
  CircularProgress
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DateTime as d } from "luxon";
import { BILLING_CYCLE_NAME } from "../../../../../reducers/bookings/types";
import { IInvoice } from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { getLocalizedBookingSyntex, getLocalizedTaxSyntex } from "../../../../../utils/localized.syntex";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { getRateTypeString, getRentalTaxAmount, getTaxesDescription, toCurrency } from "../../../../common/utils";
import { getInvoiceItemTaxValue } from "../../utils";
import { IInvoiceItem, IInvoiceItemType } from "./types";

interface IProps {
  invoice: IInvoice;
  isEditable: boolean;
  loading: boolean;
  setIsEditable(data: boolean): void;
  updateInvoice(items: IInvoiceItem[]): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      border: "solid 1px rgba(224, 224, 224, 1)",
      marginTop: "1rem"
    }
  })
);

const columns = ["#", "Particulars", "Quantity", "Unit Price", "Duration", "VAT/TAX", "Amount"];

export const InvoiceDetails = (props: IProps) => {
  const styles = useStyles();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [values, setValues] = useState<IInvoice>();
  const [invoiceItems, setInvoiceItems] = useState<IInvoiceItem[]>([]);
  const [bookingDurationInDays, setBookingDurationInDays] = useState<number>(0);
  const { invoice } = { ...props };

  useEffect(() => {
    setValues(invoice);
    setBookingDurationInDays(Math.ceil(Math.abs(d.fromISO(invoice.startDate).diff(d.fromISO(invoice.endDate),['days']).days)))
    if (invoice.invoiceItems) {
      setInvoiceItems(JSON.parse(JSON.stringify(invoice.invoiceItems)))
    }
  }, [invoice]);

  const addRows = () => {
    const rowsArr = [...invoiceItems];
    rowsArr.push({
      type: IInvoiceItemType.OTHER_CHARGE,
      description: "",
      unitPrice: 0,
      quantity: 1,
      totalAmount: 0,
      taxRate: 0,
      taxAmount: 0
    });
    setInvoiceItems(rowsArr);
  };

  const handleSubmit = () => {
    if (invoiceItems.some(charge => !charge.description)) {
      return snackbar({
        message: "Invoice item description cannot be empty!",
        variant: SnackBarVariant.ERROR
      });
    }
    if (invoiceItems.some(charge => !charge.unitPrice)) {
      return snackbar({
        message: "Invoice item price/rate should be greater than 0",
        variant: SnackBarVariant.ERROR
      });
    }
    props.updateInvoice(invoiceItems)
  };


  return (
    <Grid container item xs={12}>
      <TableContainer className={styles.tableContainer}>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              {
                columns.map((column: string) => {
                  if (country === "United States" && column === "VAT/TAX") {
                    column = "Taxes & Fees";
                  }
                  return (
                    <TableCell>
                      <Typography variant={"h4"}>{column}</Typography>
                    </TableCell>
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isEditable ? <>
              {invoiceItems?.map((item: IInvoiceItem, idx: number) => {
                let taxAmount = 0;
                if (item.taxAmount) {
                  taxAmount = item.taxAmount;
                }
                return (
                  <TableRow>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>
                      <TextField
                        placeholder="Description"
                        label="Description"
                        name={"description"}
                        inputProps={{
                          value: item.description,
                          onChange: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const invoiceItem = JSON.parse(JSON.stringify(invoiceItems))[idx];
                            const rowsArr = JSON.parse(JSON.stringify(invoiceItems));
                            invoiceItem.description = e.target.value;
                            rowsArr.splice(idx, 1, invoiceItem);
                            setInvoiceItems(rowsArr);
                          }
                        }}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      {item.quantity ? item.quantity : "N/A"}
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        placeholder="Unit Price"
                        label="Unit Price"
                        name={"unitPrice"}
                        InputProps={{
                          value: item.unitPrice,
                          inputComponent: FloatInput as any,
                          onChange: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const val = e.target.value || "0";
                            const invoiceItem = JSON.parse(JSON.stringify(invoiceItems))[idx];
                            const rowsArr = JSON.parse(JSON.stringify(invoiceItems));
                            invoiceItem.unitPrice = parseInt(val);
                            invoiceItem.totalAmount = invoiceItem.unitPrice * (invoiceItem.duration || 1) * invoiceItem.quantity
                            if (invoiceItem.isProrated && invoiceItem.proratedBillingDays) {
                              let standardBillingDays = 0;
                              standardBillingDays = d.fromISO(invoice.startDate).daysInMonth
                              switch (values?.booking.rateTypeName) {
                                case BILLING_CYCLE_NAME.DAILY:
                                  if (values.booking.isRecurringBilling && values.booking.billingFrequency) {
                                    standardBillingDays = values.booking.billingFrequency
                                  }
                                  break;
                                case BILLING_CYCLE_NAME.WEEKLY:
                                  if (values.booking.isRecurringBilling && values.booking.billingFrequency) {
                                    standardBillingDays = 7 * values.booking.billingFrequency
                                  } else {
                                    standardBillingDays = 7
                                  }
                                  break;
                                case BILLING_CYCLE_NAME.MONTHLY:
                                  if (values.booking.isRecurringBilling && values.booking.billingFrequency) {
                                    let idealEndDate = d.fromISO(invoice.startDate).plus({ months: values.booking.billingFrequency }).toUTC().toISO();
                                    standardBillingDays = d.fromISO(idealEndDate).diff(d.fromISO(invoice.startDate)).days
                                  }
                                  break;
                                default:
                                  break;
                              }
                              const proratedFraction = parseFloat((invoiceItem.proratedBillingDays / standardBillingDays).toString())
                              invoiceItem.totalAmount = Math.round(invoiceItem.totalAmount * proratedFraction);
                            }
                            if (invoiceItem.type === IInvoiceItemType.RENTAL_AMOUNT) {
                              invoiceItem.taxAmount = values?.tax ? getRentalTaxAmount(values.tax, bookingDurationInDays, invoiceItem.totalAmount, invoiceItem.quantity) : 0
                            }

                            if (invoiceItem.type === IInvoiceItemType.ADDON) {
                              invoiceItem.taxAmount = invoiceItem.totalAmount * getInvoiceItemTaxValue(invoiceItem.taxDescription[0]) / 100
                            }
                            if (invoiceItem.type === IInvoiceItemType.LOCATION_SURCHARGE) {
                              let surchargetotalPrice = invoiceItem.unitPrice * (invoiceItem.quantity || 1);
                              if (invoiceItem.duration) {
                                surchargetotalPrice = surchargetotalPrice * invoiceItem.duration;
                              }
                              invoiceItem.totalAmount = surchargetotalPrice;
                              invoiceItem.taxAmount = surchargetotalPrice * getInvoiceItemTaxValue(invoiceItem.taxDescription[0]) / 100;
                            }
                            rowsArr.splice(idx, 1, invoiceItem);
                            setInvoiceItems(rowsArr);
                          }
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {item.duration ?
                        item.isProrated ?
                          `${item.duration} ${getRateTypeString(invoice.booking.rateTypeName)} (${item.proratedBillingDays} days pro-rated)` :
                          `${item.duration} ${getRateTypeString(invoice.booking.rateTypeName)}` :
                        "N/A"
                      }
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        placeholder="Tax Amount"
                        label="Tax Amount"
                        name={"taxAmount"}
                        disabled={item.type === IInvoiceItemType.INSURANCE}
                        InputProps={{
                          value: item.taxAmount,
                          inputComponent: FloatInput as any,
                          onChange: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const val = e.target.value || "0";
                            const invoiceItem = JSON.parse(JSON.stringify(invoiceItems))[idx];
                            const rowsArr = JSON.parse(JSON.stringify(invoiceItems));
                            invoiceItem.taxAmount = parseInt(val);
                            rowsArr.splice(idx, 1, invoiceItem);
                            setInvoiceItems(rowsArr);
                          }
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {toCurrency(item.totalAmount + (item.taxAmount || 0), currency, locale)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </> :
              <>
                {values?.invoiceItems?.map((item: IInvoiceItem, idx: number) => {
                  let taxAmount = 0;
                  if (item.taxAmount) {
                    taxAmount = item.taxAmount;
                  }
                  const rentalTaxDetails = getTaxesDescription(values.tax, currency, locale);
                  return (
                    <TableRow>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>
                        {
                          item.type === IInvoiceItemType.RENTAL_AMOUNT ?
                          (values?.booking?.isSubscription ? item.description.toUpperCase() : `Vehicle Rental Charges (${item.description.toUpperCase()})`) :
                            item.type === IInvoiceItemType.DEPOSIT ?
                              `Deposit (${item.description.toUpperCase()}) (Refundable)` :
                              item.type === IInvoiceItemType.INSURANCE ?
                                `Insurance (${item.description.toUpperCase()})` :
                                item.type === IInvoiceItemType.LOCATION_SURCHARGE ?
                                  `Location Surcharge (${item.description.toUpperCase()})` :
                                  item.type === IInvoiceItemType.ADDON ?
                                    `${item.description.toUpperCase()}` :
                                    item.description.toUpperCase()
                        } <br />
                        {
                          item.type === IInvoiceItemType.RENTAL_AMOUNT ?
                            rentalTaxDetails && rentalTaxDetails.length > 0 && rentalTaxDetails.map(title => {
                              return (
                                <>
                                  <span>({title})</span><br></br>
                                </>
                              )
                            }) :
                            item.taxDescription && item.taxDescription.length > 0 &&
                            item.taxDescription.map(title => {
                              return (
                                <>
                                  <span>({title})</span><br></br>
                                </>
                              )
                            })
                        }
                      </TableCell>
                      <TableCell>{item.quantity ? item.quantity : "N/A"}</TableCell>
                      <TableCell>{toCurrency(item.unitPrice, currency, locale)}</TableCell>
                      <TableCell>
                        {item.duration ?
                          item.isProrated ?
                            `${item.duration} ${getRateTypeString(invoice.booking.rateTypeName)} (${item.proratedBillingDays} days pro-rated)` :
                            `${item.duration} ${getRateTypeString(invoice.booking.rateTypeName)}` :
                          "N/A"
                        }
                      </TableCell>
                      <TableCell>
                        {toCurrency(taxAmount, currency, locale)}
                      </TableCell>
                      <TableCell>{toCurrency(item.totalAmount + taxAmount, currency, locale)}</TableCell>
                    </TableRow>
                  )
                })}
                {invoice.discountAmount > 0 &&
                  <TableRow>
                    <TableCell colSpan={4} style={{ borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}>
                      <Typography variant="body1">{"Discount"}</Typography>
                    </TableCell>
                    <TableCell>
                      {"-"}{toCurrency(
                        invoice.discountAmount,
                        currency,
                        locale
                      )}
                    </TableCell>
                  </TableRow>
                }
                <TableRow>
                  <TableCell colSpan={4} style={{ borderBottom: "none" }}></TableCell>
                  <TableCell style={{ borderBottom: "none" }}></TableCell>
                  <TableCell style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}>
                    <Typography variant="body1">{"Total"}</Typography>
                  </TableCell>
                  <TableCell>
                    {toCurrency(
                      invoice.totalPayableAmount,
                      currency,
                      locale
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} style={{ borderBottom: "none" }}></TableCell>
                  <TableCell style={{ borderBottom: "none" }}></TableCell>
                  <TableCell style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}>
                    <Typography variant="body1">{"Paid"}</Typography>
                  </TableCell>
                  <TableCell>
                    {toCurrency(
                      invoice.confirmedPaidAmount,
                      currency,
                      locale
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4} style={{ borderBottom: "none" }}>
                    {invoice.booking?.taxExempted && <Typography variant="h4">
                      {`*This is a ${getLocalizedTaxSyntex(country)} exempted booking.`}
                    </Typography>}
                    {invoice.booking?.zeroCostBooking && <Typography variant="h4">
                      *This is a test/courtesy {getLocalizedBookingSyntex(country).toLowerCase()}.
                    </Typography>}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}></TableCell>
                  <TableCell style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}>
                    <Typography variant="h4">{"Balance Due"}</Typography>
                  </TableCell>
                  <TableCell>
                    {toCurrency(
                      invoice.dueAmount,
                      currency,
                      locale
                    )}
                  </TableCell>
                </TableRow>
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>

      {props.isEditable && <Grid container xs={12} style={{ marginTop: "20px" }}>
        <Grid container xs={1}>
          <Typography
            variant={"subtitle1"}
            onClick={addRows}
            style={{ cursor: "pointer" }}
          >
            + Add New
          </Typography>
        </Grid>
        <Grid container xs={11} justifyContent="flex-end">
          <Fab
            variant="extended"
            size="medium"
            className="blackBackButton"
            style={{ marginRight: "20px" }}
            onClick={() => {
              props.setIsEditable(false)
              setInvoiceItems(values?.invoiceItems || [])
            }}
          >
            Cancel
          </Fab>
          <Fab
            variant="extended"
            size="medium"
            onClick={handleSubmit}
            disabled={props.loading}
          >
            {props.loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
            Update
          </Fab>
        </Grid>
      </Grid>}
    </Grid>
  );
};
