import React, { useEffect, useState } from 'react'
import { ISubscription, ISubscriptionPricing } from '../../../../../../reducers/Subscription/types';
import { Avatar, Box, Checkbox, Dialog, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, IconButton, Paper, Slide, Theme, Tooltip, Typography } from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import { TransitionProps } from '@mui/material/transitions/transition';
import { ISubscriptionInfo } from '../../../../../../reducers/fleet/types';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import AddIcon from '@mui/icons-material/Add';
import { FloatInput } from '../../../../../common/FloatInput/FloatInput';
import { NuvvenDivider } from '../../../../ReservationManagement/Summary/Components/Divider';
import { useSnackBar } from '../../../../../common/SnackBarContext/SnackBarContext';
import { formatGraphQLErrorMessage } from '../../../../../common/utils';
import { SnackBarVariant } from '../../../../../common/SnackbarWrapper/SnackbarWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(2)
    },
    iconStyle: {
      padding: 2
    },
    avatarStyle: {
      background: "var(--theme-accent)",
      height: 25,
      width: 25
    },
    paperStyle: {
      flexGrow: 1
    }
  })
);

interface IProps {
  open: boolean;
  onClose(): void;
  subscription: ISubscription;
  pricings: ISubscriptionPricing[];
  handlePricingUpdate(pricings: ISubscriptionPricing[], deposit: number): void;
  deposit?: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const pricingInfoInitials: ISubscriptionInfo = {
  subscription: {
    id: ""
  },
  pricings: [{
    duration: 0,
    enrollmentAmount: 0,
    mileage: 0,
    subscriptionAmount: 0,
    pricePerExtraMile: 0,
    unlimitedMileage: false,
    swapsAllowed: 0
  }],
  deposit: 0
}
const EditSubscriptionPricingModal: React.FC<IProps> = (props) => {

  const snackbar = useSnackBar();
  const classes = useStyles();
  const [pricingInfo, setPricingInfo] = useState<ISubscriptionInfo>(pricingInfoInitials)


  useEffect(() => {
    if (props.pricings && props.pricings.length > 0) {
      setPricingInfo({
        subscription: {
          id: props.subscription.id
        },
        pricings: props.pricings,
        deposit: props.deposit || 0
      })
    }
  }, [props.pricings])

  const addPricingToPricingInfo = () => {
    setPricingInfo({
      ...pricingInfo,
      pricings: [
        ...pricingInfo.pricings,
        {
          duration: 0,
          enrollmentAmount: 0,
          mileage: 0,
          subscriptionAmount: 0,
          pricePerExtraMile: 0,
          unlimitedMileage: false,
          swapsAllowed: 0
        }
      ]
    })
  }

  const onChangeEnrollmentAmount = (event: React.ChangeEvent<HTMLInputElement>, pricingIndex: number) => {
    const value = parseInt(event.target.value);
    setPricingInfo((previousValues) => (
      {
        ...previousValues,
        pricings: [
          ...previousValues.pricings.slice(0, pricingIndex),
          { ...previousValues.pricings[pricingIndex], enrollmentAmount: value },
          ...previousValues.pricings.slice(pricingIndex + 1)
        ]
      }
    ));
  }

  const onChangeDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setPricingInfo(
      {
        ...pricingInfo,
        deposit: value
      });
  }

  const onChangeSubscriptionAmount = (event: React.ChangeEvent<HTMLInputElement>, pricingIndex: number) => {
    const value = parseInt(event.target.value);
    setPricingInfo((previousValues) => (
      {
        ...previousValues,
        pricings: [
          ...previousValues.pricings.slice(0, pricingIndex),
          { ...previousValues.pricings[pricingIndex], subscriptionAmount: value },
          ...previousValues.pricings.slice(pricingIndex + 1)
        ]
      }
    ));
  }

  const onChangePricePerExtraMile = (event: React.ChangeEvent<HTMLInputElement>, pricingIndex: number) => {
    const value = parseInt(event.target.value);
    setPricingInfo((previousValues) => (
      {
        ...previousValues,
        pricings: [
          ...previousValues.pricings.slice(0, pricingIndex),
          { ...previousValues.pricings[pricingIndex], pricePerExtraMile: value },
          ...previousValues.pricings.slice(pricingIndex + 1)
        ]
      }
    ));
  }

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onClose}
        aria-labelledby="pricing-edit-dialog"
        aria-describedby="pricing-edit-fields"
        maxWidth={"md"}
        style={{ flexGrow: 1 }}
        classes={{
          paper: classes.paperStyle
        }}
      >
        <DialogTitle id="pricing-edit-dialog"><Typography variant='h3'>{props.subscription.name}</Typography></DialogTitle>
        <DialogContent>
          <Grid container item xs={12}>
            <Grid item xs={6}><Typography variant='subtitle1'>Plans Includes</Typography></Grid>
            <Grid item xs={6} container justifyContent='flex-end'>
              <IconButton
                onClick={() => {
                  addPricingToPricingInfo()
                }}
                className={classes.iconStyle}
                aria-label="add-icon"
                size="large">
                <Tooltip title="Add Pricing" aria-label="add">
                  <Avatar className={classes.avatarStyle} ><AddIcon /></Avatar>
                </Tooltip>
              </IconButton>
            </Grid>
            <Paper elevation={0} className={classes.root}>
              <Formik
                enableReinitialize
                initialValues={pricingInfo}
                onSubmit={(values, { setSubmitting }) => {
                  const durationArray = pricingInfo.pricings.map(pricing => pricing.duration)
                  const durationAlreadyExist = durationArray.some((item, idx) => {
                    return durationArray.indexOf(item) !== idx
                  });
                  if (durationAlreadyExist) {
                    return snackbar({
                      message: formatGraphQLErrorMessage("Duration already selected please select different duration"),
                      variant: SnackBarVariant.ERROR
                    })
                  } else {
                    props.handlePricingUpdate(values.pricings, values.deposit)
                  }
                  setSubmitting(false)
                }}
              >
                {() => (
                  <Form>
                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h3">Security Deposit</Typography>
                        <Box mt={1}></Box>
                        <Grid item xs={6}>
                          <Field
                            component={TextField}
                            fullWidth
                            placeholder="Enter deposit"
                            label="Deposit Amount(Refundable)"
                            required
                            name={"deposit"}
                            InputProps={{
                              value: pricingInfo.deposit,
                              inputComponent: FloatInput as any,
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                onChangeDeposit(e)
                              }
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                          ></Field>
                        </Grid>
                      </Grid>
                      {
                        pricingInfo.pricings &&
                        pricingInfo.pricings.length > 0 &&
                        pricingInfo.pricings.map((pricing: ISubscriptionPricing, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <Grid item xs={4}>
                                <Field
                                  component={TextField}
                                  name={`${pricingInfo.pricings[index]}.duration`}
                                  label={`Duration (In ${props.subscription.rateTypeName === "weekly" ? "Weeks" : "Months"})`}
                                  fullWidth
                                  required
                                  type={"number"}
                                  inputProps={{
                                    value: pricing.duration,
                                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                      setPricingInfo({
                                        ...pricingInfo,
                                        pricings: [
                                          ...pricingInfo.pricings.slice(0, index),
                                          {
                                            ...pricingInfo.pricings[index],
                                            duration: parseInt(e.target.value)
                                          },
                                          ...pricingInfo.pricings.slice(index + 1)
                                        ]
                                      })
                                    }

                                  }}
                                >
                                </Field>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  placeholder="Enter price for selected duration"
                                  label="Enrollment Amount(One time cost)"
                                  name={`${pricingInfo.pricings[index]}.enrollmentAmount`}
                                  InputProps={{
                                    value: pricing.enrollmentAmount,
                                    inputComponent: FloatInput as any,
                                    onChange: (
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      onChangeEnrollmentAmount(e, index)
                                    }
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                ></Field>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  placeholder="Enter price for selected duration"
                                  label={`Subscription Amount(Per ${props.subscription.rateTypeName === "weekly" ? "week" : "month"} cost)`}
                                  name={`${pricingInfo.pricings[index]}.subscriptionAmount`}
                                  InputProps={{
                                    value: pricing.subscriptionAmount,
                                    inputComponent: FloatInput as any,
                                    onChange: (
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      onChangeSubscriptionAmount(e, index)
                                    }
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                  required
                                ></Field>
                              </Grid>
                              <Grid item xs={4}>
                                <Field
                                  component={TextField}
                                  name={`${pricingInfo.pricings[index]}.mileage`}
                                  label="Mileage"
                                  fullWidth
                                  required
                                  type={"number"}
                                  disabled={pricing.unlimitedMileage}
                                  inputProps={{
                                    value: pricing.mileage,
                                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                      setPricingInfo({
                                        ...pricingInfo,
                                        pricings: [
                                          ...pricingInfo.pricings.slice(0, index),
                                          {
                                            ...pricingInfo.pricings[index],
                                            mileage: parseInt(e.target.value)
                                          },
                                          ...pricingInfo.pricings.slice(index + 1)
                                        ]
                                      })
                                    }
                                  }}
                                ></Field>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  placeholder="Enter price for extra mile"
                                  label="Price Per Extra Mile"
                                  required
                                  name={`${pricingInfo.pricings[index]}.pricePerExtraMile`}
                                  disabled={pricing.unlimitedMileage}
                                  InputProps={{
                                    value: pricing.pricePerExtraMile,
                                    inputComponent: FloatInput as any,
                                    onChange: (
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      onChangePricePerExtraMile(e, index)
                                    }
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                ></Field>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={pricingInfo.pricings[index].unlimitedMileage}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setPricingInfo({
                                          ...pricingInfo,
                                          pricings: [
                                            ...pricingInfo.pricings.slice(0, index),
                                            {
                                              ...pricingInfo.pricings[index],
                                              unlimitedMileage: e.target.checked,
                                              mileage: 0,
                                              pricePerExtraMile: 0
                                            },
                                            ...pricingInfo.pricings.slice(index + 1)
                                          ]
                                        })
                                      }}
                                      name={`${pricingInfo.pricings[index]}.unlimitedMileage`}
                                      color="secondary"
                                    />
                                  }
                                  label="Unlimited Mileage"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Field
                                  component={TextField}
                                  name={`${pricingInfo.pricings[index]}.swapsAllowed`}
                                  label="Swaps Allowed"
                                  fullWidth
                                  type={"number"}
                                  inputProps={{
                                    value: pricing.swapsAllowed,
                                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                      setPricingInfo({
                                        ...pricingInfo,
                                        pricings: [
                                          ...pricingInfo.pricings.slice(0, index),
                                          {
                                            ...pricingInfo.pricings[index],
                                            swapsAllowed: parseInt(e.target.value)
                                          },
                                          ...pricingInfo.pricings.slice(index + 1)
                                        ]
                                      })
                                    }
                                  }}
                                ></Field>
                              </Grid>
                              <Grid item xs={12}><NuvvenDivider /></Grid>
                            </React.Fragment>
                          )
                        })
                      }
                    </Grid>
                    <Grid item xs={12} container justifyContent='flex-end'>
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="submit"
                        type="submit"
                      >
                        Save Pricings
                      </Fab>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </DialogContent>
      </Dialog>
    </div >
  );
}
export default EditSubscriptionPricingModal