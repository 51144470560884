export interface IAddonTypeState {
  addonType: IAddonType;
}

export interface IAddonType {
  id?: string;
  name: string;
  category: string;
  displayName: string;
  imageUrl: string;
  hasFixedRate: boolean;
  hasQuantity: boolean;
  fixedRate?: number;
  description: string;
  recurringRate?: IAddonRate[];
  totalQuantity?: number;
  availableQuantity?: number;
  isActivated: boolean;
  taxable: boolean;
  tax: IAddOnTaxInput;
  rules?: IRange[];
}

export interface IRange {
  from: number;
  to: number;
  amount: number;
}
export interface IAddOnTaxInput {
  id?: string;
  title: string;
  type: string;
  value: number;
}
export interface IAddonRate {
  rateTypeName: string;
  rateTypeDuration: number;
  rate: number;
  longTerm: boolean;
}

export type AddonPayloadType = IAddonType;

export enum DefaultAddons {
  VALET = "VALET",
  GPS = "GPS",
  BABY_SEAT = "BABY_SEAT",
  ADDITIONAL_DRIVER = "ADDITIONAL_DRIVER",
  OTHER = "OTHER",
}

export enum AddonTypes {
  SERVICE = "SERVICE",
  PRODUCT = "PRODUCT"
}
