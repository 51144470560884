import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

interface IProps {
  title: string;
  children: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightBold
    },
    expansionSummary: {
      padding: "0px 10px"
    },
    expansionPannelDetails: {
      padding: "10px 10px"
    }
  })
);

export const NuvvenExpansionPanel = (props: IProps) => {
  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className={classes.expansionSummary}
      >
        <Typography variant="h4">{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionPannelDetails}>
        <div style={{ width: "100%" }}>{props.children}</div>
      </AccordionDetails>
    </Accordion>
  );
};
