import "./index.scss";
import React from "react";

interface IProps {
  handleConfirmDeleteBtn: Function;
  open: boolean;
  handleToggle: Function;
}
export default class ConfirmDeleteModal extends React.Component<IProps> {
  render() {
    const { handleConfirmDeleteBtn, open, handleToggle } = this.props;

    return (
      <div className="delete-popup-root">
        {open && (
          <div className="delete-modal-container">
            <div className="delete-modal-header-icon">
              <img
                src={require("./../../../assets/icons/cross.svg")}
                onClick={() => handleToggle()}
                alt="Cross Icon"
              />
            </div>
            <div className="delete-modal-header-title">
              <p>Delete Vehicle</p>
            </div>
            <div className="delete-modal-body">
              <p>Are you sure you wat to delete this Vehicle?</p>
            </div>
            <div className="delete-modal-footer">
              <button onClick={() => handleConfirmDeleteBtn()}>Confirm</button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
