import { useLazyQuery } from "@apollo/client";
import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from "react-redux";

import App from "./App";
import { setTableState } from "./actions/tableState/actions";
import { updateUser } from "./actions/user/actions";
import HttpHeader from "./header";
import { SnackBarProvider } from "./components/common/SnackBarContext/SnackBarContext";
import { GET_USER } from "./graphql/user/getUser";
import { IAppState } from "./store";
import theme from './components/common/Theme';
import Authenticator from "./components/views/Auth"
import { getEvaporateClient } from './utils/evaporate';
import { getAccent, getDark } from "./utils/colours";
import { setAuthEvaporateClientAction } from './actions/auth/actions';
import { CircularProgress, ThemeProvider } from '@mui/material';
import { Settings, DateTime as d } from 'luxon';
import * as Sentry from "@sentry/react";
import { ApolloError } from "@apollo/client";
import HotglueConfig from '@hotglue/widget';
import { IOrganisation, ITenancy } from "./reducers/user/types";
import { IBranch } from "./reducers/organisation/types";

const httpHeader = HttpHeader.getInstance();

export const history = createBrowserHistory();
export const AppWithAuth = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [getUser, { data: userData }] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      // snackbar({
      //   message: formatGraphQLErrorMessage(error.message),
      //   variant: SnackBarVariant.ERROR
      // });
      console.log("from onError")
      httpHeader.resetHeaders();
      localStorage.clear();
      window.location.replace("/");
    }
  });

  useEffect(() => {
    if (userData && userData.user) {
      ReactGA.set({ userId: userData.user.id });
      const localTableState = localStorage.getItem("tablesState");


      const currentTenancy: ITenancy = userData.user.currentTenancy || userData.user.tenancy
      const currentOrganisation: IOrganisation = userData.user.currentOrganisation || userData.user.organisation
      const currentBranch: IBranch = userData.user.currentBranch || userData.user.branch

      dispatch(updateUser({
        ...userData.user,
        currentTenancy: currentTenancy,
        currentOrganisation: currentOrganisation,
        currentBranch: currentBranch
      }));
      httpHeader.setHeaders({
        tenantId: userData && userData.user.currentTenancy ? userData.user.currentTenancy?.id : userData.user.tenancy.id,
        organisationId: userData.user.currentOrganisation.id,
        branchId: userData.user.currentBranch.id,
        userId: userData.user.id
      });
      Settings.defaultZone = userData.user.currentBranch.timeZone
      if (localTableState) {
        const localTableStateData = JSON.parse(localTableState);
        dispatch(setTableState(localTableStateData));
      }
      Sentry.setContext("org", {
        userId: userData.user.id,
        tenancyName: currentTenancy.id,
        organisationName: currentOrganisation.id,
        branchName: currentBranch.id
      });
    }
  }, [userData]);

  useEffect(() => {
    const rootEl: HTMLElement | null = document.querySelector(':root');
    if (userState?.currentTenancy?.themeColor) {
      rootEl?.style.setProperty('--theme-accent', userState?.currentTenancy?.themeColor);
      rootEl?.style.setProperty('--theme-primary', getAccent(userState?.currentTenancy?.themeColor));
      rootEl?.style.setProperty('--theme-primary-dark', getDark(userState?.currentTenancy?.themeColor));
    }
    else {
      rootEl?.style.setProperty('--theme-accent', "#002F6E");
      rootEl?.style.setProperty('--theme-primary', "#FFC700");
      rootEl?.style.setProperty('--theme-primary-dark', "#F78500");
    }
  }, [userState.currentTenancy.themeColor])


  const appTheme = {
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.primary,
        main: userState?.currentTenancy?.themeColor || "#002F6E"
      },
      secondary: {
        ...theme.palette.secondary,
        main: userState?.currentTenancy?.themeColor || "#002F6E"
      }
    }
  }

  return (
    <Authenticator
      onSignIn={(user) => {
        if (user._id) {
          getUser({
            variables: {
              id: user._id
            }
          });
          getEvaporateClient().then((_e_) => {
            dispatch(setAuthEvaporateClientAction({ _e_ }))
          })
        }
      }}
    >
      <SnackBarProvider>
        <HotglueConfig
          config={{
            apiKey: process.env.REACT_APP_HOTGLUE_API_KEY || "oFqcIP7mEj2xXyLaMgdoA5FZBqRouJa356pNxoEJ",
            envId: process.env.REACT_APP_HOTGLUE_EXTERNAL_ENV || 'dev.hotglue.coastr.com'
          }}
        >
          <ThemeProvider theme={appTheme}>
            {(userState.tenancy &&
              userState.tenancy.id &&
              userState.currentOrganisation.id &&
              userState.currentBranch.id) ?
              <App /> :
              <div style={{ height: 400 }} className="flex cross-center main-center"><CircularProgress /></div>}
          </ThemeProvider>
        </HotglueConfig>
      </SnackBarProvider>
    </Authenticator>
  )

};
