import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Checkbox,
  CircularProgress,
  Fab,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from '@mui/icons-material/Done';
import React, { useEffect, useState } from "react";
import { GENERATE_INVOICES_CSV } from "../../../../graphql/reports/generateInvoicesCsvQuery";
import { CREATE_NEW_TEMPLATE } from "../../../../graphql/reports/createNewTemplate";
import { UPDATE_TEMPLATE } from "../../../../graphql/reports/updateTemplate";
import { ExtractTypes, InvoiceExtractFields, LocalizeInvoiceExtractMap } from "../utils";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../../common/utils";
import FlatPickerBar from "../../../common/FlatPicker";
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { MUIDataTableOptions } from "mui-datatables";
import { CREATE_INVOICE_FINANCE_HISTORY } from "../../../../graphql/reports/createInvoiceFinanceHistory";
import { GET_INVOICE_FINANCE_HISTORY } from "../../../../graphql/reports/getInvoiceFinanceHistory";
import { IDownloadHistoryType } from "../../../../reducers/bookings/types";
import { UPDATE_INVOICE_FINANCE_DOWNLOAD_HISTORY } from "../../../../graphql/reports/updateInvoiceHistorySettings";
import { DateTime as d } from "luxon";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import { GET_INVOICE_CSV_TEMPLATES } from "../../../../graphql/reports/getTemplates";
import AddOnsReport from "../Revenue/RevenueReport";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";

export const columns: any = (country: string) => {
  return [
    {
      label: "User Name",
      name: "userName"
    },
    {
      label: "Email",
      name: "email"
    },
    {
      label: "Date From",
      name: "rangeFrom",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Date Till",
      name: "rangeTo",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Download Date",
      name: "dowloadDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Template",
      name: "templateName"
    }
  ]
}
interface IProps {
  active: boolean
}

interface CsvTemplateField {
  key: string
  label: string
}

const FinanceReports: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [extractType, setExtractType] = useState<string>(ExtractTypes.CUSTOM);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState<any[]>([]);
  const [createTemplate, setCreateTemplate] = useState<boolean>(false);
  const [editField, setEditField] = useState<string>("");
  const [title, setTitle] = useState<string>(""); 
  const [templateId, setTemplateId] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [editTemplate, setEditTemplate] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const InvoiceExtractMap: any = LocalizeInvoiceExtractMap(country);
  const [fieldMap, setFieldMap] = useState<any>({ ...InvoiceExtractMap });

  const [
    getInvoiceFinanceHistory,
    { loading: loadingHistory, data: invoiceFinanceData }
  ] = useLazyQuery(GET_INVOICE_FINANCE_HISTORY, {
    fetchPolicy: "network-only"
  });

  const [
    getAllTemplates,
    { data: templatesData }
  ] = useLazyQuery(GET_INVOICE_CSV_TEMPLATES, {
    fetchPolicy: "network-only"
  });

  const [createInvoiceFinanceHistory] = useMutation(CREATE_INVOICE_FINANCE_HISTORY, {
    onCompleted: (data) => {
      snackbar({
        message: "Invoice finance history saved successfully",
        variant: SnackBarVariant.SUCCESS
      });
      if (data) {
        updateInvoiceFinanceHistory({
          variables: {
            organisationId: userState.currentOrganisation.id,
            branchId: userState.currentBranch.id,
            downloadHistorySettings: {
              invoiceHistory: selectedFields
            }
          }
        })
        getInvoiceFinanceHistory({
          variables: {
            type: IDownloadHistoryType.INVOICE_FINANCE_HISTORY
          }
        })
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateInvoiceFinanceHistory] = useMutation(UPDATE_INVOICE_FINANCE_DOWNLOAD_HISTORY, {
    onCompleted: () => {
      snackbar({
        message: "Download history settings updated successfully.",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });


  const [createCSVTemplate, { loading: createTemplateLoading }] = useMutation(CREATE_NEW_TEMPLATE, {
    onCompleted: () => {
      getAllTemplates();
      setCreateTemplate(false)
      snackbar({
        message: "New CSV Template created successfully.",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateTemplate, { loading: updateTemplateLoading }] = useMutation(UPDATE_TEMPLATE, {
    onCompleted: () => {
      getAllTemplates();
      setEditTemplate(false);
      setTitle("");
      snackbar({
        message: "Template updated successfully.",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [generateInvoicesCsv] = useLazyQuery(GENERATE_INVOICES_CSV, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.generateInvoicesCsv?.key) {
        const fileName = `Invoice_extract_${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
        const file = await getSignedUrl(data.generateInvoicesCsv.key);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
          });
        createInvoiceFinanceHistory({
          variables: {
            history: {
              dowloadDate: d.now().toUTC().toISO(),
              email: userState.email,
              fields: selectedFields,
              fileName,
              userName: `${userState.firstName} ${userState.lastName}`,
              type: IDownloadHistoryType.INVOICE_FINANCE_HISTORY,
              rangeFrom: d.fromISO(fromDate).toUTC().toISO(),
              rangeTo: d.fromISO(toDate).toUTC().toISO(),
              templateName: selectedTemplate
            }
          }
        })
        setLoading(false);
      } else {
        setLoading(false);
        snackbar({
          message: "No invoices found for given date range",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
  }, [templatesData])

  useEffect(() => {
    if (userState && userState.currentOrganisation) {
      getAllTemplates()
      getInvoiceFinanceHistory({
        variables: {
          type: IDownloadHistoryType.INVOICE_FINANCE_HISTORY
        }
      })
      setSelectedFields([])
      setFromDate("")
      setToDate("")
    }
  }, [userState, userState.currentOrganisation]);

  useEffect(() => {
    if (userState && userState.currentOrganisation?.sageAccounting?.active) {
      setExtractType(ExtractTypes.SAGE_COMPATIBLE)
    }
  }, [userState, userState.currentOrganisation?.sageAccounting])

  useEffect(() => {
    if (props.active) {
      getInvoiceFinanceHistory({
        variables: {
          type: IDownloadHistoryType.INVOICE_FINANCE_HISTORY
        }
      })
    }
  }, [props.active])

  useEffect(() => {
    if (invoiceFinanceData && invoiceFinanceData.downloadedHistryRecords) {
      setRows(invoiceFinanceData.downloadedHistryRecords)
    }
  }, [invoiceFinanceData])

  const handleChange = (e: React.ChangeEvent<{ value: any }>) => {
    let fields = [...selectedFields];
    if (fields.includes(e.target.value)) fields = fields.filter(x => x !== e.target.value);
    else fields.push(e.target.value);
    setSelectedFields(fields)
  }

  const handleSave = (field: string) => {
    if (field = "") return;
    else setEditField("");
  }

  const handleEdit = (field: string) => {
    setEditField(field);
  }

  const handleFieldNameChange = (event: any, field: string) => {
    let invoiceFieldMap = fieldMap;
    invoiceFieldMap[field] = event.target.value;
    setFieldMap(invoiceFieldMap);
  }

  const handleSaveChanges = () => {
    let fields: CsvTemplateField[] = [];
    selectedFields.forEach(field => {
      fields.push({ key: field, label: fieldMap[field] })
    })

    if (fields) {
      if (!editTemplate) {
        createCSVTemplate({
          variables: {
            title,
            fields
          }
        })
      } else {
        updateTemplate({
          variables: {
            templateId,
            title,
            fields
          }
        })
      }
    }
  }

  const handleCreateTemplate = () => {
    setTitle("");
    setSelectedFields([]);
    setFieldMap({ ...InvoiceExtractMap })
    setCreateTemplate(true);
  }

  const handleTemplateSelection = (id: string) => {
    setExtractType(ExtractTypes.CUSTOM);
    setTemplateId(id);
  }

  const handleEditTemplate = (e: any, template: any) => {
    e.preventDefault();
    setEditTemplate(true);

    let selectedFields: string[] = [];
    template?.fields?.forEach((arr: CsvTemplateField) => {
      selectedFields.push(arr.key)
    })
    setSelectedFields(selectedFields)
  }

  const calculateMaxToDate = (fromDate: string) => {
    const todayEnd = d.now().endOf("day");
    const maxToDate = d.fromISO(fromDate).plus({ days: 30 });
    if (maxToDate > todayEnd) {
      return todayEnd.toUTC().toISO();
    }
    return maxToDate.toUTC().toISO();
  }

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    filter: false,
    print: false,
    search: false,
    download: false,
    viewColumns: false,
    elevation: 0
  };

  return (
    <div style={{ display: props.active ? 'block' : 'none' }}>
      <Grid container spacing={2}>
        <Grid container xs={12}>
          <Paper style={{ width: "100%", padding: "1rem" }}>
            <AddOnsReport />
            <Grid container xs={12}>
              <Grid item xs={9}>
                <Typography variant="h2">
                  Download Invoice Reports
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ marginTop: "1rem" }} spacing={2}>
              <Grid item xs={3}>
                <FlatPickerBar
                  enableTime={false}
                  handleDateChange={(value: Date) => {
                    const timeZoneOffset = d.now().offset;
                    const isPositiveOffset = timeZoneOffset >= 0; 
                    if (isPositiveOffset) {
                      setFromDate(d.fromJSDate(value).toUTC().plus({minutes: timeZoneOffset}).toISO());
                    } else {
                      setFromDate(d.fromJSDate(value).toUTC().minus({minutes: timeZoneOffset}).toISO());
                    }
                  }}
                  label={"From Date"}
                  placeholderValue={"Select From Date"}
                  value={fromDate ? getLocalizedDateFormat(country, fromDate, DATE_TYPE.CONDENSED) : ""}
                  maxDate={getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}
                  country={country}
                />
              </Grid>
              <Grid item xs={3}>
                <FlatPickerBar
                  enableTime={false}
                  handleDateChange={(value: Date) => {
                    setToDate(d.fromJSDate(value).endOf("day").toUTC().toISO())
                  }}
                  label={"To Date"}
                  placeholderValue={"Select To Date"}
                  value={toDate ? getLocalizedDateFormat(country, toDate, DATE_TYPE.CONDENSED) : ""}
                  maxDate={fromDate ? calculateMaxToDate(fromDate) : "today"}
                  country={country}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-label">Template</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedTemplate}
                    renderValue={(data: any) => <Typography>{data}</Typography>}
                    label="template"
                    onChange={(event: any) => {
                      setSelectedTemplate(event.target.value)
                      setTitle(event.target.value)
                    }}
                  >
                    {userState.currentOrganisation?.sageAccounting?.active &&
                      <MenuItem onClick={() => setExtractType(ExtractTypes.SAGE_COMPATIBLE)} value="Sage Compatible" style={{ position: 'relative' }}>
                        <Typography> Sage Compatible </Typography>
                      </MenuItem>
                    }
                    {templatesData?.csvTemplates?.length &&
                      templatesData.csvTemplates.map((temp: any) => {
                        return (
                          <MenuItem key={temp.id} value={temp.title} onClick={() => handleTemplateSelection(temp.id)} style={{ position: 'relative' }}>
                            <Typography style={{ wordWrap: 'break-word', width: "80%" }}>{temp.title}</Typography>
                            <IconButton
                              style={{ position: 'absolute', right: '1rem' }}
                              title="Edit Template"
                              size="large">
                              <EditIcon style={{ fontSize: '15px' }} onClick={(e) => handleEditTemplate(e, temp)} />
                            </IconButton>
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Fab
                  variant={"extended"}
                  size="medium"
                  onClick={handleCreateTemplate}
                >
                  New Template
                </Fab>
              </Grid>
            </Grid>

            {(createTemplate || editTemplate) &&
              <>
                <Dialog
                  fullWidth={true}
                  open={true}
                  onClose={() => {
                    setCreateTemplate(false);
                    setEditTemplate(false);
                    setSelectedTemplate("");
                  }}
                >
                  <DialogTitle id="template-title" style={{ padding: "20px 24px 10px" }}><Typography variant="h3">{editTemplate ? 'Update CSV Template' : 'Create New CSV Template'}</Typography></DialogTitle>
                  <DialogContent>
                    <Grid container xs={12}>
                      <Typography style={{ marginTop: "0.5rem" }} variant="h4">Title: </Typography>
                      <Input
                        style={{ marginLeft: "1rem" }}
                        onChange={(e) => { setTitle(e.target.value); setSelectedTemplate("") }}
                        value={editTemplate ? (selectedTemplate ? selectedTemplate : title) : title}></Input>
                    </Grid>
                    <Grid container xs={12} style={{ marginTop: "1rem" }} alignItems="center">
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Typography id="demo-mutiple-checkbox-label" style={{ margin: "0.5rem" }} variant="h4">Fields to extract: </Typography>
                          <Paper style={{ width: 290, height: 320, overflow: 'auto' }}>
                            {InvoiceExtractFields.map((field: string) => (
                              <MenuItem key={'field'} value={field}>
                                <Checkbox checked={selectedFields.indexOf(field) > -1} value={field} onChange={handleChange} />
                                {editField == field ?
                                  <Input onChange={(e) => handleFieldNameChange(e, field)} multiline defaultValue={fieldMap[field]} placeholder={InvoiceExtractMap[field]}></Input> :
                                  <ListItemText primary={fieldMap[field]} primaryTypographyProps={{ style: { whiteSpace: "normal" } }} />
                                }

                                {editField == field ?
                                  <IconButton key={field} onClick={() => handleSave(field)} size="large">
                                    <DoneIcon fontSize="small" />
                                  </IconButton>
                                  :
                                  <IconButton key={field} onClick={() => handleEdit(field)} size="large">
                                    <EditIcon style={{ fontSize: '15px' }} />
                                  </IconButton>}
                              </MenuItem>
                            ))}
                          </Paper>
                          {/* </Select> */}
                        </FormControl>
                      </Grid>
                      <Grid item xs={5} style={{ marginLeft: '2.5rem' }}>
                        <Typography variant="h4" style={{ margin: '0.5rem' }}>Selected Fields:</Typography>
                        <Paper style={{ width: 230, height: 320, overflow: 'auto' }}>
                          {selectedFields &&
                            selectedFields.map((field: string) => (
                              <MenuItem style={{ alignItems: "center", marginTop: '0.5rem' }} divider={true}>
                                <ListItemText primary={fieldMap[field]} primaryTypographyProps={{ style: { whiteSpace: "normal" } }} />
                              </MenuItem>
                            ))}
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: "1rem", float: 'right' }} item xs={12}>
                      <Fab
                        variant={"extended"}
                        size="medium"
                        onClick={handleSaveChanges}
                        disabled={createTemplateLoading || !title || !selectedFields.length || updateTemplateLoading}
                      >
                        {(createTemplateLoading || updateTemplateLoading) && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                        {editTemplate ? 'Update' : 'Save'}
                      </Fab>
                    </Grid>
                  </DialogContent>
                </Dialog>
              </>
            }
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              <Fab
                variant={"extended"}
                size="medium"
                disabled={loading || !fromDate || !toDate || !selectedTemplate}
                onClick={() => {
                  (extractType === ExtractTypes.CUSTOM) ?
                    generateInvoicesCsv({
                      variables: {
                        fromDate,
                        toDate,
                        isCustom: true,
                        templateId
                      }
                    }) :
                    generateInvoicesCsv({
                      variables: {
                        fromDate,
                        toDate,
                        isCustom: false,
                      }
                    })
                  setLoading(true);
                }}
              >
                {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                Download
              </Fab>
            </Grid>
            <Box mt={2}></Box>
            <Grid item xs={12}>
              <Typography variant={"h2"}>Download History</Typography>
              <Box mt={2}></Box>
              {
                !loadingHistory ? (
                  <Paper variant="outlined">
                    <NuvvenTable
                      title={""}
                      rows={rows}
                      columns={columns(country)}
                      setSelection={setSelection}
                      options={options}
                    />
                  </Paper>
                ) : (
                  <CircularProgress />
                )
              }
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default FinanceReports;