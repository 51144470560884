import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HttpHeader from "../../../header";
import { IAppState } from "../../../store";
import styles from "./index.module.css";
import { ResetPassword, SetPassword, SignIn, VerifyOTP } from "./AuthPages";
import { AuthStates } from "../../../reducers/auth/types";
import { updateAuthStateAction } from '../../../actions/auth/actions';

interface IProps {
  children: React.ReactNode;
  onSignIn: (user: any, token: string) => void
}

export const history = createBrowserHistory();

const httpHeader = HttpHeader.getInstance();

const Authenticator: React.FC<IProps> = (props) => {
  const dispatch = useDispatch()
  const authState = useSelector((state: IAppState) => state.authReducer);

  const signIn = (user: any, token: string) => {
    localStorage.setItem("auth", JSON.stringify({ state: "SIGNED_IN", token, user }));
    httpHeader.setHeaders({ authorization: token });
    dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_IN, user, token }));
    props.onSignIn(user, token);
  }

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const authObject = JSON.parse(auth);
      if (authObject.state === "SIGNED_IN" && authObject.user && authObject.token) {
        signIn(authObject.user, authObject.token)
      }
      else if (authObject.state === "SET_PASSWORD" && authObject.user && authObject.token) {
        dispatch(updateAuthStateAction({ state: AuthStates.SET_PASSWORD, user: authObject.user, token: authObject.token }));
      }
      else {
        dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }));
      }
    }
    else {
      dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }));
    }
  }, [])

  const handleSignIn = (result: string, user: any, token?: string) => {
    if (result === "OTP_SENT") {
      dispatch(updateAuthStateAction({ state: AuthStates.OTP_SENT, user, message: "Due to some security related changes, you are required to reset your password after 4 Nov 21. Please verify OTP sent to your email to proceed" }));
    } else if (result === "SIGNIN_SUCCESS" && token) {
      signIn(user, token)
    }
  }

  const handleVerifyOTP = (user: any, token: string) => {
    localStorage.setItem("auth", JSON.stringify({ state: "SET_PASSWORD", token, user }));
    dispatch(updateAuthStateAction({ state: AuthStates.SET_PASSWORD, user, token }));
  }

  const handleSetPassword = (user: any, token: string) => {
    signIn(user, token);
  }

  const handleResetInitiate = (user: any) => {
    dispatch(updateAuthStateAction({ state: AuthStates.OTP_SENT, user }));
  }

  return (
    <>
      {authState.state === AuthStates.SIGNED_IN ? (
        <>
          {props.children}
        </>
      ) : <div className={styles.container}>
        <div className={styles.body}>
          {authState.state === AuthStates.SIGNED_OUT && <SignIn onSignIn={handleSignIn} />}
          {authState.state === AuthStates.OTP_SENT && authState.user && <VerifyOTP email={authState.user.email} onVerifyOTP={handleVerifyOTP} />}
          {authState.state === AuthStates.SET_PASSWORD && authState.user && authState.token && <SetPassword email={authState.user.email} token={authState.token} onSetPassword={handleSetPassword} />}
          {authState.state === AuthStates.RESET_PASSWORD && <ResetPassword onResetPasswordInitiate={handleResetInitiate} />}
        </div>
      </div>}
    </>
  );
};

export default React.memo(Authenticator);
