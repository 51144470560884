import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography,
  Paper,
  Theme,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Tooltip
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import _ from "lodash";
import { DateTime as d } from "luxon"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage
} from "../../common/utils";
import { IProcurementRequestItem, ProcurementItemStatuses, ProcurementRequestStatuses } from "../../../reducers/Procurement/types";
import { procurementRequestInitialState } from "../../../reducers/Procurement/const";
import { CREATE_PROCUREMENT } from "../../../graphql/Procurement/createProcurementMutation";
import { GET_PROCUREMENT_REQUEST } from "../../../graphql/Procurement/getProcurementRequestQuery";
import { NewProcurementItemRequestModel } from "./UpdateProcurementComponents/Models/NewProcurementItemModel";
import { UPDATE_PROCUREMENT } from "../../../graphql/Procurement/updateProcurementMutation";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    form: {
      flexGrow: 1
    }
  })
);

export const CreateNewProcurement: React.FC = () => {

  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const location = useLocation();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const [procurementId, setProcurementId] = useState<string>("")
  const [items, setItems] = useState<IProcurementRequestItem[]>([])
  const [procurementItem, setProcurementItem] = useState<IProcurementRequestItem>()
  const [open, setOpen] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [itemIndex, setItemIndex] = useState<number>(-1)
  const [procurementRequestStatus, setProcurementRequestStatus] = useState("");
  const [
    loadProcurementRequest,
    { loading, data }
  ] = useLazyQuery(GET_PROCUREMENT_REQUEST, { fetchPolicy: "network-only" });

  const [createProcurementRequest] = useMutation(CREATE_PROCUREMENT, {
    onCompleted: (data) => {
      snackbar({
        message: "Procurement Created successfully",
        variant: SnackBarVariant.SUCCESS
      })
      if (data && data.createProcurementRequest) {
        setItems(data.createProcurementRequest.items)
        setOpen(false)
      }
      navigate(`/procurements`)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateProcurementRequest] = useMutation(UPDATE_PROCUREMENT, {
    onCompleted: () => {
      snackbar({
        message: "Procurement updated successfully",
        variant: SnackBarVariant.SUCCESS
      })
      navigate(`/procurements`)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const procurementItemId = params.get("procurementItemId");
      if (procurementItemId) {
        setProcurementId(procurementItemId)
        setIsUpdate(true);
        loadProcurementRequest({
          variables: {
            id: procurementItemId
          }
        });
      }
    } else {
      setOpen(true)
    }
  }, [location]);

  useEffect(() => {
    if (data && data.procurementRequest) {
      setItems(data.procurementRequest.items)
      setProcurementRequestStatus(data.procurementRequest.status);
    }
  }, [data]);

  const handleFormSubmit = (pItems: IProcurementRequestItem[]) => {
    const updateItem = pItems.map(({ ...rest }) => ({ ...rest, note: { ...rest.note, createdBy: userReducer.id } }))
    if (procurementId) {
      updateProcurementRequest({
        variables: {
          id: procurementId,
          procurementRequest: {
            items: updateItem
          }
        }
      })
    } else {
      createProcurementRequest({
        variables: {
          procurementRequest: {
            items: updateItem
          }
        }
      })
    }
  }

  const handleAddItem = (values: IProcurementRequestItem) => {
    if (itemIndex >= 0 && items && items.length) {
      const newItems = [...items];
      newItems[itemIndex] = values;
      setItems(newItems);
    } else {
      setItems([...items, values]);
    }
    setItemIndex(-1);
    setOpen(false);
  }

  const handleRemoveItem = (index: number) => {
    setItems([
      ...(items ? [
        ...items.slice(0, index),
        ...items.slice(index + 1)
      ] : [])
    ])
  }

  const handleOnClose = () => {
    setOpen(false)
    setProcurementItem(_.cloneDeep(procurementRequestInitialState))
  }

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={10} alignItems="center">
        <Typography variant="h1" color="primary">
          {isUpdate && "Update"} Request
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Grid item xs={12}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={10}><Typography variant="h3">All Items</Typography></Grid>
              <Grid item xs={2} container justifyContent="flex-end">
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="add"
                  disabled={procurementRequestStatus === ProcurementRequestStatuses.COMPLETED}
                  onClick={() => {
                    setOpen(true)
                    setItemIndex(-1)
                    setProcurementItem(_.cloneDeep(procurementRequestInitialState))
                  }}
                >
                  Add New Item
                </Fab>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={"tableCellHead"} align="left">Request Id</TableCell>
                        <TableCell className={"tableCellHead"} align="left">No. of Vehicles</TableCell>
                        <TableCell className={"tableCellHead"} align="left">Make</TableCell>
                        <TableCell className={"tableCellHead"} align="left">Model</TableCell>
                        <TableCell className={"tableCellHead"} align="left">Variant</TableCell>
                        <TableCell className={"tableCellHead"} align="left">Fuel</TableCell>
                        <TableCell className={"tableCellHead"} align="left">Transmission</TableCell>
                        <TableCell className={"tableCellHead"} align="left">Condition</TableCell>
                        <TableCell className={"tableCellHead"} align="left">Expected by</TableCell>
                        <TableCell className={"tableCellHead"} align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        items &&
                        items.map((row, index: number) => (
                          <TableRow
                            key={row.requestId}
                          >
                            <TableCell align="left">{row.requestId || "N/A"}</TableCell>
                            <TableCell align="left">{row.quantity}</TableCell>
                            <TableCell align="left">{row.make}</TableCell>
                            <TableCell align="left">{row.model}</TableCell>
                            <TableCell align="left">{row.variant}</TableCell>
                            <TableCell align="left">{row.fuel}</TableCell>
                            <TableCell align="left">{row.transmission}</TableCell>
                            <TableCell align="left">{row.condition}</TableCell>
                            <TableCell align="left">{d.fromISO(row.expectedByDate).toFormat('dd/MM/yyyy')}</TableCell>
                            <TableCell align="right">
                              <Grid item xs={12} container>
                                <Tooltip title="Edit">
                                  <IconButton
                                    aria-label="edit"
                                    disabled={row.status === ProcurementItemStatuses.APPROVED || row.status === ProcurementRequestStatuses.REJECTED}
                                    onClick={() => {
                                      setOpen(true)
                                      setItemIndex(index)
                                      setProcurementItem(row)
                                    }}
                                    size="large">
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                      handleRemoveItem(index)
                                    }}
                                    disabled={row.status === ProcurementItemStatuses.APPROVED || row.status === ProcurementRequestStatuses.REJECTED}
                                    size="large">
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {
                items &&
                items.length > 0 && (
                  <Grid item xs={12}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="Create"
                      className="createButton"
                      disabled={procurementRequestStatus === ProcurementRequestStatuses.COMPLETED}
                      onClick={() => {
                        handleFormSubmit(items)
                      }}
                    >
                      save
                    </Fab>
                  </Grid>
                )
              }
              {
                open && (
                  <NewProcurementItemRequestModel
                    handleAddItem={handleAddItem}
                    onClose={() => handleOnClose()}
                    open={open}
                    procurementItem={procurementItem}
                  />
                )
              }
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}