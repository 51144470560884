import AWS from "aws-sdk";
import Evaporate from "evaporate";

import HttpHeader from "../header";

const httpHeader = HttpHeader.getInstance();

export const getEvaporateClient = async () => {
  const bucket = process.env.REACT_APP_AWS_AMPLIFY_BUCKET || "";
  return await Evaporate.create({
    aws_key: process.env.REACT_APP_AWS_KEY,
    bucket,
    awsRegion: process.env.REACT_APP_AWS_REGION,
    signerUrl: `${process.env.REACT_APP_AUTH_API_URL}/signer`,
    signHeaders: {
      authorization: `Bearer ${httpHeader.headers.authorization}`
    },
    aws_url: `https://${bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/public`,
    cloudfront: true,
    logging: false,
    awsSignatureVersion: "4",
    computeContentMd5: true,
    cryptoMd5Method: function(data) {
      return AWS.util.crypto.md5(data, "base64");
    },
    cryptoHexEncodedHash256: function(data) {
      return AWS.util.crypto.sha256(data, "hex");
    }
  });
};
