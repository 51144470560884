import React, { useEffect, useState } from "react";
import { useLazyQuery, ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { Typography, Grid, Fab, CircularProgress, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton, Checkbox, ListItemText, OutlinedInput, SelectChangeEvent } from "@mui/material";
import FlatPickerBar from "../../../common/FlatPicker";
import { DateTime as d } from "luxon";
import InfoIcon from '@mui/icons-material/Info';
import { GENERATE_ADD_ONS_REPORT_QUERY } from "../../../../graphql/reports/generateAddOnsReportQuery";
import { DATE_TYPE, MONTH_SELECTOR_TYPE, formatGraphQLErrorMessage } from "../../../common/utils";
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { GENERATE_COMPLETED_REVENUE_REPORT } from "../../../../graphql/reports/generateRevenueReportQuery";
import { GENERATE_REVENUE_OVERVIEW_REPORT } from "../../../../graphql/reports/generateRevenueOverviewQuery";
import { GENERATE_LOST_SALES_REPORT } from "../../../../graphql/reports/generateLostSalesReportQuery";
import { GET_OWNED_VEHICLES, GET_VEHICLES_LICENCE_PLATE } from "../../../../graphql/fleet/getVehiclesLicencePlate";
import { IVehicle } from "../../../../reducers/fleet/types";
import { GENERATE_FLEET_EXPENDITURE_REPORT } from "../../../../graphql/reports/generateFleetExpenditureQuery";
import { DOWNLOAD_VEHICLE_INSURANCE_REPORT } from '../../../../graphql/reports/downloadVehicleInsuranceReport';
import { GENERATE_FUTURE_RENTALS_REPORT } from "../../../../graphql/reports/generateFutureRentalsReport";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";


const RevenueReport = () => {
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;

  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<string>("");
  const [licencePlate, setLicencePlate] = useState<string[]>([]);
  const [firstDropdownValue, setFirstDropdownValue] = useState<string>("");
  const [secondDropdownValue, setSecondDropdownValue] = useState<string>("");

  const [generateFutureRentalReport] = useLazyQuery(GENERATE_FUTURE_RENTALS_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.generateFutureRentalReport) {
        const fileName = `Future_Rental_${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
        const file = await getSignedUrl(data.generateFutureRentalReport.key);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        setLoading(false);
        snackbar({
          message: "No report is available in this date range",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  })
  const [generateAddOnsReport] = useLazyQuery(GENERATE_ADD_ONS_REPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.generateAddOnsReport) {
        const fileName = `Addon_Sales_${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
        const file = await getSignedUrl(data.generateAddOnsReport.key);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        setLoading(false);
        snackbar({
          message: "No report is available in this date range",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [generateCompletedRevenueReport] = useLazyQuery(GENERATE_COMPLETED_REVENUE_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.generateRevenueReport) {
        const fileName = `Revenue_Report_for_Completed_${getLocalizedBookingSyntex(country)}s_${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
        const file = await getSignedUrl(data.generateRevenueReport.key);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        setLoading(false);
        snackbar({
          message: "No report is available in this date range",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [generateFleetExpenditureReport] = useLazyQuery(GENERATE_FLEET_EXPENDITURE_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.downloadFleetExpenditureReport) {
        const fileName = `Fleet_Expenditure_Report_${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
        const file = await getSignedUrl(data.downloadFleetExpenditureReport.key);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        setLoading(false);
        snackbar({
          message: "No report is available in this date range",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });


  const [
    loadVehiclesLicencePlate,
    { data: vehiclesData }
  ] = useLazyQuery(GET_OWNED_VEHICLES, {
    fetchPolicy: "network-only"
  });

  const [generateRevenueOverviewReport] = useLazyQuery(GENERATE_REVENUE_OVERVIEW_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.generateRevenueOverviewReport) {
        const fileName = `Revenue_Overview_Report_for_Monthly_${getLocalizedBookingSyntex(country)}_${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
        const file = await getSignedUrl(data.generateRevenueOverviewReport.key);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        setLoading(false);
        snackbar({
          message: "No report is available in this date range",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [generateLostSalesReport] = useLazyQuery(GENERATE_LOST_SALES_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.generateLostSalesReport) {
        const fileName = `${getLocalizedBookingSyntex(country)} _Cancellation_and_Lost_Sales_Report_${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
        const file = await getSignedUrl(data.generateLostSalesReport.key);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        setLoading(false);
        snackbar({
          message: "No report is available in this date range",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [downloadVehicleInsuranceReport] = useLazyQuery(DOWNLOAD_VEHICLE_INSURANCE_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.downloadVehicleInsuranceReport) {
        const fileName = `COI/HOI-report-${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
        const file = await getSignedUrl(data.downloadVehicleInsuranceReport.key);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        setLoading(false);
        snackbar({
          message: "No report is available in this date range",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });


  const months = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  const handleFirstDropdownChange = (event: SelectChangeEvent) => {
    setFirstDropdownValue(event.target.value as string);
    setSecondDropdownValue('');
  };

  const handleSecondDropdownChange = (event: SelectChangeEvent) => {
    setSecondDropdownValue(event.target.value as string);
  };

  useEffect(() => {
    setSelectedReport("");
    loadVehiclesLicencePlate();
  }, [])

  const generateReport = () => {
    setLoading(true)

    if (
      selectedReport === "Addon"
    ) {
      generateAddOnsReport({
        variables: {
          fromDate,
          toDate,
        }
      })
    } else if (selectedReport === "Completed") {
      generateCompletedRevenueReport({
        variables: {
          fromDate,
          toDate,
        }
      })
    } else if (selectedReport === "Overview") {
      generateRevenueOverviewReport({
        variables: {
          duration
        }
      })
    } else if (selectedReport === "LostSales") {
      generateLostSalesReport({
        variables: {
          fromDate,
          toDate,
        }
      })
    } else if (selectedReport === "FleetExpenditure") {
      let vehicleIds: string[] = []
      vehiclesData?.vehicles?.filter((v: any) => v.status === "Active").forEach((vehicle: any) => {
        if (licencePlate.includes(vehicle.licencePlate)) vehicleIds.push(vehicle?.id);
      })
      generateFleetExpenditureReport({
        variables: {
          duration,
          vehicleIds: vehicleIds
        }
      })
    } else if (selectedReport === "COI/HOI") {
      downloadVehicleInsuranceReport({
        variables: {
          fromDate: fromDate,
          toDate: toDate,
        }
      })
    } else if (selectedReport === "FutureRentalReport") {
      generateFutureRentalReport({
        variables: {
          startMonth: firstDropdownValue,
          endMonth: secondDropdownValue,
        }
      })
    }
  }

  const calculateMaxToDate = (fromDate: string) => {
    const todayEnd = d.now();
    const maxToDate = d.fromISO(fromDate).plus({ days: 30 });
    if (maxToDate > todayEnd) {
      return todayEnd.toUTC().toISO();
    }
    return maxToDate.toUTC().toISO();
  }

  return (
    <Grid container>
      <Grid container xs={12} style={{ alignItems: 'center' }}>
        <Grid item xs={11}>
          <Typography variant="h2">
            Downloadable Reports
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            edge="end"
            aria-label="info"
            style={{ marginLeft: '60px' }}
          >
            <Tooltip
              title={
                <div style={{ fontSize: 12 }}>
                  <p>
                    These reports are used to calculate revenue generated within selected date range.
                    Available type of reports are:
                    <li>
                      Add-ons Sales Report
                      <p>This report shows how many add-ons are sold on a given date range.It is mainly used to check what volume of add-ons are sold and what are the total earnings from it.</p>
                    </li>
                    <li>
                      Revenue Report based on return
                      <p>This report shows an overview of revenues based on completed Reservations (returned vehicles).</p>
                    </li>
                    <li>
                      Monthly Revenue Overview Report
                      <p>This report shows an overview of {getLocalizedBookingSyntex(country).toLowerCase()}s and revenue generated within the selected months. </p>
                    </li>
                    <li>
                      {getLocalizedBookingSyntex(country)} Cancellations / Lost Sales Report
                      <p>This report shows an overview of cancelled {getLocalizedBookingSyntex(country).toLowerCase()}s as well as the quotes that were not converted into {getLocalizedBookingSyntex(country).toLowerCase()}s. </p>
                    </li>
                    <li>
                      Fleet Expenditure Report
                      <p>This report shows all expense-related information by a vehicle</p>
                    </li>
                    <li>
                      COI/HOI Report
                      <p>This report shows the insurance details based on the type of insurance selected while creating a {getLocalizedBookingSyntex(country).toLowerCase()}.</p>
                    </li>
                    <li>
                      Future Rental Report
                      <p>The forecast revenue displays a sales figure within the selected months. This is based on future {getLocalizedBookingSyntex(country).toLowerCase()}s</p>
                    </li>
                  </p>
                </div>
              }
              arrow
              placement={"left"}
            >
              <InfoIcon />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
      <Typography>Select type of report to be downloaded: </Typography>
      <Grid container xs={12} spacing={2} style={{ marginTop: "1rem" }}>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-label">Reports</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              fullWidth
              id="demo-simple-select"
              renderValue={(data: any) => <Typography>{data}</Typography>}
              label="Reports"
              onChange={() => {
              }}
            >
              <MenuItem onClick={() => setSelectedReport("Addon")} value="Add-on Sales Report" style={{ position: 'relative' }}>
                <Typography> Add-on Sales Report </Typography>
              </MenuItem>
              <MenuItem onClick={() => setSelectedReport("Completed")} value="Revenue Report based on return" style={{ position: 'relative' }}>
                <Typography> Revenue Report based on return </Typography>
              </MenuItem>
              <MenuItem onClick={() => setSelectedReport("Overview")} value="Monthly Revenue Overview" style={{ position: 'relative' }}>
                <Typography> Monthly Revenue Overview </Typography>
              </MenuItem>
              <MenuItem onClick={() => setSelectedReport("LostSales")} value={` ${getLocalizedBookingSyntex(country)} Cancellations / Lost Sales Report`} style={{ position: 'relative' }}>
                <Typography> {getLocalizedBookingSyntex(country)} Cancellations / Lost Sales Report </Typography>
              </MenuItem>
              <MenuItem onClick={() => setSelectedReport("FutureRentalReport")} value="Future Rental Report" style={{ position: 'relative' }}>
                <Typography> Future Rental Report </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedReport("FleetExpenditure")
                }
                }
                value=" Fleet Expenditure Report"
                style={{ position: 'relative' }}>
                <Typography> Fleet Expenditure Report</Typography>
              </MenuItem>
              <MenuItem onClick={() => setSelectedReport("COI/HOI")} value=" COI/HOI Report" style={{ position: 'relative' }}>
                <Typography> COI / HOI Report </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {selectedReport && selectedReport !== "Overview" && selectedReport !== "FleetExpenditure" && selectedReport != "FutureRentalReport" &&
          <>
            <Grid item xs={3}>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {
                  const timeZoneOffset = d.now().offset;
                  const isPositiveOffset = timeZoneOffset >= 0; 
                  if (isPositiveOffset) {
                    setFromDate(d.fromJSDate(value).toUTC().plus({minutes: timeZoneOffset}).toISO());
                  } else {
                    setFromDate(d.fromJSDate(value).toUTC().minus({minutes: timeZoneOffset}).toISO());
                  }
                  setToDate(calculateMaxToDate(d.fromJSDate(value).startOf("day").toUTC().toISO()))
                }}
                label={"From Date"}
                placeholderValue={"Select From Date"}
                value={fromDate ? getLocalizedDateFormat(country, fromDate, DATE_TYPE.CONDENSED) : ""}
                maxDate={getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}
                country={country}
              />
            </Grid>
            <Grid item xs={3}>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {
                  setToDate(d.fromJSDate(value).endOf("day").toUTC().toISO())
                }}
                label={"To Date"}
                placeholderValue={"Select To Date"}
                value={toDate ? getLocalizedDateFormat(country, toDate, DATE_TYPE.CONDENSED) : ""}
                maxDate={fromDate ? d.fromISO(calculateMaxToDate(fromDate)).plus({ minutes: 1 }).toUTC().toISO() : "today"}
                minDate={getLocalizedDateFormat(country, fromDate, DATE_TYPE.CONDENSED)}
                monthSelectorType={MONTH_SELECTOR_TYPE.STATIC}
                country={country}
              />
            </Grid>
          </>}
        {(selectedReport === "Overview" || selectedReport === "FleetExpenditure") &&
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-duration-select-label">Duration</InputLabel>
              <Select
                labelId="demo-duration-select-label"
                id="demo-simple-select"
                label="Duration"
                onChange={(e: any) => setDuration(parseInt(e.target.value))}

              >
                <MenuItem value="1">Last 1 Month</MenuItem>
                <MenuItem value="3">Last 3 Months</MenuItem>
                <MenuItem value="6">Last 6 Months</MenuItem>
                <MenuItem value="12">Last 12 Months</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        }
        {selectedReport === "FleetExpenditure" &&
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="vehicle-select-label">Vehicle Registration Number</InputLabel>
              <Select
                labelId="vehicle-select-label"
                id="vehicle-select-label"
                label="Registration Number"
                multiple
                value={licencePlate}
                input={<OutlinedInput label="Vehicle Registration Number" />}
                renderValue={(selected: any) => selected.join(', ')}
                onChange={(e: any) => {
                  let val = (e.target.value)
                  if (val && val[0] === "All" || licencePlate.includes("All")) {
                    let regNos: string[] = [];
                    vehiclesData?.vehicles?.filter((v: any) => v.status === "Active").forEach((vehicle: IVehicle) => {
                      regNos.push(vehicle.licencePlate);
                    })
                    setLicencePlate(regNos)
                  } else if (val.length === (vehiclesData?.vehicles?.filter((v: any) => v.status === "Active").length + 1)) {
                    setLicencePlate([]);
                  } else {
                    setLicencePlate(val)
                  }
                }}
              >
                <MenuItem key="all" value="All">
                  <Checkbox checked={licencePlate.length === vehiclesData?.vehicles?.filter((v: any) => v.status === "Active").length} />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {vehiclesData?.vehicles?.filter((v: any) => v.status === "Active").map((data: any, idx: any) => (
                  <MenuItem key={data.id} value={data.licencePlate}>
                    <Checkbox checked={licencePlate.indexOf(data.licencePlate) > -1} />
                    <ListItemText primary={data.licencePlate} />
                  </MenuItem>
                ))}

              </Select>
            </FormControl>
          </Grid>
        }
        {selectedReport === "FutureRentalReport" &&
          <>
            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="first-dropdown-label">Start Month</InputLabel>
                <Select
                  labelId="first-dropdown-label"
                  value={firstDropdownValue}
                  label="Start Month"
                  onChange={handleFirstDropdownChange}
                >
                  {months.map((month) => (
                    <MenuItem
                      key={month.value}
                      value={month.value}
                    // disabled={parseInt(month.value) <= (new Date().getMonth() + 1)}
                    >
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="second-dropdown-label">End Month</InputLabel>
                <Select
                  labelId="second-dropdown-label"
                  value={secondDropdownValue}
                  label="End Month"
                  onChange={handleSecondDropdownChange}
                >
                  {months.map((month) => (
                    <MenuItem
                      key={month.value}
                      value={month.value}
                      disabled={parseInt(month.value) < (parseInt(firstDropdownValue) || 0)}
                    >
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Grid>
          </>
        }
        <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
          <Fab
            variant={"extended"}
            size="medium"
            disabled={loading || !selectedReport || (selectedReport == "FutureRentalReport" && (!firstDropdownValue || !secondDropdownValue)) || (selectedReport !== "Overview" && selectedReport !== "FleetExpenditure" && selectedReport !== "FutureRentalReport" && (!fromDate || !toDate)) || ((selectedReport === "Overview" || selectedReport === "FleetExpenditure") && !duration) || (selectedReport === 'FleetExpenditure' && !licencePlate.length)}
            onClick={() => {
              generateReport()
            }}
          >
            {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
            Download
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RevenueReport;

