import { useMutation } from "@apollo/client";
import {
  Box,
  Fab,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  setBusinessCustomer,
  setBusinessCustomerDocument
} from "../../../../../../actions/customer/actions";
import { CREATE_BUSINESS_CUSTOMER } from "../../../../../../graphql/businessCustomers/businessCustomer";
import {
  BusinessCustomerStatus,
  IBusinessCustomer,
  IBusinessCustomerDocumentInput
} from "../../../../../../reducers/customer/types";
import { IAppState } from "../../../../../../store";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  checkDeleteOrDownloadFileType,
  checkUploadFileFormat,
  formatGraphQLErrorMessage,
  isImageFileType,
  uploadFileExtensionAndContentType,
} from "../../../../../common/utils";
import { ApolloError } from "@apollo/client";
import { DocumentDialog } from "../../../CustomerDetails/DocumentDialog";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { ConfirmationDialog } from "../../../../../common/ConfirmationDialog/ConfirmationDialog";
import { getSignedUrl } from '../../../../../../utils/getSignedUrl';
import { CircularProgress } from "@mui/material";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { captureErrorException } from "../../../../../../utils/sentry";
interface IViewDocumentsProps {
  isOpen: boolean;
  deleteInProgress: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  businessCustomer: IBusinessCustomer;
  businessCustomerDocuments: IBusinessCustomerDocumentInput[];
  deleteDocument(documentKey: string, index: number | undefined): void;
  downloadDocument(url: string, name: string): void;
  onPrevious(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px",
      width: "100%"
    },
    delete: {
      marginLeft: "10px"
    },
    fileName: {
      marginLeft: "20px",
      fontSize: 12,
      color: "black"
    },
    documents: {
      alignItems: "center",
      display: "flex"
    },
    download: {
      marginLeft: "10px"
    },
    picker: {
      width: "200px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    },
    uploadButton: {
      backgroundColor: "var(--theme-primary-dark)",
      border: 0,
      borderRadius: 26,
      color: "white",
      fontSize: "14px",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    backButton: {
      backgroundColor: "black",
      border: 0,
      borderRadius: 26,
      color: "white",
      fontSize: "14px",
      marginRight: "20px",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    warning: {
      color: theme.palette.primary.dark
    },
    label: {
      color: "grey",
      fontSize: 12,
      fontWeight: 100,
      marginLeft: 20
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    listStyle: {
      "&.MuiList-root": {
        maxHeight: 250,
        overflow: "auto"
      }
    }
  })
);
interface IBusinessDocumentsProps {
  onPrevious(): void;
}

export const NewBusinessDocuments = React.memo(
  (props: IBusinessDocumentsProps) => {
    // Get tenancy details
    const userState = useSelector((state: IAppState) => state.userReducer);
    // Fetch css classes
    const classes = useStyles();
    const snackbar = useSnackBar();
    const dispatch = useDispatch();
    const businessCustomer: IBusinessCustomer = useSelector(
      (state: IAppState) => state.customerReducer.businessCustomer
    );
    const { _e_ } = useSelector((state: IAppState) => state.authReducer);
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const [documentDialogVisible, setDocumentDialogVisible] = React.useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
    // Upload the document to s3
    async function uploadDocument(
      file: File,
      title: string,
      documentType: string,
      reminderBefore: number,
      expiryDate: string | undefined,
    ) {
      try {
        if (!userState.tenancy?.id) {
          return;
        }
        // 50MB limit exceeded
        if (file.type === "application/pdf" && file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        if (isImageFileType(file.type) && file.size > 5242880) {
          throw new Error("File size exceeded limit of 5MB");
        }
        const fileName = file.name.split(".")[0];
        setUploadInProgress(true);
        if (checkUploadFileFormat(file.type)) {
          const {
            fileExtension,
          } = uploadFileExtensionAndContentType(file.type);
          const uniqueId = uuidv4();
          const key = `${userState.tenancy.id}/${uniqueId}.${fileExtension}`;
          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  const doc = {
                    name: fileName,
                    title,
                    documentType,
                    reminderBefore,
                    url: key,
                    expiryDate
                  };
                  dispatch(setBusinessCustomerDocument(doc));
                  snackbar({
                    message: "Document Added Successfully",
                    variant: SnackBarVariant.SUCCESS
                  });
                  setUploadInProgress(false);
                  setDocumentDialogVisible(false);
                }
              });
          }
        } else {
          setDocumentDialogVisible(false);
          setUploadInProgress(false);
          return snackbar({
            message: "Please only upload PDF, PNG, JPG or JPEG files",
            variant: SnackBarVariant.ERROR
          });
        }
      } catch (err: any) {
        captureErrorException(err)
        snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
      }
    }

    // Download the document from s3
    async function downloadDocument(documentUrl: string, name: string) {
      if (!documentUrl) {
        return;
      }
      const { fileExtension, contentType } = checkDeleteOrDownloadFileType(documentUrl);
      const file = await getSignedUrl(documentUrl);
      const url: any = file;
      fetch(url, {
        method: "GET"
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${name}.${fileExtension}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    }

    // Remove the document
    function deleteDocument(url: string, index: number) {
      const { contentType } = checkDeleteOrDownloadFileType(url);
      setDeleteInProgress(true);
      // TODO: implement file deleteion from s3
      businessCustomer.documents.splice(index, 1);
      dispatch(setBusinessCustomer(businessCustomer));
      snackbar({
        message: "Document Removed",
        variant: SnackBarVariant.SUCCESS
      });
      setDeleteInProgress(false);
      handleDialogClose()
    }

    const handleDialogOpen = () => {
      setOpenDeleteDialog(true)
    }

    const handleDialogClose = () => {
      setOpenDeleteDialog(false);
    }

    return (
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <Typography variant="h2">ADD BUSINESS CUSTOMER DOCUMENTS</Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Fab
              className="blackBackButton"
              variant="extended"
              size="medium"
              aria-label="Update"
              onClick={() => {
                setDocumentDialogVisible(true);
              }}
            >
              ADD DOCUMENT
            </Fab>
          </Grid>
          <div>
            <DocumentDialog
              open={documentDialogVisible}
              handleClose={() => setDocumentDialogVisible(false)}
              uploadInProgress={uploadInProgress}
              onSubmit={uploadDocument}
              accept="image/jpg, image/jpeg, image/png, application/pdf"
            />
          </div>
          <ViewDocuments
            isOpen={openDeleteDialog}
            deleteInProgress={deleteInProgress}
            handleOpen={handleDialogOpen}
            handleClose={handleDialogClose}
            businessCustomer={businessCustomer}
            businessCustomerDocuments={businessCustomer.documents}
            deleteDocument={deleteDocument}
            onPrevious={props.onPrevious}
            downloadDocument={downloadDocument}
          />
        </Grid>
      </Paper>
    );
  }
);

const ViewDocuments = (props: IViewDocumentsProps) => {
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [deleteDocumentUrl, setDeleteDocumentUrl] = useState<string>("");
  const [documentIndex, setDocumentIndex] = useState<number>();
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      label: "Document Type",
      name: "documentType",
    },
    {
      label: "Document Name/Title",
      name: "title"
    },
    {
      label: "Expiry Date",
      name: "expiryDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED) : "N/A"
      }
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <div>
              <IconButton
                edge="end"
                aria-label="download"
                onClick={() =>
                  props.downloadDocument(value.url, value.name)
                }
                size="large">
                <CloudDownloadIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  setDeleteDocumentUrl(value.url)
                  setDocumentIndex(value.index);
                  props.deleteDocument(value.url, value.index)
                  props.handleClose();
                }}
                size="large">
                <DeleteIcon />
              </IconButton>
            </div>
          );
        }
      }
    }
  ];

  const [createBusinessCustomer] = useMutation(CREATE_BUSINESS_CUSTOMER, {
    onCompleted: () => {
      snackbar({
        message: "Business Customer created.",
        variant: SnackBarVariant.SUCCESS
      });
      setLoading(false);
      navigate("/business-customer");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setLoading(false);
    }
  });
  const saveBussinessCustomer = () => {
    const { id, contactName, credentialSentOn, credentialSent, isClientExists, declinedReason, ...rest } = props.businessCustomer
    const updatedRest = { ...rest, status: BusinessCustomerStatus.APPROVED };
    if (typeof updatedRest.accountManager !== 'string') {
      updatedRest.accountManager = updatedRest.accountManager.id
    }
    createBusinessCustomer({
      variables: {
        businessCustomer: updatedRest
      }
    });
    setLoading(true);

  };

  const getRows = (customerDocuments: IBusinessCustomerDocumentInput[]) => {
    if (!customerDocuments || customerDocuments === null) {
      return [];
    }
    return customerDocuments.map((customerDocument: IBusinessCustomerDocumentInput, index: number) => {
      return {
        actions: { url: customerDocument.url, index, name: customerDocument.name },
        title: customerDocument.title || customerDocument.name,
        expiryDate: customerDocument.expiryDate,
        documentType: customerDocument.documentType
      };
    });
  };

  const handlePrevious = () => {
    props.onPrevious();
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <NuvvenTable
          key={new Date().getMilliseconds()}
          title={""}
          rows={getRows(props.businessCustomerDocuments)}
          columns={columns}
          setSelection={() => { }}
          options={{
            selectableRows: "none",
            download: false,
            filter: false,
            print: false,
            search: false,
            viewColumns: false,
            responsive: "vertical"
          }}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item container xs={12} justifyContent="flex-start">
          <Fab
            className="blackBackButton"
            variant="extended"
            size="medium"
            onClick={handlePrevious}
          >
            Back
          </Fab>
          <Box pr={2}></Box>
          <Fab variant="extended" size="medium" onClick={saveBussinessCustomer} disabled={loading}>
            {loading && (
              <CircularProgress
                size={14}
                style={{ color: "white", marginRight: "10px" }}
              />
            )}
            Save Customer
          </Fab>
        </Grid>
        {props.isOpen && (
          <ConfirmationDialog
            isOpen={props.isOpen}
            onCancel={props.handleClose}
            onConfirm={() => {
              props.deleteDocument(deleteDocumentUrl, documentIndex);
            }}
            isInProgress={props.deleteInProgress}
            title=""
            description={"Are you sure, you want to delete this document?"}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};
