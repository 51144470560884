import { Collapse, Grid, IconButton, Table, TableBody, TableCell, TableRow, Theme, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DateTime as d } from "luxon"
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/CancelOutlined"
import { IProcurementRequest, IQuote, IRevision, ProcurementItemStatuses } from '../../../../reducers/Procurement/types'
import { DATE_TYPE, checkDeleteOrDownloadFileType, formatGraphQLErrorMessage, toCurrency } from '../../../common/utils';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { UserRoles } from '../../../hoc/Authorization';
import { NewQuoteModel, quoteInitials } from './Models/NewQuoteModel';
import { DocumentsDialog } from './Models/DocumentsDialog';
import { ConfirmationDialog } from './Models/ConfirmationDialog';
import { useSnackBar } from '../../../common/SnackBarContext/SnackBarContext';
import { useMutation } from '@apollo/client';
import { ADD_PROCUREMENT_QUOTE } from '../../../../graphql/Procurement/addProcurementQuoteMutation';
import { SnackBarVariant } from '../../../common/SnackbarWrapper/SnackbarWrapper';
import { ApolloError } from '@apollo/client';
import { UPDATE_PROCUREMENT_QUOTE } from '../../../../graphql/Procurement/updateProcurementQuoteMutation';
import { UPDATE_PROCUREMENT_QUOTE_STATUS } from '../../../../graphql/Procurement/updateProcurementQuoteStatusMutation';
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { DescriptionType, DialogType } from '../const';
import { withStyles } from '@mui/styles';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 250,
    border: '1px solid #dadde9',
    padding: 15
  },
}))(Tooltip);
interface IProps {
  row: IQuote;
  procurement: IProcurementRequest;
  setProcurementRequest(data: IProcurementRequest): void;
  key: number;
}

export const CollapsibleRow: React.FC<IProps> = (props) => {

  const { row } = props;
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const { locale, currency } = userState.currentOrganisation;

  const [open, setOpen] = useState<boolean>(false);
  const [openQuoteModel, setOpenQuoteModel] = useState<boolean>(false)
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false)
  const [openDocumentDialog, setOpenDocumentDialog] = useState<boolean>(false);
  const [documents, setDocuments] = useState<string[]>([])
  const [quote, setQuote] = useState<IQuote>()
  const [title, setTitle] = useState<string>("")

  const [description, setDescription] = useState<string>("")
  const [status, setStatus] = useState<string>(ProcurementItemStatuses.PENDING)
  const [qouteItems, setQuoteItems] = useState<IQuote[]>([])

  useEffect(() => {
    if (props.procurement && props.procurement.quotes) {
      setQuoteItems(props.procurement.quotes)
    }
  }, [props.procurement])

  const [addProcurementQuote] = useMutation(ADD_PROCUREMENT_QUOTE, {
    onCompleted: (data) => {
      snackbar({
        message: "Quote added successfully",
        variant: SnackBarVariant.SUCCESS
      })
      if (data && data.addProcurementQuote) {
        props.setProcurementRequest({
          ...props.procurement,
          quotes: [...props.procurement.quotes, ...data.addProcurementQuote]
        })
        setQuote(quoteInitials)
        setOpenQuoteModel(false)
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateProcurementQuote] = useMutation(UPDATE_PROCUREMENT_QUOTE, {
    onCompleted: (data) => {
      snackbar({
        message: "Quote updated successfully",
        variant: SnackBarVariant.SUCCESS
      })
      if (data && data.updateProcurementQuote) {
        const quoteRequestIndex = qouteItems.findIndex((pQuote) => pQuote.id === data.updateProcurementQuote.id)
        if (quoteRequestIndex > -1) {
          const newQuoteRequests = [
            ...qouteItems.slice(0, quoteRequestIndex),
            {
              ...qouteItems[quoteRequestIndex],
              ...data.updateProcurementQuote
            },
            ...qouteItems.slice(quoteRequestIndex + 1)
          ];
          props.setProcurementRequest({
            ...props.procurement,
            quotes: newQuoteRequests
          })
        }
      }
      setOpenQuoteModel(false)
      setQuote(quoteInitials)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateProcurementQuoteStatus] = useMutation(UPDATE_PROCUREMENT_QUOTE_STATUS, {
    onCompleted: (data) => {
      snackbar({
        message: "Quote status updated successfully",
        variant: SnackBarVariant.SUCCESS
      })
      if (data && data.updateProcurementQuoteStatus && quote) {
        const quoteIndex = qouteItems.findIndex((pQuote) => pQuote.id === quote.id)
        if (quoteIndex > -1) {
          const newQuotes = [
            ...qouteItems.slice(0, quoteIndex),
            {
              ...qouteItems[quoteIndex],
              status
            },
            ...qouteItems.slice(quoteIndex + 1)
          ];
          props.setProcurementRequest({
            ...props.procurement,
            quotes: newQuotes,
            purchaseOrders: status === ProcurementItemStatuses.APPROVED ? [data.updateProcurementQuoteStatus] : props.procurement.purchaseOrders
          })
        }
      }
      setConfirmDialog(false)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const handleOnCloseModel = () => {
    setQuote(quoteInitials)
    setOpenQuoteModel(false)
  }

  const handelFormSubmit = (quote: IQuote) => {
    if (!quote.note || (quote.note && !quote.note.description)) {
      quote.note = {
        description: "",
        createdAt: "",
        createdBy: ""
      }
    } else if (typeof quote.note.createdBy === "object") {
      quote.note = {
        ...quote.note,
        createdBy: quote.note.createdBy.id
      }
    } else if (quote.note && quote.note.description && !quote.note.createdBy) {
      quote.note = {
        ...quote.note,
        createdBy: userState.id || ""
      }
    }
    const { id, partner, revisions, ...rest } = quote
    if (quote.id) {
      updateProcurementQuote({
        variables: {
          id: props.procurement.id,
          procurementQuoteId: quote.id,
          procurementQuote: rest
        }
      })
    } else {
      addProcurementQuote({
        variables: {
          id: props.procurement.id,
          procurementQuote: rest
        }
      })
    }
  }

  const handleUpdateQuoteStatus = () => {
    if (quote) {
      updateProcurementQuoteStatus({
        variables: {
          id: props.procurement.id,
          procurementQuoteId: quote.id,
          status
        }
      })
    }
  }

  async function downloadDocument(documentKey: string, title: string) {
    if (!userState.tenancy?.id) {
      return;
    }
    const { fileExtension, contentType } = checkDeleteOrDownloadFileType(
      documentKey
    );
    const file = await getSignedUrl(documentKey)
    const url: any = file;

    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${title}.${fileExtension}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  return <>
    <TableRow
      key={props.key}
    >
      <TableCell>
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell align="left">{row.proposalRequestId}</TableCell>
      <TableCell align="left">{row.quoteId || "N/A"}</TableCell>
      <TableCell align="left">{row.date ? d.fromISO(row.date).toFormat("dd/MM/yyyy") : "N/A"}</TableCell>
      <TableCell align="left">{row.partner?.partnerName}</TableCell>
      <TableCell align="left">{row.validUntilDate ? d.fromISO(row.validUntilDate).toFormat("dd/MM/yyyy") : "N/A"}</TableCell>
      <TableCell align="left">{toCurrency(row.amount, currency, locale)}</TableCell>
      <TableCell align="left">
        <HtmlTooltip
          title={
            <>
              <Typography variant={"h3"}>Description</Typography>
              <Typography variant={"body1"}>{row.note && row.note.description ? row.note.description : "N/A"}</Typography>
            </>
          }
        >
          <IconButton aria-label="note-preview" size="large">
            <VisibilityIcon />
          </IconButton>
        </HtmlTooltip>
      </TableCell>
      <TableCell align="left">
        <IconButton
          onClick={() => {
            setDocuments(row.documents)
            setOpenDocumentDialog(true)
          }}
          disabled={row.documents.length > 0 ? false : true}
          aria-label="documents"
          size="large">
          <VisibilityIcon />
        </IconButton>
      </TableCell>
      <TableCell align="right">
        <Grid item xs={12} container>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                setQuote(row)
                setOpenQuoteModel(true)
              }}
              disabled={
                userState.role === UserRoles.ORGANISATION_OWNER ?
                  (row.status === ProcurementItemStatuses.APPROVED || row.status === ProcurementItemStatuses.REJECTED
                  ) ? true : false
                  : true
              }
              aria-label="edit"
              size="large">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={row.status === ProcurementItemStatuses.APPROVED ? "Received" : "Approve"}>
            <IconButton
              aria-label="confirmed"
              onClick={() => {
                if (row && row.id) {
                  setTitle(DialogType.APPROVE_QUOTE)
                  setDescription(DescriptionType.APPROVE_QUOTE)
                  setStatus(ProcurementItemStatuses.APPROVED)
                  setQuote(row)
                  setConfirmDialog(true)
                }
              }}
              disabled={
                userState.role === UserRoles.FINANCE_MANAGER ?
                  (row.status !== ProcurementItemStatuses.RECEIVED ||
                    row.status === ProcurementItemStatuses.APPROVED ||
                    row.status === ProcurementItemStatuses.REJECTED
                  ) ? true : false
                  : true
              }
              size="large">
              <CheckIcon className={row.status === ProcurementItemStatuses.APPROVED ? "procurement-status-approved" : ""} />
            </IconButton>
          </Tooltip>
          <Tooltip title={row.status === ProcurementItemStatuses.REJECTED ? "Rejected" : "Reject"}>
            <IconButton
              aria-label="canclled"
              onClick={() => {
                if (row && row.id) {
                  setTitle(DialogType.REJECT_QUOTE)
                  setDescription(DescriptionType.REJECT_QUOTE)
                  setStatus(ProcurementItemStatuses.REJECTED)
                  setQuote(row)
                  setConfirmDialog(true)
                }
              }}
              disabled={
                userState.role === UserRoles.FINANCE_MANAGER ?
                  (row.status !== ProcurementItemStatuses.RECEIVED ||
                    row.status === ProcurementItemStatuses.APPROVED ||
                    row.status === ProcurementItemStatuses.REJECTED
                  ) ? true : false
                  : true
              }
              size="large">
              <CancelIcon className={row.status === ProcurementItemStatuses.REJECTED ? "procurement-status-rejected" : ""} />
            </IconButton>
          </Tooltip>
        </Grid>
      </TableCell>
    </TableRow>
    {
      row &&
      row.revisions && (

        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table aria-label="purchases">
                <TableBody>
                  {row.revisions.map((revision: IRevision, index: number) => (
                    revision.amount > 0 && (
                      <TableRow key={revision.amount}>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">{revision.date ? d.fromISO(revision.date).toFormat("dd/MM/yyyy") : "N/A"}</TableCell>
                        <TableCell align="left">{row.partner?.partnerName}</TableCell>
                        <TableCell align="left">{revision.validUntilDate ? d.fromISO(revision.validUntilDate).toFormat("dd/MM/yyyy") : "N/A"}</TableCell>
                        <TableCell align="left">{toCurrency(revision.amount, currency, locale)}</TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    )
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )
    }
    {
      openDocumentDialog && (
        <DocumentsDialog
          open={openDocumentDialog}
          documents={documents}
          download={downloadDocument}
          onClose={() => setOpenDocumentDialog(false)}
        />
      )
    }
    {
      openQuoteModel && (
        <NewQuoteModel
          onClose={() => handleOnCloseModel()}
          open={openQuoteModel}
          proposalRequests={props.procurement.proposalRequests}
          quote={quote}
          handelFormSubmit={handelFormSubmit}
          procurementExpectedDate={props.procurement.createdAt}
        />
      )
    }
    {
      confirmDialog && (
        <ConfirmationDialog
          open={confirmDialog}
          onClose={() => setConfirmDialog(false)}
          onConfirm={handleUpdateQuoteStatus}
          description={description}
          title={title}
        />
      )
    }
  </>;
}
