import _ from "lodash";
import { DateTime as d } from "luxon";
import { IBooking } from "../../../reducers/bookings/types";
import { IInvoice } from "../../../reducers/invoices/types";
import { DATE_TYPE, toCurrency } from "../../common/utils";
import { InvoiceStatus } from "./UpdateInvoice/InvoiceComponents/types";
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";

export enum InvoiceType {
  FIRST = "FIRST",
  FINAL = "FINAL",
  ACCUMULATIVE = "ACCUMULATIVE", // for extend booking
  INTERMEDIATE = "INTERMEDIATE",
  SUNDRY = "SUNDRY",
  BOOKING_EXTENSION = "BOOKING_EXTENSION",
  CONSOLIDATED = "CONSOLIDATED"
}

export function arrayUnique(array: any) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (JSON.stringify(a[i]) === JSON.stringify(a[j]))
        a.splice(j--, 1);
    }
  }
  return a;
}

export function reshapeInvoicesIntoRows(
  invoice: IInvoice,
  locale: any,
  currency: any,
  bookingRef: string,
  customer: string,
  country: string
) {
  return {
    id: invoice.id,
    referenceNumber: bookingRef,
    invoiceRef: invoice.invoiceRef,
    invoiceType: invoice.invoiceType,
    startDate: invoice.startDate,
    dateCreated: invoice.dateCreated,
    customer,
    total: invoice.totalPayableAmount,
    rentalAmount: toCurrency(invoice.rentalAmount, currency, locale),
    insurance: toCurrency(invoice.insuranceAmount, currency, locale),
    taxAmount: toCurrency(invoice.taxAmount, currency, locale),
    sumTotal: toCurrency(invoice.totalPayableAmount, currency, locale),
    status: invoice.status,
    dueDate: invoice.dueDate,
    dueAmount: invoice.dueAmount,
    sageAccountingInvoiceId: invoice.sageAccountingInvoiceId,
    sentToXero: invoice.sentToXero,
    lastSent: invoice.lastSent ? getLocalizedDateFormat(country, invoice.lastSent, DATE_TYPE.CONDENSED) : ""
  };
}

export const getTotalsFromInvoices = (invoices: IInvoice[], currency: string, locale: string) => {
  let rentalAmount: number = 0;
  let insurance: number = 0;
  let deposit: number = 0;
  let damageAmount: number = 0;
  let taxAmount: number = 0;
  let sumTotal: number = 0;
  const statuses: string[] = [];

  invoices.forEach((invoice: IInvoice) => {
    rentalAmount += invoice.rentalAmount;
    insurance += invoice.insuranceAmount;
    damageAmount += invoice.damageAmount;
    taxAmount += invoice.taxAmount;
    sumTotal += invoice.totalPayableAmount;
    statuses.push(invoice.status);
  });

  const unique = _.uniq(statuses);
  const status = unique.length === 1 ? unique[0] : InvoiceStatus.PARTIALLY_PAID;
  let totalRentalAmount = toCurrency(rentalAmount, currency, locale)
  let totalInsurance = toCurrency(insurance, currency, locale);
  let totalDeposit = toCurrency(deposit, currency, locale);
  let totalTaxAmount = toCurrency(taxAmount, currency, locale);
  let total = toCurrency(sumTotal, currency, locale);

  return {
    damageAmount,
    totalDeposit,
    totalInsurance,
    status,
    totalRentalAmount,
    total,
    totalTaxAmount
  };
};

export function getSubTotal(booking: IBooking) {
  return (
    booking.paymentDetails.rentalAmount +
    booking.paymentDetails.insuranceAmount
  );
}
interface IGetTaxAmountArgs {
  currentAmount: number
  originalAmount: number
  taxAmount: number
}

export const getTaxAmount = (args: IGetTaxAmountArgs) => {
  const {taxAmount, originalAmount, currentAmount} = args
  let amount = 0;
  const fraction = (taxAmount / originalAmount)
  amount = Math.round(currentAmount * fraction)
  return amount;
}

export function getInvoiceItemTaxValue(taxDescription: string) {
  if (taxDescription) {
    const regex = /[+-]?\d+(\.\d+)?/g;
    const matchedRegArray: RegExpMatchArray | null = taxDescription.match(regex);
    if (matchedRegArray && matchedRegArray.length) {
      return parseFloat(matchedRegArray[0]);
    }
  }
  return 0;
}

export enum IInvoiceItemType {
  "RENTAL_AMOUNT" = "RENTAL_AMOUNT",
  "DEPOSIT" = "DEPOSIT",
  "INSURANCE" = "INSURANCE",
  "OTHER_CHARGE" = "OTHER_CHARGE",
  "ADDON" = "ADDON"
}

export enum CreditNoteTypes {
  REFUNDABLE = "REFUNDABLE",
  ADJUSTABLE = "ADJUSTABLE"
}

export interface IInvoiceItemCharge {
  type: IInvoiceItemType;
  description: string;
  unitPrice: number;
  totalAmount: number;
  quantity: number;
  duration?: number;
  taxRate: number;
  taxAmount: number;
}
