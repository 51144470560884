import { gql } from "@apollo/client";

export const EXCHANGE_CODE = gql`
  mutation ExchangeCode($code: String!) {
    exchangeCode(code: $code) {
        success
        message
        missingVINs
    }
  }
`;
