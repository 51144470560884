import { AppBar, Grid, Paper, Tab, Tabs, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { IAppState } from "../../../store";
import { TabPanel } from "../../common/TabPannel/TabPannel";
import { Partner } from "./Partner";
import { PartnerDrivers } from "./PartnerDrivers";
import { IPartner } from "../../../reducers/partners/types";
import { PartnerJobHistory } from "./PartnerJobHistory";
import Dashboard from "./PartnerOverview";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    }
  })
);

export const NewPartnerTabView = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  return (
    <Grid container>
      <AppBar style={{ position: "static" }} color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Overview" />
          <Tab label="Company Information" />
          <Tab label="Drivers" />
          <Tab label="Documents" />
          <Tab label="Job History" />
        </Tabs>
      </AppBar>
      <Paper className={classes.root}>
      <TabPanel value={value} index={0}>
        <Dashboard />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Partner />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PartnerDrivers />
        </TabPanel>
        <TabPanel value={value} index={3}>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PartnerJobHistory />
        </TabPanel>
      </Paper>
    </Grid>
  );
};
