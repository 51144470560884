import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { MOBILISE_VEHICLE } from "../../../../../graphql/fleet/mobiliseVehicleQuery";
import { IMMOBILISE_VEHICLE } from "../../../../../graphql/fleet/imobiliseVehicleMutation";

interface IProps {
  esn: string;
  mobiliserOpen: boolean;
  closeMobiliser: () => void
  immobiliserOpen: boolean;
  closeImmobiliser: () => void;
  handleStarterStatus: (status?: VehicleStarterStatus) => void;
}

export enum VehicleStarterStatus {
  STARTER_ENABLED = 'STARTER_ENABLED',
  STARTER_DISABLED = 'STARTER_DISABLED',
  STARTER_ENABLED_INITIATED = 'STARTER_ENABLED_INITIATED',
  STARTER_DISABLED_INITIATED = 'STARTER_DISABLED_INITIATED',
  LOADING = 'CONFIRMING_NEW STATUS'
}

export enum FlespiVehicleStarterStatus {
  ENGINE_UNBLOCK = "ENGINE_UNBLOCK",
  ENGINE_BLOCK = "ENGINE_BLOCK",
  ENGINE_UNBLOCK_INITIATED = "ENGINE_UNBLOCK_INITIATED",
  ENGINE_BLOCK_INITIATED = "ENGINE_BLOCK_INITIATED"
}

export const MobiliseImmobilise: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const [hasAgreed, setHasAgreed] = useState<boolean>(false);

  const [mobilisevehicleMutation] = useMutation(MOBILISE_VEHICLE, {
    onCompleted: () => {
      snackbar({
        message: "Vehicle Mobilisation request registered successfully",
        variant: SnackBarVariant.SUCCESS
      });
      props.handleStarterStatus(VehicleStarterStatus.STARTER_ENABLED_INITIATED)
    },
    onError: (error: ApolloError) => {
      props.handleStarterStatus(VehicleStarterStatus.STARTER_ENABLED)
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }

  });

  const [immobiliseVehicleMutation] = useMutation(IMMOBILISE_VEHICLE, {
    onCompleted: () => {
      snackbar({
        message: "Vehicle Immobilisation request registered successfully",
        variant: SnackBarVariant.SUCCESS
      });
      props.handleStarterStatus(VehicleStarterStatus.STARTER_DISABLED_INITIATED)
    },
    onError: (error: ApolloError) => {
      props.handleStarterStatus(VehicleStarterStatus.STARTER_ENABLED)
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });


  const immobiliseVehicle = () => {
    if (props.esn) {
      immobiliseVehicleMutation({
        variables: {
          deviceEsn: props.esn
        }
      })
    }
    props.closeImmobiliser()
  }

  const mobiliseVehicle = () => {
    if (props.esn) {
      mobilisevehicleMutation({
        variables: {
          deviceEsn: props.esn
        }
      })
    }
    props.closeMobiliser()
  }

  const handleMobiliserClose = () => {
    props.closeMobiliser()
  }

  const handleImmobiliserClose = () => {
    props.closeImmobiliser()
  }
  return (
    <>
      <Dialog
        onClose={handleMobiliserClose}
        aria-labelledby="immobiliser-dialog-title"
        open={props.mobiliserOpen}
      >
        <DialogTitle style={{ padding: '16px 25px' }}>
          <Typography variant="h4" style={{ fontWeight: 700, fontSize: 16 }}>
            Mobilise Vehicle
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="immobiliser-dialog-description">
            <Grid item xs={12}>
              <Typography variant={"body1"}>Mobilising an Immobilised vehicle is a critical operation and the Software provider is not responsible for any unauthorised use of it.</Typography>
              <Box mt={1}></Box>
              <Typography variant={"body1"}>Post agreeing to the mentioned information and confirming your choice. The software will put the command in queue till the device is alive and in proper network area.</Typography>
              <Box mt={1}></Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasAgreed}
                    onChange={() => {
                      setHasAgreed(prev => !prev)
                    }}
                  />
                }
                label={<Typography variant="body1">I Agree.</Typography>}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '10px 25px' }}>
          <Fab
            className="blackBackButton"
            variant="extended"
            size="small"
            onClick={handleMobiliserClose}
          >
            Cancel
          </Fab>
          <Fab
            variant="extended"
            size="small"
            onClick={mobiliseVehicle}
            disabled={!hasAgreed}
            className={hasAgreed ? "" : "deleteButtonDisable"}
          >
            <strong>Confirm</strong>
          </Fab>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleImmobiliserClose}
        aria-labelledby="immobiliser-dialog-title"
        open={props.immobiliserOpen}
      >
        <DialogTitle id="immobiliser-dialog-title">
          <DialogTitle style={{ padding: '16px 25px' }}>
            <Typography variant="h4" style={{ fontWeight: 700, fontSize: 16 }}>
              Immobilise Vehicle
            </Typography>
          </DialogTitle>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="immobiliser-dialog-description">
            <Grid item xs={12}>
              <Typography variant={"body1"}>Immobilisation is a critical operation and the Software provider is not responsible for any unauthorised use of it.</Typography>
              <Box mt={1}></Box>
              <Typography variant={"body1"}>Post agreeing to the mentioned information and confirming your choice. The software will put the command in queue if the vehicle is in motion and it will execute when the vehicle's ignition will be off, considering the safety of the vehicle and the driver. It is important to know that the request will get processed only when the engine is off and the vehicle is in the proper network zone.</Typography>
              <Box mt={1}></Box>
              <Typography variant={"body1"}>To check the current status (Post Confirmation) you may wish to click on the refresh icon beside the cross mark.The mobilise button will be enabled once the vehicle is properly immobilised. In case you wish to see the latest position of the vehicle, use the refresh icon and map.</Typography>
              <Box mt={1}></Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasAgreed}
                    onChange={() => {
                      setHasAgreed(prev => !prev)
                    }}
                  />
                }
                label={<Typography variant="body1">I Agree.</Typography>}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '10px 25px' }}>
          <Fab
            className="blackBackButton"
            variant="extended"
            size="small"
            onClick={handleImmobiliserClose}
          >
            Cancel
          </Fab>
          <Fab
            variant="extended"
            size="small"
            onClick={immobiliseVehicle}
            disabled={!hasAgreed}
            className={hasAgreed ? "" : "deleteButtonDisable"}
          >
            <strong>Confirm</strong>
          </Fab>
        </DialogActions>
      </Dialog>
    </>
  );
}
