import { gql } from "@apollo/client";

export const CREATE_VEHICLES = gql`
  mutation createVehicles($vehicles: [CreateVehicleInput!]!) {
    createVehicles(vehicles: $vehicles) {
      errors {
        index
        message
      }
      vehicles {
        id
        insurerCode
        airConditioner
        assetTag
        isGhostVehicle
        brakeHorsePower
        bluetooth
        bodyType
        vehicleUniqueCode
        vehicleUniqueId
        co2Emissions
        colour
        cylinderCapacity
        description
        fuelType
        imported
        infoSource
        insuranceGroup
        licencePlate
        vin
        make
        model
        manufactureDate
        year
        registrationDate
        vehicleTest
        vehicleTestDetails
        vehicleTestDueDate
        numberOfDoors
        numberOfPreviousOwners
        numberOfSeats
        premium
        price
        msrp
        grossVehicleWeight
        satNav
        sixMonthRate
        status
        steering
        taxDetails
        taxed
        taxStatus
        telemetryDeviceId
        transmission
        twelveMonthRate
        typeApproval
        tyreFitments
        wheelPlan
        acrissCode
        branch
        imageUrl
        deleted
        id
        interiorColour
        pcoNumber
        batteries {
          voltage
          capacity
          coldTestCurrent
          length
          width
          height
          postPositions
          terminalType
          holdDownType
        }
        frontTyres {
          width
          ratio
          rim
          speedRating
          psi
          loadIndex
        }
        rearTyres {
          width
          ratio
          rim
          speedRating
          psi
          loadIndex
        }
        vehicleTestReports {
          testDate
          expiryDate
          testResult
          odometerReading
          odometerUnit
          motTestNumber
          advisoryItems
          minorItems
          failureItems
        }
        vehicleDocuments {
          id
          title
          documentName
          documentType
          url
          expiryDate
          createdAt
        }
        features
      }
    }
  }
`;
