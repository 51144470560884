import "./index.scss";
import { useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Field, FieldProps, Form, Formik } from "formik";
import { TextField as InputField, TextField } from "formik-mui";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { CREATE_CUSTOMER_SIMPLE } from "../../../../../../graphql/customers/createCustomerMutation";
import { ILicenseDetails, ILocation } from "../../../../../../reducers/customer/types";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  getUploadedImageByKey,
  returnfileContentTypeAndExtention,
} from "../../../../../common/utils";
import {
  validateName
} from "../../../../../common/ValidationRules";
import FlatPickerBar from "../../../../../common/FlatPicker";
import { DateTime as d } from "luxon"
import PhoneInput, { Value, isValidPhoneNumber } from "react-phone-number-input";
import AddressPicker from "../../../../CustomerManagement/NewCustomer/AddressPicker/AddressPicker";
// import { AddDrivers } from "../../../Summary/Components/AddDrivers";
import { IAddress, IDriverRow } from "../../../../../../reducers/user/types";
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../../store';
import DragDropImages from "../../../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";
import { getDefaultCountryCode, getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { ICostCenter } from "../../../../../../reducers/organisation/types";
import NuvvenAutoCompleteTags from "../../../../../common/NuvvenAutoCompleteTags";
import AddDrivers from "../../../Summary/Components/AddDrivers";
import { createStyles, makeStyles } from "@mui/styles";
import { Checkbox, Fab, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
enum VerificationMethods {
  MANUAL = "MANUAL"
}

const useStyles = makeStyles(() =>
  createStyles({
    tableContainer: {
      border: "solid 1px rgba(224, 224, 224, 1)",
      marginTop: "1rem"
    }
  })
);
interface ISimpleCustomerValues {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  email: string;
  location: ILocation;
  verificationMethod: VerificationMethods;
  license: ILicenseDetails;
  taxExempted: boolean;
}

interface IPhoneNumber {
  phone: Value;
  country: string;
}
const defaultValues: ISimpleCustomerValues = {
  email: "",
  firstName: "",
  id: "",
  lastName: "",
  phoneNumber: {
    phone: "" as Value,
    country: "GB"
  },
  location: {
    city: "",
    country: "",
    fullAddress: "",
    state: "",
    street: "",
    zipcode: ""
  },
  license: {
    licenseNumber: "",
    authority: "",
    dateOfExpiry: "",
    dateOfIssue: "",
    endorsement: "",
    images: []
  },
  verificationMethod: VerificationMethods.MANUAL,
  taxExempted: false
};

interface IProps {
  updateCustomer: (data: {
    customer?: string;
    businessCustomer?: string;
    approvedDrivers?: string[];
  }) => void;
  driverCount: number;
  licenseMinDate: string;
}

const driverSchema = Yup.object().shape({
  drivers: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        email: Yup.string()
          .email("Email is required")
          .required("Email is required"),
        license: Yup.object().shape({
          authority: Yup.string().required("Authority is required"),
          licenseNumber: Yup.string().required("Licence number is required"),
          dateOfExpiry: Yup.date().required("Licence Expiry Date is required"),
          images: Yup.array().of(Yup.string().required()).min(1, "Licence image is required")
        })
      })
    )
    .min(1)
});
const SimpleCustomerView: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer)
  const organisation = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [uploadLicenseDialogVisible, setUploadLicenseDialogVisible] = useState<boolean>(false);
  const [values, setValues] = useState<ISimpleCustomerValues>(defaultValues);
  const [licenseImages, setLicenseImages] = useState<string[]>([]);
  const [customerCreated, setCustomerCreated] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<string>("");
  const [countryCode, setCountry] = useState<any>(getDefaultCountryCode(country));
  const [uploadedImages, setUploadedImages] = useState<object[]>([]);
  const [minDate, setMinDate] = useState<string>(d.now().toFormat('dd/MM/yyyy'));
  const addDriverRef = useRef<any>(null);
  const [newDriverCount, setNewDriverCount] = useState<number>(0);
  const [addDriver, setAddDriver] = useState<boolean>(false);
  const [newlyAddedDrivers, setNewlyAddedDrivers] = useState<IDriverRow[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<IDriverRow | null>(null);
  const [availableCostCenters, setAvailableCostCenters] = useState<ICostCenter[]>([])
  const [costCenters, setCostCenters] = useState<ICostCenter[]>([{
    name: "",
    projectIds: []
  }]);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);

  const [createCustomer] = useMutation(CREATE_CUSTOMER_SIMPLE, {
    onCompleted: (data) => {
      if (data && data.createCustomer) {
        const driverArr: string[] = [];
        if (data.createCustomer.approvedDrivers) {
          data.createCustomer.approvedDrivers.forEach((driver: any) => {
            if (driver && driver.id) {
              driverArr.push(driver.id);
            }
          });
        }
        setNewDriverCount(data.createCustomer.approvedDrivers.length)
        props.updateCustomer({
          customer: data.createCustomer.id,
          approvedDrivers: driverArr
        });
        return snackbar({
          message: "Customer saved successfully",
          variant: SnackBarVariant.SUCCESS
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setCustomerCreated(false);
    }
  });

  useEffect(() => {
    setCustomerCreated(false); // FIXME: handle this through form
  }, [values]);

  useEffect(() => {
    if (organisation && organisation.enableCostCenter && organisation.costCenters) {
      setAvailableCostCenters(organisation.costCenters)
    }
  }, [organisation])

  const uploadCustomerLicenseImage = async (
    files: File[]
  ) => {
    try {
      const licenceImageArrya: any[] = [];
      const showUploadedImages: any[] = [];
      if (licenseImages && licenseImages.length >= 2) {
        return snackbar({
          message: "Maximum two images can be uploaded for licence!",
          variant: SnackBarVariant.ERROR
        });
      }
      setUploadInProgress(true);
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        // 50MB limit exceeded
        if (file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
          const { fileExtension } = returnfileContentTypeAndExtention(file);
          const uniqueId = uuidv4();
          const key = `${uniqueId}.${fileExtension}`;
          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  licenceImageArrya.push(key);
                  snackbar({
                    message: "Licence Image Uploaded Successfully",
                    variant: SnackBarVariant.SUCCESS
                  });
                  const uploadedFile = await getUploadedImageByKey(key);
                  if (uploadedFile) {
                    showUploadedImages.push(uploadedFile);
                  }
                  setUploadInProgress(false);
                  setUploadLicenseDialogVisible(false);
                }
              });
          }
        } else {
          setUploadLicenseDialogVisible(true);
          setUploadInProgress(false);
          return snackbar({
            message: "Please only upload .jpeg/.jpg/.png file!",
            variant: SnackBarVariant.ERROR
          });
        }
      }
      setLicenseImages(licenceImageArrya);
      setUploadedImages(showUploadedImages);
      setValues({
        ...values,
        license: {
          ...values.license,
          images: licenceImageArrya
        }
      })
    } catch (err: any) {
      snackbar({
        message: formatGraphQLErrorMessage(err.message),
        variant: SnackBarVariant.ERROR
      });
    }
  };

  const addRows = () => {
    const rowsArr = [...costCenters];
    rowsArr.push({
      name: "",
      projectIds: []
    });
    setCostCenters(rowsArr);
  };

  const handleEditDriver = (newDriver: IDriverRow) => {
    const { verificationMethod, ...rest } = newDriver
    setSelectedDriver(rest);
    setNewDriverCount(prevCount => prevCount - 1);
    setEnableEdit(true)
  };

  const handleRemove = (email: string) => {
    const updatedDrivers = newlyAddedDrivers.filter((driver) => driver.email !== email)
    setNewlyAddedDrivers(updatedDrivers)
    setNewDriverCount(prevCount => prevCount - 1);
  }

  const handleSubmit = (newDriver: IDriverRow) => {
    const driverIndex = newlyAddedDrivers.findIndex(driver => driver.email === newDriver.email)
    setNewDriverCount(prevCount => prevCount + 1);
    setSelectedDriver(null)
    setAddDriver(false)
    setEnableEdit(false)
    if (driverIndex > -1) {
      newlyAddedDrivers[driverIndex] = newDriver
    } else {
      setNewlyAddedDrivers((prevDrivers) => [...prevDrivers, newDriver]);
    }
  };

  const getFeatures = (ccName: string) => {
    const ele = availableCostCenters.find((cc) => cc.name === ccName);
    if (ele) {
      return ele.projectIds
    }
    return []
  }

  const onDeleteCustomerImage = (deletedFileObject: File) => {
    const images = licenseImages;
    const previewImages = uploadedImages;
    const deletedImageIndex = images.findIndex((image) => image === deletedFileObject.name);
    if (deletedImageIndex) {
      images.splice(deletedImageIndex, 1);
      previewImages.splice(deletedImageIndex, 1);
    }
    setLicenseImages([...images]);
    setUploadedImages([...previewImages]);
  }

  const onSubmit = (values: any) => {
    delete values.costCenterName;
    if (organisation.enableCostCenter) {
      if (costCenters.length) {
        if (costCenters.some((cc) => !cc.name)) {
          snackbar({
            message: "Cost Center Details can't be empty! Please remove empty rows.",
            variant: SnackBarVariant.ERROR
          })
          return
        }
        values.costCenters = costCenters
      } else {
        snackbar({
          message: "Cost Center Details can't be empty!",
          variant: SnackBarVariant.ERROR
        })
        return
      }
    }
    const newCustomer = values;
    if (newDriverCount > 0) {
      const modifiedRows = newlyAddedDrivers.map((row: any) => {
        row.verificationMethod = "MANUAL";
        return row;
      });
      newCustomer.approvedDrivers = modifiedRows;
    }
    newCustomer.verificationMethod = "MANUAL";
    const { id, driverfirstName, driverlastName, driveremail, driverlicense, ...rest } = newCustomer;
    setCustomerCreated(true);
    createCustomer({
      variables: { customer: rest }
    });
  };

  const customerSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required.")
      .email("Please enter a valid email."),
    firstName: Yup.string()
      .max(50)
      .required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    location: Yup.object().shape({
      fullAddress: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      street: Yup.string().required("Street is required"),
    }).required("Location is required"),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required.")
    }),
  });
  return (
    <Grid container item xs={12}>
      <Formik
        enableReinitialize
        validationSchema={customerSchema}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          const updateValues = {
            ...values,
            phoneNumber: {
              ...values.phoneNumber,
              country: countryCode
            }
          }
          onSubmit(updateValues);
          setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <Form style={{ width: "100%" }}>
            <Grid container item xs={12} spacing={2}>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item container xs={12}>
                <Typography variant={"subtitle1"}>Customer Detail</Typography>
              </Grid>
              <Grid item container xs={6} sm={3}>
                <Field
                  component={InputField}
                  placeholder="First Name"
                  label="First Name"
                  name={"firstName"}
                  values={formikProps.values.firstName}
                  onChange={formikProps.handleChange}
                  validate={(value: string) =>
                    validateName(value, "First Name")
                  }
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                  required
                ></Field>
              </Grid>
              <Grid item container xs={6} sm={3}>
                <Field
                  component={InputField}
                  placeholder="Last Name"
                  label="Last Name"
                  name={"lastName"}
                  values={formikProps.values.lastName}
                  onChange={formikProps.handleChange}
                  validate={(value: string) =>
                    validateName(value, "Last Name")
                  }
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                  required
                ></Field>
              </Grid>
              <Grid item container xs={12} sm={4}>
                <AddressPicker
                  fieldName="location"
                  required={true}
                  onChange={(address: IAddress) => {
                    if (address) {
                      setValues({ ...formikProps.values, location: address })
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Field
                  component={InputField}
                  name={"phoneNumber.phone"}
                  required
                  fullWidth
                >
                  {({ form: { setFieldValue, setTouched, touched, errors } }: FieldProps) => {
                    return (
                      <div className={`phone-input-container`}>
                        <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                          Phone Number <sup className={"phone-input-required"}>*</sup>
                        </label>
                        <PhoneInput
                          international={false}
                          defaultCountry={countryCode}
                          placeholder="Enter phone number"
                          className={phoneError ? "phone-input-error" : ""}
                          value={formikProps.values.phoneNumber.phone}
                          name={"phoneNumber.phone"}
                          required
                          onChange={(val: any) => {
                            if (val && isValidPhoneNumber(val)) {
                              setPhoneError("");
                            }
                            setValues({
                              ...formikProps.values,
                              phoneNumber: {
                                ...formikProps.values.phoneNumber,
                                phone: val
                              }
                            });
                          }}
                          onBlur={() => {
                            if (!formikProps.values.phoneNumber.phone) {
                              setPhoneError("Phone number is required.");
                            } else if (!isValidPhoneNumber(formikProps.values.phoneNumber.phone)) {
                              setPhoneError("Enter a valid phone number.");
                            } else {
                              setPhoneError("");
                            }
                          }}
                          onCountryChange={(val) => {
                            setCountry(val);
                          }}
                        />
                        {
                          phoneError &&
                          <span className={"phone-error-message"}>{phoneError || "Phone number is required."}</span>
                        }
                      </div>
                    );
                  }}
                </Field>
              </Grid>
              <Grid item container xs={12} sm={3}>
                <Field
                  component={InputField}
                  placeholder="Email Address"
                  label="Email Address"
                  name={"email"}
                  value={formikProps.values.email}
                  onChange={formikProps.handleChange}
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                  required
                ></Field>
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={InputField}
                  placeholder="Driving License Number"
                  label="Driving License Number"
                  name={"license.licenseNumber"}
                  value={formikProps.values.license.licenseNumber}
                  onChange={formikProps.handleChange}
                  inputProps={{ maxLength: 20 }}
                  fullWidth
                ></Field>
              </Grid>
              <Grid item container xs={12} sm={12} md={3} lg={3} xl={3}>
                <Field
                  component={InputField}
                  placeholder="Licence Issued By"
                  label="Licence Issued By"
                  name={"license.authority"}
                  value={formikProps.values.license.authority}
                  onChange={formikProps.handleChange}
                  inputProps={{ maxLength: 100 }}
                  fullWidth
                ></Field>
              </Grid>
              <Grid item container xs={12} sm={12} md={3} lg={3} xl={3}>
                <FormControl variant="outlined" fullWidth>
                  <FlatPickerBar
                    enableTime={false}
                    handleDateChange={(value: Date) => {
                      const license = {
                        ...formikProps.values.license,
                        dateOfExpiry: d.fromJSDate(value).toUTC().toISO()
                      };
                      formikProps.setFieldValue("license", license);
                    }}
                    label="Driving Licence Expiry Date"
                    identifier={"dateOfExpiry"}
                    placeholderValue={" Driving Licence Expiry Date"}
                    value={getLocalizedDateFormat(country, formikProps.values.license.dateOfExpiry, DATE_TYPE.CONDENSED)}
                    minDate={getLocalizedDateFormat(country, props?.licenseMinDate || minDate, DATE_TYPE.CONDENSED)}
                    country={country}
                  />
                </FormControl>
              </Grid>
              <Grid item container xs={12} sm={12} md={3} lg={3} xl={3}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.taxExempted}
                        onChange={() => {
                          setValues({
                            ...formikProps.values,
                            taxExempted:
                              !formikProps.values.taxExempted
                          });
                        }}
                        value="taxExempted"
                        color="secondary"
                      />
                    }
                    label={
                      <Typography variant={"h5"}>
                        {"Check this for VAT/TAX Exempt Customer"}
                      </Typography>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item container xs={12} sm={12} md={3} lg={3} xl={3}>
                <Fab
                  className="blackBackButton"
                  variant="extended"
                  size="medium"
                  aria-label="Update"
                  onClick={() => {
                    setValues(formikProps.values);
                    setUploadLicenseDialogVisible(true);
                  }}
                >
                  UPLOAD LICENCE IMAGE
                </Fab>
                <Grid item>
                  <Typography variant="h4">
                    Upload front and back image of Licence Supported file format: .png,
                    .jpeg, .jpg!.
                  </Typography>
                </Grid>
              </Grid>
              {organisation.enableCostCenter && availableCostCenters.length > 0 &&
                <Grid container xs={12} style={{ margin: "0.8rem" }}>
                  <Grid container xs={12}>
                    <Grid item xs={11}>
                      <Typography
                        variant={"subtitle1"}
                      >
                        Cost Center Details
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        variant={"subtitle1"}
                        onClick={addRows}
                        style={{ cursor: "pointer" }}
                      >
                        + Add New
                      </Typography>
                    </Grid>
                  </Grid>
                  {costCenters.map((item, idx) => {
                    return (
                      <Grid container xs={12} style={{ marginTop: "1rem" }}>
                        <Grid container xs={11} spacing={2}>
                          <Grid item xs={12} md={4}>
                            <Field
                              component={TextField}
                              required
                              label={"Cost Center"}
                              select
                              inputProps={{
                                onChange: (event: any) => {
                                  if (costCenters.some((cc) => cc.name === event.target.value)) {
                                    return snackbar({
                                      message: "Cost center already selected!",
                                      variant: SnackBarVariant.ERROR
                                    })
                                  }
                                  const item = costCenters[idx];
                                  const rowsArr = [...costCenters];
                                  const ele = availableCostCenters.find((element) => element.name === event.target.value)
                                  if (ele) {
                                    item.name = ele.name;
                                  }
                                  rowsArr.splice(idx, 1, item);
                                  setCostCenters(rowsArr);
                                },
                                value: item.name
                              }}
                              name={"costCenterName"}
                              fullWidth
                            >
                              {availableCostCenters.map((cc) => (
                                <MenuItem
                                  key={cc.name}
                                  value={cc.name}
                                >
                                  {cc.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <NuvvenAutoCompleteTags
                              label="Project Ids"
                              placeHolder="Project Ids"
                              singleLine={true}
                              freeSoloDisabled={true}
                              handleTags={(tags: string[]) => {
                                const item = costCenters[idx];
                                const rowsArr = [...costCenters];
                                item.projectIds = tags;
                                rowsArr.splice(idx, 1, item);
                                setCostCenters(rowsArr);
                              }}
                              customTags={true}
                              values={item.projectIds}
                              features={
                                getFeatures(item.name)
                              }
                              columnLarge={12}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            variant={"subtitle1"}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const rowsArr = [...costCenters];
                              rowsArr.splice(idx, 1);
                              setCostCenters(rowsArr);
                            }}
                          >
                            X
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              }
              {props.driverCount > 0 &&
                (<Grid item container xs={12}>
                  <Grid item container xs={11}>
                    <Typography variant={"subtitle1"}>
                      Additional Drivers
                    </Typography>
                  </Grid>
                  <Grid item container xs={1}>
                    <Typography
                      variant={"subtitle1"}
                      onClick={() => {
                        if (newDriverCount >= props.driverCount) {
                          return snackbar({
                            message: "Additional Drivers cannot be more than Addon quantity",
                            variant: SnackBarVariant.ERROR
                          });
                        } else {
                          setAddDriver(true)
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      + Add New Driver
                    </Typography>
                  </Grid>
                  {addDriver && (
                    <Grid item container xs={12}>
                      <AddDrivers
                        saveDriverLoading={false}
                        handleSubmit={handleSubmit}
                        openForm={() => setAddDriver(false)}
                        selectedDriver={selectedDriver}
                      />
                    </Grid>
                  )}
                  {newlyAddedDrivers.length ? (
                    <TableContainer component={Paper} className={classes.tableContainer}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography variant="h4">Name</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h4">Email</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="h4">Action</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {newlyAddedDrivers.map((driver: IDriverRow) => (
                            <TableRow key={driver.id}>
                              <TableCell>{`${driver.firstName} ${driver.lastName}`}</TableCell>
                              <TableCell>{driver.email}</TableCell>
                              <TableCell>
                                {!driver.id && (
                                  <IconButton
                                    color="primary"
                                    aria-label="edit"
                                    onClick={() => handleEditDriver(driver)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                )}
                                <IconButton
                                  color="secondary"
                                  aria-label="delete"
                                  disabled={enableEdit}
                                  onClick={() => handleRemove(driver.email)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : <></>}
                </Grid>
                )}
              <Grid item container xs={12}>
                <Fab
                  variant="extended"
                  size="medium"
                  type="submit"
                  disabled={customerCreated}
                >
                  Save
                </Fab>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <div>
        <DragDropImages
          open={uploadLicenseDialogVisible}
          handleCloseDragDrop={() => setUploadLicenseDialogVisible(false)}
          handleSave={uploadCustomerLicenseImage}
          initialFiles={uploadedImages}
          filesLimit={2}
          title={"Upload Licence Images"}
        />
      </div>
    </Grid>
  );
};

export default SimpleCustomerView;
