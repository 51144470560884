import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  CircularProgress,
  Fab
} from "@mui/material";
import React from "react";
import { FilePicker } from "../FilePicker/FilePicker";
import InputField from "../InputField";
import {
  IDvlaCsvUploadErrors,
  CsvUploadStatusType,
  ICsvUploadError,
  DvlaUploadErrorMessage
} from "../utils";

interface IBulkCsvUploadProps {
  title: string;
  subTitle?: string;
  csvTemplateLink: string;
  uploadStatus: CsvUploadStatusType;
  uploadErrors: ICsvUploadError[];
  dvlaUploadErrors?: IDvlaCsvUploadErrors[];
  fileName: string;
  handleClose: () => void;
  onDocumentSelect: (event: any) => void;
  uploadCsv: () => void;
  isVehicleCsv?: boolean;
  message?: string;
}

export const BulkCsvUploadDialog: React.FC<IBulkCsvUploadProps> = (props) => {
  return (
    <Dialog
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "20px 24px 10px" }}>
        <Typography variant="h2">{props.title}</Typography>
      </DialogTitle>
      <DialogContent style={{ overflowY: "hidden", paddingBottom: "20px" }}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Download templates .csv file:
              <a
                style={{ color: "#000" }}
                href={props.csvTemplateLink}
              >
                {" "}
                {props.subTitle}{" "}
              </a>
            </Typography>
            {props.isVehicleCsv && <Typography variant="body1">
              Download frog diagrams template:
              <a
                style={{ color: "#000" }}
                href={"https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Coastr-Vehicle-Frog-Diagrams.csv"}
              >
                {" "}
                {"Frog Diagram"}{" "}
              </a>
            </Typography>}
            {props.message && <Typography variant="body1" style={{ marginTop: 8 }}>
              {`(${props.message})`}
            </Typography>}
          </Grid>
          <Grid container item xs={8}>
            <InputField
              handleInput={() => { }}
              placeholder={"File Name"}
              value={props.fileName}
              name={"fileName"}
              disabled
            />
          </Grid>
          <Grid container item xs={4}>
            <FilePicker
              onFilePick={(e: any) => {
                props.onDocumentSelect(e);
              }}
              accept=".csv"
            />
          </Grid>
          <Grid container item xs={6}>
            {props.uploadStatus === CsvUploadStatusType.IN_PROGRESS ? (
              <CircularProgress />
            ) : (
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="uploadCsv"
                onClick={props.uploadCsv}
                disabled={!props.fileName}
              >
                Upload
              </Fab>
            )}
          </Grid>
          {props.uploadErrors.length > 0 && (
            <Grid item container xs={12}>
              <div>
                {props.uploadErrors.map((upldError: ICsvUploadError, idx: number) => {
                  return (upldError.message.includes("Upgrade your plan to add more vehicles") ? 
                  <div>
                    <Typography variant="h6" component="div" style={{ paddingLeft: '2px', marginTop: '8px' }}>
                      <strong>Upgrade Your Plan</strong>
                    </Typography>
                    <Typography>
                      You've reached your Fleet Tier limit. Please request an increase by filling out this {" "}
                      <a href="https://share.hsforms.com/17RCR7Ke1S76Q9pzR-DMNCA34aut" target="_blank" rel="noopener noreferrer">form</a>.
                    </Typography>
                  </div> : <div
                      key={idx}
                    >{`${props.title} can\'t be uploaded at row ${upldError.index +
                      1} because ${upldError.message}`}</div>);
                })}
              </div>
            </Grid>
          )}
          {props.dvlaUploadErrors && props.dvlaUploadErrors.length > 0 && (
            <Grid item container xs={12}>
              <div>
                {props.dvlaUploadErrors.map((i: IDvlaCsvUploadErrors) => {
                  return (
                    <div key={i.licencePlate}>
                      {DvlaUploadErrorMessage[i.error]} : {i.licencePlate}
                    </div>
                  );
                })}
              </div>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

