import React from 'react';
import './index.scss';
import DeleteIcon from '@mui/icons-material/Delete';

interface IImageProps {
  uploadedFiles: any[];
  onRemoveImage: (image: any) => void;
}

const ImagePreview: React.FC<IImageProps> = ({ uploadedFiles, onRemoveImage }) => {
  if (!uploadedFiles.length) {
    return (
      <div className="image-preview">
        <p>No images to display.</p>
      </div>
    );
  }

  return (
    <div className="image-preview">
      <span className="preview">Preview:</span>
      <div className="image-container">
        {uploadedFiles.map((uploadedFile, index) => (
          <div key={index} className="image-item">
            <img
              className="image-size"
              src={uploadedFile.url}
              alt={`Preview ${index}`}
            />
            <button
              className="remove-button"
              onClick={() => onRemoveImage(uploadedFile)}
            >
              <DeleteIcon style={{ fontSize: 18, height: 18, width: 18 }} />
            </button>
            <p className="image-name">{uploadedFile.file.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImagePreview;
