import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Hidden,
  Switch,
  Typography
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./index.scss"

import { DefaultAddons, IAddonType } from "../../../../../reducers/addonType/types";
import { IAddonRequirementInput } from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { getAddonIcon, toCurrency } from "../../../../common/utils";

interface IProps {
  addon: IAddonType;
  rateTypeName: string;
  selectedAddon?: IAddonRequirementInput;
  onSelect(addon?: IAddonType, rate?: number, quantity?: number): void;
}
export const CustomCard: React.FC<IProps> = (props) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [selected, setSelected] = useState<boolean>(
    !!props.selectedAddon?.addon
  );
  const { addon, selectedAddon, onSelect } = props;
  const { locale, currency } = userState.currentOrganisation;
  const recurringRate = props.addon.recurringRate?.find(
    (rate) => rate.rateTypeName === props.rateTypeName
  );
  const rate = props.addon.hasFixedRate
    ? props.addon.fixedRate
      ? props.addon.fixedRate
      : 0
    : recurringRate
      ? recurringRate.rate
      : 0;
  useEffect(() => {
    setSelected(!!props.selectedAddon?.name);
  }, [props.selectedAddon]);

  useEffect(() => {
    if (!selected && props.selectedAddon?.name) {
      props.onSelect(addon);
    }
  }, [selected]);

  return (
    <Grid container item xs={12}>
      <Hidden smDown>
        <Grid container item xs={1} alignItems="center">
          <img
            src={getAddonIcon(props.addon.name === DefaultAddons.ADDITIONAL_DRIVER ? DefaultAddons.ADDITIONAL_DRIVER : "custom-addon")}
            style={{ width: "100%", height: 75 }}
            alt="custom addon"
          />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={7}>
        <Grid item xs={12}>
          <Typography variant="h2">
            {addon.displayName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box mt={0.5}></Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"subtitle2"}>{addon.description}</Typography>
        </Grid>
      </Grid>
      <Hidden smUp>
        <Grid container item xs={4} alignItems="center">
          <img
            src={getAddonIcon(props.addon.name === DefaultAddons.ADDITIONAL_DRIVER ? DefaultAddons.ADDITIONAL_DRIVER : "custom-addon")}
            style={{ width: "100%", height: 75 }}
            alt="custom addon"
          />
        </Grid>
      </Hidden>
      <Grid container item xs={8} sm={4}>
        <Grid container item xs={12}>
          <Grid
            container
            item
            xs={12}
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            <Typography variant="h4" style={{ marginRight: 16, marginTop: 2 }}>
              {props.addon.name === DefaultAddons.ADDITIONAL_DRIVER ? `Total Charge: ${toCurrency(((selectedAddon?.quantity || 0) * rate), currency, locale) || 0}` : `Currently Available: ${props.addon.availableQuantity || 0}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}><Box mt={1.2}></Box></Grid>
        <Grid container item xs={12}>
          <Hidden smUp>
            <Grid item xs={4}>
              <Box />
            </Grid>
          </Hidden>
          <Grid container item xs={2} sm={8} justifyContent="flex-end" alignItems="center">
            <Button
              className="counter-addon-button left"
              aria-label="reduce"
              onClick={() =>
                onSelect(addon, rate, (selectedAddon?.quantity || 1) - 1)
              }
            >
              <RemoveIcon fontSize="small" />
            </Button>
            <Typography variant="body1" className="custom-addon-count">
              {props.selectedAddon?.quantity || 0}
            </Typography>
            <Button
              className="counter-addon-button right"
              aria-label="increase"
              onClick={() => {
                if (props.addon.name === DefaultAddons.ADDITIONAL_DRIVER) {
                  onSelect(addon, rate, (selectedAddon?.quantity || 0) + 1)
                } else {
                  const count = (selectedAddon?.quantity || 0) + 1;
                  if ((props.addon.availableQuantity || 0) >= count) {
                    onSelect(addon, rate, count);
                  }
                }
              }}
            >
              <AddIcon fontSize="small" />
            </Button>
          </Grid>
          <Grid container item xs={6} sm={4} alignItems="center" justifyContent="flex-end">
            <Typography
              variant="h2"
              style={{ marginRight: 16, fontWeight: 700 }}
            >
              {toCurrency(rate, currency, locale)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}><Box mt={0.5}></Box></Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormControlLabel
            control={
              <Switch
                checked={selected}
                onChange={() => {
                  if (selected) {
                    setSelected(!selected);
                  }
                }}
                name="Select"
                color="primary"
              />
            }
            label="Selected"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
