import { gql } from "@apollo/client";

export const CREATE_SERVICELOCATION = gql`
  mutation createServiceLocation(
    $organisationId: ID!
    $branchId: ID!
    $serviceLocation: ServiceLocationInput!
  ) {
    createServiceLocation(
      organisationId: $organisationId
      branchId: $branchId
      serviceLocation: $serviceLocation
    ) {
      id
      name
      isDisabled
      branch {
        id
        name
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
    }
  }
`;
