import { gql } from "@apollo/client";

export const GET_PRICE_RULES = gql`
  query priceRules($limit: Int!, $offset: Int!) {
    priceRules(limit: $limit, offset: $offset) {
      id
      name
      vehicleGroups
      customers
      serviceLocations
      operator
      isActive
      rules{
        value
        taxable
      }
    }
  }
`;

export const GET_PRICE_RULES_COUNT = gql`
  {
    priceRulesCount
  }
`;

export const SEARCH_PRICE_RULES = gql`
  query searchPriceRules($q: String!) {
    searchPriceRules(q: $q) {
      id
      name
      vehicleGroups
      customers
      serviceLocations
      operator
      isActive
      rules{
        value
        taxable
      }
    }
  }
`;
