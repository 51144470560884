import { useMutation } from "@apollo/client";
import { Box, CircularProgress, Fab, FormControl, Grid, MenuItem, TextField, Typography, Theme, Hidden, Card } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { createStyles, makeStyles } from "@mui/styles";
import { TextField as InputField } from "formik-mui";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../../../../actions/user/actions";
import { UPDATE_ORGANISATION } from "../../../../../graphql/organisation/updateOrganisationMutation";
import { IOrganisation, IOrganisationWrite, IRefundRule } from "../../../../../reducers/organisation/types";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { UserRoles } from "../../../../hoc/Authorization";
import * as Yup from "yup";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";

interface IProps {
  organisation: IOrganisation;
  step: Number;
}

export const TIME_UNITS: any = [
  {
    value: "HOURS",
    label: "HOURS"
  },
  {
    value: "DAYS",
    label: "DAYS"
  }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileBorder: {
      [theme.breakpoints.down('sm')]: {
        border: '1px solid'
      }
    },
    desktopPadding: {
      [theme.breakpoints.up('sm')]: {
        marginTop: "15px",
        padding: "10px",
      }
    },


  })
);

const RefundsPolicy: React.FC<IProps> = (props) => {
  const newProps = props;
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const styles = useStyles();
  const navigate = useNavigate();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<IOrganisation>(props.organisation);
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const [refundRules, setRefundRules] = useState<IRefundRule[]>([{
    charge: 0,
    timeFrame: 0,
    timeUnit: "DAYS"
  }]);

  const [updateOrganisation] = useMutation(UPDATE_ORGANISATION, {
    onCompleted: (data: any) => {
      setLoading(false);
      if (userReducer.currentOrganisation.id === data.updateOrganisation.id) {
        dispatch(
          updateUser({
            ...userReducer,
            currentOrganisation: data.updateOrganisation
          })
        );
      }
      snackbar({
        message: "Organisation updated",
        variant: SnackBarVariant.SUCCESS
      });
      navigate(
        `/update-organisation?organisation=${props.organisation.id}&step=${props.step}`
      );
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  useEffect(() => {
    if (props.organisation) {
      setValues(props.organisation);
      if (props.organisation?.refundPolicy) {
        setRefundRules(props.organisation.refundPolicy.refundRules)
      }
    }
  }, [props.organisation]);

  const organisationSchema = Yup.object().shape({

  });

  const addRows = () => {
    const rowsArr = [...refundRules];
    rowsArr.push({
      charge: 0,
      timeFrame: 0,
      timeUnit: "DAYS"
    });
    setRefundRules(rowsArr);
  };

  const handleSubmit = () => {
    if (!errorFlag) {
      const { id, branches, rate, ...rest } = { ...values };

      const refundRulesSet = new Set(refundRules.map((rule: IRefundRule) => `${rule.timeUnit}${rule.timeFrame}`));
      if (rest.refundPolicy) {
        if (refundRulesSet.size === refundRules.length) {
          const updatedRules = {
            ...rest,
            refundPolicy: {
              ...rest.refundPolicy,
              refundRules: refundRules
            }
          }
          if (props.organisation.id) {
            updateOrganisation({
              variables: {
                organisationId: props.organisation.id,
                organisation: updatedRules
              }
            });
          }
        } else {
          snackbar({
            message: "Duplicate rules can't be set",
            variant: SnackBarVariant.ERROR
          })
        }

      }
    }
  }

  const RefundContent = ({ row, idx }: { row: IRefundRule, idx: number }) => {
    return <>
      <Grid
        item
        container
        xs={12} sm={11}
        style={{ marginTop: "20px" }}
      >
        <Hidden smUp>
          <Grid
            item
            container
            xs={12} sm={3}
            justifyContent='center'
            style={{ paddingRight: "10px" }}
          >
            <Typography variant={"body1"}>
              Within
            </Typography>
          </Grid>
          <Spacer />
        </Hidden>
        <Hidden smDown>
          <Grid
            item
            container
            xs={12} sm={3}
            style={{ paddingRight: "10px" }}
          >
            <Typography variant={"body1"}>
              Within
            </Typography>
          </Grid>
        </Hidden>
        <Grid
          item
          container
          xs={6} sm={3}
          style={{ paddingRight: "10px" }}
        >
          <Field
            component={InputField}
            fullWidth
            placeholder="Enter Number"
            label="Enter Number"
            name={`timeFrame${idx}`}
            InputProps={{
              value: row.timeFrame,
              onChange: (
                e: React.ChangeEvent<HTMLInputElement>
              ) => {
                const val = e.target.value || "0";
                const rowsArr = [...refundRules];
                let updatedRefunds: IRefundRule[] = [
                  ...rowsArr.slice(0, idx),
                  {
                    ...rowsArr[idx],
                    timeFrame: parseInt(val)
                  },
                  ...rowsArr.slice(idx + 1)

                ]
                setRefundRules(updatedRefunds);
                setErrorFlag(false);
              }
            }}
            validate={() => {
              if (row.timeFrame < 0) {
                setErrorFlag(true)
                return `Time cannot be a negative value`
              }
            }}
            inputProps={{
              maxIntegerDigitsLength: 2,
              allowNegative: false,
              maxlength: 9
            }}
          ></Field>
        </Grid>
        <Grid
          item
          container
          xs={6} sm={3}
          style={{ paddingRight: "10px" }}
        >
          <FormControl variant="outlined" fullWidth>
            <Field
              component={TextField}
              name={`timeUnit${idx}`}
              fullWidth
              required
              label={"Select Time Unit"}
              type="text"
              select
              inputProps={{
                onChange: (e: any) => {
                  const rowsArr = [...refundRules];
                  let updatedRefunds: IRefundRule[] = [
                    ...rowsArr.slice(0, idx),
                    {
                      ...rowsArr[idx],
                      timeUnit: e.target.value || "DAYS"
                    },
                    ...rowsArr.slice(idx + 1)

                  ]
                  setRefundRules(updatedRefunds);
                },
                value: row.timeUnit
              }}
            >
              {TIME_UNITS.map((item: any, index: number) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Field>
          </FormControl>
        </Grid>
        <Hidden smUp>
          <Spacer />
        </Hidden>
        <Grid
          item
          container
          xs={12} sm={3}
          style={{ paddingRight: "10px" }}
        >
          <Field
            component={InputField}
            placeholder="Cancellation Charge (%)"
            label="Cancellation Charge (%)"
            name={`charge${idx}`}
            InputProps={{
              value: row.charge,
              inputComponent: FloatInput as any,
              onChange: (
                e: React.ChangeEvent<HTMLInputElement>
              ) => {
                if (parseFloat(e.target.value) > 10000) {
                  snackbar({
                    message:
                      "Cancellation charge can't be more than 100%",
                    variant: SnackBarVariant.ERROR
                  });
                } else {
                  const val = e.target.value || "0";
                  const rowsArr = [...refundRules];
                  let updatedRefunds: IRefundRule[] = [
                    ...rowsArr.slice(0, idx),
                    {
                      ...rowsArr[idx],
                      charge: parseInt(val)
                    },
                    ...rowsArr.slice(idx + 1)

                  ]
                  setRefundRules(updatedRefunds);
                  setErrorFlag(false)
                }
              }
            }}
            validate={() => {
              if (row.charge > 10000) {
                setErrorFlag(true)
                return `Cancellation charge can't be more than 100%`
              }
            }}
            inputProps={{
              allowNegative: false
            }}
            fullWidth
          ></Field>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12} sm={1}
        justifyContent='center'
        className={styles.desktopPadding}
      >
        <CancelIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            const rowsArr = [...refundRules];
            rowsArr.splice(idx, 1);
            setRefundRules(rowsArr);
          }}
        />
      </Grid>
    </>;
  }


  function Spacer() {
    return (
      <Grid item xs={12}>
        <Box mt={1} />
      </Grid>
    )
  }

  return (
    <Grid container style={{ padding: 30 }}>
      <Formik
        enableReinitialize
        validationSchema={organisationSchema}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          handleSubmit();
          setSubmitting(false);
        }}
      >
        {(props) => (
          <Form noValidate style={{ width: "100%" }}>
            <Grid container item xs={12}>
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid item container xs={12} sm={6}>
                  <Typography variant="h4">
                    Flat Cancellation Charges:
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <TextField
                    placeholder="Amount"
                    label="Amount"
                    name={"flatCharges"}
                    InputProps={{
                      value: values.refundPolicy?.flatCharge || 0,
                      onChange: (e) => {
                        const val = e.target.value || "0";
                        setValues({
                          ...values,
                          refundPolicy: {
                            refundRules,
                            flatCharge: parseInt(val)
                          }
                        })
                      },
                      inputComponent: FloatInput as any
                    }}
                    inputProps={{
                      hasCurrencyPrefix: true,
                      allowNegative: false,
                      currency: newProps.organisation.currency
                    }}
                    fullWidth
                  ></TextField>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                {" "}
                <Box mt={2}></Box>
              </Grid>
              <Grid item container xs={12} style={{}}>
                <Grid item xs={12} sm={10}>
                  <Typography variant="subtitle1">
                    Refund Rules
                  </Typography>
                </Grid>
                <Hidden smDown>
                  <Grid item container xs={12} sm={2}>
                    <Typography
                      variant={"subtitle1"}
                      onClick={addRows}
                      style={{ cursor: "pointer" }}
                    >
                      + Add New
                    </Typography>
                  </Grid>
                </Hidden>
              </Grid>
              {refundRules.map((row: IRefundRule, idx: number) => {
                return (
                  <Grid item container xs={12}>
                    <Hidden smDown>
                      {RefundContent({ row: row, idx: idx })}
                    </Hidden>
                    <Hidden smUp>
                      <Card style={{
                        marginTop: '8px',
                        marginBottom: '8px',
                        border: "1px solid",
                        borderRadius: "3%"
                      }}>
                        {RefundContent({ row: row, idx: idx })}
                      </Card>
                    </Hidden>
                  </Grid>
                );
              })}
              <Hidden smUp>
                <Grid item container xs={12} sm={2}>
                  <Typography
                    variant={"subtitle1"}
                    onClick={addRows}
                    style={{ cursor: "pointer" }}
                  >
                    + Add New
                  </Typography>
                </Grid>
              </Hidden>
              <Grid item container>
                {" "}
                <Box mt={2}></Box>
              </Grid>
              <Grid item container xs={12}>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="add"
                  type="submit"
                  disabled={
                    errorFlag ||
                    userReducer.role === UserRoles.BRANCH_MANAGER
                  }
                >
                  {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                  Save
                </Fab>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default RefundsPolicy;