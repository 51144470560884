import { gql } from "@apollo/client";

export const UPLOAD_VEHICLE_SERVICE = gql`
  mutation uploadVehicleService($vehicleServices: [VehicleServiceUploadInput]!) {
    uploadVehicleService(vehicleServices: $vehicleServices) {
      errors {
        index
        message
      }
      vehicleServices {
        id
      }
    }
  }
`;