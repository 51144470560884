import {
  Fab,
  FormControl,
  Grid,
  MenuItem,
  TextField
} from "@mui/material";
import { Field } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { IOrganisation } from "../../../reducers/user/types";
import { IAppState } from "../../../store";
import { getLocalizedOrganisationSyntex } from "../../../utils/localized.syntex";

interface Props {
  organisationId: string;
  organisations: IOrganisation[];
  handleOrganisationSelection: (id: string) => void;
}

export const OrganisationSelector = (props: Props) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  return (
    <Grid item container xs={12} sm={12}>
      <FormControl variant="outlined" fullWidth>
        <Field
          component={TextField}
          name={"organisationId"}
          fullWidth
          type="text"
          select
          label={getLocalizedOrganisationSyntex(country)}
          value={props.organisationId}
          InputLabelProps={{
            shrink: true
          }}
          placeholder={`Select ${getLocalizedOrganisationSyntex(country)}`}
          inputProps={{
            onChange: (e: any) =>
              props.handleOrganisationSelection(e.target.value)
          }}
        >
          {props.organisations &&
            props.organisations.map(
              (organisation: IOrganisation) => (
                <MenuItem
                  key={organisation.id}
                  value={organisation.id}
                >
                  {organisation.name}
                </MenuItem>
              )
            )}
        </Field>
      </FormControl>
    </Grid>
  )
}