import { useLazyQuery } from "@apollo/client";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DateTime as d } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { GET_CUSTOMERS } from "../../../../../graphql/customers/getCustomers";
import { DefaultAddons } from "../../../../../reducers/addonType/types";
import { BOOKING_CATEGORY, BOOKING_SOURCE, IBookingCreateInput, IBookingQuestionInput } from "../../../../../reducers/bookings/types";
import { BookingStatus, CustomerType, RATE_TYPES } from "../../utils";
import { BookingQuestions } from "./BookingQuestions";
import ExistingCustomer from "./CustomerView/ExistingCustomer";
import SimpleCustomerView from "./CustomerView/SimpleCustomerView";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { IBusinessCustomer, ICustomer } from "../../../../../reducers/customer/types";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { DATE_TYPE } from "../../../../common/utils";
import { IDriverRow } from "../../../../../reducers/user/types";
interface IProps {
  bookingData: IBookingCreateInput;
  bookingCreationFailed?: boolean;
  onSubmit: (data: IBookingCreateInput, updateOnly?: boolean) => void;
  onPrevious(): void;
  newAddedDrivers: (data: IDriverRow[]) => void;
  addedDrivers?: IDriverRow[];
  bookingLoading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

const PersonalDetails: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const bookingQuestionRef = useRef<any>();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer)
  const [businessCustomer, setBusinessCustomer] = useState<IBusinessCustomer>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [driverCount, setDriverCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState([]);
  const [loadingCustomer, setLoadingCustomer] = useState<boolean>(false)
  const [billingFrequency, setBillingFrequency] = useState<number>(0);
  const [zeroCostBooking, setZeroCostBooking] = useState(false);
  const [customer, setCustomer] = useState<ICustomer>();
  const [costCenter, setCostCenter] = useState<string>();
  const [projectId, setProjectId] = useState<string>();
  const [availableProjectIds, setAvailableProjectIds] = useState<string[]>([]);
  const [isLateDriverCount, setIsLateDriverCount] = useState<boolean>(false);

  const [loadIndividualCustomers, { loading: loadingCustomers, data: customerData }] = useLazyQuery(
    GET_CUSTOMERS,
    {
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (props.bookingData) {
      if (props.bookingData.costCenter) {
        setCostCenter(props.bookingData.costCenter)
        if (props.bookingData.projectId) {
          setProjectId(props.bookingData.projectId)
        }
      }
      if (props.bookingData.zeroCostBooking) {
        setZeroCostBooking(props.bookingData.zeroCostBooking);
      }
      if (props.bookingData.isSubscription) {
        setBillingFrequency(1)
      } else {
        if (props.bookingData.billingFrequency) {
          setBillingFrequency(props.bookingData.billingFrequency)
        } else {
          setBillingFrequency(0)
        }
      }
    }
  }, [props.bookingData])

  useEffect(() => {
    if (customerData && customerData.customers) {
      setCustomers(customerData.customers)
    }
  }, [customerData])

  useEffect(() => {
    if (props.bookingCreationFailed) {
      setLoading(false);
    }
  }, [props.bookingCreationFailed]);
  useEffect(() => {
    if (props.bookingLoading) {
      setLoading(true)
    }
  }, [props.bookingLoading])

  useEffect(() => {
    if (props.bookingData.addonRequirements) {
      const addon = props.bookingData.addonRequirements.find(item => item.name === DefaultAddons.ADDITIONAL_DRIVER);
      if (addon) {
        setDriverCount(addon.quantity);
      }
      setIsLateDriverCount(true)
    }
  }, [props.bookingData.addonRequirements]);

  useEffect(() => {
    if (customer && customer.costCenters) {
      if (costCenter) {
        setAvailableProjectIds(customer.costCenters?.find(cc => cc.name === costCenter)?.projectIds || [])
      } else {
        setAvailableProjectIds([])
      }
    }
  }, [costCenter, customer])

  const updateCustomer = ({
    customer,
    businessCustomer,
    approvedDrivers,
    customerData
  }: {
    customer?: string;
    businessCustomer?: string;
    approvedDrivers?: string[];
    customerData?: ICustomer;
  }) => {
    approvedDrivers = approvedDrivers || [];
    if (customer) {
      props.onSubmit({ ...props.bookingData, customer, approvedDrivers }, true);
    }
    if (businessCustomer) {
      props.onSubmit(
        { ...props.bookingData, businessCustomer, approvedDrivers },
        true
      );
    }
    if (customerData) {
      setCustomer(customerData)
    }
  };

  const fetchBillingOptions = () => {
    let options = [{
      label: "One Time",
      value: 0
    }]
    if (props.bookingData.isSubscription) {
      if (props.bookingData.rateTypeName === RATE_TYPES.MONTHLY) {
        options = [{
          label: "Every 1 month (calendar month)",
          value: 1
        }];
      } else if (props.bookingData.rateTypeName === RATE_TYPES.WEEKLY) {
        options = [
          {
            label: "Every 1 week",
            value: 1
          }
        ];
      }
    } else {
      if (props.bookingData.rateTypeName === RATE_TYPES.DAILY) {
        const dailyOptions = [
          {
            label: "Every 7 days",
            value: 7
          }, {
            label: "Every 14 days",
            value: 14
          }, {
            label: "Every 21 days",
            value: 21
          }, {
            label: "Every 28 days",
            value: 28
          }, {
            label: "Every 30 days",
            value: 30
          }, {
            label: "Every 31 days",
            value: 31
          }
        ]
        let diff = getBookingDurationDiff(props.bookingData.rateTypeName);
        dailyOptions.forEach(option => {
          if (diff >= option.value) {
            options.push(option)
          }
        })
      } else if (props.bookingData.rateTypeName === RATE_TYPES.WEEKLY) {
        const weeklyOptions = [
          {
            label: "Every 1 week",
            value: 1
          },
          {
            label: "Every 2 weeks",
            value: 2
          },
          {
            label: "Every 3 weeks",
            value: 3
          }
        ];
        let diff = getBookingDurationDiff(props.bookingData.rateTypeName);
        weeklyOptions.forEach((option) => {
          if (diff >= option.value) {
            options.push(option)
          }
        });
      } else if (props.bookingData.rateTypeName === RATE_TYPES.MONTHLY) {
        const monthlyOptions = [
          {
            label: "Every 1 month (calendar month)",
            value: 1
          },
          {
            label: "Every 2 months (calendar month)",
            value: 2
          },
          {
            label: "Every 3 months (calendar month)",
            value: 3
          }
        ];
        let diff = getBookingDurationDiff(props.bookingData.rateTypeName);
        monthlyOptions.forEach((option) => {
          if (diff > option.value) {
            options.push(option)
          }
        });
      }
    }
    return options;
  }

  const getBookingDurationDiff = (rateType: string) => {
    let str: any = "hour";
    switch (rateType) {
      case RATE_TYPES.DAILY:
        str = "day";
        break;
      case RATE_TYPES.WEEKLY:
        str = "weeks";
        break;
      case RATE_TYPES.MONTHLY:
        str = "months";
        break;
      default:
        break;
    }
    if (props.bookingData?.dropoffDateTime) {
      let diff = d.fromISO(props.bookingData.dropoffDateTime).diff(d.fromISO(props.bookingData.pickupDateTime), [str]).get(str);
      return diff
    }
    return 0;
  }

  if (loadingCustomers) {
    return (
      <Grid item xs={12} container justifyContent={"center"} alignItems={"center"} style={{ margin: "0 auto", marginTop: "20px", height: "50vh" }}>
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <Grid container>
      {/* Need to remove because steeper already have label */}
      {/* <Typography variant={"h2"}>Select Driver</Typography> */}
      <Grid container>
        <Paper style={{ width: "100%", marginTop: "20px" }}>
          <React.Fragment>
            <Tabs
              value={tabIndex}
              onChange={(event: any, value: number) => setTabIndex(value)}
              centered
            >
              {
                <Tab
                  label={
                    <Typography variant="body1">Existing Customer</Typography>
                  }
                />
              }
              {!(props.bookingData.id || props.bookingData.businessCustomer) && (
                <Tab
                  label={<Typography variant="body1">New Customer</Typography>}
                />
              )}
            </Tabs>

            <div className={"existingCustomer-container"}>
              <Typography
                component="div"
                role="tabpanel"
                hidden={tabIndex !== 0}
              >
                {(!props.bookingData.addonRequirements.length || isLateDriverCount) && <ExistingCustomer
                  {...(props.bookingData.businessCustomer && {
                    businessCustomer: props.bookingData.businessCustomer
                  })}
                  {...(props.bookingData.customer && {
                    customer: props.bookingData.customer
                  })}
                  loadingCustomer={(loading: boolean) => {
                    if (loading) {
                      setLoadingCustomer(loading)
                    } else {
                      setLoadingCustomer(false)
                    }
                  }}
                  customerType={props.bookingData.customerType}
                  setCustomerData={setCustomer}
                  updateCustomer={updateCustomer}
                  updateBusinessCustomerData={setBusinessCustomer}
                  approvedDrivers={props.bookingData.approvedDrivers}
                  addedDrivers={props.addedDrivers}
                  newAddedDrivers={(drivers) => props.newAddedDrivers(drivers)}
                  driverCount={driverCount}
                  isUpdate={props.bookingData.id ? true : false}
                  licenseMinDate={props.bookingData?.dropoffDateTime || d.now().toFormat(DATE_TYPE.CONDENSED)}
                />}
              </Typography>

              {!props.bookingData.businessCustomer && <Typography
                component="div"
                role="tabpanel"
                hidden={tabIndex !== 1}
              >
                <SimpleCustomerView
                  updateCustomer={updateCustomer}
                  driverCount={driverCount}
                  licenseMinDate={props.bookingData?.dropoffDateTime || d.now().toFormat(DATE_TYPE.CONDENSED)}
                />
              </Typography>}
            </div>
          </React.Fragment>
        </Paper>
      </Grid>
      {userState.currentOrganisation.enableCostCenter && customer?.costCenters && customer.costCenters.length > 0 &&
        <Grid container item xs={12} style={{ marginTop: "20px" }}>
          <Paper style={{ width: "100%", padding: "20px" }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Select Cost Center For Booking
              </Typography>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  id="free-solo-demo-cost"
                  options={customer.costCenters.map((cc) => cc.name)}
                  getOptionLabel={(option: any) => option}
                  onChange={(_event: any, value: any, reason: any) => {
                    if (reason === "clear" || reason === "removeOption") {
                      setCostCenter("")
                      setProjectId("")
                    }
                    if (reason === "selectOption") {
                      setCostCenter(value)
                    }
                  }}
                  value={costCenter}
                  renderInput={(params) => (
                    <TextField {...params}
                      label="Cost Center"
                      name="costCenter"
                      placeholder="Cost Center"
                      margin="normal"
                      variant="outlined"
                      style={{ backgroundColor: "white" }}
                    />
                  )}
                />
              </Grid>
              {availableProjectIds.length > 0 && (
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    id="free-solo-demo"
                    options={availableProjectIds}
                    getOptionLabel={(option: any) => option}
                    onChange={(_event: any, value: any, reason) => {
                      if (reason === "clear" || reason === "removeOption") {
                        setProjectId("")
                      }
                      if (reason === "selectOption") {
                        setProjectId(value)
                      }
                    }}
                    value={projectId}
                    renderInput={(params) => (
                      <TextField {...params}
                        label="Project Id"
                        name="projectId"
                        placeholder="Project Id"
                        margin="normal"
                        variant="outlined"
                        style={{ backgroundColor: "white" }}
                      />
                    )}
                  />
                </Grid>)}
            </Grid>
          </Paper>
        </Grid>
      }

      <Grid container item xs={12} style={{ marginTop: "20px" }}>
        <Paper style={{ width: "100%", padding: "20px" }}>
          <Grid container>
            <Grid item xs={6}>
              <Grid item xs={8}>
                <Typography variant="subtitle1" gutterBottom>
                  Choose Billing Type
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    value={billingFrequency}
                    disabled={props.bookingData.status === BookingStatus.CONFIRMED}
                    onChange={(e) => {
                      if (typeof e.target.value === "number") {
                        setBillingFrequency(e.target.value)
                      }
                    }}
                  >
                    {fetchBillingOptions().map(
                      (option: any, key: number) => {
                        return (
                          <MenuItem value={option.value} key={key}>
                            {option.label}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <BookingQuestions booking={props.bookingData} formikRef={bookingQuestionRef} />
      <Hidden smDown>
        <Grid container item xs={12} justifyContent={"flex-end"}>
          {/* No need for this button, as the step is now clickable in the stepper.  */}
          {/* <Fab
            className="blackBackButton"
            variant="extended"
            size="medium"
            onClick={props.onPrevious}
          >
            BACK
          </Fab> */}
          <Fab
            variant="extended"
            size="medium"
            onClick={() => {
              if (customer && userState.currentOrganisation.enableCostCenter && !costCenter) {
                return snackbar({
                  message: "Cost center info missing!",
                  variant: SnackBarVariant.ERROR
                })
              }
              const preBookingQuestionData: IBookingQuestionInput[] = []
              if (bookingQuestionRef && bookingQuestionRef.current) {
                bookingQuestionRef.current.handleSubmit();
                if (!bookingQuestionRef.current.isValid) {
                  return snackbar({
                    message: "Please answer required booking questions.",
                    variant: SnackBarVariant.ERROR
                  });
                }
                bookingQuestionRef.current.values.preBookingQuestions.filter((bq: IBookingQuestionInput) => {
                  preBookingQuestionData.push({
                    question: bq.question,
                    answerType: bq.answerType,
                    answer: bq.answer?.toString() || ""
                  })
                });
              }
              props.bookingData.preBookingQuestions = preBookingQuestionData;
              props.bookingData.billingFrequency = billingFrequency;
              props.bookingData.zeroCostBooking = zeroCostBooking;
              if (costCenter) {
                props.bookingData.costCenter = costCenter;
                if (projectId) {
                  props.bookingData.projectId = projectId;
                } else {
                  props.bookingData.projectId = ""
                }
              } else {
                props.bookingData.costCenter = ""
                props.bookingData.projectId = ""
              }
              if (billingFrequency) {
                props.bookingData.isRecurringBilling = true
              } else {
                props.bookingData.isRecurringBilling = false
              }
              if (props.bookingLoading) {
                setLoading(true);
              }
              props.onSubmit(props.bookingData);
            }}
            disabled={
              loading
            }
          >
            {(loading) && (
              <CircularProgress
                size={14}
                style={{ color: "white", marginRight: "10px" }}
              />
            )}
            {
              ([BOOKING_CATEGORY.COURTESY_CAR.toString(), BOOKING_CATEGORY.TEST_DRIVE.toString()].includes(props.bookingData.bookingCategory)) ? (
                !props.bookingData.id ? "Confirm" : "UPDATE"
              ) : (
                !props.bookingData.id ? "CREATE QUOTE" : props.bookingData.status === BookingStatus.QUOTE ? "UPDATE QUOTE" : "CONTINUE"
              )
            }
          </Fab>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <AppBar position="fixed" className={classes.appBar}>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={6}>
              <Button
                className={classes.bottomButton}
                variant="contained"
                aria-label="Back"
                onClick={props.onPrevious}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.bottomButton}
                variant="contained"
                aria-label="Continue"
                onClick={() => {
                  const preBookingQuestionData: IBookingQuestionInput[] = []
                  if (bookingQuestionRef && bookingQuestionRef.current) {
                    bookingQuestionRef.current.handleSubmit();
                    if (!props.bookingData.id) {
                      if (!bookingQuestionRef.current.isValid) {
                        return snackbar({
                          message: "Please answer required booking questions.",
                          variant: SnackBarVariant.ERROR
                        });
                      }
                    } else if (props.bookingData.id && props.bookingData.source === BOOKING_SOURCE.B2B) {
                      if (!bookingQuestionRef.current.isValid) {
                        return snackbar({
                          message: "Please answer required booking questions.",
                          variant: SnackBarVariant.ERROR
                        });
                      }
                    }
                    bookingQuestionRef.current.values.preBookingQuestions.filter((bq: IBookingQuestionInput) => {
                      preBookingQuestionData.push({
                        question: bq.question,
                        answerType: bq.answerType,
                        answer: bq.answer?.toString() || ""
                      })
                    });
                  }
                  props.bookingData.preBookingQuestions = preBookingQuestionData;
                  props.bookingData.billingFrequency = billingFrequency;
                  props.bookingData.zeroCostBooking = zeroCostBooking;
                  if (billingFrequency) {
                    props.bookingData.isRecurringBilling = true
                  } else {
                    props.bookingData.isRecurringBilling = false
                  }
                  setLoading(true);
                  props.onSubmit(props.bookingData);
                }}
                disabled={
                  loading
                }
              >
                {loading && (
                  <CircularProgress
                    size={14}
                    style={{ color: "white", marginRight: "10px" }}
                  />
                )}
                {
                  ([BOOKING_CATEGORY.COURTESY_CAR.toString(), BOOKING_CATEGORY.TEST_DRIVE.toString()].includes(props.bookingData.bookingCategory)) ? (
                    !props.bookingData.id ? "CONFIRM" : "UPDATE"
                  ) : (
                    !props.bookingData.id ? "CREATE QUOTE" : props.bookingData.status === BookingStatus.QUOTE ? "UPDATE QUOTE" : "CONTINUE"
                  )
                }
              </Button>
            </Grid>
          </Grid>
        </AppBar>
      </Hidden>
    </Grid>
  );
};

export default PersonalDetails;
