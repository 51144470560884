import "./index.scss"
import { Avatar, Box, Grid, Hidden, IconButton, TextField, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { BookingStatus, IBooking, ICustomerDetails } from "../../../../reducers/bookings/types";
import { IBusinessCustomer } from "../../../../reducers/customer/types";
import { CustomerType, getPhoneNumber } from "../../../views/ReservationManagement/utils";
import { useMutation } from "@apollo/client";
import { ADD_BOOKING_PO_NUMBER } from "../../../../graphql/bookings/addBookingPoNumberMutation";
import { SnackBarVariant } from "../../SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../utils";
import { useSnackBar } from "../../SnackBarContext/SnackBarContext";
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { deepOrange } from "@mui/material/colors";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";

interface ICustomerInfo {
  bookingData: IBooking;
  poNumber?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarCustom: {
      height: "30vw",
      width: "30vw",
      backgroundColor: deepOrange[500],
      color: theme.palette.getContrastText(deepOrange[500]),
      fontSize: "20vw",
    }
  })
);

export const CustomerInfo = (props: ICustomerInfo) => {

  const snackbar = useSnackBar();
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [isBusinessCustomerBooking, setIsBusinessCustomerBooking] = useState<boolean>()
  const [businessCustomer, setBusinessCustomer] = useState<IBusinessCustomer>()
  const [customer, setCustomer] = useState<ICustomerDetails>()
  const [booking, setBooking] = useState<IBooking>()
  const [isEditablePONumber, setIsEditablePONumber] = useState<boolean>(false);
  const [poNumber, setPoNumber] = useState<string>("")
  const { bookingData } = props;

  const [addBookingPoNumber] = useMutation(ADD_BOOKING_PO_NUMBER, {
    onCompleted: (data) => {
      snackbar({
        message: `${getLocalizedBookingSyntex(country)} purchase order number updated successfully`,
        variant: SnackBarVariant.SUCCESS
      })
      if (booking && booking.id) {
        setBooking({
          ...booking,
          poNumber: data.addBookingPoNumber || ""
        })
      }
      setPoNumber(data.addBookingPoNumber)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const addPoNumberToBooking = (_poNumber: string) => {
    if (booking) {
      addBookingPoNumber({
        variables: {
          bookingId: booking.id,
          poNumber: _poNumber
        }
      })
    }
  }

  useEffect(() => {
    if (bookingData) {
      if (bookingData.status === BookingStatus.DRAFT) {
        setIsEditablePONumber(false)
      }
      setBooking(bookingData);
      const isBusinessCustomerBooking =
        bookingData.customerType === CustomerType.BUSINESS &&
          bookingData.businessCustomer &&
          bookingData.businessCustomer.id !== ""
          ? true
          : false;
      setIsBusinessCustomerBooking(isBusinessCustomerBooking)
      setBusinessCustomer(bookingData.businessCustomer)
      setCustomer(bookingData.customer)
      setPoNumber(props.bookingData?.poNumber || "")
    }
  }, [bookingData])

  return (
    <Grid container item xs={12} alignItems="flex-start" >
      <Hidden smDown>
        <Grid item xs={4}>
          <Grid item container>
            <Grid item>
              <Typography style={{ marginBottom: 10 }} variant={"h4"}>Customer Name:</Typography>
            </Grid>
            <Grid item>
              <Typography variant={"body1"}>&nbsp;
                {`${isBusinessCustomerBooking
                  ? businessCustomer?.businessName
                  : customer
                    ? customer.firstName + " " + customer.lastName
                    : ""
                  }`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item>
              <Typography style={{ marginBottom: 10 }} variant={"h4"}>Customer Email:</Typography>
            </Grid>
            <Grid item>
              <Typography variant={"body1"}>&nbsp;
                {`${isBusinessCustomerBooking
                  ? businessCustomer?.billing.email
                  : customer
                    ? customer.email
                    : ""
                  }`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid>
              <Typography variant={"h4"}>Customer Phone:</Typography>
            </Grid>
            <Grid item>
              <Typography variant={"body1"}>&nbsp;
                {`${isBusinessCustomerBooking
                  ? getPhoneNumber(businessCustomer?.billing.phoneNumber.phone)
                  : customer
                    ? getPhoneNumber(customer.phoneNumber.phone)
                    : ""
                  }`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid item container>
            <Grid>
              <Typography style={{ marginBottom: 10 }} variant={"h4"}>{`${getLocalizedBookingSyntex(country)} Reference:`} </Typography>
            </Grid>
            <Grid>
              <Typography variant={"body1"}>&nbsp;{booking?.referenceNumber}</Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item>
              <Typography style={{ marginBottom: 10 }} variant={"h4"}>Date Created: </Typography>
            </Grid>
            <Grid item>
              <Typography variant={"body1"}>&nbsp; {getLocalizedDateFormat(country, booking?.dateCreated || "", DATE_TYPE.EXPANDED)}</Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item>
              <Typography variant={"h4"}>TBA {getLocalizedBookingSyntex(country)}: </Typography>
            </Grid>
            <Grid item>
              <Typography variant={"body1"}>&nbsp;{booking?.tba ? "YES" : "NO"}</Typography>
            </Grid>
          </Grid>
          {isEditablePONumber && <Box mt={1}></Box>}
          {
            booking?.customerType === CustomerType.BUSINESS && (
              <>
                {isEditablePONumber ? (
                  <Grid item xs={10} container className={"po-number-main"}>
                    <TextField
                      label="PO Number"
                      id="po-number"
                      fullWidth
                      value={poNumber}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPoNumber(e.target.value)
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {
                              poNumber !== booking.poNumber && <Tooltip title="Save">
                                <IconButton
                                  aria-label="toggle po number "
                                  onClick={() => {
                                    addPoNumberToBooking(poNumber)
                                    setIsEditablePONumber(false)
                                  }}
                                  size="large">
                                  <DoneIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            <Tooltip title="Close">
                              <IconButton
                                aria-label="toggle po number"
                                onClick={() => {
                                  setPoNumber(booking.poNumber || "")
                                  setIsEditablePONumber(false)
                                }}
                                size="large">
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid container item xs={12} spacing={1} alignItems="center">
                    <Grid item >
                      <Typography variant={"h4"}>PO Number: </Typography>
                    </Grid>
                    <Grid item ><Typography variant={"body1"}>{poNumber || "N/A"}</Typography></Grid>
                    <Grid item >
                      <Tooltip title={poNumber ? "Edit" : "Click to add"}>
                        <IconButton
                          aria-label="toggle password visibility"
                          disabled={booking.status === BookingStatus.DRAFT ? true : false}
                          onClick={() => setIsEditablePONumber(true)}
                          size="large">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              </>
            )
          }
        </Grid>
        <Grid item xs={4}>
          <Grid container item>
            <Typography variant={"h4"} >
              {booking?.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                ? "Delivery Address, Date & Time:"
                : "Pick-up Location, Date & Time:"}
            </Typography>
            <Typography style={{ marginLeft: 10, marginBottom: 10 }} variant={"body1"}>
              {`${booking?.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                ? booking.pickupOtherLocation.fullAddress
                : booking?.pickupServiceLocation?.name || "N/A"
                },
                ${getLocalizedDateFormat(country, booking?.pickupDateTime || "", DATE_TYPE.EXPANDED)}`}
            </Typography>
          </Grid>
          <Grid item container >
            <Typography variant={"h4"}>
              {booking?.tba ? "Renewal Location, Date & Time:": booking?.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                ? "Collection Address, Date & Time:"
                : "Drop-off Location, Date & Time:"}
            </Typography>
            <Typography style={{ marginLeft: 10 }} variant={"body1"}>
              {`${booking?.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                ? booking.dropoffOtherLocation.fullAddress
                : booking?.dropoffServiceLocation?.name || "N/A"
                },
                ${getLocalizedDateFormat(country, booking?.dropoffDateTime || "", DATE_TYPE.EXPANDED)}`}
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid container item alignItems="center" justifyContent="center" xs={5}>
          <Grid item>
            {
              isBusinessCustomerBooking ?
                <Avatar className={classes.avatarCustom}>
                  {businessCustomer?.businessName.charAt(0).toUpperCase()}
                </Avatar>
                :
                customer ?
                  <Avatar className={classes.avatarCustom}>
                    {customer.firstName.charAt(0).toUpperCase()}
                  </Avatar>
                  :
                  ""
            }
          </Grid>
        </Grid>
        <Grid container item xs={7}>
          <Grid item xs={12}>
            <Typography variant={"h3"}>
              {`${isBusinessCustomerBooking
                ? businessCustomer?.businessName
                : customer
                  ? customer.firstName + " " + customer.lastName
                  : ""
                }`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h5"}>
              {`${isBusinessCustomerBooking
                ? businessCustomer?.billing.email
                : customer
                  ? customer.email
                  : ""
                }`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1} />
          </Grid>
          <Grid container alignItems="center" item xs={12}>
            <Grid item xs={2}>
              <PinDropOutlinedIcon />
            </Grid>
            <Grid item xs={10}>
              <Typography variant={"h5"}>
                {booking?.pickupOtherLocation
                  ? booking.pickupOtherLocation.fullAddress
                  : booking?.pickupServiceLocation?.name || "N/A"
                }
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" item xs={12}>
            <Grid item xs={2}>
              <CalendarTodayOutlinedIcon />
            </Grid>
            <Grid item xs={10}>
              <Typography variant={"h5"}>
                {getLocalizedDateFormat(country, booking?.pickupDateTime || " ", DATE_TYPE.EXPANDED)}
              </Typography>
            </Grid>
          </Grid>
          {(
            (booking?.pickupOtherLocation
              ? booking.pickupOtherLocation.fullAddress
              : booking?.pickupServiceLocation?.name || "N/A"
            )
            !==
            (booking?.dropoffOtherLocation
              ? booking.dropoffOtherLocation.fullAddress
              : booking?.dropoffServiceLocation?.name || "N/A"
            )) &&
            <Grid container alignItems="center" item xs={12}>
              <Grid item xs={2}>
                <PinDropOutlinedIcon />
              </Grid>
              <Grid item xs={10}>
                <Typography variant={"h5"}>
                  {booking?.dropoffOtherLocation
                    ? booking.dropoffOtherLocation.fullAddress
                    : booking?.dropoffServiceLocation?.name || "N/A"
                  }
                </Typography>
              </Grid>
            </Grid>}
          <Grid container alignItems="center" item xs={12}>
            <Grid item xs={2}>
              <EventAvailableOutlinedIcon />
            </Grid>
            <Grid item xs={10}>
              <Typography variant={"h5"}>
                {getLocalizedDateFormat(country, booking?.dropoffDateTime || "", DATE_TYPE.EXPANDED)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" item xs={12}>
            <Grid item xs={2}>
              <ReceiptIcon />
            </Grid>
            <Grid item xs={10}>
              <Typography variant={"h5"}>
                {booking?.referenceNumber}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1} />
        </Grid>
      </Hidden >
    </Grid >
  );
};
