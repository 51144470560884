export const UPDATE_ORGANISATION = "UPDATE_ORGANISATION";
export const DELETE_ORGANISATION = "DELETE_ORGANISATION";
export const RESET_ORGANISATION = "RESET_ORGANISATION";
export const SET_ORGANISATION = "SET_ORGANISATION";
export const ADD_BRANCH = "ADD_BRANCH";
export const SET_BRANCH = "SET_BRANCH";
export const RESET_BRANCH = "RESET_BRANCH";
export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const ADD_SERVICELOCATION = "ADD_SERVICELOCATION";
export const SET_SERVICELOCATION = "SET_SERVICELOCATION";
export const RESET_SERVICELOCATION = "RESET_SERVICELOCATION";
export const SET_SERVICELOCATIONS = "SET_SERVICELOCATIONS";
export const UPDATE_SERVICELOCATION = "UPDATE_SERVICELOCATION";

interface IUpdateOrganisation {
  type: typeof UPDATE_ORGANISATION;
  payload: any;
}

interface IDeleteOrganisation {
  type: typeof DELETE_ORGANISATION;
  payload: any;
}

interface IResetOrganisation {
  type: typeof RESET_ORGANISATION;
}

interface ISetOrganisation {
  type: typeof SET_ORGANISATION;
  payload: any;
}

interface IAddBranch {
  type: typeof ADD_BRANCH;
  payload: any;
}
interface ISetBranch {
  type: typeof SET_BRANCH;
  payload: any;
}

interface IResetBranch {
  type: typeof RESET_BRANCH;
}
interface IUpdateBranch {
  type: typeof UPDATE_BRANCH;
  payload: any;
}

interface IAddServiceLocation {
  type: typeof ADD_SERVICELOCATION;
  payload: any;
}

interface ISetServiceLocation {
  type: typeof SET_SERVICELOCATION;
  payload: any;
}

interface IResetServiceLocation {
  type: typeof RESET_SERVICELOCATION;
}

interface ISetServiceLocations {
  type: typeof SET_SERVICELOCATIONS;
  payload: any;
}
interface IUpdateServiceLocation {
  type: typeof UPDATE_SERVICELOCATION;
  payload: any;
}

export type OrganisationAction =
  | IUpdateOrganisation
  | IDeleteOrganisation
  | IResetOrganisation
  | ISetOrganisation
  | IAddServiceLocation
  | ISetServiceLocation
  | IResetServiceLocation
  | ISetServiceLocations
  | IUpdateServiceLocation
  | IAddBranch
  | ISetBranch
  | IResetBranch
  | IUpdateBranch;
