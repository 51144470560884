import { useLazyQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  Hidden,
  Typography
} from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ApolloError } from "@apollo/client";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GET_VEHICLE_GROUP } from "../../../../graphql/vehiclePriceGroup/getVehicleGroupQuery";
import { vehiclePriceGroupInitialState } from "../../../../reducers/vehiclePriceGroups/consts";
import { IVehiclePriceGroup } from "../../../../reducers/vehiclePriceGroups/types";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import StepperHeader from "../../../common/StepperHeader/StepperHeader";
import { formatGraphQLErrorMessage } from "../../../common/utils";
import { GroupVehicleSelection } from "./Views/GroupVehicleSelection";
import { GroupVehicleSetPrice } from "./Views/SetPrice";
import { IAppState } from "../../../../store";
import { useSelector } from "react-redux";

export const VehiclePriceGroup: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [values, setValues] = useState<IVehiclePriceGroup>(
    _.cloneDeep(vehiclePriceGroupInitialState.vehiclePriceGroup)
  );
  const [currentStep, setCurrentStep] = useState<number>(0);
  const prevBranchRef = useRef(userState.currentBranch);
  const [
    loadVehicleGroup,
    { loading: vehicleGroupLoading, data: vehicleGroupData }
  ] = useLazyQuery(GET_VEHICLE_GROUP, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.vehicleGroup) {
        navigate("/vehicle-price-groups");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (prevBranchRef.current !== userState.currentBranch) {
      navigate("/vehicle-price-groups");
      prevBranchRef.current = userState.currentBranch;
    }
  }, [userState.currentBranch]);

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const groupId = params.get("id");
      if (groupId) {
        loadVehicleGroup({
          variables: {
            id: groupId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (vehicleGroupData && vehicleGroupData.vehicleGroup) {
      setValues(vehicleGroupData.vehicleGroup);
    }
  }, [vehicleGroupData]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousStep = (values: IVehiclePriceGroup) => {
    setValues(values);
    setCurrentStep(currentStep - 1);
  };

  const stepMapper: any = {
    0: (
      <GroupVehicleSelection
        vehicleGroup={values}
        updateVehicleGroup={setValues}
        onSubmit={nextStep}
      />
    ),
    1: <GroupVehicleSetPrice vehicleGroup={values} onPrevious={previousStep} />
  };

  if (vehicleGroupLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid item container xs={12}>
        <Hidden smDown>
          <Grid container item xs={12} sm={4} alignItems="center">
            <Typography variant="h1" color="primary">
              {" "}
              Fleet{"  "}
            </Typography>
            <Box color="white" sx={{ pr: 1 }}></Box>
            <DoubleArrowIcon />
            <Box color="white" sx={{ pl: 1 }}></Box>
            <Typography variant="h1" color="primary">
              &nbsp; Vehicle Group
            </Typography>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid container item xs={12} sm={4} alignItems="center" justifyContent='center'>
            <Typography variant="h2" color="primary">
              Vehicle Group Pricing
            </Typography>
          </Grid>
        </Hidden>
        <Grid item container xs={12} sm={8} alignContent="flex-end">
          <StepperHeader
            currentStep={currentStep}
            steps={["Group Vehicle Selection", "Set Price"]}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {stepMapper[currentStep]}
      </Grid>
    </Grid>
  );
};
