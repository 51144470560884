import { gql } from "@apollo/client";

export const CREATE_CONSOLIDATED_INVOICE = gql`
  mutation createConsolidatedInvoice(
    $businessCustomer: String!
    $invoiceIds: [String]!
    $startDate: String!
    $endDate: String!
  ) {
    createConsolidatedInvoice(
      businessCustomer: $businessCustomer
      invoiceIds: $invoiceIds
      startDate: $startDate
      endDate: $endDate
    ) {
      id
    }
  }
`;
