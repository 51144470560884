import "./index.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Theme,
  Typography,
  alpha,
  Button,
  Dialog,
  Hidden,
  MenuItem,
  Paper,
  TextField,
  AppBar,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CREATE_VEHICLE } from "../../../../../graphql/fleet/createVehicleMutation";
import { GET_DEFLEET_VEHICLE, GET_VEHICLE } from "../../../../../graphql/fleet/getVehicleQuery";
import { UPDATE_VEHICLE } from "../../../../../graphql/fleet/updateVehicleMutation";
import { initialState } from "../../../../../reducers/fleet/const";
import { IVehicle } from "../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { vehicleNonNullableFields } from "../../utils";
import { DVLASearch } from "../AddVehicle/DVLASearch";
import { TabView } from "./TabView/TabView";
import { getFrogDiagram, IFrogDiagram } from "../../../../common/VehicleDamagePreview/frogDiagram";
import { frogDiagramTypes } from "./Details/constants";
import { DamageTypes } from "./VehicleCheck/VehicleCheck";
import { ISubscriptionPricing } from "../../../../../reducers/Subscription/types";
import { UPDATE_VIRTUAL_VEHICLE } from "../../../../../graphql/fleet/updateVirtualVehicleMutation";
import { DateTime as d } from "luxon";

interface IVehicleProps {
  history: any;
  vehicle: IVehicle;
}

interface ISubscriptionUpdate {
  subscription: string;
  pricings: ISubscriptionPricing
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      marginLeft: "50px",
      marginTop: "100px"
    },
    header: {
      color: "rgba(0, 0, 0, 0.7) !important",
      fontSize: "30px !important",
      marginBottom: "24px !important"
    },
    saveButton: {
      backgroundColor: "#faaf40",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    saveButtonDisabled: {
      backgroundColor: "#d3d3d3",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 100px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    addBottomButton: {
      backgroundColor: "var(--ion-color-success)",
      "&:hover": {
        backgroundColor: "var(--ion-color-success-shade)"
      },
      position: 'relative',
      width: 'calc(50vw - 100px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    addBarButton: {
      backgroundColor: "var(--ion-color-success)",
      "&:hover": {
        backgroundColor: "var(--ion-color-success-shade)"
      },
      position: 'relative',
      width: 'calc(100vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    text: {
      position: 'relative',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    active: {
      fontWeight: "bold",
      fontSize: "initial !important"
    },
    dialogSmallInput: {
      minWidth: 'calc(50vw - 48px)',
      maxWidth: 'calc(50vw - 48px)'
    },
    dialogLargeInput: {
      minWidth: 'calc(100vw - 80px)',
      maxWidth: 'calc(100vw - 80px)'
    }
  })
);

export const Vehicle = React.memo(
  (props: any) => {
    const classes = useStyles();
    const snackbar = useSnackBar();
    const location = useLocation();
    const navigate = useNavigate();

    const userState = useSelector((state: IAppState) => state.userReducer);
    const [vehicleInfo, setVehicleInfo] = useState<IVehicle>(
      initialState.vehicle
    );
    const [saveButton, setSaveButton] = useState<boolean>(false);
    const [isNewVehicle, setIsNewVehicle] = useState<boolean>(true);
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);
    const [dvlaSearched, setDvlaSearched] = useState<boolean>(false);
    const [svg, setSvg] = useState<IFrogDiagram>();
    const [diagramType, setDiagramType] = useState<string>(DamageTypes.EXTERIOR);
    const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false)
    const [isDefleetedVehicles, setIsDefleetedVehicles] = useState<boolean>(false)

    const [
      getVehicle,
      { loading: getVehicleLoading, data: vehicleData }
    ] = useLazyQuery(GET_VEHICLE, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (
          !data.vehicle ||
          data.vehicle.branchDetails.id !== userState.currentBranch.id
        ) {
          navigate("/vehicle-inventory");
        }
      },
      onError: (error: ApolloError) => {
        if (error.message.includes("Upgrade your plan to add more vehicles")) {
          setErrorModalOpen(true)
        }
        else {
          snackbar({
            message: formatGraphQLErrorMessage(error.message),
            variant: SnackBarVariant.ERROR
          });
        }
      }
    });
    const [
      getDeFleetVehicle,
      { loading: getDeFleetVehicleLoading }
    ] = useLazyQuery(GET_DEFLEET_VEHICLE, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (
          !data.getDefleetVehicleData ||
          data.getDefleetVehicleData.branchDetails.id !== userState.currentBranch.id
        ) {
          navigate("/defleeted-vehicles");
        }
        if (
          data &&
          data.getDefleetVehicleData &&
          data.getDefleetVehicleData.licencePlate
        ) {
          let vehicle = { ...data.getDefleetVehicleData };
          delete vehicle.responseStatus;
          if (vehicle?.registrationDate) {
            const formattedDate = d.fromFormat(vehicle.registrationDate, "d MMMM yyyy").toUTC().toISO();
            vehicle.registrationDate = formattedDate;
          }
          setVehicleInfo(vehicle);
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    });

    const [tempVehicleFields, setTempVehicleFields] = useState<IVehicle>(
      vehicleInfo
    );

    const [createVehicle] = useMutation(CREATE_VEHICLE, {
      onError: (error: ApolloError) => {
        if (error.message.includes("Upgrade your plan to add more vehicles")) {
          setErrorModalOpen(true)
        }
        else {
          snackbar({
            message: formatGraphQLErrorMessage(error.message),
            variant: SnackBarVariant.ERROR
          })
        }

      },
      onCompleted: () => {
        setSaveButton(true);
        snackbar({
          message: "New Vehicle Created",
          variant: SnackBarVariant.SUCCESS
        });
        navigate("/vehicle-inventory");
      }
    });

    const [updateVehicleMutation] = useMutation(UPDATE_VEHICLE, {
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        }),
      onCompleted: (data) => {
        if (data.updateVehicle) {
          let vehicle = { ...data.updateVehicle };
          delete vehicle.responseStatus;
          if (vehicle?.registrationDate) {
            const formattedDate = d.fromFormat(vehicle.registrationDate, "d MMMM yyyy").toUTC().toISO();
            vehicle.registrationDate = formattedDate;
          }
          setVehicleInfo(vehicle);
        }
        snackbar({
          message: "Vehicle Updated",
          variant: SnackBarVariant.SUCCESS
        });
      }
    });

    const [updateVirtualVehicle] = useMutation(UPDATE_VIRTUAL_VEHICLE, {
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        }),
      onCompleted: (data) => {
        if (data.updateVirtualVehicle) {
          getVehicle({
            variables: {
              id: data.updateVirtualVehicle.id
            }
          });
          setVehicleInfo(data.updateVirtualVehicle);
        }
        snackbar({
          message: "Vehicle Updated",
          variant: SnackBarVariant.SUCCESS
        });
      }
    })

    useEffect(() => {
      const isDefleetedVehiclesPage = location.pathname === '/update-defleeted-vehicle';
      const isVehicleInventoryPage = location.pathname === '/update-vehicle';

      if (isVehicleInventoryPage) {
        setIsDefleetedVehicles(false);
      }
      if (location && location.search) {
        const params = new URLSearchParams(location.search);
        const vehicleId = params.get("vehicle");
        setIsNewVehicle(false);
        if (vehicleId) {
          if (isDefleetedVehiclesPage) {
            setIsDefleetedVehicles(true)
            getDeFleetVehicle({
              variables: {
                id: vehicleId
              }
            })
          } else {
            getVehicle({
              variables: {
                id: vehicleId
              }
            });
          }
        }
      }
    }, [location]);

    useEffect(() => {
      if (
        vehicleData &&
        vehicleData.vehicle &&
        vehicleData.vehicle.licencePlate
      ) {
        let vehicle = { ...vehicleData.vehicle };
        delete vehicle.responseStatus;
        if (vehicle?.registrationDate) {
          const formattedDate = d.fromFormat(vehicle.registrationDate, "d MMMM yyyy").toUTC().toISO();
          vehicle.registrationDate = formattedDate;
        }
        setVehicleInfo(vehicle);
      }
    }, [vehicleData]);

    useEffect(() => {
      let branch = "";
      if (userState && userState.currentBranch) {
        branch = userState.currentBranch.id;
      }
      setTempVehicleFields({ ...tempVehicleFields, branch });
    }, [userState, props.vehicle]);

    useEffect(() => {
      if (isNewVehicle && vehicleNonNullableFields) {
        const fields: any = tempVehicleFields;
        let flag = true;
        let nonNullableFields: string[] = [...vehicleNonNullableFields];
        if (fields.pcoNumber && !nonNullableFields.includes("pcoExpiry")) {
          nonNullableFields.push('pcoExpiry')
        } else {
          nonNullableFields = vehicleNonNullableFields.filter((field: string) => field !== 'pcoExpiry')
        }
        nonNullableFields.forEach((value: string) => {
          if (!fields[value]) {
            flag = false;
          }
        });
        setSaveButton(flag);
      }
    }, [tempVehicleFields]);

    function updateFields(values: any) {
      setTempVehicleFields({ ...tempVehicleFields, ...values });
      setVehicleInfo({ ...vehicleInfo, ...values })
    }

    function updateVehicle(values: any) {
      const {
        id,
        deleted,
        responseStatus,
        status,
        bookingSchedules,
        notes,
        damages,
        activeVehicleDamages,
        services,
        branchDetails,
        canTransfer,
        smartCarVehicleID,
        dateCreated,
        vehicleTaxAmount,
        vehicleOwners,
        telemetryDevicesData,
        deFleetStatus,
        ...rest
      } = values;
      if (rest.vehicleState) {
        delete rest.vehicleState
      }
      if (rest.subscriptions) {
        const formatedSubscriptions: ISubscriptionUpdate[] = rest.subscriptions.map((subscription: any) => {
          return {
            subscription: subscription.subscription.id,
            pricings: subscription.pricings
          }
        })
        rest.subscriptions = formatedSubscriptions
      }
      if (!rest.deFleetParameters) {
        rest.deFleetParameters = {
          byDistanceDriven: 0,
          byDate: ""
        }
      }
      if (!rest.regularServiceSchedule) {
        rest.regularServiceSchedule = {
          byDistance: 0,
          byMonth: 0
        }
      }
      updateVehicleMutation({
        variables: { id, vehicle: rest }
      });
    }

    function updateGhostVehicle(startDate: string) {
      const args = {
        startDate: startDate,
        vehicle: vehicleInfo.id
      }
      updateVirtualVehicle({
        variables: {
          updateVirtualVehicleInput: args
        }
      })
    }

    function onAddSubmit() {
      const vehicle = { ...tempVehicleFields };
      if (!vehicle.id) {
        const {
          id,
          deleted,
          responseStatus,
          status,
          bookingSchedules,
          branchDetails,
          canTransfer,
          vehicleTaxAmount,
          vehicleOwners,
          telemetryDevicesData,
          ...rest
        } = vehicle;
        if (rest.vehicleState) {
          delete rest.vehicleState.id;
          delete rest.vehicleState.type;
          delete rest.vehicleState.createdAt;
          delete rest.vehicleState.updatedAt;
          delete rest.vehicleState.createdBy;
          delete rest.vehicleState.updatedBy;
          delete rest.isGhostVehicle
        }
        setSaveButton(false);
        // TODO : upload all necessary documents
        createVehicle({
          variables: {
            vehicle: { ...rest }
          }
        });
      }
    }

    function dvlaSearch(data: IVehicle) {
      if (data && data.branch === "" && userState && userState.currentBranch) {
        setVehicleInfo({ ...vehicleInfo, ...data });
        updateFields({ ...vehicleInfo, ...data });
        data.branch = userState.currentBranch.id;
        setDvlaSearched(true);
      }
      if (data) {
        delete data.responseStatus;
      }
    }

    const vehicle = (
      (!isNewVehicle && (getVehicleLoading || getDeFleetVehicleLoading)) ? (
        <CircularProgress />
      ) : (
        <TabView
          vehicle={vehicleInfo}
          updateFields={updateFields}
          updateVehicle={updateVehicle}
          updateVirtualVehicle={updateGhostVehicle}
          showUpgradeErrorOpen={setErrorModalOpen}
        />
      )
    );

    function Spacer() {
      return (
        <Grid item xs={12}>
          <Box mt={1} />
        </Grid>
      )
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      event.preventDefault();
      let val: any = event.target.value;
      if (
        event.target.name === "year" ||
        event.target.name === "numberOfDoors" ||
        event.target.name === "numberOfSeats"
      ) {
        val = parseInt(event.target.value);
        if (event.target.value === "" || isNaN(val)) {
          val = 0;
        }
      }
      if (event.target.name === "frogDiagram") {
        setSvg(getFrogDiagram(val))
      }
      setVehicleInfo({ ...vehicleInfo, [event.target.name]: val });
      updateFields({
        [event.target.name]: val
      });
    }

    const handleClose = () => {
      setMobileOpen(false);
      setDvlaSearched(false);
    }

    return <>
      <Grid container spacing={2}>
        <CssBaseline />
        <Grid container item xs={12} alignItems="center">
          {isDefleetedVehicles ? (
            <Typography variant="h1" color="primary">
              De-Fleeted Vehicle Inventory
            </Typography>
          ) : (
            <Typography variant="h1" color="primary">
              Fleet Inventory
            </Typography>
          )}
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon />
          <Box color="white" sx={{ pl: 1 }}></Box>
          {isNewVehicle ? (
            <Typography variant="h1" color="primary">
              Add
            </Typography>
          ) : (
            <Typography variant="h1" color="primary">
              Update
            </Typography>
          )}
          <Typography variant="h1" color="primary">
            &nbsp;Vehicle
          </Typography>
        </Grid>
        {!getVehicleLoading ? (
          <>
            <Grid container item xs={12} spacing={2}>
              {isNewVehicle &&
                (
                  userState.currentOrganisation.address.country === "United Kingdom" ?
                    (
                      <Grid container item xs={12}>
                        <DVLASearch
                          saveButton={saveButton}
                          history={props.history}
                          onSubmit={dvlaSearch}
                          onAddSubmit={onAddSubmit}
                          setMobileOpen={setMobileOpen}
                        />
                      </Grid>
                    ) :
                    (
                      <Hidden smUp>
                        <AppBar position="fixed" className={classes.appBar}>
                          <Grid container xs={12} spacing={1}>
                            <Grid item xs={12}>
                              <Button
                                className={classes.addBarButton}
                                variant="contained"
                                aria-label="add"
                                type="submit"
                                disabled={saveButton ? false : true}
                                onClick={onAddSubmit}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </AppBar>
                      </Hidden>
                    )
                )
              }
            </Grid>
            <Hidden smDown>
              {
                isNewVehicle && (
                  <Grid container item xs={12}>
                    <Fab
                      className={
                        saveButton ? classes.saveButton : classes.saveButtonDisabled
                      }
                      disabled={saveButton ? false : true}
                      variant="extended"
                      size="medium"
                      aria-label="submit"
                      onClick={onAddSubmit}
                    >
                      Add vehicle
                    </Fab>
                  </Grid>
                )
              }
            </Hidden>
            <Grid container item xs={12}>
              {vehicle}
            </Grid>

            <Dialog open={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
              <DialogTitle>
                <Typography variant="h6" component="div" style={{ paddingLeft: '2px', marginTop: '8px' }}>
                  <strong>Upgrade Your Plan</strong>
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography>
                  You've reached your Fleet Tier limit. Please request an increase by filling out this  {" "}
                  <a href="https://share.hsforms.com/17RCR7Ke1S76Q9pzR-DMNCA34aut" target="_blank" rel="noopener noreferrer">form</a>.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setErrorModalOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

          </>
        ) : (
          <Grid container item xs={12}>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
      <Hidden smUp>
        <Dialog
          onClose={handleClose}
          open={dvlaSearched && mobileOpen}>
          <Grid container style={{ width: '90vw' }} xs={12} justifyContent='center'>
            <Spacer />
            <Grid container item xs={12} justifyContent='center'>
              <Grid item>
                <Typography variant="h2" className='bold'>
                  Vehicle Quick Add
                </Typography>
              </Grid>
            </Grid>
            <Spacer />
            <Grid container item xs={6} justifyContent='center'>
              <TextField
                className={classes.dialogSmallInput}
                placeholder="eg. AJ12OFM"
                label="Registration Number"
                name={"licencePlate"}
                InputProps={{
                  onChange: handleChange,
                  value: vehicleInfo?.licencePlate?.toUpperCase()
                }}
                inputProps={{
                  minLength: 7,
                  maxLength: 30
                }}
                required
                disabled
              />
            </Grid>
            <Grid container item xs={6} justifyContent='center'>
              <TextField
                className={classes.dialogSmallInput}
                placeholder="eg. KIA-023"
                label="Asset Tag"
                name={"assetTag"}
                required
                InputProps={{
                  onChange: handleChange,
                  value: vehicleInfo.assetTag
                }}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Spacer />
            <Grid container item xs={6} justifyContent='center'>
              <TextField
                className={classes.dialogSmallInput}
                placeholder="eg. KIA"
                label="Make"
                name={"make"}
                InputProps={{
                  onChange: handleChange,
                  value: vehicleInfo.make
                }}
                inputProps={{
                  maxLength: 30
                }}
                disabled
                required
              />
            </Grid>
            <Grid container item xs={6} justifyContent='center'>
              <TextField
                className={classes.dialogSmallInput}
                placeholder="eg. CEED"
                label="Model"
                name={"model"}
                InputProps={{
                  onChange: handleChange,
                  value: vehicleInfo.model
                }}
                inputProps={{
                  maxLength: 30
                }}
                disabled
                required
              />
            </Grid>
            <Spacer />
            <Grid container item xs={6} justifyContent='center'>
              <TextField
                className={classes.dialogSmallInput}
                placeholder="eg. RED"
                label="Colour"
                name={"colour"}
                InputProps={{
                  onChange: handleChange,
                  value: vehicleInfo.colour
                }}
                disabled
                inputProps={{ maxLength: 10 }}
                required
              />
            </Grid>
            <Grid container item xs={6} justifyContent='center'>
              <TextField
                className={classes.dialogSmallInput}
                placeholder="eg. 2003"
                label="Year"
                name={"year"}
                InputProps={{
                  onChange: handleChange,
                  value: vehicleInfo.year ? vehicleInfo.year : ""
                }}
                inputProps={{
                  maxLength: 4
                }}
                disabled
                required
              />
            </Grid>
            <Spacer />
            <Grid container item xs={12} justifyContent='center'>
              <TextField
                className={classes.dialogLargeInput}
                name={"frogDiagram"}
                type="text"
                select
                required
                fullWidth
                label="Frog Diagram"
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  onChange: handleChange,
                  value: vehicleInfo.frogDiagram
                }}
              >
                {frogDiagramTypes.map((item: any, index: number) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              {
                vehicleInfo.frogDiagram && (
                  <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
                    {diagramType === DamageTypes.EXTERIOR && svg?.exterior.svg &&
                      (<svg xmlns="http://www.w3.org/2000/svg"
                        style={{ height: `calc(100vh - 460px)`, marginBottom: "20px" }}
                        viewBox={`0 0 ${svg?.exterior.width} ${svg?.exterior.height}`}>
                        {svg?.exterior.svg}
                      </svg>)}
                    {diagramType === DamageTypes.INTERIOR && svg?.interior.svg &&
                      (<svg xmlns="http://www.w3.org/2000/svg"
                        style={{ height: `calc(100vh - 460px)`, marginBottom: "20px" }}
                        viewBox={`0 0 ${svg?.interior.width} ${svg?.interior.height}`}>
                        {svg?.interior.svg}
                      </svg>)}
                    <Typography variant="h5" ></Typography>
                    <Typography variant="h5" className={diagramType === DamageTypes.EXTERIOR ? classes.active : ""} style={{ cursor: "pointer", display: "inline", marginRight: "10px", textTransform: "uppercase" }} onClick={() => { setDiagramType(DamageTypes.EXTERIOR) }}>{"exterior"}</Typography>
                    <Typography variant="h5" className={diagramType === DamageTypes.INTERIOR ? classes.active : ""} style={{ cursor: "pointer", display: "inline", textTransform: "uppercase" }} onClick={() => { setDiagramType(DamageTypes.INTERIOR) }}>{"interior"}</Typography>
                  </Paper>
                )
              }
            </Grid>
            <Spacer />
            <Grid container item justifyContent='center'>
              <Grid item>
                <Button
                  className={classes.bottomButton}
                  variant="contained"
                  aria-label="details"
                  type="submit"
                  onClick={() => {
                    setMobileOpen(false);
                    setDvlaSearched(false);
                  }}>
                  Details
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.addBottomButton}
                  variant="contained"
                  aria-label="add"
                  type="submit"
                  disabled={saveButton ? false : true}
                  onClick={onAddSubmit}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
    </>;
  },
  (prevProps, nextProps) => prevProps.vehicle === nextProps.vehicle
);

export default Vehicle;
