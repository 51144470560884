interface IHeaders {
  authorization?: string;
  region?: string;
  tenantId?: string;
  organisationId?: string;
  branchId?: string;
  userId?: string;
  tenant?: string;
  role?: string;
  clientId?: string;
}

export default class HttpHeader {
  headers: IHeaders;
  private static instance: HttpHeader;
  constructor() {
    this.headers = {
      region: "uk",
      tenant: 'nuvven',
      clientId: process.env.REACT_APP_CLIENT_ID
    }
  }

  public static getInstance(): HttpHeader {
    if (!HttpHeader.instance) {
      HttpHeader.instance = new HttpHeader();
    }
    return HttpHeader.instance;
  }

  public setHeaders(args: Partial<IHeaders>) {
    this.headers = { ...this.headers, ...args };
  }

  public resetHeaders() {
    this.headers = {
      region: "uk",
      tenant: 'nuvven',
      clientId: process.env.REACT_APP_CLIENT_ID
    }
  }
}