import { gql } from "@apollo/client";

export const CONFIRM_BOOKING = gql`
  mutation confirmBooking($id: String!, $bookingType: String!) {
    confirmBooking(id: $id, bookingType: $bookingType) {
      id
      dateStarted
      dateReturned
      organisationId
      taxExempted
      zeroCostBooking
      zeroCostBookingNote
      invoiceNote
      firstInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
      }
      currentInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
      }
      finalInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
      }
      referenceNumber
      dateCreated
      dateUpdated
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
      pickupServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
        }
      }
      pickupOtherLocation {
        street
        city
        state
        country
        zipcode
      }
      pickupDateTime
      dropoffServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
        }
      }
      dropoffOtherLocation {
        street
        city
        state
        country
        zipcode
      }
      dropoffDateTime
      longTermBooking
      flightNumber
      insuranceRate
      excess
      customerType
      discount {
        appliedBy
        appliedDate
        isPercent
        code
        amount
      }
      tax {
        appliedBy
        appliedDate
        code
        amount
        payableAmount
        type
        rate
        subdivisionOptIn
        subdivisionValue
        subdivisionType
        subdivisionRate
      }
      invoices {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
      }
      rateTypeName
      rateTypeDuration
      billingCycleName
      billingCycleDuration
      status
      addonRequirements {
        addon {
          id
          name
          category
          imageUrl
          hasFixedRate
          hasQuantity
          fixedRate
          description
          recurringRate {
            rateTypeName
            rateTypeDuration
            rate
            longTerm
          }
          totalQuantity
          availableQuantity
          isActivated
        }
        rate
        quantity
        name
        hasFixedRate
        payableAmount
      }
      customer {
        id
        firstName
        lastName
        email
        phoneNumber {
          phone
          country
        }
        location {
          street
          city
          state
          country
          zipcode
        }
      }
      currentBookingSchedules {
        id
        startDate
        endDate
        status
        vehicle {
          id
          licencePlate
        }
      }
      businessCustomer {
        id
        businessName
        registrationNumber
        VAT
        registeredAddress {
          street
          city
          state
          zipcode
          country
        }
        tradingAddressSame
        tradingAddress {
          street
          city
          state
          zipcode
          country
        }
        approvedDrivers {
          id
          status
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        authorizedSignatories {
          id
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        billing {
          phoneNumber {
            phone
            country
          }
          email
        }
        contact {
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        documents {
          id
          name
          title
          documentType
          expiryDate
          url
        }
      }
      authorizedSignatory
      insurancePolicy {
        id
        name
        description
      }
      paymentDetails {
        startDate
        endDate
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        totalPayableAmount
        rentalTime
      }
    }
  }
`;
