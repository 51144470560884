import { Divider, Fab, Grid, Paper, Typography, Theme } from "@mui/material";
import Modal from "@mui/material/Modal";
import { createStyles, makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";

function getModalStyle() {
  const top = 50;
  const left = 52;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: "60%",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    previewImg: {
      backgroundPosition: "50% 50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      height: "20rem",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        marginBottom: "1.5rem",
        marginTop: "1.5rem"
      }
    },
    thumbButton: {
      marginTop: 15,
      marginRight: 10
    },
    thumbImg: {
      height: 100,
      width: 100
    }
  })
);
interface IModelProps {
  title: string;
  open: boolean;
  handleClose(): void;
  images: any[];
}
export default function ImageGallery(props: IModelProps) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const { open, images, title } = props;
  const [previewImage, setPreviewImage] = useState(images[0]);

  const handlePreviewImage = (image: string) => {
    setPreviewImage(image);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper style={modalStyle} className={classes.paper}>
          <Grid container>
            <Grid container item xs={12}>
              <Grid container item xs={6}>
                <Typography variant={"subtitle1"}>{title}</Typography>
              </Grid>
              <Grid container item xs={6} justifyContent="flex-end">
                <CloseIcon onClick={props.handleClose} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <div
                className={classes.previewImg}
                style={{ backgroundImage: `url(${previewImage})` }}
              ></div>
            </Grid>
          </Grid>
          <Divider />
          <Grid container item xs={12}>
            <Grid container item xs={9}>
              {images &&
                images.map((image: string, index: number) => {
                  return (
                    <button
                      className={classes.thumbButton}
                      type="button"
                      onClick={() => handlePreviewImage(image)}
                      key={index}
                    >
                      <Grid item>
                        <img
                          src={image}
                          className={classes.thumbImg}
                          alt=""
                        ></img>
                      </Grid>
                    </button>
                  );
                })}
            </Grid>
            <Grid container item xs={3} justifyContent="flex-end">
              <Fab
                style={{ marginTop: "30px" }}
                className="btn-black"
                variant="extended"
                color="primary"
                aria-label="addDocument"
              >
                <a
                  href={previewImage}
                  download={title}
                  style={{ color: "white", fontSize: "16px", textDecoration: "none" }}
                >
                  Download Image
                </a>
              </Fab>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}
