import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  TextField,
  Theme,
  Typography
} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import { DateTime as d } from "luxon";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { GET_UNPAID_INVOICES_FOR_BUSINESS_CUSTOMER } from "../../../graphql/invoices/getUnpaidInvoicesForBusinessCustomer";
import { GET_BUSINESS_CUSTOMER_PRICE } from "../../../graphql/priceRules/getBusinessCustomersQuery";
import { IBusinessCustomer } from "../../../reducers/customer/types";
import {
  IConsolidatedInvoice,
  IConsolidatedInvoiceInput,
  IInvoice
} from "../../../reducers/invoices/types";
import { IAppState } from "../../../store";
import Autocomplete from "@mui/material/Autocomplete";
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import {
  getLocalizedBookingSyntex,
  getLocalizedDateFormat
} from "../../../utils/localized.syntex";
import FlatPickerBar from "../../common/FlatPicker";
import { NuvvenTable } from "../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../common/NuvvenTable/types";
import { DATE_TYPE, toCurrency } from "../../common/utils";
import { InvoiceStatus } from "./UpdateInvoice/InvoiceComponents/types";
import { InvoiceType, reshapeInvoicesIntoRows } from "./utils";
import { useLazyQuery } from "@apollo/client";

interface IDialogProps {
  open: boolean;
  handleClose: () => void;
  inProgress: boolean;
  handleSubmit: (args: IConsolidatedInvoiceInput) => void;
}

interface IRowSelect {
  index: number;
  dataIndex: number;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const ConsolidatedBillingDialog: React.FC<IDialogProps> = (props) => {
  const { open, handleClose, handleSubmit, inProgress } = props;
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [selection, setSelection] = useState<any[]>([]);
  const [values, setValues] = useState<IConsolidatedInvoice>({
    businessCustomerId: "",
    businessCustomerEmail: "",
    startDate: "",
    endDate: ""
  });

  const [businessCustomers, setBusinessCustomers] = useState<
    IBusinessCustomer[]
  >([]);
  const [selectedBusinessCustomer, setSelectedBusinessCustomer] = useState<
    string
  >("");
  const [unpaidInvoices, setUnpaidInvoices] = useState<IInvoice[]>([]);

  const [loadBusinessCustomers, { data: businessCustomersData }] = useLazyQuery(
    GET_BUSINESS_CUSTOMER_PRICE,
    {
      fetchPolicy: "network-only"
    }
  );

  const [loadConsolidatedBillCount, { loading }] = useLazyQuery(
    GET_UNPAID_INVOICES_FOR_BUSINESS_CUSTOMER,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.getUnpaidInvoicesForBusinessCustomer) {
          const invoiceDetails = shapeInvoices(
            data.getUnpaidInvoicesForBusinessCustomer
          );
          setUnpaidInvoices(invoiceDetails);
        }
      }
    }
  );

  useEffect(() => {
    if (userState.tenancy) {
      loadBusinessCustomers();
    }
  }, [userState.tenancy]);

  useEffect(() => {
    if (businessCustomersData && businessCustomersData.businessCustomers) {
      setBusinessCustomers(
        _.sortBy(businessCustomersData.businessCustomers, (businessCustomer) =>
          businessCustomer.businessName.toLowerCase().trim()
        )
      );
    }
  }, [businessCustomersData]);

  const shapeInvoices = (data: any) => {
    let invoicesArr = data.map((invoice: IInvoice) => {
      const isBusinessCustomerBooking =
        invoice.customerType === "business" &&
          invoice.businessCustomer &&
          invoice.businessCustomer.id !== ""
          ? true
          : false;
      const businessCustomer = invoice.businessCustomer;
      const individualCustomer = invoice.customer;
      const customer = isBusinessCustomerBooking
        ? businessCustomer?.businessName || ""
        : individualCustomer
          ? individualCustomer.firstName + " " + individualCustomer.lastName
          : "";
      let invoiceRow = reshapeInvoicesIntoRows(
        invoice,
        locale,
        currency,
        invoice.booking?.referenceNumber || "",
        customer,
        country
      );
      return invoiceRow;
    });
    return invoicesArr;
  };

  const handleAutoCompleteInputChange = _.debounce(
    (value: string, reason: any) => {
      if (reason === "input" || reason === "reset") {
        const resourcesToFilter = [...businessCustomers];
        if (value) {
          const matchedBusinessCustomer = resourcesToFilter.find(
            (businessCusotmer) => businessCusotmer.businessName === value
          );
          if (matchedBusinessCustomer) {
            setValues({
              ...values,
              businessCustomerId: matchedBusinessCustomer.id,
              businessCustomerEmail: matchedBusinessCustomer.billing.email
            });
            if (
              matchedBusinessCustomer.id &&
              values.startDate &&
              values.endDate
            ) {
              loadConsolidatedBillCount({
                variables: {
                  businessCustomerId: matchedBusinessCustomer.id,
                  fromDate: values.startDate,
                  toDate: values.endDate
                }
              });
            }
          }
        }
      }
      if (reason === "clear") {
        setValues({
          ...values,
          businessCustomerId: "",
          businessCustomerEmail: ""
        });
        setSelectedBusinessCustomer("");
      }
    },
    200
  );

  const columns = [
    {
      label: `${getLocalizedBookingSyntex(country)} Ref.`,
      name: "referenceNumber",
      options: {
        filter: false
      }
    },
    {
      label: "Invoice Id",
      name: "invoiceRef",
      options: {
        filter: false
      }
    },
    {
      label: "Invoice Date",
      name: "dateCreated",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string) => {
          if (value) {
            return getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED);
          } else {
            return "-";
          }
        }
      }
    },
    {
      label: "Invoice Type",
      name: "invoiceType",
      options: {
        customBodyRender: (value: string) => {
          if (value === InvoiceType.SUNDRY) {
            return "SUNDRY";
          } else {
            return "REGULAR";
          }
        },
        filterOptions: {
          names: ["SUNDRY", "REGULAR"]
        }
      }
    },
    {
      label: "Customer",
      name: "customer",
      options: {
        filter: false,
        display: "false" as const
      }
    },
    {
      label: "Total",
      name: "total",
      options: {
        display: "false" as const,
        filter: false,
        customBodyRender: (value: number) => {
          if (value) {
            return toCurrency(value, currency, locale);
          }
          return "-";
        }
      }
    },
    {
      label: "Balance Due",
      name: "dueAmount",
      options: {
        filter: false,
        customBodyRender: (value: number) => {
          if (value) {
            return toCurrency(value, currency, locale);
          }
          return "-";
        }
      }
    },
    {
      label: "Due Date",
      name: "dueDate",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string) => {
          if (value) {
            return getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED);
          } else {
            return "-";
          }
        }
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        filter: false,
        display: "false" as const,
        customBodyRender: (value: any) => {
          if (
            [InvoiceStatus.PARTIALLY_PAID, InvoiceStatus.UNPAID].includes(value)
          ) {
            return "UNPAID";
          } else {
            return value;
          }
        },
        filterOptions: {
          names: [
            InvoiceStatus.DRAFT,
            InvoiceStatus.PAID,
            InvoiceStatus.UNPAID,
            InvoiceStatus.VOID
          ]
        }
      }
    },
    {
      label: "Email",
      name: "lastSent",
      options: {
        display: "false" as const,
        customBodyRender: (value: any) => {
          if (value) {
            return "SENT";
          } else {
            return "-";
          }
        },
        filterOptions: {
          names: ["-", "SENT"]
        }
      }
    }
  ];

  const options: MUIDataTableOptions = {
    elevation: 0,
    selectableRows: SelectableRows.MULTIPLE,
    count: unpaidInvoices.length,
    rowsPerPageOptions: [10, 20, 100],
    onTableChange: (action: string, tableState: any) => {
      tableState.filterData[3] = ["SUNDRY", "REGULAR"];
      tableState.filterData[9] = ["SENT", "-"];
    },
    download: false,
    print: false,
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: loading
          ? "Loading..."
          : "Sorry, there is no matching data to display"
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of"
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table"
      },
      filter: {
        all: "All",
        title: "Filters",
        reset: "RESET"
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns"
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows"
      }
    },
    onRowSelectionChange(currentRowsSelected, allRowsSelected, rowsSelected) {
      console.log(currentRowsSelected, allRowsSelected, rowsSelected)
      if (rowsSelected?.length) {
        setSelection(rowsSelected)
      }
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      className={"select-holidays-dialog"}
    >
      <React.Fragment>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p
            style={{
              fontSize: 18,
              color: "var(--theme-accent)",
              padding: 0,
              margin: 0
            }}
          >
            Generate Consolidated Invoice
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} style={{ marginTop: 10 }}>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                options={businessCustomers}
                getOptionLabel={(option: any) => option.businessName}
                onInputChange={(event: any, value: string, reason: string) => {
                  handleAutoCompleteInputChange(value, reason);
                  setSelectedBusinessCustomer(value);
                }}
                inputValue={selectedBusinessCustomer}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select business customer"
                    placeholder="Business customer name"
                    required
                    margin="normal"
                    variant="outlined"
                    style={{ backgroundColor: "white" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4} style={{ marginTop: 10 }}>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {
                  setValues({
                    ...values,
                    startDate: d
                      .fromJSDate(value)
                      .toUTC()
                      .toISO()
                  });
                  if (values.businessCustomerId && values.startDate && value) {
                    loadConsolidatedBillCount({
                      variables: {
                        businessCustomerId: values.businessCustomerId,
                        fromDate: d
                          .fromJSDate(value)
                          .toUTC()
                          .toISO(),
                        toDate: values.endDate
                      }
                    });
                  }
                }}
                required
                label={"Start Date"}
                identifier={`startDate`}
                placeholderValue={"select date..."}
                value={getLocalizedDateFormat(
                  country,
                  values.startDate,
                  DATE_TYPE.CONDENSED
                )}
                maxDate={"today"}
                country={country}
              />
            </Grid>
            <Grid item xs={6} md={4} style={{ marginTop: 10 }}>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {
                  setValues({
                    ...values,
                    endDate: d
                      .fromJSDate(value)
                      .toUTC()
                      .toISO()
                  });
                  if (value) {
                    loadConsolidatedBillCount({
                      variables: {
                        businessCustomerId: values.businessCustomerId,
                        fromDate: values.startDate,
                        toDate: d
                          .fromJSDate(value)
                          .toUTC()
                          .toISO()
                      }
                    });
                  }
                }}
                required
                disabled={!values.startDate}
                label={"End Date"}
                identifier={`endDate`}
                placeholderValue={"select date..."}
                value={getLocalizedDateFormat(
                  country,
                  values.endDate,
                  DATE_TYPE.CONDENSED
                )}
                minDate={getLocalizedDateFormat(
                  country,
                  values.startDate,
                  DATE_TYPE.CONDENSED
                )}
                maxDate={"today"}
                country={country}
              />
            </Grid>
            {loading && <CircularProgress />}
            {!loading && unpaidInvoices.length > 0 && (
              <Grid container item spacing={2}>
                <Grid item>
                  <Typography variant="body1">
                    {unpaidInvoices.length} unpaid invoice(s) found.
                    <br />
                    Click on the 'Generate Invoice' button to create a new
                    consolidated invoice for the selected invoices, note that
                    the existing invoices will be marked as VOID.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <NuvvenTable
                    title=""
                    rows={unpaidInvoices}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            )}
            {!loading && unpaidInvoices.length === 0 && values.endDate && (
              <Typography variant="body1" style={{ marginLeft: 6 }}>
                No unpaid invoices found for selected business customer.
              </Typography>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} style={{ paddingLeft: 15 }}>
            <Fab
              variant="extended"
              size="medium"
              aria-label="submit"
              onClick={() => {
                let invoiceIds: string[] = [];
                let businessCustomerId =
                  businessCustomers.find(
                    (customer) =>
                      customer.businessName === selectedBusinessCustomer
                  )?.id || "";
                selection.forEach((index) =>
                  invoiceIds.push(unpaidInvoices[index].id)
                );
                handleSubmit({
                  businessCustomerId,
                  invoiceIds: invoiceIds,
                  startDate: values.startDate,
                  endDate: values.endDate
                });
              }}
              disabled={
                inProgress ||
                !values.businessCustomerId ||
                !values.startDate ||
                !values.endDate ||
                !selection.length
              }
            >
              {inProgress && (
                <CircularProgress
                  size={20}
                  thickness={5}
                  style={{ marginRight: "10px" }}
                />
              )}
              Generate Invoice
            </Fab>
          </Grid>
        </DialogActions>
      </React.Fragment>
    </Dialog>
  );
};
