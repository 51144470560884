import { useMutation } from "@apollo/client";
import {
  Box,
  Fab,
  Grid,
  Typography,
  FormControl
} from "@mui/material";
import { ApolloError } from "@apollo/client";
import { Field, FieldProps, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { DateTime as d } from "luxon"
import {
  resetApprovedDriver,
  setBusinessApprovedDrivers,
  updateBusinessApprovedDriver
} from "../../../../../../actions/customer/actions";
import {
  ADD_BUSINESS_CUSTOMERDRIVER,
  UPDATE_BUSINESS_CUSTOMERDRIVER
} from "../../../../../../graphql/businessCustomers/businessCustomerDriver";
import {
  IBusinessCustomer,
  IBusinessCustomerApprovedDriverInput,
} from "../../../../../../reducers/customer/types";
import { IAppState } from "../../../../../../store";
import FlatPickerBar from "../../../../../common/FlatPicker";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  formatGraphQLErrorMessage,
  returnfileContentTypeAndExtention,
  getUploadedImageByKey,
  DATE_TYPE
} from "../../../../../common/utils";
import {
  validateName
} from "../../../../../common/ValidationRules";
import { CurrentApprovedDrivers } from "./CurrentApprovedDrivers";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { CircularProgress } from "@mui/material";
import { getDefaultCountryCode, getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import AddressPicker from "../../../NewCustomer/AddressPicker/AddressPicker";
import { IAddress } from "../../../../../../reducers/user/types";
import { SnackBar } from "../../../../../common/SnackBar/SnackBar";
import { captureErrorException } from "../../../../../../utils/sentry";
import DragDropImages from "../../../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";

export const ApprovedDrivers = () => {
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const businessCustomer: IBusinessCustomer = useSelector(
    (state: IAppState) => state.customerReducer.businessCustomer
  );
  const approvedDriver: IBusinessCustomerApprovedDriverInput = useSelector(
    (state: IAppState) => state.customerReducer.approvedDriver
  );
  const [values, setValues] = useState(approvedDriver);
  const [licenseImages, setLicenseImages] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<object[]>([]);
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(
    false
  );
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<any>(getDefaultCountryCode(country));
  const [phoneError, setPhoneError] = useState<string>("");
  const [minDate, setMinDate] = useState<string>(d.now().toFormat('dd/MM/yyyy'));
  const [approvedDriverLoading, setApprovedDriverLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [addBusinessCustomerDriver] = useMutation(ADD_BUSINESS_CUSTOMERDRIVER, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      snackbar({
        message: "Business Customer Driver added.",
        variant: SnackBarVariant.SUCCESS
      });
      dispatch(setBusinessApprovedDrivers(data.addBusinessCustomerDriver));
      dispatch(resetApprovedDriver());
      setApprovedDriverLoading(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setApprovedDriverLoading(false);
    }
  });
  const [updateBusinessCustomerDriver] = useMutation(
    UPDATE_BUSINESS_CUSTOMERDRIVER,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        snackbar({
          message: "Business Customer Driver updated.",
          variant: SnackBarVariant.SUCCESS
        });
        dispatch(
          updateBusinessApprovedDriver(data.updateBusinessCustomerDriver)
        );
        dispatch(resetApprovedDriver());
        setLoading(false);
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        setLoading(false);
      }
    }
  );

  useEffect(() => {
    if (approvedDriver) {
      setValues(approvedDriver);
      const existingImage: any[] = [];
      if (approvedDriver.license && approvedDriver.license.images && approvedDriver.license.images.length) {
        setLicenseImages(approvedDriver.license.images);
        approvedDriver.license.images.forEach(async (image: any) => {
          existingImage.push(await getUploadedImageByKey(image));
        })
      }
      setUploadedImages(existingImage);
    }
  }, [approvedDriver]);

  const uploadDocument = async (
    files: File[]
  ) => {
    try {
      const licenceImageArray: any[] = [];
      const showUploadedImages: any[] = [];
      if (!userState.tenancy?.id) {
        return;
      }
      if (uploadInProgress) {
        return snackbar({
          message: "Please wait image upload in progress!",
          variant: SnackBarVariant.ERROR
        });
      }
      if (files && files.length > 2) {
        return snackbar({
          message: "Maximum two images can be uploaded for licence!",
          variant: SnackBarVariant.ERROR
        });
      }
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        // 50MB limit exceeded
        if (file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        setUploadInProgress(true);
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
          const { fileExtension } = returnfileContentTypeAndExtention(file);
          const uniqueId = uuidv4();
          const key = `${uniqueId}.${fileExtension}`;
          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  licenceImageArray.push(key);
                  snackbar({
                    message: "Licence Image Uploaded Successfully",
                    variant: SnackBarVariant.SUCCESS
                  });
                  const uploadedFile = await getUploadedImageByKey(key);
                  if (uploadedFile) {
                    showUploadedImages.push(uploadedFile);
                  }
                  setUploadInProgress(false);
                  setDocumentDialogVisible(false);
                }
              });
          }
        } else {
          setDocumentDialogVisible(true);
          setUploadInProgress(false);
          return snackbar({
            message: "Please only upload .jpeg/.jpg/.png file!",
            variant: SnackBarVariant.ERROR
          });
        }
      }
      setLicenseImages(licenceImageArray);
      setUploadedImages(showUploadedImages);
    } catch (err: any) {
      snackbar({
        message: formatGraphQLErrorMessage(err.message),
        variant: SnackBarVariant.ERROR
      });
    }
  };

  const onDeleteSelectedImage = (deletedFileObject: File) => {
    const images = licenseImages;
    const previewImages = uploadedImages;
    const deletedImageIndex = images.findIndex((image) => image === deletedFileObject.name);
    if (deletedImageIndex) {
      images.splice(deletedImageIndex, 1);
      previewImages.splice(deletedImageIndex, 1);
    }
    setLicenseImages([...images]);
    setUploadedImages([...previewImages]);
  }

  const submitHandler = (
    values: IBusinessCustomerApprovedDriverInput,
    actions?: any
  ) => {
    const driverId = values.id;
    if (values.location && values.location.fullAddress && (!values.location.street || !values.location.zipcode)) {
      return snackbar({
        message: "Street/zipcode is requied",
        variant: SnackBarVariant.ERROR
      });
    }
    if (licenseImages && licenseImages.length) {
      values.license.images = [...licenseImages];
    }
    delete values.id;
    delete values.status;
    if (driverId && businessCustomer && businessCustomer.id) {
      updateBusinessCustomerDriver({
        variables: {
          businessCustomerId: businessCustomer.id,
          driverId,
          driver: values
        }
      });
      setLoading(true);
    } else {
      addBusinessCustomerDriver({
        variables: {
          businessCustomerId: businessCustomer.id,
          driver: values
        }
      });
      setApprovedDriverLoading(true);
    }
    actions.setSubmitting(false);
    setLicenseImages([]);
    setValues(approvedDriver);
  };

  const approvedDriverSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string()
      .required("Email is required.")
      .email("Please enter a valid email address."),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required.")
    })
  });
  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={approvedDriverSchema}
        initialValues={values}
        onSubmit={submitHandler}
      >
        {(props) => (
          <Form noValidate>
            <Grid container>
              <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12}>
                  <Typography variant="h2" gutterBottom>
                    ADD AND EDIT APPROVED DRIVERS
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="firstName"
                    placeholder="First Name"
                    label="First Name"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          firstName: e.target.value
                        })
                      },
                      value: props.values.firstName,
                      maxLength: 50
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="lastName"
                    placeholder="Last Name"
                    label="Last Name"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          lastName: e.target.value
                        })
                      },
                      value: props.values.lastName,
                      maxLength: 50
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="email"
                    placeholder="Email"
                    label="Email"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          email: e.target.value
                        })
                      },
                      value: props.values.email,
                      maxLength: 50
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name={"phoneNumber.phone"}
                    required
                  >
                    {({ form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                      return (
                        <div className={`phone-input-container`}>
                          <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                            Phone Number <sup className={"phone-input-required"}>*</sup>
                          </label>
                          <PhoneInput
                            international={false}
                            defaultCountry={countryCode}
                            placeholder="Enter phone number"
                            className={phoneError ? "phone-input-error" : ""}
                            value={props.values.phoneNumber.phone}
                            name={"phoneNumber.phone"}
                            required
                            onChange={(val: any) => {
                              if (val && isValidPhoneNumber(val)) {
                                setPhoneError("");
                              }
                              setValues({
                                ...props.values,
                                phoneNumber: {
                                  ...props.values.phoneNumber,
                                  phone: val
                                }
                              });
                            }}
                            onBlur={() => {
                              if (!props.values.phoneNumber.phone) {
                                setPhoneError("Phone number is required.");
                              } else if (!isValidPhoneNumber(props.values.phoneNumber.phone)) {
                                setPhoneError("Enter a valid phone number.");
                              } else {
                                setPhoneError("");
                              }
                            }}
                            onCountryChange={(val) => {
                              setCountryCode(val);
                            }}
                          />
                          {
                            phoneError !== "" ?
                              <span className={"phone-error-message"}>{phoneError}</span>
                              : (meta.touched && meta.error) &&
                              <span className={"phone-error-message"}>
                                {phoneError || "Phone number is required."}
                              </span>
                          }
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="National Insurance Number"
                    label="National Insurance Number"
                    name={"nationalInsuranceNumber"}
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          nationalInsuranceNumber: e.target.value
                        })
                      },
                      value: props.values.nationalInsuranceNumber,
                      maxLength: 20
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Profession"
                    label="Profession"
                    name={"profession"}
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          profession: e.target.value
                        })
                      },
                      value: props.values.profession,
                      maxLength: 30
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Driving Licence Number"
                    label="Driving Licence Number"
                    name={"license.licenseNumber"}
                    inputProps={{
                      onchange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        const license = {
                          ...props.values.license,
                          licenseNumber: e.target.value
                        };
                        setValues({
                          ...props.values,
                          license: license
                        })
                      },
                      value: props.values.license?.licenseNumber,
                      maxLength: 20
                    }}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Licence Issued By"
                    label="Licence Issued By"
                    name={"license.authority"}
                    inputProps={{
                      onchange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        const license = {
                          ...props.values.license,
                          authority: e.target.value
                        };
                        setValues({
                          ...props.values,
                          license: license
                        })
                      },
                      value: props.values.license.authority,
                      maxLength: 100
                    }}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <AddressPicker
                    fieldName={"location"}
                    onChange={(address: IAddress) => {
                      if (address) {
                        setValues({ ...props.values, location: address })
                      }
                    }}
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormControl variant="outlined" fullWidth>
                    <FlatPickerBar
                      enableTime={false}
                      handleDateChange={(value: Date) => {
                        const license = {
                          ...props.values.license,
                          dateOfExpiry: d.fromJSDate(value).toUTC().toISO()
                        };
                        setValues({
                          ...props.values,
                          license: license
                        })
                      }}
                      label={"Driving Licence Expiry Date"}
                      identifier={"dateOfExpiry"}
                      placeholderValue={" Driving Licence Expiry Date"}
                      value={getLocalizedDateFormat(country, props.values.license.dateOfExpiry, DATE_TYPE.CONDENSED)}
                      minDate={getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}
                      country={country}
                    />
                  </FormControl>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Fab
                    className="blackBackButton"
                    variant="extended"
                    size="medium"
                    aria-label="Update"
                    onClick={() => {
                      setDocumentDialogVisible(true);
                    }}
                  >
                    UPLOAD LICENCE IMAGE
                  </Fab>

                  <Grid item>
                    <Typography variant="h4">
                      Upload front and back image of Licence. Supported file format: .png,
                      .jpeg, .jpg!
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Fab
                    className="blackBackButton"
                    variant="extended"
                    size="medium"
                    aria-label="add"
                    type="submit"
                    disabled={approvedDriverLoading || loading}
                  > {(approvedDriverLoading || loading) && (
                    <CircularProgress
                      size={14}
                      style={{ color: "white", marginRight: "10px" }}
                    />
                  )}
                    {approvedDriver.id ? "Save" : "ADD"}
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <div>
        <DragDropImages
          open={documentDialogVisible}
          handleCloseDragDrop={() => setDocumentDialogVisible(false)}
          handleSave={uploadDocument}
          filesLimit={2}
          title={"Upload Licence Images"}
        />
      </div>
      <Box mb={2}></Box>
      <CurrentApprovedDrivers
        approvedDrivers={businessCustomer.approvedDrivers}
      />
    </React.Fragment>
  );
};
