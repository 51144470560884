import { useLazyQuery } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography,
  Hidden,
} from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApolloError } from "@apollo/client";
import { IAppState } from "../../../../../store";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { useNavigate } from "react-router-dom";
import { SelectableRows } from "../../../../common/NuvvenTable/types";
import { GET_USER_ACCESS_POLICIES } from "../../../../../graphql/userPermissions/userAccessQuery";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from "../../../../common/utils";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { addTableState } from "../../../../../actions/tableState/actions";
import { ITable, TableNames } from "../../../../../reducers/tableState/types";

export interface IRowUserAccessPolicy {
  id: string;
  name: string;
  permissions: string[];
  createdAt: string;
  updatedAt: string;
}

export enum UserAccessType {
  SYSTEM_DEFINED = "SYSTEM_DEFINED",
  USER_DEFINED = "USER_DEFINED"
}

export const UserAccessTypeVaue: {
  [key in UserAccessType]: string
} = {
  SYSTEM_DEFINED: "System",
  USER_DEFINED: "User"
}

export const Policies: FC = () => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();
  const [ploiciesTable, setPoliciesTable] = useState<ITable>();
  const policiesTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.policies
  );
  const [sortOrder, setSortOrder] = useState<string | undefined>();


  const { country } = userState.currentOrganisation.address;
  const [rows, setRows] = useState<IRowUserAccessPolicy[]>([]);

  const [getUserAccessPolicies, { loading, data }] = useLazyQuery(GET_USER_ACCESS_POLICIES, {
    fetchPolicy: "network-only",
    onCompleted: () => { },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    getUserAccessPolicies();
  }, [userState])
  
  useEffect(() => {
    if (policiesTableState) {
      setPoliciesTable(policiesTableState);
      let sortedOrder = returnSortedOrder(policiesTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, policiesTableState, sortedOrder)
      }
    }
  }, [policiesTableState]);

  useEffect(() => {
    if (data && data.getUserAccessPolicies) {
      setRows(data.getUserAccessPolicies)
    }
  }, [data])

  const columns = [
    {
      label: "Policy Name",
      name: "name"
    },
    {
      label: "Created At",
      name: "createdAt",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Updated At",
      name: "updatedAt",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Defined By",
      name: "policyType",
      options: {
        customBodyRender: (value: UserAccessType) => UserAccessTypeVaue[value]
      }
    },
    {
      label: "Status",
      name: "isActive",
      options: {
        customBodyRender: (value: string) => value ? "Active" : "In-active"
      }
    },
  ];

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    onRowClick: (rowData: any, rowMeta: any) => {
      const item: any = rows[rowMeta.dataIndex];
      if (item) {
        navigate(`/update-policy?policy=${item.id}`, { state: { policyType: item.policyType } });
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)      
      dispatch(
        addTableState({
          tableName: TableNames.POLICIES,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
    ploiciesTable && ploiciesTable.rowsPerPage
        ? ploiciesTable.rowsPerPage
        : limit,
  }

  return (
    <div>
      <Grid container spacing={2}>
        <CssBaseline />
        <Grid container item xs={6} alignItems="center">
          <Typography variant="h1" color="primary">
            {"  "}Policies{"  "}
          </Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          <Fab
            variant="extended"
            size="medium"
            aria-label="Add"
            onClick={() => {
              navigate("/new-policy");
            }}
          >
            Create
          </Fab>
        </Grid>
        <Grid container item xs={12}>
          {loading ? <CircularProgress /> : (
            <NuvvenTable
              title={""}
              rows={rows}
              columns={columns}
              setSelection={() => { }}
              options={options}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}