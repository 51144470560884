import { gql } from "@apollo/client";

export const UPDATE_INVOICE_FINANCE_DOWNLOAD_HISTORY = gql`
  mutation updateDownloadHistorySettings($organisationId: ID!, $branchId: ID!, $downloadHistorySettings: DownloadHistorySettingsInput) {
    updateDownloadHistorySettings(organisationId: $organisationId, branchId: $branchId, downloadHistorySettings: $downloadHistorySettings) {
        id
      name
      emailId
      phoneNumber {
        phone
        country
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      timeZone
      owner {
        id
        email
      }
      operatingHours{
        day
        dayIndex
        workingFromTime
        workingToTime
        pickupFromTime
        pickupToTime
        returnFromTime
        returnToTime
        closed
      }
      minBookingDuration
      minimumBookingAmount{
        type
        value
      }
      minBookingAmountOptIn
      minBookingDurationOptIn
      preBookingQuestions{
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
      deliveryQuestions{
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
      downloadHistorySettings {
        invoiceHistory
      }
    }
  }
`;