import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar';
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';
import styles from '../index.module.css'
import { getYears, SelectButton } from '../../utils';
import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import MenuItem from '@mui/material/MenuItem';
import api from "../../api/Reports";
import { CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DateTime as d } from 'luxon';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { getLocalizedBookingSyntex } from '../../../../../utils/localized.syntex';
import { captureErrorException } from '../../../../../utils/sentry';

const StyledInput = withStyles({
  root: {
    '& .MuiOutlinedInput-inputcatch': {
      backgroundColor: '#f6f6f6',
      fontSize: 12,
      padding: "8px 8px 5px 8px !important",
      border: 0,
      borderRadius: 5,
    },
    '& fieldset': {
      border: 0
    }
  }
})(TextField);

const colors = ["#003f5c", "#2f4b7c", "#665191", "#a05195", "#d45087", "#f95d6a", "#ff7c43", "#ffa600", "#ec7c9e", "#e43d60"]

interface IChartData {
  id: string;
  color: string;
  data: {
    x: string
    y: number
  }[]
}
interface IBSChartData {
  [key: string]: number | string
}

const useStyles = makeStyles((theme: Theme) => ({

  tag: {
    marginTop: 3,
    height: 20,
    padding: '.15em 4px',
    fontWeight: 600,
    lineHeight: '15px',
    borderRadius: 2,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  iconSelected: {
    width: 12,
    height: 12,
    marginRight: 5,
    marginLeft: -8,
  },
  text: {
    flexGrow: 1,
  },
}));

export const VehicleUsageCharts = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const tenantId = userState && userState.currentTenancy ? userState.currentTenancy?.id : userState.tenancy.id;
  const [filters, setfilters] = useState<{ year: string }>({ year: `${d.now().get('year')}` });
  const [vehicleGroups, setVehicleGroups] = useState<string[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [chartData, setChartData] = useState<IChartData[]>([]);
  const [VGUtilAPIData, setVGUtilAPIData] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);

  const getVGUtilData = async () => {
    try {
      setLoader(true)
      const { data: VGUtilData } = await api.getVehicleGroupUtilDataYearly({
        tenantID: tenantId,
        organisationID: userState.currentOrganisation.id,
        branchID: userState.currentBranch.id,
        year: filters.year
      });
      setLoader(false);
      const vGNames = Object.keys(VGUtilData.vehicleGroupUtilisation)
      setVehicleGroups(vGNames)
      setVGUtilAPIData(VGUtilData.vehicleGroupUtilisation);
      setSelectedGroups(vGNames.slice(0, 10));
    }
    catch (error: any) {
      captureErrorException(error)
      setLoader(false);
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading vehicle group utilisation data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }


  const formatAndSetChartData = () => {
    const rawData: { [key: string]: { month: string[], RentalDays: string[] } } = VGUtilAPIData;
    if (rawData) {
      let formattedData: IChartData[] = [];
      selectedGroups.map((group, index) => {
        rawData[group] && formattedData.push({
          id: group,
          color: colors[index],
          data: rawData[group].month.map((month, idx) => {
            return {
              x: month.slice(0, 1).toUpperCase() + month.slice(1, 3),
              y: parseFloat(rawData[group].RentalDays[idx])
            }
          })
        })
      })
      setChartData(formattedData);
    }
  }

  useEffect(() => {
    getVGUtilData();
  }, [filters])

  useEffect(() => {
    setInputValue("");
    formatAndSetChartData();
  }, [selectedGroups])

  const removeGroup = (name: string) => {
    let tempArr = [...selectedGroups];
    const groupIndex = tempArr.findIndex(item => item === name);
    if (groupIndex >= 0) {
      tempArr.splice(groupIndex, 1)
    }
    setSelectedGroups(tempArr)
  }

  return (
    <div className={`${styles.module} ${styles.flexible}`}>
      <div className={styles.chartHeadline} style={{ paddingRight: "calc(40% - 20px)" }}>
        <span className="font-medium bold ">Vehicle Usage by Groups</span>
        <div className="flex">
          {loader ? <div className="margin-right flex cross-center">
            <CircularProgress size={20} thickness={5} />
          </div> : null}
          <SelectButton
            onChange={(selectedYear) => setfilters({ year: selectedYear })}
            id="VEHICLE_UTIL_CHART_YEAR"
            initialValue={`${d.now().get("year")}`}
          >
            {getYears().map(year => (
              <MenuItem value={year}>
                {year}
              </MenuItem>
            ))}
          </SelectButton>
        </div>
      </div>
      <div className={styles.legendWrap}>
        {vehicleGroups.length ?
          <Autocomplete
            freeSolo
            clearOnEscape
            clearOnBlur
            disableClearable
            noOptionsText="No Groups"
            id="free-solo-fc-search"
            options={vehicleGroups}
            inputValue={inputValue}
            onInputChange={(event: any, value: any) => {
              setInputValue(value)
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <DoneIcon
                  style={{ fontSize: 12, margin: '0 5px 0 -10px', opacity: selectedGroups.includes(option) ? 1 : 0 }}
                />
                <div className={classes.text}>
                  {option}
                </div>
              </li>
            )}
            value=""
            onChange={(event: any, value: string, reason: string) => {
              if (!selectedGroups.includes(value) && selectedGroups.length < 10) {
                setSelectedGroups([...selectedGroups, value])
              }
              if (selectedGroups.length === 10) {
                snackbar({
                  message: "Max 10 vehicle groups can be selected",
                  variant: SnackBarVariant.ERROR
                });
              }
            }}
            onClose={() => setInputValue("")}
            onBlur={() => setInputValue("")}
            renderInput={(params) => (
              <StyledInput
                {...params}
                variant="outlined"
                placeholder={"Add vehicle group"}
                InputProps={{ ...params.InputProps, type: "search" }}
              />
            )}
          /> : <div style={{ width: "100%", padding: "50px 0", textAlign: "center" }}>
            <CircularProgress />
          </div>}
        <div className="padding-top"></div>
        {chartData.length ? chartData.map((item, index) => (
          <div className={styles.vglegend}>
            <div className={styles.tick} style={{ background: item.color }}></div>
            <span>{item.id}</span>
            <div className={styles.remove}>
              <IconButton onClick={() => removeGroup(item.id)} aria-label="delete" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </div>
          </div>
        )) : null}
      </div>
      <div className={styles.chartWrap}>
        <ResponsiveLine
          data={chartData}
          margin={{ top: 30, right: 20, bottom: 25, left: 200 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
          axisTop={null}
          curve="monotoneX"
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          axisLeft={{
            tickValues: 8,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Rental days per vehicle',
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          lineWidth={2}
          enableGridX={true}
          enableGridY={false}
          colors={({ id, data, color }) => color}
          pointSize={7}
          pointColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
          pointBorderColor="#000"
          pointLabelYOffset={-12}
          useMesh={true}
          enableSlices="x"
          sliceTooltip={({ slice }) => {
            const points = slice.points.sort((a, b) => parseFloat(`${b.data.yFormatted}`) - parseFloat(`${a.data.yFormatted}`))
            return (
              <div
                style={{
                  background: 'white',
                  padding: '9px 12px',
                  border: '1px solid #ccc',
                }}
              >
                {points.map(point => (
                  <div
                    key={point.id}
                    style={{
                      padding: '3px 0',
                      fontSize: 12,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <div style={{ background: point.serieColor, height: "8px", width: "8px", marginRight: 5 }} />{point.serieId} : <strong>{point.data.yFormatted}</strong>
                  </div>
                ))}
              </div>
            )
          }}
        />
      </div>
      <AllTimeUtilCharts selectedGroups={selectedGroups} />
    </div>
  )
}

const AllTimeUtilCharts: React.FC<{ selectedGroups: string[] }> = (props) => {
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const tenantId = userState && userState.currentTenancy ? userState.currentTenancy?.id : userState.tenancy.id;
  const [rawData, setRawData] = useState<any>();
  const [NOBChartData, setNOBChartData] = useState<IBSChartData[]>([]);
  const [meanHoursChartData, setMeanHoursChartData] = useState<IBSChartData[]>([]);

  const fetchData = async () => {
    try {
      const { data: axiosData } = await api.getVehicleGroupUtilDataAllTime({
        tenantID: tenantId,
        organisationID: userState.currentOrganisation.id,
        branchID: userState.currentBranch.id,
      });
      setRawData(axiosData.vehicleGroupBookingSummary);
    }
    catch (error: any) {
      captureErrorException(error)
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem on loading finance info data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  useEffect(() => {
    if (rawData && rawData.numberOfBookings && rawData.meanBookingDurationHours) {
      let NOBFormattedData: IBSChartData = {
        "type": "Bookings"
      };
      let meanHoursFormattedData: IBSChartData = {
        "type": "Mean Booking Hours"
      }
      if (props.selectedGroups && props.selectedGroups.length) {
        props.selectedGroups.map((group, index) => {
          const VGIndexOnNOBData = rawData.vehicleGroup.findIndex((item: string) => item.replace(/\s/g, "") === group)
          NOBFormattedData[group] = parseFloat(rawData.numberOfBookings[VGIndexOnNOBData]);
          NOBFormattedData[`${group}-color`] = colors[index];
          meanHoursFormattedData[group] = parseFloat(rawData.meanBookingDurationHours[VGIndexOnNOBData]);
          meanHoursFormattedData[`${group}-color`] = colors[index];
        })
        setNOBChartData([NOBFormattedData]);
        setMeanHoursChartData([meanHoursFormattedData]);
      }
    }
  }, [rawData, props.selectedGroups])

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <>
      <div className={styles.chartWrap} style={{ width: "17%", left: "60%" }}>
        <ResponsiveBar
          data={NOBChartData}
          keys={props.selectedGroups}
          indexBy="Type"
          groupMode="grouped"
          margin={{ top: 30, right: 0, bottom: 15, left: 50 }}
          padding={0}
          innerPadding={4}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: false }}
          colors={({ id, data }: any) => data[`${id}-color`]}
          theme={{
            grid: {
              line: {
                stroke: "#f2f2f2",
                strokeWidth: 1
              }
            }
          }}
          tooltip={point => {
            return <div
              key={point.id}
              style={{
                padding: '3px 0',
                fontSize: 14,
                display: "flex",
                alignItems: "center"
              }}
            >
              <div style={{ background: point.color, height: "8px", width: "8px", marginRight: 5 }} />{point.id} : <strong>{point.value}</strong>
            </div>
          }}
          borderRadius={3}
          borderWidth={0}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={{
            tickValues: 5,
            tickSize: 5,
            tickPadding: 5,
            legend: `No of ${getLocalizedBookingSyntex(country).toLowerCase()} (Life time)`,
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#fff"

          animate={true}
        />
      </div>
      <div className={styles.chartWrap} style={{ width: "23%", left: "77%", paddingRight: 30 }}>
        <ResponsiveBar
          data={meanHoursChartData}
          keys={props.selectedGroups}
          indexBy="Type"
          groupMode="grouped"
          margin={{ top: 30, right: 0, bottom: 15, left: 60 }}
          padding={0}
          innerPadding={4}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: false }}
          colors={({ id, data }: any) => data[`${id}-color`]}
          theme={{
            grid: {
              line: {
                stroke: "#f2f2f2",
                strokeWidth: 1
              }
            }
          }}
          tooltip={point => {
            return <div
              key={point.id}
              style={{
                padding: '3px 0',
                fontSize: 14,
                display: "flex",
                alignItems: "center"
              }}
            >
              <div style={{ background: point.color, height: "8px", width: "8px", marginRight: 5 }} />{point.id} : <strong>{point.value}</strong>
            </div>
          }}
          borderRadius={3}
          borderWidth={0}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={{
            tickValues: 5,
            tickSize: 5,
            tickPadding: 5,
            legend: `Mean ${getLocalizedBookingSyntex(country).toLowerCase()} hours (Life time)`,
            legendOffset: -50,
            legendPosition: 'middle'
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#fff"

          animate={true}
        />
      </div>
    </>

  )
}