import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Grid,
  CssBaseline,
  Typography,
  Paper,
  Theme,
  FormControl,
  TextField,
  MenuItem,
  Box,
  CircularProgress,
  Fab,
  IconButton,
  Tooltip
} from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import { Formik, Form, Field } from 'formik';
import * as _ from "lodash";
import { DateTime as d } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GET_BRANCHES, GET_VEHICLES } from '../../../graphql/vehicleMovement/queries';
import { IHold, IVehicle } from '../../../reducers/fleet/types';
import { IBranch } from '../../../reducers/organisation/types';
import { IAppState } from '../../../store';
import { IFleetTranserInput } from './types';
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../common/utils";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CREATE_VEHICLES_TRANSER } from './fleetTranserMutaion';
import { ApolloError } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import { VehicleTransferDialog } from './VehicleTransferDialog';
import Autocomplete from '@mui/material/Autocomplete';
import { getLocalizedBookingSyntex } from '../../../utils/localized.syntex';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3),
      width: "100%"
    },
    section: {
      marginBottom: "25px"
    }
  })
);

const initianFleetTransferData: IFleetTranserInput = {
  sourceBranch: "",
  destinationBranch: "",
  vehicleIds: []
}

export const FleetTranser: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [branches, setBranches] = useState<Partial<IBranch>[]>([]);
  const [vehicles, setVehicles] = useState<Partial<IVehicle>[]>([]);
  const [vehiclesToFilter, setVehiclesToFilter] = useState<Partial<IVehicle>[]>([]);
  const [values, setValues] = useState<IFleetTranserInput>(initianFleetTransferData);
  const [selectedVehicles, setSelectedVehicles] = useState<Partial<IVehicle>[]>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [saveInProgress, setSaveInProgess] = useState<boolean>(false);
  const [searchedInputValue, setSearchedInputValue] = useState<string>("");
  const [isVehicleAddedBySearch, setIsVehicleAddedBySearch] = useState<boolean>(false);
  const [noVehicleMatchedFound, setNoVehicleMatchedFound] = useState<boolean>(false);
  const { country } = userState.currentOrganisation.address;

  const [loadBraches, { data: branchData }] = useLazyQuery(GET_BRANCHES, {
    "fetchPolicy": "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });
  const [loadVehicles,
    { loading: vehiclesLoading, data: vehiclesData }
  ] = useLazyQuery(
    GET_VEHICLES, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createVehiclesTranser] = useMutation(CREATE_VEHICLES_TRANSER, {
    onCompleted: ({ transferVehicles }) => {
      if (transferVehicles.success) {
        snackbar({
          message: "Vehicle transfer created",
          variant: SnackBarVariant.SUCCESS
        })
        setIsConfirmDialogOpen(false);
        setSaveInProgess(false);
        navigate("/movements");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setIsConfirmDialogOpen(false);
    }
  });

  useEffect(() => {
    if (userState && userState.id) {
      loadBraches({
        variables: {
          tenancyId: userState.currentTenancy.id,
          organisationId: userState.currentOrganisation.id
        }
      });
      loadVehicles();
      setSelectedVehicles([]);
      setValues({
        ...values,
        destinationBranch: "",
      })
    }
  }, [userState]);

  useEffect(() => {
    if (branchData && branchData.branches) {
      const currentBranch = branchData.branches.find((item: Partial<IBranch>) => item.id === userState.currentBranch.id);
      setBranches(branchData.branches);
      if (currentBranch && currentBranch.id) {
        setValues({
          ...values,
          sourceBranch: currentBranch.name,
        });
      }
    }
  }, [branchData, userState]);

  useEffect(() => {
    if (vehiclesData && vehiclesData.vehicles && values.destinationBranch) {
      const vehicles = vehiclesData.vehicles.filter((vehicle: Partial<IVehicle>) => vehicle.status === "Active");
      setVehicles(vehicles.sort((a: IVehicle, b: IVehicle) => a.licencePlate.toLowerCase() > b.licencePlate.toLowerCase() ? 1 : -1));
      setVehiclesToFilter(vehicles);
    }
  }, [vehiclesData, values]);

  const handlerConfirm = () => {
    setSaveInProgess(true);
    createVehiclesTranser({
      variables: {
        vehicleIds: values.vehicleIds,
        destinationBranchId: values.destinationBranch
      }
    })
  }

  const addSelectedVehicle = (vehicle: Partial<IVehicle>) => {
    const selectedVehicleData = [...selectedVehicles];
    selectedVehicleData.unshift(vehicle);
    setSelectedVehicles([...selectedVehicleData]);
    const filteredVehicle = vehiclesToFilter.filter((item: Partial<IVehicle>) => item.id !== vehicle.id);
    setVehicles([...filteredVehicle]);
    setVehiclesToFilter([...filteredVehicle]);
    setIsVehicleAddedBySearch(true);
    setSearchedInputValue("");
  }

  const removeSelectedVehicle = (vehicle: Partial<IVehicle>) => {
    setSelectedVehicles(selectedVehicles.filter((selectedVehicle: Partial<IVehicle>) => selectedVehicle.id !== vehicle.id));
    setVehicles([vehicle, ...vehicles]);
    setVehiclesToFilter([vehicle, ...vehiclesToFilter]);
  }

  const handleAutoCompleteInputChange = _.debounce((value: string, reason: any) => {
    if (reason === "input" || reason === "reset") {
      setIsVehicleAddedBySearch(false);
      const resourcesToFilter = [...vehiclesToFilter];
      if (!value) {
        setVehicles(resourcesToFilter);
      } else {
        const filtered = resourcesToFilter.filter((resource) => {
          if (Object.values(resource).some((val) => typeof val === "string" &&
            val.toLowerCase().includes(value.toLowerCase()))
          ) {
            return resource;
          }
        });
        if (filtered) {
          setVehicles([...filtered]);
        }
        if (filtered && filtered.length === 0) {
          setNoVehicleMatchedFound(true);
        }
      }
    }
    if (reason === 'clear') {
      if (isVehicleAddedBySearch) {
        setVehiclesToFilter([...vehicles]);
        setVehicles([...vehicles]);
      } else {
        setVehicles([...vehiclesToFilter]);
      }
    }
  }, 200);

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          &nbsp;Fleet Transfer
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Formik
          enableReinitialize
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            const selectedVehicleIds: string[] = [];
            selectedVehicles.forEach((selectedVehicle: Partial<IVehicle>) => {
              if (selectedVehicle && selectedVehicle.id) {
                selectedVehicleIds.push(selectedVehicle.id);
              }
            });
            values.vehicleIds = selectedVehicleIds;
            setIsConfirmDialogOpen(true);
            setSubmitting(false);
          }}
        >
          {(props) => (
            <Form noValidate style={{ width: "100%" }}>
              <Paper className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item container xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name="sourceBranch"
                          fullWidth
                          label="Source Branch"
                          value={props.values.sourceBranch}
                          disabled
                          InputLabelProps={{
                            shrink: true
                          }}
                          required
                        >
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name="destinationBranch"
                          fullWidth
                          select
                          label="Select Destination Branch"
                          value={props.values.destinationBranch}
                          InputProps={{
                            onChange: (event: any) => {
                              setValues({
                                ...props.values,
                                destinationBranch: event.target.value
                              });
                              loadVehicles();
                              setSelectedVehicles([]);
                            }
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          required
                        >
                          {branches && branches.length > 0 &&
                            branches.filter((branch: Partial<IBranch>) => branch.id !== userState.currentBranch.id)
                              .map((item: Partial<IBranch>, index: number) => (
                                <MenuItem key={index} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              {props.values.destinationBranch ? (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      style={{
                        maxHeight: "80vh",
                        overflow: "auto",
                        padding: 10,
                        marginTop: 20,
                        marginBottom: 20
                      }}
                    >
                      <Paper
                        style={{
                          minHeight: "75vh",
                          marginTop: 10,
                          padding: 20
                        }}
                      >
                        {vehiclesLoading ? (<CircularProgress />) : (
                          <div>
                            <React.Fragment>
                              <Grid container>
                                <Grid item xs={8}>
                                  <h3 style={{ marginTop: 10 }}>Click on "+" to add vehicle to initiate transfer</h3>
                                </Grid>
                                <Grid item xs={4}>
                                  <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    options={vehicles}
                                    getOptionLabel={(option: any) =>
                                      option.licencePlate || searchedInputValue
                                    }
                                    onInputChange={(event: any, value: string, reason: string) => {
                                      handleAutoCompleteInputChange(value, reason);
                                      setSearchedInputValue(value);
                                    }}
                                    inputValue={searchedInputValue}
                                    renderInput={(params) => (
                                      <TextField {...params}
                                        label="Search"
                                        placeholder="Registration Number"
                                        margin="normal"
                                        variant="outlined"
                                        style={{ backgroundColor: "white" }}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                              {vehicles && vehicles.length > 0 ? (
                                <>
                                  {vehicles.map((vehicle: Partial<IVehicle>, idx: number) => {
                                    const currentDateTime = d.now().toUTC().toISO();
                                    let vehicleHolds: string[] = [];
                                    if (vehicle && vehicle.holds && vehicle.holds.length) {
                                      vehicle.holds?.forEach((hold: Partial<IHold>) => {
                                        if (hold && hold.id && hold.endDate && hold.endDate > currentDateTime) {
                                          vehicleHolds.push(hold.id);
                                        }
                                      })
                                    }
                                    return (
                                      <Grid item xs={12} key={idx}>
                                        <Paper
                                          elevation={3}
                                          style={{
                                            margin: "8px 1px"
                                          }}
                                        >
                                          <Grid container style={{ padding: "5px 0" }}>
                                            <Grid item xs={4}>
                                              <p style={{
                                                padding: 0,
                                                margin: 0,
                                                marginLeft: 10,
                                                fontSize: 14,
                                                marginTop: 5
                                              }}>
                                                {vehicle.licencePlate}
                                              </p>
                                            </Grid>
                                            <Grid item xs={7}>
                                              <Grid container>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  style={{ marginTop: 5 }}
                                                >
                                                  {vehicle.make} | {vehicle.model} |{" "}
                                                  {vehicle.year} | {vehicle.bodyType}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid item xs={1}>
                                              {vehicle.canTransfer && vehicleHolds.length === 0 ? (
                                                <span
                                                  onClick={() => addSelectedVehicle(vehicle)}
                                                  style={{ color: "white" }}
                                                >
                                                  <AddIcon
                                                    style={{
                                                      marginTop: 5,
                                                      marginLeft: 10,
                                                      backgroundColor: "var(--theme-accent)",
                                                      cursor: "pointer",
                                                      fontSize: 16,
                                                      borderRadius: 2
                                                    }}
                                                  />
                                                </span>
                                              ) : (
                                                <IconButton
                                                  style={{
                                                    color: "var(--theme-accent)",
                                                    padding: 0,
                                                    paddingRight: 5,
                                                    marginLeft: 8
                                                  }}
                                                  size="large">
                                                  <Tooltip
                                                    title={`This vehicle has active ${getLocalizedBookingSyntex(country).toLowerCase()}/service/holds event or in transfer process`}
                                                    arrow
                                                    placement={"right"}
                                                  >
                                                    <InfoIcon />
                                                  </Tooltip>
                                                </IconButton>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Paper>
                                      </Grid>
                                    );
                                  })}
                                </>
                              ) : (
                                <div>
                                  {!noVehicleMatchedFound ? (
                                    <div>
                                      <h3>Vehicles not available in current branch</h3>
                                    </div>
                                  ) : (
                                    <div>
                                      <h3>No matched vehicle found</h3>
                                    </div>
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          </div>
                        )}
                      </Paper>
                    </Box>
                  </Grid>
                  {selectedVehicles && selectedVehicles.length > 0 && (
                    <Grid xs={6}>
                      <Box
                        style={{
                          maxHeight: "80vh",
                          overflow: "auto",
                          marginTop: 20,
                          padding: 10,
                          marginBottom: 20
                        }}
                      >
                        <Paper
                          style={{
                            minHeight: "75vh",
                            marginTop: 10,
                            padding: 20
                          }}
                        >
                          <Grid xs={12}>
                            <h3 style={{ marginTop: 0 }}>Click on "-" to remove vehicle from selection</h3>
                          </Grid>
                          {selectedVehicles.map((vehicle: Partial<IVehicle>, idx: number) => {
                            return (
                              <Grid item xs={12} key={idx}>
                                <Paper
                                  elevation={3}
                                  style={{
                                    margin: "8px 1px"
                                  }}
                                >
                                  <Grid container style={{ padding: "5px 0" }}>
                                    <Grid item xs={4}>
                                      <p style={{
                                        padding: 0,
                                        margin: 0,
                                        marginLeft: 10,
                                        fontSize: 14,
                                        marginTop: 5
                                      }}>
                                        {vehicle.licencePlate}
                                      </p>
                                    </Grid>
                                    <Grid item xs={7}>
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ marginTop: 5 }}
                                        >
                                          {vehicle.make} | {vehicle.model} |{" "}
                                          {vehicle.year} | {vehicle.bodyType}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                      <span
                                        onClick={() => removeSelectedVehicle(vehicle)}
                                        style={{ color: "white" }}
                                      >
                                        <RemoveIcon
                                          style={{
                                            marginTop: 5,
                                            marginLeft: 10,
                                            backgroundColor: "var(--theme-accent)",
                                            cursor: "pointer",
                                            fontSize: 16,
                                            borderRadius: 2
                                          }}
                                        />
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </Grid>
                            );
                          })}
                        </Paper>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <div>
                  {vehiclesLoading ?
                    <div style={{ marginTop: 10 }}>
                      <CircularProgress />
                    </div>
                    : (
                      <div>
                        {props.values.destinationBranch && vehicles.length === 0 ? (
                          <h3>Vehicles are not found in current branch!</h3>
                        ) : ""}
                      </div>
                    )}
                </div>
              )}
              {props.values.destinationBranch && (
                <Grid container justifyContent="flex-end" style={{ marginBottom: 16 }}>
                  <Fab
                    variant="extended"
                    size="medium"
                    type="submit"
                    aria-label="add"
                    disabled={!props.values.destinationBranch || selectedVehicles.length === 0 ? true : false}
                  >
                    Save
                  </Fab>
                </Grid>
              )}
            </Form>
          )}
        </Formik>
      </Grid>
      <VehicleTransferDialog
        open={isConfirmDialogOpen}
        handleClose={() => { setIsConfirmDialogOpen(false) }}
        handleSubmit={() => handlerConfirm()}
        saveInProgress={saveInProgress}
      />
    </Grid>
  );
}
