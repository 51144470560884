import { gql } from "@apollo/client";

export const UPDATE_PROCUREMENT_PROPOSAL_REQUEST = gql`
  mutation updateProposalRequest($id: ID!, $proposalRequestId: ID!, $proposalRequest: UpdateProcurementProposalRequestInput!) {
    updateProposalRequest(id: $id, proposalRequestId: $proposalRequestId, proposalRequest: $proposalRequest) {
      items {
        vehicleMake
        vehicleModel
        vehicleVarient
        vehicleFuel
        vehicleTransmission
        vehicleCondition
        vehicleCount
        vehicleExpectedByDate
        documents
      }
      id
      proposalRequestId
      date
      partnerId
      partner {
        id
        partnerName
        email
      }
      validUntilDate
      note {
        description
        createdAt
        # createdBy {
        #   firstName
        #   lastName
        # }
      }
    }
  }
`;