import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line'
import { DateTime as d } from 'luxon';
import styles from '../index.module.css'
import { SelectButton } from '../../utils';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import api from "../../api/Reports"
import { IAppState } from '../../../../../store';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { getLocalizedBookingSyntex } from '../../../../../utils/localized.syntex';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

interface IChartData {
  id: string;
  color: string;
  data: {
    x: string
    y: number
  }[]
}

const PickupFrequencyChart = () => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const initialChartData: IChartData[] = [
    {
      id: `${getLocalizedBookingSyntex(country)}s`,
      color: "green",
      data: []
    },
    {
      id: "Cancellations",
      color: "red",
      data: []
    }
  ]
  const snackbar = useSnackBar()
  const tenantId = userState && userState.currentTenancy ? userState.currentTenancy?.id : userState.tenancy.id;
  const [filters, setfilters] = useState<{ year: number }>({ year: d.now().get("year") });
  const [availableYears, setAvailableYears] = useState<number[]>([d.now().get("year")]);
  const [rawData, setRawData] = useState<any>();
  const [chartData, setChartData] = useState<IChartData[]>(initialChartData);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true)
      const { data: axiosData } = await api.getCancellationsMonthly({
        tenantID: tenantId,
        organisationID: userState.currentOrganisation.id,
        branchID: userState.currentBranch.id,
      });
      setRawData(axiosData["bookingStats"]);
      setLoading(false)
    }
    catch (error: any) {
      setLoading(false);
      if (error.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading cancellation data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  const getDataTemplate = () => months.map(month => {
    return {
      x: month,
      y: 0
    }
  })

  const formatAndSetData = () => {
    if (rawData && rawData.monthYear) {
      const tempBookingData = getDataTemplate();
      const tempCancellationData = getDataTemplate();
      for (var i = 0; i < rawData.monthYear.length; i++) {
        const monthWithYear: string = rawData.monthYear[i];
        const year = parseInt(monthWithYear.slice(-4));
        if (year === filters.year) {
          const month = monthWithYear.slice(0, 3);
          tempBookingData.forEach((item, dataIndex) => { if (item.x === month) tempBookingData[dataIndex] = { x: month, y: rawData.TOTAL[i] }; });
          tempCancellationData.forEach((item, dataIndex) => { if (item.x === month) tempCancellationData[dataIndex] = { x: month, y: rawData.CANCELLED[i] }; });
        }
      }
      setChartData([
        {
          ...initialChartData[0],
          data: tempBookingData
        },
        {
          ...initialChartData[1],
          data: tempCancellationData
        }
      ])
    }
  }
  useEffect(() => {
    if (rawData && rawData.monthYear) {
      const years: number[] = [d.now().get("year")];
      for (var i = 0; i < rawData.monthYear.length; i++) {
        const monthWithYear = rawData.monthYear[i];
        const year = parseInt(monthWithYear.slice(-4));
        if (!years.includes(year)) {
          years.push(year);
        }
      }
      setAvailableYears(years)
      formatAndSetData();
    }
  }, [rawData])

  useEffect(() => {
    formatAndSetData();
  }, [filters.year])

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className={styles.module}>
      <div className={styles.chartHeadline}>
        <span className="font-medium bold ">Cancellations by Month</span>
        {rawData ? <div className="flex cross-center">
          <SelectButton
            onChange={(selected) => setfilters({ year: parseInt(selected) })}
            id="MONTHLY_CANCELLATION_YEAR_FILTER"
            initialValue={`${d.now().get("year")}`}
          >
            {availableYears.map((year, idx) => (
              <MenuItem key={idx} value={year}>
                {year}
              </MenuItem>
            ))}
          </SelectButton>
        </div> : (
          <>
            {loading ? (
              <div className="margin-right flex cross-center">
                <CircularProgress size={20} thickness={5} />
              </div>
            ) : ""}
          </>
        )}
      </div>
      <ResponsiveLine
        data={chartData}
        margin={{ top: 50, right: 20, bottom: 30, left: 40 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        yFormat=" >-.2f"
        curve="monotoneX"
        axisTop={null}
        axisRight={null}
        lineWidth={2}
        theme={{
          grid: {
            line: {
              stroke: "#f2f2f2",
              strokeWidth: 1
            }
          }
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          tickValues: 5,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        areaOpacity={0.1}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
          const points = slice.points.sort((a, b) => parseFloat(`${b.data.yFormatted}`) - parseFloat(`${a.data.yFormatted}`))
          return (
            <div
              style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
              }}
            >
              {points.map(point => (
                <div
                  key={point.id}
                  style={{
                    padding: '3px 0',
                    fontSize: 12,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <div style={{ background: point.serieColor, height: "8px", width: "8px", marginRight: 5 }} /><strong>{point.serieId} - {point.data.yFormatted}</strong>
                </div>
              ))}
            </div>
          )
        }}
        colors={({ color }) => color}
        pointSize={7}
        pointColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </div>
  )
}

export default PickupFrequencyChart
