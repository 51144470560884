import { gql } from "@apollo/client";

export const UPLOAD_RATE_CARDS = gql`
  mutation uploadRateCards($rateCards: [RateCardInput]) {
    uploadRateCards(rateCards: $rateCards) {
      success
      message
    }
  }
`;
