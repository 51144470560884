import { gql } from "@apollo/client";

export const ADD_SUNDRY_INVOICE_DOCUMENT = gql`
  mutation addSundryInvoiceDocument($invoiceId: String!, $document: InvoiceDocumentInput!) {
    addSundryInvoiceDocument(invoiceId: $invoiceId, document: $document) {
      title
      documentName
      documentUrl
      documentType
      expiryDate
      createdAt
      createdBy
    }
  }
`;


export const REMOVE_SUNDRY_INVOICE_DOCUMENT = gql`
  mutation removeSundryInvoiceDocument($invoiceId: String!, $documentUrl: String!) {
    removeSundryInvoiceDocument(invoiceId: $invoiceId, documentUrl: $documentUrl) {
      success
      message
    }
  }
`;