import { gql } from "@apollo/client";

export const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription($id: String!, $subscription: SubscriptionInput!) {
    updateSubscription(id: $id, subscription: $subscription) {
      id
      name
      insurancePolicy {
        id
      }
      addons {
        id
        name
        displayName
      }
      features
      swapsTnC
      serviceIncluded
      serviceTnC
      subscriptionTnC
      vehicles {
        id
        make
        model
      }
      isActive
    }
  }
`;
