import { AppBar, Paper, Tab, Tabs, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BuildIcon from "@mui/icons-material/Build";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import MoneyIcon from "@mui/icons-material/MoneyRounded";
import ReportIcon from "@mui/icons-material/Report";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ExpensesIcon from "@mui/icons-material/PaymentTwoTone";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IVehicle } from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { TabPanel } from "../../../../../common/TabPannel/TabPannel";
import { DamageView } from "../Damage/DamageView";
import Details from "../Details/Details";
import { ExpenseHistory } from "../Expense/ExpenseHistory";
import { FinanceView } from "../Finance/FinanceView";
import { MotView } from "../MotView/MotView";
import { ServiceHistory } from "../Services/ServiceHistory";
import { Specification } from "../Specification/Specification";
import { VehicleCheck } from "../VehicleCheck/VehicleCheck";
import { VehicleDocuments } from "../VehicleDocuments/VehicleDocuments";
import Subscription from "../Subscription/Subscription";
import SpeedIcon from "@mui/icons-material/Speed";
import { VehicleDashboard } from "../vehicleDashboard/VehicleDashboard";
import { OwnershipHistory } from "../Ownership/OwnershipHistory";
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 120,
      },
      flexGrow: 1
    },
    paper: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 120,
      },
      [theme.breakpoints.down('md')]: {
        width: "calc(100vw - 45px)",
      },
      [theme.breakpoints.up('sm')]: {
        width: "calc(100vw - 245px)",
      },

    },
  })
);
interface IProps {
  vehicle: IVehicle;
  updateFields: (payload: any) => void;
  updateVehicle: (payload: any) => void;
  updateVirtualVehicle: (payload: any) => void;
  showUpgradeErrorOpen: (value: boolean) => void;
}

let isCountryUsa = false;

export const TabView = (props: IProps) => {
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [value, setValue] = useState<number>(0);

  if (country === "United States") {
    isCountryUsa = true;
  }
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };
  return (
    <Paper className={classes.paper}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons
          aria-label="Scrollable Vehicle Info Tabs"
        >
          <Tab label="Dashboard" icon={<SpeedIcon />} />
          <Tab label="Details" icon={<DriveEtaIcon />} disabled={props.vehicle.deFleetStatus === "COMPLETED"} />
          <Tab label="Specification" icon={<BuildIcon />} disabled={props.vehicle.deFleetStatus === "COMPLETED"} />
          {country !== "United States" && (
            <Tab label="MOT\Tax" icon={<AssignmentIcon />} disabled={props.vehicle.isGhostVehicle || props.vehicle.deFleetStatus === "COMPLETED"} />
          )}

          <Tab label="Services" icon={<ReportProblemIcon />} disabled={props.vehicle.isGhostVehicle || props.vehicle.deFleetStatus === "COMPLETED"} />
          <Tab label="Damages" icon={<ReportIcon />} disabled={props.vehicle.isGhostVehicle || props.vehicle.deFleetStatus === "COMPLETED"} />
          <Tab label="Documents" icon={<AttachFileIcon />} disabled={props.vehicle.isGhostVehicle} />
          <Tab label="Finance" icon={<MoneyIcon />} disabled={props.vehicle.isGhostVehicle || props.vehicle.deFleetStatus === "COMPLETED"} />
          <Tab label="Subscription" icon={<MoneyIcon />} disabled={props.vehicle.isGhostVehicle || props.vehicle.deFleetStatus === "COMPLETED"} />
          <Tab label="Vehicle State" icon={<DoneAllIcon />} disabled={props.vehicle.isGhostVehicle || props.vehicle.deFleetStatus === "COMPLETED"} />
          <Tab label="Expense History" icon={<ExpensesIcon />} disabled={props.vehicle.isGhostVehicle || props.vehicle.deFleetStatus === "COMPLETED"} />
          <Tab label="Ownership History" icon={<AccountBoxIcon />} disabled={props.vehicle.isGhostVehicle || props.vehicle.deFleetStatus === "COMPLETED"} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <VehicleDashboard
          vehicle={props.vehicle}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Details
          vehicle={props.vehicle}
          updateFields={props.updateFields}
          updateVehicle={props.updateVehicle}
          updateVirtualVehicle={props.updateVirtualVehicle}
          showUpgradeErrorOpen={props.showUpgradeErrorOpen}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Specification
          vehicle={props.vehicle}
          updateFields={props.updateFields}
          updateVehicle={props.updateVehicle}
        />
      </TabPanel>
      {country !== "United States" && (
        <TabPanel value={value} index={3}>
          <MotView vehicle={props.vehicle} />
        </TabPanel>
      )
      }

      <TabPanel value={value} index={isCountryUsa ? 3 : 4}>
        <ServiceHistory vehicle={props.vehicle} />
      </TabPanel>

      <TabPanel value={value} index={isCountryUsa ? 4 : 5}>
        <DamageView vehicle={props.vehicle} />
      </TabPanel>
      <TabPanel value={value} index={isCountryUsa ? 5 : 6}>
        <VehicleDocuments
          vehicle={props.vehicle}
          updateFields={props.updateFields}
          updateVehicle={props.updateVehicle}
        />
      </TabPanel>
      <TabPanel value={value} index={isCountryUsa ? 6 : 7}>
        <FinanceView vehicle={props.vehicle} updateVehicle={props.updateVehicle} />
      </TabPanel>
      <TabPanel value={value} index={isCountryUsa ? 7 : 8}>
        <Subscription vehicle={props.vehicle} updateVehicle={props.updateVehicle} />
      </TabPanel>
      <TabPanel value={value} index={isCountryUsa ? 8 : 9}>
        <VehicleCheck
          vehicle={props.vehicle}
          updateFields={props.updateFields}
          updateVehicle={props.updateVehicle}
        />
      </TabPanel>
      <TabPanel value={value} index={isCountryUsa ? 9 : 10}>
        <ExpenseHistory
          vehicle={props.vehicle}
          updateFields={props.updateFields}
          updateVehicle={props.updateVehicle}
        />
      </TabPanel>
      <TabPanel value={value} index={isCountryUsa ? 10 : 11}>
        <OwnershipHistory
          vehicle={props.vehicle}
          updateFields={props.updateFields}
        />
      </TabPanel>
    </Paper>
  );
};
