export const sourceTypes: any = [
  {
    label: "Owned",
    value: "owned"
  },
  {
    label: "Leased",
    value: "leased"
  },
  {
    label: "Financed",
    value: "financed"
  },
  {
    label: "Rented",
    value: "rented"
  }
];

export const paymentInterval: any = [
  "Every Week",
  "Every Month",
  "Every 3 Months",
  "Every 6 Months",
  "Every Year"
];

export enum SourceType {
  OWNED = "owned",
  LEASED = "leased",
  FINANCED = "financed",
  RENTED = "rented"
}
