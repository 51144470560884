import { gql } from "@apollo/client";

export const GET_ASSET_SERVICES = gql`
  query getAssetServices($limit: Int!, $offset: Int!) {
    getAssetServices(limit: $limit, offset: $offset) {
      id
      referenceNumber
      asset{
        id
        assetTag
      }
      serviceType
      serviceProviderName
      status
      appointmentDate
      completionDate
      totalExpense
    }
  }
`;

export const GET_ASSET_SERVICE_COUNT = gql`
  {
    getAssetServiceCount
  }
`;

export const SEARCH_ALL_ASSETS = gql`
  query searchAssetByAssetTag($q: String!) {
    searchAssetByAssetTag(q: $q) {
      id
      assetTag
    }
  }
`;

export const GET_ASSET_SERVICE = gql`
  query getAssetService($assetServiceId: String!) {
    getAssetService(assetServiceId: $assetServiceId) {
      id
      asset{
        id
        assetTag
      }
      serviceType
      serviceProviderName
      serviceProviderAddress{
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      damages{
        id
        referenceNumber
        circumstance
        reportDate
        incidentDate
        status
      }
      status
      appointmentDate
      completionDate
      totalExpense
      description
    }
  }
`;