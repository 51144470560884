import {
  Box,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  IVehicleCheckoutPaymentInfo,
  VehicleCheckCustomerInfo
} from "../../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../../store";
import { FloatInput } from "../../../../../common/FloatInput/FloatInput";
import { toCurrency } from "../../../../../common/utils";
import { paymentMethods } from "./constants";
import { ICheckInfoInvoice } from "./types";

interface IPayment {
  invoice: ICheckInfoInvoice;
  onSubmit(payload: any): void;
  onPrevious(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    form: {
      flexGrow: 1
    }
  })
);
export const Payment = (props: IPayment) => {
  const classes = useStyles();
  const vehicleChecks = useSelector((state: IAppState) => state.bookingReducer);
  const [values, setValues] = useState<IVehicleCheckoutPaymentInfo>(
    vehicleChecks.checkInfo.paymentInfo
  );
  const [customerInfo, setCustomerInfo] = useState<VehicleCheckCustomerInfo>(
    vehicleChecks.checkInfo.customerInfo
  );
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const {
    totalPayableAmount,
    confirmedPaidAmount,
    confirmedRefundAmount,
    deposit
  } = props.invoice;

  const handlePrevious = () => {
    props.onPrevious();
  };

  return (
    <Grid container item xs={12}>
      <Formik
        enableReinitialize
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          const checkInfo = {
            ...vehicleChecks.checkInfo,
            customerInfo,
            paymentInfo: values
          };
          props.onSubmit(checkInfo);
        }}
      >
        {(formValues) => (
          <Form className={classes.form}>
            <Grid container item xs={12} spacing={2}>
              <Grid container item xs={12}>
                <Paper className={classes.root}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid container item xs={12}>
                      <Grid item xs={12} container>
                        <Typography variant={"h2"}>PAYMENT DETAILS </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item>
                        <Typography variant={"h4"}>Total Amount:</Typography>
                      </Grid>
                      <Grid item>
                        <Box pl={1}></Box>
                      </Grid>
                      <Grid item>
                        <Typography variant={"h4"}>
                          {toCurrency(totalPayableAmount, currency, locale)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Box pl={2}></Box>
                      </Grid>
                      <Grid item>
                        <Typography variant={"h4"}>Deposit Amount:</Typography>
                      </Grid>
                      <Grid item>
                        <Box pl={1}></Box>
                      </Grid>
                      <Grid item>
                        <Typography variant={"h4"}>
                          {toCurrency(deposit, currency, locale)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Box pl={2}></Box>
                      </Grid>
                      <Grid item>
                        <Typography variant={"h4"}>Amount Due:</Typography>
                      </Grid>
                      <Grid item>
                        <Box pl={1}></Box>
                      </Grid>
                      <Grid item>
                        <Typography variant={"h4"}>
                          {toCurrency(
                            totalPayableAmount - confirmedPaidAmount + confirmedRefundAmount,
                            currency,
                            locale
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={12} container spacing={2}>
                        <Grid container item xs={12}>
                          <Typography variant={"h4"}>
                            Payment Received
                          </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} container spacing={2}>
                            <Grid container item xs={4}>
                              <FormControl variant="outlined" fullWidth>
                                <Field
                                  component={TextField}
                                  name={"paymentMethod"}
                                  fullWidth
                                  type="text"
                                  select
                                  label="Pyment Mode"
                                  value={formValues.values.paymentMethod}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    onChange: (
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setValues({
                                        ...values,
                                        paymentMethod: event.target.value
                                      });
                                    }
                                  }}
                                >
                                  {paymentMethods.map(
                                    (item: any, index: number) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.value}
                                        >
                                          {item.label}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Field>
                              </FormControl>
                            </Grid>
                            <Grid container item xs={8}></Grid>
                            <Grid container item xs={4}>
                              <FormControl fullWidth>
                                <Field
                                  component={TextField}
                                  label="Amount Received"
                                  name={"amount"}
                                  value={formValues.values.amount}
                                  fullWidth
                                  type="number"
                                  inputProps={{
                                    inputComponent: FloatInput as any,
                                    onChange: (
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setValues({
                                        ...values,
                                        [event.target.name]: parseInt(
                                          event.target.value
                                        )
                                      });
                                    },
                                    value: formValues.values.amount
                                  }}
                                ></Field>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid container item xs={12}>
                <Paper className={classes.root}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid container item xs={12}>
                      <Typography variant={"h2"}>CUSTOMER SIGNATURE</Typography>
                    </Grid>
                    <Grid container item xs={12}>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={customerInfo.tncCheck}
                              name={"tncCheck"}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setCustomerInfo({
                                  ...customerInfo,
                                  [event.target.name]: event.target.checked
                                });
                              }}
                              value={customerInfo.tncCheck}
                              color="secondary"
                            />
                          }
                          label={
                            <Typography variant={"body1"}>
                              I have read and agree to the booking{" "}
                              <a>Terms and condition</a>
                            </Typography>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid container item xs={12} spacing={2}>
                        <Grid container item xs={4}>
                          <FormControl fullWidth>
                            <Field
                              component={TextField}
                              label="Full Name"
                              name={"name"}
                              value={customerInfo.name}
                              fullWidth
                              InputProps={{
                                onChange: (
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setCustomerInfo({
                                    ...customerInfo,
                                    [event.target.name]: event.target.value
                                  });
                                }
                              }}
                            ></Field>
                          </FormControl>
                        </Grid>
                        <Grid container item xs={4}>
                          <FormControl fullWidth>
                            <Field
                              component={TextField}
                              label="Initials"
                              name={"signature"}
                              value={customerInfo.signature}
                              fullWidth
                              InputProps={{
                                onChange: (
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setCustomerInfo({
                                    ...customerInfo,
                                    [event.target.name]: event.target.value
                                  });
                                }
                              }}
                            ></Field>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid container item xs={12}>
                <Fab
                  className="blackBackButton"
                  variant="extended"
                  size="medium"
                  onClick={handlePrevious}
                >
                  BACK
                </Fab>
                <Box ml={2}></Box>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Create"
                  type="submit"
                >
                  Save Details
                </Fab>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
