import { gql } from "@apollo/client";

export const GET_VEHICLE_DAMAGES = gql`
  query vehicleDamages($status: String, $filter: [FilterInput]) {
    vehicleDamages(status: $status, filter: $filter) {
      id
      vehicleId
      bookingRef
      reportDate
      incidentDate
      referenceNumber
      circumstance
      status
      licencePlate
      bookingReferenceNumber
    }
  }
`;
