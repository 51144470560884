import { Button, Theme } from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: "auto",
      padding: "10px 16px",
      fontSize: "14px",
      borderRadius: "4px",
      height: "40px",
      color: "#fff",
      cursor: "pointer",
      backgroundColor: "#404040",
      "&:hover": {
        background: "#404040"
      },
    },
    buttonText: {
      fontWeight: 400
    }
  })
);

interface IFilePicker {
  title?: string;
  accept: string;
  onFilePick(event: any): void;
  multiple?: boolean;
}

export const FilePicker: React.FC<IFilePicker> = (props) => {
  const classes = useStyles();

  return (
    <Button
      component="label"
      className={classes.button}
    >
      <span className={classes.buttonText}>
        {props.title ? props.title : "Browse"}
      </span>
      <input
        type="file"
        accept={props.accept}
        hidden
        onChange={props.onFilePick}
        onClick={(e: any) => {
          e.target.value = null;
        }}
        multiple={props.multiple}
      />
    </Button>
  )
}
