import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { createStyles, makeStyles } from "@mui/styles";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { Authorization, Null } from "../../hoc/Authorization";
import AppMenuItemComponent from "./AppMenuItemComponent";
import { updateListExpanded } from '../../../actions/navbar.actions'
import { Typography } from '@mui/material';

const menuNameMap: Record<string, string> = {
  "Bookings": "Reservations",
  "Organisation": "Locations",
  "Rental Agreement": "Contract"
}

const getLocalisedName = (name: string, country?: string) => {
  if (country && country === 'United States' && Object.keys(menuNameMap).includes(name)) {
    return menuNameMap[name]
  }
  return name;
}

export interface IAppMenuItem {
  Icon: JSX.Element;
  link: string;
  items: any[];
  name: any;
  auth?: string;
}
const AppMenuItem = (props: IAppMenuItem) => {
  const dispatch = useDispatch();
  const listExpanded = useSelector((state: IAppState) => state.navbarReducer.listExpanded);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { name, link, Icon, items = [] } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!listExpanded) {
      setOpen(true);
    }
    setOpen(listExpanded === name);
  }, [listExpanded]);

  function handleClick() {
    dispatch(updateListExpanded(listExpanded === name ? "" : name));
  }
  const MenuItemRoot = (
    <AppMenuItemComponent
      className={classes.menuItem}
      link={link}
      onClick={handleClick}
    >
      {!!Icon && (
        <ListItemIcon className={`${classes.menuItemIcon} menu-item-icon-main`}>
          {Icon}
        </ListItemIcon>
      )}
      <ListItemText primary={
        <Typography variant="h4">{getLocalisedName(name, userState.currentOrganisation.address.country)}</Typography>
      } inset={!Icon} />
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse
      className="collapsible-menus"
      in={open}
      timeout="auto"
      unmountOnExit
    >
      <Divider />
      <List component="div" disablePadding>
        {items.map((item: any, index: any) => {
          const NullAppMenuItem = Authorization(item.allowed)(Null)(
            AppMenuItem
          );
          return <NullAppMenuItem {...item} key={index} />;
        })}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      boxShadow: "0px -1px 0px rgba(255, 255, 255, 0.08)",
      "&.active": {
        color: "#fff",
        backgroundColor: "rgba(0,0,0,0.15)",
        "& .MuiListItemIcon-root": {
          color: "#fff"
        }
      },

      "&:hover": {
        color: "#fff",
        "& .MuiListItemIcon-root": {
          color: "#fff"
        }
      }
    },
    menuItemIcon: {
      color: "#fff",
      minWidth: 33,
      fontWeight: 600,
      fontSize: 18
    }
  })
);

export default AppMenuItem;
