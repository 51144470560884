import { ICalendarEvent } from "../models/ICalendarEvent";

export enum CalendarEventActionType {
  ADD_CALENDAR_EVENT = "ADD_CALENDAR_EVENT",
  GET_CALENDAR_EVENT = "GET_CALENDAR_EVENT",
  UPDATE_CALENDAR_EVENT = "UPDATE_CALENDAR_EVENT",
  DELETE_CALENDAR_EVENT = "DELETE_CALENDAR_EVENT"
}

interface AddCalendarEvent {
  type: CalendarEventActionType.ADD_CALENDAR_EVENT;
  payload: ICalendarEvent;
}
interface GetCalendarEvent {
  type: typeof CalendarEventActionType.GET_CALENDAR_EVENT;
  payload: ICalendarEvent;
}
interface UpdateCalendarEvent {
  type: typeof CalendarEventActionType.UPDATE_CALENDAR_EVENT;
  payload: ICalendarEvent;
}
interface DeleteCalendarEvent {
  type: typeof CalendarEventActionType.DELETE_CALENDAR_EVENT;
  payload: ICalendarEvent;
}

export function AddCalendarEvent(payload: ICalendarEvent): calendarEventAction {
  return {
    payload,
    type: CalendarEventActionType.ADD_CALENDAR_EVENT
  };
}

export function GetCalendarEvent(payload: ICalendarEvent): calendarEventAction {
  return {
    payload,
    type: CalendarEventActionType.GET_CALENDAR_EVENT
  };
}
export function UpdateCalendarEvent(
  payload: ICalendarEvent
): calendarEventAction {
  return {
    payload,
    type: CalendarEventActionType.UPDATE_CALENDAR_EVENT
  };
}
export function DeleteCalendarEvent(
  payload: ICalendarEvent
): calendarEventAction {
  return {
    payload,
    type: CalendarEventActionType.DELETE_CALENDAR_EVENT
  };
}

export type calendarEventAction =
  | AddCalendarEvent
  | GetCalendarEvent
  | UpdateCalendarEvent
  | DeleteCalendarEvent;
