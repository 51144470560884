import {
    IAdminUser
} from "../../reducers/adminUser/types";

export const UPDATE_ADMIN_USER = "UPDATE_ADMIN_USER";
export const RESET_ADMIN_USER = "RESET_ADMIN_USER";

interface IUpdateAdminUser {
    type: typeof UPDATE_ADMIN_USER;
    payload: IAdminUser;
}

interface IResetAdminUser {
    type: typeof RESET_ADMIN_USER;
}

export type AdminUserAction =
    | IUpdateAdminUser
    | IResetAdminUser;
