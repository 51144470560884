import { gql } from '@apollo/client';

export const GET_PENDING_ACTIONS = gql`
  query pendingActions {
    pendingActions {
        id
        category
        subCategory
        status
        dateCreated
        actionDate
        dateCompleted
        contentId
        description
        vehicle {
          id
          licencePlate
        }
        booking {
          id
          referenceNumber
        }
      }
  }
`
export const CANCEL_PENDING_ACTION = gql`
  mutation cancelPendingAction($id: String!) {
    cancelPendingAction(id: $id){
      message
      success
    }  
  }
`