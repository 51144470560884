import { useLazyQuery } from '@apollo/client';
import { Box, CircularProgress, Fab, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, IconButton, FormControl, MenuItem, Switch, FormControlLabel, Theme } from '@mui/material'
import { createStyles, makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Field, Form, Formik } from 'formik';
import { TextField as InputField } from "formik-mui";
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_BOOKING_SUMMARY } from '../../../../../graphql/fleet/vehicleScheduler/getBookingSummaryQuery';
import { GET_EXTERNAL_BOOKING_SUMMARY } from '../../../../../graphql/fleet/vehicleScheduler/getExternalBookingQuery';
import { BookingStatus, IBooking } from '../../../../../reducers/bookings/types';
import { IVehicle } from '../../../../../reducers/fleet/types';
import { IAppState } from '../../../../../store';
import { canExtend } from '../../../ReservationManagement/utils';
import { DialogTypes, IVehicleReplacementArgs, IVehicleSubscriptionReplacementArgs, vehicleReplacementOptions } from '../utils';
import { GET_AVAILABLE_VEHICLE_GROUPS } from '../../../../../graphql/bookings/getAvailableVehicleGroups';
import { IRate, IVehiclePriceGroup } from '../../../../../reducers/vehiclePriceGroups/types';
import { IPriceRule } from '../../../../../reducers/priceRule/types';
import Autocomplete from '@mui/material/Autocomplete';
import { DATE_TYPE, formatGraphQLErrorMessage, toCurrency } from '../../../../common/utils';
import { SimpleDateTimePicker } from '../../../../common/SimpleDateTimePicker';
import { GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS } from '../../../../../graphql/bookings/getExternalAvailableVehicleGroups';
import { DateTime as d } from "luxon";
import { GET_VEHICLES_FOR_SWAP } from '../../../../../graphql/bookings/getAvailableVehiclesForSwap';
import { ApolloError } from '@apollo/client';
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { UserRoles } from '../../../../hoc/Authorization';
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import ReplaceVehicleForConfirmedBooking from './VehicleReplacementForConfirmedBooking';
export interface IBookingSchedule {
  id?: string;
  booking: IBooking;
  vehicle: IVehicle;
  vehicleStatus: string;
  startDate: string;
  endDate: string;
  vehicleGroup?: string;
}
interface IDialogData {
  startDate?: string | undefined;
  endDate?: string;
  bookingId?: string;
}

interface IVehcileReplace {
  reason: string;
  description?: string;
  putVehicleOnHold: boolean;
  fromDate: string;
  endDate: string;
  rateTypeDuration: string;
  longTerm: boolean;
  businessCustomer: string;
  bookingId: string;
  pcoNumber: boolean;
  smartCarVehicle: boolean;
  serviceLocation: string;
  replacementDate: string;
}
interface IProps {
  type: string;
  bookingSchedule?: IBookingSchedule;
  isSharedFleetEvent?: boolean;
  handleClose(): void;
  startBooking(id: string): void;
  setDialogType(type: DialogTypes): void;
  confirmCancelBookingDialog(id: string): void;
  setDialogData(data: IDialogData): void;
  replaceVehicleForInProgressBooking(data: IVehicleReplacementArgs): void;
  replaceVehicleForInProgressSubscription(data: IVehicleSubscriptionReplacementArgs): void;
  replaceVehicleLoading?: boolean;
  replaceSubscriptionVehicleLoading?: boolean;
  reloadScheduler(): void;
}

interface IDateTime {
  date: string;
  time: number
}

const replaceVehicleInitials: IVehcileReplace = {
  bookingId: "",
  fromDate: "",
  endDate: "",
  businessCustomer: "",
  longTerm: false,
  pcoNumber: false,
  rateTypeDuration: "",
  serviceLocation: "",
  smartCarVehicle: false,
  reason: "",
  putVehicleOnHold: true,
  description: "",
  replacementDate: ""
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
    },
    tableCell: {
      border: "1px solid rgba(224, 224, 224, 1)"
    },
    dialogContent: {
      paddingTop: 10
    },
    autoCompleteMenuText: {
      textTransform: "capitalize"
    },
    autoCompleteMenuRoot: {
      "&#select-vehicle-group": {
        display: "flex",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: 120
      },
      "&#select-vehicle-": {
        display: "flex",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: 120
      }
    }
  })
)
export const BookingSummary: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userReducer.currentOrganisation;
  const { country } = userReducer.organisation.address
  const { setDialogType, confirmCancelBookingDialog, setDialogData } = props
  const bookingData = props.bookingSchedule?.booking
  const [businessCustomer, setBusinessCustomer] = useState<string>()
  const [booking, setBooking] = useState<IBooking>()
  const [replaceVehicle, setReplaceVehicle] = useState<Boolean>(false)
  const [vehicleReplaceValues, setVehicleReplaceValues] = useState<IVehcileReplace>(replaceVehicleInitials)
  const [vehicleGroups, setVehicleGroups] = useState<IVehiclePriceGroup[]>()
  const [locationSurchargeRules, setLocationSurchargeRules] = useState<IPriceRule[]>([]);
  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState<IVehiclePriceGroup>()
  const [selectedVehicleId, setSelectedVehicleId] = useState<string>(props.bookingSchedule?.vehicle.id || "")
  const [vehicles, setVehicles] = useState<IVehicle[]>()
  const [locationSurchargesAmount, setLocationSurchargesAmount] = useState<number>(0);
  const [availableVehiclesForReplacement, setAvailableVehiclesForReplacements] = useState<IVehicle[]>([])
  const enabledRoles = ["ADMIN", "SUPER_ADMIN", "BOOKING_AGENT", "BRANCH_MANAGER", "ORGANISATION_OWNER"];
  const [isVehicleReplacement, setIsVehicleReplacement] = useState<boolean>(false);
  const [getBookingSummary, { loading: loadBooking, data: bookingSummaryData }] = useLazyQuery(GET_BOOKING_SUMMARY, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [getExternalBookingSummary, { loading: loadExternalBooking, data: externalBookingSummaryData }] = useLazyQuery(GET_EXTERNAL_BOOKING_SUMMARY, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    loadVehicleGroups,
    { loading: vehicleGroupsLoading, data: availableVehicleGroupsData }
  ] = useLazyQuery(GET_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "no-cache"
  });

  const [
    loadExternalVehicleGroups,
    { loading: externalVehicleGroupsLoading, data: externalAvailableVehicleGroupsData }
  ] = useLazyQuery(GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "no-cache"
  });

  const [
    getVehiclesForSwap,
    { data: vehiclesData, loading: loadingVehicleForSwap }
  ] = useLazyQuery(GET_VEHICLES_FOR_SWAP, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (bookingData?.id) {
      if (props.isSharedFleetEvent) {
        getExternalBookingSummary({
          variables: {
            id: bookingData.id
          }
        })
      } else {
        getBookingSummary({
          variables: {
            id: bookingData.id
          }
        })
      }
    }
  }, [bookingData?.id])

  useEffect(() => {
    if (availableVehicleGroupsData &&
      availableVehicleGroupsData.availableVehicleGroups &&
      externalAvailableVehicleGroupsData &&
      externalAvailableVehicleGroupsData.externalAvailableVehicleGroups &&
      props.bookingSchedule,
      bookingData?.rateTypeDuration
    ) {
      const availableVehicleGroups: any[] = []
      if (availableVehicleGroupsData && availableVehicleGroupsData.availableVehicleGroups.vehicleGroups.length > 0) {
        const vgArr = availableVehicleGroupsData.availableVehicleGroups.vehicleGroups
        for (let i = 0; i < vgArr.length; i++) {
          const vg = vgArr[i];
          const idx = availableVehicleGroups.findIndex((item) => item.id === vg.id);
          if (idx > -1) {
            vg.vehicles.forEach((vehicle: IVehicle) => {
              if (!availableVehicleGroups[idx].vehicles.some((v: any) => v.id === vehicle.id)) {
                availableVehicleGroups[idx].vehicles.push(vehicle)
              }
            })
          } else {
            availableVehicleGroups.push(vg)
          }
        }
        let locationSurchageAmount = 0;
        const locationSurcharges = availableVehicleGroupsData.availableVehicleGroups.locationSurcharges.filter((surcharge: IPriceRule) => surcharge.isActive)
        if (locationSurcharges.length > 0 && bookingData.pickupServiceLocation) {
          locationSurcharges.forEach((charges: IPriceRule) => {
            if (charges.serviceLocations.includes(bookingData.pickupServiceLocation.id)) {
              locationSurchageAmount += charges.rules[0].value
            }
          });
        }
        setLocationSurchargeRules(locationSurcharges);
        setLocationSurchargesAmount(locationSurchageAmount)
      }
      if (
        externalAvailableVehicleGroupsData &&
        externalAvailableVehicleGroupsData.externalAvailableVehicleGroups &&
        props.bookingSchedule && bookingData?.rateTypeDuration
      ) {
        if (externalAvailableVehicleGroupsData.externalAvailableVehicleGroups.vehicleGroups.length > 0) {
          const eVgArr = externalAvailableVehicleGroupsData.externalAvailableVehicleGroups.vehicleGroups
          for (let i = 0; i < eVgArr.length; i++) {
            const eVg = eVgArr[i];
            const idx = availableVehicleGroups.findIndex((item: any) => item.id === eVg.id);
            if (idx > -1) {
              eVg.vehicles.forEach((vehicle: IVehicle) => {
                if (!availableVehicleGroups[idx].vehicles.some((v: any) => v.id === vehicle.id)) {
                  availableVehicleGroups[idx].vehicles.push({
                    ...vehicle,
                    shared: true
                  })
                }
              })
            } else {
              eVg.vehicles = eVg.vehicles.map((ele: IVehicle) => {
                return {
                  ...ele,
                  shared: true
                }
              })
              availableVehicleGroups.push(eVg)
            }
          }
          let locationSurchageAmount = 0;
          const locationSurcharges = externalAvailableVehicleGroupsData.externalAvailableVehicleGroups.locationSurcharges.filter((surcharge: IPriceRule) => surcharge.isActive)
          if (locationSurcharges.length > 0 && bookingData.pickupServiceLocation) {
            locationSurcharges.forEach((charges: IPriceRule) => {
              if (charges.serviceLocations.includes(bookingData.pickupServiceLocation.id)) {
                locationSurchageAmount += charges.rules[0].value
              }
            });
          }
          setLocationSurchargeRules(locationSurcharges);
          setLocationSurchargesAmount(locationSurchageAmount)
        }
      }
      setVehicleGroups(availableVehicleGroups)
    }
  }, [availableVehicleGroupsData, externalAvailableVehicleGroupsData, props.bookingSchedule, bookingData?.rateTypeDuration])

  useEffect(() => {
    if (externalBookingSummaryData && externalBookingSummaryData.externalBooking) {

      setBooking(externalBookingSummaryData.externalBooking)
      setVehicleReplaceValues({
        ...vehicleReplaceValues,
        bookingId: externalBookingSummaryData.externalBooking.id,
        fromDate: externalBookingSummaryData.externalBooking.pickupDateTime,
        endDate: props.bookingSchedule?.endDate || externalBookingSummaryData.externalBooking.dropoffDateTime,
        businessCustomer: externalBookingSummaryData.externalBooking.businessCustomer ? externalBookingSummaryData.externalBooking.businessCustomer.id : "",
        longTerm: externalBookingSummaryData.externalBooking.longTermBooking,
        pcoNumber: externalBookingSummaryData.externalBooking.pcoNumber,
        rateTypeDuration: externalBookingSummaryData.externalBooking.rateTypeDuration,
        serviceLocation: externalBookingSummaryData.externalBooking.pickupServiceLocation ? externalBookingSummaryData.externalBooking.pickupServiceLocation.id : "",
        smartCarVehicle: externalBookingSummaryData.externalBooking.smartCarVehicle
      })

      if (externalBookingSummaryData.externalBooking.businessCustomer) {
        setBusinessCustomer(`${externalBookingSummaryData.externalBooking.businessCustomer.businessName}`)
      }
    }
    if (bookingSummaryData && bookingSummaryData.booking) {
      setVehicleReplaceValues({
        ...vehicleReplaceValues,
        bookingId: bookingSummaryData.booking.id,
        fromDate: bookingSummaryData.booking.pickupDateTime,
        endDate: props.bookingSchedule?.endDate || bookingSummaryData.booking.dropoffDateTime,
        businessCustomer: bookingSummaryData.booking.businessCustomer ? bookingSummaryData.booking.businessCustomer.id : "",
        longTerm: bookingSummaryData.booking.longTermBooking,
        pcoNumber: bookingSummaryData.booking.pcoNumber,
        rateTypeDuration: bookingSummaryData.booking.rateTypeDuration,
        serviceLocation: bookingSummaryData.booking.pickupServiceLocation ? bookingSummaryData.booking.pickupServiceLocation.id : "",
        smartCarVehicle: bookingSummaryData.booking.smartCarVehicle
      })
      setBooking(bookingSummaryData.booking)
      if (bookingSummaryData.booking.businessCustomer) {
        setBusinessCustomer(`${bookingSummaryData.booking.businessCustomer.businessName}`)
      }
    }
  }, [bookingSummaryData, externalBookingSummaryData])

  useEffect(() => {
    if (vehiclesData && vehiclesData.availableVehiclesForSwap) {
      setAvailableVehiclesForReplacements(vehiclesData.availableVehiclesForSwap)
    }
  }, [vehiclesData])

  return (
    <>
      {
        booking ? (
          <>
            <Grid container item xs={12} className={classes.dialogContent}>
              <Grid container item xs={12} justifyContent="space-between">
                {
                  !props.isSharedFleetEvent && (
                    <Fab
                      size="medium"
                      variant="extended"
                      onClick={() => {
                        navigate(`/view-booking?booking=${booking.id}`);
                      }}
                    >
                      VIEW {getLocalizedBookingSyntex(country).toUpperCase()}
                    </Fab>
                  )
                }
                {!props.isSharedFleetEvent && booking.status === BookingStatus.CONFIRMED && (
                  <Fab
                    size="medium"
                    variant="extended"
                    onClick={() => {
                      setIsVehicleReplacement(true)
                    }}
                  >
                    Assign Vehicle
                  </Fab>)}
                <Box pl={1}></Box>
                {booking?.status === BookingStatus.IN_PROGRESS && !props.isSharedFleetEvent &&
                  <>
                    <Fab
                      size="medium"
                      variant="extended"
                      disabled={!canExtend(booking)}
                      onClick={() => {
                        if (booking.id && booking.dropoffDateTime) {
                          setDialogType(DialogTypes.EXTEND_BOOKING)
                          setDialogData({
                            bookingId: booking.id,
                            startDate: booking.dropoffDateTime,
                            endDate: booking.dropoffDateTime
                          })
                        }
                      }}
                    >
                      EXTEND {getLocalizedBookingSyntex(country).toUpperCase()}
                    </Fab>
                    <Box pl={1}></Box>
                    <Fab
                      size="medium"
                      variant="extended"
                      onClick={() => {
                        setReplaceVehicle(true)
                      }}
                    >
                      Replace Vehicle
                    </Fab>
                  </>
                }
              </Grid>
              <Grid item xs={12}><Box mt={1.5}></Box></Grid>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" size={"small"} className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCell}><Typography variant="h4">{"Customer Name"}</Typography></TableCell>
                      <TableCell className={classes.tableCell}><Typography variant="body1">{businessCustomer ? businessCustomer : `${booking?.customer?.firstName} ${booking?.customer?.lastName}`}</Typography></TableCell>
                      <TableCell className={classes.tableCell}><Typography variant="h4">{"Phone Number"}</Typography></TableCell>
                      <TableCell className={classes.tableCell}><Typography variant="body1">{`${businessCustomer ? booking.businessCustomer?.contact.phoneNumber.phone : booking?.customer?.phoneNumber.phone}`}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell}><Typography variant="h4">{`${getLocalizedBookingSyntex(country)} Reference`}</Typography></TableCell>
                      <TableCell className={classes.tableCell}><Typography variant="body1">{booking?.referenceNumber}</Typography></TableCell>
                      <TableCell className={classes.tableCell}><Typography variant="h4">{"Pick-up Date & Time"}</Typography></TableCell>
                      {/* TODO: Add locale to formal date */}
                      <TableCell className={classes.tableCell}><Typography variant="body1">{booking?.pickupDateTime && getLocalizedDateFormat(country, booking.pickupDateTime, DATE_TYPE.EXPANDED)}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell}><Typography variant="h4">{booking?.tba ? "Renewal Date & Time" : "Drop-off Date & Time"}</Typography></TableCell>                      
                      <TableCell className={classes.tableCell}><Typography variant="body1">{booking?.dropoffDateTime && getLocalizedDateFormat(country, booking.dropoffDateTime, DATE_TYPE.EXPANDED)}</Typography></TableCell>
                      <TableCell className={classes.tableCell}><Typography variant="h4">
                        {
                          (booking?.status === BookingStatus.CONFIRMED && !booking.dropoffOtherLocation) ? "Pick-up Location" :
                            (booking?.status === BookingStatus.CONFIRMED && booking.dropoffOtherLocation) ? "Delivery Address" :
                              (booking?.status === BookingStatus.IN_PROGRESS && !booking.dropoffOtherLocation) ? "Drop-off Location" :
                                (booking?.status === BookingStatus.IN_PROGRESS && booking.dropoffOtherLocation) && "Collection Address"
                        }
                      </Typography></TableCell>
                      <TableCell className={classes.tableCell} style={{ maxWidth: 0 }}>
                        <Typography variant="body1">
                          {
                            (booking?.status === BookingStatus.CONFIRMED && !booking.pickupOtherLocation) ? booking?.pickupServiceLocation.name :
                              (booking?.status === BookingStatus.CONFIRMED && booking.pickupOtherLocation) ? booking.pickupOtherLocation.fullAddress :
                                (booking?.status === BookingStatus.IN_PROGRESS && !booking.dropoffOtherLocation) ? booking?.dropoffServiceLocation.name :
                                  (booking?.status === BookingStatus.IN_PROGRESS && booking.dropoffOtherLocation) && booking.dropoffOtherLocation.fullAddress
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell}><Typography variant="h4">{`${getLocalizedBookingSyntex(country)} Branch Name`}</Typography></TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {
                            userReducer.currentOrganisation.branches.map(branch => {
                              if (branch.id === userReducer.currentBranch.id && booking.branchId === userReducer.currentBranch.id) {
                                return "own";
                              }
                              if (booking.branchId === branch.id && booking.branchId !== userReducer.currentBranch.id) {
                                return branch.name
                              }
                            })
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid item xs={12}><Box mt={2}></Box></Grid>
              {isVehicleReplacement && props?.bookingSchedule &&
                <Grid item xs={12}>
                  <ReplaceVehicleForConfirmedBooking
                    scheduleDetails={props?.bookingSchedule}
                    booking={booking}
                    handleClose={props.handleClose}
                    reloadScheduler={props.reloadScheduler}
                  />
                </Grid>
              }
              <Grid item xs={12}><Box mt={2}></Box></Grid>
              {
                replaceVehicle ? (
                  <Paper elevation={2} style={{ padding: 15, flexGrow: 1 }}>
                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={6} container alignItems="center">
                        <Typography variant="h3">Vehicle Replacement</Typography>
                      </Grid>
                      <Grid item xs={6} container justifyContent='flex-end'>
                        <IconButton
                          onClick={() => setReplaceVehicle(false)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                      <Formik
                        enableReinitialize
                        initialValues={vehicleReplaceValues}
                        onSubmit={(values, { setSubmitting }) => {
                          if (booking.isSubscription && values.bookingId) {
                            props.replaceVehicleForInProgressSubscription({
                              bookingId: values.bookingId,
                              bookingScheduleId: props?.bookingSchedule?.id || "",
                              description: values.description || "",
                              previousVehicleId: props.bookingSchedule?.vehicle.id || "",
                              putVehicleOnHold: values.putVehicleOnHold,
                              reason: values.reason,
                              replacementDateTime: values.fromDate,
                              newVehicleId: selectedVehicleId
                            })
                          }
                          if (selectedVehicleGroup && values.bookingId) {
                            const basePrice = selectedVehicleGroup?.basePrices.find((bp) => bp.rateTypeDuration === bookingData?.rateTypeDuration)
                            if (basePrice) {
                              props.replaceVehicleForInProgressBooking({
                                bookingId: values.bookingId,
                                bookingScheduleId: props?.bookingSchedule?.id || "",
                                description: values.description || "",
                                previousVehicleGroupId: props.bookingSchedule?.vehicleGroup || "",
                                previousVehicleId: props.bookingSchedule?.vehicle.id || "",
                                putVehicleOnHold: values.putVehicleOnHold,
                                reason: values.reason,
                                replacementDateTime: values.fromDate,
                                vehicleGroup: {
                                  baseRate: basePrice.applicableAmount ? basePrice.applicableAmount : basePrice.rate,
                                  count: 1,
                                  deposit: selectedVehicleGroup.deposit,
                                  mileageLimit: basePrice.mileageLimit,
                                  pricePerExtraMile: basePrice.pricePerExtraMile,
                                  priceRule: basePrice.priceRule || "",
                                  priceRuleAmount: basePrice.priceRuleAmount || 0,
                                  priceRuleName: basePrice.priceRuleName || "",
                                  priceRuleOperator: basePrice.priceRuleOperator || '',
                                  priceRuleTriggerType: basePrice.priceRuleTriggerType || "",
                                  priceRuleValueType: basePrice.priceRuleValueType || "",
                                  unlimitedMileage: basePrice.unlimitedMileage || false,
                                  vehicleGroup: selectedVehicleGroup.id || "",
                                  vehicleIds: [selectedVehicleId],
                                  name: selectedVehicleGroup.name
                                },
                                startDate: vehicleReplaceValues.fromDate,
                                endDate: vehicleReplaceValues.endDate
                              })
                            }
                          }
                          setSubmitting(false)
                        }}
                      >
                        {(replaceProps) => (
                          <Form style={{ flexGrow: 1, padding: 15 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth>
                                  <Field
                                    component={InputField}
                                    name={"replacementReason"}
                                    required
                                    fullWidth
                                    type="text"
                                    select
                                    label="Replacement Reason"
                                    inputProps={{
                                      onChange: (event: any) => {
                                        setVehicleReplaceValues({ ...replaceProps.values, reason: event.target.value })
                                      },
                                      value: replaceProps.values.reason
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  >
                                    {
                                      vehicleReplacementOptions.map((option) => {
                                        return (
                                          <MenuItem
                                            key={option}
                                            value={option}
                                          >
                                            {option}
                                          </MenuItem>
                                        )
                                      })
                                    }
                                  </Field>
                                </FormControl>
                              </Grid>
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={replaceProps.values.putVehicleOnHold}
                                      onClick={(e: any) => {
                                        setVehicleReplaceValues({
                                          ...replaceProps.values,
                                          putVehicleOnHold: e.target.checked
                                        })
                                      }}
                                      value={replaceProps.values.putVehicleOnHold}
                                    />
                                  }
                                  label="Roadworthy"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth>
                                  <Field
                                    component={InputField}
                                    name={"description"}
                                    fullWidth
                                    type="text"
                                    label="Description"
                                    inputProps={{
                                      onChange: (event: any) => {
                                        setVehicleReplaceValues({ ...replaceProps.values, description: event.target.value })
                                      },
                                      value: replaceProps.values.description
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid container item xs={6} >
                                <SimpleDateTimePicker
                                  date={vehicleReplaceValues.fromDate}
                                  handleChange={(date: IDateTime) => {
                                    setVehicleReplaceValues({
                                      ...vehicleReplaceValues,
                                      fromDate: date.date,
                                    });
                                    if (booking && bookingData && date.date > bookingData?.pickupDateTime) {
                                      if (booking.isSubscription) {
                                        getVehiclesForSwap({
                                          variables: {
                                            bookingId: booking.id,
                                            startDate: vehicleReplaceValues.fromDate,
                                            endDate: vehicleReplaceValues.endDate
                                          }
                                        })
                                      }
                                      else {
                                        loadVehicleGroups({
                                          variables: {
                                            args: {
                                              endDate: vehicleReplaceValues.endDate,
                                              startDate: d.fromISO(date.date).toUTC().toISO(),
                                              rateTypeDuration: vehicleReplaceValues.rateTypeDuration,
                                              longTerm: vehicleReplaceValues.longTerm,
                                              businessCustomer: vehicleReplaceValues.businessCustomer || "",
                                              bookingId: "",
                                              pcoNumber: vehicleReplaceValues.pcoNumber,
                                              smartCarVehicle: vehicleReplaceValues.smartCarVehicle,
                                              serviceLocation: booking?.pickupServiceLocation?.id
                                            }
                                          }
                                        });
                                        loadExternalVehicleGroups({
                                          variables: {
                                            args: {
                                              endDate: vehicleReplaceValues.endDate,
                                              startDate: d.fromISO(date.date).toUTC().toISO(),
                                              rateTypeDuration: vehicleReplaceValues.rateTypeDuration,
                                              longTerm: vehicleReplaceValues.longTerm,
                                              businessCustomer: vehicleReplaceValues.businessCustomer || "",
                                              bookingId: "",
                                              serviceLocation: booking?.pickupServiceLocation?.id
                                            }
                                          }
                                        });
                                      }
                                    }
                                  }}
                                  required={true}
                                  disabled={false}
                                  name={"fromDate"}
                                  dateTitle={"From Date"}
                                  timeTitle={"From Time"}
                                  minDate={d.now().toUTC().toISO()}
                                  maxDate={d.fromISO(vehicleReplaceValues.endDate).toUTC().toISO()}
                                />
                              </Grid>
                              <Grid container item xs={6} >
                                <SimpleDateTimePicker
                                  date={vehicleReplaceValues.endDate}
                                  handleChange={(date: IDateTime) => {

                                  }}
                                  required={true}
                                  disabled={true}
                                  name={"endDate"}
                                  dateTitle={"To Date"}
                                  timeTitle={"To Time"}
                                />
                              </Grid>
                              <Grid item xs={12}></Grid>
                              {
                                booking?.isSubscription ? (
                                  <>
                                    {
                                      loadingVehicleForSwap ? (
                                        <Grid item xs={3}>
                                          <CircularProgress size={20} />
                                        </Grid>
                                      ) :
                                        availableVehiclesForReplacement && availableVehiclesForReplacement.length > 0 ? (
                                          <>
                                            <Grid item xs={3}>
                                              <Typography variant='h4' >Select Vehicle</Typography>
                                              <Box mt={1}></Box>
                                              <Field
                                                component={TextField}
                                                select
                                                name={""}
                                                fullWidth
                                                variant={"outlined"}
                                                inputProps={{
                                                  value: selectedVehicleId,
                                                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setSelectedVehicleId(e.target.value)
                                                  }
                                                }}
                                              >
                                                {
                                                  availableVehiclesForReplacement.map(vehicle => {
                                                    return (
                                                      <MenuItem key={vehicle.id} value={vehicle.id}>
                                                        {`${vehicle.licencePlate.toLocaleUpperCase()} | ${vehicle.make} ${vehicle.model}`}
                                                      </MenuItem>
                                                    )
                                                  })
                                                }
                                              </Field>
                                            </Grid>
                                          </>
                                        ) : (
                                          <Grid container item xs={12}>
                                            <Typography variant="h4" color="error">No vehicle found for replacement. Please select the different date range.</Typography>
                                          </Grid>
                                        )
                                    }
                                  </>
                                ) : (
                                  <>
                                    {
                                      !vehicleGroupsLoading && vehicleGroups?.length ? (
                                        <>
                                          <Grid item container xs={3}>
                                            <FormControl variant="outlined" fullWidth>
                                              <Autocomplete
                                                id="select-vehicle-group"
                                                options={vehicleGroups}
                                                classes={{ option: classes.autoCompleteMenuText, input: classes.autoCompleteMenuRoot, inputFocused: classes.autoCompleteMenuRoot }}
                                                getOptionLabel={(
                                                  option: IVehiclePriceGroup
                                                ) => {
                                                  const basePrice = option.basePrices.find((bs) => bs.rateTypeDuration === booking.rateTypeDuration)
                                                  if (basePrice) {
                                                    const applicableAmount = basePrice.applicableAmount ?? basePrice.rate;
                                                    return `${option.name}  |  ${toCurrency(applicableAmount + locationSurchargesAmount, currency, locale)}`;
                                                  } else {
                                                    return "No vehicle group found"
                                                  }
                                                }}
                                                style={{ width: "100%" }}
                                                renderInput={(params: any) => (
                                                  <TextField
                                                    {...params}
                                                    value={selectedVehicleGroup}
                                                    label={"Select Vehicle Group"}
                                                    variant="outlined"
                                                    required
                                                  />
                                                )}
                                                value={selectedVehicleGroup}
                                                onChange={(event: any, newValue: any, reason) => {
                                                  if (reason === "clear") {
                                                    setSelectedVehicleId("")
                                                    setVehicles([])
                                                  }
                                                  if (newValue && newValue.id && vehicleGroups) {
                                                    const vehicleGroupData = vehicleGroups.find(
                                                      (vehicleGroup: IVehiclePriceGroup) => {
                                                        return vehicleGroup.id === newValue.id;
                                                      }
                                                    );
                                                    if (vehicleGroupData && vehicleGroupData.id) {
                                                      setSelectedVehicleId("")
                                                      setSelectedVehicleGroup(vehicleGroupData);
                                                      setVehicles(vehicleGroupData.vehicles.filter((vehicle: IVehicle) => vehicle.id !== props.bookingSchedule?.vehicle && !vehicle.isGhostVehicle))
                                                    }
                                                  }
                                                }}
                                              />
                                            </FormControl>
                                          </Grid>
                                          {
                                            vehicles && (
                                              <Grid item container xs={3}>
                                                <FormControl variant="outlined" fullWidth>
                                                  <Autocomplete
                                                    id="select-vehicle-"
                                                    options={vehicles}
                                                    classes={{ input: classes.autoCompleteMenuRoot, inputFocused: classes.autoCompleteMenuRoot }}
                                                    getOptionLabel={(
                                                      option: IVehicle
                                                    ) => {
                                                      return `${option.licencePlate} | ${option.make} | ${option.model}`;
                                                    }}
                                                    style={{ width: "100%" }}
                                                    renderInput={(params: any) => (
                                                      <TextField
                                                        {...params}
                                                        label={"Select Vehicle"}
                                                        variant="outlined"
                                                        required
                                                      />
                                                    )}
                                                    value={vehicles.find((vehicle: IVehicle) => vehicle.id === selectedVehicleId)}
                                                    onChange={(event: any, newValue: any) => {
                                                      if (newValue && newValue.id && vehicleGroups) {
                                                        const vehicleData = vehicles.find(
                                                          (vehicle: IVehicle) => {
                                                            return vehicle.id === newValue.id;
                                                          }
                                                        );
                                                        if (vehicleData && vehicleData.id) {
                                                          setSelectedVehicleId(vehicleData.id);
                                                        }
                                                      }
                                                    }}
                                                  />
                                                </FormControl>
                                              </Grid>
                                            )
                                          }
                                        </>
                                      ) : vehicleGroupsLoading ? (
                                        <Grid item xs={3}>
                                          <CircularProgress />
                                        </Grid>
                                      ) : (
                                        <Grid container item xs={12}>
                                          <Typography variant="h4" color="error">No vehicle group found for replacement. Please select the different date range.</Typography>
                                        </Grid>
                                      )
                                    }
                                  </>
                                )
                              }
                              <Grid item xs={12} container justifyContent="flex-end">
                                <Fab
                                  size="medium"
                                  variant="extended"
                                  type="submit"
                                  disabled={
                                    vehicleReplaceValues.reason &&
                                      vehicleReplaceValues.fromDate &&
                                      (booking.isSubscription ||
                                        selectedVehicleGroup?.id) &&
                                      selectedVehicleId ? false : true
                                  }
                                >
                                  {(props.replaceVehicleLoading || props.replaceSubscriptionVehicleLoading) && (
                                    <CircularProgress
                                      size={14}
                                      style={{ marginRight: "10px" }}
                                    />
                                  )}
                                  CONFIRM
                                </Fab>
                              </Grid>
                            </Grid>
                          </Form>

                        )}
                      </Formik>
                    </Grid>
                  </Paper>
                ) : vehicleGroupsLoading ? (
                  <CircularProgress />
                ) : ""
              }
              <Grid item xs={12}><Box mt={2}></Box></Grid>
            </Grid>
          </>
        ) :
          (
            <>
              {enabledRoles.includes(userReducer.role) ? <CircularProgress /> :
                <>
                  {(loadBooking || loadExternalBooking) ? <CircularProgress /> : <div>
                    <h3 style={{ color: "red" }}>Access Denied</h3>
                  </div>
                  }
                </>
              }
            </>
          )
      }
    </>
  )
}