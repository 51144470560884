import { gql } from "@apollo/client";

export const GET_CUSTOMER = gql`
  query Customer($customerId: String!) {
    customer(customerId: $customerId) {
      id
      status
      firstName
      lastName
      taxExempted
      gender
      verifiedDate
      costCenters {
        name
        projectIds
      }
      phoneNumber {
        phone
        country
      }
      secondaryPhoneNumber {
        phone
        country
      }
      email
      address
      secondaryEmail
      dateOfBirth
      location {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      license {
        licenseNumber
        dateOfExpiry
        dateOfIssue
        authority
        endorsement
        images
        offences
        points
        dvlaVerificationStatus
      }
      verified
      verificationMethod
      profilePicture
      nationalInsuranceNumber
      profession
      deleted
      documents {
        id
        documentKey
        documentName
        title
        expiryDate
        documentType
        createdAt
      }
      approvedDrivers {
        id
        firstName
        lastName
        email
        status
        phoneNumber {
          phone
          country
        }
        license {
          licenseNumber
          dateOfExpiry
          authority
          images
        }
        phoneNumber {
          phone
          country
        }
      }
      customerPCONumber
      notes {
        id
        description
        createdBy
        createdDate
      }
      accountNumber
      credasIdentityVerification {
        entityId
        processId
        lastUpdated
        verificationMethod
        verificationStatus
      }
      creditSafeData {
        creditScore
        lastChecked
      }
      overrideInvoiceDate
      overrideWeeklyInvoiceDate
      weeklyInvoicingDay
      invoicingDay
      credentialSent
      credentialSentOn
      autoPayDisabled
    }
  }
`;
