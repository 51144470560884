import { gql } from "@apollo/client";

export const GET_DEFLEET_VEHICLE = gql`
  query getDeFleetVehicle($vehicleId: String!) {
    getDeFleetVehicle(vehicleId: $vehicleId) {
      id
      licencePlate
      infoSource
      deFleetReason
      deFleetPrice
      residualValue
      namaGrading
      purchaserName
      purchaserAddress {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      deFleetCheckList {
        exteriorPaint
        wheelRims
        windscreensAndWindows
        mirrors
        bumper
        headlamps
        interiorFabric
        locksAndDoorHandles
        bothKeys
        telematicsDeviceRemoved
        pcn
        pcnTotalAmount
        licencePlateCondition
        registrationDocumentUrl
        motDocumentUrl
        loanDocumentUrl
        loanStatus
        currentLoanStatus
      }      
    }
  }
`;