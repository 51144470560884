import { AddonTypeAction, CLEAR_ADDON_TYPE, UPDATE_ADDON_TYPE } from "./types";

export function updateAddonType(payload: any): AddonTypeAction {
  return {
    payload,
    type: UPDATE_ADDON_TYPE
  };
}

export function clearAddonType(): AddonTypeAction {
  return {
    type: CLEAR_ADDON_TYPE
  };
}
