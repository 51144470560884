import { gql } from "@apollo/client";

export const GET_ADDON_TYPE = gql`
  query addonType($id: String!) {
    addonType(id: $id) {
      id
      displayName
      name
      category
      imageUrl
      hasFixedRate
      hasQuantity
      fixedRate
      description
      recurringRate {
        rateTypeName
        rateTypeDuration
        rate
        longTerm
      }
      totalQuantity
      availableQuantity
      isActivated
      taxable
      tax {
        title
        type
        value
      }
      rules {
        from
        to
        amount
      }
    }
  }
`;
