import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import api from "../../api/Reports";
import styles from "../index.module.css";
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import { allowdRolesForVehicleReports } from '../../utils';
import { captureErrorException } from '../../../../../utils/sentry';

const customTick = (tick: any) => {
  return (
    <g transform={`translate(${tick.x - 20},${tick.y})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fill: '#333',
          fontSize: 10,
        }}
      >
        {`${tick.value / 1000}K`}
      </text>
    </g>
  )
}

const StyledInput = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#f6f6f6',
      fontSize: 14,
      padding: "8px 8px 5px 8px !important",
      border: 0,
      borderRadius: 5,
      width: 180,
    },
    '& fieldset': {
      border: 0
    },
    '& .Mui-error .MuiOutlinedInput-input': {
      color: "red"
    }
  }
})(TextField);

interface IProps {
  licensePlate: string
}
interface IChartData {
  [key: string]: string | number
}

interface IVehicleData {
  licencePlate: string[];
  telemetryDeviceId: string[]
}

export const FinanceVehicleChart: React.FC<IProps> = (props) => {
  const initialFinData: IChartData = {
    "Time": "Life time",
    "Revenue": 0,
    "RevenueColor": "#147AD6",
    "Costs": 0,
    "CostsColor": "#EC6666"
  };


  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const tenantId = userState && userState.currentTenancy ? userState.currentTenancy?.id : userState.tenancy.id;
  const [chartData, setChartData] = useState<IChartData[]>([initialFinData]);
  const [loading, setLoading] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<IVehicleData>({ licencePlate: [], telemetryDeviceId: [] })
  const [filters, setfilters] = useState<{ licensePlate: string, esn: string }>({ licensePlate: "", esn: "" });
  const fetchData = async () => {
    try {
      setLoading(true)
      const { data: axiosData } = await api.getFinanceDataVehicle({
        licencePlate: filters.licensePlate,
      });
      setLoading(false)
      if (axiosData?.totalRevenue) {
        let tempData: IChartData = { ...initialFinData };
        tempData["Revenue"] = parseFloat(axiosData?.totalRevenue || "0");
        tempData["Costs"] = parseFloat(axiosData?.totalCosts || "0");
        setChartData([tempData]);
      }
    }
    catch (error: any) {
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading finance info data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }


  const fetchAllVehicles = async () => {
    try {
      const { data: axiosData } = await api.getVehicles({
        tenantID: tenantId,
        organisationID: userState.currentOrganisation.id,
        branchID: userState.currentBranch.id,
      });
      if (axiosData?.licenceData) {
        setVehicles(axiosData.licenceData);
        setfilters({
          licensePlate: axiosData.licenceData?.licencePlate[0] || "",
          esn: axiosData.licenceData?.telemetryDeviceId[0] || ""
        })
      }
    }
    catch (error: any) {
      captureErrorException(error)
      setLoading(false);
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading vehicle data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  useEffect(() => {
    if (allowdRolesForVehicleReports.includes(userState.role)) {
      fetchAllVehicles();
    }
  }, [userState, allowdRolesForVehicleReports])

  useEffect(() => {
    if (filters.licensePlate) {
      fetchData()
    }
  }, [filters.licensePlate])


  return (
    <div className={`${styles.module} ${styles.outline}`}>
      <div className={styles.chartHeadline}>
        <span className="font-medium ">
          {vehicles.licencePlate.length ? <div className={`flex cross-center`}>
            <Autocomplete
              freeSolo
              clearOnEscape
              clearOnBlur
              disableClearable
              noOptionsText="No Groups"
              id="free-solo-fc-search"
              options={vehicles.licencePlate}
              getOptionLabel={(option: any) =>
                option
              }
              fullWidth
              value={filters.licensePlate}
              onChange={(e: any, value: any) => {
                const index = vehicles.licencePlate.findIndex(item => item === value)
                if (index >= 0) {
                  setfilters({
                    ...filters,
                    esn: vehicles.telemetryDeviceId[index],
                    licensePlate: value,
                  })
                }
              }}
              renderInput={(params) => (
                <StyledInput
                  {...params}
                  variant="outlined"
                  placeholder={"Select a vehicle"}
                  InputProps={{ ...params.InputProps, type: "search" }}
                />
              )}
            />
          </div> : (
            <>
              {loading ? (
                <div className="margin-right flex cross-center">
                  <CircularProgress size={20} thickness={5} />
                </div>
              ) : ""}
            </>
          )}
          <Box mt={1}></Box>
          <strong style={{ display: "inline-block" }}>Finance Info </strong>
          <span className="opacity50" style={{ display: "inline-block" }}>(Life Time)</span>


        </span>

        <span style={{ height: 20, display: 'flex', alignItems: "center" }}>

          {loading ? <CircularProgress size={20} thickness={5} /> : null}
        </span>
      </div>
      <Box mt={1}></Box>
      {loading ? null : filters.licensePlate && !chartData[0].Revenue && !chartData[0].Costs ?
        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Typography style={{ padding: "10px 30px", borderRadius: 6, border: "1px solid #f5f5f5", textAlign: "center", margin: "0 20px" }} variant="body1">Data not available for selected vehicle</Typography>
        </div>
        : <ResponsiveBar
          data={chartData}
          keys={['Revenue', 'Costs']}
          indexBy="Time"
          groupMode="grouped"
          margin={{ top: 70, right: 0, bottom: 65, left: 40 }}
          padding={0.2}
          innerPadding={4}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: false }}
          colors={({ id, data }: any) => data[`${id}Color`]}
          theme={{
            grid: {
              line: {
                stroke: "#f2f2f2",
                strokeWidth: 1
              }
            }
          }}
          tooltip={point => {
            return <div
              key={point.id}
              style={{
                padding: '3px 0',
                fontSize: 14,
                display: "flex",
                alignItems: "center"
              }}
            >
              <div style={{ background: point.color, height: "8px", width: "8px", marginRight: 5 }} />{point.id} : <strong>{point.value}</strong>
            </div>
          }}
          borderRadius={4}
          borderWidth={0}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickValues: 5,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            renderTick: customTick
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#fff"
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              translateX: -28,
              translateY: 60,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 21,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 15,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          animate={true}
        // motionStiffness={90}
        // motionDamping={15}
        />}
    </div>
  )
}