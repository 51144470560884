import "./index.scss";
import { AppBar, Box, Grid, IconButton, Popover, Theme, Toolbar, Tooltip, Typography } from '@mui/material';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import React, { useState } from 'react';
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from '@mui/icons-material/Menu';

import { SwitchAppView } from "../Navbar/SwitchAppView";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { NotificationButton } from './Notification';
import HttpHeader from "../../../header";
import { createStyles, makeStyles } from "@mui/styles";

const httpHeader = new HttpHeader();
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "block",
      width: `calc(100% - 200px)`,
      marginLeft: 200
    },
    popoverMenu: {
      minWidth: 600,
      width: "50%",
      "& .MuiPopover-paper": {
        width: "35%",
        padding: theme.spacing(2),
        marginRight: 10,
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
      }
    },
    signOutButton: {
      border: "none",
      fontSize: 14,
      padding: 0
    },
    profile: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    menuButton: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

interface IProps {
  handleDrawerToggle(): void;
}

export const Header = (props: IProps) => {

  const classes = useStyles();

  const userState = useSelector((state: IAppState) => state.userReducer);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  const handleDrawerToggle = () => {
    props.handleDrawerToggle();
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-menu" : undefined;

  return (
    <Box style={{ flexGrow: 1 }}>
      <AppBar position="fixed" className={"app-header"} style={{ boxShadow: "none", borderBottom: "1px solid #e2e2e2" }}>
        <Toolbar className={"account-circle-icon"} style={{ minHeight: "3.5rem" }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            size="large">
            <MenuIcon />
          </IconButton>
          <Box style={{ flexGrow: 1 }} />
          <NotificationButton />
          <span className="margin-right" />
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={(event: any) => handleClick(event)}
            size="large">
            <AccountCircle fontSize={"large"} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Popover
        className={classes.popoverMenu}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} container className={classes.profile}>
            <Typography
              variant="h2"
              color="primary"
            >{`${userState.firstName} ${userState.lastName}`}</Typography>
            <Tooltip title="Sign Out">
              <IconButton
                className={classes.signOutButton}
                aria-label="signout from app "
                aria-haspopup="true"
                onClick={() => {
                  httpHeader.resetHeaders();
                  localStorage.clear();
                  window.location.replace("/");
                }}
                size="large">
                <ExitToAppIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <SwitchAppView />
          </Grid>
          <Grid item xs={8} container alignItems="center">
            <Typography variant="h4">
              <a href="https://portal.productboard.com/eg9vyuwundiuet1alsz3cfiu" target="_blank" style={{ color: "#002F6E" }}>
                {"Product Roadmap"}
              </a>
            </Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="flex-end">
            <Typography variant="h4">
              <a href="https://help.coastr.com/knowledge" target="_blank" style={{ color: "#002F6E" }}>
                {"Help"}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Popover>
    </Box >
  );
};
