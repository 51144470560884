import { gql } from "@apollo/client";

export const GET_CONSOLIDIATED_INVOICE_COUNT = gql`
  {
    consolidatedInvoiceCount
  }
`;

export const GET_CONSOLIDATED_INVOICES = gql`
  query consolidatedInvoices($limit: Int!, $offset: Int!, $filters: [FilterInput]){
    consolidatedInvoices(limit: $limit, offset: $offset, filters: $filters) {
      customerType
      insuranceAmount
      taxAmount
      dueDate
      dueAmount
      lastSent
      dateCreated
      dateUpdated
      createdBy
      updatedBy
      invoiceRef
      status
      locked
      invoiceType
      startDate
      endDate
      totalPayableAmount
      confirmedPaidAmount
      confirmedRefundAmount
      otherRefunds
      otherCharges {
        amount
        name
      }
      id
      businessCustomer {
        id
        businessName
        registrationNumber
        VAT
      }
    }
  }
`;