import { gql } from "@apollo/client";

export const DOWNLOAD_VEHICLES_DAMAGES_REPORT = gql`
  query dowloadVehiclesDamagesReport(
    $startDate: String!
    $endDate: String!
  ) {
    dowloadVehiclesDamagesReport(
        startDate: $startDate
        endDate: $endDate
      ) {
      success
      key
      message
    }
  }
`;

export const DOWNLOAD_VEHICLES_PERFORMANCE_REPORT = gql`
  query dowloadVehiclesPerformanceReport(
    $startDate: String!
    $endDate: String!
  ) {
    dowloadVehiclesPerformanceReport(
        startDate: $startDate
        endDate: $endDate
      ) {
      success
      key
      message
    }
  }
`;

export const DOWNLOAD_FUTURE_VEHICLE_AVAILABILITY_REPORT = gql`
  query downloadFutureVehiclesAvailabilityReport($startDate: String!, $endDate: String!){
    downloadFutureVehiclesAvailabilityReport(startDate: $startDate, endDate: $endDate){
      success
      key
      message
    }
  }
`