import { createTheme, hexToRgb, rgbToHex } from "@mui/material/styles";

// Hex to Complementary Colour
// Adapted from answer by Edward https://stackoverflow.com/a/37657940
function hexToComplimentary(hex: string) {
    var rgb = hexToRgb(hex);

    var r = Number(rgb.split(`,`)[0].substring(4));
    var g = Number(rgb.split(`,`)[1]);
    var b = Number(rgb.split(`,`)[2].slice(0, -1));

    // Convert RGB to HSL
    // Adapted from answer by 0x000f http://stackoverflow.com/a/34946092/4939630
    r /= 255.0;
    g /= 255.0;
    b /= 255.0;
    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);
    var h = (max + min) / 2.0;
    var s = (max + min) / 2.0;
    var l = (max + min) / 2.0;

    if (max == min) {
        h = s = 0; //achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2.0 - max - min) : d / (max + min);

        if (max == r && g >= b) {
            h = (1.0472 * (g - b)) / d;
        } else if (max == r && g < b) {
            h = (1.0472 * (g - b)) / d + 6.2832;
        } else if (max == g) {
            h = (1.0472 * (b - r)) / d + 2.0944;
        } else if (max == b) {
            h = (1.0472 * (r - g)) / d + 4.1888;
        }
    }

    h = (h / 6.2832) * 360.0 + 0;

    // Shift hue to opposite side of wheel and convert to [0-1] value
    h += 180;
    if (h > 360) {
        h -= 360;
    }
    h /= 360;

    // Convert h s and l values into r g and b values
    // Adapted from answer by Mohsen http://stackoverflow.com/a/9493060/4939630
    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        var hue2rgb = (p: number, q: number, t: number) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;

        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    r = Math.round(r * 255);
    g = Math.round(g * 255);
    b = Math.round(b * 255);

    // Convert r b and g values to hex
    rgb = `rgb(${r},${g},${b})`;
    return rgbToHex(rgb);
}

export const getAccent = (colour: string) => {
    // return (hexToComplimentary(colour))
    const theme = createTheme({
        palette: {
            primary: {
                main: `${colour}`
            }
        }
    });
    // return (`#${String(0xFFFFFF - Number(`0x${colour.substring(1)}`)).substring(2)}`)
    return theme.palette.primary.light;
};

export const getDark = (colour: string) => {
    const theme = createTheme({
        palette: {
            primary: {
                main: `${colour}`
            }
        }
    });
    // console.log("Colours")
    // console.log(theme.palette.primary)
    // console.log(theme.palette.secondary)

    // const theme = createMuiTheme({
    //     palette: {
    //         primary: {
    //             main: `${hexToComplimentary(colour)}`,
    //         },
    //     },
    // });

    return theme.palette.primary.dark;
};

export const getText = (colour: string) => {
    const theme = createTheme({
        palette: {
            primary: {
                main: `${colour}`
            }
        }
    });

    return theme.palette.primary.contrastText;
};
