import { useLazyQuery, useMutation, ApolloError } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  IconButton,
  TextField as InputField,
  MenuItem,
  Paper,
  Switch,
  Theme,
  Tooltip,
  Typography,
  CircularProgress
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { createStyles, makeStyles } from "@mui/styles";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Field, FieldProps, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { MUIDataTableOptions } from "mui-datatables";
import React, { ChangeEvent, useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { setBusinessCustomerInfo } from "../../../../../../actions/customer/actions";
import { ADD_BUSINESS_CUSTOMER_NOTE } from "../../../../../../graphql/businessCustomers/addBusinessCustomerNote";
import { UPDATE_BUSINESS_CUSTOMER } from "../../../../../../graphql/businessCustomers/businessCustomer";
import { SEND_BUSINESS_CUSTOMER_LOGIN_CREDENTIAL } from "../../../../../../graphql/businessCustomers/sendBusinessCustomerLoginCredential";
import { VERIFY_BUSINESS_CUSTOMER } from "../../../../../../graphql/businessCustomers/verifyBusinessCustomer";
import { GET_ORG_APP_SETTING } from "../../../../../../graphql/consumerWebsite/websiteQueries";
import { GET_BUSINESS_CREDIT_SCORE } from "../../../../../../graphql/customers/getBusinessCreditscoreMutation";
import { GET_VEHICLE_GROUPS_PRICE } from "../../../../../../graphql/priceRules/getVehiclesGroupsQuery";
import {
  BusinessCustomerStatus,
  IBusinessCustomer,
  IBusinessCustomerNote,
  IBusinessCustomerNoteInput
} from "../../../../../../reducers/customer/types";
import { IAddress } from "../../../../../../reducers/user/types";
import { IAppState } from "../../../../../../store";
import {
  getDefaultCountryCode,
  getLocalizedBookingSyntex,
  getLocalizedDateFormat,
  getLocalizedTaxSyntex
} from "../../../../../../utils/localized.syntex";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../../../../common/NuvvenTable/types";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  monthDates,
  weekdays
} from "../../../../../common/utils";
import { noteColumns } from "../../../../ReservationManagement/Summary/const";
import AddressPicker from "../../../NewCustomer/AddressPicker/AddressPicker";
import { useNavigate } from "react-router-dom";
import { BusinessCreditCheckDialog } from "./BusinessCreditCheckDialog";
import NuvvenAutoCompleteTags from "../../../../../common/NuvvenAutoCompleteTags";
import InfoIcon from '@mui/icons-material/Info';
import { IServiceOptionsMenu, serviceAnniversary } from "../../../../Fleet/ServicesAndRepair/constants";
import { GET_USERS_BY_ROLES } from "../../../../../../graphql/organisation/getUsersQuery";
import { UserRoles } from "../../../../../hoc/Authorization";
import { IAccountManager } from "../../../../../../reducers/bookings/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxLabel: {
      fontSize: "16px !important"
    },
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(5)
    },
    section: {
      marginBottom: "25px"
    },
    invoiceDays: {
      paddingTop: 10,
      paddingBottom: 10
    }
  })
);

interface ICustomerInfoProps {
  businessCustomer: IBusinessCustomer;
}
interface IRingFencedVehiclePriceGroup {
  id: string;
  name: string;
  branchId: string;
}

export const CustomerInfo = (props: ICustomerInfoProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [values, setValues] = useState<IBusinessCustomer>(
    props.businessCustomer
  );
  const [note, setNote] = useState<IBusinessCustomerNoteInput>();
  const [rows, setRows] = useState<IBusinessCustomerNote[]>([]);
  const [countryCode, setCountry] = useState<any>({
    billing: getDefaultCountryCode(country),
    contact: getDefaultCountryCode(country)
  });
  const [billingPhoneError, setBillingPhoneError] = useState<string>("");
  const [contactPhoneError, setContactPhoneError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedVehicleGroups, setSelectedVehicleGroups] = useState<any>([]);
  const [vehicleGroups, setVehicleGroups] = useState<
    IRingFencedVehiclePriceGroup[]
  >([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [reason, setReason] = useState<string>(
    props.businessCustomer.declinedReason
  );
  const [status, setStatus] = useState<string>(props.businessCustomer.status);
  const [signUpSetting, setSignUpSetting] = useState<boolean>(false);
  const [declineDialog, setDeclineDialog] = useState<boolean>(false);
  const [openCreditCheckDialog, setOpenCreditCheckDialog] = useState(false);
  const tenancy = userState.currentTenancy;
  const [accountManagersOptions, setAccountManagersOptions] = useState<IServiceOptionsMenu[]>([]);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    elevation: 0,
    onRowClick: () => { },
    responsive: "scroll" as any
  };

  const [getUsersByRoles, { data: usersData }] = useLazyQuery(GET_USERS_BY_ROLES, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const handleToggleSelectAll = (formikValues: any, setFormikValues: any) => {
    setSelectAll((prev) => {
      if (!prev) {
        setSelectedVehicleGroups([...vehicleGroups]);
        const updatedVehicleGroups = vehicleGroups.map((val: any) => {
          const { basePrices, vehicles, ...rest } = val;
          return rest;
        });
        setFormikValues({
          ...formikValues,
          vehicleGroups: updatedVehicleGroups
        });
      } else setSelectedVehicleGroups([]);
      return !prev;
    });
  };

  const CustomPaper = (props: any, formikValues: any, setFormikValues: any) => {
    const { children, ...restPaperProps } = props;
    return (
      <Paper elevation={8} {...restPaperProps}>
        <Box onMouseDown={(e) => e.preventDefault()} pl={1.5} py={0.5}>
          <FormControlLabel
            onClick={(e) => {
              e.preventDefault();
              handleToggleSelectAll(formikValues, setFormikValues);
            }}
            label="Select all"
            control={<Checkbox id="select-all-checkbox" checked={selectAll} />}
          />
        </Box>
        <Divider />
        {children}
      </Paper>
    );
  };

  const [loadVehicleGroups, { data: vehicleGroupsData }] = useLazyQuery(
    GET_VEHICLE_GROUPS_PRICE,
    {
      fetchPolicy: "network-only"
    }
  );

  const [updateBusinessCustomer] = useMutation(UPDATE_BUSINESS_CUSTOMER, {
    onCompleted: (data) => {
      snackbar({
        message: "Customer Information Saved",
        variant: SnackBarVariant.SUCCESS
      });
      dispatch(setBusinessCustomerInfo(data.updateBusinessCustomer));
      setValues(data.updateBusinessCustomer);
      setLoading(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setLoading(false);
    }
  });

  const [loadAppSetting] = useLazyQuery(GET_ORG_APP_SETTING, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getAppSettingForOrganisation) {
        setSignUpSetting(
          data.getAppSettingForOrganisation.webAppSetting
            .enableBusinessCustomerSignup
        );
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    verifyBusinessCustomer,
    { loading: approveCustomerLoading }
  ] = useMutation(VERIFY_BUSINESS_CUSTOMER, {
    onCompleted: (data) => {
      setStatus(data.verifyBusinessCustomer.status);
      snackbar({
        message: data.verifyBusinessCustomer.message,
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [addBusinessCustomerNote] = useMutation(ADD_BUSINESS_CUSTOMER_NOTE, {
    onCompleted: (data) => {
      snackbar({
        message: "Note added successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setRows([...rows, { ...data.addBusinessCustomerNote }]);
      setNote({
        description: "",
        createdBy: "",
        createdDate: ""
      });
    }
  });

  const [sendLoginCredential] = useMutation(
    SEND_BUSINESS_CUSTOMER_LOGIN_CREDENTIAL,
    {
      onCompleted: (data) => {
        snackbar({
          message: data.sendBusinessCustomerLoginCredential.message,
          variant: SnackBarVariant.SUCCESS
        });
        navigate("/business-customer");
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const [
    getCreditScoreForBusinessCustomer,
    { loading: scoreLoading }
  ] = useMutation(GET_BUSINESS_CREDIT_SCORE, {
    onCompleted: (data) => {
      if (!data.getBusinessCreditScore) {
        snackbar({
          message: "No valid credit reading found for this company.",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const getCreditScore = () => {
    getCreditScoreForBusinessCustomer({
      variables: {
        businessCustomerId: values.id
      }
    });
  };

  useEffect(() => {
    if (userState.tenancy) {
      loadVehicleGroups();
      loadAppSetting();
      setSelectedVehicleGroups(values.vehicleGroups || []);
    }
  }, [userState.tenancy]);

  useEffect(() => {
    if (vehicleGroupsData && vehicleGroupsData.vehicleGroups) {
      const sortedGroups = [...vehicleGroupsData.vehicleGroups].sort(
        (firstGroup: any, secondGroup: any) => {
          return firstGroup.name
            .toLowerCase()
            .localeCompare(secondGroup.name.toLowerCase());
        }
      );
      const _sortedGroup = sortedGroups.map((group: any) => {
        const { basePrices, vehicles, branchId, ...rest } = group;
        return rest;
      });
      if (values.vehicleGroups && values.vehicleGroups.length) {
        const filteredGroups = _sortedGroup.filter((group: any) => {
          return !values.vehicleGroups?.some(
            (selectedGroup) => selectedGroup.name === group.name
          );
        });
        setVehicleGroups(filteredGroups);
      } else {
        setVehicleGroups(_sortedGroup);
      }
    }
  }, [vehicleGroupsData]);

  useEffect(() => {
    if (props.businessCustomer) {
      if (props.businessCustomer.notes) {
        setRows(props.businessCustomer.notes);
      }
      setValues(props.businessCustomer);
    }
    getUsersByRoles({
      variables: {
        roles: [UserRoles.ACCOUNT_MANAGER]
      }
    })
  }, [props.businessCustomer]);

  useEffect(() => {
    if (usersData?.getUsersByRoles?.length > 0) {
      const accountManagerOptions = usersData.getUsersByRoles.map(({ id, firstName, lastName }: IAccountManager) => ({
        label: `${firstName} ${lastName}`,
        value: id,
      }));
      setAccountManagersOptions(accountManagerOptions);
    }
  }, [usersData])

  function handleCheckbox(
    event: React.ChangeEvent<HTMLInputElement>,
    values: IBusinessCustomer
  ) {
    if (event.target.checked) {
      setValues({
        ...values,
        tradingAddress: values.registeredAddress,
        tradingAddressSame: true
      });
    } else {
      setValues({
        ...values,
        tradingAddress: {
          ...values.tradingAddress,
          street: "",
          city: "",
          state: "",
          country: "",
          zipcode: "",
          fullAddress: ""
        },
        tradingAddressSame: false
      });
    }
  }

  const onSubmitHandler = (values: IBusinessCustomer, actions: any) => {
    const {
      approvedDrivers,
      authorizedSignatories,
      documents,
      notes,
      contactName,
      credentialSent,
      credentialSentOn,
      isClientExists,
      status,
      declinedReason,
      ...rest
    } = values;
    const updateValues = {
      ...rest,
      billing: {
        ...values.billing,
        accountNumber: values.billing.accountNumber
          ? values.billing.accountNumber
          : "",
        phoneNumber: {
          ...values.billing.phoneNumber,
          country: countryCode.billing
        }
      },
      contact: {
        ...values.contact,
        phoneNumber: {
          ...values.contact.phoneNumber,
          country: countryCode.contact
        }
      }
    };

    if (
      updateValues.creditLimitEnabled &&
      (!updateValues.creditLimit || updateValues.creditLimit <= 0)
    ) {
      snackbar({
        message: "Please enter valid Credit limit.",
        variant: SnackBarVariant.ERROR
      });
      actions.setSubmitting(false);
      return;
    }
    if (values.isRingFenced && !selectedVehicleGroups.length) {
      snackbar({
        message: 'Please add atleast one vehicle group or consider turning off the ring fencing setting',
        variant: SnackBarVariant.ERROR
      });
      return;
    }

    delete updateValues.businessCreditData;

    if(updateValues.accountManager && typeof updateValues.accountManager !== 'string' && updateValues.accountManager.id){
      updateValues.accountManager = updateValues.accountManager.id
    }

    if (props.businessCustomer && props.businessCustomer.id) {
      updateBusinessCustomer({
        variables: {
          businessCustomer: updateValues,
          businessCustomerId: props.businessCustomer.id
        }
      });
      setLoading(true);
    }
    actions.setSubmitting(false);
  };

  const handleDeclineDialogClose = () => {
    setDeclineDialog(false);
  };

  const handleApproveBusinessCustomer = (status: string) => {
    verifyBusinessCustomer({
      variables: {
        status,
        businessCustomerId: props.businessCustomer.id,
        reason
      }
    });
  };

  const customerInfoSchema = Yup.object().shape({
    billing: Yup.object().shape({
      email: Yup.string()
        .email("Please enter a valid Billing email.")
        .required("Billing email is required."),
      phoneNumber: Yup.object().shape({
        phone: Yup.string()
          .test("test-is-b-valid-phone-number", "", function (value) {
            if (value && !isValidPhoneNumber(value)) {
              return this.createError({ message: "Invalid phone number" });
            }
            return true;
          })
          .required("Phone number is required.")
      })
    }),
    businessName: Yup.string().required("Business name is required."),
    contact: Yup.object().shape({
      email: Yup.string()
        .required("Contact email is required.")
        .email("Please enter a valid Contact email."),
      firstName: Yup.string().required("First name is required."),
      lastName: Yup.string().required("Last name is required."),
      phoneNumber: Yup.object().shape({
        phone: Yup.string()
          .test("test-c-is-valid-phone-number", "", function (value) {
            if (value && !isValidPhoneNumber(value)) {
              return this.createError({ message: "Invalid phone number" });
            }
            return true;
          })
          .required("Phone number is required.")
      })
    }),
    registeredAddress: Yup.object()
      .shape({
        fullAddress: Yup.string().required("Registered Address is required"),
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required")
      })
      .required("Registered Address is required"),
    tradingAddress: Yup.object()
      .shape({
        fullAddress: Yup.string().required("Trading Address is required"),
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required")
      })
      .required("Trading Address is required"),
    weeklyInvoicingDay: Yup.string().when("overrideWeeklyInvoiceDate", {
      is: true,
      then: schema => schema.required("Weekly Invoicing Day is required.").nullable(),
      otherwise: schema => schema.notRequired().nullable()
    }),
    invoicingDay: Yup.string().when("overrideInvoiceDate", {
      is: true,
      then: (schema) =>
        schema.required("Monthly Invoicing Date is required.").nullable(),
      otherwise: (schema) => 
        schema.notRequired().nullable()
    }),
    netPaymentTerms: Yup.number().when("overrideNetPaymentTerms", {
      is: true,
      then: (schema) =>
        schema
          .required("Net Payment Terms is required.")
          .test(
            "must-be-greater-than-0",
            "Net Payment terms must be greater than 0",
            function() {
              return this.parent.netPaymentTerms > 0;
            }
          ),
      otherwise: (schema) => schema.notRequired().nullable()
    })
  });

  return (
    <Formik
      enableReinitialize
      validationSchema={customerInfoSchema}
      initialValues={values}
      onSubmit={onSubmitHandler}
    >
      {(props) => (
        <Form noValidate>
          <Grid container>
            <Grid item container xs={12} spacing={2}>
              <Hidden smDown>
                <Grid item container xs={4}>
                  <Typography variant="h2">COMPANY INFORMATION</Typography>
                </Grid>
                <Grid item container xs={4} justifyContent="flex-end">
                  {signUpSetting && (
                    <>
                      {status === BusinessCustomerStatus.PENDING ? (
                        <div style={{ justifyContent: "end" }}>
                          <Fab
                            variant="extended"
                            size="medium"
                            style={{
                              backgroundColor: "#009951",
                              color: "white",
                              marginRight: 10
                            }}
                            onClick={() => {
                              handleApproveBusinessCustomer(
                                BusinessCustomerStatus.APPROVED
                              );
                            }}
                          >
                            APPROVE
                          </Fab>
                          <Fab
                            variant="extended"
                            size="medium"
                            style={{ backgroundColor: "red", color: "white" }}
                            onClick={() => {
                              setDeclineDialog(true);
                            }}
                          >
                            DECLINE
                          </Fab>
                        </div>
                      ) : status === BusinessCustomerStatus.APPROVED ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end"
                          }}
                        >
                          <Typography variant="body1">
                            Verification Status:
                            <span
                              style={{ fontWeight: "bold", color: "green" }}
                            >
                              {status}
                            </span>
                          </Typography>
                          <Fab
                            variant="extended"
                            size="medium"
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              margin: 10
                            }}
                            onClick={() => {
                              setDeclineDialog(true);
                            }}
                          >
                            DECLINE
                          </Fab>
                        </div>
                      ) : status === BusinessCustomerStatus.DECLINED ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end"
                          }}
                        >
                          <Typography variant="body1">
                            Verification Status:
                            <span style={{ fontWeight: "bold", color: "red" }}>
                              {status}
                            </span>
                          </Typography>
                          <Fab
                            variant="extended"
                            size="medium"
                            style={{
                              backgroundColor: "#009951",
                              color: "white",
                              margin: 10
                            }}
                            onClick={() => {
                              handleApproveBusinessCustomer(
                                BusinessCustomerStatus.APPROVED
                              );
                            }}
                          >
                            APPROVE
                          </Fab>
                          <IconButton
                            style={{
                              color: "black",
                              padding: 0,
                              paddingRight: 5,
                              marginLeft: 10
                            }}
                          >
                            <Tooltip
                              title={
                                <React.Fragment>
                                  <h2>Declined Reason:</h2>
                                  <Typography variant="body1">
                                    {reason}
                                  </Typography>
                                </React.Fragment>
                              }
                              arrow
                              placement={"right"}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </IconButton>
                        </div>
                      ) : null}
                    </>
                  )}
                </Grid>
                <Dialog
                  open={declineDialog}
                  onClose={handleDeclineDialogClose}
                  maxWidth={"sm"}
                  fullWidth
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Customer verification"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {"Are you sure want to decline customer?"}
                    </DialogContentText>
                    <InputField
                      style={{ width: "100%", marginTop: 20 }}
                      label="Notes"
                      name={"declinedReason"}
                      multiline
                      rows={5}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setReason(event.target.value);
                      }}
                      variant="outlined"
                      value={reason}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        verifyBusinessCustomer({
                          variables: {
                            status: BusinessCustomerStatus.DECLINED,
                            businessCustomerId: values.id,
                            reason
                          }
                        });
                        setDeclineDialog(false);
                      }}
                      disabled={approveCustomerLoading}
                      color="primary"
                      style={{
                        backgroundColor: "var(--theme-primary)",
                        color: "white",
                        marginBottom: "10px",
                        marginRight: 10
                      }}
                    >
                      {approveCustomerLoading && (
                        <CircularProgress
                          size={14}
                          style={{ color: "white", marginRight: "10px" }}
                        />
                      )}
                      {"Confirm"}
                    </Button>
                    <Button
                      onClick={handleDeclineDialogClose}
                      color="primary"
                      style={{
                        backgroundColor: "#828282",
                        color: "white",
                        marginBottom: "10px",
                        marginRight: "10px"
                      }}
                    >
                      {"Cancel"}
                    </Button>
                  </DialogActions>
                </Dialog>
                <Grid item container xs={4} justifyContent="flex-end">
                  <Grid item xs={4}>
                    {userState.currentTenancy.creditCheckEnabled && (
                      <Fab
                        variant="extended"
                        size="medium"
                        style={{ color: "white", marginRight: 10 }}
                        onClick={() => {
                          if (!values.registrationNumber) {
                            snackbar({
                              message:
                                "Please enter valid Company Registration Number to check credit score",
                              variant: SnackBarVariant.ERROR
                            });
                          } else {
                            if (!values.businessCreditData) {
                              getCreditScore();
                            }
                            setOpenCreditCheckDialog(true);
                          }
                        }}
                      >
                        CREDIT CHECK
                      </Fab>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {values && values.isClientExists && tenancy.loginEnabled && (
                      <Fab
                        variant="extended"
                        size="medium"
                        disabled={
                          values && values.credentialSent ? true : false
                        }
                        onClick={() => {
                          if (props.values.id) {
                            sendLoginCredential({
                              variables: {
                                businessCustomerId: props.values.id
                              }
                            });
                          }
                        }}
                      >
                        Send Credential
                      </Fab>
                    )}
                    {values && values.credentialSentOn && tenancy.loginEnabled && (
                      <div style={{ marginTop: 5 }}>
                        Credential sent on :
                        <b>
                          {getLocalizedDateFormat(
                            country,
                            values.credentialSentOn,
                            DATE_TYPE.CONDENSED
                          )}
                        </b>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item container xs={12}>
                  <Typography variant="h2">COMPANY INFORMATION</Typography>
                </Grid>
                <Grid container justifyContent="flex-start">
                  <Grid item container xs={6}>
                    {userState.currentTenancy.creditCheckEnabled && (
                      <Fab
                        variant="extended"
                        size="medium"
                        style={{ color: "white", marginRight: 10 }}
                        onClick={() => {
                          if (!values.businessCreditData) {
                            getCreditScore();
                          }
                          setOpenCreditCheckDialog(true);
                        }}
                      >
                        CREDIT CHECK
                      </Fab>
                    )}
                  </Grid>
                  {values && values.isClientExists && tenancy.loginEnabled && (
                    <Grid item container xs={6} justifyContent="flex-start">
                      <Fab
                        variant="extended"
                        size="medium"
                        disabled={
                          values &&
                          values.credentialSentOn &&
                          values.credentialSent
                            ? true
                            : false
                        }
                        onClick={() => {
                          if (props.values.id) {
                            sendLoginCredential({
                              variables: {
                                businessCustomerId: props.values.id
                              }
                            });
                          }
                        }}
                      >
                        Send Credential
                      </Fab>
                      {values && values.credentialSentOn && tenancy.loginEnabled && (
                        <div style={{ marginTop: 5 }}>
                          Credential sent on :{" "}
                          <b>
                            {getLocalizedDateFormat(
                              country,
                              values.credentialSentOn,
                              DATE_TYPE.CONDENSED
                            )}
                          </b>
                        </div>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Hidden>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name="businessName"
                  placeholder="Business Name"
                  label="Business Name"
                  value={props.values.businessName}
                  onChange={props.handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name="registrationNumber"
                  placeholder="Company Reg. Number"
                  label="Company Reg. Number"
                  value={props.values.registrationNumber}
                  onChange={props.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name="VAT"
                  placeholder={`${getLocalizedTaxSyntex(
                    country
                  )} Registration Number`}
                  label={`${getLocalizedTaxSyntex(
                    country
                  )} Registration Number`}
                  value={props.values.VAT}
                  onChange={props.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="h3">REGISTERED ADDRESS</Typography>
                </Grid>
                <Hidden smDown>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="h3">TRADING ADDRESS</Typography>
                  </Grid>
                </Hidden>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <AddressPicker
                    fieldName={"registeredAddress"}
                    required={true}
                    onChange={(address: IAddress) => {
                      if (address) {
                        setValues({ ...values, registeredAddress: address });
                      }
                    }}
                  />
                </Grid>
                <Hidden smUp>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="h3">TRADING ADDRESS</Typography>
                  </Grid>
                </Hidden>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  {!props.values.tradingAddressSame ? (
                    <AddressPicker
                      fieldName={"tradingAddress"}
                      required={true}
                      onChange={(address: IAddress) => {
                        if (address) {
                          setValues({ ...values, tradingAddress: address });
                        }
                      }}
                    />
                  ) : (
                    <InputField
                      placeholder="Trading Address"
                      label="Trading Address"
                      disabled
                      fullWidth
                      value={props.values.registeredAddress.fullAddress}
                    ></InputField>
                  )}
                </Grid>
                <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormGroup row className={classes.checkboxLabel}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.values.tradingAddressSame}
                          onChange={(e) => handleCheckbox(e, props.values)}
                          color="secondary"
                          name={"premium"}
                        />
                      }
                      label={"Use Registered Address"}
                      classes={{ label: classes.checkboxLabel }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="h3" gutterBottom>
                    BILLING INFORMATION
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name={"billing.phoneNumber.phone"}
                  required
                >
                  {({
                    form: { setFieldValue, setTouched, touched, errors },
                    meta
                  }: FieldProps) => {
                    return (
                      <div className={`phone-input-container`}>
                        <label
                          className={`${billingPhoneError ? "input-error-label" : ""
                          } phone-input-label`}
                        >
                          Billing Phone Number{" "}
                          <sup className={"phone-input-required"}>*</sup>
                        </label>
                        <PhoneInput
                          international={false}
                          defaultCountry={countryCode.billing}
                          placeholder="Enter phone number"
                          className={
                            billingPhoneError ? "phone-input-error" : ""
                          }
                          value={props.values.billing.phoneNumber.phone}
                          name={"billing.phoneNumber.phone"}
                          required
                          onChange={(val: any) => {
                            if (val && isValidPhoneNumber(val)) {
                              setBillingPhoneError("");
                            }
                            setValues({
                              ...props.values,
                              billing: {
                                ...props.values.billing,
                                phoneNumber: {
                                  ...props.values.billing.phoneNumber,
                                  phone: val
                                }
                              }
                            });
                          }}
                          onBlur={() => {
                            if (!props.values.billing.phoneNumber.phone) {
                              setBillingPhoneError("Phone number is required.");
                            } else if (
                              !isValidPhoneNumber(
                                props.values.billing.phoneNumber.phone
                              )
                            ) {
                              setBillingPhoneError(
                                "Enter a valid phone number."
                              );
                            } else {
                              setBillingPhoneError("");
                            }
                          }}
                          onCountryChange={(val) => {
                            setCountry({
                              ...countryCode,
                              billing: val
                            });
                          }}
                        />
                        {billingPhoneError !== "" ? (
                          <span className={"phone-error-message"}>
                            {billingPhoneError}
                          </span>
                        ) : (
                          meta.touched &&
                          meta.error && (
                            <span className={"phone-error-message"}>
                              {billingPhoneError || "Phone number is required."}
                            </span>
                          )
                        )}
                      </div>
                    );
                  }}
                </Field>
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name="billing.email"
                  placeholder="Billing Email Address"
                  label="Billing Email Address"
                  value={props.values.billing.email}
                  onChange={props.handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name="billing.accountNumber"
                  placeholder="Account Number"
                  label="Account Number"
                  value={props.values.billing.accountNumber || ""}
                  onChange={props.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="h3" gutterBottom>
                  CUSTOMER CONTACT
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name="contact.firstName"
                  placeholder="First Name"
                  label="First Name"
                  value={props.values.contact.firstName}
                  onChange={props.handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name="contact.lastName"
                  placeholder="Last Name"
                  label="Last Name"
                  value={props.values.contact.lastName}
                  onChange={props.handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name={"contact.phoneNumber.phone"}
                  required
                >
                  {({
                    form: { setFieldValue, setTouched, touched, errors },
                    meta
                  }: FieldProps) => {
                    return (
                      <div className={`phone-input-container`}>
                        <label
                          className={`${contactPhoneError ? "input-error-label" : ""
                          } phone-input-label`}
                        >
                          Contact Phone Number{" "}
                          <sup className={"phone-input-required"}>*</sup>
                        </label>
                        <PhoneInput
                          international={false}
                          defaultCountry={countryCode.contact}
                          placeholder="Enter phone number"
                          className={
                            contactPhoneError ? "phone-input-error" : ""
                          }
                          value={props.values.contact.phoneNumber.phone}
                          name={"contact.phoneNumber.phone"}
                          required
                          onChange={(val: any) => {
                            if (val && isValidPhoneNumber(val)) {
                              setContactPhoneError("");
                            }
                            setValues({
                              ...props.values,
                              contact: {
                                ...props.values.contact,
                                phoneNumber: {
                                  ...props.values.contact.phoneNumber,
                                  phone: val
                                }
                              }
                            });
                          }}
                          onBlur={() => {
                            if (!props.values.contact.phoneNumber.phone) {
                              setContactPhoneError("Phone number is required.");
                            } else if (
                              !isValidPhoneNumber(
                                props.values.contact.phoneNumber.phone
                              )
                            ) {
                              setContactPhoneError(
                                "Enter a valid phone number."
                              );
                            } else {
                              setContactPhoneError("");
                            }
                          }}
                          onCountryChange={(val) => {
                            setCountry({
                              ...countryCode,
                              contact: val
                            });
                          }}
                        />
                        {contactPhoneError ? (
                          <span className={"phone-error-message"}>
                            {contactPhoneError}
                          </span>
                        ) : (
                          meta.touched &&
                          meta.error && (
                            <span className={"phone-error-message"}>
                              {contactPhoneError || "Phone number is required."}
                            </span>
                          )
                        )}
                      </div>
                    );
                  }}
                </Field>
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  component={TextField}
                  name="contact.email"
                  placeholder="Email Address"
                  label="Email Address"
                  value={props.values.contact.email}
                  onChange={props.handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.taxExempted}
                        onChange={() => {
                          setValues({
                            ...props.values,
                            taxExempted: !props.values.taxExempted
                          });
                        }}
                        value="taxExempted"
                        color="secondary"
                      />
                    }
                    label={
                      <Typography variant={"h5"}>
                        {"Check this for VAT/TAX Exempt Business Customer"}
                      </Typography>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="h3" gutterBottom>
                  ACCOUNT MANAGER
                </Typography>
              </Grid>
              <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    component={TextField}
                    name={"accountManager"}
                    fullWidth
                    type="text"
                    select
                    label="Account Manager"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={typeof props.values.accountManager === 'string'
                      ? props.values.accountManager
                      : props.values.accountManager?.id || ""}
                    InputProps={{
                      onChange: (
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setValues({
                          ...values,
                          accountManager: event.target.value
                        });
                      },
                    }}
                  >
                    {accountManagersOptions.map(
                      (
                        item: IServiceOptionsMenu,
                        index: number
                      ) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        );
                      }
                    )}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item container xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h3" gutterBottom>
                        PAYMENT TERMS
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.invoiceDays}
                      justifyContent="space-between"
                    >
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.overrideNetPaymentTerms}
                              name="overrideNetPaymentTerms"
                              onClick={props.handleChange}
                              value="overrideNetPaymentTerms"
                            />
                          }
                          label="Override Net Payment Terms"
                        />
                      </Grid>
                      {props.values.overrideNetPaymentTerms && (
                        <Grid item xs={5}>
                          <FormControl variant="outlined" fullWidth>
                            <Field
                              component={InputField}
                              name="netPaymentTerms"
                              type="number"
                              placeholder="Enter Payment Terms in days"
                              label="Net Payment Terms (days)"
                              inputProps={{
                                name: "netPaymentTerms",
                                value: props.values.netPaymentTerms,
                                onChange: props.handleChange
                              }}
                              fullWidth
                              value={props.values.netPaymentTerms}
                              required={props.values.overrideNetPaymentTerms}
                              error={props.errors.netPaymentTerms}
                              helperText={props.errors.netPaymentTerms}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.invoiceDays}
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.overrideInvoiceDate}
                              onClick={props.handleChange}
                              name="overrideInvoiceDate"
                              value="overrideInvoiceDate"
                            />
                          }
                          label={`Preferred Invoicing Date for Monthly ${getLocalizedBookingSyntex(
                            country
                          )}s`}
                        />
                      </Grid>
                      {props.values.overrideInvoiceDate && (
                        <Grid item xs={4} justifyContent="flex-end">
                          <FormControl variant="outlined" fullWidth>
                            <Field
                              component={InputField}
                              name={"invoicingDay"}
                              fullWidth
                              type="number"
                              select
                              label="Select Date"
                              required={props.values.overrideInvoiceDate}
                              error={props.errors.invoicingDay}
                              inputProps={{
                                name: "invoicingDay",
                                value: props.values.invoicingDay,
                                onChange: props.handleChange
                              }}
                              helperText={props.errors.invoicingDay}
                              value={props.values.invoicingDay || ""}
                              placeholder="Select Date"
                            >
                              {monthDates.map((item: number) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.invoiceDays}
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.values.overrideWeeklyInvoiceDate}
                              onClick={props.handleChange}
                              name="overrideWeeklyInvoiceDate"
                              value="overrideWeeklyInvoiceDate"
                            />
                          }
                          label={`Preferred Invoicing Day for Weekly ${getLocalizedBookingSyntex(
                            country
                          )}s`}
                        />
                      </Grid>
                      {props.values.overrideWeeklyInvoiceDate && (
                        <Grid item xs={4} justifyContent="flex-end">
                          <FormControl variant="outlined" fullWidth>
                            <Field
                              component={InputField}
                              name={"weeklyInvoicingDay"}
                              fullWidth
                              type="number"
                              select
                              label="Select Day"
                              value={props.values.weeklyInvoicingDay || ""}
                              error={props.errors.weeklyInvoicingDay}
                              required={props.values.overrideWeeklyInvoiceDate}
                              inputProps={{
                                name: "weeklyInvoicingDay",
                                value: props.values.weeklyInvoicingDay,
                                onChange: props.handleChange
                              }}
                              placeholder="Select Day"
                              helperText={props.errors.weeklyInvoicingDay}
                            >
                              {weekdays.map((item: string, index: number) => (
                                <MenuItem key={item} value={index + 1}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {userState.currentTenancy.loginEnabled && (
                    <Grid item xs={6}>
                      <Typography variant="h3" gutterBottom>
                        CUSTOMER PORTAL TERMS
                      </Typography>
                      <Grid item container xs={12}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  props.values.requiresBookingApproval ? true : false
                                }
                                onClick={(e: any) => {
                                  props.setValues({
                                    ...props.values,
                                    requiresBookingApproval: e.target.checked
                                  });
                                }}
                                value="requiresBookingApproval"
                              />
                            }
                            label={`${getLocalizedBookingSyntex(
                              country
                            )}s by this customer from Customer Portal requires approval`}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item container xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.values.isRingFenced}
                      name={"isRingFenced"}
                      value={"isRingFenced"}
                      onChange={(event: any) => {
                        props.setFieldValue("isRingFenced", event.target.checked);
                      }}
                    />
                  }
                  label="Ring Fencing"
                />
              </Grid>
              <Grid item container>
                {props.values.isRingFenced && (
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      freeSolo
                      id="free-solo-vehicle-group-search"
                      disableClearable
                      options={vehicleGroups}
                      getOptionLabel={(option: any) => option.name}
                      defaultValue={[]}
                      filterSelectedOptions
                      value={selectedVehicleGroups}
                      PaperComponent={(paperProps) => CustomPaper(paperProps, props.values, props.setValues)}
                      onChange={(_event: any, value: any, reason: any) => {
                        if (reason === "clear" || reason === "removeOption") {
                          setSelectAll(false);
                          setSelectedVehicleGroups(value);
                          props.setValues({
                            ...props.values,
                            vehicleGroups: value
                          });
                          const removedVehicleGroup = selectedVehicleGroups.find(
                            (group: any) => !value.includes(group)
                          );
                          if (removedVehicleGroup) {
                            const updatedVehicleGroups = [...vehicleGroups];
                            if (
                              !updatedVehicleGroups.some(
                                (group: any) =>
                                  group.name === removedVehicleGroup.name
                              )
                            ) {
                              updatedVehicleGroups.push(removedVehicleGroup);
                            }
                            setVehicleGroups(updatedVehicleGroups);
                          }
                        }
                        if (reason === "selectOption") {
                          setSelectedVehicleGroups(value);
                          const updatedVehicleGroups = value.map((val: any) => {
                            const {
                              basePrices,
                              vehicles,
                              branchId,
                              ...rest
                            } = val;
                            return rest;
                          });
                          props.setValues({
                            ...props.values,
                            vehicleGroups: updatedVehicleGroups
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <InputField
                          {...params}
                          label="Select Vehicle Group(s)*"
                          variant="outlined"
                          placeholder="Select Vehicle Group(s)"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="h3" style={{ marginBottom: 20 }}>CUSTOMER SEGMENTATION</Typography>
                    <NuvvenAutoCompleteTags
                      handleTags={(tags: string[]) => {
                        props.setValues({
                          ...props.values,
                          tags: tags
                        });
                      }}
                      label="tags"
                      columnLarge={11}
                      values={props.values.tags ?? []}
                      customTags={false}
                      features={
                        userState.currentOrganisation &&
                        userState.currentOrganisation.businessCustomerTags
                          ? userState.currentOrganisation.businessCustomerTags
                          : []
                      }
                      singleLine
                      placeHolder="Tags"
                      freeSoloDisabled
                      onEnterPress="Enter"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Typography variant="h3" style={{ marginBottom: 20 }}>OVERRIDE SERVICE SCHEDULE</Typography>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        name={"overrideServiceScheduleByMonth"}
                        fullWidth
                        type="text"
                        select
                        label="By Duration"
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={values.overrideServiceScheduleByMonth || ""}
                        InputProps={{
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...values,
                              overrideServiceScheduleByMonth: parseInt(event.target.value)
                            });
                          },
                        }}
                      >
                        {serviceAnniversary.map(
                          (
                            item: IServiceOptionsMenu,
                            index: number
                          ) => {
                            return (
                              <MenuItem
                                key={index}
                                value={item.value}
                              >
                                {item.label}
                              </MenuItem>
                            );
                          }
                        )}
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="h3">CREDIT LIMIT</Typography>
              </Grid>
              <Grid item container xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.values.creditLimitEnabled}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        props.setValues({
                          ...props.values,
                          creditLimit: 0,
                          creditLimitEnabled: e.target.checked
                        });
                      }}
                    />
                  }
                  labelPlacement="end"
                  label="Enable Credit Limit"
                />
              </Grid>
              <Grid item container xs={4}>
                {props.values?.creditLimitEnabled && (
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="creditLimit"
                      placeholder="Enter Credit Limit"
                      label="Credit Limit"
                      value={props.values.creditLimit}
                      InputProps={{
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                          props.setValues({
                            ...props.values,
                            creditLimit: parseInt(e.target.value)
                          }),
                        value: props.values.creditLimit
                      }}
                      type="number"
                      inputProps={{
                        hasCurrencyPrefix: true,
                        allowNegative: false
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item container xs={12} alignItems="center" spacing={2}>
                <Grid item>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="add"
                    type="submit"
                    disabled={loading}
                  >
                    Save
                  </Fab>
                </Grid>
                <Grid item>{loading && <CircularProgress size={20} />}</Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ marginTop: "20px" }}
              >
                <Grid item container xs={12}>
                  <Typography variant="h3" gutterBottom>
                    NOTES
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Field
                    component={TextField}
                    placeholder="Note"
                    label="Note"
                    name={"note"}
                    fullWidth
                    multiline
                    row={3}
                    InputProps={{
                      onChange: (event: ChangeEvent<HTMLInputElement>) => {
                        setNote({
                          ...note,
                          description: event.target.value,
                          createdBy:
                            userState && userState.id ? userState.id : "",
                          createdDate: new Date().toISOString()
                        });
                      },
                      value: note?.description
                    }}
                    inputProps={{ maxLength: 300 }}
                    disabled={false}
                  ></Field>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Fab
                  variant="extended"
                  size="medium"
                  onClick={() => {
                    if (props.values.id) {
                      addBusinessCustomerNote({
                        variables: {
                          businessCustomerId: props.values.id,
                          note
                        }
                      });
                    }
                  }}
                  disabled={!note?.description}
                >
                  Add
                </Fab>
              </Grid>
              <Grid container item xs={12}>
                <Paper variant="outlined" style={{width:"100%"}}>
                  <NuvvenTable
                    title=""
                    rows={rows}
                    columns={noteColumns(country)}
                    options={options}
                    setSelection={() => { }}
                  />
                </Paper>
              </Grid>
              {openCreditCheckDialog && (
                <BusinessCreditCheckDialog
                  open={openCreditCheckDialog}
                  handleClose={() => {
                    setOpenCreditCheckDialog(false);
                  }}
                  businessCustomer={values}
                />
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
