import { gql } from "@apollo/client";

export const GET_VEHICLES_BY_CRITERIA = gql`
  query vehiclesByCriteria($vehicleCriteria: [VehicleCriteriaInput]!) {
    vehiclesByCriteria(vehicleCriteria: $vehicleCriteria) {
      id
      bodyType
      make
      model
      year
      licencePlate
      transmission
      colour
      steering
      fuelType
      features
      wheelPlan
      cylinderCapacity
      numberOfSeats
      numberOfDoors
    }
  }
`;
