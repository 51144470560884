import {
  Box,
  Fab,
  FormControl,
  Grid,
  Paper,
  Theme,
  Typography,
  Hidden,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field, FieldProps, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { DateTime as d } from "luxon"
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import {
  setBusinessApprovedDrivers,
  setBusinessCustomer,
  skipBusinessApprovedDrivers
} from "../../../../../../actions/customer/actions";
import { IBusinessCustomer, IBusinessCustomerApprovedDriverInput } from "../../../../../../reducers/customer/types";
import { IAppState } from "../../../../../../store";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  validateName
} from "../../../../../common/ValidationRules";
import FlatPickerBar from "../../../../../common/FlatPicker";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  getUploadedImageByKey,
  returnfileContentTypeAndExtention
} from "../../../../../common/utils";
import PhoneInput, { Value, isValidPhoneNumber } from "react-phone-number-input";
import { getDefaultCountryCode, getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import AddressPicker from "../../../NewCustomer/AddressPicker/AddressPicker";
import { IAddress } from "../../../../../../reducers/user/types";
import DragDropImages from "../../../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";
import { captureErrorException } from "../../../../../../utils/sentry";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxLabel: {
      fontSize: "16px !important"
    },
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    },
    authorsationTable: {
      display: "flex",
      width: "50%",
      border: "1px solid lightgrey",
      padding: 10,
      alignItems: "center",
      borderRadius: 4
    },
    backButton: {
      backgroundColor: "black",
      color: "white",
      marginRight: "20px"
    }
  })
);


interface IApprovedDriversProps {
  onPrevious(): void;
  onSubmit(): void;
}

export const NewApprovedDrivers = (props: IApprovedDriversProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const businessCustomer: IBusinessCustomer = useSelector(
    (state: IAppState) => state.customerReducer.businessCustomer
  );
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [values, setValues] = useState<IBusinessCustomerApprovedDriverInput>({
    firstName: "",
    lastName: "",
    phoneNumber: {
      phone: "" as Value,
      country: ""
    },
    email: "",
    nationalInsuranceNumber: "",
    profession: "",
    license: {
      licenseNumber: "",
      authority: "",
      dateOfExpiry: "",
      dateOfIssue: "",
      endorsement: "",
      images: []
    },
    location: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
  });
  const [licenseImages, setLicenseImages] = useState<string[]>([]);
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(
    false
  );
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<any>(getDefaultCountryCode(country));
  const [phoneError, setPhoneError] = useState<string>("");
  const [uploadedImages, setUploadedImages] = useState<object[]>([]);

  const uploadDocument = async (
    files: File[]
  ) => {
    try {
      if (uploadInProgress) {
        return snackbar({
          message: "Please wait image upload in progress!",
          variant: SnackBarVariant.ERROR
        });
      }
      const licenseImageArray = [];
      const showUploadedImages: any[] = [];
      if (licenseImages && licenseImages.length >= 2) {
        return snackbar({
          message: "Maximum two images can be uploaded for licence!",
          variant: SnackBarVariant.ERROR
        });
      }
      setUploadInProgress(true);
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        // 50MB limit exceeded
        if (file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
          const { fileExtension } = returnfileContentTypeAndExtention(file);
          const uniqueId = uuidv4();
          const key = `${uniqueId}.${fileExtension}`;
          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  snackbar({
                    message: "Licence Image Uploaded Successfully",
                    variant: SnackBarVariant.SUCCESS
                  });
                  const uploadedFile = await getUploadedImageByKey(key);
                  if (uploadedFile) {
                    showUploadedImages.push(uploadedFile);
                  }
                  setUploadInProgress(false);
                  setDocumentDialogVisible(false);
                }
              });
          }

          licenseImageArray.push(key);
        } else {
          setDocumentDialogVisible(true);
          setUploadInProgress(false);
          return snackbar({
            message: "Please only upload .jpeg/.jpg/.png file!",
            variant: SnackBarVariant.ERROR
          });
        }
      }
      setLicenseImages(licenseImageArray);
      setUploadedImages(showUploadedImages);
    } catch (err: any) {
      snackbar({
        message: formatGraphQLErrorMessage(err.message),
        variant: SnackBarVariant.ERROR
      });
    }
  };

  const onDeleteSelectedImage = (deletedFileObject: File) => {
    const images = licenseImages;
    const previewImages = uploadedImages;
    const deletedImageIndex = images.findIndex((image) => image === deletedFileObject.name);
    if (deletedImageIndex) {
      images.splice(deletedImageIndex, 1);
      previewImages.splice(deletedImageIndex, 1);
    }
    setLicenseImages([...images]);
    setUploadedImages([...previewImages]);
  }

  const submitHandler = (values: IBusinessCustomerApprovedDriverInput, actions: any) => {
    const approvedDriver = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: {
        phone: values.phoneNumber.phone,
        country: countryCode
      },
      email: values.email,
      nationalInsuranceNumber: values.nationalInsuranceNumber,
      profession: values.profession,
      license: {
        licenseNumber: values.license.licenseNumber,
        authority: values.license.authority,
        dateOfExpiry: values.license.dateOfExpiry,
        endorsement: "",
        images: [...licenseImages]
      },
      location: {
        city: values.location.city,
        country: values.location.country,
        fullAddress: values.location.fullAddress,
        state: values.location.state,
        street: values.location.street,
        zipcode: values.location.zipcode
      }
    };
    if (values.location && values.location.fullAddress) {
      if (!(values.location.street || values.location.zipcode)) {
        return snackbar({
          message: "Street / Zipcode is required",
          variant: SnackBarVariant.ERROR
        });
      }
    }
    dispatch(setBusinessApprovedDrivers(approvedDriver));
    setLicenseImages([]);
    actions.setSubmitting(false);
    setValues({
      ...values,
      firstName: "",
      lastName: "",
      email: "",
      nationalInsuranceNumber: "",
      profession: "",
      phoneNumber: {
        phone: "" as Value,
        country: ""
      },
      license: {
        ...values.license,
        licenseNumber: "",
        authority: "",
        dateOfExpiry: ""
      },
      location: {
        ...values.location,
        street: "",
        country: "",
        state: "",
        fullAddress: "",
        zipcode: "",
        city: ""
      }
    });
  };

  const proceedHadler = () => {
    props.onSubmit();
  };

  const skipAndProceed = () => {
    dispatch(skipBusinessApprovedDrivers());
    props.onSubmit();
  };
  const handlePrevious = () => {
    props.onPrevious();
  };

  const removeApprovedDriverHandler = (index: number) => {
    businessCustomer.approvedDrivers.splice(index, 1);
    dispatch(setBusinessCustomer(businessCustomer));
  };

  const approvedDriverSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required.")
    }),
    email: Yup.string()
      .required("Email is required.")
      .email("Please enter a valid email address."),
  });

  const isDisabled = businessCustomer.approvedDrivers.length > 0;
  return (
    <Paper className={classes.root}>
      <Formik
        enableReinitialize
        validationSchema={approvedDriverSchema}
        initialValues={values}
        onSubmit={submitHandler}
      >
        {(props) => (
          <Form noValidate>
            <Grid container>
              <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    ADD AND EDIT APPROVED DRIVERS
                  </Typography>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="firstName"
                    placeholder="First Name"
                    label="First Name"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          firstName: e.target.value
                        })
                      },
                      value: props.values.firstName,
                      maxLength: 50
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="lastName"
                    placeholder="Last Name"
                    label="Last Name"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          lastName: e.target.value
                        })
                      },
                      value: props.values.lastName,
                      maxLength: 50
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="email"
                    placeholder="Email"
                    label="Email"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          email: e.target.value
                        })
                      },
                      value: props.values.email,
                      maxLength: 50
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name={"phoneNumber.phone"}
                    required
                  >
                    {({ form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                      return (
                        <div className={`phone-input-container`}>
                          <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                            Phone Number <sup className={"phone-input-required"}>*</sup>
                          </label>
                          <PhoneInput
                            international={false}
                            defaultCountry={countryCode}
                            placeholder="Enter phone number"
                            className={phoneError ? "phone-input-error" : ""}
                            value={props.values.phoneNumber.phone}
                            name={"phoneNumber.phone"}
                            required
                            onChange={(val: any) => {
                              if (val && isValidPhoneNumber(val)) {
                                setPhoneError("");
                              }
                              setValues({
                                ...props.values,
                                phoneNumber: {
                                  ...props.values.phoneNumber,
                                  phone: val
                                }
                              });
                            }}
                            onBlur={() => {
                              if (!props.values.phoneNumber.phone) {
                                setPhoneError("Phone number is required.");
                              } else if (!isValidPhoneNumber(props.values.phoneNumber.phone)) {
                                setPhoneError("Enter a valid phone number.");
                              } else {
                                setPhoneError("");
                              }
                            }}
                            onCountryChange={(val) => {
                              setCountryCode(val);
                            }}
                          />
                          {
                            phoneError !== "" ?
                              <span className={"phone-error-message"}>{phoneError}</span>
                              : (meta.touched && meta.error) &&
                              <span className={"phone-error-message"}>
                                {phoneError || "Phone number is required."}
                              </span>
                          }
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="National Insurance Number"
                    label="National Insurance Number"
                    name={"nationalInsuranceNumber"}
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          nationalInsuranceNumber: e.target.value
                        })
                      },
                      value: props.values.nationalInsuranceNumber,
                      maxLength: 20
                    }}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Profession"
                    label="Profession"
                    name={"profession"}
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          profession: e.target.value
                        })
                      },
                      value: props.values.profession,
                      maxLength: 30
                    }}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Driving License Number"
                    label="Driving License Number"
                    name={"license.licenseNumber"}
                    inputProps={{
                      onchange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        const license = {
                          ...props.values.license,
                          licenseNumber: e.target.value
                        };
                        setValues({
                          ...props.values,
                          license: license
                        })
                      },
                      value: props.values.license?.licenseNumber,
                      maxLength: 20
                    }}
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    placeholder="Licence Issued By"
                    label="Licence Issued By"
                    name={"license.authority"}
                    inputProps={{
                      onchange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        const license = {
                          ...props.values.license,
                          authority: e.target.value
                        };
                        setValues({
                          ...props.values,
                          license: license
                        })
                      },
                      value: props.values.license.authority,
                      maxLength: 100
                    }}
                    validate={(value: string) =>
                      validateName(value, "Licence Issued By")
                    }
                    fullWidth
                  ></Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormControl variant="outlined" fullWidth>
                    <FlatPickerBar
                      enableTime={false}
                      handleDateChange={(value: Date) => {
                        const license = {
                          ...props.values.license,
                          dateOfExpiry: d.fromJSDate(value).toUTC().toISO()
                        };
                        props.setFieldValue("license", license);
                      }}
                      label={"Driving Licence Expiry Date"}
                      identifier={"dateOfExpiry"}
                      placeholderValue={"Driving Licence Expiry Date"}
                      value={getLocalizedDateFormat(country, props.values.license.dateOfExpiry, DATE_TYPE.CONDENSED)}
                      minDate={getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}
                      country={country}
                    />
                  </FormControl>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <AddressPicker
                    fieldName={"location"}
                    onChange={(address: IAddress) => {
                      if (address) {
                        setValues({ ...props.values, location: address })
                      }
                    }}
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Fab
                    className="blackBackButton"
                    variant="extended"
                    size="medium"
                    aria-label="Update"
                    onClick={() => {
                      setDocumentDialogVisible(true);
                    }}
                  >
                    UPLOAD LICENCE IMAGE
                  </Fab>
                  <Grid item>
                    <Typography variant="h4">
                      Upload front and back image of Licence. Supported file format: .png,
                      .jpeg, .jpg!
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Fab
                    className="blackBackButton"
                    variant="extended"
                    size="medium"
                    type="submit"
                    aria-label="add"
                    disabled={!values.phoneNumber || !isValidPhoneNumber}
                  >
                    ADD
                  </Fab>
                </Grid>
                {businessCustomer.approvedDrivers.length > 0 &&
                  businessCustomer.approvedDrivers.map(
                    (signatory: any, index: number) => (
                      <Grid item container xs={12} key={index}>
                        <Hidden smDown>
                          <div className={classes.authorsationTable}>
                            <Grid container item xs={6}>
                              <Typography variant="body1">
                                {" "}
                                {signatory.firstName} {signatory.lastName}{" "}
                              </Typography>
                            </Grid>
                            <Grid container item xs={6} justifyContent="flex-end">
                              <Typography>
                                {" "}
                                <Fab
                                  variant="extended"
                                  size="medium"
                                  onClick={() =>
                                    removeApprovedDriverHandler(index)
                                  }
                                >
                                  <DeleteIcon />
                                </Fab>{" "}
                              </Typography>
                            </Grid>
                          </div>
                        </Hidden>

                        <Hidden smUp>
                          <div className={classes.authorsationTable}>
                            <Grid container item xs={10}>
                              <Typography variant="body1">
                                {" "}
                                {signatory.firstName} {signatory.lastName}{" "}
                              </Typography>
                            </Grid>
                            <Grid container item xs={2} justifyContent="flex-end">
                              <Typography>
                                {" "}
                                <Fab
                                  variant="extended"
                                  size="medium"
                                  onClick={() =>
                                    removeApprovedDriverHandler(index)
                                  }
                                >
                                  <DeleteIcon />
                                </Fab>{" "}
                              </Typography>
                            </Grid>
                          </div>
                        </Hidden>
                      </Grid>
                    )
                  )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <div>
        <DragDropImages
          open={documentDialogVisible}
          handleCloseDragDrop={() => setDocumentDialogVisible(false)}
          handleSave={uploadDocument}
          initialFiles={uploadedImages}
          filesLimit={2}
          title={"Upload Licence Images"}
        />
      </div>
      <Box mt={2}></Box>
      <Hidden smDown>
        <Grid container item xs={12}>
          <Fab
            className="blackBackButton"
            variant="extended"
            size="medium"
            onClick={handlePrevious}
          >
            BACK
          </Fab>
          <Box color="white" sx={{ pr: 2 }}></Box>
          <Fab
            variant="extended"
            size="medium"
            aria-label="add"
            disabled={!isDisabled}
            onClick={proceedHadler}
          >
            PROCEED
          </Fab>
          <Box color="white" sx={{ pr: 2 }}></Box>
          <Fab
            variant="extended"
            size="medium"
            aria-label="add"
            onClick={skipAndProceed}
          >
            Skip and Proceed
          </Fab>
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Fab
              className="blackBackButton"
              variant="extended"
              size="medium"
              onClick={handlePrevious}
            >
              BACK
            </Fab>
            <Box color="white" sx={{ pr: 2 }}></Box>
          </Grid>
          <Grid item xs={6}>
            <Fab
              variant="extended"
              size="medium"
              aria-label="add"
              disabled={!isDisabled}
              onClick={proceedHadler}
            >
              PROCEED
            </Fab>
            <Box color="white" sx={{ pr: 2 }}></Box>
          </Grid>
          <Grid item xs={6}>
            <Fab
              variant="extended"
              size="medium"
              aria-label="add"
              onClick={skipAndProceed}
            >
              Skip and Proceed
            </Fab>
          </Grid>
        </Grid>
      </Hidden>
    </Paper >
  );
};
