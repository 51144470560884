import "./index.scss";
import { SelectableRows } from "./../../../common/NuvvenTable/types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Theme,
  Box,
  CircularProgress,
  CssBaseline,
  Fab,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  AppBar,
  InputBase,
  alpha,
  Button,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import SearchIcon from '@mui/icons-material/Search';
import { get } from "lodash";
import { Display, MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import React, { useEffect, useState } from "react";
import papa from "papaparse";
import { connect, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import _ from "lodash";
import { DateTime as d } from "luxon";
import { initialState as fleetInitialState } from "../../../../reducers/fleet/const";
import { addTableState } from "../../../../actions/tableState/actions";
import { DELETE_VEHICLE } from "../../../../graphql/fleet/deleteVehicleMutation";
import { ITable, TableNames } from "../../../../reducers/tableState/types";
import {
  DateTimeFormat,
  DocumentTypeValue,
  formatGraphQLErrorMessage,
  globalVehicleCsvHeaders,
  ICsvUploadError,
  possibleCsvMimeTypes,
  returnSortedOrder,
  setTableSortOrder,
  vehicleCsvHeaders
} from "../../../common/utils";
import { GET_DEFLEETED_VEHICLES, GET_DEFLEETED_VEHICLES_COUNT, GET_VEHICLES, GET_VEHICLES_COUNT, GET_VEHICLES_INVENTORY, SEARCH_VEHICLES } from "../Graphql/getVehiclesQuery";
import {
  resetVehicle,
  updateVehicle
} from "./../../../../actions/fleet/vehicle/actions";
import { UNLOCK_VEHICLE } from "./../../../../graphql/fleet/unlockVehicleMutation";
import { getVehicle } from "./../../../../reducers/fleet/selectors";
import { FleetPayloadType, IVehicle, IVehicleDocument } from "./../../../../reducers/fleet/types";
import { IAction, IAppState } from "./../../../../store";
import ConfirmDeleteModal from "./../../../common/ConfirmDeleteModal";
import { NuvvenTable } from "./../../../common/NuvvenTable/NuvvenTable";
import { VehicleColumn } from "./VehicleColumn";
import { BulkCsvUploadDialog } from "../../../common/BulkCsvUploadDialog/BulkCsvUploadDialog";
import { DVLA_SEARCH } from "../../../../graphql/fleet/dvlaSearchQuery";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { ApolloError } from "@apollo/client";
import { CREATE_VEHICLES } from "../../../../graphql/fleet/createVehiclesMutation";
import { VehicleStatus, ResponseStatus, VehicleInfoSource, validFuelTypes } from "../utils";
import { CsvUploadStatusType } from "../../../common/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { GET_VEHICLES_BY_STATUS } from "../../../../graphql/fleet/getVehiclesByStatus";
import VehicleInventoryVirtualScroller from "./VehicleInventoryVirtualScroller";
import { VehicleBodyTypes } from "../../../common/VehicleDamagePreview/frogDiagram";
import { captureErrorException } from "../../../../utils/sentry";
import InputField from "../../../common/InputField";
import { FilePicker } from "../../../common/FilePicker/FilePicker";
import { UPLOAD_TRAILERS } from "../../../../graphql/fleet/uploadTrailerVehicles";
import { UserRoles } from "../../../hoc/Authorization";
interface IDvalVehicleUploadInput {
  registrationNo: string;
  frogDiagram: string;
  deFleetParameters: {
    byDistanceDriven: number;
    byDate: string;
    alertBeforeDistance: number;
    alertBeforeDays: number;
  }
  regularServiceSchedule: {
    byDistance: number;
    byMonth: number;
  }
}

interface IFilter {
  key: string;
  value: string;
}

const UseStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerMargin: {
      marginBottom: "120px"
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(100vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
      marginBottom: theme.spacing(1)
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    }
  })
);

interface IVehicleProps {
  history: any;
  vehicle: IVehicle;
  resetVehicle(): void;
  updateVehicle(payload: FleetPayloadType): void;
}
interface ICsvUploadErrors {
  licencePlate: string;
  error: number;
}

interface ICsvGlobalVehilcleError {
  index: number;
  message: string;
}

export function isValidDate(dateString: string, country: string): boolean {
  const date = country === "United States" ? d.fromFormat(dateString, DateTimeFormat.US_DATE_CONDENSED) : d.fromFormat(dateString, DateTimeFormat.DEFAULT_DATE_CONDENSED);
  return date.isValid;
};

const frogDiagramList = Object.values(VehicleBodyTypes)
export function VehicleInventory(props: any) {
  const navigate = useNavigate();

  const snackbar = useSnackBar();
  const location = useLocation();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { locale } = userState.currentOrganisation;
  const [selection, setSelection] = useState<any[]>([]);
  const [unlockButtonVisible, setUnlockButtonVisible] = useState<boolean>(
    false
  );
  const [isBulkCsvOpen, setIsBulkCsvOpen] = useState<boolean>(false);
  const [openTrailerUpload, setOpenTrailerUpload] = useState<boolean>(false);
  const [fileValue, setFileValue] = useState<File>();
  const [fileName, setFileName] = useState<string>("");
  const [uploadStatus, setUploadStatus] = useState<number>(
    CsvUploadStatusType.DEFAULT
  );
  const [csvErrors, setCsvErrors] = useState<ICsvUploadErrors[]>([]);
  const [csvGlobalVehicleErrors, setCsvGlobalVehicleErrors] = useState<
    ICsvGlobalVehilcleError[]
  >([]);

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [vehicleInventoryTable, setvehicleInventoryTable] = useState<ITable>();
  const [rows, setRows] = useState<IVehicle[]>([]);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [totalVehiclesCount, setTotalVehiclesCount] = useState<number>(0);
  const [filterParam, setFilterParam] = useState<string>("");
  const [statusParam, setStatusParam] = useState<string>("");
  const [documentStatus, setDocumentStatus] = useState<string>("");
  const [filterLoading, setFilterLoading] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<IFilter[]>([]);
  const [filtering, setIsFiltering] = useState<boolean>(false);
  const [isDefleetedVehicles, setIsDefleetedVehicles] = useState<boolean>(false)


  const vehicleInventoryTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.vehicleInventory
  );

  const [getVehicles, { loading, data }] = useLazyQuery(GET_VEHICLES_INVENTORY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.vehiclesInventory) {
        let oldVehicles = [...rows]
        let vehicles = data.vehiclesInventory;
        const vehicleArr = vehicles.map((vehicle: IVehicle) =>
          reshapeVehicleIntoRow(vehicle)
        );
        vehicleArr.forEach((vehicle: any) => {
          if (!oldVehicles.some((item) => item.id === vehicle.id)) {
            oldVehicles.push(vehicle)
          }
        });
        setRows(oldVehicles)
      }
      props.resetVehicle();
      setFilterLoading(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [getDefleetedVehicles, { loading: defleetedVehiclesLoading }] = useLazyQuery(GET_DEFLEETED_VEHICLES, {
    fetchPolicy: "network-only",
    onCompleted: (defleetData: any) => {
      if (defleetData && defleetData.getDefleetedVehicles) {
        let oldVehicles = [...rows]
        let vehicles = defleetData.getDefleetedVehicles;
        const vehicleArr = vehicles.map((vehicle: IVehicle) =>
          reshapeVehicleIntoRow(vehicle)
        );
        vehicleArr.forEach((vehicle: any) => {
          if (!oldVehicles.some((item) => item.id === vehicle.id)) {
            oldVehicles.push(vehicle)
          }
        });
        setRows(oldVehicles)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  })

  const [getDeFleetedVehiclesCount, { data: deFleetedVehiclesCountData }] = useLazyQuery(
    GET_DEFLEETED_VEHICLES_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (defleetedVehiclesCountData) => setTotalVehiclesCount(defleetedVehiclesCountData.deFleetedVehiclesCount),
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
      }
    }
  );

  const [getVehiclesByStatus, { loading: vehicleByStatusLoading }] = useLazyQuery(GET_VEHICLES_BY_STATUS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.getVehiclesByStatus) {
        setRows(
          data.getVehiclesByStatus.map((vehicle: any) => reshapeVehicleIntoRow(vehicle))
        );
        setTotalVehiclesCount(data.getVehiclesByStatus.length)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [deleteVehicle] = useMutation(DELETE_VEHICLE, {
    update: (proxy, { data: { deleteVehicle } }) => {
      const result: any = proxy.readQuery({ query: GET_VEHICLES });
      const vehicles = result.vehicles.filter(
        (vehicle: any) => vehicle.id !== deleteVehicle
      );
      proxy.writeQuery({
        data: { vehicles },
        query: GET_VEHICLES
      });
    }
  });

  const [getVehiclesCount, { data: vehiclesCountData }] = useLazyQuery(
    GET_VEHICLES_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (vehiclesCountData) => setTotalVehiclesCount(vehiclesCountData.vehiclesCount)
    }
  );

  const [searchVehicles, { loading: searchVehicleLoading, data: searchVehicleData }] = useLazyQuery(
    SEARCH_VEHICLES,
    {
      fetchPolicy: "network-only",
      onCompleted: (searchVehicleData) => {
        if (searchVehicleData && searchVehicleData.searchVehicles) {
          const newVehicles = searchVehicleData.searchVehicles.map((vehicle: IVehicle) =>
            reshapeVehicleIntoRow(vehicle)
          );
          setTotalVehiclesCount(newVehicles.length)
          setRows(newVehicles);
        }
      }
    }
  );

  const [unlockAVehicle] = useMutation(UNLOCK_VEHICLE, {
    update: () => {
      getVehiclesCount();
      getVehicles({
        variables: {
          limit: vehicleInventoryTableState?.rowsPerPage ? vehicleInventoryTableState?.rowsPerPage : limit,
          offset: 0
        }
      });
      setRows(
        data.vehicles.map((vehicle: any) => reshapeVehicleIntoRow(vehicle))
      );
    }
  });

  const [searchDvla, { data: dvlaData }] = useLazyQuery(DVLA_SEARCH, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && !data.dvlaSearch.length) {
        setUploadStatus(CsvUploadStatusType.ERROR);
        snackbar({
          message: "No new vehicles created",
          variant: SnackBarVariant.WARNING
        });
      }
      setFileName("");
      setFileValue(undefined);
      setUploadStatus(CsvUploadStatusType.DEFAULT);
    },
    onError: (error: ApolloError) => {
      setFileName("");
      setFileValue(undefined);
      setUploadStatus(CsvUploadStatusType.DEFAULT);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [createVehicles] = useMutation(CREATE_VEHICLES, {
    onError: (error: ApolloError) => {
      setFileName("");
      setFileValue(undefined);
      setUploadStatus(CsvUploadStatusType.DEFAULT);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    },
    onCompleted: (data) => {
      if (
        data.createVehicles &&
        data.createVehicles.errors &&
        data.createVehicles.errors.length
      ) {
        setFileName("");
        setFileValue(undefined);
        setCsvGlobalVehicleErrors(data.createVehicles.errors);
        return setUploadStatus(CsvUploadStatusType.ERROR);
      }
      if (csvErrors.length) {
        setUploadStatus(CsvUploadStatusType.ERROR);
      } else {
        setUploadStatus(CsvUploadStatusType.SUCCESS);
        setIsBulkCsvOpen(false);
      }
      snackbar({
        message: "New Vehicles Created",
        variant: SnackBarVariant.SUCCESS
      });
      setIsBulkCsvOpen(false);
      getVehiclesCount();
      getVehicles({
        variables: {
          limit: vehicleInventoryTableState?.rowsPerPage ? vehicleInventoryTableState?.rowsPerPage : limit,
          offset: 0
        }
      });
      setFileName("");
      setFileValue(undefined);
      setUploadStatus(CsvUploadStatusType.DEFAULT);
    },
    update: (proxy, { data: { createVehicles } }) => {
      if (createVehicles.length === 0) {
        return;
      }
      let result: any = proxy.readQuery({
        query: GET_VEHICLES
      });

      if (createVehicles && createVehicles.length > 0) {
        let vehicles = createVehicles
        vehicles = vehicles.map((vehicle: IVehicle) => {
          return {
            ...vehicle,
            bookingSchedules: [],
            status: VehicleStatus.ACTIVE
          };
        });
        if (!result.vehicles) {
          proxy.writeQuery({
            data: { vehicles: vehicles },
            query: GET_VEHICLES
          });
        } else {
          proxy.writeQuery({
            data: { vehicles: result.vehicles.concat(vehicles) },
            query: GET_VEHICLES
          });
        }

        result = proxy.readQuery({ query: GET_VEHICLES });
      }
    }
  });

  const [uploadTrailersVehicles] = useMutation(UPLOAD_TRAILERS, {
    onError: (error: ApolloError) => {
      setFileName("");
      setFileValue(undefined);
      setUploadStatus(CsvUploadStatusType.DEFAULT);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    },
    onCompleted: (data) => {
      if (
        data.uploadTrailers &&
        data.uploadTrailers.errors &&
        data.uploadTrailers.errors.length
      ) {
        setFileName("");
        setFileValue(undefined);
        setCsvGlobalVehicleErrors(data.uploadTrailers.errors);
        return setUploadStatus(CsvUploadStatusType.ERROR);
      }
      if (csvErrors.length) {
        setUploadStatus(CsvUploadStatusType.ERROR);
      } else {
        setUploadStatus(CsvUploadStatusType.SUCCESS);
        setIsBulkCsvOpen(false);
      }
      snackbar({
        message: "New Vehicles Created",
        variant: SnackBarVariant.SUCCESS
      });
      setOpenTrailerUpload(false);
      setIsBulkCsvOpen(false);
      getVehiclesCount();
      getVehicles({
        variables: {
          limit: vehicleInventoryTableState?.rowsPerPage ? vehicleInventoryTableState?.rowsPerPage : limit,
          offset: 0
        }
      });
      setFileName("");
      setFileValue(undefined);
      setUploadStatus(CsvUploadStatusType.DEFAULT);
    }
  });

  const filterVehicles: any = ["All"];
  let columns: any = [
    {
      label: "Vehicle",
      name: "vehicle",
      options: {
        filterType: "custom",
        display: true,
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any) => {
          filterVehicles.push(
            value.year ? value.year.toString() : "",
            value.make,
            value.licencePlate
          );
          return <VehicleColumn vehicle={value} />;
        },
        filterList: [],
        customFilterListOptions: {
          render: (item: string) => item,
          update: (filterList: any[], filterPos: number, index: number) => {
            if (filterPos === 0) {
              filterList[index] = [];
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          }
        },
        filterOptions: {
          logic: (data: any[], filters: string[]) => {
            const newData =
              data &&
              Object.values(data).map((item: any) => {
                if (typeof item === "number") {
                  return item.toString();
                }
                return item;
              });
            if (filters.length) {
              return !newData.includes(filters);
            }
            return false;
          },
          display: (
            filterList: any[],
            onChange: Function,
            index: number,
            column: any[]
          ) => {
            const optionValues =
              filterVehicles &&
              filterVehicles.filter((item: string, key: number) => {
                return filterVehicles.indexOf(item) === key;
              });
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Vehicle</InputLabel>
                <Select
                  variant="standard"
                  value={filterList[index].length ? filterList[index] : "All"}
                  onChange={(event) => {
                    if (event.target.value === "All") {
                      filterList[index] = [];
                      onChange(filterList[index], index, column);
                    } else {
                      filterList[index] = event.target.value;
                      onChange(filterList[index], index, column);
                    }
                  }}
                >
                  {optionValues &&
                    optionValues.map((item: string, key: number) => (
                      <MenuItem key={key} value={item}>
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            );
          }
        }
      }
    },
    {
      label: "Body Type",
      name: "bodyType"
    },
    {
      label: "Status",
      name: "status"
    },
    {
      label: "Fuel Type",
      name: "fuelType"
    },
    {
      label: "MOT Status",
      name: "motStatus"
    },
    {
      label: "MOT Expiry",
      name: "motExpiry",
      options: {
        display: (vehicleInventoryTableState?.viewColumns?.motExpiry === "true")
      }
    },
    {
      label: "PCO Expiry",
      name: "pcoExpiry",
      options: {
        display: (vehicleInventoryTableState?.viewColumns?.pcoExpiry === "true"),
      }
    },
    {
      label: "Insurance Status",
      name: "insuranceStatus"
    },
    {
      label: "Tax Status",
      name: "taxStatus"
    },
    {
      label: "Tax Date",
      name: "taxDetails"
    },
    {
      label: "Features",
      name: "features",
      options: {
        display: false,
        viewColumns: false
      }
    },
    {
      label: "De-Fleet status",
      name: "deFleetStatus",
      options: {
        display: false,
        viewColumns: false,
        filter: isDefleetedVehicles ? true : false
      }
    }
  ];

  if (country === "United States") {
    const excludedColumns = ["taxStatus", "taxDetails", "motStatus", "motExpiry"];
    columns = columns.filter((column: any) => !excludedColumns.includes(column.name));
  }

  useEffect(() => {
    const isDefleetedVehiclesPage = location.pathname === '/defleeted-vehicles';
    const isVehicleInventoryPage = location.pathname === '/vehicle-inventory';

    if (isVehicleInventoryPage) {
      setIsDefleetedVehicles(false);
    }
    setRows([])
    if (isDefleetedVehiclesPage) {
      setIsDefleetedVehicles(true)
      getDeFleetedVehiclesCount()
      getDefleetedVehicles({
        variables: {
          limit: vehicleInventoryTableState?.rowsPerPage ? vehicleInventoryTableState?.rowsPerPage : limit,
          offset: 0
        }
      })
    } else {
      if (location && location.search) {
        const params = new URLSearchParams(location.search);
        const status = params.get("status");
        const filterParam = params.get("filter");
        const documentstatus = params.get("documentstatus");
        if (documentstatus) {
          setDocumentStatus(documentstatus);
        }
        if (filterParam) {
          setFilterLoading(true);
          setTotalVehiclesCount(location.state.length);
          getVehicles({
            variables: {
              limit: location.state.length,
              offset: 0,
              ids: location.state
            }
          });
          setFilterParam(filterParam)
        } else if (status) {
          setStatusParam(status)
          getVehiclesByStatus({
            variables: {
              status
            }
          })
        } else {
          getVehiclesCount();
          getVehicles({
            variables: {
              limit: vehicleInventoryTableState?.rowsPerPage ? vehicleInventoryTableState?.rowsPerPage : limit,
              offset: 0
            }
          });
        }
      } else {
        getVehiclesCount();
        getVehicles({
          variables: {
            limit: vehicleInventoryTableState?.rowsPerPage ? vehicleInventoryTableState?.rowsPerPage : limit,
            offset: 0
          }
        });
      }
    }
  }, [location, userState]);

  useEffect(() => {
    if (vehicleInventoryTableState) {
      setvehicleInventoryTable(vehicleInventoryTableState);
      let sortedOrder = returnSortedOrder(vehicleInventoryTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, vehicleInventoryTableState, sortedOrder);
      }
    }
  }, [vehicleInventoryTableState]);

  useEffect(() => {
    if (!isBulkCsvOpen) {
      setCsvErrors([]);
      setCsvGlobalVehicleErrors([]);
      setFileValue(undefined);
      setFileName("");
    }
  }, [isBulkCsvOpen]);

  useEffect(() => {
    if (selection && selection.length === 1) {
      if (
        selection[0].vehicle.bookingSchedules !== null &&
        selection[0].vehicle.bookingSchedules.length > 0
      ) {
        setUnlockButtonVisible(true);
      }
    } else {
      setUnlockButtonVisible(false);
    }
  }, [selection]);


  useEffect(() => {
    if (dvlaData && dvlaData.dvlaSearch && dvlaData.dvlaSearch.length) {
      const errors: ICsvUploadErrors[] = [];
      let vehicles = dvlaData.dvlaSearch.filter((vehicle: any) => {
        if (vehicle.responseStatus !== ResponseStatus.VEHICLE_FOUND) {
          errors.push({
            licencePlate: vehicle.licencePlate,
            error: vehicle.responseStatus
          });
          return false;
        } else {
          return true;
        }
      });
      setCsvErrors(errors);
      vehicles = vehicles.map((vehicle: any) => {
        const { deleted, responseStatus, id, status, bookingSchedules, ...rest } = vehicle
        return {
          ...rest,
          infoSource: VehicleInfoSource.DVLA,
          branch: userState.currentBranch.id
        }
      });
      if (vehicles && vehicles.length) {
        createVehicles({
          variables: {
            vehicles
          }
        });
      } else {
        setUploadStatus(CsvUploadStatusType.ERROR);
        snackbar({
          message: "No new vehicles created",
          variant: SnackBarVariant.WARNING
        });
      }
    }
  }, [dvlaData]);

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchVehicles({
        variables: {
          q: searchText
        }
      })
    }
  }, 1000);

  function reshapeVehicleIntoRow(vehicle: IVehicle) {
    let testDueDate = vehicle.vehicleTestDueDate;
    if (testDueDate && !d.fromISO(testDueDate).isValid) {
      testDueDate = d.fromFormat(testDueDate, "d MMMM yyyy").toISO();
    }
    return {
      bodyType: vehicle.bodyType.toUpperCase(),
      bookingReference: get(
        vehicle,
        "bookingSchedules[0].booking.referenceNumber",
        ""
      ),
      fuelType: vehicle.fuelType === "HYBRID ELECTRIC" ? "HYBRID ELECTRIC (HEV)" : vehicle.fuelType,
      id: vehicle.id,
      status: vehicle.status,
      motStatus: vehicle.vehicleTestReports?.length ? ((vehicle.vehicleTestReports[0].testResult === "PASS" && testDueDate >= d.now().toUTC().toISO()) ? "Pass" : "Fail") : "N/A",
      motExpiry: vehicle.vehicleTestReports?.length ? vehicle.vehicleTestReports[0]?.expiryDate : vehicle.vehicleTestDueDate ? d.fromISO(vehicle.vehicleTestDueDate).toFormat('d MMMM yyyy') : "N/A",
      pcoExpiry: vehicle.pcoExpiry ? new Date(vehicle.pcoExpiry).toLocaleDateString(locale, { day: '2-digit', month: 'long', year: 'numeric' }).toUpperCase() : "N/A",
      insuranceStatus: vehicle.vehicleDocuments ? getInsuranceStatus(vehicle) : "N/A",
      vehicle,
      features: vehicle.features,
      assetTag: vehicle.assetTag,
      make: vehicle.make,
      licencePlate: vehicle.licencePlate,
      year: vehicle.year,
      imageUrl: vehicle.imageUrl,
      taxDetails: vehicle.taxDetails ? vehicle.taxDetails : "N/A",
      taxStatus: country === "United States" ? "N/A" : !vehicle.taxStatus || vehicle.taxStatus === "Untaxed" ? "Non-Taxed" : vehicle.taxStatus,
      deFleetStatus: vehicle.deFleetStatus ? vehicle.deFleetStatus : "N/A"
    };
  }

  function getInsuranceStatus(vehicle: IVehicle) {
    let status = "";
    const insuranceDocument =
      vehicle.vehicleDocuments.find((document: IVehicleDocument) =>
        document.documentType === DocumentTypeValue.VEHICLE_INSURANCE
      )

    if (insuranceDocument && insuranceDocument.expiryDate) {
      if (insuranceDocument.expiryDate >= d.now().toUTC().toISO()) {
        status = "Active"
      } else {
        status = "Expired"
      }
    } else {
      status = "N/A"
    }

    return status;
  }

  function handleDeleteButton() {
    deleteVehicle({
      variables: { vehicleId: selection[0].id }
    });
    setSelection([]);
    setDeleteModalVisible(false);
  }

  function onSelectDocument(event: any) {
    const file: File = event.target.files[0];
    setCsvErrors([]);
    setCsvGlobalVehicleErrors([]);
    if (file) {
      setFileName(file.name);
      if (possibleCsvMimeTypes.includes(file.type)) {
        setFileValue(file);
      } else {
        setFileValue(undefined);
        snackbar({
          message: "Invalid File Type. Allowed type: csv",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  function uploadCsv() {
    try {
      if (fileValue === undefined) {
        return;
      }
      setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
      papa.parse(fileValue, {
        skipEmptyLines: true,
        complete(results: any) {
          const data: any = results.data;
          if (userState.currentOrganisation.address.country !== "United Kingdom") {
            for (let index = 0; index < results.data[0].length; index++) {
              const header = results.data[0][index];
              if (header !== globalVehicleCsvHeaders[index]) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: `Invalid File Header: "${header}" is incorrect.`,
                  variant: SnackBarVariant.ERROR
                });
              }
            }
            if (data.length > 1) {
              const vehicles: any = [];
              if (data.length === 2 && !data[1][0]) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: "Licence plate is missing.",
                  variant: SnackBarVariant.ERROR
                });
              }
              // upload vehicle data
              for (let i = 1; i < data.length; i++) {
                const element = data[i];
                delete fleetInitialState.vehicle.vehicleState.id;
                delete fleetInitialState.vehicle.vehicleState.type;
                if (!element[0]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Licence plate is missing at row ${i}`,
                    variant: SnackBarVariant.ERROR
                  })
                }
                if (element[4] && !validFuelTypes.includes(element[4].toUpperCase())) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Invalid fuel type at row ${i}`,
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (!element[9]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `VIN not found at row ${i}`,
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (!element[12]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Frog Diagram not found for ${element[0]}`,
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (element[12] && !frogDiagramList.includes(element[12])) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Invalid frog diagram for ${element[0]}`,
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (!isValidDate(element[13], userState.currentOrganisation.address.country)) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Invalid date format for Vehicle De-Fleet Date at row ${i}`,
                    variant: SnackBarVariant.ERROR
                  });
                }

                const vehicleData = {
                  licencePlate: element[0].trim(),
                  bodyType: element[1],
                  colour: element[2],
                  cylinderCapacity: element[3],
                  fuelType: element[4].toUpperCase(),
                  make: element[5],
                  model: element[6],
                  year: element[7] ? parseInt(element[7]) : 0,
                  transmission: element[8],
                  vin: element[9],
                  numberOfDoors: element[10] ? parseInt(element[10]) : 0,
                  numberOfSeats: element[11] ? parseInt(element[11]) : 0,
                  frogDiagram: element[12],
                  deFleetParameters: {
                    byDistanceDriven: 0,
                    byDate: element[13],
                    alertBeforeDistance: parseInt(element[14]),
                    alertBeforeDays: parseInt(element[15])
                  },
                  regularServiceSchedule: {
                    byDistance: 0,
                    byMonth: parseInt(element[16]),
                    alertBefore: parseInt(element[17])
                  },
                  registrationDate: element[18],
                  v5c: element[19],
                  vehicleState: fleetInitialState.vehicle.vehicleState,
                  infoSource: VehicleInfoSource.BULK_CSV,
                  branch: userState.currentBranch.id
                };
                vehicles.push(vehicleData);
              }
              createVehicles({
                variables: {
                  vehicles
                }
              });
            } else {
              setUploadStatus(CsvUploadStatusType.ERROR);
              snackbar({
                message: "No data found.",
                variant: SnackBarVariant.ERROR
              });
            }
          } else {
            if (data.length > 21) {
              setUploadStatus(CsvUploadStatusType.ERROR);
              setFileName("");
              setFileValue(undefined);
              return snackbar({
                message: "Only 20 vehicles can be uploaded in one batch",
                variant: SnackBarVariant.ERROR
              });
            }
            if (data.length > 1) {
              for (let index = 0; index < data[0].length; index++) {
                const header = data[0][index];
                if (header !== vehicleCsvHeaders[index]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Invalid File Header: "${header}" is incorrect.`,
                    variant: SnackBarVariant.ERROR
                  });
                }
              }
              if (data.length === 2 && !data[1][0]) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: "Licence plate is missing.",
                  variant: SnackBarVariant.ERROR
                });
              }
              const _data: IDvalVehicleUploadInput[] = [];
              for (let index = 1; index < data.length; index++) {
                const row = data[index];
                if (!row[0]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Licence plate is missing at row ${index}`,
                    variant: SnackBarVariant.ERROR
                  })
                }
                if (!row[1]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Frog Diagram not found for ${row[0]}`,
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (row[1] && !frogDiagramList.includes(row[1])) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Invalid frog diagram for ${row[0]}`,
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (!isValidDate(row[2], userState.currentOrganisation.address.country)) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Invalid date format for Vehicle De-Fleet Date at row ${index}`,
                    variant: SnackBarVariant.ERROR
                  });
                }
                const vehicleData = {
                  registrationNo: row[0].toLowerCase().trim(),
                  frogDiagram: row[1],
                  deFleetParameters: {
                    byDistanceDriven: 0,
                    byDate: row[2],
                    alertBeforeDistance: parseInt(row[3]),
                    alertBeforeDays: parseInt(row[4])
                  },
                  regularServiceSchedule: {
                    byDistance: 0,
                    byMonth: parseInt(row[5]),
                    alertBefore: parseInt(row[6])
                  }
                }
                _data.push(vehicleData);
              }
              searchDvla({
                variables: {
                  dvlaVehicles: _data
                }
              });
            } else {
              setUploadStatus(CsvUploadStatusType.ERROR);
              snackbar({
                message: "No data found.",
                variant: SnackBarVariant.ERROR
              });
            }
          }
        }
      });
    } catch (error) {
      captureErrorException(error)
      setUploadStatus(CsvUploadStatusType.ERROR);
    }
  }

  function uploadTrailers() {
    try {
      if (fileValue === undefined) {
        return;
      }
      setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
      papa.parse(fileValue, {
        skipEmptyLines: true,
        complete(results: any) {
          const data: any = results.data;
          if (data.length > 1) {
            const vehicles: any = [];
            // upload vehicle data
            for (let i = 1; i < data.length; i++) {
              const element = data[i];
              delete fleetInitialState.vehicle.vehicleState.id;
              delete fleetInitialState.vehicle.vehicleState.type;
              if (!element[9] || !frogDiagramList.includes(element[9])) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: `Invalid frog diagram for ${element[0]}`,
                  variant: SnackBarVariant.ERROR
                });
              }
              const vehicleData = {
                licencePlate: element[0].trim(),
                vin: element[1],
                assetTag: element[2],
                year: parseInt(element[3]),
                model: element[4],
                trailerType: element[5],
                bodyCode: element[6],
                bodyType: element[7],
                colour: element[8],
                frogDiagram: element[9],
                axles: parseInt(element[10]),
                length: parseFloat(element[11]),
                palletCapacity: parseFloat(element[12]),
                height: parseFloat(element[13]),
                expiryDate: element[14].replaceAll(" ", ""),
                pmiCycle: parseInt(element[15]),
                testDate: d.fromFormat(element[16].replaceAll(" ", ""), "d/MM/yyyy").toFormat("d MMMM yyyy").toUpperCase(),
                testResult: element[17],
                vehicleState: fleetInitialState.vehicle.vehicleState,
              };
              vehicles.push(vehicleData);
            }
            uploadTrailersVehicles({
              variables: {
                vehicles
              }
            });
          } else {
            setUploadStatus(CsvUploadStatusType.ERROR);
            snackbar({
              message: "No data found.",
              variant: SnackBarVariant.ERROR
            });
          }
        }
      });
    } catch (error) {
      captureErrorException(error)
      setUploadStatus(CsvUploadStatusType.ERROR);
    }
  }

  const options: MUIDataTableOptions = {
    // custom search for license plate search
    count: totalVehiclesCount,
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: loading || searchVehicleLoading || vehicleByStatusLoading || defleetedVehiclesLoading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    selectableRows: SelectableRows.NONE,
    customSearch: (searchQuery: any, currentRow: any, columns: any) => {
      let isFound = false;
      currentRow.forEach((col: any) => {
        let value;
        if (col) {
          if (col.licencePlate) {
            Object.keys(col).forEach((key: string) => {
              if (col[key] != null) {
                value = col[key].toString().toLowerCase();
                if (value.indexOf(searchQuery.toLowerCase()) >= 0) {
                  isFound = true;
                }
              }
            });
          } else {
            value = col.toString().toLowerCase();
            if (value.indexOf(searchQuery.toLowerCase()) >= 0) {
              isFound = true;
            }
          }
        }
      });
      return isFound;
    },

    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText);
      } else {
        const params = new URLSearchParams(location.search);
        const status = params.get("status");
        const vehicleIds = location.state;
        if (status) {
          setRows([]);
          getVehiclesByStatus({
            variables: {
              status
            }
          });
        }
        //if user is coming from dashboard on clicking reminders
        else if (vehicleIds && vehicleIds.length) {
          setTotalVehiclesCount(vehicleIds.length);
          setRows([]);
          getVehicles({
            variables: {
              limit: vehicleIds.length,
              offset: 0,
              ids: vehicleIds
            }
          });
        }
        else {
          setRows([]);
          getVehiclesCount();
          getVehicles({
            variables: {
              limit,
              offset: 0
            }
          });
        }
      }
    },

    onRowClick: (
      rowData: any
    ) => {
      if (
        rowData[0].props &&
        rowData[0].props.vehicle &&
        rowData[0].props.vehicle.id
      ) {
        if (isDefleetedVehicles) {
          navigate(`/update-defleeted-vehicle?vehicle=${rowData[0].props.vehicle.id}`)
        } else {
          navigate(`/update-vehicle?vehicle=${rowData[0].props.vehicle.id}`);
        }
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.VEHICLE_INVENTROY,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      vehicleInventoryTable && vehicleInventoryTable.rowsPerPage
        ? vehicleInventoryTable.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.VEHICLE_INVENTROY,
          columnName: changedColumn,
          direction
        })
      );
    },
    onFilterChange(changedColumn, filterList, type, changedColumnIndex, displayData) {
      dispatch(
        addTableState({
          tableName: TableNames.VEHICLE_INVENTROY,
          filters: filterList
        })
      );
      let filterColumns: IFilter[] = [];
      filterList.forEach((value, index) => {
        if (value.length) {
          filterColumns.push({
            key: columns[index].name,
            value: value[0]
          });
        }
      });
      if (filterList) {
        if (isDefleetedVehicles) {
          getDefleetedVehicles({
            variables: {
              limit: vehicleInventoryTableState?.rowsPerPage || limit,
              offset: 0,
              filters: filterColumns
            }
          })
        } else {
          setFilterList(filterColumns);
          setIsFiltering(true);
          getVehicles({
            variables: {
              limit: vehicleInventoryTableState?.rowsPerPage || limit,
              offset: 0,
              filters: filterColumns
            }
          });
        }
      } else {
        setIsFiltering(false);
      }
    },
    onTableInit: (_: string, tableState: MUIDataTableState) => {
      if (vehicleInventoryTableState && vehicleInventoryTableState.filters && !location.search) {
        let filterColumns: IFilter[] = [];
        vehicleInventoryTableState.filters.forEach((value, index) => {
          if (value.length) {
            filterColumns.push({
              key: columns[index].name,
              value: value[0]
            });
          }
        });
        getVehicles({
          variables: {
            limit: vehicleInventoryTableState?.rowsPerPage || limit,
            offset: 0,
            filters: filterColumns
          }
        });
        tableState.filterList = vehicleInventoryTableState.filters;
      }
      if (vehicleInventoryTableState && vehicleInventoryTableState.viewColumns) {
        for (const [key, value] of Object.entries(vehicleInventoryTableState.viewColumns)) {
          let columnIndex = tableState.columns.findIndex(column => column.name === key);
          if (columnIndex > -1) {
            tableState.columns[columnIndex].display = value as Display;
          }
        }
      }
      if (vehicleInventoryTable && sortOrder) {
        setTableSortOrder(columns, vehicleInventoryTable, sortOrder);
      }
      if (vehicleInventoryTable && vehicleInventoryTable.rowsPerPage) {
        setLimit(vehicleInventoryTable.rowsPerPage);
      }
    },
    onColumnViewChange: (changedColumn, action) => {
      const updatedViewColumns = { ...vehicleInventoryTableState?.viewColumns, [changedColumn]: String(action === 'add') };
      dispatch(
        addTableState({
          tableName: TableNames.VEHICLE_INVENTROY,
          viewColumns: updatedViewColumns
        })
      );
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const isVehicleCol = columns.some((col: any) => col.name === "vehicle");
      if (isVehicleCol) {
        data.forEach((row: any) => {
          row.data[0] = getVehicleColumnData(row.data[0]);
        });
      }
      return buildHead(columns) + buildBody(data)
    },
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return;
          }
          const { page, rowsPerPage } = tableState;
          if (isDefleetedVehicles) {
            getDefleetedVehicles({
              variables: {
                offset: page * rowsPerPage,
                limit: rowsPerPage
              }
            })
          } else {
            if (page * rowsPerPage >= rows.length && !statusParam) {
              getVehicles({
                variables: {
                  offset: page * rowsPerPage,
                  limit: rowsPerPage,
                  filters: filterList

                }
              });
            }
          }
          break;
        case "changeRowsPerPage":
          const vehicleIds = location.state;
          if (isDefleetedVehicles) {
            setRows([]);
            getDefleetedVehicles({
              variables: {
                offset: 0,
                limit: tableState.rowsPerPage || limit
              }
            })
          } else {
            if (vehicleIds && vehicleIds.length) {
              setTotalVehiclesCount(vehicleIds.length)
              getVehicles({
                variables: {
                  offset: 0,
                  limit: vehicleIds.length,
                  ids: vehicleIds
                },
              });
            } else if (!statusParam) {
              setRows([]);
              getVehicles({
                variables: {
                  offset: 0,
                  limit: tableState.rowsPerPage || limit
                },
              });
            }
          }
          break;
        default:
          break;
      }
    },
  };

  const getVehicleColumnData = (data: IVehicle) => {
    return `${data.make},${data.model},${data.year},${data.licencePlate}`;
  }

  const classes = UseStyles();

  const handleScroll = (e: any) => {
    const threshold = 15000
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < threshold
    ) {
      getVehicles({
        variables: {
          offset: rows.length,
          limit: rows.length
        }
      })
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [data]);

  const onChange = (event: { target: { value: any; }; }) => {
    handleSearch(event.target.value);
    if (searchVehicleData && searchVehicleData.searchVehicles) {
      const newVehicles = searchVehicleData.searchVehicles.map((vehicle: IVehicle) =>
        reshapeVehicleIntoRow(vehicle)
      );
      setRows(newVehicles)
    }
    if (!event.target.value) {
      setRows([])
      getVehicles({
        variables: {
          limit: 10,
          offset: 0
        }
      })
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <CssBaseline />
        <Grid container item xs={12} sm={4}>
          {isDefleetedVehicles ? (
            <Typography variant="h1" color="primary">
              De-Fleeted Vehicle Inventory
            </Typography>
          ) : (
            <Typography variant="h1" color="primary">
              Fleet Inventory
            </Typography>
          )}
        </Grid>
        {!isDefleetedVehicles && (
          <Hidden smDown>
            <Grid container item xs={6} sm={8} justifyContent="flex-end">
              {userState.currentBranch.overbookingAllowed ?
                (<Fab
                  variant="extended"
                  size="small"
                  aria-label="addGhostVehicle"
                  className="createButton"
                  onClick={() => {
                    props.resetVehicle();
                    navigate("/new-ghostvehicle");
                  }}
                  disabled={userState.role === "FINANCE_MANAGER"}
                >
                  Add Virtual Vehicles
                </Fab>) : (<></>)
              }
              {userState.currentTenancy.trailerCSVUploadEnabled &&
                userState.role === UserRoles.SUPER_ADMIN && (
                  <>
                    <Box color="white" sx={{ pr: 2 }}></Box>
                    <Fab
                      variant="extended"
                      size="small"
                      aria-label="addMultipleVehicles"
                      className="createButton"
                      onClick={() => {
                        props.resetVehicle();
                        setFileName("");
                        setFileValue(undefined);
                        setCsvErrors([]);
                        setCsvGlobalVehicleErrors([]);
                        setOpenTrailerUpload(true);
                      }}
                    >
                      Upload Trailers
                    </Fab>
                  </>
                )}
              <Box color="white" sx={{ pr: 2 }}></Box>
              <Fab
                variant="extended"
                size="small"
                aria-label="addVehicle"
                className="createButton"
                onClick={() => {
                  props.resetVehicle();
                  navigate("/new-vehicle");
                }}
                disabled={userState.role === "FINANCE_MANAGER"}
              >
                Add a Vehicle
              </Fab>
              <Box color="white" sx={{ pr: 2 }}></Box>
              <Fab
                variant="extended"
                size="small"
                aria-label="addMultipleVehicles"
                className="createButton"
                onClick={() => {
                  props.resetVehicle();
                  setIsBulkCsvOpen(true);
                }}
                disabled={userState.role === "FINANCE_MANAGER"}
              >
                Create Multiple
              </Fab>
            </Grid>
          </Hidden>
        )}

        <Grid item xs={12}>
          {!((loading || vehicleByStatusLoading || defleetedVehiclesLoading) && !rows.length) ? (
            <Grid container xs={12}>
              <Hidden smUp>
                {searchVehicleLoading ? (
                  <CircularProgress />) : (
                  <div onScroll={handleScroll}>
                    <VehicleInventoryVirtualScroller data={rows} />
                  </div>
                )}
              </Hidden>
              <Hidden xsDown>
                {filterLoading ? <CircularProgress /> :
                  <NuvvenTable
                    title={""}
                    rows={rows}
                    columns={columns}
                    setSelection={setSelection}
                    options={options}
                  />
                }
              </Hidden>
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </Grid>
        <Grid item xs={12}>
          <ConfirmDeleteModal
            open={deleteModalVisible}
            handleToggle={() => setDeleteModalVisible(!deleteModalVisible)}
            handleConfirmDeleteBtn={handleDeleteButton}
          />
          {isBulkCsvOpen && (
            <BulkCsvUploadDialog
              title={"Create Multiple vehicle"}
              subTitle={"Vehicles"}
              csvTemplateLink={
                userState && userState.currentOrganisation &&
                  userState.currentOrganisation.address &&
                  userState.currentOrganisation.address.country === "United Kingdom" ?
                  "https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Coastr-Vehicle-Upload-UK.csv" :
                  "https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Coastr-Vehicle-Upload-Non-UK.csv"
              }
              uploadErrors={csvGlobalVehicleErrors}
              dvlaUploadErrors={csvErrors}
              uploadStatus={uploadStatus}
              onDocumentSelect={onSelectDocument}
              fileName={fileName}
              uploadCsv={uploadCsv}
              handleClose={() => setIsBulkCsvOpen(false)}
              isVehicleCsv={true}
            />
          )}
        </Grid>
      </Grid>
      {/* TODO: This is only added for Zenith to upload their trailer vehicles, This Dialog code can be removed once they onbord their vehicle on the system */}
      {openTrailerUpload && (
        <div>
          <Dialog
            fullWidth={true}
            open={true}
            onClose={() => { setOpenTrailerUpload(false) }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" style={{ padding: "20px 24px 10px" }}>
              <Typography variant="h2">{"Upload Trailer Vehicles"}</Typography>
            </DialogTitle>
            <DialogContent style={{ overflowY: "hidden", paddingBottom: "20px" }}>
              <Grid item container xs={12} spacing={2}>
                <Grid container item xs={8}>
                  <InputField
                    handleInput={() => { }}
                    placeholder={"File Name"}
                    value={fileName}
                    name={"fileName"}
                    disabled
                  />
                </Grid>
                <Grid container item xs={4}>
                  <FilePicker
                    onFilePick={(e: any) => {
                      onSelectDocument(e);
                    }}
                    accept=".csv"
                  />
                </Grid>
                <Grid container item xs={6}>
                  {uploadStatus === CsvUploadStatusType.IN_PROGRESS ? (
                    <CircularProgress />
                  ) : (
                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="uploadCsv"
                      onClick={() => uploadTrailers()}
                      disabled={!fileName}
                    >
                      Upload
                    </Fab>
                  )}
                </Grid>
                {csvGlobalVehicleErrors.length > 0 && (
                  <Grid item container xs={12}>
                    <div>
                      {csvGlobalVehicleErrors.map((upldError: ICsvUploadError, idx: number) => {
                        return <div
                          key={idx}
                        >{`vehicle can\'t be uploaded at row ${upldError.index +
                          1} because ${upldError.message}`}</div>
                      })}
                    </div>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      )}
      {!isDefleetedVehicles && (
        <Hidden smUp>
          <AppBar position="fixed" className={classes.appBar}>
            <Grid container xs={12} spacing={1}>
              <Grid item xs={12}>
                <Button
                  className={classes.bottomButton}
                  variant="contained"
                  aria-label="Create"
                  onClick={() => {
                    navigate("/new-vehicle");
                  }}
                >
                  Create
                </Button>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={onChange}
                  />
                </div>
              </Grid>
            </Grid>
          </AppBar>
        </Hidden>
      )}
    </div>
  );
}

const mapStateToProps = (state: IAppState) => {
  return {
    vehicle: getVehicle(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IAction>) => {
  return {
    resetVehicle: () => {
      dispatch(resetVehicle());
    },
    updateVehicle: (payload: IVehicle) => {
      dispatch(updateVehicle(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInventory);