import {
  Tooltip,
  IconButton,
  CircularProgress
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import styles from './index.module.css';
import { IAppState } from '../../../../store';
import { getSignedUrl } from '../../../../utils/getSignedUrl';

interface IProps {
  tenantId: string;
  imageIndex: number;
  imageKey: string;
  setImageKey: (key: string, index: number) => void;
  isGallery?: boolean;
  isFavIcon?: boolean;
  logo?: boolean;
  hideControls?: boolean;
  contain?: boolean;
}

const ImageUpload: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [imageUrl, setImageUrl] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const { imageIndex, imageKey, setImageKey, tenantId } = props;

  useEffect(() => {
    if (props.imageKey) {
      setLoading(true);
      getImageUrl(props.imageKey)
    }
  }, [props.imageKey])

  const setCarouselImageUpload = async (e: any) => {
    var filesArr = Array.prototype.slice.call(e.target.files);
    const file = filesArr[0];
    const uniqueId = uuidv4();
    if (file) {
      const _URL = window.URL || window.webkitURL;
      let img = new Image();
      img.src = _URL.createObjectURL(file);
      img.onload = async () => {
        if (!props.isFavIcon && ((img.width / img.height < 2.35) || (img.width / img.height > 2.9))) {
          return snackbar({
            message: "Incorrect Aspect Ratio!",
            variant: SnackBarVariant.ERROR
          });
        }
        else {
          let key: string = "";
          if (file.type === "image/jpeg") {
            key = `${tenantId}-${uniqueId}-crslImgUrl.jpeg`;
          } else if (file.type === "image/png") {
            key = `${tenantId}-${uniqueId}-crslImgUrl.jpeg`;
          } else if (file.type === "image/bmp") {
            key = `${tenantId}-${uniqueId}-crslImgUrl.jpeg`;
          } else {
            setTimeout(() => {
              return snackbar({
                message: "Invalid Image type. Supported types: .png, .jpeg, .bmp",
                variant: SnackBarVariant.ERROR
              });
            }, 2000);
          }
          key = `${userReducer.tenancy.id}/${key}`;
          setLoading(true);
          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  setImageKey(key, imageIndex);
                  getImageUrl(key);
                }
              });
          }
        }
      };
      img.onerror = function () {
        return snackbar({
          message: "Invalid Image type. Supported types: .png, .jpeg, .bmp",
          variant: SnackBarVariant.ERROR
        });
      };
    }

  };

  const removeImage = async () => {
    if (imageKey) {
      // TODO: implement file deleteion from s3
      setImageKey('', imageIndex);
      setImageUrl('');
    }
  }

  const getImageUrl = async (key: string) => {
    const file = await getSignedUrl(key);
    setLoading(false);
    setImageUrl(file);
  };

  const uniqueInputId = uuidv4();

  return (
    <div className={styles.imageBox}>
      <div className={styles.image} style={{ border: props.logo ? "1px solid #f0f0f0" : "", borderRadius: props.hideControls ? 0 : 8 }}>
        {loading ? <CircularProgress size={26} /> : imageUrl ? <img src={imageUrl} style={props.logo ? { width: "auto" } : props.contain ? { width: "100%", objectFit: "contain" } : {}} /> : null}
      </div>
      {!props.hideControls && <div className={styles.panel} style={{ display: props.logo ? "flex" : "initial" }}>
        {(!props.isGallery &&
          <div>
            <input accept="image/*" style={{ display: 'none' }} onChange={(e: any) => { setCarouselImageUpload(e); e.target.value = null }} id={uniqueInputId} type="file" />
            <label htmlFor={uniqueInputId}>
              <IconButton color="primary" aria-label="upload picture" component="span" size="large">
                <AddAPhotoIcon style={{ fontSize: 18 }} />
              </IconButton>
            </label>
          </div>
        )}
        {imageKey &&
          <div style={{ marginLeft: props.logo ? 5 : 0 }}>
            <Tooltip title="Remove image">
              <IconButton aria-label="Remove" color="primary" onClick={removeImage} size="large">
                <DeleteIcon style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </div>}
      </div>}
    </div>
  );
};

export default ImageUpload;
