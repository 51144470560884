import { useLazyQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  Paper,
  Theme,
  Typography,
  AppBar,
  Button,
  Hidden
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from 'lodash';
import { GET_INSURANCE_POLICIES } from "../../../../../graphql/insurancePolicy/getInsurancePolicies";
import {
  DefaultAddons,
  IAddonRate,
  IAddonType
} from "../../../../../reducers/addonType/types";
import {
  IAddonRequirementInput,
  IAddressLocation,
  IBookingCreateInput,
  IInsuranceAddon,
  IInsurancePolicyBooking,
} from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { CustomCard } from "./CustomCard";
import { InsuranceCard } from "./InsuranceCard";
import { ValetCard } from "./ValetCard";
import { GET_AVAILABLE_ADDON_TYPES } from "../../../../../graphql/addOns/getAvailableAddonsQuery";
import { GET_INSURANCE_POLICY } from "../../../../../graphql/insurancePolicy/getInsurancePolicy";

interface IAddonsProps {
  bookingData: IBookingCreateInput;
  selectedInsurancePolicy: IInsurancePolicyBooking;
  selectedInsuranceRate: number;
  isUpdate: boolean;
  onPrevious(): void;
  onSubmit(data?: IBookingCreateInput): void;
  updateParentState(newVaalue: string): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    backButton: {
      backgroundColor: "black",
      color: "white",
      marginRight: "20px"
    },
    buttonRow: {
      marginTop: "25px"
    },
    root: {
      padding: theme.spacing(1),
      flexGrow: 1,
      minHeight: 116
    }
  })
);

export const AddonsSelection: React.FC<IAddonsProps> = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState(props.bookingData);
  const [activatedAddons, setActivatedAddons] = useState<IAddonType[]>([]);
  const [selectedAddons, setSelectedAddons] = useState<
    IAddonRequirementInput[]
  >([]);
  const [insuranceAddOns, setInsuranceAddOns] = useState<IInsuranceAddon[]>([]);
  const [selectedValetPrice, setSelectedValedPrice] = useState(0);
  const userState = useSelector((state: IAppState) => state.userReducer);

  const [loadAddons, { loading: addonsLoading, data: addonsData }] = useLazyQuery(GET_AVAILABLE_ADDON_TYPES, {
    fetchPolicy: "network-only"
  });

  const [loadInsurancePolicies, { loading: insuranceLoading, data: insurancePolicyData }] = useLazyQuery(
    GET_INSURANCE_POLICIES,
    {
      fetchPolicy: "network-only"
    }
  );

  const [loadSelectedInsurancePolicy, { loading: selectedInsuranceLoading, data: selectedInsurancePolicyData }] = useLazyQuery(
    GET_INSURANCE_POLICY,
    {
      fetchPolicy: "network-only"
    }
  )

  useEffect(() => {
    if (userState.tenancy) {
      loadInsurancePolicies({
        variables: {
          organisationId: userState.currentOrganisation.id,
          fetchActiveOnly: true
        }
      });
      loadAddons({
        variables: {
          startDate: props.bookingData.pickupDateTime,
          endDate: props.bookingData.dropoffDateTime,
          bookingId: props.bookingData.id || "",
          businessCustomer: props.bookingData.businessCustomer,
          rateTypeName: props.bookingData.rateTypeName
        }
      });
      if (props.selectedInsurancePolicy && props.selectedInsurancePolicy.id) {
        loadSelectedInsurancePolicy({
          variables: {
            id: props.selectedInsurancePolicy.id
          }
        })
      }
    }
  }, [userState, props.selectedInsurancePolicy.id]);

  useEffect(() => {
    if (values.addonRequirements && values.addonRequirements.length) {
      const valetAddon = values.addonRequirements.find((ao) => ao.name === DefaultAddons.VALET);
      if (valetAddon && valetAddon.rate) {
        setSelectedValedPrice(valetAddon.rate);
      }
    }
  }, [values.addonRequirements]);

  // add on data
  useEffect(() => {
    if (addonsData && addonsData.availableAddons) {
      const selectAddons: IAddonRequirementInput[] = [];
      const filteredAddons = addonsData.availableAddons.filter((addons: IAddonType) => {
        const addonsRate = addons.recurringRate?.find((rate: IAddonRate) => rate.rateTypeName === values.rateTypeName);
        if ((addons.hasFixedRate && addons.fixedRate !== 0) || (!addons.hasFixedRate && addonsRate?.rate !== 0) || addons.name === "VALET") {
          return addons;
        }
      });
      const applicableAddons = filteredAddons.filter(
        (addon: IAddonType) => {
          const addonPresent = values.addonRequirements.find(
            (addReq) => addReq.addon === addon.id
          );
          if (addon.isActivated && addonPresent) {
            selectAddons.push(addonPresent);
          }
          return addon.isActivated;
        }
      );
      setActivatedAddons(applicableAddons);
      setSelectedAddons(selectAddons);
    }
  }, [addonsData, values.addonRequirements]);

  // insurance policy data
  useEffect(() => {
    if (insurancePolicyData && insurancePolicyData.insurancePolicies) {
      let insurancePolicies = _.cloneDeep(insurancePolicyData.insurancePolicies);
      const selectedInsurancePolicy = selectedInsurancePolicyData && selectedInsurancePolicyData?.insurancePolicy ? _.cloneDeep(selectedInsurancePolicyData.insurancePolicy) : null
      const insuranceAddOns = [];
      for (
        let index = 0;
        index < insurancePolicies.length;
        index++
      ) {
        const insurancePolicy = insurancePolicies[index];
        const insuranceRate = insurancePolicy.insuranceRate.find(
          (iRate: any) => iRate.rateName === values.rateTypeName
        );
        if (insuranceRate) {
          insuranceAddOns.push({
            ...insuranceRate,
            policyId: insurancePolicy.id,
            excess: insurancePolicy.excess,
            policyName: insurancePolicy.name,
            description: insurancePolicy.description
          });
        }
      }
      if (props.isUpdate && props.selectedInsurancePolicy?.id) {
        let _selectedInsurancePolicy = insurancePolicies.find((policy: any) => policy.id === props.selectedInsurancePolicy.id);
        if (!_selectedInsurancePolicy && selectedInsurancePolicy) {
          insurancePolicies = [...insurancePolicies, selectedInsurancePolicy]
        }
        _selectedInsurancePolicy = _selectedInsurancePolicy ? _selectedInsurancePolicy : selectedInsurancePolicy
        const _selectedPolicyRate = _selectedInsurancePolicy && _selectedInsurancePolicy?.insuranceRate ? _selectedInsurancePolicy.insuranceRate.find(
          (iRate: any) => iRate.rateName === values.rateTypeName
        ) : null
        const _selectedInsurancePolicyIndex = insurancePolicies.findIndex((policy: any) => policy.id === props.selectedInsurancePolicy.id);
        if (_selectedInsurancePolicyIndex > -1) {
          insuranceAddOns[_selectedInsurancePolicyIndex] = {
            rate: _selectedPolicyRate ? _selectedPolicyRate.rate : props.selectedInsuranceRate || 0,
            policyId: props.selectedInsurancePolicy.id,
            policyName: props.selectedInsurancePolicy.name,
            description: props.selectedInsurancePolicy.description,
            excess: props.selectedInsurancePolicy.excess
          }
        }

      }
      setInsuranceAddOns(insuranceAddOns);
    }
  }, [insurancePolicyData, selectedInsurancePolicyData]);

  const getAddons = (
    addonType?: IAddonType,
    rate?: number,
    quantity?: number,
    valetType?: string
  ) => {
    if (addonType && addonType.id && quantity) {
      const addons = [...selectedAddons];
      const existingIndex = selectedAddons.findIndex(
        (addon) => addon.addon === addonType.id
      );
      if (existingIndex > -1) {
        addons[existingIndex] = {
          ...addons[existingIndex],
          quantity,
          rate: rate || 0,
        };
      } else {
        // add to selected addons
        if (!valetType) {
          addons.push({
            displayName: addonType.displayName,
            addon: addonType.id,
            hasFixedRate: addonType.hasFixedRate,
            name: addonType.name,
            quantity,
            rate: rate || 0,
            taxable: addonType.taxable,
            tax: addonType.taxable && addonType.tax ? {
              title: addonType.tax.title,
              value: addonType.tax.value
            } : {
              title: "",
              value: 0
            }
          });
        } else {
          return selectedAddons.filter(
            (addon) => addon.addon !== addonType?.id
          );
        }
      }
      return addons;
    } else {
      return selectedAddons.filter(
        (addon) => addon.addon !== addonType?.id
      );
    }
  };

  const onCustomAddonSelect = (
    addonType?: IAddonType,
    rate?: number,
    quantity?: number,
  ) => {
    const addons = getAddons(addonType, rate, quantity);
    setSelectedAddons(addons);
    setValues({
      ...values,
      addonRequirements: addons
    });
  };

  const onValetAddonSelect = (args: {
    addonType: IAddonType,
    quantity: number,
    rate?: number,
    pickupOtherLocation?: IAddressLocation,
    dropoffOtherLocation?: IAddressLocation
  }) => {
    const { addonType, rate, pickupOtherLocation, dropoffOtherLocation } = args;
    if (addonType &&
      (pickupOtherLocation?.fullAddress ||
        dropoffOtherLocation?.fullAddress)
    ) {
      const addons = getAddons(addonType, rate, 1);
      setSelectedAddons(addons);
      setValues({
        ...values,
        addonRequirements: addons,
        pickupOtherLocation,
        dropoffOtherLocation
      });
    } else {
      const addons = getAddons(addonType);
      const { pickupOtherLocation, dropoffOtherLocation, ...rest } = values;
      setSelectedAddons(addons);
      setValues({
        ...rest,
        addonRequirements: addons
      });
    }
  };

  const onInsuranceAddonSelect = (insuranceAddOn?: string) => {
    if (insuranceAddOn) {
      const selectedInsurance = insuranceAddOns.find(
        (addOn) => addOn.policyId === insuranceAddOn
      );
      if (selectedInsurance) {
        setValues({
          ...values,
          ...{
            insuranceRate: selectedInsurance.rate,
            excess: selectedInsurance.excess,
            insurancePolicy: selectedInsurance.policyId
          }
        });
      }
    } else {
      setValues({
        ...values,
        ...{
          insuranceRate: 0,
          excess: 0,
          insurancePolicy: undefined
        }
      });
    }
  };

  const onCoiSelect = (flag: boolean) => {
    if (flag) {
      setValues({
        ...values,
        isCoi: true,
        insurancePolicy: "",
        insuranceRate: 0
      })
    } else {
      setValues({
        ...values,
        isCoi: false
      })
    }
  }

  const handlePrevious = () => {
    props.onPrevious();
  };

  const handleSubmit = () => {
    const addonRequirementsArr: IAddonRequirementInput[] = [];
    if (values && values.addonRequirements.length) {
      values.addonRequirements.forEach((item: IAddonRequirementInput) => {
        const addonObj = addonsData.availableAddons.find((addonItem: IAddonType) => addonItem.id === item.addon);
        if (addonObj && values.rateTypeName && addonObj.recurringRate?.length && addonObj.name !== DefaultAddons.VALET) {
          const rate = addonObj.recurringRate.find((rateObj: IAddonRate) => {
            return rateObj.rateTypeName === values.rateTypeName;
          });
          if (rate && !addonObj.fixedRate) {
            item.rate = rate.rate;
          }
          else {
            item.rate = addonObj.fixedRate
          }
        }
        addonRequirementsArr.push(item);
      });
    }
    const valuesObj = {
      ...values,
      addonRequirements: addonRequirementsArr
    };
    if (values.insurancePolicy) {
      const insuranceItem = insuranceAddOns.find((item) => item.policyId === values.insurancePolicy);
      if (insuranceItem && insuranceItem.rate) {
        valuesObj.insuranceRate = insuranceItem.rate;
      }
    }
    props.onSubmit(valuesObj);
  };

  if (insuranceLoading || addonsLoading || selectedInsuranceLoading) {
    return (
      <Grid item xs={12} container justifyContent={"center"} alignItems={"center"} style={{ margin: "0 auto", marginTop: "20px", height: "50vh" }}>
        <CircularProgress />
      </Grid>
    )


  }

  return <>
    <Grid style={{ marginBottom: 0 }} container item xs={12}>
      <Grid container item xs={12}>
        {/* Need to remove because steeper already have label */}
        {/* <Typography variant="h2">{"ADD-ONS"}</Typography> */}
      </Grid>
      <Grid container>
        <Box mt={2}></Box>
      </Grid>
      {
        !insuranceAddOns.length && !activatedAddons.length && (
          <Grid container item xs={12}>
            <Typography variant="h4">No available add-ons found matching your search criteria</Typography>
          </Grid>
        )
      }
      <Grid container item xs={12} sm={12} spacing={2}>
        {(insuranceAddOns.length > 0) && (
          <Grid container item xs={12}>
            <Paper
              className={classes.root}
              elevation={3}
              style={{ background: values.insurancePolicy ? "#FFF6E3" : "" }}
            >
              <InsuranceCard
                insuranceAddOns={insuranceAddOns}
                onSelect={onInsuranceAddonSelect}
                selectedAddon={values.insurancePolicy}
                isCoi={values.isCoi}
                onSelectCoi={onCoiSelect}
              />
            </Paper>
          </Grid>
        )}
        {activatedAddons.map((addonType: IAddonType, index: number) => {
          const selectedAddon = selectedAddons.find(
            (ao) => ao.addon === addonType.id
          );
          if (addonType.name === DefaultAddons.VALET) {
            return (
              <Grid container item xs={12} sm={12} md={12} lg={12} key={index}>
                <ValetCard
                  addon={addonType}
                  bookingData={values}
                  onSelect={onValetAddonSelect}
                  selectedAddon={selectedAddon}
                />
              </Grid>
            );
          } else if (addonType.name === DefaultAddons.ADDITIONAL_DRIVER) {
            return (
              <Grid container item xs={12} sm={12} md={12} lg={12} key={index}>
                <Paper
                  className={classes.root}
                  elevation={3}
                  style={{
                    background:
                      addonType.name === selectedAddon?.name ? "#FFF6E3" : ""
                  }}
                >
                  <CustomCard
                    addon={addonType}
                    rateTypeName={props.bookingData.rateTypeName}
                    onSelect={onCustomAddonSelect}
                    selectedAddon={selectedAddon}
                  />
                </Paper>
              </Grid>
            );
          }
          else {
            if (addonType.availableQuantity) {
              return (
                <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                  <Paper
                    className={classes.root}
                    elevation={3}
                    style={{
                      background:
                        addonType.name === selectedAddon?.name ? "#FFF6E3" : ""
                    }}
                  >
                    <CustomCard
                      addon={addonType}
                      rateTypeName={props.bookingData.rateTypeName}
                      onSelect={onCustomAddonSelect}
                      selectedAddon={selectedAddon}
                    />
                  </Paper>
                </Grid>
              );
            }
          }
        })}
      </Grid>
      <Grid container>
        <Box mt={2}></Box>
      </Grid>
      <Hidden smDown>
        <Grid container item xs={12} justifyContent={"flex-end"}>
          {/* No need for this button, as the step is now clickable in the stepper.  */}
          {/* <Fab
            className="blackBackButton"
            variant="extended"
            size="medium"
            onClick={handlePrevious}
          >
            BACK
          </Fab> */}
          <Fab
            variant="extended"
            size="medium"
            onClick={handleSubmit}
          >
            CONTINUE
          </Fab>
        </Grid>
      </Hidden>
    </Grid>

    <Hidden smUp>
      <Grid item xs={12} />
      <AppBar position="fixed" className={classes.appBar}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={6}>
            <Button
              className={classes.bottomButton}
              variant="contained"
              aria-label="Back"
              onClick={handlePrevious}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.bottomButton}
              variant="contained"
              aria-label="Continue"
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </AppBar>
    </Hidden>
  </>;
};
