import {
  Box,
  Fab,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { Button, DropZone, Flex, VisuallyHidden, Text } from "@aws-amplify/ui-react";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { CircularProgress } from "@mui/material";
import '@aws-amplify/ui-react/styles.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documents: {
      alignItems: "center",
      display: "flex"
    },
    download: {
      marginLeft: "10px"
    },
    picker: {
      width: "300px"
    },
    warning: {
      color: theme.palette.primary.dark
    }
  })
);

interface IViewTermsAndConditionsProps {
  termsAndConditions: any;
  downloadTermsAndConditions: (termsAndConditions: any) => void;
  removeTermsAndConditions: () => void;
  disabled?: boolean;
}

export const ViewTermsAndConditions = (props: IViewTermsAndConditionsProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.documents}
      >
        <Typography variant="subtitle2">Terms And Conditions</Typography>
        <Fab
          size="small"
          color="primary"
          aria-label="download"
          className={classes.download}
          onClick={() => {
            // console.log(props.termsAndConditions)
            props.downloadTermsAndConditions(props.termsAndConditions)
          }}
        >
          <CloudDownloadIcon />
        </Fab>
      </Grid>
    </React.Fragment>
  );
};

// const acceptedFileTypes = ['image/png', 'image/jpeg'];

interface IUploadTermsAndConditionsProps {
  termsAndConditions?: any;
  handleOnPick: (file: any) => void;
  disabled?: boolean;
  isClicked?: Boolean;
}

export const UploadTermsAndConditions = (
  props: IUploadTermsAndConditionsProps
) => {

  const [files, setFiles] = React.useState<any>([]);
  const hiddenInput = React.useRef<any>(null);

  function onPick(event: any) {
    const file = event.file;
    props.handleOnPick(file);
  }

  const onFilePickerChange = (event: any) => {
    const { files } = event.target;
    if (!files || files.length === 0) {
      return;
    }
    setFiles(Array.from(files[0]));
    props.handleOnPick(files[0])
  };

  return (
    <React.Fragment>
      <Grid container alignItems="center">
        {props.isClicked && (
          <CircularProgress
            size={20}
            style={{ color: "white", marginRight: "10px" }}
          />
        )}
        {<>
          {!props.isClicked &&
            <DropZone
              acceptedFileTypes={["application/pdf"]}
              onDropComplete={({ acceptedFiles, rejectedFiles }) => {
                setFiles(acceptedFiles);
              }}
            >
              <Flex direction="column" alignItems="center">
                <Text>Drag images here or</Text>
                <Button size="small" onClick={() => hiddenInput?.current.click()}>
                  Browse
                </Button>
              </Flex>
              <VisuallyHidden>
                <input
                  type="file"
                  tabIndex={-1}
                  ref={hiddenInput}
                  onChange={onFilePickerChange}
                  multiple={true}
                // accept={acceptedFileTypes.join(',')}
                />
              </VisuallyHidden>
            </DropZone>
          }
          {props.isClicked &&
            <Typography variant="body2">
              <strong>The File is being loaded please wait</strong>
            </Typography>
          }
        </>
        }
      </Grid>
      <Grid item xs={12}>
        <Box mt={1}></Box>
      </Grid>
      <Grid item>
        <Typography variant="h4">
          Supported file format: .pdf, .png, .jpg, .jpeg
        </Typography>
        <Typography variant="h4">
          Maximum supported file size of 50 MB for PDF.
        </Typography>
        <Typography variant="h4">
          Maximum supported file size of 5 MB for image files.
        </Typography>
      </Grid>
    </React.Fragment>
  );
};
