import {
  IBranch,
  IOrganisation,
  ITenancy,
  IUser
} from "../../reducers/user/types";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_TENANCY = "UPDATE_TENANCY";
export const UPDATE_USER_BRANCH = "UPDATE_USER_BRANCH";
export const UPDATE_TENANCY_ORGANISATION = "UPDATE_TENANCY_ORGANISATION";
export const UPDATE_USER_CURRENT_BRANCH = "UPDATE_USER_CURRENT_BRANCH";

interface IUpdateUser {
  type: typeof UPDATE_USER;
  payload: IUser;
}

interface IUpdateTenancy {
  type: typeof UPDATE_TENANCY;
  payload: ITenancy;
}

interface IUpdateUser {
  type: typeof UPDATE_USER;
  payload: IUser;
}

interface IUpdateUserBranch {
  type: typeof UPDATE_USER_BRANCH;
  payload: IBranch;
}
interface IUpdateUserCurrentBranch {
  type: typeof UPDATE_USER_CURRENT_BRANCH;
  payload: IBranch;
}
interface IUpdateTenancyOrganisation {
  type: typeof UPDATE_TENANCY_ORGANISATION;
  payload: IOrganisation;
}
export type UserAction =
  | IUpdateUser
  | IUpdateTenancy
  | IUpdateUserBranch
  | IUpdateUserCurrentBranch
  | IUpdateTenancyOrganisation;
