import {
  Checkbox,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  FormControlLabel,
  FormGroup,
  Fab,
  Box,
  CircularProgress
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-mui";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { DvlaVerificationStatus } from "../../../../reducers/customer/const";
import CheckIcon from "../../../../assets/icons/check-circle.svg";
import CloseIcon from "../../../../assets/icons/close-circle.svg";

interface IProps {
  description: string;
  isOpen: boolean;
  title: string;
  data: IDvlaCheckValues;
  verificationStatus: string;
  onCancel: () => void;
  onConfirm: (data: IDvlaCheckValues) => void;
}

export interface IDvlaCheckValues {
  licenseNumber: string;
  insuranceNumber: string;
  postCode: string;
}

const DvlaVerificationDialog: React.FC<IProps> = (props) => {
  const [values, setValues] = useState<IDvlaCheckValues>({
    licenseNumber: "",
    insuranceNumber: "",
    postCode: ""
  })
  const [agreed, setAgreed] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const organisation = userState.currentOrganisation;
  const isOpen = props.isOpen;

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (props.data) {
      setValues(props.data)
    }
  }, [props.data])

  const handleConfirm = (data: IDvlaCheckValues) => {
    const dvlaCheckValues: IDvlaCheckValues = {
      ...data,
      licenseNumber: data.licenseNumber.replaceAll(" ", ""),
      insuranceNumber: data.insuranceNumber.replaceAll(" ", "")
    };
    props.onConfirm(dvlaCheckValues);
  }

  const handleClose = () => {
    setOpen(false);
    props.onCancel();
    setAgreed(false);
    setValues({
      licenseNumber: "",
      insuranceNumber: "",
      postCode: ""
    })
  };

  const validationSchema = Yup.object().shape({
    licenseNumber: Yup.string().required("Licence Number is required"),
    insuranceNumber: Yup.string().required("Insurance Number is required"),
    postCode: Yup.string().required("Post Code is required")
  });

  return (
    <Dialog
      open={open}
      maxWidth={"sm"}
      onClose={() => { }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        height: "inherit"
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h2">
          {props.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {props.verificationStatus ?
          <>
            {props.verificationStatus === DvlaVerificationStatus.PENDING && <>
              <Grid container xs={12} justifyContent="center">
                <Typography variant="h2">
                  Licence Checking In Progress
                </Typography>
              </Grid>
              <Grid container xs={12} justifyContent="center">
                <Typography variant="body1">
                  It may take up to 2 minutes, please wait and do not refresh or close your browser
                </Typography>
              </Grid>
              <Grid container xs={12} justifyContent="center">
                <CircularProgress />
              </Grid>
            </>}
            {props.verificationStatus === DvlaVerificationStatus.SUCCEEDED && <>
              <Grid container xs={12} justifyContent="center">
                <Typography variant="h2">
                  Licence Checked Successfully
                </Typography>
              </Grid>
              <Grid container xs={12} justifyContent="center">
                <Typography variant="body1">
                  Licence Summary added to customer record
                </Typography>
              </Grid>
              <Grid container xs={12} justifyContent="center">
                <img
                  style={{ width: 100, height: 100, margin: "1rem", verticalAlign: 'middle' }}
                  src={CheckIcon}
                  alt="Succeeded"
                />
              </Grid>
              <Grid container xs={12} justifyContent="center">
                <Fab
                  size="medium"
                  variant="extended"
                  className="blackBackButton"
                  onClick={handleClose}
                >
                  Close
                </Fab>
              </Grid>
            </>}
            {props.verificationStatus === DvlaVerificationStatus.FAILED && <>
              <Grid container xs={12} justifyContent="center">
                <Typography variant="h2">
                  Licence Checking Failed
                </Typography>
              </Grid>
              <Grid container xs={12} justifyContent="center">
                <Typography variant="body1">
                  Please check if you have entered the correct details.
                </Typography>
              </Grid>
              <Grid container xs={12} justifyContent="center">
                <Typography variant="body1">
                  If the issue still persists please try again later.
                </Typography>
              </Grid>
              <Grid container xs={12} justifyContent="center">
                <img
                  style={{ width: 100, height: 100, margin: "1rem", verticalAlign: 'middle' }}
                  src={CloseIcon}
                  alt="Failed"
                />
              </Grid>
              <Grid container xs={12} justifyContent="center">
                <Fab
                  size="medium"
                  variant="extended"
                  className="blackBackButton"
                  onClick={handleClose}
                >
                  Close
                </Fab>
              </Grid>
            </>}
          </> :
          <>
            <Grid item xs={12}>
              <Typography>
                {props.description}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={values}
                onSubmit={(data, { setSubmitting }) => {
                  handleConfirm(data);
                  setSubmitting(false);
                }}
              >
                {(formikProps) => (
                  <Form>
                    <Grid container xs={12} spacing={2}>
                      <Grid item xs={7}>
                        <Field
                          component={InputField}
                          placeholder="Driving Licence Number"
                          label="Driving Licence Number"
                          name={"licenseNumber"}
                          inputProps={{
                            value: formikProps.values.licenseNumber,
                            onChange: formikProps.handleChange
                          }}
                          fullWidth
                          required
                        ></Field>
                      </Grid>

                      <Grid item xs={7}>
                        <Field
                          component={InputField}
                          placeholder="National Insurance Number"
                          label="National Insurance Number"
                          name={"insuranceNumber"}
                          inputProps={{
                            value: formikProps.values.insuranceNumber,
                            onChange: formikProps.handleChange
                          }}
                          fullWidth
                          required
                        ></Field>
                      </Grid>
                      <Grid item xs={7}>
                        <Field
                          component={InputField}
                          placeholder="PostCode"
                          label="PostCode"
                          name={"postCode"}
                          inputProps={{
                            value: formikProps.values.postCode,
                            onChange: formikProps.handleChange
                          }}
                          fullWidth
                          required
                        ></Field>
                      </Grid>
                      <Grid item xs={12} >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={agreed}
                                onChange={(e: any) => {
                                  setAgreed(e.target.checked)
                                }}
                                value={agreed}
                                color="primary"
                                name={"agreed"}
                              />
                            }
                            label={
                              <Typography variant="body1">
                                This agreement allows {organisation.name} and our insurance partner to carry out checks
                                on your driving licence and pass the data provided by you to insurers and other related third
                                parties (Coastr) for the administration of your vehicle rental, insurance policy, etc.
                                Do you accept and consent to the above?
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item container xs={12} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                        <Grid item>
                          <Fab
                            size="medium"
                            variant="extended"
                            disabled={
                              !agreed || Boolean(Object.keys(formikProps.errors).length)
                            }
                            type="submit"
                          >
                            Proceed
                          </Fab>
                        </Grid>
                        <Grid item>
                          <Fab
                            size="medium"
                            variant="extended"
                            className="blackBackButton"
                            onClick={handleClose}
                          >
                            Cancel
                          </Fab>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </>
        }
      </DialogContent>
    </Dialog>
  )
}

export default DvlaVerificationDialog;