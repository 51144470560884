import { gql } from "@apollo/client";

export const CREATE_VEHICLE_OWNERSHIP = gql`
  mutation createVehicleOwnership($vehicleId: ID!, $vehicleOwnershipInput: VehicleOwnershipInput!) {
    createVehicleOwnership(vehicleId: $vehicleId, vehicleOwnershipInput: $vehicleOwnershipInput) {
      firstName
      lastName
      ownershipNumber
      email
      address {
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      phoneNumber {
        phone
        country
      }
    }
  }
`;

export const UPDATE_VEHICLE_OWNERSHIP = gql`
  mutation updateVehicleOwnership($vehicleId: ID!, $updateVehicleOwnershipInput: UpdateVehicleOwnershipInput!){
    updateVehicleOwnership(vehicleId: $vehicleId, updateVehicleOwnershipInput: $updateVehicleOwnershipInput) {
      firstName
      lastName
      phoneNumber {
        phone
        country
      }
      email
      address {
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      ownershipNumber
    }
  }
`;