import "./index.scss";
import * as Yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Fab,
  Grid,
  Theme,
  Typography,
  alpha,
  MenuItem,
  Paper,
  TextField as InputField,
  CircularProgress,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { TextField } from "formik-mui";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { fuelTypes } from "../../utils";
import { CREATE_GHOST_VEHICLES } from "../../../../../graphql/fleet/createGhostVehiclesMutation";
import { Field, Form, Formik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { IVehiclePriceGroup } from "../../../../../reducers/vehiclePriceGroups/types";
import { GET_VEHICLE_GROUPS_PRICE } from "../../../../../graphql/priceRules/getVehiclesGroupsQuery";
import { useNavigate } from "react-router-dom";
import { DateTime as d } from "luxon";
import { IDateTime } from "../../../../../reducers/jobSchedular/types";
import { SimpleDateTimePicker } from "../../../../common/SimpleDateTimePicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      marginLeft: "50px",
      marginTop: "100px"
    },
    header: {
      color: "rgba(0, 0, 0, 0.7) !important",
      fontSize: "30px !important",
      marginBottom: "24px !important"
    },
    saveButton: {
      backgroundColor: "#faaf40",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    saveButtonDisabled: {
      backgroundColor: "#d3d3d3",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 100px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    addBottomButton: {
      backgroundColor: "var(--ion-color-success)",
      "&:hover": {
        backgroundColor: "var(--ion-color-success-shade)"
      },
      position: 'relative',
      width: 'calc(50vw - 100px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    addBarButton: {
      backgroundColor: "var(--ion-color-success)",
      "&:hover": {
        backgroundColor: "var(--ion-color-success-shade)"
      },
      position: 'relative',
      width: 'calc(100vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    text: {
      position: 'relative',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    active: {
      fontWeight: "bold",
      fontSize: "initial !important"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    dialogSmallInput: {
      minWidth: 'calc(50vw - 48px)',
      maxWidth: 'calc(50vw - 48px)'
    },
    root: {
      padding: theme.spacing(3)
    },
    dialogLargeInput: {
      minWidth: 'calc(100vw - 80px)',
      maxWidth: 'calc(100vw - 80px)'
    }
  })
);

const initialValues = {
  numberOfDoors: 0,
  numberOfSeats: 0,
  make: "",
  model: "",
  fuelType: "",
  startDate: "",
  bodyType: "",
  transmission: "",
  vehicleGroups: [],
  quantity: 0,
}
interface IGhostValues {
  numberOfDoors: number,
  numberOfSeats: number,
  make: string,
  model: string,
  fuelType: string,
  startDate: string,
  bodyType: string,
  transmission: string,
  vehicleGroups: string[]
  quantity: number
}

export const GhostVehicle = React.memo(
  (props: any) => {
    const classes = useStyles();
    const snackbar = useSnackBar();
    const navigate = useNavigate();
    const [vehicleGroups, setVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
    const [selectedVehicleGroups, setSelectedVehicleGroups] = useState<IVehiclePriceGroup[]>([])
    const userState = useSelector((state: IAppState) => state.userReducer);
    const [values, setValues] = useState<IGhostValues>(
      initialValues
    );

    const [createVehicle, { loading: createVirtualVehicleLoading }] = useMutation(CREATE_GHOST_VEHICLES, {
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        }),
      onCompleted: () => {

        snackbar({
          message: "New virtual vehicles created",
          variant: SnackBarVariant.SUCCESS
        });
        navigate("/vehicle-inventory");
      }
    });

    const [loadVehicleGroups,
      { data: vehicleGroupsData }
    ] = useLazyQuery(
      GET_VEHICLE_GROUPS_PRICE, {
      fetchPolicy: "network-only"
    });
    const GhostVehicleSchema = Yup.object().shape({
      make: Yup.string().max(15, "Make must be 15 characters or less").required("Make is required"),
      model: Yup.string().max(15, "Model must be 15 characters or less").required("Model is required"),
      bodyType: Yup.string().max(15, "Body type must be 15 characters or less").required("Body type is required"),
      transmission: Yup.string().max(15, "Transmission must be 15 characters or less").required("Transmission is required"),
      fuelType: Yup.string().required("Fuel type is required"),
      quantity: Yup.number().integer().min(1, "Quantity must be greater than zero").required("Quantity is required"),
      vehicleGroups: Yup.array().min(1, "Select at least one vehicle group").required("Select at leat one vehicle group"),
      startDate: Yup.date().required("Commence date is required"),
    })

    useEffect(() => {
      if (userState.tenancy) {
        loadVehicleGroups()
      }
    }, [userState.tenancy])

    useEffect(() => {
      if (vehicleGroupsData && vehicleGroupsData.vehicleGroups) {
        const sortedGroups = [...vehicleGroupsData.vehicleGroups].sort((firstGroup: IVehiclePriceGroup, secondGroup: IVehiclePriceGroup) => {
          return firstGroup.name.toLowerCase().localeCompare(secondGroup.name.toLowerCase());
        })
        setVehicleGroups(sortedGroups)
      }
    }, [vehicleGroupsData])


    const handleFormSubmit = (values: any) => {
      createVehicle({
        variables: {
          data: values
        }
      })
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid container item xs={12} alignItems="center">
            <Typography variant="h1" color="primary">
              Fleet Inventory
            </Typography>
            <Box color="white" sx={{ pr: 1 }}></Box>
            <DoubleArrowIcon />
            <Box color="white" sx={{ pl: 1 }}></Box>

            <Typography variant="h1" color="primary">
              Add Virtual
            </Typography>
            <Typography variant="h1" color="primary">
              &nbsp;vehicles
            </Typography>
          </Grid>

          <Grid container item xs={12}>
            <Paper className={classes.root}>
              <Formik
                enableReinitialize
                validationSchema={GhostVehicleSchema}
                initialValues={values}
                onSubmit={(data, { setSubmitting }) => {

                  handleFormSubmit(data);
                  setSubmitting(false);
                }}
              >
                {formikprops => (
                  <Form noValidate>
                    <Grid container spacing={2}>
                      <Grid item container xs={12} spacing={2}>
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <Field
                            component={TextField}
                            placeholder="eg. KIA"
                            label="Make"
                            name={"make"}
                            fullWidth
                            value={values.make}
                            onChange={(event: any) => {
                              setValues({
                                ...values,
                                make: event.target.value
                              })
                            }}
                            inputProps={{
                              maxLength: 30
                            }}
                            required
                          ></Field>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <Field
                            component={TextField}
                            placeholder="eg. CEED"
                            label="Model"
                            name={"model"}
                            fullWidth
                            value={values.model}
                            onChange={(event: any) => {
                              setValues({
                                ...values,
                                model: event.target.value
                              })
                            }}
                            inputProps={{
                              maxLength: 30
                            }}
                            required
                          ></Field>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Field
                            component={TextField}
                            label="Body Type"
                            name={"bodyType"}
                            required
                            value={values.bodyType}
                            onChange={(event: any) => {
                              setValues({
                                ...values,
                                bodyType: event.target.value
                              })
                            }}
                            inputProps={{ maxLength: 30 }}
                            fullWidth
                          ></Field>
                        </Grid>

                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <Field
                            component={TextField}
                            placeholder="auto"
                            label="Transmission"
                            name={"transmission"}
                            fullWidth
                            required
                            value={values.transmission}
                            onChange={(event: any) => {
                              setValues({
                                ...values,
                                transmission: event.target.value
                              })
                            }}

                          ></Field>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <Field
                            component={TextField}
                            placeholder="eg. 4"
                            label="Number Of Doors"
                            name={"numberOfDoors"}
                            fullWidth
                            value={values.numberOfDoors}
                            onChange={(event: any) => {
                              const value = parseInt(event.target.value);
                              if (event.target.value === "") {
                                setValues({
                                  ...values,
                                  numberOfDoors: 0
                                })
                              }
                              if (!isNaN(value) && value >= 0) {
                                setValues({
                                  ...values,
                                  numberOfDoors: parseInt(event.target.value)
                                })
                              }
                            }}

                          ></Field>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <Field
                            component={TextField}
                            placeholder="eg. 4"
                            label="Number Of Seats"
                            name={"numberOfSeats"}
                            fullWidth
                            value={values.numberOfSeats}
                            onChange={(event: any) => {
                              const value = parseInt(event.target.value);
                              if (event.target.value === "") {
                                setValues({
                                  ...values,
                                  numberOfSeats: 0
                                })
                              }
                              if (!isNaN(value) && value >= 0) {
                                setValues({
                                  ...values,
                                  numberOfSeats: parseInt(event.target.value)
                                })
                              }
                            }}
                          ></Field>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Field
                            component={TextField}
                            label="Fuel Type"
                            name={"fuelType"}
                            required
                            value={values.fuelType}
                            onChange={(event: any) => {
                              setValues({
                                ...values,
                                fuelType: event.target.value
                              })
                            }}
                            select
                            inputProps={{ maxLength: 30 }}
                            fullWidth
                          >
                            {fuelTypes.map((fuelType) => {
                              return (
                                <MenuItem key={fuelType.value} value={fuelType.value}>
                                  {fuelType.label}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <SimpleDateTimePicker
                            date={values.startDate}
                            handleChange={(date: IDateTime) => {
                              console.log(date.date)
                              setValues({
                                ...values,
                                startDate: d.fromISO(date.date).toUTC().toISO()
                              })
                            }}
                            required={true}
                            disabled={false}
                            name={"startDate"}
                            dateTitle={"Commence Date"}
                            timeTitle={"Commence Time"}
                            minDate={d.now().toUTC().toISO()}
                          />
                          {formikprops.touched.startDate && formikprops.errors.startDate && (
                            <Typography style={{ fontSize: 'smaller' }} color="error">{formikprops.errors.startDate}</Typography>
                          )}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}></Grid>
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            multiple
                            freeSolo
                            id="free-solo-vehicle-group-search"
                            disableClearable
                            options={vehicleGroups}
                            getOptionLabel={(option: any) => {
                              return option.name
                            }
                            }
                            filterSelectedOptions
                            onChange={(_: any, newValues: any, reason) => {
                              if (reason === "selectOption") {
                                setValues({
                                  ...values,
                                  vehicleGroups: [
                                    ...values.vehicleGroups,
                                    newValues[newValues.length - 1].id
                                  ]
                                })
                              }
                              if (reason === "removeOption") {
                                const updatedVgs = newValues.map((value: any) => value.id)
                                setValues({
                                  ...values,
                                  vehicleGroups: updatedVgs
                                })
                              }
                              setSelectedVehicleGroups(newValues)
                            }}
                            value={selectedVehicleGroups}
                            defaultValue={[]}
                            renderInput={(params) => (
                              <InputField
                                {...params}
                                label="Select Vehicle Group(s)*"
                                variant="outlined"
                                placeholder={"Select Vehicle Group(s)"}
                                InputProps={{ ...params.InputProps, type: "search" }}
                              />
                            )}
                            onBlur={() => formikprops.setFieldTouched("vehicleGroups", true)}
                          />
                          {values.vehicleGroups.length === 0 && formikprops.touched.vehicleGroups && (
                            <Typography style={{ fontSize: 'smaller', position: 'relative' }} color="error">{formikprops.errors.vehicleGroups}</Typography>
                          )}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} lg={4} xl={4}>
                          <Field
                            component={TextField}
                            placeholder="eg.5"
                            label="Quantity"
                            name="quantity"
                            fullWidth
                            required
                            value={formikprops.values.quantity}
                            onChange={(event: any) => {
                              const value = parseInt(event.target.value);
                              if (event.target.value === "") {
                                setValues({
                                  ...values,
                                  quantity: 0
                                })
                              }
                              if (!isNaN(value) && value >= 0) {
                                setValues({
                                  ...values,
                                  quantity: parseInt(event.target.value)
                                })
                              }
                            }}
                          />


                        </Grid>
                        <Grid item container xs={12} justifyContent="flex-start">
                          <Fab
                            variant="extended"
                            size="medium"
                            type="submit"
                            aria-label="add"
                            disabled={createVirtualVehicleLoading}
                          >
                            {createVirtualVehicleLoading && (
                              <CircularProgress
                                size={14}
                                style={{ color: "white", marginRight: "10px" }}
                              />
                            )}
                            Add Vehicle
                          </Fab>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </>
    )
  });

export default GhostVehicle;
