import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, Fab, FormControl, Grid, MenuItem, TextField, Typography, Theme
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { ImageView } from "./ImagesView";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { svgHeight } from ".";
import { IFrogDiagram } from "../../../../common/VehicleDamagePreview/frogDiagram";
import { coordinateOffset } from "../../../../common/utils";
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import DragDropImages from "../../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";
import { captureErrorException } from "../../../../../utils/sentry";
export interface IDamage {
  id?: string;
  title: string;
  descriptions: string;
  damageType: DamageTypes;
  damageKind: string;
  damageArea?: string;
  referenceNumber?: string;
  images: string[];
  location: {
    x: number;
    y: number;
  }
}
enum DamageTypes {
  EXTERIOR = "exterior damage",
  INTERIOR = "interior damage",
}
interface IProps {
  title: string;
  handleClose: () => void;
  handleSubmit: (values: IDamage, index?: number) => void;
  open: boolean;
  images: (images: string[]) => void;
  svg: any;
  values?: IDamage;
  index?: number | null;
  damageBranchId?: string;
}
const damageKind = {
  "interior damage": [
    { label: 'Broken', value: 'BROKEN' },
    { label: 'Tear', value: 'TEAR' },
    { label: 'Burnt', value: 'BURNT' },
    { label: 'Cracked', value: 'CRACKED' },
    { label: 'Gum', value: 'GUM' },
    { label: 'Spill', value: 'SPILL' },
    { label: 'Other', value: 'OTHER' },
  ],
  "exterior damage": [{ label: 'Scratch', value: 'SCRATCH' },
  { label: 'Dent', value: 'DENT' },
  { label: 'Chip', value: 'CHIP' },
  { label: 'Scuff', value: 'SCUFF' },
  { label: 'Other', value: 'OTHER' }]
};
const damageArea = [
  { label: 'Dashboard', value: 'Dashboard' },
  {
    label: 'Steering-Wheel',
    value: 'Steering-Wheel',
  },
  { label: 'Seat', value: 'Seat' },
  { label: 'Carpet', value: 'Carpet' },
];
const defaultDamage = {
  title: "",
  descriptions: "",
  damageType: DamageTypes.EXTERIOR,
  damageKind: "",
  damageArea: "",
  images: [],
  location: {
    x: 0,
    y: 0
  }
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

export const AddDamage: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [openDragDrop, setOpenDragDrop] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>([]);
  const [imageType, setImageType] = useState<string>("");
  const [damageCoordinate, setDamageCoordinate] = useState<any>();
  const svgRef = useRef<any>(null);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [values, setValues] = useState<IDamage>(props.values || defaultDamage);
  const [svg, setSvg] = useState<IFrogDiagram>();
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);

  useEffect(() => {
    if (
      props.values &&
      props.values.images &&
      props.values.images.length > 0
    ) {
      const promises: any[] = [];
      const modifyImages = JSON.parse(
        JSON.stringify(props.values.images)
      );
      props.values.images.map(
        (id: string, index: number) => {
          promises.push(
            (async () => {
              try {
                const data: any = await getImages(id);
                modifyImages[index] = data;
              } catch (error) {
                captureErrorException(error)
              }
            })()
          );
        }
      );
      Promise.all(promises).then(() => {
        setImages(modifyImages);
      });
    }
    if (props.svg && props.values && props.values.location) {
      const location = {
        x: props.values.location.x + coordinateOffset,
        y: props.values.location.y + coordinateOffset
      }
      setSvg(props.svg);
      if (props.values?.damageType === DamageTypes.EXTERIOR) {
        addMark(location, props.svg.exterior.markerRadius, props.svg.exterior.markerFont)
      } else if (props.values?.damageType === DamageTypes.INTERIOR) {
        addMark(location, props.svg.interior.markerRadius, props.svg.interior.markerFont)
      }
    }
  }, [props.values])
  const handleOpenDragDrop = (type: string) => {
    setImageType(type);
    setOpenDragDrop(true);
  };
  const handleCloseDragDrop = () => {
    setOpenDragDrop(false);
  };

  const addMark = (damage: any, markerRadius: number, markerFont: number) => {
    const xVal = parseInt(damage.x);
    const yVal = parseInt(damage.y);
    const mark = (<g id={`damage-mark`} style={{ cursor: "pointer" }}>
      <circle cx={`${xVal}`} cy={`${yVal}`} r={`${markerRadius}`} fill="red" stroke-width="0.5" />
      <text text-anchor="middle" x={`${xVal}`} y={`${yVal}`} dy="0.30em" font-size={`${markerFont}em`} stroke-width="0.8" stroke="white" fill="white">
      </text>
    </g>);
    setDamageCoordinate(mark);
  }
  const getImages = async (key: string) => {
    const file = await getSignedUrl(key)
    return file;
  };
  const damageSchema = Yup.object().shape({
    title: Yup.string().required("Title is required."),
    damageKind: Yup.string().required("Damage Kind is required."),
    descriptions: Yup.string().required("Description is required."),
    images: Yup.array().of(Yup.string().required()).min(1, "Damage image is required.")
  });
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        scroll="paper"
        aria-labelledby="max-width-dialog-title"
        maxWidth={"lg"}
      >
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={damageSchema}
          onSubmit={(values) => {
            if (props.index != null) {
              props.handleSubmit(values, props.index);
            } else {
              props.handleSubmit(values);
            }
          }}
        >
          {formikProps => (
            <Form>
              <DialogTitle style={{ padding: '16px 35px' }} id="max-width-dialog-title">
                <Typography variant="h4" style={{ fontWeight: 700, fontSize: 16 }}>
                  {props.title}
                </Typography>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid container item xs={4}>
                    <Field
                      name={"title"}
                      inputProps={{
                        value: formikProps.values.title,
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setValues({
                            ...formikProps.values,
                            title: event.target.value
                          });
                        }
                      }}
                      InputProps={{ maxLength: 30 }}
                    >
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <TextField
                            {...field}
                            error={touched && Boolean(error)}
                            placeholder="eg. NUVV120"
                            label="Title"
                            required
                            fullWidth
                          >
                          </TextField>
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name={`title`}
                      component="div"
                    >
                      {msg => <div className={"field-error-message"}>{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid container item xs={4}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        name={"damageKind"}
                        fullWidth
                        required
                        type="text"
                        select
                        label={`Damage Kind`}
                        value={formikProps.values.damageKind}
                        onChange={(event: any) => {
                          formikProps.setFieldValue("damageKind", event.target.value);
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                      >
                        {damageKind[formikProps.values.damageType]?.map((item: any, index: number) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name={`damageKind`}
                        component="div"
                      >
                        {msg => <div className={"field-error-message"}>{msg}</div>}
                      </ErrorMessage>
                    </FormControl>
                  </Grid>
                  {formikProps.values.damageType === DamageTypes.INTERIOR && (<Grid container item xs={4}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        name={"damageArea"}
                        fullWidth
                        type="text"
                        select
                        label="Damage Area"
                        value={formikProps.values.damageArea}
                        onChange={(event: any) => {
                          formikProps.setFieldValue("damageArea", event.target.value);
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                      >
                        {damageArea.map((item: any, index: number) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>
                  </Grid>)}
                  <Grid container item xs={12}>
                    <Field
                      name={"descriptions"}
                      value={formikProps.values.descriptions}
                      onChange={(event: { target: { value: any; }; }) => {
                        formikProps.setFieldValue("descriptions", event.target.value);
                      }}
                      inputProps={{ maxLength: 500 }}
                    >
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <TextField
                            {...field}
                            error={touched && Boolean(error)}
                            placeholder="eg. NUVV120"
                            label="Damage Detail"
                            multiline
                            rows={3}
                            required
                            fullWidth
                          >
                          </TextField>
                          <ErrorMessage
                            name={`descriptions`}
                            component="div"
                          >
                            {msg => <div className={"field-error-message"}>{msg}</div>}
                          </ErrorMessage>
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid container item xs={12} >
                    <div className={classes.root}>
                      <Grid container item xs={12} sm={12}>
                        <Grid container item xs={12} sm={6}>
                          <Grid item xs={12} sm={12}>
                            {formikProps.values.damageType === DamageTypes.EXTERIOR && svg?.exterior.svg &&
                              (<svg xmlns="http://www.w3.org/2000/svg"
                                style={{ height: `${svgHeight}px`, marginBottom: "20px" }}
                                ref={svgRef}
                                onClick={event => {
                                  const rect = svgRef.current.getBoundingClientRect();
                                  const location = { x: (event.clientX - rect.left) * svg?.exterior.width / rect.width, y: (event.clientY - rect.top) * svg?.exterior.height / svgHeight };
                                  addMark(location, svg?.exterior.markerRadius, svg?.exterior.markerFont)
                                  formikProps.setFieldValue("location", { x: location.x - coordinateOffset, y: location.y - coordinateOffset });
                                }}
                                viewBox={`0 0 ${svg?.exterior.width} ${svg?.exterior.height}`}>
                                {svg?.exterior.svg}
                                {damageCoordinate}
                              </svg>)}
                            {formikProps.values.damageType === DamageTypes.INTERIOR && svg?.interior.svg &&
                              (<svg xmlns="http://www.w3.org/2000/svg"
                                style={{ height: `${svgHeight}px`, marginBottom: "20px" }}
                                ref={svgRef}
                                onClick={event => {
                                  const rect = svgRef.current.getBoundingClientRect();
                                  const location = { x: (event.clientX - rect.left) * svg?.interior.width / rect.width, y: (event.clientY - rect.top) * svg?.interior.height / svgHeight };
                                  addMark(location, svg?.interior.markerRadius, svg?.interior.markerFont)
                                  formikProps.setFieldValue("location", { x: location.x - coordinateOffset, y: location.y - coordinateOffset });
                                }}
                                viewBox={`0 0 ${svg?.interior.width} ${svg?.interior.height}`}>
                                {svg?.interior.svg}
                                {damageCoordinate}
                              </svg>)}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid xs={12} sm={12}>
                            <Fab
                              className="interiorImageButton"
                              variant="extended"
                              size="medium"
                              disabled={(props.damageBranchId && userState.currentBranch.id !== props.damageBranchId ? true : false) || (userState.currentOrganisation.maxImagesPerDamage && userState.currentOrganisation.maxImagesPerDamage <= images.length ? true : false)}
                              onClick={() => handleOpenDragDrop("damageImages")}
                            >
                              {userState.currentOrganisation.maxImagesPerDamage && userState.currentOrganisation.maxImagesPerDamage > 1 ? 'Add Image(s)' : 'Add Image'}
                            </Fab>
                            <ErrorMessage
                              name={`images`}
                              component="div"
                            >
                              {msg => <div className={"field-error-message"}>{msg}</div>}
                            </ErrorMessage>
                          </Grid>
                          <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
                            {images && images.length > 0 && (
                              <ImageView images={images} />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <DragDropImages
                    open={openDragDrop}
                    handleCloseDragDrop={handleCloseDragDrop}
                    filesLimit={userState.currentOrganisation.maxImagesPerDamage || 1}
                    handleSave={async (files: any) => {
                      if (imageType === "damageImages") {
                        if (_e_) {
                          const client = _e_;
                          const filePromises = [];
                          const newImages: any = [];
                          const imagesView: any[] = [];
                          if (files) {
                            for (var i = 0; i < files.length; i++) {
                              const name = files[i].name;
                              const lastDot = name.lastIndexOf('.');
                              const ext = name.substring(lastDot + 1);
                              const key = `${userState.tenancy.id}/${uuidv4()}.${ext}`;
                              const promise = client
                                .add({
                                  name: key,
                                  file: files[i],
                                });
                              newImages.push(key);
                              const file = await getSignedUrl(key);
                              imagesView.push(file);
                              filePromises.push(promise);
                            }
                            handleCloseDragDrop();
                            Promise.all(filePromises).then(() => {
                              setValues({
                                ...formikProps.values,
                                images: [...newImages]
                              });
                              setImages([...imagesView]);
                              props.images([...imagesView]);
                            }).catch((err) => {
                              captureErrorException(err)
                            });
                          }
                        }
                      }
                    }}
                  />
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: '20px 35px' }}>
                <div style={{ display: 'flex', flex: 1 }}>
                </div>
                <Fab
                  className="blackBackButton"
                  variant="extended"
                  size="small"
                  onClick={props.handleClose}
                >
                  Cancel
                </Fab>
                <Fab
                  variant="extended"
                  size="small"
                  type="submit"
                  disabled={props.damageBranchId && userState.currentBranch.id !== props.damageBranchId ? true : false}
                >
                  <strong>Done</strong>
                </Fab>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

