import { gql } from "@apollo/client";

export const GET_BUSINESS_CUSTOMER_PRICE = gql`
  query businessCustomers {
    businessCustomers {
      id
      businessName
      billing{
        email
      }
    }
  }
`;
