import { gql } from "@apollo/client";

export const UPDATE_CREDIT_SAFE_CREDS = gql`
  mutation updateCreditSafeCreds(
    $creditSafeCreds: CreditSafeInput
  ) {
    updateCreditSafeCreds(creditSafeCreds: $creditSafeCreds) {
      id
      name
      emailId
      creditSafeCredentials {
        username
      }
    }
  }
`;
