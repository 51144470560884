import { gql } from "@apollo/client";

export const CREATE_VEHICLE_DAMAGE_INFO = gql`
  mutation createVehicleDamage(
    $vehicleId: String!
    $vehicledamage: VehicleDamageInput!
    $isRO: Boolean
  ) {
    createVehicleDamage(vehicleId: $vehicleId, vehicledamage: $vehicledamage, isRO: $isRO) {
      id
      vehicleId
      circumstance
      bookingRef
      reportDate
      incidentDate
      reportedBy
      frogDiagram
      approvalStatus
      estimatedCost
      status
      damageSeverity
      estimatedRepairDate
      damages {
        title
        descriptions
        damageType
        damageKind
        damageArea
        images
        location {
          x
          y
        }
      }
    }
  }
`;
