import { gql } from "@apollo/client";

export const UPDATE_PROCUREMENT_DELIVERY_INFO = gql`
  mutation updateProcurementDeliveryInfo($id: ID!, $deliveryInfoId: ID!, $deliveryInfo: ProcurementDeliveryInformationInput!) {
    updateProcurementDeliveryInfo(id: $id, deliveryInfoId: $deliveryInfoId, deliveryInfo: $deliveryInfo) {
      id
      deliveryNoteId
      deliveryNoteDate
      partnerId
      purchaseOrderId
      productReceivedDate
      receivedProductNumber
      documents
      status
    }
  }
`;