import { gql } from "@apollo/client";

export const CREATE_PARTNER = gql`
  mutation createPartner($partner: PartnerCreateInput!) {
    createPartner(partner: $partner) {
      id
      isActive
      partnerId
      partnerName
      partnerType
      address {
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      email
      phoneNumber {
        phone
        country
      }
      companyRegistrationNo
      partnerContact {
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      # documents
      # tenantId
      # organisationId
      # branchId
      createdAt
      createdBy
      updatedAt
      updatedBy
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      billingContact {
        phoneNumber {
          phone
          country
        }
        email
      }
    }
  }
`;
