import { Grid } from "@mui/material";
import React from "react";
import { useVirtualizer } from '@tanstack/react-virtual';
import { IUserState } from "../../../../../../reducers/user/types";
import MobileAdminUserCard from "../../../../../common/MobileAdminUserCard";


export const AdminUserVirtualScroller = ({ data }: { data: IUserState[] }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 140
  })

  return (
    <>
      {/* Scrollable Parent List  */}
      <Grid item xs={12}
        ref={parentRef}
        className="List"
      >
        {/* Large inner element for items */}
        <Grid container item xs={12} spacing={2}
        >
          {/* Virtualized Items */}
          {rowVirtualizer.getVirtualItems().map((virtualItem) => {
            var name = "";
            name += data[virtualItem.index].firstName + ' ' + data[virtualItem.index].lastName;
            return (
              <Grid container item xs={12} spacing={2}
                key={virtualItem.key}
              >
                <MobileAdminUserCard
                  id={data[virtualItem.index].id!}
                  name={name}
                  branch={data[virtualItem.index].branch?.name}
                  email={data[virtualItem.index].email}
                  organisation={data[virtualItem.index].organisation?.name}
                  role={data[virtualItem.index].role}
                />
                <br />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default AdminUserVirtualScroller;