import { gql } from "@apollo/client";

export const UPDATE_REFUND = gql`
  mutation updateRefund($creditNoteId: String!, $refundId: String!, $status: String!) {
    updateRefund(creditNoteId: $creditNoteId, refundId: $refundId, status: $status) {
      id
      creditNoteRef
      creditNoteType
      createdAt
      invoice {
        id
        invoiceRef
      }
      totalAmount
      refundedAmount
      availableAmount
      creditNoteItems {
        type
        description
        originalPrice
        quantity
        unitPrice
        totalAmount
        duration
      }
      refunds {
        mode
        amount
        status
        id
        refundRef
        currency
        paymentIntent
        paymentGateway
      }
    }
  }
`;