import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { GET_ALL_JOBS_FOR_PARTNER_DRIVERS as GET_ALL_JOBS_FOR_PARTNER_DRIVERS } from "../../../graphql/jobSchedular/getJobsQuery";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../common/utils";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { CircularProgress, Paper } from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../common/NuvvenTable/types";
import { NuvvenTable } from "../../common/NuvvenTable/NuvvenTable";
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";

export const PartnerJobHistory = () => {
    const snackbar = useSnackBar();
    const userState = useSelector((state: IAppState) => state.userReducer);
    const { country } = userState.currentOrganisation.address;
    const [rows, setRows] = useState<any[]>([]);

    const [
        getPartnerDriversJob,
        { loading: partnerDriversJobLoading, data: partnerDriversJobData }
      ] = useLazyQuery(GET_ALL_JOBS_FOR_PARTNER_DRIVERS, {
        fetchPolicy: "network-only",
        
        onCompleted: (partnerDriversJobData) => {
          const partnerdriverdata = reshapeDriverData(partnerDriversJobData.getAllJobsForPartnerDrivers)          
          if(partnerdriverdata) {
          setRows(partnerdriverdata);
          }
        },
        onError: (error: ApolloError) => {
          snackbar({
            message: formatGraphQLErrorMessage(error.message),
            variant: SnackBarVariant.ERROR
          });
        }
      });

      useEffect(() => {
        if (location && location.search) {
          const params = new URLSearchParams(location.search);
          const partnerId = params.get("partner");
          if (partnerId) {
            getPartnerDriversJob({
              variables: {
                partnerId: partnerId
              }
            });
          }
        }
      }, [location]);

      const reshapeDriverData = (data: any[]) => {
        return data.map((driver: any) => {
          return {
            referenceNumber: driver?.booking.referenceNumber,
            pickupDateTime: getLocalizedDateFormat(country, driver?.booking.pickupDateTime, DATE_TYPE.EXPANDED) ?? "N/A",
            driver: `${driver?.driver?.firstName} ${driver?.driver?.lastName}`,
            bookingType: driver?.booking.bookingType,
            customerName: driver?.booking?.businessCustomer
              ? driver?.booking?.businessCustomer
                  ?.businessName || ""
              : `${driver?.booking?.customer?.firstName} ${driver?.booking?.customer?.lastName}`,
            status: driver?.booking?.status || "" ,     
          };
        });
      };    

  const columns = [
    {
      label: "ID",
      name: "referenceNumber"
    },
    {
      label: "Booking Date",
      name: "pickupDateTime"
    },
    {
      label: "Driver Name",
      name: "driver"
    },
    {
      label: "Booking Type",
      name: "bookingType"
    },
    {
      label: "Customer Name",
      name: "customerName"
    },
    {
      label: "Status",
      name: "status"
    },
    // {
    //     label: "Customer rating",
    //     name: "rating",
    //   },
  ]

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    rowsPerPageOptions: [10, 20, 100],
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: partnerDriversJobLoading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
  };

  return (
    <div>
      {!(partnerDriversJobLoading && !rows.length) ? (
        <Paper variant="outlined">
          <NuvvenTable
            title={""}
            rows={rows}
            columns={columns}
            setSelection={() => { }}
            options={options}
          />
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </div>
  )

};