import axios from 'axios';
import HttpHeader from '../../../../../header';

const httpHeader = HttpHeader.getInstance();

const instance = axios.create({
  baseURL: (process.env.REACT_APP_NOTIFICATION_API_URL || "https://dev-notification-api.coastr.com"),
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(async function (config: any) {
  config.headers = {
    ...config.headers,
    ...httpHeader.headers,
    authorization: `Bearer ${httpHeader.headers.authorization}`
  };
  return config;
});

export default {
  getEmissionData: (variables: any) => instance.post(`/fleet/carbonfootprint`, variables),
};