import { gql } from "@apollo/client";

export const SHIFT_BOOKING_SCHEDULE = gql`
  mutation shiftBookingSchedule($data: ShiftBookingScheduleInput!) {
    shiftBookingSchedule(data: $data) {
      id
      vehicle {
        id
        licencePlate
      }
    }
  }
`;