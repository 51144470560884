import { gql } from "@apollo/client";

export const ADD_PROCUREMENT_QUOTE = gql`
  mutation addProcurementQuote($id: ID!, $procurementQuote: ProcurementQuoteInput!) {
    addProcurementQuote(id: $id, procurementQuote: $procurementQuote) {
      id
      quoteId
      date
      partnerId
      validUntilDate
      lastEmailSent
      note {
        description
        # createdBy {
        #   firstName
        #   lastName
        # }
        createdAt
      }
      amount
      proposalRequestId
      documents
      status
    }
  }
`;