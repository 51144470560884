import { gql } from "@apollo/client";

export const VERIFY_CUSTOMER_REQUEST = gql`
  mutation verifyCustomer($customerId: ID!, $status: String!, $note: String) {
    verifyCustomer(customerId: $customerId, status: $status, note: $note){
        success
        message
        status
        verifiedDate
    }
  }
`;