import React, { useCallback, useState, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Theme, ThemeProvider, StyledEngineProvider } from '@mui/material';
import theme from '../../Theme';
import { IAppState } from '../../../../store';
import { useSelector } from 'react-redux';
import ImagePreview from '../ImagePreview';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    border: 'dashed #C8C8C8',
    backgroundColor: '#F0F0F0',
    minHeight: '250px',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
  },
  customDialog: {
    width: 'calc(100% - 64px)',
  },
  icon: {
    fontSize: '48px',
    color: theme.palette.primary.main,
  },
  previewContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  fileInput: {
    display: 'none',
  },
  selectFileButton: {
    cursor: 'pointer',
    display: 'flex',
    color: 'grey',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
}));

interface IDropzoneDialogProps {
  open: boolean;
  handleSave: (files: File[]) => void;
  handleCloseDragDrop: () => void;
  filesLimit: number;
  title?: string;
  initialFiles?: any[];
  acceptedFiles?: string[];
}

function CustomFileUpload(props: IDropzoneDialogProps) {
  const classes = useStyles();
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const userState = useSelector((state: IAppState) => state.userReducer);

  const appTheme = {
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.primary,
        main: userState?.currentTenancy?.themeColor || "#002F6E"
      },
      secondary: {
        ...theme.palette.secondary,
        main: userState?.currentTenancy?.themeColor || "#002F6E"
      }
    }
  }

  const handleConfirm = () => {
    const updatedFiles: File[] = uploadedFiles.map((obj: any) => obj.file);

    props.handleSave(updatedFiles);
    setUploadedFiles([])
  };

  const handleClose = () => {
    setUploadedFiles([]);
    props.handleCloseDragDrop();
  }

  const onDrop = useCallback((e: any) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    if (files && files.length + uploadedFiles.length <= props.filesLimit) {
      Array.from(files).forEach((file: any) => {
        const url = URL.createObjectURL(file);
        setUploadedFiles((prevFiles: any) => [...prevFiles, { file, url }]);
      });
    }
  }, [props.handleSave, props.filesLimit, uploadedFiles]);

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
  };

  const removeFile = (file: any) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const handleFileInputChange = (e: any) => {

    const files = e.target.files;

    if (files && files.length + uploadedFiles.length <= props.filesLimit) {
      Array.from(files).forEach((file: any) => {
        const url = URL.createObjectURL(file);
        setUploadedFiles((prevFiles: any) => [...prevFiles, { file, url }]);
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <Dialog maxWidth="md" open={props.open} onClose={props.handleCloseDragDrop} classes={{ paper: classes.customDialog }}>
          <DialogTitle>{props.title}</DialogTitle>
          <DialogContent>
            <Paper
              className={classes.root}
              variant="outlined"
              onDrop={onDrop}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
            >
              <label htmlFor="file-input" className={classes.selectFileButton}>
                <input
                  type="file"
                  id="file-input"
                  className={classes.fileInput}
                  accept="image/jpeg, image/png, image/jpg"
                  onChange={handleFileInputChange}
                  ref={fileInputRef}
                  multiple
                />
                <CloudUploadIcon className={classes.icon} />
                <Typography variant="body2" color="textSecondary">
                  {`Drag and drop or click to upload (Maximum ${props.filesLimit} files allowed)`}
                </Typography>
              </label>
            </Paper>
            <div className={classes.previewContainer}>
              <ImagePreview
                uploadedFiles={uploadedFiles}
                onRemoveImage={(files: any) => {
                  removeFile(files)
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="secondary" disabled={!uploadedFiles.length}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default CustomFileUpload;
