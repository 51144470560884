import React, { useEffect, useState } from "react";
import { CssBaseline, Grid, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { MUIDataTableOptions } from "mui-datatables";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_EMAIL_TEMPLATES } from "../../../../../graphql/communications/getAllEmailTemplates";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { DATE_TYPE, formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from "../../../../common/utils";
import { SelectableRows } from "../../../../common/NuvvenTable/types";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { ITable, TableNames } from "../../../../../reducers/tableState/types";
import { addTableState } from "../../../../../actions/tableState/actions";

interface IRow {
  templateType: string;

}

export const EmailSettings = () => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IRow[]>([]);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();
  const [emailSettingsTable, setEmailSettingsTable] = useState<ITable>();
  const emailSettingsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.emailSettings
  );
  const [sortOrder, setSortOrder] = useState<string | undefined>();

  const [
    getAllEmailTemplates,
    { loading: emailTemplatesLoading, data: emailTemplates }
  ] = useLazyQuery(GET_ALL_EMAIL_TEMPLATES, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    getAllEmailTemplates()
  }, [])

  useEffect(() => {
    if (emailTemplates && emailTemplates.getAllEmailTemplates.length) {
      const rows = [...emailTemplates.getAllEmailTemplates]
      const reshapeRows = rows.map((temp) => {
        return {
          ...temp,
          templateType: capitilize(temp.templateType.split("_").join(" "))
        }
      })
      setRows(reshapeRows);
    }
  }, [emailTemplates])

  useEffect(() => {
    if (emailSettingsTableState) {
      setEmailSettingsTable(emailSettingsTableState);
      let sortedOrder = returnSortedOrder(emailSettingsTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, emailSettingsTableState, sortedOrder)
      }
    }
  }, [emailSettingsTableState]);

  function capitilize(str: any) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const columns: any = [
    {
      label: "Email Type",
      name: "templateType",
    },
    {
      label: "Updated By",
      name: "updatedBy",
      options: {
        customBodyRender: (value: any) => value ? (value.firstName + " " + value.lastName) : "-"
      }
    },
    {
      label: "Updated At",
      name: "updatedAt",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED) : "-"
      }
    },
  ];

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    onRowClick: (rowData: any, rowMeta: any) => {
      navigate(`/edit-email?templateType=${rowData[0].replaceAll(" ", "_")}`)
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)      
      dispatch(
        addTableState({
          tableName: TableNames.EMAIL_SETTINGS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
    emailSettingsTable && emailSettingsTable.rowsPerPage
        ? emailSettingsTable.rowsPerPage
        : limit,
  }

  if (emailTemplatesLoading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          &nbsp;Email Settings
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <NuvvenTable
          title=""
          rows={rows}
          columns={columns}
          options={options}
          setSelection={() => { }}
        />
      </Grid>
    </Grid>
  );
}