import { gql } from "@apollo/client";

export const GET_INVOICES = gql`
  query invoices($limit: Int!, $offset: Int!, $filters: [FilterInput]){
    invoices(limit: $limit, offset: $offset, filters: $filters) {
      rentalAmount
      customerType
      insuranceAmount
      damageAmount
      addonsAmount
      taxAmount
      dueDate
      dueAmount
      lastSent
      booking {
        referenceNumber
      }
      tax {
        amount
        appliedBy
        appliedDate
        code
      }
      dateCreated
      dateUpdated
      createdBy
      updatedBy
      discount {
        appliedBy
        appliedDate
        isPercent
        code
        amount
      }
      invoiceRef
      status
      locked
      invoiceType
      startDate
      endDate
      totalPayableAmount
      confirmedPaidAmount
      confirmedRefundAmount
      otherRefunds
      otherCharges {
        amount
        name
      }
      id
      customer {
        id
        firstName
        lastName
      }
      businessCustomer {
        id
        businessName
        registrationNumber
        VAT
      }
      sageAccountingInvoiceId
      sentToXero
    }
  }
`;