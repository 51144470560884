import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DATE_TYPE, toCurrency } from "../../../../common/utils";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { IAdjustedCreditNote } from "../../../../../reducers/invoices/types";
import { useNavigate } from "react-router-dom";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";

interface IProps {
  creditNotes: IAdjustedCreditNote[];
}

const AdjustedCreditNotes: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [creditNotes, setCreditNotes] = useState<IAdjustedCreditNote[]>([]);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const organisation = userState.currentOrganisation;
  const { locale, currency } = organisation;

  useEffect(() => {
    if (props.creditNotes.length) {
      setCreditNotes(props.creditNotes)
    }
  }, [props.creditNotes]);

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Typography variant="h2">Credits</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Box pt={2}></Box>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: 0 }}>
        <Grid container item xs={12}>
          <TableContainer>
            <Table aria-label="spanning table">
              <TableHead>
                <TableCell
                  align="left"
                >
                  <Typography>Credit Note Ref</Typography>
                </TableCell>
                <TableCell
                  align="left"
                >
                  <Typography>Date Adjusted</Typography>
                </TableCell>
                <TableCell
                  align="left"
                >
                  <Typography>Amount</Typography>
                </TableCell>
              </TableHead>
              <TableBody>
                {creditNotes.map((item: IAdjustedCreditNote) => {
                  return <TableRow>
                    <TableCell>
                      <Typography
                        style={{ cursor: "pointer", color: "#1875d2" }}
                        onClick={() => navigate(`/credit-note?creditNoteId=${item.creditNote.id}`)}
                      >
                        {item.creditNote.creditNoteRef}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {getLocalizedDateFormat(country, item.adjustedAt, DATE_TYPE.CONDENSED)}
                    </TableCell>
                    <TableCell>
                      {toCurrency(item.amount, currency, locale)}
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AdjustedCreditNotes;
