import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { Typography } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { DateTime as d } from "luxon";
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

export interface IPickupReturnData {
  pickups: IDataByDate[];
  returns: IDataByDate[];
}
interface IDataByDate {
  date: string;
  total: number;
  withValet: number;
  bookingIds: string[];
  bookingIdsWithValet: string[];
}
interface IChartData {
  id: string;
  color: string;
  data: IPoint[]
}
interface IPoint {
  date: string;
  x: string;
  y: number;
}

const PickupReturnCard = ({ data }: { data?: IPickupReturnData }) => {
  const navigate = useNavigate();
  const [chartData, setChartData] = useState<{ pickup: IChartData[], return: IChartData[] }>({ pickup: [], return: [] });
  const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const userState = useSelector((state: IAppState) => state.userReducer);

  useEffect(() => {
    if (data) {
      const getPointData = (pickup: boolean, withValet?: boolean) => {
        const operatorArray = pickup ? [...data.pickups] : [...data.returns]
        return operatorArray.map(item => ({
          date: item.date,
          x: weekDays[d.fromISO(item.date).weekday - 1],
          y: withValet ? item.withValet : item.total - item.withValet
        }))
      }

      let _pickupChartData: IChartData[] = [{
        id: "Pickup",
        color: "#cd8500",
        data: getPointData(true)
      },
      {
        id: "Delivery",
        color: "#0057CE",
        data: getPointData(true, true)
      }];
      let _returnChartData: IChartData[] = [{
        id: "Return",
        color: "#8b21af",
        data: getPointData(false)
      },
      {
        id: "Collection",
        color: "#17833D ",
        data: getPointData(false, true)
      }];
      setChartData({
        pickup: _pickupChartData,
        return: _returnChartData
      });
    }
  }, [data]);

  const today = {
    pickup: {
      total: data?.pickups[3].total || 0,
      pickup: (data?.pickups[3].total || 0) - (data?.pickups[3].withValet || 0),
      delivery: data?.pickups[3].withValet || 0,
      bookingIds: data?.pickups[3].bookingIds || [],
      bookingIdsWithValet: data?.pickups[3].bookingIdsWithValet || []
    },
    return: {
      total: data?.returns[3].total || 0,
      return: (data?.returns[3].total || 0) - (data?.returns[3].withValet || 0),
      collection: data?.returns[3].withValet || 0,
      bookingIds: data?.returns[3].bookingIds || [],
      bookingIdsWithValet: data?.returns[3].bookingIdsWithValet || []
    }
  }


  return (
    <div className={styles.pickupReturnCard}>
      <div className="flex fill col-flex">
        <Typography variant="h4">Pickup / Delivery</Typography>
        <div onClick={() => {
          if (today.pickup.total) {
            if (userState.role !== "FINANCE_MANAGER") {
              navigate(`/bookings?day=today&type=pickup`, { state: today.pickup.bookingIds });
            }
          }
        }} style={{ cursor: today.pickup.total ? "pointer" : "initial" }}>
          <Typography variant="body1" style={{ marginTop: 10 }}>Today's Total</Typography>
          <Typography variant="h2" className="bold">{today.pickup.total}</Typography>
        </div>
        <div className="flex margin-bottom--small margin-top">
          <div onClick={() => {
            if (today.pickup.pickup) {
              if (userState.role !== "FINANCE_MANAGER") {
                navigate(`/bookings?day=today&type=pickup&valet=0`, { state: _.difference(today.pickup.bookingIds, today.pickup.bookingIdsWithValet) });
              }
            }
          }} className={styles.rect} style={{ backgroundColor: "#F7F4EF", color: "#cd8500", cursor: today.pickup.pickup ? "pointer" : "not-allowed" }}>
            <Typography variant="body1" className="">Pickup</Typography>
            <Typography variant="body1">{today.pickup.pickup}</Typography>
          </div>
          <div onClick={() => {
            if (today.pickup.delivery) {
              if (userState.role !== "FINANCE_MANAGER") {
                navigate(`/bookings?day=today&type=pickup&valet=1`, { state: today.pickup.bookingIdsWithValet });
              }
            }
          }} className={styles.rect} style={{ backgroundColor: "#EFF4FC", color: "#0057CE", marginLeft: 6, cursor: today.pickup.delivery ? "pointer" : "not-allowed" }}>
            <Typography variant="body1" className="">Delivery</Typography>
            <Typography variant="body1">{today.pickup.delivery}</Typography>
          </div>
        </div>
        <div className={styles.chart}>
          <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
            <ResponsiveLine
              data={chartData.pickup}
              margin={{ top: 5, right: 10, bottom: 5, left: 10 }}
              xScale={{ type: 'point' }}
              colors={({ id, data, color }) => color}
              theme={{
                grid: {
                  line: {
                    stroke: "#e9e9e9",
                    strokeWidth: 1
                  }
                }
              }}
              yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false
              }}
              yFormat=" >-.2f"
              curve="monotoneX"
              lineWidth={1}
              axisTop={null}
              axisRight={null}
              axisBottom={null}
              axisLeft={null}
              enableGridY={false}
              pointSize={6}
              pointColor="#ffffff"
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabelYOffset={-12}
              enableArea={false}
              areaOpacity={0.05}
              useMesh={true}
              enableSlices="x"
              sliceTooltip={({ slice }) => {
                return (
                  <div
                    style={{
                      background: 'white',
                      padding: '9px 12px',
                      border: '1px solid #ccc',
                      borderRadius: 6
                    }}
                  >
                    <div className="margin-bottom--small">{slice.points[0].index === 10 ? <span className="bold" style={{ color: "#0057CE" }}>Today</span> : slice.points[0].data.xFormatted}</div>
                    {slice.points.map(point => (
                      <div
                        key={point.id}
                        style={{
                          padding: '3px 0',
                          fontSize: 12,
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <div style={{ background: point.serieColor, height: "8px", width: "8px", marginRight: 5 }} />{point.serieId} : <strong>{point.data.yFormatted}</strong>
                      </div>
                    ))}
                  </div>
                )
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className="flex fill col-flex">
        <Typography variant="h4">Return / Collection</Typography>
        <div onClick={() => {
          if (today.return.total) {
            if (userState.role !== "FINANCE_MANAGER") {
              navigate(`/bookings?day=today&type=return`, { state: today.return.bookingIds });
            }
          }
        }} style={{ cursor: today.return.total ? "pointer" : "initial" }}>
          <Typography variant="body1" style={{ marginTop: 10 }}>Today's Total</Typography>
          <Typography variant="h2" className="bold">{today.return.total}</Typography>
        </div>
        <div className="flex margin-bottom--small margin-top">
          <div onClick={() => {
            if (today.return.return) {
              if (userState.role !== "FINANCE_MANAGER") {
                navigate(`/bookings?day=today&type=return&valet=0`, { state: _.difference(today.return.bookingIds, today.return.bookingIdsWithValet) });
              }
            }
          }} className={styles.rect} style={{ color: "#8b21af", cursor: today.return.return ? "pointer" : "not-allowed" }}>
            <Typography variant="body1" className="">Return</Typography>
            <Typography variant="body1">{today.return.return}</Typography>
          </div>
          <div onClick={() => {
            if (today.return.collection) {
              if (userState.role !== "FINANCE_MANAGER") {
                navigate(`/bookings?day=today&type=return&valet=1`, { state: today.return.bookingIdsWithValet });
              }
            }
          }} className={styles.rect} style={{ backgroundColor: "#F0F7F3", color: "#17833D", marginLeft: 6, cursor: today.return.collection ? "pointer" : "not-allowed" }}>
            <Typography variant="body1" className="">Collection</Typography>
            <Typography variant="body1">{today.return.collection}</Typography>
          </div>
        </div>
        <div className={styles.chart}>
          <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
            <ResponsiveLine
              data={chartData.return}
              margin={{ top: 5, right: 10, bottom: 5, left: 10 }}
              xScale={{ type: 'point' }}
              colors={({ id, data, color }) => color}
              theme={{
                grid: {
                  line: {
                    stroke: "#e9e9e9",
                    strokeWidth: 1
                  }
                }
              }}
              yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false
              }}
              yFormat=" >-.2f"
              curve="monotoneX"
              lineWidth={1}
              axisTop={null}
              axisRight={null}
              axisBottom={null}
              axisLeft={null}
              enableGridY={false}
              pointSize={6}
              pointColor="#ffffff"
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabelYOffset={-12}
              enableArea={false}
              areaOpacity={0.05}
              useMesh={true}
              legends={[]}
              enableSlices="x"
              sliceTooltip={({ slice }) => {
                return (
                  <div
                    style={{
                      background: 'white',
                      padding: '9px 12px',
                      border: '1px solid #ccc',
                      borderRadius: 6
                    }}
                  >
                    <div className="margin-bottom--small">{slice.points[0].index === 10 ? <span className="bold" style={{ color: "#0057CE" }}>Today</span> : slice.points[0].data.xFormatted}</div>
                    {slice.points.map(point => (
                      <div
                        key={point.id}
                        style={{
                          padding: '3px 0',
                          fontSize: 12,
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <div style={{ background: point.serieColor, height: "8px", width: "8px", marginRight: 5 }} />{point.serieId} : <strong>{point.data.yFormatted}</strong>
                      </div>
                    ))}
                  </div>
                )
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PickupReturnCard

