import { Value } from "react-phone-number-input";

import { RATE_TYPES } from "../../components/views/ReservationManagement/utils";
import { BusinessCustomerStatus } from "../customer/types";
import { BookingStatus, IBookingState, VehicleSelectionView } from "./types";

export const bookingInitialState: IBookingState = {
  booking: {
    events: [],
    lastEventTime: "",
    lastEventType: "",
    pickupServiceLocation: {
      id: "",
      name: "",
      address: {
        city: "",
        country: "",
        fullAddress: "",
        state: "",
        street: "",
        zipcode: ""
      }
    },
    bookingCategory: "",
    dropoffServiceLocation: {
      id: "",
      name: "",
      address: {
        city: "",
        country: "",
        fullAddress: "",
        state: "",
        street: "",
        zipcode: ""
      }
    },
    excess: 0,
    dateUpdated: "",
    updatedBy: {
      id: "",
      firstName: "",
      lastName: "",
      phoneNumber: {
        phone: "" as Value,
        country: ""
      },
      organisationId: "",
      branchId: "",
      userRole: "",
      role: ""
    },
    insuranceRate: 0,
    customerType: "individual",
    discount: {
      amount: 0,
      appliedBy: "",
      appliedDate: "",
      isPercent: true
    },
    rateTypeName: RATE_TYPES.DAILY,
    rateTypeDuration: 1440,
    addonRequirements: [],
    createdBy: {
      id: "",
      firstName: "",
      lastName: ""
    },
    customer: {
      email: "",
      firstName: "",
      id: "",
      lastName: "",
      phoneNumber: {
        phone: "" as Value,
        country: ""
      },
      location: {
        city: "",
        country: "",
        fullAddress: "",
        state: "",
        street: "",
        zipcode: ""
      }
    },
    paymentDetails: {
      addonsAmount: 0,
      discountAmount: 0,
      endDate: "",
      insuranceAmount: 0,
      rentalAmount: 0,
      rentalTime: 0,
      startDate: "",
      taxAmount: 0,
      totalPayableAmount: 0
    },
    dateCreated: "",
    smartCarVehicle: false,
    flightNumber: "",
    id: "",
    longTermBooking: false,
    pickupDateTime: "",
    referenceNumber: "",
    status: BookingStatus.QUOTE,
    organisationId: "",
    vehicleGroups: [],
    invoices: [],
    businessCustomer: {
      id: "",
      accountManager:
        {
          id: "",
          firstName: "",
          lastName: ""
        } || "",
      businessName: "",
      registrationNumber: "",
      VAT: "",
      status: BusinessCustomerStatus.PENDING,
      declinedReason: "",
      registeredAddress: {
        street: "",
        city: "",
        fullAddress: "",
        state: "",
        zipcode: "",
        country: ""
      },
      tradingAddressSame: true,
      tradingAddress: {
        street: "",
        city: "",
        fullAddress: "",
        state: "",
        zipcode: "",
        country: ""
      },
      approvedDrivers: [],
      authorizedSignatories: [],
      billing: {
        phoneNumber: {
          phone: "" as Value,
          country: ""
        },
        email: ""
      },
      contact: {
        firstName: "",
        lastName: "",
        phoneNumber: {
          phone: "" as Value,
          country: ""
        },
        email: ""
      },
      documents: []
    },
    approvedDrivers: [],
    currentBookingSchedules: [],
    previousBookingSchedules: [],
    notes: [],
    noteFromWebsite: {
      description: "",
      createdAt: ""
    },
    additionalInformation: {
      description: ""
    },
    preBookingQuestions: [],
    deliveryQuestions: [],
    locationSurcharges: [],
    isCoi: false,
    coiDetails: {
      policyName: "",
      policyNumber: "",
      provider: "",
      expiryDate: "",
      documentUrl: ""
    },
    oneWayRentalFee: {
      amount: 0,
      taxable: false,
      tax: {
        title: "",
        type: "",
        value: 0
      }
    }
  },
  checkInfo: {
    vehicleState: {
      id: "",
      type: "",
      exterior: {
        front: {
          description: "",
          images: []
        },
        rear: {
          description: "",
          images: []
        },
        top: {
          description: "",
          images: []
        },
        right: {
          description: "",
          images: []
        },
        left: {
          description: "",
          images: []
        }
      },
      interior: {
        frontSeat: {
          clean: true,
          controls: true,
          seatBelt: true
        },
        rearSeat: {
          clean: true,
          controls: true,
          seatBelt: true
        },
        lockAndSwitch: {
          frontDoor: true,
          rearDoor: true,
          frontWindow: true,
          rearWindow: true,
          childLock: true
        },
        images: []
      },
      defaultAccesories: [],
      generalChecks: [],
      bookedAccesories: [],
      tyre: {
        frontLeft: {
          condition: true,
          pressure: true,
          description: ""
        },
        frontRight: {
          condition: true,
          pressure: true,
          description: ""
        },
        rearLeft: {
          condition: true,
          pressure: true,
          description: ""
        },
        rearRight: {
          condition: true,
          pressure: true,
          description: ""
        }
      },
      odometerReading: 0,
      fuelStatus: ""
    },
    customerInfo: {
      tncCheck: false,
      name: "",
      signature: ""
    },
    paymentInfo: {
      amount: 0,
      paymentMethod: "cash"
    }
  },
  vehicleSelectionView: VehicleSelectionView.CATEGORY
};

export const BOOKING_TYPES = {
  PREPAID: "PREPAID",
  POSTPAID: "POSTPAID"
};
