import {
  Card,
  CardContent,
  Grid,
  Typography
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { toCurrency } from "../../../../common/utils";

interface VehicleRow {
  name: string;
  vehicleCount: number;
  hourlyRate: number;
  dailyRate: number;
  weeklyRate: number;
  monthlyRate: number;
}

interface IProps {
  VehicleGroup: VehicleRow
}

export const MobilePriceRuleVehicleGroupCard = (props: IProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  return (
    <Grid item xs={12} style={{ marginBottom: '8px', marginTop: '8px' }}>
      <Card style={{
        borderRadius: "3%",
        minHeight: 220,
        minWidth: 'calc(100vw - 140px)',
        maxWidth: 'calc(100vw - 140px)'
      }}>
        <CardContent>
          <Grid container xs={12} justifyContent="center">
            <Grid container xs={12} justifyContent="center" item>
              <Grid item>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {props.VehicleGroup.name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container xs={12} justifyContent="center" item>
              <Grid item>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Vehicles: {props.VehicleGroup.vehicleCount}
                </Typography>
              </Grid>
            </Grid>


            <hr
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                color: "rgba(0, 0, 0, 0.1)",
                height: 1,
                width: 'calc(100vw - 156px)'
              }}
            />

            <Grid container xs={12} justifyContent="center" item>
              <Grid item>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Hourly Rate:
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} justifyContent="center" item>
              <Grid item>
                <Typography variant="body2" gutterBottom style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  {toCurrency(props.VehicleGroup.hourlyRate, currency, locale)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container xs={12} justifyContent="center" item>
              <Grid item>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Daily Rate:
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} justifyContent="center" item>
              <Grid item>
                <Typography variant="body2" gutterBottom style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  {toCurrency(props.VehicleGroup.dailyRate, currency, locale)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container xs={12} justifyContent="center" item>
              <Grid item>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Weekly Rate:
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} justifyContent="center" item>
              <Grid item>
                <Typography variant="body2" gutterBottom style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  {toCurrency(props.VehicleGroup.weeklyRate, currency, locale)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container xs={12} justifyContent="center" item>
              <Grid item>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Monthly Rate:
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12} justifyContent="center" item>
              <Grid item>
                <Typography variant="body2" gutterBottom style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  {toCurrency(props.VehicleGroup.monthlyRate, currency, locale)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default MobilePriceRuleVehicleGroupCard;
