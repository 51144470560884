import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

interface IProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  offences?: number;
  pointThreshold?: number;
}
const ConfirmCustomerWithOffences: React.FC<IProps> = (props) => {
  const { open, onCancel, onConfirm, offences, pointThreshold } = props
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Select Customer</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`This customer has ${offences} offences & ${pointThreshold}  points.
            Are you sure you want to select this customer?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" style={{ backgroundColor: "var(--theme-primary)", color: "white", marginBottom: "10px", marginRight: 10 }}>
          {"Confirm"}
        </Button>
        <Button onClick={onCancel} color="primary" style={{ backgroundColor: "#828282", color: "white", marginBottom: "10px", marginRight: "10px" }}>
          {"Cancel"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmCustomerWithOffences
