import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material'
import { createStyles, makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { ISubscription, ISubscriptionPricing } from '../../../../../reducers/Subscription/types';
import { useNavigate } from 'react-router-dom';
import EditSubscriptionPricingModal from '../../../Fleet/VehicleInventory/ViewVehicle/Finance/EditSubscriptionPricingModal';
import { ISubscriptionInfo } from '../../../../../reducers/fleet/types';
import { useMutation } from '@apollo/client';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { UPDATE_VEHICLE_SUBSCRIPTION_PRICINGS } from '../../../../../graphql/SubscriptionPlans/updateVehicleSubscriptionPricingMutation';
import { formatGraphQLErrorMessage } from '../../../../common/utils';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { ApolloError } from '@apollo/client';

const useStyles = makeStyles(() =>
  createStyles({
    iconMinWidth: {
      minWidth: 30
    },
    iconStyle: {
      fontSize: "1.3rem"
    },
    listItemStyle: {
      paddingLeft: 5
    },
    listItemTextStyle: {
      marginTop: 2
    },
    removeListPadding: {
      padding: 0
    }
  })
);

interface IProps {
  subscription: ISubscription;
  pricings?: ISubscriptionInfo[];
  vehicleId?: string;
  isUpdateSubscription: boolean;
}

const SubscriptionCard: React.FC<IProps> = (props) => {
  const { subscription } = props
  const snackbar = useSnackBar();
  const classes = useStyles();
  const navigate = useNavigate();

  const [editDialog, setEditDialog] = useState<boolean>(false)
  const [subscriptionPricings, setSubscriptionPricings] = useState<ISubscriptionPricing[]>([])
  const [selectedDeposit, setSelectedDeposit] = useState<number>(0)

  const [updateVehicleSubscriptionPricings] = useMutation(UPDATE_VEHICLE_SUBSCRIPTION_PRICINGS, {
    onCompleted: () => {
      snackbar({
        message: formatGraphQLErrorMessage("Pricings updated successfully"),
        variant: SnackBarVariant.SUCCESS
      });
      setEditDialog(false)
      navigate(`/update-vehicle?vehicle=${props.vehicleId}`);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });

    },
  });

  const updateSubscriptionPricings = (pricings: ISubscriptionPricing[], deposit: number) => {
    if (props.subscription.id) {
      updateVehicleSubscriptionPricings({
        variables: {
          subscriptionId: props.subscription.id,
          vehicleId: props.vehicleId,
          pricings: pricings,
          deposit
        }
      })
    }
  }

  return <>
    <Grid item xs={12} md={9} lg={9} xl={9}>
      <Accordion style={{ marginBottom: 20, boxShadow: 'none', border: '1px solid #ccc' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={6}><Typography variant={"h3"}>{subscription.name}</Typography></Grid>
            {
              props.isUpdateSubscription && (
                <Grid item xs={6}>
                  <Typography variant={"h4"}>Status</Typography>
                  <Typography variant={"h5"}>{subscription.isActive ? "Active" : "In-active"} </Typography>
                </Grid>
              )
            }
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container xs={12}>
            <Typography variant='subtitle1' style={{ marginBottom: "1rem" }}>Plan Includes</Typography>
            <Grid container xs={12}>
              {subscription?.insurancePolicy && <Grid item xs={4}>
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ marginLeft: "2rem" }}>
                    Insurance
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <List className={classes.removeListPadding}>
                    <ListItem className={classes.listItemStyle}>
                      <ListItemIcon className={classes.iconMinWidth}>
                        <DoneIcon className={classes.iconStyle} color='primary' />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.listItemTextStyle}
                        primary={<Typography variant='body1'>{subscription.insurancePolicy.name}</Typography>}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>}
              {subscription?.addons?.length > 0 && <Grid item xs={4}>
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ marginLeft: "2rem" }}>
                    Addons
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {subscription.addons.map(addon => {
                    return (
                      <List className={classes.removeListPadding}>
                        <ListItem className={classes.listItemStyle}>
                          <ListItemIcon className={classes.iconMinWidth}>
                            <DoneIcon className={classes.iconStyle} color='primary' />
                          </ListItemIcon>
                          <ListItemText
                            className={classes.listItemTextStyle}
                            primary={<Typography variant='body1'>{addon.displayName}</Typography>}
                          />
                        </ListItem>
                      </List>
                    )
                  })}
                </Grid>
              </Grid>}

              {subscription?.features && subscription.features.length > 0 && (
                <Grid item xs={4}>
                  <Grid item xs={12}>
                    <Typography variant="h4" style={{ marginLeft: "2rem" }}>
                      Features
                    </Typography>
                  </Grid>
                  {subscription.features.map((feature, index) => (
                    <List key={index} className={classes.removeListPadding}>
                      <ListItem className={classes.listItemStyle}>
                        <ListItemIcon className={classes.iconMinWidth}>
                          <DoneIcon className={classes.iconStyle} color='primary' />
                        </ListItemIcon>
                        <ListItemText
                          className={classes.listItemTextStyle}
                          primary={<Typography variant='body1'>{feature}</Typography>}
                        />
                      </ListItem>
                    </List>
                  ))}
                </Grid>
              )}

              {/* <Grid item xs={3}>
                <Typography variant='h4'>Policies</Typography>
                <List className={classes.removeListPadding}>
                  <ListItem className={classes.listItemStyle}>
                    <ListItemText
                      className={classes.listItemTextStyle}
                      primary={<Typography variant='body1'>Cancellation Policy</Typography>}
                    />
                  </ListItem>
                  {
                    subscription.serviceIncluded && (
                      <ListItem className={classes.listItemStyle}>
                        <ListItemText
                          className={classes.listItemTextStyle}
                          primary={<Typography variant='body1'>Service & Maintenance Policy</Typography>}
                        />
                      </ListItem>
                    )
                  }
                </List>
              </Grid> */}
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} container alignItems='baseline' justifyContent='flex-end'>
              <Tooltip title="Edit Subscription">
                <IconButton
                  onClick={() => {
                    if (props.isUpdateSubscription) {
                      navigate(`/update-subscription?id=${subscription.id}`);
                    }
                    else {
                      setEditDialog(true)
                      const selectedPricingsInfo: ISubscriptionPricing[] = []
                      let selectedDeposit = 0;
                      if (props.pricings && props.pricings.length > 0) {
                        for (let index = 0; index < props.pricings.length; index++) {
                          const pricing = props.pricings[index];
                          if (pricing.subscription.id === subscription.id) {
                            selectedDeposit = pricing.deposit
                            selectedPricingsInfo.push(...pricing.pricings)
                          }
                        }
                      }
                      setSelectedDeposit(selectedDeposit)
                      setSubscriptionPricings(selectedPricingsInfo)
                    }
                  }}
                  size="large">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
    {
      editDialog &&
      <EditSubscriptionPricingModal
        open={editDialog}
        subscription={subscription}
        pricings={subscriptionPricings}
        onClose={() => setEditDialog(false)}
        handlePricingUpdate={updateSubscriptionPricings}
        deposit={selectedDeposit}
      />
    }
  </>;
}

export default SubscriptionCard;