import { gql } from "@apollo/client";

export const UPLOAD_CUSTOMER = gql`
  mutation uploadCustomer($customers: [CustomerUploadInput]!) {
    uploadCustomer(customers: $customers) {
      errors {
        index
        message
      }
      customers {
        id
      }
    }
  }
`;