import { gql } from "@apollo/client";

export const UPLOAD_VEHICLE_SUBSCRIPTION_PRICINGS = gql`
  mutation uploadVehicleSubscriptionPricing(
    $pricings: [SubscriptionPricingInput!],
    $deposits: [SubscriptionPricingDepositInput]
  ) {
    uploadVehicleSubscriptionPricing(pricings: $pricings, deposits: $deposits) {
      successMessage
      errors {
        index
        message
      }
    }
  }
`;
