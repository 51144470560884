import { gql } from "@apollo/client";

export const CREATE_ADDON_TYPE = gql`
  mutation createAddonType($addonType: AddonTypeCreateInput!) {
    createAddonType(addonType: $addonType) {
      id
      name
      category
      displayName
      imageUrl
      hasFixedRate
      hasQuantity
      fixedRate
      description
      recurringRate {
        rateTypeName
        rateTypeDuration
        rate
        longTerm
      }
      totalQuantity
      availableQuantity
      isActivated
      rules {
        from
        to
        amount
      }
    }
  }
`;
