import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

import { SettingsCard } from "../SettingsCard/SettingsCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "50%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px",
      width: "100%"
    },
    paper: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      textAlign: "center"
    },
    root: {
      flexGrow: 1
    }
  })
);

interface IProps {
  options: ISettingsOptions[];
}

export interface ISettingsOptions {
  icon: any;
  location: string;
  title: string;
}

export const SettingsGrid = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Grid container spacing={4}>
          {props.options.map((item: any, index: number) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={3} lg={2} xl={2}>
                <SettingsCard
                  title={item.title}
                  icon={item.icon}
                  location={item.location}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};
