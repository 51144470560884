import { gql } from "@apollo/client";

export const UPDATE_PROCUREMENT = gql`
  mutation updateProcurementRequest($id: ID!, $procurementRequest: ProcurementRequestUpdateInput!) {
    updateProcurementRequest(id: $id, procurementRequest: $procurementRequest) {
      id
      status
      items {
        id
        requestId
        assetType
        quantity
        make
        model
        variant
        fuel
        transmission
        condition
        expectedByDate
        status
        note {
          description
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
        }
      }
    }
  }
`;