export enum DeFleetReasons {
  "DURATION_LIMIT" = "DURATION_LIMIT",
  "MILEAGE" = "MILEAGE",
  "AGREEMENT_END_DATE" = "AGREEMENT_END_DATE",
  "STOLEN" = "STOLEN",
  "LEASE_TO_SALE" = "LEASE_TO_SALE",
  "NOT_ROAD_WORTHY" = "NOT_ROAD_WORTHY",
  "MOVE_TO_ANOTHER_BRANCH" = "MOVE_TO_ANOTHER_BRANCH",
  "OEM_RECALL" = "OEM_RECALL",
  "HIGHER_RE_SALE_VALUE" = "HIGHER_RE_SALE_VALUE",
  "OTHERS" = "OTHERS"
}

export const deFleetReasons = [
  {
    label: "DURATION LIMIT",
    value: DeFleetReasons.DURATION_LIMIT
  },
  {
    label: "MILEAGE",
    value: DeFleetReasons.MILEAGE
  },
  {
    label: "AGREEMENT END DATE",
    value: DeFleetReasons.AGREEMENT_END_DATE
  },
  {
    label: "STOLEN",
    value: DeFleetReasons.STOLEN
  },
  {
    label: "LEASE TO SALE",
    value: DeFleetReasons.LEASE_TO_SALE
  },
  {
    label: "NOT ROAD WORTHY",
    value: DeFleetReasons.NOT_ROAD_WORTHY
  },
  {
    label: "MOVE TO ANOTHER BRANCH",
    value: DeFleetReasons.MOVE_TO_ANOTHER_BRANCH
  },
  {
    label: "OEM RECALL",
    value: DeFleetReasons.OEM_RECALL
  },
  {
    label: "HIGHER RE-SALE VALUE",
    value: DeFleetReasons.HIGHER_RE_SALE_VALUE
  },
  {
    label: "OTHERS",
    value: DeFleetReasons.OTHERS
  }
]

export enum LoanStatus {
  PAID_IN_FULL = "PAID_IN_FULL",
  OUTSTANDING = "OUTSTANDING"
}