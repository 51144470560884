import { gql } from "@apollo/client";

export const DOWNLOAD_VEHICLE_INSURANCE_REPORT = gql`
  query downloadVehicleInsuranceReport(
    $fromDate: String!
    $toDate: String!
  ) {
    downloadVehicleInsuranceReport(
        fromDate: $fromDate
        toDate: $toDate
      ) {
      key
      message
    }
  }
`;