import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CircularProgress,
  IconButton,
  Container,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ApolloError } from "@apollo/client";
import {
  IBranch,
  TaxValueType,
} from "../../../../reducers/organisation/types";
import {
  formatGraphQLErrorMessage,
} from "../../../common/utils";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import styles from './index.module.css';
import NewConnection from './NewConnection';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { IBranchConnection, RENTAL_COST_TYPE } from '../types';
import { CREATE_BRANCH_CONNECTION, UPDATE_BRANCH_CONNECTION } from '../../../../graphql/vehicleMovement/mutations';
import { GET_BRANCHES, GET_BRANCH_CONNECTIONS, GET_BRANCH_CONNECTION } from '../../../../graphql/vehicleMovement/queries';

const initialData: IBranchConnection = {
  externalBranches: [{
    branchId: "",
    transitTime: 1,
    rentalCostType: RENTAL_COST_TYPE.ZERO_COST,
    fuelCost: 0,
    driverCost: 0
  }],
  oneWayRentalFee: {
    amount: 0,
    taxable: false,
    tax: {
      title: "",
      type: TaxValueType.PERCENTAGE,
      value: 0
    }
  },
  sharedFleetData: [{
    vehicleGroup: "",
    vehicles: []
  }],
  vehicleIds: [],
  isActive: true
}

const BranchConnection = () => {
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const [isUpdate, setIsUpdate] = useState<boolean>(true);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [branchConnectionState, setBranchConnectionState] = useState<IBranchConnection>(initialData);
  const [allConnectedBranches, setAllConnectedBranches] = useState<string[]>([]);
  const [branches, setBranches] = useState<Partial<IBranch>[]>([]);

  const [getBranchConnections, { data: branchConnectionsData }] = useLazyQuery(
    GET_BRANCH_CONNECTIONS,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const [loadBranchConnection, { data: branchConnectionData }] = useLazyQuery(
    GET_BRANCH_CONNECTION,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data || !data.getFleetShareById) {
          navigate("/movements?tab=1");
        }
      },
      onError: (error: ApolloError) => {
        if (formatGraphQLErrorMessage(error.message) === "Something went wrong") {
          navigate("/movements?tab=1");
        } else {
          snackbar({
            message: formatGraphQLErrorMessage(error.message),
            variant: SnackBarVariant.ERROR
          })
          navigate("/movements?tab=1");
        }
      }
    }
  );
  const [
    loadBranches,
    { data: branchesData }
  ] = useLazyQuery(GET_BRANCHES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.branches) {
        navigate("/movements?tab=1");
      }
    },
    onError: (error: ApolloError) => {
      if (formatGraphQLErrorMessage(error.message) === "Something went wrong") {
        navigate("/movements?tab=1");
      } else {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
        navigate("/movements?tab=1");
      }
    }
  });


  const [createBranchConnection, { loading: connectionCreating }] = useMutation(CREATE_BRANCH_CONNECTION, {
    onCompleted: () => {
      snackbar({
        message: "Branch connection created successfully",
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/movements?tab=1");
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
  });

  const [updateBranchConnection, { loading: connectionUpdating }] = useMutation(UPDATE_BRANCH_CONNECTION, {
    onCompleted: () => {
      snackbar({
        message: "Branch connection updated successfully",
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/movements?tab=1");
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
  });

  useEffect(() => {
    loadBranches({
      variables: {
        tenancyId: userState.currentTenancy.id,
        organisationId: userState.currentOrganisation.id
      }
    });
    getBranchConnections();
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const connectionId = params.get("connectionId");
      if (connectionId) {
        loadBranchConnection({
          variables: {
            fleetShareId: connectionId
          }
        })
        return setIsUpdate(true);
      }
      else {
        setIsUpdate(false);
      }
    }
    else {
      setIsUpdate(false);
    }
  }, [location, userState]);

  useEffect(() => {
    if (branchesData && branchesData.branches) {
      setBranches(branchesData.branches);
    }
  }, [branchesData]);

  useEffect(() => {
    if (branchConnectionsData && branchConnectionsData.getFleetShares) {
      let branchIds: string[] = [];
      branchConnectionsData.getFleetShares.forEach((connection: IBranchConnection) => {
        connection.externalBranches.forEach((externalBranch) => {
          branchIds.push(externalBranch.branchId)
        })
      });
      setAllConnectedBranches(branchIds);
    }
  }, [branchConnectionsData]);

  useEffect(() => {
    if (branchConnectionData && branchConnectionData.getFleetShareById) {
      setBranchConnectionState(branchConnectionData.getFleetShareById)
    }
  }, [branchConnectionData]);

  const handleFormSubmit = (values: any) => {
    const branchConnection = { ...values }
    if (branchConnection.id) {
      updateBranchConnection({
        variables: {
          fleetShareId: branchConnection.id,
          fleetShareInput: {
            externalBranches: branchConnection.externalBranches.map((exBr: any) => {
              return {
                branchId: exBr.branchId,
                rentalCostType: exBr.rentalCostType,
                fuelCost: exBr.fuelCost,
                driverCost: exBr.driverCost,
                transitTime: exBr.transitTime
              }
            }),
            sharedFleetData: branchConnection.sharedFleetData,
            oneWayRentalFee: {
              ...branchConnection.oneWayRentalFee,
              amount: branchConnection.oneWayRentalFee.amount || 0,
              taxable: branchConnection.oneWayRentalFee.taxable || false,
              tax: {
                title: branchConnection.oneWayRentalFee.tax && branchConnection.oneWayRentalFee.tax.title || "",
                type: TaxValueType.PERCENTAGE,
                value: branchConnection.oneWayRentalFee.tax && branchConnection.oneWayRentalFee.tax.value || 0
              }
            },
            vehicleIds: branchConnection.vehicleIds,
            isActive: branchConnection.isActive
          }
        }
      })
    } else {
      createBranchConnection({
        variables: { fleetShareInput: branchConnection }
      })
    }
  };

  if ((isUpdate && !branchConnectionState.id) || (isUpdate && !branches.length)) {
    return <CircularProgress />
  }
  return (
    <Container>
      <div className={styles.header}>
        <IconButton
          onClick={() => navigate("/movements?tab=1")}
          edge="start"
          color="primary"
          aria-label="upload picture"
          component="span"
          size="large">
          <ArrowBackIcon />
        </IconButton>
        <p>{isUpdate ? "Update Branch Connection" : "New Branch Connection"} </p>
      </div>
      <NewConnection
        submitting={connectionCreating || connectionUpdating}
        handleSubmit={handleFormSubmit}
        branchConnectionData={branchConnectionState}
        branches={branches}
        allConnectedBranches={allConnectedBranches}
      />
    </Container>
  );
}

export default BranchConnection