import _ from "lodash";

import {
  CLEAR_PRICE_RULE,
  PriceRuleAction,
  UPDATE_PRICE_RULE
} from "../../actions/pricerule/types";
import { priceRuleInitialState } from "./consts";
import { IPriceRuleState } from "./types";

export default function(
  state: IPriceRuleState = priceRuleInitialState,
  action: PriceRuleAction
): IPriceRuleState {
  switch (action.type) {
    case UPDATE_PRICE_RULE:
      return {
        ...state,
        priceRule: {
          ...state.priceRule,
          ...action.payload
        }
      };
    case CLEAR_PRICE_RULE:
      return {
        ...priceRuleInitialState
      };
    default:
      return state;
  }
}
