import { gql } from "@apollo/client";

export const GET_VEHICLES_FOR_SWAP = gql`
  query availableVehiclesForSwap(
    $bookingId: String!
    $startDate: String!
    $endDate: String!
  ) {
    availableVehiclesForSwap(
      bookingId: $bookingId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      licencePlate
      make
      model
      year
    }
  }
`;
