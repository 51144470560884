export const UPDATE_PRICE_RULE = "UPDATE_PRICE_RULE";
export const CLEAR_PRICE_RULE = "CLEAR_PRICE_RULE";

interface IUpdatePriceRule {
  type: typeof UPDATE_PRICE_RULE;
  payload: any;
}

interface IClearPriceRule {
  type: typeof CLEAR_PRICE_RULE;
}

export type PriceRuleAction = IUpdatePriceRule | IClearPriceRule;
