import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getSignedUrl } from "../../../utils/getSignedUrl";
import { getVehicleTypeIcon } from "../utils";

interface ILicencePlateDetails {
  externalFleet: boolean;
  branchName: string;
  licencePlates: string[];
}

export const MobileVehicleCard = ({ id, imageUrl, assetTag, make, year, licencePlate, bodyType, status, fuelType, motStatus, insuranceStatus, taxStatus, taxDate }: {
  id?: string, imageUrl: any, assetTag: string, make: string, year: number, licencePlate: string, bodyType: string, status: string, fuelType: string, motStatus: string, insuranceStatus: string, taxStatus: string, taxDate: string


}) => {
  const navigate = useNavigate();
  const [vehicleImage, setVehicleImage] = useState<any>("");
  const [bodyImage, setBodyImage] = useState<any>("");
  const getVehicleImage = async (url: string) => {
    const file = await getSignedUrl(url);
    setVehicleImage(file)
  }

  useEffect(() => {
    if (imageUrl) {
      getVehicleImage(imageUrl);
    } else {
      setVehicleImage(null)
    }
  }, [imageUrl])

  useEffect(() => {
    if (bodyType) {
      setBodyImage(getVehicleTypeIcon(bodyType));
    } else {
      setBodyImage(getVehicleTypeIcon("UNDEFINED"));
    }
  }, [bodyType]);

  function routeTo() {
    if (id) {
      navigate(`/update-vehicle?vehicle=${id}`);
    }
  }

  return (
    <Grid item xs={12}>
      <ButtonBase onClick={() => routeTo()}>
        <Card style={{
          borderRadius: "3%",
          minHeight: 330,
          minWidth: 'calc(100vw - 60px)',
          maxWidth: 'calc(100vw - 60px)'
        }}>
          <CardHeader
            title={make}
            subheader={assetTag}
          />
          <Box style={{
            height: 140,
            width: '100%',
            objectFit: 'cover',
            padding: 10
          }}>
            {vehicleImage ?
              (<CardMedia
                component="img"
                //height="140"
                style={{ height: "100%", objectFit: 'cover' }}
                image={vehicleImage}
              />) : (
                <div>
                  {bodyType && (
                    <>
                      <CardMedia
                        component="img"
                        //height="140"
                        style={{ height: "100%", objectFit: 'cover' }}
                        image={bodyImage}
                      />
                    </>
                  )}
                </div>
              )}
          </Box>
          <CardContent>
            <Grid>
              <Box mt={2}></Box>
            </Grid>
            <Grid container xs={12} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Year: {year}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Body Type: {bodyType}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  License Plate: {licencePlate}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Satus: {status}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Fuel: {fuelType}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  MOT: {motStatus}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Insurance: {insuranceStatus}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Tax: {taxStatus}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Tax Date: {taxDate}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  );
};

export default MobileVehicleCard;
