import {
  Box,
  Fab,
  Grid,
} from '@mui/material'
import { DateTime as d } from 'luxon';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateVehicleService } from '../../../../../actions/fleet/vehicle/actions';
import { IVehicle } from '../../../../../reducers/fleet/types';
import { IAppState } from '../../../../../store';
import { DialogTypes } from '../utils';
import { getLocalizedBookingSyntex } from '../../../../../utils/localized.syntex';

interface IDialogData {
  id?: string;
  startDate?: string;
  endDate?: string;
  resource?: IVehicle;
  vehicleId?: string;
}
interface IProps {
  type: string;
  eventData: IDialogData;
  setDialogType(type: DialogTypes): void;
}

export const SelectCreateEvent: React.FC<IProps> = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.organisation.address
  const vehicleService = useSelector((state: IAppState) => state.fleetReducer.vehicleService)
  const { eventData, setDialogType } = props

  return (
    <React.Fragment>
      <Grid container item xs={12}>
        <Fab
          variant={"extended"}
          size={"medium"}
          onClick={() => {
            setDialogType(DialogTypes.NEW_BOOKING)
          }}
        >
          CREATE {getLocalizedBookingSyntex(country).toUpperCase()}
        </Fab>
        <Box pl={1}></Box>
        <Fab
          variant={"extended"}
          size={"medium"}
          disabled={props.eventData.resource?.isGhostVehicle}
          onClick={() => {
            if (eventData?.vehicleId && eventData.startDate && eventData.endDate) {
              dispatch(updateVehicleService({
                ...vehicleService,
                vehicleId: eventData.vehicleId,
                licencePlate: eventData.resource?.licencePlate,
                startDate: d.fromISO(eventData?.startDate).toUTC().toISO(),
                endDate: d.fromISO(eventData?.endDate).toUTC().toISO()
              }))
              navigate("/new-services-and-repair")
            }
          }}
        >
          CREATE SERVICE
        </Fab>
        <Box pl={1}></Box>
        {
          eventData.resource?.branchId === userReducer.currentBranch.id && (
            <Fab
              variant={"extended"}
              size={"medium"}
              disabled={props.eventData.resource?.isGhostVehicle}
              onClick={() => {
                setDialogType(DialogTypes.HOLD_SUMMARY)
              }}
            >
              HOLD VEHICLE
            </Fab>
          )
        }
      </Grid>
    </React.Fragment>
  )
}
