import { gql } from "@apollo/client";

export const GET_VEHICLE_GROUPS = gql`
  query vehicleGroups {
    vehicleGroups {
      id
      name
      vehicles {
        id
      }
      basePrices {
        rateTypeName
        rateTypeDuration
        rate
        longTerm
        mileageLimit
        pricePerExtraMile
      }
      isActivated
      updatedAt
    }
  }
`;

export const GET_VEHICLE_GROUPS_LIST = gql`
  query vehicleGroupsList($limit: Int!, $offset: Int!) {
    vehicleGroupsList(limit: $limit, offset: $offset) {
      id
      name
      vehicles {
        id
      }
      basePrices {
        rateTypeName
        rateTypeDuration
        rate
        longTerm
        mileageLimit
        pricePerExtraMile
      }
      isActivated
      updatedAt
    }
  }
`;

export const GET_VEHICLE_GROUPS_COUNT = gql`
  {
    vehicleGroupsCount
  }
`;

export const SEARCH_VEHICLE_GROUPS = gql`
  query searchVehicleGroups($q: String!) {
    searchVehicleGroups(q: $q) {
      id
      name
      vehicles {
        id
      }
      basePrices {
        rateTypeName
        rateTypeDuration
        rate
        longTerm
        mileageLimit
        pricePerExtraMile
      }
      isActivated
      updatedAt
    }
  }
`;

export const GET_VEHICLE_GROUP_FOR_SELECTED_BRANCH = gql`
  query getVehicleGroupForSelectedBranch($branchId: String!) {
    getVehicleGroupForSelectedBranch(branchId: $branchId) {
      id
      name
    }
  }
`;
