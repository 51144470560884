import { gql } from "@apollo/client";

export const SEARCH_DAMAGE_MATRICES = gql`
  query searchDamageMatrices($q: String!) {
    searchDamageMatrices(q: $q) {
      id
      type
      tenantId
      organisationId
      branchId
      make
      model
      year
      components{
        part
        damageType
        s1
        s2
        s3
        s4
        vatOnSMR
        vatOnParts
        vatOnInstallation
        replacementCost
        installationCharges
        overallMarkup
      }
      createdAt
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        role
      }
    }
  }
`;
