import { gql } from "@apollo/client";

export const GET_TENANCY = gql`
  query Tenancy($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      name
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      logoUri
      emailId
      phoneNumber{
        phone
        country
      }
      organisations {
        id
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        locale
        logoUrl
        owner {
          id
        }
        phoneNumber {
          phone
          country
        }
        name
      }
      owner {
        id
      }
      billing{
        billingAddressSame
        address{
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        emailId
        phoneNumber{
          phone
          country
        }
      }
      chargebeeSubscriptionId
      chargebeePlanId
      chargebeeCustomerId
      chargebeePlanType
      chargebeeSubscriptionExpiry
      appLogo
      themeColor
      enableEpyxIntegration
      apiKeys{
        name,
        secretKey,
        createdAt
      }
    }
  }
`;

export const GET_TENANCIES = gql`
  query tenancies {
    tenancies {
      id
      name
      phoneNumber {
        phone
        country
      }
      emailId
      name
      logoUri
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      billing {
        orgName
        billingAddressSame
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      organisations {
        id
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        locale
        logoUrl
        owner {
          id
        }
        branches {
          id
          name
          emailId
          phoneNumber {
            phone
            country
          }
          timeZone
          locations {
            id
            name
          }
        }
      }
      appLogo
      themeColor
    }
  }
`;
