import {
  AppBar,
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { IServiceDocument, IVehicleService } from "../../../../../../reducers/fleet/types";
import { TabPanel } from "../../../../../common/TabPannel/TabPannel";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../../../common/utils";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../../store";

interface IProps {
  row: IVehicleService;
}
const useStyles = makeStyles(() =>
  createStyles({
    imageStyle: {
      height: 70,
      width: 70
    },
    spanStyle: {
      marginLeft: 4,
      fontWeight: "bold"
    },
    tableCellPadding: {
      padding: 8
    }
  })
);
export const ServiceRowDetail = (props: IProps) => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };
  return (
    <Grid container spacing={2}>
      <Paper style={{ maxWidth: 'calc(100vw - 120px)' }}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            <Tab label="DATES" />
            &rbrace; /&gt;
            <Tab label="SERVICE PROVIDER" />
            &rbrace; /&gt;
            <Tab label="ODOMETER" />
            &rbrace; /&gt;
            <Tab label="DESCRIPTION" />
            &rbrace; /&gt;
            <Tab label="DOCUMENT" />
            &rbrace; /&gt;
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Paper>
            <Grid container style={{ padding: 10 }}>
              <Grid item xs={12} md={5}>
                <Typography component="div">
                  <Box fontSize="fontSize" m={1}>
                    Appointment Date: <span className={classes.spanStyle}>{props.row.startDate}</span>
                  </Box>
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography component="div">
                  <Box fontSize="fontSize" m={1}>
                    Service Anniversary:<span className={classes.spanStyle}>{props.row.scheduleType}</span>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography component="div">
                  <Box fontSize="fontSize" m={1}>
                    Completion Date: <span className={classes.spanStyle}>{props.row.completionDate}</span>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Paper>
            <Grid container style={{ padding: 10 }}>
              <Grid item xs={12} md={7}>
                <Typography component="div">
                  <Box fontSize="fontSize" m={1}>
                    Service Provider Name:<span className={classes.spanStyle}>{props.row.serviceProviderName}</span>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="div">
                  <Box fontSize="fontSize" m={1}>
                    Service Provider Address:<span className={classes.spanStyle}>{props.row.serviceProviderAddress.fullAddress}</span>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Paper>
            <Grid container style={{ padding: 10 }}>
              <Grid item xs={12} md={7}>
                <Typography component="div">
                  <Box fontSize="fontSize" m={1}>
                    Odometer Reading:<span className={classes.spanStyle}>{props.row.odometerReading}</span>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Paper>
            <Grid container style={{ padding: 10 }}>
              <Grid item xs={12}>
                <Typography component="div">
                  <Box fontSize="fontSize" m={1}>
                    {props.row.description ? (
                      <span>{props.row.description}</span>
                    ) : (
                      <p style={{ textAlign: "center" }}>Description not available!</p>
                    )}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Paper>
            <Grid container style={{ padding: 10 }}>
              {props.row.documents && props.row.documents.length ? (
                <TableContainer>
                  <Table aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className={classes.tableCellPadding}>
                          <Typography>Document Type</Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCellPadding}>
                          <Typography >Document Name/Title</Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCellPadding}>
                          <Typography >Expiry Date</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.row.documents && props.row.documents.length && props.row.documents.map(
                        (document: IServiceDocument, index: number) => {
                          return (
                            <TableRow key={index}>
                              <TableCell className={classes.tableCellPadding}>
                                {document.documentType}
                              </TableCell>
                              <TableCell className={classes.tableCellPadding}>
                                {document.documentName ? document.documentName : document.title}
                              </TableCell>
                              <TableCell className={classes.tableCellPadding}>{document.expiryDate ? getLocalizedDateFormat(country, document.expiryDate, DATE_TYPE.EXPANDED) : "N/A"}</TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Grid item xs={12}>
                  <p style={{ textAlign: "center" }}>Sorry, no matching records found.</p>
                </Grid>
              )}
            </Grid>
          </Paper>
        </TabPanel>
      </Paper>
    </Grid>
  );
};
