import { gql } from "@apollo/client";

export const ADD_PROCUREMENT_PROPOSAL_REQUEST = gql`
  mutation addProposalRequest($id: ID!, $proposalRequest: ProcurementProposalRequestInput!) {
    addProposalRequest(id: $id, proposalRequest: $proposalRequest) {
      proposalRequests {
        id
        proposalRequestId
        date
        partnerId
        partner {
          id
          partnerName
        }
        validUntilDate
        lastEmailSent
        note {
          description
          # createdBy {
          #   firstName
          #   lastName
          # }
          createdAt
        }
        items {
          vehicleMake
          vehicleModel
          vehicleVarient
          vehicleFuel
          vehicleTransmission
          vehicleCondition
          vehicleCount
          vehicleExpectedByDate
          documents
        }
      }
      quotes {
        id
        quoteId
        date
        partnerId
        validUntilDate
        lastEmailSent
        partner {
          id   
          partnerName
        }
        amount
        proposalRequestId
        documents
        status 
        revisions {
          amount
          date
          validUntilDate
          updatedAt
          documents
        }
      }
    }
  }
`;