import { gql } from "@apollo/client";

export const SEARCH_BOOKINGS = gql`
  query searchBookings($q: String!, $limit: Int!, $offset: Int!) {
    searchBookings(q: $q, limit: $limit, offset: $offset) {
      id
      referenceNumber
      source
      pickupServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      dropoffServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      dropoffOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      pickupDateTime
      dropoffDateTime
      customerType
      dateUpdated
      status
      customer {
        id
        firstName
        lastName
      }
      currentBookingSchedules {
        id
        startDate
        endDate
        status
        vehicleStatus
        vehicle {
          id
          licencePlate
          branchId
          imageUrl
          bodyType
        }
      }
      createdBy {
        id
        firstName
        lastName
      }
      businessCustomer {
        id
        businessName
      }
      authorizedSignatory
      isSubscription
    }
  }
`;