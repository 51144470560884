import { gql } from "@apollo/client";

export const GET_EXTERNAL_BOOKING_SUMMARY = gql`
  query externalBooking($id: String!) {
    externalBooking(id: $id) {
      id
      referenceNumber
      pickupServiceLocation {
        id
        name
      }
      pickupOtherLocation {
        fullAddress
      }
      dropoffOtherLocation {
        fullAddress
      }
      longTermBooking
      pcoNumber
      rateTypeDuration
      pickupDateTime
      dropoffServiceLocation {
        name
      }
      extendedDropoffDateTime
      initialDropoffDateTime
      rateTypeName
      dropoffDateTime
      customerType
      status
      customer {
        id
        firstName
        lastName
        email
        phoneNumber {
          phone
          country
        }
        dateOfBirth
      }
      businessCustomer {
        businessName
        id
        contact {
          phoneNumber {
            phone
          }
        }
      }
      branchId
    }
  }
`;
