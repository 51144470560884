import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { FilePicker } from "../../../common/FilePicker/FilePicker";
import { CsvUploadStatusType, DATE_TYPE, ICsvUploadError, formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from "../../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { v4 as uuidv4 } from "uuid";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { MUIDataTableOptions } from "mui-datatables";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { useNavigate } from "react-router-dom";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { UPLOAD_DAMAGE_MATRIX } from "../../../../graphql/damageMatrix/uploadDamageMatrix";
import { GET_DAMAGE_MATRICES } from "../../../../graphql/damageMatrix/getDamageMatrices";
import { GET_DAMAGE_MATRIX_COUNT } from "../../../../graphql/damageMatrix/damageMatrixCount";
import { ITable, TableNames } from "../../../../reducers/tableState/types";
import { addTableState } from "../../../../actions/tableState/actions";
import { SEARCH_DAMAGE_MATRICES } from "../../../../graphql/damageMatrix/searchDamageMatrices";
import { SOCKET_EVENTS } from "../../ReservationManagement/utils";
import { socket } from "../../../../utils/socket";

interface IFileUpload {
  name: string
  url: string
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
}

export interface IDamageMatrix {
  id: string;
  branchId: string;
  organisationId: string;
  tenantId: string;
  type: string;
  make: string;
  model: string;
  year: string;
  components: IComponent[];
  createdAt: string;
  updatedAt: string;
  updatedBy: IUser;
}

export interface IComponent {
  part: string;
  damageType: string;
  s1: number;
  s2: number;
  s3: number;
  s4: number;
  vatOnSMR: number;
  vatOnParts: number;
  vatOnInstallation: number;
  replacementCost: number;
  installationCharges: number;
  overallMarkup: number;
}

const DamageMatrix: React.FC = () => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false)
  const [files, setFiles] = useState<IFileUpload[]>([])
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [uploading, setUploading] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<ICsvUploadError[]>([]);
  const [uploadStatus, setUploadStatus] = useState<number>(
    CsvUploadStatusType.DEFAULT
  );
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [rows, setRows] = useState<IDamageMatrix[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [damageMatrixCount, setDamageMatrixCount] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [damageMatrixTable, setDamageMatrixTable] = useState<ITable>();
  const [page, setPage] = useState<number>(0);

  const damageMatrixTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.damageMatrix
  );

  const [
    getDamageMatrices,
    { loading: damageMatrixLoading }
  ] = useLazyQuery(GET_DAMAGE_MATRICES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.damageMatrices) {
        socket.emit(SOCKET_EVENTS.REQUEST_VIEW_DAMAGE_MATRIX, {
          damageMatrixRoom: `damageMatrix-${userState.id}`
        });
        setRows((prevRows) => [...prevRows, ...data.damageMatrices]);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });
  const [
    searchDamageMatrices,
    { loading: searchDamageMatrixLoading }
  ] = useLazyQuery(SEARCH_DAMAGE_MATRICES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.searchDamageMatrices) {
        setDamageMatrixCount(data.searchDamageMatrices.length)
        setRows(data.searchDamageMatrices)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [uploadDamageMatrix, { loading: damageMatrixUploading }] = useMutation(
    UPLOAD_DAMAGE_MATRIX,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ uploadDamageMatrix }) => {
        snackbar({
          message: uploadDamageMatrix.message,
          variant: SnackBarVariant.SUCCESS
        })
        setFiles([])
        setUploadModalOpen(false)
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [getDamageMatrixCount] = useLazyQuery(GET_DAMAGE_MATRIX_COUNT, {
    fetchPolicy: "network-only",
    onCompleted: (damageMatrixCount) => {
      setDamageMatrixCount(damageMatrixCount.damageMatrixCount)
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR,
      });
    }
  });

  useEffect(() => {
    socket.auth = {
      userId: userState.id,
    };
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.GET_UPDATED_DAMAGE_MATRIX, () => {
      setRows([])
      getDamageMatrices({
        variables: {
          offset: 0,
          limit: damageMatrixTable?.rowsPerPage || limit
        }
      });
    });

    return () => {
      socket.off(SOCKET_EVENTS.GET_UPDATED_DAMAGE_MATRIX);
    }
  }, [])

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchDamageMatrices({
        variables: {
          q: searchText
        }
      })
    }
  }, 1000);

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
        viewColumns: false
      }
    },
    {
      label: "Make",
      name: "make"
    },
    {
      label: "Model",
      name: "model"
    },
    {
      label: "Year",
      name: "year"
    },
    {
      label: "Last Updated By",
      name: "updatedBy",
      options: {
        customBodyRender: (data: IUser) => `${data?.firstName} ${data?.lastName}`
      }
    },
    {
      label: "Last Updated At",
      name: "updatedAt",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED) : "-"
      }
    }
  ];

  useEffect(() => {
    if (damageMatrixTableState) {
      setDamageMatrixTable(damageMatrixTableState);
      let sortedOrder = returnSortedOrder(damageMatrixTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, damageMatrixTableState, sortedOrder)
      }
    }
  }, [damageMatrixTableState]);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: damageMatrixCount,
    rowsPerPageOptions: [10, 20, 100],
    page,
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return;
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length) {
            setPage(page);
            getDamageMatrices({
              variables: {
                limit: rowsPerPage,
                offset: page * rowsPerPage
              }
            });
          }
          break;
        case "changeRowsPerPage":
          setLimit(tableState.rowsPerPage);
          setPage(0);
          setRows([])
          getDamageMatrices({
            variables: {
              offset: 0,
              limit: tableState.rowsPerPage || limit
            }
          });
          break;
        default:
          break;
      }
    },
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: damageMatrixLoading || searchDamageMatrixLoading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: IDamageMatrix = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/vehicle-damage-matrix-components?id=${row.id}`);
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.DAMAGE_MATRIX,
          rowsPerPage: numberOfRows
        })
      );
    },
    onTableInit: (action: string) => {
      if (damageMatrixTable && sortOrder) {
        setTableSortOrder(columns, damageMatrixTable, sortOrder);
      }
      if (damageMatrixTable && damageMatrixTable.rowsPerPage) {
        setLimit(damageMatrixTable.rowsPerPage);
      }
    },
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText)
      } else {
        handleSearch.cancel();
        setRows([])
        getDamageMatrixCount()
        getDamageMatrices({
          variables: {
            limit,
            offset: 0
          }
        })
      }
    },
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.DAMAGE_MATRIX,
          columnName: changedColumn,
          direction
        })
      );
    },
    rowsPerPage:
      damageMatrixTable && damageMatrixTable.rowsPerPage
        ? damageMatrixTable.rowsPerPage
        : limit,
  };

  useEffect(() => {
    if (userState) {
      getDamageMatrixCount()
      getDamageMatrices({
        variables: {
          limit: damageMatrixTableState?.rowsPerPage ? damageMatrixTableState.rowsPerPage : limit,
          offset: 0
        }
      })
    }
  }, [userState])

  const handleFileSelect = async (e: any) => {
    setUploading(true);
    const fileList: any[] = []
    const selectedFiles = e.target.files;
    if (selectedFiles?.length) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const name = file.name.replace(/((\s\([0-9]\))+)?\.[^\.]+$/, "");
        const uniqueId = uuidv4();
        const key = `${uniqueId}.xlsx`;
        if (_e_) {
          await _e_.add({
            name: key,
            file: file,
            complete: async () => {
              fileList.push({
                name,
                url: key
              })
            }
          });
        }
      }
    }
    setUploading(false)
    setFiles(fileList);
  }

  const uploadFiles = () => {
    if (files.length) {
      uploadDamageMatrix({
        variables: {
          damageMatrices: files
        }
      })
    } else {
      snackbar({
        message: "Damage Matrix not selected",
        variant: SnackBarVariant.ERROR
      })
    }
  }

  return (
    <Grid container>
      <Grid container xs={12}>
        <Grid item xs={4}>
          <Typography variant="h1" color={"primary"}>Damage Matrix</Typography>
        </Grid>
        <Grid container xs={8} justifyContent="flex-end">
          <Fab
            variant="extended"
            size="medium"
            aria-label="Upload"
            className="createButton"
            onClick={() => setUploadModalOpen(true)}
          >
            Upload
          </Fab>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ marginTop: "1rem" }}>
        <NuvvenTable
          title={""}
          rows={rows}
          columns={columns}
          setSelection={() => ""}
          options={options}
        />
      </Grid>
      {uploadModalOpen ? <Dialog
        fullWidth={true}
        open={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <DialogTitle>
          <Grid container xs={12}>
            <Grid item xs={4}>
              Upload Damage Matrix
            </Grid>
            <Grid container xs={8} justifyContent="flex-end">
              <Typography variant="body1">
                Download damage matrix template .xlsx file:
                <a
                  style={{ color: "#000" }}
                  href={"https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Damage_Matrix.xlsx"}
                >
                  {" "}
                  {"Damage Matrix"}{" "}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container xs={12}>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                Select Damage Matrix to Upload
                {uploading && <CircularProgress size={14} style={{ color: "white", marginLeft: "10px" }} />}

              </Typography>
            </Grid>
            <Grid container xs={6}>
              <FilePicker
                onFilePick={(e: any) => {
                  handleFileSelect(e);
                }}
                accept=".xlsx"
                multiple={true}
              />
            </Grid>
          </Grid>
          {files.length > 0 && <Grid container xs={12} style={{ marginTop: "1rem" }}>
            <Typography variant="body1" style={{ marginBottom: "1rem" }}>{`${files.length} Damage Matri${files.length > 1 ? 'ces' : 'x'} selected: `}</Typography>
            <Grid item xs={12}>
              {files.map(file => {
                return (<Typography variant="h4">{file.name}</Typography>)
              })}
            </Grid>

          </Grid>}
          <Grid container xs={12} spacing={2} style={{ marginTop: "1rem" }}>
            <Grid container item xs={6}>
              {uploadStatus === CsvUploadStatusType.IN_PROGRESS ? (
                <CircularProgress />
              ) : (
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="uploadCsv"
                  onClick={() => uploadFiles()}
                  disabled={damageMatrixUploading || !files.length || uploading}
                >
                  {damageMatrixUploading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                  Upload
                </Fab>
              )}
            </Grid>
            {uploadErrors.length > 0 && (
              <Grid item container xs={12}>
                <div>
                  {uploadErrors.map((upldError: ICsvUploadError, idx: number) => {
                    return (
                      <div
                        key={idx}
                      >{`${""} can\'t be uploaded at row ${upldError.index +
                        1} because ${upldError.message}`}</div>
                    );
                  })}
                </div>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog> : <></>}
    </Grid>
  )
}

export default DamageMatrix;