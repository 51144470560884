import { CircularProgress, Fab, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { TextField } from "formik-mui";
import { useSelector } from "react-redux";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../../../../common/NuvvenTable/types";
import { IVehicle, IVehicleOwnership } from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { ApolloError, useMutation } from "@apollo/client";
import theme from "../../../../../common/Theme";
import { createStyles, makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik } from "formik";
import PhoneInput, { Value, isValidPhoneNumber } from "react-phone-number-input";
import AddressPicker from "../../../../CustomerManagement/NewCustomer/AddressPicker/AddressPicker";
import { getDefaultCountryCode } from "../../../../../../utils/localized.syntex";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../../common/utils";
import _ from "lodash";
import { IAddress } from "../../../../../../reducers/user/types";
import { CREATE_VEHICLE_OWNERSHIP, UPDATE_VEHICLE_OWNERSHIP } from "../../../../../../graphql/fleet/createVehicleOwnershipMutation";


interface IProps {
  vehicle: IVehicle;
  updateFields(payload: any): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: theme.spacing(3)
    },
    createButton: {
      background: "#000000",
      color: "#fff",
      fontSize: "12px"
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    container: {
      backgroundColor: "#f4f4fa",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
  })
);



export const viewColumns = [
  {
    label: "Ownership Number",
    name: "ownershipNumber"
  },
  {
    label: "Name",
    name: "fullName"
  },
  {
    label: "Phone Number",
    name: "phoneNumber"
  },
  {
    label: "Email",
    name: "email"
  }
];

const initialOwnershipValue = {
  firstName: "",
  lastName: "",
  phoneNumber: {
    phone: "" as Value,
    country: ""
  },
  email: "",
  address: {
    city: "",
    country: "",
    fullAddress: "",
    state: "",
    street: "",
    zipcode: ""
  }
}



export const OwnershipHistory = (props: IProps) => {
  const styles = useStyles();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const { country } = userState.currentOrganisation.address;
  const [values, setValues] = useState<IVehicleOwnership>(initialOwnershipValue);
  const [countryCode, setCountryCode] = useState<any>(getDefaultCountryCode(country));
  const [phoneError, setPhoneError] = useState<string>("");
  const [editForm, setEditForm] = useState<boolean>(false);

  const [rows, setRows] = useState<any[]>([])
  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: any = rows[rowMeta.dataIndex];
      const selectedOwner = props.vehicle.vehicleOwners.find((owner) => owner.email === rowData[3])
      if (selectedOwner) {
        setEditForm(true)
        setValues(selectedOwner)
      }
    }
  };

  useEffect(() => {
    if (props.vehicle && props.vehicle?.vehicleOwners) {
      const reshapedData = reshapeTableData(props?.vehicle?.vehicleOwners)
      setRows(reshapedData)
    }
  }, [props.vehicle])

  const reshapeTableData = (data: IVehicleOwnership[]) => {
    return data.map((val: IVehicleOwnership) => ({
      ownershipNumber: val.ownershipNumber,
      fullName: `${val.firstName} ${val.lastName}`,
      phoneNumber: val.phoneNumber.phone,
      email: val.email
    }));
  }

  const [createVehicleOwnership, { loading: createOwnershipLoading }] = useMutation(
    CREATE_VEHICLE_OWNERSHIP,
    {
      onCompleted: (data) => {
        
        const { vehicle, updateFields } = props;
        setValues(initialOwnershipValue)
        const shapedOwnershipData = reshapeTableData(data.createVehicleOwnership)
        setRows(shapedOwnershipData)
        const updatedVehicle = _.cloneDeep(vehicle)
        updatedVehicle.vehicleOwners = data.updateVehicleOwnership;
        updateFields(updatedVehicle);
        snackbar({
          message: formatGraphQLErrorMessage("Owner transfer successfully"),
          variant: SnackBarVariant.SUCCESS
        });
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [updateVehicleOwnership, { loading: updateVehicleOwnershipLoading }] = useMutation(
    UPDATE_VEHICLE_OWNERSHIP,
    {
      onCompleted: (data) => {
        setValues(initialOwnershipValue)
        setEditForm(false)
        const { vehicle, updateFields } = props;
        const shapedOwnershipData = reshapeTableData(data.updateVehicleOwnership);
        setRows(shapedOwnershipData);
        const updatedVehicle = _.cloneDeep(vehicle)
        updatedVehicle.vehicleOwners = data.updateVehicleOwnership;
        updateFields(updatedVehicle);
        snackbar({
          message: formatGraphQLErrorMessage("Owner updated successfully"),
          variant: SnackBarVariant.SUCCESS
        });
      },
      onError: (error: ApolloError) => {
        setEditForm(true)
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
      }
    }
  )

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required.")
  });

  const submitHandler = () => {
    const updatedValues: IVehicleOwnership = {
      ...values,
      phoneNumber: {
        ...values.phoneNumber,
        country: countryCode
      }
    };
    if (editForm) {
      updateVehicleOwnership({
        variables: {
          vehicleId: props.vehicle.id,
          updateVehicleOwnershipInput: updatedValues
        }
      })
    } else {
      createVehicleOwnership({
        variables: {
          vehicleId: props.vehicle.id,
          vehicleOwnershipInput: updatedValues
        }
      })
    }
  }

  const validateEmail = (value: any) => {
    let error;
    const isEmailExist = rows.some((row) => row.email === value);
    if (isEmailExist) {
      error = 'This email already exists.';
    }
    return error;
  };

  return (
    <>
      <Grid container >
        <Grid item xs={12} className={styles.root}>
          <Formik
            enableReinitialize
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              submitHandler()
              setSubmitting(false);
            }}
          >
            {(props) => {
              return (
                <React.Fragment>
                  <Form>
                    <Grid container spacing={2}>
                      <Grid container item xs={12}>
                        <Typography variant="h3">Transfer Vehicle Ownership</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Field
                              component={TextField}
                              name="firstName"
                              placeholder="First Name"
                              label="First Name"
                              onChange={(e: any) => {
                                props.setFieldValue("firstName", e.target.value)
                                setValues({
                                  ...props.values,
                                  firstName: e.target.value,
                                });
                              }}
                              value={props.values.firstName}
                              inputProps={{
                                maxLength: 50,
                              }}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Field
                              component={TextField}
                              name="lastName"
                              placeholder="Last Name"
                              label="Last Name"
                              onChange={(e: any) => {
                                props.setFieldValue("lastName", e.target.value)
                                setValues({
                                  ...props.values,
                                  lastName: e.target.value
                                })
                              }}
                              value={props.values.lastName}
                              inputProps={{
                                maxLength: 50
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Field
                              component={TextField}
                              name="email"
                              placeholder="Email"
                              label="Email"
                              validate={validateEmail}
                              onChange={(e: any) => {
                                props.setFieldValue("email", e.target.value)
                                setValues({
                                  ...props.values,
                                  email: e.target.value
                                })
                              }}
                              inputProps={{
                                value: props.values.email,
                                maxLength: 50
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Field
                              component={TextField}
                              name={"phoneNumber.phone"}
                              required
                            >
                              {({ form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                                return (
                                  <div className={`phone-input-container`}>
                                    <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                                      Phone Number <sup className={"phone-input-required"}>*</sup>
                                    </label>
                                    <PhoneInput
                                      international={false}
                                      defaultCountry={countryCode}
                                      placeholder="Enter phone number"
                                      className={phoneError ? "phone-input-error" : ""}
                                      value={props.values.phoneNumber.phone}
                                      name={"phoneNumber.phone"}
                                      onChange={(val: any) => {
                                        if (val && isValidPhoneNumber(val)) {
                                          setPhoneError("");
                                        }
                                        setValues({
                                          ...props.values,
                                          phoneNumber: {
                                            ...props.values.phoneNumber,
                                            phone: val
                                          }
                                        });
                                      }}
                                      onCountryChange={(val) => {
                                        setCountryCode(val);
                                        if (val) {
                                          setValues({
                                            ...props.values,
                                            phoneNumber: {
                                              ...props.values.phoneNumber,
                                              country: val
                                            }
                                          })
                                        }
                                      }}
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </Grid>
                          <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                            <AddressPicker
                              fieldName={"address"}
                              onChange={(address: IAddress) => {
                                if (address) {
                                  setValues({ ...props.values, address: address })
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Fab
                          variant="extended"
                          size="medium"
                          color="primary"
                          aria-label="submit"
                          type="submit"
                        >
                          {(createOwnershipLoading || updateVehicleOwnershipLoading) && <CircularProgress size={16} />}
                          Submit
                        </Fab>
                      </Grid>
                    </Grid>
                  </Form>
                </React.Fragment>
              )
            }
            }
          </Formik>
        </Grid>
      </Grid >
      <Paper variant="outlined" className={styles.root}>
        <Grid className={styles.title}>
          <Typography variant="h3">Ownership HIstory</Typography>
        </Grid>
        <NuvvenTable
          title=""
          rows={rows}
          columns={viewColumns}
          options={options}
          setSelection={() => { }}
        />
      </Paper>
    </>
  )
};