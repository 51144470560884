import { gql } from "@apollo/client";

export const UPDATE_PROCUREMENT_QUOTE = gql`
  mutation updateProcurementQuote($id: ID!, $procurementQuoteId: ID!, $procurementQuote: ProcurementQuoteInput!) {
    updateProcurementQuote(id: $id, procurementQuoteId: $procurementQuoteId, procurementQuote: $procurementQuote) {
      id
      quoteId
      date
      partnerId
      validUntilDate
      lastEmailSent
      note {
        description
        # createdBy {
        #   firstName
        #   lastName
        # }
        createdAt
      }
      amount
      proposalRequestId
      documents
      status
      revisions {
        quoteId
        amount
        date
        validUntilDate
        updatedAt
        documents
      }
    }
  }
`;