import {
  CLEAR_VEHICLE_PRICE_GROUP,
  UPDATE_VEHICLE_PRICE_GROUP,
  VehiclePriceGroupAction
} from "./types";

export function updateVehiclePriceGroup(payload: any): VehiclePriceGroupAction {
  return {
    payload,
    type: UPDATE_VEHICLE_PRICE_GROUP
  };
}

export function clearVehiclePriceGroup(): VehiclePriceGroupAction {
  return {
    type: CLEAR_VEHICLE_PRICE_GROUP
  };
}
