import { gql } from "@apollo/client";

export const CREATE_EXPENSE = gql`
  mutation createExpense($expense: ExpenseInput) {
    createExpense(expense: $expense) {
      fuelType
      vendor
      id
      vehicle {
        licencePlate
      }
    }
  }
`;

