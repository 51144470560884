export enum ReportType {
  TRACKING = "tracking",
  DTC_ALERT = "dtcalert",
  CO2_EMISSION = "co2emission"
}

export enum ReportStatus {
  QUEUED = "QUEUED",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED"
}

export interface IReportRequest<T> {
  id: string;
  deviceEsns: string[];
  status: ReportStatus;
  startDate: string;
  endDate: string;
  data: T[];
  createdAt: string;
}
export interface IDeviceReportRequest {
  id: string;
  deviceEsn: string;
  status: ReportStatus;
  startDate: string;
  endDate: string;
  data?: TelematicStartStopEvent;
}

export interface IDtcAlert {
  deviceEsn: string;
  alerts: IDtcAlertEvent[];
}
export interface IDtcAlertEvent {
  codeType: string;
  deviceEsn: string;
  eventTime: string;
  eventType: string;
  gpsSpeed: number;
  latitude: number;
  longitude: number;
  description: string;
  address: string;
}

export interface TelematicStartStopEvent {
  deviceEsn: string;
  numOfJourneys: number;
  totalDuration: number;
  totalMiles: number;
  totalIdleTime: number;
  journeys: TelematicStartStopEventJourney[];
}

export interface TelematicStartStopEventJourney {
  startStreet?: string;
  endStreet?: string;
  sTime: string;
  eTime: string;
  duration: number;
  idleTime: number;
  miles: number;
  startLat: number;
  startLong: number;
  endLat: number;
  endLong: number;
  maxSpeed: number;
  odometer: number;
  harshBraking: number;
  harshAcceleration: number;
  hardCornering: number;
  speeding: number;
  lostGPS: number;
  lostGSM: number;
  tripEventDetails: ITelematicsData;
}

export interface ITelematicsData {
  milesDriven: string;
  engineHours: string;
  fuelUsage: string;
  stoppageHours: string;
  mapFlagsData: IMapFlagsData;
  mapRoutesData: IMapRouteData;
  tripTimesData: {
    tripStart: string[];
    tripEnd: string[];
  };
}

export interface IMapFlagsData {
  eventTime: string[];
  speedlimit: string[];
  magnitude: string[];
  Key: string[];
  latlong: string[];
}

export interface IMapRouteData {
  eventTime: string[];
  latlong: string[];
}
