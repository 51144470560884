import { useLazyQuery } from "@apollo/client";
import { SelectableRows } from "../../../../../common/NuvvenTable/types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET_BUSINESS_CUSTOMER_BOOKINGS } from "../../../../../../graphql/bookings/getBusinessCustomerBookingHistoryQuery";
import { IAppState } from "../../../../../../store";
import { MUIDataTableOptions } from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { BOOKING_SOURCE, BookingStatus, BookingStatusText, IBookingSchedule } from "../../../../../../reducers/bookings/types";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { CircularProgress, FormControl, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, Tooltip } from "@mui/material";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import RegistrationColumn from "../../../../ReservationManagement/Booking/RegistrationColumn";
import { isArray } from "lodash";
import { DATE_TYPE } from "../../../../../common/utils";
import InfoIcon from "@mui/icons-material/Info";

interface IProps {
  customerId: string;
}

interface IRegistrationNumberColumn {
  branchName: string;
  externalFleet: boolean;
  licencePlates: string[];
}

export const BookingHistory: React.FC<IProps> = (props) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const navigate = useNavigate();
  const { country } = userState.currentOrganisation.address;
  const [selection, setSelection] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [loadBookings, { loading, data }] = useLazyQuery(
    GET_BUSINESS_CUSTOMER_BOOKINGS,
    {
      fetchPolicy: "network-only",
      variables: {
        id: props.customerId
      }
    }
  );

  const bookingsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.bookings
  );
  const filterVehicles: any = ["All"];
  const columns: any = (columnType: string, country: string, isCarSubscription?: boolean) => {
    return [
      {
        label: "ID",
        name: "referenceNumber",
        options: {
          filter: false
        }
      },
      {
        label: "Status",
        name: "status",
        options: {
          filter: true,
          customBodyRender: (value: BookingStatus) =>
            BookingStatusText[value],
          filterOptions: {
            names: [BookingStatusText.CANCELLED, BookingStatusText.COMPLETED, BookingStatusText.CONFIRMATION_IN_PROCESS, BookingStatusText.CONFIRMED, BookingStatusText.IN_PROGRESS, BookingStatusText.QUOTE, BookingStatusText.REQUIRES_APPROVAL]
          }
        }
      },
      {
        label: columnType === "bookings" ? "Customer" : "Signatory",
        name: columnType === "bookings" ? "customer" : "authorizedSignatoryName",
        options: {
          filter: false
        }
      },
      {
        label: "Pick-up Date & Time",
        name: "pickupDateTime",
        options: {
          filter: false,
          customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
        }
      },
      {
        label: "Pick-up Location",
        name: "pickupServiceLocation",
        options: {
          filter: false
        }
      },
      {
        label: "Drop-off Date & Time",
        name: "dropoffDateTime",
        options: {
          filter: false,
          customBodyRender: (value: string) =>
            value
              ? getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
              : ""
        }
      },
      {
        label: "Drop-off Location",
        name: "dropoffServiceLocation",
        options: {
          filter: false
        }
      },
      {
        label: "Channel",
        name: "source",
        options: {
          customBodyRender: (value: string) => {
            if (value === BOOKING_SOURCE.B2B) {
              return "Web App"
            } else if (value === BOOKING_SOURCE.B2B2C) {
              return "Website"
            } else if (value === BOOKING_SOURCE.B2B2C_MOBILE) {
              return "Mobile App"
            }
          },
          filterOptions: {
            names: ["Web App", "Website", "Mobile App"]
          }
        }
      },
      {
        label: "Booking Source",
        name: "bookingSource",
        options: {
          display: false,
          filter: false,
          viewColumns: false
        }
      },
      {
        label: "licencePlateStr",
        name: "licencePlateStr",
        options: {
          display: false,
          filter: false,
          viewColumns: false
        }
      },
      {
        label: "Customer Type",
        name: "customerType",
        options: {
          customBodyRender: (value: string) => {
            if (value) {
              return value.toUpperCase();
            }
          },
          filterOptions: {
            names: ["INDIVIDUAL", "BUSINESS"]
          }
        }
      }, {
        label: "TBA",
        name: "tbaData",
        options: {
          display: (bookingsTableState?.viewColumns?.tbaData === "true"),
          filterOptions: {
            names: ["-", "TBA"]
          }
        }
      },
      isCarSubscription ? {
        label: `${getLocalizedBookingSyntex(country)} Type`,
        name: "bookingType",
        options: {
          display: (bookingsTableState?.viewColumns?.bookingType === "true"),
          filterOptions: {
            names: ["RENTAL", "SUBSCRIPTION"]
          }
        }
      } : {
        label: `${getLocalizedBookingSyntex(country)} Type`,
        name: "bookingType",
        options: {
          display: (bookingsTableState?.viewColumns?.bookingType === "true"),
          filter: false
        }
      },
      {
        label: "Created By",
        name: "bookingUser",
        options: {
          filter: false,
          customBodyRender: (value: string) => {
            return value
          }
        }
      },
      {
        label: "Registration Number",
        name: "licencePlateDetails",
        options: {
          filterType: "custom",
          filter: false,
          names: ["Registration number"],
          customBodyRender: (value: any) => {
            if (value && value.licencePlates && value.licencePlates.length) {
              value.licencePlates.forEach((licencePlate: string) => {
                if (!filterVehicles.includes(licencePlate)) {
                  filterVehicles.push(licencePlate);
                }
              })
            }
            if (value?.externalFleet) {
              return <Grid container xs={12}>
                <Grid item xs={10}>
                  <RegistrationColumn licencePlates={value.licencePlates} />
                </Grid>
                {
                  value.externalFleet && (
                    <Grid item xs={1}>
                      <Tooltip title={`Shared Fleet - ${value.branchName}`}>
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                  )
                }
              </Grid>
            }
            return <RegistrationColumn licencePlates={value?.licencePlates || []} />
          },
          filterList: [],
          customFilterListOptions: {
            render: (item: string) => {
              return item;
            },
            update: (filterList: any[], filterPos: number, index: number) => {
              if (filterPos !== -1) {
                filterList[index] = [];
              } else if (filterPos === -1) {
                filterList[index] = [];
              }
              return filterList;
            }
          },
          filterOptions: {
            logic: (data: any[], filters: string[]) => {
              const newData =
                data &&
                Object.values(data).map((item: any) => {
                  if (isArray(item)) {
                    const licencePlates: string[] = item;
                    return licencePlates.join(",");
                  }
                  return item;
                });
              if (filters.length) {
                return !newData.includes(filters);
              }
              return false;
            },
            display: (
              filterList: any[],
              onChange: Function,
              index: number,
              column: any[]
            ) => {
              const optionValues =
                filterVehicles &&
                filterVehicles.filter((item: string, key: number) => {
                  return filterVehicles.indexOf(item) === key;
                });
              return (
                <FormControl>
                  <InputLabel htmlFor="select-multiple-chip">Registration Number</InputLabel>
                  <Select
                    value={filterList[index].length ? filterList[index] : "All"}
                    onChange={(event) => {
                      if (event.target.value === "All") {
                        filterList[index] = [];
                        onChange(filterList[index], index, column);
                      } else {
                        filterList[index] = event.target.value;
                        onChange(filterList[index], index, column);
                      }
                    }}
                  >
                    {optionValues &&
                      optionValues.map((item: string, key: number) => (
                        <MenuItem key={key} value={item}>
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              );
            }
          }
        }
      }
    ];
  }

  useEffect(() => {
    if (userState.tenancy) {
      loadBookings();
    }
  }, [userState]);

  useEffect(() => {
    if (data) {
      setRows(
        data.bookingsByBusinessCustomerId.map((booking: any) =>
          reshapeBookingsIntoRows(booking) // FIXME: Send authorisedSignatory name and licencePlate from backend
        )
      );
    }
  }, [data]);

  function reshapeBookingsIntoRows(booking: any) {
    let authorizedSignatoryName = "";
    let bookingUser = `${booking?.createdBy?.firstName || "-"} ${booking?.createdBy?.lastName || ""}`
    if (booking.businessCustomer && booking.businessCustomer.authorizedSignatories) {
      const signatoryArr = booking.businessCustomer.authorizedSignatories.filter((item: any) =>
        item.id === booking.authorizedSignatory
      );
      if (signatoryArr && signatoryArr.length) {
        authorizedSignatoryName = signatoryArr[0].firstName + " " + signatoryArr[0].lastName;
      }
    }
    let licencePlateDetails: any = {
      externalFleet: false,
      branchName: "",
      licencePlates: []
    };
    if (
      booking.currentBookingSchedules &&
      booking.currentBookingSchedules.length
    ) {
      const licencePlateArr: string[] = [];
      booking.currentBookingSchedules.forEach((schedule: IBookingSchedule) => {
        if (!licencePlateDetails.externalFleet && schedule?.vehicle?.branchId !== userState.currentBranch.id) {
          const branch = userState.currentOrganisation.branches.find((item => item.id === schedule?.vehicle?.branchId))
          if (branch) {
            licencePlateDetails.branchName = branch.name
          }
        }
        if (schedule?.vehicle?.licencePlate) {
          licencePlateArr.push(
            schedule.vehicle.licencePlate.toUpperCase()
          );
        }
      });
      if (licencePlateArr.length) {
        licencePlateDetails.licencePlates = licencePlateArr;
      }
    }
    const pickupServiceLocation = booking.pickupServiceLocation?.name || "";
    const dropoffServiceLocation = booking.dropoffServiceLocation?.name || "";
    const source = booking.source || "";
    let tbaData = booking?.tba ? "TBA" : "-";
    let bookingType = booking.isSubscription ? "SUBSCRIPTION" : "RENTAL" ;
    return {
      ...booking,
      authorizedSignatoryName,
      licencePlateDetails,
      pickupServiceLocation,
      dropoffServiceLocation,
      source,
      bookingUser,
      tbaData,
      bookingType
    };
  }

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    elevation: 0,
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      if (rowData && rowData.length) {
        const bookingClicked = data.bookingsByBusinessCustomerId[rowMeta.dataIndex];
        if (bookingClicked.status === BookingStatus.QUOTE) {
          navigate(`/update-booking?booking=${bookingClicked.id}&step=3`);
        } else {
          navigate(`/view-booking?booking=${bookingClicked.id}`);
        }
      }
    },
    onDownload: (buildHead, buildBody, columns, data: { data: any[]; }[]) => {
      data.forEach(row => {
        row.data[row.data.length - 1] = getRegistrationNumber(row.data[row.data.length - 1]);
      });
      return buildHead(columns) + buildBody(data)
    },
  };

  const getRegistrationNumber = (data: IRegistrationNumberColumn) => {
    if (data.licencePlates && data.licencePlates.length > 0) {
      return data.licencePlates.join(",");
    }
    else {
      return "";
    }
  }

  return (
    <div>
      {loading ?
        <CircularProgress /> :
        (
          <Paper variant="outlined">
            <NuvvenTable
              title={""}
              rows={rows}
              columns={columns("bookingsHistory", country)}
              setSelection={setSelection}
              options={options}
            />
          </Paper>
        )}
    </div>
  );
};
