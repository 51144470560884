import { DamagesApprovalStatus } from "../../components/common/VehicleDamagePreview/frogDiagram";
import { IDamage } from "../../components/views/Fleet/Issues&Damages/NewDamage/AddDamage";
import { VehicleDamageCircumstance } from "../../components/views/Fleet/Issues&Damages/constants";
import { getLocalizedBookingSummarySyntex, getLocalizedBookingSyntex } from "../../utils/localized.syntex";
import { IVehicleState } from "../fleet/types";

export interface IVehicleDamageState {
  vehicleDamage: IVehicleDamage;
}

export type VehicleDamagePayloadType = IVehicleDamage | IVehicleDamageId;

export interface IVehicleDamage {
  approvalStatus?: DamagesApprovalStatus;
  id?: string;
  title: string;
  descriptions: string;
  damageType: string;
  circumstance: string;
  bookingRef: string;
  thirdParty: boolean;
  reportDate: string;
  incidentDate: string;
  reportedBy: string;
  estimatedCost: number;
  damageDocuments: IVehicleDamageDocument[];
  vehicleId: string;
  images: string[];
  interiorImages: string[];
  exteriorImages: IExteriorImage[];
  vehicleState: IVehicleState;
  status: string;
  damageSeverity: string;
  estimatedRepairDate: string;
  damageKind?: string;
  damageArea?: string;
  location?: IGeoCoordinates;
  frogDiagram?: string;
  damages?: IDamage[]
}

export interface IGeoCoordinates {
  x: number;
  y: number;
}

export interface IVehicleDamageDocument {
  id?: string;
  title: string;
  text: string;
}

export interface IVehicleDamageId {
  id: string;
}

export interface IExteriorImage {
  carFrontImg: string[];
  carFrontMirrorImg: string[];
  carTopImg: string[];
  carBackMirrorImg: string[];
  carBackImg: string[];
  carLeftImg: string[];
  carRightImg: string[];
}

export interface IDamagesInfo {
  DUE: IDamageInfoDetails;
  OVERDUE: IDamageInfoDetails;
}

export interface IDamageInfoDetails {
  count: number;
  ids: string[];
}

export enum VehicleDamageStatus {
  FIXED = "FIXED",
  DUE = "DUE",
  OVERDUE = "OVERDUE"
}

export const VehicleDamageStatusValue: {
  [key in VehicleDamageStatus]: string;
} = {
  FIXED: "Fixed",
  DUE: "Due",
  OVERDUE: "Overdue"
};

export enum VehicleDamageCircumstances {
  BOOKING = "BOOKING",
  TRANSIT = "TRANSIT",
  MOVEMENT = "MOVEMENT",
  OTHER = 'OTHER'
}

export const VehicleDamageCircumstancesValue: {
  [key in VehicleDamageCircumstances]: string;
} = {
  BOOKING: "During Booking",
  TRANSIT: "During Transit",
  MOVEMENT: "During Movement",
  OTHER: "Other"
};

export const getLocalizedVehicleDamageCircumstancesValue = (key: VehicleDamageCircumstance, country: string): string => {
  const circumstances: {
    [key in VehicleDamageCircumstances]: string;
  } = {
    BOOKING: `During ${getLocalizedBookingSyntex(country)}`,
    TRANSIT: "During Transit",
    MOVEMENT: "During Movement",
    OTHER: "Other"
  }
  return circumstances[key];
}

export enum DamageTypes {
  EXTERIOR = "exterior damage",
  INTERIOR = "interior damage",
  OTHER = "other damage"
}
