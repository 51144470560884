import {
  IBranch,
  IOrganisation,
  ITenancy,
  IUser
} from "../../reducers/user/types";
import {
  UPDATE_USER_BRANCH,
  UPDATE_TENANCY,
  UPDATE_TENANCY_ORGANISATION,
  UPDATE_USER,
  UserAction,
  UPDATE_USER_CURRENT_BRANCH
} from "./types";

export function updateUser(payload: IUser): UserAction {
  return {
    payload,
    type: UPDATE_USER
  };
}

export function updateTenancy(payload: ITenancy): UserAction {
  return {
    payload,
    type: UPDATE_TENANCY
  };
}

export function updateUserBranch(payload: IBranch): UserAction {
  return {
    payload,
    type: UPDATE_USER_BRANCH
  };
}
export function updateUserCurrentBranch(payload: IBranch): UserAction {
  return {
    payload,
    type: UPDATE_USER_CURRENT_BRANCH
  };
}
export function updateTenancyOrganisation(payload: IOrganisation): UserAction {
  return {
    payload,
    type: UPDATE_TENANCY_ORGANISATION
  };
}
