import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, FormGroup, Hidden, Theme, Typography } from "@mui/material";
import { ApolloError } from "@apollo/client";
import React, { useState } from "react";
import { GENERATE_INVOICE_PDF } from "../../../graphql/bookings/generateInvoicePdfMutation";

import { GENERATE_QUOTE_PDF } from "../../../graphql/bookings/generateQuoteMutation";
import { GET_BOOKING_SUMMARY_PDF } from "../../../graphql/bookings/getBookingSummaryQuery";
import { getSignedUrl } from '../../../utils/getSignedUrl';
import { useSnackBar } from "../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../SnackbarWrapper/SnackbarWrapper";
import {
  checkDeleteOrDownloadFileType,
  formatGraphQLErrorMessage
} from "../utils";
import { createStyles, makeStyles } from "@mui/styles";
import { GENERATE_CREDIT_NOTE_PDF } from "../../../graphql/bookings/generateCreditNotePdfMutation";
import { captureErrorException } from "../../../utils/sentry";

interface ISource {
  id: string;
  type: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 60px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
  })
);

const DownloadPdf = (props: any) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const [quotePdf, setQuotePdf] = useState<string>("");
  const [creditNotePdf, setCreditNotePdf] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [regenerateDialog, setRegenerateDialog] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  async function download(pdfSource: string) {
    try {
      if (pdfSource) {
        setLoading(true);
        const { contentType } = checkDeleteOrDownloadFileType(pdfSource);
        const config = { contentType, level: "public" };
        const file = await getSignedUrl(pdfSource);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = props.fileName ? props.fileName : `sample.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({
        message: err?.message,
        variant: SnackBarVariant.ERROR
      });
    }
  }

  const [generateQuotePdf] = useMutation(GENERATE_QUOTE_PDF, {
    onCompleted: (data: any) => {
      if (data.generateQuotePdf.pdf) {
        download(data.generateQuotePdf.pdf);
      } else {
        setLoading(false);
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [generateCreditNotePdf, { loading: generateCreditNotePdfLoading }] = useMutation(GENERATE_CREDIT_NOTE_PDF, {
    onCompleted: (data: any) => {
      if (data.generateCreditNotePdf.creditNoteUrl) {
        download(data.generateCreditNotePdf.creditNoteUrl);
        setRegenerateDialog(false)
        setChecked(false)
      } else {
        setLoading(false);
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [generateInvoicePdf, { loading: generateInvoicePdfLoading }] = useMutation(GENERATE_INVOICE_PDF, {
    onCompleted: (data: any) => {
      if (data.generateInvoicePdf.invoiceUrl) {
        download(data.generateInvoicePdf.invoiceUrl);
        setRegenerateDialog(false)
        setChecked(false)
      } else {
        setLoading(false);
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });
  const [
    booking,
  ] = useLazyQuery(GET_BOOKING_SUMMARY_PDF, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.booking.bookingConfirmationUrl) {
        download(data.booking.bookingConfirmationUrl);
      } else {
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const getSource = () => {
    const source: ISource = props.getSource;
    switch (source.type) {
      case "QUOTE":
        setLoading(true);
        generateQuotePdf({
          variables: {
            id: source.id
          }
        });
        break;
      case "BOOKING_SUMMARY":
        setLoading(true);
        booking({
          variables: {
            id: source.id
          }
        });
        break;
      case "INVOICE":
        setLoading(true);
        generateInvoicePdf({
          variables: {
            invoiceId: source.id
          }
        });
      case "CREDIT_NOTE":
        setLoading(true);
        generateCreditNotePdf({
          variables: {
            id: source.id
          }
        });
        break;
      default:
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR
        });
    }
  };
  const color = "white";
  return (
    <div>
      <Hidden smUp>
        <Button
          className={classes.menuButton}
          variant="contained"
          aria-label="Back"
          disabled={loading}
          onClick={() => {
            if (props.getSource.type === "INVOICE" || props.getSource.type === "CREDIT_NOTE") {
              setRegenerateDialog(true)
            } else {
              if (props.pdfSource) {
                download(props.pdfSource);
              } else {
                getSource();
              }
            }
          }}
        >
          {loading && (
            <CircularProgress
              size={14}
              style={{ color: "white", marginRight: "10px" }}
            />
          )}
          {"DOWNLOAD"}
        </Button>
      </Hidden>
      <Hidden smDown>
        <Fab
          size={"medium"}
          variant={"extended"}
          disabled={loading}
          onClick={() => {
            if (props.getSource.type === "INVOICE" || props.getSource.type === "CREDIT_NOTE") {
              setRegenerateDialog(true)
            } else {
              if (props.pdfSource) {
                download(props.pdfSource);
              } else {
                getSource();
              }
            }
          }}
        >
          {loading && (
            <CircularProgress
              size={14}
              style={{ color: "white", marginRight: "10px" }}
            />
          )}
          {"DOWNLOAD"}
        </Fab>
      </Hidden>
      {
        regenerateDialog && (
          <Dialog
            fullWidth={true}
            open={regenerateDialog}
            onClose={() => { setRegenerateDialog(false) }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title"><Typography variant="h2">{props.getSource.type === "INVOICE" ? "Download Invoice Pdf" : "Download Credit Note Pdf"}</Typography></DialogTitle>
            <DialogContent>
              <FormControlLabel
                label="Re-Generate and then download the pdf."
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e: any) => { setChecked(e.target.checked) }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
            </DialogContent>
            <DialogActions>
              <Fab
                size={"medium"}
                variant={"extended"}
                disabled={generateInvoicePdfLoading || generateCreditNotePdfLoading}
                onClick={() => {
                  if (checked) {
                    if (props.getSource.type === "INVOICE") {
                      generateInvoicePdf({
                        variables: {
                          invoiceId: props.getSource.id
                        }
                      });
                    } else {
                      generateCreditNotePdf({
                        variables: {
                          id: props.getSource.id
                        }
                      });
                    }
                  } else {
                    if (props.pdfSource) {
                      download(props.pdfSource);
                      setRegenerateDialog(false)
                    } else {
                      getSource()
                    }
                  }
                }}
              >
                {(generateInvoicePdfLoading || generateCreditNotePdfLoading) && (
                  <CircularProgress
                    size={14}
                    style={{ marginRight: "10px" }}
                  />
                )}
                Download
              </Fab>
            </DialogActions>
          </Dialog>
        )
      }
    </div>
  );
};
export default DownloadPdf;
