import { Box, Button, CircularProgress, Fab, Grid, Hidden, Theme, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { createStyles, makeStyles } from "@mui/styles";
import { TextField } from "formik-mui";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { IBooking } from "../../../../../reducers/bookings/types";
import FlatPickerBar from "../../../../common/FlatPicker";
import {
  IPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { DateTime as d } from "luxon";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../../common/utils";

interface IFormValues {
  amount: number;
}
const defaultValues: IFormValues = { amount: 0 };

interface IProps {
  loading: boolean;
  amount: number;
  booking: IBooking;
  onSubmit(data: any): void;
  collectDeposit: boolean;
  depositAmount?: number;
  creditAmount: number;
  tba?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    basicButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
    },
  })
);

export const CashTransaction = (props: IProps) => {
  const classes = useStyles();
  const [values, setValues] = useState<IFormValues>({
    ...defaultValues,
    amount: props.amount
  });
  const [capturedAt, setCapturedAt] = useState<string>("");
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency, cashEnabled } = userState.currentOrganisation;

  const { country } = userState.currentOrganisation.address;
  const [minimumPayableAmount, setMinimumPayableAmount] = useState<number>(0);

  useEffect(() => {
    if (props.collectDeposit && props.depositAmount) {
      setMinimumPayableAmount(props.depositAmount)
    }
  }, [props.collectDeposit, props.depositAmount])

  const onSubmit = (values: IFormValues) => {
    const payment: IPaymentInput = {
      amount: values.amount,
      currency,
      description: PaymentMode.CASH,
      expireBy: "",
      invoice: "",
      booking: props.booking.id,
      paymentMode: PaymentMode.CASH,
      paymentType: "INWARD",
      capturedAt: capturedAt
    };
    props.onSubmit(payment);
  };

  const AMOUNT_TOO_HIGH = `Amount must be less than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format(props.amount / 100)}`;

  const AMOUNT_TOO_LOW = `Amount must be more than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format(minimumPayableAmount / 100)}`;

  const formSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan((minimumPayableAmount - 1), AMOUNT_TOO_LOW)
      .max(props.amount, AMOUNT_TOO_HIGH)
  });

  return (
    <Grid container style={{ display: "inline-block", padding: "1rem" }}>
      {!cashEnabled ?
        <Grid container>
          <Typography variant="h4">
            To use this payment mode please enable cash payments in Payment and Integration Settings.
          </Typography>
        </Grid> :
        <Formik
          initialValues={{ ...values, amount: props.amount }}
          enableReinitialize={true}
          onSubmit={(values: any, formikBag: any) => {
            onSubmit(values);
            formikBag.setSubmitting(false);
          }}
          validationSchema={formSchema}
        >
          {(formikProps) => (
            <Form>
              <Grid container>
                <Grid item container xs={12} spacing={1}>
                  <Grid item container xs={12}>
                    <Typography variant="h3">{PaymentMode.CASH}</Typography>
                  </Grid>
                  <Grid container>
                    <Box mt={1}></Box>
                  </Grid>
                  <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Field
                      component={TextField}
                      placeholder="Enter Amount"
                      label="Enter Amount"
                      name={"amount"}
                      InputProps={{
                        inputComponent: FloatInput as any
                      }}
                      inputProps={{
                        hasCurrencyPrefix: true,
                        allowNegative: false
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FlatPickerBar
                      enableTime={false}
                      handleDateChange={(value: Date) => {
                        setCapturedAt(d.fromJSDate(value).toUTC().toISO())
                      }}
                      minDate={getLocalizedDateFormat(country, d.now().startOf("day").toUTC().toISO(), DATE_TYPE.EXPANDED)}
                      maxDate={getLocalizedDateFormat(country, d.now().endOf("day").toUTC().toISO(), DATE_TYPE.EXPANDED)}
                      label={"Payment Date"}
                      placeholderValue={"Select Payment Date*"}
                      value={capturedAt ? getLocalizedDateFormat(country, capturedAt, DATE_TYPE.CONDENSED) : ""}
                      country={country}
                    />
                  </Grid>
                  <Grid container>
                    <Box mt={1}></Box>
                  </Grid>
                  <Grid item container xs={12} justifyContent="flex-start">
                    <Hidden smDown>
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type="submit"
                        disabled={props.loading || !capturedAt}
                      >
                        {props.loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                        Save
                      </Fab>
                    </Hidden>
                    <Hidden smUp>
                      <Button
                        style={{ width: 'calc(100vw - 90px)' }}
                        className={classes.basicButton}
                        variant="contained"
                        type="submit"
                        aria-label="add"
                        disabled={props.loading}
                      >
                        {props.loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                        Save
                      </Button>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      }
    </Grid>
  );
};
