import "./index.scss";

import { useLazyQuery, useMutation, ApolloError } from "@apollo/client";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Hidden,
  InputBase,
  Theme,
  Typography,
  alpha
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import SearchIcon from '@mui/icons-material/Search';
import _ from "lodash";
import { DateTime as d } from "luxon";
import { MUIDataTableOptions } from "mui-datatables";
import papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { resetCustomer } from "../../../../actions/customer/actions";
import { addTableState } from "../../../../actions/tableState/actions";
import { UPLOAD_CUSTOMER } from "../../../../graphql/customers/customerUpload";
import {
  GET_ALL_CUSTOMERS,
  GET_CUSTOMERS
} from "../../../../graphql/customers/getCustomers";
import { GET_CUSTOMER_COUNT } from "../../../../graphql/customers/getCustomersCount";
import { SEARCH_CUSTOMER } from "../../../../graphql/customers/searchCustomerQuery";
import {
  CustomerVerificationStatus,
  CustomerVerificationStatusValue,
  ICustomer
} from "../../../../reducers/customer/types";
import { ITable, TableNames } from "../../../../reducers/tableState/types";
import { IAppState } from "../../../../store";
import { NuvvenTable } from "./../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "./../../../common/NuvvenTable/types";
import {
  CsvUploadStatusType,
  ICsvUploadError,
  formatGraphQLErrorMessage,
  individualCustomerCsvHeaders,
  possibleCsvMimeTypes
} from "./../../../common/utils";
import { BulkCsvUploadDialog } from "../../../common/BulkCsvUploadDialog/BulkCsvUploadDialog";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { returnSortedOrder, setTableSortOrder } from "../../../common/utils";
import CustomerVirtualScroller from "./CustomerVirtualScroller";
import { captureErrorException } from "../../../../utils/sentry";

export let columns: any = [
  {
    label: "First Name",
    name: "firstName"
  },
  {
    label: "Last Name",
    name: "lastName"
  },
  {
    label: "Email",
    name: "email"
  },
  {
    label: "Phone Number",
    name: "phoneNumber.phone"
  },
  {
    label: "Status",
    name: "status",
    options: {
      customBodyRender: (value: CustomerVerificationStatus) =>
        CustomerVerificationStatusValue[value]
    }
  }
];

const UseStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerMargin: {
      marginBottom: "120px"
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: "relative",
      width: "calc(100vw - 40px)",
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
      },
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: "auto",
      marginBottom: theme.spacing(1)
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: {
      width: "100%",
      color: "inherit"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch"
      }
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: "auto",
      position: "fixed",
      backgroundColor: "var(--theme-accent)"
    }
  })
);

export const Customers: React.FC = () => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const organisation = userState.currentOrganisation;
  const branch = userState.currentBranch;
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const location = useLocation();
  const customersTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.customers
  );

  const [fileValue, setFileValue] = useState<File | undefined>();
  const [fileName, setFileName] = useState<string>("");
  const [selection, setSelection] = useState<any[]>([]);
  const [uploadStatus, setUploadStatus] = useState<number>(
    CsvUploadStatusType.DEFAULT
  );
  const [limit, setLimit] = useState(10);
  const [uploadErrors, setUploadErrors] = useState<ICsvUploadError[]>([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [customersTable, setcustomersTable] = useState<ITable>();
  const [rows, setRows] = useState<ICustomer[]>([]);
  const [tableColumns, setTableColumns] = useState([...columns]);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [totalCustomerCount, setTotalCustomerCount] = useState<number>(0);
  const [paramValue, setParamValue] = useState<string>("");
  const [filterLoading, setFilterLoading] = useState<boolean>(false);

  const [loadCustomers, { loading, data }] = useLazyQuery(GET_CUSTOMERS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data) {
        const newCustomers = [...data.limitCustomers];
        const oldCustomers = [...rows];
        newCustomers.forEach((customer: ICustomer) => {
          if (!oldCustomers.some((item) => item.id === customer.id)) {
            oldCustomers.push(customer);
          }
        });
        setRows(oldCustomers);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setFilterLoading(false);
    }
  });
  const [getCustomersCount, { data: customersCountData }] = useLazyQuery(
    GET_CUSTOMER_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (customersCountData) => setTotalCustomerCount(customersCountData.customersCount)
    }
  );

  const [
    searchCustomer,
    { loading: searchCustomerLoading, data: searchCustomerData }
  ] = useLazyQuery(SEARCH_CUSTOMER, {
    fetchPolicy: "network-only",
    onCompleted: (searchCustomerData) => {
      if (searchCustomerData && searchCustomerData.searchCustomer) {
        const newCustomers = [...searchCustomerData.searchCustomer];
        setTotalCustomerCount(newCustomers.length);
        setRows(newCustomers);
      }
    }
  });
  const [getAllCustomers, { data: allCustomerData }] = useLazyQuery(
    GET_ALL_CUSTOMERS,
    {
      fetchPolicy: "network-only"
    }
  );

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchCustomer({
        variables: {
          q: searchText
        }
      });
    }
  }, 1000);

  useEffect(() => {
    if (customersTableState) {
      setcustomersTable(customersTableState);
      let sortedOrder = returnSortedOrder(customersTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, customersTableState, sortedOrder);
      }
      getCustomersCount();
      loadCustomers({
        variables: {
          offset: rows.length,
          limit: customersTableState.rowsPerPage || 10
        }
      });
    }
  }, [customersTableState]);

  useEffect(() => {
    if (userState.tenancy) {
      getCustomersCount();
      loadCustomers({
        variables: {
          limit: customersTableState?.rowsPerPage
            ? customersTableState.rowsPerPage
            : limit,
          offset: 0
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    let updatedColumns = [...columns];
    if (
      !(
        userState.currentOrganisation.requiresCustomerVerification ||
        userState.currentTenancy.idvEnabled
      )
    ) {
      updatedColumns = updatedColumns.filter(
        (column: any) => column.name !== "status"
      );
    }
    setTableColumns(updatedColumns);
  }, [userState]);

  useEffect(() => {
    dispatch(resetCustomer());
  }, []);

  useEffect(() => {
    if (allCustomerData) {
      let customers = allCustomerData.customers;
      let filteredCustomers: any = [];
      const dashboardReminder = branch?.dashboardReminder ?? 7;
      const soonDate = d
        .now()
        .endOf("day")
        .plus({ days: dashboardReminder })
        .toUTC()
        .toISO();
      const currentDateTime = d
        .now()
        .toUTC()
        .toISO();
      if (paramValue === "soon") {
        customers.forEach((customer: ICustomer) => {
          if (
            customer &&
            customer.license &&
            customer.license.dateOfExpiry &&
            customer.license.dateOfExpiry > currentDateTime &&
            customer.license.dateOfExpiry < soonDate
          ) {
            filteredCustomers.push(customer);
          }
        });
      } else if (paramValue === "overdue") {
        customers.forEach((customer: ICustomer) => {
          if (
            customer &&
            customer.license &&
            customer.license.dateOfExpiry &&
            customer.license.dateOfExpiry < currentDateTime
          ) {
            filteredCustomers.push(customer);
          }
        });
      }
      setTotalCustomerCount(filteredCustomers.length);
      setRows(filteredCustomers);
      setFilterLoading(false);
    }
  }, [allCustomerData]);

  useEffect(() => {
    if (location && location.search) {
      setFilterLoading(true);
      getAllCustomers();
      const params = new URLSearchParams(location.search);
      const drivingLicense = params.get("drivingLicense");
      if (drivingLicense) {
        setParamValue(drivingLicense);
      }
    } else {
      getCustomersCount();
      loadCustomers({
        variables: {
          offset: rows.length,
          limit: customersTableState?.rowsPerPage
          ? customersTableState.rowsPerPage
          : 10
        }
      });
    }
  }, [location]);

  useEffect(() => {
    if (!uploadModalOpen) {
      setUploadErrors([]);
    }
  }, [uploadModalOpen]);

  const [uploadCustomer] = useMutation(UPLOAD_CUSTOMER, {
    onError: (error: ApolloError) => {
      setFileName("");
      setFileValue(undefined);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      if (
        data.uploadCustomer &&
        data.uploadCustomer.errors &&
        data.uploadCustomer.errors.length
      ) {
        setFileName("");
        setFileValue(undefined);
        setUploadErrors(data.uploadCustomer.errors);
        return setUploadStatus(CsvUploadStatusType.ERROR);
      }
      snackbar({
        message: "Customers uploaded successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setUploadModalOpen(false);
      loadCustomers({
        variables: {
          offset: 0,
          limit
        }
      });
      setUploadStatus(CsvUploadStatusType.DEFAULT);
      setFileName("");
      setFileValue(undefined);
    }
  });

  function onSelectDocument(event: any) {
    const file: File = event.target.files[0];
    setUploadErrors([]);
    setUploadStatus(CsvUploadStatusType.DEFAULT);
    if (file) {
      setFileName(file.name);
      if (possibleCsvMimeTypes.includes(file.type)) {
        setFileValue(file);
      } else {
        return snackbar({
          message: "Invalid File Type. Allowed type: csv",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  function uploadCsv() {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        papa.parse(fileValue, {
          skipEmptyLines: true,
          complete(results: any) {
            const customers = [];
            if (results.data.length) {
              for (let index = 0; index < results.data[0].length; index++) {
                const header = results.data[0][index];
                if (header !== individualCustomerCsvHeaders[index]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid File Header.",
                    variant: SnackBarVariant.ERROR
                  });
                }
              }
              if (results.data.length === 2 && !results.data[1][0]) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: "No data found.",
                  variant: SnackBarVariant.ERROR
                });
              }
              const dataErrors = [];
              for (let index = 1; index < results.data.length; index++) {
                const element: any = results.data[index];
                if (element.length < 14) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid file",
                    variant: SnackBarVariant.ERROR
                  });
                }
                const data: any = {
                  firstName: element[0],
                  lastName: element[1],
                  phoneNumber: element[2],
                  email: element[3].trim(),
                  dateOfBirth: element[4] ? element[4] : "",
                  gender: element[5] ? element[5] : "",
                  license: {
                    licenseNumber: element[6] ? element[6] : "",
                    dateOfExpiry: element[7] ? element[7] : "",
                    authority: element[8] ? element[8] : ""
                  },
                  location: {
                    street: element[9],
                    city: element[10],
                    state: element[11],
                    country: element[12],
                    zipcode: element[13]
                  }
                };
                if (organisation.enableCostCenter) {
                  if (!element[14]) {
                    dataErrors.push({
                      rowIdx: index + 1,
                      message: `Cost Center info missing on row no. ${index + 1}!`
                    })
                  } else {
                    data.costCenter = element[14]
                    data.projectIds = element[15] || ""
                  }
                }
                customers.push(data);
              }
              if (dataErrors.length) {
                for (let j = 0; j < dataErrors.length; j++) {
                  snackbar({
                    message: dataErrors[j].message,
                    variant: SnackBarVariant.ERROR
                  })
                }
                return;
              }
              if (customers.length > 0) {
                uploadCustomer({
                  variables: {
                    customers
                  }
                });
              } else {
                setUploadStatus(CsvUploadStatusType.ERROR);
                snackbar({
                  message: "No data found.",
                  variant: SnackBarVariant.ERROR
                });
              }
            }
          }
        });
      }
    } catch (error) {
      captureErrorException(error)
      setUploadStatus(CsvUploadStatusType.ERROR);
    }
  }

  const options: MUIDataTableOptions = {
    rowsSelected: selection,
    selectableRows: SelectableRows.NONE,
    count: totalCustomerCount,
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch:
          loading || searchCustomerLoading
            ? "Loading..."
            : "Sorry, there is no matching data to display"
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of"
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table"
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET"
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns"
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows"
      }
    },

    onRowClick: (rowData: any, rowMeta: any) => {
      const item: any = rows[rowMeta.dataIndex];
      if (item.id) {
        navigate(`/customer-details?customer=${item.id}&editable=${true}`);
      }
    },
    setRowProps: (row: any[]) => {
      const customerRow: any = rows.find(
        (customer: ICustomer) => customer.email === row[2]
      );
      if (customerRow.status && customerRow.status === "FAILED") {
        return {
          style: { backgroundColor: "#FFFDCA" }
        };
      } else {
        return {};
      }
    },
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText);
      } else {
        setRows([])
        getCustomersCount();
        loadCustomers({
          variables: {
            offset: 0,
            limit: customersTable && customersTable.rowsPerPage
            ? customersTable.rowsPerPage
            : limit,
          }
        });
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows);
      dispatch(
        addTableState({
          tableName: TableNames.CUSTOMERS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      customersTable && customersTable.rowsPerPage
        ? customersTable.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.CUSTOMERS,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: () => {
      if (customersTable && sortOrder) {
        setTableSortOrder(columns, customersTable, sortOrder);
      }
    },
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return;
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length) {
            loadCustomers({
              variables: {
                offset: page * rowsPerPage,
                limit: rowsPerPage
              }
            });
          }
          break;
        case "changeRowsPerPage":
          setRows([]);
          loadCustomers({
            variables: {
              offset: 0,
              limit: tableState.rowsPerPage || limit
            }
          });
          break;
        default:
          break;
      }
    }
  };

  // get styles
  const classes = UseStyles();

  const handleScroll = (e: any) => {
    const threshold = 15000;
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
      threshold
    ) {
      loadCustomers({
        variables: {
          offset: rows.length,
          limit: rows.length
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [data]);

  const disabledRoles = ["FINANCE_MANAGER", "FLEET_MANAGER"];

  return (
    <>
      <Grid container spacing={2}>
        <CssBaseline />
        <Grid container item xs={12} sm={5}>
          <Typography variant="h1" color="primary">
            Customers{"  "}
          </Typography>
        </Grid>
        <Hidden xsDown>
          <Grid container item xs={12} sm={7} justifyContent="flex-end">
            <Fab
              variant="extended"
              size="medium"
              aria-label="Create"
              disabled={disabledRoles.includes(userState.role)}
              onClick={() => {
                navigate("/new-customer");
              }}
            >
              Create
            </Fab>
            <Box color="white" sx={{ pr: 2 }}></Box>
            <Fab
              variant="extended"
              size="medium"
              aria-label="Create Multiple"
              onClick={() => setUploadModalOpen(true)}
              disabled={disabledRoles.includes(userState.role)}
            >
              Create Multiple
            </Fab>
          </Grid>
        </Hidden>
        <Grid container item xs={12}>
          {!(loading && !rows.length) ? (
            <Grid container xs={12}>
              <Hidden smUp>
                {searchCustomerLoading ? (
                  <CircularProgress />
                ) : (
                  <div onScroll={handleScroll}>
                    <CustomerVirtualScroller data={rows} />
                  </div>
                )}
              </Hidden>
              <Hidden smDown>
                {filterLoading ? (
                  <CircularProgress />
                ) : (
                  <NuvvenTable
                    title={""}
                    rows={rows}
                    columns={tableColumns}
                    setSelection={setSelection}
                    options={options}
                  />
                )}
              </Hidden>
            </Grid>
          ) : (
            <CircularProgress />
          )}
          {uploadModalOpen && (
            <BulkCsvUploadDialog
              title={"Create Multiple Customers"}
              subTitle={"Individual Customers"}
              csvTemplateLink={organisation.enableCostCenter ?
                "https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Coastr-Customer-Upload-CC.csv" :
                "https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Coastr-Customer-Upload.csv"
              }
              uploadErrors={uploadErrors}
              uploadStatus={uploadStatus}
              onDocumentSelect={onSelectDocument}
              fileName={fileName}
              uploadCsv={uploadCsv}
              handleClose={() => setUploadModalOpen(false)}
            />
          )}
        </Grid>
      </Grid>
      <Hidden smUp>
        <AppBar position="fixed" className={classes.appBar}>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Button
                className={classes.bottomButton}
                variant="contained"
                aria-label="Create"
                onClick={() => {
                  navigate("/new-customer");
                }}
              >
                Create
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(event: { target: { value: string } }) => {
                    handleSearch(event.target.value);
                    if (!event.target.value) {
                      setRows([]);
                      loadCustomers({
                        variables: {
                          limit: 10,
                          offset: 0
                        }
                      });
                    }
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </AppBar>
      </Hidden>
    </>
  );
};
