export interface IInvoiceForm {
  booking: string;
  customerName: string;
  invoiceRef: string;
  invoiceType: string;
  rentalAmount: number;
  insurance: number;
  endDate: Date;
  deposit: number;
  damageAmount: number;
  finesAmount: number;
  valetAmount: number;
  taxAmount: number;
  discount: number;
  otherRefunds: number;
  discountAmount: number;
  otherChargesDescription?: string;
  otherChargesAmount?: number;
  status: string;
  startDate: Date;
  totalAmount: number;
  amountDue: number;
  basePrice: number;
  insuranceDailyRate: number;
  daysRental: number;
}

export interface IInvoiceItem {
  type: IInvoiceItemType;
  description: string;
  unitPrice: number;
  totalAmount: number;
  quantity: number;
  duration?: number;
  taxRate?: number;
  taxAmount?: number;
  isProrated?: boolean;
  proratedBillingDays?: number;
  taxDescription?: string[];
  rateTypeName?: string;
}

export enum IInvoiceItemType {
  "RENTAL_AMOUNT" = "RENTAL_AMOUNT",
  "DEPOSIT" = "DEPOSIT",
  "INSURANCE" = "INSURANCE",
  "OTHER_CHARGE" = "OTHER_CHARGE",
  "ADDON" = "ADDON",
  "LOCATION_SURCHARGE" = "LOCATION_SURCHARGE"
}

export enum PaymentActions {
  RESEND_LINK = "Resend Link"
}

export enum InvoiceStatus {
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY PAID",
  UNPAID = "UNPAID",
  VOID = "VOID",
  DRAFT = "DRAFT"
}
