import {
  Grid,
  CssBaseline,
  Typography,
  Box,
  AppBar,
  Button,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  alpha,
  Theme
} from '@mui/material'
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import React, { FC, useEffect, useState } from 'react'
import { DVLASearch } from '../../../Fleet/VehicleInventory/AddVehicle/DVLASearch'
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLazyQuery, ApolloError } from '@apollo/client';
import { GET_VEHICLE } from '../../../../../graphql/fleet/getVehicleQuery';
import { IAppState } from '../../../../../store';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { formatGraphQLErrorMessage } from '../../../../common/utils';
import { IAsset } from '../../asset.types';
import { GET_ASSET } from '../../Graphql/assetQueries';
import { AssetTabView } from './AssetTabView';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      marginLeft: "50px",
      marginTop: "100px"
    },
    header: {
      color: "rgba(0, 0, 0, 0.7) !important",
      fontSize: "30px !important",
      marginBottom: "24px !important"
    },
    saveButton: {
      backgroundColor: "#faaf40",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    saveButtonDisabled: {
      backgroundColor: "#d3d3d3",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 100px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    addBottomButton: {
      backgroundColor: "var(--ion-color-success)",
      "&:hover": {
        backgroundColor: "var(--ion-color-success-shade)"
      },
      position: 'relative',
      width: 'calc(50vw - 100px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    addBarButton: {
      backgroundColor: "var(--ion-color-success)",
      "&:hover": {
        backgroundColor: "var(--ion-color-success-shade)"
      },
      position: 'relative',
      width: 'calc(100vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    text: {
      position: 'relative',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    active: {
      fontWeight: "bold",
      fontSize: "initial !important"
    },
    dialogSmallInput: {
      minWidth: 'calc(50vw - 48px)',
      maxWidth: 'calc(50vw - 48px)'
    },
    dialogLargeInput: {
      minWidth: 'calc(100vw - 80px)',
      maxWidth: 'calc(100vw - 80px)'
    }
  })
);

export const ViewAsset: FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);

  const [asset, setAsset] = useState<IAsset>();
  const [isNewAsset, setIsNewAsset] = useState<boolean>(true);


  const [
    getAsset,
    { loading: getAssetLoading, data: assetData }
  ] = useLazyQuery(GET_ASSET, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
    },
    onError: (error: ApolloError) => {
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const assetId = params.get("asset");
      if (assetId) {
        getAsset({
          variables: {
            id: assetId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (assetData && assetData.getAsset) {
      setIsNewAsset(false);
      setAsset(assetData.getAsset);
    }
  }, [assetData]);

  if (getAssetLoading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          Asset Inventory
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>
        {isNewAsset ? (
          <Typography variant="h1" color="primary">
            Add
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Asset
        </Typography>
      </Grid>
      {false ? (
        <Grid container item xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container item xs={12}>
          <AssetTabView
            asset={asset}
          />
        </Grid>
      )}
    </Grid>
  )
}
