import { gql } from "@apollo/client";

export const RESET_EMAIL_TEMPLATE = gql`
  mutation resetToDefaultTemplate(
    $templateType: String!
  ) {
    resetToDefaultTemplate(templateType: $templateType) {
      templateType
    }
  }
`;