import { gql } from "@apollo/client";

export const ISSUE_REFUND = gql`
  mutation issueRefund($creditNoteId: String! $amount: Float!) {
    issueRefund(creditNoteId: $creditNoteId, amount: $amount) {
      id
      creditNoteRef
      creditNoteType
      createdAt
      invoice {
        id
        invoiceRef
      }
      totalAmount
      refundedAmount
      availableAmount
      creditNoteItems {
        type
        description
        originalPrice
        quantity
        unitPrice
        totalAmount
        duration
      }
      refunds {
        mode
        amount
        status
        id
        refundRef
        currency
        paymentIntent
      }
    }
  }
`;