import { gql } from "@apollo/client";

export const GET_EXTERNAL_VEHICLE = gql`
  query getExternalVehicle($id: String!, $bookingId: String!) {
    getExternalVehicle(id: $id, bookingId: $bookingId) {
      licencePlate
      make
      model
      year
      branch
      frogDiagram
      fuelType
      vehicleType
      branchDetails {
        id
        name
      }
      vehicleState {
        exterior {
          front {
            description
            images
          }
          rear {
            description
            images
          }
          top {
            description
            images
          }
          right {
            description
            images
          }
          left {
            description
            images
          }
        }
        interior {
          frontSeat {
            clean
            controls
            seatBelt
          }
          rearSeat {
            clean
            controls
            seatBelt
          }
          lockAndSwitch {
            frontDoor
            rearDoor
            frontWindow
            rearWindow
            childLock
          }
          images
        }
        generalChecks {
          name
          status
          images
        }
        defaultAccesories {
          name
          status
          images
        }
        bookedAccesories {
          name
          type
          quantity
          description
        }
        tyre {
          frontLeft {
            condition
            pressure
            description
          }
          frontRight {
            condition
            pressure
            description
          }
          rearLeft {
            condition
            pressure
            description
          }
          rearRight {
            condition
            pressure
            description
          }
        }
        id
        odometerReading
        fuelStatus
        batteryStatus
        updatedAt
        createdAt
        updatedBy {
          firstName
          lastName
        }
        createdBy {
          firstName
          lastName
        }
      }
      activeVehicleDamages {
        id
        frogDiagram
        referenceNumber
        approvalStatus
        branchId
        damages {
          title
          descriptions
          damageType
          damageKind
          damageArea
          images
          location {
            x
            y
          }
        }
      }
      damages {
        id
        vehicleId
        circumstance
        referenceNumber
        bookingRef
        reportDate
        incidentDate
        reportedBy
        frogDiagram
        approvalStatus
        estimatedCost
        status
        damageSeverity
        estimatedRepairDate
        damages {
          title
          descriptions
          damageType
          damageKind
          damageArea
          images
          location {
            x
            y
          }
        }
      }
    }
  }
`;
