import { gql } from "@apollo/client";

export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($subscription: SubscriptionInput!) {
    createSubscription(subscription: $subscription) {
      id
      name
      insurancePolicy {
        id
      }
      addons {
        id
        name
        displayName
      }
      features
      swapsTnC
      serviceIncluded
      serviceTnC
      subscriptionTnC
      vehicles {
        id
        make
        model
      }
      isActive
    }
  }
`;
