import { Grid, Typography, Fab, CircularProgress, FormControl, MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import FlatPickerBar from "../../../common/FlatPicker";
import { DateTime as d } from "luxon";
import { DOWNLOAD_FUTURE_VEHICLE_AVAILABILITY_REPORT, DOWNLOAD_VEHICLES_DAMAGES_REPORT, DOWNLOAD_VEHICLES_PERFORMANCE_REPORT } from '../../../../graphql/reports/downloadVehiclesDamagesReport';
import { useLazyQuery } from '@apollo/client';
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { ApolloError } from '@apollo/client';
import { DATE_TYPE, MONTH_SELECTOR_TYPE, formatGraphQLErrorMessage } from '../../../common/utils';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { SimpleDateTimePicker } from '../../../common/SimpleDateTimePicker';
import { getLocalizedDateFormat } from '../../../../utils/localized.syntex';

enum VehicleReportEnum {
  VEHICLE_DAMAGES = "VEHICLE_DAMAGES",
  VEHICLE_PERFORMANCE = "VEHICLE_PERFORMANCE",
  FUTURE_VEHICLE_AVAILABILITY_REPORT = "FUTURE_VEHICLE_AVAILABILITY_REPORT"
}

interface IVehicleReportMenu {
  label: string;
  value: string;
}

const vehicleReportMenu: IVehicleReportMenu[] = [{
  label: "Vehicle Damages",
  value: "VEHICLE_DAMAGES"
},
{
  label: "Vehicle Performance",
  value: "VEHICLE_PERFORMANCE"
},
{
  label: "Future Vehicle Availability Report",
  value: "FUTURE_VEHICLE_AVAILABILITY_REPORT"
}
]
interface IDateTime {
  date: string;
  time: number;
}

export const VehiclesDamagesReport: React.FC = () => {
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<string>("");
  const [startDateTimePicker, setStartDateTimePicker] = useState<string>("");
  const [endDateTimePicker, setEndDateTimePicker] = useState<string>("");

  const [downloadVehiclesDamagesReport] = useLazyQuery(DOWNLOAD_VEHICLES_DAMAGES_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.dowloadVehiclesDamagesReport) {
        const fileName = `Vehicles-damages-report-${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
        const file = await getSignedUrl(data.dowloadVehiclesDamagesReport.key);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        setLoading(false);
        snackbar({
          message: "No report is available for this day",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [downloadVehiclesPerformanceReport] = useLazyQuery(DOWNLOAD_VEHICLES_PERFORMANCE_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.dowloadVehiclesPerformanceReport) {
        const fileName = `Vehicles-performance-report-${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
        const file = await getSignedUrl(data.dowloadVehiclesPerformanceReport.key);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        setLoading(false);
        snackbar({
          message: "No report is available for this day",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [downloadFutureVehiclesAvailabilityReport] = useLazyQuery(DOWNLOAD_FUTURE_VEHICLE_AVAILABILITY_REPORT, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.downloadFutureVehiclesAvailabilityReport) {
        const fileName = `Future-Vehicles-Availability-Report-${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.xlsx`;
        const file = await getSignedUrl(data.downloadFutureVehiclesAvailabilityReport.key);
        const url: any = file;
        fetch(url, {
          method: "GET"
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
          });
      } else {
        setLoading(false);
        snackbar({
          message: "No report is available for this day",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const downloadReport = () => {
    setLoading(true)
    if (selectedReport === VehicleReportEnum.FUTURE_VEHICLE_AVAILABILITY_REPORT) {
      const currentDateTime = d.now().toUTC().toISO();
      if (startDateTimePicker < currentDateTime) {
        setLoading(false);
        return snackbar({
          message: "Please select correct date and time",
          variant: SnackBarVariant.ERROR
        });
      }
      if (endDateTimePicker > startDateTimePicker) {
        downloadFutureVehiclesAvailabilityReport({
          variables: {
            startDate: startDateTimePicker,
            endDate: endDateTimePicker,
          }
        })
      } else {
        snackbar({
          message: "Please ensure the end date is after the start date.",
          variant: SnackBarVariant.ERROR
        });
        setLoading(false)
      }
    }
    else if (selectedReport === VehicleReportEnum.VEHICLE_DAMAGES) {
      downloadVehiclesDamagesReport({
        variables: {
          startDate: fromDate,
          endDate: toDate,
        }
      })
    } else {
      downloadVehiclesPerformanceReport({
        variables: {
          startDate: fromDate,
          endDate: toDate,
        }
      })
    }
  }

  const calculateMaxToDate = (fromDate: string) => {
    const todayEnd = d.now();
    const maxToDate = d.fromISO(fromDate).plus({ days: 30 });
    if (maxToDate > todayEnd) {
      return todayEnd.toUTC().toISO();
    }
    return maxToDate.toUTC().toISO();
  }

  const allowedRolesForFutureAvailability = ["ADMIN", "ORGANISATION_OWNER", "BRANCH_MANAGER", "FLEET_MANAGER", "BOOKING_MANAGER"];

  const isFutureAvailabilityVisible = (userRole: any, itemLabel: any) => {
    return allowedRolesForFutureAvailability.includes(userRole) && itemLabel === VehicleReportEnum.FUTURE_VEHICLE_AVAILABILITY_REPORT;
  };

  const filteredVehicleReportMenu = vehicleReportMenu.filter(item => {
    if (userState.role === "FINANCE_MANAGER") {
      return item.value === VehicleReportEnum.VEHICLE_PERFORMANCE;
    }
    return true;
  });

  const menuItems = filteredVehicleReportMenu.map((item, index) => {
    if (isFutureAvailabilityVisible(userState.role, item.label)) {
      return (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      );
    }
    return (
      <MenuItem key={index} value={item.value}>
        {item.label}
      </MenuItem>
    );
  });

  return (
    <Grid container style={{ padding: "10px 20px", borderRadius: 10, border: "1px solid #e2e2e2" }}>
      <Grid item xs={9}>
        <Typography variant="h2">
          Download Vehicle Report
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2} alignItems="center" style={{ marginTop: "1rem" }}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              name={"report"}
              fullWidth
              type="text"
              select
              required
              label="Select Report"
              inputProps={{
                onChange: (event: any) => {
                  setSelectedReport(event.target.value);
                },
                value: selectedReport,
              }}
              InputLabelProps={{
                shrink: true
              }}
            >
              {menuItems}
            </TextField>
          </FormControl>
        </Grid>
        {selectedReport !== VehicleReportEnum.FUTURE_VEHICLE_AVAILABILITY_REPORT && (
          <>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {
                  const timeZoneOffset = d.now().offset;
                  const isPositiveOffset = timeZoneOffset >= 0; 
                  if (isPositiveOffset) {
                    setFromDate(d.fromJSDate(value).toUTC().plus({minutes: timeZoneOffset}).toISO());
                  } else {
                    setFromDate(d.fromJSDate(value).toUTC().minus({minutes: timeZoneOffset}).toISO());
                  }
                  setToDate(
                    calculateMaxToDate(d.fromJSDate(value).endOf("day").toUTC().toISO())
                  );
                }}
                label={"From Date"}
                placeholderValue={"Select From Date"}
                value={fromDate ? getLocalizedDateFormat(country, fromDate, DATE_TYPE.CONDENSED) : ""}
                maxDate={getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}
                country={country}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {
                  setToDate(d.fromJSDate(value).endOf("day").toUTC().toISO());
                }}
                label={"To Date"}
                placeholderValue={"Select To Date"}
                value={toDate ? getLocalizedDateFormat(country, toDate, DATE_TYPE.CONDENSED) : ""}
                maxDate={fromDate ? calculateMaxToDate(fromDate) : "today"}
                minDate={getLocalizedDateFormat(country, fromDate, DATE_TYPE.CONDENSED)}
                monthSelectorType={MONTH_SELECTOR_TYPE.STATIC}
                country={country}
              />
            </Grid>
          </>
        )}
        {selectedReport === VehicleReportEnum.FUTURE_VEHICLE_AVAILABILITY_REPORT && (
          <>
            <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}>
              <SimpleDateTimePicker
                date={startDateTimePicker}
                handleChange={(date: IDateTime) => {
                  if (date && date.date) {
                    setStartDateTimePicker(d.fromISO(date.date).toUTC().toISO());
                  }
                }}
                required={true}
                name={"startDate"}
                dateTitle={"Start Date"}
                timeTitle={"Start Time"}
              />
            </Grid>
            <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}>
              <SimpleDateTimePicker
                date={endDateTimePicker}
                handleChange={(date: IDateTime) => {
                  if (date && date.date) {
                    setEndDateTimePicker(d.fromISO(date.date).toUTC().toISO());
                  }
                }}
                required={true}
                name={"endDate"}
                dateTitle={"End Date"}
                timeTitle={"End Time"}
                minDate={startDateTimePicker}
                maxDate={d
                  .fromISO(startDateTimePicker)
                  .plus({ months: 3, hours: 12 })
                  .toUTC()
                  .toISO()}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={3} lg={3} style={{ display: "flex", alignItems: "center" }}>
          <Fab
            variant={"extended"}
            size="medium"
            disabled={
              loading ||
              !selectedReport ||
              (selectedReport ===
                VehicleReportEnum.FUTURE_VEHICLE_AVAILABILITY_REPORT &&
                (!startDateTimePicker || !endDateTimePicker)) ||
              endDateTimePicker < startDateTimePicker ||
              (selectedReport !==
                VehicleReportEnum.FUTURE_VEHICLE_AVAILABILITY_REPORT &&
                (!fromDate || !toDate))
            }
            onClick={() => {
              downloadReport();
            }}
          >
            {loading && (
              <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />
            )}
            Download
          </Fab>
        </Grid>
      </Grid>
    </Grid>


  )
}
