import "./index.scss";
import { useLazyQuery } from "@apollo/client";
import {
  Theme,
  AppBar,
  alpha,
  Button,
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography,
  InputBase,
  Hidden,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import SearchIcon from '@mui/icons-material/Search';
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAdminUser,
  updateAdminUser
} from "../../../../../actions/AdminUser/actions";
import { addTableState } from "../../../../../actions/tableState/actions";
import { GET_USERS } from "../../../../../graphql/organisation/getUsersQuery";
import { ITable, TableNames } from "../../../../../reducers/tableState/types";
import { IAppState } from "../../../../../store";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from "../../../../common/utils";
import { IUserState } from "../../../../../reducers/user/types";
import { useNavigate } from "react-router-dom";
import AdminUserVirtualScroller from "./AdminUserVirtualScroller";
import { SelectableRows } from "../../../../common/NuvvenTable/types";
import { IAdminUser } from "../../../../../reducers/adminUser/types";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";

interface IUsersProps {
  history: any;
}

export const columns = [
  {
    label: "First Name",
    name: "firstName"
  },
  {
    label: "Last Name",
    name: "lastName"
  },
  {
    label: "Email",
    name: "email"
  },
  {
    label: "Organisation",
    name: "organisation.name"
  },
  {
    label: "Branch",
    name: "branch.name"
  },
  {
    label: "Role",
    name: "role"
  }
];

const UseStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerMargin: {
      marginBottom: "120px"
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(100vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
      marginBottom: theme.spacing(1)
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    }
  })
);

export const AdminUsers = () => {
  const navigate = useNavigate();
  const snackbar = useSnackBar();

  const dispatch = useDispatch();
  const userState = useSelector((state: IAppState) => state.userReducer);

  const usersTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.users
  );

  const [rows, setRows] = useState<IUserState[]>([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [usersTable, setUsersTable] = useState<ITable>();
  const [selection, setSelection] = useState<any[]>([]);

  const [getUsers, { loading: loadingUsers, data: usersData }] = useLazyQuery(GET_USERS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (usersTableState) {
      setUsersTable(usersTableState);
      let sortedOrder = returnSortedOrder(usersTable);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, usersTableState, sortedOrder)
      }
    }
  }, [usersTableState]);

  useEffect(() => {
    getUsers();
    if (userState.currentOrganisation.address.country === "United States") {
      columns[3].label = "Location"
    }
  }, [userState])

  useEffect(() => {
    if (usersData) {
      const formattedUserRowData = usersData.users.map((user: IAdminUser) => {
        return {
          ...user,
          role: user.userRole && typeof user.userRole !== "string" ? user.userRole.name : ""
        }
      })
      setRows(formattedUserRowData);
    }
  }, [usersData]);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    onRowClick: (rowData: any, rowMeta: any) => {
      const item: any = usersData.users[rowMeta.dataIndex];
      if (item) {
        dispatch(updateAdminUser({ ...item }));
        navigate(`/update-user?user=${item.id}`);
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      dispatch(
        addTableState({
          tableName: TableNames.USERS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      usersTable && usersTable.rowsPerPage ? usersTable.rowsPerPage : 10,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.USERS,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: () => {
      if (usersTable && sortOrder) {
        setTableSortOrder(columns, usersTable, sortOrder);
      }
    }
  }

  const classes = UseStyles();

  //store search data for mobile search
  const [search, setSearch] = useState<RegExp>(new RegExp('', "i"));
  const onChange = (event: { target: { value: any; }; }) => {
    setSearch(new RegExp(event.target.value, "i"));
  };

  return (
    <div>
      <Grid container spacing={2}>
        <CssBaseline />
        <Grid container item xs={6} alignItems="center">
          <Typography variant="h1" color="primary">
            {"  "}Users{"  "}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid container item xs={6} justifyContent="flex-end">
            <Fab
              variant="extended"
              size="medium"
              aria-label="Add"
              onClick={() => {
                dispatch(resetAdminUser());
                navigate("/new-user");
              }}
              disabled={userState.role === "FINANCE_MANAGER"}
            >
              Create
            </Fab>
          </Grid>
        </Hidden>
        <Grid container item xs={12}>
          {!loadingUsers ? (
            <Grid container xs={12}>
              <Hidden smUp>
                <AdminUserVirtualScroller data={rows.filter(AdminUser =>
                  (AdminUser.firstName + ' ' + AdminUser.lastName)?.match(search) ||
                  AdminUser.branch?.name.match(search) ||
                  AdminUser.email?.match(search) ||
                  AdminUser.organisation?.name.match(search)
                )} />
              </Hidden>
              <Hidden smDown>
                <NuvvenTable
                  title={""}
                  rows={rows}
                  columns={columns}
                  setSelection={setSelection}
                  options={options}
                />
              </Hidden>
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
      <Hidden smUp>
        <AppBar position="fixed" className={classes.appBar}>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Button
                className={classes.bottomButton}
                variant="contained"
                aria-label="Create"
                onClick={() => {
                  navigate("/new-user");
                }}
              >
                Create
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={onChange}
                />
              </div>
            </Grid>
          </Grid>
        </AppBar>
      </Hidden>
    </div>
  );
};
;