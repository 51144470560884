import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, CircularProgress, Fab, Grid } from '@mui/material';
import { useSnackBar } from '../../../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../../common/SnackbarWrapper/SnackbarWrapper';

interface IProps {
  clientSecret: string;
  bookingId: string;
  invoiceId: string;
}
const CheckoutForm: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const stripe: any = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: props.bookingId ?
          `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.bookingId}&status=success` :
          `${window.location.protocol}//${window.location.host}/update-billing?invoice=${props.invoiceId}&status=success`,
      },
    });

    if (error) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: "Your payment has failed! Please check your card details or try again later.",
      })
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit} className="stripe-data-form">
      <PaymentElement />
      <Grid container xs={12}>
        <Box mt={2}></Box>
      </Grid>
      <Grid container xs={12}>
        <Fab
          type="submit"
          variant="extended"
          size="medium"
          style={{ margin: "0 auto" }}
          disabled={loading}
        >
          {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
          Submit
        </Fab>
      </Grid>
    </form>
  );
};

export default CheckoutForm;