import { SelectableRows } from "./../../../common/NuvvenTable/types";
import { useLazyQuery } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetVehicleService,
} from "../../../../actions/fleet/vehicle/actions";
import { addTableState } from "../../../../actions/tableState/actions";
import { IVehicleService, VehicleServiceStatus, VehicleServiceStatusValue, VehicleServiceType, VehicleServiceTypeValue } from "../../../../reducers/fleet/types";
import { TableNames } from "../../../../reducers/tableState/types";
import { IAppState } from "../../../../store";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { DATE_TYPE, formatGraphQLErrorMessage, toCurrency } from "../../../common/utils";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { ApolloError } from "@apollo/client";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { GET_ASSET_SERVICE_COUNT, GET_ASSET_SERVICES } from "../Graphql/assetServiceQueries";
import { IAssetService } from "../asset.types";

export const AssetServices: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [rows, setRows] = useState<IAssetService[]>([]);
  const [limit, setLimit] = useState(10);
  const { locale, currency } = userState.currentOrganisation;
  // const [assetService, setAssetService] = useState<IAssetService[]>([]);
  const [assetServiceCount, setAssetServiceCount] = useState<number>(0);

  const [loadAssetServices, { loading, data }] = useLazyQuery(GET_ASSET_SERVICES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.getAssetServices) {
        // const oldAssetService = [...rows];
        // const newAssetService = reshapeAssetServicesIntoRows(data.getAssetServices);
        // const assetServiceArray = _.union(oldAssetService, newAssetService);
        // setAssetService(assetServiceArray);newAssetService
        // setRows(assetServiceArray);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [getAssetServiceCount] = useLazyQuery(
    GET_ASSET_SERVICE_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (assetCountData) => { setAssetServiceCount(assetCountData.getAssetCount) }
    }
  );

  useEffect(() => {
    if (userState.tenancy) {
      getAssetServiceCount();
      loadAssetServices({
        variables: {
          limit,
          offset: 0
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    if (data && data.getAssetServices) {
      setRows(data.getAssetServices)
    }
  }, [data])

  const assetServicesTableColumn: any = [
    {
      label: "Reference Number",
      name: "referenceNumber"
    },
    {
      label: "Asset",
      name: "asset.assetTag"
    },
    {
      label: "Service Type",
      name: "serviceType",
      options: {
        customBodyRender: (value: VehicleServiceType) => VehicleServiceTypeValue[value]
      }
    },
    {
      label: "Appointment Date",
      name: "appointmentDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Completion Date",
      name: "completionDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Provider Name",
      name: "serviceProviderName"
    },
    {
      label: "Total Expense",
      name: "totalExpense",
      options: {
        customBodyRender: (value: string) => toCurrency(parseInt(value), currency, locale)
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value: VehicleServiceStatus) => VehicleServiceStatusValue[value]
      }
    }
  ];

  const options: MUIDataTableOptions = {
    count: assetServiceCount,
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: IAssetService = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/update-asset-services?id=${row?.id}`);
      }
    },

    onChangeRowsPerPage: (numberOfRows: number) => {
      dispatch(
        addTableState({
          tableName: TableNames.ASSET_SERVICE,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage: 10,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.ASSET_SERVICE,
          columnName: changedColumn,
          direction
        })
      );
    }
  };

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Asset Services
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          className="createButton"
          onClick={() => {
            dispatch(resetVehicleService());
            navigate("/new-asset-services");
          }}
        >
          Create
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        {!loading ? (
          <NuvvenTable
            title=""
            rows={rows}
            columns={assetServicesTableColumn}
            options={options}
            setSelection={() => { }}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
};
