import {
  Grid,
  Typography,
  CircularProgress,
  Fab,
  Paper,
  IconButton,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Theme,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import styles from './index.module.css';
import { IBranch, TaxValueType } from '../../../../reducers/organisation/types';
import { IAppState } from '../../../../store';
import Autocomplete from '@mui/material/Autocomplete';
import RemoveIcon from '@mui/icons-material/Remove';
import { IBranchConnection, IExternalBranch, IOneWayRentalFee, RENTAL_COST_TYPE } from '../types';
import { BRANCH_SHARING_VEHICLES, GET_VEHICLES, GET_VEHICLE_GROUPS } from '../../../../graphql/vehicleMovement/queries';
import { IVehicle } from '../../../../reducers/fleet/types';
import ClearIcon from '@mui/icons-material/Clear';
import { IVehiclePriceGroup } from '../../../../reducers/vehiclePriceGroups/types';
import { FloatInput } from '../../../common/FloatInput/FloatInput';
import { TextField as FormikTextField } from "formik-mui";
import { VehicleStatus } from '../../Fleet/utils';
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import { GET_VEHICLE_GROUP_FOR_SELECTED_BRANCH } from "../../../../graphql/vehiclePriceGroup/getVehicleGroupsQuery";
import { useLazyQuery } from "@apollo/client";

interface IProps {
  isUpdate?: boolean
  branchConnectionData: IBranchConnection
  handleSubmit: (branchConnection: any) => void
  submitting: boolean;
  branches: Partial<IBranch>[];
  allConnectedBranches: string[];
}

interface IVg {
  id: string,
  name: string
}

const initialVgValues: IVg = {
  id: "",
  name: ""
}

const WhiteCircularProgress = withStyles(() =>
  createStyles({
    root: {
      color: '#fff !important',
      width: 30,
      height: 30
    },
  }),
)(CircularProgress);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper1: {
      minHeight: 200,
      [theme.breakpoints.up('sm')]: {
        marginLeft: "30px",
        paddingBottom: 20,
        padding: 20,
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: '8px'
      },
    },
    paper2: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: "30px",
        paddingBottom: 20,
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: '8px'
      },
      minHeight: "86vh"
    },
    box: {
      [theme.breakpoints.up('sm')]: {
        overflow: "auto"
      },
      [theme.breakpoints.down('xs')]: {
        overflow: "visible"
      },
    }
  }))

const StyledInput = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#f6f6f6',
      fontSize: 14,
      padding: "8px 8px 5px 8px !important",
      border: 0,
      borderRadius: 5,
      width: 180,
    },
    '& fieldset': {
      border: 0
    },
    '& .Mui-error .MuiOutlinedInput-input': {
      color: "red"
    }
  }
})(TextField);

const BranchConnection: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const externalBranchId = props.branchConnectionData.externalBranches[0]?.branchId;
  const [vehicleGroups, setVehicleGroups] = useState<Partial<IVehiclePriceGroup>[]>([]);
  const [query, setQuery] = useState<string>("");
  const [leftVehiclesList, setLeftVehiclesList] = useState<any[]>([]);
  const [rightVehiclesList, setRightVehiclesList] = useState<any[]>([]);
  const [externalBranchVehicleGroups, setExternalBranchVehicleGroups] = useState<any[]>([]);
  const [warningDialog, setWarningDialog] = useState<boolean>(false);
  const [vehicleGroupsNotExist, setVehicleGroupsNotExist] = useState<any[]>([])
  const [selectedOwnVehicleGroup, setSelectedOwnVehicleGroup] = useState<IVg>(initialVgValues);
  const [selectedExtVehicleGroup, setSelectedExtVehicleGroup] = useState<IVg>(initialVgValues);
  const [oneWayRentalFee, setOneWayRentalFee] = useState<IOneWayRentalFee>(props.branchConnectionData.oneWayRentalFee);

  const [loadVehicleGroups,
    { data: vehiclesGroupsData }
  ] = useLazyQuery(
    GET_VEHICLE_GROUPS, {
    fetchPolicy: "network-only"
  });
  const [loadOtherBranchVehicleGroup, { data: externalVgData }] = useLazyQuery(GET_VEHICLE_GROUP_FOR_SELECTED_BRANCH, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (!data.getVehicleGroupForSelectedBranch.length) {
        setSelectedOwnVehicleGroup(initialVgValues)
        setSelectedExtVehicleGroup(initialVgValues)
        setLeftVehiclesList([])
        setExternalBranchVehicleGroups([])
        return snackbar({
          message: "There is no vehicle group in this branch..",
          variant: SnackBarVariant.ERROR
        })
      } else {
        setExternalBranchVehicleGroups(data.getVehicleGroupForSelectedBranch)
      }
    },
  });
  const [searchBranchSharingVehicles,
    { loading: branchSharingVehiclesLoading, data: branchSharingVehiclesData }
  ] = useLazyQuery(
    BRANCH_SHARING_VEHICLES, {
    fetchPolicy: "network-only",
    onCompleted(data) {

    },
  });

  useEffect(() => {
    if (externalBranchId) {
      loadOtherBranchVehicleGroup({
        variables: {
          branchId: externalBranchId
        }
      })

      const sharedFleetData = props.branchConnectionData.sharedFleetData;
      const transformedData: any[] = [];

      sharedFleetData.forEach((group) => {
        const groupId = group.vehicleGroup.id;
        const groupName = group.vehicleGroup.name;

        group.vehicles.forEach((vehicle: IVehicle) => {
          transformedData.push({
            id: vehicle.id,
            licencePlate: vehicle.licencePlate,
            vehicleGroupId: groupId,
            externalVg: group.externalVehicleGroup?.id || "",
            vehicleGroupName: groupName
          });
        });
      });
      setRightVehiclesList(transformedData)
    }
  }, [externalBranchId])

  useEffect(() => {
    loadVehicleGroups();
  }, []);

  useEffect(() => {
    if (vehiclesGroupsData && vehiclesGroupsData.vehicleGroups) {
      const activeVGs = vehiclesGroupsData.vehicleGroups.filter((VG: IVehiclePriceGroup) => VG.isActivated);
      setVehicleGroups(activeVGs);
    }
  }, [vehiclesGroupsData]);

  useEffect(() => {
    if (externalVgData?.getVehicleGroupForSelectedBranch?.length) {
      if (vehiclesGroupsData && vehiclesGroupsData.vehicleGroups) {
        const activeVGs = vehiclesGroupsData.vehicleGroups.filter((VG: IVehiclePriceGroup) => VG.isActivated);
        setVehicleGroups(activeVGs);
      }
    }
  }, [externalVgData?.getVehicleGroupForSelectedBranch])

  useEffect(() => {
    if (query) {
      handleSearch(query)
    }
  }, [query])


  const isBranchSelected = (selection: IExternalBranch[], thisValue?: string, branchId?: string) => {
    let selected: boolean = false;
    selection.forEach((exBr) => {
      if (exBr.branchId === branchId && branchId !== thisValue) {
        selected = true;
      }
    });
    return selected
  }

  const handleChange = _.debounce((searchText: string) => {
    setQuery(searchText);
  }, 500)

  const handleSearch = (searchText: string) => {
    if (searchText.trim().length > 2) {
      searchBranchSharingVehicles({
        variables: {
          q: searchText.trim()
        }
      })
    }
  }

  const handleClose = () => {
    setWarningDialog(false)
  }

  const handleToggle = (item: any, method: string) => {
    const _item = _.cloneDeep(item);
    if (method === "ADD") {
      const isVehicleInRightList = rightVehiclesList.some(
        (vehicle) => vehicle.externalVg === selectedExtVehicleGroup.id && vehicle.id === _item.id
      );
      if (isVehicleInRightList) {
        return snackbar({
          message: "same vehicle of similar group already exists.",
          variant: SnackBarVariant.ERROR
        });
      } else {
        setLeftVehiclesList((prevState: any) => prevState.filter((vehicle: any) => vehicle.id !== _item.id));
        _item.vehicleGroupId = selectedOwnVehicleGroup.id;
        _item.vehicleGroupName = selectedOwnVehicleGroup.name;
        _item.externalVg = selectedExtVehicleGroup.id;
        setRightVehiclesList((prevList) => [...prevList, _item]);
      }
    }

    if (method === "REMOVE") {
      if (selectedExtVehicleGroup.id === _item.externalVg) {
        setLeftVehiclesList((prevState) => [...prevState, _item])
        setRightVehiclesList((state) => state.filter((vehicle: any) => vehicle.id !== _item.id))
      } else {
        setRightVehiclesList((state) => state.filter((vehicle: any) => vehicle.id !== _item.id))
      }
    }
  };



  const handleAllRight = () => {
    const externalVehicleGroup = externalBranchVehicleGroups.find((item) => item.name.toLowerCase().trim().replace(/\s+/g, ' ') === selectedOwnVehicleGroup.name.toLowerCase().trim().replace(/\s+/g, ' '));
    const updatedRightList = leftVehiclesList.map((vehicle: any) => ({
      ...vehicle,
      vehicleGroupId: selectedOwnVehicleGroup.id,
      vehicleGroupName: selectedOwnVehicleGroup.name,
      externalVg: externalVehicleGroup.id
    }));

    setRightVehiclesList((prevList) => [...prevList, ...updatedRightList]);
    setLeftVehiclesList([])
  };

  const handleAllLeft = () => {
    setRightVehiclesList([]);
  };

  const validationSchema = Yup.object().shape({
    externalBranches: Yup.array().of(
      Yup.object().shape({
        branchId: Yup.string().required("Select a branch"),
        rentalCostType: Yup.string().required("Required*"),
        fuelCost: Yup.number().min(0, "Can not be less than 0").required("Required*"),
        driverCost: Yup.number().min(0, "Can not be less than 0").required("Required*"),
        transitTime: Yup.number().min(15, "Minimum 15 minutes").required("Required*"),
      })
    )
  });

  return (
    <Paper style={{ minHeight: 800, borderRadius: 5, boxShadow: '0 0 20px 1px rgba(0,0,0,0)' }}>
      <Formik
        initialValues={{
          ...props.branchConnectionData,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const sharedFleetData = rightVehiclesList.reduce((acc, vehicle) => {
            const groupIndex = acc.findIndex((group: any) => group.vehicleGroup === vehicle.vehicleGroupId);
            if (groupIndex !== -1) {
              acc[groupIndex].vehicles.push(vehicle.id);
            } else {
              acc.push({
                vehicleGroup: vehicle.vehicleGroupId,
                externalVehicleGroup: vehicle.externalVg || "",
                vehicles: [vehicle.id],
              });
            }
            return acc;
          }, []);
          if (oneWayRentalFee && oneWayRentalFee.taxable && !oneWayRentalFee.tax.value) {
            return snackbar({
              message: "Tax value should be more than 0",
              variant: SnackBarVariant.ERROR
            });
          }
          const input: any = {
            ...values,
            oneWayRentalFee: {
              ...oneWayRentalFee
            },
            sharedFleetData
          }
          props.handleSubmit(input);

        }}
      >
        {formikProps => (
          <Form>
            <div className={styles.settingModule}>
              <Grid style={{ width: 300, marginRight: 80 }}>
                <Typography variant="subtitle1" style={{ marginBottom: 15 }} >Connected Branch</Typography>
                <Typography variant="subtitle2" style={{ color: '#5a5a5a' }}>Add branch you want to share your vehicles for cross hire.</Typography>
              </Grid>
              <Grid container xs>
                <FieldArray name="externalBranches">
                  {({ move, swap, push, insert, remove, pop, form }) => (
                    <>
                      <Grid item xs={12}>
                        {formikProps.values.externalBranches.map((branch, index) => (
                          <>
                            <div className={styles.shadedWrap}>
                              {formikProps.values.externalBranches.length > 1 && <div className={styles.cross}>
                                <IconButton size="small" onClick={() => remove(index)}>
                                  <ClearIcon />
                                </IconButton>
                              </div>}
                              <Grid container item spacing={2} xs>
                                <Grid item xs={12} lg={6}>
                                  <Field
                                    name={`externalBranches[${index}].branchId`}
                                  >
                                    {({ field, meta: { touched, error }, }: FieldProps) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="simple-rate-type-select">Branch *</InputLabel>
                                        <Select
                                          {...field}
                                          onChange={(event) => {
                                            setSelectedOwnVehicleGroup(initialVgValues)
                                            setSelectedExtVehicleGroup(initialVgValues)
                                            setLeftVehiclesList([])
                                            setRightVehiclesList([])
                                            if (props.allConnectedBranches.includes(event.target.value as string)) {
                                              snackbar({
                                                message: "Branch connection already exist",
                                                variant: SnackBarVariant.ERROR
                                              });
                                            }
                                            else {
                                              field.onChange(event);
                                              loadOtherBranchVehicleGroup({
                                                variables: {
                                                  branchId: event.target.value
                                                }
                                              })
                                            }
                                          }}
                                          label={"Rate Type"}
                                          disabled={!!props?.branchConnectionData?.id}
                                          required
                                          error={touched && Boolean(error)}
                                        >
                                          {props.branches.map((branch) => (
                                            (branch.id !== userState.currentBranch.id && !isBranchSelected(formikProps.values.externalBranches, field.value, branch.id)) && <MenuItem key={branch.id} value={branch.id}>
                                              {branch.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        {touched && Boolean(error) && <FormHelperText error>{error}</FormHelperText>}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Field
                                    component={FormikTextField}
                                    label={"Transit time (In minutes)*"}
                                    name={`externalBranches[${index}].transitTime`}
                                    fullWidth
                                    type="number"
                                  />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Field
                                    component={FormikTextField}
                                    label={"Fuel cost *"}
                                    name={`externalBranches[${index}].fuelCost`}
                                    fullWidth
                                    InputProps={{
                                      inputComponent: FloatInput as any
                                    }}
                                    inputProps={{
                                      hasCurrencyPrefix: true,
                                      allowNegative: false
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Field
                                    component={FormikTextField}
                                    label={"Driver cost *"}
                                    name={`externalBranches[${index}].driverCost`}
                                    fullWidth
                                    InputProps={{
                                      inputComponent: FloatInput as any
                                    }}
                                    inputProps={{
                                      hasCurrencyPrefix: true,
                                      allowNegative: false
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </>
                        ))}
                      </Grid>
                      {/* <Grid item xs={12}>
                        <Fab
                          variant="extended"
                          size="small"
                          type="button"
                          onClick={() => {
                            push({
                              branchId: "",
                              transitTime: 1,
                              rentalCostType: RENTAL_COST_TYPE.ZERO_COST,
                              fuelCost: 0,
                              driverCost: 0,
                            })
                          }}
                        >
                          Add+
                        </Fab>
                      </Grid> */}
                    </>
                  )}
                </FieldArray>
                {userState.currentOrganisation.crossLocationBookingEnabled && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="h4">
                        One Way Fee Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Field
                        component={FormikTextField}
                        label={"One way fee"}
                        name={`amount`}
                        fullWidth
                        InputProps={{
                          inputComponent: FloatInput as any,
                          value: oneWayRentalFee && oneWayRentalFee.amount ? oneWayRentalFee.amount : 0,
                          onChange: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const cost = parseFloat(e.target.value) ? parseFloat(e.target.value) : 0;
                            if (cost > -1) {
                              setOneWayRentalFee({
                                ...oneWayRentalFee,
                                amount: cost
                              })
                            }
                          }
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={oneWayRentalFee && oneWayRentalFee.taxable ? true : false}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setOneWayRentalFee({
                                ...oneWayRentalFee,
                                taxable: e.target.checked
                              })
                            }}
                            name={`taxable`}
                            color="primary"
                          />
                        }
                        label={userState.currentOrganisation.address.country === "United States" ? "Add Taxes & Fees" : "Add Tax"}
                      />
                    </Grid>
                    {oneWayRentalFee && oneWayRentalFee.taxable && (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Field
                            component={TextField}
                            label="Tax Title"
                            name={"title"}
                            fullWidth
                            InputProps={{
                              value:
                                oneWayRentalFee.tax && oneWayRentalFee.tax.title
                                  ? oneWayRentalFee.tax.title
                                  : "",
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setOneWayRentalFee({
                                  ...oneWayRentalFee,
                                  tax: {
                                    ...oneWayRentalFee.tax,
                                    title: e.target.value
                                  }
                                });
                              }
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            required
                          ></Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Field
                            component={TextField}
                            label="Tax Value(%)"
                            fullWidth
                            name={"value"}
                            type="number"
                            InputProps={{
                              value:
                                oneWayRentalFee.tax && oneWayRentalFee.tax.value
                                  ? oneWayRentalFee.tax.value
                                  : 0,
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = parseFloat(e.target.value) ? parseFloat(e.target.value) : 0;
                                if (value > 100) {
                                  snackbar({
                                    message:
                                      "Tax Value can't be more than 100%",
                                    variant: SnackBarVariant.ERROR
                                  });
                                }
                                else if (value < 0) {
                                  snackbar({
                                    message:
                                      "Tax value can't be less than 0%",
                                    variant: SnackBarVariant.ERROR
                                  });
                                }
                                else {
                                  setOneWayRentalFee({
                                    ...oneWayRentalFee,
                                    tax: {
                                      ...oneWayRentalFee.tax,
                                      value: value
                                    }
                                  });
                                }
                              }
                            }}
                          ></Field>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </div>
            <div className={styles.settingModule}>
              <Grid container xs>
                <Grid item xs={12} sm={6}>
                  <Box
                    style={{ maxHeight: "100vh" }}
                    className={classes.box}
                  >
                    <Paper
                      variant="outlined"
                      className={classes.paper1}
                      square
                    >
                      <div className={styles.borderedWrap} style={{ minHeight: "60vh", border: "2px dashed lightgray" }}>
                        <Grid container item spacing={2} xs >
                          <Grid item xs={6}>
                            <Autocomplete
                              freeSolo
                              fullWidth
                              disabled={!externalBranchVehicleGroups.length}
                              noOptionsText="No vehicle Groups"
                              id="VEHICLE_GROUP_SEARCH"
                              options={vehicleGroups}
                              getOptionLabel={(option: any) =>
                                option.name
                              }
                              onChange={(event: any, newValue: any, reason: string) => {
                                if (reason === "select-option" && newValue && externalBranchVehicleGroups.length) {
                                  setSelectedOwnVehicleGroup({
                                    id: newValue.id,
                                    name: newValue.name
                                  })
                                  const selectedVGs = vehicleGroups.filter((val) => val.id === newValue.id)
                                  const isVehicleGroupExist = externalBranchVehicleGroups.some(group => {
                                    if (group.name.toLowerCase().trim().replace(/\s+/g, ' ') === newValue.name.toLowerCase().trim().replace(/\s+/g, ' ')) {
                                      const extVgObj = externalBranchVehicleGroups.find((item) => item.name.toLowerCase().trim().replace(/\s+/g, ' ') === newValue.name.toLowerCase().trim().replace(/\s+/g, ' '))
                                      setSelectedExtVehicleGroup({
                                        id: extVgObj.id,
                                        name: extVgObj.name
                                      })
                                      return true;
                                    }
                                    return false;
                                  });
                                  if (!isVehicleGroupExist && selectedVGs) {
                                    setVehicleGroupsNotExist(selectedVGs)
                                    setWarningDialog(true)
                                    setLeftVehiclesList([])
                                    setSelectedOwnVehicleGroup(initialVgValues)
                                    setSelectedExtVehicleGroup(initialVgValues)
                                    return snackbar({
                                      message: "Vehicle group is not present in the selected branch.",
                                      variant: SnackBarVariant.ERROR
                                    })
                                  } else {
                                    const selectedVgObj: any = vehicleGroups.find((val) => val.id === newValue.id)
                                    if (selectedVgObj) {
                                      if (selectedVgObj.vehicles.length) {
                                        const vehicles = selectedVgObj?.vehicles.filter((val: IVehicle) =>
                                          !val.isGhostVehicle && !rightVehiclesList.some((item: any) => item.id === val.id)
                                        )
                                        if (vehicles.length) {
                                          if (externalBranchId) {
                                            const existingVgObj = props?.branchConnectionData?.sharedFleetData.find((vg) => vg.externalVehicleGroup.id === selectedExtVehicleGroup.id)
                                            const filteredArr = vehicles.filter((val: any) => {
                                              return !existingVgObj?.vehicles.some((vehicle: any) => vehicle.id === val.id)
                                            })
                                            setLeftVehiclesList(filteredArr);
                                          } else {
                                            setLeftVehiclesList(vehicles);
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                                if (reason === "clear") {
                                  setLeftVehiclesList([])
                                  setSelectedOwnVehicleGroup(initialVgValues)
                                  setSelectedExtVehicleGroup(initialVgValues)
                                }
                              }}
                              inputValue={selectedOwnVehicleGroup.name}
                              onInputChange={(event, value, reason) => {
                                if (reason === "clear") {
                                  setLeftVehiclesList([])
                                  setSelectedOwnVehicleGroup(initialVgValues)
                                  setSelectedExtVehicleGroup(initialVgValues)
                                }
                                if (reason === "reset" && value && externalBranchVehicleGroups.length) {
                                  const selectedVGs = vehicleGroups.filter((val: any) => val.name.toLowerCase().trim().replace(/\s+/g, ' ') === value.toLowerCase().trim().replace(/\s+/g, ' '))
                                  const ownVgObj: any = vehicleGroups.find((vg: any) => vg.name.toLowerCase().trim().replace(/\s+/g, ' ') === value.toLowerCase().trim().replace(/\s+/g, ' '))
                                  setSelectedOwnVehicleGroup({
                                    id: ownVgObj?.id,
                                    name: ownVgObj?.name
                                  })
                                  const isVgExist = externalBranchVehicleGroups.some((extVg) => {
                                    if (extVg.name.toLowerCase().trim().replace(/\s+/g, ' ') === value.toLowerCase().trim().replace(/\s+/g, ' ')) {
                                      const extVgobj: any = externalBranchVehicleGroups.find((item: any) => item.name.toLowerCase().trim().replace(/\s+/g, ' ') === value.toLowerCase().trim().replace(/\s+/g, ' '))
                                      if (extVgobj) {
                                        setSelectedExtVehicleGroup({
                                          id: extVgobj.id,
                                          name: extVgobj.name
                                        })
                                      }
                                      return true;
                                    }
                                    return false;
                                  })
                                  if (!isVgExist && selectedVGs) {
                                    setVehicleGroupsNotExist(selectedVGs)
                                    setWarningDialog(true)
                                    setLeftVehiclesList([])
                                    setSelectedOwnVehicleGroup(initialVgValues)
                                    setSelectedExtVehicleGroup(initialVgValues)
                                    return snackbar({
                                      message: "Vehicle group is not present in the selected branch.",
                                      variant: SnackBarVariant.ERROR
                                    })
                                  } else {
                                    if (ownVgObj) {
                                      if (ownVgObj.vehicles.length) {
                                        const vehicles = ownVgObj?.vehicles.filter((val: IVehicle) =>
                                          !val.isGhostVehicle && !rightVehiclesList.some((item: any) => item.id === val.id)
                                        )
                                        if (vehicles.length) {
                                          if (externalBranchId) {
                                            const existingVgObj = props?.branchConnectionData?.sharedFleetData.find((vg) => vg.externalVehicleGroup.id === selectedExtVehicleGroup.id)
                                            const filteredArr = vehicles.filter((val: any) => {
                                              return !existingVgObj?.vehicles.some((vehicle: any) => vehicle.id === val.id)
                                            })
                                            setLeftVehiclesList(filteredArr);
                                          } else {
                                            setLeftVehiclesList(vehicles);
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }}
                              renderInput={(params: any) => {
                                return (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Search vehicle groups"}
                                    value={selectedOwnVehicleGroup.name}
                                    onChange={(e) => {
                                      setSelectedOwnVehicleGroup({ id: "", name: e.target.value })
                                    }}
                                  />
                                )
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid></Grid>
                            <Fab
                              variant="extended"
                              size="small"
                              type="button"
                              disabled={!leftVehiclesList?.length}
                              onClick={() => {
                                handleAllRight()
                              }}
                            >
                              Add All
                            </Fab>
                            <div>
                              {leftVehiclesList?.length ? leftVehiclesList.map((vehicle: any) => {
                                return (
                                  <div key={vehicle.id} style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
                                    <IconButton
                                      onClick={() => handleToggle(vehicle, "ADD")}
                                      style={{ color: "white" }}
                                    >
                                      <AddIcon style={{
                                        marginLeft: 20,
                                        fontSize: 18,
                                        backgroundColor: "var(--theme-primary)",
                                        cursor: "pointer",
                                        borderRadius: 2
                                      }}
                                      />
                                    </IconButton>
                                    <span className="margin-left padding-left flex fill">
                                      <span>{vehicle.licencePlate}</span>
                                    </span>
                                  </div>
                                )
                              }) : ""}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    className={classes.box}
                    style={{
                      maxHeight: "100vh",
                    }}
                  >
                    <Paper
                      variant="outlined"
                      square
                      className={classes.paper1}
                    >
                      <div className={styles.borderedWrap} style={{ minHeight: "60vh", border: "2px dashed lightgray" }}>
                        <Fab
                          variant="extended"
                          size="small"
                          type="button"
                          disabled={!rightVehiclesList.length}
                          onClick={() => {
                            handleAllLeft()
                          }}
                        >
                          Remove All
                        </Fab>
                        <div style={{ backgroundColor: "#f6f6f6" }}>
                          {rightVehiclesList.map((vehicle) => {
                            return (<div key={vehicle.vehicleId} style={{ display: 'flex', alignItems: 'center' }}>
                              <IconButton
                                onClick={() => handleToggle(vehicle, "REMOVE")}
                                style={{ color: "white" }}
                              >
                                <RemoveIcon
                                  style={{
                                    marginTop: 5,
                                    marginLeft: 10,
                                    backgroundColor: "var(--theme-primary)",
                                    cursor: "pointer",
                                    fontSize: 16,
                                    borderRadius: 2
                                  }}
                                />
                              </IconButton>
                              <span className="margin-left padding-left flex fill">
                                <span>{`${vehicle.licencePlate} - `}</span>
                                <span>
                                  {vehicle.vehicleGroupName}
                                </span>
                              </span>
                            </div>
                            )
                          })}
                        </div>
                      </div>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <Dialog
              open={warningDialog}
              onClose={handleClose}
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {vehicleGroupsNotExist.length && (
                    <>
                      <div>
                        {vehicleGroupsNotExist.map((object, index) => (
                          <span key={index} style={{ color: "black" }}>
                            {index > 0 && ', '}
                            {object.name}
                          </span>
                        ))}
                      </div>
                      <div>
                        Vehicle group is not present in the selected branch.
                      </div>
                      <div>
                        Please contact the selected branch to create the missing vehicle group and then come back to share the vehicles between branches.
                      </div>
                    </>
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>

            <div style={{ padding: 40, textAlign: 'right' }}>
              <Fab
                variant="extended"
                size="medium"
                type="submit"
              >
                {props.submitting ? <WhiteCircularProgress size="small" thickness={5} /> : <strong>Save</strong>}
              </Fab>
            </div>
          </Form>
        )}
      </Formik>
    </Paper >
  );
}

export default BranchConnection;