import { MutationHookOptions, QueryHookOptions } from "@apollo/client";
import { DateTime as d } from "luxon";
import { useEffect, useState } from "react";

import ADDITIONAL_DRIVER from "./../../assets/icons/additional_driver.svg";
import BABY_SEAT from "./../../assets/icons/baby-seat-addon.svg";
import INSURANCE from "./../../assets/icons/car-insurance.svg";
import CONVERTIBLE_ICON from "./../../assets/icons/convertable.svg";
import DOOR_ICON from "./../../assets/icons/door.svg";
import SUV_ICON from "./../../assets/icons/fourByFour.svg";
import PUMP_ICON from "./../../assets/icons/gasoline-pump.svg";
import GEAR_ICON from "./../../assets/icons/gearshift.svg";
import GENERAL_ADDON from "./../../assets/icons/general-add-ons.svg";
import GPS from "./../../assets/icons/gps-addon.svg";
import HATCHBACK_ICON from "./../../assets/icons/hatchback.svg";
import MPV_ICON from "./../../assets/icons/mpv.svg";
import SEAT_ICON from "./../../assets/icons/safety-seat.svg";
import SALOON_ICON from "./../../assets/icons/saloon.svg";
import SCOOTER_ICON from "./../../assets/icons/scooter.svg";
import SPORTS_ICON from "./../../assets/icons/sports.svg";
import TOURER_ICON from "./../../assets/icons/tourer.svg";
import VALET from "./../../assets/icons/valet-addon.svg";
import {
  BILLING_CYCLE_NAME,
  IBooking,
  ITax,
  IVehicleGroup
} from "./../../reducers/bookings/types";
import { BillingCycle } from "./../../reducers/user/types";
import { DefaultAddons } from "../../reducers/addonType/types";
import { ITable } from "../../reducers/tableState/types";
import { getSignedUrl } from "../../utils/getSignedUrl";
import { captureErrorException } from "../../utils/sentry";
import { CURRENCY_SYMBOLS } from "../views/Settings/GeneralSettings/OrganisationStructure/languageCurrencyConstant";
import { IFilter, IFilterOption } from "./FilterSection/FilterSection";

interface ITableColumns {
  label: string;
  name: string;
  options?: object;
}

const CONVERTIBLE = "convertible";
const COUPE = "coupe";
const ESTATE = "estate";
const HATCHBACK_5_DOOR = "5 door hatchback";
const HATCHBACK = "hatchback";
const MPV = "mpv";
const SALOON = "saloon";
const SUV = "suv";
const SCOOTER = "scooter";
const UNDEFINED = "undefined";

const vehicleCategories: { [index: string]: string } = {
  [CONVERTIBLE]: CONVERTIBLE_ICON,
  [COUPE]: SPORTS_ICON,
  [ESTATE]: TOURER_ICON,
  [HATCHBACK]: HATCHBACK_ICON,
  [HATCHBACK_5_DOOR]: HATCHBACK_ICON,
  [MPV]: MPV_ICON,
  [SALOON]: SALOON_ICON,
  [SUV]: SUV_ICON,
  [SCOOTER]: SCOOTER_ICON,
  [UNDEFINED]: MPV_ICON
};

const featureCategories: { [index: string]: string } = {
  fuelType: PUMP_ICON,
  numberOfDoors: DOOR_ICON,
  numberOfSeats: SEAT_ICON,
  transmission: GEAR_ICON
};

export function getVehicleTypeIcon(category: string) {
  const myCategory = category.toLowerCase();
  return vehicleCategories[myCategory]
    ? vehicleCategories[myCategory]
    : vehicleCategories[UNDEFINED];
}

export function getFeatureTypeIcon(feature: string) {
  return featureCategories[feature];
}

export function getAddonIcon(category: string) {
  switch (category) {
    case "insurance":
      return INSURANCE;
    case DefaultAddons.VALET:
      return VALET;
    case DefaultAddons.BABY_SEAT:
      return BABY_SEAT;
    case DefaultAddons.GPS:
      return GPS;
    case DefaultAddons.ADDITIONAL_DRIVER:
      return ADDITIONAL_DRIVER;
    case DefaultAddons.OTHER:
      return GENERAL_ADDON;
    default:
      return GENERAL_ADDON;
  }
}

export const isLongTermBooking = (bookingData: IBooking) => {
  const longTermBooking =
    bookingData.hasOwnProperty("isRecurringBilling") &&
    typeof bookingData.isRecurringBilling === "boolean"
      ? bookingData.isRecurringBilling
      : [BILLING_CYCLE_NAME.WEEKLY, BILLING_CYCLE_NAME.MONTHLY].includes(
          bookingData.rateTypeName
        );
  return longTermBooking;
};

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export type OptionsHeaders = MutationHookOptions | QueryHookOptions | undefined;

export function toCurrency(value: number, currency: string, locale: string) {
  const currencySymbol = CURRENCY_SYMBOLS[currency]
    ? CURRENCY_SYMBOLS[currency]
    : "";
  return `${currencySymbol}${numberWithCommas(
    (value / 100).toFixed(currency == "JPY" ? 0 : 2)
  )}`;
}

export function numberWithCommas(x: string): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatLicenceNumber(licence: string) {
  if (licence.length === 7) {
    return licence.toUpperCase().replace(/\s\s+/g, "").trim();
  }
  return licence.toUpperCase();
}

export function generateFiltersFromVehicles(vehicles: IVehicleGroup[]) {
  const filters: { [key: string]: IFilter } = {
    bodyType: { name: "Car Type", id: "bodyType", options: [] },
    colour: { name: "Colour", id: "colour", options: [] },
    fuelType: { name: "Fuel Type", id: "fuelType", options: [] },
    make: { name: "Make", id: "make", options: [] },
    model: { name: "Model", id: "model", options: [] },
    transmission: { name: "Transmission", id: "transmission", options: [] },
    year: { name: "Year", id: "year", options: [] }
  };
  vehicles.forEach((vehicle: IVehicleGroup) => {
    Object.keys(vehicle).forEach((key: string) => {
      populateFilters(filters, vehicle, key);
    });
  });

  return Object.values(filters);
}

function populateFilters(filters: any, object: any, key: string) {
  if (filters[key]) {
    const exists =
      filters[key].options.filter(
        (option: IFilterOption) =>
          option.id ===
          (typeof object[key] === "string"
            ? object[key].toLowerCase()
            : object[key].toString().toLowerCase())
      ).length === 0
        ? false
        : true;

    if (!exists) {
      filters[key].options.push({
        id:
          typeof object[key] === "string"
            ? object[key].toLowerCase()
            : object[key].toString().toLowerCase(),
        name: object[key]
      });
    }
  }
}

export function formatGraphQLErrorMessage(message: string) {
  const title = "GraphQL error: ";
  return message.replace(title, "");
}

export function getFileExtension(mimeType: string) {
  switch (mimeType) {
    case "application/pdf":
      return "pdf";
    case "image/jpeg":
      return "jpg";
    case "image/png":
      return "png";
    default:
      return "pdf";
  }
}

export function getCycleByValue(value: number) {
  return Object.keys(BillingCycle).filter(
    (key: string) => BillingCycle[key] === value
  )[0];
}

export function capitalize(value: string) {
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
}

export const checkUploadFileFormatAppSettings = (fileType: string) => {
  if (
    fileType === "application/pdf" ||
    fileType === "application/msword" ||
    fileType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    fileType === "application/zip" ||
    fileType === "image/png" ||
    fileType === "image/jpg" ||
    fileType === "image/jpeg" ||
    fileType === "application/x-zip-compressed"
  ) {
    return true;
  }
};

export const checkUploadFileFormat = (fileType: string) => {
  if (
    fileType === "application/pdf" ||
    fileType === "image/png" ||
    fileType === "image/jpg" ||
    fileType === "image/jpeg"
  ) {
    return true;
  }
};

export const isImageFileType = (fileType: string) => {
  return (
    fileType === "image/png" ||
    fileType === "image/jpg" ||
    fileType === "image/jpeg"
  );
};

export const uploadFileExtensionAndContentType = (fileType: string) => {
  let fileExtension: string = "";
  let contentType: string = "";
  if (fileType === "application/pdf") {
    fileExtension = "pdf";
    contentType = "application/pdf";
  } else if (fileType === "application/msword") {
    fileExtension = "doc";
    contentType = "applicaiton/msword";
  } else if (
    fileType ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    fileExtension = "docx";
    contentType =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  } else if (fileType === "image/png") {
    fileExtension = "png";
    contentType = "image/png";
  } else if (fileType === "image/jpg") {
    fileExtension = "jpg";
    contentType = "image/jpg";
  } else if (fileType === "image/jpeg") {
    fileExtension = "jpeg";
    contentType = "image/jpeg";
  }
  return { fileExtension, contentType };
};

export const checkDeleteOrDownloadFileType = (documentUrl: string) => {
  const fileExtension = documentUrl.split(".")[1];
  let contentType: string = "";
  if (fileExtension === "doc") {
    contentType = "application/msword";
  } else if (fileExtension === "docx") {
    contentType =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  } else if (fileExtension === "pdf") {
    contentType = "application/pdf";
  } else if (fileExtension === "application/zip") {
    contentType = "application/zip";
  } else if (fileExtension === "application/x-zip-compressed") {
    contentType = "application/x-zip-compressed";
  } else if (fileExtension === "png") {
    contentType = "image/png";
  } else if (fileExtension === "jpg") {
    contentType = "image/jpg";
  } else if (fileExtension === "jpeg") {
    contentType = "image/jpeg";
  }
  return { fileExtension, contentType };
};

export const returnSortedOrder = (tableData: any) => {
  if (tableData && tableData.direction === "descending") {
    return "desc";
  } else if (tableData && tableData.direction === "ascending") {
    return "asc";
  }
};

export const getImage = async (key: string) => {
  try {
    let contentType;
    const fileExtension = key.split(".")[1];
    if (
      fileExtension === "jpeg" ||
      fileExtension === "png" ||
      fileExtension === "jpg"
    ) {
      contentType = "imgage/*";
    } else {
      contentType = "application/pdf";
    }
    const file = await getSignedUrl(key);
    return file;
  } catch (error) {
    captureErrorException(error);
  }
};

export const returnfileContentTypeAndExtention = (file: File) => {
  let fileExtension: string = "";
  let contentType: string = "";
  if (file.type === "application/pdf") {
    fileExtension = "pdf";
    contentType = "application/pdf";
  } else if (file.type === "image/jpeg") {
    fileExtension = "jpeg";
    contentType = "image/jpeg";
  } else if (file.type === "image/jpg") {
    fileExtension = "jpg";
    contentType = "image/jpg";
  } else if (file.type === "image/png") {
    fileExtension = "png";
    contentType = "image/png";
  } else if (file.type === "image/bmp") {
    fileExtension = "bmp";
    contentType = "image/bmp";
  }
  return { fileExtension, contentType };
};

export const setTableSortOrder = (
  tableColumns: ITableColumns[],
  tableData: ITable,
  sortOrder: string
) => {
  for (let index = 0; index < tableColumns.length; index++) {
    const element: any = tableColumns[index];
    if (element && element.hasOwnProperty("options")) {
      element.options.sortDirection = "none";
      if (tableData && tableData.columnName === element.name) {
        element.options.sortDirection = sortOrder;
      }
    } else {
      element.options = { sortDirection: "none" };
      if (tableData && tableData.columnName === element.name) {
        element.options = { sortDirection: sortOrder };
      }
    }
  }
};

export function downloadBlob(blob: any, filename: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
}

export const possibleCsvMimeTypes = [
  "text/plain",
  "text/x-csv",
  "application/vnd.ms-excel",
  "application/csv",
  "application/x-csv",
  "text/csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values",
  ""
];

export const INVOICE = "INVOICE";
export const BOOKING = "BOOKING";

export const converToAplphabet = (value: string) => {
  return value.replace(/[^A-Za-z]/gi, "");
};

export enum DateTimePickerType {
  BOOKING = "BOOKING",
  PRICE_RULE = "PRICE_RULE",
  SERVICE = "SERVICE",
  SCHEDULER_BOOKING = "SCHEDULER_BOOKING",
  ON_HOLD = "ON_HOLD"
}

export interface IPickupDropOffDateTime {
  pickupDate: string;
  pickupTime: number;
  dropOffDate: string;
  dropOffTime: number;
}

export const dateTime: IPickupDropOffDateTime = {
  pickupDate: "",
  pickupTime: 0,
  dropOffDate: "",
  dropOffTime: 0
};

export enum PaymentGateway {
  STRIPE = "STRIPE",
  CONVERGE = "CONVERGE",
  OFFLINE = "OFFLINE"
}

export const isValidName = (value: string) => {
  if (!/[^A-z\s]/g.test(value)) {
    return false;
  }
  return true;
};

export const getRateTypeString = (rateType: string) => {
  if (rateType) {
    switch (rateType) {
      case "hourly":
        return "Hour (s)";
      case "daily":
        return "Day (s)";
      case "weekly":
        return "Week (s)";
      case "monthly":
        return "Month (s)";
      default:
        return "Day (s)";
    }
  }
};

export const returnSortedDocuments = (documents: any) => {
  if (documents && documents.length) {
    return [...documents].sort((a: any, b: any) => {
      let dateOne = d.fromISO(a.createdAt),
        dateTwo = d.fromISO(b.createdAt);
      // Compare the 2 dates
      if (dateOne < dateTwo) return 1;
      if (dateOne > dateTwo) return -1;
      return 0;
    });
  }
};

export const getUploadedImageByKey = async (key: string) => {
  try {
    let contentType = "";
    const fileExtension = key.split(".")[1];
    if (
      fileExtension === "jpeg" ||
      fileExtension === "png" ||
      fileExtension === "jpg"
    ) {
      contentType = "imgage/*";
    } else {
      contentType = "application/pdf";
    }
    const file = await getSignedUrl(key);
    return file;
  } catch (error) {
    captureErrorException(error);
  }
};

export enum DocumentType {
  PCO_DOCUMENT = "PCO_DOCUMENT",
  POLICY_DOCUMENT = "POLICY_DOCUMENT",
  INSPECTION_REPORT_DOCUMENT = "INSPECTION_REPORT_DOCUMENT",
  INDIVIDUAL_INSURANCE_DOCUMENT = "INDIVIDUAL_INSURANCE_DOCUMENT",
  SERVICE_RECEIPT = "SERVICE_RECEIPT",
  OTHER = "OTHER",
  VEHICLE_INSURANCE = "VEHICLE_INSURANCE",
  VEHICLE_LOGBOOK = "VEHICLE_LOGBOOK",
  MOT_CERTIFICATE = "MOT_CERTIFICATE",
  DRIVER_INSURANCE = "DRIVER_INSURANCE",
  CORPORATE_FLEET_INSURANCE = "CORPORATE_FLEET_INSURANCE",
  CLAIM = "CLAIM",
  INVOICE = "INVOICE",
  RECEIPT = "RECEIPT"
}

export const DocumentTypeValue: { [key in DocumentType]: string } = {
  PCO_DOCUMENT: "PCO Document",
  POLICY_DOCUMENT: "Policy Document",
  INSPECTION_REPORT_DOCUMENT: "Inspection Report Document",
  INDIVIDUAL_INSURANCE_DOCUMENT: "Individual Insurance Document",
  SERVICE_RECEIPT: "Service Receipt",
  OTHER: "Other",
  VEHICLE_INSURANCE: "Vehicle Insurance",
  VEHICLE_LOGBOOK: "Vehicle Logbook",
  MOT_CERTIFICATE: "MOT Certificate",
  DRIVER_INSURANCE: "Driver Insurance",
  CORPORATE_FLEET_INSURANCE: "Corporate Fleet Insurance",
  CLAIM: "Claim",
  INVOICE: "Invoice",
  RECEIPT: "Receipt"
};

export enum CsvUploadStatusType {
  DEFAULT,
  IN_PROGRESS,
  ERROR,
  SUCCESS
}
export interface ICsvUploadError {
  index: number;
  message: string;
}

export interface IDvlaCsvUploadErrors {
  licencePlate: string;
  error: number;
}

export const DvlaUploadErrorMessage = [
  "Licence Plate already exists.",
  "Licence plate found.",
  "Licence Plate not found.",
  "Invalid licence plate.",
  "DVLA api error"
];

export const individualCustomerCsvHeaders = [
  "First Name",
  "Last Name",
  "Phone Number",
  "Email",
  "Date Of Birth(Optional)",
  "Gender(Optional)",
  "Licence Number(Optional)",
  "Date Of Expiry(Optional)",
  "Authority(Optional)",
  "Street",
  "City",
  "State",
  "Country",
  "Zip Code/Postal Code",
  "Cost Center",
  "Project Ids (Optional)"
];

export const businessCustomerCsvHeaders = [
  "First Name",
  "Last Name",
  "Email",
  "Phone Number",
  "Business Name",
  "Registration Number(Optional)",
  "VAT/Tax(Optional)",
  "Billing Phone Number",
  "Billing Email",
  "Street",
  "City",
  "State",
  "Country",
  "Zip Code/Postal Code",
  "Signatory First Name",
  "Signatory Last Name",
  "Signatory Email",
  "Signatory Phone Number"
];

export const bookingCsvHeaders = [
  "Customer",
  "Customer Email",
  "Customer Type",
  "Signatory Email",
  "Licence Plates",
  "Vehicle Groups",
  "Pickup Date Time",
  "Dropoff Date Time",
  "Pickup Service Location",
  "Dropoff Service Location",
  "Booking Status",
  "Insurance Policy Name",
  "Discount Amount",
  "addons",
  "Addon Quantity",
  "Billing Cylce",
  "Paid Amount",
  "Cost Center",
  "Project Id (Optional)"
];

export const globalVehicleCsvHeaders = [
  "Vehicle Registration No.",
  "Body Type",
  "Vehicle Color",
  "Cylinder Capacity",
  "Fuel Type",
  "Make",
  "Model",
  "Year",
  "Transmission",
  "VIN",
  "Number Of Doors(Optional)",
  "Number Of Seats(Optional)",
  "Frog Diagram",
  "Vehicle De-Fleet Date",
  "DeFleet Alert Before By Distance(In Number)",
  "DeFleet Alert Before By Day(s)(In Number)",
  "Service Schedule By Month(In Number)",
  "Service Alert Before(In Number)",
  "Registration Date",
  "V5C Date"
];

export const subscriptionPricingCsvHeaders = [
  "Licence Plate",
  "Subscription Name",
  "Duration",
  "Enrollment Amount",
  "Subscription Amount",
  "Mileage",
  "Price Per Extra Mile",
  "Unlimited Mileage",
  "Swaps Allowed",
  "Deposit(Refundable)"
];

export const vehicleServiceUploadCsvHeaders = [
  "Licence Plate",
  "Vehicle De-Fleet Date",
  "Service Schedule By Month(In Number)"
];

export const vehicleDefleetUploadCsvHearders = [
  "Licence Plate",
  "Residual Value",
  "Exterior paint",
  "Wheel rims",
  "Windscreens and windows",
  "Mirrors",
  "Bumper",
  "Headlamps",
  "Interior fabric",
  "Locks and door handles",
  "Keys",
  "Telematics device removed(if installed)",
  "PCN",
  "PCN Total Amount",
  "Loan Status",
  "Licence Plate Status",
  "Nama Grading",
  "De-Fleet Price"
]

export const vehicleCsvHeaders = [
  "Vehicle Registration Number(e.g. sm11xyz)",
  "Frog Diagram",
  "Vehicle De-Fleet Date",
  "DeFleet Alert Before By Distance(In Number)",
  "DeFleet Alert Before By Day(s)(In Number)",
  "Service Schedule By Month(In Number)",
  "Service Alert Before(In Number)"
];

export const coordinateOffset = 50;

export const getRentalTaxAmount = (
  tax: ITax[],
  rentalTime: number,
  taxableAmount: number,
  vehicleCount: number
) => {
  let payableTaxAmount = 0;
  tax.forEach((taxObj) => {
    if (taxObj.type === "PERCENTAGE" && taxObj.rate === "ONE_TIME") {
      payableTaxAmount += Math.round((taxObj.amount * taxableAmount) / 100);
      if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * vehicleCount;
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * rentalTime * vehicleCount;
      }
    } else if (taxObj.type === "PERCENTAGE" && taxObj.rate === "PER_DAY") {
      payableTaxAmount += Math.round((taxObj.amount * taxableAmount) / 100);
      if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * vehicleCount;
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * rentalTime * vehicleCount;
      }
    } else if (taxObj.type === "FIXED" && taxObj.rate === "ONE_TIME") {
      payableTaxAmount += taxObj.amount * vehicleCount;
      if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * vehicleCount;
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * rentalTime * vehicleCount;
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      }
    } else if (taxObj.type === "FIXED" && taxObj.rate === "PER_DAY") {
      payableTaxAmount += taxObj.amount * rentalTime * vehicleCount;
      if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * rentalTime * vehicleCount;
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * vehicleCount;
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionOptIn &&
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      }
    } else if (!taxObj.type) {
      payableTaxAmount += Math.round((taxObj.amount * taxableAmount) / 10000);
    }
  });
  return payableTaxAmount;
};

export const getTaxesDescription = (
  taxes: ITax[],
  currency: string,
  locale: string
) => {
  const taxTitles: string[] = [];
  let title;
  if (taxes) {
    taxes.forEach((taxObj) => {
      if (taxObj.amount > 0) {
        if (
          taxObj.subdivisionOptIn &&
          taxObj.subdivisionValue &&
          taxObj.subdivisionRate &&
          taxObj.subdivisionType
        ) {
          let subdivisionTaxValue =
            taxObj.subdivisionType === "PERCENTAGE"
              ? taxObj.subdivisionValue
              : toCurrency(taxObj.subdivisionValue, currency, locale);
          title = `${taxObj.code} ${
            taxObj.type === "PERCENTAGE"
              ? taxObj.amount
              : toCurrency(taxObj.amount, currency, locale)
          } ${
            taxObj.type === "PERCENTAGE"
              ? `% ${taxObj.rate === "PER_DAY" ? "/day" : ""}`
              : taxObj.rate === "PER_DAY"
              ? "/day"
              : ""
          } + ${subdivisionTaxValue}${
            taxObj.subdivisionType === "PERCENTAGE"
              ? `% ${taxObj.subdivisionRate === "PER_DAY" ? "/day" : ""}`
              : taxObj.subdivisionRate === "PER_DAY"
              ? "/day"
              : ""
          }`;
        } else if (!taxObj.type) {
          title = `${taxObj.code} ${taxObj.amount / 100}%`;
        } else if (taxObj.type === "PERCENTAGE" && taxObj.rate === "PER_DAY") {
          title = `${taxObj.code} ${taxObj.amount}%/day`;
        } else {
          title = `${taxObj.code} ${
            taxObj.type === "PERCENTAGE"
              ? taxObj.amount
              : toCurrency(taxObj.amount, currency, locale)
          }${
            taxObj.type === "PERCENTAGE"
              ? `% ${taxObj.rate === "PER_DAY" ? "/day" : ""}`
              : taxObj.rate === "PER_DAY"
              ? "/day"
              : ""
          }`;
        }
        taxTitles.push(title);
      }
    });
  }
  return taxTitles;
};

export const useContainerDimensions = (myRef: any) => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight
  });

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

export enum DateTimeFormat {
  US_DATE_EXPANDED = "MM/dd/yyyy, hh:mm a",
  US_DATE_CONDENSED = "MM/dd/yyyy",
  DEFAULT_DATE_EXPANDED = "dd/MM/yyyy, hh:mm a",
  DEFAULT_DATE_CONDENSED = "dd/MM/yyyy"
}

export const DATE_TYPE = {
  EXPANDED: "EXPANDED",
  CONDENSED: "CONDENSED"
};

export enum MONTH_SELECTOR_TYPE {
  STATIC = "static",
  DROPDOWN = "dropdown"
}

export const isWhitespaceString = (str: string | undefined) => {
  if (typeof str === "undefined") {
    return 0;
  }
  return !str.replace(/\s/g, "").length;
};

export const monthDates = Array.from({ length: 28 }, (_, i) => i + 1);
export const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];
