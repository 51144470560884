import "./index.scss";
import { useLazyQuery } from "@apollo/client";
import { Box, CircularProgress, Grid, Hidden, Theme, Typography, Paper, Tooltip, FormControl, InputLabel, Select, MenuItem, ListItemText } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { DateTime as d } from 'luxon';
import { BOOKING_SOURCE, BookingStatus, BookingStatusText, IBooking, IBookingSchedule } from "../../../../reducers/bookings/types";
import { IAppState } from "../../../../store";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import { GET_CUSTOMER_BOOKINGS } from "../../../../graphql/bookings/getCustomerBookingHistoryQuery";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../common/utils";
import RegistrationColumn from "../../ReservationManagement/Booking/RegistrationColumn";
import { isArray } from "lodash";

interface IProps {
  customerId: string;
}
interface IRegistrationNumberColumn {
  branchName: string;
  externalFleet: boolean;
  licencePlates: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expansionPanel: {
      marginTop: 8,
      [theme.breakpoints.down('md')]: {
        minWidth: `calc(100vw - 105px)`,
      },
      [theme.breakpoints.up('md')]: {
        minWidth: `calc(100vw - 305px)`,
      },
    },
    newPaper: {
      [theme.breakpoints.down('sm')]: {
        width: `calc(100vw - 45px)`,
      },
      [theme.breakpoints.up('sm')]: {
        width: `calc(100vw - 245px)`,
      },
    },
    summaryLeftMargin: {
      marginLeft: 5
    },
    linkText: {
      color: theme.palette.primary.main,
      marginLeft: "1rem",
      cursor: "pointer"
    }
  })
);


export const CustomerBookingHistory: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const navigate = useNavigate();
  const [selection, setSelection] = React.useState<any[]>([]);
  const [loadBookings, { loading, data }] = useLazyQuery(
    GET_CUSTOMER_BOOKINGS,
    {
      fetchPolicy: "network-only",
      variables: {
        id: props.customerId
      }
    }
  );

  const [rows, setRows] = useState([]);

  const bookingsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.bookings
  );
  const filterVehicles: any = ["All"];
  const columns: any = (columnType: string, country: string, isCarSubscription?: boolean) => {
    return [
      {
        label: "ID",
        name: "referenceNumber",
        options: {
          filter: false
        }
      },
      {
        label: "Status",
        name: "status",
        options: {
          filter: true,
          customBodyRender: (value: BookingStatus) =>
            BookingStatusText[value],
          filterOptions: {
            names: [BookingStatusText.CANCELLED, BookingStatusText.COMPLETED, BookingStatusText.CONFIRMATION_IN_PROCESS, BookingStatusText.CONFIRMED, BookingStatusText.IN_PROGRESS, BookingStatusText.QUOTE, BookingStatusText.REQUIRES_APPROVAL]
          }
        }
      },
      {
        label: columnType === "bookings" ? "Customer" : "Signatory",
        name: columnType === "bookings" ? "customer" : "authorizedSignatoryName",
        options: {
          filter: false
        }
      },
      {
        label: "Pick-up Date & Time",
        name: "pickupDateTime",
        options: {
          filter: false,
          customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
        }
      },
      {
        label: "Pick-up Location",
        name: "pickupServiceLocation",
        options: {
          filter: false
        }
      },
      {
        label: "Drop-off Date & Time",
        name: "dropoffDateTime",
        options: {
          filter: false,
          customBodyRender: (value: string) =>
            value
              ? getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
              : ""
        }
      },
      {
        label: "Drop-off Location",
        name: "dropoffServiceLocation",
        options: {
          filter: false
        }
      },
      {
        label: "Channel",
        name: "source",
        options: {
          customBodyRender: (value: string) => {
            if (value === BOOKING_SOURCE.B2B) {
              return "Web App"
            } else if (value === BOOKING_SOURCE.B2B2C) {
              return "Website"
            } else if (value === BOOKING_SOURCE.B2B2C_MOBILE) {
              return "Mobile App"
            }
          },
          filterOptions: {
            names: ["Web App", "Website", "Mobile App"]
          }
        }
      },
      {
        label: "Booking Source",
        name: "bookingSource",
        options: {
          display: false,
          filter: false,
          viewColumns: false
        }
      },
      {
        label: "licencePlateStr",
        name: "licencePlateStr",
        options: {
          display: false,
          filter: false,
          viewColumns: false
        }
      },
      {
        label: "Customer Type",
        name: "customerType",
        options: {
          customBodyRender: (value: string) => {
            if (value) {
              return value.toUpperCase();
            }
          },
          filterOptions: {
            names: ["INDIVIDUAL", "BUSINESS"]
          }
        }
      }, {
        label: "TBA",
        name: "tbaData",
        options: {
          display: (bookingsTableState?.viewColumns?.tbaData === "true"),
          filterOptions: {
            names: ["-", "TBA"]
          }
        }
      },
      isCarSubscription ? {
        label: `${getLocalizedBookingSyntex(country)} Type`,
        name: "bookingType",
        options: {
          display: (bookingsTableState?.viewColumns?.bookingType === "true"),
          filterOptions: {
            names: ["RENTAL", "SUBSCRIPTION"]
          }
        }
      } : {
        label: `${getLocalizedBookingSyntex(country)} Type`,
        name: "bookingType",
        options: {
          display: (bookingsTableState?.viewColumns?.bookingType === "true"),
          filter: false
        }
      },
      {
        label: "Created By",
        name: "bookingUser",
        options: {
          filter: false,
          customBodyRender: (value: string) => {
            return value
          }
        }
      },
      {
        label: "Registration Number",
        name: "licencePlateDetails",
        options: {
          filterType: "custom",
          filter: false,
          names: ["Registration number"],
          customBodyRender: (value: any) => {
            if (value && value.licencePlates && value.licencePlates.length) {
              value.licencePlates.forEach((licencePlate: string) => {
                if (!filterVehicles.includes(licencePlate)) {
                  filterVehicles.push(licencePlate);
                }
              })
            }
            if (value?.externalFleet) {
              return <Grid container xs={12}>
                <Grid item xs={10}>
                  <RegistrationColumn licencePlates={value.licencePlates} />
                </Grid>
                {
                  value.externalFleet && (
                    <Grid item xs={1}>
                      <Tooltip title={`Shared Fleet - ${value.branchName}`}>
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                  )
                }
              </Grid>
            }
            return <RegistrationColumn licencePlates={value?.licencePlates || []} />
          },
          filterList: [],
          customFilterListOptions: {
            render: (item: string) => {
              return item;
            },
            update: (filterList: any[], filterPos: number, index: number) => {
              if (filterPos !== -1) {
                filterList[index] = [];
              } else if (filterPos === -1) {
                filterList[index] = [];
              }
              return filterList;
            }
          },
          filterOptions: {
            logic: (data: any[], filters: string[]) => {
              const newData =
                data &&
                Object.values(data).map((item: any) => {
                  if (isArray(item)) {
                    const licencePlates: string[] = item;
                    return licencePlates.join(",");
                  }
                  return item;
                });
              if (filters.length) {
                return !newData.includes(filters);
              }
              return false;
            },
            display: (
              filterList: any[],
              onChange: Function,
              index: number,
              column: any[]
            ) => {
              const optionValues =
                filterVehicles &&
                filterVehicles.filter((item: string, key: number) => {
                  return filterVehicles.indexOf(item) === key;
                });
              return (
                <FormControl>
                  <InputLabel htmlFor="select-multiple-chip">Registration Number</InputLabel>
                  <Select
                    value={filterList[index].length ? filterList[index] : "All"}
                    onChange={(event) => {
                      if (event.target.value === "All") {
                        filterList[index] = [];
                        onChange(filterList[index], index, column);
                      } else {
                        filterList[index] = event.target.value;
                        onChange(filterList[index], index, column);
                      }
                    }}
                  >
                    {optionValues &&
                      optionValues.map((item: string, key: number) => (
                        <MenuItem key={key} value={item}>
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              );
            }
          }
        }
      }
    ];
  }


  useEffect(() => {
    if (userState.tenancy) {
      loadBookings();
    }
  }, [userState]);

  useEffect(() => {
    if (data) {
      setRows(
        data.bookingsByCustomerId.map(
          (booking: any) => reshapeBookingsIntoRows(booking) // FIXME: Send licencePlate from backend
        )
      );
    }
  }, [data]);

  function reshapeBookingsIntoRows(booking: IBooking) {
    const customer = booking.businessCustomer
      ? booking.businessCustomer.businessName
      : booking.customer
        ? booking.customer.firstName + " " + booking.customer.lastName
        : "";
    let bookingUser = `${booking?.createdBy?.firstName || "-"} ${booking?.createdBy?.lastName || ""}`
    let licencePlateDetails: any = {
      externalFleet: false,
      branchName: "",
      licencePlates: []
    };
    if (
      booking.currentBookingSchedules &&
      booking.currentBookingSchedules.length
    ) {
      const licencePlateArr: string[] = [];
      booking.currentBookingSchedules.forEach((schedule: IBookingSchedule) => {
        if (!licencePlateDetails.externalFleet && schedule?.vehicle?.branchId !== userState.currentBranch.id) {
          const branch = userState.currentOrganisation.branches.find((item => item.id === schedule?.vehicle?.branchId))
          if (branch) {
            licencePlateDetails.branchName = branch.name
          }
        }
        if (schedule?.vehicle?.licencePlate) {
          licencePlateArr.push(
            schedule.vehicle.licencePlate.toUpperCase()
          );
        }
      });
      if (licencePlateArr.length) {
        licencePlateDetails.licencePlates = licencePlateArr;
      }
    }
    const pickupServiceLocation = booking.pickupServiceLocation?.name || "";
    const dropoffServiceLocation = booking.dropoffServiceLocation?.name || "";
    const source = booking.source || "";
    let tbaData = booking?.tba ? "TBA" : "-";
    let bookingType = booking.isSubscription ? "SUBSCRIPTION" : "RENTAL" ;
    return {
      ...booking,
      customer,
      licencePlateDetails,
      dropoffServiceLocation,
      pickupServiceLocation,
      source,
      bookingUser,
      tbaData,
      bookingType
    };
  }

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    elevation: 0,
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      if (rowData && rowData.length) {
        const bookingClicked = data.bookingsByCustomerId[rowMeta.dataIndex];
        if (bookingClicked.status === BookingStatus.QUOTE) {
          navigate(`/update-booking?booking=${bookingClicked.id}&step=3`);
        } else {
          navigate(`/view-booking?booking=${bookingClicked.id}`);
        }
      }
    },
    setRowProps: (row) => {
      const bookingRow: IBooking = data.bookingsByCustomerId.find(
        (booking: IBooking) => booking.referenceNumber === row[0]
      );
      if (
        bookingRow.status === BookingStatus.IN_PROGRESS &&
        !bookingRow.longTermBooking &&
        bookingRow.dropoffDateTime &&
        d.now().toUTC().toISO() > (bookingRow.dropoffDateTime)
      ) {
        return {
          style: { backgroundColor: "#FFFDCA" }
        };
      } else {
        return {};
      }
    },
    onDownload: (buildHead, buildBody, columns, data: { data: any[]; }[]) => {
      data.forEach(row => {
        row.data[row.data.length - 1] = getRegistrationNumber(row.data[row.data.length - 1]);
      });
      return buildHead(columns) + buildBody(data)
    },
  };

  const getRegistrationNumber = (data: IRegistrationNumberColumn) => {
    if (data.licencePlates && data.licencePlates.length > 0) {
      return data.licencePlates.join(",");
    }
    else {
      return "";
    }
  }

  return (
    <>
      {!loading ? (
        <>
          <Hidden smDown>
            <Grid container spacing={2}>
              <Grid item container xs={12}>
                <Typography variant={"h3"}>{getLocalizedBookingSyntex(country).toUpperCase()} HISTORY</Typography>
              </Grid>
              <Grid container item xs={12}>
                <Paper variant='outlined' className={classes.newPaper}>
                  <NuvvenTable
                    title={""}
                    rows={rows}
                    columns={columns("bookings", country)}
                    setSelection={setSelection}
                    options={options}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Hidden>
          {/* New mobile friendly expansion panels below */}
          <Hidden mdUp>
            <Box className={classes.expansionPanel} >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h3">{getLocalizedBookingSyntex(country)} History</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container xs={12} spacing={1}>
                    {rows.map((booking: IBooking, index: number) => {
                      return (
                        <Grid item xs={12}>
                          <Grid container
                            key={index}
                            style={{
                              background: "#f5f5f5",
                              borderRadius: 5,
                              padding: 20
                            }}>
                            <Grid container item xs={12}>
                              <Grid item>
                                <Typography variant="h6">
                                  ID:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1" className={classes.linkText}
                                  onClick={() => {
                                    if (booking.status === BookingStatus.QUOTE) {
                                      navigate(`/update-booking?booking=${booking.id}&step=3`);
                                    } else {
                                      navigate(`/view-booking?booking=${booking.id}`);
                                    }
                                  }}>{booking.referenceNumber}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Booking Status:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">{BookingStatusText[booking.status]}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Pick-Up Date/Time:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">
                                  {getLocalizedDateFormat(country, booking.pickupDateTime, DATE_TYPE.EXPANDED)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Pick-Up Location:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">
                                  {booking.pickupServiceLocation}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Drop-Off Date/Time:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">{getLocalizedDateFormat(country, booking.dropoffDateTime || "", DATE_TYPE.EXPANDED)}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Channel:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">
                                  {booking.source}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  TBA:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">
                                  {booking.source}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Hidden>
        </>
      ) : (
        <CircularProgress />
      )}

    </>
  );
}
