import { DateTime as d } from "luxon";
import { DATE_TYPE, DateTimeFormat } from "../components/common/utils";
import { BOOKING_TYPE } from "../reducers/organisation/types";
import { DialogTypes } from "../components/views/Fleet/VehicleScheduler/utils";

export const getLocalizedTaxSyntex = (country: string) => {
  if (country === "United States") {
    return "Taxes & Fees";
  } else {
    return "VAT/TAX";
  }
};

export const getLocalizedBookingSyntex = (country: string) => {
  if (country === "United States") {
    return "Reservation";
  } else {
    return "Booking";
  }
};

export const getLocalizedCancelBookingSyntex = (country: string) => {
  if (country === "United States") {
    return "Cancel Reservation";
  } else {
    return "Cancel Booking";
  }
};

export const getLocalizedBookingSummarySyntex = (country: string) => {
  if (country === "United States") {
    return DialogTypes.RESERVATION_SUMMARY;
  } else {
    return DialogTypes.BOOKING_SUMMARY;
  }
};

export const getLocalizedOrganisationSyntex = (country: string) => {
  if (country === "United States") {
    return "Location";
  } else {
    return "Organisation";
  }
};

export const getLocalizedRASyntex = (country: string, type: BOOKING_TYPE) => {
  if(type === BOOKING_TYPE.RENTAL ) {
    if (country === "United States") {
      return "Contract";
    } else {
      return "Rental Agreement";
    }
  } else {
    return "Subscription Agreement"
  }
 
};

export const getLocalizedVatNumberSyntex = (country: string) => {
  if (country === "United States") {
    return "Tax number";
  } else {
    return "VAT number";
  }
};

export const getLocalizedInsuranceNumberSyntex = (country: string) => {
  if (country === "United States") {
    return "Driver Insurance Number";
  } else {
    return "National Insurance Number";
  }
};

export const getLocalizedSundrySyntex = (country: string) => {
  if (country === "United States") {
    return "Past Due";
  } else {
    return "Sundry";
  }
};

export const getLocalizedDateFormat = (
  country: string,
  date: string,
  dateType: string
) => {
  if (country === "United States") {
    if (dateType === DATE_TYPE.EXPANDED) {
      return d.fromISO(date).toFormat(DateTimeFormat.US_DATE_EXPANDED);
    }
    return d.fromISO(date).toFormat(DateTimeFormat.US_DATE_CONDENSED);
  } else {
    if (dateType === DATE_TYPE.EXPANDED) {
      return d.fromISO(date).toFormat(DateTimeFormat.DEFAULT_DATE_EXPANDED);
    }
    return d.fromISO(date).toFormat(DateTimeFormat.DEFAULT_DATE_CONDENSED);
  }
};

export const getLocalizedDateFormatString = (country: string, dateType: string) => {
  if (country === "United States") {
    if (dateType === DATE_TYPE.EXPANDED) {
      return DateTimeFormat.US_DATE_EXPANDED;
    }
    return DateTimeFormat.US_DATE_CONDENSED;
  } else {
    if (dateType === DATE_TYPE.EXPANDED) {
      return DateTimeFormat.DEFAULT_DATE_EXPANDED;
    }
    return DateTimeFormat.DEFAULT_DATE_CONDENSED;
  }
}

export const getDefaultCountryCode = (country: string) => {
  if (country === "United States") {
    return "US";
  } else {
    return "GB";
  }
};

export const formatTelematicDateTime = (inputTimestamp: string, country: string) => {
  if (inputTimestamp) {
    const modifiedTimestamp = inputTimestamp.replace(' ', 'T').replace(/\.\d+/, '');
    const dateTime = d.fromISO(modifiedTimestamp, { zone: 'utc' });
    const formattedDateTime = dateTime.toISO({ suppressMilliseconds: false });
    return getLocalizedDateFormat(country, formattedDateTime, DATE_TYPE.EXPANDED)
  } else {
    return ""
  }
}
