export const CURRENCY_LIST = [
  { value: "USD", label: "US Dollar", name: "USD" },
  { value: "EUR", label: "Euro", name: "EUR" },
  { value: "JPY", label: "Japanese Yen", name: "JPY" },
  { value: "GBP", label: "British Pound", name: "GBP" },
  { value: "AED", label: "United Arab Emirates Dirham", name: "AED" },
  { value: "AFN", label: "Afghan Afghani", name: "AFN" },
  { value: "ALL", label: "Albanian Lek", name: "ALL" },
  { value: "AMD", label: "Armenian Dram", name: "AMD" },
  { value: "ANG", label: "Netherlands Antillean Guilder", name: "ANG" },
  { value: "AOA", label: "Angolan Kwanza", name: "AOA" },
  { value: "ARS", label: "Argentine Peso", name: "ARS" },
  { value: "AUD", label: "Australian Dollar", name: "AUD" },
  { value: "AWG", label: "Aruban Florin", name: "AWG" },
  { value: "AZN", label: "Azerbaijani Manat", name: "AZN" },
  {
    value: "BAM",
    label: "Bosnia and Herzegovina Convertible Mark",
    name: "BAM"
  },
  { value: "BBD", label: "Barbadian Dollar", name: "BBD" },
  { value: "BDT", label: "Bangladeshi Taka", name: "BDT" },
  { value: "BGN", label: "Bulgarian Lev", name: "BGN" },
  { value: "BHD", label: "Bahraini Dinar", name: "BHD" },
  { value: "BIF", label: "Burundian Franc", name: "BIF" },
  { value: "BMD", label: "Bermudian Dollar", name: "BMD" },
  { value: "BND", label: "Brunei Dollar", name: "BND" },
  { value: "BOB", label: "Bolivian Boliviano", name: "BOB" },
  { value: "BRL", label: "Brazilian Real", name: "BRL" },
  { value: "BSD", label: "Bahamian Dollar", name: "BSD" },
  { value: "BTN", label: "Bhutanese Ngultrum", name: "BTN" },
  { value: "BWP", label: "Botswana Pula", name: "BWP" },
  { value: "BYN", label: "Belarusian Ruble", name: "BYN" },
  { value: "BZD", label: "Belize Dollar", name: "BZD" },
  { value: "CAD", label: "Canadian Dollar", name: "CAD" },
  { value: "CDF", label: "Congolese Franc", name: "CDF" },
  { value: "CHF", label: "Swiss Franc", name: "CHF" },
  { value: "CLP", label: "Chilean Peso", name: "CLP" },
  { value: "CNY", label: "Chinese Yuan", name: "CNY" },
  { value: "COP", label: "Colombian Peso", name: "COP" },
  { value: "CRC", label: "Costa Rican Colón", name: "CRC" },
  { value: "CUC", label: "Cuban Convertible Peso", name: "CUC" },
  { value: "CUP", label: "Cuban Peso", name: "CUP" },
  { value: "CVE", label: "Cape Verdean Escudo", name: "CVE" },
  { value: "CZK", label: "Czech Koruna", name: "CZK" },
  { value: "DJF", label: "Djiboutian Franc", name: "DJF" },
  { value: "DKK", label: "Danish Krone", name: "DKK" },
  { value: "DOP", label: "Dominican Peso", name: "DOP" },
  { value: "DZD", label: "Algerian Dinar", name: "DZD" },
  { value: "EGP", label: "Egyptian Pound", name: "EGP" },
  { value: "ERN", label: "Eritrean Nakfa", name: "ERN" },
  { value: "ETB", label: "Ethiopian Birr", name: "ETB" },
  { value: "FJD", label: "Fijian Dollar", name: "FJD" },
  { value: "FKP", label: "Falkland Islands Pound", name: "FKP" },
  { value: "GEL", label: "Georgian Lari", name: "GEL" },
  { value: "GGP", label: "Guernsey Pound", name: "GGP" },
  { value: "GHS", label: "Ghanaian Cedi", name: "GHS" },
  { value: "GIP", label: "Gibraltar Pound", name: "GIP" },
  { value: "GMD", label: "Gambian Dalasi", name: "GMD" },
  { value: "GNF", label: "Guinean Franc", name: "GNF" },
  { value: "GTQ", label: "Guatemalan Quetzal", name: "GTQ" },
  { value: "GYD", label: "Guyanese Dollar", name: "GYD" },
  { value: "HNL", label: "Honduran Lempira", name: "HNL" },
  { value: "HKD", label: "Hong Kong Dollar", name: "HKD" },
  { value: "HRK", label: "Croatian Kuna", name: "HRK" },
  { value: "HTG", label: "Haitian Gourde", name: "HTG" },
  { value: "HUF", label: "Hungarian Forint", name: "HUF" },
  { value: "IDR", label: "Indonesian Rupiah", name: "IDR" },
  { value: "ILS", label: "Israeli new Shekel", name: "ILS" },
  { value: "IMP", label: "Manx Pound", name: "IMP" },
  { value: "INR", label: "Indian Rupee", name: "INR" },
  { value: "IQD", label: "Iraqi Dinar", name: "IQD" },
  { value: "IRR", label: "Iranian Rial", name: "IRR" },
  { value: "ISK", label: "Icelandic Króna", name: "ISK" },
  { value: "JEP", label: "Jersey Pound", name: "JEP" },
  { value: "JMD", label: "Jamaican Dollar", name: "JMD" },
  { value: "JOD", label: "Jordanian Dinar", name: "JOD" },
  { value: "KES", label: "Kenyan Shilling", name: "KES" },
  { value: "KGS", label: "Kyrgyzstani Som", name: "KGS" },
  { value: "KHR", label: "Cambodian Riel", name: "KHR" },
  { value: "KID", label: "Kiribati Dollar", name: "KID" },
  { value: "KMF", label: "Comorian Franc", name: "KMF" },
  { value: "KPW", label: "North Korean Won", name: "KPW" },
  { value: "KRW", label: "South Korean Won", name: "KRW" },
  { value: "KWD", label: "Kuwaiti Dinar", name: "KWD" },
  { value: "KYD", label: "Cayman Islands Dollar", name: "KYD" },
  { value: "KZT", label: "Kazakhstani Tenge", name: "KZT" },
  { value: "LAK", label: "Lao Kip", name: "LAK" },
  { value: "LBP", label: "Lebanese Pound", name: "LBP" },
  { value: "LKR", label: "Sri Lankan Rupee", name: "LKR" },
  { value: "LRD", label: "Liberian Dollar", name: "LRD" },
  { value: "LSL", label: "Lesotho Loti", name: "LSL" },
  { value: "LYD", label: "Libyan Dinar", name: "LYD" },
  { value: "MAD", label: "Moroccan Dirham", name: "MAD" },
  { value: "MDL", label: "Moldovan Leu", name: "MDL" },
  { value: "MGA", label: "Malagasy Ariary", name: "MGA" },
  { value: "MKD", label: "Macedonian Denar", name: "MKD" },
  { value: "MMK", label: "Burmese Kyat", name: "MMK" },
  { value: "MNT", label: "Mongolian Tögrög", name: "MNT" },
  { value: "MOP", label: "Macanese Pataca", name: "MOP" },
  { value: "MRU", label: "Mauritanian Ouguiya", name: "MRU" },
  { value: "MUR", label: "Mauritian Rupee", name: "MUR" },
  { value: "MVR", label: "Maldivian Rufiyaa", name: "MVR" },
  { value: "MWK", label: "Malawian Kwacha", name: "MWK" },
  { value: "MXN", label: "Mexican Peso", name: "MXN" },
  { value: "MYR", label: "Malaysian Ringgit", name: "MYR" },
  { value: "MZN", label: "Mozambican Metical", name: "MZN" },
  { value: "NAD", label: "Namibian Dollar", name: "NAD" },
  { value: "NGN", label: "Nigerian Naira", name: "NGN" },
  { value: "NIO", label: "Nicaraguan Córdoba", name: "NIO" },
  { value: "NOK", label: "Norwegian Krone", name: "NOK" },
  { value: "NPR", label: "Nepalese Rupee", name: "NPR" },
  { value: "NZD", label: "New Zealand Dollar", name: "NZD" },
  { value: "OMR", label: "Omani Rial", name: "OMR" },
  { value: "PAB", label: "Panamanian Balboa", name: "PAB" },
  { value: "PEN", label: "Peruvian Sol", name: "PEN" },
  { value: "PGK", label: "Papua New Guinean Kina", name: "PGK" },
  { value: "PHP", label: "Philippine Peso", name: "PHP" },
  { value: "PKR", label: "Pakistani Rupee", name: "PKR" },
  { value: "PLN", label: "Polish Złoty", name: "PLN" },
  { value: "PRB", label: "Transnistrian Ruble", name: "PRB" },
  { value: "PYG", label: "Paraguayan Guaraní", name: "PYG" },
  { value: "QAR", label: "Qatari Riyal", name: "QAR" },
  { value: "RON", label: "Romanian Leu", name: "RON" },
  { value: "RSD", label: "Serbian Dinar", name: "RSD" },
  { value: "RUB", label: "Russian Ruble", name: "RUB" },
  { value: "RWF", label: "Rwandan Franc", name: "RWF" },
  { value: "SAR", label: "Saudi Riyal", name: "SAR" },
  { value: "SEK", label: "Swedish Krona", name: "SEK" },
  { value: "SGD", label: "Singapore Dollar", name: "SGD" },
  { value: "SHP", label: "Saint Helena Pound", name: "SHP" },
  { value: "SLL", label: "Sierra Leonean Leone", name: "SLL" },
  { value: "SLS", label: "Somaliland Shilling", name: "SLS" },
  { value: "SOS", label: "Somali Shilling", name: "SOS" },
  { value: "SRD", label: "Surinamese Dollar", name: "SRD" },
  { value: "SSP", label: "South Sudanese Pound", name: "SSP" },
  { value: "STN", label: "São Tomé and Príncipe Dobra", name: "STN" },
  { value: "SYP", label: "Syrian Pound", name: "SYP" },
  { value: "SZL", label: "Swazi Lilangeni", name: "SZL" },
  { value: "THB", label: "Thai Baht", name: "THB" },
  { value: "TJS", label: "Tajikistani Somoni", name: "TJS" },
  { value: "TMT", label: "Turkmenistan Manat", name: "TMT" },
  { value: "TND", label: "Tunisian Dinar", name: "TND" },
  { value: "TOP", label: "Tongan Paʻanga", name: "TOP" },
  { value: "TRY", label: "Turkish Lira", name: "TRY" },
  { value: "TTD", label: "Trinidad and Tobago Dollar", name: "TTD" },
  { value: "TVD", label: "Tuvaluan Dollar", name: "TVD" },
  { value: "TWD", label: "New Taiwan Dollar", name: "TWD" },
  { value: "TZS", label: "Tanzanian Shilling", name: "TZS" },
  { value: "UAH", label: "Ukrainian Hryvnia", name: "UAH" },
  { value: "UGX", label: "Ugandan Shilling", name: "UGX" },
  { value: "UYU", label: "Uruguayan Peso", name: "UYU" },
  { value: "UZS", label: "Uzbekistani Soʻm", name: "UZS" },
  { value: "VES", label: "Venezuelan Bolívar Soberano", name: "VES" },
  { value: "VND", label: "Vietnamese Dồng", name: "VND" },
  { value: "VUV", label: "Vanuatu Vatu", name: "VUV" },
  { value: "WST", label: "Samoan Tālā", name: "WST" },
  { value: "XAF", label: "Central African CFA Franc", name: "XAF" },
  { value: "XCD", label: "Eastern Caribbean Dollar", name: "XCD" },
  { value: "XOF", label: "West African CFA Franc", name: "XOF" },
  { value: "XPF", label: "CFP Franc", name: "XPF" },
  { value: "ZAR", label: "South African Rand", name: "ZAR" },
  { value: "ZMW", label: "Zambian Kwacha", name: "ZMW" },
  { value: "ZWB", label: "Zimbabwean Bonds", name: "ZWB" }
];

export const LANGUAGE_LIST = [
  { name: "Afghanistan - Pashto", value: "ps-AF" },
  { name: "Albania - Albanian", value: "sq-AL" },
  { name: "Algeria - Arabic", value: "ar-DZ" },
  { name: "Algeria - Tamazight (Latin)", value: "tzm-DZ" },
  { name: "Argentina - Spanish", value: "es-AR" },
  { name: "Armenia - Armenian", value: "hy-AM" },
  { name: "Australia - English", value: "en-AU" },
  { name: "Austria - German", value: "de-AT" },
  { name: "Azerbaijan - Azeri (Cyrillic)", value: "az-AZ" },
  { name: "Azerbaijan - Azeri (Latin)", value: "az-AZ" },
  { name: "Bahrain - Arabic", value: "ar-BH" },
  { name: "Bangladesh - Bengali", value: "bn-BD" },
  { name: "Belarus - Belarusian", value: "be-BY" },
  { name: "Belgium - Dutch", value: "nl-BE" },
  { name: "Belgium - French", value: "fr-BE" },
  { name: "Belize - English", value: "en-BZ" },
  { name: "Bolivarian Republic of Venezuela - Spanish", value: "es-VE" },
  { name: "Bolivia - Quechua", value: "quz-BO" },
  { name: "Bolivia - Spanish", value: "es-BO" },
  { name: "Bosnia and Herzegovina - Bosnian (Cyrillic)", value: "bs-BA" },
  { name: "Bosnia and Herzegovina - Bosnian (Latin)", value: "bs-BA" },
  { name: "Bosnia and Herzegovina - Croatian", value: "hr-BA" },
  { name: "Bosnia and Herzegovina - Serbian (Cyrillic)", value: "sr-BA" },
  { name: "Bosnia and Herzegovina - Serbian (Latin)", value: "sr-BA" },
  { name: "Brazil - Portuguese", value: "pt-BR" },
  { name: "Brunei Darussalam - Malay", value: "ms-BN" },
  { name: "Bulgaria - Bulgarian", value: "bg-BG" },
  { name: "Cambodia - Khmer", value: "km-KH" },
  { name: "Canada - English", value: "en-CA" },
  { name: "Canada - French", value: "fr-CA" },
  { name: "Canada - Inuktitut (Latin)", value: "iu-CA" },
  { name: "Canada - Inuktitut (Syllabics)", value: "iu-CA" },
  { name: "Canada - Mohawk", value: "moh-CA" },
  { name: "Caribbean - English", value: "en-029" },
  { name: "Chile - Mapudungun", value: "arn-CL" },
  { name: "Chile - Spanish", value: "es-CL" },
  { name: "Colombia - Spanish", value: "es-CO" },
  { name: "Costa Rica - Spanish", value: "es-CR" },
  { name: "Croatia - Croatian", value: "hr-HR" },
  { name: "Czech Republic - Czech", value: "cs-CZ" },
  { name: "Denmark - Danish", value: "da-DK" },
  { name: "Dominican Republic - Spanish", value: "es-DO" },
  { name: "Ecuador - Quechua", value: "quz-EC" },
  { name: "Ecuador - Spanish", value: "es-EC" },
  { name: "Egypt - Arabic", value: "ar-EG" },
  { name: "El Salvador - Spanish", value: "es-SV" },
  { name: "Estonia - Estonian", value: "et-EE" },
  { name: "Ethiopia - Amharic", value: "am-ET" },
  { name: "Faroe Islands - Faroese", value: "fo-FO" },
  { name: "Finland - Finnish", value: "fi-FI" },
  { name: "Finland - Sami (Inari)", value: "smn-FI" },
  { name: "Finland - Sami (Northern)", value: "se-FI" },
  { name: "Finland - Sami (Skolt)", value: "sms-FI" },
  { name: "Finland - Swedish", value: "sv-FI" },
  { name: "France - Alsatian", value: "gsw-FR" },
  { name: "France - Breton", value: "br-FR" },
  { name: "France - Corsican", value: "co-FR" },
  { name: "France - French", value: "fr-FR" },
  { name: "France - Occitan", value: "oc-FR" },
  { name: "Georgia - Georgian", value: "ka-GE" },
  { name: "Germany - German", value: "de-DE" },
  { name: "Germany - Lower Sorbian", value: "dsb-DE" },
  { name: "Germany - Upper Sorbian", value: "hsb-DE" },
  { name: "Greece - Greek", value: "el-GR" },
  { name: "Greenland - Greenlandic", value: "kl-GL" },
  { name: "Guatemala - K'iche", value: "qut-GT" },
  { name: "Guatemala - Spanish", value: "es-GT" },
  { name: "Honduras - Spanish", value: "es-HN" },
  { name: "Hong Kong S.A.R. - Chinese (Traditional) Legacy", value: "zh-HK" },
  { name: "India - English", value: "en-IN" },
  { name: "India - Hindi", value: "hi-IN" },
  { name: "Indonesia - Indonesian", value: "id-ID" },
  { name: "Iran - Persian", value: "fa-IR" },
  { name: "Iraq - Arabic", value: "ar-IQ" },
  { name: "Ireland - English", value: "en-IE" },
  { name: "Ireland - Irish", value: "ga-IE" },
  { name: "Islamic Republic of Pakistan - Urdu", value: "ur-PK" },
  { name: "Israel - Hebrew", value: "he-IL" },
  { name: "Italy - Italian", value: "it-IT" },
  { name: "Jamaica - English", value: "en-JM" },
  { name: "Japan - Japanese", value: "ja-JP" },
  { name: "Jordan - Arabic", value: "ar-JO" },
  { name: "Kazakhstan - Kazakh", value: "kk-KZ" },
  { name: "Kenya - Kiswahili", value: "sw-KE" },
  { name: "Korea - Korean", value: "ko-KR" },
  { name: "Kuwait - Arabic", value: "ar-KW" },
  { name: "Kyrgyzstan - Kyrgyz", value: "ky-KG" },
  { name: "Lao P.D.R. - Lao", value: "lo-LA" },
  { name: "Latvia - Latvian", value: "lv-LV" },
  { name: "Lebanon - Arabic", value: "ar-LB" },
  { name: "Libya - Arabic", value: "ar-LY" },
  { name: "Liechtenstein - German", value: "de-LI" },
  { name: "Lithuania - Lithuanian", value: "lt-LT" },
  { name: "Luxembourg - French", value: "fr-LU" },
  { name: "Luxembourg - German", value: "de-LU" },
  { name: "Luxembourg - Luxembourgish", value: "lb-LU" },
  { name: "Macao S.A.R. - Chinese (Traditional) Legacy", value: "zh-MO" },
  { name: "Macedonia (FYROM) - Macedonian (FYROM)", value: "mk-MK" },
  { name: "Malaysia - English", value: "en-MY" },
  { name: "Malaysia - Malay", value: "ms-MY" },
  { name: "Maldives - Divehi", value: "dv-MV" },
  { name: "Malta - Maltese", value: "mt-MT" },
  { name: "Mexico - Spanish", value: "es-MX" },
  { name: "Mongolia - Mongolian (Cyrillic)", value: "mn-MN" },
  { name: "Montenegro - Serbian (Cyrillic)", value: "sr-ME" },
  { name: "Montenegro - Serbian (Latin)", value: "sr-ME" },
  { name: "Morocco - Arabic", value: "ar-MA" },
  { name: "Nepal - Nepali", value: "ne-NP" },
  { name: "Netherlands - Dutch", value: "nl-NL" },
  { name: "Netherlands - Frisian", value: "fy-NL" },
  { name: "New Zealand - English", value: "en-NZ" },
  { name: "New Zealand - Maori", value: "mi-NZ" },
  { name: "Nicaragua - Spanish", value: "es-NI" },
  { name: "Nigeria - Hausa (Latin)", value: "ha-NG" },
  { name: "Nigeria - Igbo", value: "ig-NG" },
  { name: "Nigeria - Yoruba", value: "yo-NG" },
  { name: "Norway - Norwegian (Bokmal)", value: "nb-NO" },
  { name: "Norway - Norwegian (Nynorsk)", value: "nn-NO" },
  { name: "Norway - Sami (Lule)", value: "smj-NO" },
  { name: "Norway - Sami (Northern)", value: "se-NO" },
  { name: "Norway - Sami (Southern)", value: "sma-NO" },
  { name: "Oman - Arabic", value: "ar-OM" },
  { name: "Panama - Spanish", value: "es-PA" },
  { name: "Paraguay - Spanish", value: "es-PY" },
  {
    name: "People's Republic of China - Chinese (Simplified) Legacy",
    value: "zh-CN"
  },
  {
    name: "People's Republic of China - Mongolian (Traditional Mongolian)",
    value: "mn-CN"
  },
  { name: "People's Republic of China - Tibetan", value: "bo-CN" },
  { name: "People's Republic of China - Uyghur", value: "ug-CN" },
  { name: "People's Republic of China - Yi", value: "ii-CN" },
  { name: "Peru - Quechua", value: "quz-PE" },
  { name: "Peru - Spanish", value: "es-PE" },
  { name: "Philippines - Filipino", value: "fil-PH" },
  { name: "Poland - Polish", value: "pl-PL" },
  { name: "Portugal - Portuguese", value: "pt-PT" },
  { name: "Principality of Monaco - French", value: "fr-MC" },
  { name: "Puerto Rico - Spanish", value: "es-PR" },
  { name: "Qatar - Arabic", value: "ar-QA" },
  { name: "Republic of the Philippines - English", value: "en-PH" },
  { name: "Romania - Romanian", value: "ro-RO" },
  { name: "Russia - Bashkir", value: "ba-RU" },
  { name: "Russia - Russian", value: "ru-RU" },
  { name: "Russia - Tatar", value: "tt-RU" },
  { name: "Russia - Yakut", value: "sah-RU" },
  { name: "Rwanda - Kinyarwanda", value: "rw-RW" },
  { name: "Saudi Arabia - Arabic", value: "ar-SA" },
  { name: "Senegal - Wolof", value: "wo-SN" },
  { name: "Serbia - Serbian (Cyrillic)", value: "sr-RS" },
  { name: "Serbia - Serbian (Latin)", value: "sr-RS" },
  {
    name: "Serbia and Montenegro (Former) - Serbian (Cyrillic)",
    value: "sr-CS"
  },
  { name: "Serbia and Montenegro (Former) - Serbian (Latin)", value: "sr-CS" },
  { name: "Singapore - Chinese (Simplified) Legacy", value: "zh-SG" },
  { name: "Singapore - English", value: "en-SG" },
  { name: "Slovakia - Slovak", value: "sk-SK" },
  { name: "Slovenia - Slovenian", value: "sl-SI" },
  { name: "South Africa - Afrikaans", value: "af-ZA" },
  { name: "South Africa - English", value: "en-ZA" },
  { name: "South Africa - isiXhosa", value: "xh-ZA" },
  { name: "South Africa - isiZulu", value: "zu-ZA" },
  { name: "South Africa - Sesotho sa Leboa", value: "nso-ZA" },
  { name: "South Africa - Setswana", value: "tn-ZA" },
  { name: "Spain - Basque", value: "eu-ES" },
  { name: "Spain - Catalan", value: "ca-ES" },
  { name: "Spain - Galician", value: "gl-ES" },
  { name: "Spain - Spanish", value: "es-ES" },
  { name: "Sri Lanka - Sinhala", value: "si-LK" },
  { name: "Sweden - Sami (Lule)", value: "smj-SE" },
  { name: "Sweden - Sami (Northern)", value: "se-SE" },
  { name: "Sweden - Sami (Southern)", value: "sma-SE" },
  { name: "Sweden - Swedish", value: "sv-SE" },
  { name: "Switzerland - French", value: "fr-CH" },
  { name: "Switzerland - German", value: "de-CH" },
  { name: "Switzerland - Italian", value: "it-CH" },
  { name: "Switzerland - Romansh", value: "rm-CH" },
  { name: "Syria - Arabic", value: "ar-SY" },
  { name: "Syria - Syriac", value: "syr-SY" },
  { name: "Taiwan - Chinese (Traditional) Legacy", value: "zh-TW" },
  { name: "Tajikistan - Tajik (Cyrillic)", value: "tg-TJ" },
  { name: "Thailand - Thai", value: "th-TH" },
  { name: "Trinidad and Tobago - English", value: "en-TT" },
  { name: "Tunisia - Arabic", value: "ar-TN" },
  { name: "Turkey - Turkish", value: "tr-TR" },
  { name: "Turkmenistan - Turkmen", value: "tk-TM" },
  { name: "U.A.E. - Arabic", value: "ar-AE" },
  { name: "Ukraine - Ukrainian", value: "uk-UA" },
  { name: "United Kingdom - English", value: "en-GB" },
  { name: "United Kingdom - Scottish Gaelic", value: "gd-GB" },
  { name: "United Kingdom - Welsh", value: "cy-GB" },
  { name: "United States - English", value: "en-US" },
  { name: "United States - Spanish", value: "es-US" },
  { name: "Uruguay - Spanish", value: "es-UY" },
  { name: "Uzbekistan - Uzbek (Cyrillic)", value: "uz-UZ" },
  { name: "Uzbekistan - Uzbek (Latin)", value: "uz-UZ" },
  { name: "Vietnam - Vietnamese", value: "vi-VN" },
  { name: "Yemen - Arabic", value: "ar-YE" },
  { name: "Zimbabwe - English", value: "en-ZW" }
];

export const CURRENCY_SYMBOLS: Record<string, string> = {
  AED: 'AED ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: '$'
};

export const TIMEZONES = [
  {
    "name": "(GMT-11:00) Niue",
    "value": "Pacific/Niue"
  },
  {
    "name": "(GMT-11:00) Pago Pago",
    "value": "Pacific/Pago_Pago"
  },
  {
    "name": "(GMT-10:00) Hawaii Time",
    "value": "Pacific/Honolulu"
  },
  {
    "name": "(GMT-10:00) Rarotonga",
    "value": "Pacific/Rarotonga"
  },
  {
    "name": "(GMT-10:00) Tahiti",
    "value": "Pacific/Tahiti"
  },
  {
    "name": "(GMT-09:30) Marquesas",
    "value": "Pacific/Marquesas"
  },
  {
    "name": "(GMT-09:00) Alaska Time",
    "value": "America/Anchorage"
  },
  {
    "name": "(GMT-09:00) Gambier",
    "value": "Pacific/Gambier"
  },
  {
    "name": "(GMT-08:00) Pacific Time",
    "value": "America/Los_Angeles"
  },
  {
    "name": "(GMT-08:00) Pacific Time - Tijuana",
    "value": "America/Tijuana"
  },
  {
    "name": "(GMT-08:00) Pacific Time - Vancouver",
    "value": "America/Vancouver"
  },
  {
    "name": "(GMT-08:00) Pacific Time - Whitehorse",
    "value": "America/Whitehorse"
  },
  {
    "name": "(GMT-08:00) Pitcairn",
    "value": "Pacific/Pitcairn"
  },
  {
    "name": "(GMT-07:00) Mountain Time - Dawson Creek",
    "value": "America/Dawson_Creek"
  },
  {
    "name": "(GMT-07:00) Mountain Time",
    "value": "America/Denver"
  },
  {
    "name": "(GMT-07:00) Mountain Time - Edmonton",
    "value": "America/Edmonton"
  },
  {
    "name": "(GMT-07:00) Mountain Time - Hermosillo",
    "value": "America/Hermosillo"
  },
  {
    "name": "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan",
    "value": "America/Mazatlan"
  },
  {
    "name": "(GMT-07:00) Mountain Time - Arizona",
    "value": "America/Phoenix"
  },
  {
    "name": "(GMT-07:00) Mountain Time - Yellowknife",
    "value": "America/Yellowknife"
  },
  {
    "name": "(GMT-06:00) Belize",
    "value": "America/Belize"
  },
  {
    "name": "(GMT-06:00) Central Time",
    "value": "America/Chicago"
  },
  {
    "name": "(GMT-06:00) Costa Rica",
    "value": "America/Costa_Rica"
  },
  {
    "name": "(GMT-06:00) El Salvador",
    "value": "America/El_Salvador"
  },
  {
    "name": "(GMT-06:00) Guatemala",
    "value": "America/Guatemala"
  },
  {
    "name": "(GMT-06:00) Managua",
    "value": "America/Managua"
  },
  {
    "name": "(GMT-06:00) Central Time - Mexico City",
    "value": "America/Mexico_City"
  },
  {
    "name": "(GMT-06:00) Central Time - Regina",
    "value": "America/Regina"
  },
  {
    "name": "(GMT-06:00) Central Time - Tegucigalpa",
    "value": "America/Tegucigalpa"
  },
  {
    "name": "(GMT-06:00) Central Time - Winnipeg",
    "value": "America/Winnipeg"
  },
  {
    "name": "(GMT-06:00) Galapagos",
    "value": "Pacific/Galapagos"
  },
  {
    "name": "(GMT-05:00) Bogota",
    "value": "America/Bogota"
  },
  {
    "name": "(GMT-05:00) America Cancun",
    "value": "America/Cancun"
  },
  {
    "name": "(GMT-05:00) Cayman",
    "value": "America/Cayman"
  },
  {
    "name": "(GMT-05:00) Guayaquil",
    "value": "America/Guayaquil"
  },
  {
    "name": "(GMT-05:00) Havana",
    "value": "America/Havana"
  },
  {
    "name": "(GMT-05:00) Eastern Time - Iqaluit",
    "value": "America/Iqaluit"
  },
  {
    "name": "(GMT-05:00) Jamaica",
    "value": "America/Jamaica"
  },
  {
    "name": "(GMT-05:00) Lima",
    "value": "America/Lima"
  },
  {
    "name": "(GMT-05:00) Nassau",
    "value": "America/Nassau"
  },
  {
    "name": "(GMT-05:00) Eastern Time",
    "value": "America/New_York"
  },
  {
    "name": "(GMT-05:00) Panama",
    "value": "America/Panama"
  },
  {
    "name": "(GMT-05:00) Port-au-Prince",
    "value": "America/Port-au-Prince"
  },
  {
    "name": "(GMT-05:00) Rio Branco",
    "value": "America/Rio_Branco"
  },
  {
    "name": "(GMT-05:00) Eastern Time - Toronto",
    "value": "America/Toronto"
  },
  {
    "name": "(GMT-05:00) Easter Island",
    "value": "Pacific/Easter"
  },
  {
    "name": "(GMT-04:30) Caracas",
    "value": "America/Caracas"
  },
  {
    "name": "(GMT-03:00) Asuncion",
    "value": "America/Asuncion"
  },
  {
    "name": "(GMT-04:00) Barbados",
    "value": "America/Barbados"
  },
  {
    "name": "(GMT-04:00) Boa Vista",
    "value": "America/Boa_Vista"
  },
  {
    "name": "(GMT-03:00) Campo Grande",
    "value": "America/Campo_Grande"
  },
  {
    "name": "(GMT-03:00) Cuiaba",
    "value": "America/Cuiaba"
  },
  {
    "name": "(GMT-04:00) Curacao",
    "value": "America/Curacao"
  },
  {
    "name": "(GMT-04:00) Grand Turk",
    "value": "America/Grand_Turk"
  },
  {
    "name": "(GMT-04:00) Guyana",
    "value": "America/Guyana"
  },
  {
    "name": "(GMT-04:00) Atlantic Time - Halifax",
    "value": "America/Halifax"
  },
  {
    "name": "(GMT-04:00) La Paz",
    "value": "America/La_Paz"
  },
  {
    "name": "(GMT-04:00) Manaus",
    "value": "America/Manaus"
  },
  {
    "name": "(GMT-04:00) Martinique",
    "value": "America/Martinique"
  },
  {
    "name": "(GMT-04:00) Port of Spain",
    "value": "America/Port_of_Spain"
  },
  {
    "name": "(GMT-04:00) Porto Velho",
    "value": "America/Porto_Velho"
  },
  {
    "name": "(GMT-04:00) Puerto Rico",
    "value": "America/Puerto_Rico"
  },
  {
    "name": "(GMT-04:00) Santo Domingo",
    "value": "America/Santo_Domingo"
  },
  {
    "name": "(GMT-04:00) Thule",
    "value": "America/Thule"
  },
  {
    "name": "(GMT-04:00) Bermuda",
    "value": "Atlantic/Bermuda"
  },
  {
    "name": "(GMT-03:30) Newfoundland Time - St. Johns",
    "value": "America/St_Johns"
  },
  {
    "name": "(GMT-03:00) Araguaina",
    "value": "America/Araguaina"
  },
  {
    "name": "(GMT-03:00) Buenos Aires",
    "value": "America/Argentina/Buenos_Aires"
  },
  {
    "name": "(GMT-03:00) Salvador",
    "value": "America/Bahia"
  },
  {
    "name": "(GMT-03:00) Belem",
    "value": "America/Belem"
  },
  {
    "name": "(GMT-03:00) Cayenne",
    "value": "America/Cayenne"
  },
  {
    "name": "(GMT-03:00) Fortaleza",
    "value": "America/Fortaleza"
  },
  {
    "name": "(GMT-03:00) Godthab",
    "value": "America/Godthab"
  },
  {
    "name": "(GMT-03:00) Maceio",
    "value": "America/Maceio"
  },
  {
    "name": "(GMT-03:00) Miquelon",
    "value": "America/Miquelon"
  },
  {
    "name": "(GMT-03:00) Montevideo",
    "value": "America/Montevideo"
  },
  {
    "name": "(GMT-03:00) Paramaribo",
    "value": "America/Paramaribo"
  },
  {
    "name": "(GMT-03:00) Recife",
    "value": "America/Recife"
  },
  {
    "name": "(GMT-03:00) Santiago",
    "value": "America/Santiago"
  },
  {
    "name": "(GMT-02:00) Sao Paulo",
    "value": "America/Sao_Paulo"
  },
  {
    "name": "(GMT-03:00) Palmer",
    "value": "Antarctica/Palmer"
  },
  {
    "name": "(GMT-03:00) Rothera",
    "value": "Antarctica/Rothera"
  },
  {
    "name": "(GMT-03:00) Stanley",
    "value": "Atlantic/Stanley"
  },
  {
    "name": "(GMT-02:00) Noronha",
    "value": "America/Noronha"
  },
  {
    "name": "(GMT-02:00) South Georgia",
    "value": "Atlantic/South_Georgia"
  },
  {
    "name": "(GMT-01:00) Scoresbysund",
    "value": "America/Scoresbysund"
  },
  {
    "name": "(GMT-01:00) Azores",
    "value": "Atlantic/Azores"
  },
  {
    "name": "(GMT-01:00) Cape Verde",
    "value": "Atlantic/Cape_Verde"
  },
  {
    "name": "(GMT+00:00) Abidjan",
    "value": "Africa/Abidjan"
  },
  {
    "name": "(GMT+00:00) Accra",
    "value": "Africa/Accra"
  },
  {
    "name": "(GMT+00:00) Bissau",
    "value": "Africa/Bissau"
  },
  {
    "name": "(GMT+00:00) Casablanca",
    "value": "Africa/Casablanca"
  },
  {
    "name": "(GMT+00:00) El Aaiun",
    "value": "Africa/El_Aaiun"
  },
  {
    "name": "(GMT+00:00) Monrovia",
    "value": "Africa/Monrovia"
  },
  {
    "name": "(GMT+00:00) Danmarkshavn",
    "value": "America/Danmarkshavn"
  },
  {
    "name": "(GMT+00:00) Canary Islands",
    "value": "Atlantic/Canary"
  },
  {
    "name": "(GMT+00:00) Faeroe",
    "value": "Atlantic/Faroe"
  },
  {
    "name": "(GMT+00:00) Reykjavik",
    "value": "Atlantic/Reykjavik"
  },
  {
    "name": "(GMT+00:00) GMT (no daylight saving)",
    "value": "Etc/GMT"
  },
  {
    "name": "(GMT+00:00) Dublin",
    "value": "Europe/Dublin"
  },
  {
    "name": "(GMT+00:00) Lisbon",
    "value": "Europe/Lisbon"
  },
  {
    "name": "(GMT+00:00) London",
    "value": "Europe/London"
  },
  {
    "name": "(GMT+01:00) Algiers",
    "value": "Africa/Algiers"
  },
  {
    "name": "(GMT+01:00) Ceuta",
    "value": "Africa/Ceuta"
  },
  {
    "name": "(GMT+01:00) Lagos",
    "value": "Africa/Lagos"
  },
  {
    "name": "(GMT+01:00) Ndjamena",
    "value": "Africa/Ndjamena"
  },
  {
    "name": "(GMT+01:00) Tunis",
    "value": "Africa/Tunis"
  },
  {
    "name": "(GMT+02:00) Windhoek",
    "value": "Africa/Windhoek"
  },
  {
    "name": "(GMT+01:00) Amsterdam",
    "value": "Europe/Amsterdam"
  },
  {
    "name": "(GMT+01:00) Andorra",
    "value": "Europe/Andorra"
  },
  {
    "name": "(GMT+01:00) Central European Time - Belgrade",
    "value": "Europe/Belgrade"
  },
  {
    "name": "(GMT+01:00) Berlin",
    "value": "Europe/Berlin"
  },
  {
    "name": "(GMT+01:00) Brussels",
    "value": "Europe/Brussels"
  },
  {
    "name": "(GMT+01:00) Budapest",
    "value": "Europe/Budapest"
  },
  {
    "name": "(GMT+01:00) Copenhagen",
    "value": "Europe/Copenhagen"
  },
  {
    "name": "(GMT+01:00) Gibraltar",
    "value": "Europe/Gibraltar"
  },
  {
    "name": "(GMT+01:00) Luxembourg",
    "value": "Europe/Luxembourg"
  },
  {
    "name": "(GMT+01:00) Madrid",
    "value": "Europe/Madrid"
  },
  {
    "name": "(GMT+01:00) Malta",
    "value": "Europe/Malta"
  },
  {
    "name": "(GMT+01:00) Monaco",
    "value": "Europe/Monaco"
  },
  {
    "name": "(GMT+01:00) Oslo",
    "value": "Europe/Oslo"
  },
  {
    "name": "(GMT+01:00) Paris",
    "value": "Europe/Paris"
  },
  {
    "name": "(GMT+01:00) Central European Time - Prague",
    "value": "Europe/Prague"
  },
  {
    "name": "(GMT+01:00) Rome",
    "value": "Europe/Rome"
  },
  {
    "name": "(GMT+01:00) Stockholm",
    "value": "Europe/Stockholm"
  },
  {
    "name": "(GMT+01:00) Tirane",
    "value": "Europe/Tirane"
  },
  {
    "name": "(GMT+01:00) Vienna",
    "value": "Europe/Vienna"
  },
  {
    "name": "(GMT+01:00) Warsaw",
    "value": "Europe/Warsaw"
  },
  {
    "name": "(GMT+01:00) Zurich",
    "value": "Europe/Zurich"
  },
  {
    "name": "(GMT+02:00) Cairo",
    "value": "Africa/Cairo"
  },
  {
    "name": "(GMT+02:00) Johannesburg",
    "value": "Africa/Johannesburg"
  },
  {
    "name": "(GMT+02:00) Maputo",
    "value": "Africa/Maputo"
  },
  {
    "name": "(GMT+02:00) Tripoli",
    "value": "Africa/Tripoli"
  },
  {
    "name": "(GMT+02:00) Amman",
    "value": "Asia/Amman"
  },
  {
    "name": "(GMT+02:00) Beirut",
    "value": "Asia/Beirut"
  },
  {
    "name": "(GMT+02:00) Damascus",
    "value": "Asia/Damascus"
  },
  {
    "name": "(GMT+02:00) Gaza",
    "value": "Asia/Gaza"
  },
  {
    "name": "(GMT+02:00) Jerusalem",
    "value": "Asia/Jerusalem"
  },
  {
    "name": "(GMT+02:00) Nicosia",
    "value": "Asia/Nicosia"
  },
  {
    "name": "(GMT+02:00) Athens",
    "value": "Europe/Athens"
  },
  {
    "name": "(GMT+02:00) Bucharest",
    "value": "Europe/Bucharest"
  },
  {
    "name": "(GMT+02:00) Chisinau",
    "value": "Europe/Chisinau"
  },
  {
    "name": "(GMT+02:00) Helsinki",
    "value": "Europe/Helsinki"
  },
  {
    "name": "(GMT+02:00) Istanbul",
    "value": "Europe/Istanbul"
  },
  {
    "name": "(GMT+02:00) Moscow-01 - Kaliningrad",
    "value": "Europe/Kaliningrad"
  },
  {
    "name": "(GMT+02:00) Kiev",
    "value": "Europe/Kiev"
  },
  {
    "name": "(GMT+02:00) Riga",
    "value": "Europe/Riga"
  },
  {
    "name": "(GMT+02:00) Sofia",
    "value": "Europe/Sofia"
  },
  {
    "name": "(GMT+02:00) Tallinn",
    "value": "Europe/Tallinn"
  },
  {
    "name": "(GMT+02:00) Vilnius",
    "value": "Europe/Vilnius"
  },
  {
    "name": "(GMT+03:00) Khartoum",
    "value": "Africa/Khartoum"
  },
  {
    "name": "(GMT+03:00) Nairobi",
    "value": "Africa/Nairobi"
  },
  {
    "name": "(GMT+03:00) Syowa",
    "value": "Antarctica/Syowa"
  },
  {
    "name": "(GMT+03:00) Baghdad",
    "value": "Asia/Baghdad"
  },
  {
    "name": "(GMT+03:00) Qatar",
    "value": "Asia/Qatar"
  },
  {
    "name": "(GMT+03:00) Riyadh",
    "value": "Asia/Riyadh"
  },
  {
    "name": "(GMT+03:00) Minsk",
    "value": "Europe/Minsk"
  },
  {
    "name": "(GMT+03:00) Moscow+00 - Moscow",
    "value": "Europe/Moscow"
  },
  {
    "name": "(GMT+03:30) Tehran",
    "value": "Asia/Tehran"
  },
  {
    "name": "(GMT+04:00) Baku",
    "value": "Asia/Baku"
  },
  {
    "name": "(GMT+04:00) Dubai",
    "value": "Asia/Dubai"
  },
  {
    "name": "(GMT+04:00) Tbilisi",
    "value": "Asia/Tbilisi"
  },
  {
    "name": "(GMT+04:00) Yerevan",
    "value": "Asia/Yerevan"
  },
  {
    "name": "(GMT+04:00) Moscow+01 - Samara",
    "value": "Europe/Samara"
  },
  {
    "name": "(GMT+04:00) Mahe",
    "value": "Indian/Mahe"
  },
  {
    "name": "(GMT+04:00) Mauritius",
    "value": "Indian/Mauritius"
  },
  {
    "name": "(GMT+04:00) Reunion",
    "value": "Indian/Reunion"
  },
  {
    "name": "(GMT+04:30) Kabul",
    "value": "Asia/Kabul"
  },
  {
    "name": "(GMT+05:00) Mawson",
    "value": "Antarctica/Mawson"
  },
  {
    "name": "(GMT+05:00) Aqtau",
    "value": "Asia/Aqtau"
  },
  {
    "name": "(GMT+05:00) Aqtobe",
    "value": "Asia/Aqtobe"
  },
  {
    "name": "(GMT+05:00) Ashgabat",
    "value": "Asia/Ashgabat"
  },
  {
    "name": "(GMT+05:00) Dushanbe",
    "value": "Asia/Dushanbe"
  },
  {
    "name": "(GMT+05:00) Karachi",
    "value": "Asia/Karachi"
  },
  {
    "name": "(GMT+05:00) Tashkent",
    "value": "Asia/Tashkent"
  },
  {
    "name": "(GMT+05:00) Moscow+02 - Yekaterinburg",
    "value": "Asia/Yekaterinburg"
  },
  {
    "name": "(GMT+05:00) Kerguelen",
    "value": "Indian/Kerguelen"
  },
  {
    "name": "(GMT+05:00) Maldives",
    "value": "Indian/Maldives"
  },
  {
    "name": "(GMT+05:30) India Standard Time",
    "value": "Asia/Kolkata"
  },
  {
    "name": "(GMT+05:30) Colombo",
    "value": "Asia/Colombo"
  },
  {
    "name": "(GMT+05:45) Katmandu",
    "value": "Asia/Katmandu"
  },
  {
    "name": "(GMT+06:00) Vostok",
    "value": "Antarctica/Vostok"
  },
  {
    "name": "(GMT+06:00) Almaty",
    "value": "Asia/Almaty"
  },
  {
    "name": "(GMT+06:00) Bishkek",
    "value": "Asia/Bishkek"
  },
  {
    "name": "(GMT+06:00) Dhaka",
    "value": "Asia/Dhaka"
  },
  {
    "name": "(GMT+06:00) Moscow+03 - Omsk, Novosibirsk",
    "value": "Asia/Omsk"
  },
  {
    "name": "(GMT+06:00) Thimphu",
    "value": "Asia/Thimphu"
  },
  {
    "name": "(GMT+06:00) Chagos",
    "value": "Indian/Chagos"
  },
  {
    "name": "(GMT+06:30) Rangoon",
    "value": "Asia/Rangoon"
  },
  {
    "name": "(GMT+06:30) Cocos",
    "value": "Indian/Cocos"
  },
  {
    "name": "(GMT+07:00) Davis",
    "value": "Antarctica/Davis"
  },
  {
    "name": "(GMT+07:00) Bangkok",
    "value": "Asia/Bangkok"
  },
  {
    "name": "(GMT+07:00) Hovd",
    "value": "Asia/Hovd"
  },
  {
    "name": "(GMT+07:00) Jakarta",
    "value": "Asia/Jakarta"
  },
  {
    "name": "(GMT+07:00) Moscow+04 - Krasnoyarsk",
    "value": "Asia/Krasnoyarsk"
  },
  {
    "name": "(GMT+07:00) Hanoi",
    "value": "Asia/Saigon"
  },
  {
    "name": "(GMT+07:00) Ho Chi Minh",
    "value": "Asia/Ho_Chi_Minh"
  },
  {
    "name": "(GMT+07:00) Christmas",
    "value": "Indian/Christmas"
  },
  {
    "name": "(GMT+08:00) Casey",
    "value": "Antarctica/Casey"
  },
  {
    "name": "(GMT+08:00) Brunei",
    "value": "Asia/Brunei"
  },
  {
    "name": "(GMT+08:00) Choibalsan",
    "value": "Asia/Choibalsan"
  },
  {
    "name": "(GMT+08:00) Hong Kong",
    "value": "Asia/Hong_Kong"
  },
  {
    "name": "(GMT+08:00) Moscow+05 - Irkutsk",
    "value": "Asia/Irkutsk"
  },
  {
    "name": "(GMT+08:00) Kuala Lumpur",
    "value": "Asia/Kuala_Lumpur"
  },
  {
    "name": "(GMT+08:00) Macau",
    "value": "Asia/Macau"
  },
  {
    "name": "(GMT+08:00) Makassar",
    "value": "Asia/Makassar"
  },
  {
    "name": "(GMT+08:00) Manila",
    "value": "Asia/Manila"
  },
  {
    "name": "(GMT+08:00) China Time - Beijing",
    "value": "Asia/Shanghai"
  },
  {
    "name": "(GMT+08:00) Singapore",
    "value": "Asia/Singapore"
  },
  {
    "name": "(GMT+08:00) Taipei",
    "value": "Asia/Taipei"
  },
  {
    "name": "(GMT+08:00) Ulaanbaatar",
    "value": "Asia/Ulaanbaatar"
  },
  {
    "name": "(GMT+08:00) Western Time - Perth",
    "value": "Australia/Perth"
  },
  {
    "name": "(GMT+08:30) Pyongyang",
    "value": "Asia/Pyongyang"
  },
  {
    "name": "(GMT+09:00) Dili",
    "value": "Asia/Dili"
  },
  {
    "name": "(GMT+09:00) Jayapura",
    "value": "Asia/Jayapura"
  },
  {
    "name": "(GMT+09:00) Seoul",
    "value": "Asia/Seoul"
  },
  {
    "name": "(GMT+09:00) Tokyo",
    "value": "Asia/Tokyo"
  },
  {
    "name": "(GMT+09:00) Moscow+06 - Yakutsk",
    "value": "Asia/Yakutsk"
  },
  {
    "name": "(GMT+09:00) Palau",
    "value": "Pacific/Palau"
  },
  {
    "name": "(GMT+10:30) Central Time - Adelaide",
    "value": "Australia/Adelaide"
  },
  {
    "name": "(GMT+09:30) Central Time - Darwin",
    "value": "Australia/Darwin"
  },
  {
    "name": "(GMT+10:00) Dumont D'Urville",
    "value": "Antarctica/DumontDUrville"
  },
  {
    "name": "(GMT+10:00) Moscow+07 - Magadan",
    "value": "Asia/Magadan"
  },
  {
    "name": "(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk",
    "value": "Asia/Vladivostok"
  },
  {
    "name": "(GMT+10:00) Eastern Time - Brisbane",
    "value": "Australia/Brisbane"
  },
  {
    "name": "(GMT+11:00) Eastern Time - Hobart",
    "value": "Australia/Hobart"
  },
  {
    "name": "(GMT+11:00) Eastern Time - Melbourne, Sydney",
    "value": "Australia/Sydney"
  },
  {
    "name": "(GMT+10:00) Truk",
    "value": "Pacific/Chuuk"
  },
  {
    "name": "(GMT+10:00) Guam",
    "value": "Pacific/Guam"
  },
  {
    "name": "(GMT+10:00) Port Moresby",
    "value": "Pacific/Port_Moresby"
  },
  {
    "name": "(GMT+11:00) Efate",
    "value": "Pacific/Efate"
  },
  {
    "name": "(GMT+11:00) Guadalcanal",
    "value": "Pacific/Guadalcanal"
  },
  {
    "name": "(GMT+11:00) Kosrae",
    "value": "Pacific/Kosrae"
  },
  {
    "name": "(GMT+11:00) Norfolk",
    "value": "Pacific/Norfolk"
  },
  {
    "name": "(GMT+11:00) Noumea",
    "value": "Pacific/Noumea"
  },
  {
    "name": "(GMT+11:00) Ponape",
    "value": "Pacific/Pohnpei"
  },
  {
    "name": "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
    "value": "Asia/Kamchatka"
  },
  {
    "name": "(GMT+13:00) Auckland",
    "value": "Pacific/Auckland"
  },
  {
    "name": "(GMT+13:00) Fiji",
    "value": "Pacific/Fiji"
  },
  {
    "name": "(GMT+12:00) Funafuti",
    "value": "Pacific/Funafuti"
  },
  {
    "name": "(GMT+12:00) Kwajalein",
    "value": "Pacific/Kwajalein"
  },
  {
    "name": "(GMT+12:00) Majuro",
    "value": "Pacific/Majuro"
  },
  {
    "name": "(GMT+12:00) Nauru",
    "value": "Pacific/Nauru"
  },
  {
    "name": "(GMT+12:00) Tarawa",
    "value": "Pacific/Tarawa"
  },
  {
    "name": "(GMT+12:00) Wake",
    "value": "Pacific/Wake"
  },
  {
    "name": "(GMT+12:00) Wallis",
    "value": "Pacific/Wallis"
  },
  {
    "name": "(GMT+14:00) Apia",
    "value": "Pacific/Apia"
  },
  {
    "name": "(GMT+13:00) Enderbury",
    "value": "Pacific/Enderbury"
  },
  {
    "name": "(GMT+13:00) Fakaofo",
    "value": "Pacific/Fakaofo"
  },
  {
    "name": "(GMT+13:00) Tongatapu",
    "value": "Pacific/Tongatapu"
  },
  {
    "name": "(GMT+14:00) Kiritimati",
    "value": "Pacific/Kiritimati"
  }
];
