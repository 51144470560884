import React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../store";

// Authorization HOC
export const Authorization = (allowedRoles?: string[]) => (
  SecondaryComponent: React.ElementType
) => (MainComponent: React.ElementType) => {
  return function Authorization({ ...props }) {
    const userReducer = useSelector((state: IAppState) => state.userReducer);
    if (
      (allowedRoles && allowedRoles.includes(userReducer.role)) ||
      !allowedRoles
    ) {
      if (["Subscription Agreement", "Subscription Plans"].includes(props.name) && !userReducer.currentTenancy.carSubscriptionEnabled) {
        return <SecondaryComponent />
      }
      if (props.name === "Penalty Charge Notice" && !userReducer.currentTenancy.transfer360Enabled) {
        return <SecondaryComponent />
      }
      if (props.name === "Vehicle Rate Cards" && !userReducer.currentTenancy.rateCardsEnabled) {
        return <SecondaryComponent />
      }
      if (props.name === "Addon Rate Cards" && !userReducer.currentTenancy.rateCardsEnabled) {
        return <SecondaryComponent />
      }
      return <MainComponent {...props} />;
    }
    return <SecondaryComponent />;
  };
};

export const Null = () => {
  return <></>;
};

export const AccessDenied = () => {
  return (
    <>
      <h1>Access denied</h1>
    </>
  );
};

export enum UserRoles {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  ORGANISATION_OWNER = "ORGANISATION_OWNER",
  BRANCH_MANAGER = "BRANCH_MANAGER",
  FLEET_MANAGER = "FLEET_MANAGER",
  BOOKING_AGENT = "BOOKING_AGENT",
  FINANCE_MANAGER = "FINANCE_MANAGER",
  ACCOUNT_MANAGER = "ACCOUNT_MANAGER"
}

export const UserRolesValue: { [key in UserRoles]: string } = {
  SUPER_ADMIN: "Super Admin",
  ADMIN: "Admin",
  ORGANISATION_OWNER: "Organisation Owner",
  BRANCH_MANAGER: "Branch Manager",
  BOOKING_AGENT: "Booking Agent",
  FLEET_MANAGER: "Fleet Manager",
  FINANCE_MANAGER: "Finance Manager",
  ACCOUNT_MANAGER: "Account Manager"
};
export const ADMIN_ALLOWED = [UserRoles.ADMIN, UserRoles.SUPER_ADMIN];
export const ORGANISATION_OWNER_ALLOWED = [
  UserRoles.SUPER_ADMIN,
  UserRoles.ADMIN,
  UserRoles.ORGANISATION_OWNER
];
export const BRANCH_MANAGER_ALLOWED = [
  UserRoles.SUPER_ADMIN,
  UserRoles.ADMIN,
  UserRoles.ORGANISATION_OWNER,
  UserRoles.BRANCH_MANAGER
];
export const FLEET_MANAGER_ALLOWED = [
  UserRoles.SUPER_ADMIN,
  UserRoles.ADMIN,
  UserRoles.ORGANISATION_OWNER,
  UserRoles.BRANCH_MANAGER,
  UserRoles.FLEET_MANAGER,
  UserRoles.FINANCE_MANAGER
];
export const BOOKING_AGENT_ALLOWED = [
  UserRoles.SUPER_ADMIN,
  UserRoles.ADMIN,
  UserRoles.ORGANISATION_OWNER,
  UserRoles.BRANCH_MANAGER,
  UserRoles.BOOKING_AGENT
];
export const BRANCH_FLEET_FINANCE_MANAGER_ALLOWED = [
  UserRoles.SUPER_ADMIN,
  UserRoles.ADMIN,
  UserRoles.ORGANISATION_OWNER,
  UserRoles.BRANCH_MANAGER,
  UserRoles.FINANCE_MANAGER,
  UserRoles.FLEET_MANAGER
]

export const NullAdmin = Authorization(ADMIN_ALLOWED)(Null);
export const NullOrganisatioOwner = Authorization(ORGANISATION_OWNER_ALLOWED)(
  Null
);
export const NullBranchManager = Authorization(BRANCH_MANAGER_ALLOWED)(Null);
export const NullFleetManager = Authorization(FLEET_MANAGER_ALLOWED)(Null);
export const NullBookingAgent = Authorization(BOOKING_AGENT_ALLOWED)(Null);

export const AccessDeniedAdmin = Authorization(ADMIN_ALLOWED)(AccessDenied);
export const AccessDeniedOrganisatioOwner = Authorization(
  ORGANISATION_OWNER_ALLOWED
)(AccessDenied);
export const AccessDeniedBranchManager = Authorization(BRANCH_MANAGER_ALLOWED)(
  AccessDenied
);
export const AccessDeniedFleetManager = Authorization(FLEET_MANAGER_ALLOWED)(
  AccessDenied
);
export const AccessDeniedBookingAgent = Authorization(BOOKING_AGENT_ALLOWED)(
  AccessDenied
);
