import { gql } from "@apollo/client";

export const SEARCH_BUSINESS_CUSTOMER = gql`
  query searchBusinessCustomer($q: String!) {
    searchBusinessCustomer(q: $q) {
      id
      businessName
      VAT
      status
      contact {
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
    }
  }
`;