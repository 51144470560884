import React, { useEffect, useState } from "react";

import { MUIDataTableOptions } from "mui-datatables";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder, toCurrency } from "../../common/utils";
import { GET_ALL_PENDING_DEPOSITS } from "../../../graphql/pendingDeposits/getAllPendingDepositsQuery";
import { NuvvenTable } from "../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../common/NuvvenTable/types";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../utils/localized.syntex";
import { GET_PENDING_DEPOSITS_COUNT } from "../../../graphql/pendingDeposits/getPendingDepositCount";
import { ITable, TableNames } from "../../../reducers/tableState/types";
import _ from "lodash";
import { addTableState } from "../../../actions/tableState/actions";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { IBooking } from "../../../reducers/bookings/types";
import { SEARCH_ALL_PENDING_DEPOSITS } from "../../../graphql/pendingDeposits/searchPendingDeposits";
import { UserRoles } from "../../hoc/Authorization";

const Deposits: React.FC = () => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [rows, setRows] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [selection, setSelection] = useState<any[]>([]);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [totalDepositCount, setTotalDepositCount] = useState<number>(0);
  const depositsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.deposits
  );
  const [depositsTable, setDepositsTable] = useState<ITable>();
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [selectedDeposit, setSelectedDeposit] = useState<any>();
  const dispatch = useDispatch();
  const [deposits, setDeposits] = useState<IBooking[]>([]);

  const [loadDeposits, { loading: depositsLoading, data }] =
    useLazyQuery(GET_ALL_PENDING_DEPOSITS, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data && data.pendingDeposits) {
          const oldDeposits = [...rows];
          const newDeposits = reshapeBookingIntoRows(data.pendingDeposits);
          const depositsArray = _.union(oldDeposits, newDeposits);
          setDeposits(depositsArray);
          setRows(depositsArray);
        }
      },
      onError: (error) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR,
        });
      },
    });

  const [getDepositsCount] = useLazyQuery(
    GET_PENDING_DEPOSITS_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (depositsCountData) => {setTotalDepositCount(depositsCountData.pendingDepositsCount)},
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );
  const [searchDeposits, { loading: searchDepositsLoading, data: searchDepositsData }] = useLazyQuery(
    SEARCH_ALL_PENDING_DEPOSITS,
    {
      fetchPolicy: "network-only",
      onCompleted: (searchDepositsData) => {
        if (searchDepositsData && searchDepositsData.searchDeposits) {
          const oldDeposits = [...rows];
          let deposits = reshapeBookingIntoRows(searchDepositsData.searchDeposits)
          const depositsArray = _.union(oldDeposits, deposits);
          setDeposits(depositsArray);
          setTotalDepositCount(deposits.length)
          setRows(depositsArray);
        }
      }
    }
  );

  const columns = [
    {
      label: `${getLocalizedBookingSyntex(country)} ID`,
      name: "referenceNumber",
      options: {
        filter: false,
      },
    },
    {
      label: "Customer",
      name: "createdBy",
    },
    {
      label: "Pick-up Date",
      name: "pickupDateTime",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string) => {
          if (value) {
            return getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
          } else {
            return "-"
          }
        }
      }
    },
    {
      label: "Drop-off Date",
      name: "dropoffDateTime",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string) => {
          if (value) {
            return getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
          } else {
            return "-"
          }
        }
      }
    },
    {
      label: "Deposit",
      name: "initialDeposit",
      options: {
        filter: false,
        customBodyRender: (value: number) => {
          if (value) {
            return toCurrency(value, currency, locale)
          }
          return "-"
        }
      }
    },
    {
      label: "Deductions",
      name: "deductions",
      options: {
        filter: false,
        customBodyRender: (value: number) => {
          if (value) {
            return toCurrency(value, currency, locale)
          }
          return "-"
        }
      }
    },
    {
      label: "Refundable",
      name: "remainingPendingDeposit",
      options: {
        filter: false,
        customBodyRender: (value: number) => {
          if (value) {
            return toCurrency(value, currency, locale)
          }
          return "-"
        }
      }
    },
    {
      label: "Deposit Status",
      name: "depositStatus",
      options: {
        filter: false
      }
    },
  ];

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchDeposits({
        variables: {
          q: searchText,
          limit,
          offset:0
        }
      })
    }
  }, 500);

  const reshapeBookingIntoRows = (bookings: any[]) => {
    const depositDetails: any[] = []

    bookings.forEach((booking: any) => {
      const customer = booking.businessCustomer
        ? booking.businessCustomer.businessName
        : booking.customer
          ? booking.customer.firstName + " " + booking.customer.lastName
          : "";
      depositDetails.push({
        id: booking.id,
        referenceNumber: booking.referenceNumber,
        createdBy: customer,
        pickupDateTime: booking.pickupDateTime,
        dropoffDateTime: booking.dropoffDateTime,
        initialDeposit: booking.depositDetails?.amount,
        deductions: booking.depositDetails?.amountAdjusted,
        remainingPendingDeposit: booking.depositDetails?.amount - booking.depositDetails?.amountAdjusted,
        depositStatus: "Pending Refund",
      })
    })
    return depositDetails
  };

  useEffect(() => {
    if (depositsTableState) {
      setDepositsTable(depositsTableState);
      let sortedOrder = returnSortedOrder(depositsTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, depositsTableState, sortedOrder)
      }
    }
  }, [depositsTableState]);

  useEffect(() => {
    if (userState.tenancy) {
      getDepositsCount();
      loadDeposits({
        variables: {
          limit: depositsTableState?.rowsPerPage ? depositsTableState.rowsPerPage : limit,
          offset: 0,
        },
      });
    }
  }, [userState]);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: totalDepositCount,
    pagination: !depositsLoading,
    rowsPerPageOptions: [10, 20, 100],
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return;
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length) {
            loadDeposits({
              variables: {
                limit: rowsPerPage,
                offset: page * rowsPerPage,
              },
            });
          }
          break;
        case "changeRowsPerPage":
          setRows([]);
          loadDeposits({
            variables: {
              limit: tableState.rowsPerPage || limit,
              offset: 0,
            },
          });
          break;
        default:
          break;
      }
    },
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: depositsLoading || searchDepositsLoading
          ? "Loading..."
          : "Sorry, there is no matching data to display",
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTER",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (rowData: string[]) => {
      if (rowData && rowData.length) {
        const depositClicked = deposits.find(
          (row: any) => row.referenceNumber === rowData[0]
        );
        if (depositClicked) {
          navigate(`/view-booking?booking=${depositClicked.id}`);
          setSelectedDeposit(depositClicked); // Store selected deposit in state
        }
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.DEPOSITS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      depositsTable && depositsTable.rowsPerPage
        ? depositsTable.rowsPerPage
        : limit,

    onTableInit: (action: string) => {
      if (depositsTable && sortOrder) {
        setTableSortOrder(columns, depositsTable, sortOrder);
      }
      if (depositsTable && depositsTable.rowsPerPage) {
        setLimit(depositsTable.rowsPerPage);
      }
    },
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText)
      } else {
        setRows([])
        getDepositsCount()
        loadDeposits({
          variables: {
            limit: limit,
            offset: 0
          }
        })
      }
    },

  };

  return (
    <Grid container spacing={2} xs={12}>
      <>
        <Grid container item xs={4}>
          <Typography variant="h1" color="primary">
            Pending Deposits
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          {!(depositsLoading && !rows.length) ? (
            <NuvvenTable
              title={""}
              rows={rows}
              columns={columns}
              setSelection={setSelection}
              options={options}
            />
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </>
    </Grid>
  );
};

export default Deposits;
