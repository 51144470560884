import React, { useState, useCallback, useEffect } from 'react';
import styles from './index.module.css';
import Flatpickr from "react-flatpickr";
import { DateTime as d } from 'luxon';
import VehicleSelection, { ISelectedVehicle, IVehicleGroupData } from '../Trips/VehicleSelection';
import Typography from '@mui/material/Typography';
import { useLazyQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { GET_CRASHBOX_EVENTS } from '../../../../graphql/fleet/telematicAlertsQuery';
import { Document, Page } from "react-pdf";
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { IVehicle } from '../../../../reducers/fleet/types';
import { getLocalizedDateFormat } from '../../../../utils/localized.syntex';
import { DATE_TYPE } from '../../../common/utils';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';

interface IProps {
  active?: boolean;
  vehicles: IVehicle[];
  loadingVehicleGroups: boolean;
  vehicleGroups: IVehicleGroupData[];
}

interface ICrashEvent {
  id: string;
  pdf: string;
  pdof: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  severity: string;
  crashDirection: string;
  crashId: string;
  datetime: string;
}

interface ICrashReport {
  deviceEsn: string;
  crashes: ICrashEvent[]
}


const CrashReport: React.FC<IProps> = ({ active, vehicleGroups }) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const [filters, setfilters] = useState<{ startDate: string, endDate: string }>({ startDate: d.now().minus({ days: 7 }).toUTC().toISO(), endDate: d.now().toUTC().toISO() });
  const [selectedVehicles, setSelectedVehicles] = useState<ISelectedVehicle[]>([]);
  const [crashReports, setCrashReports] = useState<ICrashReport[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<ICrashEvent | undefined>();
  const [numPages, setNumPages] = useState(null);
  const [pdf, setPdf] = useState<string>("");
  const [downloading, setDownloading] = useState<string | undefined>();

  const [loadEvents,
    { loading: eventsLoading, data: eventsData }
  ] = useLazyQuery(
    GET_CRASHBOX_EVENTS, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (filters.startDate && filters.endDate) {
      setSelectedEvent(undefined);
      setCrashReports([]);
      loadEvents({
        variables: {
          deviceEsn: selectedVehicles.map((vehicle) => vehicle.deviceEsn),
          startDate: filters.startDate,
          endDate: filters.endDate
        }
      });
    }
  }, [selectedVehicles, filters.endDate]);

  useEffect(() => {
    if (eventsData && eventsData.telematicCrashboxEvents) {
      setCrashReports(eventsData.telematicCrashboxEvents);
    }
  }, [eventsData])

  const handleSetVehicles = useCallback((vehicles: ISelectedVehicle[]) => {
    setSelectedVehicles(vehicles);
    setSelectedEvent(undefined);
    setCrashReports([]);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  }
  useEffect(() => {
    if (selectedEvent) {
      getPdf(selectedEvent.pdf);
    }
  }, [selectedEvent]);

  async function getPdf(pdfUrl: string) {
    const file = await getSignedUrl(pdfUrl)
    setPdf(file);
  }


  async function download(pdf: any) {
    setDownloading(pdf)
    const file = await getSignedUrl(pdf)
    fetch(file, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `crash-report-${pdf}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setDownloading(undefined);
      });
  }

  return (
    <div className={styles.wrapper} style={{ display: active ? 'block' : 'none' }}>
      <div className="flex fill" style={{ margin: "-30px -20px 0 -20px", height: "calc(100vh - 110px)", overflow: "hidden" }}>
        <div className={styles.leftColumn}>
          {vehicleGroups.length > 0 ? <VehicleSelection selectedVehicles={selectedVehicles} vehicleGroups={vehicleGroups} setVehicles={handleSetVehicles} /> : (
            <div className='flex main-center cross-center' style={{ backgroundColor: "White", paddingLeft: 16, marginLeft: 20 }}>
              <p>Either access denied or no vehicle with telematic data found</p>
            </div>
          )}
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.vehicleResults}>
            <div className="flex space-between">
              <div style={{ position: "relative", width: 150 }}>
                <label htmlFor={`CRASH_REPORT_DATE_RANGE`} style={{ minWidth: 140 }} className={styles.filterInput}>{filters.startDate ? getLocalizedDateFormat(country, filters.startDate, DATE_TYPE.CONDENSED) : "From date"} to {filters.endDate ? getLocalizedDateFormat(country, filters.endDate, DATE_TYPE.CONDENSED) : "To date"}</label>
                <div style={{ position: "absolute", left: 0, right: 0, }}>
                  <Flatpickr
                    options={{
                      mode: "range",
                      static: true,
                      minuteIncrement: 1,
                      dateFormat: country === "United States" ? "m/d/Y" : "d/m/Y",
                      maxDate: d.now().toUTC().toISO()
                    }}
                    onChange={(dates) => setfilters({ ...filters, startDate: d.fromFormat(`${dates[0].getFullYear()}/${dates[0].getMonth() + 1}/${dates[0].getDate()}`, "yyyy/M/d").toUTC().toISO(), endDate: dates[1] ? d.fromFormat(`${dates[1].getFullYear()}/${dates[1].getMonth() + 1}/${dates[1].getDate()}`, "yyyy/M/d").toUTC().toISO() : "" })
                    }
                    render={
                      ({ defaultValue, value, ...props }: any, ref: any) => (
                        <input id={`CRASH_REPORT_DATE_RANGE`} {...props} defaultValue={defaultValue} ref={ref} style={{ height: 0, width: 0, overflow: "hidden", opacity: 0, position: "absolute", left: -100, top: 30 }} />
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="margin-top">
              {eventsLoading ? <CircularProgress size={20} thickness={5} /> : crashReports.length ? crashReports.map((report) => (
                report.crashes.map(event => (
                  <div
                    key={event.id}
                    className={`${styles.result} ${selectedEvent?.id === event.id ? styles.selected : ""} `}
                    onClick={() => {
                      setSelectedEvent(event);
                    }}
                  >
                    <div className="flex fill col-flex">
                      <Typography variant="body1" className="flex fill">
                        <span className="flex fill bold">{selectedVehicles.find((vehicle) => vehicle.deviceEsn === report.deviceEsn)?.licencePlate || ""}</span>
                      </Typography>
                      <div className="flex cross-center" style={{ marginTop: 5 }}>
                        <span className="flex cross-start">
                          {d.fromISO(event.datetime).toFormat("dd/MM/yyyy, hh:mma")}
                        </span>
                      </div>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!downloading)
                            download(event.pdf)
                        }}
                      >
                        {downloading === event.pdf ? <CircularProgress size={14} /> : <Typography variant="body2" style={{ textTransform: "initial" }}>Download</Typography>}
                      </Button>
                    </div>
                  </div>
                ))
              )) : <div className={`${styles.result} `}>
                <Typography variant="body2">Select Vehicles from left panel to view data</Typography>
              </div>}
            </div>
          </div>
          {selectedEvent && <div className={styles.rightSub}>
            <div className={`${styles.crashBoxpdf}`}>
              {pdf && (
                <Document
                  file={pdf}
                  options={{ cMapUrl: "/pdf.worker.js" }}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={<CircularProgress size={26} thickness={5} />}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              )}
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default CrashReport

