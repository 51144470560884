import { gql } from "@apollo/client";

// The data returned should be same as GET_BOOKING query
export const CREATE_BOOKING_EXTENSION = gql`
  mutation extendBooking($extensionData: ExtendBookingInput!) {
    extendBooking(extensionData: $extensionData) {
      id
      referenceNumber
      extensionNumber
      status
      startDate
      endDate
      dropoffServiceLocation {
        id
        name
      }
      subscriptionDetails {
        subscriptionAmount
        modifiedSubscriptionAmount
        vehicleId
      }
      insuranceRate
      insurancePolicy {
        id
        name
        description
      }
      vehicleDetails {
        vehicleGroupId
        vehicleIds
        basePrice
        priceRuleAmount
        quantity
        modifiedPrice
        description
      }
      paymentDetails {
        rentalAmount
        startDate
        endDate
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        totalPayableAmount
        rentalTime
      }
      addonRequirements {
        rate
        quantity
        displayName
        hasFixedRate
        tax {
          title
          value
        }
      }
      otherCharges {
        description
        quantity
        unitPrice
      }
    }
  }
`;
