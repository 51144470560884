export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const DELETE_VEHICLE = "DELETE_VEHICLE";
export const RESET_VEHICLE = "RESET_VEHICLE";
export const UPDATE_VEHICLE_SERVICE = "UPDATE_VEHICLE_SERVICE";
export const UPDATE_VEHICLE_FINANCE = "UPDATE_VEHICLE_FINANCE";
export const RESET_VEHICLE_SERVICE = "RESET_VEHICLE_SERVICE";
export const RESET_VEHICLE_FINANCE = "RESET_VEHICLE_FINANCE";
export const UPDATE_VEHICLE_DAMAGE = "UPDATE_VEHICLE_DAMAGE";
export const RESET_VEHICLE_DAMAGE = "RESET_VEHICLE_DAMAGE";
interface IUpdateVehicle {
  type: typeof UPDATE_VEHICLE;
  payload: any;
}

interface IDeleteVehicle {
  type: typeof DELETE_VEHICLE;
  payload: any;
}

interface IResetVehicle {
  type: typeof RESET_VEHICLE;
}

interface IResetVehicleService {
  type: typeof RESET_VEHICLE_SERVICE;
}
interface IUpdateVehicleService {
  type: typeof UPDATE_VEHICLE_SERVICE;
}

interface IUpdateVehicleDamage {
  type: typeof UPDATE_VEHICLE_DAMAGE;
  payload: any;
}
interface IResetVehicleDamage {
  type: typeof RESET_VEHICLE_DAMAGE;
}

interface IResetVehicleFinance {
  type: typeof RESET_VEHICLE_FINANCE;
}
interface IUpdateVehicleFinance {
  type: typeof UPDATE_VEHICLE_FINANCE;
}

export type VehicleAction = IUpdateVehicle | IDeleteVehicle | IResetVehicle;

export type VehicleServiceAction = IUpdateVehicleService | IResetVehicleService;

export type VehicleDamageAction = IUpdateVehicleDamage | IResetVehicleDamage;

export type VehicleFinanceAction = IUpdateVehicleFinance | IResetVehicleFinance;
