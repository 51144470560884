import { MenuItem, TextField as InputField, } from "@mui/material";
import React, { useMemo } from "react";
import { DateTime as d } from 'luxon';

interface IProps {
  setTime: (time: string) => void;
  time: string;
  is24Hour?: boolean;
  placeholder?: string;
  label?: string;
}

export const TimePicker: React.FC<IProps> = (props) => {
  const { setTime, time } = props;
  const displayFormat = props.is24Hour ? "HH:mm" : "hh:mm a";
  const timeRange = useMemo(() => {
    return [...new Array(96)].map((_, index) => {
      return {
        index: index,
        formatted: d.now().startOf("day").plus({ minutes: index * 15 }).toFormat(displayFormat)
      }
    })
  }, [])

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const time = d.now().startOf('day').plus({ minutes: parseInt(event.target.value) }).toFormat("HH:mm")
    setTime(time)
  };
  return (
    <InputField
      label={props.label}
      select
      value={d.fromISO(time).hour * 60 + d.fromISO(time).minute}
      placeholder={props.placeholder}
      InputProps={{
        onChange: handleTimeChange
      }}
      InputLabelProps={{

      }}
      fullWidth
    >
      {timeRange.map((item, i) => (
        <MenuItem value={item.index * 15} key={i}>
          {item.formatted}
        </MenuItem>
      ))}
    </InputField>
  );
};
