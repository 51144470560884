import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { IVehicleDamage, getLocalizedVehicleDamageCircumstancesValue } from "../../../../../reducers/damageVehicle/types";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { VehicleDamageCircumstance } from "../../Issues&Damages/constants";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../../common/utils";
import { Paper } from "@mui/material";

interface IDamageTalbeProps {
  vehicleDamages: any[];
  selectedDamages: any[];
  damagesSelectionHandler: (selectedIndex: number[]) => void;
}

interface IColumns {
  label: string;
  name: string;
  options?: object;
}

interface IRowSelect {
  index: number;
  dataIndex: number;
}

export const DamagesTable = (props: IDamageTalbeProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [rows, setRows] = useState<IVehicleDamage[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const selectedDamageIndex: number[] = [];

  useEffect(() => {
    if (props.vehicleDamages) {
      const reshapedRowData = props.vehicleDamages.map((item) => {
        return {
          ...item,
          descriptions: item.damages?.length ? item.damages[0].descriptions : ""
        }
      })
      setRows(reshapedRowData);
    }
    props.vehicleDamages.map((damage, index) => {
      if (damage && damage.id) {
        props.selectedDamages.forEach((selectedDamage) => {
          if (typeof selectedDamage === "object" && selectedDamage && damage.id === selectedDamage.id) {
            selectedDamageIndex.push(index);
          }
        });
      }
    });
    setSelectedRows(selectedDamageIndex);
  }, [props.vehicleDamages, props.selectedDamages]);

  const options: MUIDataTableOptions = {
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row = rows[rowMeta.rowIndex];
    },
    elevation: 0,
    onRowSelectionChange: (currentRowsSelected: IRowSelect[], allRows: IRowSelect[]) => {
      setSelectedRows(allRows.map(row => row.dataIndex));
      let selectedRowIndex = currentRowsSelected.map((row) => row.index);
      props.damagesSelectionHandler(selectedRowIndex);
    },
    selectableRows: "multiple",
    rowsSelected: selectedRows,
  };

  const columns: IColumns[] = [
    {
      label: "Reference No.",
      name: "referenceNumber"
    },
    {
      label: "Description",
      name: "descriptions"
    },
    {
      label: "Circumstance",
      name: "circumstance",
      options: {
        customBodyRender: (value: VehicleDamageCircumstance) => getLocalizedVehicleDamageCircumstancesValue(value, country)
      }
    },
    {
      label: "Reported Date & Time",
      name: "reportDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    }
  ];

  return (
    <div style={{ width: "100%" }}>
      <Paper variant='outlined'>
        <NuvvenTable
          title=""
          rows={rows}
          columns={columns}
          options={options}
          setSelection={() => { }}
        />
      </Paper>
    </div>
  );
}