import {
  Grid,
  ButtonBase,
  Card,
  Box,
  Typography,
  Collapse
} from "@mui/material";
import CardContent from '@mui/material/CardContent';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IVehicleService } from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { DATE_TYPE, toCurrency } from "../../../../../common/utils";
import { ServiceRowDetail } from "./ServiceRowDetail";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";

interface IProps {
  row: IVehicleService;
  index: number;
}

export const MobileServiceCard = (props: IProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [expanded, setExpanded] = useState<boolean>(false);
  const { locale, currency } = userState.currentOrganisation;

  const borderColour = "rgba(0, 0, 0, 1)"

  return (
    <Card style={{
      width: 'calc(100vw - 120px)',
      border: "2px solid",
      borderColor: `${borderColour}`,
      borderRadius: "3%",
      marginTop: 8,
      marginBottom: 8
    }}>
      <ButtonBase onClick={() => {
        if (expanded) {
          setExpanded(false);
        } else {
          setExpanded(true);
        }
      }} >
        <CardContent>
          <Grid container item xs={12} justifyContent='center'>
            <Grid item xs={6}>
              <Typography>Start Date:</Typography>
              <Typography>{getLocalizedDateFormat(country, props.row.startDate, DATE_TYPE.CONDENSED)}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography>Completion Date:</Typography>
              <Typography>{getLocalizedDateFormat(country, props.row.completionDate, DATE_TYPE.CONDENSED)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mt={1} />
            </Grid>
            <Grid item xs={6}>
              <Typography>Service Type:</Typography>
              <Typography>{props.row.serviceType}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Status:</Typography>
              <Typography>{props.row.status}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mt={1} />
            </Grid>
            <Grid item xs={12}>
              <Typography>Cost:</Typography>
              <Typography>{toCurrency(props.row.totalExpenses, currency, locale)}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </ButtonBase>
      <Collapse in={expanded} unmountOnExit>
        <ServiceRowDetail row={props.row} />
      </Collapse>
    </Card>

  );
};
