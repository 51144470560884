import { gql } from "@apollo/client";

export const SEND_QUOTE = gql`
  mutation sendQuote($id: String!, $additionalMails: [String]) {
    sendQuote(id: $id, additionalMails: $additionalMails)
  }
`;

export const SEND_BOOKING_CONFIRMATION_EMAIL = gql`
  mutation sendConfirmBookingEmail($bookingId: String!,$additionalMails: [String]) {
    sendConfirmBookingEmail(bookingId: $bookingId,additionalMails: $additionalMails)
  }
`;