import { Grid, Typography } from "@mui/material";
import React from "react";
import { useVirtualizer } from '@tanstack/react-virtual';
import MobileOrganisationCard from "../../../../../common/MobileOrganisationCard";


export const OrganisationStructureVirtualScroller = ({ data }: { data: any[] }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 182
  })

  if (!data.length) {
    return <Typography>Sorry no matching record found</Typography>
  }

  return (
    <>
      {/* Scrollable Parent List  */}
      <Grid item xs={12}
        ref={parentRef}
        className="List"
        style={{
          height: `calc(100vh - 250px)`,
          width: 'calc(100vw - 60px)',
          overflow: 'auto',
        }}
      >
        {/* Large inner element for items */}
        <Grid container item xs={12} spacing={2}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {/* Virtualized Items */}
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <Grid container item xs={12} spacing={2}
              key={virtualItem.key}
              style={{
                position: 'absolute',
                width: '100%',
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <MobileOrganisationCard
                id={data[virtualItem.index].id}
                name={data[virtualItem.index].name}
                country={data[virtualItem.index].countryName}
                email={data[virtualItem.index].emailId}
                contact={data[virtualItem.index].contact}
                totalBranch={data[virtualItem.index].totalBranches}
                currency={data[virtualItem.index].currency} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default OrganisationStructureVirtualScroller;