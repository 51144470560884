import { gql } from "@apollo/client";

export const GET_AVAILABLE_ADDON_TYPES = gql`
  query availableAddons($startDate: String!, $endDate: String!, $bookingId: String, $rateTypeName: String, $businessCustomer: String) {
    availableAddons(startDate: $startDate, endDate: $endDate, bookingId: $bookingId, rateTypeName: $rateTypeName, businessCustomer: $businessCustomer) {
      id
      name
      category
      displayName
      imageUrl
      hasFixedRate
      hasQuantity
      fixedRate
      description
      recurringRate {
        rateTypeName
        rateTypeDuration
        rate
        longTerm
      }
      totalQuantity
      availableQuantity
      isActivated
      taxable
      tax {
        title
        type
        value
      }
    }
  }
`;
