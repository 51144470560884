import { ICommonItemFields, IProcurementRequestItemInput } from "./types";

export const procurementRequestInitialState: IProcurementRequestItemInput = {
	assetType: "",
	condition: "",
	expectedByDate: "",
	fuel: "",
	make: "",
	model: "",
	note: {
		createdAt: "",
		createdBy: "",
		description: ""
	},
	quantity: 0,
	requestId: "",
	status: "",
	transmission: "",
	variant: ""
}

export const itemCommonInitials: ICommonItemFields = {
	documents: [],
	vehicleCondition: "",
	vehicleCount: 0,
	vehicleExpectedByDate: "",
	vehicleFuel: "",
	vehicleMake: "",
	vehicleModel: "",
	vehicleTransmission: "",
	vehicleVarient: ""
}
