import { gql } from "@apollo/client";

export const UPDATE_ADDON_RATE_CARD = gql`
  mutation updateAddonRateCard($id: String!, $businessCustomers: [String]) {
    updateAddonRateCard(id: $id, businessCustomers: $businessCustomers) {
      id
      name
      isActive
      isDefault
      displayName
      dateUpdated
      lastUpdatedBy {
        firstName
        lastName
      }
      businessCustomers {
        businessName
        id
      }
      addons {
        name
        pricing {
          rangeStart
          rangeEnd
          unitPrice
        }
        addon {
          id
          name
        }
      }
    }
  }
`;
