import React, { FC, useEffect, useState } from "react";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography,
  Box,
  Paper,
  Theme,
  TextField as InputField
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import * as Yup from "yup";

import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  formatGraphQLErrorMessage
} from "../../../../common/utils";
import { useSelector } from "react-redux";
import { AssetTypeInitialValue, IAssetType } from "../../../Assets/asset.types";
import { IAppState } from "../../../../../store";
import _ from "lodash";
import { GET_ASSET_TYPE } from "../../../Assets/Graphql/assetTypeQueries";
import { CREATE_ASSET_TYPE, UPDATE_ASSET_TYPE } from "../../../Assets/Graphql/assetTypeMutations";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    form: {
      flexGrow: 1
    }
  })
);

export const NewAssetTypes: FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const [values, setValues] = useState<IAssetType>(AssetTypeInitialValue);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);


  const [getAssetType, { loading: loadingAssetType, data: assetTypeData }] = useLazyQuery(GET_ASSET_TYPE, {
    fetchPolicy: "network-only",
    onCompleted: ({ assetTypeById }) => {
      if (!assetTypeById) {
        navigate("/asset-types");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
      navigate("/asset-types");
    }
  });

  const [createAssetType, { loading: createAssetTypeLoading }] = useMutation(CREATE_ASSET_TYPE, {
    onCompleted: () => {
      snackbar({
        message: formatGraphQLErrorMessage("Asset Type created"),
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/asset-types");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  });

  const [updateAssetType, { loading: updateAssetTypeLoading }] = useMutation(UPDATE_ASSET_TYPE, {
    onCompleted: () => {
      snackbar({
        message: formatGraphQLErrorMessage("Asset Type updated"),
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const id = params.get("id");
      if (id) {
        setIsUpdate(true);
        getAssetType({
          variables: {
            id
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (assetTypeData && assetTypeData.assetTypeById) {
      setValues({
        ...assetTypeData.assetTypeById
      });
    }
  }, [assetTypeData]);

  const handleFormSubmit = (values: IAssetType) => {
    if (!values.customFields.length) {
      return snackbar({
        message: "Atleast one custom field should be added.",
        variant: SnackBarVariant.ERROR
      });
    }
    const { id, dateCreated, ...rest } = values
    if (values.id) {
      updateAssetType({
        variables: {
          assetTypeId: values.id,
          assetType: rest
        }
      });
    } else {
      createAssetType({
        variables: {
          assetType: rest
        }
      });
    }
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          Asset Types{"  "}
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>
        {!isUpdate ? (
          <Typography variant="h1" color="primary">
            New
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Asset Type
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            // validationSchema={partnerValidationSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              handleFormSubmit(values);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      placeholder="Asset type Name"
                      label="Asset type Name"
                      name={"name"}
                      value={props.values.name}
                      onChange={props.handleChange}
                      fullWidth
                      required
                    ></Field>
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    {props.values.customFields.length > 0 && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h3">Asset Custom Fields</Typography>
                      </Grid>
                    )}
                    <FieldArray name="customFields">
                      {({ insert, remove, push }) => (
                        <>
                          {props.values.customFields.length > 0 &&
                            props.values.customFields.map((customField, index) => (
                              <>
                                <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                  <Field
                                    component={TextField}
                                    label={`Custom Fields ${index + 1}`}
                                    name={`customFields.${index}.label`}
                                    fullWidth
                                    required
                                  ></Field>
                                  {index === props.values.customFields.length - 1 && (
                                    <span
                                      onClick={() => remove(index)}
                                      title="Remove Field"
                                      style={{ color: "white" }}>
                                      <RemoveIcon style={{
                                        marginLeft: 20,
                                        fontSize: 18,
                                        backgroundColor: "var(--theme-primary)",
                                        cursor: "pointer",
                                        borderRadius: 2
                                      }}
                                      />
                                    </span>
                                  )}
                                </Grid>
                              </>
                            ))}
                          <Grid item container xs={1}
                            alignItems={"center"}
                          >
                            <span
                              onClick={() => push({ label: '', value: '' })}
                              title="Add Field"
                              style={{ color: "white" }}>
                              <AddIcon style={{
                                fontSize: 18,
                                backgroundColor: "var(--theme-primary)",
                                cursor: "pointer",
                                borderRadius: 2
                              }}
                              />
                            </span>
                          </Grid>
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                  <Grid item container xs={12}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                    >
                      {(createAssetTypeLoading || updateAssetTypeLoading) && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                      Save
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  )
}



/**
 * <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            // validationSchema={partnerValidationSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              handleFormSubmit(values);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Field
                      component={TextField}
                      placeholder="Asset type Name"
                      label="Asset type Name"
                      name={"name"}
                      value={props.values.name}
                      onChange={props.handleChange}
                      fullWidth
                      required
                    ></Field>
                  </Grid>
                  <FieldArray name="customFields">
                    {({ insert, remove, push }) => (
                      <>
                        {props.values.customFields.length > 0 &&
                          props.values.customFields.map((customField, index) => (
                            <>
                              <Grid item xs={4}>
                                <Field
                                  component={TextField}
                                  label={`Custom Fields ${index + 1}`}
                                  name={`customFields.${index}.label`}
                                  fullWidth
                                  required
                                ></Field>
                              </Grid>
                            </>
                          ))}
                        <Grid item container xs={1}
                          alignItems={"center"}
                          style={{ marginTop: 5 }}
                        >
                          <span
                            onClick={() => push({ label: '', value: '' })}
                            title="Add Field"
                            style={{ color: "white" }}>
                            <AddIcon style={{
                              fontSize: 18,
                              backgroundColor: "var(--theme-primary)",
                              cursor: "pointer",
                              borderRadius: 2
                            }}
                            />
                          </span>
                          <span
                            onClick={() => {
                              setValues({
                                ...props.values,
                                customFields: [...props.values.customFields.slice(0, props.values.customFields.length - 1)]
                              })
                            }}
                            title="Remove Field"
                            style={{ color: "white" }}>
                            <RemoveIcon style={{
                              marginLeft: 20,
                              fontSize: 18,
                              backgroundColor: "var(--theme-primary)",
                              cursor: "pointer",
                              borderRadius: 2
                            }}
                            />
                          </span>
                        </Grid>
                      </>
                    )}
                  </FieldArray>
                  <Grid item container xs={12}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                    >
                      {(createAssetTypeLoading || updateAssetTypeLoading) && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                      Save
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
 *
 */



// {props.values.customFields.length > 0 && props.values.customFields.map((customField, index) => {
//   return (
//     <Grid item xs={4}>
//       <Field
//         component={TextField}
//         placeholder={customField.label}
//         label={customField.label}
//         name={customField.label}
//         value={customField.label}
//         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
//           const newVal = e.target.value.trim();
//           if (newVal) {
//             const updatedCustomFields = props.values.customFields.map((field) => field);
//             updatedCustomFields[index].label = newVal;
//             updatedCustomFields[index].value = newVal;
//             setValues({
//               ...props.values,
//               customFields: updatedCustomFields
//             })
//           }
//         }}
//         fullWidth
//         required
//       ></Field>
//     </Grid>
//   )
// })}
// <Grid item container xs={1}>
//   <Fab
//     variant="extended"
//     size="medium"
//     aria-label="add"
//     onClick={() => {
//       setValues({
//         ...props.values,
//         customFields: [...props.values.customFields, { label: "New Field", value: "newField" }]
//       })
//     }}
//   >
//     + Add
//   </Fab>
// </Grid>
// {props.values.customFields.length > 0 && (
//   <Grid item container xs={2}>
//     <Fab
//       variant="extended"
//       size="medium"
//       aria-label="add"
//       onClick={() => {
//         setValues({
//           ...props.values,
//           customFields: [...props.values.customFields.slice(0, props.values.customFields.length - 1)]
//         })
//       }}
//     >
//       - Remove
//     </Fab>
//   </Grid>
// )}