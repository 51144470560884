import { gql } from "@apollo/client";

export const REPLACE_IN_PROGRESS_BOOKING_VEHICLE = gql`
  mutation replaceVehicleForInProgressBooking($vehicleReplacementInput: VehicleReplacementInput!) {
    replaceVehicleForInProgressBooking(vehicleReplacementInput: $vehicleReplacementInput) {
      id
      endDate
      startDate
      vehicleStatus
      vehicle
      vehicleGroup
      booking
      tba
    }
  }
`;

export const REPLACE_VEHICLE_FOR_CONFIMED_BOOKING = gql`
  mutation replaceVehicleForConfirmedBooking($replaceVehicleForConfirmedBookingInput: ReplaceVehicleForConfirmedBookingInput) {
    replaceVehicleForConfirmedBooking(replaceVehicleForConfirmedBookingInput: $replaceVehicleForConfirmedBookingInput) {
      id
    }
  }
`;
