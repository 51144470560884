import { gql } from "@apollo/client";

export const UPLOAD_ADDON_RATE_CARDS = gql`
  mutation uploadAddonRateCards($rateCards: [RateCardInput]) {
    uploadAddonRateCards(rateCards: $rateCards) {
      success
      message
    }
  }
`;
