import { gql } from "@apollo/client";

export const ADD_PROCUREMENT_FINANCE_ORDER = gql`
  mutation addProcurementFinanceInfo($id: ID!, $financeInformation: ProcurementFinanceInfoInput!) {
    addProcurementFinanceInfo(id: $id, financeInformation: $financeInformation) {
      id
      purchaseOrderId
      purchaseOrderDate
      partnerId
      invoiceId
      invoiceDate
      totalAmount
      expectedArrivalDate
      advancedPayment
      paymentSource
      partner {
        id
        partnerName
      }
      documents
    }
  }
`;