import "../index.scss";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { IBooking } from "../../../../../reducers/bookings/types";
import { IVehicle, IVehicleService } from "../../../../../reducers/fleet/types";
import { IVehiclePriceGroup } from "../../../../../reducers/vehiclePriceGroups/types";
import { DIALOG_TITLES, DialogTypes, IVehicleReplacementArgs, IVehicleSubscriptionReplacementArgs } from "../utils";
import { BookingSummary } from "./BookingSummary";
import { ConfirmBookingDrop } from "./ConfirmBookingDrop";
import { ConfirmBookingUpdate } from "./ConfirmBookingUpdate";
import { ConfirmCancelBooking } from "./ConfirmCancelBooking";
import { ConfirmCompleteBooking } from "./ConfirmCompleteBooking";
import { DeactivatedVehicleError } from "./DeactivatedVehicleError";
import { DragAndDropVehicleGroups } from "./DragAndDropVehicleGroups";
import { DragInProgressEventError } from "./DragInProgressEventError";
import { DragNotAllowed } from "./DragNotAllowed";
import DropErrorForDayView from "./DropErrorForDayView";
import { DropEventError } from "./DropEventError";
import { EventExistError } from "./EventExistsAlready";
import { EventOverlapError } from "./EventOverlapError";
import { ExtendEventError } from "./ExtendEventError";
import ExtendInProgressBooking from "./ExtendInProgressBooking";
import { ExtendMultipleEventError } from "./ExtendMultipleEventError";
import { NewBookingDialog } from "./NewBookingDialog";
import { IHoldInput, OnHoldSummary } from "./OnHoldSummary";
import { SameDayCreateEvent } from "./SameDayCreateEvent";
import { SelectCreateEvent } from "./SelectCreateEvent";
import { ServiceSummary } from "./ServiceSummary";
import { VehiclePreparationTime } from "./VehiclePreparationTime";
import { VehicleInTransferProcess } from "./VehicleInTransferProcess";
import { IHoldInfo } from "../index";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";

interface IBookingSchedule {
  booking: IBooking;
  vehicle: IVehicle;
  vehicleStatus: string;
  startDate: string;
  endDate: string;
  vehicleGroup?: string;
}

interface IUpdateBookingSchedule {
  startDate: string;
  endDate: string;
  bookingId: string;
  bookingSchedules: string[];
}

interface IDropEvent {
  startDate: string;
  endDate: string;
  bookingId: string;
  bookingScheduleId: string;
  vehicleGroupId: string;
  previousVehicle: string;
  currentVehicle: string;
  rateTypeName?: string;
  rateTypeDuration?: number;
  vehicleGroups?: IVehiclePriceGroup[];
}

interface IDialogData {
  bookingSchedule?: IBookingSchedule;
  service?: IVehicleService;
  hold?: IHoldInfo;
  startDate?: string | undefined;
  endDate?: string;
  vehicleId?: string;
  resource?: IVehicle;
  bookingId?: string;
  onConfirm?(): void;
  onCancel?(): void;
  dropEvent?: IDropEvent;
  isSharedFleetEvent?: boolean;
}

interface IExtendDialogData {
  startDate?: string | undefined;
  endDate?: string;
  bookingId?: string;
}

interface IProps {
  type?: DialogTypes;
  openDialog: boolean;
  handleClose(type?: boolean): void;
  dialogData: IDialogData;
  setDialogType(type: DialogTypes): void;
  onCreateHold(
    vehicleId: string,
    holdId?: string,
    holdInfo?: IHoldInput
  ): void;
  startBooking(id: string): void;
  cancelBooking(): void;
  confirmCancelBookingDialog(id: string): void;
  bookingId?: string;
  cancelBooking(): void;
  completeBooking(): void;
  updateBookingSchedules(values: IUpdateBookingSchedule): void;
  shiftBookingSchedule(values: IDropEvent): void;
  setDialogData(data: IExtendDialogData): void;
  replaceVehicleForInProgressBooking(data: IVehicleReplacementArgs): void;
  replaceVehicleForInProgressSubscription(data: IVehicleSubscriptionReplacementArgs): void;
  replaceVehicleLoading?: boolean;
  replaceSubscriptionVehicleLoading?: boolean;
  reloadScheduler(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiPaper-root": {
        flexGrow: 1
      }
    },
    dialogTitle: {
      padding: "10px 30px"
    },
    dialogContent: {
      padding: "15px 30px"
    }
  })
);

export const SchedulerDialog: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address

  const {
    openDialog,
    handleClose,
    dialogData,
    type,
    onCreateHold,
    setDialogType,
    startBooking,
    cancelBooking,
    confirmCancelBookingDialog,
    completeBooking,
    updateBookingSchedules,
    shiftBookingSchedule,
    setDialogData,
    replaceVehicleForInProgressBooking,
    replaceVehicleForInProgressSubscription,
    replaceVehicleLoading,
    replaceSubscriptionVehicleLoading
  } = props;

  const renderDialogContent = (type: DialogTypes) => {
    switch (type) {
      case country === "United States" ? DialogTypes.RESERVATION_SUMMARY : DialogTypes.BOOKING_SUMMARY:
        return (
          <BookingSummary
            type={type}
            handleClose={handleClose}
            bookingSchedule={dialogData.bookingSchedule}
            isSharedFleetEvent={dialogData.isSharedFleetEvent}
            startBooking={startBooking}
            setDialogType={setDialogType}
            confirmCancelBookingDialog={confirmCancelBookingDialog}
            setDialogData={setDialogData}
            replaceVehicleForInProgressBooking={replaceVehicleForInProgressBooking}
            replaceVehicleForInProgressSubscription={replaceVehicleForInProgressSubscription}
            replaceVehicleLoading={replaceVehicleLoading}
            replaceSubscriptionVehicleLoading={replaceSubscriptionVehicleLoading}
            reloadScheduler={props?.reloadScheduler}
          />
        );
      case DialogTypes.SERVICE_SUMMARY:
        return (
          <ServiceSummary
            type={type}
            handleClose={handleClose}
            serviceSummary={dialogData.service}
            isSharedFleetEvent={dialogData.isSharedFleetEvent}
          />
        );
      case DialogTypes.HOLD_SUMMARY:
        return (
          <OnHoldSummary
            type={type}
            handleClose={handleClose}
            holdSummary={dialogData.hold || dialogData}
            onCreateHold={onCreateHold}
          />
        );
      case DialogTypes.NEW_EVENT:
        return (
          <SelectCreateEvent
            type={type}
            eventData={dialogData}
            setDialogType={setDialogType}
          />
        );
      case DialogTypes.NEW_BOOKING:
        return (
          <NewBookingDialog
            type={type}
            handleClose={handleClose}
            dialogData={dialogData}
          />
        );
      case DialogTypes.UPDATE_BOOKING:
        return (
          <ConfirmBookingUpdate
            type={type}
            handleClose={handleClose}
            dialogData={dialogData}
            updateBookingSchedules={updateBookingSchedules}
          />
        );
      case DialogTypes.EXTEND_BOOKING:
        return (
          <ExtendInProgressBooking
            type={type}
            handleClose={handleClose}
            dialogData={dialogData}
            updateBookingSchedules={updateBookingSchedules}
          />
        );
      case DialogTypes.CANCEL_BOOKING:
        return (
          <ConfirmCancelBooking
            type={type}
            handleClose={handleClose}
            cancelBooking={cancelBooking}
          />
        );
      case DialogTypes.COMPLETE_BOOKING:
        return (
          <ConfirmCompleteBooking
            handleClose={handleClose}
            completeBooking={completeBooking}
          />
        );
      case DialogTypes.DROP_ERROR:
        return <DropEventError handleClose={handleClose} />;
      case DialogTypes.DEACTIVATED_VEHICLE:
        return (
          <DeactivatedVehicleError
            dialogData={dialogData}
            isSharedFleetEvent={dialogData.isSharedFleetEvent}
          />
        );
      case DialogTypes.DROP_VEHICLE_GROUP:
        return (
          <DragAndDropVehicleGroups
            dropEventData={dialogData.dropEvent}
            handleClose={handleClose}
            shiftBookingSchedule={shiftBookingSchedule}
          />
        );
      case DialogTypes.SAME_DAY_CREATE_EVENT:
        return <SameDayCreateEvent />;
      case DialogTypes.CONFIRM_BOOKING_UPDATE:
        return (
          <ConfirmBookingDrop
            dialogData={dialogData.dropEvent}
            handleClose={handleClose}
          />
        );
      case DialogTypes.DRAG_DROP_EVENT_ERROR:
        return <DragNotAllowed />;
      case DialogTypes.EXTEND_EVENT_ERROR:
        return <ExtendEventError />;
      case DialogTypes.EXTEND_MULTIPLE_EVENT_ERROR:
        return <ExtendMultipleEventError />;
      case DialogTypes.DRAG_IN_PROGRESS_ERROR:
        return <DragInProgressEventError />;
      case DialogTypes.EVENT_OVERLAP_ERROR:
        return <EventOverlapError />;
      case DialogTypes.EVENT_EXISTS_ALREADY_ERROR:
        return <EventExistError />;
      case DialogTypes.VEHICLE_PREPARATION_TIME:
        return <VehiclePreparationTime vehicleData={dialogData} />;
      case DialogTypes.DAY_VIEW_DRAG_DROP_ERROR:
        return <DropErrorForDayView />;
      case DialogTypes.VEHICLE_IN_TRANSFER_PROCESS:
        return <VehicleInTransferProcess />
    }
  };

  const dialogMaxWidth = (type: string) => {
    switch (type) {
      case DialogTypes.CANCEL_BOOKING:
        return 450;
      case DialogTypes.SERVICE_SUMMARY:
        return 500;
      case DialogTypes.COMPLETE_BOOKING:
        return 450;
      case DialogTypes.VEHICLE_IN_TRANSFER_PROCESS:
        return 500;
    }
  };
  return (
    <>
      {type && openDialog && (
        <Dialog
          className={`${classes.root} scheduler-dialog ${DialogTypes.HOLD_SUMMARY === type && (dialogData && !dialogData.hold?.isSharedFleetEvent) ? "hold-dialog" : ""
            }`}
          style={{ maxWidth: dialogMaxWidth(type), margin: "0 auto" }}
          open={openDialog}
          scroll='body'
          maxWidth={DialogTypes.BOOKING_SUMMARY ? "md" : "xs"}
          onClose={() => {
            if (
              DialogTypes.CONFIRM_BOOKING_UPDATE ||
              DialogTypes.DROP_VEHICLE_GROUP
            ) {
              handleClose(true);
            } else {
              handleClose();
            }
          }}
        >
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            <Grid container item xs={12}>
              <Typography variant="h2">{DIALOG_TITLES[type]}</Typography>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.dialogContent} dividers={DialogTypes.BOOKING_SUMMARY ? true : false}>
            {renderDialogContent(type)}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
