import {
  Box,
  Fab,
  Grid,
  Typography,
  Paper
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetApprovedDriver,
  setApprovedDriver,
  setBusinessCustomer
} from "../../../../../../actions/customer/actions";
import { IBusinessCustomer, IBusinessCustomerCommonInput } from "../../../../../../reducers/customer/types";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { DELETE_BUSINESS_CUSTOMER_DRIVER } from "../../../../../../graphql/businessCustomers/businessCustomerDriver";
import { useMutation } from "@apollo/client";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../../common/utils";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { IAppState } from "../../../../../../store";
import { DELETE_DRIVER, DELETE_DRIVER_DESCRIPTION, IConfirmationDialogData, defaultConfirmationDialogData } from "../../../../ReservationManagement/Summary/const";
import { ConfirmationDialog } from "../../../../../common/ConfirmationDialog/ConfirmationDialog";
import { resetBranch } from "../../../../../../actions/organisation/actions";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 8,
    },
    verified: {
      fill: "green"
    },
    resentVerificationButton: {
      backgroundColor: "#faaf40",
      border: 0,
      borderRadius: 26,
      color: "white",
      fontSize: "14px",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    }
  })
);

export const columns: any = [
  {
    label: "First Name",
    name: "firstName"
  },
  {
    label: "Last Name",
    name: "lastName"
  },
  {
    label: "Email",
    name: "email"
  }

];

export const VerifiedFormatter = (value: any) => {
  const classes = useStyles();

  return value.value && <CheckCircleOutlineIcon className={classes.verified} />;
};

export const CurrentApprovedDrivers = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const [selection, setSelection] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [updateButtonVisible, setUpdateButtonVisible] = useState<boolean>(
    false
  );
  const [deleteButtonVisible, setdeleteButtonVisible] = useState<boolean>(
    false
  );
  const [confirmationDialogData, setConfirmationDialogData] = useState<
    IConfirmationDialogData
  >(defaultConfirmationDialogData);

  const businessCustomer: IBusinessCustomer = useSelector(
    (state: IAppState) => state.customerReducer.businessCustomer
  );

  const [deleteBusinessCustomerDriver] = useMutation(
    DELETE_BUSINESS_CUSTOMER_DRIVER,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        snackbar({
          message: "Business customer driver deleted.",
          variant: SnackBarVariant.SUCCESS
        });
        setConfirmationDialogData({
          ...confirmationDialogData,
          isOpen: false,
          isLoading: false
        })
        dispatch(setBusinessCustomer(data.deleteBusinessCustomerDriver));
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  useEffect(() => {
    if (selection.length === 1) {
      setUpdateButtonVisible(true);
      setdeleteButtonVisible(true);
    } else {
      setUpdateButtonVisible(false);
      setdeleteButtonVisible(false);
      dispatch(resetApprovedDriver());
    }
  }, [selection, dispatch]);

  useEffect(() => {
    if (props.approvedDrivers) {
      setRows(props.approvedDrivers);
      setSelection([]);
    }
  }, [props.approvedDrivers]);

  const options: MUIDataTableOptions = {
    responsive: "vertical",
    elevation: 0,
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      if (currentRowsSelected && !currentRowsSelected.length) {
        dispatch(resetBranch());
        setUpdateButtonVisible(false);
        setdeleteButtonVisible(false)
        setSelection([]);

      } else {
        if (currentRowsSelected?.length) {
          setUpdateButtonVisible(true);
          setdeleteButtonVisible(true)
          const selectedRows = allRowsSelected.map(row => rows[row.dataIndex]);
          setSelection(selectedRows);
        }
      }
    },
  };

  return (
    <Grid container>
      <Grid container xs={12} item>
        <Typography variant="subtitle1">
          {"CURRENT APPROVED DRIVERS"}
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={12} className={classes.root}>
        <Grid container item xs={12} justifyContent="flex-end" style={{ paddingBottom: "20px" }} >
          <Box pr={2}></Box>
          <Fab
            variant="extended"
            size="medium"
            aria-label="Update"
            disabled={!deleteButtonVisible}
            className={
              deleteButtonVisible ? "deleteButton" : "deleteButtonDisable"
            }
            onClick={() => {
              setConfirmationDialogData({
                description: DELETE_DRIVER_DESCRIPTION,
                isOpen: true,
                onCancel: () =>
                  setConfirmationDialogData({
                    ...confirmationDialogData,
                    isOpen: false
                  }),
                onConfirm: () => {
                  setConfirmationDialogData({
                    ...confirmationDialogData,
                    description: DELETE_DRIVER_DESCRIPTION,
                    title: DELETE_DRIVER,
                    isLoading: true,
                    isOpen: true
                  })
                  deleteBusinessCustomerDriver({
                    variables: {
                      businessCustomerId: businessCustomer.id,
                      driverId: selection[0].id
                    }
                  });
                  setSelection([]);
                },
                title: DELETE_DRIVER,
              })
            }}
          >
            Delete
          </Fab>
          <Box pr={2}></Box>
          <Fab
            variant="extended"
            size="medium"
            aria-label="Update"
            disabled={!updateButtonVisible}
            className={
              updateButtonVisible ? "deleteButton" : "deleteButtonDisable"
            }
            onClick={() => {
              const filteredApprovedDrivers = props.approvedDrivers.filter(
                (element: IBusinessCustomerCommonInput) =>
                  element.id === selection[0].id
              )[0]
              dispatch(
                setApprovedDriver(filteredApprovedDrivers)
              );
            }}
          >
            Update
          </Fab>
        </Grid>

        <Grid item container xs={12}>
          <Paper variant="outlined" style={{ width: "73vw" }}>
            <NuvvenTable
              title={""}
              rows={rows}
              columns={columns}
              setSelection={setSelection}
              options={options}
            />
          </Paper>
        </Grid>
      </Grid >
      <ConfirmationDialog {...confirmationDialogData} />
    </Grid >
  );
};
