import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styles from './index.module.css';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress, Fab, Grid, IconButton } from '@mui/material';
import { NuvvenTable } from '../../common/NuvvenTable/NuvvenTable';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_BRANCH_CONNECTIONS, GET_FLEET_MOVEMENTS, GET_FlEET_MOVEMENT_COUNT, SEARCH_FLEET_MOVEMENT } from '../../../graphql/vehicleMovement/queries';
import { FleetMovementSource, FleetMovementStatus, FleetMovementStatuseValue, IBranchConnection, IConfirmedMovementUpdateArgs, IExternalBranch, IFleetMovement, getLocalizedFleetMovementSourceValue } from './types';
import ConfirmMovementDialog from './ConfirmMovementDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import { SelectableRows } from "../../common/NuvvenTable/types";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationDialog } from '../../common/ConfirmationDialog/ConfirmationDialog';
import { CANCEL_FLEET_MOVEMENT, REMOVE_BRANCH_CONNECTION } from '../../../graphql/vehicleMovement/mutations';
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { ApolloError } from '@apollo/client';
import { DATE_TYPE, formatGraphQLErrorMessage } from '../../common/utils';
import { getLocalizedDateFormat } from '../../../utils/localized.syntex';
import { UserRoles } from '../../hoc/Authorization';
import { ITable, TableNames } from '../../../reducers/tableState/types';
import _ from 'lodash';
import { addTableState } from '../../../actions/tableState/actions';
import iconCheckCircle from "../../../assets/icons/icon-check-circle.svg"
import iconCloseOutlined from "../../../assets/icons/icon-close-outlined.svg"
import editIcon from "../../../assets/icons/edit-icon.svg"
const StyledTabs = withStyles({
  root: {
    backgroundColor: 'transparent',
  },
  indicator: {
    height: 3,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: 'var(--theme-accent)',
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    textTransform: 'none',
    minWidth: '80px !important',
    color: '#333',
    '&:hover': {
      color: 'var(--theme-accent)',
      opacity: 1,
    },
    '&$selected': {
      color: 'var(--theme-accent)',
    },
  },
  selected: {},
})(Tab);

const VehicleMovement = () => {
  const [tab, setTab] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const snackbar = useSnackBar();
  const dispatch = useDispatch();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [dialogState, setDialogState] = useState<{ open: boolean, movement?: IFleetMovement }>({ open: false });
  const [branchConnections, setBranchConnections] = useState<IBranchConnection[]>([]);
  const [fleetMovements, setFleetMovements] = useState<IFleetMovement[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const fleetMovementTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.fleetMovement
  );
  const [rows, setRows] = useState<IFleetMovement[]>([]);
  const [limit, setLimit] = useState(10);
  const [totalFleetMovementCount, setTotalFleetMovementCount] = useState<number>(0);
  const [fleetMovementTable, setFleetMovementTable] = useState<ITable>();

  const [getBranchConnections, { loading: connectionsLoading, data: BranchConnectionData }] = useLazyQuery(
    GET_BRANCH_CONNECTIONS,
    {
      fetchPolicy: "network-only"
    }
  ); index

  const [getVehicleMovements, { loading: movementsLoading, data: MovementData }] = useLazyQuery(
    GET_FLEET_MOVEMENTS,
    {
      fetchPolicy: "network-only",
      onCompleted: (MovementData) => {
        if (MovementData && MovementData.getFleetMovements) {
          const newData = [...MovementData.getFleetMovements]
          const oldData = [...fleetMovements]
          newData.forEach((Movement: IFleetMovement) => {
            if (!oldData.some((item) => item.id === Movement.id)) {
              oldData.push(Movement)
            }
          });
          setFleetMovements(oldData)
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [getFleetMovementsCount, { loading: fleetMovementsCountLoading, data: fleetMovementCountData }] = useLazyQuery(
    GET_FlEET_MOVEMENT_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (fleetMovementCountData) => setTotalFleetMovementCount(fleetMovementCountData.getFleetMovementsCount)
    }
  );

  const [searchfleetMovements, { loading: searchfleetMovementsLoading, data: searchFleetMovementData }] = useLazyQuery(
    SEARCH_FLEET_MOVEMENT,
    {
      fetchPolicy: "network-only",
      onCompleted: (searchFleetMovementData) => {
        if (searchFleetMovementData && searchFleetMovementData.searchFleetMovements) {
          const newMovements = [...searchFleetMovementData.searchFleetMovements]
          setTotalFleetMovementCount(newMovements.length)
          setFleetMovements(newMovements)
        }
      }
    }
  );
  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchfleetMovements({
        variables: {
          q: searchText
        }
      })
    }
  }, 1000);

  const [removeBranchConnection] = useMutation(
    REMOVE_BRANCH_CONNECTION,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        snackbar({
          message: "Branch connection removed successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setOpenDeleteDialog(false);
        getBranchConnections();
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const [cancelFleetMovementMutation] = useMutation(CANCEL_FLEET_MOVEMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      snackbar({
        message: "Fleet movement has been cancelled successfully.",
        variant: SnackBarVariant.SUCCESS
      });
      setOpenCancelDialog(false);
      let _fleetMovements = [...fleetMovements];
      _fleetMovements = [
        ..._fleetMovements.slice(0, index),
        {
          ..._fleetMovements[index],
          status: FleetMovementStatus.CANCELLED
        },
        ..._fleetMovements.slice(index + 1)
      ]
      setFleetMovements(_fleetMovements);
      setIndex(0)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  }
  );

  useEffect(() => {
    if (userState.tenancy) {
      getBranchConnections();
      getFleetMovementsCount();
      getVehicleMovements({
        variables: {
          limit: fleetMovementTableState?.rowsPerPage || limit,
          offset: 0
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    if (BranchConnectionData && BranchConnectionData.getFleetShares) {
      setBranchConnections(BranchConnectionData.getFleetShares)
    }
  }, [BranchConnectionData])

  useEffect(() => {
    if (fleetMovementTableState) {
      setFleetMovementTable(fleetMovementTableState)
      getVehicleMovements({
        variables: {
          offset: fleetMovements.length,
          limit: fleetMovementTableState.rowsPerPage || limit
        }
      })
    }
  }, [fleetMovementTableState])


  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const tab = params.get("tab");
      if (tab) {
        setTab(parseInt(tab));
      }
    }
  }, [location])

  const handleChange = (event: any, newValue: any) => {
    setTab(newValue);
    navigate(`/movements?tab=${newValue}`);
  };

  const branchConnectionColumns: any = [
    {
      label: "Branches",
      name: "externalBranches",
      options: {
        customBodyRender: (branches: IExternalBranch[]) => {
          return branches.map((branch) => {
            return <span className="padding-right">{branch.branch?.name || ""}</span>
          })
        },
      }
    },
    {
      label: "Shared vehicles",
      name: "sharedFleetData",
      options: {
        customBodyRender: (sharedFleetData: any[], meta: any) => {
          const vehiclesCount = sharedFleetData.reduce((total, group) => total + (group.vehicles.length || 0), 0);
          return <React.Fragment>
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <span className="padding-right">{vehiclesCount}</span>
              {<IconButton
                edge="end"
                aria-label="delete"
                style={{ marginLeft: 10, color: "red" }}
                onClick={(event) => {
                  event.stopPropagation();
                  setOpenDeleteDialog(true);
                  setIndex(meta.rowIndex);
                }}
              >
                <DeleteIcon titleAccess={"Delete branch connection"} />
              </IconButton>}
            </div>
          </React.Fragment>
        }
      }
    }
  ];

  const movememntColumns: MUIDataTableColumnDef[] = [
    {
      label: "Reference number",
      name: "referenceNumber"
    },
    {
      label: "Vehicle",
      name: "vehicle.licencePlate"
    },
    {
      label: "From Location",
      name: "source.name"
    },
    {
      label: "To Location",
      name: "destination.name"
    },
    {
      label: "Reason",
      name: "movementSource",
      options: {
        customBodyRender: (value: FleetMovementSource) => getLocalizedFleetMovementSourceValue(value, country)
      }
    },
    {
      label: "Checkout before",
      name: "estimatedCheckout",
      options: {
        customBodyRender: (estimatedCheckout: string) => estimatedCheckout ? getLocalizedDateFormat(country, estimatedCheckout, DATE_TYPE.EXPANDED) : ''
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (status: FleetMovementStatus, meta) => {
          const thisMovement = fleetMovements.find((movement) => movement.referenceNumber === meta.rowData[0]);
          return thisMovement && <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>{FleetMovementStatuseValue[status]}</span>
          </div>
        }
      }
    },
    {
      label: "Actions",
      name: "status",
      options: {
        filter: false,
        customBodyRender: (status: FleetMovementStatus, meta) => {
          const thisMovement = fleetMovements.find((movement) => movement.referenceNumber === meta.rowData[0]);
          return thisMovement && <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
            {(status === FleetMovementStatus.PENDING && thisMovement.branchId === userState.currentBranch.id) &&
              <span
                style={{ padding: 0, margin: -5 }}
                title='Confirm'
                onClick={(event) => {
                  event.stopPropagation();
                  setDialogState({
                    open: true,
                    movement: thisMovement
                  })
                }}
              >
                <img alt="" src={iconCheckCircle}
                  style={{
                    width: 26,
                    height: 26,
                    marginLeft: 8,
                  }}
                />
              </span>
            }
            {((status === FleetMovementStatus.CONFIRMED) && thisMovement.branchId === userState.currentBranch.id) &&
              <span
                style={{ padding: 0, margin: -5 }}
                title='Confirm'
                onClick={(event) => {
                  event.stopPropagation();
                  setDialogState({
                    open: true,
                    movement: thisMovement
                  })
                }}
              >
                <img alt="" src={editIcon}
                  style={{
                    width: 20,
                    height: 20,
                    marginLeft: 8,
                  }}
                />
              </span>
            }
            {(thisMovement.movementSource === FleetMovementSource.FLEET_TRANSFER &&
              (status === FleetMovementStatus.PENDING ||
                status === FleetMovementStatus.CONFIRMED ||
                status === FleetMovementStatus.CHECKOUT_COMPLETE) &&
              thisMovement.branchId === userState.currentBranch.id) &&
              <span
                style={{ padding: 0, margin: -5 }}
                title='Cancel'
                onClick={(event) => {
                  event.stopPropagation();
                  setOpenCancelDialog(true);
                  setIndex(meta.rowIndex);
                }}
              >
                <img alt="" src={iconCloseOutlined}
                  style={{
                    width: 26,
                    height: 26,
                    marginLeft: 8,
                  }}
                />
              </span>
            }
          </div>
        }
      }
    },
  ];

  const branchConnectionOptions: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row = branchConnections[rowMeta.dataIndex];
      const connectionId = row.id
      if (connectionId) {
        navigate(`/branch-connection?connectionId=${connectionId}`);
      }
    },
    filter: false,
    print: false,
    search: false,
    download: false,
    viewColumns: false,
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      columns.forEach((col, i) => {
        if (col.searchable) {
          if (currentRow && currentRow[i][0].branch && currentRow[i][0].branch.name.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        }
      });
      return isFound;
    },
  };

  const movementOptions: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: totalFleetMovementCount,
    rowsPerPageOptions: [10, 20, 100],
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: (movementsLoading || fleetMovementsCountLoading || searchfleetMovementsLoading) ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row = fleetMovements[rowMeta.dataIndex];
      const connectionId = row.id
      if (connectionId) {
        navigate(`/movement-lifecycle?movementId=${connectionId}`);
      }
    },
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText)
      } else {
        setFleetMovements([])
        getFleetMovementsCount();
        getVehicleMovements({
          variables: {
            offset: 0,
            limit
          }
        });
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.FLEET_MOVEMENT,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      fleetMovementTable && fleetMovementTable.rowsPerPage
        ? fleetMovementTable.rowsPerPage
        : limit,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return; // Skip executing changePage if there is a search text
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= fleetMovements.length) {
            getVehicleMovements({
              variables: {
                offset: page * rowsPerPage,
                limit: rowsPerPage
              }
            });
          }
          break;
        case "changeRowsPerPage":
          setRows([])
          getVehicleMovements({
            variables: {
              offset: 0,
              limit: tableState.rowsPerPage || limit
            }
          });
          break;
        default:
          break;
      }
    },
    filter: true,
    print: true,
    search: true,
    download: true,
    viewColumns: true
  };

  return (
    <>
      <div className={styles.tabsWrap}>
        <ConfirmMovementDialog
          open={dialogState.open}
          handleClose={(confirmed: boolean, args?: IConfirmedMovementUpdateArgs) => {
            let _updatedMovement = { ...dialogState.movement };
            if (_updatedMovement && _updatedMovement.id && confirmed) {
              _updatedMovement = {
                ..._updatedMovement,
                ...args, 
                status: FleetMovementStatus.CONFIRMED
              };
              const rowIndex = fleetMovements.findIndex((movement) => _updatedMovement?.id === movement.id);
              if (rowIndex >= 0) {
                let _movements = [...fleetMovements];
                _movements = [
                  ..._movements.slice(0, rowIndex),
                  {
                    ..._movements[rowIndex],
                    ..._updatedMovement
                  },
                  ..._movements.slice(rowIndex + 1)
                ]
                setFleetMovements(_movements);
              }
            }
            setDialogState({ open: false })
          }}
          movement={dialogState.movement} />
        <CssBaseline />
        <h2>Vehicle Movement</h2>
        <div>
          <StyledTabs value={tab} onChange={handleChange}>
            <StyledTab disableRipple label="Movements" />
            <StyledTab disableRipple label="Branch Connections" />
          </StyledTabs>
        </div>
        {tab === 1 && <div className="flex fill main-end">
          <Fab
            variant="extended"
            size="medium"
            aria-label="Create"
            onClick={() => {
              navigate(`/branch-connection`);
            }}
            disabled={userState.role === UserRoles.BOOKING_AGENT || userState.role === UserRoles.FINANCE_MANAGER}
          >
            New Connection
          </Fab>
        </div>}
      </div>
      {userState.role !== UserRoles.BOOKING_AGENT && userState.role !== UserRoles.FINANCE_MANAGER && (<>
        {tab === 0 && <Grid item container xs={12} style={{ marginTop: 15 }}>
          {connectionsLoading ? (
            <CircularProgress />
          ) : (

            <NuvvenTable
              title={""}
              rows={fleetMovements}
              columns={movememntColumns}
              options={movementOptions}
              setSelection={(item: any) => item}
            />
          )}
        </Grid>}
        {tab === 1 && <Grid item container xs={12} style={{ marginTop: 15 }}>
          {movementsLoading ? (
            <CircularProgress />
          ) : (
            <NuvvenTable
              title={""}
              rows={branchConnections}
              columns={branchConnectionColumns}
              options={branchConnectionOptions}
              setSelection={(item: any) => item}
            />
          )}
        </Grid>}
        <ConfirmationDialog
          isOpen={openDeleteDialog}
          title={"Delete branch connection"}
          description={"Are you sure, you want to delete this branch connection?"}
          onCancel={() => setOpenDeleteDialog(false)}
          onConfirm={() => {
            if (index >= 0) {
              removeBranchConnection({
                variables: {
                  fleetShareId: branchConnections[index].id
                }
              })
            }
          }}
          confirmText={"Yes"}
          cancelText={"No"}
        />
        {openCancelDialog && (
          <ConfirmationDialog
            isOpen={openCancelDialog}
            title={"Cancel fleet movement"}
            description={"Are you sure, you want to cancel this fleet movement?"}
            onCancel={() => setOpenCancelDialog(false)}
            onConfirm={() => {
              if (index >= 0) {
                cancelFleetMovementMutation({
                  variables: {
                    movementId: fleetMovements[index].id
                  }
                })
              }
            }}
            confirmText={"Yes"}
            cancelText={"No"}
          />
        )}
      </>
      )}
    </>
  );
}

export default VehicleMovement

