import { MUIDataTableMeta } from "mui-datatables";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../common/utils";
export interface IConfirmationDialogData {
  description: string;
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  isLoading?: boolean
}

export interface ICompleteConfirmationDialogData {
  description: string;
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: (sendMail: boolean) => void;
  confirmText?: string;
  cancelText?: string;
  isLoading?: boolean
}

export const defaultConfirmationDialogData: IConfirmationDialogData = {
  description: "",
  isOpen: false,
  title: "",
  onCancel: () => {
    return;
  },
  onConfirm: () => {
    return;
  },
  isLoading: false
};

export const RefundStatus = {
  PENDING: "PENDING",
  PROCESSED: "PROCESSED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED"
}

export const CANCEL_DESCRIPTION =
  "Are you sure you want to cancel this booking?";
export const EARLY_TERMINATION_DESCRIPTION =
  "Are you sure you want to early terminate this booking?";
export const CANCEL_TITLE = "Cancel Booking";
export const EARLY_TERMINATE_TITLE = "Early Terminate Booking";
export const CANCEL_DESCRIPTION_QUOTE =
  "Are you sure you want to cancel this quote?";
export const CANCEL_TITLE_QUOTE = "Cancel Quote";
export const COMPLETE_DESCRIPTION =
  "Are you sure you want to complete this booking?";
export const START_DESCRIPTION = "Are you sure you want to start this booking?";
export const COMPLETE_TITLE = "Complete Booking";
export const START_TITLE = "Start Booking";
export const ISSUE_REFUND_TITLE = "Issue Refund";
export const ISSUE_REFUND_DESCRIPTION = "Are your sure you want to issue refund?";
export const MARK_INVOICE_VOID_TITLE = "Mark Invoice Void"
export const CONFIRM_INVOICE_TITLE = "Confirm Invoice"
export const MARK_INVOICE_VOID_DESCRIPTION = "Are you sure you want to mark this invoice as void?"
export const CONFIRM_INVOICE_DESCRIPTION = "Are you sure you want to confirm this invoice?"
export const APPROVE_DESCRIPTION = "Are you sure you want to approve this booking?";
export const DECLINE_DESCRIPTION = "Are you sure you want to decline this booking?";
export const APPROVE_TITLE = "Approve Booking";
export const DECLINE_TITLE = "Decline Booking";
export const DELETE_DRIVER_DESCRIPTION = "Are you sure you want to delete the driver?";
export const DELETE_DRIVER = "Delete Driver";

export const BOOKING_STATUS = {
  QUOTE: "QUOTE",
  CONFIRMED: "CONFIRMED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED"
};

export const VEHICLE_STATUS = {
  CONFIRMED: "CONFIRMED",
  IN_PROGRESS: "IN_PROGRESS",
  DROPOFF_CHECK_COMPLETE: "DROPOFF_CHECK_COMPLETE",
  PICKUP_CHECK_COMPLETE: "PICKUP_CHECK_COMPLETE",
  COMPLETED: "COMPLETED",
};

export const CHECK_TYPE = {
  COLLECTION: "COLLECTION",
  DELIVERY: "DELIVERY"
};

export function customRowIndexColumn() {
  return ({
    name: '#',
    options: {
      filter: false,
      customBodyRender: (value: any,meta: MUIDataTableMeta) => {
        return (
          meta.rowIndex + 1
        );
      }
    }
  })
}

export const noteColumns = (country: string) => {
  return [customRowIndexColumn(),
    {
      label: "Note",
      name: "description",
      options: {
        setCellProps: () => ({ style: { minWidth: "800px", maxWidth: "800px" } }),
        customBodyRender: (value: string) => {
          return value;
        }
      }
    },
    {
      label: "Date Created",
      name: "createdDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED)
      }
    },]
}

export const terminateOptions = [
  {
    value: "TERMINATION_CHARGES",
    label: "Early Termination Charges"
  },
  {
    value: "CREATE_CREDIT_NOTE",
    label: "Create credit note(Pro-rated balance)"
  },
  {
    value: "NONE",
    label: "None"
  }
]

export enum TerminateOptionTypes {
  TERMINATION_CHARGES = "TERMINATION_CHARGES",
  CREATE_CREDIT_NOTE = "CREATE_CREDIT_NOTE",
  NONE = "NONE"
}

