import {
  BusinessCustomerPayloadType,
  CustomerPayloadType,
  IBusinessCustomerApprovedDriverInput,
  IBusinessCustomerCommonInput,
  IBusinessCustomerDocumentInput
} from "../../reducers/customer/types";
import {
  CustomerAction,
  RESET_APPROVED_DRIVER,
  RESET_AUTHORISED_SIGNATORY,
  RESET_BUSINESS_CUSTOMER,
  RESET_CUSTOMER,
  SET_APPROVED_DRIVER,
  SET_AUTHORISED_SIGNATORY,
  SET_BUSINESS_APPROVED_DRIVERS,
  SET_BUSINESS_AUTHORISED_SIGNATORY,
  SET_BUSINESS_CUSTOMER,
  SET_BUSINESS_CUSTOMER_BILLING,
  SET_BUSINESS_CUSTOMER_DOCUMENT,
  SET_BUSSINESS_CUSTOMERINFO,
  SKIP_BUSINESS_APPROVED_DRIVERS,
  UPDATE_BUSINESS_APPROVED_DRIVER,
  UPDATE_CUSTOMER,
  VIEW_CUSTOMER
} from "./types";

export function updateCustomer(payload: CustomerPayloadType): CustomerAction {
  return {
    payload,
    type: UPDATE_CUSTOMER
  };
}

export function viewCustomer(payload: CustomerPayloadType): CustomerAction {
  return {
    payload,
    type: VIEW_CUSTOMER
  };
}
export function resetCustomer(): CustomerAction {
  return {
    type: RESET_CUSTOMER
  };
}
export function setBusinessCustomer(
  payload: BusinessCustomerPayloadType
): CustomerAction {
  return {
    payload,
    type: SET_BUSINESS_CUSTOMER
  };
}
export function resetBusinessCustomer(): CustomerAction {
  return {
    type: RESET_BUSINESS_CUSTOMER
  };
}

export function setAuthorisedSignatory(
  payload: IBusinessCustomerCommonInput
): CustomerAction {
  return {
    payload,
    type: SET_AUTHORISED_SIGNATORY
  };
}

export function setApprovedDriver(
  payload: IBusinessCustomerApprovedDriverInput
): CustomerAction {
  return {
    payload,
    type: SET_APPROVED_DRIVER
  };
}

// export function updateAuthorisedSignatory(payload: IBusinessCustomerCommonInput): CustomerAction {
//   return {
//     payload,
//     type: SET_AUTHORISED_SIGNATORY
//   };
// }
// export function updateApprovedDriver(payload: IBusinessCustomerCommonInput): CustomerAction {
//   return {
//     payload,
//     type: SET_APPROVED_DRIVER
//   };
// }
export function resetAuthorisedSignatory(): CustomerAction {
  return {
    type: RESET_AUTHORISED_SIGNATORY
  };
}

export function resetApprovedDriver(): CustomerAction {
  return {
    type: RESET_APPROVED_DRIVER
  };
}

export function setBusinessCustomerInfo(
  payload: BusinessCustomerPayloadType
): CustomerAction {
  return {
    payload,
    type: SET_BUSSINESS_CUSTOMERINFO
  };
}
export function setBusinessCustomerBilling(payload: any): CustomerAction {
  return {
    payload,
    type: SET_BUSINESS_CUSTOMER_BILLING
  };
}
export function setBusinessAuthorisedSignatory(payload: any): CustomerAction {
  return {
    payload,
    type: SET_BUSINESS_AUTHORISED_SIGNATORY
  };
}
export function setBusinessApprovedDrivers(payload: any): CustomerAction {
  return {
    payload,
    type: SET_BUSINESS_APPROVED_DRIVERS
  };
}

export function updateBusinessApprovedDriver(payload: IBusinessCustomerCommonInput): CustomerAction {
  return {
    payload,
    type: UPDATE_BUSINESS_APPROVED_DRIVER
  };
}

export function skipBusinessApprovedDrivers(): CustomerAction {
  return {
    type: SKIP_BUSINESS_APPROVED_DRIVERS
  };
}
export function setBusinessCustomerDocument(
  payload: IBusinessCustomerDocumentInput
): CustomerAction {
  return {
    payload,
    type: SET_BUSINESS_CUSTOMER_DOCUMENT
  };
}
