import "./index.scss";

import {
  DataTypeProvider,
  IntegratedSorting,
  RowDetailState,
  Sorting,
  SortingState
} from "@devexpress/dx-react-grid";
import {
  Grid as DevExpressGrid,
  Table,
  TableHeaderRow,
  TableRowDetail
} from "@devexpress/dx-react-grid-material-ui";
import {
  CircularProgress,
  Fab,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { columns } from "./constants";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-mui";
import { DateTime as d } from "luxon";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  GET_MOT_DETAILS,
  GET_VEHICLE_TAX_DETAILS
} from "../../../../../../graphql/fleet/getMOTDetails";
import {
  UPDATE_TRAILERS,
  UPDATE_VEHICLE_TAX_AMOUNT
} from "../../../../../../graphql/fleet/vehicleServices/updateVehicleTaxAmount";
import {
  IVehicle,
  IVehicleTestReport,
  TestResult,
  VehicleTypes
} from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { captureErrorException } from "../../../../../../utils/sentry";
import FlatPickerBar from "../../../../../common/FlatPicker";
import { FloatInput } from "../../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage
} from "../../../../../common/utils";
import { MobileMotCard } from "./MobileMotCard";
import { MotRowDetail } from "./MotRowDetail";

interface IProps {
  vehicle: IVehicle;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    green: {
      color: "#00ff00"
    },
    header: {
      color: "rgb(45, 157, 184)",
      fontSize: 20
    },
    label: {
      color: "grey",
      fontSize: 12,
      fontWeight: 100
    },
    paper: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      textAlign: "center"
    },
    red: {
      color: "#ff0000"
    },
    root: {
      padding: theme.spacing(3)
    }
  })
);

export const MotView = (props: IProps) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const [values, setValues] = useState<IVehicle>({ ...props.vehicle });
  const [sorting, setSorting] = useState<Sorting[]>();
  const [showLoading, setLoading] = useState<boolean>(false);
  const [expandedRowIds, setExpandedRowIds] = useState<(string | number)[]>([]);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [vehicleTestReportValues, setVehicleTestReportValues] = useState<
    IVehicleTestReport
  >({
    advisoryItems: [],
    expiryDate: "",
    failureItems: [],
    minorItems: [],
    motTestNumber: 0,
    odometerReading: 0,
    odometerUnit: "",
    testDate: "",
    testResult: "",
    vehicleTestDueDate: ""
  });

  const [
    getMOTDetails,
    { loading: loadingMOTDetails, data: MOTDetailsData }
  ] = useLazyQuery(GET_MOT_DETAILS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [
    getVehicleTaxDetails,
    { loading: loadingVehicleTaxDetails, data: vehicleTaxDetailsData }
  ] = useLazyQuery(GET_VEHICLE_TAX_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: ({ getVehicleTaxDetails }) => {
      setValues({
        ...values,
        taxDetails: getVehicleTaxDetails.taxDetails,
        taxStatus: getVehicleTaxDetails.taxStatus,
        taxed: getVehicleTaxDetails.taxed
      })
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [updateVehicleTaxAmount] = useMutation(UPDATE_VEHICLE_TAX_AMOUNT, {
    onCompleted: (data) => {
      if (data.updateVehicleTaxAmount.success) {
        snackbar({
          message: "Vehicle Tax Details saved successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setLoading(false);
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateTrailers, { loading: updateTrailerLoading }] = useMutation(
    UPDATE_TRAILERS,
    {
      onCompleted: (data) => {
        if (data.updateTrailers.id) {
          snackbar({
            message: "Trailer updated successfully",
            variant: SnackBarVariant.SUCCESS
          });
          setValues({
            ...values,
            vehicleTestReports: data.updateTrailers.vehicleTestReports
          });
        }
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const trailerValidationSchema = Yup.object().shape({
    testDate: Yup.string().required("Test Date is required"),
    vehicleTestDueDate: Yup.string().required("Expiry Date is required"),
    testResult: Yup.string().required("Test Result is required"),
  });

  useEffect(() => {
    setValues({ ...props.vehicle });
    if (
      props.vehicle &&
      props.vehicle.vehicleTestReports &&
      props.vehicle.vehicleTestReports[0] &&
      props.vehicle.vehicleTestReports[0].expiryDate
    ) {
      setValues({
        ...props.vehicle,
        vehicleTestDetails: props.vehicle.vehicleTestReports[0].expiryDate
      });
    }
  }, [props.vehicle]);

  useEffect(() => {
    if (MOTDetailsData && MOTDetailsData.getMOTDetails) {
      const newMOTDetails = MOTDetailsData.getMOTDetails;
      setValues({
        ...values,
        vehicleTestDetails:
          newMOTDetails &&
          newMOTDetails.vehicleTestReports &&
          newMOTDetails.vehicleTestReports.length &&
          newMOTDetails.vehicleTestReports[0] &&
          newMOTDetails.vehicleTestReports[0].expiryDate
            ? newMOTDetails.vehicleTestReports[0].expiryDate
            : newMOTDetails.vehicleTestDetails,
        vehicleTestReports: newMOTDetails.vehicleTestReports
      });
    }
  }, [MOTDetailsData]);

  function handleFormSubmit(values: IVehicle) {
    try {
      setLoading(true);
      setValues({
        ...values,
        vehicleTaxAmount: values.vehicleTaxAmount
      });
      updateVehicleTaxAmount({
        variables: {
          licencePlate: values.licencePlate,
          taxAmount: values.vehicleTaxAmount || 0
        }
      });
    } catch (err) {
      captureErrorException(err);
      if (err instanceof Error) {
        snackbar({ message: err.message, variant: SnackBarVariant.ERROR });
      }
    }
  }

  function handleTrailerFormSubmit(
    vehicleTestReportValues: IVehicleTestReport
  ) {
    try {
      setVehicleTestReportValues({
        ...vehicleTestReportValues
      });
      updateTrailers({
        variables: {
          vehicleId: props.vehicle.id,
          updateVehicleTestReport: {
            testDate: vehicleTestReportValues.testDate,
            testResult: vehicleTestReportValues.testResult,
            vehicleTestDueDate: vehicleTestReportValues.vehicleTestDueDate
          }
        }
      });
    } catch (err) {
      captureErrorException(err);
      if (err instanceof Error) {
        snackbar({ message: err.message, variant: SnackBarVariant.ERROR });
      }
    }
  }
  return (
    <Grid container>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} spacing={2}>
          <Formik
            enableReinitialize
            initialValues={values}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleFormSubmit(values);
              setSubmitting(false);
              resetForm(values);
            }}
          >
            {(formikProps) => {
              return (
                <React.Fragment>
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">
                          Vehicle Tax Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormControl variant="outlined" fullWidth>
                              <Field
                                component={InputField}
                                label="Vehicle Tax"
                                name={"vehicleTaxAmount"}
                                value={formikProps.values.vehicleTaxAmount || 0}
                                InputProps={{
                                  inputComponent: FloatInput as any
                                }}
                                inputProps={{
                                  allowNegative: false,
                                  hasCurrencyPrefix: true
                                }}
                                fullWidth
                              ></Field>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <Fab
                            variant="extended"
                            color="primary"
                            aria-label="save"
                            type="submit"
                            size="medium"
                            disabled={!formikProps.dirty}
                          >
                            Save
                          </Fab>
                        </Grid>
                        <Grid item>
                          {showLoading && <CircularProgress size={25} />}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                </React.Fragment>
              );
            }}
          </Formik>
        </Grid>

        {props.vehicle.vehicleType &&
          props.vehicle.vehicleType === VehicleTypes.TRAILER && (
            <Grid item xs={12} spacing={2}>
              <Formik
                initialValues={vehicleTestReportValues}
                validationSchema={trailerValidationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleTrailerFormSubmit(values);
                  setSubmitting(false);
                  resetForm();
                }}
              >
                {(formikProps) => {
                  return (
                    <React.Fragment>
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h2">
                              Update MOT Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControl variant="outlined" fullWidth>
                                  <FlatPickerBar
                                    enableTime={false}
                                    name="testDate"
                                    handleDateChange={(value: Date) => {
                                      if (value) {
                                        formikProps.setValues({
                                          ...formikProps.values,
                                          testDate: d.fromJSDate(value).toFormat('d MMMM yyyy').toUpperCase()
                                        });
                                      }
                                    }}
                                    label={"Test Date"}
                                    identifier={"testDate"}
                                    placeholderValue={"Test Date"}
                                    value={ d.fromFormat(formikProps.values.testDate, "d MMMM yyyy").toUTC().toISO()}
                                    maxDate={d.now().toJSDate()}
                                    country={country}
                                    required
                                  />
                                </FormControl>
                                <ErrorMessage
                                name="testDate"
                                component="div"
                                className="field-error"
                                >
                                  {msg => <div className={"field-error-message"}>{msg}</div>}
                                </ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControl variant="outlined" fullWidth>
                                  <FlatPickerBar
                                    enableTime={false}
                                    name="vehicleTestDueDate"
                                    handleDateChange={(value: Date) => {
                                      if (value) {
                                        formikProps.setValues({
                                          ...formikProps.values,
                                          vehicleTestDueDate: d.fromJSDate(value).toFormat('d MMMM yyyy').toUpperCase()
                                        });
                                      }
                                    }}
                                    label={"Expiry Date"}
                                    identifier={"expiryDate"}
                                    placeholderValue={"Expiry Date"}
                                    value={
                                      d.fromFormat(formikProps.values.vehicleTestDueDate, "d MMMM yyyy").toUTC().toISO()}
                                      minDate={getLocalizedDateFormat(
                                        country,
                                        d
                                          .now()
                                          .toUTC()
                                          .toISO(),
                                        DATE_TYPE.CONDENSED
                                      )}
                                    country={country}
                                    required
                                  />
                                </FormControl>
                                <ErrorMessage
                                name="vehicleTestDueDate"
                                component="div"
                                className="field-error"
                                >
                                  {msg => <div className={"field-error-message"}>{msg}</div>}
                                </ErrorMessage>
                              </Grid>
                              <Grid item container xs={12} sm={6} md={3}>
                                <FormControl variant="outlined" fullWidth>
                                  <InputLabel id="vehicle-type-select">
                                    Test Result
                                  </InputLabel>
                                  <Select
                                    labelId="vehicle-type-label"
                                    name="testResult"
                                    id="vehicle-type-select"
                                    label="Test Result"
                                    value={formikProps.values.testResult}
                                    required
                                    onChange={(event) => {
                                      const testResult = event.target
                                        .value as TestResult;

                                      formikProps.setValues({
                                        ...formikProps.values,
                                        testResult
                                      });
                                    }}
                                  >
                                    <MenuItem value={TestResult.PASS}>
                                      PASS
                                    </MenuItem>
                                    <MenuItem value={TestResult.FAIL}>
                                      FAIL
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                <ErrorMessage
                                name="testResult"
                                component="div"
                                className="field-error"
                                >
                                  {msg => <div className={"field-error-message"}>{msg}</div>}
                                </ErrorMessage>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item>
                              <Fab
                                variant="extended"
                                color="primary"
                                aria-label="save"
                                type="submit"
                                size="medium"
                                disabled={!formikProps.dirty || updateTrailerLoading}
                              >
                                Save
                              </Fab>
                            </Grid>
                            <Grid item>
                              {updateTrailerLoading && <CircularProgress size={25} />}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    </React.Fragment>
                  );
                }}
              </Formik>
            </Grid>
          )}

            <Grid container item xs={12} spacing={2} style={{ marginTop: 20 }}>
              <Grid item container xs={3}>
                <div>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}> MOT Details: </span>
                    {values.vehicleTestDetails
                      ? values.vehicleTestDetails
                      : "N/A"}
                  </Typography>
                </div>
              </Grid>
              <Grid item container xs={2}>
                <div>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}> Tax Status: </span>
                    {!values.taxStatus || values.taxStatus === "Untaxed"
                      ? "Non-Taxed"
                      : values.taxStatus}
                  </Typography>
                </div>
              </Grid>
              <Grid item container xs={2}>
                <div>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}> Tax Date: </span>
                    {values.taxDetails ? values.taxDetails : "N/A"}
                  </Typography>
                </div>
              </Grid>
              <Grid item container xs={5} justifyContent="flex-end">
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="add"
                  type="button"
                  disabled={loadingVehicleTaxDetails}
                  style={{ marginRight: 20 }}
                  onClick={() => {
                    if (values && values.id) {
                      getVehicleTaxDetails({
                        variables: {
                          licencePlate: values.licencePlate
                        }
                      });
                    }
                  }}
                >
                  Refresh Tax
                  {loadingVehicleTaxDetails && (
                    <CircularProgress style={{ padding: 10 }} />
                  )}
                </Fab>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="add"
                  type="button"
                  disabled={loadingMOTDetails}
                  onClick={() => {
                    if (values && values.id) {
                      getMOTDetails({
                        variables: {
                          licencePlate: values.licencePlate
                        }
                      });
                    }
                  }}
                >
                  Refresh MOT
                  {loadingMOTDetails && (
                    <CircularProgress style={{ padding: 10 }} />
                  )}
                </Fab>
              </Grid>
            </Grid>
        <Grid container item xs={12}>
          <Typography variant="h3">MOT Reports</Typography>
          <Hidden xsDown>
            <DevExpressGrid
              rows={values.vehicleTestReports ? values.vehicleTestReports : []}
              columns={columns}
            >
              <MotResultTypeProvider for={["testResult"]} />
              <SortingState sorting={sorting} onSortingChange={setSorting} />
              <IntegratedSorting />
              <RowDetailState
                expandedRowIds={expandedRowIds}
                onExpandedRowIdsChange={setExpandedRowIds}
              />
              <Table />
              <TableHeaderRow showSortingControls />
              <TableRowDetail contentComponent={MotRowDetail} />
            </DevExpressGrid>
          </Hidden>
          <Hidden smUp>
            {values.vehicleTestReports &&
              values.vehicleTestReports.length === 0 && (
                <Grid xs={12} item>
                  <Typography variant="h4">No MOT Data</Typography>
                </Grid>
              )}
            {values.vehicleTestReports &&
              values.vehicleTestReports.map(
                (testReport: IVehicleTestReport, index: number) => {
                  return (
                    <Grid container item xs={12} justifyContent="center">
                      <Grid item>
                        <MobileMotCard row={testReport} index={index} />
                      </Grid>
                    </Grid>
                  );
                }
              )}
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MotResultTypeProvider = (props: any) => (
  <DataTypeProvider formatterComponent={MotResultFormatter} {...props} />
);

const MotResultFormatter = (item: any) => {
  const classes = useStyles();

  return (
    <div
      className={
        item.value.toLowerCase() === "pass" ? classes.green : classes.red
      }
    >
      {item.value}
    </div>
  );
};
