import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from '@mui/icons-material/Close';
import styles from "../vehicle/index.module.css"
import { SelectButton } from '../utils';
import TextField from '@mui/material/TextField';
import { withStyles, makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import MenuItem from '@mui/material/MenuItem';
import api from "./api";
import { CircularProgress, Theme } from '@mui/material';
import { DateTime as d } from 'luxon';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { useSnackBar } from '../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../common/SnackbarWrapper/SnackbarWrapper';
import { useLazyQuery } from '@apollo/client';
import { GET_VEHICLES_FOR_EMISSIONS } from '../../../../graphql/fleet/getVehiclesQuery';
import { IVehicle } from '../../../../reducers/fleet/types';
import { ApolloError } from '@apollo/client';
import { formatGraphQLErrorMessage } from '../../../common/utils';
import { captureErrorException } from '../../../../utils/sentry';

const StyledInput = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#f6f6f6',
      fontSize: 12,
      padding: "8px 8px 5px 8px !important",
      border: 0,
      borderRadius: 5,
    },
    '& fieldset': {
      border: 0
    }
  }
})(TextField);

const colors = ["#003f5c", "#2f4b7c", "#665191", "#a05195", "#d45087", "#f95d6a", "#ff7c43", "#ffa600", "#ec7c9e", "#e43d60"]

interface IChartData {
  id: string;
  color: string;
  data: {
    x: string
    y: number
  }[]
}

const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    marginTop: 3,
    height: 20,
    padding: '.15em 4px',
    fontWeight: 600,
    lineHeight: '15px',
    borderRadius: 2,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  iconSelected: {
    width: 12,
    height: 12,
    marginRight: 5,
    marginLeft: -8,
  },
  text: {
    flexGrow: 1,
  },
}));

export const EmissionChart = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const tenantId = userState && userState.currentTenancy ? userState.currentTenancy?.id : userState.tenancy.id;
  const [filters, setfilters] = useState<{ month: string }>({ month: `${d.now().get('month')}` });
  const [vehicles, setVehicles] = useState<Partial<IVehicle>[]>([]);
  const [selectedVehicles, setSelectedVehicles] = useState<Partial<IVehicle>[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [chartData, setChartData] = useState<IChartData[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const [loadVehicles,
    { loading: vehiclesLoading, data: vehiclesData }
  ] = useLazyQuery(
    GET_VEHICLES_FOR_EMISSIONS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      setLoader(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const getEmissionData = async () => {
    try {
      setLoader(true)
      const { data: emissionData } = await api.getEmissionData({
        tenantId: tenantId,
        organisationId: userState.currentOrganisation.id,
        branchId: userState.currentBranch.id,
        startDate: d.utc(d.now().year, parseInt(filters.month), 1).toISO(),
        endDate: d.utc(d.now().year, parseInt(filters.month), 1).endOf("month").toISO(),
        vehicleIds: selectedVehicles.map(v => v.id)
      });
      const cumulativeEmissions: number[] = [];
      for (let index = 0; index < emissionData.data.emissions.length; index++) {
        const emission = emissionData.data.emissions[index] / 1000;
        if (index === 0) {
          cumulativeEmissions[index] = emission;
        } else {
          cumulativeEmissions[index] = cumulativeEmissions[index - 1] + emission;
        }
      }
      setLoader(false);
      let formattedData: IChartData[] = [];
      formattedData.push({
        id: 'daily',
        color: colors[Math.floor(Math.random() * colors.length)],
        data: emissionData.data.dates.map((date: string, idx: number) => {
          return {
            x: d.fromISO(date).day,
            y: Math.floor(emissionData.data.emissions[idx] / 1000)
          }
        })
      })
      formattedData.push({
        id: 'cumulative',
        color: colors[Math.floor(Math.random() * colors.length)],
        data: emissionData.data.dates.map((date: string, idx: number) => {
          return {
            x: d.fromISO(date).day,
            y: Math.floor(cumulativeEmissions[idx])
          }
        })
      })
      setChartData(formattedData)
    }
    catch (error: any) {
      captureErrorException(error)
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading emission data",
          variant: SnackBarVariant.ERROR
        });
      }
      setLoader(false);
    }
  }

  useEffect(() => {
    if (selectedVehicles.length) {
      getEmissionData();
    }
  }, [filters])

  useEffect(() => {
    loadVehicles();
  }, []);

  useEffect(() => {
    if (vehiclesData && vehiclesData.vehicles) {
      const vehicles = [];
      for (let index = 0; index < vehiclesData.vehicles.length; index++) {
        const vehicle = vehiclesData.vehicles[index];
        if (vehicle.telemetryDeviceId) {
          vehicles.push(vehicle);
        }
      }
      const end = vehicles.length > 10 ? 10 : vehicles.length - 1;
      setSelectedVehicles(vehicles.slice(0, end));
      setVehicles(vehicles)
    }
  }, [vehiclesData])

  useEffect(() => {
    setInputValue("");
    if (selectedVehicles.length) {
      getEmissionData();
    }
  }, [selectedVehicles])

  const removeGroup = (licencePlate: string) => {
    let tempArr = [...selectedVehicles];
    const vehicleIndex = tempArr.findIndex(item => item.licencePlate === licencePlate);
    if (vehicleIndex >= 0) {
      tempArr.splice(vehicleIndex, 1)
    }
    setSelectedVehicles(tempArr)
  }

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  return (
    <div className={`${styles.module} ${styles.flexible}`}>
      <div className={styles.chartHeadline} style={{ paddingRight: "calc(40% - 20px)", zIndex: 10 }}>
        <span className="font-medium bold margin-right">Month: </span>
        <div className="flex">
          <SelectButton
            onChange={(month) => setfilters({ month })}
            id="EMISSION_CHART_MONTH_SELECT"
            initialValue={filters.month}
            renderVelue={(value) => months[parseInt(value) - 1]}
          >
            {months.map((month, idx) => (
              <MenuItem value={idx + 1}>
                {month}
              </MenuItem>
            ))}
          </SelectButton>
          {loader && <div className="margin-left flex cross-center">
            <CircularProgress size={20} thickness={5} />
          </div>}
        </div>
      </div>
      <div className={styles.legendWrap} style={{ textAlign: "initial" }}>
        {vehicles && vehicles.length > 0 ?
          <Autocomplete
            freeSolo
            clearOnEscape
            clearOnBlur
            disableClearable
            noOptionsText="No vehicles"
            id="free-solo-fc-search"
            options={vehicles.map(v => v.licencePlate || "")}
            inputValue={inputValue}
            onInputChange={(event: any, value: any) => {
              setInputValue(value)
            }}
            renderOption={(option: any) => {
              return (
                <React.Fragment>
                  <DoneIcon
                    style={{ fontSize: 12, margin: '0 5px 0 -10px', opacity: selectedVehicles.length > 0 && selectedVehicles.findIndex(v => v.licencePlate === option?.key) >= 0 ? 1 : 0 }}
                  />
                  <div className={classes.text}>
                    {option?.key}
                  </div>
                </React.Fragment>
              )
            }}
            value=""
            onChange={(value: any) => {
              const _vehicle = vehicles.find(v => v.licencePlate === value);
              if (_vehicle && selectedVehicles.findIndex(v => v.licencePlate === value) < 0 && selectedVehicles.length < 10) {
                setSelectedVehicles([...selectedVehicles, _vehicle])
              }
              if (selectedVehicles.length === 10) {
                snackbar({
                  message: "Max 10 vehicles can be selected",
                  variant: SnackBarVariant.ERROR
                });
              }
            }}
            onClose={() => setInputValue("")}
            onBlur={() => setInputValue("")}
            renderInput={(params) => (
              <StyledInput
                {...params}
                variant="outlined"
                placeholder={"Add vehicle"}
                InputProps={{ ...params.InputProps, type: "search" }}
              />
            )}
          /> : (
            <>
              {vehiclesLoading ? (
                <div style={{ width: "100%", padding: "50px 0", textAlign: "center" }}>
                  <CircularProgress />
                </div>
              ) : null}
            </>
          )}
        <div className="padding-top"></div>
        {selectedVehicles.length ? selectedVehicles.map((vehicle) => vehicle.licencePlate && (
          <div className={styles.vglegend}>
            <span>{vehicle.licencePlate}</span>
            <div className={styles.remove}>
              <IconButton onClick={() => removeGroup(vehicle.licencePlate || "")} aria-label="delete" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </div>
          </div>
        )) : null}
        <div style={{ marginTop: 20 }}></div>
      </div>

      {chartData.length > 0 && (
        <div className={styles.chartWrap} style={{ width: "100%", display: 'flex' }}>
          <div style={{ display: 'flex', width: 'calc(50% + 100px)', height: '100%' }}>
            <ResponsiveLine
              data={[chartData[1]]}
              margin={{ top: 30, right: 20, bottom: 25, left: 200 }}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
              axisTop={null}
              curve="monotoneX"
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisLeft={{
                tickValues: 8,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Cumulative(KG)',
                legendOffset: -40,
                legendPosition: 'middle'
              }}
              lineWidth={2}
              enableGridX={true}
              enableGridY={false}
              colors={({ id, data, color }) => color}
              pointSize={7}
              pointColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
              pointBorderColor="#000"
              pointLabelYOffset={-12}
              useMesh={true}
              enableSlices="x"
            />
          </div>

          <div style={{ display: 'flex', width: 'calc(50% - 100px)', height: '100%' }}>
            <ResponsiveLine
              data={[chartData[0]]}
              margin={{ top: 30, right: 20, bottom: 25, left: 60 }}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
              axisTop={null}
              curve="monotoneX"
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisLeft={{
                tickValues: 8,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Daily(KG)',
                legendOffset: -40,
                legendPosition: 'middle'
              }}
              lineWidth={2}
              enableGridX={true}
              enableGridY={false}
              colors={({ id, data, color }) => color}
              pointSize={7}
              pointColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
              pointBorderColor="#000"
              pointLabelYOffset={-12}
              useMesh={true}
              enableSlices="x"
            />
          </div>
        </div>
      )}
    </div>
  )
}
