import React, { useState, useCallback, useEffect, useRef } from 'react';
import styles from './index.module.css';
import Flatpickr from "react-flatpickr";
import { DateTime as d } from 'luxon';
import VehicleSelection, { ISelectedVehicle, IVehicleGroupData } from '../Trips/VehicleSelection';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useSnackBar } from '../../../common/SnackBarContext/SnackBarContext';
import { IDtcAlert, IReportRequest, ReportStatus, ReportType } from '../types';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import api from '../api/Reports'
import { SnackBarVariant } from '../../../common/SnackbarWrapper/SnackbarWrapper';
import { Grid, IconButton, Tooltip } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IVehicle } from '../../../../reducers/fleet/types';
import { DATE_TYPE } from '../../../common/utils';
import { getLocalizedDateFormat } from '../../../../utils/localized.syntex';
import { captureErrorException } from '../../../../utils/sentry';

interface IProps {
  active?: boolean;
  vehicles: IVehicle[];
  loadingVehicleGroups: boolean;
  vehicleGroups: IVehicleGroupData[];
}

const Alerts: React.FC<IProps> = ({ active, vehicleGroups, vehicles }) => {
  const snackbar = useSnackBar();
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [filters, setFilters] = useState<{
    startDate: string, endDate: string
  }>({ startDate: "", endDate: "" });

  const [selectedVehicles, setSelectedVehicles] = useState<ISelectedVehicle[]>([]);
  const [loadingReportRequests, setLoadingReportRequests] = useState<boolean>(true);
  const [dtcReportRequests, setDtcReportRequests] = useState<IReportRequest<IDtcAlert>[]>([]);
  const [completedReportRequests, setCompletedReportRequests] = useState<IReportRequest<IDtcAlert>[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<IDtcAlert & { id: string }>();

  const makeCsv = async (event: IDtcAlert, startDate: string, endDate: string) => {
    const separator: string = ',';
    const columns = [
      "Vehicle",
      "Alert type",
      "Alert code",
      "Description",
      "Date and time",
      "Address",
    ]
    const csvContent = `${columns.join(separator)}\n${event?.alerts.map((dtcAlert) => {
      let cells = [
        vehicles.find((vehicle) => vehicle.telemetryDeviceId === dtcAlert.deviceEsn)?.licencePlate || "",
        dtcAlert.eventType || 'DTC',
        dtcAlert.codeType,
        dtcAlert.description,
        getLocalizedDateFormat(country, dtcAlert.eventTime, DATE_TYPE.EXPANDED).replace(',', ''),
        dtcAlert.address.split(",").join(" ")
      ]
      cells.join(separator);
      return cells
    }).join('\n')}`;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const fileName = `DTC-alerts${event?.alerts.length ? event?.alerts[0].deviceEsn : "-no-data"}`
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const requestReport = async () => {
    try {
      const { data }: { data: IReportRequest<IDtcAlert> } = await api.createReportRequest({
        deviceEsns: selectedVehicles.map((vehicle) => vehicle.deviceEsn),
        vehicleIds: [],
        startDate: filters.startDate,
        endDate: filters.endDate,
        reportType: ReportType.DTC_ALERT,
        branchId: userState.currentBranch.id,
        organisationId: userState.currentOrganisation.id,
        tenantId: userState.currentTenancy.id
      });
      setDtcReportRequests([
        data,
        ...dtcReportRequests
      ]);
      if (!timer.current) {
        getReportRequests()
      }
      setSelectedVehicles([])
    }
    catch (error: any) {
      captureErrorException(error)
      setLoadingReportRequests(false);
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading telematics data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  const getReportRequests = async () => {
    try {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      };
      const { data: axiosData } = await api.getReportRequests({
        reportType: ReportType.DTC_ALERT,
        branchId: userState.currentBranch.id,
        organisationId: userState.currentOrganisation.id,
        tenantId: userState.currentTenancy.id
      });
      if (axiosData) {
        let _reportRequests: IReportRequest<IDtcAlert>[] = [...dtcReportRequests]
        axiosData.forEach((request: IReportRequest<IDtcAlert>) => {
          const findIndex = _reportRequests.findIndex(rq => rq.id === request.id)
          if (findIndex >= 0) {
            if (_reportRequests[findIndex].status !== ReportStatus.COMPLETED) {
              _reportRequests = [
                ..._reportRequests.slice(0, findIndex),
                { ..._reportRequests[findIndex], ...request },
                ..._reportRequests.slice(findIndex + 1)
              ]
            }
          } else {
            _reportRequests = [
              ..._reportRequests,
              request
            ]
          }
        });

        _reportRequests = _reportRequests.sort((a, b) => d.fromISO(b.createdAt).toUnixInteger() - d.fromISO(a.createdAt).toUnixInteger());
        setDtcReportRequests(_reportRequests);
        if (_reportRequests.some((request: IReportRequest<IDtcAlert>) => request.status !== ReportStatus.COMPLETED)) {
          timer.current = setTimeout(() => {
            getReportRequests()
          }, 3000)
        }
      }
      return axiosData;
    } catch (error: any) {
      captureErrorException(error)
      if (error?.message === "Request failed with status code 403") {
        snackbar({
          message: "Access denied",
          variant: SnackBarVariant.ERROR
        });
      } else {
        snackbar({
          message: "Problem loading telematics data",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  const getReportRequest = async (requestId: string) => {
    try {
      const { data: axiosData } = await api.getReportRequest(requestId);
      return axiosData;
    } catch (error: any) {
      captureErrorException(error)
      snackbar({
        message: "Problem loading telematics data",
        variant: SnackBarVariant.ERROR
      });
    }
  }

  useEffect(() => {
    getReportRequests()
      .finally(() => setLoadingReportRequests(false))
  }, [])

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])

  useEffect(() => {
    const promises = [];
    for (const dtcReportRequest of dtcReportRequests) {
      if (dtcReportRequest.status === ReportStatus.COMPLETED) {
        const completedReportIndex = completedReportRequests.findIndex((r) => r.id === dtcReportRequest.id);
        if (completedReportIndex < 0) {
          promises.push(getReportRequest(dtcReportRequest.id))
        }
      }
    }
    Promise.all(promises).then((requests: IReportRequest<IDtcAlert>[]) => {
      if (requests.length) {
        setCompletedReportRequests([
          ...completedReportRequests,
          ...requests
        ].sort((a, b) => d.fromISO(b.createdAt).toUnixInteger() - d.fromISO(a.createdAt).toUnixInteger()));
      }
    });
  }, [dtcReportRequests]);

  const handleSetVehicles = useCallback((vehicles: ISelectedVehicle[]) => {
    setSelectedVehicles(vehicles);
    setSelectedEvent(undefined)
  }, []);

  const RenderTripCard = (request: any, event: any, completed: boolean) => {
    return (
      <div
        className={`${styles.result} ${`${selectedEvent?.id}-${selectedEvent?.deviceEsn}` === `${request.id}-${event.deviceEsn}` ? styles.selected : ""} `}
        style={{ marginRight: 2, overflowY: "auto", pointerEvents: !completed ? "none" : "auto" }}
        onClick={() => {
          setSelectedEvent({ id: request.id, ...event });
        }}
        key={`${request.id}-${event.deviceEsn}`}
      >
        <Grid container xs={12} style={{ position: "relative" }}>
          {completed ? <Typography variant="body1" className="flex fill">
            <Typography variant="h4">{vehicles.find((vehicle) => vehicle.telemetryDeviceId === event.deviceEsn)?.licencePlate || ""}</Typography>
            <span style={{ position: "absolute", right: 5 }}>
              {`${event.alerts && event.alerts.length ? event.alerts.length + " Alerts" : "0 Alert"}`}
            </span>
          </Typography> : <Typography variant="body1" className="flex fill">
            <span className="flex fill bold">{vehicles.find((vehicle) => vehicle.telemetryDeviceId === event)?.licencePlate || ""}</span>
            <span style={{ textTransform: "capitalize" }}>{request.status.toLowerCase()}</span>
          </Typography>}
        </Grid>
        <Grid container xs={12} className="flex cross-center" style={{ marginTop: 5 }}>
          <Grid item xs={6}>
            <span className="flex cross-start" style={{ color: "#848484" }}>
              {`${getLocalizedDateFormat(country, request.startDate, DATE_TYPE.CONDENSED)}- ${getLocalizedDateFormat(country, request.endDate, DATE_TYPE.CONDENSED)}`}
            </span>
          </Grid>
        </Grid>
        {
          completed && (
            <Grid item xs={12} justifyContent='space-between' alignItems='center' container style={{ marginTop: 5 }}>
              <span className="flex cross-start" style={{ color: "#848484" }}><strong>Requested at: &nbsp; </strong> {getLocalizedDateFormat(country, request.createdAt, DATE_TYPE.EXPANDED)} </span>
              {event?.alerts?.length > 0 &&
                <Tooltip title={<Typography variant='h5'>Download Report</Typography>}>
                  <IconButton size="small" style={{ marginTop: -13 }} onClick={(e) => {
                    e.stopPropagation();
                    makeCsv(event, request.startDate, request.endDate);
                  }}>
                    <CloudDownloadIcon className="flex-box" style={{ marginTop: 5 }} />
                  </IconButton>
                </Tooltip>
              }
            </Grid>
          )
        }
      </div>
    )
  }
  return (
    <div className={styles.wrapper} style={{ display: active ? 'block' : 'none' }}>
      <div style={{ display: "flex", alignItems: "center", backgroundColor: "black", color: "white", width: "auto", height: "auto", marginTop: -30, marginBottom: 10, marginLeft: 0, fontSize: 10, paddingLeft: 15, paddingTop: 5, paddingRight: 6, paddingBottom: 5 }}>
        <InfoOutlinedIcon style={{ marginRight: "5px" }} fontSize="small" />
        <Typography variant="h4" className="flex fill" style={{ paddingLeft: 10 }}>
          Report generated will be available for 24 hours before it is removed from the list below. Please note that it may take several minutes to prepare the report depending on the date range and number of vehicles selected.
        </Typography>
      </div>
      <div className="flex fill" style={{ margin: "-30px -20px 0 -0px 0px", height: "calc(100vh - 110px)", overflow: "hidden" }}>
        <div className={styles.leftColumn}>
          {vehicleGroups.length > 0 ? <VehicleSelection selectedVehicles={selectedVehicles} vehicleGroups={vehicleGroups} setVehicles={handleSetVehicles} /> : (
            <div className='flex main-center cross-center' style={{ backgroundColor: "White", paddingLeft: 20 }}>
              <p>Either access denied or no vehicle with telematic data found</p>
            </div>
          )}
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.vehicleResults}>
            <div className="flex space-between">
              <div style={{ position: "relative", width: 150, marginBottom: 10 }}>
                <label htmlFor={`TRIPS_DATE_RANGE`} style={{ minWidth: 140 }} className={styles.filterInput}>{filters.startDate ? getLocalizedDateFormat(country, filters.startDate, DATE_TYPE.CONDENSED) : "From date"} to {filters.endDate ? getLocalizedDateFormat(country, filters.endDate, DATE_TYPE.CONDENSED) : "To date"}</label>
                <div style={{ position: "absolute", left: 0, right: 0, }}>
                  <Flatpickr
                    options={{
                      mode: "range",
                      static: true,
                      minuteIncrement: 1,
                      dateFormat: country === "United States" ? "m/d/Y" : "d/m/Y",
                      defaultDate: [filters.startDate, filters.endDate],
                      maxDate: d.now().toUTC().toISO()
                    }}
                    onChange={(dates) => {
                      if (dates && dates[0] && dates[1]) {
                        const from = d.fromFormat(`${dates[0].getFullYear()}/${dates[0].getMonth() + 1}/${dates[0].getDate()}`, "yyyy/M/d")
                        const end = d.fromFormat(`${dates[1].getFullYear()}/${dates[1].getMonth() + 1}/${dates[1].getDate()}`, "yyyy/M/d").toUTC().toISO()
                        const dayRangeCount = d.fromISO(end).diff(from, "days").days
                        if (dayRangeCount <= 14) {
                          setFilters({
                            ...filters,
                            startDate: d.fromFormat(`${dates[0].getFullYear()}/${dates[0].getMonth() + 1}/${dates[0].getDate()}`, "yyyy/M/d").toUTC().toISO(),
                            endDate: dates[1] ? d.fromFormat(`${dates[1].getFullYear()}/${dates[1].getMonth() + 1}/${dates[1].getDate()}`, "yyyy/M/d").toUTC().toISO() : ""
                          })
                        } else {
                          setFilters({
                            startDate: "",
                            endDate: ""
                          })
                          return (
                            snackbar({
                              message: "Selection must be 15 or less than 15 days.",
                              variant: SnackBarVariant.ERROR
                            })
                          )
                        }
                      }
                    }
                    }
                    render={
                      ({ defaultValue, value, ...props }: any, ref: any) => (
                        <input id={`TRIPS_DATE_RANGE`} {...props} defaultValue={defaultValue} ref={ref} style={{ height: 0, width: 0, overflow: "hidden", opacity: 0, position: "absolute", left: -100, top: 30 }} />
                      )
                    }
                  />
                </div>
              </div>
              <Button variant="outlined" size="small" color='primary' disabled={!selectedVehicles.length} style={{ marginBottom: 10 }} onClick={() => {
                requestReport()
              }}>
                <Typography variant="body2" style={{ textTransform: "initial" }}>Generate</Typography>
              </Button>
            </div>
            {
              !loadingReportRequests ? (
                <div className={styles.scroll} style={{ display: "flex-fill" }}>
                  {dtcReportRequests.length ? [...dtcReportRequests, ...completedReportRequests].map((request) => {
                    if (request.status !== ReportStatus.COMPLETED) {
                      return request.deviceEsns.map(esn => {
                        return RenderTripCard(request, esn, false)
                      })
                    } else {
                      if (request.data) {
                        return request.data.map(event => {
                          return RenderTripCard(request, event, true)
                        })
                      }
                    }
                  }) :
                    <div className={`${styles.result} `}>
                      <Typography variant="body2">{selectedVehicles.length ? "No data found!" : "Please select vehicles from left panel to generate report"}</Typography>
                    </div>
                  }
                </div>
              ) : <CircularProgress size={20} thickness={5} />
            }
          </div>
          {selectedEvent && <div className={styles.rightSub}>
            <div className={`${styles.scroll} flex fill`}>
              <div className="flex fill col-flex padding">
                <Typography variant="h4" className="margin-bottom padding-bottom">Alerts for:  {vehicles.find((vehicle) => vehicle.telemetryDeviceId === selectedEvent.deviceEsn)?.licencePlate || ""}</Typography>
                {selectedEvent?.alerts && selectedEvent.alerts.length > 0 ? <table className={styles.tripTable}>
                  <table className={styles.alertTable}>
                    <tr>
                      <th>Vehicle</th>
                      <th>Alert type</th>
                      <th>Alert code</th>
                      <th>Description</th>
                      <th>Date and time</th>
                      <th>Address</th>
                    </tr>
                    {selectedEvent.alerts.map((alert) => {
                      return (
                        <tr
                          className={`${styles.trip}`}
                        >
                          <td>
                            <Typography variant="h4">{vehicles.find((vehicle) => vehicle.telemetryDeviceId === selectedEvent.deviceEsn)?.licencePlate || ""}</Typography>
                          </td>
                          <td>
                            <Typography variant="body1">{alert.eventType || ""}</Typography>
                          </td>
                          <td>
                            <Typography variant="body2">{alert.codeType}</Typography>
                          </td>
                          <td>
                            <Typography variant="body2">{alert.description}</Typography></td>
                          <td>
                            <Typography variant="body2">{getLocalizedDateFormat(country, alert.eventTime, DATE_TYPE.EXPANDED)}</Typography></td>
                          <td>
                            <Typography variant="body2">{alert.address}</Typography></td>
                        </tr>
                      )
                    })}
                  </table>
                </table>
                  : <div >
                    <Typography variant="body2">No alerts available for selected vehicle on selected time period</Typography>
                  </div>
                }
              </div>
            </div>
          </div>}
        </div>
      </div >
    </div >
  );
}

export default Alerts

