import { gql } from "@apollo/client";

export const CREATE_CHECK_INFO = gql`
  mutation addVehicleCheck(
    $bookingScheduleId: String!
    $type: String!
    $checkInfo: CheckInfoInput!
  ) {
    addVehicleCheck(
      bookingScheduleId: $bookingScheduleId
      type: $type
      checkInfo: $checkInfo
    ) {
      vehicleState {
        exterior {
          front {
            description
            images
          }
          rear {
            description
            images
          }
          top {
            description
            images
          }
          right {
            description
            images
          }
          left {
            description
            images
          }
        }
        interior {
          frontSeat {
            clean
            controls
            seatBelt
          }
          rearSeat {
            clean
            controls
            seatBelt
          }
          lockAndSwitch {
            frontDoor
            rearDoor
            frontWindow
            rearWindow
            childLock
          }
          images
        }
        generalChecks{
          name
          status
          images
        }
        defaultAccesories{
          name
          status
          images
        }
        bookedAccesories {
          name
          type
          quantity
          description
        }
        tyre {
          frontLeft {
            condition
            pressure
            description
          }
          frontRight {
            condition
            pressure
            description
          }
          rearLeft {
            condition
            pressure
            description
          }
          rearRight {
            condition
            pressure
            description
          }
        }
        odometerReading
        fuelStatus
      }
      customerInfo {
        tncCheck
        name
        signature
      }
    }
  }
`;
