import { IVehicleDamage } from "../../../reducers/damageVehicle/types";
import {
  IVehicle,
  IVehicleFinance,
  IVehicleService
} from "../../../reducers/fleet/types";
import {
  DELETE_VEHICLE,
  RESET_VEHICLE,
  RESET_VEHICLE_DAMAGE,
  RESET_VEHICLE_FINANCE,
  RESET_VEHICLE_SERVICE,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_DAMAGE,
  UPDATE_VEHICLE_FINANCE,
  UPDATE_VEHICLE_SERVICE,
  VehicleAction,
  VehicleDamageAction,
  VehicleFinanceAction,
  VehicleServiceAction
} from "./types";

export function updateVehicle(payload: IVehicle): VehicleAction {
  return {
    payload,
    type: UPDATE_VEHICLE
  };
}

export function deleteVehicle(payload: IVehicle): VehicleAction {
  return {
    payload,
    type: DELETE_VEHICLE
  };
}

export function resetVehicle(): VehicleAction {
  return {
    type: RESET_VEHICLE
  };
}

export function updateVehicleService(payload: IVehicleService) {
  return {
    payload,
    type: UPDATE_VEHICLE_SERVICE
  };
}

export function updateVehicleFinances(payload: IVehicleFinance) {
  return {
    payload,
    type: UPDATE_VEHICLE_FINANCE
  };
}

export function resetVehicleFinance(): VehicleFinanceAction {
  return {
    type: RESET_VEHICLE_FINANCE
  };
}

export function resetVehicleService(): VehicleServiceAction {
  return {
    type: RESET_VEHICLE_SERVICE
  };
}

export function updateVehicleDamage(
  payload: IVehicleDamage
): VehicleDamageAction {
  return {
    payload,
    type: UPDATE_VEHICLE_DAMAGE
  };
}

export function resetVehicleDamage(): VehicleDamageAction {
  return {
    type: RESET_VEHICLE_DAMAGE
  };
}
