import { Grid } from "@mui/material";
import React from "react";
import { useVirtualizer } from '@tanstack/react-virtual';
import MobilePriceRuleCard from "./MobilePriceRuleCard";

interface IRow {
  id: string;
  name: string;
  operator: string;
  customers: number;
  vehicleGroups: number;
  status: string;
}

export const PriceRulesVirtualScroller = ({ data }: { data: IRow[] }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 166
  })


  return (
    <>
      {/* Scrollable Parent List  */}
      <Grid item xs={12}
        ref={parentRef}
        className="List"
        style={{
          height: `calc(100vh - 250px)`,
          width: 'calc(100vw - 40px)',
          overflow: 'auto',
        }}
      >
        {/* Large inner element for items */}
        <Grid container item xs={12} spacing={2}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            top: 10,
            left: 10,
            position: 'relative',
          }}
        >
          {/* Virtualized Items */}
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <Grid container item xs={12} spacing={2}
              key={virtualItem.key}
              style={{
                position: 'absolute',
                width: '100%',
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <MobilePriceRuleCard
                id={data[virtualItem.index].id}
                name={data[virtualItem.index].name}
                operator={data[virtualItem.index].operator}
                customers={data[virtualItem.index].customers}
                vehicleGroups={data[virtualItem.index].vehicleGroups}
                status={data[virtualItem.index].status}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default PriceRulesVirtualScroller;