// import "./index.scss";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Checkbox,
  CssBaseline,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField as TextInput,
  Theme,
  Tooltip,
  Typography,
  Hidden,
  CircularProgress
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  addServiceLocation,
  resetServiceLocation,
  setServiceLocations,
  updateServiceLocation
} from "../../../../../actions/organisation/actions";
import { CREATE_SERVICELOCATION } from "../../../../../graphql/organisation/createServiceLocationMutation";
import { GET_LOCATIONS } from "../../../../../graphql/organisation/getLocationsQuery";
import { GET_BRANCH_TAXES } from "../../../../../graphql/organisation/getTaxesQuery";
import { UPDATE_SERVICELOCATION } from "../../../../../graphql/organisation/updateServiceLocationMutation";
import { taxRateTypes, taxTypes } from "../../../../../reducers/organisation/const";
import {
  IBranch,
  ILocationSurchargeInput,
  IOrganisation,
  IServiceLocation,
  TaxValueType
} from "../../../../../reducers/organisation/types";
import { IAddress } from "../../../../../reducers/user/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import AddressPicker from "../../../CustomerManagement/NewCustomer/AddressPicker/AddressPicker";
import { ConfirmationDialog } from "./ConfirmationDialog";
import ServiceLocationList from "./serviceLocationList";
import MobileServiceLocationCards from "./MobileServiceLocationCards";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    }
  })
);

const active = {
  backgroundColor: "#fafafa",
  cursor: "pointer"
} as React.CSSProperties;

const inactive = {
  backgroundColor: "#ffffff",
  cursor: "pointer"
} as React.CSSProperties;

interface IServiceLocationProps {
  organisation: IOrganisation;
  branches: IBranch[];
  serviceLocation: IServiceLocation;
  step: number;
}

interface IBranchTaxes {
  id: string;
  title: string;
}

const ServiceLocation = (props: IServiceLocationProps) => {
  const newProps = props;
  const serviceLocationToUpdate = useSelector(
    (state: any) => state.organisationReducer.serviceLocation
  );
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [locations, setLocations] = useState([]);
  const [branches, setBranches] = useState<IBranch[]>([]);
  const classes = useStyles();
  const snackbar = useSnackBar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState(props.serviceLocation);
  const [openSurchargeDialog, setOpenSurchargeDialog] = useState<boolean>(
    false
  );
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState<boolean>(false);

  const [loadServiceLocations, { data: serviceLocations }] = useLazyQuery(
    GET_LOCATIONS,
    {
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    loadServiceLocations({
      variables: {
        organisationId: props.organisation.id
      }
    });
  }, []);

  useEffect(() => {
    if (serviceLocations && serviceLocations.locations) {
      setLocations(serviceLocations.locations);
    }
  }, [serviceLocations]);

  useEffect(() => {
    if (props.branches) {
      setBranches(props.branches);
    }
  }, [props.branches]);

  const [addServiceLocationMutation, { loading: saveServicelocLoading }] = useMutation(CREATE_SERVICELOCATION, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      if (data && data.createServiceLocation) {
        snackbar({
          message: "Service Location created",
          variant: SnackBarVariant.SUCCESS
        });
        navigate(
          `/update-organisation?organisation=${props.organisation.id}&step=${props.step}`
        );
        dispatch(addServiceLocation(data.createServiceLocation));
        dispatch(resetServiceLocation());
        setValues(props.serviceLocation);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [updateServiceLocationMutation, { loading: updateServiceLocLoading }] = useMutation(UPDATE_SERVICELOCATION, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      if (data && data.updateServiceLocation) {
        snackbar({
          message: "Service Location updated",
          variant: SnackBarVariant.SUCCESS
        });
        navigate(
          `/update-organisation?organisation=${props.organisation.id}&step=${props.step}`
        );
        dispatch(updateServiceLocation(data.updateServiceLocation));
        dispatch(resetServiceLocation());
        setIsUpdateSuccessful(true);
        setValues(props.serviceLocation);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (serviceLocationToUpdate) {
      const addressValues = Object.values(serviceLocationToUpdate.address);
      setValues({
        address: serviceLocationToUpdate.address
          ? serviceLocationToUpdate.address
          : {
            city: "",
            country: "",
            fullAddress: "",
            state: "",
            street: "",
            zipcode: ""
          },
        branch: serviceLocationToUpdate.branch
          ? serviceLocationToUpdate.branch.id
          : "",
        id: serviceLocationToUpdate.id ? serviceLocationToUpdate.id : "",
        name: serviceLocationToUpdate.name ? serviceLocationToUpdate.name : "",
        addSurchargesOptIn: serviceLocationToUpdate && serviceLocationToUpdate.surcharges && serviceLocationToUpdate.surcharges.length
          && serviceLocationToUpdate.addSurchargesOptIn ? true : false,
        surcharges: serviceLocationToUpdate.surcharges || [],
        isDisabled: serviceLocationToUpdate.isDisabled || false
      });
    }
  }, [serviceLocationToUpdate]);

  const saveServiceLocation = (values: IServiceLocation) => {
    if (!values.address || !values.branch) {
      snackbar({
        message: "Service Location address and branch required!",
        variant: SnackBarVariant.ERROR
      });
      return;
    }
    if (serviceLocationToUpdate && serviceLocationToUpdate.id) {
      updateServiceLocationMutation({
        variables: {
          serviceLocation: {
            address: values.address,
            branchId: values.branch,
            name: values.name.trim(),
            addSurchargesOptIn: values.addSurchargesOptIn,
            surcharges: values.surcharges,
            isDisabled: values.isDisabled
          },
          serviceLocationId: serviceLocationToUpdate.id,
          organisationId: props.organisation.id
        }
      }).then(() => {
        loadServiceLocations({
          variables: {
            organisationId: props.organisation.id
          }
        });
      });
    } else {
      delete values.id;
      addServiceLocationMutation({
        variables: {
          branchId: values.branch,
          organisationId: props.organisation.id,
          serviceLocation: {
            address: values.address,
            name: values.name.trim(),
            addSurchargesOptIn: values.addSurchargesOptIn,
            surcharges: values.surcharges,
            isDisabled: values.isDisabled
          }
        }
      }).then(() => {
        loadServiceLocations({
          variables: {
            organisationId: props.organisation.id
          }
        });
      });
    }
  };

  const serviceLocationSchema = Yup.object().shape({
    name: Yup.string().required("Service location is required."),
    branch: Yup.string().required("Please select a branch."),
    address: Yup.object()
      .shape({
        fullAddress: Yup.string().required("Address is required"),
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required"),
        zipcode: Yup.string().required("Zip/Postal Code is required")
      })
      .required("Address is required")
  });

  const handleSurchargeRemove = () => {
    setValues({
      ...values,
      addSurchargesOptIn: false,
      surcharges: []
    });
    setOpenSurchargeDialog(false);
  };

  return (
    <Grid container item xs={12} className={classes.root}>
      <CssBaseline />
      <Formik
        enableReinitialize
        validationSchema={serviceLocationSchema}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          if (values.surcharges && values.surcharges.length) {
            values.surcharges = values.surcharges.map((surcharge) => {
              let updatedTax = { ...surcharge.tax };
              if (surcharge.taxable) {
                updatedTax.type = "PERCENTAGE";
              } else {
                updatedTax.type = "";
              }
              return {
                ...surcharge,
                tax: updatedTax
              };
            });
          }
          saveServiceLocation(values);
          setSubmitting(false);
        }}
      >
        {(props) => (
          <Form style={{ flexGrow: 1 }}>
            <Grid item container xs={12} spacing={2}>
              <Grid item container xs={12} justifyContent="end">
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.values.isDisabled}
                      name={"isDisabled"}
                      value={"isDisabled"}
                      onChange={(event: any) => {
                        setValues({ ...props.values, isDisabled: event.target.checked });
                      }}
                    />
                  }
                  label="Disable"
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Field
                  component={TextField}
                  placeholder="Location Name"
                  label="Location Name"
                  name={"name"}
                  value={props.values.name}
                  onChange={props.handleChange}
                  inputProps={{ maxLength: 75 }}
                  fullWidth
                  required
                ></Field>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    component={TextField}
                    name={"branch"}
                    fullWidth
                    select
                    label="Associated Branch"
                    InputLabelProps={{
                      shrink: true
                    }}
                    required
                    InputProps={{
                      onChange: (event: any) => {
                        setValues({
                          ...props.values,
                          branch: event.target.value
                        });
                      },
                      value: props.values.branch
                    }}
                  >
                    {branches &&
                      branches.length &&
                      branches.map((branch: IBranch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          {branch.name}
                        </MenuItem>
                      ))}
                  </Field>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <AddressPicker
                  fieldName={"address"}
                  required={true}
                  addGeometry={true}
                  onChange={(address: IAddress) => {
                    if (address) {
                      setValues({ ...props.values, address });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.values.addSurchargesOptIn}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (!event.target.checked) {
                          setOpenSurchargeDialog(true);
                        }
                        if (
                          event.target.checked &&
                          !props.values.surcharges.length
                        ) {
                          setValues({
                            ...props.values,
                            addSurchargesOptIn: event.target.checked,
                            surcharges: [
                              {
                                rate: "ONE_TIME",
                                type: "",
                                taxable: false,
                                title: "",
                                value: 0,
                                tax: {
                                  title: "",
                                  value: 0
                                }
                              }
                            ]
                          });
                        }
                      }}
                      name="addSurchargesOptIn"
                      color="primary"
                    />
                  }
                  style={{
                    marginLeft: "0px",
                    marginBottom: "10px"
                  }}
                  labelPlacement="end"
                  label={
                    <Typography variant="h3" color="primary">
                      ADD LOCATION BASED SURCHARGE(S)
                    </Typography>
                  }
                />
              </Grid>
              {props.values.addSurchargesOptIn && (
                <Grid item xs={12} container>
                  <Grid item xs={12} container justifyContent="flex-end">
                    <Tooltip title="Add new Surcharge item">
                      <IconButton
                        aria-label="add"
                        onClick={() => {
                          setValues({
                            ...values,
                            surcharges: [
                              ...values.surcharges,
                              {
                                rate: "ONE_TIME",
                                type: "",
                                taxable: false,
                                title: "",
                                value: 0,
                                tax: {
                                  title: "",
                                  value: 0
                                }
                              }
                            ]
                          });
                        }}
                        size="large">
                        <AddCircleIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {values.surcharges &&
                    values.surcharges.length > 0 &&
                    values.surcharges.map(
                      (surCharge: ILocationSurchargeInput, index: number) => {
                        return (
                          <Paper
                            variant="outlined"
                            key={index}
                            style={{
                              flexGrow: 1,
                              padding: 15,
                              marginBottom: 10,
                              width: "100%"
                            }}
                          >
                            <Grid item xs={12} container spacing={2}>
                              <Grid item xs={12} sm={6} md={3}>
                                <Field
                                  component={TextField}
                                  name={`${values.surcharges[index]}.title`}
                                  placeholder="Surcharge Title"
                                  label="Surcharge Title"
                                  InputProps={{
                                    onChange: (event: any) => {
                                      const updatedSurcharges = [
                                        ...values.surcharges.slice(0, index),
                                        {
                                          ...values.surcharges[index],
                                          title: event.target.value
                                        },
                                        ...values.surcharges.slice(index + 1)
                                      ];
                                      setValues({
                                        ...values,
                                        surcharges: updatedSurcharges
                                      });
                                    },
                                    value: surCharge.title
                                  }}
                                  inputProps={{ maxLength: 75 }}
                                  fullWidth
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Field
                                  component={TextField}
                                  name={`${values.surcharges[index]}.type`}
                                  placeholder="type"
                                  label="Type"
                                  inputProps={{
                                    value: surCharge.type,
                                    onChange: (event: any) => {
                                      const updatedTaxes = [
                                        ...values.surcharges.slice(0, index),
                                        {
                                          ...values.surcharges[index],
                                          type: event.target.value
                                        },
                                        ...values.surcharges.slice(index + 1)
                                      ];
                                      setValues({
                                        ...values,
                                        surcharges: updatedTaxes
                                      });
                                    }
                                  }}
                                  fullWidth
                                  select
                                  required
                                >
                                  {taxTypes.map((type) => {
                                    return (
                                      <MenuItem
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Grid>
                              {surCharge.type === TaxValueType.PERCENTAGE ? (
                                <>
                                  <Grid item container xs={12} sm={6} md={3}>
                                    <Field
                                      component={TextField}
                                      placeholder="e.g 10"
                                      label="Value(%)"
                                      name={`${values.surcharges[index]}.value`}
                                      required
                                      type="number"
                                      InputProps={{
                                        value: surCharge.value,
                                        onChange: (
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          const value = parseFloat(
                                            e.target.value
                                          );
                                          // onChangeAmount(floatValue, index);
                                          if (value > 100) {
                                            snackbar({
                                              message:
                                                "Value can't be more than 100%",
                                              variant: SnackBarVariant.ERROR
                                            });
                                          }
                                          else if (value < 0) {
                                            snackbar({
                                              message:
                                                "Value can't be less than 0%",
                                              variant: SnackBarVariant.ERROR
                                            });
                                          }
                                          else {
                                            const updatedSurcharges = [
                                              ...values.surcharges.slice(0, index),
                                              {
                                                ...values.surcharges[index],
                                                value
                                              },
                                              ...values.surcharges.slice(index + 1)
                                            ];
                                            setValues({
                                              ...values,
                                              surcharges: updatedSurcharges
                                            });
                                          }
                                        },
                                        min: "1"
                                      }}
                                      fullWidth
                                    />
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <Grid item xs={12} sm={6} md={3}>
                                    <Field
                                      component={TextField}
                                      label="Amount"
                                      fullWidth
                                      name={`${values.surcharges[index]}.value`}
                                      InputProps={{
                                        value: surCharge.value,
                                        inputComponent: FloatInput as any,
                                        onChange: (
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          const value = parseInt(e.target.value);
                                          const updatedSurcharges = [
                                            ...values.surcharges.slice(0, index),
                                            {
                                              ...values.surcharges[index],
                                              value
                                            },
                                            ...values.surcharges.slice(index + 1)
                                          ];
                                          setValues({
                                            ...values,
                                            surcharges: updatedSurcharges
                                          });
                                        }
                                      }}
                                      inputProps={{
                                        hasCurrencyPrefix: true,
                                        allowNegative: false,
                                        currency: newProps.organisation.currency
                                      }}
                                    ></Field>
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={12} sm={6} md={3}>
                                <Field
                                  component={TextField}
                                  name={`${values.surcharges[index]}.rate`}
                                  placeholder="rate"
                                  label="Rate"
                                  InputProps={{
                                    onChange: (event: any) => {
                                      const updatedSurcharges = [
                                        ...values.surcharges.slice(0, index),
                                        {
                                          ...values.surcharges[index],
                                          rate: event.target.value
                                        },
                                        ...values.surcharges.slice(index + 1)
                                      ];
                                      setValues({
                                        ...values,
                                        surcharges: updatedSurcharges
                                      });
                                    }
                                  }}
                                  disabled
                                  value={"ONE_TIME"}
                                  inputProps={{ maxLength: 75 }}
                                  fullWidth
                                  select
                                  required
                                >
                                  {taxRateTypes.map((rateType) => {
                                    return (
                                      <MenuItem
                                        key={rateType.value}
                                        value={rateType.value}
                                      >
                                        {rateType.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={surCharge.taxable}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (!e.target.checked) {
                                          const updatedSurcharges = [
                                            ...values.surcharges.slice(
                                              0,
                                              index
                                            ),
                                            {
                                              ...values.surcharges[index],
                                              taxable: e.target.checked,
                                              tax: {
                                                title: "",
                                                value: 0,
                                                type: ""
                                              }
                                            },
                                            ...values.surcharges.slice(
                                              index + 1
                                            )
                                          ];
                                          setValues({
                                            ...values,
                                            surcharges: updatedSurcharges
                                          });
                                        } else {
                                          const updatedSurcharges = [
                                            ...values.surcharges.slice(
                                              0,
                                              index
                                            ),
                                            {
                                              ...values.surcharges[index],
                                              taxable: e.target.checked
                                            },
                                            ...values.surcharges.slice(
                                              index + 1
                                            )
                                          ];
                                          setValues({
                                            ...values,
                                            surcharges: updatedSurcharges
                                          });
                                        }
                                      }}
                                      name={`surcharges[${index}].rate`}
                                      color="primary"
                                    />
                                  }
                                  label="Add Tax"
                                />
                              </Grid>
                              {surCharge.taxable && (
                                <>
                                  <Grid item xs={12} sm={6} md={3}>
                                    <Field
                                      component={TextField}
                                      name={`${values.surcharges[index].tax}.title`}
                                      placeholder="Tax title"
                                      label="Tax title"
                                      InputProps={{
                                        onChange: (event: any) => {
                                          const updatedSurcharges = [
                                            ...values.surcharges.slice(
                                              0,
                                              index
                                            ),
                                            {
                                              ...values.surcharges[index],
                                              tax: {
                                                ...values.surcharges[index]
                                                  .tax,
                                                title: event.target.value
                                              }
                                            },
                                            ...values.surcharges.slice(
                                              index + 1
                                            )
                                          ];
                                          setValues({
                                            ...values,
                                            surcharges: updatedSurcharges
                                          });
                                        },
                                        value: surCharge.tax?.title
                                      }}
                                      inputProps={{ maxLength: 75 }}
                                      fullWidth
                                      required
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={3}>
                                    <Field
                                      component={TextField}
                                      label="Tax Value(%)"
                                      fullWidth
                                      name={`${values.surcharges[index].tax}.value`}
                                      type="number"
                                      InputProps={{
                                        value: surCharge.tax?.value,
                                        onChange: (
                                          e: React.ChangeEvent<
                                            HTMLInputElement
                                          >
                                        ) => {
                                          const value = parseFloat(
                                            e.target.value
                                          );
                                          if (value > 100) {
                                            snackbar({
                                              message:
                                                "Tax Value can't be more than 100%",
                                              variant: SnackBarVariant.ERROR
                                            });
                                          }
                                          else if (value < 0) {
                                            snackbar({
                                              message:
                                                "Tax value can't be less than 0%",
                                              variant: SnackBarVariant.ERROR
                                            });
                                          }
                                          else {
                                            const updatedSurcharges = [
                                              ...values.surcharges.slice(
                                                0,
                                                index
                                              ),
                                              {
                                                ...values.surcharges[index],
                                                tax: {
                                                  ...values.surcharges[index]
                                                    .tax,
                                                  value
                                                }
                                              },
                                              ...values.surcharges.slice(
                                                index + 1
                                              )
                                            ];
                                            setValues({
                                              ...values,
                                              surcharges: updatedSurcharges
                                            });
                                          }
                                        }
                                      }}
                                    ></Field>
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={12} sm={6} md={3}>
                                <Tooltip
                                  title={
                                    values.surcharges.length < 2
                                      ? "Surcharge item can not be removed because if enabled tax one item should be there"
                                      : "Remove Surcharge item"
                                  }
                                >
                                  <IconButton
                                    aria-label="remove"
                                    onClick={() => {
                                      const updatedSurcharges = [
                                        ...values.surcharges.slice(0, index),
                                        ...values.surcharges.slice(index + 1)
                                      ];
                                      setValues({
                                        ...values,
                                        surcharges: updatedSurcharges
                                      });
                                    }}
                                    disabled={values.surcharges.length < 2}
                                    size="large">
                                    <DeleteIcon color="primary" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>

                            </Grid>
                          </Paper>
                        );
                      }
                    )}
                </Grid>
              )}
              <Grid item xs={12}>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="add"
                  type="submit"
                  disabled={saveServicelocLoading || updateServiceLocLoading}
                >
                  {(saveServicelocLoading || updateServiceLocLoading) && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                  Save
                </Fab>
              </Grid>
              <Grid item xs={12} >
                {locations && locations.length > 0 && (
                  <>
                    <Hidden smDown>
                      <ServiceLocationList serviceLocations={locations} isUpdateSuccessful={isUpdateSuccessful} setIsUpdateSuccessful={setIsUpdateSuccessful} />
                    </Hidden>
                    <Hidden mdUp>
                      <MobileServiceLocationCards serviceLocations={locations} />
                    </Hidden>
                  </>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {openSurchargeDialog && (
        <ConfirmationDialog
          open={openSurchargeDialog}
          description="Toggling off the Surcharge section would remove all the surcharge details you have created and can not be retrieved. "
          onClose={() => setOpenSurchargeDialog(false)}
          onConfirm={handleSurchargeRemove}
          title="Remove Surcharges"
        />
      )}
    </Grid>
  );
};
export default ServiceLocation;
