import { gql } from "@apollo/client";

export const MARK_INVOICES_PAID = gql`
  mutation markInvoicesAsPaid($invoiceIds: [String!]!) {
    markInvoicesAsPaid(invoiceIds: $invoiceIds) {
      success
      message
    }
  }
`;
