import { gql } from "@apollo/client";

export const GET_SUBSCRIPTIONS = gql`
  {
    getVehicleSubscriptions {
      id
      name
      rateTypeName
      isActive
      insurancePolicy {
        id
        name
      }
      addons {
        id
        name
        displayName
      }
      isActive
      features
      swapsTnC
      serviceIncluded
      serviceTnC
      subscriptionTnC
      vehicles {
        id
        make
        model
      }
    }
  }
`;

export const GET_SUBSCRIPTIONS_LIST = gql`
  query subscriptionsList($limit: Int!, $offset: Int!){
    subscriptionsList(limit: $limit, offset: $offset) {
      id
      name
      isActive
      insurancePolicy {
        id
        name
      }
      addons {
        id
        name
        displayName
      }
      isActive
      features
      rateTypeName
      swapsTnC
      serviceIncluded
      serviceTnC
      subscriptionTnC
      vehicles {
        id
        make
        model
      }
    }
  }
`;

export const GET_VEHICLE_SUBSCRIPTIONS_COUNT = gql`
  {
    subscriptionsCount
  }
`;
export const SEARCH_SUBSCRIPTIONS = gql`
  query searchSubscriptions($q: String!) {
    searchSubscriptions(q: $q) {
      id
      name
      isActive
      insurancePolicy {
        id
        name
      }
      addons {
        id
        name
        displayName
      }
      isActive
      features
      swapsTnC
      serviceIncluded
      serviceTnC
      subscriptionTnC
      vehicles {
        id
        make
        model
      }
    }
  }
`;
