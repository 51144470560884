import {
  Dialog,
  DialogContent,
  Typography,
  Theme,
  Grid,
  Fab,
  CircularProgress,
} from "@mui/material";
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import React from "react";
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_PROCESS_FOR_IDV_CHECK } from "../../../../graphql/customers/idvCheckMutation";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { ApolloError } from "@apollo/client";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../../common/utils";
import { ICustomer } from "../../../../reducers/customer/types";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { GET_ID_VERIFICATION_PDF } from "../../../../graphql/customers/getIDVerificationPDF";

interface IDialogProps {
  open: boolean,
  handleClose: () => void;
  customer: ICustomer;
  handleCustomerUpdate: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const IDVerificationDialog: React.FC<IDialogProps> = (props) => {
  const { open, handleClose, customer, handleCustomerUpdate } = props;
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const snackbar = useSnackBar();

  const [createProcessForIDV, { loading: processLoading }] = useMutation(CREATE_PROCESS_FOR_IDV_CHECK, {
    onCompleted: (data) => {
      snackbar({
        message: "Email has been sent for Identity Verification",
        variant: SnackBarVariant.SUCCESS
      });
      handleCustomerUpdate();
      handleClose();
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  function b64toBlob(base64: string): Blob {
    const byteCharacters = atob(base64);
    const byteArrays: Uint8Array[] = [];
    const chunkSize = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += chunkSize) {
      const slice = byteCharacters.slice(offset, offset + chunkSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: 'application/pdf' });
  }

  const [downloadIDVReportPdf, { data }] = useLazyQuery(GET_ID_VERIFICATION_PDF, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      if (data && data.getIDVerificationPDF) {
        const pdfBase64 = data.getIDVerificationPDF;
        const pdfBlob = b64toBlob(pdfBase64);

        const pdfUrl = URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = pdfUrl;
        a.target = '_blank';
        a.download = `${props.customer.firstName}_${props.customer.lastName}_verification.pdf`
        document.body.appendChild(a);

        a.click();
        document.body.removeChild(a);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const handleIdvCheck = () => {
    createProcessForIDV({
      variables: {
        customerId: customer.id
      }
    })
  }

  const downloadIDVPdf = () => {
    downloadIDVReportPdf({
      variables: {
        processId: customer.credasIdentityVerification?.processId
      }
    })
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      className={"select-holidays-dialog"}
    >
      <React.Fragment>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p style={{ fontSize: 18, color: "rgba(0, 0, 0, 0.54)", padding: 0, margin: 0 }}>
            IDENTITY VERIFICATION
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container xs={12} spacing={1} >
            {customer.credasIdentityVerification &&
              <>
                <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
                  <Typography variant="h4">Verification Status:  {(customer.credasIdentityVerification.verificationStatus || "").replace("_", " ")}</Typography>
                </Grid>
                <Grid container item xs={12} style={{ paddingBottom: "20px" }}>
                  <Typography variant="h4"> Last Checked: {customer?.credasIdentityVerification?.lastUpdated ? getLocalizedDateFormat(country, customer?.credasIdentityVerification?.lastUpdated, DATE_TYPE.CONDENSED) : "N/A"}</Typography>
                </Grid>
              </>
            }
            <Grid container item xs={12}>
              <Fab
                variant="extended"
                size="medium"
                style={{ color: "white", position: "relative", left: "25%", marginTop: "10px" }}
                onClick={() => {
                  handleIdvCheck()
                }}
                disabled={processLoading}
              >
                {processLoading && (
                  <CircularProgress
                    size={14}
                    style={{ color: "white" }}
                  />
                )}
                RE-SEND VERIFICATION EMAIL
              </Fab>
              {customer.credasIdentityVerification &&
                <IconButton
                  edge="end"
                  aria-label="download"
                  title="Download ID Verification Report"
                  style={{ position: "relative", left: "28%" }}
                  onClick={() =>
                    downloadIDVPdf()
                  }
                >
                  <CloudDownloadIcon />
                </IconButton>
              }
            </Grid>
          </Grid>
        </DialogContent>
      </React.Fragment>
    </Dialog >
  )
}




