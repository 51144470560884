import { BranchPayloadType, OrganisationPayloadType, ServiceLocationPayloadType } from "../../reducers/organisation/types";
import {
  ADD_BRANCH,
  ADD_SERVICELOCATION,
  OrganisationAction,
  RESET_BRANCH,
  RESET_ORGANISATION,
  RESET_SERVICELOCATION,
  SET_BRANCH,
  SET_ORGANISATION,
  SET_SERVICELOCATION,
  SET_SERVICELOCATIONS,
  UPDATE_BRANCH,
  UPDATE_ORGANISATION,
  UPDATE_SERVICELOCATION
} from "./types";

export function updateOrganisation(
  payload: OrganisationPayloadType
): OrganisationAction {
  return {
    payload,
    type: UPDATE_ORGANISATION
  };
}

export function resetOrganisation(): OrganisationAction {
  return {
    type: RESET_ORGANISATION
  };
}

export function setOrganisation(payload: OrganisationPayloadType): OrganisationAction {
  return {
    payload,
    type: SET_ORGANISATION
  };
}

export function addBranch(payload: BranchPayloadType): OrganisationAction {
  return {
    payload,
    type: ADD_BRANCH
  };
}

export function setBranch(payload: BranchPayloadType): OrganisationAction {
  return {
    payload,
    type: SET_BRANCH,
  };
}

export function resetBranch(): OrganisationAction {
  return {
    type: RESET_BRANCH
  };
}

export function updateBranch(payload: BranchPayloadType): OrganisationAction {
  return {
    payload,
    type: UPDATE_BRANCH
  };
}

export function addServiceLocation(payload: ServiceLocationPayloadType): OrganisationAction {
  return {
    payload,
    type: ADD_SERVICELOCATION
  }
}

export function setServiceLocation(payload: ServiceLocationPayloadType): OrganisationAction {
  return {
    payload,
    type: SET_SERVICELOCATION
  };
}

export function resetServiceLocation(): OrganisationAction {
  return {
    type: RESET_SERVICELOCATION
  };
}

export function setServiceLocations(payload: ServiceLocationPayloadType): OrganisationAction {
  return {
    payload,
    type: SET_SERVICELOCATIONS
  }
}
export function updateServiceLocation(payload: ServiceLocationPayloadType): OrganisationAction {
  return {
    payload,
    type: UPDATE_SERVICELOCATION
  }
}
