import { Grid, Paper, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { formatGraphQLErrorMessage } from "../../../common/utils";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { MUIDataTableOptions } from "mui-datatables";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import { useNavigate } from "react-router-dom";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { GET_DAMAGE_MATRIX } from "../../../../graphql/damageMatrix/getDamageMatrix";
import { IComponent, IDamageMatrix } from "./DamageMatrix";
import { debounce } from "lodash";
import styles from './index.module.css';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: "30px"
    },
    table: {
    },
    checkBox: {},
    checkBoxTitle: {
      fontSize: "14px",
      fontWeight: "normal",
      margin: "auto"
    },
    defaultFont: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "normal"
    },
    title: {
      fontWeight: 600
    },
    button: {
      paddingTop: "30px"
    },
    disabledCursor: {
      cursor: "default"
    }
  })
);

const DamageMatrixComponents: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [damageMatrix, setDamageMatrix] = useState<IDamageMatrix | null>(null);
  const [filteredComponents, setFilteredComponents] = useState<IComponent[]>([]);
  const [componentCount, setComponentCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const [getDamageMatrix, { loading }] = useLazyQuery(GET_DAMAGE_MATRIX, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data) {
        navigate('/vehicle-damage-matrix')
      }
      if (data?.damageMatrix) {
        const components = data.damageMatrix.components || [];
        setDamageMatrix(data.damageMatrix);
        setFilteredComponents(components);
        setComponentCount(components.length);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR,
      });
    }
  });

  const columns: any = [
    {
      label: "Part / Component",
      name: "part",
      options:{
        setCellHeaderProps: () => ({
          className: styles.tableHeaderCell
        })
      }
    },
    {
      label: "Damage Type",
      name: "damageType",
      options: {
        setCellHeaderProps: () => ({
          className: styles.tableHeaderCell
        })
      }
    },
    {
      label: "S1",
      name: "s1"
    },
    {
      label: "S2",
      name: "s2"
    },
    {
      label: "S3",
      name: "s3"
    },
    {
      label: "S4",
      name: "s4"
    },
    {
      label: "VAT on Service & Repair(%)",
      name: "vatOnSMR",
      options: {
        customBodyRender: (data: number) => data / 100,
        setCellHeaderProps: () => ({
          className: styles.tableHeaderCell
        })
      }
    },
    {
      label: "Replacement Cost",
      name: "replacementCost",
      options: {
        setCellHeaderProps: () => ({
          className: styles.tableHeaderCell
        })
      }
    },
    {
      label: "VAT on Parts(%)",
      name: "vatOnParts",
      options: {
        customBodyRender: (data: number) => data / 100,
        setCellHeaderProps: () => ({
          className: styles.tableHeaderCell
        })
      }
    },
    {
      label: "Installation Charges",
      name: "installationCharges",
      options: {
        setCellHeaderProps: () => ({
          className: styles.tableHeaderCell
        })
      }
    },
    {
      label: "VAT on Installation(%)",
      name: "vatOnInstallation",
      options: {
        customBodyRender: (data: number) => data / 100,
        setCellHeaderProps: () => ({
          className: styles.tableHeaderCell
        })
      }
    },
    {
      label: "Overall Markup(%)",
      name: "overallMarkup",
      options: {
        customBodyRender: (data: number) => data / 100,
        setCellHeaderProps: () => ({
          className: styles.tableHeaderCell
        })
      }
    },
  ];

  const handleSearchChange = debounce((searchText: string | null) => {
    if (!damageMatrix?.components) return;

    if (searchText) {
      const lowerCasedSearchText = searchText.toLowerCase();
      const filtered = damageMatrix.components.filter((component) =>
        Object.values(component).some((value) =>
          value?.toString().toLowerCase().includes(lowerCasedSearchText)
        )
      );
      setFilteredComponents(filtered);
      setComponentCount(filtered.length);
    } else {
      setFilteredComponents(damageMatrix.components);
      setComponentCount(damageMatrix.components.length);
    }
  }, 1000);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: componentCount,
    rowsPerPage,
    page,
    rowsPerPageOptions: [10, 20, 100],
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: loading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onSearchChange: handleSearchChange,
    onChangeRowsPerPage: (newRowsPerPage: number) => {
      setRowsPerPage(newRowsPerPage);
      setPage(0);
    },
    onChangePage: (newPage: number) => {
      setPage(newPage);
    }
  };

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const damageMatrixId = params.get("id");
      if (damageMatrixId) {
        getDamageMatrix({
          variables: {
            id: damageMatrixId
          }
        });
      }
    }
  }, [location]);

  return (
    <Grid container>
      <Grid container xs={12}>
        <Typography variant="h1" color={"primary"}>Damages / Losses and Related Services</Typography>
      </Grid>
      <Paper className={classes.root} style={{ width: "100%" }} sx={{ mt: 2 }}>
        <Grid container item xs={12} justifyContent={'space-around'}>
          <Grid item xs={2} display={'flex'} alignItems={'center'}>
            <Typography variant="h6" >
              Make:
            </Typography>
            <Typography variant="h4" ml={0.5}>
              {damageMatrix?.make}
            </Typography>
          </Grid>
          <Grid item xs={2} display={'flex'} alignItems={'center'}>
            <Typography variant="h6">
              Model:
            </Typography>
            <Typography variant="h4" ml={0.5}>
              {damageMatrix?.model}
            </Typography>
          </Grid>
          <Grid item xs={2} display={'flex'} alignItems={'center'}>
            <Typography variant="h6">
              Year:
            </Typography>
            <Typography variant="h4" ml={0.5}>
              {damageMatrix?.year}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Grid container xs={12} style={{ marginTop: "1rem" }}>
        <NuvvenTable
          title={""}
          rows={filteredComponents}
          columns={columns}
          setSelection={() => ""}
          options={options}
        />
      </Grid>
    </Grid>
  )
}

export default DamageMatrixComponents;