import { gql } from "@apollo/client";

export const SEARCH_PCN = gql`
  query searchPCN($q: String!) {
    searchPCN(q: $q) {
      id
      referenceNumber
      noticeRef
      receivedAt
      noticeType
      vehicle {
        id
        licencePlate
      }
      location
      noticeToKeeper {
        url
      }
      contraventionType
      contraventionDate
      liabilityTransferred
      totalDue
      paymentUrl
      appealUrl
      dateCreated
      status
      isPaid
    }
  }
`;
