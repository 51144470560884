import "../style.scss"
import { Fab, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withStyles } from "@mui/styles"
import CheckIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/CancelOutlined"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DateTime as d } from "luxon"
import { IProcurementRequest, IProcurementRequestItem, ProcurementItemStatuses } from '../../../../reducers/Procurement/types';
import { useMutation } from "@apollo/client";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../common/utils";
import { ApolloError } from "@apollo/client";
import { UPDATE_PROCUREMENT_REQUEST_STATUS } from "../../../../graphql/Procurement/updateProcurementItemStatusMutation"
import { useSelector } from "react-redux"
import { IAppState } from "../../../../store"
import { UserRoles } from "../../../hoc/Authorization"
import { DescriptionType, DialogType } from "../const"
import { ConfirmationDialog } from "./Models/ConfirmationDialog"

const HtmlTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#ffffff',
		color: 'rgba(0, 0, 0, 0.87)',
		minWidth: 250,
		border: '1px solid #dadde9',
		padding: 15
	},
}))(Tooltip);

interface IProps {
	procurement: IProcurementRequest;
	onNextStep(data: any): void;
	setProcurementRequest(data: IProcurementRequest): void;
}

export const ReviewRequest: React.FC<IProps> = (props) => {

	const snackbar = useSnackBar();
	const userState = useSelector((state: IAppState) => state.userReducer);
	const [items, setItems] = useState<IProcurementRequestItem[]>(props.procurement.items)
	const [status, setStatus] = useState<string>(ProcurementItemStatuses.PENDING)
	const [item, setItem] = useState<IProcurementRequestItem>()
	const [confirmDialog, setConfirmDialog] = useState<boolean>(false)
	const [isNext, setIsNext] = useState<boolean>(true);
	const [title, setTitle] = useState<string>("")
	const [description, setDescription] = useState<string>("")

	useEffect(() => {
		if (props.procurement && props.procurement.items) {
			setItems(props.procurement.items)
			let checkRequests = props.procurement.items.some((item) => item.status === ProcurementItemStatuses.APPROVED)
			if (checkRequests) {
				setIsNext(false)
			}
		}
	}, [props.procurement])

	const [updateProcurementItemStatus] = useMutation(UPDATE_PROCUREMENT_REQUEST_STATUS, {
		onCompleted: (data) => {
			snackbar({
				message: "Procurement items status updated successfully",
				variant: SnackBarVariant.SUCCESS
			})
			if (data && data.updateProcurementItemStatus && item) {
				const itemIndex = items.findIndex((pItem) => pItem.id === item.id)
				if (itemIndex > -1) {
					const newRequests = [
						...items.slice(0, itemIndex),
						{
							...items[itemIndex],
							status
						},
						...items.slice(itemIndex + 1)
					];
					// setItems(newRequests)
					props.setProcurementRequest({
						...props.procurement,
						items: newRequests
					})
				}
			}

			setConfirmDialog(false)
		},
		onError: (error: ApolloError) =>
			snackbar({
				message: formatGraphQLErrorMessage(error.message),
				variant: SnackBarVariant.ERROR
			})
	});

	const handleUpdateStatus = () => {
		if (item && item.id) {
			updateProcurementItemStatus({
				variables: {
					id: props.procurement.id,
					itemId: item.id,
					status
				}
			})
		}
	}
	return (
        <Grid container item xs={12} spacing={2}>
			<Grid item xs={12}>
				<Grid item xs={12}>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell className={"tableCellHead"}>No. of Vehicles</TableCell>
									<TableCell className={"tableCellHead"} align="left">Make</TableCell>
									<TableCell className={"tableCellHead"} align="left">Model</TableCell>
									<TableCell className={"tableCellHead"} align="left">Variant</TableCell>
									<TableCell className={"tableCellHead"} align="left">Fuel</TableCell>
									<TableCell className={"tableCellHead"} align="left">Transmission</TableCell>
									<TableCell className={"tableCellHead"} align="left">Condition</TableCell>
									<TableCell className={"tableCellHead"} align="left">Expected by</TableCell>
									<TableCell className={"tableCellHead"} align="left">Notes</TableCell>
									<TableCell className={"tableCellHead"} align="left">Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									items &&
									items.map((row: IProcurementRequestItem, index: number) => (
										<TableRow
											key={row.requestId}
										>
											<TableCell align="left">{row.quantity}</TableCell>
											<TableCell align="left">{row.make}</TableCell>
											<TableCell align="left">{row.model}</TableCell>
											<TableCell align="left">{row.variant}</TableCell>
											<TableCell align="left">{row.fuel}</TableCell>
											<TableCell align="left">{row.transmission}</TableCell>
											<TableCell align="left">{row.condition}</TableCell>
											<TableCell align="left">{d.fromISO(row.expectedByDate).toFormat('dd/MM/yyyy')}</TableCell>
											<TableCell align="left">
												<HtmlTooltip
													title={
														<>
															<Typography variant={"h3"}>Description</Typography>
															<Typography variant={"body1"}>{row.note && row.note.description ? row.note.description : "N/A"}</Typography>
														</>
													}
												>
													<IconButton aria-label="note-preview" size="large">
														<VisibilityIcon />
													</IconButton>
												</HtmlTooltip>
											</TableCell>
											<TableCell align="right">
												<Grid item xs={12} container>
													<Tooltip title={row.status === ProcurementItemStatuses.APPROVED ? "Approved" : "Approve"}>
														<IconButton
                                                            aria-label="confirmed"
                                                            onClick={() => {
																setTitle(DialogType.APPROVE_REQUEST)
																setDescription(DescriptionType.APPROVE_REQUEST_ITEM)
																setStatus(ProcurementItemStatuses.APPROVED)
																setItem(row)
																setConfirmDialog(true)
															}}
                                                            disabled={
																userState.role === UserRoles.ORGANISATION_OWNER ?
																	(row.status === ProcurementItemStatuses.APPROVED || row.status === ProcurementItemStatuses.REJECTED) ? true
																		: false
																	:
																	true
															}
                                                            size="large">
															<CheckIcon className={row.status === ProcurementItemStatuses.APPROVED ? "procurement-status-approved" : ""} />
														</IconButton>
													</Tooltip>
													<Tooltip title={row.status === ProcurementItemStatuses.REJECTED ? "Rejected" : "Reject"}>
														<IconButton
                                                            aria-label="canclled"
                                                            onClick={() => {
																setTitle(DialogType.REJECT_REQUEST)
																setDescription(DescriptionType.REJECT_REQUEST_ITEM)
																setItem(row)
																setStatus(ProcurementItemStatuses.REJECTED)
																setConfirmDialog(true)
															}}
                                                            disabled={
																userState.role === UserRoles.ORGANISATION_OWNER ?
																	(row.status === ProcurementItemStatuses.APPROVED || row.status === ProcurementItemStatuses.REJECTED) ? true
																		: false
																	:
																	true
															}
                                                            size="large">
															<CancelIcon className={row.status === ProcurementItemStatuses.REJECTED ? "procurement-status-rejected" : ""} />
														</IconButton>
													</Tooltip>
												</Grid>
											</TableCell>
										</TableRow>
									))
								}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			<Grid item container xs={12} justifyContent="flex-end">
				<Fab
					variant="extended"
					size="medium"
					aria-label="add"
					disabled={isNext}
					onClick={() => {
						props.onNextStep(items)
					}}
				>
					Next
				</Fab>
			</Grid>
			{
				confirmDialog && (
					<ConfirmationDialog
						open={confirmDialog}
						onClose={() => setConfirmDialog(false)}
						onConfirm={handleUpdateStatus}
						description={description}
						title={title}
					/>
				)
			}
		</Grid>
    );
}
