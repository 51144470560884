import { gql } from "@apollo/client";

export const ADD_BOOKING_NOTE = gql`
  mutation addBookingNote($bookingId: ID! $note:BookingNoteInput!) {
    addBookingNote(bookingId: $bookingId,note:$note) {
      id
      description
      createdBy{
        id
        firstName
        lastName
      }
      createdDate
    }
  }
`;

export const ADD_ADDITIONAL_INFORMATION = gql`
  mutation addAdditionalInformation($bookingId: ID! $additionalInformation: String!) {
    addAdditionalInformation(bookingId: $bookingId, additionalInformation:$additionalInformation) {
      description
    }
  }
`;

export const ADD_COI_DETAILS = gql`
mutation addCOIDetailsToBooking($bookingId: String! $coiDetails: CoiDetailsInput!) {
  addCOIDetailsToBooking(bookingId: $bookingId, coiDetails: $coiDetails) {
    coiDetails {
      policyName
      policyNumber
      provider
      expiryDate
      documentUrl
    }
  }
}
`;