import { Value } from "react-phone-number-input";

import { INewVehicleDamage } from "../../components/views/Fleet/Issues&Damages/NewDamage";
import { ITelemetryEventData } from "../../components/views/Fleet/VehicleTracking/TelematicsView";
import { IMapRouteData } from "../../components/views/Reports/types";
import { IAdminUser } from "../adminUser/types";
import { IBookingSchedule } from "../bookings/types";
import { IVehicleDamage } from "../damageVehicle/types";
import { ISubscription, ISubscriptionPricing } from "../Subscription/types";
import { IBranch } from "../user/types";

export interface IFleetState {
  vehicle: IVehicle;
  vehicleService: IVehicleService;
  vehicleFinance: IVehicleFinance;
  vehicleDamage: IVehicleDamage;
}

export type FleetPayloadType =
  | IVehicle
  | IVehicleService
  | IVehicleDamage
  | IVehicleFinance;

export interface IVehicle {
  isGhostVehicle?: boolean;
  insurerCode: string;
  airConditioner: boolean;
  assetTag: string;
  batteries: IBattery[];
  brakeHorsePower: string;
  bluetooth: boolean;
  bodyType: string;
  vehicleUniqueCode: string;
  vehicleUniqueId: string;
  co2Emissions: string;
  colour: string;
  cylinderCapacity: string;
  description: string;
  frontTyres: ITyre[];
  fuelType: string;
  imported: boolean;
  infoSource: string;
  insuranceGroup: string;
  licencePlate: string;
  vin: string;
  make: string;
  model: string;
  manufactureDate: string;
  year: number;
  registrationDate: string;
  v5c: string;
  vehicleTest: boolean;
  vehicleTestDetails: string;
  vehicleTestDueDate: string;
  vehicleTestReports: IVehicleTestReport[];
  vehicleTaxAmount?: number;
  numberOfDoors: number;
  numberOfPreviousOwners: number;
  numberOfSeats: number;
  premium: boolean;
  price: string;
  msrp: string;
  rearTyres: ITyre[];
  grossVehicleWeight: string;
  satNav: boolean;
  sixMonthRate: string;
  status?: string;
  steering: string;
  taxDetails: string;
  taxed: boolean;
  taxStatus: string;
  telemetryDeviceId: string;
  teltonikaDeviceId: string;
  telemetryDevicesData: ITelemetryEventData[];
  immobiliser: boolean;
  transmission: string;
  twelveMonthRate: string;
  typeApproval: string;
  tyreFitments: string[];
  vehicleDocuments: IVehicleDocument[];
  wheelPlan: string;
  acrissCode: string;
  branch: string;
  branchDetails?: IBranch;
  imageUrl: any;
  deleted?: boolean;
  id?: string;
  interiorColour: string;
  responseStatus?: number;
  bookingSchedules?: IBookingSchedule[];
  features: string[];
  vehicleState: IVehicleState;
  pcoNumber: string;
  pcoExpiry: string;
  nextPMIDate: string;
  nextBRTDate: string;
  frogDiagram?: string;
  services?: IVehicleService[];
  vehicleOwners: IVehicleOwnership[];
  holds?: IHold[];
  telemetryDeviceData?: {
    avlEvent: IAvlEvent;
    stopHours: string;
    idleHours: string;
    driveHours: string;
  };
  telematicCoreData?: {
    latitude: number;
    longitude: number;
    address?: IAvlEventAddress;
    odometer: number;
    speed: number;
    eventTime?: string;
  };
  smartCarVehicleData?: Partial<ISmartCarVehicleData>;
  className?: string;
  notes?: IVehicleNote[];
  activeVehicleDamages?: INewVehicleDamage[];
  vehicleStarter?: IVehicleStarter;
  damages?: INewVehicleDamage[];
  branchId?: string;
  canTransfer?: boolean;
  activeTransferMovement?: boolean;
  tankCapacity?: string;
  canRemoveFromFleetshare?: boolean;
  subscriptions: ISubscriptionInfo[];
  isCarShare?: boolean;
  smartCarVehicleID?: string;
  deFleetParameters: IDeFleetParameters;
  regularServiceSchedule: IRegularServiceSchedule;
  residualValue?: number;
  holdingCostPerDay?: number;
  dateCreated?: string;
  currentBranchId?: string;
  currentServiceLocation?: IServiceLocation;
  shared?: boolean;
  startDate?: string;
  dtcAlertSupported?: boolean;
  awayDistance?: number;
  vehicleType?: VehicleTypes;
  trailerType?: string;
  bodyCode?: string;
  axles?: number;
  length?: number;
  height?: number;
  palletCapacity?: number;
  pmiCycle?: number;
  emmissionType: string;
  ulezComplaint: string;
  ukVehicle: string;
  refrigeratorHours?: number;
  isRefrigerator?: boolean;
  deFleetReason?: string;
  deFleetStatus?: string;
  availableFrom?: string;
}

export interface IVehicleOwnership {
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  email: string;
  address: IAddress;
  ownershipNumber?: number;
}

interface IPhoneNumber {
  phone: string;
  country: string;
}

export interface IAddress {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zipcode?: string;
  fullAddress?: string;
}

export interface IServiceLocation {
  id: string;
  name: string;
  isDisabled: boolean;
}
export interface ISmartCarVehicleData {
  vehicleLocation: ISmartcarLocation;
  odometer: ISmartcarOdometer;
  eVChargeStatus: ISmartcarChargeStatus;
  eVBatteryLevel: ISmartcarBatteryLevel;
  fuelLevel: ISmartcarFuelLevel;
  engineOilLife: ISmartcarEngineOilLife;
  tirePressure: ISmartcarTirePressure;
  ISmartcarEVBatteryCapacity: ISmartcarEVBatteryCapacity;
  mapRoutesData: IMapRouteData;
  unitSystem?: string;
}

interface ISmartcarLocation {
  latitude: number;
  longitude: number;
}

interface ISmartcarOdometer {
  distance: number;
}

interface ISmartcarEngineOilLife {
  lifeRemaining: number;
}

interface ISmartcarTirePressure {
  frontLeft: number;
  frontRight: number;
  backLeft: number;
  backRight: number;
}

interface ISmartcarEVBatteryCapacity {
  capacity: number;
}

interface ISmartcarBatteryLevel {
  range: number;
  percentRemaining: number;
}

interface ISmartcarChargeStatus {
  state: string; // e.g. "FULLY_CHARGED"
  isPluggedIn: boolean;
}

export interface ISmartcarFuelLevel {
  range: number;
  percentRemaining: number;
  amountRemaining: number;
}
export interface IDeFleetParameters {
  byDistanceDriven: number;
  byDate: string;
  alertBeforeDistance: number;
  alertBeforeDays: number;
}

export interface IDeFleetCheckList {
  exteriorPaint: string;
  wheelRims: string;
  windscreensAndWindows: string;
  mirrors: string;
  bumper: string;
  headlamps: string;
  interiorFabric: string;
  locksAndDoorHandles: string;
  bothKeys: string;
  telematicsDeviceRemoved: string;
  pcn: string;
  pcnTotalAmount: number;
  loanStatus: string;
  currentLoanStatus: string;
  licencePlateCondition: string;
  registrationDocumentUrl: string;
  motDocumentUrl: string;
  loanDocumentUrl: string;
}

export interface IVehicleDefleet {
  id?: string;
  licencePlate: string;
  deFleetReason: string;
  deFleetPrice: number;
  residualValue: number;
  namaGrading: string;
  purchaserName?: string;
  purchaserAddress?: IAddress;
  deFleetCheckList: IDeFleetCheckList;
}

export interface IRegularServiceSchedule {
  byDistance: number;
  byMonth: number;
  alertBefore: number;
}

export interface ISubscriptionInfo {
  subscription: Partial<ISubscription>;
  pricings: ISubscriptionPricing[];
  deposit: number;
}
interface IVehicleStarter {
  lastestActionBy: string;
  latestActionTime: string;
  status: string;
}
export interface IVehicleNote {
  id?: string;
  description: string;
  createdBy: string;
  createdDate: string;
}

export interface IVehicleNoteInput {
  description: string;
  createdBy: string;
  createdDate: string;
}
interface IAvlEvent {
  latitude: number;
  longitude: number;
  address?: IAvlEventAddress;
}

interface IAvlEventAddress {
  street: String;
  city: string;
}
export interface IVehicleDocument {
  title: string;
  expiryDate: string;
  documentType: string;
  reminderBefore?: number;
  documentName: string;
  url: string;
  createdAt?: string;
}

export interface IVehicleCheckListInput {
  id?: "";
  generalCheckListItems: ICheckItem[];
  accessoriesCheckListItems: ICheckItem[];
  frogDiagram: string;
}

export interface IVehicleCheckList {
  id: "";
  checkListName: string;
  generalCheckListItems: ICheckItem[];
  accessoriesCheckListItems: ICheckItem[];
  frogDiagram: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

export interface ICheckItem {
  name: string;
  isMandatory: boolean;
}

export interface IVehicleService {
  id?: string;
  vehicleId: string;
  scheduleType: IServiceScheduleType;
  serviceType: string;
  serviceProviderId: string;
  serviceProviderName: string;
  serviceProviderAddress: IAddress;
  // The initial appointment date, derived from the start date
  appointmentDate: string;
  // The fixed start date for the scheduled service, which will not change
  startDate: string;
  // The fixed end date for the scheduled service, which will not change
  endDate: string;
  // The completion date for the scheduled service, which can be modified
  completionDate: string;
  serviceAnniversaryByMileage: number;
  serviceAnniversaryByMonth: number;
  odometerReading: number;
  description: string;
  totalExpenses: number;
  status: VehicleServiceStatus;
  notes?: IVehicleServiceNote[];
  damages: (IVehicleDamage | string)[];
  documents: IServiceDocument[];
  licencePlate?: string;
  referenceNumber?: string;
  branchId: string;
  jobSheet: IJobSheet[];
  jobSheetStatus: JobSheetStatus;
  jobSheetEmailLastSentOn?: string;
  taxPercent: number;
  insuranceInformation: IInsuranceInformation;
  purchaseOrderInformation: IPurchaseOrderInformation;
  driverName: string;
  driverEmail: string;
  epyxReferenceNumber?: string;
  sentToXero?: boolean;
}

export interface IPurchaseOrderInformation {
  poNumber: string;
  documentUrl: string;
}

export interface IInsuranceInformation {
  providerName: string;
  policyNumber: string;
  expiryDate: string;
  claimAmount: number;
  documentUrl: string;
}

export interface IJobSheet {
  id?: string;
  name: string;
  description: string;
  cost: number;
}

export enum JobSheetStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED"
}

export const JobsheetStatusValue: {
  [key in JobSheetStatus]: string;
} = {
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected"
};

export enum VehicleServiceStatus {
  SCHEDULED = "SCHEDULED",
  NOT_STARTED = "NOT_STARTED",
  IN_SERVICE = "IN_SERVICE",
  DUE = "DUE",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED"
}

export enum VehicleServiceType {
  REGULAR = "REGULAR",
  ADHOC = "ADHOC",
  EMERGENCY = "EMERGENCY"
}
export const VehicleServiceTypeValue: {
  [key in VehicleServiceType]: string;
} = {
  REGULAR: "Regular",
  ADHOC: "Ad-hoc",
  EMERGENCY: "Emergency"
};

export enum VehicleServiceTemplateType {
  ONE_TIME = "ONE_TIME",
  RECURRING = "RECURRING"
}

export const VehicleServiceTemplateTypeValue: {
  [key in VehicleServiceTemplateType]: string;
} = {
  ONE_TIME: "One Time",
  RECURRING: "Recurring"
};

export enum ServiceScheduleType {
  BY_MONTH = "BY_MONTH",
  BY_MILEAGE = "BY_MILEAGE"
}

export enum ServiceAnniversary {
  EVERY_MONTH = 1,
  EVERY_3_MONTHS = 3,
  EVERY_6_MONTHS = 6,
  EVERY_YEAR = 12
}

export const VehicleServiceStatusValue: {
  [key in VehicleServiceStatus]: string;
} = {
  NOT_STARTED: "Not Started",
  IN_SERVICE: "In Service",
  DUE: "Due",
  COMPLETED: "Completed",
  SCHEDULED: "Scheduled",
  CANCELLED: "Cancelled"
};

export interface IServiceScheduleType {
  byMonth: boolean;
  byMileage: boolean;
}
export interface IVehicleServiceNote {
  description: string;
  createdBy: string | undefined;
  createdDate: string;
}
export interface IServiceDocument {
  title: string;
  documentName: string;
  documentType: string;
  reminderBefore?: number;
  expiryDate: string;
  documentUrl: string;
  createdAt: string;
}

export interface IVehicleFinance {
  id: string;
  vehicleId: string;
  vehicleHoldingCost: number;
  sourceType: string;
  purchasePrice: number;
  leasePrice: number;
  paymentInterval: string;
  leasedFrom: string;
  purchasedFrom: string;
  purchasedOn: string;
  leasedOn: string;
  leasedUntil: string;
  financedFrom: string;
  financedFromDate: string;
  financedToDate: string;
  financedPrice: number;
  financedDeposit: number;
  financedPaymentInterval: number;
  rentedFrom: string;
  rentedFromDate: string;
  rentedToDate: string;
  rentedPrice: number;
  residualValue: number;
  depreciationPercentage: number;
  rentedPaymentInterval: number;
  roadFundTax: number;
}
export interface WalkAroundImage {
  key: string;
  capturedAt: string;
  angle: string;
}
export interface IVehicleState {
  id?: string;
  type?: string;
  exterior: IVehicleStateExterior;
  interior: IVehicleStateInterior;
  defaultAccesories: IVehicleStateCheck[];
  generalChecks: IVehicleStateCheck[];
  bookedAccesories?: IVehicleStateBookedAccesory[];
  tyre: IVehicleStateTyre;
  odometerReading: number;
  fuelStatus?: string;
  batteryStatus?: number;
  updatedAt?: string;
  updatedBy?: IAdminUser;
  createdAt?: string;
  createdBy?: IAdminUser;
  consumerWalkAroundImages?: WalkAroundImage[];
}

export interface IVehicleStateExterior {
  front: IVehicleStateCommonExterior;
  rear: IVehicleStateCommonExterior;
  top: IVehicleStateCommonExterior;
  right: IVehicleStateCommonExterior;
  left: IVehicleStateCommonExterior;
}
export interface IVehicleStateCommonExterior {
  description: string;
  images: string[];
}
export interface IVehicleStateInterior {
  frontSeat: IVehicleStateInteriorFrontSeat;
  rearSeat: IVehicleStateInteriorRearSeat;
  lockAndSwitch: IVehicleStateInteriorLockAndSwitch;
  images: string[];
}

export interface IVehicleStateCheck {
  name: string;
  status: CheckListStatus;
  images: string[];
}

export enum CheckListStatus {
  "MISSING" = "MISSING",
  "CHECKED" = "CHECKED",
  "N/A" = "N/A"
}
export interface IVehicleStateInteriorRearSeat {
  clean: boolean;
  controls: boolean;
  seatBelt: boolean;
}
export interface IVehicleStateInteriorFrontSeat {
  clean: boolean;
  controls: boolean;
  seatBelt: boolean;
}
export interface IVehicleStateInteriorLockAndSwitch {
  frontDoor: boolean;
  rearDoor: boolean;
  frontWindow: boolean;
  rearWindow: boolean;
  childLock: boolean;
}
export interface IVehicleStateBookedAccesory {
  name: string;
  type: string;
  quantity: number;
  description: string;
}
export interface IVehicleStateTyre {
  frontLeft: IVehicleStateTyreCommon;
  frontRight: IVehicleStateTyreCommon;
  rearLeft: IVehicleStateTyreCommon;
  rearRight: IVehicleStateTyreCommon;
}
export interface IVehicleStateTyreCommon {
  condition: boolean;
  pressure: boolean;
  description: string;
}
export interface IHold {
  id: string;
  startDate: string;
  endDate: string;
}
export interface IBattery {}

export interface ITyre {}

export enum VehicleTypes {
  TWO_WHEELER = "TWO_WHEELER",
  ATV = "ATV",
  DEFAULT_VEHICLE = "DEFAULT_VEHICLE",
  TRAILER = "TRAILER"
}

export interface IVehicleTestReport {
  advisoryItems: string[];
  expiryDate: string;
  failureItems: any[];
  minorItems: any[];
  motTestNumber: number;
  odometerReading: number;
  odometerUnit: string;
  testDate: string;
  testResult: string;
  vehicleTestDueDate: string;
}

export enum TestResult {
  PASS = "PASS",
  FAIL = "FAIL"
}

export enum VehicleFuelType {
  DIESEL = "DIESEL",
  PETROL = "PETROL",
  HYBRID_ELECTRIC = "HYBRID ELECTRIC",
  ELECTRICITY = "ELECTRICITY",
  ELECTRIC_DIESEL = "ELECTRIC DIESEL",
  GAS_BI_FUEL = "GAS BI-FUEL",
  HYBRID_ELECTRIC_CLEAN = "HYBRID ELECTRIC (CLEAN)",
  ELECTRIC = "ELECTRIC",
  GASOLINE = "GASOLINE"
}

export type VehicleStateType =
  | IVehicleStateExterior
  | IVehicleStateInterior
  | IVehicleStateTyre;

export type VehicleStateElementType =
  | boolean
  | string
  | IVehicleStateCommonExterior
  | IVehicleStateInteriorFrontSeat
  | IVehicleStateInteriorRearSeat
  | IVehicleStateInteriorLockAndSwitch
  | IVehicleStateTyreCommon;

export type VehicleStateElementKeyType = keyof IVehicleStateInterior;

export type VehicleStateInteriorKeyType =
  | keyof IVehicleStateInteriorFrontSeat
  | keyof IVehicleStateInteriorRearSeat
  | keyof IVehicleStateInteriorLockAndSwitch;

export interface IFuelExpense {
  vehicle: string;
  expenseDate: string;
  odometerReading: number;
  fuelType: string;
  vendor: string;
  fuelQuantity: number;
  amount: number;
  reference: string;
  document: [
    {
      title: string;
      documentName: string;
      documentType: "string";
      reminderBefore: number;
      expiryDate: string;
      url: string;
    }
  ];
  driver: string;
  referenceNumber: string;
  createdBy: string;
  id: string;
  type: string;
  tenantId: string;
  organisationId: string;
  branchId: string;
  dateCreated: string;
}

export interface ISuggestedPricing {
  subscriptionAmount: number;
  rateTypeName: string;
}

export enum DeFleetDocumentType {
  REGISTRATION = "REGISTRATION",
  MOT = "MOT",
  LOAN_CERTIFICATE = "LOAN_CERTIFICATE"
}
