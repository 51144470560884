import { ADD_TABLE_STATE, SET_TABLE_STATE, TableStateAction } from "../../actions/tableState/types";
import { initialState } from "./const";
import { ITableState } from "./types";

export default function (
    state: ITableState = initialState,
    action: TableStateAction
): ITableState {
    switch (action.type) {
        case ADD_TABLE_STATE:
            return {
                ...state,
                [action.payload.tableName]: {
                    ...state[action.payload.tableName],
                    ...action.payload
                }
            };
        case SET_TABLE_STATE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}