import { Grid, Typography } from "@mui/material";
import React from "react";
import { DateTime as d } from "luxon";
import { useVirtualizer } from '@tanstack/react-virtual';
import { IVehicle, IVehicleDocument } from "../../../../../reducers/fleet/types";
import MobileVehicleCard from "../../../../common/MobileVehicleCard";
import { DocumentTypeValue } from "../../../../common/utils";


export const VehicleInventoryVirtualScroller = ({ data }: { data: IVehicle[] }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 480
  })

  if (!data.length) {
    return <Typography>Sorry no matching record found</Typography>
  }
  function getInsuranceStatus(vehicle: IVehicle) {
    let status = "";
    const insuranceDocument =
      vehicle.vehicleDocuments.find((document: IVehicleDocument) =>
        document.documentType === DocumentTypeValue.VEHICLE_INSURANCE
      )

    if (insuranceDocument && insuranceDocument.expiryDate) {
      if (insuranceDocument.expiryDate >= d.now().toUTC().toISO()) {
        status = "Active"
      } else {
        status = "Expired"
      }
    } else {
      status = "N/A"
    }

    return status;
  }

  return (
    <>
      {/* Scrollable Parent List  */}
      <Grid item xs={12}
        ref={parentRef}
        className="List"
        style={{
          height: `calc(100vh - 250px)`,
          width: 'calc(100vw - 40px)',
          overflow: 'auto',
        }}
      >
        {/* Large inner element for items */}
        <Grid container item xs={12} spacing={2}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {/* Virtualized Items */}
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <Grid container item xs={12} spacing={2}
              key={virtualItem.key}
              style={{
                position: 'absolute',
                width: '100%',
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <MobileVehicleCard
                id={data[virtualItem.index].id}
                imageUrl={data[virtualItem.index].imageUrl}
                assetTag={data[virtualItem.index].assetTag}
                make={data[virtualItem.index].make}
                year={data[virtualItem.index].year}
                licencePlate={data[virtualItem.index].licencePlate}
                bodyType={data[virtualItem.index].bodyType}
                status={data[virtualItem.index].status!}
                fuelType={data[virtualItem.index].fuelType}
                motStatus={data[virtualItem.index].vehicleTestReports?.length ? ((data[virtualItem.index].vehicleTestReports[0].testResult === "PASS" && data[virtualItem.index].vehicleTestDueDate >= d.now().toUTC().toISO()) ? "Pass" : "Fail") : "N/A"}
                insuranceStatus={data[virtualItem.index].vehicleDocuments ? getInsuranceStatus(data[virtualItem.index]) : "N/A"}
                taxStatus={data[virtualItem.index].taxStatus ? data[virtualItem.index].taxStatus : "Non-Taxed"}
                taxDate={data[virtualItem.index].taxDetails ? data[virtualItem.index].taxDetails : "N/A"}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default VehicleInventoryVirtualScroller;