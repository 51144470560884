import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query Users {
    users {
      id
      email
      firstName
      lastName
      role
      userRole{
        id
        name
        key
        roleType
      }
      organisation {
        id
        name
      }
      branch {
        id
        name
        depositCollection
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($userId: ID!) {
    getUser(userId: $userId) {
      id
      email
      firstName
      lastName
      phoneNumber {
        phone
        country
      }
      active
      role
      userRole{
        id
        name
        key
        policies{
          id
          permissions
        }
      }
      organisation {
        id
        name
        sepaEnabled
        bacsEnabled
        cashEnabled
        cardEnabled
        offlineBankTransferEnabled
        payByLinkEnabled
        payOnCollectionEnabled
        autoChargeEnabled
      }
      branch {
        id
        name
        depositCollection
      }
    }
  }
`;

export const GET_USERS_BY_ROLES = gql`
  query getUsersByRoles($roles: [String!]!) {
    getUsersByRoles(roles: $roles) {
      id
      email
      firstName
      lastName
      role
      userRole{
        id
        name
        key
        roleType
      }
      organisation {
        id
        name
      }
      branch {
        id
        name
        depositCollection
      }
    }
  }
`;