import { gql } from "@apollo/client";

export const GET_FILTERED_BOOKINGS = gql`
query filteredBookings($selectedDate: String) {
    filteredBookings(selectedDate: $selectedDate) {
      id
      referenceNumber
      source
      createdBy {
        id
        firstName
        lastName
      }
      pickupServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      dropoffServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      dropoffOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      pickupOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      pickupDateTime
      dropoffDateTime
      customerType
      dateUpdated
      status
      customer {
        id
        firstName
        lastName
      }
      currentBookingSchedules {
        id
        startDate
        endDate
        status
        vehicleStatus
        vehicle {
          id
          licencePlate
          branchId
          imageUrl
          bodyType
          isGhostVehicle
        }
      }
      businessCustomer {
        id
        businessName
      }
      authorizedSignatory
      isSubscription
    }
  }
`;
