import { gql } from "@apollo/client";

export const CREATE_VEHICLE_FINANCE = gql`
  mutation createVehicleFinance($vehicleFinance: CreateVehicleFinanceInput!) {
    createVehicleFinance(vehicleFinance: $vehicleFinance) {
      id
      vehicleId
      vehicleHoldingCost
      depreciationPercentage
      sourceType
      purchasePrice
      leasePrice
      paymentInterval
      leasedFrom
      purchasedFrom
      purchasedOn
      leasedOn
      leasedUntil
      leasedUntil
      financedFrom
      financedFromDate
      financedToDate
      financedPrice
      financedDeposit
      financedPaymentInterval
      rentedFrom
      rentedFromDate
      rentedToDate
      rentedPrice
      rentedPaymentInterval
      residualValue
      roadFundTax
    }
  }
`;
