import axios from "axios";
import HttpHeader from '../header';

const httpHeader = HttpHeader.getInstance();

const instance = axios.create({
  baseURL: process.env.REACT_APP_APOLLO_SERVER,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(async function (config: any) {
  config.headers = {
    ...config.headers,
    ...httpHeader.headers,
    authorization: `Bearer ${httpHeader.headers.authorization}`
  };
  return config;
});

export const signedUrlAPI = (key: string) => instance.post("/", {
  query: `query signedUrl($key: String!) {
    getS3ObjectSignedUrl(key: $key)
  }`,
  variables: {
    key
  }
})

export const getSignedUrl = async (key: string) => {
  if (key === "") {
    return;
  }
  const { data } = await signedUrlAPI(key);
  return data.data.getS3ObjectSignedUrl;
}

export enum ErrorMessage {
  ACCESS_DENIED = "Access Denied"
}