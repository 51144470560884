import { gql } from "@apollo/client";

// The data returned should be same as GET_BOOKING query
export const CONFIRM_BOOKING_EXTENSION = gql`
  mutation confirmBookingExtension($id: String!) {
    confirmBookingExtension(id: $id) {
      id
      referenceNumber
      extensionNumber
      status
      startDate
      endDate
      dropoffServiceLocation {
        id
        name
      }
      insuranceRate
      insurancePolicy {
        id
        name
        description
      }
      vehicleDetails {
        vehicleGroupId
        vehicleIds
        basePrice
        priceRuleAmount
        quantity
        modifiedPrice
        description
      }
      paymentDetails {
        rentalAmount
        startDate
        endDate
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        totalPayableAmount
        rentalTime
      }
      addonRequirements {
        rate
        quantity
        displayName
        hasFixedRate
        tax {
          title
          value
        }
      }
      otherCharges {
        description
        quantity
        unitPrice
      }
    }
  }
`;