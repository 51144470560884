import React, { useEffect, useState } from "react";
import { useLazyQuery } from '@apollo/client';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { GET_VEHICLE_GROUPS_TRACKING, GET_VEHICLES } from '../../../../graphql/fleet/getVehicleGroupTrackingQuery';
import { IAppState } from '../../../../store';
import styles from "./index.module.css";
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import { IVehicle } from '../../../../reducers/fleet/types';
import { ISmartcarData } from "./TelematicsView";
import { getChargingStatusText } from "../utils";

const StyledInput = withStyles({
  root: {
    padding: "0px !important",
    '& .MuiOutlinedInput-input': {
      backgroundColor: 'transparent',
      fontSize: 14,
      padding: "32px 30px 10px 14px !important",
      border: 0,
      fontWeight: 700,
      borderRadius: 0,
      width: "auto"
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: "0px !important",
    },
    '& fieldset': {
      border: 0
    },
    '& .MuiAutocomplete-endAdornment': {
      top: "initial",
      bottom: 5,
      right: "4px !important"
    }
  },
  focus: {
    '& .MuiOutlinedInput-input': {
      backgroundColor: "#f6f6f6"
    },
    '& fieldset': {
      border: "0 !important",
    }
  }
})(TextField);

interface IProps {
  setVehicles: (vehicles: IVehicleTelematics[]) => void;
  active: boolean;
  setMarkedVehicle: (vehicle?: IVehicleTelematics) => void;
  setSmartcarData: (data: ISmartcarData[]) => void;
}

export interface IVehicleGroupData {
  id: string
  name: string;
  vehicles: IVehicleId[]
}

export interface IVehicleTelematics {
  lat?: number;
  lng?: number;
  status?: string;
  esn?: string;
  id: string;
  licencePlate?: string;
  lastLocation?: string;
  lastLocationTime?: string;
}

interface IVehicleId {
  id: string
}

export const QueryBox: React.FC<IProps> = (props) => {
  const [allVehicles, setAllVehicles] = useState<IVehicleTelematics[]>([]);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [vehicleGroups, setVehicleGroups] = useState<IVehicleGroupData[]>([]);
  const [selectedVG, setSelectedVG] = useState<IVehicleGroupData | undefined>();
  const [VGInputText, setVGInputText] = useState<string>("");
  const [vehicleInputText, setVehicleInputText] = useState<string>("");

  const [loadVehicleGroups,
    { loading: loadingVehicleGroups, data: vehicleGroupsData }
  ] = useLazyQuery(
    GET_VEHICLE_GROUPS_TRACKING, {
    fetchPolicy: "network-only"
  });

  const [loadVehicles,
    { data: vehiclesFetchedData }
  ] = useLazyQuery(
    GET_VEHICLES, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (userState.tenancy) {
      loadVehicleGroups();
      loadVehicles();
    }
  }, [userState]);


  useEffect(() => {
    if (vehicleGroupsData && vehicleGroupsData.vehicleGroups) {
      const sortedGroups: IVehicleGroupData[] = [...vehicleGroupsData.vehicleGroups].sort((firstGroup: IVehicleGroupData, secondGroup: IVehicleGroupData) => {
        return firstGroup.name.toLowerCase().localeCompare(secondGroup.name.toLowerCase());
      })
      setVehicleGroups(sortedGroups);
    }
  }, [vehicleGroupsData])

  useEffect(() => {
    if (vehiclesFetchedData && vehiclesFetchedData.vehicles && vehiclesFetchedData.vehicles.length) {
      let _vehicles: IVehicleTelematics[] = [];
      let _smartcardata: ISmartcarData[] = [];
      for (let i = 0; i < vehiclesFetchedData.vehicles.length; i++) {
        const _vehicle: IVehicle = vehiclesFetchedData.vehicles[i];
        if ((!_vehicle.smartCarVehicleData && _vehicle.telematicCoreData && _vehicle.telematicCoreData.latitude && _vehicle.telematicCoreData.longitude) || (_vehicle.immobiliser && _vehicle.teltonikaDeviceId)) {
          _vehicles.push({
            lat: _vehicle.telematicCoreData?.latitude,
            lng: _vehicle.telematicCoreData?.longitude,
            esn: _vehicle.telemetryDeviceId,
            id: _vehicle.id || "",
            licencePlate: _vehicle.licencePlate.toUpperCase(),
            lastLocation: `${_vehicle.telematicCoreData?.address?.street || ""} ${_vehicle.telematicCoreData?.address?.city || ""}`,
            lastLocationTime: _vehicle.telematicCoreData?.eventTime || ""
          })
        } else if (_vehicle.smartCarVehicleData && _vehicle.smartCarVehicleData?.vehicleLocation?.latitude && _vehicle.smartCarVehicleData?.vehicleLocation?.longitude) {
          _vehicles.push({
            lat: _vehicle.smartCarVehicleData?.vehicleLocation?.latitude,
            lng: _vehicle.smartCarVehicleData?.vehicleLocation?.longitude,
            id: _vehicle.id || "",
            licencePlate: _vehicle.licencePlate.toUpperCase(),
          })
          _smartcardata.push({
            id: _vehicle.id || "",
            latitude: _vehicle.smartCarVehicleData?.vehicleLocation?.latitude,
            longitude: _vehicle.smartCarVehicleData?.vehicleLocation?.longitude,
            engineOilLife: _vehicle.smartCarVehicleData.engineOilLife?.lifeRemaining,
            evBatteryLevel: _vehicle.smartCarVehicleData.eVBatteryLevel?.percentRemaining,
            evChargeStatus: getChargingStatusText(_vehicle.smartCarVehicleData.eVChargeStatus?.state),
            fuelLevel: _vehicle.smartCarVehicleData.fuelLevel?.percentRemaining,
            odometer: _vehicle.smartCarVehicleData.odometer?.distance,
            unitSystem: _vehicle.smartCarVehicleData.unitSystem,
            evBatteryRange: _vehicle.smartCarVehicleData?.eVBatteryLevel?.range || 0,
            fuelRange: _vehicle.smartCarVehicleData?.fuelLevel?.range
          })

        }
      }
      setAllVehicles(_vehicles);
      props.setVehicles(_vehicles);
      props.setSmartcarData(_smartcardata);
    }
  }, [vehiclesFetchedData])

  const getVehicleOptions = () => {
    if (selectedVG && selectedVG.vehicles.length && allVehicles.length) {
      return getVehiclesForGroup(selectedVG.vehicles);
    }
    return allVehicles
  }

  const getVehiclesForGroup = (vehicleIds: IVehicleId[]) => {
    const _vehicles: IVehicleTelematics[] = []
    for (let i = 0; i < vehicleIds.length; i++) {
      const _vehicle = allVehicles.find((item) => item.id === vehicleIds[i].id);
      if (_vehicle) {
        _vehicles.push(_vehicle);
      }
    }
    return _vehicles
  }

  const handleSelectedGroup = (vg: IVehicleGroupData) => {
    if (vg) {
      setSelectedVG(vg)
      props.setVehicles(getVehiclesForGroup(vg.vehicles))
      props.setMarkedVehicle();
    }
  }

  const handleMarkedVehicle = (vehicle?: IVehicleTelematics) => {
    props.setMarkedVehicle(vehicle)
  }

  return (
    <Fade in={props.active} >
      <div className={styles.queryCard}>
        <h2>Vehicle <span >Tracking</span></h2>
        <div className={styles.searchBox}>
          <div className={styles.block}>
            <div className={styles.inputLabel}>
              <span className="padding-right" >Vehicle Group</span>
            </div>
            {loadingVehicleGroups ? <div style={{ padding: "30px 30px 4px 14px" }}>
              <CircularProgress size={20} thickness={5} />
            </div> : <Autocomplete
              freeSolo
              noOptionsText="No Groups"
              id="FLEET_DASHBOARD_VG_SEARCH"
              options={vehicleGroups}
              getOptionLabel={(option: any) =>
                option.name
              }
              inputValue={VGInputText}
              onInputChange={(_, value, reason) => {
                setVGInputText(value);
                if (!value.length) {
                  setSelectedVG(undefined);
                  props.setVehicles(allVehicles)
                }
              }}
              onChange={(_: any, newVal: any, reason) => {
                if (newVal && newVal.id) {
                  handleSelectedGroup(newVal);
                  handleMarkedVehicle();
                  setVehicleInputText("")
                }
              }}
              renderInput={(params) => (
                <StyledInput
                  {...params}
                  variant="outlined"
                  placeholder={"Add vehicle group"}
                  InputProps={{ ...params.InputProps, type: "search" }}
                />
              )}
            />}

          </div>
          <div className={styles.block}>
            <div className={styles.inputLabel}>
              Registration No.
            </div>
            {loadingVehicleGroups ? <div style={{ padding: "30px 30px 4px 14px" }}>
              <CircularProgress size={20} thickness={5} />
            </div> : <Autocomplete
              freeSolo
              noOptionsText="No Groups"
              id="FLEET_DASHBOARD_VEHICLE_SEARCH"
              options={getVehicleOptions()}
              getOptionLabel={(option: any) => option.licencePlate || ""}
              inputValue={vehicleInputText}
              onInputChange={(_, value, reason) => {
                setVehicleInputText(value);
                if (!value.length) {
                  handleMarkedVehicle()
                }
              }}
              onChange={(_: any, newVal: any, reason) => {
                if (newVal && newVal.id) {
                  handleMarkedVehicle(newVal)
                }
              }}
              renderInput={(params) => (
                <StyledInput
                  {...params}
                  variant="outlined"
                  placeholder={"Search"}
                  InputProps={{ ...params.InputProps, type: "search" }}
                />
              )}
            />}
          </div>
        </div>
      </div>
    </Fade>
  );
};


