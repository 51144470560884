import "./index.scss";

import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import carImage from "../../../../../../assets/images/car-outline.png";
import { GET_VEHICLE_DAMAGES } from "../../../../../../graphql/damageVehicle/getDamageVehicles";
import { IExteriorImage } from "../../../../../../reducers/damageVehicle/types";
import {
  IVehicleState,
  IVehicleStateExterior,
  IVehicleStateInterior,
  VehicleStateElementType,
  VehicleStateType
} from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { DamageRowDetail } from "../../../../Fleet/VehicleInventory/ViewVehicle/Damage/DamageRowDetail";
import {
  vehicleCheckTitles,
  vehicleUploadImages
} from "../../../../Fleet/VehicleInventory/ViewVehicle/VehicleCheck/constants";
import { ImagesView } from "./ImagesView";
import { fuelStatus } from "./constants";
import { getSignedUrl } from '../../../../../../utils/getSignedUrl';
import DragDropImages from "../../../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";
import { captureErrorException } from "../../../../../../utils/sentry";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    table: {
      minWidth: 650
    }
  })
);
interface IVehicleChecks {
  onSubmit(payload: any): void;
  onPrevious(): void;
  vehicleId?: string;
}

export const VehicleChecks = (props: IVehicleChecks) => {
  const classes = useStyles();
  const vehicleChecks = useSelector((state: IAppState) => state.bookingReducer);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [imageType, setImageType] = useState<string>("");
  const [openDragDrop, setOpenDragDrop] = useState<boolean>(false);
  const [subImageType, setSubImageType] = useState<string>("");
  const [values, setValues] = useState<IVehicleState>(
    vehicleChecks.checkInfo.vehicleState
  );
  const [interiorImages, setInteriorImages] = useState<string[]>([]);
  const [exteriorImages, setExteriorImages] = useState<IVehicleStateExterior>({
    front: { description: "", images: [] },
    rear: { description: "", images: [] },
    top: { description: "", images: [] },
    left: { description: "", images: [] },
    right: { description: "", images: [] }
  });
  const [vehicleDamage, setVehicleDamage] = useState<any>();
  const [loadVehicleDamages, { loading, data }] = useLazyQuery(
    GET_VEHICLE_DAMAGES,
    {
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (vehicleChecks) {
      setExteriorImages(vehicleChecks.checkInfo.vehicleState.exterior);
      setInteriorImages(vehicleChecks.checkInfo.vehicleState.interior.images);
    }
  });

  useEffect(() => {
    loadVehicleDamages();
  }, []);

  useEffect(() => {
    if (data && data.vehicleDamages) {
      const filterVehicle = data.vehicleDamages.find((damage: any) => {
        return damage.vehicleId === props.vehicleId;
      });

      setVehicleDamage(filterVehicle);
    }
  }, [data]);

  const getImages = async (key: string) => {
    const config = { contentType: "image/*", level: "public" };
    const file = await getSignedUrl(key);
    return file;
  };

  const handleOpenDragDrop = (type: string, subType?: string) => {
    setImageType(type);
    setSubImageType(subType ? subType : "");
    setOpenDragDrop(true);
  };

  const handleCloseDragDrop = () => {
    setOpenDragDrop(false);
  };

  const handlePrevious = () => {
    props.onPrevious();
  };

  const getImageUpload = async (type: string, fileName: any, file: any) => {
    const uniqueId = uuidv4();
    const key = `${userState.tenancy.id}/${fileName}-${uniqueId}.${fileName}`;
    if (_e_) {
      await _e_
        .add({
          name: key,
          file: file,
        });
    }
    return key;
  };

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Paper className={classes.root}>
            <Grid container item xs={12}>
              <Typography variant="h3">DAMAGE INFORMATION</Typography>
            </Grid>
            <Grid container item xs={12}>
              <Box mt={2}></Box>
            </Grid>
            <Grid container item xs={12}>
              <DamageRowDetail row={vehicleDamage} />
            </Grid>
          </Paper>
        )}
      </Grid>
      <Formik
        enableReinitialize
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit({
            ...vehicleChecks.checkInfo,
            vehicleState: { ...values }
          });
        }}
      >
        {(formValues) => (
          <Form>
            <Grid container item xs={12} spacing={2}>
              {values &&
                Object.keys(values).map((key: string) => {
                  const vehicleChecks = ((values as unknown) as {
                    [key: string]: VehicleStateType;
                  })[key];
                  if (typeof vehicleChecks === "object") {
                    return (
                      <React.Fragment>
                        {imageType === key && (
                          <DragDropImages
                            open={openDragDrop}
                            handleCloseDragDrop={handleCloseDragDrop}
                            filesLimit={10}
                            handleSave={(files: any) => {
                              if (imageType === "interior") {
                                const promises: any[] = [];
                                const newInt = vehicleChecks as IVehicleStateInterior;
                                const interiorView: any[] = [];
                                files.map((image: any) => {
                                  promises.push(
                                    (async () => {
                                      try {
                                        const result: any = await getImageUpload(
                                          "interiorImages",
                                          image.name,
                                          image
                                        );
                                        const data: any = await getImages(
                                          result.key
                                        );
                                        newInt.images.push(result.key);
                                        interiorImages.push(data);
                                      } catch (error) {
                                        captureErrorException(error)
                                      }
                                    })()
                                  );
                                });
                                Promise.all(promises).then(() => {
                                  setValues({
                                    ...values,
                                    [key]: {
                                      ...vehicleChecks,
                                      ...newInt
                                    }
                                  });
                                  setInteriorImages([
                                    ...interiorImages,
                                    ...interiorView
                                  ]);
                                });
                                handleCloseDragDrop();
                              } else {
                                const promises: any[] = [];
                                const images: any = vehicleChecks;
                                const ImagesForView: any = exteriorImages;
                                files.map((image: any) => {
                                  promises.push(
                                    (async () => {
                                      try {
                                        const result: any = await getImageUpload(
                                          imageType,
                                          image.name,
                                          image
                                        );
                                        const data: any = await getImages(
                                          result.key
                                        );

                                        images[subImageType].images.push(
                                          result.key
                                        );
                                        ImagesForView[subImageType].images.push(
                                          data
                                        );
                                      } catch (error) {
                                        captureErrorException(error)
                                      }
                                    })()
                                  );
                                });
                                Promise.all(promises).then(() => {
                                  setValues({
                                    ...values,
                                    [key]: { ...vehicleChecks, ...images }
                                  });
                                  setExteriorImages({
                                    ...ImagesForView
                                  });
                                });

                                handleCloseDragDrop();
                              }
                            }}
                          />
                        )}
                        {key === "exterior" && (
                          <Grid container item xs={12}>
                            <Paper className={classes.root} elevation={0}>
                              <Grid container item xs={12}>
                                <Grid container item xs={12}>
                                  <Typography variant="h2">
                                    {vehicleCheckTitles[key]}
                                  </Typography>
                                </Grid>
                                <Grid>
                                  <Box mt={5}></Box>
                                </Grid>
                                <Grid container item xs={12}>
                                  <div className="upload-ext-images-checks">
                                    {vehicleUploadImages.map((data: any) => {
                                      return (
                                        <Grid container item xs={12}>
                                          <div key={key}>
                                            <label className={data.class}>
                                              <Button
                                                onClick={() =>
                                                  handleOpenDragDrop(
                                                    key,
                                                    data.id
                                                  )
                                                }
                                              >
                                                <Tooltip title={data.id}>
                                                  <CameraAltIcon
                                                    aria-label={key}
                                                  />
                                                </Tooltip>
                                              </Button>
                                            </label>
                                          </div>
                                        </Grid>
                                      );
                                    })}
                                    <img src={carImage} alt="carImage" />
                                  </div>
                                </Grid>
                                <Grid container item xs={12}>
                                  <Box mt={2}></Box>
                                </Grid>
                                <Grid container item xs={12}>
                                  <Paper style={{ padding: 15, flexGrow: 1 }}>
                                    {exteriorImages && (
                                      <ImagesView
                                        exteriorImages={exteriorImages}
                                      />
                                    )}
                                  </Paper>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        )}

                        {key === "interior" && (
                          <Grid container item xs={12}>
                            <Paper className={classes.root} elevation={0}>
                              <Grid container item xs={12}>
                                <Typography variant="h2">
                                  {vehicleCheckTitles[key]}
                                </Typography>
                              </Grid>
                              <Grid container>
                                <Box mt={2}></Box>
                              </Grid>
                              <Paper className={classes.root} elevation={3}>
                                <Grid item container xs={12}>
                                  <Grid container item xs={12}>
                                    {Object.keys(vehicleChecks).map(
                                      (elementKey: string) => {
                                        const vehicleChecksElement = ((vehicleChecks as unknown) as {
                                          [key: string]: VehicleStateElementType;
                                        })[elementKey];
                                        return (
                                          <Grid container item xs={12}>
                                            {typeof vehicleChecksElement ===
                                              "object" && (
                                                <Grid container item xs={12}>
                                                  <Grid container item xs={12}>
                                                    <Box mb={1}></Box>
                                                  </Grid>
                                                  <Grid container item xs={12}>
                                                    <Typography
                                                      style={{
                                                        textTransform: "uppercase"
                                                      }}
                                                      variant="subtitle1"
                                                    >
                                                      {
                                                        vehicleCheckTitles[
                                                        elementKey
                                                        ]
                                                      }
                                                    </Typography>
                                                  </Grid>
                                                  <Grid container item xs={12}>
                                                    {Object.keys(
                                                      vehicleChecksElement
                                                    ).map(
                                                      (
                                                        elementChildKey: string
                                                      ) => {
                                                        const vehicleChecksElementChild = ((vehicleChecksElement as unknown) as {
                                                          [key: string]:
                                                          | boolean
                                                          | string
                                                          | string[];
                                                        })[elementChildKey];
                                                        if (
                                                          typeof vehicleChecksElementChild ===
                                                          "boolean"
                                                        ) {
                                                          return (
                                                            <Grid
                                                              container
                                                              item
                                                              xs={3}
                                                            >
                                                              <Grid
                                                                container
                                                                item
                                                                xs={3}
                                                                alignItems="center"
                                                              >
                                                                <Typography variant="h4">
                                                                  {
                                                                    vehicleCheckTitles[
                                                                    elementChildKey
                                                                    ]
                                                                  }
                                                                </Typography>
                                                              </Grid>
                                                              <Grid
                                                                container
                                                                item
                                                                xs={9}
                                                              >
                                                                <Field
                                                                  component={
                                                                    RadioGroup
                                                                  }
                                                                  name={
                                                                    elementChildKey
                                                                  }
                                                                  row
                                                                  required
                                                                  value={
                                                                    vehicleChecksElementChild
                                                                      ? "yes"
                                                                      : "no"
                                                                  }
                                                                  onChange={(
                                                                    event: React.ChangeEvent<
                                                                      HTMLInputElement
                                                                    >
                                                                  ) => {
                                                                    const check =
                                                                      event.target
                                                                        .value ===
                                                                        "yes"
                                                                        ? true
                                                                        : false;

                                                                    setValues({
                                                                      ...values,
                                                                      [key]: {
                                                                        ...values[
                                                                        key
                                                                        ],
                                                                        [elementKey]: {
                                                                          ...vehicleChecksElement,
                                                                          [event
                                                                            .target
                                                                            .name]: check
                                                                        }
                                                                      }
                                                                    });
                                                                  }}
                                                                >
                                                                  <FormControlLabel
                                                                    value="yes"
                                                                    name={
                                                                      elementChildKey
                                                                    }
                                                                    control={
                                                                      <Radio
                                                                        required
                                                                      />
                                                                    }
                                                                    label="Yes"
                                                                  />
                                                                  <FormControlLabel
                                                                    value="no"
                                                                    name={
                                                                      elementChildKey
                                                                    }
                                                                    control={
                                                                      <Radio
                                                                        required
                                                                      />
                                                                    }
                                                                    label="No"
                                                                  />
                                                                </Field>
                                                              </Grid>
                                                            </Grid>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  </Grid>
                                                </Grid>
                                              )}{" "}
                                          </Grid>
                                        );
                                      }
                                    )}
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      justifyContent="center"
                                    >
                                      <Fab
                                        className="interiorImageButton"
                                        variant="extended"
                                        size="medium"
                                        onClick={() => handleOpenDragDrop(key)}
                                      >
                                        Add Interior Images
                                      </Fab>
                                    </Grid>
                                    <Grid container item xs={12}>
                                      <Box mt={2}></Box>
                                    </Grid>
                                    <Grid container item xs={12}>
                                      <Paper
                                        style={{ padding: 15, flexGrow: 1 }}
                                      >
                                        {interiorImages.length > 0 && (
                                          <ImagesView
                                            interiorImages={interiorImages}
                                          />
                                        )}
                                      </Paper>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Paper>
                          </Grid>
                        )}
                      </React.Fragment>
                    );
                  } else if (key === "odometerReading" && "fuelStatus") {
                    return (
                      <Grid container item xs={12}>
                        <Paper className={classes.root} elevation={0}>
                          <Grid container item xs={12}>
                            <Typography variant="h3">
                              {"FUEL & MILEAGE"}
                            </Typography>
                          </Grid>
                          <Grid container item xs={12}>
                            <TableContainer component={Paper}>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                      <Typography variant="h4">
                                        Pick-up
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="h4">
                                        Drop-off
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h4">
                                        Mileage Details
                                      </Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                      <Field
                                        component={InputField}
                                        label="Mileage"
                                        placeholder="Enter Mileage"
                                        value={
                                          formValues.values.odometerReading
                                        }
                                        type="number"
                                        name="odometerReading"
                                        inputProps={{
                                          onChange: (
                                            event: React.ChangeEvent<
                                              HTMLInputElement
                                            >
                                          ) => {
                                            setValues({
                                              ...values,
                                              [event.target.name]: Number(
                                                event.target.value
                                              )
                                            });
                                          }
                                        }}
                                      ></Field>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="h4">
                                        Fuel Details
                                      </Typography>{" "}
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                      <Grid container item xs={12}>
                                        {fuelStatus.map((fuelStatus: any) => {
                                          return (
                                            <Field
                                              component={RadioGroup}
                                              name={fuelStatus}
                                              row
                                              value={
                                                formValues.values.fuelStatus
                                              }
                                              required
                                              onChange={(
                                                event: React.ChangeEvent<
                                                  HTMLInputElement
                                                >
                                              ) => {
                                                setValues({
                                                  ...values,
                                                  [event.target.name]:
                                                    event.target.value
                                                });
                                              }}
                                            >
                                              <FormControlLabel
                                                value={fuelStatus.value}
                                                name={"fuelStatus"}
                                                control={<Radio required />}
                                                label={fuelStatus.label}
                                              />
                                            </Field>
                                          );
                                        })}
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  }
                })}

              <Grid container item xs={12}>
                <Fab
                  className="blackBackButton"
                  variant="extended"
                  size="medium"
                  onClick={handlePrevious}
                >
                  BACK
                </Fab>
                <Box ml={2}></Box>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Create"
                  type="submit"
                >
                  Proceed
                </Fab>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
