import { gql } from "@apollo/client";

export const GENERATE_EOD_BANKING_REPORT_QUERY = gql`
  query generateBankingReport($fromDate: String!, $id: String) {
    generateBankingReport(fromDate: $fromDate, id: $id) {
      success
      id
      key
      message
    }
  }
`;