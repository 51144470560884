import {
  Avatar,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import React from "react";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    cardStyle: {
      minWidth: "calc(100vw - 60px)",
      maxWidth: "calc(100vw - 60px)",
      borderRadius: "12px",

    },
    avatarCustom: {
      backgroundColor: "orange",
      width: theme.spacing(6),
      height: theme.spacing(6),
    }
  }));

export const MobileAdminUserCard = ({ id, name, email, organisation, branch, role }: {
  id: string, name: string, email: string, organisation: string, branch: string, role: string
}) => {
  const classes = useStyle();
  const navigate = useNavigate();

  function routeTo() {
    if (id) {
      navigate(`/update-user?user=${id}`);
    }
  }

  return (
    <ButtonBase onClick={() => routeTo()}>
      <Card className={classes.cardStyle}>
        <CardContent>
          <Grid container item xs={12} spacing={2}>
            <Grid item container xs={12} md={3} justifyContent="center">
              <Avatar className={classes.avatarCustom}>{name[0]}</Avatar>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography style={{ fontWeight: "bold" }}>{name}</Typography>
              <Typography>{email}</Typography>
              <Typography>{organisation}</Typography>
              <Typography>{branch}</Typography>
              <Typography>{role}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ButtonBase >
  );
};

export default MobileAdminUserCard;