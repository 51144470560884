

import { gql } from "@apollo/client";

export const UPLOAD_DEFLEET_VEHICLES = gql`
  mutation uploadDefleetVehicles($deFleetvehicles: [UploadVehicleDefleetInput]) {
    uploadDefleetVehicles(deFleetvehicles: $deFleetvehicles) {
    errors {
      index
      message
    }
    success
    message
    }
  }
`;