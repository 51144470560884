import { gql } from "@apollo/client";

export const GET_VEHICLE_ACTIVE_DAMAGES = gql`
  query getVehicleActiveDamages($vehicleId:ID!) {
    getVehicleActiveDamages(vehicleId:$vehicleId) {
      id
      referenceNumber
      circumstance
      bookingRef
      damages{
        descriptions
      }
      reportDate
      status
    }
  }
`;

export const DOWNLOAD_JOB_SHEET = gql`
  query downloadJobSheet($vehicleServiceId: String!) {
    downloadJobSheet(vehicleServiceId: $vehicleServiceId){
        success
        message
        key
    }
  }
`;
