import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid, IconButton, Popover, TextField, Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import { getLocalizedInsuranceNumberSyntex, getLocalizedTaxSyntex } from "../../../../../utils/localized.syntex";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { SettingsMap, SubscriptionAgreementSettingsMap } from "./utils";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from "@mui/icons-material/CheckCircle"

interface IProps {
  availableList: string[];
  customFields: any;
  selectedList: string[];
  itemName: string;
  updateSelected(selectedList: string[], itemName: string, rentalAgreementSettingsMap?: any): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: "2rem",
      width: "100%",
      alignItems: "center",
      border: "solid 1px black",
      fontSize: 16
    },
    titleBlock: {
      paddingLeft: "0.5rem",
      backgroundColor: "lightGray",
      height: "2rem"
    },
    settingsBlock: {
      borderLeft: "solid 1px black"
    },
    secondRow: {
      borderTop: "solid 1px black"
    }
  })
);

const RowItemsSelector: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [editableFields, setEditableFields] = useState<{ [key: string]: string }>({...props.customFields});
  const [editingField, setEditingField] = useState<string | null>(null);
  const [initialCheckedState, setInitialCheckedState] = useState<{ [key: string]: boolean }>({});


  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const displayListItem = (listItem: string, itemName: string) => {
    const selectedItems = SubscriptionAgreementSettingsMap[SettingsMap[itemName]];
    if (editableFields[listItem]) {
      return editableFields[listItem];
    }
    if (selectedItems[listItem] === "VAT/TAX") {
      return getLocalizedTaxSyntex(country);
    } else if (selectedItems[listItem] === "National Insurance No.") {
      return getLocalizedInsuranceNumberSyntex(country)
    } else {
      return selectedItems[listItem];
    }
  }

  const handleChange = (event: any) => {
    let selected = [...props.selectedList];
    if (event.target.checked) {
      if (selected.indexOf(event.target.value) < 0) {
        selected.push(event.target.value)
      }
    } else {
      if (selected.indexOf(event.target.value) >= 0) {
        selected = selected.filter((item: string) => item !== event.target.value)
      }
    }
    props.updateSelected(selected, props.itemName)
  }

  const hideBlock = (event: any) => {
    if (event.target.checked) {
      props.updateSelected([], props.itemName)
    }
  }

  const getCheckedStatus = (item: string) => {
    const selectedList = [...props.selectedList];
    if (selectedList.indexOf(item) > -1) {
      return true;
    } else {
      return false;
    }
  }

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, listItem: string) => {
    const value = event.target.value;
    setEditableFields({
      ...editableFields,
      [listItem]: value,
    });
  };

  const handleEditClick = (listItem: string) => {
    setInitialCheckedState({
      ...initialCheckedState,
      [listItem]: getCheckedStatus(listItem),
    });
    setEditingField(listItem);
  };

  const handleSaveClick = () => {
    props.updateSelected(props.selectedList, props.itemName, {[SettingsMap[props.itemName]]: editableFields});
    setEditingField(null);
  };

  return (
    <Grid container xs={12} className={classes.root}>
      <Grid xs={2} className={classes.titleBlock}>
        {props.itemName}
      </Grid>
      <Grid xs={9} container>
        <Grid xs={12} container>
          {props.selectedList.map((listItem: string, index: number) => {
            const data = displayListItem(listItem, props.itemName)
            if (data) {
              return (
                <Grid container xs={3} justifyContent="center" style={{ borderRight: "solid 1px black" }} className={index >= 4 ? classes.secondRow : ""}>
                  {data}
                </Grid>
              )
            }
          })}
        </Grid>
      </Grid>
      <Grid container xs={1} justifyContent="center" className={classes.settingsBlock}>
        <SettingsIcon style={{ cursor: "pointer" }} id="settingsIcon" onClick={handleClick} />
        <Popover
          id="settingsIcon"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}

        >
          {props.availableList.map((listItem: string, index: number) => {
            return (
              <FormGroup row key={index} style={{ padding: "0 1rem 0rem 1rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getCheckedStatus(listItem)}
                      onChange={handleChange}
                      value={listItem}
                      color="secondary"
                      name={listItem}
                    />
                  }
                  label={
                    <Grid container alignItems="center">
                      {editingField === listItem ? (
                        <TextField
                          value={displayListItem(listItem, props.itemName)}
                          onChange={(event) => handleFieldChange(event, listItem)}
                          style={{ marginRight: "0.5rem" }}
                        />
                      ) : (
                        <Typography variant="body1" style={{ marginRight: "0.5rem" }}>
                          {displayListItem(listItem, props.itemName)}
                        </Typography>
                      )}
                      <IconButton 
                        onClick={(event) => {
                          if (editingField === listItem) {
                            handleSaveClick()
                          } else {
                            handleEditClick(listItem)
                          }
                          event.preventDefault();
                          event.stopPropagation();
                        }}>
                        {editingField === listItem ? <CheckIcon /> : <EditIcon />}
                      </IconButton>
                    </Grid>
                  }
                />
              </FormGroup>
            )
          })}
          <FormGroup row key={props.availableList.length} style={{ padding: "0 1rem 0rem 1rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!props.selectedList.length}
                  onChange={hideBlock}
                  value={"Hide this block"}
                  color="secondary"
                  name={"Hide this block"}
                />
              }
              label={<Typography variant="body1">Hide this block</Typography>}
            />
          </FormGroup>
        </Popover>
      </Grid>
    </Grid>
  )
}

export default RowItemsSelector;