import { useMutation } from "@apollo/client";
import { Box, CircularProgress, Fab, Grid, Typography } from "@mui/material";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CREATE_PAYMENT_FOR_DEPOSIT } from "../../../../../graphql/bookings/createPaymentForDepositMutation";
import { IBooking } from "../../../../../reducers/bookings/types";
import {
  IDepositPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";

interface IProps {
  amount: number;
  booking: IBooking;
  handleClose: () => void;
}

const CashTransactionDeposit = (props: IProps) => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency, cashEnabled } = userState.currentOrganisation;

  const [createPaymentForDeposit, { loading, data: createDepositPaymentData }] = useMutation(CREATE_PAYMENT_FOR_DEPOSIT, {
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
    onCompleted: async () => {
      props.handleClose()
    }
  });

  const onSubmit = () => {
    const payment: IDepositPaymentInput = {
      amount: props.amount,
      currency,
      booking: props.booking.id,
      paymentMode: PaymentMode.CASH
    };
    createPaymentForDeposit({
      variables: {
        payment
      }
    })
  };

  const AMOUNT_TOO_HIGH = `Amount must be less than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format(props.amount / 100)}`;

  const formSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan(0)
      .max(props.amount, AMOUNT_TOO_HIGH)
  });

  return (
    <Grid container style={{ display: "inline-block", padding: "1rem" }}>
      {!cashEnabled ?
        <Grid container>
          <Typography variant="h4">
            To use this payment mode please enable cash payments in Payment and Integration Settings.
          </Typography>
        </Grid> :
        <Formik
          initialValues={{ amount: props.amount }}
          enableReinitialize={true}
          onSubmit={(values: any, formikBag: any) => {
            onSubmit();
            formikBag.setSubmitting(false);
          }}
          validationSchema={formSchema}
        >
          {(formikProps) => (
            <Form>
              <Grid container>
                <Grid item container xs={12} spacing={1}>
                  <Grid item container xs={12}>
                    <Typography variant="h3">{PaymentMode.CASH}</Typography>
                  </Grid>
                  <Grid container>
                    <Box mt={1}></Box>
                  </Grid>
                  <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Field
                      component={TextField}
                      placeholder="Enter Amount"
                      label="Enter Amount"
                      name={"amount"}
                      InputProps={{
                        inputComponent: FloatInput as any
                      }}
                      inputProps={{
                        hasCurrencyPrefix: true,
                        allowNegative: false
                      }}
                      disabled
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid container>
                    <Box mt={1}></Box>
                  </Grid>
                  <Grid item container xs={12} justifyContent="flex-start">
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                      Save
                    </Fab>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      }
    </Grid>
  );
};

export default CashTransactionDeposit;