import "./index.scss";
import { useLazyQuery } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  Grid,
  Typography
} from "@mui/material";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTableState } from "../../../actions/tableState/actions";
import { ITable, TableNames } from "../../../reducers/tableState/types";
import { IAppState } from "../../../store";
import { NuvvenTable } from "../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../common/NuvvenTable/types";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  returnSortedOrder,
  setTableSortOrder,
  toCurrency,
} from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { MUIDataTableOptions } from 'mui-datatables';
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { GET_CREDIT_NOTES } from "../../../graphql/invoices/getCreditNotes";
import { GET_CREDIT_NOTES_COUNT } from "../../../graphql/invoices/getCreditNotesCountQuery";
import { ICreditNote } from "../../../reducers/bookings/types";

const CreditNotes: React.FC = () => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const { locale, currency } = userState.currentOrganisation;
  const [selection, setSelection] = useState<any[]>([]);
  const { country } = userState.currentOrganisation.address;
  const creditNotesTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.creditNotes
  );
  const [creditNotes, setCreditNotes] = useState<ICreditNote[]>([]);
  const [rows, setRows] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [creditNotesTable, setCreditNotesTable] = useState<ITable>();
  const [totalCreditNotesCount, setTotalCreditNotesCount] = useState<number>(0);
  const [limit, setLimit] = useState(10);

  const [loadCreditNotes, { loading: creditNotesLoading, data: creditNotesData }] = useLazyQuery(
    GET_CREDIT_NOTES,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [getCreditNotesCount, { data: creditNotesCountData }] = useLazyQuery(
    GET_CREDIT_NOTES_COUNT,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  useEffect(() => {
    if (creditNotesCountData && creditNotesCountData.creditNotesCount) {
      setTotalCreditNotesCount(creditNotesCountData.creditNotesCount)
    }
  }, [creditNotesCountData])

  useEffect(() => {
    if (creditNotesTableState) {
      setCreditNotesTable(creditNotesTableState);
      let sortedOrder = returnSortedOrder(creditNotesTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, creditNotesTableState, sortedOrder)
      }
    }
  }, [creditNotesTableState]);

  useEffect(() => {
    if (userState.tenancy) {
      getCreditNotesCount();
      loadCreditNotes({
        variables: {
          limit: creditNotesTableState?.rowsPerPage ? creditNotesTableState.rowsPerPage : limit,
          offset: 0
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    if (creditNotesData && creditNotesData.creditNotes) {
      const oldCreditNotes = [...rows];
      const newCreditNotes = shapeCreditNotes(creditNotesData.creditNotes);
      const creditNotesArr = _.union(oldCreditNotes, newCreditNotes);
      setCreditNotes(creditNotesArr);
      setRows(creditNotesArr);
    }
  }, [creditNotesData])

  const shapeCreditNotes = (data: any) => {
    let creditNotesArr = data
      .map((creditNote: ICreditNote) => {
        const updatedCreditNote: ICreditNote | any = { ...creditNote };
        const isBusinessCustomerBooking = updatedCreditNote?.businessCustomer ? true : false;
        const businessCustomer = updatedCreditNote?.businessCustomer;
        const individualCustomer = updatedCreditNote.customer;
        const customer = isBusinessCustomerBooking
          ? businessCustomer?.businessName || ""
          : individualCustomer
            ? individualCustomer.firstName + " " + individualCustomer.lastName
            : "";

        return {
          ...creditNote,
          customerName: customer,
          invoiceRef: creditNote.invoice?.invoiceRef || "",
          sentToXero: creditNote.sentToXero ? creditNote.sentToXero : false

        };
      })
    return creditNotesArr;
  }

  const columns = [
    {
      label: "Credit Note Id",
      name: "creditNoteRef"
    },
    {
      label: "Invoice Id",
      name: "invoiceRef"
    },
    {
      label: "Customer",
      name: "customerName"
    },
    {
      label: "Amount Refunded",
      name: "refundedAmount",
      options: {
        customBodyRender: (value: number) => {
          if (value) {
            return toCurrency(value, currency, locale)
          }
          return "-"
        }
      }
    },
    {
      label: "Amount Available",
      name: "availableAmount",
      options: {
        customBodyRender: (value: number) => {
          if (value) {
            return toCurrency(value, currency, locale)
          }
          return toCurrency(0, currency, locale)
        }
      }
    },
    {
      label: "Created At",
      name: "createdAt",
      options: {
        sort: true,
        customBodyRender: (value: string) => {
          if (value) {
            return getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
          } else {
            return "-"
          }
        }
      }
    }
  ]

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: totalCreditNotesCount,
    rowsPerPageOptions: [10, 20, 100],
    setRowProps: (row, rowIndex) => {
      const creditNoteRow = rows.find(
        (creditNote: ICreditNote) => creditNote.creditNoteRef === row[0]
      );
      return {
        style: { backgroundColor: creditNoteRow.sentToXero ? "#def7e8" : "#FFEFED" }
      }
    },
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return; // Skip executing changePage if there is a search text
          }
          const { page, rowsPerPage } = tableState
          if (page * rowsPerPage >= rows.length) {
            loadCreditNotes({
              variables: {
                limit: rowsPerPage,
                offset: page * rowsPerPage
              }
            })
          }
          break;
        case "changeRowsPerPage":
          setRows([]);
          loadCreditNotes({
            variables: {
              limit: tableState.rowsPerPage || limit,
              offset: 0
            }
          });
          break;
        default:
          break;
      }
    },
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: (creditNotesLoading) ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (rowData: string[]) => {
      if (rowData && rowData.length) {
        // FIXME: Idially the rows should change based on sorting and then find data based on rowindex
        const creditNoteClicked = creditNotes.find(
          (row: any) => row.creditNoteRef === rowData[0]
        );
        if (creditNoteClicked) {
          navigate(`/credit-note?creditNoteId=${creditNoteClicked.id}`)
        }
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows);
      dispatch(
        addTableState({
          tableName: TableNames.CREDIT_NOTES,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      creditNotesTable && creditNotesTable.rowsPerPage
        ? creditNotesTable.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.INVOICES,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: (action: string) => {
      if (creditNotesTable && sortOrder) {
        setTableSortOrder(columns, creditNotesTable, sortOrder);
      }
    },
    onSearchChange: (searchText: string | null) => {
      // if (searchText) {
      //   handleSearch(searchText)
      // } else {
      //   getCreditNotesCount()
      //   loadCreditNotes({
      //     variables: {
      //       limit,
      //       offset: 0
      //     }
      //   })
      // }
    },
  };

  // const handleSearch = _.debounce((searchText: string) => {
  //   if (searchText) {
  //     searchInvoice({
  //       variables: {
  //         q: searchText
  //       }
  //     })
  //   }
  // }, 1000);

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12}>
        <Typography variant="h1" color="primary">
          Credit Notes{"  "}
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        {!(creditNotesLoading && !rows.length) ? (
          <NuvvenTable
            title={""}
            rows={rows}
            columns={columns}
            setSelection={setSelection}
            options={options}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
}

export default CreditNotes;
