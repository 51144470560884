import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../../store";
import { ICreditNote } from "../../../../../../reducers/bookings/types";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { CircularProgress, Grid, Typography, Paper } from "@mui/material";
import { GET_BUSINESS_CUSTOMER_CREDIT_NOTES } from "../../../../../../graphql/bookings/getBusinessCustomerCreditNoteHistoryQuery";
import { toCurrency } from "../../../../../common/utils";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../../../../common/NuvvenTable/types";
import { useNavigate } from "react-router-dom";

interface IProps {
  customerId: string;
}

const columns = [
  {
    label: "ID",
    name: "creditNoteRef"
  },
  {
    label: "Invoice Ref",
    name: "invoiceRef"
  },
  {
    label: "Total Amount",
    name: "totalAmount"
  },
  {
    label: "Remaining Amount",
    name: "formattedAvailableAmount"
  }
];

export const CreditNoteHistory: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const [rows, setRows] = useState<any[]>([]);
  const [creditNotes, setCreditNotes] = useState<ICreditNote[]>([])
  const [loadCreditNotes, { loading, data }] = useLazyQuery(
    GET_BUSINESS_CUSTOMER_CREDIT_NOTES,
    {
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    if (userState.tenancy) {
      loadCreditNotes({
        variables: {
          id: props.customerId
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    if (data && data.creditNotesByBusinessCustomerId?.length) {
      setCreditNotes(data.creditNotesByBusinessCustomerId)
      setRows(
        data.creditNotesByBusinessCustomerId.map((creditNote: ICreditNote) =>
          reshapeCreditNoteIntoRow(creditNote)
        )
      );
    }
  }, [data]);

  const reshapeCreditNoteIntoRow = (creditNote: ICreditNote) => {
    let data: any = { ...creditNote }
    data.invoiceRef = creditNote.invoice.invoiceRef || "";
    data.totalAmount = toCurrency(creditNote.totalAmount, currency, locale);
    data.formattedAvailableAmount = toCurrency(creditNote.availableAmount, currency, locale);
    return data
  }

  const calculateAvailableCreditNoteBalance = () => {
    let amount = 0;
    rows.forEach((row: any) => {
      amount += row.availableAmount
    })
    return toCurrency(amount, currency, locale);
  }

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    responsive: "vertical",
    elevation: 0,
    onRowClick: (rowData: string[]) => {
      if (rowData && rowData.length) {
        // FIXME: Idially the rows should change based on sorting and then find data based on rowindex
        const creditNoteClicked = creditNotes.find(
          (row: any) => row.creditNoteRef === rowData[0]
        );
        if (creditNoteClicked) {
          navigate(`/credit-note?creditNoteId=${creditNoteClicked.id}`)
        }
      }
    },
  }

  return (
    <div>
      {loading ?
        <CircularProgress /> :
        (
          <>
            <Grid xs={12} container style={{ marginBottom: "1rem" }}>
              <Typography variant="h3">
                Available Credit Note Balance: {calculateAvailableCreditNoteBalance()}
              </Typography>
            </Grid>
            <Paper variant="outlined">
              <NuvvenTable
                title={""}
                rows={rows}
                columns={columns}
                options={options}
              />
            </Paper>
          </>
        )}
    </div>
  );
};
