import React from 'react';
import { Box, Card, CardContent, Grid, Typography, Rating, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { CalendarToday, Person, School, Star, Event } from '@mui/icons-material';

interface ChartData {
  name: string;
  value: number;
  color: string;
}

interface RatingData {
  name: string;
  rating: number;
  reviews: number;
}

interface BookingData {
  date: number;
  bookings: number;
}

// Job Status Data
const jobStatusData: ChartData[] = [
  { name: 'Completed', value: 30, color: '#1e88e5' },
  { name: 'Pending', value: 20, color: '#ef5350' },
  { name: 'In Progress', value: 50, color: '#66bb6a' },
];

// Delivery Data
const deliveryData: ChartData[] = [
  { name: 'Courtesy Car', value: 25, color: '#4fc3f7' },
  { name: 'New Car', value: 14, color: '#ff4081' },
  { name: 'Test Drive', value: 18, color: '#7e57c2' },
];

// Collection Data
const collectionData: ChartData[] = [
  { name: 'Courtesy Car', value: 14, color: '#4fc3f7' },
  { name: 'Test Drive', value: 10, color: '#7e57c2' },
];

// Driver Availability Data
const driverAvailabilityData: ChartData[] = [
  { name: 'Available', value: 15, color: '#4fc3f7' },
  { name: 'In Service', value: 46, color: '#ff9800' },
  { name: 'On Leave', value: 6, color: '#4caf50' },
];

// Driver Training Data
const driverTrainingData: ChartData[] = [
  { name: 'Training Not Started', value: 3, color: '#7e57c2' },
  { name: 'Training in progress', value: 11, color: '#3f51b5' },
];

// Overall Rating Data
const overallRatingData: RatingData[] = [
  { name: 'Car handover', rating: 4.5, reviews: 130 },
  { name: 'Car Collection', rating: 4.0, reviews: 78 },
  { name: 'Test Drive', rating: 4.2, reviews: 105 },
  { name: 'CC Courtesy', rating: 4.3, reviews: 91 },
];

// Bookings by Date Data (Sample data for July 2024)
const bookingsData: BookingData[] = [
  { date: 1, bookings: 5 },
  { date: 2, bookings: 3 },
  { date: 3, bookings: 7 },
  // ... add more dates as needed
];

interface PieChartComponentProps {
  data: ChartData[];
  innerRadius?: number;
  outerRadius?: number;
}

const PieChartComponent: React.FC<PieChartComponentProps> = ({ data, innerRadius = 0, outerRadius = 80 }) => (
  <ResponsiveContainer width="100%" height={200}>
    <PieChart>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        fill="#8884d8"
        paddingAngle={2}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip />
      <Legend layout="vertical" align="right" verticalAlign="middle" />
    </PieChart>
  </ResponsiveContainer>
);

interface DashboardCardProps {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ title, icon, children }) => (
  <Card elevation={3} sx={{ height: '100%' }}>
    <CardContent>
      <Box display="flex" alignItems="center" mb={2}>
        {icon}
        <Typography variant="h6" component="div" ml={1}>
          {title}
        </Typography>
      </Box>
      {children}
    </CardContent>
  </Card>
);

interface BookingsCalendarProps {
  data: BookingData[];
}

const BookingsCalendar: React.FC<BookingsCalendarProps> = ({ data }) => {
  const daysInWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const weeks: number[][] = [
    [25, 26, 27, 28, 29, 30, 1],
    [2, 3, 4, 5, 6, 7, 8],
    [9, 10, 11, 12, 13, 14, 15],
    [16, 17, 18, 19, 20, 21, 22],
    [23, 24, 25, 26, 27, 28, 29],
    [30, 31, 1, 2, 3, 4, 5],
  ];

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {daysInWeek.map((day) => (
              <TableCell key={day} align="center">{day}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {weeks.map((week, weekIndex) => (
            <TableRow key={weekIndex}>
              {week.map((day) => {
                const bookingData = data.find(b => b.date === day);
                const isCurrentMonth = day >= 1 && day <= 31;
                return (
                  <TableCell 
                    key={day} 
                    align="center" 
                    sx={{
                      bgcolor: bookingData ? '#e3f2fd' : 'inherit',
                      color: isCurrentMonth ? 'inherit' : 'text.disabled',
                    }}
                  >
                    <Typography variant="body2">{day}</Typography>
                    {bookingData && (
                      <Typography variant="caption" color="primary">
                        {bookingData.bookings} bookings
                      </Typography>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Dashboard: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1, p: 3, bgcolor: '#f5f5f5' }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <DashboardCard title="Job Status" icon={<CalendarToday color="primary" />}>
            <PieChartComponent data={jobStatusData} />
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard title="Delivery" icon={<CalendarToday color="secondary" />}>
            <PieChartComponent data={deliveryData} />
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard title="Collection" icon={<CalendarToday color="success" />}>
            <PieChartComponent data={collectionData} />
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard title="Bookings by Date" icon={<Event color="primary" />}>
            <Typography variant="subtitle1" gutterBottom>July 2024</Typography>
            <BookingsCalendar data={bookingsData} />
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard title="Driver Availability" icon={<Person color="primary" />}>
            <PieChartComponent data={driverAvailabilityData} innerRadius={40} outerRadius={80} />
            <Typography variant="h6" align="center" mt={2}>
              67 Active Drivers
            </Typography>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard title="Driver Training" icon={<School color="secondary" />}>
            <PieChartComponent data={driverTrainingData} innerRadius={40} outerRadius={80} />
            <Typography variant="h6" align="center" mt={2}>
              14 New Drivers
            </Typography>
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard title="Overall Rating" icon={<Star color="primary" />}>
            {overallRatingData.map((item, index) => (
              <Box key={index} display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="body2">{item.name}</Typography>
                <Box display="flex" alignItems="center">
                  <Rating value={item.rating} precision={0.1} size="small" readOnly />
                  <Typography variant="caption" ml={1}>
                    ({item.reviews})
                  </Typography>
                </Box>
              </Box>
            ))}
          </DashboardCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;