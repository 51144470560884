import { gql } from "@apollo/client";

export const UPDATE_DEFLEET_VEHICLE = gql`
  mutation updateVehicleDeFleet($vehicleDefleetInput: VehicleDefleetInput) {
    updateVehicleDeFleet(vehicleDefleetInput: $vehicleDefleetInput) {
      id
      licencePlate
      deFleetReason
      deFleetPrice
      residualValue
      namaGrading
      purchaserName
      purchaserAddress {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      deFleetCheckList {
        exteriorPaint
        wheelRims
        windscreensAndWindows
        mirrors
        bumper
        headlamps
        interiorFabric
        locksAndDoorHandles
        bothKeys
        telematicsDeviceRemoved
        pcn
        pcnTotalAmount
        licencePlateCondition
        registrationDocumentUrl
        motDocumentUrl
        loanDocumentUrl
        loanStatus
        currentLoanStatus
      }
    }
  }
`;