import {
  Divider,
  Grid,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: "25px",
      marginTop: "25px"
    }
  })
);

interface IProps {
  noMargin?: boolean;
  variant?: DividerVariant;
}

export enum DividerVariant {
  FULL_WIDTH = "fullWidth",
  INSET = "inset",
  MIDDLE = "middle"
}

export const NuvvenDivider = (props: IProps) => {
  const styles = useStyles();

  return (
    <Grid className={!props.noMargin ? styles.divider : ""} item xs={12}>
      <Divider
        variant={props.variant ? props.variant : DividerVariant.FULL_WIDTH}
      />
    </Grid>
  );
};
