import { gql } from "@apollo/client";

export const CHANGE_SUBSCRIPTION_STATUS = gql`
  mutation updateSubscriptionStatus($id: String!, $isActive: Boolean!) {
    updateSubscriptionStatus(id: $id, isActive: $isActive) {
      id
      name
      insurancePolicy {
        id
        name
      }
      addons {
        id
        name
        displayName
      }
      features
      swapsTnC
      serviceIncluded
      serviceTnC
      subscriptionTnC
      vehicles {
        id
        make
        model
      }
      isActive
    }
  }
`;
