import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress, Fab, Grid, Typography } from '@mui/material';
import { NuvvenTable } from '../../../../common/NuvvenTable/NuvvenTable';
import { MUIDataTableOptions, MUIDataTableColumn } from 'mui-datatables';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ALL_DOCUMENT_TYPES } from '../../../../../graphql/DocumentType/queries';
import { CREATE_DOCUMENT_TYPE, UPDATE_DOCUMENT_TYPE } from '../../../../../graphql/DocumentType/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { AddDocumentTypeDialog, IDocumentType } from "./AddDocumentTypeDialog"
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from '../../../../common/utils';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { SelectableRows } from "../../../../common/NuvvenTable/types";
import { ApolloError } from '@apollo/client';
import { ITable, TableNames } from '../../../../../reducers/tableState/types';
import { addTableState } from '../../../../../actions/tableState/actions';

const DocumentTypes = () => {
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [documents, setDocuments] = useState<IDocumentType[]>([]);
  const [dialog, setDialog] = useState<{ open: boolean, data: IDocumentType }>({
    open: false,
    data: {
      typeName: "",
      hasExpiry: false,
      reminderBefore: 7,
      isMandatory: false,
      applicableFor: []
    }
  });
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();
  const [documentsTable, setDocumentsTable] = useState<ITable>();
  const documentsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.documents
  );
  const [sortOrder, setSortOrder] = useState<string | undefined>();

  const columns = [
    {
      label: "Document type name",
      name: "typeName"
    },
    {
      label: "Document has expiry",
      name: "hasExpiry",
      options: {
        customBodyRender: (value: boolean) => value ? "Yes" : "No"
      }
    },
    {
      label: "Reminder Before",
      name: "reminderBefore",
      options: {
        customBodyRender: (value: any, meta: any) => {
          return value && meta.rowData[1] ? `${value} days` : "N/A"
        }
      }
    },
    {
      label: "Is Mandatory",
      name: "isMandatory",
      options: {
        customBodyRender: (value: any, meta: any) => {
          return value && meta.rowData[3] ? `Yes` : "No"
        }
        
      }
      
    }
  ];

  const [getAllDocumentTypes, { data: documentTypesData }] = useLazyQuery(
    GET_ALL_DOCUMENT_TYPES,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const [
    createDocumentTypeMutation,
    { loading: docTypeCreating }
  ] = useMutation(CREATE_DOCUMENT_TYPE, {
    onError: (error: any) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      setDocuments(prev => [...prev, data.createDocumentType]);
      snackbar({
        message: formatGraphQLErrorMessage("Document type added"),
        variant: SnackBarVariant.SUCCESS
      });
      setDialog(prev => ({ ...prev, open: false }))
    }
  });

  const [
    updateDocumentTypeMutation,
    { loading: docTypeUpdating }
  ] = useMutation(UPDATE_DOCUMENT_TYPE, {
    onError: (error: any) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      const _documents = [...documents];
      const index = _documents.findIndex((doc) => doc.id === data.updateDocumentType?.id);
      if (index >= 0) {
        _documents[index] = data.updateDocumentType
      }
      setDocuments(_documents);
      snackbar({
        message: formatGraphQLErrorMessage("Document type updated"),
        variant: SnackBarVariant.SUCCESS
      });
      setDialog(prev => ({ ...prev, open: false }))
    }
  });


  useEffect(() => {
    if (userState.tenancy) {
      getAllDocumentTypes();
    }
  }, [userState]);

  useEffect(() => {
    if (documentTypesData && documentTypesData.getAllDocumentTypes) {
      setDocuments(documentTypesData.getAllDocumentTypes)
    }
  }, [documentTypesData]);

  useEffect(() => {
    if (documentsTableState) {
      setDocumentsTable(documentsTableState);
      let sortedOrder = returnSortedOrder(documentsTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, documentsTableState, sortedOrder)
      }
    }
  }, [documentsTableState]);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row = documents[rowMeta.dataIndex];
      setDialog({ open: true, data: row });
    },
    filter: false,
    print: false,
    search: false,
    download: false,
    viewColumns: false,
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)      
      dispatch(
        addTableState({
          tableName: TableNames.DOCUMENTS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
    documentsTable && documentsTable.rowsPerPage
        ? documentsTable.rowsPerPage
        : limit,
  };

  const handleAddDocumentType = (documentType: IDocumentType) => {
    if (docTypeCreating || docTypeUpdating) {
      return
    }
    if (documentType.id) {
      return updateDocumentTypeMutation({
        variables: {
          documentTypeId: documentType.id,
          documentType
        }
      });
    }
    createDocumentTypeMutation({
      variables: {
        documentType
      }
    });
  }

  const disabledRoles = ["FINANCE_MANAGER", "FLEET_MANAGER"];

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Document Types
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          className="createButton"
          onClick={() => {
            setDialog({
              open: true,
              data: {
                typeName: "",
                hasExpiry: false,
                reminderBefore: 7,
                isMandatory: false,
                applicableFor: []
              }
            })
          }}
          disabled={disabledRoles.includes(userState.role)}
        >
          Create
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        {false ? (
          <CircularProgress />
        ) : (
          <NuvvenTable
            title={""}
            rows={documents}
            columns={columns}
            setSelection={(item: any) => item}
            options={options}
          />
        )}
      </Grid>
      <AddDocumentTypeDialog open={dialog.open} data={dialog.data} handleClose={() => setDialog(prev => ({ ...prev, open: false }))} handleSubmit={handleAddDocumentType} setButtonDisabled={docTypeCreating || docTypeUpdating} />
    </Grid>
  );
}

export default DocumentTypes

