import { gql } from "@apollo/client";


export const SAVE_POST_BOOKING_QUESTIONS = gql`
  mutation savePostBookingQuestions($bookingId: String! $postBookingQuestionsInput:[BookingQuestionInput!]) {
    savePostBookingQuestions(bookingId: $bookingId,postBookingQuestionsInput:$postBookingQuestionsInput) {
      success
      message
    }
  }
`;