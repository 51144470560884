import { gql } from "@apollo/client";

export const GET_BOOKING_SUMMARY = gql`
  query booking($id: String!) {
    booking(id: $id) {
      id
      referenceNumber
      taxExempted
      pickupServiceLocation {
        id
        name
      }
      pickupOtherLocation {
        fullAddress
      }
      dropoffOtherLocation {
        fullAddress
      }
      pickupDateTime
      dropoffServiceLocation {
        name
      }
      currentBookingSchedules {
        id
        vehicleStatus
      }
      isSubscription
      tba
      activeSubscription {
        startDate
        endDate
        subscription {
          id
          name
          insurancePolicy {
            name
          }
          addons {
            displayName
          }
        }
        vehicle {
          licencePlate
          make
          model
          vin
          id
        }
        duration
        subscriptionAmount
        enrollmentAmount
        mileageLimit
        unlimitedMileage
        pricePerExtraMile
        swapsAllowed
      }
      pcoNumber
      longTermBooking
      rateTypeDuration
      extendedDropoffDateTime
      initialDropoffDateTime
      rateTypeName
      isRecurringBilling
      billingFrequency
      dropoffDateTime
      customerType
      status
      customer {
        id
        firstName
        lastName
        email
        phoneNumber {
          phone
          country
        }
        dateOfBirth
      }
      businessCustomer {
        id
        businessName
        contact {
          phoneNumber {
            phone
          }
        }
      }
      branchId
      vehicleGroups {
        vehicleGroup {
          id
        }
        activeVehicleSchedules {
          id
          vehicleStatus
          status
          endDate
        }
        vehicleIds
        baseRate
        priceRuleOperator
        priceRuleValueType
        priceRuleTriggerType
        priceRuleAmount
        priceRuleName
        deposit
        mileageLimit
        pricePerExtraMile
        unlimitedMileage
        count
      }
      vehicleReplacement {
        vehicleIds
      }
    }
  }
`;
