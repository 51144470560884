import { gql } from "@apollo/client";

export const CREATE_ASSET_TYPE = gql`
  mutation createAssetType($assetType: AssetTypeInput!) {
    createAssetType(assetType: $assetType) {
      id
      name
      customFields{
        label
        value
      }
    }
  }
`;


export const UPDATE_ASSET_TYPE = gql`
  mutation updateAssetType($assetTypeId: String!, $assetType: AssetTypeInput!) {
    updateAssetType(assetTypeId:$assetTypeId, assetType: $assetType) {
      id
      name
      customFields{
        label
        value
      }
    }
  }
`;