import React, { useEffect, useState } from 'react';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery50Icon from '@mui/icons-material/Battery50';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import styles from './index.module.css';
import { CircularProgress, Fab, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Slide, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RefreshIcon from '@mui/icons-material/Refresh';
import CachedIcon from '@mui/icons-material/Cached';
import SpeedIcon from '@mui/icons-material/Speed';
import Battery20Icon from '@mui/icons-material/Battery20';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import StopIcon from '@mui/icons-material/Stop';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import api from "../../../Reports/api/Reports"
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { GET_SMART_VEHICLE_DATA, GET_VEHICLE_DATA } from '../../../../../graphql/fleet/getVehicleGroupTrackingQuery';
import { useLazyQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { DATE_TYPE, formatGraphQLErrorMessage, getVehicleTypeIcon, numberWithCommas } from '../../../../common/utils';
import { MobiliseImmobilise, VehicleStarterStatus } from './MobiliseImmobilise';
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { formatTelematicDateTime, getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { capitalizeFirstLetter } from '../../../ReservationManagement/utils';
import { getChargingStatusText, mapFuelTypesAndDisplayType } from '../../utils';
import { captureErrorException } from '../../../../../utils/sentry';
import { FlespiVehicleStarterStatus } from '../../../Reports/Trips/TelematicsView/MobiliseImmobilise';


const VehicleDataField = ({ title, value, subValue, icon, valueStyle }: { title: string, value: string, subValue?: string, icon: JSX.Element, valueStyle?: React.CSSProperties }) => {
  return (
    <div className={styles.itemContainer}>
      {icon}
      <br />
      <div className={styles.itemValueContainer}>
        <span className={styles.itemValue} style={valueStyle || undefined}>{value}</span>
        <span className={styles.itemSubValue}>{subValue}</span>
      </div>
      <span className={styles.itemHeading}>{title}</span>
    </div>
  );
}

interface IProps {
  active: boolean;
  handleClose: () => void;
  setData: (data?: ITelematicsData | undefined) => void;
  setMapData: (data?: IMapData | undefined) => void;
  data?: ITelematicsData;
  smartcarData: ISmartcarData[] | undefined;
  esn: string;
  vehicleId: string;
  selectedEvents: string[];
  handleSelectEvents: (selectedEvents: string[]) => void;
  vehicleLastLocation: string;
  lastLocationTime: string;
}

export const eventKeysMap: { [key: string]: { display: string, color: string } } = {
  "speeding": {
    display: "Speeding",
    color: "#0EB000"
  },
  "hardCornering": {
    display: "Hard-Corner",
    color: "#7300FF"
  },
  "harshAcceleration": {
    display: "Harsh-Accel.",
    color: "#CF9F00"
  },
  "harshBraking": {
    display: "Harsh-Brake",
    color: "#F05821"
  },
  "lostGSM": {
    display: "Lost-GSM",
    color: "#747474"
  },
  "lostGPS": {
    display: "Lost-GPS",
    color: "teal"
  }

}

export interface ITelematicsData {
  milesDriven: string;
  engineHours: string;
  fuelUsage: string;
  stoppageHours: string;
  lastLocation: string;
}

export interface ISmartcarData {
  id: string;
  latitude?: number;
  longitude?: number;
  engineOilLife?: number;
  evBatteryLevel?: number;
  evChargeStatus?: string;
  fuelLevel?: number;
  odometer?: number;
  unitSystem?: string;
  evBatteryRange?: number;
  fuelRange?: number;
}

export interface IMapRouteData {
  eventTime: string[];
  latLong: string[];
}

export interface IVehicleData {
  cylinderCapacity: string;
  fuelType: string;
  licencePlate: string;
  telemetryDeviceId: string;
  imageUrl: string;
  id: string;
  model: string;
  make: string;
  teltonikaDeviceId: string;
  immobiliser: boolean
  telemetryDevicesData: ITelemetryEventData[]
  vehicleStarter?: {
    status?: VehicleStarterStatus;
    latestActionTime?: string;
    lastestActionBy?: string;
  }
}

export interface ITelemetryEventData {
  status: FlespiVehicleStarterStatus;
  medium: string;
  disabledAt: string;
  disabledBy: string;
  enabledAt: string;
  enabledBy: string;
  active: boolean,
  telemetryDeviceId: string,
  deviceId: string
}
const defaultTelemetryEventData: ITelemetryEventData = {
  status: FlespiVehicleStarterStatus.ENGINE_BLOCK,
  medium: "",
  disabledAt: "",
  disabledBy: "",
  enabledAt: "",
  enabledBy: "",
  active: true,
  telemetryDeviceId: "",
  deviceId: ""
};

export interface IMapData {
  mapFlagsData: {
    eventTime: string[];
    speedLimit: string[];
    magnitude: string[];
    Key: string[];
    latLong: string[];
  },
  mapRoutesData: IMapRouteData;
}

const convertDistance = (value: number, smartCarUnitSystem?: string, branchDistanceUnit?: string) => {
  let result = value;
  // Convert miles to kilometers if the smart car uses the imperial unit system and the branch uses the metric unit system.
  if (smartCarUnitSystem === "imperial" && branchDistanceUnit === "kilometer") {
    result = value * 1.60934;
  }

  // Convert kilometers to miles if the smart car uses the metric unit system and the branch uses the imperial unit system.
  if (smartCarUnitSystem !== "imperial" && branchDistanceUnit === "mile") {
    result = value * 0.621371;
  }

  // If the smart car and branch use the same unit system, return the value unchanged.
  return result;
}

const getFormattedUnit = (type?: string, unit?: string) => {
  switch (type) {
    case 'distance':
      if (unit === "imperial") {
        return "mi";
      } else if (unit == "mile") {
        return "mi";
      }
      return "KMs";
    default:
      return '';
  }
};

const ForSelectedPeriod = ({ onRefresh, data }: { data: boolean, onRefresh: () => void }) => {
  return (
    <div className="flex row-flex padding-top padding-left space-between">
      <p className={styles.forSelectedPeriod}>For Selected Period : </p>
      <IconButton
        onClick={onRefresh}
        className={styles.iconButton} aria-label="refresh"  >
        {
          data ? (
            <RefreshIcon fontSize="small" />
          ) :
            <CachedIcon fontSize="small" />
        }
      </IconButton>
    </div>
  )
}

interface LastLocationAndTime {
  lastLocation: string;
  lastLocationTime: string;
}

const TelematicsViewComponent: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [vehicleData, setVehicleData] = useState<IVehicleData | undefined>();
  const [currentTelemetryEventData, setCurrentTelemetryEventsData] = useState<ITelemetryEventData>(defaultTelemetryEventData)
  const [vehicleImage, setVehicleImage] = useState<any>(getVehicleTypeIcon(""));
  const [isDefaultVehicleImage, setIsDefaultVehicleImage] = useState<boolean>(true);
  const [mobiliserOpen, setMobiliserOpen] = useState<boolean>(false);
  const [immobiliserOpen, setImmobiliserOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [trackingHistory, setTrackingHistory] = useState<number>(2);
  const [mobiliserRequired, setMobiliserRequired] = useState<boolean>(false);
  const [smartcarVehicle, setSmartcarVehicle] = useState<ISmartcarData>();
  const [smartcarLastLocationAndTime, setSmartcarLastLocationAndTime] = useState<LastLocationAndTime>();
  const [vehicleLastLocationTime, setVehicleLastLocationTime] = useState<string>("")

  const { country } = userState.currentOrganisation.address;
  const { distanceUnit: branchDistanceUnit } = userState.currentBranch;

  useEffect(() => {
    if (props.lastLocationTime) {
      const locationTime = formatTelematicDateTime(props.lastLocationTime, country)
      setVehicleLastLocationTime(locationTime)
    }
  }, [props.lastLocationTime])

  const getVehicleImage = async (url: string) => {
    if (url) {
      try {
        const file = await getSignedUrl(url)
        setVehicleImage(file);
      } catch (error) {
        captureErrorException(error)
        snackbar({
          message: error as string,
          variant: SnackBarVariant.ERROR
        });
      }
    }
  };

  const [
    getVehicle,
    { loading: getVehicleLoading, data: vehicleAPIData }
  ] = useLazyQuery(GET_VEHICLE_DATA, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.vehicle) {
        setVehicleData(data.vehicle)
        if (data.vehicle.imageUrl) {
          getVehicleImage(data.vehicle.imageUrl);
          setIsDefaultVehicleImage(false);
        } else {
          setVehicleImage(getVehicleTypeIcon(capitalizeFirstLetter(data.vehicle?.bodyType || "")));
          setIsDefaultVehicleImage(true);
        }
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    getSmartVehicleData,
    { loading: smartVehicleDataLoading }
  ] = useLazyQuery(GET_SMART_VEHICLE_DATA, {
    fetchPolicy: "network-only",
    onCompleted: async ({ getSmartCarDataForVehicleId }) => {
      if (getSmartCarDataForVehicleId) {

        const formattedData: IMapData = {
          mapFlagsData: {
            eventTime: [],
            speedLimit: [],
            magnitude: [],
            Key: [],
            latLong: [],
          },
          mapRoutesData: {
            eventTime: getSmartCarDataForVehicleId.mapRoutesData?.eventTime || [],
            latLong: getSmartCarDataForVehicleId.mapRoutesData?.latlong || []
          }
        };

        const smartCarData: ISmartcarData = {
          id: getSmartCarDataForVehicleId?.vehicleId,
          latitude: getSmartCarDataForVehicleId?.vehicleLocation?.latitude,
          longitude: getSmartCarDataForVehicleId?.vehicleLocation?.longitude,
          engineOilLife: getSmartCarDataForVehicleId?.engineOilLife?.lifeRemaining,
          evBatteryRange: getSmartCarDataForVehicleId?.eVBatteryLevel?.range || 0,
          evChargeStatus: getChargingStatusText(getSmartCarDataForVehicleId?.eVChargeStatus?.state),
          fuelRange: getSmartCarDataForVehicleId?.fuelLevel?.range,
          odometer: getSmartCarDataForVehicleId?.odometer?.distance || 0,
          unitSystem: getSmartCarDataForVehicleId?.unitSystem
        };

        if (getSmartCarDataForVehicleId?.eVBatteryLevel?.percentRemaining !== undefined) {
          smartCarData["evBatteryLevel"] = getSmartCarDataForVehicleId?.eVBatteryLevel?.percentRemaining;
        };

        if (getSmartCarDataForVehicleId?.fuelLevel?.percentRemaining !== undefined) {
          smartCarData["fuelLevel"] = getSmartCarDataForVehicleId?.fuelLevel?.percentRemaining;
        }

        const latLongs = getSmartCarDataForVehicleId.mapRoutesData?.latlong;
        let lastLocationLatitude, lastLocationLongitude, lastEventTime, locationData;
        if (latLongs?.length) {
          const [lat, long] = latLongs[latLongs.length - 1].split(",");
          lastLocationLatitude = lat;
          lastLocationLongitude = long;
        } else if (getSmartCarDataForVehicleId?.vehicleLocation) {
          const { latitude, longitude } = getSmartCarDataForVehicleId?.vehicleLocation;
          lastLocationLatitude = latitude;
          lastLocationLongitude = longitude;
        }
        const eventTimes = getSmartCarDataForVehicleId.mapRoutesData?.eventTime;
        if (eventTimes?.length) {
          lastEventTime = eventTimes[eventTimes.length - 1].split(",")[0];
        }

        if (getSmartCarDataForVehicleId.lastLocation) {
          locationData = getSmartCarDataForVehicleId.lastLocation
        }
        const locationTime = formatTelematicDateTime(lastEventTime, country)
        setSmartcarLastLocationAndTime({
          lastLocation: (locationData || "") as string,
          lastLocationTime: locationTime
        })

        setSmartcarVehicle(smartCarData);
        props.setMapData(formattedData);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (vehicleAPIData?.vehicle) {
      setVehicleData(vehicleAPIData.vehicle)
      if (vehicleAPIData.vehicle?.immobiliser && vehicleAPIData.vehicle?.telemetryDevicesData?.length) {
        const event = vehicleAPIData.vehicle?.telemetryDevicesData?.find((eventData: ITelemetryEventData) => eventData.active && eventData.medium === "TELTONIKA")
        setCurrentTelemetryEventsData(event)
      }
      if (vehicleAPIData.vehicle.imageUrl) {
        getVehicleImage(vehicleAPIData.vehicle.imageUrl);
        setIsDefaultVehicleImage(false);
      } else {
        setVehicleImage(getVehicleTypeIcon(capitalizeFirstLetter(vehicleAPIData.vehicle?.bodyType || "")));
        setIsDefaultVehicleImage(true);
      }
    }
  }, vehicleAPIData)

  const fetchData = async () => {
    try {
      props.setData(undefined);
      const { data: axiosData } = await api.getTelematicsMapData({
        esn: props.esn,
        hours: trackingHistory
      });
      if (axiosData) {
        const formattedData: ITelematicsData = {
          milesDriven: axiosData.milesDriven || "",
          engineHours: axiosData.engineHours ? parseFloat(axiosData.engineHours).toFixed(1) : "",
          fuelUsage: axiosData.fuelUsage || "",
          stoppageHours: axiosData.stoppageHours ? parseFloat(axiosData.stoppageHours).toFixed(1) : "",
          lastLocation: axiosData.lastLocation || ""
        }

        const mapData = {
          mapFlagsData: {
            eventTime: axiosData.mapFlagsData?.eventTime || [],
            speedLimit: axiosData.mapFlagsData["speedlimit"] || [],
            magnitude: axiosData.mapFlagsData?.magnitude || [],
            Key: axiosData.mapFlagsData?.Key || [],
            latLong: axiosData.mapFlagsData["latlong"] || [],
          },
          mapRoutesData: {
            eventTime: axiosData.mapRoutesData?.eventTime || [],
            latLong: axiosData.mapRoutesData["latlong"] || []
          }
        }

        props.setData(formattedData);
        props.setMapData(mapData);
      }
    }
    catch (error) {
      captureErrorException(error)

      snackbar({
        message: "Problem loading telematics data",
        variant: SnackBarVariant.ERROR
      });
    }
  }

  const loadVehicleMapRouteData = () => {
    try {
      if (props.esn) {
        fetchData();
        const re = new RegExp('^46');
        const teltonikaRe = new RegExp('^35');
        if (re.test(props.esn)) setMobiliserRequired(true);
        if (teltonikaRe.test(props.esn)) setMobiliserRequired(true);
      } else {
        if (props.vehicleId && trackingHistory) {
          getSmartVehicleData({
            variables: {
              vehicleId: props.vehicleId,
              trackingHours: trackingHistory
            }
          })
        }
      }
    } catch (error) {
      captureErrorException(error)
      snackbar({
        message: 'Problem loading vehicle data !',
        variant: SnackBarVariant.ERROR
      });
    }
  }

  useEffect(() => {
    loadVehicleMapRouteData();
  }, [props.esn, trackingHistory]);


  useEffect(() => {
    if (props.vehicleId) {
      setVehicleData(undefined);
      setSmartcarLastLocationAndTime(undefined);
      getVehicle({
        variables: {
          id: props.vehicleId
        }
      });

      const smartcar: ISmartcarData | undefined = props.smartcarData?.find(ele => ele?.id === props.vehicleId);
      setSmartcarVehicle(smartcar);
      loadVehicleMapRouteData();
    }
  }, [props.vehicleId]);

  const handleTrackingHistory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrackingHistory(parseInt(e.target.value))
  }

  const onLegendClick = (legend: string) => {
    const tempLegends = [...props.selectedEvents];
    const legendIndex = tempLegends.findIndex((item) => item === legend);
    if (legendIndex === -1) {
      tempLegends.push(legend)
    }
    else {
      tempLegends.splice(legendIndex, 1);
    }
    props.handleSelectEvents(tempLegends);
  }

  const handleStarterStatus = (status?: VehicleStarterStatus) => {
    if (!status) {
      setLoading(true)
    }
    else {
      setLoading(false)
      setVehicleData((prev) => {
        if (prev) {
          return {
            ...prev,
            vehicleStarter: {
              ...prev?.vehicleStarter,
              status
            }
          }
        }
      })
    }
  }

  const handleTelemetryDeviceStatus = (status?: FlespiVehicleStarterStatus) => {
    if (!status) {
      setLoading(true)
    } else {
      setLoading(false)
      setCurrentTelemetryEventsData(prev => {
        if (prev) {
          return {
            ...prev,
            status
          };
        }
        return prev;
      });
    }
  }

  const handleRefreshButtonClick = () => {
    getVehicle({
      variables: {
        id: props.vehicleId
      }
    });
    loadVehicleMapRouteData();
  }

  const getChargingStatusIcon = (status?: string) => {
    switch (status) {
      case 'Charging':
        return <BatteryCharging20Icon className={styles.vehicleDataFieldIconStyle} style={{ fill: '#20B6F1' }} />;
      case 'Unplugged':
        return <Battery50Icon className={styles.vehicleDataFieldIconStyle} style={{ fill: 'orange' }} />;
      case 'Charged':
        return <BatteryFullIcon className={styles.vehicleDataFieldIconStyle} style={{ fill: 'green' }} />;
      default:
        return <BatteryUnknownIcon className={styles.vehicleDataFieldIconStyle} style={{ fill: '#20B6F1' }} />;
    }
  }


  const starterStatus = vehicleData?.vehicleStarter?.status || VehicleStarterStatus.STARTER_ENABLED;

  const { fuelAndBattery, fuelOnly, batteryOnly } = mapFuelTypesAndDisplayType(vehicleData?.fuelType);
  const isDataAvailable = props.data !== undefined || smartcarVehicle !== undefined;
  return (
    <>
      <Slide direction="down" in={props.active} mountOnEnter unmountOnExit>
        <div className={styles.cardLeft}>
          <IconButton
            edge="start"
            onClick={() => {
              props.handleClose();
              props.setData();
              setTrackingHistory(2);
            }}
            aria-label="Go back"
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <div className="flex fill col-flex">
            <h2>Tracking history (hours)</h2>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="tracking-history"
                name="trackingHistory"
                value={trackingHistory}
              >
                {
                  [2, 4, 8, 12, 24].map((item) => {
                    return (
                      <FormControlLabel
                        key={item}
                        value={item}
                        control={
                          <Radio
                            checked={item === trackingHistory}
                            checkedIcon={<RadioButtonCheckedIcon style={{ fontSize: 16 }} />}
                            icon={<RadioButtonUncheckedIcon style={{ fontSize: 16 }} />}
                            value={item}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleTrackingHistory(e)
                            }}
                          />
                        }
                        label={<span className="flex" style={{ paddingTop: 3, marginLeft: -5, marginRight: 5 }}>{item}</span>}
                      />
                    )
                  })
                }
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </Slide>
      <Slide direction="left" in={props.active} mountOnEnter unmountOnExit>
        <div className={styles.cardRight}>
          <div className={styles.vehicleInfoBox}>
            <div className={`${styles.vehicleImage} ${isDefaultVehicleImage ? styles.vehicleDefaultImage : ''}`}>
              {vehicleImage && <img src={vehicleImage} alt="vehicle" />}
            </div>
            {vehicleData ? <>
              <div className={styles.vehicleDetailsMainContainer} >
                <div>
                  <span className={`flex fill bold ${styles.vehicleModelAndMake}`}>
                    {vehicleData.model} {vehicleData.make}
                  </span>
                  <div>
                    <span>{vehicleData.licencePlate.toUpperCase()}</span>
                  </div>
                </div>
                <div className={styles.vehicleCapacityContainer}>
                  <OfflineBoltIcon fontSize="small" />
                  <span> {vehicleData.cylinderCapacity || ""}</span>
                </div>
              </div>

              {props.esn &&
                <div style={{ padding: 10 }}>
                  {mobiliserRequired &&
                    <Fab
                      className={styles.mobiliseButton}
                      variant="extended"
                      size="medium"
                      type="submit"
                      onClick={() => {
                        if (starterStatus === VehicleStarterStatus.STARTER_DISABLED || (vehicleData.immobiliser && vehicleData?.telemetryDeviceId && (!vehicleData.telemetryDevicesData?.length || currentTelemetryEventData?.status === FlespiVehicleStarterStatus.ENGINE_BLOCK))) {
                          return setMobiliserOpen(true)
                        }
                        if (starterStatus === VehicleStarterStatus.STARTER_ENABLED || (vehicleData.immobiliser && vehicleData?.telemetryDeviceId && (!vehicleData.telemetryDevicesData?.length || currentTelemetryEventData?.status === FlespiVehicleStarterStatus.ENGINE_UNBLOCK))) {
                          return setImmobiliserOpen(true)
                        }
                      }}
                      disabled={starterStatus === VehicleStarterStatus.STARTER_DISABLED_INITIATED ||
                        starterStatus === VehicleStarterStatus.STARTER_ENABLED_INITIATED ||
                        currentTelemetryEventData?.status === FlespiVehicleStarterStatus.ENGINE_UNBLOCK_INITIATED ||
                        currentTelemetryEventData?.status === FlespiVehicleStarterStatus.ENGINE_BLOCK_INITIATED}
                    >
                      {loading ? (
                        <CircularProgress
                          size={18}
                          style={{ color: "white", marginRight: "10px" }}
                        />
                      ) : ((starterStatus === VehicleStarterStatus.STARTER_DISABLED ||
                        starterStatus === VehicleStarterStatus.STARTER_ENABLED_INITIATED) ||
                        (vehicleData.immobiliser && vehicleData.telemetryDeviceId && (!vehicleData.telemetryDevicesData?.length ||
                          (currentTelemetryEventData?.status === FlespiVehicleStarterStatus.ENGINE_BLOCK || currentTelemetryEventData?.status === FlespiVehicleStarterStatus.ENGINE_UNBLOCK_INITIATED)))) ? "Mobilise" : "Immobilise"}
                    </Fab>
                  }
                </div>
              }

              <div className="flex padding-left">
                {!!props.esn && (
                  <div className="flex fill col-flex">
                    <p className={styles.label}>Telemetry Device</p>
                    <span>{props.esn}</span>
                  </div>
                )}
                {!!vehicleData.fuelType && (
                  <div className="flex fill col-flex">
                    <p className={styles.label}>Fuel Type</p>
                    <span>{vehicleData.fuelType}</span>
                  </div>
                )}
              </div>
              {(props.vehicleLastLocation || smartcarLastLocationAndTime?.lastLocation) &&
                <div className="flex col-flex padding-top padding-left">
                  <div className="flex fill col-flex">
                    <p className={styles.label}>Last Location</p>
                    <span>{props.vehicleLastLocation || smartcarLastLocationAndTime?.lastLocation}</span>
                    {vehicleLastLocationTime ?
                      <Typography style={{ marginTop: 3 }} variant="body2">{vehicleLastLocationTime}</Typography>
                      : smartcarLastLocationAndTime?.lastLocationTime ?
                        <Typography style={{ marginTop: 3 }} variant="body2">{smartcarLastLocationAndTime?.lastLocationTime}</Typography>
                        : <></>
                    }
                  </div>
                </div>
              }

            </> : null}

            {
              isDataAvailable && (
                <ForSelectedPeriod
                  onRefresh={handleRefreshButtonClick}
                  data={props.esn ? props.data !== undefined : smartcarVehicle !== undefined}
                />
              )
            }
            {props.data && (
              <>
                <div className={`flex padding-top ${styles.fieldsGroup}`}>
                  <VehicleDataField
                    icon={<DriveEtaIcon className={styles.vehicleDataFieldIconStyle} style={{ fill: '#008080' }} />}
                    title="Miles driven"
                    value={props.data.milesDriven}
                  />
                  <VehicleDataField
                    icon={<HourglassFullIcon className={styles.vehicleDataFieldIconStyle} style={{ fill: '#1CC1EA' }} />}
                    title="Engine hours"
                    value={props.data.engineHours}
                    subValue={'Hrs'}
                  />
                </div>
                <div className={`flex padding-top ${styles.fieldsGroup}`}>
                  {(fuelAndBattery || fuelOnly) && (
                    <VehicleDataField
                      icon={<LocalGasStationIcon className={styles.vehicleDataFieldIconStyle} style={{ fill: '#E38519' }} />}
                      title="Fuel usage"
                      value={props.data.fuelUsage}
                      subValue={'L'}
                    />
                  )}
                  <VehicleDataField
                    icon={<StopIcon className={styles.vehicleDataFieldIconStyle} style={{ fill: '#E3B11A' }} />}
                    title="Stoppage hours"
                    value={props.data.stoppageHours}
                    subValue={'Hrs'}
                  />
                </div>
              </>
            )}

            {(getVehicleLoading || smartVehicleDataLoading) ? (
              <div className="flex cross-center main-center" style={{ height: "80%", position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2, backgroundColor: "transparent" }}>
                <CircularProgress size={24} thickness={5} />
              </div>
            ) :
              <></>
            }

            {
              smartcarVehicle && !getVehicleLoading && (
                <div>
                  <div className={`flex padding-top ${styles.fieldsGroup}`}>
                    {
                      (fuelAndBattery || batteryOnly) ? (
                        <VehicleDataField
                          icon={getChargingStatusIcon(smartcarVehicle.evChargeStatus)}
                          title="Charge Status"
                          valueStyle={{
                            fontSize: 18
                          }}
                          value={smartcarVehicle.evChargeStatus || "N/A"}
                        />
                      ) : (
                        <VehicleDataField
                          icon={<TimelapseIcon className={styles.vehicleDataFieldIconStyle} style={{ fill: 'green' }} />}
                          title="Engine oil life"
                          value={smartcarVehicle?.engineOilLife ? (smartcarVehicle?.engineOilLife * 100)?.toFixed(0)?.toString() : "N/A"}
                          subValue={smartcarVehicle?.engineOilLife !== undefined ? "% remaining" : ""}
                        />
                      )
                    }
                    <VehicleDataField
                      icon={<SpeedIcon className={styles.vehicleDataFieldIconStyle} style={{ fill: '#DBDBDB' }} />}
                      title="Odometer"
                      value={numberWithCommas(convertDistance(smartcarVehicle?.odometer ?? 0, smartcarVehicle.unitSystem, branchDistanceUnit)?.toFixed(0))}
                      subValue={getFormattedUnit("distance", branchDistanceUnit)}
                    />
                  </div>
                  {
                    (fuelAndBattery || batteryOnly) && (
                      <div className={`flex padding-top ${styles.fieldsGroup}`}>
                        <VehicleDataField
                          icon={<Battery20Icon className={styles.vehicleDataFieldIconStyle} style={{ fill: 'green' }} />}
                          title="Battery Usage"
                          value={smartcarVehicle.evBatteryLevel !== undefined ? ((1 - smartcarVehicle.evBatteryLevel) * 100).toFixed(2).toString() : 'N/A'}
                          subValue={smartcarVehicle.evBatteryLevel !== undefined ? '%' : ''}
                        />
                        <VehicleDataField
                          icon={<DriveEtaIcon className={styles.vehicleDataFieldIconStyle} style={{ fill: '#E3B11A' }} />}
                          title="Battery Range"
                          value={smartcarVehicle.evBatteryRange ? convertDistance(smartcarVehicle.evBatteryRange ?? 0, smartcarVehicle.unitSystem, branchDistanceUnit)?.toFixed(2) : 'N/A'}
                          subValue={smartcarVehicle.fuelRange !== undefined ? getFormattedUnit("distance", branchDistanceUnit) : ''}
                        />
                      </div>
                    )
                  }
                  {
                    (fuelAndBattery || fuelOnly) && (
                      <div className={`flex padding-top ${styles.fieldsGroup}`}>
                        <VehicleDataField
                          icon={<LocalGasStationIcon className={styles.vehicleDataFieldIconStyle} style={{ fill: 'red' }} />}
                          title="Fuel Usage"
                          value={smartcarVehicle.fuelLevel ? ((1 - smartcarVehicle.fuelLevel) * 100).toFixed(2).toString() : 'N/A'}
                          subValue={smartcarVehicle.fuelLevel !== undefined ? '%' : ''}
                        />
                        <VehicleDataField
                          icon={<DriveEtaIcon className={styles.vehicleDataFieldIconStyle} style={{ fill: '#1ABBE3' }} />}
                          title="Fuel Range"
                          value={smartcarVehicle.fuelRange ? convertDistance(smartcarVehicle.fuelRange ?? 0, smartcarVehicle.unitSystem, branchDistanceUnit)?.toFixed(2) : 'N/A'}
                          subValue={smartcarVehicle.fuelRange !== undefined ? getFormattedUnit("distance", branchDistanceUnit) : ''}
                        />
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
          {props.data &&
            <div className={styles.legendBox}>
              <div className="flex wrap padding-top padding-bottom position-relative">
                {Object.keys(eventKeysMap).map((key, index) => (
                  <p key={index} onClick={() => onLegendClick(key)} className={`${styles.legend} ${props.selectedEvents.includes(key) ? styles.active : ''}`}>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <g id="Group_30" data-name="Group 30" transform="translate(-876 -767)">
                          <g id="Ellipse_27" data-name="Ellipse 27" transform="translate(876 767)" fill="#fff" stroke={eventKeysMap[key].color} stroke-width="1">
                            <circle cx="6" cy="6" r="6" stroke="none" />
                            <circle cx="6" cy="6" r="5.5" fill="none" />
                          </g>
                          <circle id="Ellipse_28" data-name="Ellipse 28" cx="3" cy="3" r="3" transform="translate(879 770)" fill={eventKeysMap[key].color} />
                        </g>
                      </svg>
                    </span>
                    <span>{eventKeysMap[key].display}</span>
                  </p>
                ))}
              </div>
            </div>
          }
        </div>
      </Slide>
      <MobiliseImmobilise
        handleStarterStatus={handleStarterStatus}
        mobiliserOpen={mobiliserOpen}
        immobiliserOpen={immobiliserOpen}
        closeMobiliser={() => setMobiliserOpen(false)}
        closeImmobiliser={() => setImmobiliserOpen(false)}
        esn={props.esn}
        immobiliser={vehicleData?.immobiliser || false}
        teltonikaDeviceId={vehicleData?.teltonikaDeviceId || ""}
        handleTelemetryDeviceStatus={handleTelemetryDeviceStatus}
        vehicleId={props?.vehicleId}
      />
    </>
  )
}

export const TelematicsView = React.memo(TelematicsViewComponent);