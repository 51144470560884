export const UserJobTypes = [
	{
		label: "Vehicle Fueling",
		value: "VEHICLE_FUELING"
	},
	{
		label: "Vehicle Cleaning",
		value: "VEHICLE_CLEANING"
	},
  {
    label: "Vehicle Pickup",
    value: "VEHICLE_PICK_UP"
  },
  {
    label: "Vehicle Drop Off",
    value: "VEHICLE_DROP_OFF"
  },
  {
    label: "Vehicle Service Pickup",
    value: "VEHICLE_SERVICE_PICK_UP"
  },
  {
    label: "Vehicle Service Drop Off",
    value: "VEHICLE_SERVICE_DROP_OFF"
  },
  {
    label: "Early Termination",
    value: "EARLY_TERMINATION"
  },
  {
    label: "Booking Extension",
    value: "BOOKING_EXTENSION" 
  },
  {
    label: "Vehicle Swap", 
    value: "VEHICLE_SWAP"
  },
  {
    label: "Vehicle Check",
    value: "VEHICLE_CHECK", 
  },
  {
    label: "Approve/Reject Booking",
    value: "APPROVE_REJECT_BOOKING" 
  },
  {
    label: "Approve/Reject Customer",
    value: "APPROVE_REJECT_CUSTOMER"
  },
  {
    label: "Vehicle Transfer Pickup",
    value: "VEHICLE_TRANSFER_PICKUP"
  },
  {
    label: "Vehicle Transfer Drop Off",
    value: "VEHICLE_TRANSFER_DROP_OFF"
  },
  {
    label: "Others",
    value: "OTHERS"
  }
]

export const userJobStatus = [  
  {
    label: "PENDING",
    value: "PENDING"
  },
  {
    label: "COMPLETE",
    value: "COMPLETE"
  },
  {
    label: "IN-PROGRESS",
    value: "IN_PROGRESS"
  },
  {
    label: "CANCELLED",
    value: "CANCELLED"
  }
]