import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-mui";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IBooking, ICreditNoteInput, ICreditNoteItem } from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { getRentalTaxAmount, toCurrency } from "../../../../common/utils";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { getTaxAmount } from "../../../Invoices/utils";

interface IProps {
  description: string;
  isOpen: boolean;
  title: string;
  booking: IBooking;
  creditNotesInput: ICreditNoteInput[];
  onCancel: () => void;
  onConfirm: (creditNoteInput: ICreditNoteInput) => void;
}

export const CreditNoteCreateDialog = (props: IProps) => {
  const snackbar = useSnackBar();
  const [open, setOpen] = useState<boolean>(props.isOpen);
  const [loading, setLoading] = useState<boolean>(false);
  const [creditNotesInput, setCreditNotesInput] = useState(props.creditNotesInput)
  const userState = useSelector((state: IAppState) => state.userReducer);
  const organisation = userState.currentOrganisation;
  const { locale, currency } = organisation;
  const isOpen = props.isOpen;

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    props.onCancel();
  };

  const handleConfirm = () => {
    let flag = false;
    const refundAmount = calculateTotalRefundAmount();
    const paidAmount = calculateTotalPaidAmount();
    if (refundAmount > paidAmount) {
      flag = true;
    }
    if (flag) {
      snackbar({
        message: "Refund Amount cannot be greater than paid amount",
        variant: SnackBarVariant.ERROR
      });
    } else {
      setOpen(false);
      const data = creditNotesInput.map((creditNoteInput: ICreditNoteInput) => {
        const creditNoteItems = creditNoteInput.creditNoteItems.map((item: ICreditNoteItem) => {
          if (!item.duration) {
            delete item.duration;
          }
          return item
        })
        return {
          ...creditNoteInput,
          creditNoteItems: creditNoteItems

        }
      })
      props.onConfirm(data[0]);
    }
  };
  const validationSchema = Yup.object().shape({

  });

  const updateCreditNoteInput = (creditNoteInput: ICreditNoteInput, creditNoteItems: ICreditNoteItem[]): ICreditNoteInput => {
    if (creditNoteItems?.length) {
      let refundAmount = 0;
      creditNoteItems.forEach((item) => {
        refundAmount += (item.unitPrice * item.quantity);
      })
      return {
        ...creditNoteInput,
        creditNoteItems
      }
    }
    return creditNoteInput;
  }

  const calculateTotalRefundAmount = () => {
    let refundAmount = 0;
    creditNotesInput.forEach((creditNoteInput) => {
      creditNoteInput.creditNoteItems.forEach((item) => {
        refundAmount += item.totalAmount
      })
    })
    return refundAmount
  }

  const calculateTotalPaidAmount = () => {
    let paidAmount = 0;
    creditNotesInput.forEach((cancellationCharge) => {
      paidAmount += cancellationCharge.paidAmount
    })
    return paidAmount;
  }

  return (
    <Dialog
      open={open}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h2">
          {props.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={creditNotesInput}
            onSubmit={(extensionData, { setSubmitting }) => {
              handleConfirm();
              setSubmitting(false);
            }}
          >
            {() => (
              <Form>
                {creditNotesInput.length > 0 ?
                  <Grid container xs={12}>
                    {creditNotesInput.map((creditNoteInput: ICreditNoteInput, mainIdx: number) => {
                      return <Grid container xs={12}>
                        <Grid container xs={9}>
                          <Typography variant={"h4"}>
                            {"Invoice: "}
                            <Typography variant="body1" style={{ display: "inline" }}>
                              {creditNoteInput.invoiceRef}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid container xs={3}>
                          <Typography variant={"h4"}>
                            {"Total Paid Amount: "}
                            <Typography variant="body1" style={{ display: "inline" }}>
                              {toCurrency(creditNoteInput.paidAmount, currency, locale)}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid container xs={12}>
                          <Table aria-label="spanning table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography variant={"h4"}>Item</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant={"h4"}>Amount</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant={"h4"}>Refund Amount</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {creditNoteInput.creditNoteItems.map((item: ICreditNoteItem, idx: number) => {
                                return (
                                  <TableRow>
                                    <TableCell>
                                      {item.description}
                                    </TableCell>
                                    <TableCell>
                                      {toCurrency(
                                        item.originalPrice,
                                        currency,
                                        locale
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Field
                                        component={InputField}
                                        fullWidth
                                        placeholder={`Refund Amount`}
                                        label={`Refund Amount`}
                                        name={`totalAmount${idx}`}
                                        InputProps={{
                                          value: item.totalAmount,
                                          inputComponent: FloatInput as any,
                                          onChange: (
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) => {
                                            const val = e.target.value || "0";
                                            let item = creditNoteInput.creditNoteItems[idx];
                                            const creditNoteItemsArr = [...creditNoteInput.creditNoteItems];
                                            const creditNotesInputArr = [...creditNotesInput];
                                            item = {
                                              ...item,
                                              totalAmount: parseInt(val)
                                            };
                                            if (!item.totalAmount) {
                                              item = {
                                                ...item,
                                                unitPrice: 0,
                                                taxAmount: 0,
                                                subTotal: 0
                                              }
                                            }
                                            const taxAmount = getTaxAmount({
                                              currentAmount: item.totalAmount,
                                              originalAmount: item.originalPrice,
                                              taxAmount: item.originalTaxAmount || 0
                                            });
                                            item = {
                                              ...item,
                                              taxAmount,
                                              subTotal: item.totalAmount - (taxAmount || 0),
                                              unitPrice: Math.round((item.subTotal || 0) / ((item.duration || 1) * item.quantity))
                                            }

                                            // item.unitPrice = Math.round(item.totalAmount / (item.quantity * (item.duration || 1)))
                                            creditNoteItemsArr.splice(idx, 1, item);
                                            const updatedCreditNoteInput: ICreditNoteInput = updateCreditNoteInput(creditNoteInput, creditNoteItemsArr);
                                            creditNotesInputArr.splice(mainIdx, 1, updatedCreditNoteInput)
                                            setCreditNotesInput(creditNotesInputArr)
                                          }
                                        }}
                                        validate={() => {
                                          if (item.totalAmount > item.originalPrice) {
                                            return `Should be less than or equal to ${toCurrency(item.originalPrice, locale, currency)}.`
                                          }
                                        }}
                                        inputProps={{
                                          hasCurrencyPrefix: true,
                                          allowNegative: false
                                        }}
                                      ></Field>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    })}
                  </Grid> :
                  <Grid container xs={12}>
                    Invoice for this booking has already been voided.
                  </Grid>
                }
                <Box mt={2}></Box>
                <Grid container xs={12}>
                  <Grid container item xs={9}>
                    <Typography variant="h4">
                      {"Total Refund Amount: "}
                      <Typography variant="body1" style={{ display: "inline" }}>
                        {toCurrency(
                          calculateTotalRefundAmount(),
                          currency,
                          locale
                        )}
                      </Typography>
                    </Typography>
                    {(calculateTotalRefundAmount() > calculateTotalPaidAmount()) && <Grid item xs={12}>
                      <Typography variant="body1" style={{ color: "red" }}>
                        Note: Refund amount can't be greater than paid amount!
                      </Typography>
                    </Grid>}
                    {calculateTotalRefundAmount() < 0 && <Grid item xs={12}>
                      <Typography variant="body1" style={{ color: "red" }}>
                        Note: Refund amount can't be negative value!
                      </Typography>
                    </Grid>}
                  </Grid>

                  <Grid container item xs={12} justifyContent="flex-end">
                    <Fab
                      size="medium"
                      variant="extended"
                      disabled={(calculateTotalRefundAmount() > calculateTotalPaidAmount()) || calculateTotalRefundAmount() < 0 || creditNotesInput[0]?.creditNoteItems.some((item) => item.totalAmount > item.originalPrice)}
                      type="submit"
                    >
                      {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                      Proceed
                    </Fab>
                    <Box pl={2}></Box>
                    <Fab
                      size="medium"
                      variant="extended"
                      className="blackBackButton"
                      onClick={handleClose}
                    >
                      Cancel
                    </Fab>
                  </Grid>
                </Grid>

              </Form>
            )}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog >
  );
};
