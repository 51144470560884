import _ from "lodash";

import {
  AddonTypeAction,
  CLEAR_ADDON_TYPE,
  UPDATE_ADDON_TYPE
} from "../../actions/addonTypes/types";
import { addonInitialState } from "./consts";
import { IAddonTypeState } from "./types";

export default function (
  state: IAddonTypeState = addonInitialState,
  action: AddonTypeAction
): IAddonTypeState {
  switch (action.type) {
    case UPDATE_ADDON_TYPE:
      return {
        ...state,
        addonType: {
          ...state.addonType,
          ...action.payload
        }
      };
    case CLEAR_ADDON_TYPE:
      addonInitialState.addonType.recurringRate?.forEach(reccuringRate => {
        reccuringRate.rate = 0;
      });
      return {
        ...state,
        addonType: { ...addonInitialState.addonType }
      };
    default:
      return state;
  }
}
