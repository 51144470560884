import { DATE_TYPE, isImageFileType, returnSortedDocuments } from "../../../../common/utils";
import "./InsuranceSettings.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Fab,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Theme,
  Typography,
  FormControlLabel,
  Switch,
  Hidden
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import _ from "lodash";
import { CREATE_INSURANCE_POLICY } from "../../../../../graphql/insurancePolicy/createInsurancePolicyMutation";
import { GET_INSURANCE_POLICIES } from "../../../../../graphql/insurancePolicy/getInsurancePolicies";
import { GET_INSURANCE_POLICY } from "../../../../../graphql/insurancePolicy/getInsurancePolicy";
import { UPDATE_INSURANCE_POLICY } from "../../../../../graphql/insurancePolicy/updateInsuranceMutation";
import { IInsuranceDocument, IInsurancePolicyCreateInput } from "../../../../../reducers/insurance/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  checkDeleteOrDownloadFileType,
  checkUploadFileFormat,
  formatGraphQLErrorMessage,
  uploadFileExtensionAndContentType
} from "../../../../common/utils";
import { DocumentDialog } from "../../../CustomerManagement/CustomerDetails/DocumentDialog";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { UserRoles } from "../../../../hoc/Authorization";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { captureErrorException } from "../../../../../utils/sentry";

export const initialValues: IInsurancePolicyCreateInput = {
  name: "",
  description: "",
  excess: 0,
  documents: [],
  insuranceRate: [
    {
      rateName: "hourly",
      rateDuration: 60,
      rate: 0,
      longTerm: false
    },
    {
      rateName: "daily",
      rateDuration: 1440,
      rate: 0,
      longTerm: false
    },
    {
      rateName: "weekly",
      rateDuration: 10080,
      rate: 0,
      longTerm: false
    },
    {
      rateName: "monthly",
      rateDuration: 43200,
      rate: 0,
      longTerm: false
    }
  ],
  organisationId: "",
  isActivated: true
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    },
    uploadButton: {
      marginTop: "20px",
      backgroundColor: "#faaf40"
    },
    viewDocumentList: {
      backgroundColor: "#f4f4fa"
    }
  })
);

interface IViewDocumentProps {
  isOpen: boolean;
  deleteInProgress: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  insurancePolicyDocuments: IInsuranceDocument[];
  downloadDocument(documentUrl: string, documentName: string): void;
  deleteDocument(url: string, index: number | undefined): void;
}

const NewInsurance = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [values, setValues] = useState<IInsurancePolicyCreateInput>(_.cloneDeep(initialValues));
  const [documentDialogVisible, setDocumentDialogVisible] = React.useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [isNewInsurance, setIsNewInsurance] = useState<boolean>(true);
  const [documents, setDocuments] = useState<IInsuranceDocument[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const prevBranchRef = useRef(userState.currentBranch);

  const [
    loadInsurancePolicy,
    { loading: loadingInsurance, data: insurancePolicyData }
  ] = useLazyQuery(GET_INSURANCE_POLICY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.insurancePolicy) {
        navigate("/insurance-settings");
      }
    },
    onError: (error: ApolloError) => {
      if (formatGraphQLErrorMessage(error.message) === "Insurance Policy does not exist") {
        navigate("/insurance-settings");
      }
    }
  });

  const [createInsurance, { loading: createInsuranceLoading }] = useMutation(CREATE_INSURANCE_POLICY, {
    onCompleted: () =>
      snackbar({
        message: "Insurance Created successfully",
        variant: SnackBarVariant.SUCCESS
      }),
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
    update: (proxy, { data: { createInsurance } }) => {
      try {
        const result: any = proxy.readQuery({ query: GET_INSURANCE_POLICIES });
        proxy.writeQuery({
          data: { insurance: result.createInsurance.concat([createInsurance]) },
          query: GET_INSURANCE_POLICIES
        });
      } catch (err: any) {
        return;
      }
    }
  });

  const [updateInsurance, { loading: insuranceUpdateLoading }] = useMutation(UPDATE_INSURANCE_POLICY, {
    onCompleted: (data) => {
      if (data && data.updateInsurancePolicy) {
        if (data.updateInsurancePolicy.documents
          && data.updateInsurancePolicy.documents.length) {
          setDocuments(data.updateInsurancePolicy.documents);
        } else {
          setDocuments([])
        }
        setValues(values => {
          return {
            ...values,
            name: data.updateInsurancePolicy.name,
            insuranceRate: data.updateInsurancePolicy.insuranceRate,
            description: data.updateInsurancePolicy.description,
            excess: data.updateInsurancePolicy.excess,
            id: data.updateInsurancePolicy.id,
            organisationId: data.updateInsurancePolicy.organisationId,
            isActivated: data.updateInsurancePolicy.isActivated
          }
        });
        snackbar({
          message: "Insurance Updated successfully",
          variant: SnackBarVariant.SUCCESS
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  useEffect(() => {
    if (prevBranchRef.current !== userState.currentBranch) {
      navigate("/insurance-settings");
      prevBranchRef.current = userState.currentBranch;
    }
    setValues({
      ...values,
      organisationId: String(userState.currentOrganisation.id)
    });
  }, [userState, userState.currentBranch]);



  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const insuranceId = params.get("insuranceId");
      if (insuranceId) {
        setIsNewInsurance(false);
        loadInsurancePolicy({
          variables: {
            id: insuranceId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (insurancePolicyData && insurancePolicyData.insurancePolicy) {
      if (insurancePolicyData.insurancePolicy.documents
        && insurancePolicyData.insurancePolicy.documents.length) {
        setDocuments(insurancePolicyData.insurancePolicy.documents);
      }
      setValues(values => {
        return {
          ...values,
          name: insurancePolicyData.insurancePolicy.name,
          insuranceRate: insurancePolicyData.insurancePolicy.insuranceRate,
          description: insurancePolicyData.insurancePolicy.description,
          excess: insurancePolicyData.insurancePolicy.excess,
          id: insurancePolicyData.insurancePolicy.id,
          organisationId: insurancePolicyData.insurancePolicy.organisationId,
          isActivated: insurancePolicyData.insurancePolicy.isActivated
        }
      });
    }
  }, [insurancePolicyData]);

  async function handleDocumentSave(file: File, title: string, documentType: string, reminderBefore: number, expiryDate: string) {
    try {
      // 50MB limit exceeded
      if (file.type === "application/pdf" && file.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      if (isImageFileType(file.type) && file.size > 5242880) {
        throw new Error("File size exceeded limit of 5MB");
      }
      const insurenaceDocuments = [...documents];
      const fileName = file.name.split(".")[0];
      setUploadInProgress(true);
      if (checkUploadFileFormat(file.type)) {
        const uniqueId = uuidv4();
        const {
          fileExtension
        } = uploadFileExtensionAndContentType(file.type);
        const key = `${userState.tenancy.id}/${uniqueId}-insurance-document.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                insurenaceDocuments.unshift({
                  documentUrl: key,
                  title,
                  documentName: fileName,
                  expiryDate,
                  documentType,
                  reminderBefore
                })
                snackbar({
                  message: "Document uploaded successfully.",
                  variant: SnackBarVariant.SUCCESS
                });
                setDocumentDialogVisible(false);
                setUploadInProgress(false);
                if (values.id) {
                  delete values.organisationId;
                  updateInsurance({
                    variables: {
                      insurancePolicy: {
                        ...values,
                        documents: [...insurenaceDocuments]
                      }
                    }
                  })
                }
              }
            });
        }
      } else {
        setDocumentDialogVisible(false);
        setUploadInProgress(false);
        return snackbar({
          message: "Please only upload PDF, PNG, JPG or JPEG files",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err) {
      captureErrorException(err)
      if (err instanceof Error) {
        snackbar({ message: err.message, variant: SnackBarVariant.ERROR })
      };
    }
  }

  const downloadDocument = async (
    documentUrl: string,
    documentName: string
  ) => {
    let downloadUrl: string = "";
    if (typeof documentUrl === "object") {
      downloadUrl = documentUrl[0];
    } else if (typeof documentUrl === "string") {
      downloadUrl = documentUrl;
    }
    const { fileExtension } = checkDeleteOrDownloadFileType(
      downloadUrl
    );
    const file = await getSignedUrl(documentUrl)
    const url: any = file;
    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${documentName}.${fileExtension}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const deleteDocument = (url: string, index: number) => {
    setDeleteInProgress(true)
    const { contentType } = checkDeleteOrDownloadFileType(url[0]);
    // TODO: implement file deleteion from s3
    const { organisationId, ...rest } = values
    const updatedDocuments = [
      ...documents.slice(0, index),
      ...documents.slice(index + 1)
    ]
    const updatedValues = {
      ...rest,
      documents: updatedDocuments
    }
    if (values.id) {
      updateInsurance({
        variables: {
          insurancePolicy: updatedValues
        }
      });
    }
    snackbar({
      message: "Document Removed",
      variant: SnackBarVariant.SUCCESS
    });
    setDeleteInProgress(false);
    handleDialogClose();
  };

  const handleFormSubmit = (values: any) => {
    const insurance = {
      ...values
    };
    delete insurance.daily;
    delete insurance.hourly;
    delete insurance.monthly;
    delete insurance.weekly;
    insurance.documents = [...documents];
    if (insurance.id) {
      delete insurance.organisationId;
      updateInsurance({
        variables: {
          insurancePolicy: insurance
        }
      })
        .then(() => {
          navigate("/insurance-settings");
        })
        .catch(() => {
          return;
        });
    } else {
      delete insurance.id;
      if (insurance && !insurance.organisationId) {
        insurance.organisationId = userState.currentOrganisation.id
      }
      createInsurance({
        variables: { insurancePolicy: insurance }
      })
        .then(() => {
          navigate("/insurance-settings");
        })
        .catch(() => {
          return;
        });
    }
  };

  const handleDialogOpen = () => {
    setOpenDeleteDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDeleteDialog(false);
  }

  function handleRateChange(
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number,
    values: IInsurancePolicyCreateInput
  ) {
    const val = event.target.value || "0";
    const item = values.insuranceRate[idx];
    const updatedInsurance = [...values.insuranceRate];
    const itemToUpdate = { ...item }
    itemToUpdate.rate = parseInt(val);
    updatedInsurance.splice(idx, 1, itemToUpdate);
    setValues({
      ...values,
      insuranceRate: updatedInsurance
    });
  }
  const detailsFormSchema = Yup.object().shape({
    name: Yup.string()
      .min(1)
      .required("Policy name is required.")
  });

  const options: MUIDataTableOptions = {
    pagination: false
  };

  if (loadingInsurance) {
    return <CircularProgress />;
  }
  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6} alignItems="center">
        <Typography variant="h1" color="primary">
          Insurance{"  "}
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>
        {isNewInsurance ? (
          <Typography variant="h1" color="primary">
            New
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Insurance
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent={"flex-end"}>
        <FormControlLabel
          control={
            <Switch
              checked={values.isActivated}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setValues({
                  ...values,
                  isActivated: event.target.checked
                });
              }}
              name="isActivated"
              color="primary"
            />
          }
          label={values.isActivated ? "Active" : "Activate"}
        />
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            validationSchema={detailsFormSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              handleFormSubmit(values);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item container xs={12}>
                      <Typography variant="h2">
                        {isNewInsurance ? "CREATE" : "UPDATE"} INSURANCE
                      </Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Field
                        component={TextField}
                        placeholder="Policy Name"
                        label="Policy Name"
                        name={"name"}
                        value={props.values.name}
                        onChange={props.handleChange}
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    {props.values.insuranceRate.length > 0 &&
                      props.values.insuranceRate.map((item, index) => {
                        return (
                          <Grid container item xs={6} key={index} className={"rate-types"}>
                            <Field
                              component={TextField}
                              placeholder={item.rateName}
                              label={item.rateName}
                              name={item.rateName}
                              InputProps={{
                                onChange: (event: any) => handleRateChange(event, index, props.values),
                                value: item.rate,
                                inputComponent: FloatInput as any
                              }}
                              inputProps={{
                                hasCurrencyPrefix: true,
                                allowNegative: false
                              }}
                              fullWidth
                            />
                          </Grid>
                        );
                      })}
                    <Grid item container xs={6}>
                      <Field
                        component={TextField}
                        placeholder="EXCESS"
                        label="Excess"
                        name={"excess"}
                        InputProps={{
                          value: props.values.excess,
                          inputComponent: FloatInput as any
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                        fullWidth
                      />

                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        component={TextField}
                        id={"description"}
                        placeholder="Description"
                        label="Description"
                        name={"description"}
                        value={props.values.description}
                        inputProps={{ maxLength: 500 }}
                        rows={"3"}
                        onChange={props.handleChange}
                        multiline
                      />
                    </FormGroup>
                  </Grid>
                  <Hidden smDown>
                    <Grid spacing={2} container item xs={12}>
                      <Grid item container xs={10}>
                        <Typography variant="h3">POLICY DOCUMENT</Typography>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                        <Fab
                          className="blackBackButton"
                          variant="extended"
                          size="medium"
                          aria-label="Update"
                          onClick={() => {
                            setDocumentDialogVisible(true);
                          }}
                        >
                          ADD DOCUMENT
                        </Fab>
                      </Grid>
                      <DocumentDialog
                        open={documentDialogVisible}
                        handleClose={() => setDocumentDialogVisible(false)}
                        uploadInProgress={uploadInProgress}
                        onSubmit={handleDocumentSave}
                        accept="image/jpg, image/jpeg, image/png, application/pdf"
                      />
                      <ViewDocuments
                        isOpen={openDeleteDialog}
                        deleteInProgress={deleteInProgress}
                        handleOpen={handleDialogOpen}
                        handleClose={handleDialogClose}
                        insurancePolicyDocuments={documents}
                        downloadDocument={downloadDocument}
                        deleteDocument={deleteDocument}
                      />
                    </Grid>
                  </Hidden>
                  <Hidden smUp>
                    <Grid spacing={2} container item xs={12}>
                      <Grid item container xs={12} sm={12}>
                        <Typography variant="h3">POLICY DOCUMENT</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        <Fab
                          className="blackBackButton"
                          variant="extended"
                          size="medium"
                          aria-label="Update"
                          onClick={() => {
                            setDocumentDialogVisible(true);
                          }}
                        >
                          ADD DOCUMENT
                        </Fab>
                      </Grid>
                      <DocumentDialog
                        open={documentDialogVisible}
                        handleClose={() => setDocumentDialogVisible(false)}
                        uploadInProgress={uploadInProgress}
                        onSubmit={handleDocumentSave}
                        accept="image/jpg, image/jpeg, image/png, application/pdf"
                      />
                      <ViewDocuments
                        isOpen={openDeleteDialog}
                        deleteInProgress={deleteInProgress}
                        handleOpen={handleDialogOpen}
                        handleClose={handleDialogClose}
                        insurancePolicyDocuments={documents}
                        downloadDocument={downloadDocument}
                        deleteDocument={deleteDocument}
                      />
                    </Grid>
                  </Hidden>
                  <Grid container item xs={12}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                      disabled={createInsuranceLoading || insuranceUpdateLoading}
                    >
                      {(createInsuranceLoading || insuranceUpdateLoading) && (
                        <CircularProgress
                          size={14}
                          style={{ color: "white", marginRight: "10px" }}
                        />
                      )}
                      Save
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

const ViewDocuments = (props: IViewDocumentProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const [url, setUrl] = useState<string>("");
  const [index, setIndex] = useState<number | undefined>();

  const columns = [
    {
      label: "Document Type",
      name: "documentType",
    },
    {
      label: "Document Name/Title",
      name: "title"
    },
    {
      label: "Expiry Date",
      name: "expiryDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED) : "N/A"
      }
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <React.Fragment>
              {(userState.role === UserRoles.ADMIN || userState.role === UserRoles.SUPER_ADMIN) && (
                <div>
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() =>
                      props.downloadDocument(value.url, value.name)
                    }
                    size="large">
                    <CloudDownloadIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      setUrl(value.url);
                      setIndex(value.index);
                      return props.handleOpen();
                    }}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
            </React.Fragment>
          );
        }
      }
    }
  ];

  const getRows = (insurancePolicy: IInsuranceDocument[]) => {
    if (!insurancePolicy || insurancePolicy === null) {
      return [];
    }
    const docs = [...insurancePolicy]
    const sortedDocuments = returnSortedDocuments(docs);
    if (sortedDocuments && sortedDocuments.length) {
      return sortedDocuments.map((insuranceDocument: IInsuranceDocument, index: number) => {
        const { fileExtension } = checkDeleteOrDownloadFileType(insuranceDocument.documentUrl);
        const title = (insuranceDocument.title || insuranceDocument.documentName) + '.' + fileExtension;
        return {
          ...insuranceDocument,
          actions: { url: insuranceDocument.documentUrl, name: insuranceDocument.documentName, index },
          title: title,
          expiryDate: insuranceDocument.expiryDate,
          documentType: insuranceDocument.documentType
        };
      });
    } else {
      return []
    }
  };

  return (
    <Grid item xs={12} >
      <Paper variant='outlined'>
        <NuvvenTable
          key={new Date().getMilliseconds()}
          title={""}
          rows={getRows(props.insurancePolicyDocuments)}
          columns={columns}
          setSelection={() => { }}
          options={{
            selectableRows: "none",
            download: false,
            filter: false,
            print: false,
            search: false,
            viewColumns: false,
            elevation: 0
          }}
        />
      </Paper>
      <ConfirmationDialog
        description={"Are you sure, you want to delete this document?"}
        title={""}
        isOpen={props.isOpen}
        onCancel={props.handleClose}
        onConfirm={() => {
          if (url) {
            props.deleteDocument(url, index);
          }
        }}
        isInProgress={props.deleteInProgress}
      />
    </Grid>
  );
};


export default NewInsurance;
