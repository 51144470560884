import { Box, Grid, Paper, Theme, Typography, FormControl, TextField, Fab, CircularProgress, IconButton, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from "@mui/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Field, Form, Formik } from "formik";
import InputField from "../../common/InputField";
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_VEHICLES_LICENCE_PLATE, GET_ADDONS_FOR_EXPENSE } from '../../../graphql/fleet/getVehiclesLicencePlate';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import { DateTime as d } from "luxon";
import { IAppState } from '../../../store';
import { SimpleDateTimePicker } from '../../common/SimpleDateTimePicker';
import { FloatInput } from '../../common/FloatInput/FloatInput';
import { DocumentDialog } from '../CustomerManagement/CustomerDetails/DocumentDialog';
import { checkDeleteOrDownloadFileType, checkUploadFileFormat, DATE_TYPE, formatGraphQLErrorMessage, isImageFileType, uploadFileExtensionAndContentType } from '../../common/utils';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';
import { CREATE_EXPENSE } from '../../../graphql/fleet/createVehicleFuelExpense';
import { ApolloError } from '@apollo/client';
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { GET_EXPENSE } from '../../../graphql/fleet/getVehicleExpenseQuery';
import { UPDATE_EXPENSE } from '../../../graphql/fleet/updateVehicleExpense';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { getSignedUrl } from '../../../utils/getSignedUrl';
import { captureErrorException } from '../../../utils/sentry';
import { IAddonType } from '../../../reducers/addonType/types';
import * as Yup from "yup";
import { IExpense, IDocument, AssetTypes, AssetType, IDateTime, ExpenseType, ExpenseTypes, InsuranceCycle, initialValue } from './expenses.types';
import FlatPickerBar from "../../common/FlatPicker"
import { getLocalizedDateFormat } from '../../../utils/localized.syntex';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    iconButton: {
      padding: 10
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    title: {
      color: "skyblue",
      fontSize: 18,
      fontWeight: 500,
      textTransform: "uppercase"
    },
    table: {
      minWidth: 650,
    },
    button: {
      backgroundColor: "#faaf40",
      border: 0,
      borderRadius: 26,
      color: "white",
      fontSize: "14px",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    }
  })
);

export const NewFuelExpense: React.FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const location = useLocation();
  const [values, setValues] = useState<IExpense>(initialValue)
  const [licencePlate, setLicensePlate] = useState<string>("");
  const [addons, setAddons] = useState<Partial<IAddonType>[]>([]);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [regNoError, setRegNoError] = useState<boolean>(false)
  const [regNoErrorText, setRegNoErrorText] = useState<string>("")
  const [addonError, setAddonError] = useState<boolean>(false)
  const [addonErrorText, setAddonErrorText] = useState<string>("")
  const [odometerErrorText, setOdometerErrorText] = useState<string>("");
  const [odometerValues, setOdometerValues] = useState<boolean>(false);
  const [vendorValues, setVendorValues] = useState<boolean>(false);
  const [quantityValues, setQuantityValues] = useState<boolean>(false);
  const [quantityErrorText, setQuantityErrorText] = useState<string>("");
  const [vendorErrorText, setVendorErrorText] = useState<string>("");
  const [priceValues, setPriceValues] = useState<boolean>(false);
  const [priceErrorText, setPriceErrorText] = useState<string>("");
  const [expenseId, setExpenseId] = useState<string>("")
  const [addonName, setAddonName] = useState<string>("");
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);

  const styles = useStyles();

  const [
    loadVehiclesLicencePlate,
    { loading: loadingLicenceNumbers, data: vehiclesData }
  ] = useLazyQuery(GET_VEHICLES_LICENCE_PLATE, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [getExpense, { data: expenseData }] = useLazyQuery(GET_EXPENSE, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (!data.expense) {
        navigate("/expenses");
      }
    },
  });

  const [
    loadAddons,
    { loading: loadingAddons, data: addonData }
  ] = useLazyQuery(GET_ADDONS_FOR_EXPENSE, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  useEffect(() => {
    loadVehiclesLicencePlate();
    loadAddons();
  }, [userState])

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const expense = params.get("expense");
      if (expense) {
        setExpenseId(expense)
        setIsUpdate(true)
        getExpense({
          variables: { expenseId: expense }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (expenseData?.expense) {
      if (expenseData.expense && expenseData.expense.vehicle) {
        setLicensePlate(expenseData.expense.vehicle.licencePlate);
      }
      if (expenseData.expense && expenseData.expense.addon) {
        setAddonName(expenseData.expense.addon.name)
      }
      setValues({
        ...expenseData?.expense,
        addon: expenseData.expense && expenseData.expense.addon ? expenseData.expense.addon.id : "",
        vehicle: expenseData.expense && expenseData.expense.vehicle ? expenseData.expense.vehicle.id : ""
      })
    }
  }, [expenseData])

  useEffect(() => {
    if (vehiclesData?.getOwnedAndExternalVehicles) {
      const vehicle = vehiclesData.getOwnedAndExternalVehicles.find((v: any) => v.id === values.vehicle);
      if (vehicle) {
        setValues({
          ...values,
          fuelType: vehicle.fuelType
        })
      }
    }

  }, [values.vehicle, vehiclesData])

  useEffect(() => {
    if (addonData && addonData.addonTypes) {
      setAddons(addonData.addonTypes);
    }
  }, [addonData])

  const [createExpense, { loading: saveFuelLoading }] = useMutation(CREATE_EXPENSE, {
    onCompleted: (data: any) => {
      if (data && data.createExpense) {
        snackbar({
          message: "Expense saved successfully",
          variant: SnackBarVariant.SUCCESS
        });
        navigate("/expenses");
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateExpense] = useMutation(UPDATE_EXPENSE, {
    onCompleted: (data: any) => {
      if (data && data.updateExpense) {
        snackbar({
          message: "Expense updated successfully",
          variant: SnackBarVariant.SUCCESS
        });
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  async function handleDocumentSave(file: File, title: string, documentType: string, reminderBefore: number, expiryDate: string) {
    try {
      // 50MB limit exceeded
      if (file.type === "application/pdf" && file.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      if (isImageFileType(file.type) && file.size > 5242880) {
        throw new Error("File size exceeded limit of 5MB");
      }
      const fileName = file.name.split(".")[0];
      let expenseDocument = documents;
      setUploadInProgress(true);
      if (checkUploadFileFormat(file.type)) {
        const uniqueId = uuidv4();
        const {
          fileExtension,
          contentType
        } = uploadFileExtensionAndContentType(file.type);
        const key = `${userState.tenancy.id}/${uniqueId}-fuel-expense-document.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                expenseDocument.unshift({
                  url: key,
                  title,
                  documentName: fileName,
                  expiryDate,
                  documentType,
                  reminderBefore
                })
                setDocumentDialogVisible(false);
                setUploadInProgress(false);
              }
            });
        }
      } else {
        setDocumentDialogVisible(false);
        setUploadInProgress(false);
        return snackbar({
          message: "Please only upload PDF, PNG, JPG or JPEG files",
          variant: SnackBarVariant.ERROR
        });
      }
      setValues({
        ...values,
        document: expenseDocument
      })
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  const handleFormSubmit = (values: IExpense) => {
    const { branch, ...rest } = values;
    if (typeof rest.cycle === 'string') {
      rest.cycle = parseInt(rest.cycle);
    }
    if (!isUpdate) {
      createExpense({
        variables: {
          expense: rest
        }
      })
    } else {
      updateExpense({
        variables: {
          expense: rest,
          expenseId
        }
      })
    }
  };

  async function downloadDocument(documentUrl: string, title: string) {
    if (!userState.tenancy?.id) {
      return;
    }
    const { fileExtension } = checkDeleteOrDownloadFileType(
      documentUrl
    );
    const file = await getSignedUrl(documentUrl)
    const url: any = file;
    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileExtension ? `${title}.${fileExtension}` : title;;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  const resetValues = () => {
    setValues(initialValue);
  };

  if (loadingLicenceNumbers) {
    return <CircularProgress />
  }

  const expenseValidationSchema = Yup.object().shape({});

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item sm={12} alignItems="center">
          <Typography variant="h1" color="primary">
            Expense
          </Typography>
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon />
          <Box color="white" sx={{ pl: 1 }}></Box>

          <Typography variant="h1" color="primary">
            {!isUpdate ? "Add " : "Update "} Expense
          </Typography>
        </Grid>
        <Grid container item sm={12}>
          <Paper className={styles.root}>
            <Formik
              validationSchema={expenseValidationSchema}
              enableReinitialize
              initialValues={values}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values);
                setSubmitting(false);
              }}
            >
              {(props) => {
                return (
                  <React.Fragment>
                    {vehiclesData &&
                      vehiclesData.getOwnedAndExternalVehicles &&
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth>
                              <Field
                                component={TextField}
                                name={"assetType"}
                                fullWidth
                                type="text"
                                select
                                required
                                value={props.values.assetType}
                                disabled={isUpdate}
                                label="Asset Type"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{
                                  onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setValues({
                                      ...props.values,
                                      ...initialValue,
                                      assetType: event.target.value
                                    });
                                  },
                                }}
                              >
                                {AssetTypes.map((item: any, index: number) => {
                                  return (
                                    <MenuItem key={index} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                              </Field>
                            </FormControl>
                          </Grid>
                          {props.values.assetType === AssetType.ADDON && (
                            <Grid container item xs={3}>
                              {isUpdate ?
                                <Field
                                  component={InputField}
                                  value={addonName}
                                  fullWidth
                                  disabled
                                  label="Addon"
                                /> :
                                <Autocomplete
                                  id="combo-box-demo"
                                  options={addons}
                                  getOptionLabel={(option: Partial<IAddonType>) => {
                                    if (option) {
                                      return option.displayName ? option.displayName : option.name ? option.name : ""
                                    } else return "";
                                  }}
                                  fullWidth
                                  onChange={(event: any, newVal: any | null) => {
                                    setAddonError(false);
                                    setAddonErrorText("");
                                    if (newVal) {
                                      setValues({
                                        ...values,
                                        addon: newVal.id
                                      })
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Addon"
                                      required
                                      fullWidth
                                      name="addon"
                                      value={props.values.addon}
                                      error={regNoError}
                                      helperText={regNoErrorText}
                                      onBlur={(e) => {
                                        if (e.target.value === "") {
                                          setAddonError(true)
                                          setAddonErrorText("Addon is required")
                                        }
                                        else {
                                          setAddonError(false)
                                          setAddonErrorText("")
                                        }
                                      }}
                                    />
                                  )}
                                />
                              }
                            </Grid>
                          )}
                          {props.values.assetType === AssetType.VEHICLE && (
                            <Grid container item xs={3}>
                              {isUpdate ?
                                <Field
                                  component={InputField}
                                  value={licencePlate}
                                  fullWidth
                                  disabled
                                  label="Registration Number"
                                /> : <Autocomplete
                                  id="combo-box-demo"
                                  options={vehiclesData?.getOwnedAndExternalVehicles?.filter((v: any) => v.status === "Active" && !(v.isGhostVehicle))}
                                  getOptionLabel={(option: any) => {
                                    if (option) {
                                      return userState.currentBranch.id === option && option.branchDetails && option.branchDetails.id ?
                                        `${option.licencePlate}` : `${option.licencePlate} (${option.branchDetails.name})`
                                    } else return "";
                                  }}
                                  fullWidth
                                  onChange={(event: any, newVal: any | null) => {
                                    setRegNoError(false);
                                    setRegNoErrorText("");
                                    if (newVal) {
                                      setValues({
                                        ...values,
                                        vehicle: newVal.id
                                      })
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      value={""}
                                      label="Registration Number"
                                      required
                                      fullWidth
                                      name="vehicleId"
                                      error={regNoError}
                                      helperText={regNoErrorText}
                                      onBlur={(e) => {
                                        if (e.target.value === "") {
                                          setRegNoError(true)
                                          setRegNoErrorText("Registration number is required")
                                        }
                                        else {
                                          setRegNoError(false)
                                          setRegNoErrorText("")
                                        }
                                      }}
                                    />
                                  )}
                                />}
                              {/* )} */}
                            </Grid>
                          )}
                          <Grid container item xs={3}>
                            <FlatPickerBar
                              enableTime={false}
                              handleDateChange={(value: Date) => {
                                if (value) {
                                  setValues({
                                    ...values,
                                    expenseDate: d.fromJSDate(value).toUTC().toISO(),
                                  })
                                }
                              }}
                              label={"Expense Date*"}
                              placeholderValue={"Select Expense Date*"}
                              required={true}
                              value={getLocalizedDateFormat(country, values.expenseDate, DATE_TYPE.CONDENSED)}
                              minDate={values.expenseDate ? getLocalizedDateFormat(country, values.expenseDate, DATE_TYPE.CONDENSED) : getLocalizedDateFormat(country, d.now().minus({ year: 1 }).toUTC().toISO(), DATE_TYPE.CONDENSED)}
                              maxDate={getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}
                              country={country}
                            />
                          </Grid>
                          {props.values.assetType === AssetType.VEHICLE && (
                            <Grid item xs={3}>
                              <FormControl variant="outlined" fullWidth>
                                <Field
                                  component={TextField}
                                  name={"expenseType"}
                                  fullWidth
                                  type="text"
                                  select
                                  required
                                  value={props.values.expenseType}
                                  label="Expense Type"
                                  disabled={isUpdate}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    onChange: (
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setValues({
                                        ...props.values,
                                        expenseType: event.target.value,
                                        fuelType: event.target.value === ExpenseType.FUEL ? props.values.fuelType : "",
                                        fuelQuantity: 0,
                                        amount: 0,
                                        itemType: '',
                                        unit: 0,
                                        amountPerUnit: 0,
                                        authority: '',
                                        vendor: '',
                                        cycle: 0
                                      });
                                    },
                                  }}
                                >
                                  {ExpenseTypes.map((item: any, index: number) => {
                                    return (
                                      <MenuItem key={index} value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                              </FormControl>
                            </Grid>
                          )}

                          {props.values.expenseType === ExpenseType.FUEL && (
                            <>
                              <Grid container item xs={3}>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                >
                                  <Field
                                    component={TextField}
                                    name={"fuelType"}
                                    fullWidth
                                    required
                                    type="text"
                                    label="Fuel Type"
                                    disabled
                                    value={values.fuelType}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  >
                                  </Field>
                                </FormControl>
                              </Grid>
                            </>
                          )}

                          {props.values.expenseType === ExpenseType.FUEL && (
                            <>
                              <Grid container item sm={3}>
                                <TextField
                                  label="Quantity"
                                  name={"quantity"}
                                  value={values.fuelQuantity}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValues({
                                      ...values,
                                      fuelQuantity: parseFloat(event.target.value)
                                    });
                                  }}
                                  InputProps={{
                                    inputComponent: FloatInput as any
                                  }}
                                  fullWidth
                                  inputProps={{
                                    allowNegative: false
                                  }}
                                  required
                                  error={quantityValues}
                                  helperText={quantityErrorText}
                                  onBlur={(e) => {
                                    if (e.target.value === "0.00") {
                                      setQuantityValues(true)
                                      setQuantityErrorText("Quantity is required")
                                    }
                                    else {
                                      setQuantityValues(false)
                                      setQuantityErrorText("")
                                    }
                                  }}
                                />
                              </Grid>
                            </>
                          )}
                          {props.values.expenseType === ExpenseType.FINE && (
                            <Grid container item sm={3}>
                              <TextField
                                label="Authority"
                                name={"authority"}
                                value={props.values.authority}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  setValues({
                                    ...values,
                                    authority: event.target.value
                                  });
                                }}
                                fullWidth
                                required
                              />
                            </Grid>
                          )}
                          {props.values.expenseType === ExpenseType.MAINTENANCE_AND_VALET && (
                            <>
                              <Grid container item sm={3}>
                                <TextField
                                  label="Item Type *"
                                  name={"itemType"}
                                  value={props.values.itemType}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValues({
                                      ...values,
                                      itemType: event.target.value
                                    });
                                  }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid container item sm={3}>
                                <TextField
                                  label="Unit *"
                                  name={"unit"}
                                  type='number'
                                  value={props.values.unit}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValues({
                                      ...values,
                                      unit: parseInt(event.target.value)
                                    });
                                  }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid container item sm={3}>
                                <TextField
                                  label="Unit Price *"
                                  name={"amountPerUnit"}
                                  value={values.amountPerUnit}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValues({
                                      ...values,
                                      amountPerUnit: parseFloat(event.target.value)
                                    });

                                  }}
                                  InputProps={{
                                    inputComponent: FloatInput as any
                                  }}
                                  fullWidth
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                />
                              </Grid>
                            </>
                          )}

                          {props.values.expenseType === ExpenseType.INSURANCE && (
                            <>
                              <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth>
                                  <Field
                                    component={TextField}
                                    name={"cycle"}
                                    fullWidth
                                    type="text"
                                    select
                                    required
                                    value={props.values.cycle}
                                    label="Insurance Cycle"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    InputProps={{
                                      onChange: (
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setValues({
                                          ...props.values,
                                          cycle: parseInt(event.target.value)
                                        });
                                      },
                                    }}
                                  >
                                    {InsuranceCycle.map((item: any, index: number) => {
                                      return (
                                        <MenuItem key={index} value={item.value}>
                                          {item.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Field>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <SimpleDateTimePicker
                                  date={values.insuranceExpiryDate}
                                  handleChange={(date: IDateTime) => {
                                    if (date && date.date) {
                                      setValues({
                                        ...values,
                                        insuranceExpiryDate: date.date,
                                      })
                                    }
                                  }}
                                  required={true}
                                  name={"expenseDate"}
                                  dateTitle={"Expiry Date"}
                                  timeTitle={"Expiry Time"}
                                  timeToShow={"before"}
                                  minDate={d.now().toUTC().toISO()}
                                />
                              </Grid>
                            </>
                          )}
                          <Grid container item sm={3}>
                            <TextField
                              label="Price"
                              name={"price"}
                              value={values.amount}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setValues({
                                  ...values,
                                  amount: parseFloat(event.target.value)
                                });

                              }}
                              InputProps={{
                                inputComponent: FloatInput as any
                              }}
                              fullWidth
                              inputProps={{
                                hasCurrencyPrefix: true,
                                allowNegative: false
                              }}
                              required
                              error={priceValues}
                              helperText={priceErrorText}
                              onBlur={(e) => {
                                if (!/[1-9]/.test(e.target.value)) {
                                  setPriceValues(true)
                                  setPriceErrorText("Price is required")
                                }
                                else {
                                  setPriceValues(false)
                                  setPriceErrorText("")
                                }
                              }}
                            />
                          </Grid>
                          {props.values.assetType === AssetType.VEHICLE && (
                            <Grid container item sm={3}>
                              <TextField
                                label="Odometer"
                                name={"odometer"}
                                value={values.odometerReading}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  setValues({
                                    ...values,
                                    odometerReading: parseFloat(event.target.value) ? parseFloat(event.target.value) : 0
                                  });
                                }}
                                fullWidth
                                error={odometerValues}
                                helperText={odometerErrorText}
                                onBlur={(e) => {
                                  if (e.target.value === "0") {
                                    setOdometerValues(true)
                                    setOdometerErrorText("Odometer Reading is required")
                                  }
                                  else {
                                    setOdometerValues(false)
                                    setOdometerErrorText("")
                                  }
                                }}
                              />
                            </Grid>
                          )}

                          {(props.values.expenseType === ExpenseType.FUEL ||
                            props.values.expenseType === ExpenseType.INSURANCE) && (
                              <Grid container item sm={3}>
                                <TextField
                                  value={values.vendor}
                                  label="Vendor"
                                  required
                                  fullWidth
                                  name="vendor"
                                  error={vendorValues}
                                  helperText={vendorErrorText}
                                  onBlur={(e) => {
                                    if (e.target.value === "") {
                                      setVendorValues(true)
                                      setVendorErrorText("Vendor is required")
                                    }
                                    else {
                                      setVendorValues(false)
                                      setVendorErrorText("")
                                    }
                                  }}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValues({
                                      ...values, vendor: event.target.value
                                    });
                                  }}
                                />
                              </Grid>
                            )}

                          <Grid container item sm={3}>
                            <InputField
                              label="Reference"
                              name={"refrence"}
                              value={values.reference}
                              handleInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setValues({
                                  ...values,
                                  reference: event.target.value
                                });
                              }}
                            ></InputField>
                          </Grid>

                          {values.assetType === AssetType.VEHICLE && values.expenseType === ExpenseType.FUEL && (
                            <Grid container item xs={3}>
                              <FormControl
                                variant="outlined"
                                fullWidth
                              >
                                <Field
                                  component={TextField}
                                  name={"fuelCarNumber"}
                                  fullWidth
                                  type="text"
                                  label="Fuel Card Number"
                                  value={values.fuelCardNumber}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setValues({
                                      ...values,
                                      fuelCardNumber: event.target.value
                                    });
                                  }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                >
                                </Field>
                              </FormControl>
                            </Grid>
                          )}

                          {values.assetType === AssetType.VEHICLE && values.expenseType !== ExpenseType.INSURANCE && (
                            <Grid container item sm={3}>
                              <InputField
                                label="Driver Name"
                                name={"driver"}
                                value={values.driver}
                                handleInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  setValues({
                                    ...values,
                                    driver: event.target.value
                                  });
                                }}
                              ></InputField>
                            </Grid>
                          )}

                          {values.expenseType === ExpenseType.OTHER && (
                            <Grid item xs={12}>
                              <TextField
                                style={{ width: "100%" }}
                                label="Description"
                                name={"description"}
                                multiline
                                rows={3}
                                inputProps={{ maxLength: 500 }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  setValues({
                                    ...values,
                                    description: e.target.value
                                  })
                                }}
                                variant="outlined"
                                value={values.description}
                              />
                            </Grid>
                          )}


                          <Grid container item spacing={2}>
                            <Grid container item>
                              {values.document?.length ?
                                <div>
                                  {`${values.document[0].documentName}`}
                                  <IconButton
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                      downloadDocument(values.document[0].url, values.document[0].documentName)
                                    }}
                                  >
                                    <CloudDownloadIcon />
                                  </IconButton>
                                </div> :
                                <></>
                              }
                            </Grid>
                            <Grid container item style={{ marginTop: "-20px" }}>
                              <Fab
                                className="blackBackButton"
                                variant="extended"
                                size="medium"
                                aria-label="Update"
                                onClick={() => {
                                  setDocumentDialogVisible(true);
                                }}
                              >
                                UPLOAD DOCUMENT
                              </Fab>
                            </Grid>
                            <Grid item sm={12}>
                              <Fab
                                variant="extended"
                                size="medium"
                                type="submit"
                                disabled={
                                  !values.assetType
                                  || (values.assetType === AssetType.VEHICLE && !values.expenseType)
                                  || (values.assetType === AssetType.ADDON && !values.addon)
                                  || !values.expenseDate || !values.amount
                                }
                              >
                                {saveFuelLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                                Save
                              </Fab>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    }
                  </React.Fragment>
                )

              }}
            </Formik>
            {
              documentDialogVisible && (
                <DocumentDialog
                  open={documentDialogVisible}
                  handleClose={() => setDocumentDialogVisible(false)}
                  uploadInProgress={uploadInProgress}
                  onSubmit={handleDocumentSave}
                  accept="image/jpg, image/jpeg, image/png, application/pdf"
                />
              )
            }
          </Paper>
        </Grid>
      </Grid>
    </>)
}
export default NewFuelExpense;