import React, { useState } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { CURRENCY_SYMBOLS } from "../../views/Settings/GeneralSettings/OrganisationStructure/languageCurrencyConstant";

interface IProps {
  inputRef: (instance: NumericFormatProps | null) => void;
  onChange: (value: any) => void;
  name: string;
  value: number;
  maxIntegerDigitsLength: number;
  maxMantissaDigitsLength: number;
  hasCurrencyPrefix: boolean;
  allowNegative: boolean;
  currency?: string;
}

export const FloatInput = React.memo(
  (props: IProps) => {
    const {
      onChange,
      inputRef,
      value,
      name,
      hasCurrencyPrefix,
      allowNegative,
      ...other
    } = props;
    const userState = useSelector((state: IAppState) => state.userReducer);
    const currency = props.currency ? props.currency : userState.currentOrganisation.currency;
    const currencySymbol = CURRENCY_SYMBOLS[currency];
    const maxMantissaDigitsLength = props.maxMantissaDigitsLength ? props.maxMantissaDigitsLength : currency === "JPY" ? 0 : 2;
    const maxIntegerDigitsLength = props.maxIntegerDigitsLength ? props.maxIntegerDigitsLength : currency === "JPY" ? 14 : 5;
    const [japaneseValue, setJapaneseValue] = useState<number>(0);
    return (
      <NumericFormat
        {...other}
        name={name}
        getInputRef={inputRef}
        value={value / Math.pow(10, maxMantissaDigitsLength || 2)}
        onValueChange={(values: any) => {
          let finalValue;
          if (values.floatValue === 0) {
            finalValue = 0;
          }
          else if (currency === "JPY") {
            const valueString = Math.round(
              parseFloat(values.value) * Math.pow(10, maxMantissaDigitsLength || 2)
            ).toString();
            if (valueString.length <= maxIntegerDigitsLength + 2) {
              setJapaneseValue(parseFloat(valueString))
              finalValue = parseFloat(valueString)
            }
            else {
              finalValue = japaneseValue
            }
          }
          else {
            let oldValueString = value?.toString() || "0";
            const newValueString = Math.round(
              parseFloat(values.value) * Math.pow(10, maxMantissaDigitsLength || 2)
            ).toString();
            if (value === 0) {
              finalValue = parseInt(newValueString);
            } else {
              if (newValueString.length < oldValueString.length) {
                finalValue = parseInt(newValueString);
              } else {
                const lengthOverflow =
                  newValueString.length -
                  maxIntegerDigitsLength -
                  (maxMantissaDigitsLength);

                if (
                  lengthOverflow > 0 ||
                  newValueString.length === oldValueString.length
                ) {
                  if (newValueString.length !== oldValueString.length) {
                    const diff = newValueString.length - oldValueString.length;
                    oldValueString = (value * Math.pow(10, diff)).toString();
                  }

                  let breakPoint = 0;
                  for (let x = 0; x < newValueString.length; x++) {
                    if (oldValueString[x] !== newValueString[x]) {
                      breakPoint = x;
                      break;
                    }
                  }
                  const finalValueString =
                    newValueString.substr(0, breakPoint + 1) +
                    oldValueString.substr(breakPoint + 1);

                  finalValue = Math.floor(
                    parseInt(finalValueString) /
                    (lengthOverflow > 0 ? Math.pow(10, lengthOverflow) : 1)
                  );
                } else {
                  finalValue = parseInt(newValueString);
                }
              }
            }
          }

          onChange({
            target: {
              name,
              value: finalValue ? finalValue : 0
            }
          });
        }}
        isAllowed={() => {
          return true;
        }}
        thousandSeparator
        displayType={"input"}
        decimalSeparator={"."}
        fixedDecimalScale
        decimalScale={(currency == "JPY") ? 0 : 2}
        allowNegative={allowNegative}
        allowLeadingZeros
        prefix={hasCurrencyPrefix ? currencySymbol : ""}
        valueIsNumericString
      />
    );
  },
  (prevProps: IProps, nextProps: IProps) => prevProps === nextProps
);
