import {
  AppBar,
  Grid,
  Paper,
  Tab,
  Tabs,
  Theme
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { IBusinessCustomer } from "../../../../../reducers/customer/types";
import { TabPanel } from "../../../../common/TabPannel/TabPannel";
import { ApprovedDrivers } from "./Components/ApprovedDrivers";
import { AuthorisedSignatories } from "./Components/AuthorisedSignatories";
import { BookingHistory } from "./Components/BookingHistory";
import { CreditNoteHistory } from "./Components/CreditNoteHistory";
import { CustomerInfo } from "./Components/CustomerInfo";
import { Documents } from "./Components/Documents";
import { CustomerInvoice } from "./Components/CustomerInvoice";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { getLocalizedBookingSyntex } from "../../../../../utils/localized.syntex";

interface IProps {
  businessCustomer: IBusinessCustomer;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    }
  })
);
export const NewBusinessCustomerTabView = (props: IProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;

  return (
    <Grid container>
      <AppBar style={{position: "static"}} color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Customer Information" />
          <Tab label="Authorised Signatories" />
          <Tab label="Approved Drivers" />
          <Tab label={`${getLocalizedBookingSyntex(country)} History`} />
          <Tab label="Credit Notes History" />
          <Tab label="Documents" />
          <Tab label="Invoice History"/>
        </Tabs>
      </AppBar>
      <Paper className={classes.root}>
        <TabPanel value={value} index={0}>
          <CustomerInfo businessCustomer={props.businessCustomer} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AuthorisedSignatories />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ApprovedDrivers />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <BookingHistory customerId={props.businessCustomer.id} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <CreditNoteHistory customerId={props.businessCustomer.id} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Documents />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <CustomerInvoice customerId={props.businessCustomer.id}/>
        </TabPanel>
      </Paper>
    </Grid>
  );
};
