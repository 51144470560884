import { gql } from "@apollo/client";

export const VERIFY_BUSINESS_CUSTOMER = gql`
mutation verifyBusinessCustomer($status: String!, $businessCustomerId: String!, $reason: String) {
  verifyBusinessCustomer(status: $status, businessCustomerId: $businessCustomerId, reason: $reason) {
    success
    message
    status
    }
  }
`;