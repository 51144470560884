import "./index.scss";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  MenuItem,
  Theme,
  Typography
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { DateTime as d } from "luxon"
import React, { ChangeEvent, useState, useEffect } from "react";
import { FilePicker } from "../../../common/FilePicker/FilePicker";
import FlatPickerBar from "../../../common/FlatPicker";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { checkUploadFileFormat, DATE_TYPE, DocumentType } from "../../../common/utils";
import { IDocumentType } from '../../Settings/GeneralSettings/Documents/AddDocumentTypeDialog';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_DOCUMENT_TYPES } from '../../../../graphql/DocumentType/queries';
import { useNavigate } from 'react-router-dom';
import { IAppState } from "../../../../store";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { useSelector } from "react-redux";
import { captureErrorException } from "../../../../utils/sentry";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      minWidth: 690,
      height: 480
    }
  })
);

interface IProps {
  open: boolean;
  uploadInProgress?: boolean;
  handleClose(): void;
  onSubmit(
    file: File | undefined,
    title: string,
    documentType: string,
    reminderBefore: number,
    expiryDate: string | undefined,
    values?: any
  ): Promise<void>;
  accept: string;
  values?: any;
}

interface IUploadDocumentValues {
  fileName: string;
  title: string;
  expiryDate?: string;
  documentType: string;
  reminderBefore: number;
  hasExpiry: boolean;
}

const defaultValues: IUploadDocumentValues = {
  fileName: "",
  title: "",
  expiryDate: "",
  documentType: "",
  reminderBefore: 7,
  hasExpiry: false
};

export const DocumentDialog = (props: IProps) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { uploadInProgress } = props;
  const [fileValue, setFileValue] = useState<File | undefined>(undefined);
  const [inputValues, setInputValues] = useState<IUploadDocumentValues>(
    defaultValues
  );
  const [calOpen, setCalOpen] = useState<boolean>(false)

  const snackbar = useSnackBar();
  const [documentTypes, setDocumentTypes,] = useState<IDocumentType[]>([])

  const [getAllDocumentTypes, { data: documentTypesData }] = useLazyQuery(
    GET_ALL_DOCUMENT_TYPES,
    {
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    getAllDocumentTypes();
  }, []);

  useEffect(() => {
    if (documentTypesData && documentTypesData.getAllDocumentTypes) {
      setDocumentTypes(prev => [...prev, ...documentTypesData.getAllDocumentTypes])
    }
  }, [documentTypesData])

  function onSelectDocument(event: any) {
    const file = event.target.files[0];
    if (!checkUploadFileFormat(file.type)) {
      return snackbar({
        message: "Invalid File Format. supported formats : PDF, PNG, JPG and JPEG",
        variant: SnackBarVariant.ERROR
      });
    }
    setFileValue(file);
    setInputValues({ ...inputValues, fileName: file.name.split(".")[0] });
  }
  return (
    <Dialog
      fullWidth={true}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      className={"document-upload-customer"}
      classes={{ paper: calOpen ? classes.dialogPaper : "" }}
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h2" style={{ color: "#000" }}>
          Upload Document
        </Typography>
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden", padding: "16px 24px" }}>
        <Formik
          initialValues={{ ...inputValues }}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) =>
            props
              .onSubmit(
                fileValue,
                values.title,
                values.documentType,
                values.reminderBefore,
                values.expiryDate,
                props.values
              )
              .then(() => {
                setFileValue(undefined);
                setInputValues({
                  fileName: "",
                  title: "",
                  documentType: "",
                  reminderBefore: 7,
                  expiryDate: "",
                  hasExpiry: false
                });
                setSubmitting(false);
              })
              .catch((error) => {
                captureErrorException(error)
                setSubmitting(false);
                throw new Error(error);
              })
          }
        >
          {(formikProps) => (
            <Form>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      component={TextField}
                      name={"documentType"}
                      fullWidth
                      type="text"
                      select
                      required
                      label="Select Document Type"
                      inputProps={{
                        onChange: (
                          event: ChangeEvent<HTMLInputElement>
                        ) => {
                          const thisDocType = documentTypes.find(docType => docType.typeName === event.target.value)
                          thisDocType && setInputValues({
                            ...formikProps.values,
                            documentType: event.target.value,
                            reminderBefore: thisDocType.reminderBefore || 7,
                            hasExpiry: thisDocType.hasExpiry || false,
                            expiryDate: ""
                          });
                        },
                        value: formikProps.values.documentType
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    >
                      {documentTypes.length ?
                        documentTypes.map(
                          (
                            item,
                            index: number
                          ) => {
                            return (
                              <MenuItem key={index} value={item.typeName}>
                                {item.typeName}
                              </MenuItem>
                            );
                          }
                        ) : <MenuItem>
                          <div>
                            <div style={{ textAlign: "center", marginBottom: 10 }}>No document types founds</div>
                            <Fab
                              onClick={(e) => {
                                e.preventDefault();
                                props.handleClose();
                                navigate("/document-types")
                              }}
                              className="blackBackButton"
                              variant="extended"
                              size="small"
                              aria-label="addDocument"
                              type="button"
                            >
                              Create a document type
                            </Fab>
                          </div>
                        </MenuItem>}
                    </Field>
                  </FormControl>
                </Grid>
                {inputValues.hasExpiry && (
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          if (value) {
                            setInputValues({
                              ...formikProps.values,
                              expiryDate: d.fromJSDate(value).toUTC().toISO()
                            });
                          }
                          setCalOpen(false)
                        }}
                        label={"Expiry Date"}
                        identifier={"dateOfExpiry"}
                        placeholderValue={"Expiry Date"}
                        value={getLocalizedDateFormat(country, formikProps.values.expiryDate || "", DATE_TYPE.CONDENSED)}
                        minDate={getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}
                        country={country}
                        onOpenCalendar={() => setCalOpen(true)}
                        onCloseCalendar={() => setCalOpen(false)}
                      />
                    </FormControl>
                  </Grid>
                )}
                {inputValues.documentType === DocumentType.OTHER && (
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      label={"Title"}
                      name={"title"}
                      inputProps={{
                        onChange: (
                          event: ChangeEvent<HTMLInputElement>
                        ) => {
                          setInputValues({
                            ...formikProps.values,
                            title: event.target.value
                          });
                        },
                        value: formikProps.values.title
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      required
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    label={"File Name"}
                    name={"fileName"}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} justifyContent={"flex-end"}>
                  <FilePicker
                    onFilePick={(e: any) => {
                      onSelectDocument(e);
                    }}
                    accept={props.accept}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fab
                    className="blackBackButton"
                    variant="extended"
                    size="medium"
                    aria-label="addDocument"
                    type="submit"
                    disabled={
                      !inputValues.fileName || !inputValues.documentType || (inputValues.hasExpiry && !inputValues.expiryDate) || uploadInProgress
                    }
                  >
                    {uploadInProgress ? <CircularProgress /> : "ADD"}
                  </Fab>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog >
  );
};
