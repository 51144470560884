import { gql } from "@apollo/client";

export const REPLACE_IN_PROGRESS_SUBSCRIPTION_VEHICLE = gql`
  mutation replaceSubscriptionVehicleForInProgressBooking($replacementInput: SubscriptionReplacementInput!) {
    replaceSubscriptionVehicleForInProgressBooking(replacementInput: $replacementInput) {
      id
      endDate
      startDate
      vehicleStatus
      vehicle
      vehicleGroup
      booking
      tba
    }
  }
`;
