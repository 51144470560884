import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
interface IProps {
  description: string;
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: (sendMail: boolean) => void;
  isInProgress?: boolean;
  confirmText?: string;
  cancelText?: string;
  disabledBackDropClick?: boolean;
  onBackDropClick?: () => void;
  children?: React.ReactNode;
  isSendButtonClicked?: boolean;
  isLoading?: boolean;
}
const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: 'orange',
    color: 'white',
    marginBottom: '10px',
    marginRight: 10,
    '&.Mui-disabled': {
      backgroundColor: 'gray',
      color: 'white',
    },
    '&:hover': {
      backgroundColor: 'orange !important',
      color: 'white !important',
    },
  },
}));

export const CompleteConfirmationDialog = (props: IProps) => {
  const [open, setOpen] = React.useState(props.isOpen);
  const [sendMail, setSendMail] = useState<boolean>(false);
  const isOpen = props.isOpen;
  const classes = useStyles();
  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const handleCancel = () => {
    setOpen(false);
    setSendMail(false);
    props.onCancel();
  };

  const handleConfirm = () => {
    props.onConfirm(sendMail);
  };

  return (
    <Dialog
      open={open}
      onClose={(event: any, reason: string) => {
        if (reason === "backdropClick") {
          props.onBackDropClick
        } else {
          handleCancel
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown={props.disabledBackDropClick ? props.disabledBackDropClick : false}

    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>{props.description}</div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendMail}
                  onChange={(e) => setSendMail(!sendMail)}
                  color="secondary"
                  name={"sendMail"}
                />
              }
              label={"Send customer a booking complete email"}
            />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} disabled={props.isSendButtonClicked || props.isLoading} className={classes.button} style={{ marginBottom: "10px", marginRight: 10 }}>
          {props.isSendButtonClicked || props.isLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
          {props.confirmText ? props.confirmText : "Confirm"}
        </Button>
        <Button onClick={handleCancel} disabled={props.isLoading} color="primary" style={{ backgroundColor: "#828282", color: "white", marginBottom: "10px", marginRight: "10px" }}>
          {props.cancelText ? props.cancelText : "Cancel"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
