import { Grid, Typography } from '@mui/material'
import React from 'react'

export const EventExistError: React.FC = () => {
  return (
    <Grid container item xs={12} style={{ marginBottom: 20 }}>
      <Typography variant="h4">
        Event already exists for the selected time period.
      </Typography>
    </Grid>
  )
}
