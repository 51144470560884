import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React from "react";

interface IProps {
  row: any;
}

export const MotRowDetail = (props: IProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Failure Items</Typography>
        <Divider />
        <List dense>
          {props.row.failureItems?.map((item: string, index: number) => {
            return (
              <>
                <ListItem key={index} button>
                  <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Minor Items</Typography>
        <Divider />
        <List dense>
          {props.row.minorItems?.map((item: string, index: number) => {
            return (
              <>
                <ListItem key={index} button>
                  <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Advisory Items</Typography>
        <Divider />
        <List dense>
          {props.row.advisoryItems?.map((item: string, index: number) => {
            return (
              <>
                <ListItem key={index} button>
                  <ListItemIcon>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};
