import { gql } from "@apollo/client";

export const GET_VEHICLES = gql`
  {
    vehicles {
      insurerCode
      airConditioner
      assetTag
      brakeHorsePower
      bluetooth
      bodyType
      vehicleUniqueCode
      vehicleUniqueId
      co2Emissions
      colour
      cylinderCapacity
      description
      fuelType
      imported
      infoSource
      insuranceGroup
      licencePlate
      vin
      make
      model
      manufactureDate
      year
      registrationDate
      vehicleTest
      vehicleTestDetails
      vehicleTestDueDate
      numberOfDoors
      numberOfPreviousOwners
      numberOfSeats
      premium
      price
      msrp
      grossVehicleWeight
      satNav
      sixMonthRate
      status
      steering
      taxDetails
      taxed
      taxStatus
      telemetryDeviceId
      transmission
      twelveMonthRate
      typeApproval
      tyreFitments
      wheelPlan
      acrissCode
      branch
      isGhostVehicle
      branchDetails{
        id
        name
      }
      imageUrl
      deleted
      id
      interiorColour
      pcoNumber
      pcoExpiry
      batteries {
        voltage
        capacity
        coldTestCurrent
        length
        width
        height
        postPositions
        terminalType
        holdDownType
      }
      frontTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      rearTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      vehicleTestReports {
        testDate
        expiryDate
        testResult
        odometerReading
        odometerUnit
        motTestNumber
        advisoryItems
        minorItems
        failureItems
      }
      vehicleDocuments {
        id
        title
        documentName
        documentType
        reminderBefore
        expiryDate
        url
      }
      bookingSchedules {
        booking {
          id
        }
        endDate
        id
        startDate
        status
      }
      features
    }
  }
`;

export const GET_VEHICLES_INVENTORY = gql`
  query vehiclesInventory($limit: Int!, $offset: Int!,$ids: [String], $filters: [FilterInput]) {
    vehiclesInventory(limit: $limit, offset: $offset, ids: $ids filters: $filters) {
      insurerCode
      airConditioner
      assetTag
      brakeHorsePower
      bluetooth
      bodyType
      vehicleUniqueCode
      vehicleUniqueId
      co2Emissions
      colour
      cylinderCapacity
      description
      fuelType
      imported
      infoSource
      insuranceGroup
      licencePlate
      vin
      make
      model
      manufactureDate
      year
      registrationDate
      vehicleTest
      vehicleTestDetails
      vehicleTestDueDate
      numberOfDoors
      numberOfPreviousOwners
      numberOfSeats
      premium
      price
      msrp
      grossVehicleWeight
      satNav
      sixMonthRate
      status
      steering
      taxDetails
      taxed
      taxStatus
      telemetryDeviceId
      transmission
      twelveMonthRate
      typeApproval
      tyreFitments
      wheelPlan
      acrissCode
      branch
      isGhostVehicle
      deFleetStatus
      branchDetails{
        id
        name
      }
      imageUrl
      deleted
      id
      interiorColour
      pcoNumber
      pcoExpiry
      batteries {
        voltage
        capacity
        coldTestCurrent
        length
        width
        height
        postPositions
        terminalType
        holdDownType
      }
      frontTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      rearTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      vehicleTestReports {
        testDate
        expiryDate
        testResult
        odometerReading
        odometerUnit
        motTestNumber
        advisoryItems
        minorItems
        failureItems
      }
      vehicleDocuments {
        id
        title
        documentName
        documentType
        reminderBefore
        expiryDate
        url
      }
      bookingSchedules {
        booking {
          id
        }
        endDate
        id
        startDate
        status
      }
      features
    }
  }
`;

export const GET_DEFLEETED_VEHICLES = gql`
query getDefleetedVehicles($limit: Int!, $offset: Int!, $filters: [FilterInput]) {
  getDefleetedVehicles(limit: $limit, offset: $offset, filters: $filters) {
    insurerCode
    airConditioner
    assetTag
    brakeHorsePower
    bluetooth
    bodyType
    vehicleUniqueCode
    vehicleUniqueId
    co2Emissions
    colour
    cylinderCapacity
    description
    fuelType
    imported
    infoSource
    insuranceGroup
    licencePlate
    vin
    make
    model
    manufactureDate
    year
    registrationDate
    vehicleTest
    vehicleTestDetails
    vehicleTestDueDate
    numberOfDoors
    numberOfPreviousOwners
    numberOfSeats
    premium
    price
    msrp
    grossVehicleWeight
    satNav
    sixMonthRate
    status
    steering
    taxDetails
    taxed
    taxStatus
    telemetryDeviceId
    transmission
    twelveMonthRate
    typeApproval
    tyreFitments
    wheelPlan
    acrissCode
    branch
    deFleetStatus
    isGhostVehicle
    branchDetails{
      id
      name
    }
    imageUrl
    deleted
    id
    interiorColour
    pcoNumber
    pcoExpiry
    batteries {
      voltage
      capacity
      coldTestCurrent
      length
      width
      height
      postPositions
      terminalType
      holdDownType
    }
    frontTyres {
      width
      ratio
      rim
      speedRating
      psi
      loadIndex
    }
    rearTyres {
      width
      ratio
      rim
      speedRating
      psi
      loadIndex
    }
    vehicleTestReports {
      testDate
      expiryDate
      testResult
      odometerReading
      odometerUnit
      motTestNumber
      advisoryItems
      minorItems
      failureItems
    }
    vehicleDocuments {
      id
      title
      documentName
      documentType
      reminderBefore
      expiryDate
      url
    }
    bookingSchedules {
      booking {
        id
      }
      endDate
      id
      startDate
      status
    }
    features
  }
}
`;

export const GET_DEFLEETED_VEHICLES_COUNT = gql`
  {
    deFleetedVehiclesCount
  }
`;

export const GET_VEHICLES_COUNT = gql`
  {
    vehiclesCount
  }
`;

export const SEARCH_VEHICLES = gql`
  query searchVehicles($q: String!) {
    searchVehicles(q: $q) {
      insurerCode
      airConditioner
      assetTag
      brakeHorsePower
      bluetooth
      bodyType
      vehicleUniqueCode
      vehicleUniqueId
      co2Emissions
      colour
      cylinderCapacity
      description
      fuelType
      imported
      infoSource
      insuranceGroup
      licencePlate
      vin
      make
      model
      manufactureDate
      year
      registrationDate
      vehicleTest
      vehicleTestDetails
      vehicleTestDueDate
      numberOfDoors
      numberOfPreviousOwners
      numberOfSeats
      premium
      price
      msrp
      grossVehicleWeight
      satNav
      sixMonthRate
      status
      steering
      taxDetails
      taxed
      taxStatus
      telemetryDeviceId
      transmission
      twelveMonthRate
      typeApproval
      tyreFitments
      wheelPlan
      acrissCode
      branch
      isGhostVehicle
      branchDetails{
        id
        name
      }
      imageUrl
      deleted
      id
      interiorColour
      pcoNumber
      pcoExpiry
      batteries {
        voltage
        capacity
        coldTestCurrent
        length
        width
        height
        postPositions
        terminalType
        holdDownType
      }
      frontTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      rearTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      vehicleTestReports {
        testDate
        expiryDate
        testResult
        odometerReading
        odometerUnit
        motTestNumber
        advisoryItems
        minorItems
        failureItems
      }
      vehicleDocuments {
        id
        title
        documentName
        documentType
        reminderBefore
        expiryDate
        url
      }
      bookingSchedules {
        booking {
          id
        }
        endDate
        id
        startDate
        status
      }
      features
    }
  }
`;
export const SEARCH_ALL_VEHICLES = gql`
  query searchOwnedAndExternalVehicles($q: String!) {
    searchOwnedAndExternalVehicles(q: $q) {
      id
      licencePlate
      branchDetails{
        id
        name
      }
    }
  }
`;
