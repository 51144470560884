import { gql } from "@apollo/client";

export const CREATE_BRANCH = gql`
  mutation createBranch($organisationId: ID!, $branch: BranchInput!) {
    createBranch(organisationId: $organisationId, branch: $branch) {
      id
      name
      emailId
      overbookingAllowed
      depositCollection
      phoneNumber {
        phone
        country
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      timeZone
      owner {
        id
        email
      }
      operatingHours {
        day
        dayIndex
        workingFromTime
        workingToTime
        pickupFromTime
        pickupToTime
        returnFromTime
        returnToTime
        closed
      }
      minBookingDuration
      minimumBookingAmount {
        type
        value
      }
      minBookingAmountOptIn
      minBookingDurationOptIn
      preBookingQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
      isAdditionalDriverQuestionsEnabled
      additionalDriverQuestions{
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
      }
      deliveryQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
      vehiclePreparationTime
      carShareGracePeriodMins
      durationBasedPricingEnabled
      holidays{
        id
        name
        startDate
        endDate
        isActive
      }
      dashboardReminder
      autoPayEnabled
      autoPayDays
    }
  }
`;
