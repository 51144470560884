import { Fab, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IProcurementRequest, IQuote, ProcurementItemStatuses } from '../../../../reducers/Procurement/types';
import { CollapsibleRow } from './CollapsibleRow';

interface IProps {
  procurement: IProcurementRequest;
  onNextStep(data: any): void
  onPreviousStep(): void;
  setProcurementRequest(data: IProcurementRequest): void;
}

export const Quote: React.FC<IProps> = (props) => {

  const [qouteItems, setQuoteItems] = useState<IQuote[]>(props.procurement.quotes)
  const [isNext, setIsNext] = useState<boolean>(true);

  useEffect(() => {
    if (props.procurement && props.procurement.quotes) {
      setQuoteItems(props.procurement.quotes)
      let checkQuote = props.procurement.quotes.every((item) => item.status !== ProcurementItemStatuses.RECEIVED && item.status !== ProcurementItemStatuses.PENDING)
      if (checkQuote) {
        setIsNext(false)
      }
    }
  }, [props.procurement])

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={"tableCellHead"} align="left"></TableCell>
                <TableCell className={"tableCellHead"} align="left">Proposal ID</TableCell>
                <TableCell className={"tableCellHead"} align="left">Quote ID</TableCell>
                <TableCell className={"tableCellHead"} align="left">Quote Date</TableCell>
                <TableCell className={"tableCellHead"} align="left">Partner</TableCell>
                <TableCell className={"tableCellHead"} align="left">Valid Until</TableCell>
                <TableCell className={"tableCellHead"} align="left">Quote Amount</TableCell>
                <TableCell className={"tableCellHead"} align="left">Notes</TableCell>
                <TableCell className={"tableCellHead"} align="left">Documents</TableCell>
                <TableCell className={"tableCellHead"} align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                qouteItems &&
                qouteItems.map((row: IQuote, index: number) => (
                  <>
                    <CollapsibleRow
                      key={index}
                      row={row}
                      procurement={props.procurement}
                      setProcurementRequest={props.setProcurementRequest}
                    />
                  </>
                )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item container xs={12} justifyContent="space-between">
        <Fab
          className="blackBackButton"
          variant="extended"
          size="medium"
          aria-label="add"
          onClick={() => {
            props.onPreviousStep()
          }}
        >
          Previous
        </Fab>
        <Fab
          variant="extended"
          size="medium"
          aria-label="add"
          onClick={() => {
            props.onNextStep(qouteItems)
          }}
          disabled={isNext}
        >
          Next
        </Fab>
      </Grid>
    </Grid>
  )
}
