import { gql } from "@apollo/client";

export const GET_INVOICE_FINANCE_HISTORY = gql`
  query downloadedHistryRecords($type: String!) {
    downloadedHistryRecords(type: $type) {
      id
      userName
      email
      dowloadDate
      fields
      fileName
      branchId
      organisationId
      tenantId
      rangeFrom
      rangeTo
      templateName
    }
  }
`;

export const GET_ALL_TIME_BOOKINGS = gql`
  query getAllTimeBookings {
    getAllTimeBookings {
      bookingStart
      bookingEnd
      hourOfDayStart
      hourOfDayEnd
    }
  }
`;