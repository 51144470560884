import { gql } from "@apollo/client";

// The data returned should be same as GET_BOOKING query
export const CREATE_BOOKING = gql`
  mutation createBooking($booking: CreateBookingInput!) {
    createBooking(booking: $booking) {
      id
      bookingCategory
      dateStarted
      dateReturned
      organisationId
      tba
      autoRenewal
      isSubscription
      activeSubscription {
        startDate
        endDate
        subscription {
          id
          name
          rateTypeName
          insurancePolicy {
            name
          }
          addons {
            displayName
          }
        }
        vehicle {
          licencePlate
          make
          model
          vin
          id
        }
        duration
        subscriptionAmount
        deposit
        enrollmentAmount
        mileageLimit
        unlimitedMileage
        pricePerExtraMile
        swapsAllowed
      }
      costCenter
      projectId
      isCoi
      coiDetails {
        policyName
        policyNumber
        provider
        expiryDate
        documentUrl
      }
      taxExempted
      zeroCostBooking
      zeroCostBookingNote
      invoiceNote
      firstInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
        payments {
          id
          dateCreated
          lastUpdated
          createdBy
          updatedBy
          note
          paymentMode
          paymentType
          emailNotify
          expireBy
          amount
          currency
          description
          smsNotify
          status
        }
      }
      currentInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
        payments {
          id
          dateCreated
          lastUpdated
          createdBy
          updatedBy
          note
          paymentMode
          paymentType
          emailNotify
          expireBy
          amount
          currency
          description
          smsNotify
          status
        }
      }
      finalInvoice {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        payments {
          id
          dateCreated
          lastUpdated
          createdBy
          updatedBy
          note
          paymentMode
          paymentType
          emailNotify
          expireBy
          amount
          currency
          description
          smsNotify
          status
        }
      }
      referenceNumber
      dateCreated
      dateUpdated
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
      pickupServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      pickupOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      pickupDateTime
      dropoffServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      dropoffOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
        location {
          type
          latitude
          longitude
        }
      }
      dropoffDateTime
      longTermBooking
      flightNumber
      insuranceRate
      excess
      customerType
      billingFrequency
      discount {
        appliedBy
        appliedDate
        isPercent
        code
        amount
      }
      tax {
        appliedBy
        appliedDate
        code
        amount
        payableAmount
        rate
        type
        subdivisionOptIn
        subdivisionRate
        subdivisionType
        subdivisionValue
      }
      invoices {
        dateCreated
        dateUpdated
        createdBy
        updatedBy
        discount {
          appliedBy
          appliedDate
          isPercent
          code
          amount
        }
        invoiceRef
        status
        locked
        invoiceType
        startDate
        endDate
        tax {
          appliedBy
          appliedDate
          code
          amount
        }
        totalPayableAmount
        anyPaidAmount
        confirmedPaidAmount
        anyRefundAmount
        confirmedRefundAmount
        otherRefunds
        depositRefund
        balanceDueFromPreviousInvoice
        damageAmount
        finesAmount
        valetAmount
        otherCharges {
          amount
          name
        }
        id
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        rateTypeName
        rateTypeDuration
      }
      rateTypeName
      rateTypeDuration
      billingCycleName
      billingCycleDuration
      status
      addonRequirements {
        addon {
          id
          name
          category
          imageUrl
          hasFixedRate
          hasQuantity
          displayName
          fixedRate
          description
          recurringRate {
            rateTypeName
            rateTypeDuration
            rate
            longTerm
          }
          totalQuantity
          availableQuantity
          isActivated
        }
        rate
        quantity
        name
        hasFixedRate
        displayName
        payableAmount
        tax {
          title
          value
        }
      }
      customer {
        id
        firstName
        lastName
        email
        phoneNumber {
          phone
          country
        }
        dateOfBirth
        location {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
        license {
          dateOfExpiry
          licenseNumber
          authority
        }
      }
      currentBookingSchedules {
        id
        startDate
        endDate
        status
        vehicle {
          id
          licencePlate
        }
      }
      businessCustomer {
        id
        businessName
        registrationNumber
        VAT
        registeredAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        tradingAddressSame
        tradingAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        approvedDrivers {
          id
          status
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        authorizedSignatories {
          id
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        billing {
          phoneNumber {
            phone
            country
          }
          email
        }
        contact {
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        documents {
          id
          name
          title
          documentType
          expiryDate
          url
        }
      }
      authorizedSignatory
      insurancePolicy {
        id
        name
        description
      }
      paymentDetails {
        startDate
        endDate
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        totalPayableAmount
        rentalTime
      }
      vehicleGroups {
        activeVehicleSchedules {
          id
          vehicleStatus
          vehicle {
            isGhostVehicle
            id
            licencePlate
            make
            model
            features
            colour
            pcoNumber
            vin
          }
        }
        vehicleGroup {
          id
          name
        }
        vehicleIds
        baseRate
        priceRuleOperator
        priceRuleValueType
        priceRuleTriggerType
        priceRuleAmount
        priceRuleName
        deposit
        mileageLimit
        pricePerExtraMile
        count
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      notes {
        id
        description
        createdBy {
          id
          firstName
          lastName
        }
        createdDate
      }
      preBookingQuestions {
        question
        answer
        answerType
      }
      deliveryQuestions {
        question
        answer
        answerType
      }
      locationSurcharges {
        id
        name
        value
        rate
        type
        tax {
          title
          value
        }
      }
      oneWayRentalFee {
        amount
        taxable
        tax {
          title
          type
          value
        }
      }
      source
    }
  }
`;
