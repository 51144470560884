import { gql } from "@apollo/client";

export const GET_VEHICLES_UTILISATION_REPORT = gql`
  query getVehiclesUtilizationReport(
    $vehicleGroupId: String!
    $startDate: String!
    $endDate: String!
    $quarter: String
  ) {
    getVehiclesUtilizationReport(
      vehicleGroupId: $vehicleGroupId
      startDate: $startDate
      endDate: $endDate
      quarter: $quarter
    ) {
      licencePlate
      onHire
      offHire
      onHold
      inService
    }
  }
`;