import { gql } from "@apollo/client";

export const GET_ALL_PENDING_DEPOSITS = gql`
query pendingDeposits($limit: Int!, $offset: Int!) {
  pendingDeposits(limit: $limit, offset: $offset) {
      id
      referenceNumber
      pickupDateTime
      dropoffDateTime
      customer {
        firstName
        lastName
      }
      businessCustomer{
        businessName
      }
      depositDetails {
        amount
        amountAdjusted
        payment {
          amount
          remainingAmount
          refundAmount
        }
        refund {
          amount
          status
          currency
        }
      }  
    }
  }
`;


