import { Value } from "react-phone-number-input";

import { ICostCenter } from "../organisation/types";
import { IAddress } from "../user/types";
import { IAccountManager } from "../bookings/types";

export interface ICustomerState {
  customer: ICustomer;
  businessCustomer: IBusinessCustomer;
  authorisedSignatory: IBusinessCustomerCommonInput;
  approvedDriver: IBusinessCustomerApprovedDriverInput;
}

export type CustomerPayloadType = ICustomer | ICustomerId;
export type BusinessCustomerPayloadType =
  | IBusinessCustomer
  | IBusinessCustomerId;
export type BusinessCustomerCommonPayloadType = IBusinessCustomerApprovedDriverInput;

export enum BusinessCustomerStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED"
}
export interface ICustomer {
  id: string;
  status: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  nationalInsuranceNumber?: string;
  profession?: string;
  profilePicture?: string;
  email: string;
  address: string;
  dateOfBirth: string;
  location: ILocation;
  overrideInvoiceDate?: boolean;
  overrideWeeklyInvoiceDate?: boolean;
  invoicingDay?: number;
  weeklyInvoicingDay?: number;
  verified: boolean;
  verificationMethod: string;
  deleted: boolean;
  secondaryPhoneNumber: IPhoneNumber;
  secondaryEmail: string;
  documents: ICustomerDocument[];
  gender: string;
  license: ILicenseDetails;
  customerPCONumber?: string;
  approvedDrivers?: string[];
  notes?: ICustomerNote[];
  accountNumber?: string;
  taxExempted?: boolean;
  verifiedDate?: string;
  coiDetails?: ICoiDetails;
  credasIdentityVerification?: ICredasVerification;
  creditSafeData?: ICreditSafeData;
  costCenters?: ICostCenter[];
  credentialSent?: boolean;
  credentialSentOn?: string;
  autoPayDisabled?: boolean;
}

interface ICredasVerification {
  entityId?: string;
  processId?: string;
  lastUpdated?: string;
  verificationMethod?: string;
  verificationStatus?: string;
}

interface ICreditSafeData {
  creditScore: number;
  lastChecked: string;
}

export interface IPhoneNumber {
  phone: Value;
  country: string;
}
export interface ICustomerNote {
  id?: string;
  description: string;
  createdBy: string;
  createdDate: string;
}

export interface ICustomerNoteInput {
  description: string;
  createdBy: string;
  createdDate: string;
}

export interface ICoiDetails {
  policyName: string;
  policyNumber: string;
  provider: string;
  expiryDate: string;
  documentUrl: string;
}
export interface ILicenseDetails {
  authority: string;
  licenseNumber: string;
  dateOfExpiry: string;
  dateOfIssue: string;
  endorsement: string;
  images: string[];
  offences?: number;
  points?: number;
  dvlaVerificationStatus?: string;
}

export interface IIdentityDocument {
  document_type: string;
  document_information: IDocumentInformation[];
}

export interface IDocumentInformation {
  key: string;
  value: string;
}

export interface ICustomerDocument {
  id: string;
  documentKey: string;
  title: string;
  documentName: string;
  documentType: string;
  reminderBefore?: number;
  expiryDate: string;
  createdAt?: string;
}

export interface ICustomerId {
  id: string;
}

export interface ILocation {
  street: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  fullAddress: string;
}

export enum Gender {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other",
  NOTTODISCLOSE = "Prefer not to disclose"
}

export interface IBusinessCustomer {
  id: string;
  businessName: string;
  accountManager: IAccountManager | string;
  registrationNumber: string;
  VAT: string;
  registeredAddress: ILocation;
  tradingAddressSame: boolean;
  tradingAddress: ILocation;
  approvedDrivers: IBusinessCustomerApprovedDriverInput[];
  authorizedSignatories: IBusinessCustomerCommonInput[];
  billing: IBusinessCustomerBillingInput;
  contact: IBusinessCustomerCommonInput;
  contactName?: string;
  contactEmail?: string;
  contactNumber?: string;
  documents: IBusinessCustomerDocumentInput[];
  notes?: IBusinessCustomerNote[];
  overrideNetPaymentTerms?: boolean;
  netPaymentTerms?: number;
  overrideInvoiceDate?: boolean;
  dailyBillingCycle?: number;
  weeklyBillingCycle?: number;
  monthlyBillingCycle?: number;
  invoicingDay?: number;
  credentialSent?: boolean;
  credentialSentOn?: string;
  isClientExists?: boolean;
  requiresBookingApproval?: boolean;
  coiDetails?: ICoiDetails;
  isRingFenced?: boolean;
  vehicleGroups?: IRingFencedVehicleGroup[];
  taxExempted?: boolean;
  status: string;
  declinedReason: string;
  businessCreditData?: IBusinessCreditData;
  tags?: string[];
  creditLimitEnabled?: boolean;
  creditLimit?: number;
  overrideWeeklyInvoiceDate?: boolean;
  weeklyInvoicingDay?: number;
  overrideServiceScheduleByMonth?: number;
  autoPayDisabled?: boolean;
}

interface IBusinessCreditData {
  creditScore?: number;
  creditDescription?: string;
  lastChecked?: string;
  companyId?: string;
}

export interface IRingFencedVehicleGroup {
  id: string;
  name: string;
}

export interface IBusinessCustomerNote {
  id?: string;
  description: string;
  createdBy: string;
  createdDate: string;
}

export interface IBusinessCustomerNoteInput {
  description: string;
  createdBy: string;
  createdDate: string;
}
export interface IBusinessCustomerCommonInput {
  id?: string;
  status?: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  email: string;
}

export interface IBusinessCustomerApprovedDriverInput {
  id?: string;
  status?: string;
  nationalInsuranceNumber?: string;
  profession?: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  email: string;
  license: ILicenseDetails;
  location: IAddress;
}

export interface IBusinessCustomerCommonInputWithId {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  email: string;
  license: ILicenseDetails;
  location: IAddress;
}

export interface IBusinessCustomerBillingInput {
  phoneNumber: IPhoneNumber;
  email: string;
  accountNumber?: string;
}

export interface IBusinessCustomerDocumentInput {
  id?: any;
  name: string;
  title: string;
  url: string;
  expiryDate: string | undefined;
  documentType: string;
  reminderBefore?: number;
  createdAt?: string;
}

export interface IBusinessCustomerId {
  id: string;
}

export enum CustomerVerificationStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  IN_REVIEW = "IN_REVIEW"
}

export const CustomerVerificationStatusValue: {
  [key in CustomerVerificationStatus]: string;
} = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  IN_REVIEW: "IN REVIEW"
};
