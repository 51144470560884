import { EmissionType } from "../../components/views/Fleet/utils";
import { VEHICLE_DAMAGE_INITIALSTATE } from "../damageVehicle/const";
import { IFleetState, VehicleTypes, JobSheetStatus, VehicleServiceStatus } from "./types";

export const initialState: IFleetState = {
  vehicle: {
    insurerCode: "",
    airConditioner: false,
    assetTag: "",
    batteries: [],
    brakeHorsePower: "",
    bluetooth: false,
    bodyType: "",
    vehicleUniqueCode: "",
    vehicleUniqueId: "",
    co2Emissions: "",
    colour: "",
    cylinderCapacity: "",
    v5c: "",
    emmissionType: "",
    ulezComplaint: "",
    ukVehicle: "",
    description: "",
    frontTyres: [],
    fuelType: "",
    imported: false,
    infoSource: "manual",
    insuranceGroup: "",
    licencePlate: "",
    vin: "",
    make: "",
    model: "",
    manufactureDate: "",
    year: 0,
    registrationDate: "",
    vehicleTest: false,
    vehicleTestDetails: "",
    vehicleTestDueDate: "",
    vehicleTestReports: [],
    vehicleTaxAmount: 0,
    numberOfDoors: 0,
    numberOfPreviousOwners: 0,
    numberOfSeats: 0,
    premium: false,
    isCarShare: false,
    vehicleType: VehicleTypes.DEFAULT_VEHICLE,
    price: "",
    msrp: "",
    rearTyres: [],
    grossVehicleWeight: "",
    satNav: false,
    sixMonthRate: "",
    status: "",
    steering: "",
    taxDetails: "",
    taxed: false,
    taxStatus: "",
    telemetryDeviceId: "",
    immobiliser: false,
    teltonikaDeviceId: "",
    telemetryDevicesData: [],
    refrigeratorHours: 0,
    isRefrigerator: false,
    transmission: "",
    twelveMonthRate: "",
    typeApproval: "",
    tyreFitments: [],
    vehicleDocuments: [],
    vehicleOwners: [],
    wheelPlan: "",
    acrissCode: "",
    branch: "",
    imageUrl: "",
    deleted: false,
    id: "",
    interiorColour: "",
    pcoNumber: "",
    pcoExpiry: "",
    nextPMIDate: "",
    nextBRTDate: "",
    frogDiagram: "",
    responseStatus: -1,
    features: [],
    bookingSchedules: [],
    notes: [],
    vehicleState: {
      id: "",
      type: "",
      exterior: {
        front: {
          description: "",
          images: []
        },
        rear: {
          description: "",
          images: []
        },
        top: {
          description: "",
          images: []
        },
        right: {
          description: "",
          images: []
        },
        left: {
          description: "",
          images: []
        }
      },
      interior: {
        frontSeat: {
          clean: true,
          controls: true,
          seatBelt: true
        },
        rearSeat: {
          clean: true,
          controls: true,
          seatBelt: true
        },
        lockAndSwitch: {
          frontDoor: true,
          rearDoor: true,
          frontWindow: true,
          rearWindow: true,
          childLock: true
        },
        images: []
      },
      defaultAccesories: [],
      generalChecks: [],
      tyre: {
        frontLeft: {
          condition: true,
          pressure: true,
          description: ""
        },
        frontRight: {
          condition: true,
          pressure: true,
          description: ""
        },
        rearLeft: {
          condition: true,
          pressure: true,
          description: ""
        },
        rearRight: {
          condition: true,
          pressure: true,
          description: ""
        }
      },
      odometerReading: 0,
      fuelStatus: ""
    },
    subscriptions: [],
    deFleetParameters: {
      byDistanceDriven: 0,
      byDate: "",
      alertBeforeDistance: 0,
      alertBeforeDays: 0
    },
    regularServiceSchedule: {
      byDistance: 0,
      byMonth: 0,
      alertBefore: 0
    },
  },
  vehicleService: {
    id: "",
    vehicleId: "",
    serviceType: "",
    branchId: "",
    scheduleType: {
      byMonth: false,
      byMileage: false
    },
    serviceProviderId: "",
    serviceProviderName: "",
    serviceProviderAddress: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    appointmentDate: "",
    startDate: "",
    endDate: "",
    completionDate: "",
    serviceAnniversaryByMileage: 0,
    description: "",
    totalExpenses: 0,
    status: VehicleServiceStatus.IN_SERVICE,
    notes: [],
    damages: [],
    documents: [],
    licencePlate: "",
    jobSheet: [],
    jobSheetStatus: JobSheetStatus.PENDING,
    jobSheetEmailLastSentOn: "",
    insuranceInformation: {
      providerName: "",
      policyNumber: "",
      claimAmount: 0,
      expiryDate: "",
      documentUrl: ""
    },
    odometerReading: 0,
    serviceAnniversaryByMonth: 0,
    purchaseOrderInformation: {
      poNumber: "",
      documentUrl: ""
    },
    taxPercent: 0,
    driverName: "",
    driverEmail: ""
  },
  vehicleDamage: VEHICLE_DAMAGE_INITIALSTATE.vehicleDamage,
  vehicleFinance: {
    id: "",
    vehicleId: "",
    vehicleHoldingCost: 0,
    sourceType: "",
    purchasePrice: 0,
    leasePrice: 0,
    paymentInterval: "",
    leasedFrom: "",
    purchasedFrom: "",
    purchasedOn: "",
    leasedOn: "",
    leasedUntil: "",
    financedFrom: "",
    financedFromDate: "",
    financedToDate: "",
    financedDeposit: 0,
    financedPrice: 0,
    rentedFrom: "",
    rentedFromDate: "",
    rentedToDate: "",
    rentedPrice: 0,
    residualValue: 0,
    depreciationPercentage: 0,
    financedPaymentInterval: 0,
    rentedPaymentInterval: 0,
    roadFundTax:0
  },
};
