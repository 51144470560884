import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
interface IProps {
  description: string;
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  isInProgress?: boolean;
  confirmText?: string;
  cancelText?: string;
  disabledBackDropClick?: boolean;
  onBackDropClick?: () => void;
  children?: React.ReactNode;
  isSendButtonClicked?: boolean;
  isLoading?: boolean;
  showInfo?: boolean;
  infoMessage?: string;
}
const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: "orange",
    color: "white",
    marginBottom: "10px",
    marginRight: 10,
    "&.Mui-disabled": {
      backgroundColor: "gray",
      color: "white"
    },
    "&:hover": {
      backgroundColor: "orange !important",
      color: "white !important"
    }
  }
}));

export const ConfirmationDialog = (props: IProps) => {
  const [open, setOpen] = React.useState(props.isOpen);
  const isOpen = props.isOpen;
  const classes = useStyles();
  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const handleCancel = () => {
    setOpen(false);
    props.onCancel();
  };

  const handleConfirm = () => {
    props.onConfirm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="action confirmaion dialog"
      aria-describedby="dialog to confirm action"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3">{props.title}</Typography>
      </DialogTitle>
      <DialogContent style={{ paddingBottom: "10" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DialogContentText>
              <Typography variant="body1">{props.description}</Typography>
            </DialogContentText>
          </Grid>
          {props.showInfo && (
            <Grid item xs={12}>
              <Alert severity="info">
                <Typography variant="h5">{props.infoMessage}</Typography>
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          disabled={props.isSendButtonClicked || props.isLoading}
          className={classes.button}
          style={{ marginBottom: "10px", marginRight: 10 }}
        >
          {props.isSendButtonClicked ||
            (props.isLoading && (
              <CircularProgress
                size={14}
                style={{ color: "white", marginRight: "10px" }}
              />
            ))}
          {props.confirmText ? props.confirmText : "Confirm"}
        </Button>
        <Button
          onClick={handleCancel}
          disabled={props.isLoading}
          color="primary"
          style={{
            backgroundColor: "#828282",
            color: "white",
            marginBottom: "10px",
            marginRight: "10px"
          }}
        >
          {props.cancelText ? props.cancelText : "Cancel"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
