import "./index.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  Link,
  Hidden,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Switch,
  DialogContent,
  DialogContentText,
  RadioGroup,
  Radio
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { ApolloError } from "@apollo/client";
import dayjs from "dayjs";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ADD_BOOKING_NOTE, ADD_ADDITIONAL_INFORMATION } from "../../../../graphql/bookings/addBookingNoteMutation";
import { CANCEL_BOOKING } from "../../../../graphql/bookings/cancelBookingMutation";
import { COMPLETE_BOOKING } from "../../../../graphql/bookings/completeBookingMutation";
import { GET_BOOKING } from "../../../../graphql/bookings/getBookingQuery";
import {
  BookingExtensionStatus,
  BookingStatus,
  IAddonRequirement,
  IBooking,
  IBookingAdditionalInformation,
  IBookingExtension,
  IBookingNote,
  IBookingNoteInput,
  IBookingSchedule,
  IBookingVehicleGroup,
  ICreditNote,
  ICreditNoteInput,
  IRefund,
  BookingStatusText,
  BookingEventTypes,
  ISwapRequest,
  INotesInput,
  IGenericSwapInput,
  SwapRequestStatus,
  BOOKING_CATEGORY
} from "../../../../reducers/bookings/types";
import {
  IBusinessCustomer,
  ICustomer
} from "../../../../reducers/customer/types";
import { IInvoice, PaymentMode, PaymentStatus } from "../../../../reducers/invoices/types";
import { IVehiclePriceGroup } from "../../../../reducers/vehiclePriceGroups/types";
import { IAppState } from "../../../../store";
import { ConfirmationDialog } from "../../../common/ConfirmationDialog/ConfirmationDialog";
import PdfViewer from "../../../common/Pdf/ShowPdf";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage, getImage, toCurrency } from "../../../common/utils";
import { canExtend, canTerminate, CustomerType, getMileageLimitText, SOCKET_EVENTS } from "../utils";
import { NuvvenDivider } from "./Components/Divider";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  BOOKING_STATUS,
  CANCEL_TITLE,
  CHECK_TYPE,
  COMPLETE_DESCRIPTION,
  COMPLETE_TITLE,
  IConfirmationDialogData,
  VEHICLE_STATUS,
  defaultConfirmationDialogData,
  EARLY_TERMINATION_DESCRIPTION,
  EARLY_TERMINATE_TITLE,
  ICompleteConfirmationDialogData
} from "./const";
import { DriversInformation } from "./Components/DriversInformation";
import { SEND_BOOKING_CONFIRMATION_EMAIL } from "../../../../graphql/bookings/sendQuoteMutation";
import { PaymentInfo } from "./Components/PaymentInfo";
import { ExtendBookingDialog } from "./Components/ExtendBookingDialog";
import { InvoiceStatus } from "../../Invoices/UpdateInvoice/InvoiceComponents/types";
import { GET_CREDIT_NOTE_DATA } from "../../../../graphql/bookings/getCreditNoteDataQuery";
import { CreditNoteCreateDialog } from "./Components/CreditNoteCreateDialog";
import { EarlyTerminationDialog } from "./Components/EarlyTerminationDialog";
import { EARLY_TERMINATE_BOOKING } from "../../../../graphql/bookings/earlyTeminateBookingMutation";
import { RefundDialog } from "./Components/RefundDialog";
import { UPDATE_REFUND } from "../../../../graphql/bookings/updateRefundMutation";
import MailSendBox from "../../../common/MailSendBox";
import ShowMessage from "../../../common/MailSendBox/ShowMessage";
import { SEND_RENTAL_AGREEMENT } from "../../../../graphql/bookings/sendRentalAgreementMutation";
import { ViewBookingQuestions } from "./Components/ViewBookingQuestions";
import { DeliveryQuestions } from "../NewBooking/PersonalDetails/DeliveryQuestions";
import { ADD_ADDITIONAL_TNC } from "../../../../graphql/bookings/addBookingTncMutation";
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { SEND_VEHICLE_CONDITION_REPORT } from "../../../../graphql/bookings/sendVehicleConditionReportMutation";
import { GENERATE_RENTAL_AGREEMENT_PDF } from "../../../../graphql/bookings/generateRAPdfMutation";
import { UPDATE_BOOKING_DEPOSIT_MUTATION } from "../../../../graphql/bookings/updateBookingDepositMutation";
import { FloatInput } from "../../../common/FloatInput/FloatInput";
import DepositPaymentDialog from "./DepositPaymentView/DepositPaymentDialog";
import { REFUND_BOOKING_DEPOSIT } from "../../../../graphql/bookings/refundBookingDepositQuery";
import { DateTime as d } from 'luxon';
import { getLocalizedBookingSyntex, getLocalizedCancelBookingSyntex, getLocalizedDateFormat, getLocalizedInsuranceNumberSyntex, getLocalizedRASyntex } from "../../../../utils/localized.syntex";
import { MARK_BOOKING_AS_TBA } from "../../../../graphql/bookings/markBookingTbaMutation";
import { DECLINE_BOOKING } from "../../../../graphql/bookings/declineBookingMutation";
import { APPROVE_BOOKING } from "../../../../graphql/bookings/approveBookingMutation";
import UpdateVehicleSwapRequest from "./Components/UpdateVehicleSwapRequest";
import { ADD_NOTES_TO_SWAP_REQUEST } from "../../../../graphql/bookings/addNotesToSwapRequestMutation";
import { APPROVE_SWAP_REQUEST } from "../../../../graphql/bookings/approveSwapRequestMutation";
import { DECLINE_SWAP_REQUEST } from "../../../../graphql/bookings/declineSwapRequestMutation";
import { COMPLETE_SWAP_REQUEST } from "../../../../graphql/bookings/CompleteSwapRequestMutation";
import { REGENERATE_VEHICLE_CONDITION_REPORT } from "../../../../graphql/bookings/regenerateVehicleConditionReportMutation";
import AddCoiDetails from "./Components/AddCoiDetails";
import EarlyTerminateSubscriptionDialog from "./Components/EarlyTerminateSubscriptionDialog";
import { GENERATE_SUBSCRIPTION_AGREEMENT_PDF } from "../../../../graphql/bookings/generateSubscriptionAgreement";
import { BOOKING_TYPE } from "../../../../reducers/organisation/types";
import { CANCEL_PAYMENT_LINK } from "../../../../graphql/invoices/cancelPaymentLinkMutation";
import { captureErrorException } from "../../../../utils/sentry";
import { socket } from "../../../../utils/socket";
import { UserRoles } from "../../../hoc/Authorization";
import { CompleteConfirmationDialog } from "../../../common/ConfirmationDialog/CompleteConfirmationDialog";
import VehicleReplacementDialog from "./VehicleReplacementDialog";

// TODO Integrate the new images here

enum PDFViewMode {
  RENTAL_AGGREMENT = 'RENTAL_AGGREMENT',
  VC_REPORT = 'VC_REPORT'
}

export enum VehicleCheckTypes {
  PICKUP = "PICKUP",
  DROPOFF = "DROPOFF"
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expansionPanel: {
      marginTop: 8,
      width: "calc(100vw - 44px)"
    },
    imageContainer: {
      border: "1px solid #e2e2e2",
      padding: 5,
      display: "inherit",
      maxWidth: "50%",
      marginTop: "10px",
      marginRight: "10px"
    },
    imageStyle: {
      height: 80,
      width: 90,
    },
    boldtext: {
      fontSize: "16px",
      fontWeight: "bold"
    },
    button: {
      width: "100%"
    },
    buttonEnabled: {
      backgroundColor: "#faaf40",
      color: "white",
      width: "100px"
    },
    sliderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '1rem'
    },
    iconStyle: {
      padding: "0.3rem",
      borderRadius: "3rem",
      backgroundColor: "#F5F5F5"
    },
    content: {
      padding: "50px"
    },
    details: {
      color: "#000000",
      fontSize: 16
    },
    header: {},
    para: {
      fontSize: "16px"
    },
    pdf: {},
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    space: {
      fontSize: "16px",
      fontWeight: "bold"
    },
    subtitle: {
      color: "#2a9cb7",
      fontSize: "16px",
      marginBottom: "0px"
    },
    tags: {
      fontSize: "16px",
      fontWeight: "bold"
    },
    title: {
      color: "#faaf40",
      fontSize: "16px",
      fontWeight: "bold",
      width: "100%"
    },
    tableCellBorder: {
      borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
    image: {
      width: "150px",
      height: "100px",
      objectFit: "contain"
    },
    subMenu: {
      padding: 10
    },
    subMenu_li: {
      listStyleType: "none",
      textDecoration: "none",
      paddingTop: 6,
      cursor: "pointer"
    },
    summaryLeftMargin: {
      marginLeft: 5
    },
    linkText: {
      color: "rgb(24, 117, 210)",
      textDecoration: "none",
      cursor: "pointer"
    },
    inlineButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(100vw - 44px)',
      left: '-6px',
      marginTop: theme.spacing(2),
    },
    text: {
      position: 'relative',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 40px)',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    cancelButton: {
      backgroundColor: "var(--ion-color-danger)",
      "&:hover": {
        backgroundColor: "var(--ion-color-danger-shade)"
      },
      position: 'relative',
      width: 'calc(50vw - 40px)',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    completeButton: {
      backgroundColor: "var(--ion-color-success)",
      "&:hover": {
        backgroundColor: "var(--ion-color-success-shade)"
      },
      position: 'relative',
      width: 'calc(50vw - 40px)',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    swapDetailsBox: {
      display: "flex",
      padding: "20px 0px 0px 20px",
      justifyContent: "space-between"
    },
    rowHover: {
      cursor: "pointer",
      "& .MuiTableRow-root:hover": {
        background: "lightgrey"
      }
    },
    proceedButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      color: 'white',
      marginBottom: '10px',
      marginRight: 10,
      '&.Mui-disabled': {
        backgroundColor: 'gray',
        color: 'white',
      },
    },
  })
);

export interface IVehicleConsumerImages {
  licencePlate: string;
  images: IImages;
}

export interface IImages {
  pickupImages: string[];
  returnImages: string[];
}

const ViewBooking: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const location = useLocation();
  const { currentBranch } = useSelector((state: IAppState) => state.userReducer);
  const { distanceUnit } = currentBranch;
  const [booking, setBooking] = useState<IBooking>();
  const [pdfOpen, setPdfOpen] = useState<boolean>(false);
  const [currentBookingSchedule, setBookingSchedule] = useState<string>("");
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [pdfFileName, setPdfFileName] = useState<string>("");
  const [pdfTitle, setPdfTitle] = useState<string>("");
  const [pdfId, setPdfId] = useState<string | undefined>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>();
  const [confirmationDialogData, setConfirmationDialogData] = useState<
    IConfirmationDialogData
  >(defaultConfirmationDialogData);
  const [completeConfirmationDialogData, setCompleteConfirmationDialogData] = useState<
    ICompleteConfirmationDialogData
  >(defaultConfirmationDialogData);
  const [depositPaymentDialogOpen, setDepositPaymentDialogOpen] = useState<boolean>(false);
  const [earlyTerminateDialogOpen, setEarlyTerminateDialogOpen] = useState<boolean>(false);
  const [subscriptionEarlyTerminateDialog, setSubscriptionEarlyTerminateDialog] = useState<boolean>(false);
  const [cancellationDialogOpen, setCancellationDialogOpen] = useState<boolean>(false);
  const [refundDialogOpen, setRefundDialogOpen] = useState<boolean>(false);
  const [selectedCreditNote, setSelectedCreditNote] = useState<ICreditNote>();
  const [creditNotesInput, setCreditNotesInput] = useState<ICreditNoteInput[]>();
  const [isBusinessCustomerBooking, setIsBusinessCustomerBooking] = useState<boolean>();
  const [businessCustomer, setBusinessCustomer] = useState<IBusinessCustomer>();
  const [customer, setCustomer] = useState<ICustomer>();
  const [note, setNote] = useState<IBookingNoteInput>();
  const [notes, setNotes] = useState<IBookingNote[]>([]);
  const [additionalInformation, setAdditionalInformation] = useState<IBookingAdditionalInformation>();
  const [editAdditionalInformation, setEditAdditionalInformation] = useState<boolean>(false);
  const [additionalTnc, setAdditionalTnc] = useState<string>("");
  const [editAdditionalTnc, setEditAdditionalTnc] = useState<boolean>(false);
  const [cancellationLoading, setCancellationLoading] = useState<boolean>(false);
  const [bookingLoading, setBookingLoading] = useState<boolean>(false);
  const [refundLoading, setRefundLoading] = useState<boolean>(false);
  const [bookingId, setBookingId] = useState<string>();
  const [intervalId, setIntervalId] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [raLoading, setRALoading] = useState<boolean>(false);
  const [pdfViewMode, setPdfViewMode] = useState<string>();
  const [vcReportType, setVCReportType] = useState<VehicleCheckTypes>();
  const [openExtendBookingDialog, setOpenExtendBookingDialog] = useState<boolean>(false);
  const [isSendEmailClick, setIsSendEmailClick] = useState<boolean>(false);
  const [isRentalSendEmailClick, setIsRentalSendEmailClick] = useState<boolean>(false);
  const [isSendVehicleChecksEmailClick, setIsSendVehicleChecksEmailClick] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showRentalMessage, setShowRentalMessage] = useState<boolean>(false);
  const [showVehicleChecksMessage, setShowVehicleChecksMessage] = useState<boolean>(false);
  const [showMails, setShowMails] = useState<string[]>([]);
  const [showRentalMails, setShowRentalMails] = useState<string[]>([]);
  const [showVehicleChecksMails, setShowVehicleChecksMails] = useState<string[]>([]);
  const [rentalAgreementUrl, setRentalAgreementUrl] = useState<string>();
  const [selectedVehicleScheduleId, setSelectedVehicleScheduleId] = useState<string>("");
  const [depositEditable, setDepositEditable] = useState<boolean>(false);
  const [editIconVisible, setEditIconVisible] = useState<boolean>(false);
  const [depositAmount, setDepositAmount] = useState<number>(booking?.depositDetails?.amount || 0)
  const [poNumber, setPoNumber] = useState<number>()
  const userState = useSelector((state: IAppState) => state.userReducer);
  const organisation = userState.currentOrganisation;
  const { locale, currency } = organisation;
  const { country } = userState.currentOrganisation.address;
  const [pdf, setPdf] = useState<string>("");
  const [rentalDialogOpen, setRentalDialogOpen] = useState<boolean>(false);
  const [tbaDialogOpen, setTbaDialogOpen] = useState<boolean>(false);
  const [tbaNotes, settbaNotes] = useState<string>("");
  const [mobileRentalbutton, setMobileRentalButton] = useState<boolean>(false);
  const [consumerImages, setConsumerImages] = useState<IVehicleConsumerImages[]>([]);
  const [currentPreviewIndex, setcurrentPreviewIndex] = useState<number>(0);
  const [currentReturnPreviewIndex, setcurrentReturnPreviewIndex] = useState<number>(0);
  const [openUpdateSwapRequest, setOpenUpdateSwapRequest] = useState<boolean>(false);
  const [selectedSwapRequest, setSelectedSwapRequest] = useState<ISwapRequest>();
  const prevBranchRef = useRef(userState.currentBranch);
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [replaceVehicle, setReplaceVehicle] = useState<boolean>(false);
  const [bookingVgs, setBookingVgs] = useState<any[]>([]);
  const [bookingVehicles, setBookingVehicles] = useState<any[]>([]);
  const [showTBAToggle, setTBAToggleVisible] = useState<boolean>(false);
  const [showBookingExtension, setBookingExtensionVisible] = useState<boolean>(false);

  const CANCELLATION_REASONS = [
    'No show',
    'Vehicle not available',
    'Requirement changed',
    'Price',
    'Other',
  ];

  const maxCounter = 7;
  const [
    loadBooking,
    { data: bookingData, loading: bookingDataLoading }
  ] = useLazyQuery(GET_BOOKING, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.booking) {
        const queryParams = new URLSearchParams(location.search);
        queryParams.has('payment_intent') ? navigate('/bookings') : navigate(-1);
      }
      setBooking(data.booking);
      setBookingLoading(false);
      setBookingVgs(data.booking?.vehicleGroups)
      const vehicles: any[] = [];
      data.booking?.vehicleGroups.forEach((vehicleGroup: any) => {
        vehicleGroup.activeVehicleSchedules.forEach((schedule: any) => {
          vehicles.push(schedule.vehicle);
        });
      });
      setBookingVehicles(vehicles);
      if ([BookingStatus.CONFIRMED, BookingStatus.IN_PROGRESS].includes(data.booking.status)) {
        setTBAToggleVisible(true);
      }
      if (data.booking.bookingCategory && [BOOKING_CATEGORY.COURTESY_CAR, BOOKING_CATEGORY.TEST_DRIVE].includes(data.booking.bookingCategory)) {
        setTBAToggleVisible(false);
        setBookingExtensionVisible(false);
      }
      if (data.booking.status === BookingStatus.IN_PROGRESS && !data.booking.zeroCostBooking) {
        setBookingExtensionVisible(true);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    refundDeposit,
    { loading: refundDepositLoading }
  ] = useLazyQuery(REFUND_BOOKING_DEPOSIT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.refundBookingDeposit) {
        setBooking(data.refundBookingDeposit);
        setBookingLoading(false);
      } else {
        snackbar({
          message: "Something went wrong",
          variant: SnackBarVariant.ERROR
        });
      };
      setConfirmationDialogData({
        ...confirmationDialogData,
        isLoading: false,
        isOpen: false
      });
      snackbar({
        message: `Amount Refunded successfully`,
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    getCreditNoteData, { loading: creditNoteDataLoading }
  ] = useLazyQuery(GET_CREDIT_NOTE_DATA, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.creditNoteData) {
        setConfirmationDialogData({
          ...confirmationDialogData,
          isOpen: false
        })
        if (booking && booking.status === BookingStatus.CONFIRMED) {
          setCancellationDialogOpen(true)
        } else {
          setEarlyTerminateDialogOpen(true)
        }
        setCreditNotesInput(data.creditNoteData)
        setDialogOpen(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [earlyTerminateBookingMutation] = useMutation(EARLY_TERMINATE_BOOKING, {
    onCompleted: () => {
      setCancellationLoading(false)
      snackbar({
        message: "Booking terminated early successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setConfirmationDialogData({
        ...confirmationDialogData,
        isOpen: false
      });
      setEarlyTerminateDialogOpen(false);
      setSubscriptionEarlyTerminateDialog(false)
      loadBooking({
        variables: {
          id: booking?.id
        }
      });
      setBookingLoading(true);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      navigate("/bookings");
    }
  })
  const [updateRefundMutation] = useMutation(UPDATE_REFUND, {
    onCompleted: (data) => {
      if (data && data.updateRefund) {
        setCancellationLoading(false)
        snackbar({
          message: "Refund Updated Successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setConfirmationDialogData({
          ...confirmationDialogData,
          isOpen: false
        });
        setSelectedCreditNote(data.updateRefund);
        setRefundLoading(false);
        loadBooking({
          variables: {
            id: booking?.id
          }
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  })

  const [updateDeposit, { loading: updateDepositLoading }] = useMutation(UPDATE_BOOKING_DEPOSIT_MUTATION, {
    onCompleted: ({ updateBookingDeposit }) => {
      setBooking(updateBookingDeposit)
      setDepositAmount(updateBookingDeposit?.depositDetails?.amount || 0)
      snackbar({
        message: "Deposit updated successfully",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [cancelBookingMutation, { loading: cancelBookingLoading }] = useMutation(CANCEL_BOOKING, {
    onCompleted: () => {
      snackbar({
        message: `${getLocalizedBookingSyntex(country)} cancelled successfully`,
        variant: SnackBarVariant.SUCCESS
      });
      setCancellationDialogOpen(false)
      setConfirmationDialogData({
        ...confirmationDialogData,
        isOpen: false
      });
      setDialogOpen(false)
      setCancellationLoading(false)
      loadBooking({
        variables: {
          id: booking?.id
        }
      });
      setBookingLoading(true);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      navigate("/bookings");
    }
  });

  const [completeBookingMutation] = useMutation(COMPLETE_BOOKING, {
    onCompleted: () => {
      snackbar({
        message: "Booking completed successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setConfirmationDialogData({
        ...confirmationDialogData,
        isOpen: false
      });
      loadBooking({
        variables: {
          id: booking?.id
        }
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [approveBookingMutation] = useMutation(APPROVE_BOOKING, {
    onCompleted: () => {
      snackbar({
        message: `${getLocalizedBookingSyntex(country)} approved successfully`,
        variant: SnackBarVariant.SUCCESS
      });
      setConfirmationDialogData({
        ...confirmationDialogData,
        isLoading: false,
        isOpen: false
      });
      loadBooking({
        variables: {
          id: booking?.id
        }
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [declineBookingMutation] = useMutation(DECLINE_BOOKING, {
    onCompleted: () => {
      snackbar({
        message: `${getLocalizedBookingSyntex(country)} declined successfully`,
        variant: SnackBarVariant.SUCCESS
      });
      setConfirmationDialogData({
        ...confirmationDialogData,
        isLoading: false,
        isOpen: false
      });
      loadBooking({
        variables: {
          id: booking?.id
        }
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });


  const [addBookingNote] = useMutation(ADD_BOOKING_NOTE, {
    onCompleted: (data) => {
      snackbar({
        message: "Note added successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setNotes([...notes, { ...data.addBookingNote }]);
      setNote({
        description: "",
        createdBy: "",
        createdDate: ""
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [addBookingAdditionalInformation] = useMutation(ADD_ADDITIONAL_INFORMATION, {
    onCompleted: (data) => {
      if (data.addAditionalInformation) {
        setAdditionalInformation(data.additionalInformation.description);
      }
      snackbar({
        message: "Additional information saved successfully",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [addBookingAdditionalTnc] = useMutation(ADD_ADDITIONAL_TNC, {
    onCompleted: (data) => {
      if (data.addAdditionalTnc) {
        setAdditionalTnc(data.addAdditionalTnc)
        snackbar({
          message: "Additional Tnc saved successfully",
          variant: SnackBarVariant.SUCCESS
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [sendBookingConfirmation] = useMutation(SEND_BOOKING_CONFIRMATION_EMAIL, {
    onCompleted: () => {
      setLoading(false);
      setShowMessage(true);
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [generateRentalAgreementPdf] = useMutation(GENERATE_RENTAL_AGREEMENT_PDF, {
    onCompleted: (data: any) => {
      if (data.generateRentalAgreementPdf.success) {
        setRALoading(false);
        snackbar({
          message: "File generated successfully, please wait while we fetch latest document for you!",
          variant: SnackBarVariant.SUCCESS
        })
        setPdfOpen(false)
        loadBooking({
          variables: {
            id: booking?.id
          }
        })
      } else {
        setRALoading(false);
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR
        });
        setPdfOpen(false)
      }
    },
    onError: (error: ApolloError) => {
      setRALoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [generateSubscriptionAgreementPdf] = useMutation(GENERATE_SUBSCRIPTION_AGREEMENT_PDF, {
    onCompleted: (data: any) => {
      if (data.generateSubscriptionAgreementPdf.success) {
        setRALoading(false);
        snackbar({
          message: "File generated successfully, please wait while we fetch latest document for you!",
          variant: SnackBarVariant.SUCCESS
        })
        setPdfOpen(false)
        loadBooking({
          variables: {
            id: booking?.id
          }
        })
      } else {
        setRALoading(false);
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR
        });
        setPdfOpen(false)
      }
    },
    onError: (error: ApolloError) => {
      setRALoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [regenerateVehicleConditionReport] = useMutation(REGENERATE_VEHICLE_CONDITION_REPORT, {
    onCompleted: (data: any) => {
      if (data.regenerateVehicleConditionReport.success) {
        setRALoading(false);
        snackbar({
          message: "File generation in progress, please wait while we fetch latest document for you!",
          variant: SnackBarVariant.SUCCESS
        })
        setPdfOpen(false);
        loadBooking({
          variables: {
            id: booking?.id
          }
        });
      } else {
        setRALoading(false);
        snackbar({
          message: "File being generated, Please try after sometime",
          variant: SnackBarVariant.ERROR
        });
        setPdfOpen(false);
      }
    },
    onError: (error: ApolloError) => {
      setRALoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [cancelPaymentLink, { loading: cancelPaymentLinkLoading }] = useMutation(CANCEL_PAYMENT_LINK, {
    onCompleted: (data) => {
      if (data?.cancelPaymentLink?.status === PaymentStatus.CANCELLED) {
        setConfirmationDialogData({
          ...confirmationDialogData,
          isLoading: false,
          isOpen: false
        });
        snackbar({
          message: "Payment cancelled successfully",
          variant: SnackBarVariant.SUCCESS
        });
        loadBooking({
          variables: {
            id: booking?.id
          }
        })
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  })


  const [sendRentalAgreement] = useMutation(SEND_RENTAL_AGREEMENT, {
    onCompleted: () => {
      setShowRentalMessage(true);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [sendVehicleConditionReport] = useMutation(SEND_VEHICLE_CONDITION_REPORT, {
    onCompleted: () => {
      setShowVehicleChecksMessage(true);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [markBookingAsTba, { loading: tbaLoading }] = useMutation(MARK_BOOKING_AS_TBA, {
    onCompleted: (data) => {
      if (data.markBookingAsTba) {
        setTbaDialogOpen(false)
        setBooking(data.markBookingAsTba)
        if (data.markBookingAsTba.tba) {
          snackbar({
            message: "Booking marked as TBA successfully",
            variant: SnackBarVariant.SUCCESS
          })
        } else {
          snackbar({
            message: "Booking marked as not TBA successfully",
            variant: SnackBarVariant.SUCCESS
          })
        }
      }

    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [addNotesToSwapRequest, { loading: loadingAddNotes }] = useMutation(
    ADD_NOTES_TO_SWAP_REQUEST,
    {
      onCompleted: (data) => {
        if (data) {
          setBooking(data.addNotesToSwapRequest)
        }
        snackbar({
          message: "Swap request notes added successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setOpenUpdateSwapRequest(false)
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [approveSwapRequest, { loading: loadingApproveRequest }] = useMutation(
    APPROVE_SWAP_REQUEST,
    {
      onCompleted: (data) => {
        if (data) {
          setBooking(data.approveSwapRequest)
        }
        snackbar({
          message: "Swap request approved successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setOpenUpdateSwapRequest(false)
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [declineSwapRequest, { loading: loadingDeclineRequest }] = useMutation(
    DECLINE_SWAP_REQUEST,
    {
      onCompleted: (data) => {
        if (data) {
          setBooking(data.declineSwapRequest)
        }
        snackbar({
          message: "Swap request declined successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setOpenUpdateSwapRequest(false)
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [completeSwapRequest, { loading: loadingCompleteRequest }] = useMutation(
    COMPLETE_SWAP_REQUEST,
    {
      onCompleted: (data) => {
        if (data) {
          setBooking(data.completeSwapRequest)
        }
        snackbar({
          message: "Swap request completed successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setOpenUpdateSwapRequest(false)
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const handleReasonChange = (reason: string) => {
    setSelectedReason(reason);
    if (reason !== 'Other') {
      setOtherReason('');
    }
  };
  async function getPdf(conditionsUrl: string) {
    const file = await getSignedUrl(conditionsUrl)
    setPdf(file);
  }

  useEffect(() => {
    if (pdfId) {
      getPdf(pdfId);
    }
  }, [pdfId]);

  useEffect(() => {
    if (mobileRentalbutton && pdf && booking) {
      if (booking.status === BookingStatus.CONFIRMED ||
        booking.status === BookingStatus.IN_PROGRESS ||
        booking.status === BookingStatus.COMPLETED) {
        setRentalDialogOpen(true);
      } else {
        downloadRentalAgreement(pdf)
      }
      setMobileRentalButton(false);
    }
  }, [pdf, mobileRentalbutton]);

  useEffect(() => {
    socket.auth = {
      userId: userState.id,
    };
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);


  useEffect(() => {
    if (bookingData && bookingData.booking && bookingData.booking.id) {
      socket.emit(SOCKET_EVENTS.REQUEST_VIEW_BOOKING, { bookingId: bookingData.booking.id })
    }
  }, [bookingData]);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.GET_UPDATED_BOOKING, (data) => {
      loadBooking({
        variables: {
          id: data.bookingId
        }
      })
    })

    socket.on(SOCKET_EVENTS.RENTAL_AGGREMENT_GENERATED, (data) => {
      setRentalAgreementUrl(data.rentalAgreementUrl);
    });

    return () => {
      socket.off(SOCKET_EVENTS.GET_UPDATED_BOOKING);
      socket.off(SOCKET_EVENTS.RENTAL_AGGREMENT_GENERATED);
    }
  }, [])

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const booking = params.get("booking");
      if (booking) {
        loadBooking({
          variables: {
            id: booking
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (bookingData && bookingData.booking) {
      if ([BookingStatus.CANCELLED].includes(bookingData.booking.status)) {
        clearInterval(intervalId);
      }
      if (bookingData.booking.rentalAgreementUrl) {
        setRentalAgreementUrl(bookingData.booking.rentalAgreementUrl);
      }
      if (bookingData.booking.notes) {
        setNotes(bookingData.booking.notes);
      }
      setDepositAmount(bookingData.booking.depositDetails?.amount || 0)
      if (bookingData.booking.additionalInformation
        && bookingData.booking.additionalInformation.description && !editAdditionalInformation) {
        setAdditionalInformation({
          ...additionalInformation,
          description: bookingData.booking.additionalInformation.description
        });
      }
      if (bookingData.booking.poNumber) {
        setPoNumber(bookingData.booking.poNumber)
      }
      if (bookingData.booking.additionalTnc && !editAdditionalTnc) {
        setAdditionalTnc(bookingData.booking.additionalTnc)
      }
      const isBusinessCustomerBooking =
        bookingData.booking.customerType === "business" &&
          bookingData.booking.businessCustomer &&
          bookingData.booking.businessCustomer.id !== ""
          ? true
          : false;
      setIsBusinessCustomerBooking(isBusinessCustomerBooking);
      setBusinessCustomer(bookingData.booking.businessCustomer);
      setCustomer(bookingData.booking.customer);
      let totalAmount = 0;
      let paidAmount = 0;
      bookingData.booking.invoices.forEach((invoice: IInvoice) => {
        if (invoice.confirmedPaidAmount) {
          paidAmount += invoice.confirmedPaidAmount;
        }
        if (invoice.totalPayableAmount) {
          totalAmount += invoice.totalPayableAmount;
        }
      });
    }
  }, [bookingData]);

  useEffect(() => {
    (async function () {
      if (booking) {
        let consumerImgArr: IVehicleConsumerImages[] = [];
        let groups = booking.vehicleGroups;
        groups.forEach((vg) => {
          vg.activeVehicleSchedules?.forEach((bs) => {
            let pickupImages: string[] = [];
            let returnImages: string[] = [];

            if (bs.dropoffCheck?.vehicleState?.consumerWalkAroundImages?.length) {
              pickupImages = bs.dropoffCheck.vehicleState.consumerWalkAroundImages
                .filter((image) => image.key)
                .map((image) => image.key);
            }

            if (bs.pickupCheck?.vehicleState?.consumerWalkAroundImages?.length) {
              returnImages = bs.pickupCheck.vehicleState.consumerWalkAroundImages
                .filter((image) => image.key)
                .map((image) => image.key);
            }

            if (pickupImages.length || returnImages.length) {
              consumerImgArr.push({
                licencePlate: bs.vehicle.licencePlate,
                images: {
                  pickupImages,
                  returnImages
                }
              });
            }
          });
        });

        const _consumerImgArr = JSON.parse(JSON.stringify(consumerImgArr));
        for (let i = 0; i < _consumerImgArr.length; i++) {
          const promises: any = [];
          let item = _consumerImgArr[i];
          let pickupImgArr: string[] = [];
          let returnImgArr: string[] = [];
          if (item.images?.pickupImages?.length) {
            item.images.pickupImages.map((img: string) => {
              promises.push(
                (async () => {
                  try {
                    const data: any = await getImage(img);
                    pickupImgArr.push(data)
                  } catch (error) {
                    captureErrorException(error)
                    return;
                  }
                })()
              );
            });
            await Promise.all(promises)
            _consumerImgArr[i].images.pickupImages = pickupImgArr
          }
          if (item.images?.returnImages?.length) {
            item.images.returnImages.map((img: string) => {
              promises.push(
                (async () => {
                  try {
                    const data: any = await getImage(img);
                    returnImgArr.push(data)
                  } catch (error) {
                    captureErrorException(error)
                    return;
                  }
                })()
              );
            });
            await Promise.all(promises)
            _consumerImgArr[i].images.returnImages = returnImgArr
          }
        }
        setConsumerImages(_consumerImgArr)
      }
    })()
  }, [booking])

  useEffect(() => {
    getTermsAndConditions();
    if (prevBranchRef.current !== userState.currentBranch) {
      navigate("/bookings");
      prevBranchRef.current = userState.currentBranch;
    }
  }, [userState.tenancy?.id, userState.currentBranch]);


  const updateBookingDeposit = () => {
    if (booking) {
      updateDeposit({
        variables: {
          bookingId: booking.id,
          depositAmount
        }
      })
    }
  }

  const fetchCancellationCharges = () => {
    if (booking) {
      if (booking.firstInvoice && [InvoiceStatus.UNPAID.toString(), InvoiceStatus.DRAFT.toString()].includes(booking.firstInvoice.status) && booking.status === BookingStatus.CONFIRMED) {
        setCancellationLoading(true)
        cancelBookingMutation({
          variables: {
            id: booking.id,
            cancellationReason: selectedReason === "Other" ? otherReason : selectedReason
          }
        });
      } else {
        getCreditNoteData({
          variables: {
            bookingId: booking?.id
          }
        });
      }
    }
  }

  const updateRefund = (refund: IRefund, creditNote: ICreditNote) => {
    if (refund) {
      setRefundLoading(true);
      updateRefundMutation({
        variables: {
          creditNoteId: creditNote.id,
          refundId: refund.id,
          status: refund.status
        }
      })
    }
  }

  const earlyTerminateBooking = (bookingScheduleIds: string[], endDate: string, amount?: number, terminationType?: string) => {
    if (booking) {
      setCancellationLoading(true)
      earlyTerminateBookingMutation({
        variables: {
          bookingId: booking.id,
          bookingScheduleIds,
          endDate,
          amount,
          terminationType
        }
      })
    }
  }

  const cancelBooking = (creditNoteInput: ICreditNoteInput) => {
    if (booking) {
      setCancellationLoading(true)
      cancelBookingMutation({
        variables: {
          id: booking.id,
          creditNoteInput,
          cancellationReason: selectedReason === "Other" ? otherReason : selectedReason
        }
      });
    }
  };

  const completeBooking = (sendMail: boolean) => {
    if (booking) {
      const id = booking.id;
      completeBookingMutation({
        variables: {
          id,
          sendMail
        }
      });
    }
  };

  const approveBooking = () => {
    if (booking) {
      approveBookingMutation({
        variables: {
          bookingId: booking.id
        }
      });
    }
  };

  const declineBooking = () => {
    if (booking) {
      declineBookingMutation({
        variables: {
          bookingId: booking.id
        }
      });
    }
  };

  const goLeft = () => {
    setcurrentPreviewIndex((currentPreviewIndex - 1))
  }

  const goRight = () => {
    setcurrentPreviewIndex((currentPreviewIndex + 1))
  }

  const goLeftReturn = () => {
    setcurrentReturnPreviewIndex((currentReturnPreviewIndex - 1))
  }

  const goRightReturn = () => {
    setcurrentReturnPreviewIndex((currentReturnPreviewIndex + 1))
  }

  const canCancel = () => {
    if (booking) {
      return (
        booking.status === BookingStatus.QUOTE ||
        booking.status === BookingStatus.CONFIRMED
      );
    }
  };

  const canCollectDeposit = () => {
    let flag = true
    if (booking) {
      if ([BookingStatus.CONFIRMED, BookingStatus.IN_PROGRESS].includes(booking.status)) {
        if (booking.depositDetails?.payment) {
          if (booking.depositDetails.payment?.amount) {
            if (booking.depositDetails.payment?.status &&
              [PaymentStatus.PAID, PaymentStatus.AUTHORISED].includes(booking.depositDetails.payment.status)) {
              flag = false
            }
          } else {
            flag = false
          }
        }
      } else {
        flag = false
      }
    } else {
      flag = false;
    }
    return flag;
  }

  const canEditDeposit = () => {
    let flag = true;
    if (booking && booking.status) {
      if (![BOOKING_STATUS.CONFIRMED, BOOKING_STATUS.IN_PROGRESS].includes(booking?.status)) {
        flag = false
      } else {
        if (booking.depositDetails?.payment?.status) {
          const paymentStatus = booking?.depositDetails?.payment?.status
          if ([PaymentStatus.AUTHORISED, PaymentStatus.PAID].includes(paymentStatus)) {
            flag = false
          }
        }
      }
    } else {
      flag = false
    }
    return flag;
  }

  const canComplete = () => {
    let invoiceClear: boolean = false;
    if (isBusinessCustomerBooking) {
      invoiceClear = true
    } else if (booking?.currentInvoice &&
      (booking.currentInvoice?.totalPayableAmount -
        booking.currentInvoice?.confirmedPaidAmount) === 0
    ) {
      invoiceClear = true;
    }
    return (
      booking && booking.status === BookingStatus.IN_PROGRESS && invoiceClear
    );
  };


  const getTermsAndConditions = async () => {
    if (!userState.tenancy?.id) {
      return;
    }

    const key = `${userState.tenancy?.id}.pdf`;

    const file = await getSignedUrl(key)

    setTermsAndConditions(file);
  };

  const isReasonValid = () => {
    if (selectedReason === 'Other') {
      return otherReason.trim() !== '';
    }
    return selectedReason !== '';
  };

  const handleSubmit = () => {
    if (isReasonValid()) {
      fetchCancellationCharges();
    } else {
      if (selectedReason === 'Other') {
        snackbar({
          message: "Please enter the reason to cancel",
          variant: SnackBarVariant.ERROR
        });
      }
      else {
        snackbar({
          message: "Please select a reason to cancel",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  };
  async function downloadRentalAgreement(url: any) {
    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = pdfFileName ? pdfFileName : `Rental_agreement.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  const ifPCONumberExists = () => {
    let flag = false;
    if (booking && booking.vehicleGroups && booking.vehicleGroups.length) {
      booking.vehicleGroups.forEach((vg: any) => {
        if (vg.activeVehicleSchedules && vg.activeVehicleSchedules.length) {
          vg.activeVehicleSchedules.forEach((schedule: any) => {
            if (schedule.vehicle && schedule.vehicle.pcoNumber) {
              flag = true;
            }
          });
        }
      });
    }
    return flag;
  };

  const ifchecksIncomplete = () => {
    let flag: boolean = false;
    booking?.currentBookingSchedules.forEach((schedule) => {
      if (
        booking?.status === BOOKING_STATUS.CONFIRMED &&
        schedule.vehicleStatus !== VEHICLE_STATUS.DROPOFF_CHECK_COMPLETE
      ) {
        flag = true;
      }
      if (
        booking?.status === BOOKING_STATUS.IN_PROGRESS &&
        schedule.vehicleStatus !== VEHICLE_STATUS.PICKUP_CHECK_COMPLETE
      ) {
        flag = true;
      }
    });
    return flag;
  };

  const isVehicleChecked = (vehicleStatus: string) => {
    let flag: boolean = false;
    let checkType = CHECK_TYPE.DELIVERY;
    if (booking?.status === BOOKING_STATUS.CONFIRMED) {
      if (vehicleStatus === VEHICLE_STATUS.DROPOFF_CHECK_COMPLETE) {
        flag = true;
      }
    } else if (booking?.status === BOOKING_STATUS.IN_PROGRESS || booking?.status === BOOKING_STATUS.COMPLETED) {
      checkType = CHECK_TYPE.COLLECTION;
      if (vehicleStatus === VEHICLE_STATUS.PICKUP_CHECK_COMPLETE || vehicleStatus === VEHICLE_STATUS.COMPLETED) {
        flag = true;
      }
    }
    const resObj = {
      checkType,
      checked: flag
    };
    return resObj;
  };

  const sendBookingConfirmationEmail = (emails: string[]) => {
    setLoading(true);
    setShowMessage(false);
    setIsSendEmailClick(false);
    let customerEmail = "";
    if (booking?.customerType === CustomerType.INDIVIDUAL && booking.customer) {
      customerEmail = booking.customer.email;
    } else if (
      booking?.customerType === CustomerType.BUSINESS &&
      booking.businessCustomer
    ) {
      customerEmail = booking.businessCustomer.contact.email;
    }
    setShowMails([customerEmail, ...emails]);
    if (booking && booking.id) {
      sendBookingConfirmation({
        variables: {
          bookingId: booking.id,
          additionalMails: emails
        }
      })
    }
  }

  const sendRentalAgreementEmail = (emails: string[]) => {
    setShowRentalMessage(false);
    setIsRentalSendEmailClick(false);
    let customerEmail = "";
    if (booking?.customerType === CustomerType.INDIVIDUAL && booking.customer) {
      customerEmail = booking.customer.email;
    } else if (
      booking?.customerType === CustomerType.BUSINESS &&
      booking.businessCustomer
    ) {
      customerEmail = booking.businessCustomer.contact.email;
    }
    setShowRentalMails([customerEmail, ...emails]);
    if (booking && booking.id) {
      sendRentalAgreement({
        variables: {
          id: booking.id,
          additionalMails: emails
        }
      })
    }
  }

  const sendVehicleConditionReportEmail = (emails: string[]) => {
    setShowVehicleChecksMessage(false);
    setIsSendVehicleChecksEmailClick(false);
    let customerEmail = "";
    if (booking?.customerType === CustomerType.INDIVIDUAL && booking.customer) {
      customerEmail = booking.customer.email;
    } else if (
      booking?.customerType === CustomerType.BUSINESS &&
      booking.businessCustomer
    ) {
      customerEmail = booking.businessCustomer.contact.email;
    }
    setShowVehicleChecksMails([customerEmail, ...emails]);
    if (booking && booking.id && selectedVehicleScheduleId) {
      sendVehicleConditionReport({
        variables: {
          bookingId: booking.id,
          bookingScheduleId: selectedVehicleScheduleId,
          additionalMails: emails
        }
      })
    }
  }
  const handlerInvoiceClick = (invoiceId: string | undefined) => {
    if (invoiceId) {
      navigate(`/update-billing?invoice=${invoiceId}`);
    }
  }

  const getUpdatedRentalAgreementUrl = (newRentalAgreementUrl: string) => {
    if (newRentalAgreementUrl) {
      setRentalAgreementUrl(newRentalAgreementUrl);
    }
  }

  const handleClose = () => {
    setRentalDialogOpen(false)
  }

  const handleTbaDialogClose = () => {
    setTbaDialogOpen(false)
  }

  const onAddSwapNote = (notes: INotesInput) => {
    addNotesToSwapRequest({
      variables: {
        data: {
          bookingId: booking?.id,
          swapRequestId: notes.swapRequestId,
          notes: notes.notes
        }
      }
    })
  }

  const onApproveSwapRequest = (data: IGenericSwapInput) => {
    approveSwapRequest({
      variables: {
        data: {
          bookingId: data.bookingId,
          swapRequestId: data.swapRequestId
        }
      }
    })
  }

  const createTask = () => {
    if (booking)
      navigate(`/user-job?booking=${booking?.id}`);
  }

  const onDeclineSwapRequest = (data: IGenericSwapInput) => {
    declineSwapRequest({
      variables: {
        data: {
          bookingId: booking?.id,
          swapRequestId: data.swapRequestId,
        }
      }
    })
  }

  const onCompleteSwapRequest = (data: IGenericSwapInput) => {
    completeSwapRequest({
      variables: {
        data: {
          bookingId: booking?.id,
          swapRequestId: data.swapRequestId,
        }
      }
    })
  }

  const renderVehicleDetails = () => {
    if (booking) {
      if (booking.isSubscription) {
        const vehicleSchedule = booking.currentBookingSchedules[0]
        const driver = booking.businessCustomer?.approvedDrivers.find((driver => driver.id === vehicleSchedule.driver?.id));
        return (
          <>
            {
              booking.isSubscription &&
              booking.previousBookingSchedules &&
              booking.previousBookingSchedules.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      Previous Vehicle Details
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <TableContainer>
                      <Table aria-label="spanning table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                            >
                              <Typography>Subscription</Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              <Typography>Registration</Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              <Typography>Make/Model</Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              <Typography>VIN</Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              <Typography>Mileage Limit</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography>Status</Typography>
                            </TableCell>
                            {
                              booking.customerType === CustomerType.BUSINESS && (
                                <TableCell align="left">
                                  <Typography>Driver Assigned</Typography>
                                </TableCell>
                              )
                            }
                            <TableCell align="left">
                              <Typography>Vehicle Condition Report</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography></Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            booking.previousBookingSchedules.map(vSchedule => {
                              return (
                                <>
                                  <TableRow>
                                    <TableCell>{booking?.activeSubscription?.subscription?.name}</TableCell>
                                    <TableCell>{vSchedule.vehicle?.licencePlate}</TableCell>
                                    <TableCell>{vSchedule.vehicle?.make} {vSchedule.vehicle?.model}</TableCell>
                                    <TableCell>{vSchedule.vehicle?.vin}</TableCell>
                                    <TableCell>{booking?.activeSubscription?.unlimitedMileage ? "Unlimited" : booking?.activeSubscription?.mileageLimit}</TableCell>
                                    <TableCell>
                                      {booking.status === BookingStatus.CANCELLED ? "N/A" : vSchedule && vSchedule.dropoffCheck && !vSchedule.pickupCheck ?
                                        "Collection Check Pending" : vSchedule && vSchedule.dropoffCheck && vSchedule.pickupCheck ?
                                          "Collection Check Completed" : (
                                            <Tooltip title={"Please use mobile app to check the vehicle status to start the booking"}>
                                              <Typography>
                                                Delivery Check Pending
                                              </Typography>
                                            </Tooltip>
                                          )}
                                    </TableCell>
                                    {
                                      booking.customerType === CustomerType.BUSINESS && (
                                        <TableCell>
                                          {driver ? `${driver.firstName} ${driver.lastName}` : "N/A"}
                                        </TableCell>
                                      )
                                    }
                                    <TableCell style={{ textAlign: "center" }}>
                                      {vSchedule && vSchedule.dropoffCheck && (
                                        <Fab
                                          size="medium"
                                          variant="extended"
                                          onClick={() => {
                                            if (vSchedule && vSchedule.dropoffCheck) {
                                              setPdfId(vSchedule.dropoffCheck.vehicleStatePdfUrl);
                                              setBookingSchedule(vSchedule.id);
                                              setSendEmail(false);
                                              setPdfFileName(
                                                `VehicleConditionReport-${booking.referenceNumber}.pdf`
                                              );
                                              setPdfTitle(
                                                `Vehicle Condition Report`
                                              );
                                              setPdfOpen(true);
                                              setPdfViewMode(PDFViewMode.VC_REPORT);
                                              setVCReportType(VehicleCheckTypes.DROPOFF);
                                            }
                                          }}
                                          title="View Pre-Rental Vehicle Checks"
                                        >
                                          <VisibilityIcon />
                                        </Fab>
                                      )}
                                      {vSchedule && vSchedule.pickupCheck && (
                                        <Fab
                                          size="medium"
                                          variant="extended"
                                          style={{ marginLeft: 5 }}
                                          onClick={() => {
                                            if (vSchedule && vSchedule.pickupCheck) {
                                              setPdfId(vSchedule.pickupCheck.vehicleStatePdfUrl);
                                              setSendEmail(false);
                                              setBookingSchedule(vSchedule.id);
                                              setPdfFileName(
                                                `VehicleConditionReport-${booking.referenceNumber}.pdf`
                                              );
                                              setPdfTitle(
                                                `Vehicle Condition Report`
                                              );
                                              setPdfOpen(true);
                                              setPdfViewMode(PDFViewMode.VC_REPORT);
                                              setVCReportType(VehicleCheckTypes.PICKUP);
                                            }
                                          }}
                                          title="View Post-Rental Vehicle Checks"
                                        >
                                          <VisibilityIcon />
                                        </Fab>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {vSchedule && (vSchedule.dropoffCheck || vSchedule.pickupCheck) && (
                                        <Fab
                                          size={"medium"}
                                          variant={"extended"}
                                          onClick={() => {
                                            setIsSendVehicleChecksEmailClick(true);
                                            setSelectedVehicleScheduleId(vSchedule.id)
                                          }}
                                        >
                                          {"Send E-Mail"}
                                        </Fab>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </>
                              )
                            })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid >
                </>
              )
            }
            <Grid item xs={12}>
              <Box mt={2}></Box>
              <Typography variant="h3">
                Current Vehicle Details
              </Typography>
            </Grid >
            <Grid container item xs={12}>
              <TableContainer>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                      >
                        <Typography>Subscription</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography>Registration</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography>Make/Model</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography>VIN</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography>Mileage Limit</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>Status</Typography>
                      </TableCell>
                      {
                        booking.customerType === CustomerType.BUSINESS && (
                          <TableCell align="left">
                            <Typography>Driver Assigned</Typography>
                          </TableCell>
                        )
                      }
                      <TableCell align="left">
                        <Typography>Vehicle Condition Report</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography></Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                      >
                        {booking?.activeSubscription?.subscription?.name}
                      </TableCell>
                      <TableCell>{booking?.activeSubscription?.vehicle?.licencePlate}</TableCell>
                      <TableCell>{`${booking?.activeSubscription?.vehicle?.make} ${booking?.activeSubscription?.vehicle?.model}`}</TableCell>
                      <TableCell>{booking?.activeSubscription?.vehicle.vin}</TableCell>
                      <TableCell>{booking?.activeSubscription?.unlimitedMileage ? "Unlimited" : booking?.activeSubscription?.mileageLimit}</TableCell>
                      <TableCell>
                        {booking.status === BookingStatus.CANCELLED ? "N/A" : vehicleSchedule && vehicleSchedule.dropoffCheck && !vehicleSchedule.pickupCheck ?
                          "Collection Check Pending" : vehicleSchedule && vehicleSchedule.dropoffCheck && vehicleSchedule.pickupCheck ?
                            "Collection Check Completed" : (
                              <Tooltip title={"Please use mobile app to check the vehicle status to start the booking"}>
                                <Typography>
                                  Delivery Check Pending
                                </Typography>
                              </Tooltip>
                            )}
                      </TableCell>
                      {
                        booking.customerType === CustomerType.BUSINESS && (
                          <TableCell>
                            {driver ? `${driver.firstName} ${driver.lastName}` : "N/A"}
                          </TableCell>
                        )
                      }
                      <TableCell style={{ textAlign: "center" }}>
                        {vehicleSchedule && vehicleSchedule.dropoffCheck && (
                          <Fab
                            size="medium"
                            variant="extended"
                            onClick={() => {
                              if (vehicleSchedule && vehicleSchedule.dropoffCheck) {
                                setPdfId(vehicleSchedule.dropoffCheck.vehicleStatePdfUrl);
                                setBookingSchedule(vehicleSchedule.id);
                                setSendEmail(false);
                                setPdfFileName(
                                  `VehicleConditionReport-${booking.referenceNumber}.pdf`
                                );
                                setPdfTitle(
                                  `Vehicle Condition Report`
                                );
                                setPdfOpen(true);
                                setPdfViewMode(PDFViewMode.VC_REPORT);
                                setVCReportType(VehicleCheckTypes.DROPOFF);
                              }
                            }}
                            title="View Pre-Rental Vehicle Checks"
                          >
                            <VisibilityIcon />
                          </Fab>
                        )}
                        {vehicleSchedule && vehicleSchedule.pickupCheck && (
                          <Fab
                            size="medium"
                            variant="extended"
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                              if (vehicleSchedule && vehicleSchedule.pickupCheck) {
                                setPdfId(vehicleSchedule.pickupCheck.vehicleStatePdfUrl);
                                setBookingSchedule(vehicleSchedule.id);
                                setSendEmail(false);
                                setPdfFileName(
                                  `VehicleConditionReport-${booking.referenceNumber}.pdf`
                                );
                                setPdfTitle(
                                  `Vehicle Condition Report`
                                );
                                setPdfOpen(true);
                                setPdfViewMode(PDFViewMode.VC_REPORT);
                                setVCReportType(VehicleCheckTypes.DROPOFF);
                              }
                            }}
                            title="View Post-Rental Vehicle Checks"
                          >
                            <VisibilityIcon />
                          </Fab>
                        )}
                      </TableCell>
                      <TableCell>
                        {vehicleSchedule && (vehicleSchedule.dropoffCheck || vehicleSchedule.pickupCheck) && (
                          <Fab
                            size={"medium"}
                            variant={"extended"}
                            onClick={() => {
                              setIsSendVehicleChecksEmailClick(true);
                              setSelectedVehicleScheduleId(vehicleSchedule.id)
                            }}
                          >
                            {"Send E-Mail"}
                          </Fab>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid >
            {/* {
              booking?.activeSubscription?.swapsAllowed && (
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <Grid item xs={12}><Typography variant="h3">Vehicle Swap Details</Typography></Grid>
                  <TableContainer >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Vehicle Reg Number</TableCell>
                          <TableCell >No. of swaps allowed</TableCell>
                          <TableCell >No. of swaps availed</TableCell>
                          <TableCell >No. Of swaps Remaining</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{booking?.activeSubscription?.vehicle?.licencePlate}</TableCell>
                          <TableCell>{booking.activeSubscription.swapsAllowed}</TableCell>
                          <TableCell>{booking?.swapRequests?.length || 0}</TableCell>
                          <TableCell>{booking.activeSubscription.swapsAllowed - (booking?.swapRequests?.length || 0)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            } */}
            {
              booking.swapRequests && booking.swapRequests.length > 0 &&
              <Grid item xs={12} style={{ marginTop: 25 }}>
                <Grid container xs={12} alignItems="baseline" justifyContent="space-between">
                  <Grid item xs={2}>
                    <Typography variant="h3">Swap Request History</Typography>
                  </Grid>
                  <Grid item xs={2}><Typography variant="h5">Swaps allowed: <strong>{booking.activeSubscription.swapsAllowed}</strong></Typography></Grid>
                  <Grid item xs={2}><Typography variant="h5">Swaps availed: <strong>{booking?.consumedSwaps?.length || 0}</strong></Typography></Grid>
                  <Grid item xs={2}><Typography variant="h5">Swaps Remaining: <strong>{booking.activeSubscription.swapsAllowed - (booking?.consumedSwaps?.length || 0)}</strong></Typography></Grid>
                </Grid>
                <TableContainer >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>SL. NO</TableCell>
                        <TableCell >REQUESTED ON</TableCell>
                        <TableCell >SWAP DATE</TableCell>
                        <TableCell >STATUS</TableCell>
                        <TableCell >Old Vehicle</TableCell>
                        <TableCell >New Vehicle</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        booking.swapRequests.map((swapRequest: ISwapRequest, index: number) => {
                          return (
                            <>
                              <TableRow
                                onClick={() => {
                                  setSelectedSwapRequest(swapRequest)
                                  setOpenUpdateSwapRequest(true)
                                }}
                                style={{ cursor: "pointer" }}
                                className="row-hover"
                              >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{d.fromISO(swapRequest.createdAt).toFormat("dd/MM/yyyy hh:mm a")}</TableCell>
                                <TableCell>{d.fromISO(swapRequest.dateForSwap).toFormat("dd/MM/yyyy hh:mm a")}</TableCell>
                                <TableCell>{swapRequest.status}</TableCell>
                                <TableCell>{swapRequest.vehicle?.licencePlate}</TableCell>
                                <TableCell>{(swapRequest.status === SwapRequestStatus.APPROVED || swapRequest.status === SwapRequestStatus.COMPLETED) ? swapRequest.vehicleAllocated?.licencePlate : ""}</TableCell>
                                {/* <TableCell >
                                  <Tooltip title="Add Note">
                                    <IconButton
                                      onClick={() => {
                                        setSelectedSwapRequest(swapRequest)
                                        setOpenSwapNotes(true)
                                      }}
                                    >
                                      {loadingBookingSwapData ? <CircularProgress /> : <NoteAddIcon />}
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                                <TableCell >
                                  <Tooltip title={swapRequest.status === "PENDING" ? "Approve" : ""}>
                                    <IconButton
                                      onClick={() => {
                                        setSelectedSwapRequest(swapRequest)
                                        setOpenApproveSwapRequest(true)
                                      }}
                                      disabled={swapRequest.status !== SwapRequestStatus.PENDING}
                                    >
                                      <CheckIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                                <TableCell >
                                  <Tooltip title={"Decline Request"}>
                                    <IconButton
                                      onClick={() => {
                                        setSelectedSwapRequest(swapRequest)
                                        setoOenDeclineSwapRequest(true)
                                       }}
                                      disabled={swapRequest.status !== SwapRequestStatus.PENDING}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell> */}
                              </TableRow>
                            </>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                {
                  openUpdateSwapRequest && (
                    <UpdateVehicleSwapRequest
                      open={openUpdateSwapRequest}
                      swapRequest={selectedSwapRequest}
                      customerName={businessCustomer ? businessCustomer.businessName : ""}
                      booking={booking}
                      onAddNote={onAddSwapNote}
                      onApproveSwapRequest={onApproveSwapRequest}
                      onDeclineSwapRequest={onDeclineSwapRequest}
                      onCompleteSwapRequest={onCompleteSwapRequest}
                      onClose={() => setOpenUpdateSwapRequest(false)}
                      loadingAddNotes={loadingAddNotes}
                      loadingApproveRequest={loadingApproveRequest}
                      loadingCompleteRequest={loadingCompleteRequest}
                      loadingDeclineRequest={loadingDeclineRequest}
                    />
                  )
                }
              </Grid>
            }
          </>
        )
      } else {
        return (
          <>
            <Grid item xs={12}>
              <Typography variant="h3">
                Current Vehicle Details
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <TableContainer>
                <Table aria-label="spanning table">

                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                      >
                        <Typography>Price Group</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography>Make/Model</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography>Registration</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography>VIN</Typography>
                      </TableCell>
                      {ifPCONumberExists() && (
                        <TableCell
                          align="left"

                        >
                          <Typography>PCO Number</Typography>
                        </TableCell>
                      )}
                      <TableCell align="left">
                        <Typography>Mileage Limit</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>End Date</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>Status</Typography>
                      </TableCell>
                      {
                        booking.customerType === CustomerType.BUSINESS && (
                          <TableCell align="left">
                            <Typography>Driver Assigned</Typography>
                          </TableCell>
                        )
                      }
                      <TableCell align="left">
                        <Typography>Vehicle Condition Report</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography></Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {booking?.vehicleGroups?.map(
                      (vehicleGroup: IBookingVehicleGroup, idx: number) => {
                        const vg = vehicleGroup.vehicleGroup as IVehiclePriceGroup;
                        const basePrice = vg.basePrices.find(bp => bp.rateTypeName === booking.rateTypeName);
                        return (
                          <React.Fragment key={idx}>
                            {vehicleGroup?.activeVehicleSchedules?.map(
                              (
                                vehicleSchedule: IBookingSchedule,
                                index: number
                              ) => {
                                const {
                                  vehicle
                                } = vehicleSchedule;

                                const driver = booking.businessCustomer?.approvedDrivers.find((driver => driver?.id === vehicleSchedule.driver?.id));
                                return (
                                  <React.Fragment key={index}>

                                    <TableRow>
                                      {index === 0 && (
                                        <TableCell
                                          rowSpan={
                                            vehicleGroup.activeVehicleSchedules
                                              ?.length
                                          }
                                        >
                                          {vehicleGroup.name ? vehicleGroup.name : vg.name}
                                        </TableCell>
                                      )}
                                      <TableCell>{`${vehicle?.make} ${vehicle.model}`}</TableCell>
                                      <TableCell>
                                        {userState.currentBranch.id !== vehicle.branchDetails?.id ? (
                                          <a
                                            onClick={() => navigate(`/vehicle-state?vehicleId=${vehicle.id}`, { state: { bookingId: booking.id } })}
                                            style={{
                                              color: "rgb(24, 117, 210)",
                                              textDecoration: "none",
                                              cursor: "pointer"
                                            }}
                                          > {vehicle?.licencePlate.toUpperCase()} </a>
                                        ) : (
                                          <span>{vehicle?.licencePlate.toUpperCase()}</span>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {vehicle.vin || "N/A"}
                                      </TableCell>
                                      {ifPCONumberExists() && (
                                        <TableCell>
                                          {vehicle.pcoNumber || "N/A"}
                                        </TableCell>
                                      )}
                                      {
                                        vehicleGroup && vehicleGroup.mileageLimit && basePrice ?
                                          <TableCell>
                                            {getMileageLimitText(vehicleGroup.mileageLimit, basePrice.rateTypeName, distanceUnit)}
                                          </TableCell>
                                          : vehicleGroup?.unlimitedMileage ?
                                            <TableCell>
                                              Unlimited Mileage
                                            </TableCell> :
                                            <TableCell>
                                              N/A
                                            </TableCell>
                                      }
                                      <TableCell>
                                        {getLocalizedDateFormat(country, vehicleSchedule.endDate, DATE_TYPE.EXPANDED)}
                                      </TableCell>
                                      <TableCell>
                                        {vehicle.isGhostVehicle ? <a style={{ color: "red" }}>Virtual Vehicle </a> : booking.status === BookingStatus.CANCELLED ? "N/A" : vehicleSchedule && vehicleSchedule.dropoffCheck && !vehicleSchedule.pickupCheck ?
                                          "Collection Check Pending" : vehicleSchedule && vehicleSchedule.dropoffCheck && vehicleSchedule.pickupCheck ?
                                            "Collection Check Completed" : (
                                              <Tooltip title={"Please use mobile app to check the vehicle status to start the booking"}>
                                                <Typography>
                                                  Delivery Check Pending
                                                </Typography>
                                              </Tooltip>
                                            )}
                                      </TableCell>
                                      {
                                        booking.customerType === CustomerType.BUSINESS && (
                                          <TableCell>
                                            {driver ? `${driver.firstName} ${driver.lastName}` : "N/A"}
                                          </TableCell>
                                        )
                                      }
                                      <TableCell style={{ textAlign: "center" }}>
                                        {vehicleSchedule && vehicleSchedule.dropoffCheck && (
                                          <Fab
                                            size="medium"
                                            variant="extended"
                                            onClick={() => {
                                              if (vehicleSchedule && vehicleSchedule.dropoffCheck) {
                                                setPdfId(vehicleSchedule.dropoffCheck.vehicleStatePdfUrl);
                                                setBookingSchedule(vehicleSchedule.id);
                                                setSendEmail(false);
                                                setPdfFileName(
                                                  `VehicleConditionReport-${booking.referenceNumber}.pdf`
                                                );
                                                setPdfTitle(
                                                  `Vehicle Condition Report`
                                                );
                                                setPdfOpen(true);
                                                setPdfViewMode(PDFViewMode.VC_REPORT);
                                                setVCReportType(VehicleCheckTypes.DROPOFF);
                                              }
                                            }}
                                            title="View Pre-Rental Vehicle Checks"
                                          >
                                            <VisibilityIcon />
                                          </Fab>
                                        )}
                                        {vehicleSchedule && vehicleSchedule.pickupCheck && (
                                          <Fab
                                            size="medium"
                                            variant="extended"
                                            style={{ marginLeft: 5 }}
                                            onClick={() => {
                                              if (vehicleSchedule && vehicleSchedule.pickupCheck) {
                                                setPdfId(vehicleSchedule.pickupCheck.vehicleStatePdfUrl);
                                                setBookingSchedule(vehicleSchedule.id);
                                                setSendEmail(false);
                                                setPdfFileName(
                                                  `VehicleConditionReport-${booking.referenceNumber}.pdf`
                                                );
                                                setPdfTitle(
                                                  `Vehicle Condition Report`
                                                );
                                                setPdfOpen(true);
                                                setPdfViewMode(PDFViewMode.VC_REPORT);
                                                setVCReportType(VehicleCheckTypes.PICKUP);
                                              }
                                            }}
                                            title="View Post-Rental Vehicle Checks"
                                          >
                                            <VisibilityIcon />
                                          </Fab>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {vehicleSchedule && (vehicleSchedule.dropoffCheck || vehicleSchedule.pickupCheck) && (
                                          <Fab
                                            size={"medium"}
                                            variant={"extended"}
                                            onClick={() => {
                                              setIsSendVehicleChecksEmailClick(true);
                                              setSelectedVehicleScheduleId(vehicleSchedule.id)
                                            }}
                                          >
                                            {"Send E-Mail"}
                                          </Fab>
                                        )}
                                      </TableCell>
                                    </TableRow>


                                  </React.Fragment>
                                );
                              }
                            )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid >
          </>
        )
      }
    }
  }

  if (!organisation) {
    return (
      <Grid item xs={12}>
        Unable to load company details
      </Grid>
    );
  }

  if (!booking || cancellationLoading || bookingLoading) {
    return <CircularProgress />;
  }

  if (booking && booking.status === BookingStatus.QUOTE) {
    return (
      <>
        <CircularProgress size={24} style={{ marginRight: "10px" }} />
        <Typography variant="body1" style={{ display: "inline", fontSize: 24 }}>
          Payment Confirmation in progress!
        </Typography>
      </>
    );
  }

  if (booking && booking.status === BookingStatus.CONFIRMATION_IN_PROCESS) {
    if (booking.currentInvoice?.status === "PAID") {
      return (
        <>
          <CircularProgress size={24} style={{ marginRight: "10px" }} />
          <Typography
            variant="body1"
            style={{ display: "inline", fontSize: 24 }}
          >
            {`Payment is confirmed. ${getLocalizedBookingSyntex(country)} Confirmation in progress!`}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <CircularProgress size={24} style={{ marginRight: "10px" }} />
          <Typography
            variant="body1"
            style={{ display: "inline", fontSize: 24 }}
          >
            {getLocalizedBookingSyntex(country)} Confirmation in progress!
          </Typography>
        </>
      );
    }

  }

  return (
    <Grid container item xs={12} style={{ position: 'relative' }}>

      <Hidden smDown>
        {/** Action buttons start here */}
        <Grid item xs={12}>
          <Grid container item xs={12} alignItems="center">
            <Typography variant="h1" color="primary">
              {getLocalizedBookingSyntex(country)}
            </Typography>
            <Box color="white" sx={{ pr: 1 }}></Box>
            <DoubleArrowIcon />
            <Box color="white" sx={{ pl: 1 }}></Box>
            <Typography variant="h1" color="primary">
              {` ${getLocalizedBookingSyntex(country)} Management`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          {booking.status === BookingStatus.REQUIRES_APPROVAL &&
            <Grid container item xs={12} justifyContent="flex-end" spacing={1} sx={{ marginTop: "0.25rem" }}>
              <Grid item>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Approve"
                  onClick={() => {
                    setConfirmationDialogData({
                      description: `Are you sure you want to approve this ${getLocalizedBookingSyntex(country).toLowerCase()}?`,
                      isOpen: true,
                      onCancel: () =>
                        setConfirmationDialogData({
                          ...confirmationDialogData,
                          isOpen: false
                        }),
                      onConfirm: () => {
                        setConfirmationDialogData({
                          ...confirmationDialogData,
                          description: `Are you sure you want to approve this ${getLocalizedBookingSyntex(country).toLowerCase()}?`,
                          title: `Approve ${getLocalizedBookingSyntex(country)}`,
                          isLoading: true,
                          isOpen: true
                        })
                        approveBooking();
                      },
                      title: `Approve ${getLocalizedBookingSyntex(country)}`,
                    })
                  }}
                >
                  Approve
                </Fab>
              </Grid>
              <Grid item>
                <Fab
                  className="blackBackButton"
                  variant="extended"
                  size="medium"
                  aria-label="Decline"
                  onClick={() => {
                    setConfirmationDialogData({
                      description: `Are you sure you want to decline this ${getLocalizedBookingSyntex(country).toLowerCase()}?`,
                      isOpen: true,
                      onCancel: () =>
                        setConfirmationDialogData({
                          ...confirmationDialogData,
                          isOpen: false
                        }),
                      onConfirm: () => {
                        setConfirmationDialogData({
                          ...confirmationDialogData,
                          description: `Are you sure you want to decline this ${getLocalizedBookingSyntex(country).toLowerCase()}?`,
                          title: `Decline ${getLocalizedBookingSyntex(country)}`,
                          isLoading: true,
                          isOpen: true
                        })
                        declineBooking();
                      },
                      title: `Decline ${getLocalizedBookingSyntex(country)}`
                    })
                  }}
                >
                  Decline
                </Fab>
              </Grid>
            </Grid>
          }
          <Grid container item xs={12} justifyContent="flex-end" spacing={1} style={{ marginTop: 10 }}>
            {booking.status === BookingStatus.CONFIRMED &&
              <Grid item>
                <Fab
                  variant="extended"
                  size="medium"
                  type="button"
                  onClick={() => {
                    const scheduleDetails = {

                    }
                    setReplaceVehicle(true)
                  }}
                >
                  Assign Vehicle
                </Fab>
              </Grid>
            }
            {(booking.status === BookingStatus.CONFIRMED ||
              booking.status === BookingStatus.IN_PROGRESS) &&
              <Grid item>
                <Fab
                  variant="extended"
                  size="medium"
                  type="button"
                  onClick={() => createTask()}
                >
                  {"Create Task"}
                </Fab>
              </Grid>
            }
            {(booking.status === BookingStatus.CONFIRMED ||
              booking.status === BookingStatus.IN_PROGRESS ||
              booking.status === BookingStatus.COMPLETED ||
              (booking.isSubscription && booking.status === BookingStatus.REQUIRES_APPROVAL)
            ) && (
                <Grid item>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="Pay"
                    onClick={() => {
                      if (rentalAgreementUrl) {
                        setPdfId(rentalAgreementUrl);
                        setPdfViewMode(PDFViewMode.RENTAL_AGGREMENT);
                        setPdfFileName(
                          booking.isSubscription ? `SubscriptionAgreement-${booking.referenceNumber}.pdf` : `${getLocalizedRASyntex(country, BOOKING_TYPE.RENTAL)}-${booking.referenceNumber}.pdf`
                        );
                        setSendEmail(true);
                        setPdfTitle(
                          `Rental Agreement`
                        );
                        setPdfOpen(true);
                      } else {
                        navigate(
                          `./rental-agreement?booking=${booking.id}`
                        );
                      }
                    }}
                    disabled={!rentalAgreementUrl}
                  >
                    {getLocalizedRASyntex(country, booking.isSubscription ? BOOKING_TYPE.SUBSCRIPTION : BOOKING_TYPE.RENTAL)}
                  </Fab>
                </Grid>
              )}
            {booking.status === BookingStatus.CONFIRMED && (
              <Grid item>
                <Fab
                  variant="extended"
                  size="medium"
                  type="button"
                  disabled={loading}
                  onClick={() => setIsSendEmailClick(true)}
                >
                  {loading && (
                    <CircularProgress
                      size={14}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  {"Send E-Mail"}
                </Fab>
              </Grid>
            )}
            {booking.status !== BookingStatus.CANCELLED && (
              <Grid item>
                {isSendVehicleChecksEmailClick ? (
                  <MailSendBox
                    documentType="Vehicle Condition Report"
                    title="Send Vehicle Condition Reports email to customers"
                    mails={(emails: string[]) => sendVehicleConditionReportEmail(emails)}
                    open={isSendVehicleChecksEmailClick}
                    handleClose={() => setIsSendVehicleChecksEmailClick(false)}
                  />
                ) : (
                  ""
                )}
                {showVehicleChecksMessage ? (
                  <ShowMessage
                    open={showVehicleChecksMessage}
                    sentMails={showVehicleChecksMails}
                    handleClose={() => setShowVehicleChecksMessage(false)}
                  />
                ) : (
                  ""
                )}
              </Grid>
            )}
            {booking.status === BookingStatus.CONFIRMED && (
              <Grid item>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Pay"
                  onClick={() => {
                    const params = new URLSearchParams(location.search);
                    const booking = params.get("booking");
                    navigate(`/update-booking?booking=${booking}&step=0`);
                  }}
                >
                  Update
                </Fab>
              </Grid>
            )}
            {booking.status === BookingStatus.IN_PROGRESS && booking.zeroCostBooking && (
              <Grid item>
                <Tooltip
                  title={`Zero charge ${getLocalizedBookingSyntex(country).toLowerCase()} can not be extended.`}
                >
                  <span>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="Complete"
                      onClick={() => setOpenExtendBookingDialog(true)}
                      disabled={true}
                    >
                      {`Extend ${getLocalizedBookingSyntex(country)}`}
                    </Fab>
                  </span>
                </Tooltip>
              </Grid>
            )}
            {showBookingExtension && (
              <Grid item>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Complete"
                  onClick={() => setOpenExtendBookingDialog(true)}
                  disabled={!canExtend(booking)}
                >
                  {`Extend ${getLocalizedBookingSyntex(country)}`}
                </Fab>
              </Grid>
            )}
            {booking.status === BookingStatus.IN_PROGRESS && (
              <Grid item>
                <Tooltip title={`${getLocalizedBookingSyntex(country)} can be completed only after collection checks have been completed and there is no outstanding payment due`}>
                  <span>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="Complete"
                      disabled={!canComplete() || ifchecksIncomplete()}
                      onClick={() =>
                        setCompleteConfirmationDialogData({
                          description: COMPLETE_DESCRIPTION,
                          isOpen: true,
                          onCancel: () =>
                            setCompleteConfirmationDialogData({
                              ...completeConfirmationDialogData,
                              isOpen: false
                            }),
                          onConfirm: (sendMail) => {
                            completeBooking(sendMail);
                            setCompleteConfirmationDialogData({
                              ...completeConfirmationDialogData,
                              isOpen: false
                            })
                          },
                          title: COMPLETE_TITLE
                        })
                      }
                    >
                      Complete
                    </Fab>
                  </span>
                </Tooltip>
              </Grid>
            )}
            {(booking.status === BookingStatus.QUOTE || booking.status === BookingStatus.CONFIRMED) &&
              <Grid item>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Cancel"
                  onClick={() =>
                    (setDialogOpen(true))
                  }
                  disabled={!canCancel()}
                >
                  Cancel
                </Fab>
              </Grid>
            }
            {booking.status === BookingStatus.IN_PROGRESS && (
              <Grid item>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Early Terminate"
                  onClick={() => {
                    setConfirmationDialogData({
                      description: EARLY_TERMINATION_DESCRIPTION,
                      isOpen: true,
                      confirmText: "YES",
                      cancelText: "NO",
                      onCancel: () =>
                        setConfirmationDialogData({
                          ...confirmationDialogData,
                          isOpen: false
                        }),
                      onConfirm: () => {
                        setConfirmationDialogData({
                          ...confirmationDialogData,
                          isOpen: false
                        })
                        if (booking && booking.status === BookingStatus.CONFIRMED) {
                          setCancellationDialogOpen(true)
                        } else {
                          if (booking.isSubscription) {
                            setSubscriptionEarlyTerminateDialog(true)
                          } else {
                            setEarlyTerminateDialogOpen(true)
                          }
                        }
                      },
                      title: EARLY_TERMINATE_TITLE
                    })
                  }}
                  disabled={!canTerminate(booking)}
                >
                  Early Terminate
                </Fab>
              </Grid>
            )}
          </Grid>
          {replaceVehicle &&
            <VehicleReplacementDialog
              booking={booking}
              vehicleGroups={bookingVgs}
              vehicles={bookingVehicles}
              open={replaceVehicle}
              handleClose={() => {
                setReplaceVehicle(false)
                loadBooking({
                  variables: {
                    id: booking?.id
                  }
                });
              }}
            />
          }


        </Grid >
        {/** Action buttons end here */}
        < Grid container item xs={12} >
          <Box mt={2}></Box>
        </Grid >
        <Grid container item xs={12} spacing={2}>
          {/* Booking Information starts here */}
          <Grid item xs={12}>
            <Paper
              className={classes.root}
            >
              <Grid item xs={12} style={{ display: "flex" }}>
                <Grid item xs={3}>
                  <Typography variant="h3">
                    {`${getLocalizedBookingSyntex(country)} Summary`}
                  </Typography>
                </Grid>
                <Grid container item xs={9} alignItems="center" justifyContent="flex-end" spacing={2}>
                  {booking.lastEventType &&
                    <Grid item>
                      <Typography style={{ color: "red" }}>
                        {booking.lastEventType === BookingEventTypes.EXTENSION &&
                          `Note: This ${getLocalizedBookingSyntex(country).toLowerCase()} has been extended on ${getLocalizedDateFormat(country, booking.lastEventTime, DATE_TYPE.EXPANDED)}${booking.lastEventBy ? ` by ${booking.lastEventBy?.firstName} ${booking.lastEventBy?.lastName}.` : ""}`}
                        {booking.lastEventType === BookingEventTypes.EARLY_TERMINATION &&
                          `Note: This ${getLocalizedBookingSyntex(country).toLowerCase()} has been early terminated on ${getLocalizedDateFormat(country, booking.lastEventTime, DATE_TYPE.EXPANDED)}${booking.lastEventBy ? ` by ${booking.lastEventBy?.firstName} ${booking.lastEventBy?.lastName}.` : ""}`}
                      </Typography>
                    </Grid>
                  }
                  {showTBAToggle &&
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={booking.tba}
                            onClick={(e: any) => {
                              setTbaDialogOpen(true)
                            }}
                            value="tba"
                          />
                        }
                        label={`TBA ${getLocalizedBookingSyntex(country)}`}
                      />
                    </Grid>
                  }
                </Grid>

              </Grid>
              <Grid container item xs={12} style={{ marginTop: 20 }}>
                <Grid item xs={4} spacing={1}>
                  <Grid container item xs={12}>
                    <Grid item>
                      <Typography variant="h6">
                        Confirmation Number:
                      </Typography>
                    </Grid>
                    <Grid item className={classes.summaryLeftMargin}>
                      <Typography variant="body1">
                        {booking.referenceNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} style={{ marginTop: 10 }}>
                    <Grid item>
                      <Typography variant="h6">
                        {`${getLocalizedBookingSyntex(country)} Status:`}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.summaryLeftMargin}>
                      <Typography variant="body1">{BookingStatusText[booking.status]}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} style={{ marginTop: 10 }}>
                    <Grid item>
                      <Typography variant="h6">
                        Payment Status:
                      </Typography>
                    </Grid>
                    <Grid item className={classes.summaryLeftMargin}>
                      {booking.updateInProgress ? <Grid container>
                        <CircularProgress size={14} style={{ color: "white", margin: "0 10px" }} />
                        <Typography variant='body1'>Fetching...</Typography>
                      </Grid> :
                        <Typography variant="body1">
                          {booking.firstInvoice?.status || "N/A"}
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} style={{ marginTop: 10 }}>
                    <Grid item>
                      <Typography variant="h6">
                        Invoice Number:
                      </Typography>
                    </Grid>
                    <Grid item className={classes.summaryLeftMargin}>
                      {booking.updateInProgress ? <Grid container>
                        <CircularProgress size={14} style={{ color: "white", margin: "0 10px" }} />
                        <Typography variant='body1'>Fetching...</Typography>
                      </Grid> :
                        <Typography variant="body1">
                          <a
                            onClick={() => handlerInvoiceClick(booking.firstInvoice?.id)}
                            style={{
                              color: "rgb(24, 117, 210)",
                              textDecoration: "none",
                              cursor: "pointer"
                            }}> {booking.firstInvoice?.invoiceRef || "N/A"} </a>
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                  {booking.businessCustomer?.accountManager && typeof booking.businessCustomer.accountManager !== "string" && (
                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography variant="h6">
                          Account Manager:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography>
                          {`${booking.businessCustomer.accountManager.firstName} ${booking.businessCustomer.accountManager.lastName}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={4} spacing={1}>
                  <Grid container item xs={12}>
                    <Grid item>
                      <Typography variant="h6">
                        Customer Name:
                      </Typography>
                    </Grid>
                    <Grid item className={classes.summaryLeftMargin}>
                      <Link
                        underline="none"
                        href={
                          `${isBusinessCustomerBooking ? `./update-business-customer?businesscustomer=${businessCustomer?.id}`
                            : `./customer-details?customer=${customer?.id}&editable=${true}`}`
                        }
                      >
                        <Typography variant="body1">
                          {`${isBusinessCustomerBooking
                            ? businessCustomer?.businessName || ""
                            : `${customer?.firstName} ${customer?.lastName}`
                            }
                            `}
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} style={{ marginTop: 10 }}>
                    <Grid item>
                      <Typography variant="h6">
                        Email Address:
                      </Typography>
                    </Grid>
                    <Grid item className={classes.summaryLeftMargin}>
                      <Typography variant="body1">{`${isBusinessCustomerBooking
                        ? businessCustomer?.billing.email
                        : customer
                          ? customer.email
                          : ""
                        }`}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} style={{ marginTop: 10 }}>
                    <Grid item>
                      <Typography variant="h6">
                        Contact Number:
                      </Typography>
                    </Grid>
                    <Grid item className={classes.summaryLeftMargin}>
                      <Typography variant="body1">{`${isBusinessCustomerBooking
                        ? businessCustomer?.billing.phoneNumber.phone || ""
                        : customer
                          ? customer.phoneNumber.phone
                          : ""
                        }`}</Typography>
                    </Grid>
                  </Grid>
                  {
                    !isBusinessCustomerBooking &&
                    (<Grid container item xs={12} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography variant="h6">
                          {getLocalizedInsuranceNumberSyntex(country)}:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography variant="body1">{`${customer && customer.nationalInsuranceNumber ? customer.nationalInsuranceNumber
                          : "N/A"
                          }`}</Typography>
                      </Grid>
                    </Grid>
                    )
                  }
                  {
                    booking.customerType === CustomerType.BUSINESS && booking.poNumber && (
                      <Grid container item xs={12} style={{ marginTop: 10 }}>
                        <Grid item>
                          <Typography variant="h6">PO Number:</Typography>
                        </Grid>
                        <Grid item className={classes.summaryLeftMargin}>
                          <Typography variant="body1">{booking.poNumber}</Typography>
                        </Grid>
                      </Grid>
                    )
                  }
                </Grid>
                <Grid item xs={4} spacing={1}>
                  <Grid container item xs={12}>
                    <Grid item>
                      <Typography variant="h6">
                        {booking.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                          ? "Delivery Address, Date & Time:"
                          : "Pick-up Location, Date & Time:"}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.summaryLeftMargin}>
                      <Typography variant="body1">
                        {`${booking.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                          ? booking.pickupOtherLocation.fullAddress
                          : booking.pickupServiceLocation?.name || "N/A"
                          },
                      ${getLocalizedDateFormat(country, booking.pickupDateTime, DATE_TYPE.EXPANDED)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} style={{ marginTop: 10 }}>
                    <Grid item>
                      <Typography variant="h6">
                        {booking.tba ? "Renewal Location, Date & Time:": booking.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                          ? "Collection Address, Date & Time:"
                          : "Drop-off Location, Date & Time:"}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.summaryLeftMargin}>
                      <Typography variant="body1">
                        {`${booking.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                          ? booking.dropoffOtherLocation.fullAddress
                          : booking.dropoffServiceLocation?.name || "N/A"
                          },
                      ${getLocalizedDateFormat(country, booking.dropoffDateTime || "", DATE_TYPE.EXPANDED)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} style={{ marginTop: 10 }}>
                    <Grid item>
                      <Typography variant="h6">
                        Number of Additional Drivers:
                      </Typography>
                    </Grid>
                    <Grid item className={classes.summaryLeftMargin}>
                      <Typography variant="body1">
                        {booking?.approvedDrivers ? booking.approvedDrivers.length : 0}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} ><Box mt={1}></Box></Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* Booking Information ends here */}

          {/* Booking Deposit Information starts here */}
          {
            (![BOOKING_CATEGORY.COURTESY_CAR.toString(), BOOKING_CATEGORY.TEST_DRIVE.toString()].includes(booking.bookingCategory)) && booking.depositDetails && (
              <Grid container item xs={12}>
                <Paper
                  className={classes.root}
                >
                  <Grid container xs={12}>
                    <Grid container item xs={10} style={{ paddingTop: "0.6rem" }}>
                      <Typography variant="h3">
                        Security Deposit Details
                      </Typography>
                    </Grid>
                    {!booking.zeroCostBooking && (
                      <Grid container item xs={2} justifyContent="flex-end">
                        {canCollectDeposit() &&
                          <Fab
                            variant="extended"
                            size="medium"
                            aria-label="Collect Deposit"
                            onClick={() => setDepositPaymentDialogOpen(true)}
                          >
                            Collect Deposit
                          </Fab>
                        }
                      </Grid>
                    )}
                  </Grid>
                  {updateDepositLoading ?
                    <CircularProgress /> :
                    <Grid container xs={12} style={{ marginTop: "1rem" }}>
                      <Grid container xs={12}>
                        <Typography variant="h4">
                          Amount:
                        </Typography>
                        {depositEditable ?
                          <>
                            <Grid xs={3} container style={{ marginLeft: "1rem" }}>
                              <TextField
                                placeholder="Deposit Amount"
                                label="Deposit Amount"
                                name={"depositAmount"}
                                fullWidth
                                style={{
                                  position: "relative",
                                  bottom: "0.4rem",
                                }}
                                InputProps={{
                                  inputComponent: FloatInput as any,
                                  value: depositAmount,
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const val = e.target.value || "0";
                                    setDepositAmount(parseInt(val));
                                  }
                                }}
                                inputProps={{
                                  hasCurrencyPrefix: true,
                                  allowNegative: false
                                }}
                              />
                            </Grid>
                            <CheckIcon
                              onClick={() => {
                                setEditIconVisible(false)
                                setDepositEditable(false)
                                updateBookingDeposit()
                              }}
                              style={{
                                marginLeft: "1rem",
                                cursor: "pointer"
                              }}
                            />
                            <CancelIcon
                              onClick={() => {
                                setDepositAmount(booking.depositDetails?.amount || 0)
                                setEditIconVisible(false)
                                setDepositEditable(false)
                              }}
                              style={{
                                marginLeft: "0.5rem",
                                cursor: "pointer"
                              }}
                            />
                          </> :
                          <span
                            style={{
                              display: "inline-block",
                              position: "relative",
                              bottom: "0.2rem"
                            }}
                            onMouseEnter={() => setEditIconVisible(true)}
                            onMouseLeave={() => setEditIconVisible(false)}
                          >
                            <Typography variant="body1" style={{ marginLeft: "1rem", display: "inline" }}>
                              {toCurrency(booking.depositDetails?.amount || 0, currency, locale)}
                            </Typography>
                            {editIconVisible && canEditDeposit() &&
                              <EditIcon
                                onClick={() => setDepositEditable(true)}
                                style={{
                                  marginLeft: "1rem",
                                  position: "relative",
                                  top: "0.2rem",
                                  cursor: "pointer",
                                  fontSize: "1rem",
                                  display: "inline"
                                }}
                              />
                            }
                          </span>
                        }
                      </Grid>
                      {booking.depositDetails?.payment?.amount > 0 && <Grid container xs={12} style={{ marginTop: "1rem" }}>
                        <Typography variant="h3">
                          Payment Details
                        </Typography>
                        <Table aria-label="spanning table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                <Typography>Amount</Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography>Mode</Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography>Status</Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography>Date</Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography>Actions</Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                {toCurrency(booking.depositDetails.payment.amount || 0, currency, locale)}
                              </TableCell>
                              <TableCell>
                                {`${booking.depositDetails.payment.paymentMode} (${booking.depositDetails.payment.paymentGateway})`}
                              </TableCell>
                              <TableCell>
                                {booking.depositDetails.payment.status}
                              </TableCell>
                              <TableCell>
                                {
                                  getLocalizedDateFormat(country, booking.depositDetails.payment.dateCreated || "", DATE_TYPE.EXPANDED)}
                              </TableCell>
                              <TableCell>
                                {[BOOKING_STATUS.CANCELLED, BOOKING_STATUS.COMPLETED].includes(booking.status) &&
                                  [PaymentStatus.AUTHORISED, PaymentStatus.PAID].includes(booking.depositDetails.payment.status) &&
                                  booking?.depositDetails?.payment?.remainingAmount &&
                                  !booking.depositDetails?.refund?.refundRef ?
                                  <Typography
                                    style={{ cursor: "pointer", color: refundDepositLoading ? "gray" : "#1875d2" }}
                                    onClick={() => {
                                      if (PaymentStatus.AUTHORISED !== booking.depositDetails?.payment.status) {
                                        setConfirmationDialogData({
                                          description: "Are you sure you want to process this refund?",
                                          isOpen: true,
                                          confirmText: "YES",
                                          cancelText: "NO",
                                          onCancel: () =>
                                            setConfirmationDialogData(prevState => {
                                              return { ...prevState, isOpen: false };
                                            }),
                                          onConfirm: () => {
                                            setConfirmationDialogData({
                                              ...confirmationDialogData,
                                              isOpen: true,
                                              description: "Are you sure you want to process this refund?",
                                              title: "Confirm Refund",
                                              isLoading: true,
                                              confirmText: "YES",
                                              cancelText: "NO",
                                            }),
                                              refundDeposit({
                                                variables: {
                                                  bookingId: booking.id
                                                }
                                              });

                                          },
                                          title: "Confirm Refund"
                                        })
                                      }
                                      else {
                                        setConfirmationDialogData({
                                          description: "Are you sure you want to cancel this refund?",
                                          isOpen: true,
                                          confirmText: "YES",
                                          cancelText: "NO",
                                          onCancel: () =>
                                            setConfirmationDialogData(prevState => {
                                              return { ...prevState, isOpen: false };
                                            }),
                                          onConfirm: () => {
                                            setConfirmationDialogData({
                                              ...confirmationDialogData,
                                              isOpen: true,
                                              description: "Are you sure you want to cancel this refund?",
                                              title: "Cancel Refund",
                                              isLoading: true,
                                              confirmText: "YES",
                                              cancelText: "NO",
                                            }),
                                              refundDeposit({
                                                variables: {
                                                  bookingId: booking.id
                                                }
                                              });
                                          },
                                          title: "Cancel Refund"
                                        })
                                      }
                                    }}>
                                    {PaymentStatus.AUTHORISED === booking.depositDetails.payment.status ? "Cancel" : "Refund"}
                                  </Typography> : ""
                                }
                                {booking.depositDetails?.payment?.status === PaymentStatus.NEW && booking.depositDetails?.payment?.paymentMode === PaymentMode.PAY_BY_LINK &&
                                  <Typography
                                    style={{ cursor: "pointer", color: cancelPaymentLinkLoading ? "gray" : "#1875d2" }}
                                    onClick={() => {
                                      setConfirmationDialogData({
                                        description: "Are you sure you want to cancel the payment?",
                                        isOpen: true,
                                        confirmText: "YES",
                                        cancelText: "NO",
                                        onCancel: () =>
                                          setConfirmationDialogData(prevState => {
                                            return { ...prevState, isOpen: false };
                                          }),
                                        onConfirm: () => {
                                          setConfirmationDialogData({
                                            ...confirmationDialogData,
                                            isOpen: true,
                                            description: "Are you sure you want to cancel the payment?",
                                            title: "Cancel Payment",
                                            isLoading: true,
                                            confirmText: "YES",
                                            cancelText: "NO",
                                          })
                                          cancelPaymentLink({
                                            variables: {
                                              paymentId: booking.depositDetails?.payment.id || ""
                                            }
                                          });
                                        },
                                        title: "Cancel Payment"
                                      })
                                    }}>
                                    Cancel
                                  </Typography>
                                }
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>}
                      {booking.depositDetails.adjustedAgainst && booking.depositDetails.amountAdjusted &&
                        <Grid container xs={12} style={{ marginTop: "1rem" }}>
                          <Typography variant="h3">
                            Adjustment Details
                          </Typography>
                          <Table aria-label="spanning table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">
                                  <Typography>Invoice Ref</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Amount Adjusted</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="body1">
                                    <a
                                      onClick={() => handlerInvoiceClick(booking?.depositDetails?.adjustedAgainst?.id || "")}
                                      style={{
                                        color: "rgb(24, 117, 210)",
                                        textDecoration: "none",
                                        cursor: "pointer"
                                      }}>{booking.depositDetails.adjustedAgainst.invoiceRef}</a>
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {toCurrency(booking.depositDetails.amountAdjusted || 0, currency, locale)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      }
                      {
                        booking.depositDetails.refund &&
                        <Grid container xs={12} style={{ marginTop: "1rem" }}>
                          <Typography variant="h3">
                            Refund Details
                          </Typography>
                          <Table aria-label="spanning table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">
                                  <Typography>Refund Ref</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Amount</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Mode</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Status</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  {booking.depositDetails.refund.refundRef}
                                </TableCell>
                                <TableCell>
                                  {toCurrency(booking.depositDetails.refund.amount || 0, currency, locale)}
                                </TableCell>
                                <TableCell>
                                  {`${booking.depositDetails.refund.mode} (${booking.depositDetails.refund.paymentGateway})`}
                                </TableCell>
                                <TableCell>
                                  {booking.depositDetails.refund.status}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      }
                    </Grid>
                  }
                </Paper>
              </Grid>
            )
          }
          {/* Booking Deposit Information ends here */}

          {/* Booking Questions starts here */}
          {((currentBranch && currentBranch.deliveryQuestions && currentBranch.deliveryQuestions.length > 0) ||
            (booking.deliveryQuestions && booking.deliveryQuestions.length > 0)) && (
              <Grid item xs={12} >
                <Paper className={classes.root}>
                  <Grid item xs={12}>
                    <Typography variant="h3">Vehicle Handover Questions</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <DeliveryQuestions booking={booking} />
                  </Grid>
                </Paper>
              </Grid>
            )}
          {/* Booking Questions ends here */}

          {booking.isCoi &&
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Grid item xs={12}>
                  <Typography variant="h3">Please add COI Details</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <AddCoiDetails booking={booking} />
                </Grid>
              </Paper>
            </Grid>
          }

          {/* Booking Questions starts here */}
          {booking.preBookingQuestions && booking.preBookingQuestions.length > 0 && (
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Grid item xs={12}>
                  <Typography variant="h3">{`${getLocalizedBookingSyntex(country)} Questions`}</Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <ViewBookingQuestions
                    preBookingQuestions={booking.preBookingQuestions}
                    deliveryQuestions={booking.deliveryQuestions}
                  />
                </Grid>
              </Paper>
            </Grid>
          )}
          {/* Booking Questions ends here */}

          {/* Booking extension information starts here */}
          {booking.extensions?.length &&
            booking.extensions.some((ext: IBookingExtension) => ext.status === BookingExtensionStatus.CONFIRMED) ?
            <Grid item xs={12}>
              <Paper
                className={classes.root}
              >
                <Grid item xs={12}>
                  <Typography variant="h3">
                    Extension Details
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <TableContainer>
                    <Table aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                          >
                            <Typography>Extension Number</Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                          >
                            <Typography>Confirmation Number</Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                          >
                            <Typography>Invoice Ref</Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                          >
                            <Typography>Start Date</Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                          >
                            <Typography>End Date</Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                          >
                            <Typography>Drop-off Location</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {booking.extensions.map((ext: IBookingExtension) => {
                          if (ext.status === BookingExtensionStatus.CONFIRMED) {
                            return (
                              <TableRow>
                                <TableCell>
                                  {ext.extensionNumber}
                                </TableCell>
                                <TableCell>
                                  {ext.referenceNumber}
                                </TableCell>
                                <TableCell>
                                  {ext.invoice?.invoiceRef || "-"}
                                </TableCell>
                                <TableCell className={classes.tableCellBorder}>
                                  {
                                    getLocalizedDateFormat(country, ext.startDate || "", DATE_TYPE.EXPANDED)
                                  }
                                </TableCell>
                                <TableCell className={classes.tableCellBorder}>
                                  {
                                    getLocalizedDateFormat(country, ext.endDate || "", DATE_TYPE.EXPANDED)
                                  }
                                </TableCell>
                                <TableCell>
                                  {ext.dropoffServiceLocation?.name || ""}
                                </TableCell>
                              </TableRow>
                            )
                          }
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Paper>
            </Grid> :
            <></>
          }
          {/* Booking extension information ends here */}

          {/* Credit Notes Info starts here*/}
          {/* {booking?.creditNotes && booking.creditNotes.length > 0 ?
              <Grid container item xs={12} style={{ paddingTop: 0 }}>
                <Paper className={classes.root}>
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      Refunds
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <TableContainer>
                      <Table aria-label="spanning table">
                        <TableHead>
                          <TableCell
                            align="left"
                          >
                            <Typography>Reference Number</Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                          >
                            <Typography>Invoice Ref</Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                          >
                            <Typography>Invoice Amount</Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                          >
                            <Typography>Refund Amount</Typography>
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          {booking.creditNotes.map((creditNote: ICreditNote) => {
                            return <TableRow>
                              <TableCell>
                                <Typography
                                  className={classes.linkText}
                                  onClick={() => {
                                    setSelectedCreditNote(creditNote);
                                    setRefundDialogOpen(true);
                                  }}
                                >
                                  {creditNote.creditNoteRef}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {creditNote.invoice?.invoiceRef}
                              </TableCell>
                              <TableCell>
                                {toCurrency(creditNote.invoice.confirmedPaidAmount, currency, locale)}
                              </TableCell>
                              <TableCell>
                                {toCurrency(creditNote.totalAmount, currency, locale)}
                              </TableCell>
                            </TableRow>
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Paper>
              </Grid>
              : <></>} */}
          {/* Credit Notes Info ends here*/}

          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Grid item xs={12}>
                <DriversInformation
                  booking={booking}
                  getUpdatedRentalAgreementUrl={getUpdatedRentalAgreementUrl}
                />
              </Grid>
            </Paper>
          </Grid>

          {/* vehicle group details and addons starts here */}
          <Grid item xs={12}>
            <Paper className={classes.root}>
              {
                renderVehicleDetails()
              }
              {
                booking.addonRequirements.length > 0 && (
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Typography variant="h3">
                      Add-Ons
                    </Typography>
                    <Box mt={1}></Box>

                    <TableContainer>
                      <Table aria-label="spanning table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                            >
                              <Typography>Add On</Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              <Typography>Description</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography>Quantity</Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {booking.addonRequirements.map(
                            (item: IAddonRequirement, idx: number) => {
                              return (
                                <TableRow key={idx}>
                                  <TableCell>
                                    {item.displayName?.toUpperCase() ||
                                      item.name?.toUpperCase()}
                                  </TableCell>
                                  <TableCell>{item.addon.description}</TableCell>
                                  <TableCell>{item.quantity}</TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )
              }
            </Paper >
          </Grid >
          {
            booking.vehicleReplacement && booking.vehicleReplacement?.length > 0 && (
              <Grid item xs={12}>
                <Paper style={{ flexGrow: 1, padding: 30 }}>
                  <Grid item xs={12}><Typography variant="h2">Previous Vehicle Details</Typography></Grid>
                  <Grid container item xs={12}>
                    <TableContainer>
                      <Table aria-label="spanning table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                            >
                              <Typography>Price Group</Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              <Typography>Make/Model</Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              <Typography>Registration</Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                            >
                              <Typography>VIN</Typography>
                            </TableCell>
                            {ifPCONumberExists() && (
                              <TableCell
                                align="left"
                              >
                                <Typography>PCO Number</Typography>
                              </TableCell>
                            )}
                            <TableCell align="left">
                              <Typography>Mileage Limit</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography>End Date</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography>Status</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography>Vehicle Condition Report</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography></Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {booking?.vehicleReplacement?.map(
                            (vehicleGroup: IBookingVehicleGroup, idx: number) => {
                              const vg = vehicleGroup.vehicleGroup as IVehiclePriceGroup;
                              const basePrice = vg.basePrices.find(bp => bp.rateTypeName === booking.rateTypeName);
                              return (
                                <React.Fragment key={idx}>
                                  {vehicleGroup?.activeVehicleSchedules?.map(
                                    (
                                      vehicleSchedule: IBookingSchedule,
                                      index: number
                                    ) => {
                                      const {
                                        vehicle
                                      } = vehicleSchedule;

                                      return (
                                        <React.Fragment key={index}>
                                          <TableRow>
                                            {index === 0 && (
                                              <TableCell
                                                rowSpan={
                                                  vehicleGroup.activeVehicleSchedules
                                                    ?.length
                                                }
                                              >
                                                {vehicleGroup.name ? vehicleGroup.name : vg.name}
                                              </TableCell>
                                            )}
                                            <TableCell>{`${vehicle?.make} ${vehicle.model}`}</TableCell>
                                            <TableCell>
                                              {userState.currentBranch.id !== vehicle.branchDetails?.id ? (
                                                <a
                                                  onClick={() => navigate(`/vehicle-state?vehicleId=${vehicle.id}`)}
                                                  style={{
                                                    color: "rgb(24, 117, 210)",
                                                    textDecoration: "none",
                                                    cursor: "pointer"
                                                  }}
                                                > {vehicle?.licencePlate.toUpperCase()} </a>
                                              ) : (
                                                <span>{vehicle?.licencePlate.toUpperCase()}</span>
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {vehicle.vin || "N/A"}
                                            </TableCell>
                                            {ifPCONumberExists() && (
                                              <TableCell>
                                                {vehicle.pcoNumber || "N/A"}
                                              </TableCell>
                                            )}
                                            {
                                              vehicleGroup && vehicleGroup.mileageLimit && basePrice ?
                                                <TableCell>
                                                  {getMileageLimitText(vehicleGroup.mileageLimit, basePrice.rateTypeName, distanceUnit)}
                                                </TableCell>
                                                : vehicleGroup?.unlimitedMileage ?
                                                  <TableCell>
                                                    Unlimited Mileage
                                                  </TableCell> :
                                                  <TableCell>
                                                    N/A
                                                  </TableCell>
                                            }
                                            <TableCell>
                                              {getLocalizedDateFormat(country, vehicleSchedule.endDate, DATE_TYPE.EXPANDED)}
                                            </TableCell>
                                            <TableCell>
                                              {booking.status === BookingStatus.CANCELLED ? "N/A" : vehicleSchedule && vehicleSchedule.dropoffCheck && !vehicleSchedule.pickupCheck ?
                                                "Collection Check Pending" : vehicleSchedule && vehicleSchedule.dropoffCheck && vehicleSchedule.pickupCheck ?
                                                  "Collection Check Completed" : "Delivery Check Pending"}
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center" }}>
                                              {vehicleSchedule && vehicleSchedule.dropoffCheck && (
                                                <Fab
                                                  size="medium"
                                                  variant="extended"
                                                  onClick={() => {
                                                    if (vehicleSchedule && vehicleSchedule.dropoffCheck) {
                                                      setPdfId(vehicleSchedule.dropoffCheck.vehicleStatePdfUrl);
                                                      setSendEmail(false);
                                                      setBookingSchedule(vehicleSchedule.id);
                                                      setPdfFileName(
                                                        `VehicleConditionReport-${booking.referenceNumber}.pdf`
                                                      );
                                                      setPdfTitle(
                                                        `Vehicle Condition Report`
                                                      );
                                                      setPdfOpen(true);
                                                      setPdfViewMode(PDFViewMode.VC_REPORT);
                                                      setVCReportType(VehicleCheckTypes.DROPOFF);
                                                    }
                                                  }}
                                                  title="View Pre-Rental Vehicle Checks"
                                                >
                                                  <VisibilityIcon />
                                                </Fab>
                                              )}
                                              {vehicleSchedule && vehicleSchedule.pickupCheck && (
                                                <Fab
                                                  size="medium"
                                                  variant="extended"
                                                  style={{ marginLeft: 5 }}
                                                  onClick={() => {
                                                    if (vehicleSchedule && vehicleSchedule.pickupCheck) {
                                                      setPdfId(vehicleSchedule.pickupCheck.vehicleStatePdfUrl);
                                                      setBookingSchedule(vehicleSchedule.id);
                                                      setSendEmail(false);
                                                      setPdfFileName(
                                                        `VehicleConditionReport-${booking.referenceNumber}.pdf`
                                                      );
                                                      setPdfTitle(
                                                        `Vehicle Condition Report`
                                                      );
                                                      setPdfOpen(true);
                                                      setPdfViewMode(PDFViewMode.VC_REPORT);
                                                      setVCReportType(VehicleCheckTypes.PICKUP);
                                                    }
                                                  }}
                                                  title="View Post-Rental Vehicle Checks"
                                                >
                                                  <VisibilityIcon />
                                                </Fab>
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {vehicleSchedule && (vehicleSchedule.dropoffCheck || vehicleSchedule.pickupCheck) && (
                                                <Fab
                                                  size={"medium"}
                                                  variant={"extended"}
                                                  onClick={() => {
                                                    setIsSendVehicleChecksEmailClick(true);
                                                    setSelectedVehicleScheduleId(vehicleSchedule.id)
                                                  }}
                                                >
                                                  {"Send E-Mail"}
                                                </Fab>
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </React.Fragment>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid >
                </Paper>
              </Grid>
            )
          }
          {/* vehicle group details and addons end here */}

          {/* {vehicle state images starts here} */}
          {consumerImages.length ? <Grid item>
            <Paper className={classes.root}>
              <Grid item xs={12} style={{ marginBottom: 1 }}>
                <Typography variant="h3">
                  Vehicle State Images
                </Typography>
              </Grid>
              <Grid container item xs={12}>

                {consumerImages.map((item) => {
                  return (
                    <Grid container xs={12}>
                      <Grid item xs={12} style={{ marginTop: '5px' }}>
                        <Typography>
                          Registration Number: {item.licencePlate}
                        </Typography>
                      </Grid>

                      {item.images?.pickupImages?.length ?
                        (<>
                          <Grid style={{ marginTop: '10px' }}>
                            <Typography>At the time of pickup :</Typography>
                          </Grid>
                          <Grid container xs={12}>
                            <Grid container xs={11}>
                              {item.images.pickupImages.map((img, index) => {
                                if (index >= currentPreviewIndex && index < currentPreviewIndex + maxCounter) {
                                  return (
                                    <React.Fragment key={index}>
                                      <div className={classes.imageContainer}>
                                        <a href={img} target="_blank">
                                          <img src={img} className={classes.imageStyle} alt="" />
                                        </a>
                                      </div>
                                    </React.Fragment>
                                  )
                                } else {
                                  return null;
                                }
                              })}

                            </Grid>
                            {item.images.pickupImages.length > maxCounter && <>
                              <Grid item xs={1}>
                                <Grid container className={classes.sliderContainer}>
                                  <Button onClick={goLeft} disabled={currentPreviewIndex === 0}>
                                    <ChevronLeftIcon className={classes.iconStyle} />
                                  </Button>
                                  <Button onClick={goRight} disabled={currentPreviewIndex + maxCounter >= item.images?.pickupImages?.length}>
                                    <ChevronRightIcon className={classes.iconStyle} />
                                  </Button>
                                </Grid>
                              </Grid>
                            </>}
                          </Grid>
                        </>
                        ) : <></>
                      }

                      {/* images after return  */}
                      {item.images?.returnImages?.length ?
                        (<>
                          <Grid style={{ marginTop: '10px' }}>
                            <Typography>At the time of return :</Typography>
                          </Grid>
                          <Grid container xs={12}>
                            <Grid container xs={11}>
                              {item.images.returnImages.map((img, index) => {
                                if (index >= currentReturnPreviewIndex && index < currentReturnPreviewIndex + maxCounter) {
                                  return (
                                    <React.Fragment key={index}>
                                      <div className={classes.imageContainer}>
                                        <a href={img} target="_blank">
                                          <img src={img} className={classes.imageStyle} alt="" />
                                        </a>
                                      </div>
                                    </React.Fragment>
                                  )
                                } else {
                                  return null;
                                }
                              })}
                            </Grid>
                            {item.images.returnImages.length > maxCounter && <>
                              <Grid item xs={1}>
                                <Grid container className={classes.sliderContainer}>
                                  <Button onClick={goLeftReturn} disabled={currentReturnPreviewIndex === 0}>
                                    <ChevronLeftIcon className={classes.iconStyle} />
                                  </Button>
                                  <Button onClick={goRightReturn} disabled={currentReturnPreviewIndex + maxCounter >= item.images?.returnImages?.length}>
                                    <ChevronRightIcon className={classes.iconStyle} />
                                  </Button>
                                </Grid>
                              </Grid>
                            </>}
                          </Grid>
                        </>
                        ) : <></>
                      }
                    </Grid>
                  )
                })}
              </Grid>
            </Paper>
          </Grid> : <></>}
          {/* {vehicle state images ends here} */}

          {/* Payment information starts here */}
          {
            ![BOOKING_CATEGORY.COURTESY_CAR.toString(), BOOKING_CATEGORY.TEST_DRIVE.toString()].includes(booking.bookingCategory) && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  <Grid item xs={12}>
                    <PaymentInfo
                      booking={booking}
                      handlerInvoiceClick={handlerInvoiceClick}
                    />
                  </Grid>
                </Paper>
              </Grid>
            )
          }
          {/* Payment information ends here */}

          {/* notes and note from website starts here */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Paper className={classes.root}>
              <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>
                  Internal Notes(Private)
                </Typography>
              </Grid>
              {booking.noteFromWebsite && booking.noteFromWebsite.description && (
                <React.Fragment>
                  <Grid item xs={12} style={{ marginTop: 10, marginBottom: 16, fontSize: "14px", fontFamily: "sans-serif" }}>
                    <div style={{ marginTop: 16 }}>
                      <p style={{ margin: 0 }}>
                        <b style={{ fontSize: 16 }}>{`${isBusinessCustomerBooking
                          ? businessCustomer?.businessName || ""
                          : `${customer?.firstName} ${customer?.lastName}`
                          }`}</b> |
                        <span style={{ fontSize: 12, marginLeft: 4 }}>{getLocalizedDateFormat(country, booking.noteFromWebsite.createdAt || "", DATE_TYPE.EXPANDED)}</span>
                      </p>
                      <span style={{ margin: 0, marginBottom: 5 }}>{booking.noteFromWebsite.description}</span>
                    </div>
                  </Grid>
                  <NuvvenDivider noMargin={true} />
                </React.Fragment>
              )}
              <Grid item xs={12} style={{ marginTop: 10, fontSize: "14px", fontFamily: "sans-serif" }}>
                {notes && notes.length > 0 && notes.map((note: IBookingNote) => (
                  <div style={{ marginTop: 16 }}>
                    <p style={{ margin: 0 }}>
                      <b style={{ fontSize: 16 }}>{note.createdBy.firstName} {note.createdBy.lastName}</b> |
                      <span style={{ fontSize: 12, marginLeft: 4 }}>{getLocalizedDateFormat(country, note.createdDate || "", DATE_TYPE.EXPANDED)}</span>
                    </p>
                    <span style={{ margin: 0, marginBottom: 5 }}>{note.description}</span>
                  </div>
                ))}
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <TextField
                  style={{ width: "100%" }}
                  label="Note"
                  name={"note.description"}
                  multiline
                  rows={3}
                  inputProps={{ maxLength: 300 }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setNote({
                      ...note,
                      description: event.target.value,
                      createdBy: userState && userState.id ? userState.id : "",
                      createdDate: new Date().toISOString()
                    });
                  }}
                  variant="outlined"
                  value={note?.description}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Fab
                  variant="extended"
                  size="medium"
                  onClick={() => {
                    addBookingNote({
                      variables: {
                        bookingId: booking.id,
                        note
                      }
                    });
                  }}
                  disabled={!note?.description}
                >
                  Add
                </Fab>
              </Grid>
            </Paper>
          </Grid>
          {/* Notes and Note from website end here */}

          {/* Additional information starts here */}
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>
                  Additional Customer Information(Printed on Rental Agreement)
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <TextField
                  style={{ width: "100%" }}
                  label="Additional Customer Information"
                  name={"note.description"}
                  multiline
                  rows={5}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setEditAdditionalInformation(true);
                    setAdditionalInformation({
                      ...additionalInformation,
                      description: event.target.value
                    });
                  }}
                  variant="outlined"
                  value={additionalInformation?.description}
                  disabled={booking.status !== BookingStatus.CONFIRMED}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Fab
                  variant="extended"
                  size="medium"
                  onClick={() => {
                    setEditAdditionalInformation(false);
                    addBookingAdditionalInformation({
                      variables: {
                        bookingId: booking.id,
                        additionalInformation: additionalInformation?.description
                      }
                    });
                  }}
                  disabled={!additionalInformation?.description || booking.status !== BookingStatus.CONFIRMED}
                >
                  Save
                </Fab>
              </Grid>
            </Paper>
          </Grid>
          {/* Additional information ends here */}

          {/* Additional TNC starts here */}
          <Grid container item xs={12}>
            <Paper className={classes.root}>
              <Grid item xs={12}>
                <Typography variant="h3" gutterBottom>
                  Additional Terms And Conditions
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <TextField
                  style={{ width: "100%" }}
                  label="Additional Terms And Conditions"
                  name={"additionalTnc"}
                  multiline
                  rows={5}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setEditAdditionalTnc(true);
                    setAdditionalTnc(event.target.value)
                  }}
                  variant="outlined"
                  value={additionalTnc}
                  disabled={booking.status !== BookingStatus.CONFIRMED}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Fab
                  variant="extended"
                  size="medium"
                  onClick={() => {
                    setEditAdditionalTnc(false);
                    addBookingAdditionalTnc({
                      variables: {
                        bookingId: booking.id,
                        additionalTnc: additionalTnc
                      }
                    });
                  }}
                  disabled={!additionalTnc || booking.status !== BookingStatus.CONFIRMED}
                >
                  Save
                </Fab>
              </Grid>
            </Paper>
          </Grid>
          {/* Additional TNC ends here */}

        </Grid>
      </Hidden>

      {/* Everything underneath is new mobile friendly design code */}
      <Hidden smUp implementation="css" >
        <Grid container item xs={12} spacing={1} style={{ marginTop: 12, display: "flex", width: "calc(100vw - 44px)" }}>
          {booking.status !== BookingStatus.CANCELLED && (
            <Grid item>
              {isSendVehicleChecksEmailClick ? (
                <MailSendBox
                  documentType="Vehicle Condition Report"
                  title="Send Vehicle Condition Reports email to customers"
                  mails={(emails: string[]) => sendVehicleConditionReportEmail(emails)}
                  open={isSendVehicleChecksEmailClick}
                  handleClose={() => setIsSendVehicleChecksEmailClick(false)}
                />
              ) : (
                ""
              )}
              {showVehicleChecksMessage ? (
                <ShowMessage
                  open={showVehicleChecksMessage}
                  sentMails={showVehicleChecksMails}
                  handleClose={() => setShowVehicleChecksMessage(false)}
                />
              ) : (
                ""
              )}
            </Grid>
          )}
          <Box className={classes.expansionPanel} >
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {booking.lastEventType ? <>
                  <Grid container item xs={12} style={{ display: "flex" }}>
                    <Grid item xs={12}>
                      <Typography variant="h3">
                        Booking Summary
                      </Typography>
                    </Grid>
                    <Grid item xs={12} alignItems="center" justifyContent="flex-end">
                      <Typography style={{ color: "red" }}>
                        {booking.lastEventType === BookingEventTypes.EXTENSION &&
                          `Note: This booking has been extended on ${d.fromISO(booking.lastEventTime).toFormat("dd/MM/yyyy, h:mm a")}${booking.lastEventBy ? ` by ${booking.lastEventBy?.firstName} ${booking.lastEventBy?.lastName}.` : ""}`}
                        {booking.lastEventType === BookingEventTypes.EARLY_TERMINATION &&
                          `Note: This booking has been early terminated on ${d.fromISO(booking.lastEventTime).toFormat("dd/MM/yyyy, h:mm a")}${booking.lastEventBy ? ` by ${booking.lastEventBy?.firstName} ${booking.lastEventBy?.lastName}.` : ""}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </> : <>
                  <Grid item xs={12}>
                    <Typography variant="h3">
                      Booking Summary
                    </Typography>
                  </Grid>
                </>}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container item xs={12}>
                  <Grid item xs={12} spacing={1}>
                    <Grid container item xs={12}>
                      <Grid item>
                        <Typography variant="h6" >
                          Confirmation Number:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography variant="body1">
                          {booking.referenceNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography variant="h6">
                          Booking Status:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography variant="body1">{BookingStatusText[booking.status]}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography variant="h6">
                          Payment Status:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography variant="body1">
                          {booking.firstInvoice?.status || "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography variant="h6">
                          Invoice Number:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography variant="body1">
                          <a
                            onClick={() => handlerInvoiceClick(booking.firstInvoice?.id)}
                            style={{
                              color: "rgb(24, 117, 210)",
                              textDecoration: "none",
                              cursor: "pointer"
                            }}> {booking.firstInvoice?.invoiceRef || "N/A"} </a>
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} style={{ marginTop: 10 }}>
                        <Grid item>
                          <Typography variant="h6">
                            Customer Name:
                          </Typography>
                        </Grid>
                        <Grid item className={classes.summaryLeftMargin}>
                          <Link
                            href={
                              `${isBusinessCustomerBooking ? `./update-business-customer?businesscustomer=${businessCustomer?.id}`
                                : `./customer-details?customer=${customer?.id}&editable=${true}`}`
                            }
                          >
                            <Typography variant="body1">
                              {`${isBusinessCustomerBooking
                                ? businessCustomer?.businessName || ""
                                : `${customer?.firstName} ${customer?.lastName}`
                                }
                            `}
                            </Typography>
                          </Link>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} style={{ marginTop: 10 }}>
                        <Grid item>
                          <Typography variant="h6">
                            Email Address:
                          </Typography>
                        </Grid>
                        <Grid item className={classes.summaryLeftMargin}>
                          <Typography variant="body1">{`${isBusinessCustomerBooking
                            ? businessCustomer?.billing.email
                            : customer
                              ? customer.email
                              : ""
                            }`}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} style={{ marginTop: 10 }}>
                        <Grid item>
                          <Typography variant="h6">
                            Contact Number:
                          </Typography>
                        </Grid>
                        <Grid item className={classes.summaryLeftMargin}>
                          <Typography variant="body1">{`${isBusinessCustomerBooking
                            ? businessCustomer?.billing.phoneNumber.phone || ""
                            : customer
                              ? customer.phoneNumber.phone
                              : ""
                            }`}</Typography>
                        </Grid>
                      </Grid>
                      {
                        !isBusinessCustomerBooking &&
                        (<Grid container item xs={12} style={{ marginTop: 10 }}>
                          <Grid item>
                            <Typography variant="h6">
                              {getLocalizedInsuranceNumberSyntex(country)}:
                            </Typography>
                          </Grid>
                          <Grid item className={classes.summaryLeftMargin}>
                            <Typography variant="body1">{`${customer && customer.nationalInsuranceNumber ? customer.nationalInsuranceNumber
                              : "N/A"
                              }`}</Typography>
                          </Grid>
                        </Grid>
                        )
                      }
                      {
                        booking.customerType === CustomerType.BUSINESS && booking.poNumber && (
                          <Grid container item xs={12} style={{ marginTop: 10 }}>
                            <Grid item>
                              <Typography variant="h6">PO Number:</Typography>
                            </Grid>
                            <Grid item className={classes.summaryLeftMargin}>
                              <Typography variant="body1">{booking.poNumber}</Typography>
                            </Grid>
                          </Grid>
                        )
                      }
                      <Grid container item xs={12} style={{ marginTop: 10 }}>
                        <Grid item>
                          <Typography variant="h6">
                            {booking.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                              ? "Delivery Address, Date & Time:"
                              : "Pick-up Location, Date & Time:"}
                          </Typography>
                        </Grid>
                        <Grid item className={classes.summaryLeftMargin}>
                          <Typography variant="body1">
                            {`${booking.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                              ? booking.pickupOtherLocation.fullAddress
                              : booking.pickupServiceLocation?.name || "N/A"
                              },
                      ${getLocalizedDateFormat(country, booking.pickupDateTime || "", DATE_TYPE.EXPANDED)}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={12} style={{ marginTop: 10 }}>
                        <Grid item>
                          <Typography variant="h6">
                            {booking.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                              ? "Collection Address, Date & Time:"
                              : "Drop-off Location, Date & Time:"}
                          </Typography>
                        </Grid>
                        <Grid item className={classes.summaryLeftMargin}>
                          <Typography variant="body1">
                            {`${booking.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                              ? booking.dropoffOtherLocation.fullAddress
                              : booking.dropoffServiceLocation?.name || "N/A"
                              },
                      ${getLocalizedDateFormat(country, booking?.dropoffDateTime || "", DATE_TYPE.EXPANDED)}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      {booking.dropoffDateTime !== booking.initialDropoffDateTime ?
                        <Grid container item xs={12} style={{ marginTop: 10 }}>
                          <Grid item>
                            <Typography variant="h6">
                              Previous Drop-off Time:
                            </Typography>
                          </Grid>
                          <Grid item className={classes.summaryLeftMargin}>
                            <Typography variant="body1">
                              {getLocalizedDateFormat(country, booking?.initialDropoffDateTime || "", DATE_TYPE.EXPANDED)}
                            </Typography>
                          </Grid>
                        </Grid>
                        : <></>}
                      <Grid container item xs={12} style={{ marginTop: 10 }}>
                        <Grid item>
                          <Typography variant="h6">
                            Number of Additional Drivers:
                          </Typography>
                        </Grid>
                        <Grid item className={classes.summaryLeftMargin}>
                          <Typography variant="body1">
                            {booking?.approvedDrivers ? booking.approvedDrivers.length : 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box className={classes.expansionPanel}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h3">Security Deposit Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Booking Deposit Information starts here */}
                {booking.depositDetails && <Grid container item xs={12} style={{ paddingTop: 0 }}>
                  <Grid container xs={12}>
                    {!booking.zeroCostBooking && (
                      <Grid container item xs={12} justifyContent="flex-end">
                        {canCollectDeposit() &&
                          <Fab
                            variant="extended"
                            size="medium"
                            aria-label="Collect Deposit"
                            onClick={() => setDepositPaymentDialogOpen(true)}
                          >
                            Collect Deposit
                          </Fab>
                        }
                      </Grid>
                    )}
                  </Grid>
                  {updateDepositLoading ?
                    <CircularProgress /> :
                    <Grid container xs={12} style={{ marginTop: "1rem" }}>
                      <Grid container xs={12}>
                        <Typography variant="h4">
                          Amount:
                        </Typography>
                        {depositEditable ?
                          <>
                            <Grid xs={3} container style={{ marginLeft: "1rem" }}>
                              <TextField
                                placeholder="Deposit Amount"
                                label="Deposit Amount"
                                name={"depositAmount"}
                                fullWidth
                                style={{
                                  position: "relative",
                                  bottom: "0.4rem",
                                }}
                                InputProps={{
                                  inputComponent: FloatInput as any,
                                  value: depositAmount,
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const val = e.target.value || "0";
                                    setDepositAmount(parseInt(val));
                                  }
                                }}
                                inputProps={{
                                  hasCurrencyPrefix: true,
                                  allowNegative: false
                                }}
                              />
                            </Grid>
                            <CheckIcon
                              onClick={() => {
                                setEditIconVisible(false)
                                setDepositEditable(false)
                                updateBookingDeposit()
                              }}
                              style={{
                                marginLeft: "1rem",
                                cursor: "pointer"
                              }}
                            />
                            <CancelIcon
                              onClick={() => {
                                setDepositAmount(booking.depositDetails?.amount || 0)
                                setEditIconVisible(false)
                                setDepositEditable(false)
                              }}
                              style={{
                                marginLeft: "0.5rem",
                                cursor: "pointer"
                              }}
                            />
                          </> :
                          <span
                            style={{
                              display: "inline-block",
                              position: "relative",
                              bottom: "0.2rem"
                            }}
                            onMouseEnter={() => setEditIconVisible(true)}
                            onMouseLeave={() => setEditIconVisible(false)}
                          >
                            <Typography variant="body1" style={{ marginLeft: "1rem", display: "inline" }}>
                              {toCurrency(booking.depositDetails?.amount || 0, currency, locale)}
                            </Typography>
                            {editIconVisible && canEditDeposit() &&
                              <EditIcon
                                onClick={() => setDepositEditable(true)}
                                style={{
                                  marginLeft: "1rem",
                                  position: "relative",
                                  top: "0.2rem",
                                  cursor: "pointer",
                                  fontSize: "1rem",
                                  display: "inline"
                                }}
                              />
                            }
                          </span>
                        }
                      </Grid>
                      {booking.depositDetails?.payment?.amount > 0 && <Grid container xs={12} style={{ marginTop: "1rem" }}>
                        <Typography variant="h3">
                          Payment Details
                        </Typography>
                        <Paper className="deposit-details-responsive-table">
                          <Table aria-label="spanning table" >
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">
                                  <Typography>Amount</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Mode</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Status</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Date</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Actions</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  {toCurrency(booking.depositDetails.payment.amount || 0, currency, locale)}
                                </TableCell>
                                <TableCell>
                                  {`${booking.depositDetails.payment.paymentMode} (${booking.depositDetails.payment.paymentGateway})`}
                                </TableCell>
                                <TableCell>
                                  {booking.depositDetails.payment.status}
                                </TableCell>
                                <TableCell>
                                  {
                                    getLocalizedDateFormat(country, booking.depositDetails.payment.dateCreated || "", DATE_TYPE.EXPANDED)
                                  }
                                </TableCell>
                                <TableCell>
                                  {[BOOKING_STATUS.CANCELLED, BOOKING_STATUS.COMPLETED].includes(booking.status) &&
                                    [PaymentStatus.AUTHORISED, PaymentStatus.PAID].includes(booking.depositDetails.payment.status) &&
                                    booking?.depositDetails?.payment?.remainingAmount &&
                                    !booking.depositDetails?.refund?.refundRef ?
                                    <Fab
                                      size="medium"
                                      variant="extended"
                                      onClick={() => {
                                        refundDeposit({
                                          variables: {
                                            bookingId: booking.id
                                          }
                                        })
                                      }}
                                    >
                                      {refundDepositLoading && (
                                        <CircularProgress
                                          size={14}
                                          style={{ marginRight: "10px" }}
                                        />
                                      )}
                                      {PaymentStatus.AUTHORISED === booking.depositDetails.payment.status ? "Cancel" : "Refund"}
                                    </Fab> : "N/A"
                                  }
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Paper>
                      </Grid>}
                      {booking.depositDetails.adjustedAgainst && booking.depositDetails.amountAdjusted &&
                        <Grid container xs={12} style={{ marginTop: "1rem" }}>
                          <Typography variant="h3">
                            Adjustment Details
                          </Typography>
                          <Table aria-label="spanning table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">
                                  <Typography>Invoice Ref</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Amount Adjusted</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="body1">
                                    <a
                                      onClick={() => handlerInvoiceClick(booking?.depositDetails?.adjustedAgainst?.id || "")}
                                      style={{
                                        color: "rgb(24, 117, 210)",
                                        textDecoration: "none",
                                        cursor: "pointer"
                                      }}>{booking.depositDetails.adjustedAgainst.invoiceRef}</a>
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {toCurrency(booking.depositDetails.amountAdjusted || 0, currency, locale)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      }
                      {
                        booking.depositDetails.refund &&
                        <Grid container xs={12} style={{ marginTop: "1rem" }}>
                          <Typography variant="h3">
                            Refund Details
                          </Typography>
                          <Table aria-label="spanning table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">
                                  <Typography>Refund Ref</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Amount</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Mode</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography>Status</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  {booking.depositDetails.refund.refundRef}
                                </TableCell>
                                <TableCell>
                                  {toCurrency(booking.depositDetails.refund.amount || 0, currency, locale)}
                                </TableCell>
                                <TableCell>
                                  {`${booking.depositDetails.refund.mode} (${booking.depositDetails.refund.paymentGateway})`}
                                </TableCell>
                                <TableCell>
                                  {booking.depositDetails.refund.status}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      }
                    </Grid>
                  }
                </Grid>}
                {/* Booking Deposit Information ends here */}
              </AccordionDetails>
            </Accordion>
          </Box>

          {((currentBranch && currentBranch.deliveryQuestions && currentBranch.deliveryQuestions.length > 0) ||
            (booking.deliveryQuestions && booking.deliveryQuestions.length > 0)) && (
              <Box className={classes.expansionPanel} >
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h3">Vehicle Handover Questions</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <DeliveryQuestions booking={booking} />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
          {booking.preBookingQuestions && booking.preBookingQuestions.length > 0 && (
            <Box className={classes.expansionPanel} >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h3">Booking Questions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <ViewBookingQuestions
                      preBookingQuestions={booking.preBookingQuestions}
                      deliveryQuestions={booking.deliveryQuestions}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          {booking.extensions?.length &&
            booking.extensions.some((ext: IBookingExtension) => ext.status === BookingExtensionStatus.CONFIRMED) ?
            <Box className={classes.expansionPanel} >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h3">Extension Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    {booking.extensions.map((ext: IBookingExtension, index: number) => {
                      if (ext.status === BookingExtensionStatus.CONFIRMED) {
                        return (
                          <Grid item xs={12}>
                            <Grid container
                              key={index}
                              style={{
                                background: "#f5f5f5",
                                borderRadius: 5,
                                padding: 20
                              }}>
                              <Grid container item xs={12}>
                                <Grid item>
                                  <Typography variant="h6">
                                    Extension Number:
                                  </Typography>
                                </Grid>
                                <Grid item className={classes.summaryLeftMargin}>
                                  <Typography variant="body1">{ext.extensionNumber}</Typography>
                                </Grid>
                              </Grid>
                              <Grid container item xs={12} style={{ marginTop: 10 }}>
                                <Grid item>
                                  <Typography variant="h6">
                                    Confirmation Number:
                                  </Typography>
                                </Grid>
                                <Grid item className={classes.summaryLeftMargin}>
                                  <Typography variant="body1">{ext.referenceNumber}</Typography>
                                </Grid>
                              </Grid>
                              {
                                ext.invoice?.invoiceRef &&
                                <Grid container item xs={12} style={{ marginTop: 10 }}>
                                  <Grid item>
                                    <Typography variant="h6">
                                      Invoice Ref:
                                    </Typography>
                                  </Grid>
                                  <Grid item className={classes.summaryLeftMargin}>
                                    <Typography variant="body1">{ext.invoice.invoiceRef}</Typography>
                                  </Grid>
                                </Grid>
                              }
                              <Grid container item xs={12} style={{ marginTop: 10 }}>
                                <Grid item>
                                  <Typography variant="h6">
                                    Start Date:
                                  </Typography>
                                </Grid>
                                <Grid item className={classes.summaryLeftMargin}>
                                  <Typography variant="body1">
                                    {
                                      getLocalizedDateFormat(country, ext.startDate || "", DATE_TYPE.EXPANDED)
                                    }</Typography>
                                </Grid>
                              </Grid>
                              <Grid container item xs={12} style={{ marginTop: 10 }}>
                                <Grid item>
                                  <Typography variant="h6">
                                    End Date:
                                  </Typography>
                                </Grid>
                                <Grid item className={classes.summaryLeftMargin}>
                                  <Typography variant="body1">
                                    {
                                      getLocalizedDateFormat(country, ext.endDate || "", DATE_TYPE.EXPANDED)
                                    }</Typography>
                                </Grid>
                              </Grid>
                              {
                                ext.dropoffServiceLocation?.name &&
                                <Grid container item xs={12} style={{ marginTop: 10 }}>
                                  <Grid item>
                                    <Typography variant="h6">
                                      Drop-off Location:
                                    </Typography>
                                  </Grid>
                                  <Grid item className={classes.summaryLeftMargin}>
                                    <Typography variant="body1">{ext.dropoffServiceLocation.name}</Typography>
                                  </Grid>
                                </Grid>
                              }
                            </Grid>
                          </Grid>
                        )
                      }
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box> :
            <></>
          }
          {booking?.creditNotes && booking.creditNotes.length > 0 ?
            <Box className={classes.expansionPanel} >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h3">Credit Notes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container xs={12} spacing={1}>
                    {booking.creditNotes.map((creditNote: ICreditNote, index: number) => {
                      return (
                        <Grid item xs={12}>
                          <Grid container
                            key={index}
                            style={{
                              background: "#f5f5f5",
                              borderRadius: 5,
                              padding: 20
                            }}>
                            <Grid container item xs={12}>
                              <Grid item>
                                <Typography variant="h6">
                                  Reference Number:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1" className={classes.linkText}
                                  onClick={() => {
                                    setSelectedCreditNote(creditNote);
                                    setRefundDialogOpen(true);
                                  }}>{creditNote.creditNoteRef}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Invoice Ref:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">{creditNote.invoice?.invoiceRef}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Invoice Amount:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">{toCurrency(creditNote.invoice.confirmedPaidAmount, currency, locale)}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop: 10 }}>
                              <Grid item>
                                <Typography variant="h6">
                                  Refund Amount:
                                </Typography>
                              </Grid>
                              <Grid item className={classes.summaryLeftMargin}>
                                <Typography variant="body1">{toCurrency(creditNote.totalAmount, currency, locale)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            : <></>}
          <Box className={classes.expansionPanel} >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h3">Drivers</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DriversInformation
                  booking={booking}
                  getUpdatedRentalAgreementUrl={getUpdatedRentalAgreementUrl}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box className={classes.expansionPanel} >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h3">Vehicle Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12} spacing={1}>
                  {booking?.vehicleGroups?.map(
                    (vehicleGroup: IBookingVehicleGroup, idx: number) => {
                      const vg = vehicleGroup.vehicleGroup as IVehiclePriceGroup;
                      const basePrice = vg.basePrices.find(bp => bp.rateTypeName === booking.rateTypeName);
                      return (
                        <React.Fragment key={idx}>
                          {vehicleGroup?.activeVehicleSchedules?.map(
                            (
                              vehicleSchedule: IBookingSchedule,
                              index: number
                            ) => {
                              const {
                                vehicle,
                                vehicleStatus,
                              } = vehicleSchedule;
                              const checkStatusObj = isVehicleChecked(
                                vehicleStatus
                              );
                              const driver = booking.businessCustomer?.approvedDrivers.find((driver => driver?.id === vehicleSchedule.driver?.id));
                              return (
                                <Grid item xs={12}>
                                  <Grid container
                                    key={index}
                                    style={{
                                      background: "#f5f5f5",
                                      borderRadius: 5,
                                      padding: 20
                                    }}>
                                    <Grid container item xs={12}>
                                      <Grid item>
                                        <Typography variant="h6">
                                          Price Group:
                                        </Typography>
                                      </Grid>
                                      <Grid item className={classes.summaryLeftMargin}>
                                        <Typography variant="body1">
                                          {vg.name}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                                      <Grid item>
                                        <Typography variant="h6">
                                          Make/Model:
                                        </Typography>
                                      </Grid>
                                      <Grid item className={classes.summaryLeftMargin}>
                                        <Typography variant="body1">
                                          {`${vehicle?.make} ${vehicle.model}`}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                                      <Grid item>
                                        <Typography variant="h6">
                                          Registration:
                                        </Typography>
                                      </Grid>
                                      <Grid item className={classes.summaryLeftMargin}>
                                        <Typography variant="body1">
                                          {userState.currentBranch.id !== vehicle.branchDetails?.id ? (
                                            <a
                                              onClick={() => navigate(`/vehicle-state?vehicleId=${vehicle.id}`)}
                                              style={{
                                                color: "rgb(24, 117, 210)",
                                                textDecoration: "none",
                                                cursor: "pointer"
                                              }}
                                            > {vehicle?.licencePlate.toUpperCase()} </a>
                                          ) : (
                                            <span>{vehicle?.licencePlate.toUpperCase()}</span>
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                                      <Grid item>
                                        <Typography variant="h6">
                                          VIN:
                                        </Typography>
                                      </Grid>
                                      <Grid item className={classes.summaryLeftMargin}>
                                        <Typography variant="body1">
                                          {vehicle.vin || "N/A"}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    {ifPCONumberExists() && (
                                      <Grid container item xs={12} style={{ marginTop: 10 }}>
                                        <Grid item>
                                          <Typography variant="h6">
                                            PCO Number:
                                          </Typography>
                                        </Grid>
                                        <Grid item className={classes.summaryLeftMargin}>
                                          <Typography variant="body1">
                                            {vehicle.pcoNumber || "N/A"}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    )}
                                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                                      <Grid item>
                                        <Typography variant="h6">
                                          Mileage Limit:
                                        </Typography>
                                      </Grid>
                                      <Grid item className={classes.summaryLeftMargin}>
                                        {
                                          vehicleGroup && vehicleGroup.mileageLimit && basePrice ?
                                            <Typography variant="body1">
                                              {getMileageLimitText(vehicleGroup.mileageLimit, basePrice.rateTypeName, distanceUnit)}
                                            </Typography>
                                            : vehicleGroup?.unlimitedMileage ?
                                              <Typography variant="body1">
                                                Unlimited Mileage
                                              </Typography> :
                                              <Typography variant="body1">
                                                N/A
                                              </Typography>
                                        }
                                      </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                                      <Grid item>
                                        <Typography variant="h6">
                                          End Date:
                                        </Typography>
                                      </Grid>
                                      <Grid item className={classes.summaryLeftMargin}>
                                        <Typography variant="body1">
                                          {
                                            getLocalizedDateFormat(country, vehicleSchedule.endDate || "", DATE_TYPE.EXPANDED)
                                          }
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                                      <Grid item>
                                        <Typography variant="h6">
                                          Status:
                                        </Typography>
                                      </Grid>
                                      <Grid item className={classes.summaryLeftMargin}>
                                        <Typography variant="body1">
                                          {[
                                            BOOKING_STATUS.CANCELLED
                                          ].includes(booking.status) ? (
                                            "N/A"
                                          ) : !checkStatusObj.checked ?
                                            <Tooltip title="Please use mobile app to check the vehicle status to start the booking">
                                              <Typography>
                                                {checkStatusObj.checkType.charAt(
                                                  0
                                                ) +
                                                  checkStatusObj.checkType
                                                    .slice(1)
                                                    .toLowerCase()}{" "}
                                                Check Pending
                                              </Typography>
                                            </Tooltip>
                                            : (
                                              <Typography>
                                                {checkStatusObj.checkType.charAt(
                                                  0
                                                ) +
                                                  checkStatusObj.checkType
                                                    .slice(1)
                                                    .toLowerCase()}{" "}
                                                Check Completed
                                              </Typography>
                                            )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                                      <Grid item>
                                        <Typography variant="h6">
                                          Driver Assigned:
                                        </Typography>
                                      </Grid>
                                      <Grid item className={classes.summaryLeftMargin}>
                                        <Typography variant="body1">
                                          {driver ? `${driver.firstName} ${driver.lastName}` : "N/A"}
                                        </Typography>
                                      </Grid>
                                    </Grid>

                                    <Grid container item xs={12}>
                                      <Grid item style={{ marginTop: 10, marginRight: 10 }}>
                                        <Typography variant="h6">
                                          View Pre & Post Rental Vehicle Checks:
                                        </Typography>
                                      </Grid>
                                      {vehicleSchedule && vehicleSchedule.dropoffCheck && (
                                        <Fab
                                          size="medium"
                                          variant="extended"
                                          onClick={() => {
                                            if (vehicleSchedule && vehicleSchedule.dropoffCheck) {
                                              setPdfId(vehicleSchedule.dropoffCheck.vehicleStatePdfUrl);
                                              setSendEmail(false);
                                              setBookingSchedule(vehicleSchedule.id);
                                              setPdfFileName(
                                                `VehicleConditionReport-${booking.referenceNumber}.pdf`
                                              );
                                              setPdfTitle(
                                                `Vehicle Condition Report`
                                              );
                                              setPdfOpen(true);
                                              setPdfViewMode(PDFViewMode.VC_REPORT);
                                              setVCReportType(VehicleCheckTypes.DROPOFF);
                                            }
                                          }}
                                          title="View Pre-Rental Vehicle Checks"
                                        >
                                          <VisibilityIcon />
                                        </Fab>
                                      )}
                                      {vehicleSchedule && vehicleSchedule.pickupCheck && (
                                        <Fab
                                          size="medium"
                                          variant="extended"
                                          style={{ marginLeft: 5 }}
                                          onClick={() => {
                                            if (vehicleSchedule && vehicleSchedule.pickupCheck) {
                                              setPdfId(vehicleSchedule.pickupCheck.vehicleStatePdfUrl);
                                              setSendEmail(false);
                                              setBookingSchedule(vehicleSchedule.id);
                                              setPdfFileName(
                                                `VehicleConditionReport-${booking.referenceNumber}.pdf`
                                              );
                                              setPdfTitle(
                                                `Vehicle Condition Report`
                                              );
                                              setPdfOpen(true);
                                              setPdfViewMode(PDFViewMode.VC_REPORT);
                                              setVCReportType(VehicleCheckTypes.PICKUP);
                                            }
                                          }}
                                          title="View Post-Rental Vehicle Checks"
                                        >
                                          <VisibilityIcon />
                                        </Fab>
                                      )}
                                      {vehicleSchedule && (vehicleSchedule.dropoffCheck || vehicleSchedule.pickupCheck) && (
                                        <Fab
                                          size={"medium"}
                                          variant={"extended"}
                                          style={{ marginLeft: 4 }}
                                          onClick={() => {
                                            setIsSendVehicleChecksEmailClick(true);
                                            setSelectedVehicleScheduleId(vehicleSchedule.id)
                                          }}
                                        >
                                          {"Send E-Mail"}
                                        </Fab>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                        </React.Fragment>
                      );
                    }
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          {
            booking.addonRequirements.length > 0 && (
              <Box className={classes.expansionPanel} >
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h3">Add-Ons</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container xs={12} spacing={1}>
                      {booking.addonRequirements.map((item: IAddonRequirement, index: number) => {
                        return (
                          <Grid item xs={12}>
                            <Grid container
                              key={index}
                              style={{
                                background: "#f5f5f5",
                                borderRadius: 5,
                                padding: 20
                              }}>
                              <Grid container item xs={12}>
                                <Grid item>
                                  <Typography variant="h6">
                                    Name:
                                  </Typography>
                                </Grid>
                                <Grid item className={classes.summaryLeftMargin}>
                                  <Typography variant="body1">
                                    {item.addon?.displayName?.toUpperCase() ||
                                      item.addon?.name?.toUpperCase()}</Typography>
                                </Grid>
                              </Grid>
                              <Grid container item xs={12} style={{ marginTop: 10 }}>
                                <Grid item>
                                  <Typography variant="h6">
                                    Description:
                                  </Typography>
                                </Grid>
                                <Grid item className={classes.summaryLeftMargin}>
                                  <Typography variant="body1">{item.addon.description}</Typography>
                                </Grid>
                              </Grid>
                              <Grid container item xs={12} style={{ marginTop: 10 }}>
                                <Grid item>
                                  <Typography variant="h6">
                                    Quantity:
                                  </Typography>
                                </Grid>
                                <Grid item className={classes.summaryLeftMargin}>
                                  <Typography variant="body1">{item.quantity}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )
          }
          <Box className={classes.expansionPanel} >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h3">Payment Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PaymentInfo
                  booking={booking}
                  handlerInvoiceClick={handlerInvoiceClick}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box className={classes.expansionPanel} >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h3">Internal Notes(Private)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12} spacing={1}>
                  {booking.noteFromWebsite && booking.noteFromWebsite.description && (
                    <React.Fragment>
                      <Grid item xs={12} style={{ marginTop: 10, marginBottom: 16, fontSize: "14px", fontFamily: "sans-serif" }}>
                        <div style={{ marginTop: 16 }}>
                          <p style={{ margin: 0 }}>
                            <b style={{ fontSize: 16 }}>{`${isBusinessCustomerBooking
                              ? businessCustomer?.businessName || ""
                              : `${customer?.firstName} ${customer?.lastName}`
                              }`}</b> |
                            <span style={{ fontSize: 12, marginLeft: 4 }}>
                              {
                                getLocalizedDateFormat(country, booking.noteFromWebsite.createdAt || "", DATE_TYPE.EXPANDED)
                              }
                            </span>
                          </p>
                          <span style={{ margin: 0, marginBottom: 5 }}>{booking.noteFromWebsite.description}</span>
                        </div>
                      </Grid>
                      <NuvvenDivider noMargin={true} />
                    </React.Fragment>
                  )}
                  <Grid item xs={12} style={{ marginTop: 10, fontSize: "14px", fontFamily: "sans-serif" }}>
                    {notes && notes.length > 0 && notes.map((note: IBookingNote) => (
                      <div style={{ marginTop: 16 }}>
                        <p style={{ margin: 0 }}>
                          <b style={{ fontSize: 16 }}>{note.createdBy.firstName} {note.createdBy.lastName}</b> |
                          <span style={{ fontSize: 12, marginLeft: 4 }}>
                            {
                              getLocalizedDateFormat(country, note.createdDate || "", DATE_TYPE.EXPANDED)
                            }
                          </span>
                        </p>
                        <span style={{ margin: 0, marginBottom: 5 }}>{note.description}</span>
                      </div>
                    ))}
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <TextField
                      style={{ width: "100%" }}
                      label="Note"
                      name={"note.description"}
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 300 }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setNote({
                          ...note,
                          description: event.target.value,
                          createdBy: userState && userState.id ? userState.id : "",
                          createdDate: new Date().toISOString()
                        });
                      }}
                      variant="outlined"
                      value={note?.description}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      onClick={() => {
                        addBookingNote({
                          variables: {
                            bookingId: booking.id,
                            note
                          }
                        });
                      }}
                      disabled={!note?.description}
                    >
                      Add
                    </Fab>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box className={classes.expansionPanel} >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h3">Additional Customer Information(Printed on Rental Agreement)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <TextField
                      style={{ width: "100%" }}
                      label="Additional Customer Information"
                      name={"note.description"}
                      multiline
                      rows={5}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setEditAdditionalInformation(true);
                        setAdditionalInformation({
                          ...additionalInformation,
                          description: event.target.value
                        });
                      }}
                      variant="outlined"
                      value={additionalInformation?.description}
                      disabled={booking.status !== BookingStatus.CONFIRMED}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      onClick={() => {
                        setEditAdditionalInformation(false);
                        addBookingAdditionalInformation({
                          variables: {
                            bookingId: booking.id,
                            additionalInformation: additionalInformation?.description
                          }
                        });
                      }}
                      disabled={!additionalInformation?.description || booking.status !== BookingStatus.CONFIRMED}
                    >
                      Save
                    </Fab>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box className={classes.expansionPanel} >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h3">Additional Terms And Conditions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <TextField
                      style={{ width: "100%" }}
                      label="Additional Terms And Conditions"
                      name={"additionalTnc"}
                      multiline
                      rows={5}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setEditAdditionalTnc(true);
                        setAdditionalTnc(event.target.value)
                      }}
                      variant="outlined"
                      value={additionalTnc}
                      disabled={booking.status !== BookingStatus.CONFIRMED}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Fab
                      variant="extended"
                      size="medium"
                      onClick={() => {
                        setEditAdditionalTnc(false);
                        addBookingAdditionalTnc({
                          variables: {
                            bookingId: booking.id,
                            additionalTnc: booking.additionalTnc || ""
                          }
                        });
                      }}
                      disabled={!additionalTnc || booking.status !== BookingStatus.CONFIRMED}
                    >
                      Save
                    </Fab>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
        {
          (booking.status === BookingStatus.CONFIRMED ||
            booking.status === BookingStatus.IN_PROGRESS ||
            booking.status === BookingStatus.COMPLETED) && (
            <Button
              className={classes.inlineButton}
              variant="contained"
              type="button"
              aria-label="Pay"
              onClick={() => {
                if (rentalAgreementUrl) {
                  setMobileRentalButton(true);
                  setPdfId(rentalAgreementUrl);
                  setPdfViewMode(PDFViewMode.RENTAL_AGGREMENT);
                  setPdfFileName(
                    `${getLocalizedRASyntex(country, BOOKING_TYPE.RENTAL)}-${booking.referenceNumber}.pdf`
                  );
                  setSendEmail(true);
                  setPdfTitle(
                    `Rental Agreement`
                  );
                } else {
                  navigate(`/rental-agreement?booking=${booking.id}`);
                }
              }}
            >
              Rental Agreement
            </Button>
          )
        }

        {booking.status === BookingStatus.CONFIRMED && (
          <Button
            className={classes.inlineButton}
            variant="contained"
            type="button"
            disabled={loading}
            onClick={() => setIsSendEmailClick(true)}
          >
            {loading && (
              <CircularProgress
                size={14}
                style={{ marginRight: "10px" }}
              />
            )}
            {"Send E-Mail"}
          </Button>
        )}
        {showBookingExtension && (
          <Button
            className={classes.inlineButton}
            variant="contained"
            type="button"
            aria-label="Complete"
            onClick={() => setOpenExtendBookingDialog(true)}
            disabled={!canExtend(booking) || booking.zeroCostBooking}
          >
            {`Extend ${getLocalizedBookingSyntex(country)}`}
          </Button>
        )}

        <Grid item xs={12}>
          <Box marginTop={4} />
        </Grid>

        <AppBar position="fixed" className={classes.appBar}>
          <Grid container xs={12} spacing={1}>
            {booking.status === BookingStatus.CONFIRMED && (
              <>
                <Grid item xs={6}>
                  <Button
                    className={classes.cancelButton}
                    variant="contained"
                    aria-label="Cancel"
                    onClick={() =>
                      (setDialogOpen(true), setCancellationDialogOpen(true))
                    }
                    disabled={!canCancel()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.bottomButton}
                    variant="contained"
                    aria-label="Pay"
                    onClick={() => {
                      const params = new URLSearchParams(location.search);
                      const booking = params.get("booking");
                      navigate(`/update-booking?booking=${booking}&step=0`);
                    }}
                  >
                    Update
                  </Button>
                </Grid>
              </>
            )}
            {booking.status === BookingStatus.IN_PROGRESS && (
              <>
                <Grid item xs={6}>
                  <Button
                    className={classes.cancelButton}
                    variant="contained"
                    aria-label="Early Terminate"
                    onClick={() => {
                      setConfirmationDialogData({
                        description: EARLY_TERMINATION_DESCRIPTION,
                        isOpen: true,
                        confirmText: "YES",
                        cancelText: "NO",
                        onCancel: () =>
                          setConfirmationDialogData({
                            ...confirmationDialogData,
                            isOpen: false
                          }),
                        onConfirm: () => {
                          setConfirmationDialogData({
                            ...confirmationDialogData,
                            isOpen: false
                          })
                          if (booking && booking.status === BookingStatus.CONFIRMED) {
                            setCancellationDialogOpen(true)
                          } else {
                            if (booking.isSubscription) {
                              setSubscriptionEarlyTerminateDialog(true)
                            } else {
                              setEarlyTerminateDialogOpen(true)
                            }
                          }
                        },
                        title: EARLY_TERMINATE_TITLE
                      })
                    }}
                    disabled={!canTerminate(booking)}
                  >
                    Early Terminate
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip title="Booking can be completed only after collection checks have been completed and there is no outstanding payment due">
                    <Button
                      className={classes.completeButton}
                      variant="contained"
                      aria-label="Complete"
                      disabled={!canComplete() || ifchecksIncomplete()}
                      onClick={() =>
                        setCompleteConfirmationDialogData({
                          description: COMPLETE_DESCRIPTION,
                          isOpen: true,
                          onCancel: () =>
                            setCompleteConfirmationDialogData({
                              ...completeConfirmationDialogData,
                              isOpen: false
                            }),
                          onConfirm: (sendEmail) => {
                            completeBooking(sendEmail)
                            setCompleteConfirmationDialogData({
                              ...completeConfirmationDialogData,
                              isOpen: false
                            })
                          },
                          title: COMPLETE_TITLE
                        })
                      }
                    >
                      Complete
                    </Button>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </AppBar>

      </Hidden >
      {bookingDataLoading && <div style={{ position: 'fixed', top: 0, left: 200, padding: 20 }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 60,
          width: 60,
          borderRadius: 30,
          background: '#fff',
          boxShadow: '0 0 30px 1px #e2e2e2'
        }}>
          <CircularProgress size={26} thickness={5} />
        </div>
      </div>}

      {isSendEmailClick ? (
        <MailSendBox
          documentType="Booking Confirmation"
          title="Send Booking Confirmation email to customers"
          mails={(emails: string[]) => sendBookingConfirmationEmail(emails)}
          open={isSendEmailClick}
          handleClose={() => setIsSendEmailClick(false)}
        />
      ) : (
        ""
      )}
      {showMessage ? (
        <ShowMessage
          open={showMessage}
          sentMails={showMails}
          handleClose={() => setShowMessage(false)}
        />
      ) : (
        ""
      )}
      {isRentalSendEmailClick ? (
        <MailSendBox
          documentType="Rental Agreement"
          title={`Send ${getLocalizedRASyntex(country, BOOKING_TYPE.RENTAL)} email to customers`}
          mails={(emails: string[]) => sendRentalAgreementEmail(emails)}
          open={isRentalSendEmailClick}
          handleClose={() => setIsRentalSendEmailClick(false)}
        />
      ) : (
        ""
      )}
      {showRentalMessage ? (
        <ShowMessage
          open={showRentalMessage}
          sentMails={showRentalMails}
          handleClose={() => setShowRentalMessage(false)}
        />
      ) : (
        ""
      )}
      {pdfOpen ? (
        <div>
          <PdfViewer
            open={pdfOpen}
            document={pdfId}
            fileName={pdfFileName}
            title={pdfTitle}
            raLoading={raLoading}
            handleClose={() => {
              setPdfOpen(false);
            }}
            handleMail={() => { setIsRentalSendEmailClick(true); }}
            booking={booking}
            sendEmail={sendEmail}
            {...(pdfViewMode === PDFViewMode.RENTAL_AGGREMENT && {
              regeneratePdf: () => {
                setRALoading(true);
                if (booking.isSubscription) {
                  generateSubscriptionAgreementPdf({
                    variables: {
                      id: booking.id
                    }
                  })
                } else {
                  generateRentalAgreementPdf({
                    variables: {
                      id: booking.id
                    }
                  })
                }
              }
            })}
            {...(pdfViewMode === PDFViewMode.VC_REPORT && {
              renderMode: 'canvas',
              error: "Could not load Vehicle Condition Report. Please click on the Regenerate button above to regenerate the Vehicle Condition Report.",
              ...(userState.role === UserRoles.SUPER_ADMIN && {
                regeneratePdf: () => {
                  setRALoading(true);
                  regenerateVehicleConditionReport({
                    variables: {
                      bookingScheduleId: currentBookingSchedule,
                      checkType: vcReportType
                    }
                  });
                }
              })
            })}
          />
        </div>
      ) : (
        ""
      )}

      <ConfirmationDialog {...confirmationDialogData} />
      <CompleteConfirmationDialog {...completeConfirmationDialogData} />

      {depositPaymentDialogOpen &&
        <DepositPaymentDialog
          booking={booking}
          handleClose={() => {
            loadBooking({
              variables: {
                id: booking.id
              }
            });
            setDepositPaymentDialogOpen(false)
          }}
        />
      }

      {openExtendBookingDialog && (
        <ExtendBookingDialog
          handleClose={() => {
            setOpenExtendBookingDialog(false)
            if (booking) {
              loadBooking({
                variables: {
                  id: booking.id
                }
              });
            }
          }}
          booking={booking}
        />
      )}

      {(creditNotesInput && !dialogOpen) &&
        <CreditNoteCreateDialog
          isOpen={cancellationDialogOpen}
          onCancel={() => {
            setSelectedReason("")
            setCancellationDialogOpen(false)
          }}
          onConfirm={cancelBooking}
          booking={booking}
          title={"Cancellation Charges"}
          description={"Do you wish to continue?"}
          creditNotesInput={creditNotesInput}
        />
      }

      {
        subscriptionEarlyTerminateDialog && (
          <EarlyTerminateSubscriptionDialog
            open={subscriptionEarlyTerminateDialog}
            onCancel={() => setSubscriptionEarlyTerminateDialog(false)}
            subscription={booking.activeSubscription}
            booking={booking}
            onConfirm={earlyTerminateBooking}
          />
        )
      }
      {dialogOpen && (
        <Dialog
          open={dialogOpen}
          onClose={() => {
            setSelectedReason("")
            setDialogOpen(false)
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{getLocalizedCancelBookingSyntex(country)}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"> Please select a reason to cancel the {getLocalizedBookingSyntex(country).toLowerCase()}: </DialogContentText>
            <RadioGroup value={selectedReason} onChange={(e) => handleReasonChange(e.target.value)}>
              {CANCELLATION_REASONS.map((reason) => (
                <FormControlLabel key={reason} value={reason} control={<Radio />} label={reason} />
              ))}
            </RadioGroup>

            {selectedReason === 'Other' && (
              <div>
                <h5>Enter the reason:</h5>
                <TextField
                  type="text"
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      padding: '10px 14px',
                    },
                  }}
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} disabled={creditNoteDataLoading || cancelBookingLoading} className={classes.proceedButton} style={{ marginBottom: "10px", marginRight: 10 }}>
              {(creditNoteDataLoading || cancelBookingLoading) && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
              Confirm
            </Button>
            <Button onClick={() => (setDialogOpen(false), setCancellationDialogOpen(false))} disabled={creditNoteDataLoading || cancelBookingLoading} color="primary" style={{ backgroundColor: "#828282", color: "white", marginBottom: "10px", marginRight: "10px" }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {
        earlyTerminateDialogOpen &&
        <EarlyTerminationDialog
          booking={booking}
          isOpen={earlyTerminateDialogOpen}
          onCancel={() => setEarlyTerminateDialogOpen(false)}
          onConfirm={earlyTerminateBooking}
        />
      }
      {
        selectedCreditNote &&
        <RefundDialog
          isOpen={refundDialogOpen}
          refundLoading={refundLoading}
          onCancel={() => setRefundDialogOpen(false)}
          creditNote={selectedCreditNote}
          updateRefund={updateRefund}
        />
      }
      <Dialog onClose={handleClose} aria-labelledby="rental-agreement-dialog" open={rentalDialogOpen}>
        <DialogTitle id="simple-dialog-title">
          <Typography variant='h2'>
            Rental Agreement Actions
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => { downloadRentalAgreement(pdf) }} color="primary">
            View/Download
          </Button>
          <Button onClick={() => { setIsRentalSendEmailClick(true) }} color="primary">
            Send E-mail
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={tbaDialogOpen}
        onClose={handleTbaDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Booking TBA"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {booking.tba ?
              "Are you sure you want to mark this booking as Not TBA?" :
              "Are you sure you want to mark this booking as TBA?"}
          </DialogContentText>
          <TextField
            style={{ width: "100%", marginTop: 10 }}
            label="Notes"
            name={"tbaNotes"}
            multiline
            rows={5}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              settbaNotes(event.target.value);
            }}
            variant="outlined"
            value={tbaNotes}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              markBookingAsTba({
                variables: {
                  bookingId: booking.id,
                  tba: !booking.tba,
                  notes: tbaNotes
                }
              })
            }}
            disabled={tbaLoading}
            color="primary"
            style={{ backgroundColor: "var(--theme-primary)", color: "white", marginBottom: "10px", marginRight: 10 }}
          >
            {tbaLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
            {"Confirm"}
          </Button>
          <Button onClick={handleTbaDialogClose} color="primary" style={{ backgroundColor: "#828282", color: "white", marginBottom: "10px", marginRight: "10px" }}>
            {"Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid >
  );
};

export default ViewBooking;