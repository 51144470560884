import {
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IAppState } from "../../../../../../../store";


export const MobilePriceRuleCard = ({ id, name, operator, customers, vehicleGroups, status }: {
  id: string;
  name: string;
  operator: string;
  customers: number;
  vehicleGroups: number;
  status: string;
}) => {

  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;

  const colour = (status === "Active") ? ('var(--ion-color-success)') : (
    (status === "In-Active") ? ('var(--ion-color-danger)') : (
      "rgba(0, 0, 0, 1)"
    )
  )
  const useStyle = makeStyles((theme: Theme) =>
    createStyles({
      cardStyle: {
        padding: "5px",
        height: "150px",
        minWidth: "calc(100vw - 60px)",
        maxWidth: "calc(100vw - 60px)",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: `${colour}`
      },
    }));

  const classes = useStyle();

  function routeTo() {
    if (id) {
      navigate(`/update-price-rule?id=${id}`)
    }
  }

  return (
    <ButtonBase onClick={() => routeTo()}>
      <Card className={classes.cardStyle} >
        <CardContent>
          <Grid container item xs={12}>

            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                  {name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "10px" }}>
                  Price Effect:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                  {operator}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={6}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "10px" }}>
                  Customers:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                  {customers}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={6}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "10px" }}>
                  Vehicle Groups:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                  {vehicleGroups}
                </Typography>
              </Grid>
            </Grid>

          </Grid>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

export default MobilePriceRuleCard;