import "./style.scss"
import { useLazyQuery } from "@apollo/client";
import { CircularProgress, CssBaseline, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DateTime as d } from "luxon"
import { useLocation, useNavigate } from "react-router-dom";
import { GET_PROCUREMENT_REQUEST } from "../../../graphql/Procurement/getProcurementRequestQuery";
import { IProcurementRequest } from "../../../reducers/Procurement/types";
import StepperHeader from "../../common/StepperHeader/StepperHeader";
import { FinanceInformation } from "./UpdateProcurementComponents/FinanceInformation";
import { PurchaseOrder } from "./UpdateProcurementComponents/PurchaseOrder";
import { Quote } from "./UpdateProcurementComponents/Quote";
import { ReviewRequest } from "./UpdateProcurementComponents/ReviewRequest";
import { RFP } from "./UpdateProcurementComponents/RFP";
import { DeliveryInformation } from "./UpdateProcurementComponents/DeliveryInformation";
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";
import { DATE_TYPE } from "../../common/utils";
import { IAppState } from "../../../store";
import { useSelector } from "react-redux";

export const ReviewProcurementRequestTab: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.organisation.address
  const [procurementRequest, setProcurementRequest] = useState<IProcurementRequest>();
  const [partner, setPartner] = useState<string>("")
  const [purchaseOrderId, setPurchaseOrderId] = useState<string>("")

  const [
    loadProcurementRequest,
    { loading, data }
  ] = useLazyQuery(GET_PROCUREMENT_REQUEST, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (!data.procurementRequest) {
        navigate(`/procurements`)
      }
    },
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const procurementsId = params.get("procurement");
      if (procurementsId) {
        loadProcurementRequest({
          variables: {
            id: procurementsId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (data && data.procurementRequest) {
      setProcurementRequest(data.procurementRequest)
      if (data.procurementRequest && data.procurementRequest.purchaseOrders.length) {
        setPartner(data.procurementRequest.purchaseOrders[0].partner.partnerName)
        setPurchaseOrderId(data.procurementRequest.purchaseOrders[0].purchaseOrderId)
      }
    }
  }, [data]);

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  };

  const stepMapper: any = {
    0: (
      procurementRequest &&
      <ReviewRequest
        procurement={procurementRequest}
        onNextStep={nextStep}
        setProcurementRequest={setProcurementRequest}
      />
    ),
    1: (
      procurementRequest &&
      <RFP
        procurement={procurementRequest}
        onNextStep={nextStep}
        onPreviousStep={previousStep}
        setProcurementRequest={setProcurementRequest}
      />
    ),
    2: (
      procurementRequest &&
      <Quote
        procurement={procurementRequest}
        onNextStep={nextStep}
        onPreviousStep={previousStep}
        setProcurementRequest={setProcurementRequest}
      />
    ),
    3: (
      procurementRequest &&
      <PurchaseOrder
        procurement={procurementRequest}
        onNextStep={nextStep}
        onPreviousStep={previousStep}
        setProcurementRequest={setProcurementRequest}
      />
    ),
    4: (
      procurementRequest &&
      <FinanceInformation
        procurement={procurementRequest}
        onNextStep={nextStep}
        onPreviousStep={previousStep}
        setProcurementRequest={setProcurementRequest}
      />
    ),
    5: (
      procurementRequest &&
      <DeliveryInformation
        procurement={procurementRequest}
        onPreviousStep={previousStep}
        setProcurementRequest={setProcurementRequest}
      />
    )
  };

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2} className="update-rpcurement-stepper">
      <Grid item xs={12} container>
        <Grid item xs={4}><Typography color="primary" variant={"h1"}>Procurement Request</Typography></Grid>
        <Grid item xs={8}>
          <StepperHeader
            currentStep={currentStep}
            steps={[
              "Review Request",
              "RFP",
              "Quote",
              "Purchase Order",
              "Financial Information",
              "Delivery Information"
            ]}
          />
        </Grid>
      </Grid>
      <CssBaseline />
      <Grid container item xs={12} >
        <Grid item xs={12} container spacing={2}>
          <Grid item><Typography variant={"h4"}>Request ID: <span>{procurementRequest?.referenceNumber}</span></Typography></Grid>
          <Grid item><Typography variant={"h4"}>Request By: <span>{`${procurementRequest?.createdBy.firstName} ${procurementRequest?.createdBy.lastName}`}</span></Typography></Grid>
          <Grid item><Typography variant={"h4"}>Request Date: <span>{procurementRequest && d.fromISO(procurementRequest?.createdAt).toFormat("dd/MM/yyyy")}</span></Typography></Grid>
          {
            partner && <Grid item><Typography variant={"h4"}>Partner: <span>{partner}</span></Typography></Grid>
          }
          {
            purchaseOrderId && <Grid item><Typography variant={"h4"}>PO ID: <span>{purchaseOrderId}</span></Typography></Grid>
          }
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {(loading) ? <CircularProgress /> : stepMapper[currentStep]}
      </Grid>
    </Grid>
  );
};
