import { gql } from "@apollo/client";

export const GET_CUSTOMER_BOOKINGS = gql`
  query bookingsByCustomerId($id: String!) {
    bookingsByCustomerId(id: $id) {
      id
      dateStarted
      source
      dateReturned
      referenceNumber
      dateCreated
      dateUpdated
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
      pickupServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      pickupOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      pickupDateTime
      dropoffServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      dropoffOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      dropoffDateTime
      longTermBooking
      flightNumber
      insuranceRate
      customerType
      discount {
        appliedBy
        appliedDate
        isPercent
        code
        amount
      }
      tax {
        appliedBy
        appliedDate
        code
        amount
        payableAmount
      }
      rateTypeName
      rateTypeDuration
      billingCycleName
      billingCycleDuration
      isRecurringBilling
      billingFrequency
      status
      customer {
        id
        firstName
        lastName
        email
        phoneNumber {
          phone
          country
        }
        location {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      organisationId
      tba
      isSubscription
      currentBookingSchedules {
        id
        startDate
        endDate
        status
        vehicle {
          id
          licencePlate
        }
      }
      businessCustomer {
        id
        businessName
        registrationNumber
        VAT
        registeredAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        tradingAddressSame
        tradingAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        approvedDrivers {
          id
          status
          firstName
          lastName
          email
        }
        authorizedSignatories {
          id
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        billing {
          phoneNumber {
            phone
            country
          }
          email
        }
        contact {
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        documents {
          id
          name
          title
          documentType
          expiryDate
          url
        }
      }
      authorizedSignatory
      insurancePolicy {
        id
        name
        description
      }
      paymentDetails {
        startDate
        endDate
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        totalPayableAmount
        rentalTime
      }
    }
  }
`;
