import { gql } from "@apollo/client";

export const GET_VEHICLE_FINANCE = gql`
  query vehicleFinance($id: String!) {
    vehicleFinance(id: $id) {
      id
      vehicleId
      vehicleHoldingCost
      depreciationPercentage
      sourceType
      purchasePrice
      leasePrice
      leasedOn
      purchasedOn
      leasedUntil
      paymentInterval
      purchasedFrom
      leasedFrom
      financedFrom
      financedFromDate
      financedToDate
      financedPrice
      financedDeposit
      financedPaymentInterval
      rentedFrom
      rentedFromDate
      rentedToDate
      rentedPrice
      rentedPaymentInterval
      residualValue
      roadFundTax
    }
  }
`;
