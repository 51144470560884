import {
  RESET_INSURANCE,
  UPDATE_INSURANCE_POLICY
} from "../../actions/insurancePolicy/types";
import { initialState } from "./const";
import { IInsurancePolicyState } from "./types";

export default function(
  state: IInsurancePolicyState = initialState,
  action: any
): IInsurancePolicyState {
  switch (action.type) {
    case UPDATE_INSURANCE_POLICY:
      return {
        ...state,
        insurancePolicy: { ...state.insurancePolicy, ...action.payload }
      };
    case RESET_INSURANCE:
      return {
        ...state,
        insurancePolicy: { ...initialState.insurancePolicy }
      };
    default:
      return state;
  }
}
