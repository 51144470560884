
import React from "react"

export const HGVTrailerSVG = {
    interior: {
        width: 211.6,
        height: 960,
        svg: (<>
                <g id="Layer_2_00000094579066947539520630000001056272371906818238_">
                    <g>
                        <rect x="26.7" y="200.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="159.3" height="742.5" />
                        <rect x="30.7" y="204.9" fill="none" stroke="#000000" stroke-miterlimit="10" width="151.1" height="734.8" />
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M108.2,174.6H29.5V49c0,0-0.4-4.5,2.4-5.3c0,0,19.4-1.5,76.3-2.8"
                            />
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M104.5,174.6h78.7V49c0,0,0.4-4.5-2.4-5.3c0,0-19.4-1.5-76.3-2.8"
                            />
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M104.5,21.3c0,0,35-1.3,54.2,2.3c0,0,10.2,1.8,17.9,1.8
				c0,0,10.7,0.4,10.7,5.1v54.9c0,0.6,0.5,1.2,1.1,1.3c0.8,0.2,1.8,0.6,2,2c0,0,4.1,54.3,2.8,87.9h-8.4v23.8"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M110.4,21.3c0,0-35-1.3-54.2,2.3c0,0-10.2,1.8-17.9,1.8
				c0,0-10.7,0.4-10.7,5.1v54.9c0,0.6-0.5,1.2-1.1,1.3c-0.8,0.2-1.8,0.6-2,2c0,0-4.1,54.3-2.8,87.9h8.4v23.8"/>
                        </g>
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="42.2" y1="175.1" x2="42.2" y2="200.4" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="170.5" y1="175.1" x2="170.5" y2="200.3" />
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M168.5,175.1v11c0,2.7-2.2,4.9-4.9,4.9H49.8c-2.7,0-4.9-2.2-4.9-4.9
			v-11"/>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M105.9,47.4c0,0,44.9-0.6,73.1,3c0,0,2.2,2.2,0,4
				c0,0-53-3.2-70.9-2.9"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M105.9,47.4c0,0-44.6-0.6-72.8,3c0,0-2.2,2.2,0,4
				c0,0,58.2-3.4,75-2.9"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M105.7,56.6c0,0,44.9-0.6,73.1,3c0,0,2.2,2.2,0,4
				c0,0-53-3.2-70.9-2.9"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M105.7,56.6c0,0-44.6-0.6-72.8,3c0,0-2.2,2.2,0,4
				c0,0,58.2-3.4,75-2.9"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M105.7,66.2c0,0,44.9-0.6,73.1,3c0,0,2.2,2.2,0,4
				c0,0-53-3.2-70.9-2.9"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M105.7,66.2c0,0-44.6-0.6-72.8,3c0,0-2.2,2.2,0,4
				c0,0,58.2-3.4,75-2.9"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M105.7,75.3c0,0,44.9-0.6,73.1,3c0,0,2.2,2.2,0,4
				c0,0-53-3.2-70.9-2.9"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M105.7,75.3c0,0-44.6-0.6-72.8,3c0,0-2.2,2.2,0,4
				c0,0,58.2-3.4,75-2.9"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M106.7,38.3c0,0,59.4,0.8,76.5,3.6l1.3-9.3
				c0.2-1.4-0.6-2.7-1.9-3.1c-1.5-0.5-7.8-1.1-11.3-1.3c0,0-49-1.8-64.6-1.8"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M106.7,38.3c0,0-59.4,0.8-76.5,3.6l-1.3-9.3
				c-0.2-1.4,0.6-2.7,1.9-3.1c1.5-0.5,7.8-1.1,11.3-1.3c0,0,49-1.8,64.6-1.8"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M25.5,62.4H8.3v-7.3c0-1.3,1.1-2.4,2.4-2.4h12.4
				c1.3,0,2.4,1.1,2.4,2.4L25.5,62.4L25.5,62.4z"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="27.5" y1="39.7" x2="18.6" y2="52.7" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="27.7" y1="42.7" x2="20.9" y2="52.7" />
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M189.5,62.4h17.2v-7.3c0-1.3-1.1-2.4-2.4-2.4h-12.4
				c-1.3,0-2.4,1.1-2.4,2.4L189.5,62.4L189.5,62.4z"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="187.5" y1="39.7" x2="196.4" y2="52.7" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="187.3" y1="42.7" x2="194.1" y2="52.7" />
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M54.7,88h31.2c0,0,4.6,0.8,6.4,8.9l0.3,19.9c0,0-2.3,11.4-8.8,15
				h-28c0,0-5.7-4.4-8.2-14.8V96.6C47.5,96.6,49.8,89,54.7,88z"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M93.2,128.4l-0.7-1.2h-3.9l-2.6,3l-3.1,1.6l-13-0.1v-0.1L56.6,132
				l-2.8-2.2l-2.1-2.8l-4.4,0.2l-1.2,2.2l-0.7,5.8v17.7l0.6,4.6l1.6,3.1l2,2l3.2,1.6h4.4H59l0.1,1.3h10.7v0.1h10.7l0.1-1.3h1.9H87
				l3.2-1.6l2-2l1.6-3.1l0.6-4.6v-17.7l-0.6-4.6C93.8,129.8,93.5,129.1,93.2,128.4z"/>
                            <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="69.9,152.2 56.5,152.2 56.5,163.9 59,166.6 69.9,166.6 
				80.7,166.6 83.2,163.9 83.2,152.2 			"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M123.9,88.7h31.2c0,0,4.6,0.8,6.4,8.9l0.3,19.9
				c0,0-2.3,11.4-8.8,15h-28c0,0-5.7-4.4-8.2-14.8V97.3C116.7,97.3,119,89.7,123.9,88.7z"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M162.4,129.1l-0.7-1.2h-3.9l-2.6,3l-3.1,1.6l-13-0.1v-0.1
				l-13.3,0.4l-2.8-2.2l-2.1-2.8l-4.4,0.2l-1.2,2.2l-0.7,5.8v17.7l0.6,4.6l1.6,3.1l2,2l3.2,1.6h4.4h1.9l0.1,1.3h10.7v0.1h10.7
				l0.1-1.3h1.9h4.4l3.2-1.6l2-2l1.6-3.1l0.6-4.6V136l-0.6-4.6C163,130.5,162.8,129.8,162.4,129.1z"/>
                            <polygon fill="none" stroke="#000000" stroke-miterlimit="10" points="139.1,152.9 125.7,152.9 125.7,164.6 128.2,167.3 
				139.1,167.3 149.9,167.3 152.5,164.6 152.5,152.9 			"/>
                        </g>
                    </g>
                </g>
            </>
        )
    },
    exterior: {
        width: 808.9,
        height: 1562.6,
        svg: (
            <>
                <g id="Layer_1_00000078732010504787691150000008439868460044561834_">
                    <g>
                        <rect x="104.1" y="515.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="162.9" height="744" />
                        <rect x="108.4" y="520.8" fill="none" stroke="#000000" stroke-miterlimit="10" width="153" height="733.3" />
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M62.6,1120c20.4-0.1,36.6,15.6,36.6,34.9
				c0,19.8-15.3,34.6-36.6,34.6"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M62.6,1116c27.3,0,40.4,19.6,40.4,38.9c0,19.4-13.7,39.1-40.4,38.6
				"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="62.6" y1="1115.5" x2="62.6" y2="1120.5" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="62.1" y1="1189" x2="62.1" y2="1194" />
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M63,1040c20.4-0.1,36.6,15.6,36.6,34.9c0,19.8-15.3,34.6-36.6,34.6
				"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M63,1036c27.3,0,40.4,19.6,40.4,38.9c0,19.4-13.7,39.1-40.4,38.6"
                            />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="63" y1="1035.5" x2="63" y2="1040.5" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="62.6" y1="1109" x2="62.6" y2="1114" />
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M62.8,959.3c20.4-0.1,36.6,15.6,36.6,34.9
				c0,19.8-15.3,34.6-36.6,34.6"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M62.8,955.3c27.3,0,40.4,19.6,40.4,38.9
				c0,19.4-13.7,39.1-40.4,38.6"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="62.8" y1="954.8" x2="62.8" y2="959.8" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="62.3" y1="1028.4" x2="62.3" y2="1033.3" />
                        </g>
                        <rect x="69.9" y="1233.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="34.2" height="6.3" />
                        <rect x="71.1" y="1239.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="3.1" />
                        <rect x="73.7" y="1243" fill="none" stroke="#000000" stroke-miterlimit="10" width="10.1" height="3.1" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.1" y1="1232.8" x2="86.1" y2="1195.2" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.1" y1="953" x2="86.1" y2="757.1" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.6" y1="1195.6" x2="66.2" y2="1193.5" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.6" y1="952.4" x2="66.2" y2="955.4" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="67" y1="1032.9" x2="67" y2="1036.4" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="67" y1="1112.9" x2="67" y2="1116.4" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.1" y1="728.2" x2="104.1" y2="701.3" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="86.1" y1="741.2" x2="86.1" y2="727.9" />
                        <g>
                            <g>
                                <g>
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="103.9" y1="744.6" x2="68.1" y2="744.6" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="104" y1="741.3" x2="76.9" y2="741.3" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="77.2" y1="741.2" x2="73.1" y2="744.6" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="68.4" y1="744.7" x2="64.3" y2="741.3" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.4" y1="741.7" x2="64.4" y2="738.3" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.9" y1="738.3" x2="60.5" y2="738.3" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="60.4" y1="737.8" x2="60.4" y2="749.4" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="68.3" y1="744.6" x2="68.3" y2="749.9" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="73.5" y1="744.6" x2="73.5" y2="749.9" />
                                </g>
                                <g>
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="103.9" y1="754.2" x2="68.1" y2="754.2" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="104" y1="757.5" x2="76.9" y2="757.5" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="77.2" y1="757.6" x2="73.1" y2="754.2" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="68.4" y1="754.1" x2="64.3" y2="757.5" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.4" y1="757.1" x2="64.4" y2="760.6" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="64.9" y1="760.6" x2="60.5" y2="760.6" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="60.4" y1="761.1" x2="60.4" y2="749.2" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="68.3" y1="754.2" x2="68.3" y2="749" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="73.5" y1="754.2" x2="73.5" y2="749" />
                                </g>
                            </g>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="101.5" y1="741.3" x2="104.1" y2="732.7" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="101.5" y1="757.7" x2="104.1" y2="766.3" />
                        </g>
                        <g>
                            <circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="63.9" cy="994.1" r="31.9" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="63.9" cy="994.1" r="18.1" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="63.9" cy="994.1" r="3.6" />
                        </g>
                        <g>
                            <circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="63.9" cy="1074.8" r="31.9" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="63.9" cy="1074.8" r="18.1" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="63.9" cy="1074.8" r="3.6" />
                        </g>
                        <g>
                            <circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="64.5" cy="1154.9" r="31.9" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="64.5" cy="1154.9" r="18.1" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="64.5" cy="1154.9" r="3.6" />
                        </g>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M101.3,458.7" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M85.2,710.2h6.4c0.8,0,1.6-0.2,2.4-0.5
			c0,0,0.1,0,0.1-0.1c1.9-0.9,3-2.9,3-5v-5.8"/>
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="97.1" y1="655.3" x2="97.1" y2="485.4" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="56.4" y1="645.1" x2="56.4" y2="631.3" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="56.4" y1="528.7" x2="56.4" y2="525.5" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="48.2" y1="525.5" x2="48.2" y2="485.4" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="72.8" y1="516.5" x2="72.8" y2="494.6" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="97.4" y1="485.7" x2="48.2" y2="485.7" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="97.4" y1="494.6" x2="48.2" y2="494.6" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="97.1" y1="516.5" x2="47.9" y2="516.5" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="92.9" y1="550.1" x2="50" y2="550.1" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="92.9" y1="546.2" x2="50" y2="546.2" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="92.9" y1="583.9" x2="50" y2="583.9" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="92.9" y1="580" x2="50" y2="580" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="92.9" y1="614.8" x2="50" y2="614.8" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="92.9" y1="610.9" x2="50" y2="610.9" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="97.1" y1="525.1" x2="47.9" y2="525.1" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M50,628v-96.1c0-1.8,1.5-3.3,3.3-3.3h36.4
			c1.8,0,3.3,1.5,3.3,3.3V628c0,1.8-1.5,3.3-3.3,3.3H53.3C51.5,631.3,50,629.8,50,628z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M91.5,710.5v3.8c0,0-0.1,1-1.5,1H79.7
			c0,0-1.5,0.3-1.7-1.6"/>
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="97" y1="609.6" x2="104.1" y2="621.5" />
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M62.7,642.2c20.4-0.1,36.6,15.6,36.6,34.9
				c0,19.8-15.3,34.6-36.6,34.6"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M62.7,638.2c27.3,0,40.4,19.6,40.4,38.9
				c0,19.4-13.7,39.1-40.4,38.6"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="62.7" y1="637.7" x2="62.7" y2="642.7" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="62.2" y1="711.2" x2="62.2" y2="716.2" />
                        </g>
                        <g>

                            <ellipse transform="matrix(9.089786e-03 -1 1 9.089786e-03 -613.752 734.6436)" fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="63.8" cy="677" rx="31.9" ry="31.9" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="63.8" cy="677" r="18.1" />

                            <ellipse transform="matrix(1.850894e-02 -0.9998 0.9998 1.850894e-02 -614.2649 728.2585)" fill="none" stroke="#000000" stroke-miterlimit="10" cx="63.8" cy="677" rx="3.6" ry="3.6" />
                        </g>
                        <g>
                            <circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="63.8" cy="441.4" r="31.9" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="63.8" cy="441.4" r="18.1" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="63.8" cy="441.4" r="3.6" />
                        </g>
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M101.2,490.9l112-2.3h3.6c0,0,8.2-2.3,9.1-10.2l2-79.9l-1.6-29.1
			c0,0,0.7-10.9-9.6-14.1L158.6,340h-8.8h-39.6c0,0-6.2,0.2-6.2,4.7h-6.9l-5.4-6c-0.5-0.6-1.3-0.9-2-0.9H54.4c0,0-2.8,0.1-5.4,0.9
			c-2.6,0.8-4.3,3.2-4.3,5.9v59.8h24.8c0,0,25.7,0.3,31.9,26.3L101.2,490.9z"/>
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M216.3,355c0,0-0.8,2.4-2.9,2.4L160.8,344c0,0-1,0.1-1.2-4" />
                        <path fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" d="M209.6,446.7v-76.3c0,0,0-5.4-3.7-6l-38.8-5.9
			c0,0-4.6-0.6-4.6,3.6v27c0,2.7,0.5,5.3,1.6,7.7l7.1,16.2c1,2.2,1.5,4.5,1.6,6.9l0.8,24.7c0.1,2.6,2.1,4.7,4.7,4.9h0.1h27.5
			c0.8,0,1.5-0.2,2.2-0.5C208.9,448.6,209.6,447.9,209.6,446.7z"/>
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="213.7" y1="488.3" x2="213.7" y2="363.9" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="216.9" y1="488.3" x2="216.9" y2="363.9" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="213.2" y1="363.9" x2="217.4" y2="363.9" />
                        <rect x="173.9" y="428.3" fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" width="34.7" height="6.2" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="172.6" y1="431.3" x2="116" y2="431.3" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M116.4,490.3V431c0,0,2.3-23.4-28-30.5H45" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="104.3" y1="407.7" x2="104.3" y2="345" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="149.2" y1="421.9" x2="149.2" y2="340.1" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="139.4" y1="489.9" x2="139.4" y2="431.3" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="120.4" y1="490.3" x2="120.4" y2="431.8" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M120.4,431.3v-9.8c0-2.2,1.5-4.1,3.3-4.1h12.5
			c1.8,0,3.3,1.8,3.3,4.1v9.8"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M123.3,431.3v-6.7c0-1.5,1-2.8,2.2-2.8h8.6
			c1.2,0,2.2,1.3,2.2,2.8v6.7"/>
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="136.4" y1="428.2" x2="123.3" y2="428.2" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M204.6,476.8v-23c0-0.4,0.4-0.8,0.8-0.8h4.8
			c0.4,0,0.8,0.4,0.8,0.8v23c0,0.4-0.4,0.8-0.8,0.8h-4.8C204.9,477.6,204.6,477.2,204.6,476.8z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M196.7,476.8v-23c0-0.4,0.4-0.8,0.8-0.8h4.8
			c0.4,0,0.8,0.4,0.8,0.8v23c0,0.4-0.4,0.8-0.8,0.8h-4.8C197.1,477.6,196.7,477.2,196.7,476.8z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M188.9,476.8v-23c0-0.4,0.4-0.8,0.8-0.8h4.8
			c0.4,0,0.8,0.4,0.8,0.8v23c0,0.4-0.4,0.8-0.8,0.8h-4.8C189.3,477.6,188.9,477.2,188.9,476.8z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M181.1,476.8v-23c0-0.4,0.4-0.8,0.8-0.8h4.8
			c0.4,0,0.8,0.4,0.8,0.8v23c0,0.4-0.4,0.8-0.8,0.8h-4.8C181.4,477.6,181.1,477.2,181.1,476.8z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M173.4,476.8v-23c0-0.4,0.4-0.8,0.8-0.8h4.8
			c0.4,0,0.8,0.4,0.8,0.8v23c0,0.4-0.4,0.8-0.8,0.8h-4.8C173.7,477.6,173.4,477.2,173.4,476.8z"/>
                        <g>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M80.7,398.1v-40.3c0-0.4,0.3-0.6,0.6-0.6h14.6
				c0.4,0,0.6,0.3,0.6,0.6v40.3c0,0.4-0.3,0.6-0.6,0.6H81.4C81,398.7,80.7,398.4,80.7,398.1z"/>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M80.7,398.1v-38.4c0-0.3,0.2-0.6,0.5-0.6h11.6
				c0.3,0,0.5,0.3,0.5,0.6v38.4c0,0.3-0.2,0.6-0.5,0.6H81.2C80.9,398.7,80.7,398.4,80.7,398.1z"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M63.5,398.1v-40.3c0-0.4,0.3-0.6,0.6-0.6h14.6
				c0.4,0,0.6,0.3,0.6,0.6v40.3c0,0.4-0.3,0.6-0.6,0.6H64.1C63.7,398.7,63.5,398.4,63.5,398.1z"/>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M63.5,398.1v-38.4c0-0.3,0.2-0.6,0.5-0.6h11.6
				c0.3,0,0.5,0.3,0.5,0.6v38.4c0,0.3-0.2,0.6-0.5,0.6H64C63.7,398.7,63.5,398.4,63.5,398.1z"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M46.2,398.1v-40.3c0-0.4,0.3-0.6,0.6-0.6h14.6
				c0.4,0,0.6,0.3,0.6,0.6v40.3c0,0.4-0.3,0.6-0.6,0.6H46.8C46.5,398.7,46.2,398.4,46.2,398.1z"/>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M46.2,398.1v-38.4c0-0.3,0.2-0.6,0.5-0.6h11.6
				c0.3,0,0.5,0.3,0.5,0.6v38.4c0,0.3-0.2,0.6-0.5,0.6H46.7C46.4,398.7,46.2,398.4,46.2,398.1z"/>
                        </g>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M72,347.3v-9.7h14.5v12.7H75
			C73.3,350.4,72,349,72,347.3z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M59.5,347.3v-9.7h4.2v12.7h-1.2
			C60.9,350.4,59.5,349,59.5,347.3z"/>
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M189.5,489.9v11.2c0,0,0,4.9-7,4.9H97.1" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="97.1" y1="494.6" x2="101.4" y2="490.9" />
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M101.3,459c0,0-17.3,17.1-42.4,20h-2.3c-0.8,0-1.5,0.2-2.2,0.5
			c-0.9,0.5-1.9,1.6-1.6,3.7l27.6-0.2c1.4,0,2.7-0.3,3.9-0.9c1.3-0.6,2.6-1.4,2.8-2.5V477c0-0.4,0.2-0.8,0.5-1
			c2.2-1.5,10.8-7.1,13.6-8"/>
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M48.7,404.3c0,0,4.5,0.8,6.5,5.7" />
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M60.5,474c0,0,0,3-2.6,5.1" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M167.1,374.5h39.3v-5c0,0-0.6-3.2-4.3-3.6
			c0,0-17.9-3.8-34.8,2.4c-0.5,0.2-0.9,0.7-0.9,1.2v4.3C166.4,374.3,166.7,374.5,167.1,374.5z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M157.8,363.1h-5.1c0,0-0.8-0.1-0.9-1.8v-14.6
			c0-0.6,0.4-1.1,0.9-1.1h0.5c0.7,0,1.4,0.4,1.8,1.1c1,1.7,2.9,4.8,3.4,6.7l0.1,8.8C158.5,362.7,158.2,363.1,157.8,363.1z"/>
                        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M156.6,360.2l9.8,9.1l1.5-1.2l-11-10.5
			c-0.3-0.3-0.8,0-0.8,0.4v1.3C156.2,359.6,156.4,360,156.6,360.2z"/>
                    </g>
                    <g>
                        <rect x="541.8" y="515.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="162.9" height="744" />
                        <rect x="547.5" y="520.7" fill="none" stroke="#000000" stroke-miterlimit="10" width="153" height="733.3" />
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M746.3,1120c-20.4-0.1-36.6,15.6-36.6,34.9
				c0,19.8,15.3,34.6,36.6,34.6"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M746.3,1116c-27.3,0-40.4,19.6-40.4,38.9
				c0,19.4,13.7,39.1,40.4,38.6"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="746.3" y1="1115.5" x2="746.3" y2="1120.5" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="746.8" y1="1189" x2="746.8" y2="1194" />
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M745.9,1040c-20.4-0.1-36.6,15.6-36.6,34.9
				c0,19.8,15.3,34.6,36.6,34.6"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M745.9,1036c-27.3,0-40.4,19.6-40.4,38.9
				c0,19.4,13.7,39.1,40.4,38.6"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="745.9" y1="1035.5" x2="745.9" y2="1040.5" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="746.3" y1="1109" x2="746.3" y2="1114" />
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M746.1,959.3c-20.4-0.1-36.6,15.6-36.6,34.9
				c0,19.8,15.3,34.6,36.6,34.6"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M746.1,955.3c-27.3,0-40.4,19.6-40.4,38.9
				c0,19.4,13.7,39.1,40.4,38.6"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="746.1" y1="954.8" x2="746.1" y2="959.8" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="746.5" y1="1028.4" x2="746.5" y2="1033.3" />
                        </g>
                        <rect x="704.7" y="1233.3" fill="none" stroke="#000000" stroke-miterlimit="10" width="34.2" height="6.3" />
                        <rect x="722.7" y="1239.6" fill="none" stroke="#000000" stroke-miterlimit="10" width="15" height="3.1" />
                        <rect x="725" y="1243" fill="none" stroke="#000000" stroke-miterlimit="10" width="10.1" height="3.1" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="722.7" y1="1232.8" x2="722.7" y2="1195.2" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="722.7" y1="953" x2="722.7" y2="757.1" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="722.3" y1="1195.6" x2="742.6" y2="1193.5" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="722.3" y1="952.4" x2="742.6" y2="955.4" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="741.9" y1="1032.9" x2="741.9" y2="1036.4" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="741.9" y1="1112.9" x2="741.9" y2="1116.4" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="722.8" y1="728.2" x2="704.7" y2="701.3" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="722.7" y1="741.2" x2="722.7" y2="727.9" />
                        <g>
                            <g>
                                <g>
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="705" y1="744.6" x2="740.8" y2="744.6" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="704.8" y1="741.3" x2="732" y2="741.3" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="731.7" y1="741.2" x2="735.8" y2="744.6" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="740.5" y1="744.7" x2="744.6" y2="741.3" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="744.4" y1="741.7" x2="744.4" y2="738.3" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="743.9" y1="738.3" x2="748.3" y2="738.3" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="748.5" y1="737.8" x2="748.5" y2="749.4" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="740.6" y1="744.6" x2="740.6" y2="749.9" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="735.3" y1="744.6" x2="735.3" y2="749.9" />
                                </g>
                                <g>
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="705" y1="754.2" x2="740.8" y2="754.2" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="704.8" y1="757.5" x2="732" y2="757.5" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="731.7" y1="757.6" x2="735.8" y2="754.2" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="740.5" y1="754.1" x2="744.6" y2="757.5" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="744.4" y1="757.1" x2="744.4" y2="760.6" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="743.9" y1="760.6" x2="748.3" y2="760.6" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="748.5" y1="761.1" x2="748.5" y2="749.2" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="740.6" y1="754.2" x2="740.6" y2="749" />
                                    <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="735.3" y1="754.2" x2="735.3" y2="749" />
                                </g>
                            </g>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="707.4" y1="741.3" x2="704.7" y2="732.7" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="707.4" y1="757.7" x2="704.7" y2="766.3" />
                        </g>
                        <g>
                            <circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="745" cy="994.1" r="31.9" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="745" cy="994.1" r="18.1" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="745" cy="994.1" r="3.6" />
                        </g>
                        <g>
                            <circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="745" cy="1074.8" r="31.9" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="745" cy="1074.8" r="18.1" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="745" cy="1074.8" r="3.6" />
                        </g>
                        <g>
                            <circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="744.3" cy="1154.9" r="31.9" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="744.3" cy="1154.9" r="18.1" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="744.3" cy="1154.9" r="3.6" />
                        </g>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M707.6,458.7" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M723.7,710.2h-6.4c-0.8,0-1.6-0.2-2.4-0.5
			c0,0-0.1,0-0.1-0.1c-1.9-0.9-3-2.9-3-5v-5.8"/>
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="711.8" y1="655.3" x2="711.8" y2="485.4" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="752.5" y1="645.1" x2="752.5" y2="631.3" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="752.5" y1="528.7" x2="752.5" y2="525.5" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="760.7" y1="525.5" x2="760.7" y2="485.4" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="736.1" y1="516.5" x2="736.1" y2="494.6" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="711.4" y1="485.7" x2="760.7" y2="485.7" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="711.4" y1="494.6" x2="760.7" y2="494.6" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="711.8" y1="516.5" x2="761" y2="516.5" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="716" y1="550.1" x2="758.9" y2="550.1" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="716" y1="546.2" x2="758.9" y2="546.2" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="716" y1="583.9" x2="758.9" y2="583.9" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="716" y1="580" x2="758.9" y2="580" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="716" y1="614.8" x2="758.9" y2="614.8" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="716" y1="610.9" x2="758.9" y2="610.9" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="711.8" y1="525.1" x2="761" y2="525.1" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M758.9,628v-96.1c0-1.8-1.5-3.3-3.3-3.3h-36.4
			c-1.8,0-3.3,1.5-3.3,3.3V628c0,1.8,1.5,3.3,3.3,3.3h36.4C757.4,631.3,758.9,629.8,758.9,628z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M717.4,710.5v3.8c0,0,0.1,1,1.5,1h10.3
			c0,0,1.5,0.3,1.7-1.6"/>
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="711.9" y1="609.6" x2="704.7" y2="621.5" />
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M746.2,642.2c-20.4-0.1-36.6,15.6-36.6,34.9
				c0,19.8,15.3,34.6,36.6,34.6"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M746.2,638.2c-27.3,0-40.4,19.6-40.4,38.9
				c0,19.4,13.7,39.1,40.4,38.6"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="746.2" y1="637.7" x2="746.2" y2="642.7" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="746.7" y1="711.2" x2="746.7" y2="716.2" />
                        </g>
                        <g>
                            <circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="745.1" cy="677" r="31.9" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="745.1" cy="677" r="18.1" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="745.1" cy="677" r="3.6" />
                        </g>
                        <g>
                            <circle fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" cx="745.1" cy="441.4" r="31.9" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="745.1" cy="441.4" r="18.1" />
                            <circle fill="none" stroke="#000000" stroke-miterlimit="10" cx="745.1" cy="441.4" r="3.6" />
                        </g>
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M707.7,490.9l-112-2.3H592c0,0-8.2-2.3-9.1-10.2l-2-79.9l1.6-29.1
			c0,0-0.7-10.9,9.6-14.1l58.1-15.3h8.8h39.6c0,0,6.2,0.2,6.2,4.7h6.9l5.4-6c0.5-0.6,1.3-0.9,2-0.9h35.5c0,0,2.8,0.1,5.4,0.9
			c2.6,0.8,4.3,3.2,4.3,5.9v59.8h-24.8c0,0-25.7,0.3-31.9,26.3L707.7,490.9z"/>
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M592.6,355c0,0,0.8,2.4,2.9,2.4l52.6-13.4c0,0,1,0.1,1.2-4" />
                        <path fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" d="M599.3,446.7v-76.3c0,0,0-5.4,3.7-6l38.8-5.9
			c0,0,4.6-0.6,4.6,3.6v27c0,2.7-0.5,5.3-1.6,7.7l-7.1,16.2c-1,2.2-1.5,4.5-1.6,6.9l-0.8,24.7c-0.1,2.6-2.1,4.7-4.7,4.9h-0.1H603
			c-0.8,0-1.5-0.2-2.2-0.5C600,448.6,599.3,447.9,599.3,446.7z"/>
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="595.2" y1="488.3" x2="595.2" y2="363.9" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="592" y1="488.3" x2="592" y2="363.9" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="595.7" y1="363.9" x2="591.5" y2="363.9" />
                        <rect x="600.2" y="428.3" fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" width="34.7" height="6.2" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="636.3" y1="431.3" x2="692.9" y2="431.3" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M692.5,490.3V431c0,0-2.3-23.4,28-30.5h43.3" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="704.6" y1="407.7" x2="704.6" y2="345" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="659.7" y1="421.9" x2="659.7" y2="340.1" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="669.4" y1="489.9" x2="669.4" y2="431.3" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="688.5" y1="490.3" x2="688.5" y2="431.8" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M688.5,431.3v-9.8c0-2.2-1.5-4.1-3.3-4.1h-12.5
			c-1.8,0-3.3,1.8-3.3,4.1v9.8"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M685.6,431.3v-6.7c0-1.5-1-2.8-2.2-2.8h-8.6
			c-1.2,0-2.2,1.3-2.2,2.8v6.7"/>
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="672.5" y1="428.2" x2="685.6" y2="428.2" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M604.3,476.8v-23c0-0.4-0.4-0.8-0.8-0.8h-4.8
			c-0.4,0-0.8,0.4-0.8,0.8v23c0,0.4,0.4,0.8,0.8,0.8h4.8C603.9,477.6,604.3,477.2,604.3,476.8z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M612.1,476.8v-23c0-0.4-0.4-0.8-0.8-0.8h-4.8
			c-0.4,0-0.8,0.4-0.8,0.8v23c0,0.4,0.4,0.8,0.8,0.8h4.8C611.8,477.6,612.1,477.2,612.1,476.8z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M620,476.8v-23c0-0.4-0.4-0.8-0.8-0.8h-4.8
			c-0.4,0-0.8,0.4-0.8,0.8v23c0,0.4,0.4,0.8,0.8,0.8h4.8C619.6,477.6,620,477.2,620,476.8z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M627.8,476.8v-23c0-0.4-0.4-0.8-0.8-0.8h-4.8
			c-0.4,0-0.8,0.4-0.8,0.8v23c0,0.4,0.4,0.8,0.8,0.8h4.8C627.4,477.6,627.8,477.2,627.8,476.8z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M635.5,476.8v-23c0-0.4-0.4-0.8-0.8-0.8h-4.8
			c-0.4,0-0.8,0.4-0.8,0.8v23c0,0.4,0.4,0.8,0.8,0.8h4.8C635.1,477.6,635.5,477.2,635.5,476.8z"/>
                        <g>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M728.2,398.1v-40.3c0-0.4-0.3-0.6-0.6-0.6H713
				c-0.4,0-0.6,0.3-0.6,0.6v40.3c0,0.4,0.3,0.6,0.6,0.6h14.6C727.9,398.7,728.2,398.4,728.2,398.1z"/>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M728.2,398.1v-38.4c0-0.3-0.2-0.6-0.5-0.6H716
				c-0.3,0-0.5,0.3-0.5,0.6v38.4c0,0.3,0.2,0.6,0.5,0.6h11.6C727.9,398.7,728.2,398.4,728.2,398.1z"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M745.4,398.1v-40.3c0-0.4-0.3-0.6-0.6-0.6
				h-14.6c-0.4,0-0.6,0.3-0.6,0.6v40.3c0,0.4,0.3,0.6,0.6,0.6h14.6C745.1,398.7,745.4,398.4,745.4,398.1z"/>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M745.4,398.1v-38.4c0-0.3-0.2-0.6-0.5-0.6
				h-11.6c-0.3,0-0.5,0.3-0.5,0.6v38.4c0,0.3,0.2,0.6,0.5,0.6h11.6C745.2,398.7,745.4,398.4,745.4,398.1z"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M762.7,398.1v-40.3c0-0.4-0.3-0.6-0.6-0.6
				h-14.6c-0.4,0-0.6,0.3-0.6,0.6v40.3c0,0.4,0.3,0.6,0.6,0.6H762C762.4,398.7,762.7,398.4,762.7,398.1z"/>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M762.7,398.1v-38.4c0-0.3-0.2-0.6-0.5-0.6
				h-11.6c-0.3,0-0.5,0.3-0.5,0.6v38.4c0,0.3,0.2,0.6,0.5,0.6h11.6C762.4,398.7,762.7,398.4,762.7,398.1z"/>
                        </g>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M736.9,347.3v-9.7h-14.5v12.7h11.5
			C735.5,350.4,736.9,349,736.9,347.3z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M749.3,347.3v-9.7h-4.2v12.7h1.2
			C748,350.4,749.3,349,749.3,347.3z"/>
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M619.3,489.9v11.2c0,0,0,4.9,7,4.9h85.4" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="711.8" y1="494.6" x2="707.5" y2="490.9" />
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M707.6,459c0,0,17.3,17.1,42.4,20h2.3c0.8,0,1.5,0.2,2.2,0.5
			c0.9,0.5,1.9,1.6,1.6,3.7l-27.6-0.2c-1.4,0-2.7-0.3-3.9-0.9c-1.3-0.6-2.6-1.4-2.8-2.5V477c0-0.4-0.2-0.8-0.5-1
			c-2.2-1.5-10.8-7.1-13.6-8"/>
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M760.1,404.3c0,0-4.5,0.8-6.5,5.7" />
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M748.4,474c0,0,0,3,2.6,5.1" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M641.8,374.5h-39.3v-5c0,0,0.6-3.2,4.3-3.6
			c0,0,17.9-3.8,34.8,2.4c0.5,0.2,0.9,0.7,0.9,1.2v4.3C642.4,374.3,642.1,374.5,641.8,374.5z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M651.1,363.1h5.1c0,0,0.8-0.1,0.9-1.8v-14.6
			c0-0.6-0.4-1.1-0.9-1.1h-0.5c-0.7,0-1.4,0.4-1.8,1.1c-1,1.7-2.9,4.8-3.4,6.7l-0.1,8.8C650.3,362.7,650.7,363.1,651.1,363.1z"/>
                        <path fill="#FFFFFF" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M652.3,360.2l-9.8,9.1l-1.5-1.2l11-10.5
			c0.3-0.3,0.8,0,0.8,0.4v1.3C652.6,359.6,652.5,360,652.3,360.2z"/>
                    </g>
                </g>
                <g id="Layer_2_00000094579066947539520630000001056272371906818238_">
                    <g>
                        <rect x="322.1" y="521.9" fill="none" stroke="#000000" stroke-miterlimit="10" width="159.3" height="742.5" />
                        <rect x="326.1" y="526" fill="none" stroke="#000000" stroke-miterlimit="10" width="151.1" height="734.8" />
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M403.6,495.7h-78.7V370.1c0,0-0.4-4.5,2.4-5.3
				c0,0,19.4-1.5,76.3-2.8"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M399.9,495.7h78.7V370.1c0,0,0.4-4.5-2.4-5.3
				c0,0-19.4-1.5-76.3-2.8"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M399.9,342.4c0,0,35-1.3,54.2,2.3c0,0,10.2,1.8,17.9,1.8
				c0,0,10.7,0.4,10.7,5.1v54.9c0,0.6,0.5,1.2,1.1,1.3c0.8,0.2,1.8,0.6,2,2c0,0,4.1,54.3,2.8,87.9h-8.4v23.8"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M405.8,342.4c0,0-35-1.3-54.2,2.3c0,0-10.2,1.8-17.9,1.8
				c0,0-10.7,0.4-10.7,5.1v54.9c0,0.6-0.5,1.2-1.1,1.3c-0.8,0.2-1.8,0.6-2,2c0,0-4.1,54.3-2.8,87.9h8.4v23.8"/>
                        </g>
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="337.6" y1="496.2" x2="337.6" y2="521.5" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="465.9" y1="496.2" x2="465.9" y2="521.4" />
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M463.9,496.2v11c0,2.7-2.2,4.9-4.9,4.9H345.2
			c-2.7,0-4.9-2.2-4.9-4.9v-11"/>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M402.1,408.3c0,0,46.2-0.4,70.3,9.1c0,0,2.3,1.2,3,3.6
				c0.3,1-0.2,2-1.1,2.5c-1.2,0.7-2.9,2-3,3.6l4.3,55.4c0,0,0.5,5-3.2,9.7c-0.7,1-1.9,1.5-3.1,1.5h-67.2"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M402.1,408.3c0,0-46.2-0.4-70.3,9.1c0,0-2.3,1.2-3,3.6
				c-0.3,1,0.2,2,1.1,2.5c1.2,0.7,2.9,2,3,3.6l-4.3,55.4c0,0-0.5,5,3.2,9.7c0.7,1,1.9,1.5,3.1,1.5h67.2"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.3,368.5c0,0,44.9-0.6,73.1,3c0,0,2.2,2.2,0,4
				c0,0-53-3.2-70.9-2.9"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.3,368.5c0,0-44.6-0.6-72.8,3c0,0-2.2,2.2,0,4
				c0,0,58.2-3.4,75-2.9"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.1,377.7c0,0,44.9-0.6,73.1,3c0,0,2.2,2.2,0,4
				c0,0-53-3.2-70.9-2.9"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.1,377.7c0,0-44.6-0.6-72.8,3c0,0-2.2,2.2,0,4
				c0,0,58.2-3.4,75-2.9"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.1,387.3c0,0,44.9-0.6,73.1,3c0,0,2.2,2.2,0,4
				c0,0-53-3.2-70.9-2.9"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.1,387.3c0,0-44.6-0.6-72.8,3c0,0-2.2,2.2,0,4
				c0,0,58.2-3.4,75-2.9"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.1,396.4c0,0,44.9-0.6,73.1,3c0,0,2.2,2.2,0,4
				c0,0-53-3.2-70.9-2.9"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.1,396.4c0,0-44.6-0.6-72.8,3c0,0-2.2,2.2,0,4
				c0,0,58.2-3.4,75-2.9"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M402.1,359.4c0,0,59.4,0.8,76.5,3.6l1.3-9.3
				c0.2-1.4-0.6-2.7-1.9-3.1c-1.5-0.5-7.8-1.1-11.3-1.3c0,0-49-1.8-64.6-1.8"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M402.1,359.4c0,0-59.4,0.8-76.5,3.6l-1.3-9.3
				c-0.2-1.4,0.6-2.7,1.9-3.1c1.5-0.5,7.8-1.1,11.3-1.3c0,0,49-1.8,64.6-1.8"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M320.9,383.5h-17.2v-7.3c0-1.3,1.1-2.4,2.4-2.4h12.4
				c1.3,0,2.4,1.1,2.4,2.4L320.9,383.5L320.9,383.5z"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="322.9" y1="360.8" x2="314" y2="373.8" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="323.1" y1="363.8" x2="316.3" y2="373.8" />
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M484.9,383.5h17.2v-7.3c0-1.3-1.1-2.4-2.4-2.4h-12.4
				c-1.3,0-2.4,1.1-2.4,2.4L484.9,383.5L484.9,383.5z"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="482.9" y1="360.8" x2="491.8" y2="373.8" />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="482.7" y1="363.8" x2="489.5" y2="373.8" />
                        </g>
                    </g>
                </g>
                <g id="Layer_3">
                    <g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M512,141.9h-10.2c-2.6,0-4.7-2.1-4.7-4.7V99
				c0-2.6,2.1-4.7,4.7-4.7H512c2.6,0,4.7,2.1,4.7,4.7v38.2C516.6,139.8,514.5,141.9,512,141.9z"/>
                            <rect x="495" y="149.6" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="2.6" height="10.7" />
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M497.8,151.1c0,0,4.1-2.1,4.1-9.2h3.3
				c0,0,0.2,10.6-7.4,13.5V151.1z"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M292.2,141.8h10.2c2.6,0,4.7-2.1,4.7-4.7V98.9
				c0-2.6-2.1-4.7-4.7-4.7h-10.2c-2.6,0-4.7,2.1-4.7,4.7v38.2C287.6,139.7,289.7,141.8,292.2,141.8z"/>
                            <rect x="306.6" y="149.5" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="2.6" height="10.7" />
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M306.4,151.1c0,0-4.1-2.1-4.1-9.2H299
				c0,0-0.2,10.6,7.4,13.5V151.1z"/>
                        </g>
                        <rect x="309.2" y="25.2" fill="none" stroke="#000000" stroke-miterlimit="10" width="186" height="136.8" />
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M309.2,149.6c0,0,94.9,3.4,186,0" />
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M501.7,202.9c-1.1-2.6-3-3.5-4.5-3.9c-1.2-0.3-2-1.3-2-2.6v-25.3
			c0-1.5-1.2-2.6-2.6-2.6h-90.9h-90.4c-1.5,0-2.6,1.2-2.6,2.6v25.3c0,1.2-0.8,2.3-2,2.6c-1.5,0.3-3.4,1.3-4.5,3.9
			c-0.1,0.3-0.2,0.7-0.2,1.1l2.3,47.6c0,0.5,0.2,0.9,0.4,1.3c0.5,0.7,1.3,1.8,2.2,2.5c0.6,0.5,1,1.3,1,2.1v13.9c0,0.1,0,0.1,0,0.2
			c0,0.7,0.4,5.1,2.5,8.5c1.1,1.8,3.1,2.9,5.2,2.9h30.6c0.5,0,1.1-0.2,1.5-0.5c1-0.7,2.8-1.9,4.1-1.9h49.9h0.5h49.9
			c1.3,0,3.1,1.2,4.1,1.9c0.4,0.3,1,0.5,1.5,0.5h30.6c2.1,0,4.1-1.1,5.2-2.9c2.1-3.4,2.4-7.9,2.5-8.5c0-0.1,0-0.1,0-0.2v-13.9
			c0-0.8,0.4-1.6,1-2.1c0.9-0.7,1.7-1.9,2.2-2.5c0.3-0.4,0.4-0.8,0.4-1.3l2.3-47.6C501.9,203.6,501.9,203.3,501.7,202.9z"/>
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="310" y1="162.5" x2="310" y2="169" />
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="494.4" y1="162.5" x2="494.4" y2="169" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M337,283.1v13.7c0,2.9-2.5,5.3-5.6,5.3h-13.1
			c-3.1,0-5.6-2.4-5.6-5.3v-13.7"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M491.8,282.4v13.7c0,2.9-2.5,5.3-5.6,5.3h-13.1
			c-3.1,0-5.6-2.4-5.6-5.3v-13.7"/>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M310.5,169.6l28.9,2.8c2,0.2,4,1.1,5.3,2.7c0.7,0.9,1.3,2,1.4,3.3
				l4,34.6h-36.8c0,0-1.3,0.1-2.6-0.8c-1-0.8-1.5-2-1.5-3.3v-7.1v-31C309.2,170.1,309.8,169.6,310.5,169.6z"/>
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="309.2" y1="201.1" x2="331.9" y2="201.1" />
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M493.9,169.6l-28.9,2.8c-2,0.2-4,1.1-5.3,2.7
				c-0.7,0.9-1.3,2-1.4,3.3l-4,34.6h36.8c0,0,1.3,0.1,2.6-0.8c1-0.8,1.5-2,1.5-3.3v-7.1v-31C495.2,170.1,494.6,169.6,493.9,169.6z"
                            />
                            <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="495.2" y1="201.1" x2="472.5" y2="201.1" />
                        </g>
                        <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M440.6,211.3h-77.1c-1.2,0-2.3-0.9-2.5-2.1l-5.2-35
			c-0.2-1.2,0.8-2.4,2-2.4h88.3c1.3,0,2.2,1.1,2,2.4l-5.2,35C442.9,210.4,441.9,211.3,440.6,211.3z"/>
                        <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="355.9" y1="175.3" x2="447.2" y2="175.3" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M493.5,227.7l-6.2-8.4
			c-0.6-0.8-1.4-1.2-2.4-1.2H407h-10h-77.9c-0.9,0-1.8,0.4-2.4,1.2l-6.2,8.4c-0.5,0.7-0.8,1.6-0.8,2.5v42.4H397h10h87.3v-42.4
			C494.3,229.3,494,228.4,493.5,227.7z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M337.3,251.6h-23.1c-0.8,0-1.5-0.7-1.5-1.5
			v-12.9c0-1,0.8-1.8,1.8-1.8h18.1c1.2,0,2.2,0.8,2.5,2l2.9,13.4C338,251.2,337.7,251.6,337.3,251.6z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M467.2,251.6h23.1c0.8,0,1.5-0.7,1.5-1.5v-12.9
			c0-1-0.8-1.8-1.8-1.8h-18.1c-1.2,0-2.2,0.8-2.5,2l-2.9,13.4C466.4,251.2,466.8,251.6,467.2,251.6z"/>
                        <rect x="312.6" y="261.8" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="21.2" height="5.6" />
                        <rect x="470.6" y="261.8" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="21.2" height="5.6" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M453,270H350.7c-2,0-3.7-1.5-3.9-3.5l-3.9-33.8
			c-0.2-1.6,1.1-3.1,2.7-3.1h114c1.7,0,3,1.5,2.8,3.2l-4.8,33.2C457.2,268.3,455.3,270,453,270z"/>
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="346" y1="259.8" x2="309.8" y2="259.8" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="494.3" y1="259.8" x2="458.1" y2="259.8" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="317.1" y1="213.1" x2="317.1" y2="218.7" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="486.8" y1="213.1" x2="486.8" y2="218.7" />
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M452.2,266.8H351.5c-1.3,0-2.4-1.1-2.4-2.5
			l-1.2-12.7h108.4l-1.2,12.1C455.1,265.4,453.8,266.8,452.2,266.8z"/>
                        <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M349.4,245.8h106.3c1.4,0,2.5-1,2.5-2.3
			l1.3-11.7H345.1l1.3,11.2C346.3,244.5,347.7,245.8,349.4,245.8z"/>
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="314.1" y1="84.4" x2="309.2" y2="91.1" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="490.4" y1="84.4" x2="495.4" y2="91.1" />
                        <polyline fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" points="313.1,85.8 313.1,29.7 490.4,29.7 
			490.4,84.4 		"/>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M398.2,82.2c0,0,63.8-0.5,90.4,2.4c1,0.1,1.8,0.7,1.8,1.5l1.8,47.8
				c0,0,0.3,2.8-5.2,2.8c0,0-75.4,0.8-89.8,0.8"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M406.2,82.2c0,0-63.8-0.5-90.4,2.4c-1,0.1-1.8,0.7-1.8,1.5
				l-1.8,47.8c0,0-0.3,2.8,5.2,2.8c0,0,75.4,0.8,89.8,0.8"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M401.6,70c0,0,66.6-0.8,84.1,6.8c1.5,0.7,2.8,1.7,3.7,3.1
				c0.9,1.5,1,3.6,1,6.2"/>
                            <path fill="none" stroke="#000000" stroke-miterlimit="10" d="M403,70c0,0-66.6-0.8-84.1,6.8c-1.5,0.7-2.8,1.7-3.7,3.1
				c-0.9,1.5-1,3.6-1,6.2"/>
                        </g>
                    </g>
                </g>
                <g id="Layer_4">
                    <g>
                        <g>

                            <rect x="321.9" y="1295.7" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="158.9" height="161.5" />

                            <rect x="324.5" y="1298.5" fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" width="153.6" height="156.1" />
                        </g>
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="401.4" y1="1298.5" x2="401.4" y2="1454.6" />
                        <line fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" x1="403.1" y1="1298.5" x2="403.1" y2="1454.6" />
                        <g>
                            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="392.2" y1="1298.4" x2="392.2" y2="1454.5" />
                            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="393.5" y1="1298.3" x2="393.5" y2="1454.4" />
                            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M394.1,1311h-2.4c-0.4,0-0.8-0.3-0.8-0.8
				v-6.9c0-0.4,0.3-0.8,0.8-0.8h2.4c0.4,0,0.8,0.3,0.8,0.8v6.9C394.8,1310.6,394.5,1311,394.1,1311z"/>
                            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M394.1,1380.6h-2.4c-0.4,0-0.8-0.3-0.8-0.8
				v-6.9c0-0.4,0.3-0.8,0.8-0.8h2.4c0.4,0,0.8,0.3,0.8,0.8v6.9C394.8,1380.3,394.5,1380.6,394.1,1380.6z"/>
                            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M394.1,1450.2h-2.4c-0.4,0-0.8-0.3-0.8-0.8
				v-6.9c0-0.4,0.3-0.8,0.8-0.8h2.4c0.4,0,0.8,0.3,0.8,0.8v6.9C394.8,1449.9,394.5,1450.2,394.1,1450.2z"/>
                            <g>
                                <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M394,1433.5h-18.1v-8h17.9c0.5,0,1,0.4,1,1
					v6.2C394.8,1433.2,394.5,1433.5,394,1433.5z"/>

                                <rect x="373.6" y="1424.6" fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.3" height="10" />

                                <rect x="375.9" y="1425.6" fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.3" height="8" />
                                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="378.2" y1="1426.9" x2="389.7" y2="1427.9" />
                                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="389.7" y1="1431.2" x2="378.2" y2="1432.2" />
                                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="389.4" y1="1431.4" x2="389.4" y2="1427.7" />
                            </g>
                        </g>
                        <g>
                            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="411.2" y1="1298.4" x2="411.2" y2="1454.5" />
                            <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="410" y1="1298.3" x2="410" y2="1454.4" />
                            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M409.4,1311h2.4c0.4,0,0.8-0.3,0.8-0.8v-6.9
				c0-0.4-0.3-0.8-0.8-0.8h-2.4c-0.4,0-0.8,0.3-0.8,0.8v6.9C408.6,1310.6,409,1311,409.4,1311z"/>
                            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M409.4,1380.6h2.4c0.4,0,0.8-0.3,0.8-0.8
				v-6.9c0-0.4-0.3-0.8-0.8-0.8h-2.4c-0.4,0-0.8,0.3-0.8,0.8v6.9C408.6,1380.3,409,1380.6,409.4,1380.6z"/>
                            <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M409.4,1450.2h2.4c0.4,0,0.8-0.3,0.8-0.8
				v-6.9c0-0.4-0.3-0.8-0.8-0.8h-2.4c-0.4,0-0.8,0.3-0.8,0.8v6.9C408.6,1449.9,409,1450.2,409.4,1450.2z"/>
                            <g>
                                <path fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M409.4,1433.5h18.1v-8h-17.9
					c-0.5,0-1,0.4-1,1v6.2C408.6,1433.2,409,1433.5,409.4,1433.5z"/>

                                <rect x="427.6" y="1424.6" fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.3" height="10" />

                                <rect x="425.3" y="1425.5" fill="#FFFFFF" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.3" height="8" />
                                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="425.2" y1="1426.9" x2="413.8" y2="1427.9" />
                                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="413.8" y1="1431.2" x2="425.2" y2="1432.2" />
                                <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="414" y1="1431.4" x2="414" y2="1427.7" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="341.5,1307 324.5,1308 324.5,1301.9 
					341.5,1302.9 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="328.7,1304.4 326.6,1304.4 
					326.6,1302.1 328.7,1302.3 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="326.6,1305.5 328.7,1305.5 
					328.7,1307.7 326.6,1307.8 				"/>
                                <rect x="338.9" y="1304.2" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.4" height="1.4" />
                            </g>
                            <g>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="341.5,1355.6 324.5,1356.6 
					324.5,1350.5 341.5,1351.5 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="328.7,1353 326.6,1353 326.6,1350.7 
					328.7,1350.9 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="326.6,1354.1 328.7,1354.1 
					328.7,1356.3 326.6,1356.4 				"/>
                                <rect x="338.9" y="1352.8" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.4" height="1.4" />
                            </g>
                            <g>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="341.5,1403.7 324.5,1404.7 
					324.5,1398.6 341.5,1399.6 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="328.7,1401.1 326.6,1401.1 
					326.6,1398.8 328.7,1399 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="326.6,1402.2 328.7,1402.2 
					328.7,1404.4 326.6,1404.6 				"/>
                                <rect x="338.9" y="1401" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.4" height="1.4" />
                            </g>
                            <g>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="341.9,1450.7 324.9,1451.7 
					324.9,1445.6 341.9,1446.6 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="329.1,1448.1 327,1448.1 327,1445.8 
					329.1,1446 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="327,1449.2 329.1,1449.2 
					329.1,1451.4 327,1451.5 				"/>
                                <rect x="339.3" y="1447.9" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.4" height="1.4" />
                            </g>
                        </g>
                        <g>
                            <g>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="460.9,1307 477.9,1308 477.9,1301.9 
					460.9,1302.9 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="473.7,1304.4 475.8,1304.4 
					475.8,1302.1 473.7,1302.3 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="475.8,1305.5 473.7,1305.5 
					473.7,1307.7 475.8,1307.8 				"/>
                                <rect x="461.2" y="1304.2" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.4" height="1.4" />
                            </g>
                            <g>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="460.9,1355.6 477.9,1356.6 
					477.9,1350.5 460.9,1351.5 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="473.7,1353 475.8,1353 475.8,1350.7 
					473.7,1350.9 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="475.8,1354.1 473.7,1354.1 
					473.7,1356.3 475.8,1356.4 				"/>
                                <rect x="461.2" y="1352.9" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.4" height="1.4" />
                            </g>
                            <g>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="460.9,1403.7 477.9,1404.7 
					477.9,1398.6 460.9,1399.6 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="473.7,1401.1 475.8,1401.1 
					475.8,1398.8 473.7,1399 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="475.8,1402.2 473.7,1402.2 
					473.7,1404.4 475.8,1404.6 				"/>
                                <rect x="461.2" y="1400.9" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.4" height="1.4" />
                            </g>
                            <g>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="460.5,1450.7 477.5,1451.7 
					477.5,1445.6 460.5,1446.6 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="473.3,1448.1 475.3,1448.1 
					475.3,1445.8 473.3,1446 				"/>
                                <polygon fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" points="475.3,1449.2 473.3,1449.2 
					473.3,1451.4 475.3,1451.5 				"/>
                                <rect x="460.7" y="1447.9" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="2.4" height="1.4" />
                            </g>
                        </g>
                        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M325.4,1457.1c0,0-1.5,0.3-2.6,1.8
			c-0.6,0.8-0.9,1.8-0.9,2.9v34.1c0,1,0.3,1.9,1,2.5c0.4,0.4,0.9,0.7,1.6,0.7h45.9v-8.3"/>
                        <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M478,1457.1c0,0,1.5,0.3,2.6,1.8
			c0.6,0.8,0.9,1.8,0.9,2.9v34.1c0,1-0.3,1.9-1,2.5c-0.4,0.4-0.9,0.7-1.6,0.7H433v-8.3"/>
                        <rect x="321.9" y="1476.6" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="159.6" height="14" />
                        <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="370.5" y1="1476.5" x2="370.5" y2="1457.4" />
                        <line fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" x1="433.6" y1="1476.4" x2="433.6" y2="1457.3" />
                        <rect x="326.8" y="1479" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="34.8" height="9.6" />
                        <rect x="442.2" y="1478.8" fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" width="34.8" height="9.6" />
                        <g>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M345.5,1499.1v27.7c0,1.5-1.2,2.7-2.6,2.7
				h-15.6c-1.5,0-2.6-1.2-2.6-2.7v-27.7"/>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M368.3,1499.1v27.7c0,1.5-1.2,2.7-2.6,2.7
				h-15.6c-1.5,0-2.6-1.2-2.6-2.7v-27.7"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M455.3,1499.1v27.7c0,1.5-1.2,2.7-2.6,2.7H437
				c-1.5,0-2.6-1.2-2.6-2.7v-27.7"/>
                            <path fill="none" stroke="#000000" stroke-width="0.75" stroke-miterlimit="10" d="M478.1,1499.1v27.7c0,1.5-1.2,2.7-2.6,2.7
				h-15.6c-1.5,0-2.6-1.2-2.6-2.7v-27.7"/>
                        </g>
                        <g>
                            <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M368.3,1508.2h20.6l7.2,4.8
				c0.6,0.4,1.3,0.6,2,0.6h3.6"/>
                            <path fill="none" stroke="#000000" stroke-width="0.5" stroke-miterlimit="10" d="M434.7,1508.2h-20.6l-7.2,4.8
				c-0.6,0.4-1.3,0.6-2,0.6h-3.6"/>
                        </g>
                    </g>
                </g>
            </>
        )
    }
}