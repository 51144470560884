// import "./index.scss";

import {
  CssBaseline,
  Fab,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  resetBranch,
  resetServiceLocation,
  setServiceLocation
} from "../../../../../actions/organisation/actions";
import { IServiceLocation } from "../../../../../reducers/organisation/types";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";

const useStyles = makeStyles(() =>
  createStyles({
    verified: {
      fill: "green"
    }
  })
);
interface ServiceListProps {
  serviceLocations: IServiceLocation[];
  isUpdateSuccessful: boolean;
  setIsUpdateSuccessful: (isUpdateSuccessful: boolean) => void;
}

export const columns = [
  {
    label: "Service Location",
    name: "name"
  },
  {
    label: "Address",
    name: "address",
    options: {
      customBodyRender: (value: any) => value.fullAddress || value.street
    }
  },
  {
    label: "Associated Branch",
    name: "branch.name"
  }
];

export const VerifiedFormatter = (value: any) => {
  const classes = useStyles();
  return value.value && <CheckCircleOutlineIcon className={classes.verified} />;
};
const ServiceLocationList: React.FC<ServiceListProps> = (props) => {
  const [selection, setSelection] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [rowSelected, setRowSelected] = useState<any[]>([]);
  const [updateButtonVisible, setUpdateButtonVisible] = useState<boolean>(
    false
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.serviceLocations) {
      setRows(props.serviceLocations);
    }
  }, [props.serviceLocations]);

  useEffect(() => {
    if (props.isUpdateSuccessful) {
      setUpdateButtonVisible(false);
      setRowSelected([]);
      props.setIsUpdateSuccessful(false);
    }
  }, [props.isUpdateSuccessful]);

  return (
    <Grid container item xs={12}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h2">Service Location List</Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Update"
          disabled={!updateButtonVisible}
          className={
            updateButtonVisible ? "deleteButton" : "deleteButtonDisable"
          }
          onClick={() => {
            dispatch(
              setServiceLocation({
                ...props.serviceLocations.filter(
                  (element: IServiceLocation) => element.id === selection[0].id
                )[0]
              })
            );
          }}
        >
          Update
        </Fab>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: "20px" }}>
        <NuvvenTable
          title={""}
          rows={rows}
          columns={columns}
          setSelection={setSelection}
          options={{
            elevation: 2,
            rowsSelected: rowSelected,
            onRowSelectionChange(currentRowsSelected, allRowsSelected, rowsSelected) {
              if (currentRowsSelected && !rowsSelected?.length) {
                dispatch(resetBranch());
                setRowSelected([]);
                setUpdateButtonVisible(false);
              } else {
                if (currentRowsSelected.length > 0) {
                  const selectedRow = rows[currentRowsSelected[0].dataIndex];
                  setSelection([selectedRow]);
                  setRowSelected([currentRowsSelected[0].dataIndex]);
                  setUpdateButtonVisible(true);
                }
              }
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ServiceLocationList;
