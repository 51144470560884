import "./AccountSettings.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Checkbox,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField as InputField,
  Paper,
  Theme,
  Typography,
  InputAdornment,
  InputBase,
  Switch,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ApolloError } from "@apollo/client";
import { Field, FieldProps, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import _ from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { DELETE_API_KEY, UPDATE_TENANCY } from "../../../../../graphql/tenancy/updateTenancyMutation";
import { IAddress, IBilling } from "../../../../../reducers/user/types";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import PhoneInput, { Value, isValidPhoneNumber } from "react-phone-number-input";
import { GET_TENANCY } from "../../../../../graphql/tenancy/fetchTenancy";
import AddressPicker from "../../../CustomerManagement/NewCustomer/AddressPicker/AddressPicker";
import ImageUpload from '../../../AppSettings/NewAppSetting/ImageUpload';
import { withStyles } from '@mui/styles';
import { getDefaultCountryCode, getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../../../common/utils";
import DeleteIcon from '@mui/icons-material/Delete';
import { CreateApiKeyModal } from "./CreateApiKeyModal";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { UserRoles } from "../../../../hoc/Authorization";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxLabel: {
      fontSize: "16px !important"
    },
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    },
    save: {
      background: "#faaf40",
      color: "#fff",
      border: "none",
      width: "124px",
      height: "40px",
      fontSize: "14px",
      cursor: "pointer"
    },
    table: {
      minWidth: 650,
    },
  })
);

const StyledInputBase = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5,
    },
    error: {
      '& input': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
      },
      '& textarea': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
      },
      '& .MuiSelect-select': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        backgroundColor: '#fff !important',
      }
    },
    input: {
      borderRadius: "4px !important",
      position: 'relative',
      backgroundColor: theme.palette.common.white + "!important",
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '12px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      minHeight: 'initial'
    },
  }),
)(InputBase);


interface ITenancyUpdate {
  name: string;
  emailId: string;
  address: IAddress;
  logoUri: string;
  phoneNumber: IPhoneNumber;
  billing: IBilling;
  appLogo: string;
  themeColor: string;
  enableEpyxIntegration: boolean;
}

interface IPhoneNumber {
  phone: Value;
  country: string;
}
export const AccountSettings = () => {
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const tenancySettings = _.get(userState, "tenancy");
  const snackbar = useSnackBar();
  const [countryCode, setCountryCode] = useState<any>(getDefaultCountryCode(country));
  const [phoneError, setPhoneError] = useState<string>("");
  const [customThemeActive, setCustomThemeActive] = useState<boolean>(false);
  const disabledRoles = ["FLEET_MANAGER"];
  const [openApiKeyModal, setOpenApiKeyModal] = useState(false);
  const [openDeleteApiKey, setOpenDeleteApiKey] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [selectedApiKey, setSelectedApiKey] = useState("");

  const [getTenancy, { loading: tenancyLoading, data: tenancyData }] = useLazyQuery(GET_TENANCY, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [values, setValues] = useState<ITenancyUpdate>({
    address: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    billing: {
      address: {
        city: "",
        country: "",
        fullAddress: "",
        state: "",
        street: "",
        zipcode: ""
      },
      billingAddressSame: false,
      emailId: "",
      name: "",
      phoneNumber: {
        phone: "" as Value,
        country: ""
      }
    },
    emailId: "",
    logoUri: "",
    name: "",
    phoneNumber: {
      phone: "" as Value,
      country: ""
    },
    appLogo: "",
    themeColor: "",
    enableEpyxIntegration: false
  });

  const [updateTenancyMutation] = useMutation(UPDATE_TENANCY, {
    onCompleted: () => {
      snackbar({
        message: "Account settings saved successfully",
        variant: SnackBarVariant.SUCCESS
      });
      window.location.reload();
    },
    onError: () =>
      snackbar({
        message: "Error while saving account settings",
        variant: SnackBarVariant.ERROR
      })
  });

  const [deleteApiKey, { loading: loadingDeleteApiKey }] = useMutation(DELETE_API_KEY, {
    onCompleted: (data: any) => {
      getTenancy({
        variables: {
          tenancyId: userState.currentTenancy.id
        }
      })
      snackbar({
        message: "API key has been revoked successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setOpenDeleteApiKey(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
      setOpenDeleteApiKey(false);
    }
  });

  useEffect(() => {
    if (userState && userState.currentTenancy) {
      if (userState.currentTenancy.id) {
        getTenancy({
          variables: {
            tenancyId: userState.currentTenancy.id
          }
        });
      }
      if (userState.currentTenancy.appLogo || userState.currentTenancy.themeColor) {
        setCustomThemeActive(true);
      }
    }
  }, [userState]);

  useEffect(() => {
    if (tenancyData && tenancyData.tenancy) {
      const { tenancy } = tenancyData;
      setApiKeys(tenancy.apiKeys);
      setValues({
        emailId: tenancy ? tenancy.emailId : "",
        name: tenancy ? tenancy.name : "",
        address: {
          city: tenancy ? _.get(tenancy, "address.city", "") : "",
          country: tenancy
            ? _.get(tenancy, "address.country", "")
            : "",
          fullAddress: tenancy
            ? _.get(tenancy, "address.fullAddress", "")
            : "",
          state: tenancy
            ? _.get(tenancy, "address.state", "")
            : "",
          street: tenancy
            ? _.get(tenancy, "address.street", "")
            : "",
          zipcode: tenancy
            ? _.get(tenancy, "address.zipcode", "")
            : ""
        },
        logoUri: tenancy ? tenancy.logoUri : "",
        phoneNumber: {
          phone: tenancy && tenancy.phoneNumber && tenancy.phoneNumber.phone ? tenancy.phoneNumber.phone : "",
          country: tenancy && tenancy.phoneNumber && tenancy.phoneNumber.country ? tenancy.phoneNumber.country : ""
        },
        billing: {
          billingAddressSame: tenancy
            ? tenancy.billing.billingAddressSame
            : false,
          emailId: tenancy ? tenancy.billing.emailId : "",
          name: tenancy ? tenancy.billing.name : "",
          phoneNumber: {
            phone: tenancy && tenancy.billing.phoneNumber && tenancy.billing.phoneNumber.phone ? tenancy.billing.phoneNumber.phone : "",
            country: tenancy && tenancy.billing.phoneNumber && tenancy.billing.phoneNumber.country ? tenancy.billing.phoneNumber.country : ""
          },
          address: {
            city: tenancy ? tenancy.billing.address.city : "",
            country: tenancy
              ? tenancy.billing.address.country
              : "",
            fullAddress: tenancy
              ? tenancy.billing.address.fullAddress
              : "",
            state: tenancy ? tenancy.billing.address.state : "",
            street: tenancy ? tenancy.billing.address.street : "",
            zipcode: tenancy
              ? tenancy.billing.address.zipcode
              : ""
          }
        },
        appLogo: tenancy?.appLogo || "",
        themeColor: tenancy?.themeColor || "",
        enableEpyxIntegration: tenancy.enableEpyxIntegration || false
      })
    }
  }, [tenancyData]);

  const tenancySettingsSchema = Yup.object().shape({
    name: Yup.string().required("Registered name is required."),
    emailId: Yup.string()
      .email("Please enter a valid email address.")
      .required("Email is required."),
    address: Yup.object().shape({
      fullAddress: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      street: Yup.string().required("Street is required"),
    }).required("Address is required"),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required.")
    }),
    billing: Yup.object().shape({
      address: Yup.object().shape({
        fullAddress: Yup.string().required("Address is required"),
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required"),
      }).required("Address is required")
    }),
    themeColor: Yup.string().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
  });

  const validateThemeInput = (value: any, required: boolean) => {
    let error;
    if (required && !value) {
      error = "Required"
    }
    return error;
  }

  const handleFormSubmit = (values: ITenancyUpdate) => {
    const updateValues = {
      ...values,
      phoneNumber: {
        ...values.phoneNumber,
        country: countryCode
      }
    }
    if (!values.address.fullAddress || !values.billing.address.fullAddress) {
      if (!values.address.fullAddress) {
        snackbar({
          message: "Registered address is required.",
          variant: SnackBarVariant.ERROR
        });
      }
      if (!values.billing.address.fullAddress) {
        snackbar({
          message: "Billing address is required.",
          variant: SnackBarVariant.ERROR
        });
      }
    } else {
      updateTenancyMutation({
        variables: {
          tenancyId: tenancyData.tenancy.id,
          tenancy: updateValues
        }
      });
    }
  };
  const handleBillingAddress = (event: any) => {
    if (event.target.checked) {
      setValues({
        ...values,
        billing: {
          ...values.billing,
          address: values.address,
          billingAddressSame: true
        }
      });
    } else {
      setValues({
        ...values,
        billing: {
          ...values.billing,
          address: {
            street: tenancySettings
              ? tenancySettings?.billing?.address?.street
              : "",
            city: tenancySettings ? tenancySettings?.billing?.address?.city : "",
            state: tenancySettings ? tenancySettings?.billing?.address?.state : "",
            country: tenancySettings
              ? tenancySettings?.billing?.address?.country
              : "",
            zipcode: tenancySettings
              ? tenancySettings?.billing?.address?.zipcode
              : "",
            fullAddress: tenancySettings
              ? tenancySettings?.billing?.address?.fullAddress
              : ""
          },
          billingAddressSame: false
        }
      });
    }
  };

  if (tenancyLoading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          Account Settings
        </Typography>
      </Grid>
      {(!disabledRoles.includes(userState.role)) && (
        <Grid container item xs={12}>
          <Paper className={classes.root}>
            <Formik
              enableReinitialize
              validationSchema={tenancySettingsSchema}
              initialValues={values}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values);
                setSubmitting(false);
              }}
            >
              {(props) => (
                <Form>
                  <Grid container>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item container xs={12}>
                        <Typography variant="h2" gutterBottom>
                          ACCOUNT SETTINGS
                        </Typography>
                      </Grid>
                      <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          placeholder="e.g <your brand> Vehicle Rental"
                          label="Registered Name"
                          name={"name"}
                          value={props.values.name}
                          onChange={props.handleChange}
                          fullWidth
                          required
                          inputProps={{ maxLength: 50 }}
                        ></Field>
                      </Grid>
                      <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                        <AddressPicker
                          fieldName="address"
                          required
                          onChange={(address: IAddress) => {
                            if (address) {
                              setValues({
                                ...props.values,
                                address
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid
                        className={classes.section}
                        style={{ marginBottom: 0 }}
                        item
                        container
                        xs={12}
                        spacing={1}
                      >
                        <Grid item container xs={12}>
                          <Typography variant="h3" gutterBottom>
                            BILLING DETAILS
                          </Typography>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                          >
                            <Field
                              component={TextField}
                              name={"phoneNumber.phone"}
                              required
                            >
                              {({ field, form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                                return (
                                  <div className={`phone-input-container`}>
                                    <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                                      Phone Number <sup className={"phone-input-required"}>*</sup>
                                    </label>
                                    <PhoneInput
                                      international={false}
                                      defaultCountry={countryCode}
                                      placeholder="Enter phone number"
                                      className={phoneError ? "phone-input-error" : ""}
                                      value={props.values.phoneNumber.phone}
                                      name={"phoneNumber.phone"}
                                      required
                                      onChange={(val: any) => {
                                        if (val && isValidPhoneNumber(val)) {
                                          setPhoneError("");
                                        }
                                        setValues({
                                          ...props.values,
                                          phoneNumber: {
                                            ...props.values.phoneNumber,
                                            phone: val
                                          }
                                        });
                                      }}
                                      onBlur={(val) => {
                                        if (!props.values.phoneNumber.phone) {
                                          setPhoneError("Phone number is required.");
                                        } else if (!isValidPhoneNumber(props.values.phoneNumber.phone)) {
                                          setPhoneError("Enter a valid phone number.");
                                        } else {
                                          setPhoneError("");
                                        }
                                      }}
                                      onCountryChange={(val) => {
                                        setCountryCode(val);
                                      }}
                                    />
                                    {
                                      phoneError !== "" ?
                                        <span className={"phone-error-message"}>{phoneError}</span>
                                        : (meta.touched && meta.error) &&
                                        <span className={"phone-error-message"}>
                                          {phoneError || "Phone number is required."}
                                        </span>
                                    }
                                  </div>
                                );
                              }}
                            </Field>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                          >
                            <Field
                              component={TextField}
                              placeholder="Email Address"
                              label="Email Address"
                              name={"emailId"}
                              value={props.values.emailId}
                              onChange={props.handleChange}
                              inputProps={{ maxLength: 45 }}
                              fullWidth
                              required
                            ></Field>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                          ></Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                          >
                            {!props.values.billing.billingAddressSame ? (
                              <AddressPicker
                                fieldName="billing.address"
                                required={!props.values.billing.billingAddressSame}
                                onChange={(address: IAddress) => {
                                  if (address) {
                                    setValues({
                                      ...props.values,
                                      billing: {
                                        ...props.values.billing,
                                        address
                                      }
                                    });
                                  }
                                }}
                              />
                            ) : (
                              <InputField
                                placeholder="Trading Address"
                                label="Billing Address"
                                disabled
                                value={props.values.address.fullAddress}
                                fullWidth
                              ></InputField>
                            )}
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      props.values.billing.billingAddressSame
                                    }
                                    onChange={handleBillingAddress}
                                    color="secondary"
                                    name={""}
                                  />
                                }
                                label={
                                  <Typography variant="body1">
                                    {"Use Registered Address"}
                                  </Typography>
                                }
                                classes={{ label: classes.checkboxLabel }}
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: 0 }}>
                        <Typography variant="h2" gutterBottom>
                          ENABLE EPYX
                        </Typography>
                        <Typography variant="subtitle2" style={{ margin: '4px 0 8px 0', color: '#5a5a5a' }}>
                          Once you enabled the epyx integration, Epyx account number and scheme reference number will be required to update every branch, under branch settings.
                        </Typography>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Switch
                            checked={props.values.enableEpyxIntegration}
                            size="small"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              setValues({
                                ...values,
                                enableEpyxIntegration: event.target.checked
                              })
                            }}
                          />
                          <Typography variant="h4">Enable Epyx Integration</Typography>
                        </div>
                      </Grid>
                      <Grid container item xs={12} alignItems="center" style={{ marginTop: 10 }}>
                        <Typography variant="h2" gutterBottom>
                          APPLICATION THEME SETTINGS
                        </Typography>
                        <Typography variant="subtitle2" style={{ margin: '4px 0 8px 0', color: '#5a5a5a' }}>
                          Change the default application theme to reflect your brand identity. The theme you select determines the subsequent navigation layout and colour schemes across the web and mobile operator applications.
                        </Typography>
                        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                          <Switch
                            checked={customThemeActive}
                            size="small"
                            onChange={() => {
                              if (customThemeActive) {
                                props.setFieldValue("appLogo", "");
                                props.setFieldValue("themeColor", "");
                              }
                              setCustomThemeActive((prev) => !prev);
                            }}
                          />
                          <Typography variant="h6">Activate custom theme</Typography>
                        </div>
                      </Grid>
                      {customThemeActive && <Grid container item xs={12} lg={8} spacing={4}>
                        <Grid item xs={6} >
                          <Field name="appLogo" validate={(value: any) => validateThemeInput(value, customThemeActive)}>
                            {({ meta: { touched, error }, }: FieldProps) => (
                              <Typography variant="h6" style={{ fontWeight: 700 }}>
                                Company logo *
                                {touched && Boolean(error) && <span className="errorText">Required*</span>}
                              </Typography>
                            )}
                          </Field>
                          <Typography variant="subtitle2" style={{ margin: '4px 0 8px 0', color: '#5a5a5a' }}>
                            Upload your Company's logo.
                          </Typography>
                          <div style={{ width: 145, height: 80, display: "flex" }}>
                            {tenancyData?.tenancy?.id && <ImageUpload isFavIcon contain setImageKey={(key: string, index: number) => { props.setFieldValue("appLogo", key) }} imageKey={props.values.appLogo} imageIndex={0} tenantId={tenancyData.tenancy.id} />}
                          </div>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Field
                            name="themeColor"
                            validate={(value: any) => validateThemeInput(value, customThemeActive)}
                          >
                            {({ field, meta: { touched, error }, }: FieldProps) => (
                              <>
                                <Typography variant="h6" style={{ fontWeight: 700 }}>
                                  Brand theme colour *
                                  {touched && Boolean(error) && <span className="errorText">Invalid colour*</span>}
                                </Typography>
                                <Typography variant="subtitle2" style={{ margin: '4px 0 8px 0', color: '#5a5a5a' }}>Enter hex code of your brand colour (ex : #FFC700)</Typography>
                                <StyledInputBase
                                  fullWidth
                                  placeholder="Put the hex code here"
                                  {...field}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <div className="colorPeek" style={{ background: field.value }}></div>
                                    </InputAdornment>
                                  }
                                  error={touched && Boolean(error)}
                                />
                              </>
                            )}
                          </Field>
                        </Grid>
                      </Grid>}
                      {(userState.role === UserRoles.SUPER_ADMIN || userState.role === UserRoles.ADMIN) && (
                        <>
                          <Grid container xs={12} style={{ marginTop: 30 }}>
                            <Grid item xs={3}>
                              <Typography variant="h3" gutterBottom>
                                API KEYS
                              </Typography>
                            </Grid>
                            <Grid item container xs={9} justifyContent="flex-end">
                              <Fab
                                variant="extended"
                                size={"medium"}
                                aria-label="add"
                                onClick={() => setOpenApiKeyModal(true)}
                              >
                                + create new secret key
                              </Fab>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12}>
                            <TableContainer>
                              <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{ fontWeight: "bold" }}>NAME</TableCell>
                                    <TableCell style={{ fontWeight: "bold" }}>KEY</TableCell>
                                    <TableCell style={{ fontWeight: "bold" }}>CREATED AT</TableCell>
                                    <TableCell style={{ fontWeight: "bold" }}></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {apiKeys && apiKeys.length ? apiKeys.map((apiKey: any) => (
                                    <TableRow key={apiKey.name}>
                                      <TableCell component="th" scope="row">
                                        {apiKey.name}
                                      </TableCell>
                                      <TableCell>{apiKey.secretKey.replace(apiKey.secretKey.substring(4, 32), "**********************")}</TableCell>
                                      <TableCell>{getLocalizedDateFormat(country, apiKey.createdAt, DATE_TYPE.EXPANDED)}</TableCell>
                                      <TableCell>
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedApiKey(apiKey.secretKey);
                                            setOpenDeleteApiKey(true);
                                          }}>
                                          <DeleteIcon />
                                        </span>
                                      </TableCell>
                                    </TableRow>
                                  )) : (
                                    <div style={{ marginTop: 10 }}>
                                      <Typography variant="body1" gutterBottom>
                                        No api keys found
                                      </Typography>
                                    </div>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-start" style={{ marginTop: 20 }}>
                      <Fab
                        variant="extended"
                        size={"medium"}
                        aria-label="add"
                        type="submit"
                        disabled={userState.role === "FINANCE_MANAGER"}
                      >
                        Save
                      </Fab>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
            {openApiKeyModal && (
              <CreateApiKeyModal
                open={openApiKeyModal}
                tenantId={tenancyData && tenancyData.tenancy && tenancyData.tenancy.id}
                handleClose={() => {
                  getTenancy({
                    variables: {
                      tenancyId: userState.currentTenancy.id
                    }
                  })
                  setOpenApiKeyModal(false)
                }}
              />
            )}

            {openDeleteApiKey && (
              <ConfirmationDialog
                isOpen={openDeleteApiKey}
                title={""}
                description={"Are you sure, you want to revoke this api key"}
                confirmText="Revoke key"
                onCancel={() => setOpenDeleteApiKey(false)}
                isLoading={loadingDeleteApiKey}
                onConfirm={() => {
                  deleteApiKey({
                    variables: {
                      tenancyId: tenancyData.tenancy.id,
                      apiKey: selectedApiKey
                    }
                  })
                }}
              />
            )}
          </Paper>
        </Grid>
      )
      }
    </Grid >
  );
};