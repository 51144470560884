import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  MenuItem,
  Collapse,
  IconButton,
  DialogActions,
  Fab,
  Theme,
  InputBase,
  Select,
  Switch,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { Formik, Form, Field, FieldProps, FieldArray } from "formik";
import React from "react";
import UUID from "uuid";
import * as Yup from "yup";
import { IBookingQuestion, ANSWER_TYPES } from "../../../AppSettings/types";
import styles from "../../../AppSettings/NewAppSetting/index.module.css";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IAppState } from "../../../../../store";
import { useSelector } from "react-redux";
import { getLocalizedBookingSyntex } from "../../../../../utils/localized.syntex";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    }
  })
);

const StyledInputBase = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5,
    },
    error: {
      '& input': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
      },
      '& textarea': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main
      },
      '& .MuiSelect-select': {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        backgroundColor: '#fff !important',
      }
    },
    input: {
      borderRadius: "4px !important",
      position: 'relative',
      backgroundColor: theme.palette.common.white + "!important",
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '12px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      minHeight: 'initial'
    },
  }),
)(InputBase);

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 34,
      height: 22,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 4,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: 'var(--theme-primary)',
          borderColor: 'var(--theme-primary)',
        },
      },
    },
    thumb: {
      width: 14,
      height: 14,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[800]}`,
      borderRadius: 22 / 2,
      height: 22,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);

const initialBookingQuestionData: IBookingQuestion = {
  question: '',
  answerType: ANSWER_TYPES.YES_NO,
  required: false,
  expectedAnswer: 'YES',
  options: [''],
  errorMessage: ''
}

enum BookingQuestionType {
  "PRE_BOOKING" = "PRE_BOOKING",
  "VEHICLE_PICKUP" = "VEHICLE_PICKUP"
}

interface IQuestionDialogProps {
  isPreBooking?: boolean,
  open: boolean,
  data: IBookingQuestion;
  questionIndex?: number;
  handleClose: () => void;
  handleSubmit: (data: IBookingQuestion) => void;
  handleDelete: () => void;
}

export const QuestionsDialog: React.FC<IQuestionDialogProps> = (props) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { open, handleClose, data, questionIndex, handleDelete, isPreBooking } = props;  

  const questionsSchema = Yup.object().shape({
    question: Yup.string().required("Question is required"),
    errorMessage: Yup.string().when('required', {
      is: true,
      then: schema => schema.required('Error message is required'),
    }),
    options: Yup.array()
      .when('answerType', {
        is: ANSWER_TYPES.DROP_DOWN,
        then: schema => schema
          .of(
            Yup.string().required()
          )
          .min(1)
      }),
    B2B: Yup.bool().when(['B2B2C', 'mobileApp'], {
      is: false,
      then: schema => schema.oneOf([true], 'Select At least one *'),
    })
  });


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Formik
          enableReinitialize
          initialValues={{
            ...data,
            B2B: isPreBooking ? data.B2B || false : !isPreBooking ? data.B2B : true,
            B2B2C: isPreBooking ? data.B2B2C || false : false
          }}
          validationSchema={questionsSchema}
          onSubmit={(values, { setSubmitting }) => {
            props.handleSubmit(values);
          }}
        >
          {props => (
            <Form>
              <DialogTitle style={{ padding: '16px 35px' }}>
                <Typography variant="h4" style={{ fontWeight: 700, fontSize: 16 }}>
                  {typeof (questionIndex) === 'number' ? 'Edit' : 'Add'} Question
                </Typography>
              </DialogTitle>
              <DialogContent dividers>
                <div className={styles.questionWrapper}>
                  <Grid item xs>
                    <Field name="question">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700 }}>
                            Question *
                            {touched && error && <span className={styles.errorText}>{error === "Max siz exceeded" ? error : "Required*"}</span>}
                          </Typography>
                          <StyledInputBase
                            {...field}
                            placeholder={`Enter the ${getLocalizedBookingSyntex(country)} question`}
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                        </>
                      )}
                    </Field>
                    <Field
                      name="answerType"
                    >
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <Typography variant="h6" style={{ fontWeight: 700, marginTop: 20 }}>
                            Select Answer type *
                            {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                          </Typography>
                          <Select
                            {...field}
                            fullWidth
                            input={<StyledInputBase />}
                            error={touched && Boolean(error)}
                          >
                            <MenuItem value="YES_NO">
                              Yes/No
                            </MenuItem>
                            <MenuItem value="TEXT">
                              Text
                            </MenuItem>
                            <MenuItem value="DROP_DOWN">
                              Drop-down
                            </MenuItem>
                            <MenuItem value="CHECKBOX">
                              Checkbox
                            </MenuItem>
                          </Select>
                        </>
                      )}
                    </Field>
                    <div className={styles.answerOptions}>
                      <Collapse in={props.values.answerType === "DROP_DOWN"}>
                        <div style={{ marginBottom: 15, paddingBottom: 15, borderBottom: '1px solid #e2e2e2' }}>
                          <Typography variant="h6">
                            Drop-down options
                          </Typography>
                          <FieldArray
                            name="options"
                          >
                            {({ push, remove, form }) => <>
                              {props.values.options?.map((option, index) => (
                                <Field key={index} name={`options[${index}]`}>
                                  {({ field, meta: { touched, error }, }: FieldProps) => (
                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                      <StyledInputBase
                                        {...field}
                                        placeholder={`Option ${index + 1} ${(touched && Boolean(error)) ? 'required*' : ''}`}
                                        fullWidth
                                        error={touched && Boolean(error)}
                                      />
                                      <IconButton
                                        onClick={() => remove(index)}
                                        edge="end"
                                        color="inherit"
                                        aria-label="Remove"
                                        component="span"
                                        size="large">
                                        <HighlightOffIcon fontSize="small" />
                                      </IconButton>
                                    </div>
                                  )}
                                </Field>
                              ))}
                              {typeof (form.errors.options) === "string" && <p className={`${styles.errorText} ${styles.left}`}>Add atleast one option</p>}
                              <Typography
                                variant="h6"
                                className={styles.addOptionText}
                                onClick={() => push('')}
                              >
                                + Add option
                              </Typography>
                            </>
                            }
                          </FieldArray>
                        </div>
                      </Collapse>
                      <div style={{ display: 'flex' }} >
                        <Field
                          name="required"
                        >
                          {({ field, form: { setTouched, touched }, meta }: FieldProps) => (
                            <AntSwitch checked={field.value} {...field} />
                          )}
                        </Field>
                        <Typography variant="h6" style={{ fontWeight: 700, marginLeft: 10 }}>Required</Typography>
                      </div>
                      <Collapse in={props.values.required}>
                        {props.values.answerType === "YES_NO" ? <Field
                          name="expectedAnswer"
                        >
                          {({ field, meta: { touched, error }, }: FieldProps) => (
                            <>
                              <Typography variant="h6" style={{ marginTop: 20 }}>
                                Expected  Answer *
                                {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                              </Typography>
                              <Select
                                {...field}
                                fullWidth
                                input={<StyledInputBase />}
                                error={touched && Boolean(error)}
                              >
                                <MenuItem selected value="YES">
                                  Yes
                                </MenuItem>
                                <MenuItem value="NO">
                                  No
                                </MenuItem>
                              </Select>
                            </>
                          )}
                        </Field> : null}
                        <Field name="errorMessage">
                          {({ field, meta: { touched, error }, }: FieldProps) => (
                            <>
                              <Typography variant="h6" style={{ marginTop: 15 }}>
                                Error Message *
                                {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                              </Typography>
                              <StyledInputBase
                                {...field}
                                placeholder="Enter the error message"
                                fullWidth
                                error={touched && Boolean(error)}
                              />
                            </>
                          )}
                        </Field>
                      </Collapse>
                    </div>
                    {isPreBooking && (
                      <React.Fragment>
                        <Typography variant="h6" style={{ fontWeight: 700, marginTop: 20 }}>
                          Question appears on *
                          {props.touched.B2B && Boolean(props.errors.B2B) && <span className={styles.errorText}>Select at least one *</span>}
                        </Typography>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Field
                                name="B2B"
                                type="checkbox"
                              >
                                {({ field, meta: { touched, error }, }: FieldProps) => (
                                  <Checkbox
                                    checked={field.value}
                                    onChange={() => props.setFieldValue("B2B", !field.value)}
                                    color="primary"
                                  />
                                )}
                              </Field>
                            }
                            label="Web App"
                          />
                          <span className="padding-left padding-right" />
                          <FormControlLabel
                            control={
                              <Field
                                name="B2B2C"
                                type="checkbox"
                              >
                                {({ field, meta: { touched, error }, }: FieldProps) => (
                                  <Checkbox
                                    checked={field.value}
                                    onChange={() => props.setFieldValue("B2B2C", !field.value)}
                                    color="primary"
                                  />
                                )}
                              </Field>
                            }
                            label="Website"
                          />
                        </FormGroup>
                      </React.Fragment>)}
                  </Grid>
                </div>
              </DialogContent>
              <DialogActions style={{ padding: '20px 35px' }}>
                <div style={{ display: 'flex', flex: 1 }}>
                  {typeof (questionIndex) === 'number' ? <Fab
                    className="redButton"
                    variant="extended"
                    size="small"
                    style={{ alignSelf: "flex-start" }}
                    onClick={() => {
                      handleDelete()
                    }}
                  >
                    Delete
                  </Fab> : null}
                </div>
                <Fab
                  className="blackBackButton"
                  variant="extended"
                  size="small"
                  onClick={handleClose}
                >
                  Cancel
                </Fab>
                <Fab
                  variant="extended"
                  size="small"
                  type="submit"
                >
                  <strong>Save</strong>
                </Fab>
              </DialogActions>
            </Form>
          )}
        </Formik>

      </Dialog>
    </div>
  );
}
