
import styles from "../index.module.css"
import React, { useEffect, useState } from 'react'
import { ISubscriptionInfo, ISuggestedPricing, IVehicle } from '../../../../../reducers/fleet/types';
import { IBookingCreateInput, ISubscriptionArgs } from '../../../../../reducers/bookings/types';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { createStyles, makeStyles } from '@mui/styles';
import { DateTime as d } from "luxon";
import { Box, Card, CardActions, CardContent, CardHeader, CardMedia, CssBaseline, Fab, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Theme, Typography } from '@mui/material';
import { capitalizeFirstLetter, RATE_TYPES } from '../../utils';
import { DATE_TYPE, getFeatureTypeIcon, getVehicleTypeIcon, toCurrency } from '../../../../common/utils';
import { IAppState } from '../../../../../store';
import { useSelector } from 'react-redux';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { IAddons, ISubscriptionPricing } from "../../../../../reducers/Subscription/types";
import FlatPicker from "../../../../common/FlatPicker";
import CustomizeContract from "./CustomizeContract";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      objectFit: "fill"
    },
    cardHeader: {
      background: "#55c2da",
      color: "#fff",
      textAlign: "center"
    },
    actionBtn: {
      backgroundImage: "linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)"
    },
    subButn: {
      background: "none",
      border: "none",
      boxShadow: "none",
      fontSize: 16,
      color: "#fff",
      width: "100%",
      height: 0,
      "&:hover": {
        background: "linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)",
      }
    },
    inclusionItemBox: {
      padding: 5,
      textAlign: "center",
      background: "#ececec",
      borderRadius: 6
    }
  }),
);

interface IProps {
  subscriptionVehicles: IVehicle[];
  bookingDurationInMonths: number;
  bookingDurationInWeeks: number;
  onSelectSubscription: (subscription: ISubscriptionArgs, vehicle: IVehicle, startDate: string) => void;
}

const SelectSubscriptionVehiclesView: React.FC<IProps> = (props) => {

  const classes = useStyles();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { locale, currency } = userState.currentOrganisation;

  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle>();
  const [vehicleImages, setVehicleImages] = useState<any>([]);
  const [vehicles, setVehicles] = useState<IVehicle[]>([])
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>()

  useEffect(() => {
    if (props.subscriptionVehicles && props.subscriptionVehicles.length) {
      const filterActiveSubscriptionVehicles: IVehicle[] = props.subscriptionVehicles
        .map((vehicle) => {
          if (!vehicle || !vehicle.subscriptions.length) return null;

          const activeSubscriptions = vehicle.subscriptions
            .filter(subscription => subscription.subscription.isActive);
          return activeSubscriptions.length
            ? { ...vehicle, subscriptions: activeSubscriptions }
            : null;
        })
        .filter(Boolean) as IVehicle[];
      if (filterActiveSubscriptionVehicles.length) {
        getVehicleImage(filterActiveSubscriptionVehicles);
        setVehicles(filterActiveSubscriptionVehicles);
      }
    }
  }, [props.subscriptionVehicles]);

  const getVehicleImage = async (vehicles: IVehicle[]) => {
    try {
      const imagePromises = vehicles.map(async (vehicle) => {
        if (vehicle && vehicle.imageUrl) {
          try {
            const config = { contentType: 'image/*', level: 'public' };
            const file = await getSignedUrl(vehicle.imageUrl)
            return {
              vehicleId: vehicle.id,
              imageUrl: file,
            };
          } catch (urlError) {
            snackbar({
              message: 'Error fetching signed URL',
              variant: SnackBarVariant.ERROR,
            });
            return null;
          }
        }
        return null;
      });
      const resolvedImages = await Promise.all(imagePromises);
      const validImages = resolvedImages.filter((image) => image !== null);
      setVehicleImages(validImages)
    } catch (error) {
      snackbar({
        message: "Invalid File Type. Allowed type: jpeg or png",
        variant: SnackBarVariant.ERROR
      });
    }
  };

  const getLowestSubscriptionPricing = (vehicles: IVehicle[]): ISuggestedPricing => {
    const subscriptions: ISubscriptionInfo[] = [];

    // Collect all subscriptions
    vehicles.forEach(vehicle => {
      if (vehicle.subscriptions?.length) {
        subscriptions.push(...vehicle.subscriptions);
      }
    });

    // Filter active subscriptions and sort them by rateTypeName
    const activeSubscriptions = subscriptions.filter(subscription => subscription.subscription.isActive);

    // Gather pricing information
    let monthlyPricing: ISuggestedPricing = { subscriptionAmount: Infinity, rateTypeName: 'monthly' };
    let weeklyPricing: ISuggestedPricing = { subscriptionAmount: Infinity, rateTypeName: 'weekly' };

    activeSubscriptions.forEach(subscription => {
      subscription.pricings.forEach(pricing => {
        if (subscription.subscription.rateTypeName === 'monthly') {
          if (pricing.subscriptionAmount < monthlyPricing.subscriptionAmount) {
            monthlyPricing = {
              subscriptionAmount: pricing.subscriptionAmount,
              rateTypeName: 'monthly'
            };
          }
        } else if (subscription.subscription.rateTypeName === 'weekly') {
          if (pricing.subscriptionAmount < weeklyPricing.subscriptionAmount) {
            weeklyPricing = {
              subscriptionAmount: pricing.subscriptionAmount,
              rateTypeName: 'weekly'
            };
          }
        }
      });
    });

    // Return the monthly pricing if available; otherwise, return the weekly pricing
    return monthlyPricing.subscriptionAmount < Infinity ? monthlyPricing : weeklyPricing;
  };

  return (
    <Grid
      container
      item
      sm={11}
      style={{
        marginLeft: "20px",
        marginTop: "20px",
      }}
      spacing={2}
    >
      {
        vehicles && vehicles.length > 0 &&
        vehicles.map((vehicle: IVehicle, index: number) => {
          if (vehicleImages && vehicleImages.length) {
            vehicleImages.forEach((vehicleImage: any, index: number) => {
              if (vehicleImage.imageUrl && vehicleImage.vehicleId === vehicle.id) {
                vehicle = {
                  ...vehicle,
                  imageUrl: vehicleImage.imageUrl
                }
              }
            });
          }
          if (vehicle && vehicle.subscriptions.length) {
            const lowestPrice = getLowestSubscriptionPricing([vehicle])
            if (lowestPrice.subscriptionAmount !== Infinity) {
              return (
                <>
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    container
                  >
                    <Card
                      onClick={() => {
                        setSelectedVehicle(vehicle)
                        setSelectedCardIndex(index + 1)
                      }}
                      style={{ border: selectedVehicle?.id === vehicle.id ? "2px solid red" : "" }}
                      className={styles.vehicle_catalogue}>
                      <CardContent>
                        <Grid container item xs={12}>
                          <CssBaseline />
                          <Grid item xs={12}>
                            <Typography variant="h4" fontSize={18}>{vehicle.licencePlate.toUpperCase()}</Typography>
                          </Grid>
                          <Grid item xs={12}><Box mt={2}></Box></Grid>
                          <Grid item xs={12} container justifyContent={"center"}>
                            <CardMedia
                              className={classes.media}
                              component="img"
                              height="200"
                              style={{ borderRadius: 6 }}
                              image={`${vehicle.imageUrl || getVehicleTypeIcon(capitalizeFirstLetter(vehicle?.bodyType || ""))}`}
                              alt="Paella dish"
                            />
                          </Grid>
                          <Grid item xs={12}><Box mt={2}></Box></Grid>
                          <Grid item xs={4}>
                            <Typography style={{ fontWeight: 600, marginTop: 2, textTransform: "uppercase" }}>{`${vehicle.make} ${vehicle.model}`}</Typography>
                          </Grid>
                          <Grid item xs={8} justifyContent={"flex-end"} container alignItems="baseline">
                            <Typography variant="h3" style={{ fontWeight: 600, textTransform: "uppercase" }}>{toCurrency(lowestPrice.subscriptionAmount, currency, locale)}  </Typography>
                            <Typography variant="h5" style={{ marginLeft: 5 }}>{lowestPrice.rateTypeName} incl VAT/TAX</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography style={{ marginTop: 15 }} variant="h4">Available From: {getLocalizedDateFormat(country, vehicle.availableFrom || "", DATE_TYPE.CONDENSED)}</Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  {
                    selectedCardIndex &&
                    (index + 1) % 3 === 0 &&
                    Math.ceil(selectedCardIndex / 3) === Math.ceil((index + 1) / 3) &&
                    selectedVehicle &&
                    (
                      <CustomizeContract
                        selectedVehicle={selectedVehicle}
                        onSelectSubscription={props.onSelectSubscription}
                      />
                    )
                  }
                </>
              )
            }
          }
        })
      }
      {
        selectedCardIndex &&
        vehicles.length % 3 !== 0 &&
        Math.ceil(selectedCardIndex / 3) === Math.ceil(vehicles.length / 3) &&
        selectedVehicle &&
        (
          <CustomizeContract
            selectedVehicle={selectedVehicle}
            onSelectSubscription={props.onSelectSubscription}
          />
        )
      }
    </Grid>
  )
}

export default SelectSubscriptionVehiclesView
