import { Grid, Paper } from '@mui/material'
import React from 'react'

interface IProps {
  active: boolean
}

const PremiumReports: React.FC<IProps> = ({ active }) => {
  return (
    <Grid container spacing={2} style={{ padding: 20 }}>
      <Paper elevation={3} style={{ flexGrow: 1 }}>
        <iframe title='advance-reports' frameBorder={0} style={{ width: "100%", height: '100vh' }} src="https://eu-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/892175551664/dashboards/c23c6643-a3f4-4042-9a28-d979b1a069c8/sheets/c23c6643-a3f4-4042-9a28-d979b1a069c8_ba858d63-c292-43e2-852a-e20eb76b5868?directory_alias=nuvven"></iframe>
      </Paper>
    </Grid>
  )
}

export default PremiumReports
