import { gql } from "@apollo/client";

export const SEARCH_USER = gql`
  query getPortalUserByCustomerId($customerId: String!) {
    getPortalUserByCustomerId(customerId: $customerId) {
      id
      email
      firstName
      lastName
      phoneNumber {
        phone
        country
      }
      active
      role
      userRole {
        id
        name
        key
        policies {
          id
          permissions
        }
      }
      organisation {
        id
        name
        sepaEnabled
        bacsEnabled
        cashEnabled
        cardEnabled
        offlineBankTransferEnabled
        payByLinkEnabled
        payOnCollectionEnabled
        autoChargeEnabled
      }
      branch {
        id
        name
        depositCollection
      }
    }
  }
`;
