import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IInvoice } from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { DATE_TYPE, formatGraphQLErrorMessage, toCurrency } from "../../../../common/utils";
import { CustomerType } from "../../../ReservationManagement/utils";
import { InvoiceType } from "../../utils";
import { SummaryField } from "./SummaryField";
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { useLazyQuery, useMutation } from "@apollo/client";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { ADD_BOOKING_PO_NUMBER } from "../../../../../graphql/bookings/addBookingPoNumberMutation";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { ApolloError } from "@apollo/client";
import { GET_BOOKING_INVOICE_STATUS } from "../../../../../graphql/invoices/getBookingInvoiceStatus";
import { InvoiceStatus } from "./types";
import { getLocalizedBookingSyntex, getLocalizedDateFormat, getLocalizedInsuranceNumberSyntex } from "../../../../../utils/localized.syntex";
interface Props {
  invoice: IInvoice
  updatePoNumber: any
}

export const InvoiceSummary = (props: Props) => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const { invoice } = props;
  const isBusinessCustomerBooking =
    invoice.businessCustomer &&
      invoice.businessCustomer.id !== ""
      ? true
      : false;
  const businessCustomer = invoice.businessCustomer;
  const customer = invoice.customer;
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [regNumbers, setRegistrationNumbers] = useState<string>("");
  const [isEditablePONumber, setIsEditablePONumber] = useState<boolean>(false);
  const [poNumber, setPoNumber] = useState<string>("");
  const [editablePO, setEditablePO] = useState<boolean>(true);

  const [getbookingInvoiceStatus, { data: invoiceStatusData }] = useLazyQuery(
    GET_BOOKING_INVOICE_STATUS,
    {
      fetchPolicy: "network-only"
    }
  );

  const [addBookingPoNumber] = useMutation(ADD_BOOKING_PO_NUMBER, {
    onCompleted: (data) => {
      snackbar({
        message: `${getLocalizedBookingSyntex(country)} purchase order number updated successfully`,
        variant: SnackBarVariant.SUCCESS
      })
      setPoNumber(data.addBookingPoNumber)
      props.updatePoNumber(data.addBookingPoNumber)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  useEffect(() => {
    if (props.invoice && props.invoice.booking) {
      if (props.invoice.status === InvoiceStatus.VOID) {
        setEditablePO(false)
      }
      getbookingInvoiceStatus({
        variables: {
          booking: props.invoice.booking.id,
          status: [InvoiceStatus.PAID, InvoiceStatus.PARTIALLY_PAID]
        }
      })
    }
  }, [props.invoice, props.invoice.booking])

  useEffect(() => {
    if (props.invoice && props.invoice.booking && props.invoice.booking.currentBookingSchedules) {
      setPoNumber(props.invoice.booking.poNumber || "")
      const registrationNumbers: string[] = [];
      props.invoice.booking.currentBookingSchedules.forEach(schedule => {
        if (schedule.vehicle && schedule.endDate > props.invoice.startDate) {
          registrationNumbers.push(schedule.vehicle.licencePlate)
        }
      })
      if (registrationNumbers.length > 0) {
        setRegistrationNumbers(registrationNumbers.join(", "))
      }
    }
  }, [props.invoice])

  useEffect(() => {
    if (invoiceStatusData && invoiceStatusData.checkBookingInvoiceStatus) {
      setEditablePO(false)
    }
  }, [invoiceStatusData])

  const addPoNumberToBooking = (_poNumber: string) => {
    if (invoice.booking && invoice.booking.id) {
      addBookingPoNumber({
        variables: {
          bookingId: invoice.booking.id,
          poNumber: _poNumber
        }
      })
    }
  }

  return (
    <Grid container>
      <Grid container item xs={3} spacing={1}>
        {isBusinessCustomerBooking && businessCustomer &&
          businessCustomer.businessName && (
            <Grid item xs={12}>
              <SummaryField
                label="Business Name"
                value={businessCustomer?.businessName}
              />
            </Grid>
          )}
        <Grid item xs={12}>
          <SummaryField
            label="Customer Name"
            value={
              isBusinessCustomerBooking
                ? businessCustomer
                  ? `${businessCustomer.contact.firstName} ${businessCustomer.contact.lastName}`
                  : ""
                : customer
                  ? customer.firstName + " " + customer.lastName
                  : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <SummaryField
            label="Customer Email"
            value={
              isBusinessCustomerBooking
                ? businessCustomer
                  ? businessCustomer.billing.email
                  : ""
                : customer
                  ? customer.email
                  : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <SummaryField
            label="Customer Phone Number"
            value={
              isBusinessCustomerBooking
                ? businessCustomer
                  ? businessCustomer.billing.phoneNumber.phone
                  : ""
                : customer
                  ? customer.phoneNumber.phone
                  : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <SummaryField
            label="Address"
            value={
              isBusinessCustomerBooking
                ? businessCustomer
                  ? businessCustomer.registeredAddress.fullAddress
                  : ""
                : customer
                  ? customer.location.fullAddress || ""
                  : ""
            }
          />
        </Grid>
        {!isBusinessCustomerBooking && (<Grid item xs={12}>
          <SummaryField
            label={getLocalizedInsuranceNumberSyntex(country)}
            value={
              customer && customer.nationalInsuranceNumber
                ? customer.nationalInsuranceNumber
                : "N/A"
            }
          />
        </Grid>)}
      </Grid>
      {invoice.invoiceType !== InvoiceType.CONSOLIDATED && (
        <Grid container item xs={3} spacing={1}>
          <Grid item xs={12}>
            <SummaryField
              label={`${getLocalizedBookingSyntex(country)} Reference`}
              value={invoice.booking?.referenceNumber}
              onClick={() => {
                if (userState.role !== "FINANCE_MANAGER") {
                  const bookingId = invoice.booking.id;
                  const bookingURL = `/view-booking?booking=${bookingId}`;
                  navigate(bookingURL)
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SummaryField
              label={`${getLocalizedBookingSyntex(country)} Start Date`}
              value={getLocalizedDateFormat(country, invoice.booking?.pickupDateTime, DATE_TYPE.CONDENSED)}
            />
          </Grid>
          <Grid item xs={12}>
            <SummaryField
              label={`${getLocalizedBookingSyntex(country)} End Date`}
              value={getLocalizedDateFormat(country, invoice.booking?.dropoffDateTime || "", DATE_TYPE.CONDENSED)}
            />
          </Grid>
          {
            regNumbers && (
              <Grid item xs={12}>
                <SummaryField
                  label="Vehicle Registration Number(s)"
                  value={regNumbers}
                />
              </Grid>
            )
          }
          {
            invoice.booking?.customerType === CustomerType.BUSINESS &&
            <>
              {isEditablePONumber ? (
                <Grid item xs={10} container className={"po-number-main"}>
                  <TextField
                    label="PO Number"
                    id="po-number"
                    fullWidth
                    value={poNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPoNumber(e.target.value)
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {
                            <Tooltip title="Save">
                              <IconButton
                                aria-label="toggle po number "
                                onClick={() => {
                                  addPoNumberToBooking(poNumber)
                                  setIsEditablePONumber(false)
                                }}
                                size="large">
                                <DoneIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="toggle po number"
                              onClick={() => {
                                if (invoice) {
                                  setPoNumber(invoice?.booking?.poNumber || "")
                                }

                                setIsEditablePONumber(false)
                              }}
                              size="large">
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )
                    }}
                  />
                </Grid>
              ) : (
                <Grid container item xs={12} spacing={1} alignItems="center">
                  <Grid item >
                    <Typography variant={"h4"}>PO Number: </Typography>
                  </Grid>
                  <Grid item ><Typography variant={"body1"}>{poNumber || "N/A"}</Typography></Grid>
                  {
                    editablePO && (
                      <Grid item >
                        <Tooltip title={poNumber ? "Edit" : "Click to add"}>
                          <IconButton
                            aria-label="toggle edit visibility"
                            onClick={() => setIsEditablePONumber(true)}
                            size="large">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )
                  }
                </Grid>
              )}
            </>
          }
        </Grid>)}
      <Grid container item xs={3} spacing={1}>
        <Grid item xs={12}>
          <SummaryField
            label={invoice.invoiceType === InvoiceType.SUNDRY ? `Sundry Invoice Reference` : "Invoice Reference"}
            value={invoice.invoiceRef}
          />
        </Grid>
        <Grid item xs={12}>
          <SummaryField
            label="Invoice Period"
            value={`${getLocalizedDateFormat(country, invoice.startDate, DATE_TYPE.EXPANDED)} - ${getLocalizedDateFormat(country, invoice.endDate, DATE_TYPE.EXPANDED)}`}
          />
        </Grid>
        <Grid item xs={12}>
          <SummaryField
            label={invoice.invoiceType === InvoiceType.SUNDRY ? `Sundry Invoice Status` : "Invoice Status"}
            value={["PARTIALLY PAID", "UNPAID"].includes(invoice.status) ?
              "UNPAID" : invoice.status
            }
            color={invoice.status === "PAID" ? "#00bcb5" : "#c54740"}
          />
        </Grid>
        <Grid item xs={12}>
          {invoice.status === InvoiceStatus.PAID && invoice.paidAt && (
            <SummaryField
              label="Paid On"
              value={getLocalizedDateFormat(country, invoice.paidAt, DATE_TYPE.EXPANDED)}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {invoice.consolidatedBy && (
            <SummaryField
              label={"Invoice Voided By"}
              value={invoice.consolidatedBy.invoiceRef}
              onClick={() => {
                navigate(`/update-billing?invoice=${invoice.consolidatedBy?.id}`)
              }
              }
            />
          )}
        </Grid>
      </Grid>
      <Grid container item xs={3} spacing={1}>
        <Grid item xs={12}>
          <SummaryField
            label="Total"
            value={toCurrency(invoice.totalPayableAmount, currency, locale)}
          />
        </Grid>
        <Grid item xs={12}>
          <SummaryField
            label="Balance Due"
            value={toCurrency(
              invoice.dueAmount,
              currency,
              locale
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <SummaryField
            label={"Generated at"}
            value={getLocalizedDateFormat(country, (invoice.confirmedAt) ? invoice.confirmedAt : invoice.dateCreated, DATE_TYPE.EXPANDED)} />
        </Grid>
        <Grid item xs={12}>
          {invoice.invoiceType !== InvoiceType.CONSOLIDATED && (
            <SummaryField
              label={"Due Date"}
              value={getLocalizedDateFormat(country, invoice.dueDate, DATE_TYPE.EXPANDED)} />
          )}
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Grid>
  );
};
