import { useLazyQuery } from "@apollo/client";
import {
  Box,
  CssBaseline,
  Grid,
  Typography,
  CircularProgress,
  FormControlLabel,
  Switch
} from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setBusinessCustomer } from "../../../../../actions/customer/actions";
import { GET_BUSINESS_CUSTOMER } from "../../../../../graphql/businessCustomers/businessCustomer";
import { IBusinessCustomer } from "../../../../../reducers/customer/types";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { NewBusinessCustomerTabView } from "./UpdateBusinessCustomerTabView";
import { IAppState } from "../../../../../store";

export const UpdateBusinessCustomer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const [businessCustomerValues, setBusinessCustomerValues] = useState<IBusinessCustomer>();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const branch = userState.currentBranch;
  const [getBusinessCustomer, {
    data: businessCustomerData
  }] = useLazyQuery(GET_BUSINESS_CUSTOMER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.businessCustomer) {
        navigate("/business-customer");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const businessCustomerId = params.get("businesscustomer");
      if (businessCustomerId) {
        getBusinessCustomer({
          variables: {
            businessCustomerId
          }
        })
      }
    }
  }, [location]);

  useEffect(() => {
    if (businessCustomerData && businessCustomerData.businessCustomer) {
      let _businessCustomer = { ...businessCustomerData.businessCustomer };
      _businessCustomer.accountManager = _businessCustomer.accountManager || "";
      dispatch(setBusinessCustomer({ ...businessCustomerData.businessCustomer }));
      _businessCustomer.overrideInvoiceDate = _businessCustomer.overrideInvoiceDate || false;
      _businessCustomer.overrideWeeklyInvoiceDate = _businessCustomer.overrideWeeklyInvoiceDate || false;
      _businessCustomer.weeklyInvoicingDay = _businessCustomer.weeklyInvoicingDay || undefined;
      _businessCustomer.invoicingDay = _businessCustomer.invoicingDay || undefined;
      setBusinessCustomerValues(_businessCustomer);
    }
  }, [businessCustomerData])


  return (
    <Grid container>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center" justifyContent={"space-between"}>
        <Grid container item xs={6}>
          <Typography variant="h1" color="primary">
            Customers
          </Typography>
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon sx={{mt: 1}}/>
          <Box color="white" sx={{ pl: 1 }}></Box>
          {businessCustomerValues && businessCustomerValues.id ? (
            <Typography variant="h1" color="primary">
              Update
            </Typography>
          ) : (
            <Typography variant="h1" color="primary">
              New
            </Typography>
          )}
          <Typography variant="h1" color="primary">
            &nbsp;Business Customer
          </Typography>
        </Grid>
        {businessCustomerValues && branch.autoPayEnabled && <Grid container item xs={6} justifyContent={"flex-end"}>
          <FormControlLabel
            control={
              <Switch
                checked={businessCustomerValues.autoPayDisabled || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setBusinessCustomerValues({
                    ...businessCustomerValues,
                    autoPayDisabled: event.target.checked
                  })
                }}
                name="autoPayDisabled"
                value="autoPayDisabled"
              />
            }
            label={<Typography style={{ display: "inline", marginLeft: "0.5rem" }}>{"Disable Invoice Auto Pay"}</Typography>}
            labelPlacement="bottom"
          />
        </Grid>}
      </Grid>      
      <Grid container>
        {" "}
        <Box mt={2}></Box>
      </Grid>
      <Grid container item xs={12}>
        {businessCustomerValues ? (
          <NewBusinessCustomerTabView businessCustomer={businessCustomerValues} />
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
};
