import { gql } from "@apollo/client";

export const UPDATE_PRICE_RULE = gql`
  mutation updatePriceRule($priceRule: UpdatePriceRuleInput!) {
    updatePriceRule(priceRule: $priceRule) {
      id
      name
    }
  }
`;
