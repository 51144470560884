import "../index.scss";
import {
  Grid,
  Typography,
  Hidden,
  Theme,
  Paper
} from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { GET_EXPENSE_BY_VEHICLE_ID } from "../../../../../../graphql/fleet/getExpenseByVehicleId";
import { IVehicle } from "../../../../../../reducers/fleet/types";
import { useLazyQuery } from "@apollo/client";
import { NuvvenTable } from "../../../../../common/NuvvenTable/NuvvenTable";
import { MUIDataTableOptions } from "mui-datatables";
import { SelectableRows } from "../../../../../common/NuvvenTable/types";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../../store";
import { ExpenseType, ExpenseTypeValue } from "../../../../Expenses/expenses.types";
import { DATE_TYPE, toCurrency } from "../../../../../common/utils";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { createStyles, makeStyles } from "@mui/styles";

interface IProps {
  vehicle: IVehicle;
  updateFields(payload: any): void;
  updateVehicle(payload: any): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    green: {
      color: "#00ff00"
    },
    header: {
      color: "rgb(45, 157, 184)",
      fontSize: 20
    },
    label: {
      color: "grey",
      fontSize: 12,
      fontWeight: 100,
      marginLeft: 20
    },
    paper: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      textAlign: "center"
    },
    red: {
      color: "#ff0000"
    },
    root: {
      padding: theme.spacing(3)
    }
  })
);

export const ExpenseHistory = (props: IProps) => {

  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const { locale, currency } = userState.currentOrganisation;

  const viewColumns = [
    {
      label: "Expense Type",
      name: 'expenseType',
      options: {
        customBodyRender: (value: ExpenseType) => ExpenseTypeValue[value]
      }
    },
    {
      name: "expenseDate",
      label: "Expense Date",
      options: {
        customBodyRender: (value: string) =>
          value
            ?
            getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED)
            : ""
      }
    },
    {
      name: "odometerReading",
      label: "Odometer",
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        customBodyRender: (value: number) => toCurrency(value, currency, locale)
      }
    },
    {
      name: "vendor",
      label: "Vendor"
    },
    {
      name: "reference",
      label: "Reference"
    }
  ];

  const [
    getExpenseByVehicleId,
    { data: vehicleExpenses }
  ] = useLazyQuery(GET_EXPENSE_BY_VEHICLE_ID, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (props.vehicle?.id) {
      getExpenseByVehicleId({
        variables: {
          vehicleId: props.vehicle.id
        }
      })
    }
  }, [props.vehicle])

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    elevation: 0,
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">EXPENSE HISTORY</Typography>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12}>
              <Paper variant="outlined">
                <NuvvenTable
                  title={""}
                  rows={vehicleExpenses && vehicleExpenses.expensesByVehicleId}
                  columns={viewColumns}
                  options={options}
                />
              </Paper>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            {(vehicleExpenses?.expensesByVehicleId.length === 0) && (
              <Grid xs={12} item>
                <Typography variant="h4">
                  No Expense Data
                </Typography>
              </Grid>
            )}
          </Hidden>
        </Grid>
      </Grid>
    </>
  )
}