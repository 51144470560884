import { CLEAR_PRICE_RULE, PriceRuleAction, UPDATE_PRICE_RULE } from "./types";

export function updatePriceRule(payload: any): PriceRuleAction {
  return {
    payload,
    type: UPDATE_PRICE_RULE
  };
}

export function clearPriceRule(): PriceRuleAction {
  return {
    type: CLEAR_PRICE_RULE
  };
}
