import "./App.scss";

import { Fab, Grid, Theme, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import HelpIcon from '@mui/icons-material/Help';
import { createStyles, makeStyles } from "@mui/styles";
import * as Sentry from "@sentry/react";
import Amplify from "@aws-amplify/core";
import { createBrowserHistory } from "history";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import {
  Router
} from "react-router-dom";

import errorImage from "./assets/images/car-broken-error-meta.png";
import awsconfig from "./aws-exports";
import CustomRoutes from "./routes";
import { Header } from "./components/common/Header";
import NavBar from "./components/common/Navbar";

Amplify.configure(awsconfig);
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || "");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      backgroundColor: "#f4f4fa",
      display: "block",
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - 215px)`,
        marginLeft: 215,
      },
      [theme.breakpoints.down('sm')]: {
        width: `calc(100%)`,
        marginLeft: 0,
      },
    },
    footer: {
      width: `calc(100% - 200px)`,
      background: "var(--theme-accent)",
      position: "fixed",
      bottom: 0,
      [theme.breakpoints.up('sm')]: {
        left: 200,
      },
      [theme.breakpoints.down('sm')]: {
        left: 0,
      },
      boxShadow: "0px 1px 0px rgba(255, 255, 255, 0.08)",
      zIndex: 200
    },
    root: {
      display: "flex",
      flexGrow: 1
    }
  })
);

function ErrorFallback({ resetError }: any) {
  return (
    <div role="alert" className="flex cross-center col-flex error-message">
      <div className="react-error-image">
        <img src={errorImage} />
      </div>
      <h1>Oops!</h1>
      <span>Looks like something went wrong here</span>
      <div className="error-action flex col-flex cross-center">
        <Fab
          className="blackBackButton"
          variant="extended"
          size="medium"
          type="button"
          onClick={() => {
            resetError();
          }}
        >
          <span>Retry</span>
          <RefreshIcon fontSize="small" color="inherit" />
        </Fab>
        <div style={{ padding: 20 }}></div>
        <Typography variant="body2">
          Do not worry, we have captured the error.
        </Typography>
        <Typography variant="body2">
          We are constantly working on improving the product to make it better.
        </Typography>
      </div>
    </div>
  );
}
export const history = createBrowserHistory();
const App = (props: any) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      navigator={history}
      location={state.location}
      navigationType={state.action}
    >
      <div className={classes.root}>
        <main className={classes.content}>
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <div style={{ padding: "15px 30px", marginTop: "3.5rem" }}>
              <CustomRoutes />
            </div>
          </Sentry.ErrorBoundary>
        </main>
        <NavBar />
      </div>
    </Router>
  );
};

export default App;
