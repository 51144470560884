import { gql } from "@apollo/client";

export const GET_ALL_NOTICES = gql`
  query getAllPCN($limit: Int!, $offset: Int!) {
    getAllPCN(limit: $limit, offset: $offset) {
        id
        referenceNumber
        noticeRef
        receivedAt
        noticeType
        vehicle {
            licencePlate
        }
        location
        contraventionType
        contraventionDate
        totalDue
        status
        isPaid
  }
}
`;
