import { gql } from "@apollo/client";

export const GET_BUSINESS_CUSTOMER_BOOKINGS = gql`
  query bookingsByBusinessCustomerId($id: String!) {
    bookingsByBusinessCustomerId(id: $id) {
      id
      authorizedSignatory
      referenceNumber
      source
      isSubscription
      pickupServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      pickupOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      pickupDateTime
      dropoffServiceLocation {
        id
        name
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      dropoffOtherLocation {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      dropoffDateTime
      businessCustomer {
        businessName
        authorizedSignatories {
          firstName
          id
          lastName
        }
      }
      paymentDetails {
        startDate
        endDate
        taxAmount
        rentalAmount
        insuranceAmount
        discountAmount
        addonsAmount
        totalPayableAmount
        rentalTime
      }
      customerType
      createdBy {
        id
        firstName
        lastName
      }
      status
      tba
      currentBookingSchedules {
        vehicle {
          licencePlate
        }
      }
    }
  }
`;
