import { IInvoiceItem } from "../../components/views/Invoices/UpdateInvoice/InvoiceComponents/types";
import {
  IBooking,
  ICreditNote,
  ICustomerDetails,
  IDiscount,
  ITax
} from "../bookings/types";
import { IBusinessCustomer } from "../customer/types";

export interface IInvoicesState {
  invoice: IInvoice;
}

export type InvoicesPayloadType = IInvoice | IInvoiceId;

export interface InvoiceSummary {
  id: string;
  invoiceRef: string; 
}

export interface IInvoice {
  id: string;
  latestPayment?: string;
  latestRefund?: string;
  customer: ICustomerDetails;
  businessCustomer?: IBusinessCustomer;
  customerType: string;
  dateCreated: string;
  dateUpdated: string;
  createdBy: string;
  updatedBy: string;
  discount: IDiscount;
  invoiceRef: string;
  status: string;
  locked: boolean;
  invoiceType: string;
  refunds: string[];
  startDate: string;
  endDate: string;
  tax: ITax[];
  totalPayableAmount: number;
  anyPaidAmount: number;
  confirmedPaidAmount: number;
  anyRefundAmount: number;
  confirmedRefundAmount: number;
  otherRefunds: number;
  depositRefund: number;
  balanceDueFromPreviousInvoice: number;
  damageAmount: number;
  finesAmount: number;
  valetAmount: number;
  otherCharges: IOtherCharge[];
  invoiceItems: IInvoiceItem[];
  lastSent?: string;
  booking: IBooking;
  payments: IPayment[];
  taxAmount: number;
  rentalAmount: number;
  insuranceAmount: number;
  discountAmount: number;
  addonsAmount: number;
  rateTypeName: string;
  rateTypeDuration: number;
  sageAccountingInvoiceId: string;
  sageAccountingError: string;
  invoiceUrl?: string;
  creditNotes?: ICreditNote[];
  adjustedCreditNotes?: IAdjustedCreditNote[];
  documents?: IInvoiceDocument[];
  dueDate: string;
  dueAmount: number;
  adjustedDeposit?: IAdjustedDeposit;
  confirmedAt?: string;
  sentToXero?: boolean;
  xeroInvoiceStatus?: XeroStatus;
  invoices?: InvoiceSummary[];
  consolidatedBy?: InvoiceSummary 
  paidAt?: string;
}

export enum XeroStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED"
}

export interface IAdjustedDeposit {
  amount: number;
  adjustedAt: string;
}
export interface IInvoiceDocument {
  title: string;
  documentUrl: string;
  documentName: string;
  expiryDate: string;
  documentType: string;
  reminderBefore?: number;
}

export interface IAdjustedCreditNote {
  amount: number;
  creditNote: ICreditNote;
  adjustedAt: string;
}
export interface IInvoiceBooking {
  id: string;
  excess: number;
}

export interface IOtherCharge {
  amount: number;
  name: string;
}

export interface IInvoiceId {
  id: string;
}

export interface IInvoiceCustomerId {
  id: string;
}

export interface IPayment {
  id: string;
  billingAddress?: IBillingAddress;
  dateCreated: string;
  lastUpdated: string;
  createdBy: string;
  updatedBy: string;
  note: string;
  paymentMode: string;
  paymentType: string;
  emailNotify: boolean;
  expireBy: string;
  amount: number;
  remainingAmount: number;
  currency: string;
  description: string;
  smsNotify: boolean;
  invoice?: IInvoiceId;
  customer?: IInvoiceCustomerId;
  status: PaymentStatus;
  stripeCheckoutSessionId?: string;
  paymentGateway?: string;
  capturedAt?: string;
  paymentModeEdited?: string; // Add key to store manipulated string
}

export interface IPaymentForm {
  amount: number;
  email?: string;
  transactionId?: string;
  paymentMode: PaymentMode;
  note: string;
  capturedAt: string;
}

export interface IPaymentInput {
  paymentMode: PaymentMode;
  paymentType: string;
  billingAddress?: IBillingAddress;
  emailNotify?: boolean;
  expireBy: string;
  invoice?: string;
  booking: string;
  bookingExtension?: string;
  businessCustomer?: string;
  amount: number;
  creditAmount?: number
  currency: string;
  description: string;
  note?: string;
  smsNotify?: boolean;
  successUrl?: string;
  cancelUrl?: string;
  paymentGateway?: string;
  transactionId?: string;
  captureLater?: boolean;
  collectDeposit?: boolean;
  capturedAt?: string;
  stripePaymentMethodId?: string;
  convergeTokenizedCard?: string;
}

export interface IDepositPaymentInput {
  booking: string;
  amount: number;
  currency: string;
  transactionId?: string;
  captureLater?: boolean;
  paymentMode: string;
  paymentGateway?: string;
  cancelUrl?: string;
  successUrl?: string;
  saveDetails?: boolean;
}

export interface IBillingAddress {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  postcode?: string;
}

export interface ISOTPaymentIntegrationInput {
  SOTPayVendor: string;
  APIToken: string;
  paymentMode: PaymentMode;
  emailNotify?: boolean;
  email?: string;
}

export interface ISOTPayIntegration {
  id: string;
  SOTPayVendor: string;
  APIToken?: string;
  emailNotify?: boolean;
  paymentMode: PaymentMode;
  email?: string;
}

export enum PaymentMode {
  CARD = "CARD",
  CASH = "CASH",
  PAY_BY_LINK = "PAY BY LINK",
  PAY_BY_COLLECTION = "PAY ON COLLECTION",
  SEPA_BANK_DEBIT = "SEPA BANK DEBIT",
  BACS_BANK_DEBIT = "BACS BANK DEBIT",
  OFFLINE_BANK_TRANSFER = "OFFLINE BANK TRANSFER",
  AUTO_CHARGE = "AUTO CHARGE"
}

export enum PaymentStatus {
  PAID = "PAID",
  NEW = "NEW",
  FAILED = "FAILED",
  ABORT = "ABORT",
  EXPIRED = "EXPIRED",
  VOIDED = "VOIDED",
  AUTHORISED = "AUTHORISED",
  CANCELLED = "CANCELLED"
}

export interface IConsolidatedInvoice {
  businessCustomerId: string;
  businessCustomerEmail: string;
  startDate: string;
  endDate: string;
}

export interface IConsolidatedInvoiceInput {
  businessCustomerId: string;
  invoiceIds: string[];
  startDate: string;
  endDate: string;
}