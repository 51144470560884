import { Box, CircularProgress, CssBaseline, Fab, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ISubscription } from '../../../../../reducers/Subscription/types';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SUBSCRIPTIONS_LIST, GET_VEHICLE_SUBSCRIPTIONS_COUNT, SEARCH_SUBSCRIPTIONS } from '../../../../../graphql/SubscriptionPlans/getSubscriptionsQuery';
import { BulkCsvUploadDialog } from '../../../../common/BulkCsvUploadDialog/BulkCsvUploadDialog';
import { CsvUploadStatusType, ICsvUploadError, formatGraphQLErrorMessage, possibleCsvMimeTypes, returnSortedOrder, setTableSortOrder, subscriptionPricingCsvHeaders } from '../../../../common/utils';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import papa from "papaparse";
import { ApolloError } from '@apollo/client';
import { UPLOAD_VEHICLE_SUBSCRIPTION_PRICINGS } from '../../../../../graphql/SubscriptionPlans/uploadVehicleSubscriptionPricingsMutation';
import { NuvvenTable } from '../../../../common/NuvvenTable/NuvvenTable';
import { SelectableRows } from '../../../../common/NuvvenTable/types';
import { addTableState } from '../../../../../actions/tableState/actions';
import { ITable, TableNames } from '../../../../../reducers/tableState/types';
import { MUIDataTableOptions } from 'mui-datatables';
import _ from 'lodash';
import { captureErrorException } from '../../../../../utils/sentry';


interface IDeposit {
  subscriptionName: string,
  deposit: number[]
}

const Subscriptions: React.FC = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const subscriptionsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.subscriptions
  );
  const [subscriptionsTable, setSubscriptionsTable] = useState<ITable>();
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [uploadErrors, setUploadErrors] = useState<ICsvUploadError[]>([]);
  const [uploadStatus, setUploadStatus] = useState<number>(
    CsvUploadStatusType.DEFAULT
  );
  const [fileName, setFileName] = useState<string>("");
  const [fileValue, setFileValue] = useState<File | undefined>();
  const [rows, setRows] = useState<ISubscription[]>([]);
  const disabledRoles = ["FINANCE_MANAGER", "FLEET_MANAGER"];
  const [limit, setLimit] = useState(10);
  const [totalSubscriptionsCount, setTotalSubscriptionsCount] = useState<number>(0);

  const [loadSubscriptions, { loading, data }] = useLazyQuery(GET_SUBSCRIPTIONS_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.subscriptionsList
        && data.subscriptionsList.length) {
        const oldSubscriptions = [...rows]
        const reShapeRows = [...data.subscriptionsList]
        reShapeRows.forEach((subscription: ISubscription) => {
          if (!oldSubscriptions.some((item) => item.id === subscription.id)) {
            oldSubscriptions.push(subscription)
          }
        });
        setRows(oldSubscriptions)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [subscriptionsCount, { data: subscriptionsCountData }] = useLazyQuery(GET_VEHICLE_SUBSCRIPTIONS_COUNT, {
    fetchPolicy: "network-only",
    onCompleted: (subscriptionsCountData) => setTotalSubscriptionsCount(subscriptionsCountData.subscriptionsCount),
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [searchSubscriptions, { loading: searchSubscriptionsLoading, data: searchSubscriptionsData }] = useLazyQuery(SEARCH_SUBSCRIPTIONS, {
    fetchPolicy: "network-only",
    onCompleted: (searchSubscriptionsData) => {
      if (searchSubscriptionsData && searchSubscriptionsData.searchSubscriptions) {
        const reShapeRows = searchSubscriptionsData.searchSubscriptions
        setTotalSubscriptionsCount(reShapeRows.length)
        setRows(reShapeRows)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchSubscriptions({
        variables: {
          q: searchText
        }
      })
    }
  }, 1000);

  const [uploadVehicleSubscriptionPricings] = useMutation(UPLOAD_VEHICLE_SUBSCRIPTION_PRICINGS, {
    onError: (error: ApolloError) => {
      setFileName("");
      setFileValue(undefined);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      if (
        data &&
        data.uploadVehicleSubscriptionPricing &&
        data.uploadVehicleSubscriptionPricing.errors &&
        data.uploadVehicleSubscriptionPricing.errors.length
      ) {
        setFileName("");
        setFileValue(undefined);
        setUploadErrors(data.uploadVehicleSubscriptionPricing.errors);
        return setUploadStatus(CsvUploadStatusType.ERROR);
      }
      snackbar({
        message: "Subscription pricings uploaded successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setUploadModalOpen(false);
      setFileName("");
      setFileValue(undefined);
      setUploadStatus(CsvUploadStatusType.DEFAULT);
    }
  });

  useEffect(() => {
    if (subscriptionsTableState) {
      setSubscriptionsTable(subscriptionsTableState);
      let sortedOrder = returnSortedOrder(subscriptionsTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(subscriptionColumns, subscriptionsTableState, sortedOrder)
      }
      loadSubscriptions({
        variables: {
          offset: rows.length,
          limit: subscriptionsTableState.rowsPerPage || limit
        }
      })
    }
  }, [subscriptionsTableState])

  useEffect(() => {
    if (userState.tenancy) {
      subscriptionsCount();
      loadSubscriptions({
        variables: {
          limit: subscriptionsTableState?.rowsPerPage || limit,
          offset: 0
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    if (!uploadModalOpen) {
      setUploadErrors([]);
    }
  }, [uploadModalOpen]);

  function onSelectDocument(event: any) {
    const file: File = event.target.files[0];
    setUploadErrors([]);
    setUploadStatus(CsvUploadStatusType.DEFAULT);
    if (file) {
      setFileName(file.name);
      if (possibleCsvMimeTypes.includes(file.type)) {
        setFileValue(file);
      } else {
        return snackbar({
          message: "Invalid File Type. Allowed type: csv",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  function uploadCsv() {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        papa.parse(fileValue, {
          skipEmptyLines: true,
          complete(results: any) {
            const vehicleSubscrPricings = [];
            if (results.data.length) {
              if (results.data.length > 21) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: "Only 20 vehicle subsription pricings can be uploaded in one batch",
                  variant: SnackBarVariant.ERROR
                });
              }
              const deposits: IDeposit[] = []

              for (let index = 0; index < results.data[0].length; index++) {
                const header = results.data[0][index];
                if (header !== subscriptionPricingCsvHeaders[index]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid File Header.",
                    variant: SnackBarVariant.ERROR
                  });
                }
              }
              if (results.data.length === 2 && !results.data[1][0]) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: "No data found.",
                  variant: SnackBarVariant.ERROR
                });
              }
              for (let index = 1; index < results.data.length; index++) {
                const element: any = results.data[index];
                if (element.length < 10) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid file",
                    variant: SnackBarVariant.ERROR
                  });
                }
                const isInActiveSubscription = rows.some(sub => sub.name.toLowerCase() === element[1].toLowerCase() && !sub.isActive);

                if (isInActiveSubscription) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "InActive subscription can't be uploaded",
                    variant: SnackBarVariant.ERROR
                  });
                }
                const unlimitedMileage = element[7].toLowerCase() === "true" ? true : element[7].toLowerCase() === "false" ? false : null
                if (typeof unlimitedMileage !== "boolean") {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Unlimited mileage must be a true/false value at row" + index,
                    variant: SnackBarVariant.ERROR
                  });
                }
                const duration = Number(element[2])
                if (!Number.isInteger(duration)) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `"Duration should be a number value and must be greater than zero at pricing item" ${index} `,
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (element[9] && element[9] >= 0) {
                  const oldDeposits = deposits
                  const oldDepositIndex = oldDeposits.findIndex(d => d.subscriptionName === element[1])
                  if (oldDepositIndex > -1) {
                    if (oldDeposits[oldDepositIndex].deposit.some(oldDeposit => oldDeposit !== parseFloat(element[9]) * 100)) {
                      setUploadStatus(CsvUploadStatusType.ERROR)
                      setFileName("");
                      setFileValue(undefined);
                      return snackbar({
                        message: `Deposit should be same for subscription ${oldDeposits[oldDepositIndex].subscriptionName}`,
                        variant: SnackBarVariant.ERROR
                      });
                    }
                  } else {
                    deposits.push(
                      ...oldDeposits, {
                      subscriptionName: element[1] || "",
                      deposit: [parseFloat(element[9]) * 100]
                    })
                  }
                } else {
                  setUploadStatus(CsvUploadStatusType.ERROR)
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: `Deposit should be zero or or than zero at row ${index}`,
                    variant: SnackBarVariant.ERROR
                  });
                }
                const data = {
                  licencePlate: element[0],
                  subscriptionName: element[1],
                  duration: parseInt(element[2]),
                  enrollmentAmount: (parseFloat(element[3]) * 100),
                  subscriptionAmount: (parseFloat(element[4]) * 100),
                  mileage: parseInt(element[5]),
                  pricePerExtraMile: (parseFloat(element[6]) * 100),
                  unlimitedMileage: unlimitedMileage,
                  swapsAllowed: parseInt(element[8])
                };
                vehicleSubscrPricings.push(data);
              }
              if (vehicleSubscrPricings.length > 0) {
                const updateDeposits = deposits.filter((deposit, index) => index === deposits.findIndex(d => deposit.subscriptionName === d.subscriptionName))

                uploadVehicleSubscriptionPricings({
                  variables: {
                    pricings: vehicleSubscrPricings,
                    deposits: updateDeposits
                  }
                });
              } else {
                setUploadStatus(CsvUploadStatusType.ERROR);
                snackbar({
                  message: "No data found.",
                  variant: SnackBarVariant.ERROR
                });
              }
            }
          }
        });
      }
    } catch (error) {
      captureErrorException(error)
      setUploadStatus(CsvUploadStatusType.ERROR);
    }
  }

  const subscriptionColumns: any = [
    {
      label: "Plan Name",
      name: "name"
    },
    {
      label: "Insurance Policy",
      name: "insurancePolicy.name",
      options: {
        customBodyRender: (value: any) => {
          return value ? value : 'N/A';
        }
      }
  
    },
    // {
    //   label: "Add-ons",
    //   name: "addons"
    // },
    // {
    //   label: "Features",
    //   name: "features"
    // },
    {
      label: "Rate Type",
      name: "rateTypeName"

    },
    {
      label: "Status",
      name: "isActive",
      options: {
        customBodyRender: (value: boolean) => {
          return value ? "Active" : "In-Active"
        }
      }
    },
  ];

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: totalSubscriptionsCount,
    rowsPerPageOptions: [10, 20, 100],
    rowHover: true,
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: (loading || searchSubscriptionsLoading) ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: ISubscription = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/update-subscription?id=${row.id}`);
      }
    },

    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText)
      } else {
        setRows([])
        subscriptionsCount();
        loadSubscriptions({
          variables: {
            offset: 0,
            limit
          }
        });
      }
    },

    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.SUBSCRIPTIONS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      subscriptionsTable && subscriptionsTable.rowsPerPage
        ? subscriptionsTable.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.SUBSCRIPTIONS,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: () => {
      if (subscriptionsTable && sortOrder) {
        setTableSortOrder(subscriptionColumns, subscriptionsTable, sortOrder);
      }
    },
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return; // Skip executing changePage if there is a search text
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length) {
            loadSubscriptions({
              variables: {
                offset: page * rowsPerPage,
                limit: rowsPerPage
              }
            });
          }
          break;
        case "changeRowsPerPage":
          setRows([])
          loadSubscriptions({
            variables: {
              offset: 0,
              limit: subscriptionsTable?.rowsPerPage || tableState.rowsPerPage
            }
          });
          break;
        default:
          break;
      }
    },
  };

  return (
    <>
      <Grid container spacing={2} item xs={12}>
        <CssBaseline />
        <Grid item xs={6}>
          <Typography variant="h1" color={"primary"}>Subscriptions</Typography>
        </Grid>
        <Grid container item xs={6} spacing={2} justifyContent={"flex-end"}>
          {/* <Grid container spacing={1} justifyContent="flex-end"> */}
          <Grid item>
            <Fab
              variant="extended"
              size="medium"
              aria-label="Create"
              className="createButton"
              onClick={() => {
                navigate("/new-subscription");
              }}
              disabled={disabledRoles.includes(userState.role)}
            >
              Create
            </Fab>
          </Grid>
          <Grid item>
            {/* This will be not a part of feb release will picked up in march */}
            <Fab
              variant="extended"
              size="medium"
              aria-label="Create"
              className="createButton"
              onClick={() => setUploadModalOpen(true)}
              disabled={disabledRoles.includes(userState.role)}
            >
              Upload Subscr. Pricings
            </Fab>
            {/* </Grid> */}
          </Grid>
        </Grid>
        {
          loading && !rows.length ? (
            <Grid item xs={12}><CircularProgress /></Grid>
          ) : (
            <Grid item xs={12}>
              <NuvvenTable
                title=""
                rows={rows}
                columns={subscriptionColumns}
                options={options}
                setSelection={() => { }}
              />
            </Grid>
          )
        }
        {
          uploadModalOpen && (
            <BulkCsvUploadDialog
              title={"Upload Subscriptions"}
              subTitle={"Subscription pricings"}
              csvTemplateLink={"https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/upload-subscription-pricings.csv"}
              uploadErrors={uploadErrors}
              uploadStatus={uploadStatus}
              onDocumentSelect={onSelectDocument}
              fileName={fileName}
              uploadCsv={uploadCsv}
              handleClose={() => setUploadModalOpen(false)}
            />
          )
        }
      </Grid>
    </>
  )
}

export default Subscriptions
