import {
  Avatar,
  ButtonBase,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { IPhoneNumber } from "../../../reducers/customer/types";
import { getImage } from "../utils";
import { deepOrange } from "@mui/material/colors";


const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    cardStyle: {
      padding: "5px",
      height: "100px",
      minWidth: "calc(100vw - 60px)",
      maxWidth: "calc(100vw - 60px)",
      borderRadius: "12px",

    },
    avatarCustom: {
      height: "60px",
      width: "60px",
      backgroundColor: deepOrange[500],
      color: theme.palette.getContrastText(deepOrange[500]),
    }
  })
);



export const MobileCustomerCard = ({ id, name, imgKey, phone, email }: {
  id: string, name: string, imgKey?: string, phone: IPhoneNumber, email: string
}) => {
  const classes = useStyle();
  const navigate = useNavigate();

  const [profilePic, setProfilePic] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const getProfilePicture = async (key: string) => {
    setLoading(true)
    setProfilePic(await getImage(key));
    setLoading(false)
  };


  function routeTo() {
    if (id) {
      navigate(`/customer-details?customer=${id}&editable=${true}`);
    }
  }

  useEffect(() => {
    if (imgKey) {
      getProfilePicture(imgKey);
    } else {
      setProfilePic("");
    }
  }, [imgKey]);

  return (
    <ButtonBase onClick={() => routeTo()}>
      <Card className={classes.cardStyle}>
        <CardContent>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              {
                imgKey ? (
                  !loading ?
                    (
                      <Avatar className={classes.avatarCustom}>
                        <img alt="Profile" src={profilePic} style={{ fontSize: '8px', width: "60px", height: "60px", borderRadius: "50%" }} />
                      </Avatar>
                    )
                    :
                    (
                      <Avatar className={classes.avatarCustom}>
                        <CircularProgress />
                      </Avatar>
                    )

                ) :
                  (<Avatar className={classes.avatarCustom}>
                    {name.charAt(0).toUpperCase()}
                  </Avatar>)
              }
            </Grid>
            <Grid item xs={9}>
              <Typography style={{ fontWeight: "bold" }}>{name}</Typography>
              <Typography>{phone.phone}</Typography>
              <Typography>{email}</Typography>
            </Grid>

          </Grid>

        </CardContent>
      </Card>
    </ButtonBase >
  );
};

export default MobileCustomerCard;