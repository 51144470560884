import { gql } from "@apollo/client";

export const GET_PROCUREMENT_REQUEST = gql`
  query procurementRequest($id: ID!) {
    procurementRequest(id: $id) {
      id
      status
      referenceNumber
      createdAt
      createdBy {
        firstName
        lastName
      }
      updatedAt
      updatedBy {
        firstName
        lastName
      }
      note {
        description
        createdBy {
          id
          firstName
          lastName
        }
        createdAt
      }
      items {
        id
        requestId
        assetType
        quantity
        make
        model
        variant
        fuel
        transmission
        condition
        expectedByDate
        status
        note {
          description
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
        }
      }
      proposalRequests {
        id
        proposalRequestId
        date
        partnerId
        validUntilDate
        partner {
          id
          partnerName
          email
        }
        note {
          description
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
        }
        items {
          vehicleMake
          vehicleModel
          vehicleVarient
          vehicleFuel
          vehicleTransmission
          vehicleCondition
          vehicleCount
          vehicleExpectedByDate
        }
      }
      quotes {
        id
        quoteId
        date
        partnerId
        validUntilDate
        lastEmailSent
        note {
          description
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
        }
        partner {
          id   
          partnerName
          email
        }
        amount
        proposalRequestId
        documents
        status 
        revisions {
          quoteId
          amount
          date
          validUntilDate
          updatedAt
          documents
        }
      }
      purchaseOrders {
        id
        purchaseOrderId
        date
        partnerId
        quoteId
        validUntilDate
        lastEmailSent
        note {
          description
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
        }
        partner {
          id 
          partnerName
          email
        }
        items {
          requestId
          vehicleMake
          vehicleModel
          vehicleVarient
          vehicleFuel
          vehicleTransmission
          vehicleCondition
          vehicleCount
          vehicleExpectedByDate
        }
      }
      financeInformation {
        id
        purchaseOrderId
        purchaseOrderDate
        partnerId
        invoiceId
        invoiceDate
        totalAmount
        expectedArrivalDate
        advancedPayment
        paymentSource
        documents
        partner {
          id
          partnerName
          email
        }
      }
      deliveryInformation {
        id
        deliveryNoteId
        deliveryNoteDate
        partnerId
        purchaseOrderId
        productReceivedDate
        receivedProductNumber
        documents
        partner {
          id 
          partnerName
          email
        }
        status
      }
    }
  }
`;