import {
  Box,
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography
} from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import React from "react"
import { useNavigate } from 'react-router-dom';
import { IVehicleService } from '../../../../../reducers/fleet/types';
import { DATE_TYPE } from '../../../../common/utils';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';

interface IProps {
  type: string
  handleClose(): void;
  serviceSummary?: IVehicleService;
  isSharedFleetEvent?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
    },
    tableCell: {
      minWidth: 150,
      background: "#EEEEEE"
    },
    dialogContent: {
      padding: "0px 24px"
    }
  })
)

export const ServiceSummary: React.FC<IProps> = (props) => {

  const classes = useStyles()
  const navigate = useNavigate()
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { handleClose, serviceSummary } = props;
  return (
    <>
      <Grid container item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size={"small"} className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell}><Typography variant="h4">{"Appointment Date"}</Typography></TableCell>
                <TableCell><Typography variant="body1">{getLocalizedDateFormat(country, serviceSummary?.startDate || "", DATE_TYPE.EXPANDED)}</Typography></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell}><Typography variant="h4">{"Completion Date"}</Typography></TableCell>
                <TableCell><Typography variant="body1">{getLocalizedDateFormat(country, serviceSummary?.completionDate || "", DATE_TYPE.EXPANDED)}</Typography></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCell}><Typography variant="h4">{"Service Type"}</Typography></TableCell>
                <TableCell><Typography variant="body1">{serviceSummary?.serviceType}</Typography></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}><Box mt={1.5}></Box></Grid>
      <Grid container item xs={12}>
        {
          !props.isSharedFleetEvent && (
            <Fab
              size="medium"
              variant="extended"
              onClick={() => {
                handleClose()
                if (serviceSummary?.id) {
                  navigate(`/new-services-and-repair?id=${serviceSummary.id}`)
                }
              }}
            >
              VIEW DETAILS
            </Fab>
          )
        }
      </Grid>
      <Grid item xs={12}><Box mt={1.5}></Box></Grid>
    </>
  )
}
