import { gql } from "@apollo/client";

export const ADD_BUSINESS_CUSTOMER_NOTE = gql`
  mutation addBusinessCustomerNote($businessCustomerId: ID! $note:CustomerNoteInput!) {
    addBusinessCustomerNote(businessCustomerId: $businessCustomerId,note:$note) {
      id
      description
      createdBy
      createdDate
    }
  }
`;