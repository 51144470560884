import {
  PriceRuleOperator,
  ValueType
} from "../../../../../reducers/priceRule/types";

export function calculateNewPrice(
  baseRate: number,
  operator?: string,
  valueType?: ValueType,
  value?: number
) {
  if (operator && valueType && value) {
    switch (operator) {
      case PriceRuleOperator.SURGE:
        if (valueType === ValueType.VARIABLE) {
          return baseRate + Math.round((baseRate * value) / 100);
        }
        return baseRate + value;
      case PriceRuleOperator.DISCOUNT:
        let amount = 0;
        if (valueType === ValueType.VARIABLE) {
          amount = baseRate - Math.round((baseRate * value) / 100);
        } else {
          amount = baseRate - value;
        }
        return amount > 0 ? amount : 0;
    }
  }
  return baseRate;
}

export const ruleOperators = [
  {
    label: "Increase",
    value: PriceRuleOperator.SURGE
  },
  {
    label: "Decrease",
    value: PriceRuleOperator.DISCOUNT
  }
];

export const rateTypes = [
  {
    label: "Hourly",
    value: "hourly"
  },
  {
    label: "Daily",
    value: "daily"
  },
  {
    label: "Weekly",
    value: "weekly"
  },
  {
    label: "Monthly",
    value: "monthly"
  }
];

export const daySpecific: string[] = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];

export const monthsSpecific: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const valueTypes = [
  {
    label: "Amount",
    value: ValueType.FIXED
  },
  {
    label: "Percentage",
    value: ValueType.VARIABLE
  }
];
