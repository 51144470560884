import { useLazyQuery } from '@apollo/client';
import { CircularProgress, CssBaseline, Fab, Grid, Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addTableState } from '../../../actions/tableState/actions';
import { GET_PARTNERS } from '../../../graphql/partners/getPartnersQuery';
import { IPartner } from '../../../reducers/partners/types';
import { ITable, TableNames } from '../../../reducers/tableState/types';
import { IAppState } from '../../../store';
import { NuvvenTable } from '../../common/NuvvenTable/NuvvenTable';
import { formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from '../../common/utils';
import { PartnerType, PartnerTypeValue } from './utils';
import { SelectableRows } from "../../common/NuvvenTable/types";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";

export const Partners: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const partnersTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.serviceAndRepair
  );
  const disabledRoles = ["BOOKING_AGENT", "FINANCE_MANAGER"];
  const [rows, setRows] = useState([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [partnersTable, setPartnersTable] = useState<ITable>();

  const [
    loadPartners,
    { loading, data }
  ] = useLazyQuery(GET_PARTNERS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (userState.tenancy) {
      loadPartners()
    }
  }, [userState.currentOrganisation])

  useEffect(() => {
    if (partnersTableState) {
      setPartnersTable(partnersTableState);
      let sortedOrder = returnSortedOrder(partnersTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(partnerTableColumn, partnersTableState, sortedOrder)
      }
    }
  }, [partnersTableState])

  useEffect(() => {
    if (data && data.partners) {
      const reShapeRows = data.partners.map((partner: any) => {
        const reshapePartner = {
          ...partner,
          contactName: `${partner.partnerContact.firstName} ${partner.partnerContact.lastName}`,
          status: partner.isActive ? "Active" : "In-Active"
        }
        return reshapePartner
      })
      setRows(reShapeRows)
    }
  }, [data]);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: IPartner = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/update-partner?partner=${row.id}`);
      }
    },

    onChangeRowsPerPage: (numberOfRows: number) => {
      dispatch(
        addTableState({
          tableName: TableNames.PARTNERS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      partnersTable && partnersTable.rowsPerPage
        ? partnersTable.rowsPerPage
        : 10,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.PARTNERS,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: () => {
      if (partnersTable && sortOrder) {
        setTableSortOrder(partnerTableColumn, partnersTable, sortOrder);
      }
    }
  };

  const partnerTableColumn: any = [
    {
      label: "ID",
      name: "partnerId"
    },
    {
      label: "Partner Name",
      name: "partnerName"
    },
    {
      label: "Partner Type",
      name: "partnerType",
      options: {
        customBodyRender: (value: PartnerType) => PartnerTypeValue[value]
      }
    },
    {
      label: "Contact Person",
      name: "contactName"
    },
    {
      label: "Contact Person Phone",
      name: "partnerContact.phoneNumber.phone"
    },
    {
      label: "Contact Person Email",
      name: "partnerContact.email"
    },
    {
      label: "Status",
      name: "status"
    }
  ];

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Partners
        </Typography>
      </Grid>
      {(!disabledRoles.includes(userState.role)) && (
        <Grid container item xs={6} justifyContent="flex-end">
          <Fab
            variant="extended"
            size="medium"
            aria-label="Create"
            className="createButton"
            onClick={() => {
              navigate("/new-partner");
            }}
          >
            Create
          </Fab>
        </Grid>
      )}
      <Grid container item xs={12}>
        <NuvvenTable
          title=""
          rows={rows}
          columns={partnerTableColumn}
          options={options}
          setSelection={() => { }}
        />
      </Grid>
    </Grid>
  )
}
