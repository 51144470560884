import { gql } from "@apollo/client";

export const GET_ADDITIONAL_DRIVER = gql`
  {
    addonTypes {
      id
      name
      category
      isActivated
    }
  }
`;