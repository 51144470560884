import { IBusinessCustomerCommonInput } from "../../reducers/customer/types";

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const VIEW_CUSTOMER = "VIEW_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const RESET_CUSTOMER = "RESET_CUSTOMER";

export const SET_AUTHORISED_SIGNATORY = "SET_AUTHORISED_SIGNATORY";
export const SET_APPROVED_DRIVER = "SET_APPROVED_DRIVER";

export const RESET_AUTHORISED_SIGNATORY = "RESET_AUTHORISED_SIGNATORY";
export const RESET_APPROVED_DRIVER = "RESET_APPROVED_DRIVER";
export const RESET_BUSINESS_CUSTOMER = "RESET_BUSINESS_CUSTOMER";

export const SET_BUSINESS_CUSTOMER = "SET_BUSINESS_CUSTOMER";
export const SET_BUSSINESS_CUSTOMERINFO = "SET_BUSSINESS_CUSTOMERINFO ";
export const SET_BUSINESS_CUSTOMER_BILLING = "SET_BUSINESS_CUSTOMER_BILLING";
export const SET_BUSINESS_AUTHORISED_SIGNATORY =
  "SET_BUSINESS_AUTHORISED_SIGNATORY";
export const SET_BUSINESS_APPROVED_DRIVERS = "SET_BUSINESS_APPROVED_DRIVERS";
export const SET_BUSINESS_CUSTOMER_DOCUMENT = "SET_BUSINESS_CUSTOMER_DOCUMENT";
export const SKIP_BUSINESS_APPROVED_DRIVERS = "SKIP_BUSINESS_APPROVED_DRIVERS";
export const UPDATE_BUSINESS_APPROVED_DRIVER = "UPDATE_BUSINESS_APPROVED_DRIVER";
interface IUpdateCustomer {
  type: typeof UPDATE_CUSTOMER;
  payload: any;
}

interface IViewCustomer {
  type: typeof VIEW_CUSTOMER;
  payload: any;
}

interface IDeleteCustomer {
  type: typeof UPDATE_CUSTOMER;
  payload: any;
}

interface IResetCustomer {
  type: typeof RESET_CUSTOMER;
}

interface ISetAuthorisedSignatory {
  type: typeof SET_AUTHORISED_SIGNATORY;
  payload: any;
}

interface ISetApprovedDriver {
  type: typeof SET_APPROVED_DRIVER;
  payload: any;
}
interface ISetBusinessCustomer {
  type: typeof SET_BUSINESS_CUSTOMER;
  payload: any;
}
// interface IUpdateAuthorisedSignatory {
//   type: typeof UPDATE_AUTHORISED_SIGNATORY;
//   payload: any;
// }
// interface IUpdateApprovedDriver {
//   type: typeof UPDATE_APPROVED_DRIVER;
//   payload: any;
// }
interface IResetAuthorisedSignagory {
  type: typeof RESET_AUTHORISED_SIGNATORY;
}
interface IResetApprovedDriver {
  type: typeof RESET_APPROVED_DRIVER;
}

interface IResetBusinessCustomer {
  type: typeof RESET_BUSINESS_CUSTOMER;
}
interface ISetBusinessCustomerInfo {
  type: typeof SET_BUSSINESS_CUSTOMERINFO;
  payload: any;
}

interface ISetBusinessCustomerBilling {
  type: typeof SET_BUSINESS_CUSTOMER_BILLING;
  payload: any;
}

interface ISetBusinessAuthorisedSignatory {
  type: typeof SET_BUSINESS_AUTHORISED_SIGNATORY;
  payload: any;
}

interface ISetBusinessApprovedDrivers {
  type: typeof SET_BUSINESS_APPROVED_DRIVERS;
  payload: any;
}

interface IUpdateBusinessApprovedDriver {
  type: typeof UPDATE_BUSINESS_APPROVED_DRIVER;
  payload: IBusinessCustomerCommonInput
}

interface ISetBusinessCustomerDocument {
  type: typeof SET_BUSINESS_CUSTOMER_DOCUMENT;
  payload: any;
}

interface ISkipBusinessCustomer {
  type: typeof SKIP_BUSINESS_APPROVED_DRIVERS;
}
export type CustomerAction =
  | IUpdateCustomer
  | IViewCustomer
  | IDeleteCustomer
  | IResetCustomer
  | ISetAuthorisedSignatory
  | IResetAuthorisedSignagory
  | ISetApprovedDriver
  | IResetApprovedDriver
  // | IUpdateAuthorisedSignatory
  // | IUpdateApprovedDriver
  | ISetBusinessCustomer
  | IResetBusinessCustomer
  | ISetBusinessCustomerInfo
  | ISetBusinessCustomerBilling
  | ISetBusinessAuthorisedSignatory
  | ISetBusinessApprovedDrivers
  | ISetBusinessCustomerDocument
  | IUpdateBusinessApprovedDriver
  | ISkipBusinessCustomer;
