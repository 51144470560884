import {
  UPDATE_USER_BRANCH,
  UPDATE_TENANCY,
  UPDATE_TENANCY_ORGANISATION,
  UPDATE_USER,
  UPDATE_USER_CURRENT_BRANCH
} from "../../actions/user/types";
import { initialState } from "./const";
import { IUserState } from "./types";

export default function (
  state: IUserState = initialState,
  action: any
): IUserState {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
        currentTenancy: {
          ...state.currentTenancy,
          ...action.payload.currentTenancy
        },
        currentOrganisation: {
          ...state.currentOrganisation,
          ...action.payload.currentOrganisation,
          locale: action.payload.currentOrganisation.locale
            ? action.payload.currentOrganisation.locale
            : state.currentOrganisation.locale,
          currency: action.payload.currentOrganisation.currency
            ? action.payload.currentOrganisation.currency
            : state.currentOrganisation.currency
        },
        currentBranch: {
          ...state.currentBranch,
          ...action.payload.currentBranch
        }
      };
    case UPDATE_TENANCY:
      return {
        ...state,
        tenancy: {
          ...state.tenancy,
          ...action.payload
        }
      };
    case UPDATE_USER_BRANCH:
      return {
        ...state,
        branch: {
          ...state.branch,
          ...action.payload
        }
      };
      case UPDATE_USER_CURRENT_BRANCH:
      return {
        ...state,
        currentBranch: {
          ...state.currentBranch,
          ...action.payload
        }
      };
    case UPDATE_TENANCY_ORGANISATION:
      return {
        ...state,
        organisation: {
          ...state.organisation,
          ...action.payload,
          locale: action.payload.locale
            ? action.payload.locale
            : state.organisation.locale,
          currency: action.payload.currency
            ? action.payload.currency
            : state.organisation.currency
        }
      };
    default:
      return state;
  }
}
