import { gql } from "@apollo/client";

export const GET_USER_PERMISSION_DOCUMENT = gql`
  query getUserPermissionDocument {
    getUserPermissionDocument{
      id
      permissionList{
        key
        label
        actions{
          key
          label
          permissions
          extends
          actions{
            key
            label
            permissions
            extends
            actions{
              key
              label
              permissions
              extends
            }
          }
        }
      }
    }
  }
`;