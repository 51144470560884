import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Paper,
  Theme,
  Typography,
  Tooltip,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Hidden,
  Button,
  AppBar,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContentText
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-mui";
import { DateTime as d } from "luxon";
import React, { ChangeEvent, useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearBooking } from "../../../../../actions/bookings/actions";
import { APPLY_DISCOUNT_MUTATION } from '../../../../../graphql/bookings/applyDiscountMutation';
import { CANCEL_QUOTE } from "../../../../../graphql/bookings/cancelQuoteMutation";
import { GET_BOOKING } from "../../../../../graphql/bookings/getBookingQuery";
import { SEND_QUOTE } from "../../../../../graphql/bookings/sendQuoteMutation";
import {
  BOOKING_SOURCE,
  IAddonRequirementInput,
  IBooking,
  IBookingCreateInput,
  IBookingVehicleGroup,
  ValueType
} from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { CompanyInfo } from "../../../../common/InvoiceTemplate/Components/CompanyInfo";
import { CustomerInfo } from "../../../../common/InvoiceTemplate/Components/CustomerInfo";
import { InvoiceItems } from "../../../../common/InvoiceTemplate/Components/InvoiceItems";
import MailSendBox from "../../../../common/MailSendBox";
import ShowMessage from "../../../../common/MailSendBox/ShowMessage";
import DownloadPdf from "../../../../common/Pdf/DownloadPdf";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  toCurrency
} from "../../../../common/utils";
import {
  CANCEL_TITLE_QUOTE
} from "../../Summary/const";
import { BookingStatus, CustomerType } from "../../utils";
import { PriceRuleOperator } from "../../../../../reducers/priceRule/types";
import { calculateNewPrice } from "../../../Settings/Pricing/PriceRuleCreation/utils";
import { UPDATE_BOOKING_DEPOSIT_MUTATION } from "../../../../../graphql/bookings/updateBookingDepositMutation";
import { red } from "@mui/material/colors";
import { UserRoles } from "../../../../hoc/Authorization";
import { ADD_INVOICE_NOTE } from "../../../../../graphql/bookings/addInvoiceNoteMutation";
import { CONFIRM_BOOKING } from "../../../../../graphql/bookings/confirmBookingMutation";
import { BOOKING_TYPES } from "../../../../../reducers/bookings/consts";
import { getLocalizedDateFormat, getLocalizedTaxSyntex } from "../../../../../utils/localized.syntex";
interface IProps {
  bookingData: IBookingCreateInput;
  onSubmit(data?: IBookingCreateInput): void;
  updateBooking(data?: IBookingCreateInput): void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    menuButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 60px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    basicButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
    },
    cancelButton: {
      position: 'relative',
      width: 'calc(100vw - 100px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[900]
      }
    },
    pullRight: {
      float: "right",
      margin: theme.spacing(2)
    },
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    tableCellBorder: {
      borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
    discountSection: {
      marginTop: "10px",
      marginBottom: "10px",
      borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
    proceedButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      color: 'white',
      marginBottom: '10px',
      marginRight: 10,
      '&.Mui-disabled': {
        backgroundColor: 'gray',
        color: 'white',
      },
    },
  })
);
const QuoteView: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [booking, setBooking] = useState<IBooking>();
  const [sendClick, setSendClick] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [editIconVisible, setEditIconVisible] = useState<boolean>(false);
  const [mobileEditIconVisible, setMobileEditIconVisible] = useState<boolean>(true);
  const [depositEditable, setDepositEditable] = useState<boolean>(false);
  const [depositAmount, setDepositAmount] = useState<number>(booking?.depositDetails?.amount || 0)
  const [showMails, setShowMails] = useState<string[]>([]);
  const [quoteRefContent, setQuoteRefContent] = useState<HTMLElement>();
  const [discount, setDiscount] = useState<number>(0);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [invoiceNote, setinvoiceNote] = useState("");
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const CANCELLATION_REASONS = [
    'No show',
    'Vehicle Not available',
    'Requirement changed',
    'Price',
    'Other',
  ];

  const [loadBooking, { data: bookingData }] = useLazyQuery(GET_BOOKING, {
    fetchPolicy: "no-cache"
  });
  const [sendQuote] = useMutation(SEND_QUOTE, {
    onCompleted: () => {
      setLoading(false);
      setShowMessage(true);
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      setShowMessage(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const handleReasonChange = (reason: string) => {
    setSelectedReason(reason);
    if (reason !== 'Other') {
      setOtherReason('');
    }
  };
  const [updateDeposit, { loading: updateDepositLoading }] = useMutation(UPDATE_BOOKING_DEPOSIT_MUTATION, {
    onCompleted: ({ updateBookingDeposit }) => {
      setBooking(updateBookingDeposit)
      snackbar({
        message: "Deposit updated successfully",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [confirmBookingMutation] = useMutation(CONFIRM_BOOKING, {
    onCompleted: ({ confirmBooking }) => {
      navigate(`/view-booking?booking=${confirmBooking.id}`);
      setConfirmationLoading(false)
    },
    onError: (error: ApolloError) => {
      setConfirmationLoading(false)
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })

    }
  });

  const [addInvoiceNote] = useMutation(ADD_INVOICE_NOTE, {
    onCompleted: ({ addInvoiceNote }) => {
      setinvoiceNote(addInvoiceNote)
      snackbar({
        message: "Notes added successfully",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [applyDiscount, { loading: applyDiscountLoading }] = useMutation(APPLY_DISCOUNT_MUTATION, {
    onCompleted: ({ applyDiscount }) => {
      setBooking(applyDiscount)
      snackbar({
        message: "Discount applied successfully",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (props.bookingData.id) {
      loadBooking({
        variables: {
          id: props.bookingData.id
        }
      });
    }
  }, [props.bookingData]);
  useEffect(() => {
    if (bookingData && bookingData.booking) {
      setBooking(bookingData.booking);
      setDepositAmount(bookingData.booking.depositDetails?.amount || 0)
      setDiscount(bookingData.booking.discount.amount);
      setinvoiceNote(bookingData.booking.invoiceNote);
    }
  }, [bookingData]);
  const getApplicableRentalAmount = (
    basePrice: number,
    priceRuleOperator?: PriceRuleOperator,
    priceRuleValueType?: ValueType,
    priceRuleAmount?: number
  ) => {
    return calculateNewPrice(basePrice, priceRuleOperator, priceRuleValueType, priceRuleAmount)
  };
  const getTotalRentalPrice = () => {
    if (booking && booking?.vehicleGroups.length) {
      let totalRentalAmount = 0;
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < booking?.vehicleGroups.length; index++) {
        const vehicleGroup = booking?.vehicleGroups[index];
        const rentalPrice =
          getApplicableRentalAmount(
            vehicleGroup.baseRate,
            vehicleGroup.priceRuleOperator,
            vehicleGroup.priceRuleValueType,
            vehicleGroup.priceRuleAmount
          ) *
          vehicleGroup.count *
          (booking.paymentDetails?.rentalTime || 1);
        totalRentalAmount += rentalPrice;
      }
      return totalRentalAmount;
    }
    if (booking && booking.isSubscription) {
      return booking.activeSubscription.subscriptionAmount
    }
  };
  const onDiscountSubmit = (value: number) => {
    const totalRentalPrice = getTotalRentalPrice();
    if (totalRentalPrice && value >= totalRentalPrice) {
      const discountMessage = booking?.isSubscription ?
        "Discount should not be greater than or equal to total subscription amount" : "Discount should not be greater than or equal to total rental amount"
      return snackbar({
        message: formatGraphQLErrorMessage(
          discountMessage
        ),
        variant: SnackBarVariant.ERROR
      });
    }
    if (booking && booking.id) {
      applyDiscount({
        variables: {
          bookingId: booking.id,
          discount: {
            isPercent: booking.discount.isPercent,
            amount: value ? value : 0
          }
        }
      });
    }
  };

  const cancelQuote = (id: string) => {
    cancelQuoteMutation({
      variables: {
        id,
        cancellationReason: selectedReason === "Other" ? otherReason : selectedReason
      }
    });
  };

  const [cancelQuoteMutation, { loading: cancelQuoteLoading }] = useMutation(CANCEL_QUOTE, {
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: () => {
      snackbar({
        message: "Booking Cancelled",
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/bookings");
    }
  });
  const isReasonValid = () => {
    if (selectedReason === 'Other') {
      return otherReason.trim() !== '';
    }
    return selectedReason !== '';
  };
  const handleSubmit = (id: string) => {
    if (isReasonValid()) {
      cancelQuote(id);
    } else {
      if (selectedReason === 'Other') {
        snackbar({
          message: "Please enter the reason to cancel",
          variant: SnackBarVariant.ERROR
        });
      }
      else {
        snackbar({
          message: "Please select a reason to cancel",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  };
  const ifPastDateBooking = () => {
    if (userState.role !== UserRoles.SUPER_ADMIN) {
      const startTime = d.fromISO(booking?.pickupDateTime || "").toUTC().toISO();
      const nowTime = d.now().toUTC().toISO();
      if (nowTime > startTime) {
        return true;
      }
      return false;
    }
    return false;
  };
  const sendQuoteEmail = (sentMails: string[]) => {
    setLoading(true);
    setShowMessage(false);
    setSendClick(false);
    let customerEmail = "";
    if (booking?.customerType === CustomerType.INDIVIDUAL && booking.customer) {
      customerEmail = booking.customer.email;
    } else if (
      booking?.customerType === CustomerType.BUSINESS &&
      booking.businessCustomer
    ) {
      customerEmail = booking.businessCustomer.contact.email;
    }
    setShowMails([customerEmail, ...sentMails]);
    sendQuote({
      variables: {
        id: booking?.id,
        additionalMails: sentMails
      }
    });
  };

  const updateBooking = (booking: IBooking) => {
    const addonRequirements: IAddonRequirementInput[] = []
    booking.addonRequirements.forEach(item => {
      addonRequirements.push({
        ...item,
        addon: item.addon.id,
        tax: {
          title: item.tax && item.tax.title ? item.tax.title : "",
          value: item.tax && item.tax.value ? item.tax.value : 0
        }
      })
    })
    const vehicleGroups: IBookingVehicleGroup[] = []
    booking.vehicleGroups.forEach((item: IBookingVehicleGroup) => {
      const vg = {
        ...item,
        unlimitedMileage: item.unlimitedMileage || false,
        vehicleGroup: typeof item.vehicleGroup === "string" ? item.vehicleGroup : item.vehicleGroup.id
      }
      delete vg.activeVehicleSchedules
      vehicleGroups.push(vg)
    })
    if (!booking.zeroCostBooking && vehicleGroups.some((vg) => (vg.baseRate + (vg.priceRuleAmount || 0)) <= 0)) {
      return snackbar({
        message: "Rental Amount cannot be less than or equal to 0",
        variant: SnackBarVariant.ERROR
      })
    }
    props.updateBooking({
      ...booking,
      bookingId: booking.bookingId,
      pickupServiceLocation: booking.pickupServiceLocation.id,
      dropoffServiceLocation: booking.dropoffServiceLocation.id,
      insurancePolicy: booking.insurancePolicy?.id || "",
      addonRequirements,
      customer: booking.customer?.id || "",
      approvedDrivers: booking.approvedDrivers || [],
      businessCustomer: booking.businessCustomer?.id || "",
      vehicleGroups,
      source: BOOKING_SOURCE.B2B,
      locationSurcharges: booking.locationSurcharges || [],
      oneWayRentalFee: booking.oneWayRentalFee || null,
      retainDepositDetails: true
    })
  }

  const updateBookingDeposit = () => {
    if (booking) {
      updateDeposit({
        variables: {
          bookingId: booking.id,
          depositAmount
        }
      })
    }
  }

  if (!booking) {
    return (
      <Grid item xs={12} container justifyContent={"center"} alignItems={"center"} style={{ margin: "0 auto", marginTop: "20px", height: "50vh" }}>
        <CircularProgress />
      </Grid>
    )
  }
  return (
    <Grid container item xs={12} spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} sm={6}>
        <Hidden smDown>
          {/* Need to remove because steeper already have label */}
          {/* <Typography variant="h2">{"QUOTE"}</Typography> */}
        </Hidden>
        <Hidden smUp>
          <Typography variant="h2">{"SUMMARY"}</Typography>
        </Hidden>
      </Grid>
      <Hidden smDown>
        <Grid container item xs={6} justifyContent="flex-end">
          {!isEditable &&
            <Tooltip
              title={ifPastDateBooking() || booking.zeroCostBooking ? "Button disabled because either the pick-up time/date is in the past or it is a zero charge booking." : ""}
            >
              <span>
                <Fab
                  onClick={() => {
                    setIsEditable(true)
                  }}
                  variant="extended"
                  size="medium"
                  type="button"
                  disabled={ifPastDateBooking() || booking.zeroCostBooking || booking.isSubscription}
                >
                  {"Edit"}
                </Fab>
              </span>
            </Tooltip>}
        </Grid>
      </Hidden>
      <Grid container item style={{ marginBottom: 60 }} xs={12}>
        <Hidden smDown>
          <Paper
            className={classes.root}
            ref={(el: HTMLElement | null) => {
              if (el) {
                setQuoteRefContent(el);
              }
            }}
          >
            <Grid container item xs={12} spacing={2}>
              <CompanyInfo type="Quote" />
              <CustomerInfo bookingData={booking} />
              <InvoiceItems
                bookingData={booking}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                updateBooking={updateBooking}
                setBooking={setBooking}
              />
            </Grid>
            <Grid container item xs={12} style={{ marginTop: 20 }}>
              <Grid item xs={12} style={{ marginRight: 20 }}>
                <TextField
                  style={{ width: "100%" }}
                  label="Add Notes"
                  placeholder={"Additional TnCs, etc."}
                  name={"invoiceNote"}
                  multiline
                  rows={1}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setinvoiceNote(event.target.value);
                  }}
                  variant="outlined"
                  value={invoiceNote}
                  disabled={ifPastDateBooking()}
                  inputProps={{
                    maxLength: 300
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ marginTop: 10 }}>
                <Fab
                  className="blackBackButton"
                  variant="extended"
                  size="medium"
                  disabled={ifPastDateBooking() || !invoiceNote}
                  onClick={() => {
                    addInvoiceNote({
                      variables: {
                        bookingId: props.bookingData.id,
                        note: invoiceNote
                      }
                    })
                  }}
                >
                  Save
                </Fab>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid container xs={6} className={classes.discountSection}>
                {
                  booking.status === BookingStatus.QUOTE && (
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={1}
                      style={{ marginBottom: "10px" }}
                    >
                      <Grid container item xs={12}>
                        <Typography variant="h3">DISCOUNT {"&"} OFFERS</Typography>
                      </Grid>
                      <Grid container item xs={12}>
                        <Typography variant="body1">
                          Discount is applicable only on {booking.isSubscription ? "Subscription" : "Rental"} Amount. <br />
                        </Typography>
                      </Grid>
                      <Grid container item xs={12}>
                        <Formik
                          initialValues={{
                            discountAmount: discount
                          }}
                          onSubmit={({ discountAmount }, { setSubmitting }) => {
                            onDiscountSubmit(discountAmount);
                            setSubmitting(false);
                          }
                          }
                        >
                          {(props) => (
                            <Form>
                              <Grid xs={12} item container spacing={2}>
                                {
                                  !booking.discount.isPercent ? (
                                    <Grid xs={8} item container>
                                      <Field
                                        component={InputField}
                                        placeholder="Discount Amount"
                                        label="Discount Amount"
                                        name={"discountAmount"}
                                        value={props.values.discountAmount}
                                        fullWidth
                                        InputProps={{
                                          inputComponent: FloatInput as any
                                        }}
                                        inputProps={{
                                          hasCurrencyPrefix: true,
                                          allowNegative: false
                                        }}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid xs={8} item container>
                                      <Field
                                        component={InputField}
                                        placeholder="Discount(%)"
                                        label="Discount(%)"
                                        name={"discountAmount"}
                                        value={props.values.discountAmount}
                                        type="number"
                                        inputProps={{
                                          min: 0
                                        }}
                                        fullWidth
                                      />
                                    </Grid>
                                  )
                                }
                                <Grid xs={4} item container>
                                  <Tooltip
                                    title={ifPastDateBooking() ? "Button disabled because the pick-up time/date is in the past" : ""}
                                  >
                                    <span>
                                      <Fab
                                        className="blackBackButton"
                                        variant="extended"
                                        size="medium"
                                        aria-label="add"
                                        type="submit"
                                        disabled={ifPastDateBooking() || applyDiscountLoading || booking.zeroCostBooking}
                                      >{applyDiscountLoading ? (
                                        <CircularProgress
                                          size={14}
                                          style={{ color: "white", marginRight: "10px" }}
                                        />
                                      ) : 'Save'}
                                      </Fab>
                                    </span>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                      </Grid>
                    </Grid>
                  )
                }
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {`*${getLocalizedTaxSyntex(country)} is calculated against ${booking.isSubscription ? "Subscription" : "Rental"} Price and some selected
                    Add-Ons`} {"(Excluding Insurance and Deposit)"}
                  </Typography>
                  <Typography variant="body2">
                    *Editing any unit price on the quote will remove any applied discount
                    and it will need to be added again
                  </Typography>
                </Grid>
              </Grid>
              <Grid container xs={6} style={{ marginTop: "10px", marginBottom: "10px", paddingLeft: "10px" }}>
                <Grid container xs={12}>
                  <Typography variant="h3">
                    SECURITY DEPOSIT DETAILS
                  </Typography>
                </Grid>
                {updateDepositLoading ?
                  <CircularProgress /> :
                  <Grid container xs={12} style={{ marginTop: "1rem" }}>
                    <Typography variant="h4">
                      Payable Deposit:
                    </Typography>
                    {depositEditable ?
                      <>
                        <Grid xs={4} container style={{ marginLeft: "1rem" }}>
                          <TextField
                            placeholder="Deposit Amount"
                            label="Deposit Amount"
                            name={"depositAmount"}
                            fullWidth
                            style={{
                              position: "relative",
                              bottom: "0.4rem",
                            }}
                            InputProps={{
                              inputComponent: FloatInput as any,
                              value: depositAmount,
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const val = e.target.value || "0";
                                setDepositAmount(parseInt(val));
                              }
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                          />
                        </Grid>
                        <CheckIcon
                          onClick={() => {
                            setEditIconVisible(false)
                            setMobileEditIconVisible(true)
                            setDepositEditable(false)
                            updateBookingDeposit()
                          }}
                          style={{
                            marginLeft: "1rem",
                            cursor: "pointer"
                          }}
                        />
                        <CancelIcon
                          onClick={() => {
                            setEditIconVisible(false)
                            setMobileEditIconVisible(true)
                            setDepositEditable(false)
                            setDepositAmount(booking?.depositDetails?.amount || 0)
                          }}
                          style={{
                            marginLeft: "0.5rem",
                            cursor: "pointer"
                          }}
                        />
                      </> :
                      <span
                        style={{
                          display: "inline-block"
                        }}
                        onMouseEnter={() => setEditIconVisible(true)}
                        onMouseLeave={() => setEditIconVisible(false)}
                      >
                        <Typography variant="body1" style={{ marginLeft: "1rem", display: "inline" }}>
                          {toCurrency(booking.depositDetails?.amount || 0, currency, locale)}
                        </Typography>
                        {editIconVisible && !booking.zeroCostBooking && <EditIcon
                          onClick={() => setDepositEditable(true)}
                          style={{
                            marginLeft: "1rem",
                            position: "relative",
                            top: "0.2rem",
                            cursor: "pointer",
                            fontSize: "1rem",
                            display: "inline"
                          }}
                        />}
                      </span>
                    }
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              item
              xs={12}
              style={{ marginTop: "20px" }}
            >
              <Fab
                onClick={() => {
                  dispatch(clearBooking());
                  navigate("/bookings");
                }}
                className="greyButton"
                variant="extended"
                size="medium"
                type="button"
              >
                {"Close"}
              </Fab>
              <Box color="white" sx={{ pr: 2 }}></Box>
              {
                booking.status === BookingStatus.QUOTE && (
                  <>
                    <Fab
                      onClick={() => {
                        setDialogOpen(true)
                      }}
                      className="greyButton"
                      variant="extended"
                      size="medium"
                      type="button"
                    >
                      {"Cancel"}
                    </Fab>
                    <Box color="white" sx={{ pr: 2 }}></Box>
                    <DownloadPdf
                      getSource={{ type: "QUOTE", id: booking.id }}
                      pdfSource={booking.quoteUrl}
                      fileName={`Quote-${booking?.referenceNumber}.pdf`}
                    />
                    <Box color="white" sx={{ pr: 2 }}></Box>
                    <Fab
                      onClick={() => {
                        setSendClick(true);
                      }}
                      variant="extended"
                      size="medium"
                      type="button"
                      disabled={loading}
                    >
                      {loading && (
                        <CircularProgress
                          size={14}
                          style={{ marginRight: "10px" }}
                        />
                      )}
                      {"Send E-Mail"}
                    </Fab>
                    {sendClick ? (
                      <MailSendBox
                        documentType="Quote"
                        mails={(emails: string[]) => sendQuoteEmail(emails)}
                        open={sendClick}
                        handleClose={() => setSendClick(false)}
                      />
                    ) : (
                      ""
                    )}
                    {showMessage ? (
                      <ShowMessage
                        open={showMessage}
                        sentMails={showMails}
                        handleClose={() => setShowMessage(false)}
                      />
                    ) : (
                      ""
                    )}
                    <Box color="white" sx={{ pr: 2 }}></Box>
                    {booking.zeroCostBooking ? (
                      <Tooltip
                        title={ifPastDateBooking() ? "Button disabled because the pick-up time/date is in the past" : ""}
                      >
                        <span>
                          <Fab
                            onClick={() => {
                              setConfirmationLoading(true);
                              confirmBookingMutation({
                                variables: {
                                  id: booking.id,
                                  bookingType: BOOKING_TYPES.PREPAID
                                }
                              })
                            }}
                            disabled={ifPastDateBooking()}
                            variant="extended"
                            size="medium"
                            type="button"
                          >
                            {confirmationLoading && (
                              <CircularProgress
                                size={14}
                                style={{ color: "white", marginRight: "10px" }}
                              />
                            )} {"Confirm"}
                          </Fab>
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={ifPastDateBooking() ? "Button disabled because the pick-up time/date is in the past" : ""}
                      >
                        <span>
                          <Fab
                            onClick={() => {
                              // move to payments page
                              props.onSubmit();
                            }}
                            disabled={ifPastDateBooking()}
                            variant="extended"
                            size="medium"
                            type="button"
                          >
                            {"Pay & Confirm"}
                          </Fab>
                        </span>
                      </Tooltip>
                    )}
                  </>
                )
              }
              {
                booking.status === BookingStatus.DRAFT && (
                  <>
                    <Box color="white" sx={{ pr: 2 }}></Box>
                    <Fab
                      onClick={() => {
                        updateBooking(booking);
                      }}
                      variant="extended"
                      size="medium"
                      type="button"
                    >
                      {"Confirm"}
                    </Fab>
                  </>
                )
              }
            </Grid>
            {(booking.dateCreated !== booking.dateUpdated) && booking.updatedBy?.firstName && <Grid container xs={12} style={{ marginTop: "20px" }}>
              <Typography variant="h3">
                Last updated by {booking.updatedBy.firstName} at {getLocalizedDateFormat(country, booking?.dateUpdated, DATE_TYPE.EXPANDED)}
              </Typography>
            </Grid>}
          </Paper>
        </Hidden>
        {dialogOpen && (
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{CANCEL_TITLE_QUOTE}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description"> Please select a reason to cancel the quote: </DialogContentText>
              <RadioGroup value={selectedReason} onChange={(e) => handleReasonChange(e.target.value)}>
                {CANCELLATION_REASONS.map((reason) => (
                  <FormControlLabel key={reason} value={reason} control={<Radio />} label={reason} />
                ))}
              </RadioGroup>

              {selectedReason === 'Other' && (
                <div>
                  <h3>Enter the reason:</h3>
                  <TextField
                    type="text"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        padding: '10px 14px',
                      },
                    }}
                  />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleSubmit(booking.id)} disabled={cancelQuoteLoading} className={classes.proceedButton} style={{ marginBottom: "10px", marginRight: 10 }}>
                {cancelQuoteLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                Confirm
              </Button>
              <Button onClick={() => setDialogOpen(false)} disabled={cancelQuoteLoading} color="primary" style={{ backgroundColor: "#828282", color: "white", marginBottom: "10px", marginRight: "10px" }}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Hidden smUp>
          <CustomerInfo bookingData={booking} />
          <InvoiceItems
            bookingData={booking}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            updateBooking={updateBooking}
            setBooking={setBooking}
          />
          <Grid container item xs={12} style={{ marginTop: 20 }}>
            <Grid item xs={12} style={{ marginRight: 20 }}>
              <TextField
                style={{ width: "100%" }}
                label="Add Notes"
                placeholder={"Additional TnCs, etc."}
                name={"invoiceNote"}
                multiline
                rows={1}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setinvoiceNote(event.target.value);
                }}
                variant="outlined"
                value={invoiceNote}
              />
            </Grid>
            <Grid item xs={4} style={{ marginTop: 10 }}>
              <Fab
                className="blackBackButton"
                variant="extended"
                size="medium"
                onClick={() => {
                  addInvoiceNote({
                    variables: {
                      bookingId: props.bookingData.id,
                      note: invoiceNote
                    }
                  })
                }}
              >
                Save
              </Fab>
            </Grid>
          </Grid>
          {
            booking.status === BookingStatus.QUOTE && (
              <Grid
                container
                item
                xs={12}
                spacing={1}
                style={{ marginBottom: "10px" }}
              >
                <Grid container item xs={12}>
                  <Typography variant="h3">DISCOUNT {"&"} OFFERS</Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Typography variant="body1">
                    Discount is applicable only on Rental Amount. <br />
                  </Typography>
                </Grid>
                <Grid container item xs={12} justifyContent="center">
                  <Formik
                    initialValues={{
                      discountAmount: discount
                    }}
                    onSubmit={({ discountAmount }, { setSubmitting }) => {
                      onDiscountSubmit(discountAmount);
                      setSubmitting(false);
                    }
                    }
                  >
                    {(props) => (
                      <Form>
                        <Grid xs={12} item container>
                          {
                            !booking.discount.isPercent ? (
                              <Grid xs={12} item container>
                                <Field
                                  component={InputField}
                                  placeholder="Discount Amount"
                                  label="Discount Amount"
                                  name={"discountAmount"}
                                  value={props.values.discountAmount}
                                  fullWidth
                                  InputProps={{
                                    inputComponent: FloatInput as any
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                />
                              </Grid>
                            ) : (
                              <Grid xs={12} item container>
                                <Field
                                  component={InputField}
                                  placeholder="Discount(%)"
                                  label="Discount(%)"
                                  name={"discountAmount"}
                                  value={props.values.discountAmount}
                                  type="number"
                                  inputProps={{
                                    min: 0
                                  }}
                                  fullWidth
                                />
                              </Grid>
                            )
                          }
                          <Grid xs={12} item container>
                            <Box mt={1} />
                          </Grid>
                          <Grid xs={12} item container>
                            <Tooltip
                              title={ifPastDateBooking() ? "Button disabled because the pick-up time/date is in the past" : ""}
                            >
                              <Button
                                style={{ width: 'calc(100vw - 90px)' }}
                                className={classes.basicButton}
                                variant="contained"
                                type="submit"
                                aria-label="add"
                                disabled={ifPastDateBooking() || applyDiscountLoading || booking.zeroCostBooking}
                              >
                                {applyDiscountLoading ? (
                                  <CircularProgress
                                    size={14}
                                    style={{ color: "white", marginRight: "10px" }}
                                  />
                                ) : 'Save'}
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            )
          }
          <Grid item xs={12}>
            <Typography variant="body2">
              {`*${getLocalizedTaxSyntex(country)} is calculated against ${booking.isSubscription ? "Subscription" : "Rental"} Price and some selected
              Add-Ons`} {"(Excluding Insurance and Deposit)"}
            </Typography>
            <Typography variant="body2" style={{ marginTop: 8 }}>
              *Editing any unit price on the quote will remove any applied discount
              and it will need to be added again
            </Typography>
          </Grid>
          <Grid container xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Grid container xs={12}>
              <Typography variant="h3">
                SECURITY DEPOSIT DETAILS
              </Typography>
            </Grid>
            {updateDepositLoading ?
              <CircularProgress /> :
              <Grid container xs={12} style={{ marginTop: "1rem" }}>
                <Typography variant="h4">
                  Payable Deposit:
                </Typography>
                {depositEditable ?
                  <>
                    <Grid xs={4} container style={{ marginLeft: "1rem" }}>
                      <TextField
                        placeholder="Deposit Amount"
                        label="Deposit Amount"
                        name={"depositAmount"}
                        fullWidth
                        style={{
                          position: "relative",
                          bottom: "0.4rem",
                        }}
                        InputProps={{
                          inputComponent: FloatInput as any,
                          value: depositAmount,
                          onChange: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const val = e.target.value || "0";
                            setDepositAmount(parseInt(val));
                          }
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                      />
                    </Grid>
                    <CheckIcon
                      onClick={() => {
                        setEditIconVisible(false)
                        setMobileEditIconVisible(true)
                        setDepositEditable(false)
                        updateBookingDeposit()
                      }}
                      style={{
                        marginLeft: "1rem",
                        cursor: "pointer"
                      }}
                    />
                    <CancelIcon
                      onClick={() => {
                        setEditIconVisible(false)
                        setMobileEditIconVisible(true)
                        setDepositEditable(false)
                      }}
                      style={{
                        marginLeft: "0.5rem",
                        cursor: "pointer"
                      }}
                    />
                  </> :
                  <span
                    style={{
                      display: "inline-block"
                    }}
                  >
                    <Typography variant="body1" style={{ marginLeft: "1rem", display: "inline" }}>
                      {toCurrency(booking.depositDetails?.amount || 0, currency, locale)}
                    </Typography>
                    {mobileEditIconVisible && !booking.zeroCostBooking && <EditIcon
                      onClick={() => {
                        setMobileEditIconVisible(false)
                        setDepositEditable(true)
                      }}
                      style={{
                        marginLeft: "1rem",
                        position: "relative",
                        top: "0.2rem",
                        cursor: "pointer",
                        fontSize: "1rem",
                        display: "inline"
                      }}
                    />}
                  </span>
                }
              </Grid>
            }
          </Grid>
          {
            booking.status === BookingStatus.QUOTE && (
              <>
                <Grid container xs={12}>
                  <Grid item xs={6}>
                    <Button
                      className={classes.menuButton}
                      variant="contained"
                      aria-label="Back"
                      onClick={() => {
                        setSendClick(true);
                      }}
                      disabled={loading}
                    >
                      {loading && (
                        <CircularProgress
                          size={14}
                          style={{ marginRight: "10px" }}
                        />
                      )}
                      {"E-Mail"}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <DownloadPdf
                      getSource={{ type: "QUOTE", id: booking.id }}
                      pdfSource={booking.quoteUrl}
                      fileName={`Quote-${booking?.referenceNumber}.pdf`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className={classes.cancelButton}
                      variant="contained"
                      aria-label="Back"
                      onClick={() => {
                        setDialogOpen(true)
                      }}
                      disabled={loading}
                    >
                      {"Cancel"}
                    </Button>
                  </Grid>
                </Grid>
                {sendClick ? (
                  <MailSendBox
                    documentType="Quote"
                    mails={(emails: string[]) => sendQuoteEmail(emails)}
                    open={sendClick}
                    handleClose={() => setSendClick(false)}
                  />
                ) : (
                  ""
                )}
                {showMessage ? (
                  <ShowMessage
                    open={showMessage}
                    sentMails={showMails}
                    handleClose={() => setShowMessage(false)}
                  />
                ) : (
                  ""
                )}
              </>
            )
          }
          {(booking.dateCreated !== booking.dateUpdated) && booking.updatedBy?.firstName && <Grid container xs={12} style={{ marginTop: "20px" }}>
            <Typography variant="h3">
              Last updated by {booking.updatedBy.firstName} at {getLocalizedDateFormat(country, booking?.dateUpdated, DATE_TYPE.EXPANDED)}
            </Typography>
          </Grid>}
          <AppBar position="fixed" className={classes.appBar}>
            <Grid container xs={12} spacing={1}>
              <Grid item xs={6}>
                <Button
                  className={classes.bottomButton}
                  variant="contained"
                  aria-label="Back"
                  onClick={() => {
                    dispatch(clearBooking());
                    navigate("/bookings");
                  }}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs={6}>
                {
                  booking.status === BookingStatus.QUOTE && booking.zeroCostBooking && (
                    <Tooltip
                      title={ifPastDateBooking() ? "Button disabled because the pick-up time/date is in the past" : ""}
                    >
                      <Button
                        className={classes.bottomButton}
                        variant="contained"
                        aria-label="Continue"
                        onClick={() => {
                          setConfirmationLoading(true);
                          confirmBookingMutation({
                            variables: {
                              id: booking.id,
                              bookingType: BOOKING_TYPES.PREPAID
                            }
                          })
                        }}
                        disabled={ifPastDateBooking()}
                      >
                        {confirmationLoading && (
                          <CircularProgress
                            size={14}
                            style={{ color: "white", marginRight: "10px" }}
                          />
                        )} {"Confirm"}
                      </Button>
                    </Tooltip>)
                }
                {
                  booking.status === BookingStatus.QUOTE && !booking.zeroCostBooking && (
                    <Tooltip
                      title={ifPastDateBooking() ? "Button disabled because the pick-up time/date is in the past" : ""}
                    >
                      <Button
                        className={classes.bottomButton}
                        variant="contained"
                        aria-label="Continue"
                        onClick={() => {
                          // move to payments page
                          props.onSubmit();
                        }}
                        disabled={ifPastDateBooking()}
                      >
                        {"Pay & Confirm"}
                      </Button>
                    </Tooltip>)
                }
                {
                  booking.status === BookingStatus.DRAFT && (
                    <>
                      <Button
                        className={classes.bottomButton}
                        variant="contained"
                        aria-label="Continue"
                        onClick={() => {
                          updateBooking(booking);
                        }}
                      >
                        {"Confirm"}
                      </Button>
                    </>
                  )
                }
              </Grid>
            </Grid>
          </AppBar>
        </Hidden>
      </Grid>
    </Grid >
  );
};
export default QuoteView;