import {
  FormControl,
  TextField
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ChangeEvent } from "react";

interface IProps {
  placeholder?: string;
  handleInput: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value?: string | number;
  type?: string;
  disabled?: boolean;
  maxLength?: number;
  onBlur?: any;
  required?: boolean;
  label?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: 30,
      fontSize: 14,
      height: 54,
      marginBottom: 28,
      marginRight: 60,
      minHeight: 54,
      outline: "none",
      paddingLeft: 24
    },
    root: {
      width: "100%"
    }
  })
);

export const InputField = (props: IProps) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.root}>
      <TextField
        type={props.type ? props.type : "text"}
        placeholder={props.placeholder}
        onChange={props.handleInput}
        name={props.name}
        value={props.value}
        disabled={props.disabled}
        onBlur={props.onBlur}
        required={props.required}
        label={props.label}
        inputProps={{ maxLength: props.maxLength && props.maxLength }}
      />
    </FormControl>
  );
};

export default InputField;
