import { gql } from "@apollo/client";

export const MARK_PCN_AS_PAID = gql`
  mutation markPCNPaid($id: String!) {
    markPCNPaid(id: $id) {
        id
        referenceNumber
        status
        isPaid
    }
  }
`;
