import { gql } from "@apollo/client";

export const GET_ASSET_DAMAGES = gql`
  query getAssetDamages($limit: Int!, $offset: Int!) {
    getAssetDamages(limit: $limit, offset: $offset) {
      id
      referenceNumber
      asset{
        id
        assetTag
      }
      bookingRef
      circumstance
      incidentDate
      estimatedRepairDate
      reportDate
      totalExpense
      status
      damages{
        title
        damageType
        damageKind
        damageArea
        images
        descriptions
      }
    }
  }
`;

export const GET_ASSET_DAMAGE = gql`
  query getAssetDamage($assetDamageId: String!) {
    getAssetDamage(assetDamageId: $assetDamageId) {
      id
      referenceNumber
      asset{
        id
        assetTag
      }
      bookingRef
      circumstance
      incidentDate
      estimatedRepairDate
      reportDate
      totalExpense
      status
      damages{
        title
        damageType
        damageKind
        damageArea
        images
        descriptions
      }
    }
  }
`;

export const GET_ASSET_DAMAGE_COUNT = gql`
  {
    getAssetDamageCount
  }
`;

export const GET_ASSET_ACTIVE_DAMAGES = gql`
  query getAssetActiveDamages($assetId: String!) {
    getAssetActiveDamages(assetId: $assetId) {
      id
      referenceNumber
      incidentDate
      reportDate
      circumstance
      totalExpense
      status
    }
  }
`;