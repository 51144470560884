import "./index.scss";

import { Theme, Hidden, SwipeableDrawer } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import React, { useState } from "react";
import poweredByText from "../../../assets/images/poweredbytext.png";
import AppMenu from "./AppMenu";
import { Header } from "../Header";

const drawerWidth = 230;
const UseStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "block",
      width: `calc(100% - 230px)`,
      marginLeft: drawerWidth
    },
    appBar: {
      top: 0,
      left: 0,
      bottom: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    popoverMenu: {
      width: "50%",
      "& .MuiPopover-paper": {
        width: "35%",
        padding: theme.spacing(2),
        marginRight: 10,
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
      }
    },
    signOutButton: {
      border: "none",
      background: "none",
      fontSize: 14,
      padding: 0
    },
    drawer: {
      zIndex: 1300,
    },
    buttons: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      [theme.breakpoints.down('lg')]: {
        width: `calc(100%px)`,
        marginLeft: 0,
      },
    },
    menuButton: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    drawerPaper: {
      zIndex: 1300,
      paddingBottom: 50,
      whiteSpace: "nowrap",
      width: drawerWidth,
      flexShrink: 0,
      height: "100%",
      background: "var(--theme-accent)",
      color: "#fff",
      "&::-webkit-scrollbar": {
        width: "6px"
      },
      "&::-webkit-scrollbar-track": {
        background: "var(--theme-accent)",
        borderRadius: 6
      },
      "&::-webkit-scrollbar-thumb": {
        background: "white",
        borderRadius: 6
      }
    },
    footerText: {
      color: "#fff",
      marginRight: theme.spacing(3),
      lineHeight: 0
    }
  })
);




const NavBar = () => {
  const styles = UseStyles();
  const container = window !== undefined ? () => document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <AppMenu />
      <div style={{ width: `${drawerWidth}px` }} className="powered-by-coastr">
        {true ? <img
          src={poweredByText}
          height={26}
          alt={"Powered by Coastr"}
        ></img> :
          "Powered by Nuvven"}
      </div>
    </div >
  )

  const iOS = (typeof window !== 'undefined') && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <div>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <nav className={styles.drawer} aria-label="site pages">
        <Hidden mdUp>
          <SwipeableDrawer
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            onOpen={() => setMobileOpen(true)}
            container={container}
            variant="temporary"
            anchor='left'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: styles.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            classes={{
              paper: styles.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div >
  );
};

export default NavBar;
