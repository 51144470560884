import { useLazyQuery } from '@apollo/client';
import { Box, CircularProgress, CssBaseline, Fab, Grid, Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import { DateTime as d } from "luxon"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_PROCUREMENTS } from '../../../graphql/Procurement/getProcurementsQuery';
import { IProcurementRequest, ProcurementRequestStatuses, ProcurementStatusValue } from '../../../reducers/Procurement/types';
import { IAppState } from '../../../store';
import { NuvvenTable } from '../../common/NuvvenTable/NuvvenTable';
import { UserRoles } from '../../hoc/Authorization';
import { getLocalizedDateFormat } from '../../../utils/localized.syntex';
import { DATE_TYPE, returnSortedOrder, setTableSortOrder } from '../../common/utils';
import { ITable, TableNames } from '../../../reducers/tableState/types';
import { addTableState } from '../../../actions/tableState/actions';

export const Procurements: React.FC = () => {
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState<IProcurementRequest[]>([]);
  const [procurementsTable, setProcurementssTable] = useState<ITable>();
  const procurementsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.procurements
  );
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();

  const [
    loadProcurements,
    { loading, data }
  ] = useLazyQuery(GET_PROCUREMENTS, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (userState.tenancy) {
      loadProcurements()
    }
  }, [userState.tenancy])

  useEffect(() => {
    if (procurementsTableState) {
      setProcurementssTable(procurementsTableState);
      let sortedOrder = returnSortedOrder(procurementsTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(partnerTableColumn, procurementsTableState, sortedOrder)
      }
    }
  }, [procurementsTableState]);

  useEffect(() => {
    if (data && data.procurementRequests) {
      const reShapeRows = data.procurementRequests.map((procurementRequest: any) => {
        let totalVehicles = 0
        for (let i = 0; i < procurementRequest.items.length; i++) {
          let item = procurementRequest.items[i];
          if (item) {
            totalVehicles += item.quantity
          }
        }
        return {
          ...procurementRequest,
          userName: `${procurementRequest.createdBy.firstName} ${procurementRequest.createdBy.lastName}`,
          noOfVehicles: totalVehicles
        }
      })
      setRows(reShapeRows)
    }
  }, [data]);

  const partnerTableColumn: any = [
    {
      label: "ID",
      name: "referenceNumber"
    },
    {
      label: "Requested By",
      name: "userName"
    },
    {
      label: "Creation Date",
      name: "createdAt",
      options: {
        customBodyRender: (value: string) =>
          getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED)
      }
    },
    {
      label: "No. of Vehicles",
      name: "noOfVehicles"
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value: ProcurementRequestStatuses) => ProcurementStatusValue[value]
      }
    }
  ];

  const options: MUIDataTableOptions = {
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: IProcurementRequest = rows[rowMeta.dataIndex];
      navigate(`/review-procurement-request?procurement=${row.id}`);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)      
      dispatch(
        addTableState({
          tableName: TableNames.PROCUREMENTS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
    procurementsTable && procurementsTable.rowsPerPage
        ? procurementsTable.rowsPerPage
        : limit,
        onTableInit: (action: string) => {
          if (procurementsTable && sortOrder) {
            setTableSortOrder(partnerTableColumn, procurementsTable, sortOrder);
          }
          if (procurementsTable && procurementsTable.rowsPerPage) {
            setLimit(procurementsTable.rowsPerPage);
          }
        },
  };

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Procurements
        </Typography>
      </Grid>
      {
        userState.role === UserRoles.FLEET_MANAGER && (
          <Grid container item xs={6} justifyContent="flex-end">
            <Fab
              variant="extended"
              size="medium"
              aria-label="Create"
              className="createButton"
              disabled={selection.length && UserRoles.FINANCE_MANAGER ? false : true}
              onClick={() => {
                const procurement = { ...selection[0] }
                if (userState.role === UserRoles.FLEET_MANAGER) {
                  navigate(`/update-procurement-request?procurementItemId=${procurement.id}`);
                } else {
                  return;
                }
              }}
            >
              Update
            </Fab>
            <Box pl={2}></Box>
            <Fab
              variant="extended"
              size="medium"
              aria-label="Create"
              className="createButton"
              onClick={() => {
                navigate("/new-procurement");
              }}
            >
              Create
            </Fab>

          </Grid>
        )
      }
      <Grid container item xs={12}>
        <NuvvenTable
          title=""
          rows={rows}
          options={options}
          columns={partnerTableColumn}
          setSelection={setSelection}
        />
      </Grid>
    </Grid >
  )
}