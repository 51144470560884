import { RESET_ADMIN_USER, UPDATE_ADMIN_USER } from "../../actions/AdminUser/types";
import { initialState } from "./const";
import { IAdminUserState } from "./types";

export default function (
  state: IAdminUserState = initialState,
  action: any
): IAdminUserState {
  switch (action.type) {
    case UPDATE_ADMIN_USER:
      return {
        ...state,
        adminUser: {
          ...state.adminUser,
          ...action.payload,
          organisationId: action.payload.organisation ? action.payload.organisation.id : "",
          branchId: action.payload.branch ? action.payload.branch.id : "",
        }
      };
    case RESET_ADMIN_USER: {
      return {
        ...state,
        adminUser: { ...initialState.adminUser }
      }
    }
    default:
      return state;
  }
}