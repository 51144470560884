import { Value } from "react-phone-number-input";

import { BOOKING_CATEGORY } from "../bookings/types";
import {
  IBranchState,
  IOrganisationState,
  IRentalAgreementSettings,
  IServiceLocationState,
  ISubscriptionAgreementSettings
} from "./types";

export const initialState: IOrganisationState = {
  branch: {
    address: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    emailId: "",
    id: "",
    name: "",
    owner: {
      id: "",
      email: ""
    },
    phoneNumber: {
      phone: "" as Value,
      country: "GB"
    },
    timeZone: "Europe/London",
    operatingHours: [],
    minimumBookingAmount: {
      type: "FIXED",
      value: 0
    },
    overbookingAllowed: false,
    minBookingDurationOptIn: false,
    minBookingAmountOptIn: false,
    preBookingQuestions: [],
    additionalDriverQuestions: [],
    isAdditionalDriverQuestionsEnabled: false,
    deliveryQuestions: [],
    distanceUnit: "mile",
    vehiclePreparationTime: 15,
    carShareGracePeriodMins: 15,
    holidays: [],
    addTaxOptIn: false,
    taxes: [],
    dailyBillingCycle: 30,
    weeklyBillingCycle: 1,
    monthlyBillingCycle: 1,
    durationBasedPricingEnabled: false,
    corporateRateCardEnabled: false,
    dashboardReminder: 7,
    subscriptionSettings: {
      creditCheckEnabled: false,
      dvlaVerificationEnabled: false,
      idvEnabled: false
    },
    autoPayEnabled: false,
    autoPayDays: []
  },
  organisation: {
    address: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    branches: [],
    cashEnabled: true,
    cardEnabled: true,
    payOnCollectionEnabled: true,
    payByLinkEnabled: true,
    autoChargeEnabled: false,
    requiresCustomerVerification: false,
    currency: "",
    emailId: "",
    id: "",
    locale: "",
    logoUrl: "",
    name: "",
    orgRegNumber: "",
    orgVatNumber: "",
    owner: "",
    phoneNumber: {
      phone: "" as Value,
      country: "GB"
    },
    rate: null,
    stripeAccountId: "",
    termsAndConditionsUrl: "",
    insuranceDeclaration: "",
    cancellationPolicy: "",
    refundPolicy: {
      flatCharge: 0,
      refundRules: []
    },
    requiresBookingApproval: false,
    closeGroupSharingEnabled: false,
    allowCarSharing: false,
    disableMapForCarShareVehicles: false,
    enableAutoCreditCheck: false,
    creditLimits: [
      { type: "AUTO_REJECT", lowerLimit: 0, upperLimit: 0 },
      { type: "APPROVAL_REQUIRED", lowerLimit: 0, upperLimit: 0 },
      { type: "AUTO_APPROVE", lowerLimit: 0, upperLimit: 0 }
    ],
    enableIdentityVerification: false,
    creditSafeCredentials: {
      username: "",
      password: ""
    },
    allowPreSigningAgreement: false,
    inspectionCheckEnabled: false,
    bookingCategories: [
      { isActive: true, type: BOOKING_CATEGORY.RENTAL, default: false },
      { isActive: false, type: BOOKING_CATEGORY.SUBSCRIPTION, default: false },
      { isActive: false, type: BOOKING_CATEGORY.COURTESY_CAR, default: false },
      { isActive: false, type: BOOKING_CATEGORY.TEST_DRIVE, default: false }
    ]
  },
  serviceLocation: {
    address: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    branch: "",
    id: "",
    name: "",
    addSurchargesOptIn: false,
    surcharges: [],
    isDisabled: false
  },
  serviceLocations: []
};

export const branchInitialState: IBranchState = {
  branch: {
    address: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    emailId: "",
    id: "",
    name: "",
    owner: {
      id: "",
      email: ""
    },
    phoneNumber: {
      phone: "" as Value,
      country: "GB"
    },
    timeZone: "Europe/London",
    operatingHours: [],
    minimumBookingAmount: {
      type: "VARIABLE",
      value: 100
    },
    overbookingAllowed: false,
    minBookingDurationOptIn: false,
    minBookingAmountOptIn: false,
    preBookingQuestions: [],
    additionalDriverQuestions: [],
    isAdditionalDriverQuestionsEnabled: false,
    deliveryQuestions: [],
    distanceUnit: "mile",
    vehiclePreparationTime: 15,
    carShareGracePeriodMins: 15,
    holidays: [],
    addTaxOptIn: false,
    taxes: [],
    dailyBillingCycle: 30,
    weeklyBillingCycle: 1,
    monthlyBillingCycle: 1,
    durationBasedPricingEnabled: false,
    corporateRateCardEnabled: false,
    dashboardReminder: 7,
    subscriptionSettings: {
      creditCheckEnabled: false,
      dvlaVerificationEnabled: false,
      idvEnabled: false
    },
    autoPayEnabled: false,
    autoPayDays: []
  }
};

export const serviceLocationInitialState: IServiceLocationState = {
  serviceLocation: {
    address: {
      city: "",
      country: "",
      fullAddress: "",
      state: "",
      street: "",
      zipcode: ""
    },
    branch: "",
    id: "",
    name: "",
    addSurchargesOptIn: false,
    surcharges: [],
    isDisabled: false
  }
};

export const DEFAULT_RENTAL_AGREEMENT_SETTINGS: IRentalAgreementSettings = {
  business: {
    additionalInfo: true,
    additonalTnc: true,
    addonDetails: ["addonName", "description", "quantity"],
    insuranceDetails: ["insuranceName", "description", "price"],
    bookingDetails: [
      "pickup",
      "dropoff",
      "additionalDrivers",
      "poNumber",
      "mileageLimit"
    ],
    bookingExtension: [
      "extensionNumber",
      "referenceNumber",
      "extensionPeriod",
      "invoiceRef",
      "dropOffLocation"
    ],
    cancellationPolicy: "",
    customerDeclaration: true,
    customerDetails: [
      "businessName",
      "email",
      "phoneNumber",
      "registrationNumber",
      "insuranceNumber"
    ],
    deliveryQuestions: true,
    securityDeposit: true,
    driverDetails: ["name", "phoneNumber", "address", "licenseNumber", "email"],
    insuranceDeclaration: "",
    paymentInfo: [
      "rentalAmount",
      "addonAmount",
      "deposit",
      "subtotal",
      "taxAmount",
      "totalPayableAmount",
      "amountPaid",
      "amountDue",
      "discountAmount",
      "insuranceAmount"
    ],
    preBookingQuestions: true,
    additionalDriverQuestions: true,
    rentalCompanyDetails: [
      "logoUrl",
      "name",
      "address",
      "phoneNumber",
      "emailId",
      "orgVatNumber",
      "orgRegNumber"
    ],
    tnc: "",
    vehicleDetails: [
      "make/model",
      "registrationNumber",
      "vin",
      "pcoNumber",
      "color"
    ],
    oldVehicleDetails: [
      "make/model",
      "registrationNumber",
      "vin",
      "pcoNumber",
      "color"
    ],
    subscriptionDetails: ["subscription", "pickup", "dropoff"]
  },
  individual: {
    additionalInfo: true,
    additonalTnc: true,
    securityDeposit: true,
    addonDetails: ["addonName", "description", "quantity"],
    insuranceDetails: ["insuranceName", "description", "price"],
    bookingDetails: [
      "pickup",
      "dropoff",
      "additionalDrivers",
      "poNumber",
      "mileageLimit"
    ],
    bookingExtension: [
      "extensionNumber",
      "referenceNumber",
      "extensionPeriod",
      "invoiceRef",
      "dropOffLocation"
    ],
    cancellationPolicy: "",
    customerDeclaration: true,
    customerDetails: [
      "name",
      "phoneNumber",
      "email",
      "licenseNumber",
      "licenseExpiry",
      "dateOfBirth",
      "address",
      "insuranceNumber"
    ],
    deliveryQuestions: true,
    driverDetails: ["name", "phoneNumber", "address", "licenseNumber", "email"],
    insuranceDeclaration: "",
    paymentInfo: [
      "rentalAmount",
      "addonAmount",
      "deposit",
      "subtotal",
      "taxAmount",
      "totalPayableAmount",
      "amountPaid",
      "amountDue",
      "discountAmount",
      "insuranceAmount"
    ],
    preBookingQuestions: true,
    additionalDriverQuestions: true,
    rentalCompanyDetails: [
      "logoUrl",
      "name",
      "address",
      "phoneNumber",
      "emailId",
      "orgVatNumber",
      "orgRegNumber"
    ],
    tnc: "",
    vehicleDetails: [
      "make/model",
      "registrationNumber",
      "vin",
      "pcoNumber",
      "color"
    ],
    oldVehicleDetails: [
      "make/model",
      "registrationNumber",
      "vin",
      "pcoNumber",
      "color"
    ],
    subscriptionDetails: ["subscription", "pickup", "dropoff"]
  }
};

export const DEFAULT_SUBSCRIPTION_AGREEMENT_SETTINGS: ISubscriptionAgreementSettings = {
  business: {
    additionalInfo: true,
    additonalTnc: true,
    securityDeposit: true,
    addonDetails: ["addonName", "description", "quantity"],
    insuranceDetails: ["insuranceName", "description", "price"],
    bookingExtension: [
      "extensionNumber",
      "invoiceRef",
      "referenceNumber",
      "dropOffLocation",
      "extensionPeriod"
    ],
    cancellationPolicy: "",
    customerDeclaration: true,
    customerDetails: [
      "name",
      "businessName",
      "phoneNumber",
      "dateOfBirth",
      "email",
      "address"
    ],
    deliveryQuestions: true,
    insuranceDeclaration: "",
    paymentInfo: [
      "addonAmount",
      "taxAmount",
      "totalPayableAmount",
      "amountPaid",
      "amountDue"
    ],
    preBookingQuestions: true,
    additionalDriverQuestions: true,
    rentalCompanyDetails: [
      "logoUrl",
      "name",
      "address",
      "phoneNumber",
      "emailId",
      "orgVatNumber",
      "orgRegNumber"
    ],
    tnc: "",
    vehicleDetails: [
      "make/model",
      "registrationNumber",
      "vin",
      "pcoNumber",
      "color"
    ],
    subscriptionDetails: [
      "subscription",
      "pickup",
      "subscriptionDate",
      "dropoff",
      "poNumber",
      "subscriptionPrice",
      "duration"
    ],
    oldVehicleDetails: [
      "make/model",
      "registrationNumber",
      "vin",
      "pcoNumber",
      "color"
    ]
  },
  individual: {
    additionalInfo: true,
    additonalTnc: true,
    securityDeposit: true,
    addonDetails: ["addonName", "description", "quantity"],
    insuranceDetails: ["insuranceName", "description", "price"],
    bookingExtension: [
      "extensionNumber",
      "invoiceRef",
      "referenceNumber",
      "dropOffLocation",
      "extensionPeriod"
    ],
    cancellationPolicy: "",
    customerDeclaration: true,
    customerDetails: ["name", "phoneNumber", "dateOfBirth", "email", "address"],
    deliveryQuestions: true,
    insuranceDeclaration: "",
    paymentInfo: [
      "addonAmount",
      "taxAmount",
      "totalPayableAmount",
      "amountPaid",
      "amountDue"
    ],
    preBookingQuestions: true,
    additionalDriverQuestions: true,
    rentalCompanyDetails: [
      "logoUrl",
      "name",
      "address",
      "phoneNumber",
      "emailId",
      "orgVatNumber",
      "orgRegNumber"
    ],
    tnc: "",
    vehicleDetails: [
      "make/model",
      "registrationNumber",
      "vin",
      "pcoNumber",
      "color"
    ],
    subscriptionDetails: [
      "subscription",
      "pickup",
      "subscriptionDate",
      "dropoff",
      "poNumber",
      "subscriptionPrice",
      "duration"
    ],
    oldVehicleDetails: [
      "make/model",
      "registrationNumber",
      "vin",
      "pcoNumber",
      "color"
    ]
  }
};

export const DepositCollection: {
  [key: string]: string;
} = {
  AT_BOOKING_CONFIRMATION: "AT_BOOKING_CONFIRMATION",
  AT_BOOKING_START: "AT_BOOKING_START"
};

export const MinimumBookingAmountTypes = {
  FIXED: "FIXED",
  VARIABLE: "VARIABLE"
};

export const taxTypes = [
  {
    label: "Percentage",
    value: "PERCENTAGE"
  },
  {
    label: "Fixed",
    value: "FIXED"
  }
];
export const taxRateTypes = [
  {
    label: "One Time",
    value: "ONE_TIME"
  },
  {
    label: "Per Day",
    value: "PER_DAY"
  }
];
