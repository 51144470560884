import { Box, CircularProgress, Fab, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import { withStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import React, { useEffect, useState } from 'react'
import { IProcurementRequest, IPurchaseOrder } from '../../../../reducers/Procurement/types'
import { DateTime as d } from "luxon"
import { useSnackBar } from '../../../common/SnackBarContext/SnackBarContext';
import { useMutation } from '@apollo/client';
import { ADD_PROCUREMENT_PURCHASE_ORDER } from '../../../../graphql/Procurement/addProcurementPurchaseOrderMutation';
import { SnackBarVariant } from '../../../common/SnackbarWrapper/SnackbarWrapper';
import { ApolloError } from '@apollo/client';
import { formatGraphQLErrorMessage } from '../../../common/utils';
import { UPDATE_PROCUREMENT_PURCHASE_ORDER } from '../../../../graphql/Procurement/updateProcurementPurchaseOrderMutation';
import { NewPurchaseOrderModel } from './Models/NewPurchaseOrderModel';
import { IAppState } from '../../../../store';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../../hoc/Authorization';
import MailSendBox from '../../../common/MailSendBox';
import ShowMessage from '../../../common/MailSendBox/ShowMessage';
import { SEND_PURCHASE_ORDER_EMAIL } from '../../../../graphql/Procurement/sendProcurementEmailMutation';

const HtmlTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#ffffff',
		color: 'rgba(0, 0, 0, 0.87)',
		minWidth: 250,
		border: '1px solid #dadde9',
		padding: 15
	},
}))(Tooltip);

const purchaseOrderInitials: IPurchaseOrder = {
	date: "",
	items: [],
	lastEmailSent: "",
	note: {
		createdAt: "",
		createdBy: "",
		description: "",
	},
	partnerId: "",
	purchaseOrderId: "",
	quoteId: "",
	validUntilDate: ""
}

interface IProps {
	procurement: IProcurementRequest;
	onNextStep(data: any): void
	onPreviousStep(): void;
	setProcurementRequest(data: IProcurementRequest): void;
}

export const PurchaseOrder: React.FC<IProps> = (props) => {

	const snackbar = useSnackBar();
	const userReducer = useSelector((state: IAppState) => state.userReducer);
	const [purchaseOrders, setPurchaseOrders] = useState<IPurchaseOrder[]>(props.procurement.purchaseOrders)
	const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>()
	const [open, setOpen] = useState<boolean>(false);
	const [sendClick, setSendClick] = useState<boolean>(false);
	const [showMessage, setShowMessage] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [showMails, setShowMails] = useState<string[]>([]);
	const [isNext, setIsNext] = useState<boolean>(true);

	useEffect(() => {
		if (props.procurement && props.procurement.purchaseOrders) {
			setPurchaseOrders(props.procurement.purchaseOrders)
			if (props.procurement.purchaseOrders[0].date && props.procurement.purchaseOrders[0].validUntilDate) {
				setIsNext(false)
			}
		}
	}, [props.procurement])

	const [addProcurementPurchaseOrder] = useMutation(ADD_PROCUREMENT_PURCHASE_ORDER, {
		onCompleted: (data) => {
			snackbar({
				message: "Purchase order added successfully",
				variant: SnackBarVariant.SUCCESS
			})
			if (data && data.addProcurementPurchaseOrder) {
				setPurchaseOrders([
					...(purchaseOrders ?? []), data.addProcurementPurchaseOrder
				])
				setPurchaseOrder(purchaseOrderInitials)
				setOpen(false)
			}
		},
		onError: (error: ApolloError) =>
			snackbar({
				message: formatGraphQLErrorMessage(error.message),
				variant: SnackBarVariant.ERROR
			})
	});

	const [updateProcurementPurchaseOrder] = useMutation(UPDATE_PROCUREMENT_PURCHASE_ORDER, {
		onCompleted: (data) => {
			snackbar({
				message: "Purchase order updated successfully",
				variant: SnackBarVariant.SUCCESS
			})
			if (data && data.updateProcurementPurchaseOrder) {
				const itemIndex = purchaseOrders.findIndex((pOrder) => pOrder.id === data.updateProcurementPurchaseOrder.purchaseOrder.id)
				if (itemIndex > -1) {
					const newOrders = [
						...purchaseOrders.slice(0, itemIndex),
						{
							...purchaseOrders[itemIndex],
							...data.updateProcurementPurchaseOrder.purchaseOrder
						},
						...purchaseOrders.slice(itemIndex + 1)
					];
					props.setProcurementRequest({
						...props.procurement,
						purchaseOrders: newOrders,
						financeInformation: [data.updateProcurementPurchaseOrder.financialInformation]
					})
				}
			}
			setOpen(false)
			setPurchaseOrder(purchaseOrderInitials)
		},
		onError: (error: ApolloError) =>
			snackbar({
				message: formatGraphQLErrorMessage(error.message),
				variant: SnackBarVariant.ERROR
			})
	});

	const [sendPurchaseOrderEmail] = useMutation(SEND_PURCHASE_ORDER_EMAIL, {
		onCompleted: () => {
			setLoading(false);
			setShowMessage(true);
		},
		onError: (error: ApolloError) => {
			setLoading(false);
			setShowMessage(false);
			snackbar({
				message: formatGraphQLErrorMessage(error.message),
				variant: SnackBarVariant.ERROR
			});
		}
	});

	const handelFormSubmit = (purchaseOrder: IPurchaseOrder) => {
		purchaseOrder.note = { ...purchaseOrder.note, createdBy: userReducer.id || "" }
		const { id, partner, ...rest } = purchaseOrder
		if (purchaseOrder.id) {
			updateProcurementPurchaseOrder({
				variables: {
					id: props.procurement.id,
					purchaseOrderId: purchaseOrder.id,
					purchaseOrder: rest,
				}
			})
		} else {
			addProcurementPurchaseOrder({
				variables: {
					id: props.procurement.id,
					purchaseOrder: rest
				}
			})
		}
	}

	const handleCloseDialog = () => {
		setOpen(false)
		setPurchaseOrder(purchaseOrderInitials)
	}

	const handleSendPurchaseOrderEmail = (purchaseOrderId: string, partnerEmail: string, sentMails: string[]) => {
		setLoading(true);
		setShowMessage(false);
		setSendClick(false);
		setShowMails([...sentMails, partnerEmail])
		sendPurchaseOrderEmail({
			variables: {
				id: props.procurement.id,
				purchaseOrderId,
				additionalMails: sentMails
			}
		});
	};

	return (
        <Grid container item xs={12} spacing={2}>
			<Grid item xs={12}>
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell className={"tableCellHead"} align="left">Quote ID</TableCell>
								<TableCell className={"tableCellHead"} align="left">PO Date</TableCell>
								<TableCell className={"tableCellHead"} align="left">Valid Until</TableCell>
								<TableCell className={"tableCellHead"} align="left">Notes</TableCell>
								<TableCell className={"tableCellHead"} align="left">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								props.procurement.purchaseOrders &&
								props.procurement.purchaseOrders.map((row: IPurchaseOrder, index: number) => (
									<TableRow
										key={index}
									>
										<TableCell align="left">{row.quoteId}</TableCell>
										<TableCell align="left">{row.date ? d.fromISO(row.date).toFormat("dd/MM/yyyy") : "N/A"}</TableCell>
										<TableCell align="left">{row.validUntilDate ? d.fromISO(row.validUntilDate).toFormat("dd/MM/yyyy") : "N/A"}</TableCell>
										<TableCell align="left">
											<HtmlTooltip
												title={
													<>
														<Typography variant={"h3"}>Description</Typography>
														<Typography variant={"body1"}>{row.note && row.note.description ? row.note.description : "N/A"}</Typography>
													</>
												}
											>
												<IconButton aria-label="note-preview" size="large">
													<VisibilityIcon />
												</IconButton>
											</HtmlTooltip>
										</TableCell>
										<TableCell align="right">
											<Grid item xs={12} container>
												<Tooltip title="Edit">
													<IconButton
                                                        aria-label="edit"
                                                        onClick={() => {
															const pOrder = purchaseOrders[index];
															setPurchaseOrder(pOrder);
															setOpen(true);
														}}
                                                        disabled={userReducer.role === UserRoles.ORGANISATION_OWNER ? false : true}
                                                        size="large">
														<EditIcon />
													</IconButton>
												</Tooltip>
												{/* <Tooltip title="Delete">
													<IconButton
														aria-label="delete"
														onClick={() => {
															if (row && row.id) {
																deletePurchaseOrder(props.procurement.id, row.id)
															}
														}}
														disabled={userReducer.role === UserRoles.ORGANISATION_OWNER ? false : true}
													>
														<DeleteIcon />
													</IconButton>
												</Tooltip> */}
												<IconButton
                                                    aria-label="mail"
                                                    disabled={userReducer.role === UserRoles.ORGANISATION_OWNER ? false : true || loading}
                                                    onClick={() => {
														setSendClick(true);
													}}
                                                    size="large">
													{loading && (
														<CircularProgress
															size={14}
															style={{ marginRight: "10px" }}
														/>
													)}
													<MailOutlineIcon />
												</IconButton>
												{sendClick ? (
													<MailSendBox
														documentType="Purchase order"
														mails={(emails: string[]) => {
															if (row && row.id && row.partner) {
																handleSendPurchaseOrderEmail(row.id, row.partner?.email, emails)
															}
														}}
														open={sendClick}
														handleClose={() => setSendClick(false)}
													/>
												) : (
													""
												)}
												{showMessage ? (
													<ShowMessage
                            open={showMessage}
                            sentMails={showMails}
                            handleClose={() => setShowMessage(false)}
                          />
												) : (
													""
												)}
											</Grid>
										</TableCell>
									</TableRow>
								))
							}
						</TableBody>
					</Table>
				</TableContainer>
				{
					open && (
						<NewPurchaseOrderModel
							handelFormSubmit={handelFormSubmit}
							onClose={() => handleCloseDialog()}
							open={open}
							purchaseOrder={purchaseOrder}
							items={props.procurement.items}
							procurementExpectedDate={props.procurement.createdAt}
						/>
					)
				}
				<Grid item xs={12}><Box mt={2}></Box></Grid>
				<Grid item container xs={12} justifyContent="space-between">
					<Fab
						className="blackBackButton"
						variant="extended"
						size="medium"
						aria-label="add"
						onClick={() => {
							props.onPreviousStep()
						}}
					>
						Previous
					</Fab>
					<Fab
						variant="extended"
						size="medium"
						aria-label="add"
						onClick={() => {
							props.onNextStep(purchaseOrders)
						}}
						disabled={isNext}
					>
						Next
					</Fab>
				</Grid>
			</Grid>
		</Grid>
    );
}
