import React, { ChangeEvent, FC, useEffect, useState } from "react";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  FormControl,
  Grid,
  Typography,
  Box,
  Paper,
  MenuItem,
  Theme,
  FormControlLabel,
  Switch,
  TextField as InputField,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "formik-mui";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import * as Yup from "yup";
import { DateTime as d } from "luxon"

import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  formatGraphQLErrorMessage
} from "../../../common/utils";
import { useSelector } from "react-redux";
import { GET_ASSET_SERVICE, SEARCH_ALL_ASSETS } from "../Graphql/assetServiceQueries";
import { assetDamageCircumstances, AssetDamageInitialValue, IAsset, IAssetDamage, IAssetDamageItem, IAssetService, IUpdateAssetServiceStatusInput } from "../asset.types";
import { IAppState } from "../../../../store";
import _ from "lodash";
import { IDateTime } from "../../Fleet/ServicesAndRepair/NewServicesAndRepair";
import { SimpleDateTimePicker } from "../../../common/SimpleDateTimePicker";
import { FloatInput } from "../../../common/FloatInput/FloatInput";
import { VehicleDamageStatus } from "../../../../reducers/damageVehicle/types";
import { GET_ASSET_DAMAGE } from "../Graphql/assetDamageQueries";
import { CREATE_ASSET_DAMAGE, UPDATE_ASSET_DAMAGE } from "../Graphql/assetDamageMutations";
import { AssetDamageItems } from "./AssetDamageItems";
import { NewImageView, SingleImageView } from "../../Fleet/Issues&Damages/NewDamage/NewImageView";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    },
    form: {
      flexGrow: 1
    }
  })
);

const initialAssetDamageItemData = {
  title: "",
  damageKind: "",
  damageType: "",
  damageArea: "",
  descriptions: "",
  images: [],
  location: {
    x: 0,
    y: 0
  }
}

export const NewAssetDamages: FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const [values, setValues] = useState<IAssetDamage>(AssetDamageInitialValue);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [assetTags, setAssetTags] = useState([]);
  const [openAddDamageItemDialog, setOpenAddDamageItemDialog] = useState(false);
  const [selectedDamageItemIndex, setSelectedDamageItemIndex] = useState<undefined | number>(undefined);
  const [assetDamageItem, setAssetDamageItem] = useState<IAssetDamageItem>(initialAssetDamageItemData);
  const [assetDamageItems, setAssetDamageItems] = useState<IAssetDamageItem[]>([]);
  const [assetDamageFabErrorState, setAssetDamageFabErrorState] = useState<Boolean>(false);
  const [images, setImages] = useState<string[]>([]);

  const [searchAssetByAssetTag, {
    loading: searchAssetByAssetTagLoading,
    data: searchAssetByAssetTagData
  }] = useLazyQuery(SEARCH_ALL_ASSETS, {
    fetchPolicy: "network-only"
  });

  const [getAssetDamage, { loading: loadingGetAssetDamage, data: assetDamageData }] = useLazyQuery(GET_ASSET_DAMAGE, {
    fetchPolicy: "network-only",
    onCompleted: ({ getAssetDamage }) => {
      if (!getAssetDamage) {
        navigate("/asset-damage");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
      navigate("/asset-damage");
    }
  });

  const [createAssetDamage, { loading: createAssetDamageLoading }] = useMutation(CREATE_ASSET_DAMAGE, {
    onCompleted: () => {
      snackbar({
        message: formatGraphQLErrorMessage("Asset damage created"),
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/asset-damage");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  });

  const [updateAssetDamage, { data: updateAssetDamageData, loading: updateAssetDamageLoading }] = useMutation(UPDATE_ASSET_DAMAGE, {
    onCompleted: ({ updateAssetDamage }) => {
      setValues(updateAssetDamage);
      const _damageItems = updateAssetDamage.damages.map((damage: IAssetDamageItem, index: number) => {
        return {
          ...damage,
          id: index + 1
        }
      });
      setAssetDamageItems(_damageItems);
      snackbar({
        message: formatGraphQLErrorMessage("Asset Damage updated"),
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/asset-damage");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const assetDamageId = params.get("id");
      if (assetDamageId) {
        setIsUpdate(true);
        getAssetDamage({
          variables: {
            assetDamageId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (searchAssetByAssetTagData && searchAssetByAssetTagData.searchAssetByAssetTag) {
      const assets = searchAssetByAssetTagData.searchAssetByAssetTag.filter((asset: Partial<IAsset>) => !asset.isActive);
      setAssetTags(assets);
    }
  }, [searchAssetByAssetTagData]);

  useEffect(() => {
    if (assetDamageData && assetDamageData.getAssetDamage) {
      setValues({
        ...assetDamageData.getAssetDamage,
        assetTag: assetDamageData.getAssetDamage.asset.assetTag || values.assetTag
      });
      const _damageItems = assetDamageData.getAssetDamage.damages.map((damage: IAssetDamageItem, index: number) => {
        return {
          ...damage,
          id: index + 1
        }
      });
      setAssetDamageItems(_damageItems);
    }
  }, [assetDamageData, updateAssetDamageData]);

  useEffect(() => {
    if (query) {
      handleSearch(query)
    } else {
      setAssetTags([])
    }
  }, [query])

  const handleChange = _.debounce((searchText: string) => {
    setQuery(searchText);
  }, 500)

  const handleSearch = (searchText: string) => {
    if (searchText.trim().length > 2) {
      searchAssetByAssetTag({
        variables: {
          q: searchText.trim()
        }
      })
    } else {
      setAssetTags([])
    }
  }

  // const detailsFormSchema = Yup.object().shape({
  //   name: Yup.string().required("Name is required."),
  //   operator: Yup.string().required("Please select Rule Effect."),
  // });

  const handleAddNewDamageItem = (damageItem: IAssetDamageItem) => {
    if (selectedDamageItemIndex !== undefined) {
      const updatedDamageItems = values.damages.map((damage, i) => {
        if (i === selectedDamageItemIndex) {
          return damageItem;
        } else {
          return damage;
        }
      });
      setValues({
        ...values,
        damages: updatedDamageItems
      });
    } else {
      setValues({
        ...values,
        damages: [...values.damages, { ...damageItem }]
      });
    }
    setOpenAddDamageItemDialog(false);
    setSelectedDamageItemIndex(undefined);
    setAssetDamageItem(initialAssetDamageItemData);
  }

  const handleFormSubmit = (values: IAssetDamage) => {
    const { id, assetTag, referenceNumber, ...rest } = values
    if (!rest.status) {
      rest.status = VehicleDamageStatus.DUE
    }
    if (rest.asset && typeof rest.asset !== 'string') {
      rest.asset = rest.asset.id
    }
    if (values.id) {
      updateAssetDamage({
        variables: {
          assetDamageId: values.id,
          assetDamage: rest
        }
      });
    } else {
      createAssetDamage({
        variables: {
          assetDamage: rest
        }
      });
    }
  }

  if (loadingGetAssetDamage) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          Asset Damages{"  "}
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>
        {!isUpdate ? (
          <Typography variant="h1" color="primary">
            New
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Asset Damage
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            // validationSchema={partnerValidationSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              if (!values.damages.length) {
                snackbar({
                  message: 'No damage items were added',
                  variant: SnackBarVariant.ERROR
                })
                setAssetDamageFabErrorState(true);
                return;
              }
              handleFormSubmit(values);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid container item xs={values.id ? 2 : 12} style={{ maxWidth: '70%' }}>
                    <Typography variant="h2">DAMAGE DETAILS</Typography>
                  </Grid>
                  {
                    values.id &&
                    <Grid container item xs={10} justifyContent="flex-end">
                      <Grid item style={{ marginLeft: '10px' }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                props.values.status === VehicleDamageStatus.FIXED
                                  ? false
                                  : true
                              }
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                setValues({
                                  ...props.values,
                                  status: event.target.checked
                                    ? VehicleDamageStatus.DUE
                                    : VehicleDamageStatus.FIXED
                                });
                              }}
                              name="inService"
                              color="primary"
                            />
                          }
                          label={
                            <Typography variant="body1">
                              {props.values.status === VehicleDamageStatus.FIXED
                                ? "Fixed"
                                : "Due"}
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  }
                  <Grid item xs={4}>
                    <FormControl variant="outlined" fullWidth>
                      <Field
                        component={TextField}
                        name={"circumstance"}
                        fullWidth
                        type="text"
                        select
                        required
                        label="Circumstance"
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setValues({
                              ...props.values,
                              circumstance: event.target.value
                            });
                          },
                          value: props.values.circumstance
                        }}
                      >
                        {assetDamageCircumstances.map((item: any, index: number) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      id="combo-box-asset-vehicle-id"
                      onChange={(
                        _: React.ChangeEvent<{}>,
                        newVal: any
                      ) => {
                        if (newVal && newVal.id) {
                          setValues({
                            ...props.values,
                            asset: newVal.id,
                            assetTag: newVal.assetTag
                          });
                        } else {
                          setValues({
                            ...props.values,
                            asset: "",
                            assetTag: ""
                          });
                        }
                      }}
                      disabled={values.id ? true : false}
                      loading={searchAssetByAssetTagLoading}
                      loadingText={"Loading vehicles..."}
                      noOptionsText={query.length > 2 && !assetTags.length ?
                        "No asset found with search criteria" :
                        "Type to search assets"
                      }
                      options={assetTags ?? []}
                      getOptionLabel={(option: any) => {
                        return option.assetTag;
                      }
                      }
                      value={values}
                      style={{ width: "100%" }}
                      renderInput={(params: any) => (
                        <InputField
                          {...params}
                          label={"Search for asset"}
                          variant="outlined"
                          fullWidth
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
                          value={values.assetTag}
                        />
                      )}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} >
                    <SimpleDateTimePicker
                      date={props.values.incidentDate}
                      handleChange={(date: IDateTime) => {
                        props.setValues({
                          ...props.values,
                          incidentDate: d.fromISO(date.date).toUTC().toISO()
                        });
                      }}
                      required={true}
                      disabled={false}
                      name={"incidentDate"}
                      dateTitle={"Incident Date"}
                      timeTitle={"Incident Time"}
                      minDate={d.now().minus({ day: 7 }).toUTC().toISO()}
                      maxDate={d.now().toUTC().toISO()}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <SimpleDateTimePicker
                      date={props.values.reportDate || ""}
                      handleChange={(date: IDateTime) => {
                        setValues({
                          ...props.values,
                          reportDate: d.fromISO(date.date).toUTC().toISO()
                        })
                      }}
                      required={true}
                      disabled={!props.values.incidentDate}
                      name={"reportDate"}
                      dateTitle={"Report Date"}
                      timeTitle={"Report Time"}
                      minDate={d.fromISO(props.values.incidentDate).toUTC().toISO()}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <SimpleDateTimePicker
                      date={props.values.estimatedRepairDate || ""}
                      handleChange={(date: IDateTime) => {
                        setValues({
                          ...props.values,
                          estimatedRepairDate: d.fromISO(date.date).toUTC().toISO()
                        })
                      }}
                      required={true}
                      disabled={!props.values.incidentDate}
                      name={"estimatedRepairDate"}
                      dateTitle={"Estd. Repair Date"}
                      timeTitle={"Estd. Repair Time"}
                      minDate={d.fromISO(props.values.incidentDate).toUTC().toISO()}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Field
                      component={TextField}
                      label="Total Expense"
                      name={"totalExpense"}
                      placeholder={"e.g 100"}
                      InputProps={{
                        inputComponent: FloatInput as any
                      }}
                      inputProps={{
                        hasCurrencyPrefix: true,
                        allowNegative: false
                      }}
                      value={props.values.totalExpense}
                      onChange={props.handleChange}
                      fullWidth
                    ></Field>
                  </Grid>
                  <Grid container item xs={8} style={{ maxWidth: '70%', marginTop: 20 }}>
                    <Typography variant="h2">DAMAGE ITEMS DETAILS</Typography>
                  </Grid>
                  <Grid container item xs={4} style={{ marginTop: 20 }} justifyContent={"flex-end"}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      style={assetDamageFabErrorState ? { border: 'red 3px solid' } : undefined}
                      onClick={() => {
                        setAssetDamageFabErrorState(false)
                        setOpenAddDamageItemDialog(true)
                      }}
                    >
                      Add Damage Items
                    </Fab>
                  </Grid>
                  <Grid container item sm={12}>
                    <Box mt={1}></Box>
                  </Grid>
                  {assetDamageItems && assetDamageItems.length > 0 && (
                    <Grid item xs={12} style={{ marginBottom: "20px", marginLeft: "25px" }}>
                      <NewImageView data={assetDamageItems} />
                    </Grid>
                  )}
                  <Grid container item xs={12}>
                    {values.damages && values.damages.length ? (
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell><span style={{ fontWeight: "bold" }}>ID</span></TableCell>
                              <TableCell><span style={{ fontWeight: "bold" }}>Title</span></TableCell>
                              <TableCell><span style={{ fontWeight: "bold" }}>Damage Type</span></TableCell>
                              <TableCell><span style={{ fontWeight: "bold" }}>Damage Kind</span></TableCell>
                              <TableCell><span style={{ fontWeight: "bold" }}>Damage Area</span></TableCell>
                              <TableCell><span style={{ fontWeight: "bold" }}>Images</span></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.damages.map((damage, index) => (
                              <TableRow
                                key={index}
                                style={{ cursor: "pointer" }}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={() => {
                                  setAssetDamageItem(damage);
                                  setSelectedDamageItemIndex(index);
                                  setOpenAddDamageItemDialog(true);
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {damage.title}
                                </TableCell>
                                <TableCell>{damage.damageType}</TableCell>
                                <TableCell>{damage.damageKind}</TableCell>
                                <TableCell>{damage.damageArea}</TableCell>
                                <TableCell>
                                  {damage.images && damage.images.length > 0 && (
                                    <Grid container spacing={1} columns={3} sx={{ maxWidth: '175px' }}>
                                      {damage.images.map((img: string, index: number) => (
                                        <Grid item key={`img: ${index}`} >
                                          <SingleImageView image={img} />
                                        </Grid>
                                      ))}
                                    </Grid>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : "No Damage item found!"}
                  </Grid>
                  <Grid item container xs={12}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="submit"
                    >
                      {(createAssetDamageLoading || updateAssetDamageLoading) && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                      Save
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {openAddDamageItemDialog &&
            <AssetDamageItems
              open={openAddDamageItemDialog}
              handleClose={() => {
                setOpenAddDamageItemDialog(false);
                setSelectedDamageItemIndex(undefined);
                setAssetDamageItem(initialAssetDamageItemData)
              }}
              images={(img: any) => setImages([...images, img])}
              assetDamageItem={assetDamageItem}
              handleSubmit={handleAddNewDamageItem}
            />}
        </Paper>
      </Grid>
    </Grid>
  )
}