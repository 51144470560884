import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import * as Yup from 'yup';
import {
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Theme,
} from "@mui/material";
import styles from "./index.module.css";
import { createStyles, withStyles } from '@mui/styles';
import { Field, FieldProps, Formik } from 'formik';
import authApi from "./api/AuthApi"
import { useMutation } from '../../../utils/axios-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { updateAuthStateAction } from '../../../actions/auth/actions';
import { AuthStates } from '../../../reducers/auth/types';
import { IAppState } from '../../../store';
import HttpHeader from "../../../header";

const StyledInputBase = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5,
    },
    error: {
      '& input': {
        borderColor: "#c71717",
        color: "#c71717"
      },
      '& textarea': {
        borderColor: "#c71717",
        color: "#c71717"
      },
      '& .MuiSelect-select': {
        borderColor: "#c71717",
        color: "#c71717",
        backgroundColor: '#fff !important',
      }
    },
    input: {
      borderRadius: "4px !important",
      position: 'relative',
      backgroundColor: "#fff",
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '12px 12px',
      minHeight: 'initial'
    },
  }),
)(InputBase);

interface ISignInProps {
  onSignIn: (result: string, user: any, token?: string) => void;
}

export const SignIn: React.FC<ISignInProps> = (props) => {
  const dispatch = useDispatch();
  const authState = useSelector((state: IAppState) => state.authReducer);

  const { mutate: signIn, error, loading } = useMutation<any, any>(authApi.signin, {
    onSuccess: (res, variables) => {
      if (res.success && !res.token) {
        props.onSignIn("OTP_SENT", { email: variables.email });
      }
      if (res.success && res.token) {
        props.onSignIn("SIGNIN_SUCCESS", res.user, res.token)
      }
    }
  });

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  return (
    <div className={styles.card}>
      {authState.message && <div className={styles.infoBox}>{authState.message}</div>}
      <div className={styles.top}>
        <div style={{ width: "100%" }}>
          <h3 style={{ margin: "10px 0" }}>Sign in</h3>
          <p style={{ opacity: 0.6 }}>Enter your email and password to login</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          validationSchema={schema}
          onSubmit={values => {
            signIn(values)
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit} className="text-left">
              <Field name="email">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ display: "flex", justifyContent: "space-beetween" }}>
                      <span>Email*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Enter your email here"
                      fullWidth
                      error={touched && Boolean(error)}
                    />
                  </>
                )}
              </Field>
              <Field name="password">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ marginTop: 20, display: "flex", justifyContent: "space-beetween" }}>
                      <span>Password*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Password"
                      fullWidth
                      type="password"
                      error={touched && Boolean(error)}
                    />
                  </>
                )}
              </Field>
              <Button type="submit" variant="contained" color="primary" size="large" fullWidth style={{ background: "var(--theme-primary", marginTop: 20, boxShadow: "none", fontSize: 16, fontWeight: 700 }}>
                {loading ? <CircularProgress color="inherit" size={20} thickness={5} /> : <span>Sign in &#8594;</span>}
              </Button>
              <p onClick={() => dispatch(updateAuthStateAction({ state: AuthStates.RESET_PASSWORD }))} style={{ color: "var(--theme-accent", margin: "16px 0 10px 0", cursor: "pointer" }}>Forgot password?</p>
              {error ? (
                <div className={styles.errorBox}>{error.response?.data.message}</div>
              ) : null}
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
};

interface IResetPasswordProps {
  onResetPasswordInitiate: (user: any) => void;
}

export const ResetPassword: React.FC<IResetPasswordProps> = (props) => {
  const dispatch = useDispatch()

  const { mutate: passwordResetInitiate, error, loading } = useMutation<any, any>(authApi.forgotPassword, {
    onSuccess: (res, variables) => {
      props.onResetPasswordInitiate({ email: variables.email });
    }
  });

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  return (
    <div className={styles.card}>
      <div className={styles.top}>
        <div>
          <h3 style={{ margin: "10px 0" }}>Password reset</h3>
          <p style={{ opacity: 0.6 }}>You will receive an OTP for password reset on your email</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={schema}
          onSubmit={values => {
            passwordResetInitiate(values)
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit} className="text-left">
              <Field name="email">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ display: "flex", justifyContent: "space-beetween" }}>
                      <span>Email*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Enter your email here"
                      fullWidth
                      error={touched && Boolean(error)}
                    />
                  </>
                )}
              </Field>
              <Button type="submit" variant="contained" color="primary" size="large" fullWidth style={{ background: "var(--theme-primary", marginTop: 20, boxShadow: "none", fontSize: 16, fontWeight: 700 }}>
                {loading ? <CircularProgress color="inherit" size={20} thickness={5} /> : <span>Send OTP &#8594;</span>}
              </Button>
              <p onClick={() => dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }))} style={{ color: "var(--theme-accent", margin: "16px 0 10px 0", cursor: "pointer" }}>Login instead?</p>
              {error ? (
                <div className={styles.errorBox}>{error.response?.data.message}</div>
              ) : null}
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
};

interface IVerifyOTPProps {
  email: string;
  onVerifyOTP: (user: any, token: string) => void;
}

export const VerifyOTP: React.FC<IVerifyOTPProps> = (props) => {
  const dispatch = useDispatch();
  const authState = useSelector((state: IAppState) => state.authReducer);

  const { mutate: validateOtp, error, loading } = useMutation<any, any>(authApi.validateOtp, {
    onSuccess: (res, variables) => {
      if (res.success && res.data.token) {
        props.onVerifyOTP(res.data.user, res.data.token);
      }
    }
  });

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    otp: Yup.string().required(),
  });

  return (
    <div className={styles.card}>
      {authState.message && <div className={styles.infoBox}>{authState.message}</div>}
      <div className={styles.top}>
        <div>
          <div style={{ margin: "0 10px 0 -20px" }}>
            <IconButton onClick={() => dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }))} aria-label="delete" size="small" color="default" edge="start">
              <KeyboardBackspaceIcon style={{ fontSize: 24 }} />
            </IconButton>
          </div>
        </div>
        <div>
          <h3 style={{ margin: "10px 0" }}>Verify OTP</h3>
          <p style={{ opacity: 0.6 }}>The OTP has been sent to your email</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <Formik
          initialValues={{
            email: props.email,
            otp: ""
          }}
          validationSchema={schema}
          onSubmit={values => {
            validateOtp(values)
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit} className="text-left">
              <Field name="otp">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ marginTop: 20, display: "flex", justifyContent: "space-beetween" }}>
                      <span>Enter OTP*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Enter OTP here"
                      fullWidth
                      type="text"
                      error={touched && Boolean(error)}
                    />
                  </>
                )}
              </Field>
              <Button type="submit" variant="contained" color="primary" size="large" fullWidth style={{ background: "var(--theme-primary", marginTop: 20, boxShadow: "none", fontSize: 16, fontWeight: 700 }}>
                {loading ? <CircularProgress color="inherit" size={20} thickness={5} /> : <span>Verify &#8594;</span>}
              </Button>
              {error ? (
                <div className={styles.errorBox}>{error.response?.data.message}</div>
              ) : null}
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
};

interface ISetPasswordProps {
  email: string;
  token: string;
  onSetPassword: (user: any, token: string) => void;
}

const httpHeader = HttpHeader.getInstance();

export const SetPassword: React.FC<ISetPasswordProps> = (props) => {
  const dispatch = useDispatch()

  const signIn = (user: any, token: string) => {
    localStorage.setItem("auth", JSON.stringify({ state: "SIGNED_IN", token, user }));
    httpHeader.setHeaders({ authorization: token });
    dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_IN, user, token }));
    window.location.href = "/"
  }

  const { mutate: setPassword, error, loading } = useMutation(authApi.setPassword, {
    onSuccess: (res) => {
      signIn(res.user, res.token);
    },
  });

  const schema = Yup.object().shape({
    password: Yup.string().required("Required*")
      .min(8, 'password length must be >=8')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    repeatPassword: Yup.string().required("Required*")
      .oneOf([Yup.ref('password'), ""], 'Passwords must match')
  });

  return (
    <div className={styles.card}>
      <div className={styles.top}>
        <div>
          <div style={{ margin: "0 10px 0 -20px" }}>
            <IconButton onClick={() => dispatch(updateAuthStateAction({ state: AuthStates.SIGNED_OUT }))} aria-label="delete" size="small" color="default" edge="start">
              <KeyboardBackspaceIcon style={{ fontSize: 24 }} />
            </IconButton>
          </div>
        </div>
        <div>
          <h3 style={{ margin: "10px 0" }}>Set new password</h3>
          <p style={{ opacity: 0.6 }}>Set new password for <b style={{ paddingLeft: 4 }}>{props.email}</b></p>
        </div>
      </div>
      <div className={styles.bottom}>
        <Formik
          initialValues={{
            password: "",
            repeatPassword: ""
          }}
          validationSchema={schema}
          onSubmit={values => {
            setPassword({
              email: props.email,
              token: props.token,
              password: values.password
            })
          }}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit} className="text-left">
              <Field name="password">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ display: "flex", justifyContent: "space-beetween" }}>
                      <span>New Password*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>Required*</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Enter password here"
                      fullWidth
                      type="password"
                      error={touched && Boolean(error)}
                    />
                    {field.value && (
                      <ul style={{ marginTop: 5, paddingLeft: 20 }}>
                        {field.value.length < 8 ? (
                          <li className={styles.invalid}><b>Must be 8 characters long</b></li>
                        ) : (
                          <li className={styles.valid}><b>Must be 8 characters long</b></li>
                        )}
                        <li className={/[A-Z]/.test(field.value) ? styles.valid : styles.invalid}><b>At least one Uppercase letter</b></li>
                        <li className={/[a-z]/.test(field.value) ? styles.valid : styles.invalid}><b>At least one Lowercase letter</b></li>
                        <li className={/[0-9]/.test(field.value) ? styles.valid : styles.invalid}><b>At least one Numerical character</b></li>
                        <li className={/[!@#$%^&*(),.?":{}|<>]/.test(field.value) ? styles.valid : styles.invalid}><b>At least one Special character</b></li>
                      </ul>
                    )}
                  </>
                )}
              </Field>
              <Field name="repeatPassword">
                {({ field, meta: { touched, error }, }: FieldProps) => (
                  <>
                    <p style={{ marginTop: 20, display: "flex", justifyContent: "space-beetween" }}>
                      <span>Confirm Password*</span>
                      {touched && Boolean(error) && <span className={styles.errorText}>{error}</span>}
                    </p>
                    <StyledInputBase
                      {...field}
                      placeholder="Re-enter password here"
                      fullWidth
                      type="password"
                      error={touched && Boolean(error)}
                    />
                  </>
                )}
              </Field>
              <Button type="submit" variant="contained" color="primary" size="large" fullWidth style={{ background: "var(--theme-primary", marginTop: 20, boxShadow: "none", fontSize: 16, fontWeight: 700 }}>
                {loading ? <CircularProgress color="inherit" size={20} thickness={5} /> : <span>Confirm &#8594;</span>}
              </Button>
              {error ? (
                <div className={styles.errorBox}>{error.response?.data.message}</div>
              ) : null}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
