import { IAppState } from "../../store";

export function getVehicle(state: IAppState) {
  return state.fleetReducer.vehicle;
}

export function getVehicleService(state: IAppState) {
  return state.fleetReducer.vehicleService;
}

export function getVehicleFinance(state: IAppState) {
  return state.fleetReducer.vehicleFinance;
}

export function getVehicleDamage(state: IAppState) {
  return state.fleetReducer.vehicleDamage;
}
