import { Dialog, DialogContent, Theme, DialogTitle, Grid, Typography, Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material'
import React from 'react'
import { makeStyles } from "@mui/styles";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const useStyles = makeStyles((theme: Theme) => ({
	dialogPaper: {
		width: '400px'
	},
}));

interface IProps {
	open: boolean;
	documents: string[];
	onClose(): void;
	download(doc: string, title: string): void;
}

export const DocumentsDialog: React.FC<IProps> = (props) => {

	const classes = useStyles();

	return (
        <Dialog
			maxWidth="md"
			aria-labelledby="documents-dialog"
			open={props.open}
			classes={{ paper: classes.dialogPaper }}
			onClose={props.onClose}
		>
			<DialogTitle id="documents-dialog"><Typography variant="h2" color='primary'>Documents</Typography></DialogTitle>
			<DialogContent>
				<Grid item xs={12}>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell className={"tableCellHead"} align="left">Document Name</TableCell>
									<TableCell className={"tableCellHead"} align="left">Download</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									props.documents &&
									props.documents.map((doc: string, index: number) => {
										return (
                                            <TableRow>
												<>
													<TableCell><span>{`doc ${index + 1}`}</span></TableCell>
													<TableCell>
														<IconButton
                                                            onClick={() => {
																if (doc) {
																	props.download(doc, `quote${index + 1}`)
																}
															}}
                                                            aria-label="preview"
                                                            size="large">
															<CloudDownloadIcon />
														</IconButton>
													</TableCell>
												</>
											</TableRow>
                                        );
									})
								}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</DialogContent>
		</Dialog>
    );
}
