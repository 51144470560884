import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Theme,
  Typography, Hidden, FormControl, MenuItem
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress
} from "react-places-autocomplete";
import { useSelector } from "react-redux";
import {
  IAddonType
} from "../../../../../reducers/addonType/types";
import {
  IAddonRequirementInput,
  IAddressLocation,
  IBookingCreateInput
} from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage, getAddonIcon } from "../../../../common/utils";
import { GET_VALET_ADDON_PRICE } from "../../../../../graphql/addOns/getValetAddonPrice";
import { useLazyQuery } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import { LocationTypes, ValetTypes, valetTypes } from "../../utils";
import { captureErrorException } from "../../../../../utils/sentry";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      flexGrow: 1
    },
    addonIcon: {
      width: "100%",
      height: "75px"
    },
    address: {
      marginLeft: "30px",
      marginBottom: "8px",
      width: "100%"
    },
    root: {
      padding: theme.spacing(1),
      flexGrow: 1,
      minHeight: 116
    }
  })
);

interface IProps {
  addon: IAddonType;
  bookingData: IBookingCreateInput;
  selectedAddon?: IAddonRequirementInput;
  onSelect(args: {
    addonType: IAddonType,
    quantity: number,
    rate?: number,
    pickupOtherLocation?: IAddressLocation,
    dropoffOtherLocation?: IAddressLocation,
    valetType?: String
  }): void;
}

const active = {
  backgroundColor: "#fafafa",
  cursor: "pointer"
} as React.CSSProperties;

const inactive = {
  backgroundColor: "#ffffff",
  cursor: "pointer"
} as React.CSSProperties;

const addressLocation: IAddressLocation = {
  city: "",
  country: "",
  fullAddress: "",
  state: "",
  street: "",
  zipcode: "",
  location: {
    type: "Point",
    latitude: 0,
    longitude: 0
  }
};

export const ValetCard: React.FC<IProps> = (props) => {

  const { addon, selectedAddon, onSelect, bookingData } = props;

  const snackbar = useSnackBar();
  const classes = useStyles();

  const userState = useSelector((state: IAppState) => state.userReducer);
  const branchInfo = {
    branchId: userState.currentBranch.id,
    organisationId: userState.currentOrganisation.id,
    tenantId: userState.currentTenancy.id
  }

  const [selected, setSelected] = useState<boolean>(!!selectedAddon?.addon);
  const [pickupOtherLocation, setPickupOtherLocation] = useState<IAddressLocation>(_.cloneDeep(addressLocation));
  const [dropoffOtherLocation, setDropoffOtherLocation] = useState<IAddressLocation>(_.cloneDeep(addressLocation));
  const [selectedAddressType, setSelectedAddressType] = useState<string>(ValetTypes.DELIVERY_AND_COLLECTION);
  const [deliveryAddonRate, setDeliveryAddonRate] = useState<number>(0)
  const [collectionAddonRate, setCollectionAddonRate] = useState<number>(0)
  const [addonRate, setAddonRate] = useState<number>(0)
  const [selectedAddressTypeKey, setSelectedAddressTypeKey] = useState<LocationTypes>()

  const [getValetAddonPrice, { data: valetAddonPriceData }] = useLazyQuery(GET_VALET_ADDON_PRICE, {
    fetchPolicy: "network-only",
    onCompleted: (valetAddonPriceData:any) => {
      if (selectedAddressType && valetAddonPriceData && valetAddonPriceData.getValetAddonPrice >= 0) {
        if (pickupOtherLocation.fullAddress && selectedAddressTypeKey === LocationTypes.PICKUP_LOCATION) {
          setDeliveryAddonRate(valetAddonPriceData.getValetAddonPrice)
        }
        if (dropoffOtherLocation.fullAddress && selectedAddressTypeKey === LocationTypes.DROP_OFF_LOCATION) {
          setCollectionAddonRate(valetAddonPriceData.getValetAddonPrice)
        }
        if (selectedAddressType === ValetTypes.DELIVERY && pickupOtherLocation.fullAddress) {
          setSelected(true)
          setAddonRate(valetAddonPriceData.getValetAddonPrice)
          onSelect({
            addonType: addon,
            quantity: 1,
            rate: valetAddonPriceData.getValetAddonPrice,
            pickupOtherLocation,
            dropoffOtherLocation
          });
        }
        if (selectedAddressType === ValetTypes.COLLECTION && dropoffOtherLocation.fullAddress) {
          setSelected(true)
          setAddonRate(valetAddonPriceData.getValetAddonPrice)
          onSelect({
            addonType: addon,
            quantity: 1,
            rate: valetAddonPriceData.getValetAddonPrice,
            pickupOtherLocation,
            dropoffOtherLocation
          });
        }
      }
    },
    onError: (error: ApolloError) => {
      setPickupOtherLocation(_.cloneDeep(addressLocation));
      setDropoffOtherLocation(_.cloneDeep(addressLocation));
      setSelected(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  })

  useEffect(() => {
    if (!selected) {
      props.onSelect({ addonType: addon, quantity: 0 });
    }
  }, [selected]);

  useEffect(() => {
    if (selectedAddon && bookingData) {
      if (bookingData.pickupOtherLocation?.fullAddress && !bookingData.dropoffOtherLocation?.fullAddress) {
        setPickupOtherLocation(bookingData.pickupOtherLocation)
        setDeliveryAddonRate(selectedAddon.rate)
        setAddonRate(selectedAddon.rate)
        setSelectedAddressType(ValetTypes.DELIVERY)
      }
      if (bookingData.dropoffOtherLocation?.fullAddress && !bookingData.pickupOtherLocation?.fullAddress) {
        setDropoffOtherLocation(bookingData.dropoffOtherLocation)
        setCollectionAddonRate(selectedAddon.rate)
        setAddonRate(selectedAddon.rate)
        setSelectedAddressType(ValetTypes.COLLECTION)
      }
      if (bookingData.pickupOtherLocation?.fullAddress && bookingData.dropoffOtherLocation?.fullAddress) {
        setPickupOtherLocation(bookingData.pickupOtherLocation)
        setDropoffOtherLocation(bookingData.dropoffOtherLocation)
        setAddonRate(selectedAddon.rate)
        setSelectedAddressType(ValetTypes.DELIVERY_AND_COLLECTION)
      }
    }
  }, [selectedAddon, bookingData]);


  useEffect(() => {
    if (
      pickupOtherLocation.fullAddress && pickupOtherLocation.city &&
      dropoffOtherLocation.fullAddress && dropoffOtherLocation.city
    ) {
      if (selectedAddressType === ValetTypes.DELIVERY_AND_COLLECTION) {
        setSelected(true);
        setAddonRate(deliveryAddonRate + collectionAddonRate);
        onSelect({
          addonType: addon,
          quantity: 1,
          rate: deliveryAddonRate + collectionAddonRate || addonRate,
          pickupOtherLocation,
          dropoffOtherLocation,
        });
      }
    }
  }, [
    pickupOtherLocation,
    dropoffOtherLocation,
    deliveryAddonRate,
    collectionAddonRate,
  ]);


  const handleSelectAddress = (address: string, type: LocationTypes) => {
    geocodeByAddress(address)
      .then((results) => {
        if (results.length) {
          const loc = _.cloneDeep(addressLocation);
          const place = results[0];
          const streetName: string[] = [];
          place.address_components.forEach((item) => {
            if (item.types.includes("country")) {
              loc.country = item.long_name;
            } else if (item.types.includes("postal_code")) {
              loc.zipcode = item.long_name;
            } else if (item.types.includes("administrative_area_level_2")) {
              loc.state = item.short_name;
            } else if (
              item.types.includes("postal_town") ||
              item.types.includes("locality")
            ) {
              loc.city = item.long_name;
            } else if (
              item.types.includes("street_number") ||
              item.types.includes("route")
            ) {
              streetName.push(item.long_name);
            }
          });
          loc.street = streetName.join(", ");
          loc.fullAddress = address;
          const lng = place.geometry.location.lng();
          const lat = place.geometry.location.lat();
          loc.location = {
            type: "Point",
            latitude: lat,
            longitude: lng
          };

          if (loc && loc.location && loc.location.latitude && loc.location.longitude) {
            const currentServiceLocation = userState.currentBranch.locations.find(location => location.id === bookingData.pickupServiceLocation)
            if (currentServiceLocation && currentServiceLocation.address && currentServiceLocation.address.geometry) {
              if (type === LocationTypes.PICKUP_LOCATION) {
                setSelectedAddressTypeKey(LocationTypes.PICKUP_LOCATION)
                getValetAddonPrice({
                  variables: {
                    branchInfo,
                    coordinates: {
                      startLat: String(currentServiceLocation.address.geometry?.latitude),
                      startLong: String(currentServiceLocation.address.geometry?.longitude),
                      endLat: String(loc.location.latitude),
                      endLong: String(loc.location.longitude)
                    }
                  },
                });
                setPickupOtherLocation({
                  ...pickupOtherLocation,
                  ...loc
                });
              }
              if (type === LocationTypes.DROP_OFF_LOCATION) {
                setSelectedAddressTypeKey(LocationTypes.DROP_OFF_LOCATION)
                getValetAddonPrice({
                  variables: {
                    branchInfo,
                    coordinates: {
                      startLat: String(currentServiceLocation.address.geometry?.latitude),
                      startLong: String(currentServiceLocation.address.geometry?.longitude),
                      endLat: String(loc.location.latitude),
                      endLong: String(loc.location.longitude)
                    }
                  },
                });
                setDropoffOtherLocation({
                  ...dropoffOtherLocation,
                  ...loc
                });
              }
            }
          }
        }
      })
      .catch((error) =>{
        captureErrorException(error)
      });
  };

  const handleOnChange = (value: string, type: LocationTypes) => {
    if (type === LocationTypes.PICKUP_LOCATION) {
      if (!value) {
        setPickupOtherLocation(_.cloneDeep(addressLocation));
        setDeliveryAddonRate(0)
        setAddonRate(0)
        setSelected(false)
      } else {
        setPickupOtherLocation({
          ...pickupOtherLocation,
          fullAddress: value
        });
      }
    }
    if (type === LocationTypes.DROP_OFF_LOCATION) {
      if (!value) {
        setDropoffOtherLocation(_.cloneDeep(addressLocation));
        setCollectionAddonRate(0)
        setAddonRate(0)
        setSelected(false)
      } else {
        setDropoffOtherLocation({
          ...dropoffOtherLocation,
          fullAddress: value
        });
      }
    }
  }

  return (
    <Paper
      className={classes.root}
      elevation={3}
      style={{ background: selected ? "#FFF6E3" : "" }}
    >
      <Grid container item xs={12} spacing={2}>
        <Hidden smDown>
          <Grid container item xs={1} alignItems="center">
            <img
              className={classes.addonIcon}
              src={getAddonIcon("valet")}
              alt="addon icon"
            />
          </Grid>
        </Hidden>
        <Grid container item xs={12} sm={11}>
          <Grid item xs={12}>
            <Box mt={0.5}></Box>
          </Grid>

          <Hidden smUp>
            <Grid container item xs={4} alignItems="center">
              <img
                className={classes.addonIcon}
                src={getAddonIcon("valet")}
                alt="addon icon"
              />
            </Grid>
          </Hidden>
          <Formik enableReinitialize initialValues={{}} onSubmit={() => { }}>
            {(props) => (
              <Form className={classes.form}>
                <Grid container item xs={12} spacing={2}>
                  <Grid container item xs={9} spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h2"> {addon.displayName ? addon.displayName : addon.name} </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">{addon.description}</Typography>
                    </Grid>
                    {(selectedAddressType === ValetTypes.DELIVERY || selectedAddressType === ValetTypes.DELIVERY_AND_COLLECTION) && (
                      <Grid item xs={12} sm={4}>
                        <PlacesAutocomplete
                          value={
                            pickupOtherLocation
                              ? pickupOtherLocation.fullAddress
                              : ""
                          }
                          onChange={(value: string) => {
                            handleOnChange(value, LocationTypes.PICKUP_LOCATION)
                          }}
                          onSelect={(value) => {
                            handleSelectAddress(value, LocationTypes.PICKUP_LOCATION);
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading
                          }) => (
                            <div className="location-search">
                              <Field
                                component={TextField}
                                fullWidth
                                style={{
                                  backgroundColor: selectedAddon ? "#FFFFFF" : ""
                                }}
                                label={"Delivery Address"}
                                {...getInputProps({
                                  className: "location-search-input",
                                  name: "state",
                                  placeholder: "Delivery Address"
                                })}
                              ></Field>
                              <div className="autocomplete-dropdown-container">
                                {suggestions.map((suggestion, idx) => {
                                  const style = suggestion.active
                                    ? active
                                    : inactive;
                                  suggestion.id = suggestion.id || idx.toString();
                                  return (
                                    <div
                                      className="suggestions"
                                      {...getSuggestionItemProps(suggestion, {
                                        style
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Grid>
                    )}
                    {(selectedAddressType === ValetTypes.COLLECTION || selectedAddressType === ValetTypes.DELIVERY_AND_COLLECTION) && (
                      <Grid item xs={12} sm={4}>
                        <PlacesAutocomplete
                          value={
                            dropoffOtherLocation
                              ? dropoffOtherLocation.fullAddress
                              : ""
                          }
                          onChange={(value) => {
                            handleOnChange(value, LocationTypes.DROP_OFF_LOCATION)
                          }}
                          onSelect={(value) => {
                            handleSelectAddress(value, LocationTypes.DROP_OFF_LOCATION);
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading
                          }) => (
                            <Grid className="location-search">
                              <Field
                                component={TextField}
                                fullWidth
                                style={{
                                  backgroundColor: selectedAddon ? "#FFFFFF" : ""
                                }}
                                label={"Collection Address"}
                                {...getInputProps({
                                  className: "location-search-input",
                                  name: "state",
                                  placeholder: "Collection Address",
                                  disabled: selectedAddressType === ValetTypes.DELIVERY_AND_COLLECTION && !pickupOtherLocation.fullAddress
                                })}

                              ></Field>
                              <Grid className="autocomplete-dropdown-container">
                                {suggestions.map((suggestion, idx) => {
                                  const style = suggestion.active
                                    ? active
                                    : inactive;
                                  suggestion.id = suggestion.id || idx.toString();
                                  return (
                                    <Grid
                                      className="suggestions"
                                      {...getSuggestionItemProps(suggestion, {
                                        style
                                      })}
                                    >
                                      {suggestion.description}
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          )}
                        </PlacesAutocomplete>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        placeholder={"Amount"}
                        label={"Amount"}
                        name={"amount"}
                        style={{
                          backgroundColor: selectedAddon ? "#FFFFFF" : ""
                        }}
                        disabled={true}
                        InputProps={{
                          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                          },
                          value: addonRate,
                          inputComponent: FloatInput as any
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={3} container justifyContent="flex-end">
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name={"typeOfValet"}
                          fullWidth
                          select
                          label="Type Of Valet"
                          value={selectedAddressType}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            onChange: (event: React.ChangeEvent<{ value: unknown }>) => {
                              setPickupOtherLocation(_.cloneDeep(addressLocation))
                              setDropoffOtherLocation(_.cloneDeep(addressLocation))
                              setSelected(false)
                              setDeliveryAddonRate(0)
                              setCollectionAddonRate(0)
                              setAddonRate(0)
                              setSelectedAddressType(event.target.value as string)
                            }
                          }}
                          placeholder="Preferred Invoicing Day"
                        >
                          {
                            valetTypes.map((item: string, index: number) => <MenuItem key={index} value={item}>{item}</MenuItem>)
                          }
                        </Field>
                      </FormControl>
                      <Grid item xs={6} style={{ marginLeft: 'auto', display: 'flex' }} alignItems="flex-end">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={selected}
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  setPickupOtherLocation(_.cloneDeep(addressLocation))
                                  setDropoffOtherLocation(_.cloneDeep(addressLocation))
                                  setSelected(e.target.checked);
                                  setDeliveryAddonRate(0)
                                  setCollectionAddonRate(0)
                                  setAddonRate(0)
                                } else {
                                  if (selectedAddressType === ValetTypes.DELIVERY_AND_COLLECTION && pickupOtherLocation.fullAddress && dropoffOtherLocation.fullAddress) {
                                    setSelected(e.target.checked);
                                  }
                                  else if (selectedAddressType === ValetTypes.DELIVERY && pickupOtherLocation.fullAddress) {
                                    setSelected(e.target.checked);
                                  }
                                  else if (selectedAddressType === ValetTypes.COLLECTION && dropoffOtherLocation.fullAddress) {
                                    setSelected(e.target.checked);
                                  }
                                }

                              }}
                              name="Selected"
                              color="primary"
                              style={{ marginLeft: 'auto' }}
                            />
                          }
                          label="Select"
                        />
                      </Grid>
                    </Grid>
                  </Grid>


                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Hidden smUp>
          <Grid container item xs={9}>
            <Box />
          </Grid>
        </Hidden>
      </Grid>
    </Paper>
  );
};
