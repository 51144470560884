import "./index.scss";
import { useLazyQuery } from "@apollo/client";
import {
  AppBar,
  Button,
  CircularProgress,
  CssBaseline,
  Fab,
  alpha,
  Grid,
  Hidden,
  InputBase,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import SearchIcon from '@mui/icons-material/Search';
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";
import { addTableState } from "../../../../actions/tableState/actions";
import {
  clearVehiclePriceGroup
} from "../../../../actions/vehiclePriceGroup/actions";
import { GET_VEHICLE_GROUPS_COUNT, GET_VEHICLE_GROUPS_LIST, SEARCH_VEHICLE_GROUPS } from "../../../../graphql/vehiclePriceGroup/getVehicleGroupsQuery";
import { TableNames } from "../../../../reducers/tableState/types";
import { IVehiclePriceGroup } from "../../../../reducers/vehiclePriceGroups/types";
import { IAppState } from "../../../../store";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder, toCurrency } from "../../../common/utils";
import { GET_VEHICLES_STATUS } from '../../../../graphql/fleet/getVehiclesStatus';
import { SelectableRows } from "../../../common/NuvvenTable/types";
import VehiclePriceGroupsVirtualScroller from "./VehiclePriceGroupsVirtualScroller";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";


interface IRow {
  id: string;
  name: string;
  vehicleCount: number;
  status: string;
  hourlyRate: number;
  dailyRate: number;
  weeklyRate: number;
  monthlyRate: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerMargin: {
      marginBottom: "120px"
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(100vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
      marginBottom: theme.spacing(1)
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    }
  })
);

export const VehiclePriceGroups: React.FC<any> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const [selection, setSelection] = useState<any>([]);
  const [rows, setRows] = useState<IRow[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [totalVehicleGroupsCount, setTotalVehicleGroupsCount] = useState<number>(0);
  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
        viewColumns: false
      }
    },
    {
      label: "Group Name",
      name: "name"
    },
    {
      label: "Vehicles",
      name: "vehicleCount"
    },
    {
      label: "Hourly Rate",
      name: "hourlyRate",
      options: {
        customBodyRender: (value: number) => toCurrency(value, currency, locale)
      }
    },
    {
      label: "Daily Rate",
      name: "dailyRate",
      options: {
        customBodyRender: (value: number) => toCurrency(value, currency, locale)
      }
    },
    {
      label: "Weekly Rate",
      name: "weeklyRate",
      options: {
        customBodyRender: (value: number) => toCurrency(value, currency, locale)
      }
    },
    {
      label: "Monthly Rate",
      name: "monthlyRate",
      options: {
        customBodyRender: (value: number) => toCurrency(value, currency, locale)
      }
    },
    {
      label: "Status",
      name: "status"
    }
  ];

  const [
    loadVehicleGroups,
    { loading: vehicleGroupsLoading, data: vehicleGroupsData }
  ] = useLazyQuery(GET_VEHICLE_GROUPS_LIST, {
    fetchPolicy: "network-only",
    onCompleted: (vehicleGroupsData) => {
      if (vehicleGroupsData && vehicleGroupsData.vehicleGroupsList) {
        let oldVehicleGroups = [...rows]
        let newVehicleGroups = vehicleGroupsData.vehicleGroupsList
        const reshapedVehicleGroups = newVehicleGroups.map(
          (vehicleGroup: IVehiclePriceGroup) =>
            reshapePriceRuleIntoRows(vehicleGroup)
        );
        reshapedVehicleGroups.forEach((vehicleGroup: any) => {
          if (!oldVehicleGroups.some((item) => item.id === vehicleGroup.id)) {
            oldVehicleGroups.push(vehicleGroup)
          }
        })
        setRows(oldVehicleGroups);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [vehicleGroupsCount, { loading: vehicleGroupsCountLoading, data: vehicleGroupsCountData }] = useLazyQuery(
    GET_VEHICLE_GROUPS_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (vehicleGroupsCountData) => setTotalVehicleGroupsCount(vehicleGroupsCountData.vehicleGroupsCount)
    }
  );

  const [searchVehicleGroups, { loading: searchVehicleGroupsLoading, data: searchVehicleGroupsData }] = useLazyQuery(
    SEARCH_VEHICLE_GROUPS,
    {
      fetchPolicy: "network-only",
      onCompleted: (searchVehicleGroupsData) => {
        if (searchVehicleGroupsData && searchVehicleGroupsData.searchVehicleGroups) {
          const newVehicleGroups = searchVehicleGroupsData.searchVehicleGroups.map((vehicle: IVehiclePriceGroup) =>
            reshapePriceRuleIntoRows(vehicle)
          );
          setTotalVehicleGroupsCount(newVehicleGroups.length)
          setRows(newVehicleGroups);
        }
      }
    }
  );

  const [
    loadVehicles,
    { loading: vehiclesLoading, data: vehiclesData }
  ] = useLazyQuery(GET_VEHICLES_STATUS, {
    fetchPolicy: "network-only"
  });

  const [sortOrder, setSortOrder] = useState<string>();
  const vehiclePriceGroups = useSelector(
    (state: IAppState) => state.tableStateReducer.vehiclePriceGroups
  );

  useEffect(() => {
    if (vehiclePriceGroups) {
      setSortOrder(returnSortedOrder(vehiclePriceGroups));
      loadVehicleGroups({
        variables: {
          limit: vehiclePriceGroups.rowsPerPage || limit,
          offset: 0
        }
      })
    }
  }, [vehiclePriceGroups]);

  useEffect(() => {
    if (userState.tenancy) {
      vehicleGroupsCount();
      loadVehicleGroups({
        variables: {
          limit: vehiclePriceGroups?.rowsPerPage || limit,
          offset: 0
        }
      });
      loadVehicles();
    }
  }, [userState]);

  const reshapePriceRuleIntoRows = (vehicleGroup: IVehiclePriceGroup) => {
    return {
      id: vehicleGroup.id,
      name: vehicleGroup.name,
      updatedAt: vehicleGroup.updatedAt,
      vehicleCount: vehicleGroup.vehicles.length,
      hourlyRate: vehicleGroup.basePrices.find(
        (item) => item.rateTypeName === "hourly"
      )?.rate,
      dailyRate: vehicleGroup.basePrices.find(
        (item) => item.rateTypeName === "daily"
      )?.rate,
      weeklyRate: vehicleGroup.basePrices.find(
        (item) => item.rateTypeName === "weekly"
      )?.rate,
      monthlyRate: vehicleGroup.basePrices.find(
        (item) => item.rateTypeName === "monthly"
      )?.rate,
      status: vehicleGroup.isActivated ? "Activated" : "Deactivated"
    };
  };

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchVehicleGroups({
        variables: {
          q: searchText
        }
      })
    }
  }, 1000);


  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: totalVehicleGroupsCount,
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: vehicleGroupsLoading || vehicleGroupsCountLoading || searchVehicleGroupsLoading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (rowData: any, rowMeta: { dataIndex: number, rowIndex: number }) => {
      if (rowData && rowData.length) {
        const row = rows[rowMeta.dataIndex];
        const rowId = row.id
        if (rowId) {
          navigate(`/update-vehicle-price-group?id=${rowId}`)
        }
      }
    },

    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText)
      } else {
        setRows([])
        vehicleGroupsCount()
        loadVehicleGroups({
          variables: {
            limit,
            offset: 0
          }
        })
      }
    },

    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.VEHICLE_PRICE_GROUPS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      vehiclePriceGroups && vehiclePriceGroups.rowsPerPage
        ? vehiclePriceGroups.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.VEHICLE_GROUPS,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: () => {
      if (vehiclePriceGroups && sortOrder) {
        setTableSortOrder(columns, vehiclePriceGroups, sortOrder);
      }
      if (vehiclePriceGroups && vehiclePriceGroups.rowsPerPage) {
        setLimit(vehiclePriceGroups.rowsPerPage);
      }
    },
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return; // Skip executing changePage if there is a search text
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length) {
            loadVehicleGroups({
              variables: {
                offset: page * rowsPerPage,
                limit: rowsPerPage
              }
            });
          }
          break;
        case "changeRowsPerPage":
          setLimit(tableState.rowsPerPage)
          setRows([])
          loadVehicleGroups({
            variables: {
              offset: 0,
              limit: vehiclePriceGroups?.rowsPerPage || tableState.rowsPerPage
            }
          });
          break;
        default:
          break;
      }
    },
  };

  const onChange = (event: { target: { value: any; }; }) => {
    if (event.target.value) {
      handleSearch(event.target.value);
      if (searchVehicleGroupsData && searchVehicleGroupsData.searchVehicleGroups) {
        const newGroups = searchVehicleGroupsData.searchVehicleGroups.map((group: IVehiclePriceGroup) =>
          reshapePriceRuleIntoRows(group)
        );
        setRows(newGroups)
      }
    }
    if (!event.target.value) {
      setRows([])
      loadVehicleGroups({
        variables: {
          limit: 10,
          offset: 0
        }
      })
    }
  };

  const handleScroll = (e: any) => {
    const threshold = 15000
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < threshold
    ) {
      loadVehicleGroups({
        variables: {
          offset: rows.length,
          limit: rows.length
        }
      })
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [vehicleGroupsData]);


  return <>
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} sm={6}>
        <Typography variant="h1" color="primary">
          Vehicle Group{"  "}
        </Typography>
        {vehiclesData && vehiclesData.vehicles && !vehiclesData.vehicles.length && (
          <Typography variant="h3" color="error">
            Please add vehicles in inventory to create vehicle group
          </Typography>
        )}
      </Grid>
      <Hidden smDown>
        <Grid container item xs={6} justifyContent="flex-end">
          <Fab
            variant="extended"
            size="medium"
            aria-label="Create"
            className="createButton"
            onClick={() => {
              dispatch(clearVehiclePriceGroup());
              navigate("/new-vehicle-price-group");
            }}
            disabled={vehiclesLoading || (vehiclesData && vehiclesData.vehicles && !vehiclesData.vehicles.length)}
          >
            Create
          </Fab>
        </Grid>
      </Hidden>
      {!(vehicleGroupsLoading && !rows.length) ? (
        <Grid container item xs={12}>
          <>
            <Hidden smDown>
              <NuvvenTable
                title={""}
                rows={rows}
                columns={columns}
                setSelection={setSelection}
                options={options}
              />
            </Hidden>
            <Hidden smUp>
              {searchVehicleGroupsLoading ? (<CircularProgress />) : (
                <div onScroll={handleScroll}>
                  <VehiclePriceGroupsVirtualScroller data={rows} />
                </div>
              )}
            </Hidden>
          </>
        </Grid>
      ) : (<CircularProgress />)}
    </Grid>
    <Hidden smUp>
      <AppBar position="fixed" className={classes.appBar}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Button
              className={classes.bottomButton}
              variant="contained"
              aria-label="Create"
              onClick={() => {
                dispatch(clearVehiclePriceGroup());
                navigate("/new-vehicle-price-group");
              }}
              disabled={vehiclesLoading || (vehiclesData && vehiclesData.vehicles && !vehiclesData.vehicles.length)}
            >
              Create
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={onChange}
              />
            </div>
          </Grid>
        </Grid>
      </AppBar>
    </Hidden>
  </>;
};
