import { gql } from "@apollo/client";

export const CREATE_ADMIN_USER = gql`
  mutation createAdminUser($user: AdminUserCreateInput!) {
    createAdminUser(user: $user){
      success
      message
    }
  }
`;

export const UPDATE_ADMIN_USER = gql`
  mutation updateAdminUser($userId: ID!, $user: UserUpdateInput!) {
    updateAdminUser(userId: $userId, user: $user) {
      id
      firstName
      lastName
      phoneNumber {
        phone
        country
      }
      role
      email
      active
      tenancy {
        id
        name
        appLogo
        themeColor
      }
      currentTenancy {
        id
        name
      }
      organisation {
        id
        name
      }
    }
  }
`;
