import { gql } from "@apollo/client";

export const GET_INVOICE_COUNT = gql`
  {
    invoiceCount
  }
`;

export const GET_BUSINESS_CUSTOMER_INVOICE_COUNT = gql`
query businessCustomerInvoiceCount($businessCustomerId: ID) {
  businessCustomerInvoiceCount(businessCustomerId: $businessCustomerId)
}
`;

export const GET_INDIVIDUAL_CUSTOMER_INVOICE_COUNT = gql`
query individualCustomerInvoiceCount($individualCustomerId: ID) {
  individualCustomerInvoiceCount(individualCustomerId: $individualCustomerId)
}
`;