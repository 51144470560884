import { ApolloError, useMutation } from "@apollo/client";
import {
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { CREATE_PAYMENT_FOR_DEPOSIT } from "../../../../../graphql/bookings/createPaymentForDepositMutation";
import { IBooking } from "../../../../../reducers/bookings/types";
import {
  IDepositPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  PaymentGateway,
  formatGraphQLErrorMessage
} from "../../../../common/utils";

interface IProps {
  amount: number;
  booking: IBooking;
  handleClose: () => void;
}

const PayByLinkDeposit: React.FC<IProps> = (props) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const {
    locale,
    currency,
    payByLinkEnabled,
    stripeAccountId,
    convergeEnabled
  } = userState.currentOrganisation;
  const snackbar = useSnackBar();
  const [amount, setAmount] = useState<number>(props.amount);
  const [paymentGateway, setPaymentGateway] = useState<string>(
    PaymentGateway.STRIPE
  );

  const [
    createPaymentForDeposit,
    { loading: createDepositPaymentLoading, data: createDepositPaymentData }
  ] = useMutation(CREATE_PAYMENT_FOR_DEPOSIT, {
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
    onCompleted: async (data) => {
      snackbar({
        message: "Payment Link sent successfully",
        variant: SnackBarVariant.SUCCESS
      });
      props.handleClose();
    }
  });

  useEffect(() => {
    setAmount(props.amount);
  }, [props.amount]);

  const handleSubmit = () => {
    const payment: IDepositPaymentInput = {
      amount: amount,
      booking: props.booking.id,
      currency,
      paymentMode: PaymentMode.PAY_BY_LINK,
      paymentGateway,
      successUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.booking.id}&status=success`,
      cancelUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.booking.id}`
    };
    createPaymentForDeposit({
      variables: {
        payment
      }
    });
  };

  const AMOUNT_TOO_HIGH = `Amount must be less than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format(props.amount / 100)}`;

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan(0)
      .max(props.amount, AMOUNT_TOO_HIGH)
  });

  const renderConvergeOptions = () => {
    const usOrg =
      userState.currentOrganisation.address.country === "United States";
    if (convergeEnabled && !usOrg) {
      return (
        <Grid item container>
          <Fab
            variant="extended"
            size="medium"
            aria-label="add"
            type="submit"
            disabled={createDepositPaymentLoading}
          >
            {createDepositPaymentLoading && (
              <CircularProgress
                size={14}
                style={{ color: "white", marginRight: "10px" }}
              />
            )}
            Send Payment Link
          </Fab>
        </Grid>
      );
    } else if (convergeEnabled && usOrg) {
      return (
        <Grid container xs={12}>
          <Typography style={{ margin: "1rem" }}>
            Pay by Link is not available for Converge.
          </Typography>
        </Grid>
      );
    } else {
      return (
        <Grid container xs={12}>
          <Typography style={{ margin: "1rem" }}>
            Please add Converge Credentials in Payments & Integrations settings
            to use this feature.
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <Grid container style={{ display: "inline-block", padding: "1rem" }}>
      {!payByLinkEnabled ? (
        <Grid container>
          <Typography variant="h4">
            To use this payment mode please enable Pay By Link in Payment and
            Integration Settings.
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item container xs={12}>
            <Formik
              enableReinitialize
              initialValues={{ amount: amount }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit();
                setSubmitting(false);
              }}
            >
              {(formikProps) => (
                <Form>
                  <Grid item xs={12}>
                    <FormControl>
                      <RadioGroup
                        row
                        name="paymentGateway"
                        value={paymentGateway}
                        onChange={(event) => {
                          setPaymentGateway(event.target.value);
                          setAmount(props.amount);
                        }}
                      >
                        <FormControlLabel
                          value={PaymentGateway.STRIPE}
                          control={<Radio />}
                          disabled={formikProps.isSubmitting}
                          label={
                            <Typography variant="body1">STRIPE</Typography>
                          }
                        />
                        <FormControlLabel
                          value={PaymentGateway.CONVERGE}
                          control={<Radio />}
                          disabled={formikProps.isSubmitting}
                          label={
                            <Typography variant="body1">CONVERGE</Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {paymentGateway === PaymentGateway.STRIPE &&
                    (stripeAccountId ? (
                      <Grid item container>
                        <Fab
                          variant="extended"
                          size="medium"
                          aria-label="add"
                          type="submit"
                          disabled={createDepositPaymentLoading}
                        >
                          {createDepositPaymentLoading && (
                            <CircularProgress
                              size={14}
                              style={{ color: "white", marginRight: "10px" }}
                            />
                          )}
                          Send Payment Link
                        </Fab>
                      </Grid>
                    ) : (
                      <Grid container xs={12}>
                        <Typography style={{ margin: "1rem" }}>
                          Please add Stripe Account Id in organisation settings
                          to use this feature.
                        </Typography>
                      </Grid>
                    ))}
                  {paymentGateway === PaymentGateway.CONVERGE &&
                    renderConvergeOptions()}
                </Form>
              )}
            </Formik>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PayByLinkDeposit;
