import {
  Grid,
  CssBaseline,
  Typography,
  Paper,
  FormControl,
  MenuItem,
  Fab,
  Theme,
  Box,
  Switch
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { ApolloError } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../common/utils";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { frogDiagramTypes } from "../VehicleInventory/ViewVehicle/Details/constants";
import { AddCustomCheckDialog } from "./AddCustomCheckDialog";
import { ICheckItem, IVehicleCheckList, IVehicleCheckListInput } from "../../../../reducers/fleet/types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_VEHICLE_CHECKLIST } from "../../../../graphql/fleet/vehicleCheckList/createVehicleCheckList";
import { GET_VEHICLE_CHECKLIST } from "../../../../graphql/fleet/vehicleCheckList/getCheckList";
import { GET_ACTIVE_FROGDIAGRAMS } from "../../../../graphql/fleet/vehicleCheckList/getActiveFrogDiagrams";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { CircularProgress } from "@mui/material";
import { VehicleBodyTypes } from "../../../common/VehicleDamagePreview/frogDiagram";

const defalutSuggestedGeneralChecks = [
  { name: "Washer/Wiper", isMandatory: false },
  { name: "Dashboard", isMandatory: false },
  { name: "Horn Sound", isMandatory: false },
  { name: "Interior Lights", isMandatory: false },
  { name: "Sunroof", isMandatory: false },
  { name: "Seat Belt", isMandatory: false },
  { name: "Ash Tray", isMandatory: false },
  { name: "Glove Box", isMandatory: false },
  { name: "Indicators", isMandatory: false },
  { name: "12v USB", isMandatory: false },
  { name: "Brake Light", isMandatory: false },
  { name: "Hazard Light", isMandatory: false },
  { name: "Number of Keys", isMandatory: false },
  { name: "SAT Nav", isMandatory: false },
  { name: "Pump Kit", isMandatory: false },
  { name: "Rear Mirror", isMandatory: false },
  { name: "Electric Window", isMandatory: false },
  { name: "Electric Mirror", isMandatory: false },
  { name: "Tow Bar", isMandatory: false },
  { name: "Media Screen", isMandatory: false },
  { name: "NS Mirror", isMandatory: false },
  { name: "Engine Oil Level", isMandatory: false },
  { name: "Brake Fluid", isMandatory: false },
  { name: "Coolant", isMandatory: false },
  { name: "Front Right Tyre Pressure", isMandatory: false },
  { name: "Rear Right Tyre Pressure", isMandatory: false },
  { name: "Front Left Tyre Pressure", isMandatory: false },
  { name: "Rear Left Tyre Pressure", isMandatory: false },
  { name: "Wheel Nut Torque Setting", isMandatory: false },
  { name: "Rear Screen/Window", isMandatory: false },
  { name: "Boot Carpet", isMandatory: false },
  { name: "Front Carpet", isMandatory: false },
  { name: "Aerial", isMandatory: false },
  { name: "Windscreen", isMandatory: false },
  { name: "Bonnet", isMandatory: false },
  { name: "Book Pack", isMandatory: false },
  { name: "Rear Bumper", isMandatory: false },
  { name: "Front Bumper", isMandatory: false },
  { name: "Roof Panel", isMandatory: false },
  { name: "Roof Bar/Rack", isMandatory: false },
  { name: "Front Grill", isMandatory: false },
  { name: "CD/DVD", isMandatory: false },
  { name: "Radio/DVD Rear Media", isMandatory: false },
  { name: "iPod Connector", isMandatory: false },
  { name: "Clean Exterior", isMandatory: false },
  { name: "Clean Interior", isMandatory: false },
  { name: "Water Level", isMandatory: false },
  { name: "Service Book", isMandatory: false },
  { name: "Wheel Trims", isMandatory: false },
  { name: "Next service due sticker", isMandatory: false }
];

const defalutTwoWheelerSuggestedGeneralChecks = [
  { name: "Oil Change", isMandatory: false },
  { name: "Oil filter", isMandatory: false },
  { name: "Air Filter", isMandatory: false },
  { name: "Spark Plug", isMandatory: false },
  { name: "Fuel System", isMandatory: false },
  { name: "Brakes", isMandatory: false },
  { name: "Tires and Wheels", isMandatory: false },
  { name: "Suspension", isMandatory: false },
  { name: "Drive Belt/Chain", isMandatory: false },
  { name: "Battery", isMandatory: false },
  { name: "Lights", isMandatory: false },
  { name: "Controls", isMandatory: false },
  { name: "Exhaust System", isMandatory: false },
  { name: "Coolant", isMandatory: false },
  { name: "Transmission/Clutch", isMandatory: false },
  { name: "Belts and Hoses", isMandatory: false },
  { name: "Chassis", isMandatory: false },
  { name: "Electrical System", isMandatory: false },
  { name: "Steering", isMandatory: false },
  { name: "Safety Systems", isMandatory: false },
  { name: "Fasteners", isMandatory: false }
];

const defalutSuggestedAccessoriesChecks = [
  { name: "Jack", isMandatory: false },
  { name: "Wrench", isMandatory: false },
  { name: "Tool Box", isMandatory: false },
  { name: "Stepney", isMandatory: false },
  { name: "Rear Camera", isMandatory: false },
  { name: "Tool Brace", isMandatory: false }
];

const defalutTwoWheelerSuggestedAccessoriesChecks = [
  { name: "Tool Box", isMandatory: false }
];

const defaultVehicleCheckList: IVehicleCheckListInput = {
  generalCheckListItems: [],
  accessoriesCheckListItems: [],
  frogDiagram: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3),
      width: "100%"
    },
    section: {
      marginBottom: "25px"
    }
  })
);

export const NewVehicleCheckList: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [values, setValues] = useState<IVehicleCheckListInput>(defaultVehicleCheckList);
  const [selectedGeneralChecks, setSelectedGeneralChecks] = useState<ICheckItem[]>([]);
  const [selectedAccessoriesChecks, setSelectedAccessoriesChecks] = useState<ICheckItem[]>([]);
  const [suggestedGeneralChecks, setSuggestedGeneralChecks] = useState<ICheckItem[]>([]);
  const [suggestedAccessoriesChecks, setSuggestedAccessoresChecks] = useState<ICheckItem[]>([]);
  const [frogDiagrams, setFrogDiagrams] = useState<any[]>(frogDiagramTypes);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [checkType, setCheckType] = useState<string>("");

  const [getVehicleCheckList, { data }] = useLazyQuery(GET_VEHICLE_CHECKLIST, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [getActiveFrogDiagrams, {
    data: activeFrogDiagramData
  }] = useLazyQuery(GET_ACTIVE_FROGDIAGRAMS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createVehicleCheckList, { loading: createVehicleCheckListLoading }] = useMutation(CREATE_VEHICLE_CHECKLIST, {
    onCompleted: () => {
      snackbar({
        message: "Vehicle Check list created",
        variant: SnackBarVariant.SUCCESS
      });
      navigate('/vehicle-checklist');
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (userState && userState.id) {
      getActiveFrogDiagrams();
      setSelectedAccessoriesChecks([]);
      setSelectedGeneralChecks([]);
      setValues({
        ...values,
        frogDiagram: ""
      })
    }
  }, [userState]);

  useEffect(() => {
    if (activeFrogDiagramData &&
      activeFrogDiagramData.getActiveFrogDiagrams &&
      activeFrogDiagramData.getActiveFrogDiagrams.length) {
      setFrogDiagrams(frogDiagramTypes.filter((frogDiagram: any) => activeFrogDiagramData.getActiveFrogDiagrams.includes(frogDiagram.value)));
    } else {
      setFrogDiagrams([]);
    }
  }, [activeFrogDiagramData])

  useEffect(() => {
    if (data) {
      const vehicleCheckListData: IVehicleCheckList = data.getVehicleCheckList;
      if (data.getVehicleCheckList) {
        
        setValues({
          ...values,
          id: vehicleCheckListData.id,
          frogDiagram: vehicleCheckListData.frogDiagram,
          generalCheckListItems: vehicleCheckListData.generalCheckListItems,
          accessoriesCheckListItems: vehicleCheckListData.accessoriesCheckListItems
        });
        setSelectedGeneralChecks(vehicleCheckListData.generalCheckListItems);
        setSelectedAccessoriesChecks(vehicleCheckListData.accessoriesCheckListItems);
        if (values.frogDiagram === VehicleBodyTypes.SCOOTER) {
          setSuggestedGeneralChecks(defalutTwoWheelerSuggestedGeneralChecks.filter(
            (generalCheck: ICheckItem) => !vehicleCheckListData?.generalCheckListItems?.some((item: ICheckItem) => item.name === generalCheck.name)
          ));
          setSuggestedAccessoresChecks(defalutTwoWheelerSuggestedAccessoriesChecks.filter(
            (accessoryCheck: ICheckItem) => !vehicleCheckListData?.accessoriesCheckListItems?.some((item: ICheckItem) => item.name === accessoryCheck.name)
          ));
        } else {
          setSuggestedGeneralChecks(defalutSuggestedGeneralChecks.filter(
            (generalCheck: ICheckItem) => !vehicleCheckListData?.generalCheckListItems?.some((item: ICheckItem) => item.name === generalCheck.name)
          ));
          setSuggestedAccessoresChecks(defalutSuggestedAccessoriesChecks.filter(
            (accessoryCheck: ICheckItem) => !vehicleCheckListData?.accessoriesCheckListItems?.some((item: ICheckItem) => item.name === accessoryCheck.name)
          ));
        }
      } else {
        if (values.frogDiagram === VehicleBodyTypes.SCOOTER) {
          setSuggestedGeneralChecks(defalutTwoWheelerSuggestedGeneralChecks.filter(
            (generalCheck: ICheckItem) => !data.getVehicleCheckList?.generalCheckListItems?.some((item: ICheckItem) => item.name === generalCheck.name)
          ));
          setSuggestedAccessoresChecks(defalutTwoWheelerSuggestedAccessoriesChecks.filter(
            (accessoryCheck: ICheckItem) => !data.getVehicleCheckList?.accessoriesCheckListItems?.some((item: ICheckItem) => item.name === accessoryCheck.name)
          ));
        } else {
          setSuggestedGeneralChecks(defalutSuggestedGeneralChecks.filter(
            (generalCheck: ICheckItem) => !data.getVehicleCheckList?.generalCheckListItems?.some((item: ICheckItem) => item.name === generalCheck.name)
          ));
          setSuggestedAccessoresChecks(defalutSuggestedAccessoriesChecks.filter(
            (accessoryCheck: ICheckItem) => !data.getVehicleCheckList?.accessoriesCheckListItems?.some((item: ICheckItem) => item.name === accessoryCheck.name)
          ));
        }
        setSelectedGeneralChecks([]);
        setSelectedAccessoriesChecks([]);
      }
    }
  }, [data]);

  const onSubmitHandler = (values: IVehicleCheckListInput) => {
    const vehicleCheckList = { ...values };
    if (selectedGeneralChecks && !selectedGeneralChecks.length) {
      return snackbar({
        message: "Please select at least one general check",
        variant: SnackBarVariant.ERROR
      });
    }
    if (selectedAccessoriesChecks && !selectedAccessoriesChecks.length) {
      return snackbar({
        message: "Please select at least one accessories check",
        variant: SnackBarVariant.ERROR
      });
    }
    vehicleCheckList.generalCheckListItems = [...selectedGeneralChecks];
    vehicleCheckList.accessoriesCheckListItems = [...selectedAccessoriesChecks];
    delete vehicleCheckList.id;
    createVehicleCheckList({
      variables: {
        vehicleCheckList
      }
    });
  };

  const handleAddCustomCheck = (customCheck: string, isisMandatory: boolean) => {
    const newCheck = { name: customCheck, isMandatory: isisMandatory };

    if (checkType === "GeneralCheck") {
      setSelectedGeneralChecks([newCheck, ...selectedGeneralChecks]);
    } else {
      setSelectedAccessoriesChecks([newCheck, ...selectedAccessoriesChecks]);
    }
    setOpenDialog(false);
    setCheckType("");
  }

  const vehicleCheckListSchema = Yup.object().shape({
    frogDiagram: Yup.string().required("Please select a frog diagram."),
  });

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          &nbsp;Create Vehicle Checklist
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            validationSchema={vehicleCheckListSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              const updateValues = {
                ...values
              }
              setSubmitting(false);
              onSubmitHandler(updateValues);
            }}
          >
            {(props) => (
              <Form noValidate>
                <Grid container spacing={2}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name="frogDiagram"
                          fullWidth
                          select
                          label="Select frog diagram"
                          value={props.values.frogDiagram}
                          InputProps={{
                            onChange: (event: any) => {
                              setValues({
                                ...props.values,
                                frogDiagram: event.target.value
                              });
                              if (event.target.value) {
                                getVehicleCheckList({
                                  variables: {
                                    frogDiagram: event.target.value
                                  }
                                })
                              }
                            }
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          required
                        >
                          {frogDiagrams &&
                            frogDiagrams?.map((item: any, index: number) => (
                              <MenuItem key={index} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {props.values.frogDiagram && (
                    <React.Fragment>
                      <Grid item container xs={12} spacing={3}>
                        <Grid item xs={9} style={{ paddingBottom: 0 }}>
                          <Typography variant="subtitle1">Vehicle General Checks</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container justifyContent="flex-end" style={{ height: 30 }}>
                            <Typography
                              variant="subtitle2"
                              style={{ cursor: "pointer", fontWeight: "bold" }}
                              onClick={() => {
                                setOpenDialog(true)
                                setCheckType("GeneralCheck");
                              }}
                            >
                              + Add Custom General Checks
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">Suggested Checks</Typography>
                          <Box
                            style={{
                              maxHeight: "80vh",
                              overflow: "auto"
                            }}
                          >
                            <Paper
                              variant="outlined"
                              square
                              style={{
                                minHeight: "75vh",
                                marginTop: 10
                              }}
                            >
                              {suggestedGeneralChecks?.map((suggestedCheck: {name: string; isMandatory: boolean; }, idx: number) => {
                                return (
                                  <Grid item xs={12} key={idx}>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        margin: "5px",
                                        marginLeft: "16px",
                                        marginRight: "16px"
                                      }}
                                    >
                                      <Grid container style={{ padding: "5px 15px", alignItems: "center" }}>
                                        <Grid item xs={8}>
                                          <p style={{
                                            padding: 0,
                                            margin: 0,
                                            fontSize: 14,
                                            marginTop: 5
                                          }}>
                                            {suggestedCheck.name}
                                          </p>
                                        </Grid>
                                        <Grid item xs={4} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                          <Typography>Mandatory</Typography>
                                          <Switch
                                          checked={suggestedCheck.isMandatory}
                                          onChange={() => {
                                            const updatedChecks = [...suggestedGeneralChecks];
                                            updatedChecks[idx] = {
                                              ...updatedChecks[idx],
                                              isMandatory: !updatedChecks[idx].isMandatory
                                            };
                                            setSuggestedGeneralChecks(updatedChecks);
                                          }}
                                          color="primary"
                                          />
                                          <span
                                            onClick={() => {
                                              setSelectedGeneralChecks([suggestedCheck, ...(selectedGeneralChecks || [])]);
                                              setSuggestedGeneralChecks(suggestedGeneralChecks.filter((check, index) => index !== idx));
                                            }}
                                            style={{ color: "white", cursor: "pointer" }}
                                          >
                                            <AddIcon
                                              style={{
                                                marginTop: 5,
                                                marginLeft: 10,
                                                backgroundColor: "var(--theme-accent)",
                                                cursor: "pointer",
                                                fontSize: 16,
                                                borderRadius: 2
                                              }}
                                            />
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                );
                              })}
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">Selected Checks</Typography>
                          <Box
                            style={{
                              maxHeight: "80vh",
                              overflow: "auto"
                            }}
                          >
                            <Paper
                              variant="outlined"
                              square
                              style={{
                                minHeight: "75vh",
                                marginTop: 10
                              }}
                            >
                              {selectedGeneralChecks?.map((selectedCheck: {name: string; isMandatory: boolean; }, idx: number) => {
                                return (
                                  <Grid item xs={12} key={idx}>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        margin: "5px",
                                        marginLeft: "16px",
                                        marginRight: "16px"
                                      }}
                                    >
                                      <Grid container style={{ padding: "5px 15px", alignItems: "center" }}>
                                        <Grid item xs={8}>
                                          <p style={{
                                            padding: 0,
                                            margin: 0,
                                            fontSize: 14,
                                            marginTop: 5
                                          }}>
                                            {selectedCheck.name}
                                          </p>
                                        </Grid>
                                        <Grid item xs={4} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                        <Typography>Mandatory</Typography>
                                        <Switch
                                        checked={selectedCheck.isMandatory}
                                        onChange={() => {
                                          const updatedChecks = [
                                            ...selectedGeneralChecks
                                          ];
                                          updatedChecks[idx] = {
                                            ...updatedChecks[idx],
                                            isMandatory: !updatedChecks[idx]
                                              .isMandatory
                                          };
                                          setSelectedGeneralChecks(
                                            updatedChecks
                                          );
                                        }}
                                        color="primary"
                                        />
                                          <span
                                            onClick={() => {
                                              const existsInSuggestedGeneralChecks = defalutSuggestedGeneralChecks?.some((check) => check.name === selectedCheck.name);
                                              setSelectedGeneralChecks(selectedGeneralChecks.filter((check, index) => index !== idx));
                                              if (existsInSuggestedGeneralChecks) {
                                                setSuggestedGeneralChecks([selectedCheck, ...suggestedGeneralChecks]);
                                              }
                                            }}
                                            style={{ color: "white", cursor: "pointer" }}
                                          >
                                            <RemoveIcon
                                              style={{
                                                marginTop: 5,
                                                marginLeft: 10,
                                                backgroundColor: "var(--theme-accent)",
                                                cursor: "pointer",
                                                fontSize: 16,
                                                borderRadius: 2
                                              }}
                                            />
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                );
                              })}
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} spacing={3}>
                        <Grid item xs={9} style={{ paddingBottom: 0 }}>
                          <Typography variant="subtitle1">Vehicle Accessories Checks</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container justifyContent="flex-end" style={{ height: 30 }}>
                            <Typography
                              variant="subtitle2"
                              style={{
                                cursor: "pointer",
                                fontWeight: "bold"
                              }}
                              onClick={() => {
                                setOpenDialog(true);
                                setCheckType("AccessoriesCheck")
                              }}
                            >
                              + Add Custom Accessories Checks
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">Suggested Accessories Checks</Typography>
                          <Box
                            style={{
                              maxHeight: "80vh",
                              overflow: "auto"
                            }}
                          >
                            <Paper
                              variant="outlined"
                              square
                              style={{
                                minHeight: "75vh",
                                marginTop: 10
                              }}
                            >
                              {suggestedAccessoriesChecks?.map((suggestedAccessoryCheck: {name: string; isMandatory: boolean; }, idx: number) => {
                                return (
                                  <Grid item xs={12} key={idx}>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        margin: "5px",
                                        marginLeft: "16px",
                                        marginRight: "16px"
                                      }}
                                    >
                                      <Grid container style={{ padding: "5px 15px", alignItems: "center" }}>
                                        <Grid item xs={8}>
                                          <p style={{
                                            padding: 0,
                                            margin: 0,
                                            fontSize: 14,
                                            marginTop: 5
                                          }}>
                                            {suggestedAccessoryCheck.name}
                                          </p>
                                        </Grid>
                                        <Grid item xs={4} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                        <Typography>Mandatory</Typography>
                                        <Switch
                                        checked={
                                          suggestedAccessoryCheck.isMandatory
                                        }
                                        onChange={() => {
                                          const updatedChecks = [
                                            ...suggestedAccessoriesChecks
                                          ];
                                          updatedChecks[idx] = {
                                            ...updatedChecks[idx],
                                            isMandatory: !updatedChecks[idx]
                                              .isMandatory
                                          };
                                          setSuggestedAccessoresChecks(
                                            updatedChecks
                                          );
                                        }}
                                        color="primary"
                                        />
                                          <span
                                            onClick={() => {
                                              setSelectedAccessoriesChecks([suggestedAccessoryCheck, ...(selectedAccessoriesChecks || [])]);
                                              setSuggestedAccessoresChecks(suggestedAccessoriesChecks.filter((check, index) => index !== idx));
                                            }}
                                            style={{ color: "white", cursor: "pointer" }}
                                          >
                                            <AddIcon
                                              style={{
                                                marginTop: 5,
                                                marginLeft: 10,
                                                backgroundColor: "var(--theme-accent)",
                                                cursor: "pointer",
                                                fontSize: 16,
                                                borderRadius: 2
                                              }}
                                            />
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                );
                              })}
                            </Paper>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">Selected Accessories Checks</Typography>
                          <Box
                            style={{
                              maxHeight: "80vh",
                              overflow: "auto"
                            }}
                          >
                            <Paper
                              variant="outlined"
                              square
                              style={{
                                minHeight: "75vh",
                                marginTop: 10
                              }}
                            >
                              {selectedAccessoriesChecks?.map((selectedAccessoryCheck: {name: string; isMandatory: boolean; }, idx: number) => {
                                return (
                                  <Grid item xs={12} key={idx}>
                                    <Paper
                                      elevation={3}
                                      style={{
                                        margin: "5px",
                                        marginLeft: "16px",
                                        marginRight: "16px"
                                      }}
                                    >
                                      <Grid container style={{ padding: "5px 15px", alignItems: "center" }}>
                                        <Grid item xs={8}>
                                          <p style={{
                                            padding: 0,
                                            margin: 0,
                                            fontSize: 14,
                                            marginTop: 5
                                          }}>
                                            {selectedAccessoryCheck.name}
                                          </p>
                                        </Grid>
                                        <Grid item xs={4} style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                        <Typography>Mandatory</Typography>
                                        <Switch
                                        checked={
                                          selectedAccessoryCheck.isMandatory
                                        }
                                        onChange={() => {
                                          const updatedChecks = [
                                            ...selectedAccessoriesChecks
                                          ];
                                          updatedChecks[idx] = {
                                            ...updatedChecks[idx],
                                            isMandatory: !updatedChecks[idx]
                                              .isMandatory
                                          };
                                          setSelectedAccessoriesChecks(
                                            updatedChecks
                                          );
                                        }}
                                        color="primary"
                                        />
                                          <span
                                            onClick={() => {
                                              const existsInSuggestedAccessores = defalutSuggestedAccessoriesChecks?.some((check) => check.name === selectedAccessoryCheck.name);
                                              setSelectedAccessoriesChecks(selectedAccessoriesChecks.filter((check, index) => index !== idx));
                                              if (existsInSuggestedAccessores) {
                                                setSuggestedAccessoresChecks([selectedAccessoryCheck, ...suggestedAccessoriesChecks]);
                                              }
                                            }}
                                            style={{ color: "white", cursor: "pointer" }}
                                          >
                                            <RemoveIcon
                                              style={{
                                                marginTop: 5,
                                                marginLeft: 10,
                                                backgroundColor: "var(--theme-accent)",
                                                cursor: "pointer",
                                                fontSize: 16,
                                                borderRadius: 2
                                              }}
                                            />
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                );
                              })}
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} justifyContent="flex-start">
                        <Fab
                          variant="extended"
                          size="medium"
                          type="submit"
                          aria-label="add"
                          disabled={createVehicleCheckListLoading}
                        >
                          {createVehicleCheckListLoading && (
                            <CircularProgress
                              size={14}
                              style={{ color: "white", marginRight: "10px" }}
                            />
                          )}
                          SAVE
                        </Fab>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
          <AddCustomCheckDialog
            open={openDialog}
            handleClose={() => { setOpenDialog(false) }}
            handleSubmit={handleAddCustomCheck}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}
