import { gql } from "@apollo/client";

export const CREATE_INSURANCE_POLICY = gql`
  mutation createInsurancePolicy(
    $insurancePolicy: InsurancePolicyCreateInput!
  ) {
    createInsurancePolicy(insurancePolicy: $insurancePolicy) {
      name
      description
      excess
      isActivated
      documents{
        title
        documentType
        documentUrl
        documentName
        expiryDate
        createdAt
		  }
      insuranceRate {
        rateName
        rateDuration
        rate
        longTerm
      }
      organisationId
    }
  }
`;
