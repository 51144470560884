import { PriceRuleOperator, ValueType } from "../bookings/types";
import { IPriceRuleState } from "./types";

export const priceRuleInitialState: IPriceRuleState = {
  priceRule: {
    name: "",
    vehicleGroups: [],
    customers: [],
    serviceLocations: [],
    operator: PriceRuleOperator.DISCOUNT,
    rules: [
      {
        rateType: "",
        value: 0,
        valueType: ValueType.FIXED,
        dateRangeOptIn: false,
        durationOptIn: false,
        timeRangeOptIn: false,
        pickupDropoffDaysOptIn: false,
        mileageLimitOptIn: false,
        unlimitedMileage: false
      }
    ],
    isActive: true
  }
};

