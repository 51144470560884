import "./index.scss";


import * as _ from "lodash";
import {
  Display,
  FilterType,
  MUIDataTableOptions,
  MUIDataTableState
} from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addTableState } from "../../../actions/tableState/actions";
import { CREATE_CONSOLIDATED_INVOICE } from "../../../graphql/invoices/generateConsolidatedInvoice";
import {
  GET_CONSOLIDATED_INVOICES,
  GET_CONSOLIDIATED_INVOICE_COUNT
} from "../../../graphql/invoices/getConsolidatedInvoices";
import { SEARCH_CONSOLIDATED_INVOICE } from "../../../graphql/invoices/searchConsolidatedInvoicesQuery";
import {
  IConsolidatedInvoiceInput,
  IInvoice
} from "../../../reducers/invoices/types";
import { ITable, ITableState, TableNames } from "../../../reducers/tableState/types";
import { IAppState } from "../../../store";
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";
import { NuvvenTable } from "../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../common/NuvvenTable/types";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  returnSortedOrder,
  setTableSortOrder,
  toCurrency
} from "../../common/utils";
import { ConsolidatedBillingDialog } from "./ConsolidatedBillingDialog";
import { InvoiceStatus } from "./UpdateInvoice/InvoiceComponents/types";
import { reshapeInvoicesIntoRows } from "./utils";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { CircularProgress, CssBaseline, Fab, Grid, Typography } from "@mui/material";
import { viewColumns } from "../Fleet/VehicleInventory/ViewVehicle/Damage/constants";

interface IFilter {
  key: string;
  value: string;
}

export function ConsolidatedInvoices() {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const invoicesTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.consolidatedInvoices
  );
  const [rows, setRows] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [invoicesTable, setInvoicesTable] = useState<ITable>();
  const [totalInvoiceCount, setTotalInvoiceCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [filterList, setFilterList] = useState<IFilter[]>([]);
  const [inProgess, setInProgess] = useState<boolean>(false);
  const [limit, setLimit] = useState(10);
  const [filtering, setIsFiltering] = useState<boolean>(false);

  const [
    loadConsolidatedInvoices,
    { loading: invoicesLoading, data: invoicesData }
  ] = useLazyQuery(GET_CONSOLIDATED_INVOICES, {
    fetchPolicy: "network-only",
    onCompleted: (invoicesData) => {
      if (invoicesData && invoicesData.consolidatedInvoices) {
        const newInvoices = shapeInvoices(invoicesData.consolidatedInvoices);
        setRows(_.union(rows, newInvoices));
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    searchConsolidatedInvoice,
    { loading: searchInvoicesLoading, data: searchInvoiceData }
  ] = useLazyQuery(SEARCH_CONSOLIDATED_INVOICE, {
    fetchPolicy: "network-only",
    onCompleted: (searchInvoiceData) => {
      if (searchInvoiceData && searchInvoiceData.searchConsolidatedInvoice) {
        let invoices = shapeInvoices(searchInvoiceData.searchConsolidatedInvoice);
        setTotalInvoiceCount(invoices.length);
        setRows(invoices);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    getConsolidatedInvoiceCount,
    { data: invoiceCountData }
  ] = useLazyQuery(GET_CONSOLIDIATED_INVOICE_COUNT, {
    fetchPolicy: "network-only",
    onCompleted: (invoiceCountData) => setTotalInvoiceCount(invoiceCountData.consolidatedInvoiceCount),
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createConsolidatedInvoice] = useMutation(CREATE_CONSOLIDATED_INVOICE, {
    onCompleted: (data) => {
      if (data && data.createConsolidatedInvoice) {
        setOpen(false);
        setInProgess(false);
        navigate(
          `/update-billing?invoice=${data.createConsolidatedInvoice.id}`
        );
      }
    },
    onError: () => {
      setInProgess(false);
      setOpen(false);
    }
  });

  useEffect(() => {
    if (userState.tenancy) {
      getConsolidatedInvoiceCount();
      loadConsolidatedInvoices({
        variables: {
          limit: invoicesTableState?.rowsPerPage
            ? invoicesTableState.rowsPerPage
            : limit,
          offset: 0
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    if (invoicesTableState) {
      setInvoicesTable(invoicesTableState);
      let sortedOrder = returnSortedOrder(invoicesTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, invoicesTableState, sortedOrder);
      }
    }
  }, [invoicesTableState]);

  const shapeInvoices = (data: any) => {
    let invoicesArr = data.map((invoice: IInvoice) => {
      const businessCustomer = invoice.businessCustomer;
      const customer = businessCustomer?.businessName || "";
      let invoiceRow = reshapeInvoicesIntoRows(
        invoice,
        locale,
        currency,
        "",
        customer,
        country
      );
      return invoiceRow;
    });
    return invoicesArr;
  };

  const columns = [
    {
      label: "Invoice ID",
      name: "invoiceRef",
      options: {
        filter: false
      }
    },
    {
      label: "Invoice Date",
      name: "dateCreated",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string) => {
          if (value) {
            return getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED);
          } else {
            return "-";
          }
        }
      }
    },
    {
      label: "Business Customer",
      name: "customer",
      options: {
        filter: false
      }
    },
    {
      label: "Total",
      name: "total",
      options: {
        filter: false,
        customBodyRender: (value: number) => {
          if (value) {
            return toCurrency(value, currency, locale);
          }
          return "-";
        }
      }
    },
    {
      label: "Balance Due",
      name: "dueAmount",
      options: {
        filter: false,
        customBodyRender: (value: number) => {
          if (value) {
            return toCurrency(value, currency, locale);
          }
          return "-";
        }
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value: any) => {
          if (
            [InvoiceStatus.PARTIALLY_PAID, InvoiceStatus.UNPAID].includes(value)
          ) {
            return "UNPAID";
          } else {
            return value;
          }
        },
        filterOptions: {
          names: [InvoiceStatus.PAID, InvoiceStatus.UNPAID, InvoiceStatus.VOID]
        }
      }
    },
    {
      label: "Email",
      name: "lastSent",
      options: {
        customBodyRender: (value: any) => {
          if (value) {
            return "SENT";
          } else {
            return "-";
          }
        },
        filterOptions: {
          names: ["-", "SENT"]
        }
      }
    }
  ];

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: totalInvoiceCount,
    rowsPerPageOptions: [10, 20, 100],
    onTableChange: (action: string, tableState: any) => {
      tableState.filterData[8] = ["PAID", "UNPAID", "VOID"];
      tableState.filterData[9] = ["SENT", "-"];
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return;
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length || filtering) {
            if (filtering) {
              loadConsolidatedInvoices({
                variables: {
                  offset: page * rowsPerPage,
                  limit: rowsPerPage,
                  filters: filterList
                }
              });
            } else {
              loadConsolidatedInvoices({
                variables: {
                  limit: rowsPerPage,
                  offset: page * rowsPerPage
                }
              });
            }
          }
          break;
        case "changeRowsPerPage":
          setRows([]);
          loadConsolidatedInvoices({
            variables: {
              offset: 0,
              limit: tableState.rowsPerPage || limit
            }
          });
          break;
        default:
          break;
      }
    },
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch:
          invoicesLoading || searchInvoicesLoading
            ? "Loading..."
            : "Sorry, there is no matching data to display"
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of"
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table"
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET"
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns"
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows"
      }
    },
    onRowClick: (rowData: string[]) => {
      if (rowData && rowData.length) {
        // FIXME: Idially the rows should change based on sorting and then find data based on rowindex
        const invoiceClicked = rows.find(
          (row: any) => row.invoiceRef === rowData[0]
        );
        if (invoiceClicked) {
          navigate(`/update-billing?invoice=${invoiceClicked.id}`);
        }
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows);
      dispatch(
        addTableState({
          tableName: TableNames.CONSOLIDATED_INVOICES,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      invoicesTable && invoicesTable.rowsPerPage
        ? invoicesTable.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.CONSOLIDATED_INVOICES,
          columnName: changedColumn,
          direction
        })
      );
    },
    onFilterChange(changedColumn, filterList, type, changedColumnIndex, displayData) {
      dispatch(
        addTableState({
          tableName: TableNames.CONSOLIDATED_INVOICES,
          filters: filterList
        })
      );
      let filterColumns: IFilter[] = [];
      filterList.forEach((value, index) => {
        if (value.length) {
          filterColumns.push({
            key: columns[index].name,
            value: value[0]
          });
        }
      });
      if (filterList) {
        setFilterList(filterColumns);
        setIsFiltering(true);
        loadConsolidatedInvoices({
          variables: {
            limit: invoicesTableState?.rowsPerPage || limit,
            offset: 0,
            filters: filterColumns
          }
        });
      } else {
        setIsFiltering(false);
      }
    },
    onTableInit: (_: string, tableState: MUIDataTableState) => {
      if (invoicesTableState && invoicesTableState.filters) {
        let filterColumns: IFilter[] = [];
        invoicesTableState.filters.forEach((value, index) => {
          if (value.length) {
            filterColumns.push({
              key: columns[index].name,
              value: value[0]
            });
          }
        });
        loadConsolidatedInvoices({
          variables: {
            limit: invoicesTableState?.rowsPerPage || limit,
            offset: 0,
            filters: filterColumns
          }
        });
        tableState.filterList = invoicesTableState.filters;
      }
      if (invoicesTableState && invoicesTableState.viewColumns) {
        for (const [key, value] of Object.entries(invoicesTableState.viewColumns)) {
          let columnIndex = tableState.columns.findIndex(column => column.name === key);
          console.log(columnIndex,"clmindex")
          if (columnIndex > -1 ) {
            tableState.columns[columnIndex].display = value as Display;
          }
        }
      }
      if (invoicesTable && sortOrder) {
        setTableSortOrder(columns, invoicesTable, sortOrder);
      }
      if (invoicesTable && invoicesTable.rowsPerPage) {
        setLimit(invoicesTable.rowsPerPage);
      }
    },
    onColumnViewChange: (changedColumn, action) => {
      const updatedViewColumns = { ...invoicesTableState?.viewColumns, [changedColumn]: String(action === 'add') };
      dispatch(
        addTableState({
          tableName: TableNames.CONSOLIDATED_INVOICES,
          viewColumns: updatedViewColumns
        })
      );
    },
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText);
      } else {
        setRows([])
        getConsolidatedInvoiceCount()
        loadConsolidatedInvoices({
          variables: {
            limit,
            offset: 0
          }
        });
      }
    }
  };

  const handleSearch = _.debounce((searchText: string) => {    
    if (searchText) {
      searchConsolidatedInvoice({
        variables: {
          q: searchText,
          limit,
          offset:0
        }
      });
    }
  }, 1000);

  const generateConsolidatedBill = (args: IConsolidatedInvoiceInput) => {
    const { businessCustomerId, invoiceIds, startDate, endDate } = args;
    setInProgess(true);
    createConsolidatedInvoice({
      variables: {
        businessCustomer: businessCustomerId,
        invoiceIds,
        startDate,
        endDate
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Consolidated Invoices
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          onClick={() => {
            setOpen(true);
          }}
          disabled={userState.role === "FLEET_MANAGER"}
          variant="extended"
          size="medium"
          type="button"
        >
          {"GENERATE CONSOLIDATED INVOICE"}
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        {!(invoicesLoading && !rows.length) ? (
          <NuvvenTable
            title={""}
            rows={rows}
            columns={columns}
            options={options}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
      {open && (
        <ConsolidatedBillingDialog
          open={open}
          inProgress={inProgess}
          handleClose={() => {
            setOpen(false);
          }}
          handleSubmit={(args: IConsolidatedInvoiceInput) =>
            generateConsolidatedBill(args)
          }
        />
      )}
    </Grid>
  );
}

export default ConsolidatedInvoices;