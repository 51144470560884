import { Grid, Typography } from '@mui/material'
import React from 'react'

export const SameDayCreateEvent: React.FC = () => {
  return (
    <Grid container item xs={12} style={{ marginBottom: 20 }}>
      <Typography variant={"h3"}>New event creation for the same day is Allowed in Day view only.</Typography>
    </Grid>
  )
}
