import { IVehicleDamageState, VehicleDamageStatus } from "./types";
export const VEHICLE_DAMAGE_INITIALSTATE: IVehicleDamageState = {
  vehicleDamage: {
    title: "",
    descriptions: "",
    damageType: "",
    circumstance: "",
    bookingRef: "",
    images: [],
    thirdParty: false,
    reportDate: "",
    incidentDate: "",
    reportedBy: "",
    vehicleId: "",
    estimatedCost: 0,
    status: VehicleDamageStatus.DUE,
    damageSeverity: "",
    estimatedRepairDate: "",
    damageDocuments: [],
    interiorImages: [],
    exteriorImages: [
      {
        carFrontImg: [],
        carFrontMirrorImg: [],
        carTopImg: [],
        carBackMirrorImg: [],
        carBackImg: [],
        carLeftImg: [],
        carRightImg: []
      }
    ],
    vehicleState: {
      id: "",
      type: "",
      exterior: {
        front: {
          description: "",
          images: []
        },
        rear: {
          description: "",
          images: []
        },
        top: {
          description: "",
          images: []
        },
        right: {
          description: "",
          images: []
        },
        left: {
          description: "",
          images: []
        }
      },
      interior: {
        frontSeat: {
          clean: true,
          controls: true,
          seatBelt: true
        },
        rearSeat: {
          clean: true,
          controls: true,
          seatBelt: true
        },
        lockAndSwitch: {
          frontDoor: true,
          rearDoor: true,
          frontWindow: true,
          rearWindow: true,
          childLock: true
        },
        images: []
      },
      defaultAccesories: [],
      generalChecks: [],
      tyre: {
        frontLeft: {
          condition: true,
          pressure: true,
          description: ""
        },
        frontRight: {
          condition: true,
          pressure: true,
          description: ""
        },
        rearLeft: {
          condition: true,
          pressure: true,
          description: ""
        },
        rearRight: {
          condition: true,
          pressure: true,
          description: ""
        }
      },
      odometerReading: 0,
      fuelStatus: ""
    }
  }
};
