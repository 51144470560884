import { gql } from "@apollo/client";

export const UPDATE_SERVICELOCATION = gql`
  mutation updateServiceLocation(
    $serviceLocationId: ID!
    $organisationId: ID!
    $serviceLocation: ServiceLocationUpdateInput!
  ) {
    updateServiceLocation(
      serviceLocationId: $serviceLocationId
      organisationId: $organisationId
      serviceLocation: $serviceLocation
    ) {
      id
      name
      isDisabled
      branch {
        id
        name
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
    }
  }
`;
