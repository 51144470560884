import { useLazyQuery, useMutation } from '@apollo/client';
import { Grid, CssBaseline, Typography, Fab, CircularProgress, IconButton } from '@mui/material';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ALL_SERVICE_TASK_ITEMS } from '../../../../../graphql/fleet/vehicleServices/getAllServiceTaskListQuery';
import { IAppState } from '../../../../../store';
import { NuvvenTable } from '../../../../common/NuvvenTable/NuvvenTable';
import { SelectableRows } from '../../../../common/NuvvenTable/types';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import EditIcon from '@mui/icons-material/Edit';
import { AddServiceTaskItemDialog } from './AddServiceTaskItemDialog';
import { formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from '../../../../common/utils';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { CREATE_CUSTOM_SERVICE_TASK, DELETE_VEHICLE_SERVICE_TASK, UPDATE_VEHICLE_SERVICE_TASK } from '../../../../../graphql/fleet/vehicleServices/vehicleServiceTaskMutation';
import { ConfirmationDialog } from '../../../../common/ConfirmationDialog/ConfirmationDialog';
import { ApolloError } from '@apollo/client';
import { ITable, TableNames } from '../../../../../reducers/tableState/types';
import { addTableState } from '../../../../../actions/tableState/actions';

export interface IServiceTaskList {
  id: string;
  name: string;
  taskType: string;
  description: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

interface IRow {
  id: string;
  name: string;
  description: string;
  taskType: string;
  actions: {
    id: string;
    index?: number
  }
}

export enum TaskType {
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM"
}

export interface IServiceTask {
  id?: string;
  name: string;
  description: string;
}

export interface IServiceTaskInput {
  id?: string;
  name: string;
  description: string;
  taskType: string;
}

export const ServiceTaskItems: FC = () => {
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [serviceTaskList, setServiceTaskList] = useState<IRow[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [vehicleServiceTaskId, setVehicleServiceTaskId] = useState("");
  const [selectedTask, setSelectedTask] = useState<IRow>();
  const [inProgress, setInProgess] = useState(false);
  const [dialog, setDialog] = useState<{ open: boolean, data: IServiceTaskInput }>({
    open: false,
    data: {
      name: "",
      description: "",
      taskType: ""
    }
  });
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();
  const [serviceTaskItemsTable, setServiceTaskItemsTable] = useState<ITable>();
  const serviceTaskItemsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.serviceTaskItems
  );
  const [sortOrder, setSortOrder] = useState<string | undefined>();


  const [getAllServiceTaskItems, { loading: allServiceTaskLoading, data }] = useLazyQuery(
    GET_ALL_SERVICE_TASK_ITEMS,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [createCustomServiceTaskMutation] = useMutation(CREATE_CUSTOM_SERVICE_TASK, {
    onError: (error: any) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: () => {
      getAllServiceTaskItems();
      snackbar({
        message: formatGraphQLErrorMessage("Service task added"),
        variant: SnackBarVariant.SUCCESS
      });
      setDialog(prev => ({ ...prev, open: false }))
    }
  });

  const [updateServiceTaskMutation] = useMutation(UPDATE_VEHICLE_SERVICE_TASK, {
    onError: (error: any) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: ({ updateVehicleServiceTask }) => {
      const _serviceTaskList = [...serviceTaskList];
      const index = _serviceTaskList.findIndex((task) => task.id === updateVehicleServiceTask?.id);
      if (index >= 0) {
        _serviceTaskList[index] = updateVehicleServiceTask
      }
      setServiceTaskList(_serviceTaskList);
      getAllServiceTaskItems();
      snackbar({
        message: formatGraphQLErrorMessage("Service task updated"),
        variant: SnackBarVariant.SUCCESS
      });
      setDialog(prev => ({ ...prev, open: false }))
    }
  });

  const [deleteServiceTaskMutation] = useMutation(DELETE_VEHICLE_SERVICE_TASK, {
    onError: (error: any) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: ({ deleteVehicleServiceTask }) => {
      if (deleteVehicleServiceTask) {
        getAllServiceTaskItems();
        setOpenDialog(false);
        setInProgess(false)
        snackbar({
          message: "Task Item deleted",
          variant: SnackBarVariant.SUCCESS
        });
      }
    }
  });

  useEffect(() => {
    if (userState.tenancy) {
      getAllServiceTaskItems();
    }
  }, [userState]);

  useEffect(() => {
    if (data && data.getAllVehicleServiceTask) {
      const allServiceTasks = [...data.getAllVehicleServiceTask.customTasks, ...data.getAllVehicleServiceTask.defaultTasks]
      const reshapedServiceTask = allServiceTasks.map((item: IServiceTaskList, index: number) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          taskType: item.taskType,
          actions: {
            index,
            id: item.id
          }
        }
      })
      setServiceTaskList(reshapedServiceTask);
      setVehicleServiceTaskId(data.getAllVehicleServiceTask.id)
    }
  }, [data]);

  useEffect(() => {
    if (serviceTaskItemsTableState) {
      setServiceTaskItemsTable(serviceTaskItemsTableState);
      let sortedOrder = returnSortedOrder(serviceTaskItemsTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, serviceTaskItemsTableState, sortedOrder)
      }
    }
  }, [serviceTaskItemsTableState]);

  const handleServiceTaskSubmit = (serviceTaskInput: IServiceTaskInput) => {
    if (serviceTaskInput.id) {
      const { id, taskType, ...rest } = serviceTaskInput;
      updateServiceTaskMutation({
        variables: {
          vehicleServiceTaskId,
          serviceTaskId: serviceTaskInput.id,
          taskType: selectedTask?.taskType,
          vehicleServiceTask: rest
        }
      })
    } else {
      const { id, taskType, ...rest } = serviceTaskInput;
      createCustomServiceTaskMutation({
        variables: {
          vehicleServiceTaskId,
          vehicleServiceTask: rest
        }
      })
    }
  }

  const columns = [
    {
      label: "Name",
      name: "name"
    },
    {
      label: "Description",
      name: "description"
    },
    {
      label: "Task Type",
      name: "taskType"
    },
    {
      label: "Action",
      name: "actions",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <React.Fragment>
              <div style={{ display: "flex" }}>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => {
                    const selectedTaskIndex = serviceTaskList.findIndex((task) => task && task.id === value.id);
                    const selectedServiceTask = serviceTaskList[selectedTaskIndex];
                    setSelectedTask(selectedServiceTask);
                    setDialog({
                      open: true,
                      data: {
                        id: selectedServiceTask ? selectedServiceTask.id : "",
                        name: selectedServiceTask ? selectedServiceTask.name : "",
                        description: selectedServiceTask ? selectedServiceTask.description : "",
                        taskType: selectedServiceTask ? selectedServiceTask.taskType : ""
                      }
                    })
                  }}
                  style={{ color: "black" }}
                  size="large">
                  <EditIcon />
                </IconButton>
              </div>
            </React.Fragment>
          );
        }
      }
    }
  ];

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    filter: false,
    print: false,
    search: false,
    download: false,
    viewColumns: false,
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)      
      dispatch(
        addTableState({
          tableName: TableNames.SERVICE_TASK_ITEMS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
    serviceTaskItemsTable && serviceTaskItemsTable.rowsPerPage
        ? serviceTaskItemsTable.rowsPerPage
        : limit,
  };

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Service Task Items
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          className="createButton"
          onClick={() => {
            setDialog({
              open: true,
              data: {
                name: "",
                description: "",
                taskType: ""
              }
            })
          }}
        >
          Create
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        {allServiceTaskLoading ? (
          <CircularProgress />
        ) : (
          <NuvvenTable
            title={""}
            rows={serviceTaskList}
            columns={columns}
            setSelection={(item: any) => item}
            options={options}
          />
        )}
      </Grid>
      <AddServiceTaskItemDialog open={dialog.open} data={dialog.data} handleClose={() => setDialog(prev => ({ ...prev, open: false }))} handleSubmit={handleServiceTaskSubmit} />
      {openDialog && <ConfirmationDialog
        isOpen={openDialog}
        description="Are you sure you want to delete this task item?"
        onCancel={() => setOpenDialog(false)}
        onConfirm={() => {
          if (selectedTask) {
            setInProgess(true)
            deleteServiceTaskMutation({
              variables: {
                serviceTaskId: selectedTask.id
              }
            })
          }
        }}
        title=""
        confirmText='Yes'
        cancelText='No'
        isInProgress={inProgress}
      />}
    </Grid>
  )
}

export default ServiceTaskItems