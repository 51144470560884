import {
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IAppState } from "../../../../../../store";
import { toCurrency } from "../../../../../common/utils";


export const MobileVehiclePriceGroupCard = ({ id, name, vehicleCount, status, hourlyRate, dailyRate, weeklyRate, monthlyRate }: {
  id: string;
  name: string;
  vehicleCount: number;
  status: string;
  hourlyRate: number;
  dailyRate: number;
  weeklyRate: number;
  monthlyRate: number;
}) => {

  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;

  const colour = (status === "Activated") ? ('var(--ion-color-success)') : (
    (status === "Deactivated") ? ('var(--ion-color-danger)') : (
      "rgba(0, 0, 0, 1)"
    )
  )
  const useStyle = makeStyles((theme: Theme) =>
    createStyles({
      cardStyle: {
        padding: "5px",
        height: "178px",
        minWidth: "calc(100vw - 60px)",
        maxWidth: "calc(100vw - 60px)",
        borderRadius: "12px",
        border: "1px solid",
        borderColor: `${colour}`
      },
    }));

  const classes = useStyle();

  function routeTo() {
    if (id) {
      navigate(`/update-vehicle-price-group?id=${id}`)
    }
  }

  return (
    <ButtonBase onClick={() => routeTo()}>
      <Card className={classes.cardStyle} >
        <CardContent>
          <Grid container item xs={12}>

            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                  {name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "10px" }}>
                  Vehicles:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                  {vehicleCount}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={6}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "10px" }}>
                  Hourly Rate:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                  {toCurrency(hourlyRate, currency, locale)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={6}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "10px" }}>
                  Daily Rate:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                  {toCurrency(dailyRate, currency, locale)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={6}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "10px" }}>
                  Weekly Rate:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                  {toCurrency(weeklyRate, currency, locale)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={6}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: "10px" }}>
                  Monthly Rate:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: "bold", fontSize: "12px", paddingBottom: "2px" }}>
                  {toCurrency(monthlyRate, currency, locale)}
                </Typography>
              </Grid>
            </Grid>

          </Grid>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

export default MobileVehiclePriceGroupCard;