import { gql } from "@apollo/client";

export const UPDATE_VEHICLE_SERVICE = gql`
  mutation updateVehicleService($vehicleService: UpdateVehicleServiceInput!) {
    updateVehicleService(vehicleService: $vehicleService) {
      serviceType
      scheduleType{
        byMonth,
        byMileage
      }
      serviceProviderId
      serviceProviderName
      serviceProviderAddress{
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      serviceAnniversaryByMileage
      description
      totalExpenses
      documents {
        title
        documentName
        documentType
        documentUrl
        expiryDate
        createdAt
      }
      status
      notes{
        description,
        createdBy {
          id
          firstName
          lastName
        }
        createdDate
      }
      damages{
        id
        referenceNumber
        circumstance
        bookingRef
        reportDate
        status
      }
      vehicleId
      appointmentDate
      startDate
      endDate
      completionDate
      driverName
      driverEmail
      jobSheetStatus
      jobSheetEmailLastSentOn
      jobSheet{
        id
        name
        description
        cost
      }
      insuranceInformation{
        providerName
        policyNumber
        expiryDate
        claimAmount
        documentUrl
      }
      purchaseOrderInformation{
        poNumber
        documentUrl
      }
    }
  }
`;
