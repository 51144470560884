import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, CircularProgress, Fab, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from "@mui/material";
import { ApolloError } from "@apollo/client";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ISSUE_REFUND } from "../../../graphql/bookings/issueRefundMutation";
import { GET_CREDIT_NOTE } from "../../../graphql/invoices/getCreditNoteQuery";
import { ICreditNote } from "../../../reducers/bookings/types";
import { IAppState } from "../../../store";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../utils/localized.syntex";
import { ConfirmationDialog } from "../../common/ConfirmationDialog/ConfirmationDialog";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage, toCurrency } from "../../common/utils";
import { defaultConfirmationDialogData, IConfirmationDialogData, ISSUE_REFUND_DESCRIPTION, ISSUE_REFUND_TITLE } from "../ReservationManagement/Summary/const";
import { SummaryField } from "./UpdateInvoice/InvoiceComponents/SummaryField";
import DownloadPdf from "../../common/Pdf/DownloadPdf";
import MailSendBox from "../../common/MailSendBox";
import ShowMessage from "../../common/MailSendBox/ShowMessage";
import { SEND_CREDIT_NOTE_MAIL } from "../../../graphql/bookings/sendCreditNoteMailMutation";
import { SEND_CREDIT_NOTE_TO_XERO } from "../../../graphql/invoices/resendInvoiceToSageMutation";
import { XeroStatus } from "../../../reducers/invoices/types";
import { socket } from "../../../utils/socket";
import { SOCKET_EVENTS } from "../ReservationManagement/utils";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: "100%"
  },
  tableContainer: {
    border: "solid 1px rgba(224, 224, 224, 1)",
    marginTop: "1rem"
  }
}))

const CreditNote: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [creditNote, setCreditNote] = useState<ICreditNote>();
  const [confirmationDialogData, setConfirmationDialogData] = useState<IConfirmationDialogData>(defaultConfirmationDialogData);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { taxEnabledCreditNotes } = userState.currentTenancy;
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [sendClick, setSendClick] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMails, setShowMails] = useState<string[]>([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [sendingToXero, setSendingToXero] = useState(false);
  const [loadingRefunds, showRefundsLoad] = useState<boolean>(false);

  const isBusinessCustomerBooking = creditNote?.businessCustomer &&
    creditNote.businessCustomer.id !== ""
    ? true
    : false;
  const businessCustomer = creditNote?.businessCustomer;
  const customer = creditNote?.customer;

  const [getCreditNote, { data: creditNoteData }] = useLazyQuery(
    GET_CREDIT_NOTE,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (!data.creditNote) {
          navigate("/credit-notes");
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const creditNoteId = params.get("creditNoteId");
      if (creditNoteId) {
        getCreditNote({
          variables: {
            id: creditNoteId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (creditNoteData?.creditNote) {
      setCreditNote(creditNoteData.creditNote);
      socket.emit(SOCKET_EVENTS.REQUEST_VIEW_CREDIT_NOTE, { creditNoteId: creditNoteData.creditNote.id });
      showRefundsLoad(false);
    }
  }, [creditNoteData])

  const [issueRefundMutation] = useMutation(ISSUE_REFUND, {
    onCompleted: (data) => {
      if (data && data.issueRefund) {
        snackbar({
          message: "Refund Issued Successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setConfirmationDialogData({
          ...confirmationDialogData,
          isOpen: false,
          isLoading: false
        })
        getCreditNote({
          variables: {
            id: creditNote?.id
          }
        });
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  })

  const [sendCreditNote] = useMutation(SEND_CREDIT_NOTE_MAIL, {
    onCompleted: () => {
      setLoading(false);
      setShowMessage(true);
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [sendCreditNoteToXero] = useMutation(SEND_CREDIT_NOTE_TO_XERO, {
    onCompleted: ({ createXeroCreditNote }) => {
      snackbar({
        message: createXeroCreditNote.message,
        variant: SnackBarVariant.SUCCESS
      });
      setSendingToXero(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setSendingToXero(false);
    }
  });

  useEffect(() => {
    socket.auth = {
      userId: userState.id,
    }; 
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.GET_UPDATED_CREDIT_NOTE, (data) => {
      showRefundsLoad(true);
      getCreditNote({
        variables: {
          id: data.creditNoteId
        }
      })
    });

    return () => {
      socket.off(SOCKET_EVENTS.GET_UPDATED_CREDIT_NOTE);
    }
  }, [])

  const getSubTotal = () => {
    let amount = 0;
    creditNote?.creditNoteItems.forEach((item) => {
      amount += item.subTotal || 0
    })
    return amount
  }

  const getTotalTax = () => {
    let amount = 0;
    creditNote?.creditNoteItems.forEach((item) => {
      amount += item.taxAmount || 0
    })
    return amount
  }

  const getGrandTotal = () => {
    let amount = 0;
    creditNote?.creditNoteItems.forEach((item) => {
      amount += item.totalAmount || 0
    })
    return amount
  }

  const sendCreditNoteEmail = (sentMails: string[]) => {
    const customerMail = isBusinessCustomerBooking
      ? businessCustomer
        ? businessCustomer.billing.email
        : ""
      : customer
        ? customer.email
        : "";
    setShowMails([customerMail, ...sentMails]);
    setLoading(true);
    setSendClick(false);
    sendCreditNote({
      variables: {
        id: creditNote?.id,
        additionalMails: sentMails
      }
    });
  };

  if (!creditNote) {
    return (<CircularProgress />)
  }

  return (
    <Grid item container spacing={2} xs={12}>
      <Grid container item xs={12}>
        <Grid container item xs={4}>
          <Typography variant="h1" color="primary">
            Credit Note
          </Typography>
        </Grid>
        <Grid container xs={8} justifyContent={"flex-end"}>
          {userState.currentOrganisation.xeroEnabled && !creditNote.sentToXero && (
            <Fab
              variant={"extended"}
              size="medium"
              aria-label="Update"
              style={{ marginRight: 10 }}
              onClick={() => {
                if (creditNote.xeroCreditNoteStatus === XeroStatus.IN_PROGRESS) {
                  snackbar({
                    message: "Credit note has been sent to xero.Please reload to get the updated status",
                    variant: SnackBarVariant.ERROR
                  });
                } else {
                  setSendingToXero(true);
                  sendCreditNoteToXero({
                    variables: {
                      creditNoteId: creditNote.id
                    }
                  })
                }
              }}
            >
              Send Credit Note to Xero {sendingToXero && <CircularProgress size={14} style={{ color: "black", marginLeft: "10px" }} />}
            </Fab>
          )}
          <Fab
            variant={"extended"}
            size="medium"
            aria-label="Update"
            style={{ marginRight: 10 }}
            disabled={!creditNote.availableAmount}
            onClick={() => {
              setConfirmationDialogData({
                description: ISSUE_REFUND_DESCRIPTION,
                isOpen: true,
                confirmText: "YES",
                cancelText: "NO",
                onCancel: () =>
                  setConfirmationDialogData(prevState => {
                    return {...prevState, isOpen: false, isLoading: false};
                  }),
                onConfirm: () => {
                  setConfirmationDialogData(prevState => {
                    return {...prevState, isLoading: true};
                  })
                  issueRefundMutation({
                    variables: {
                      creditNoteId: creditNote.id,
                      amount: creditNote.availableAmount
                    }
                  })

                },
                title: ISSUE_REFUND_TITLE
              })
            }}
          >
            {`Issue Refund`}
          </Fab>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Grid container spacing={2}>
            <Grid container item xs={6}>
              <Typography variant={"h2"}>
                {"Credit Note Summary"}
              </Typography>
            </Grid>
            <Grid container item xs={6}>
            </Grid>
            <Grid container item xs={12}>
              <Grid container xs={4} spacing={1}>
                <Grid item xs={12}>
                  <SummaryField
                    label={`Credit Note Reference`}
                    value={creditNote.creditNoteRef}
                  />
                </Grid>
                {isBusinessCustomerBooking && businessCustomer &&
                  businessCustomer.businessName && (
                    <Grid item xs={12}>
                      <SummaryField
                        label="Business Name"
                        value={businessCustomer?.businessName}
                      />
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <SummaryField
                    label="Customer Name"
                    value={
                      isBusinessCustomerBooking
                        ? businessCustomer
                          ? `${businessCustomer.contact.firstName} ${businessCustomer.contact.lastName}`
                          : ""
                        : customer
                          ? customer.firstName + " " + customer.lastName
                          : ""
                    }
                    onClick={() => {
                      if (isBusinessCustomerBooking && businessCustomer) {
                        navigate(`/update-business-customer?businesscustomer=${businessCustomer.id}`)
                      } else if (customer) {
                        navigate(`/customer-details?customer=${customer.id}&editable=true`)
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SummaryField
                    label={`Customer Email`}
                    value={
                      isBusinessCustomerBooking
                        ? businessCustomer
                          ? businessCustomer.billing.email
                          : ""
                        : customer
                          ? customer.email
                          : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid container xs={4}>
                {creditNote.booking &&
                  <Grid item xs={12}>
                    <SummaryField
                      label={`${getLocalizedBookingSyntex(country)} Reference`}
                      value={creditNote.booking.referenceNumber}
                      onClick={() => {
                        if (userState.role !== "FINANCE_MANAGER" && creditNote.booking) {
                          const bookingId = creditNote.booking?.id;
                          navigate(`/view-booking?booking=${bookingId}`)
                        }
                      }}
                    />
                  </Grid>
                }
                <Grid item xs={12}>
                  <SummaryField
                    label={`Invoice Reference`}
                    value={creditNote.invoice.invoiceRef}
                    onClick={() => {
                      if (userState.role !== "FINANCE_MANAGER") {
                        const invoiceId = creditNote.invoice.id;
                        navigate(`/update-billing?invoice=${invoiceId}`)
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SummaryField
                    label={`Created On`}
                    value={getLocalizedDateFormat(country, creditNote.createdAt, DATE_TYPE.CONDENSED)}
                  />
                </Grid>
              </Grid>
              <Grid container xs={4}>
                <Grid item xs={12}>
                  <SummaryField
                    label="Total Amount"
                    value={toCurrency(creditNote?.totalAmount || 0, currency, locale)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SummaryField
                    label="Amount Adjusted/Refunded"
                    value={toCurrency(creditNote?.refundedAmount || 0, currency, locale)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SummaryField
                    label="Amount Available"
                    value={toCurrency(creditNote?.availableAmount || 0, currency, locale)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <TableContainer className={classes.tableContainer}>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant={"h4"}>S.No.</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={"h4"}>Description</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={"h4"}>Quantity</Typography>
                      </TableCell>
                      {taxEnabledCreditNotes &&
                        <>
                          <TableCell>
                            <Typography variant={"h4"}>SubTotal</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant={"h4"}>Tax Amount</Typography>
                          </TableCell>
                        </>
                      }
                      <TableCell>
                        <Typography variant={"h4"}>Total Amount</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {creditNote.creditNoteItems.map((item, idx) => {
                      return (
                        <TableRow>
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell>
                            {item.description.toUpperCase()}
                          </TableCell>
                          <TableCell>{item.quantity ? item.quantity : "N/A"}</TableCell>
                          {taxEnabledCreditNotes &&
                            <>
                              <TableCell>
                                {toCurrency(item.subTotal || item.totalAmount, currency, locale)}
                              </TableCell>
                              <TableCell>
                                {toCurrency(item.taxAmount || 0, currency, locale)}
                              </TableCell>
                            </>
                          }
                          <TableCell>{toCurrency(item.totalAmount, currency, locale)}</TableCell>
                        </TableRow>
                      )
                    })}
                    {taxEnabledCreditNotes ?
                      <>
                        <TableRow>
                          <TableCell colSpan={4} style={{ borderBottom: "none" }}></TableCell>
                          <TableCell style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}>
                            <Typography variant="h4">{"SubTotal"}</Typography>
                          </TableCell>
                          <TableCell>
                            {toCurrency(
                              getSubTotal() || getGrandTotal(),
                              currency,
                              locale
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={4} style={{ borderBottom: "none" }}></TableCell>
                          <TableCell style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}>
                            <Typography variant="h4">{"Total Tax"}</Typography>
                          </TableCell>
                          <TableCell>
                            {toCurrency(
                              getTotalTax(),
                              currency,
                              locale
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={4} style={{ borderBottom: "none" }}></TableCell>
                          <TableCell style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}>
                            <Typography variant="h4">{"Grand Total"}</Typography>
                          </TableCell>
                          <TableCell>
                            {toCurrency(
                              getGrandTotal(),
                              currency,
                              locale
                            )}
                          </TableCell>
                        </TableRow>
                      </> :
                      null
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {creditNote.refunds?.length > 0 && <Grid container item xs={12}>
              <Grid item container xs={12} spacing={1} alignItems={"center"}>
                <Grid item >
                  <Typography variant="h2">Refunds</Typography>
                </Grid>
                {loadingRefunds &&
                  <Grid item>
                    <CircularProgress size={20}/>
                  </Grid>
                }
              </Grid>
              <Grid container item xs={12}>
                <TableContainer className={classes.tableContainer}>
                  <Table aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant={"h4"}>S.No.</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"h4"}>Amount</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"h4"}>Refunded on</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"h4"}>Mode</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"h4"}>Status</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {creditNote.refunds.map((item, idx) => {
                        return (
                          <TableRow>
                            <TableCell>{idx + 1}</TableCell>
                            <TableCell>
                              {toCurrency(item.amount, currency, locale)}
                            </TableCell>
                            <TableCell>
                              {item.dateCreated ? getLocalizedDateFormat(country, item.dateCreated, DATE_TYPE.EXPANDED) : "-"}
                            </TableCell>
                            <TableCell>{`${item?.mode?.toUpperCase()} (${item?.paymentGateway?.toUpperCase()})`}</TableCell>
                            <TableCell>{item?.status?.toUpperCase()}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>}
            {creditNote.allocations?.length > 0 && <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography variant="h2">Adjustments</Typography>
              </Grid>
              <Grid container item xs={12}>
                <TableContainer className={classes.tableContainer}>
                  <Table aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant={"h4"}>S.No.</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"h4"}>Amount</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"h4"}>Adjusted To Invoice</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant={"h4"}>Adjusted At</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {creditNote.allocations.map((item, idx) => {
                        return (
                          <TableRow>
                            <TableCell>{idx + 1}</TableCell>
                            <TableCell>
                              {toCurrency(item.amount, currency, locale)}
                            </TableCell>
                            <TableCell>
                              <Typography
                                style={{ cursor: "pointer", color: "#1875d2" }}
                                onClick={() => navigate(`/update-billing?invoice=${item.invoice.id}`)}
                              >
                                {item.invoice.invoiceRef}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {item.allocatedAt ? getLocalizedDateFormat(country, item.allocatedAt, DATE_TYPE.EXPANDED) : "-"}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>}
            <Grid item container justifyContent="flex-end" xs={12} alignItems="center" spacing={1}>
              <Grid item>
                <DownloadPdf getSource={{ type: "CREDIT_NOTE", id: creditNote.id }} pdfSource={creditNote.creditNoteUrl} fileName={`CreditNote-${creditNote.creditNoteRef}.pdf`} />
              </Grid>
              <Grid item>
                <Fab
                  onClick={() => {
                    setSendClick(true);
                  }}
                  variant="extended"
                  size="medium"
                  type="button"
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress
                      size={14}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  {"Send E-Mail"}
                </Fab>
              </Grid>
              {sendClick ? (
                <MailSendBox
                  documentType="Credit-Note"
                  mails={(emails: string[]) => sendCreditNoteEmail(emails)}
                  open={sendClick}
                  handleClose={() => {
                    setSendClick(false);

                  }}
                />
              ) : (
                ""
              )}
              {showMessage ? (
                <ShowMessage
                  open={showMessage}
                  sentMails={showMails}
                  handleClose={() => setShowMessage(false)}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <ConfirmationDialog {...confirmationDialogData} />
    </Grid>
  )
}

export default CreditNote;