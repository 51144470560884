import {
  ADD_BRANCH,
  ADD_SERVICELOCATION,
  DELETE_ORGANISATION,
  RESET_BRANCH,
  RESET_ORGANISATION,
  RESET_SERVICELOCATION,
  SET_BRANCH,
  SET_ORGANISATION,
  SET_SERVICELOCATION,
  SET_SERVICELOCATIONS,
  UPDATE_BRANCH,
  UPDATE_ORGANISATION,
  UPDATE_SERVICELOCATION
} from "../../actions/organisation/types";
import { initialState } from "./const";
import { IOrganisationState } from "./types";

export default function (
  state: IOrganisationState = initialState,
  action: any
): IOrganisationState {
  switch (action.type) {
    case UPDATE_ORGANISATION:
      return {
        ...state,
        organisation: { ...state.organisation, ...action.payload }
      };
    case SET_BRANCH:
      return {
        ...state,
        branch: { ...state.branch, ...action.payload }
      };
    case RESET_BRANCH:
      return {
        ...state,
        branch: { ...initialState.branch }
      };
    case ADD_BRANCH:
      return {
        ...state,
        organisation: { ...state.organisation, branches: [...state.organisation.branches, action.payload] }
      };
    case UPDATE_BRANCH: {
      const updatedBranch = state.organisation.branches.map((branch) => (branch.id === action.payload.id) ?
        { ...branch, ...action.payload } : branch);
      return {
        ...state,
        organisation: { ...state.organisation, branches: [...updatedBranch] }
      };
    }
    case SET_SERVICELOCATIONS:
      return {
        ...state,
        serviceLocations: [...action.payload]
      };
    case ADD_SERVICELOCATION:
      return {
        ...state,
        serviceLocations: [...state.serviceLocations, action.payload]
      };
    case SET_SERVICELOCATION:
      return {
        ...state,
        serviceLocation: { ...state.serviceLocation, ...action.payload }
      };
    case RESET_SERVICELOCATION:
      return {
        ...state,
        serviceLocation: { ...initialState.serviceLocation }
      };
    case UPDATE_SERVICELOCATION: {
      const updatedServiceLocations = state.serviceLocations.map((serviceLocation) =>
        (serviceLocation.id === action.payload.id) ? { ...serviceLocation, ...action.payload } : serviceLocation);
      return {
        ...state,
        serviceLocations: [...updatedServiceLocations]
      };
    }
    case SET_ORGANISATION:
      return {
        ...state,
        organisation: { ...state.organisation, ...action.payload }
      };
    case DELETE_ORGANISATION:
      return {
        ...state
      };
    case RESET_ORGANISATION:
      return {
        ...state,
        organisation: { ...initialState.organisation }
      };
    default:
      return state;
  }
}
