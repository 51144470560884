import { IAddonType } from "../../../../../reducers/addonType/types";

export const columns: any = [
  {
    label: "Name",
    name: "name"
  },
  {
    label: "Fixed Price",
    name: "fixedRate"
  },
  {
    label: "Hourly Price",
    name: "hourly"
  },
  {
    label: "Daily Price",
    name: "daily"
  },
  {
    label: "Weekly Price",
    name: "weekly"
  },
  {
    label: "Monthly Price",
    name: "monthly"
  },
  {
    label: "Quantity",
    name: "totalQuantity"
  }
];

export const subCategories = [
  {
    label: "Delivery & Collection",
    value: "VALET",
    type: "SERVICE"
  },
  {
    label: "GPS",
    value: "GPS",
    type: "PRODUCT"
  },
  {
    label: "Baby Seat",
    value: "BABY_SEAT",
    type: "PRODUCT"
  },
  {
    label: "Additional Drivers",
    value: "ADDITIONAL_DRIVER",
    type: "PRODUCT"
  },
  {
    label: "Other",
    value: "OTHER",
    type: "PRODUCT"
  }
];

export const category = [
  {
    label: "Service",
    value: "SERVICE"
  },
  {
    label: "Product",
    value: "PRODUCT"
  }
];
export function getRateType(rateTypeName: string, addonType: IAddonType) {
  const rates = addonType.recurringRate;
  const rate = rates?.find((rate: any) => rate.rateTypeName === rateTypeName);
  return rate ? rate.rate : 0;
}
