export const phoneRegExp = /^\(?[+]?([0-9]{1,2})\)?[ ]?([0-9]{3})\)?[-.]?([0-9]{3})[-.]?([0-9]{4})$/;
export const integerRegExp = /[0-9]*/;

export const validateName = (value: string, fieldName: string) => {
  if (/[^A-z\s]/g.test(value)) {
    return `Invalid ${fieldName}`;
  }
  return undefined;
};

export const validateEmail = (value: string) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return " Invalid email address";
  }
  return undefined;
};

export const validateString = (value: string) => {
  if (!/[^A-z\s]/g.test(value)) {
    return true
  }
  else {
    return false
  }
}