import { gql } from "@apollo/client";

export const MARK_PCN_AS_CLOSED = gql`
  mutation markPCNClosed($id: String!) {
    markPCNClosed(id: $id) {
        id
        referenceNumber
        status
        isPaid
    }
  }
`;
