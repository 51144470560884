import { Value } from "react-phone-number-input";

import { IAdminUserState } from "./types";

export const initialState: IAdminUserState = {
  adminUser: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: {
      phone: "" as Value,
      country: "GB"
    },
    organisationId: "",
    branchId: "",
    role: "",
    userRole: "",
    active: true
  }
};
