import MuiAutocomplete from "./MuiAutocomplete";
import MuiButton from "./MuiButton";
import MuiCircularProgress from "./MuiCircularProgress";
import MuiFab from "./MuiFab";
import MuiFormLabel from "./MuiFormLabel";
import MuiInputBase from "./MuiInputBase";
import MuiListItemText from "./MuiListItemText";
import MuiPaper from "./MuiPaper";
import MuiTabs from "./MuiTabs";
import MuiTextField from "./MuiTextField";
import MuiCheckbox from "./MuiCheckbox";

export default {
  MuiButton,
  MuiTextField,
  MuiFab,
  MuiInputBase,
  MuiAutocomplete,
  MuiTabs,
  MuiPaper,
  MuiListItemText,
  MuiCircularProgress,
  MuiCheckbox
};
