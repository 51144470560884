import { useMutation } from '@apollo/client';
import { Fab, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ApolloError } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { ADD_PROCUREMENT_DELIVERY_INFO } from '../../../../graphql/Procurement/addProcurementDeliveryInfoMutation';
import { UPDATE_PROCUREMENT_DELIVERY_INFO } from '../../../../graphql/Procurement/updateProcurementDeliveryInfoMutation';
import { IDeliveryInformation, IProcurementRequest, ProcurementItemStatuses } from '../../../../reducers/Procurement/types';
import { useSnackBar } from '../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../common/SnackbarWrapper/SnackbarWrapper';
import { DATE_TYPE, checkDeleteOrDownloadFileType, formatGraphQLErrorMessage } from '../../../common/utils';
import { DateTime as d } from "luxon"
import { deliveryInfoInitials, NewDeliveryInfoModel } from './Models/NewDeliveryInfoModel';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { UserRoles } from '../../../hoc/Authorization';
import { IAppState } from '../../../../store';
import { useSelector } from 'react-redux';
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { getLocalizedDateFormat } from '../../../../utils/localized.syntex';

interface IProps {
  procurement: IProcurementRequest;
  onPreviousStep(): void;
  setProcurementRequest(data: IProcurementRequest): void;
}

export const DeliveryInformation: React.FC<IProps> = (props) => {

  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const [deliveryInfoList, setDeliveryInfoList] = useState<IDeliveryInformation[]>(props.procurement.deliveryInformation);
  const [deliveryInfo, setDeliveryInfo] = useState<IDeliveryInformation>(deliveryInfoInitials);
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (props.procurement && props.procurement.deliveryInformation) {
      setDeliveryInfoList(props.procurement.deliveryInformation)
    }
  }, [props.procurement])

  const [addProcurementDelivery] = useMutation(ADD_PROCUREMENT_DELIVERY_INFO, {
    onCompleted: (data) => {
      snackbar({
        message: "Delivery Info successfully",
        variant: SnackBarVariant.SUCCESS
      })
      if (data && data.addProcurementDeliveryInfo) {
        setDeliveryInfoList([
          ...(deliveryInfoList ?? []), data.addProcurementDeliveryInfo
        ])
        setDeliveryInfo(deliveryInfoInitials)
        setOpen(false)
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateProcurementDeliveryInfo] = useMutation(UPDATE_PROCUREMENT_DELIVERY_INFO, {
    onCompleted: (data) => {
      snackbar({
        message: "Delivery info updated successfully",
        variant: SnackBarVariant.SUCCESS
      })
      if (data && data.updateProcurementDeliveryInfo) {
        const deliveryInfoIndex = deliveryInfoList.findIndex((deliveryInfo) => deliveryInfo.id === data.updateProcurementDeliveryInfo.id)
        if (deliveryInfoIndex > -1) {
          const newDeliveryInfoList = [
            ...deliveryInfoList.slice(0, deliveryInfoIndex),
            {
              ...deliveryInfoList[deliveryInfoIndex],
              ...data.updateProcurementDeliveryInfo
            },
            ...deliveryInfoList.slice(deliveryInfoIndex + 1)
          ];
          props.setProcurementRequest({
            ...props.procurement,
            deliveryInformation: newDeliveryInfoList
          })
        }
      }
      setOpen(false)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const handleOnCloseModel = () => {
    setDeliveryInfo(deliveryInfoInitials)
    setOpen(false)
  }

  const handelFormSubmit = (deliveryInfo: IDeliveryInformation) => {
    const { id, partner, status, ...rest } = deliveryInfo
    if (deliveryInfo.id) {
      updateProcurementDeliveryInfo({
        variables: {
          id: props.procurement.id,
          deliveryInfoId: deliveryInfo.id,
          deliveryInfo: rest
        }
      })
    } else {
      addProcurementDelivery({
        variables: {
          id: props.procurement.id,
          deliveryInfo: rest
        }
      })
    }
  }

  async function downloadDocument(documentKey: string, title: string) {
    if (!userState.tenancy?.id) {
      return;
    }
    const { fileExtension } = checkDeleteOrDownloadFileType(
      documentKey
    );
    const file = await getSignedUrl(documentKey)
    const url: any = file;

    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${title}.${fileExtension}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={"tableCellHead"} align="left">Delivery Challan ID</TableCell>
                <TableCell className={"tableCellHead"} align="left">Delivery Challan Date</TableCell>
                <TableCell className={"tableCellHead"} align="left">Product Received Date</TableCell>
                <TableCell className={"tableCellHead"} align="left">Status</TableCell>
                <TableCell className={"tableCellHead"} align="left">Documents</TableCell>
                <TableCell className={"tableCellHead"} align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                deliveryInfoList &&
                deliveryInfoList.map((row: IDeliveryInformation, index: number) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell align="left">{row.deliveryNoteId || "N/A"}</TableCell>
                    <TableCell align="left">{row.deliveryNoteDate ? getLocalizedDateFormat(country, row.deliveryNoteDate, DATE_TYPE.CONDENSED) : "N/A"}</TableCell>
                    <TableCell align="left">{row.productReceivedDate ? getLocalizedDateFormat(country, row.productReceivedDate, DATE_TYPE.CONDENSED) : "N/A"}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="left">
                      {
                        row &&
                        row.documents &&
                        row.documents.map((doc: string, index: number) => {
                          return <>
                            <span>{`doc ${index + 1}`}</span>
                            <IconButton
                              onClick={() => {
                                if (doc) {
                                  downloadDocument(doc, `finance_info${index + 1}`)
                                }
                              }}
                              aria-label="preview"
                              size="large">
                              <CloudDownloadIcon />
                            </IconButton>
                          </>;
                        })
                      }
                    </TableCell>
                    <TableCell align="right">
                      <Grid item xs={12} container>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => {
                              const deliveryData = deliveryInfoList[index]
                              setDeliveryInfo(deliveryData)
                              setOpen(true)
                            }}
                            aria-label="edit"
                            disabled={userState.role !== UserRoles.FLEET_MANAGER || row.status === ProcurementItemStatuses.RECEIVED ? true : false}
                            size="large">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {
        open && (
          <NewDeliveryInfoModel
            handelFormSubmit={handelFormSubmit}
            onClose={handleOnCloseModel}
            open={open}
            deliveryInfo={deliveryInfo}
            purchaseOrders={props.procurement.purchaseOrders}
            procurementExpectedDate={props.procurement.createdAt}
          />
        )
      }
      <Grid item container xs={12} justifyContent="space-between">
        <Fab
          className="blackBackButton"
          variant="extended"
          size="medium"
          aria-label="add"
          onClick={() => {
            props.onPreviousStep()
          }}
        >
          Previous
        </Fab>
      </Grid>
    </Grid >
  );
}
