import {
	Grid,
	Typography,
	TableContainer,
	Paper,
	Table,
	TableRow,
	TableCell,
	IconButton,
	TableHead,
	TableBody,
	Fab,
	CircularProgress,
	Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ICommonItemFields, IProcurementRequest, IProposalRequest, IQuote, ProcurementItemStatuses } from '../../../../reducers/Procurement/types'
import { useSnackBar } from '../../../common/SnackBarContext/SnackBarContext';
import { DateTime as d } from "luxon"
import { useMutation } from '@apollo/client';
import { ADD_PROCUREMENT_PROPOSAL_REQUEST } from '../../../../graphql/Procurement/addProposalRequestMutation';
import { ApolloError } from '@apollo/client';
import { SnackBarVariant } from '../../../common/SnackbarWrapper/SnackbarWrapper';
import { formatGraphQLErrorMessage } from '../../../common/utils';
import { UPDATE_PROCUREMENT_PROPOSAL_REQUEST } from '../../../../graphql/Procurement/updateProposalRequestMutation';
import { NewProposalRequestModel, proposalRequestInitials } from './Models/NewProposalRequestModel';
import { IAppState } from '../../../../store';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../../hoc/Authorization';
import MailSendBox from '../../../common/MailSendBox';
import ShowMessage from '../../../common/MailSendBox/ShowMessage';
import { withStyles } from '@mui/styles';
import { SEND_PROPOSAL_REQUEST_EMAIL } from '../../../../graphql/Procurement/sendProcurementEmailMutation';

const HtmlTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#ffffff',
		color: 'rgba(0, 0, 0, 0.87)',
		minWidth: 250,
		border: '1px solid #dadde9',
		padding: 15
	},
}))(Tooltip);
interface IProps {
	procurement: IProcurementRequest;
	onNextStep(data: any): void
	onPreviousStep(): void;
	setProcurementRequest(data: IProcurementRequest): void;
}

export const RFP: React.FC<IProps> = (props) => {

	const snackbar = useSnackBar();
	const userReducer = useSelector((state: IAppState) => state.userReducer);
	const [proposalRequests, setProposalRequests] = useState<IProposalRequest[]>([]);
	const [proposalRequest, setProposalRequest] = useState<IProposalRequest>()
	const [open, setOpen] = useState<boolean>(false);
	const [displayMailbox, setDisplayMailbox] = useState<boolean>(false);
	const [showMessage, setShowMessage] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [showMails, setShowMails] = useState<string[]>([]);
	const [isNewRFP, setIsNewRFP] = useState<boolean>(false);
	const [proposalRequestData, setProposalRequestData] = useState({
		proposalId: "",
		partnerEmail: ""
	})

	useEffect(() => {
		if (props.procurement && props.procurement.proposalRequests) {
			setProposalRequests(props.procurement.proposalRequests)
			if (props.procurement.quotes.length > 0) {
				const anyQuoteApproved = props.procurement.quotes.some((quote: IQuote) => quote.status === ProcurementItemStatuses.APPROVED)
				if (anyQuoteApproved) {
					setIsNewRFP(anyQuoteApproved)
				}
			}
		}
	}, [props.procurement])

	const [addProcurementProposalRequest] = useMutation(ADD_PROCUREMENT_PROPOSAL_REQUEST, {
		onCompleted: (data) => {
			snackbar({
				message: "Proposal request added successfully",
				variant: SnackBarVariant.SUCCESS
			})
			if (data && data.addProposalRequest) {
				props.setProcurementRequest({
					...props.procurement,
					proposalRequests: [...props.procurement.proposalRequests, ...data.addProposalRequest.proposalRequests],
					quotes: [...props.procurement.quotes, ...data.addProposalRequest.quotes]
				})
				setOpen(false)
			}
		},
		onError: (error: ApolloError) =>
			snackbar({
				message: formatGraphQLErrorMessage(error.message),
				variant: SnackBarVariant.ERROR
			})
	});

	const [updateProcurementProposalRequest] = useMutation(UPDATE_PROCUREMENT_PROPOSAL_REQUEST, {
		onCompleted: (data) => {
			snackbar({
				message: "Proposal request updated successfully",
				variant: SnackBarVariant.SUCCESS
			})
			if (data && data.updateProposalRequest) {
				const proposalRequestIndex = proposalRequests.findIndex((proposalRequest) => proposalRequest.id === data.updateProposalRequest.id)
				if (proposalRequestIndex > -1) {
					const newProposalRequests = [
						...proposalRequests.slice(0, proposalRequestIndex),
						{
							...proposalRequests[proposalRequestIndex],
							...data.updateProposalRequest
						},
						...proposalRequests.slice(proposalRequestIndex + 1)
					];
					setProposalRequests(newProposalRequests)
				}
			}
			setOpen(false)
			setProposalRequest(proposalRequestInitials)
		},
		onError: (error: ApolloError) =>
			snackbar({
				message: formatGraphQLErrorMessage(error.message),
				variant: SnackBarVariant.ERROR
			})
	});

	const [sendProposalRequestEmail] = useMutation(SEND_PROPOSAL_REQUEST_EMAIL, {
		onCompleted: () => {
			setLoading(false);
			setShowMessage(true);
		},
		onError: (error: ApolloError) => {
			setLoading(false);
			setShowMessage(false);
			snackbar({
				message: formatGraphQLErrorMessage(error.message),
				variant: SnackBarVariant.ERROR
			});
		}
	});

	const handelFormSubmit = (proposalRequest: IProposalRequest) => {
		const updatedProposalRequest = { ...proposalRequest, note: { ...proposalRequest.note, createdBy: userReducer.id } }
		const { id, partner, partnerIds, lastEmailSent, ...rest } = updatedProposalRequest
		let proposalItems: ICommonItemFields[] = []
		props.procurement.items.forEach(item => {
			if (item.status === ProcurementItemStatuses.APPROVED) {
				proposalItems.push({
					requestId: item.requestId,
					vehicleMake: item.make,
					vehicleModel: item.model,
					vehicleVarient: item.variant,
					vehicleFuel: item.fuel,
					vehicleTransmission: item.transmission,
					vehicleCondition: item.condition,
					vehicleCount: item.quantity,
					vehicleExpectedByDate: item.expectedByDate,
					documents: []
				})
			}
		});
		if (proposalRequest.id) {
			updateProcurementProposalRequest({
				variables: {
					id: props.procurement.id,
					proposalRequestId: proposalRequest.id,
					proposalRequest: { ...rest, items: proposalItems, id: proposalRequest.id }
				}
			})
		} else {
			addProcurementProposalRequest({
				variables: {
					id: props.procurement.id,
					proposalRequest: { ...rest, partnerIds: proposalRequest.partnerIds, items: proposalItems }
				}
			})
		}
	}

	const handleCloseDialog = () => {
		setOpen(false)
		setProposalRequest(proposalRequestInitials)
	}

	const handleSendProposalRequestEmail = (proposalId: string, partnerEmail: string, additionalEmailIds: string[]) => {
		setLoading(true);
		setShowMessage(false);
		setDisplayMailbox(false);
		setShowMails([...additionalEmailIds, partnerEmail])
		sendProposalRequestEmail({
			variables: {
				id: props.procurement.id,
				proposalRequestId: proposalId,
				additionalMails: additionalEmailIds
			}
		});
	};

	return (
        <Grid container item xs={12} spacing={2}>
			<Grid item xs={12} container justifyContent="flex-end">
				{
					!isNewRFP && (
						<Fab
							variant="extended"
							size="medium"
							aria-label="add"
							onClick={() => {
								setOpen(true)
							}}
							disabled={userReducer.role === UserRoles.ORGANISATION_OWNER ? false : true}
						>
							CREATE RFP
						</Fab>
					)
				}
			</Grid>
			<Grid item xs={12}>
				<Grid item xs={12}>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell className={"tableCellHead"} align="left">ID</TableCell>
									<TableCell className={"tableCellHead"} align="left">RFP Date</TableCell>
									<TableCell className={"tableCellHead"} align="left">Partner</TableCell>
									<TableCell className={"tableCellHead"} align="left">Valid Until</TableCell>
									<TableCell className={"tableCellHead"} align="left">Notes</TableCell>
									<TableCell className={"tableCellHead"} align="left">Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									proposalRequests &&
									proposalRequests.map((row: IProposalRequest, index: number) => (
										<TableRow
											key={index}
										>
											<TableCell align="left">{row.proposalRequestId}</TableCell>
											<TableCell align="left">{d.fromISO(row.date).toFormat("dd/MM/yyyy")}</TableCell>
											<TableCell align="left">{row.partner?.partnerName}</TableCell>
											<TableCell align="left">{d.fromISO(row.validUntilDate).toFormat("dd/MM/yyyy")}</TableCell>
											<TableCell align="left">
												<HtmlTooltip
													title={
														<>
															<Typography variant={"h3"}>Description</Typography>
															<Typography variant={"body1"}>{row.note && row.note.description ? row.note.description : "N/A"}</Typography>
														</>
													}
												>
													<IconButton aria-label="note-preview" size="large">
														<VisibilityIcon />
													</IconButton>
												</HtmlTooltip>
											</TableCell>
											<TableCell align="left">
												<Grid container item xs={12}>
													<IconButton
                                                        aria-label="edit-rfp"
                                                        onClick={() => {
															const pRequest = proposalRequests[index]
															setProposalRequest(pRequest)
															setOpen(true)
														}}
                                                        disabled={userReducer.role === UserRoles.ORGANISATION_OWNER ? false : true}
                                                        size="large">
														<EditIcon />
													</IconButton>
													<IconButton
                                                        aria-label="mail"
                                                        disabled={userReducer.role === UserRoles.ORGANISATION_OWNER ? false : true || loading}
                                                        onClick={() => {
															if (row.id && row.partner?.email) {
																setProposalRequestData({
																	proposalId: row.id,
																	partnerEmail: row.partner?.email
																})
																setDisplayMailbox(true);
															}

														}}
                                                        size="large">
														{loading && (
															<CircularProgress
																size={14}
																style={{ marginRight: "10px" }}
															/>
														)}
														<MailOutlineIcon />
													</IconButton>
												</Grid>
											</TableCell>
										</TableRow>
									))
								}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				{displayMailbox &&
					<MailSendBox
						title='Send proposal request to partner'
						documentType="Proposal Request"
						mails={(emails: string[]) => {
							if (proposalRequestData.proposalId && proposalRequestData.partnerEmail) {
								handleSendProposalRequestEmail(proposalRequestData.proposalId, proposalRequestData.partnerEmail, emails)
							}
						}}
						open={displayMailbox}
						handleClose={() => setDisplayMailbox(false)}
					/>
				}
				{showMessage &&
					<ShowMessage
            open={showMessage}
            sentMails={showMails}
            handleClose={() => setShowMessage(false)}
          />
				}
				{
					open && (
						<NewProposalRequestModel
							onClose={() => handleCloseDialog()}
							open={open}
							handelFormSubmit={handelFormSubmit}
							proposalRequest={proposalRequest}
							items={props.procurement.items}
							procurementExpectedDate={props.procurement.createdAt}
							proposalRequests={props.procurement.proposalRequests}
						/>
					)
				}
			</Grid>
			<Grid item container xs={12} justifyContent="space-between">
				<Fab
					className="blackBackButton"
					variant="extended"
					size="medium"
					aria-label="add"
					onClick={() => {
						props.onPreviousStep()
					}}
				>
					Previous
				</Fab>
				<Fab
					variant="extended"
					size="medium"
					aria-label="add"
					onClick={() => {
						props.onNextStep(proposalRequests)
					}}
					disabled={props.procurement.proposalRequests.length > 0 ? false : true}
				>
					Next
				</Fab>
			</Grid>
		</Grid>
    );
}
