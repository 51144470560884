import { useLazyQuery, useMutation, ApolloError } from "@apollo/client";
import {
  Accordion,
  Avatar,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Fab,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Switch,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { deepOrange } from "@mui/material/colors";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { MUIDataTableOptions } from "mui-datatables";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { ADD_CUSTOMER_NOTE_MUTATION } from "../../../../graphql/customers/addCustomerNoteMutation";
import { CREATE_DOCUMENT } from "../../../../graphql/customers/createDocumentMutation";
import { DELETE_DOCUMENT } from "../../../../graphql/customers/deleteDocumentMutation";
import { GET_CREDIT_SCORE } from "../../../../graphql/customers/getCreditScoreMutation";
import { GET_CUSTOMER } from "../../../../graphql/customers/getCustomerQuery";
import { CREATE_PROCESS_FOR_IDV_CHECK } from "../../../../graphql/customers/idvCheckMutation";
import { UPDATE_CUSTOMER } from "../../../../graphql/customers/updateCustomerMutation";
import { VERIFY_CUSTOMER_LICENCE_DVLA } from "../../../../graphql/customers/verifyCustomerLicenceDvlaQuery";
import { VERIFY_CUSTOMER_REQUEST } from "../../../../graphql/customers/verifyCustomerRequestMutation";
import { DvlaVerificationStatus } from "../../../../reducers/customer/const";
import {
  CustomerVerificationStatus,
  ICustomer,
  ICustomerNote,
  ICustomerNoteInput
} from "../../../../reducers/customer/types";
import { IAppState } from "../../../../store";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { ConfirmationDialog } from "../../../common/ConfirmationDialog/ConfirmationDialog";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  formatGraphQLErrorMessage,
  getImage,
  returnfileContentTypeAndExtention,
  toCurrency
} from "../../../common/utils";
import { UserRoles } from "../../../hoc/Authorization";
import DragDropImages from "../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";
import { noteColumns } from "../../ReservationManagement/Summary/const";
import { CustomerBookingHistory } from "./BookingHistory";
import { CreditCheckDialog } from "./CreditCheckDialog";
import CustomerCreditNoteHistory from "./CreditNoteHistory";
import { CustomerDocuments } from "./CustomerDocuments";
import CustomerUpdate from "./CustomerUpdate";
import { CustomerVerificationDialog } from "./CustomerVerificationDialog";
import DvlaVerificationDialog, {
  IDvlaCheckValues
} from "./DvlaVerificationDialog";
import { IDVerificationDialog } from "./IDVerificationDialog";
import { captureErrorException } from "../../../../utils/sentry";
import { SEARCH_USER } from "../../../../graphql/customers/searchUserQuery";
import { UPDATE_USER_STATUS } from "../../../../graphql/customers/updateUserStatusMutation";
import { IUser } from "../../../../reducers/auth/types";
import Edit from "@mui/icons-material/Edit";
import DoneIcon from '@mui/icons-material/Done';
import { SOCKET_EVENTS } from "../../ReservationManagement/utils";
import { socket } from "../../../../utils/socket";
import { CustomerInvoiceHistory } from "./InvoiceHistory";
import { SEND_CUSTOMER_LOGIN_CREDENTIAL } from "../../../../graphql/customers/sendCustomerLoginCredential";

interface ICustomerDetails {
  customer: ICustomer;
  history: any;
}

interface ILicenseDocument {
  documentKey: string;
  document: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      flexGrow: 1,
      padding: 30,
      [theme.breakpoints.down("sm")]: {
        width: `calc(100vw - 45px)`
      },
      [theme.breakpoints.up("sm")]: {
        width: `calc(100vw - 245px)`
      }
    },
    newPaper: {
      [theme.breakpoints.down("sm")]: {
        width: `calc(100vw - 45px)`
      },
      [theme.breakpoints.up("sm")]: {
        width: `calc(100vw - 245px)`
      }
    },
    expansionPanel: {
      marginTop: 8,
      [theme.breakpoints.down("sm")]: {
        minWidth: `calc(100vw - 105px)`
      },
      [theme.breakpoints.up("sm")]: {
        minWidth: `calc(100vw - 305px)`
      }
    },
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    orange: {
      backgroundColor: deepOrange[500],
      color: theme.palette.getContrastText(deepOrange[500]),
      fontSize: "3rem",
      height: "100px",
      width: "100px"
    },
    root: {
      padding: theme.spacing(5)
    },
    section: {
      marginBottom: "25px"
    },
    imageContainer: {
      height: 80,
      border: "1px solid #e2e2e2",
      padding: 5
    },
    imageStyle: {
      height: 50,
      width: "100%",
      borderBottom: "1px solid #e2e2e2"
    },
    imageContainer2: {
      border: "1px solid #e2e2e2",
      padding: 5,
      display: "inherit",
      maxWidth: "50%"
    },
    imageStyle2: {
      height: 50,
      width: 60
    },
    greyColored: {
      backgroundColor: "lightGrey"
    },
    summaryLeftMargin: {
      marginLeft: 5
    },
    linkText: {
      color: theme.palette.primary.main,
      marginLeft: "1rem",
      cursor: "pointer"
    },
    endorsementsTextfields: {
      width: "4rem",
    }
  })
);

export const CustomerDetails = React.memo((props: any) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [customer, setCustomer] = useState<ICustomer>(props.customer);
  const organisation = userState.currentOrganisation;
  const tenancy = userState.currentTenancy;
  const branch = userState.currentBranch;
  const { locale, currency, requiresCustomerVerification } = organisation;
  const [creditNoteBalance, setCreditNoteBalance] = useState<number>(0);
  const [editable, setEditable] = useState<boolean>(false);
  const [note, setNote] = useState<ICustomerNoteInput>();
  const [rows, setRows] = useState<ICustomerNote[]>([]);
  const [licenseImages, setLicenseImages] = useState<ILicenseDocument[]>([]);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(
    false
  );
  const [profileDialogVisible, setProfileDialogVisible] = useState<boolean>(
    false
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [deleteDocumentKey, setDeleteDocumentKey] = useState<string>("");
  const [profilePic, setProfilePic] = useState<any>("");
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [openIDVerificationDialog, setOpenIDVerificationDialog] = useState(
    false
  );
  const [openCreditCheckDialog, setOpenCreditCheckDialog] = useState(false);
  const [status, setStatus] = useState<CustomerVerificationStatus>(
    CustomerVerificationStatus.PENDING
  );
  const [inProgress, setInProgress] = useState(false);
  const [user, setUser] = useState<IUser>();
  const [openUserStatusDialog, setOpenUserStatusDialog] = useState<boolean>(false);
  const [endorsementEdit, setEndorsementEdit] = useState<boolean>(false);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    onRowClick: () => { },
    elevation: 0
  };
  const [dvlaDialogVisible, setDvlaDialogVisible] = useState<boolean>(false);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [intervalId, setIntervalId] = useState<any>();
  const [dvlaVerificationStatus, setDvlaVerificationStatus] = useState<string>(
    ""
  );
  const customerSelector = useSelector(
    (state: IAppState) => state.customerReducer.customer
  );

  const [getCustomer, { data }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data.customer) {
        navigate("/individual-customer");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [searchUserWithPortalAccess] = useLazyQuery(SEARCH_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.getPortalUserByCustomerId) {
        setUser(data.getPortalUserByCustomerId);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [verifyCustomerLicenceDvla] = useLazyQuery(
    VERIFY_CUSTOMER_LICENCE_DVLA,
    {
      fetchPolicy: "no-cache"
    }
  );

  const [addCustomerDocument] = useMutation(CREATE_DOCUMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.addCustomerDocument) {
        reshapeCustomer(data.addCustomerDocument)
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [deleteCustomerDocument] = useMutation(DELETE_DOCUMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.deleteCustomerDocument) {
        reshapeCustomer(data.deleteCustomerDocument)
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: (data) => {
      snackbar({
        message: "Customer updated successfully!",
        variant: SnackBarVariant.SUCCESS
      });
      getCustomer({
        variables: {
          customerId: data.updateCustomer.id
        }
      });
      if (!data.updateCustomer.license.images.length) {
        setLicenseImages([]);
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [addCustomerNote] = useMutation(ADD_CUSTOMER_NOTE_MUTATION, {
    onCompleted: (data) => {
      snackbar({
        message: "Note added successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setRows([...rows, { ...data.addCustomerNote }]);
      setNote({
        description: "",
        createdBy: "",
        createdDate: ""
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [verifyCustomerRequest] = useMutation(VERIFY_CUSTOMER_REQUEST, {
    onCompleted: (data) => {
      if (data && data.verifyCustomer) {
        snackbar({
          message: data.verifyCustomer.message,
          variant: SnackBarVariant.SUCCESS
        });
        getCustomer({
          variables: {
            customerId: customer.id
          }
        });
        setCustomer({
          ...customer,
          status: data.verifyCustomer.status,
          verifiedDate: data.verifyCustomer.verifiedDate
        });
        setOpenVerificationDialog(false);
        setInProgress(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setInProgress(false);
    }
  });
  const [createProcessForIDV, { loading: processLoading }] = useMutation(
    CREATE_PROCESS_FOR_IDV_CHECK,
    {
      onCompleted: (data) => {
        if (data?.createNewProcessForIDV) {
          snackbar({
            message: "Email has been sent for Identity Verification",
            variant: SnackBarVariant.SUCCESS
          });
          setCustomer(data.createNewProcessForIDV);
        }
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const [getCreditScoreForCustomer, { loading: scoreLoading }] = useMutation(
    GET_CREDIT_SCORE,
    {
      onCompleted: (data) => {
        getCustomer({
          variables: {
            customerId: customer.id
          }
        });
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const [updateUserStatus, { loading: userLoading }] = useMutation(
    UPDATE_USER_STATUS,
    {
      onCompleted: (data) => {
        setUser(data.updateUserStatus)
        setOpenUserStatusDialog(false);
        snackbar({
          message: "User Status has been updated successfully",
          variant: SnackBarVariant.SUCCESS
        })
      },
      onError: (error: ApolloError) =>
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
    }
  );

  const [sendLoginCredential] = useMutation(SEND_CUSTOMER_LOGIN_CREDENTIAL, {
    onCompleted: (data) => {
      snackbar({
        message: data.sendCustomerLoginCredential.message,
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/individual-customer");
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const customerId = params.get("customer");
      const editable = params.get("editable");
      if (customerId && editable) {
        setEditable(Boolean(editable));
        getCustomer({
          variables: {
            customerId: customerId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (data && data.customer) {
      socket.emit(SOCKET_EVENTS.REQUEST_VIEW_CUSTOMER, { customerId: data.customer.id });
      reshapeCustomer(data.customer)
    }
  }, [data]);

  useEffect(() => {
    socket.auth = {
      userId: userState.id,
    };
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.GET_UPDATED_CUSTOMER, (data) => {
      getCustomer({
        variables: {
          customerId: data.customerId
        }
      })
    })

    return () => {
      socket.off(SOCKET_EVENTS.GET_UPDATED_CUSTOMER);
    }
  }, [])

  const reshapeCustomer = (customerData: ICustomer) => {
    _setCustomer(customerData);
    if (dvlaDialogVisible && customerData.license?.dvlaVerificationStatus) {
      setDvlaVerificationStatus(customerData.license.dvlaVerificationStatus);
    }
    if (customerData.notes?.length) {
      setRows(customerData.notes);
    }
    if (customerData && customerData.profilePicture) {
      getProfilePicture(customerData.profilePicture);
    }
    if (customerData && customerData.id) {
      searchUserWithPortalAccess({
        variables: {
          customerId: customerData.id
        }
      })
    }
  }




  const _setCustomer = (customer: ICustomer) => {
    const promises: any[] = [];
    try {
      if (
        customer &&
        customer.license &&
        customer.license.images &&
        customer.license.images.length
      ) {
        const dlImages: ILicenseDocument[] = [];
        customer.license.images.map((img: string) => {
          if (img) {
            promises.push(
              (async () => {
                try {
                  const data: any = await getImage(img);
                  dlImages.push({
                    documentKey: img,
                    document: data
                  });
                } catch (error) {
                  return;
                }
              })()
            );
          }
        });
        Promise.all(promises).then(() => {
          setLicenseImages(dlImages);
        });
      } else {
        customer.license.images = [];
      }
    } catch (error) {
      captureErrorException(error)
      throw error;
    }
    customer.overrideInvoiceDate = customer.overrideInvoiceDate || false;
    customer.overrideWeeklyInvoiceDate = customer.overrideWeeklyInvoiceDate || false;
    customer.weeklyInvoicingDay = customer.weeklyInvoicingDay || undefined;
    customer.invoicingDay = customer.invoicingDay || undefined;
    setCustomer(customer);
  };


  const pad2 = (value: number) => {
    if (value < 10) {
      return "0" + value;
    }
    return value;
  };

  const onUpdateHandler = (updatedCustomer: ICustomer) => {
    setCustomer(updatedCustomer);
  };

  const uploadDocument = async (files: File[]) => {
    try {
      if (!userState.tenancy?.id) {
        return;
      }
      if (uploadInProgress) {
        return snackbar({
          message: "Please wait image upload in progress!",
          variant: SnackBarVariant.ERROR
        });
      }
      if (
        customer &&
        customer.license &&
        customer.license.images &&
        customer.license.images.length >= 2
      ) {
        return snackbar({
          message: "Maximum two images can be upload for licence!",
          variant: SnackBarVariant.ERROR
        });
      }
      if (
        customer &&
        customer.license &&
        customer.license.images &&
        customer.license.images.length === 1 &&
        files.length === 2
      ) {
        return snackbar({
          message: "Maximum two images can be upload for licence!",
          variant: SnackBarVariant.ERROR
        });
      }
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        // 50MB limit exceeded
        if (file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        setUploadInProgress(true);
        if (
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg"
        ) {
          const { fileExtension } = returnfileContentTypeAndExtention(file);
          const customerLicenseImages: string[] = customer.license.images;
          const uniqueId = uuidv4();
          const key = `${uniqueId}.${fileExtension}`;
          if (_e_) {
            await _e_.add({
              name: key,
              file: file,
              complete: async () => {
                customerLicenseImages.push(key);
                snackbar({
                  message: "Licence Image Uploaded Successfully",
                  variant: SnackBarVariant.SUCCESS
                });
                const image = await getImage(key);
                setUploadedImage(key, image);
                setDocumentDialogVisible(false);
                setUploadInProgress(false);
                customer.license.images = customerLicenseImages;
                const {
                  id,
                  notes,
                  approvedDrivers,
                  verifiedDate,
                  creditSafeData,
                  credentialSent,
                  credentialSentOn,
                  ...rest
                } = customer;
                setCustomer(customer);
                updateCustomer({
                  variables: {
                    customer: rest,
                    customerId: customer.id
                  }
                });
              }
            });
          }
        } else {
          setDocumentDialogVisible(false);
          setUploadInProgress(false);
          return snackbar({
            message: "Please only upload .jpeg/.jpg/.png image format!",
            variant: SnackBarVariant.ERROR
          });
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        snackbar({
          message: formatGraphQLErrorMessage(err.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  };

  const getProfilePicture = async (key: string) => {
    setProfilePic(await getImage(key));
  };

  // Remove the document
  function deleteDocument(documentKey: string) {
    if (
      userState.role !== UserRoles.ADMIN &&
      userState.role !== UserRoles.SUPER_ADMIN &&
      userState.role !== UserRoles.ORGANISATION_OWNER &&
      userState.role !== UserRoles.BRANCH_MANAGER
    ) {
      return snackbar({
        message: "Not allowed to delete!",
        variant: SnackBarVariant.ERROR
      });
    }
    setDeleteInProgress(true);
    const key = documentKey;
    // TODO: implement file deleteion from s3
    snackbar({
      message: "Document Removed Successfully",
      variant: SnackBarVariant.SUCCESS
    });
    const updatedLicenseImages = customer.license.images.filter((image) => {
      return image !== key;
    });
    customer.license.images = updatedLicenseImages;
    const {
      id,
      notes,
      approvedDrivers,
      verifiedDate,
      creditSafeData,
      credentialSent,
      credentialSentOn,
      ...rest
    } = customer;
    updateCustomer({
      variables: {
        customer: rest,
        customerId: customer.id
      }
    });
    setDeleteInProgress(false);
    handleDialogClose();
  }

  const setUploadedImage = (key: string, image: any) => {
    setCustomer({
      ...customer,
      license: {
        ...customer.license,
        images: [...customer.license.images, key]
      }
    });
  };

  const eighteenYearsOldDate = new Date();
  eighteenYearsOldDate.setFullYear(eighteenYearsOldDate.getFullYear() - 18);

  const onDocumentChange = (action: string, value: any) => {
    if (action === "delete") {
      deleteCustomerDocument({
        variables: {
          customerId: customer.id,
          documentId: value.documentId
        }
      })
    } else {
      delete value.type;
      delete value.createdAt;
      addCustomerDocument({
        variables: {
          customerId: customer.id,
          document: value
        }
      })
    }
  };

  const uploadProfilePic = async (files: File[]) => {
    try {
      if (!userState.tenancy?.id) {
        return;
      }
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        // 50MB limit exceeded
        if (file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        setUploadInProgress(true);
        if (
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg"
        ) {
          const { fileExtension } = returnfileContentTypeAndExtention(file);
          const uniqueId = uuidv4();
          const key = `${uniqueId}.${fileExtension}`;
          if (_e_) {
            await _e_.add({
              name: key,
              file: file,
              complete: async () => {
                snackbar({
                  message: "Profile Image Uploaded Successfully",
                  variant: SnackBarVariant.SUCCESS
                });
                const image = await getImage(key);
                setProfilePic(image);
                setProfileDialogVisible(false);
                setUploadInProgress(false);
                customer.profilePicture = key;
                const {
                  id,
                  notes,
                  approvedDrivers,
                  documents,
                  deleted,
                  verified,
                  verifiedDate,
                  creditSafeData,
                  credentialSent,
                  credentialSentOn,
                  ...rest
                } = customer;
                setCustomer(customer);
                updateCustomer({
                  variables: {
                    customer: rest,
                    customerId: customer.id
                  }
                });
              }
            });
          }
        } else {
          setProfileDialogVisible(false);
          setUploadInProgress(false);
          return snackbar({
            message: "Please only upload .jpeg/.jpg/.png image format!",
            variant: SnackBarVariant.ERROR
          });
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        snackbar({
          message: formatGraphQLErrorMessage(err.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  };

  const onDeleteSelectedProfileImage = () => {
    setProfilePic("");
  };

  const verifyLicenceWithDvla = (data: IDvlaCheckValues) => {
    verifyCustomerLicenceDvla({
      variables: {
        ...data,
        customerId: customer.id
      }
    });
    setDvlaVerificationStatus(DvlaVerificationStatus.PENDING);
  };

  const handleDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleCustomerVerification = (note: string) => {
    setInProgress(true);
    verifyCustomerRequest({
      variables: {
        customerId: customer.id,
        status,
        note
      }
    });
  };
  const handleIdvCheck = () => {
    createProcessForIDV({
      variables: {
        customerId: customer.id
      }
    });
  };

  const getCreditScore = () => {
    getCreditScoreForCustomer({
      variables: {
        customerId: customer.id
      }
    });
  };

  const saveEndorsements = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomer({
      ...customer,
      license: {
        ...customer.license,
        [event.target.name]: parseInt(event.target.value),
      }
    });
  }
  const convertNotesDescriptionToHTML = (value: { description?: any }) => {
    const description: string | undefined = value?.description;
    if (typeof description !== 'string') {
      return value;
    }
    if (!description.includes('\n')) {
      return value;
    }
    const newDescription = description.split('\n').reduce<JSX.Element[]>(
      (acc, line, index) => {
        acc.push(<React.Fragment key={index}>{line}</React.Fragment>);
        acc.push(<br key={`br-${index}`} />);
        return acc;
      }, []
    );
    return {
      ...value,
      description: newDescription,
    };
  };
  const newRows = Array.isArray(rows) ? rows.map(convertNotesDescriptionToHTML) : [];
  return (
    <>
      {customer ? (
        <Grid container spacing={2}>
          <CssBaseline />
          <Grid container item xs={6} alignItems="center">
            <Typography variant="h1" color="primary">
              {" "}
              Customer
            </Typography>
            <Box color="white" sx={{ pr: 1 }}></Box>
            <DoubleArrowIcon />
            <Box color="white" sx={{ pl: 1 }}></Box>

            <Typography variant="h1" color="primary">
              Customer Details
            </Typography>
          </Grid>
          <Grid container item xs={6} alignItems="center" justifyContent={"flex-end"}>
            {branch.autoPayEnabled && <Grid container item xs={8} justifyContent={"flex-end"}>
              <FormControlLabel
                control={
                  <Switch
                    checked={customer.autoPayDisabled || false}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCustomer({
                        ...customer,
                        autoPayDisabled: event.target.checked
                      })
                    }}
                    name="autoPayDisabled"
                    value="autoPayDisabled"
                  />
                }
                label={<Typography style={{ display: "inline", marginLeft: "0.5rem" }}>{"Disable Invoice Auto Pay"}</Typography>}
                labelPlacement="bottom"
              />
            </Grid>}
            {user ?
              <Grid container item xs={2} justifyContent={"flex-end"}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={user?.active || false}
                      onChange={(e: any) => {
                        setOpenUserStatusDialog(true);
                      }}
                      name="disablePortalAccess"
                      value="disablePortalAccess"
                    />
                  }
                  label={<Typography style={{ display: "inline", marginLeft: "0.5rem" }}>{!user?.active ? "Activate" : "Active"}</Typography>}
                  labelPlacement="bottom"
                />
              </Grid> : <></>
            }
          </Grid>
          <Grid container item xs={12}>
            <Paper className={classes.paper}>
              <Grid container alignItems="center" item xs={12} spacing={2}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12} sm={2} style={{ position: "relative" }}>
                    {profilePic ? (
                      <div style={{ width: "100px" }}>
                        <img
                          src={profilePic}
                          style={{ width: "100%", borderRadius: "50%" }}
                        />
                      </div>
                    ) : (
                      <Avatar className={classes.orange}>
                        {customer.firstName.charAt(0).toUpperCase() +
                          customer.lastName.charAt(0).toUpperCase()}
                      </Avatar>
                    )}

                    <Button
                      onClick={() => setProfileDialogVisible(true)}
                      style={{
                        position: "absolute",
                        marginTop: "-30px",
                        marginLeft: "50px",
                        background: "transparent"
                      }}
                    >
                      <div
                        style={{
                          background: " #eceeec",
                          padding: "4px",
                          borderRadius: "50%"
                        }}
                      >
                        <Tooltip title={"change profile pic"}>
                          <CameraAltIcon />
                        </Tooltip>
                      </div>
                    </Button>
                  </Grid>

                  <Grid
                    container
                    xs={12}
                    sm={
                      userState.currentOrganisation.address.country ===
                        "United States"
                        ? 7
                        : 6
                    }
                    style={{ paddingBottom: "0.5rem" }}
                  >
                    <table
                      className="borderedTable"
                      style={{ width: "100%" }}
                    >
                      <tr>
                        <td className={classes.greyColored}>Customer Name</td>
                        <td>
                          {customer.firstName + " " + customer.lastName}
                        </td>
                        <td className={classes.greyColored}>
                          Driving Licence No.
                        </td>
                        <td>
                          {customer?.license?.licenseNumber ? (
                            <>
                              <span>{customer?.license?.licenseNumber}</span>
                              {organisation.address.country ===
                                "United Kingdom" && (
                                  <span
                                    className={classes.linkText}
                                    onClick={() => setDvlaDialogVisible(true)}
                                  >
                                    Verify now
                                  </span>
                                )}
                            </>
                          ) : (
                            "Not Available"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.greyColored}>Email Address</td>
                        <td>{customer.email}</td>
                        <td className={classes.greyColored}>Issued By</td>
                        <td>
                          {customer?.license?.authority || "Not Available"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.greyColored}>Phone</td>
                        <td>{customer.phoneNumber.phone}</td>
                        <td className={classes.greyColored}>
                          Licence Expiry Date
                        </td>
                        <td>
                          {customer?.license?.dateOfExpiry
                            ? getLocalizedDateFormat(
                              country,
                              customer?.license?.dateOfExpiry,
                              DATE_TYPE.CONDENSED
                            )
                            : "Not Available"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.greyColored}>
                          Available Credit
                        </td>
                        <td>
                          {toCurrency(creditNoteBalance, currency, locale)}
                        </td>
                        <td className={classes.greyColored}>
                          {userState.currentOrganisation
                            .requiresCustomerVerification
                            ? "Customer Verification Status"
                            : ""}
                        </td>
                        {userState.currentOrganisation
                          .requiresCustomerVerification && (
                            <td>
                              <span
                                style={{
                                  color:
                                    customer.status ===
                                      CustomerVerificationStatus.APPROVED
                                      ? "green"
                                      : "red"
                                }}
                              >
                                {customer.status ===
                                  CustomerVerificationStatus.IN_REVIEW
                                  ? "IN REVIEW"
                                  : customer.status}
                                {customer.verifiedDate &&
                                  ` on ${getLocalizedDateFormat(
                                    country,
                                    customer.verifiedDate,
                                    DATE_TYPE.CONDENSED
                                  )}`}
                              </span>
                            </td>
                          )}
                      </tr>
                    </table>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    sm={
                      userState.currentOrganisation.address.country ===
                        "United States"
                        ? 3
                        : 2
                    }
                    style={{ paddingLeft: "0.5rem", paddingBottom: "0.5rem" }}
                  >
                    <table
                      className="borderedTable"
                      style={{ width: "100%" }}
                    >
                      <tr style={{ height: "30px" }}>
                        <th>Driving Licence Images</th>
                      </tr>
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: "none"
                          }}
                        >
                          {licenseImages && licenseImages.length > 0 ? (
                            licenseImages.map((image) => {
                              const imageContainer =
                                userState.role === UserRoles.ADMIN ||
                                  userState.role === UserRoles.SUPER_ADMIN
                                  ? classes.imageContainer
                                  : classes.imageContainer2;
                              const imageStyle =
                                userState.role === UserRoles.ADMIN ||
                                  userState.role === UserRoles.SUPER_ADMIN
                                  ? classes.imageStyle
                                  : classes.imageStyle2;
                              return (
                                <React.Fragment>
                                  <div className={imageContainer}>
                                    <a href={image.document} target="_blank">
                                      <img
                                        src={image.document}
                                        className={imageStyle}
                                        alt=""
                                      />
                                    </a>
                                    {(userState.role === UserRoles.ADMIN ||
                                      userState.role ===
                                      UserRoles.SUPER_ADMIN ||
                                      userState.role ===
                                      UserRoles.ORGANISATION_OWNER ||
                                      userState.role ===
                                      UserRoles.BRANCH_MANAGER) && (
                                        <div style={{ textAlign: "center" }}>
                                          <a
                                            style={{
                                              color: "var(--theme-primary)"
                                            }}
                                            onClick={() => {
                                              setDeleteDocumentKey(
                                                image.documentKey
                                              );
                                              return setOpenDeleteDialog(true);
                                            }}
                                          >
                                            <DeleteIcon
                                              style={{
                                                paddingBottom: "5px",
                                                fontSize: "22px",
                                                cursor: "pointer"
                                              }}
                                            />
                                          </a>
                                        </div>
                                      )}
                                  </div>
                                </React.Fragment>
                              );
                            })
                          ) : (
                            <div>
                              <h3>Not Available</h3>
                            </div>
                          )}
                        </td>
                      </tr>
                    </table>
                  </Grid>
                  {userState.currentOrganisation.address.country !==
                    "United States" && (
                      <Grid container xs={12} sm={2} style={{ paddingLeft: "0.5rem", paddingBottom: "0.5rem" }}>
                        <table
                          className="borderedTable"
                          style={{ width: "100%" }}
                        >
                          <tr style={{ height: "30px" }}>
                            <th colSpan={2}>
                              <Grid container>
                                <Grid container xs={10} style={{ justifyContent: "center" }} alignItems="center">
                                  Endorsement
                                </Grid>
                                <Grid container xs={2} style={{ justifyContent: "flex-end" }}>
                                  <IconButton
                                    color="secondary"
                                    aria-label="edit emdorsements"
                                    onClick={() => { setEndorsementEdit(!endorsementEdit) }}
                                  >
                                    {endorsementEdit ? <DoneIcon /> : <Edit />}
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </th>
                          </tr>
                          <tr>
                            <td>
                              <Grid container>
                                {endorsementEdit ? (
                                  <Grid container xs={12} style={{ justifyContent: "center" }}>
                                    <TextField
                                      value={customer.license.offences?.toString() || '0'}
                                      className={classes.endorsementsTextfields}
                                      hiddenLabel
                                      type="number"
                                      name="offences"
                                      onChange={saveEndorsements}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid container xs={12} style={{ justifyContent: "center" }}>
                                    <Typography variant="h1">{customer.license.offences || 0}</Typography>
                                  </Grid>
                                )}
                                <Grid container xs={12} style={{ justifyContent: "center" }}>
                                  <Typography>Offences</Typography>
                                </Grid>
                              </Grid>
                            </td>
                            <td>
                              <Grid container>
                                {endorsementEdit ? (
                                  <Grid container xs={12} style={{ justifyContent: "center" }}>
                                    <TextField
                                      value={customer.license.points?.toString() || '0'}
                                      className={classes.endorsementsTextfields}
                                      name="points"
                                      hiddenLabel
                                      type="number"
                                      onChange={saveEndorsements}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid container xs={12} style={{ justifyContent: "center" }}>
                                    <Typography variant="h1">{customer.license.points || 0}</Typography>
                                  </Grid>
                                )}
                                <Grid container xs={12} style={{ justifyContent: "center" }}>
                                  <Typography>Points</Typography>
                                </Grid>
                              </Grid>
                            </td>
                          </tr>
                        </table>
                      </Grid>
                    )}
                </Grid>
                <Grid container item xs={12} spacing={2}>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    justifyContent={"flex-end"}
                  >
                    {userState.currentTenancy.idvEnabled && (
                      <Fab
                        variant="extended"
                        size="medium"
                        style={{ color: "white", marginRight: 10, marginBottom: 10 }}
                        onClick={() => {
                          customer.credasIdentityVerification
                            ? setOpenIDVerificationDialog(true)
                            : handleIdvCheck();
                        }}
                      >
                        IDENTITY VERIFICATION
                      </Fab>
                    )}
                    {userState.currentTenancy.creditCheckEnabled && (
                      <Fab
                        variant="extended"
                        size="medium"
                        style={{ color: "white", marginRight: 10 }}
                        onClick={() => {
                          if (!customer.creditSafeData) {
                            getCreditScore();
                          }
                          setOpenCreditCheckDialog(true);
                        }}
                      >
                        CREDIT CHECK
                      </Fab>
                    )}

                    {requiresCustomerVerification && (
                      <>
                        {(customer.status ===
                          CustomerVerificationStatus.PENDING ||
                          customer.status ===
                          CustomerVerificationStatus.IN_REVIEW ||
                          customer.status ===
                          CustomerVerificationStatus.DECLINED) && (
                            <Fab
                              variant="extended"
                              size="medium"
                              style={{
                                backgroundColor: "#009951",
                                color: "white",
                                marginRight: 10
                              }}
                              onClick={() => {
                                if (customer.id) {
                                  setOpenVerificationDialog(true);
                                  setStatus(CustomerVerificationStatus.APPROVED);
                                }
                              }}
                            >
                              APPROVE
                            </Fab>
                          )}
                        {(customer.status ===
                          CustomerVerificationStatus.PENDING ||
                          customer.status ===
                          CustomerVerificationStatus.IN_REVIEW ||
                          customer.status ===
                          CustomerVerificationStatus.APPROVED) && (
                            <Fab
                              variant="extended"
                              size="medium"
                              style={{ backgroundColor: "red", color: "white" }}
                              onClick={() => {
                                if (customer.id) {
                                  setOpenVerificationDialog(true);
                                  setStatus(CustomerVerificationStatus.DECLINED);
                                }
                              }}
                            >
                              DECLINE
                            </Fab>
                          )}
                      </>
                    )}
                    {tenancy.loginEnabled && (
                      <Fab
                        variant="extended"
                        size="medium"
                        style={{
                          color: "white",
                          marginLeft: 10,
                          marginBottom: 10
                        }}
                        disabled={
                          customer.credentialSent || Boolean(customer.credentialSentOn)
                        }
                        onClick={() => {
                          if (customer.id) {
                            sendLoginCredential({
                              variables: {
                                customerId: customer.id
                              }
                            });
                          }
                        }}
                      >
                        Send Credential
                      </Fab>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={"flex-end"}
                    style={{ paddingTop: "0" }}
                  >
                    {customer && customer.credentialSentOn && tenancy.loginEnabled && (
                      <div>
                        Credential sent on :
                        <b>
                          {getLocalizedDateFormat(
                            country,
                            customer.credentialSentOn,
                            DATE_TYPE.CONDENSED
                          )}
                        </b>
                      </div>
                    )}
                  </Grid>

                  <Grid container item xs={12}>
                    <Typography variant={"h2"}>CUSTOMER DETAILS</Typography>
                  </Grid>
                  {editable && (
                    <Grid container item xs={12}>
                      <CustomerUpdate
                        customer={customer}
                        onUpdate={onUpdateHandler}
                        uploadDocument={uploadDocument}
                        documentDialogVisible={documentDialogVisible}
                        uploadInProgress={uploadInProgress}
                        filesLimit={2}
                        handleClose={() => {
                          setDocumentDialogVisible(false);
                        }}
                        handleOpen={() => setDocumentDialogVisible(true)}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ marginTop: "10px" }}
                >
                  <Grid item container xs={12}>
                    <Typography variant="h3" gutterBottom>
                      NOTES
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      label="Note"
                      name={"note.description"}
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 750 }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setNote({
                          ...note,
                          description: event.target.value,
                          createdBy:
                            userState && userState.id ? userState.id : "",
                          createdDate: new Date().toISOString()
                        });
                      }}
                      value={note?.description}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Fab
                    variant="extended"
                    size="medium"
                    onClick={() => {
                      if (customer.id) {
                        addCustomerNote({
                          variables: {
                            customerId: customer.id,
                            note
                          }
                        });
                      }
                    }}
                    disabled={!note?.description}
                  >
                    Add
                  </Fab>
                </Grid>
                <Hidden mdUp>
                  <Grid container item xs={12}>
                    <Box className={classes.expansionPanel}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h3">Note History</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container xs={12} spacing={1}>
                            {rows.map((note: ICustomerNote, index: number) => {
                              return (
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    key={index}
                                    style={{
                                      background: "#f5f5f5",
                                      borderRadius: 5,
                                      padding: 20
                                    }}
                                  >
                                    <Grid container item xs={12}>
                                      <Grid item>
                                        <Typography variant="h1">
                                          #{index}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      style={{ marginTop: 10 }}
                                    >
                                      <Grid item>
                                        <Typography variant="h2">
                                          {getLocalizedDateFormat(
                                            country,
                                            note.createdDate,
                                            DATE_TYPE.CONDENSED
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      style={{ marginTop: 10 }}
                                    >
                                      <Grid item>
                                        <Typography variant="h6">
                                          {note.description}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Grid>
                </Hidden>
                <Hidden smDown>
                  <Grid container item xs={12}>
                    <Paper variant="outlined" className={classes.newPaper}>
                      <NuvvenTable
                        title=""
                        rows={newRows}
                        columns={noteColumns(country)}
                        options={options}
                        setSelection={() => { }}
                      />
                    </Paper>
                  </Grid>
                  <Grid container item xs={12}>
                    <CustomerDocuments
                      customer={customer}
                      onChange={onDocumentChange}
                    />
                  </Grid>
                </Hidden>
                <Grid container item xs={12}>
                  <CustomerBookingHistory customerId={customer.id} />
                </Grid>
                <Grid container item xs={12}>
                  <CustomerInvoiceHistory
                    customerId={customer.id}
                  />
                </Grid>
                <Grid container item xs={12}>
                  <CustomerCreditNoteHistory
                    customerId={customer.id}
                    updateCreditNoteBalance={setCreditNoteBalance}
                  />
                </Grid>
                <DragDropImages
                  title={"Upload Profile Image"}
                  open={profileDialogVisible}
                  handleCloseDragDrop={() => setProfileDialogVisible(false)}
                  handleSave={uploadProfilePic}
                  filesLimit={1}
                  initialFiles={profilePic}
                />
                <DvlaVerificationDialog
                  title={"Check Licence"}
                  description={
                    "Please validate or enter following details to proceed for licence check. All fields are mandatory"
                  }
                  isOpen={dvlaDialogVisible}
                  onCancel={() => {
                    setDvlaDialogVisible(false);
                    setDvlaVerificationStatus("");
                  }}
                  onConfirm={verifyLicenceWithDvla}
                  data={{
                    licenseNumber: customer?.license?.licenseNumber || "",
                    insuranceNumber: customer?.nationalInsuranceNumber || "",
                    postCode: customer?.location?.zipcode || ""
                  }}
                  verificationStatus={dvlaVerificationStatus}
                />
              </Grid>
              {openDeleteDialog && (
                <ConfirmationDialog
                  isOpen={openDeleteDialog}
                  onCancel={() => setOpenDeleteDialog(false)}
                  onConfirm={() => {
                    deleteDocument(deleteDocumentKey);
                  }}
                  isInProgress={deleteInProgress}
                  title=""
                  description={
                    "Are you sure, you want to delete this licence image?"
                  }
                />
              )}
              {openVerificationDialog && (
                <CustomerVerificationDialog
                  open={openVerificationDialog}
                  inProgress={inProgress}
                  status={status}
                  handleClose={() => {
                    setOpenVerificationDialog(false);
                  }}
                  handleSubmit={handleCustomerVerification}
                />
              )}
              {openCreditCheckDialog && (
                <CreditCheckDialog
                  open={openCreditCheckDialog}
                  handleClose={() => {
                    setOpenCreditCheckDialog(false);
                  }}
                  customer={customer}
                />
              )}
              {openIDVerificationDialog && (
                <IDVerificationDialog
                  open={openIDVerificationDialog}
                  handleClose={() => {
                    setOpenIDVerificationDialog(false);
                  }}
                  handleCustomerUpdate={() => {
                    getCustomer({
                      variables: {
                        customerId: customer.id
                      }
                    });
                  }}
                  customer={customer}
                />
              )}
              {openUserStatusDialog && (
                <ConfirmationDialog
                  isOpen={openUserStatusDialog}
                  onCancel={() => setOpenUserStatusDialog(false)}
                  onConfirm={() => {
                    if (user) {
                      updateUserStatus({
                        variables: {
                          userId: user.id,
                          isActive: !user.active
                        }
                      })
                    }
                  }}
                  isLoading={userLoading}
                  isInProgress={userLoading}
                  title=""
                  description={
                    user?.active ? "Are you sure, you want to deactivate this user?" : "Are you sure, you want to activate this user?"
                  }
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </>
  );
});

export default CustomerDetails;
