import { ICalendarEvent } from "../models/ICalendarEvent";
import {
  CalendarEventActionType,
  calendarEventAction
} from "./../actions/calendarEventsAction";

const INITIAL_STATE: ICalendarEventTypeState = {
  calendarEvent: [
    {
      id: 0,
      title: "John Doe",
      resourceId: 1,
      subject: "hello",
      customerName: "John Doe",
      taskOwner: "Tim Smith",
      type: "Appointment",
      priority: "last",
      description: `Promises are a hot topic in JavaScript development circles,
             and you should definitely get acquainted with them. They are not easy to wrap your head around;`,
      start: new Date(2019, 9, 10, 0, 0, 0),
      end: new Date(2019, 9, 10, 1, 0, 0)
    },
    {
      id: 1,
      title: "John Doe",
      resourceId: 2,
      type: "Task",
      subject: "hello",
      customerName: "John Doe",
      taskOwner: "Tim Smith",
      priority: "last",
      description: `Promises are a hot topic in JavaScript development circles, and you should definitely
            get acquainted with them. They are not easy to wrap your head around;`,
      start: new Date(2019, 9, 10, 1, 0, 0),
      end: new Date(2019, 9, 10, 2, 0, 0)
    },
    {
      id: 2,
      resourceId: 3,
      title: "John Doe",
      subject: "hello",
      type: "Event",
      customerName: "John Doe",
      taskOwner: "Tim Smith",
      priority: "last",
      description: `Booking 011672 PICK UP Jonathan DumphhreyHatchback - 6days`,
      start: new Date(2019, 9, 10, 2, 0, 0),
      end: new Date(2019, 9, 10, 3, 0, 0)
    }
  ],
  updateCalendarEvent: null,
  deleteCalendarEvent: {}
};

export interface ICalendarEventTypeState {
  calendarEvent: ICalendarEvent[];
  updateCalendarEvent: ICalendarEvent | null;
  deleteCalendarEvent: ICalendarEvent | object;
}

export default function(
  state: ICalendarEventTypeState = INITIAL_STATE,
  action: calendarEventAction
): ICalendarEventTypeState {
  switch (action.type) {
    case CalendarEventActionType.GET_CALENDAR_EVENT:
      return {
        ...state
      };
    case CalendarEventActionType.ADD_CALENDAR_EVENT:
      return {
        ...state,
        calendarEvent: [...state.calendarEvent, action.payload]
      };

    case CalendarEventActionType.UPDATE_CALENDAR_EVENT:
      state.calendarEvent.forEach((element, index) => {
        if (element.id === action.payload.id) {
          state.calendarEvent[index] = action.payload;
        }
      });
      return { ...state, updateCalendarEvent: action.payload };

    case CalendarEventActionType.DELETE_CALENDAR_EVENT:
      const filterEvents = state.calendarEvent.filter((val, i) => {
        return val.id !== action.payload.id;
      });
      return { ...state, calendarEvent: filterEvents };

    default:
      return state;
  }
}
