import { gql } from "@apollo/client";

export const GET_BUSINESS_CUSTOMER_CREDIT_NOTES = gql`
  query creditNotesByBusinessCustomerId($id: String!) {
    creditNotesByBusinessCustomerId(id: $id) {
      id
      creditNoteRef
      createdAt
      invoice {
        id
        invoiceRef
      }
      totalAmount
      refundedAmount
      availableAmount
      creditNoteItems {
        type
        description
        originalPrice
        quantity
        unitPrice
        totalAmount
        duration
      }
      refunds {
        mode
        amount
        status
        id
        refundRef
        currency
        paymentIntent
      }
      allocations {
        amount
        allocatedAt
        invoice {
          invoiceRef
        }
      }
    }
  }
`;
