import { gql } from "@apollo/client";

export const GET_ALL_SMART_CARS = gql`
  query getAllSmartCars {
    getAllSmartCars {
      vehicle {
        id
        licencePlate
        make
        model
        year
        branchId
      }
    }
  }
`;
