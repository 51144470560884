import { gql } from "@apollo/client";

export const UPDATE_PROCUREMENT_PURCHASE_ORDER = gql`
  mutation updateProcurementPurchaseOrder($id: ID!, $purchaseOrderId: ID!, $purchaseOrder: ProcurementPurchaseOrderInput!) {
    updateProcurementPurchaseOrder(id: $id, purchaseOrderId: $purchaseOrderId, purchaseOrder: $purchaseOrder) {
      purchaseOrder {
        id
        purchaseOrderId
        date
        partnerId
        quoteId
        validUntilDate
        lastEmailSent
        partner {
          partnerName
          email
        }
        note {
          description
          # createdBy {
          #   firstName
          #   lastName
          # }
          createdAt
        }
        items {
          requestId
          vehicleMake
          vehicleModel
          vehicleVarient
          vehicleFuel
          vehicleTransmission
          vehicleCondition
          vehicleCount
          vehicleExpectedByDate
          documents
        }
      }
      financialInformation {
        id
        purchaseOrderId
        purchaseOrderDate
        partnerId
        invoiceId
        invoiceDate
        totalAmount
        expectedArrivalDate
        advancedPayment
        paymentSource
        documents
        partner {
          id
          partnerName
          email
        }
      }
    }
  }
`;