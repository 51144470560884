import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Typography
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { BOOKING_SOURCE } from "../../../reducers/bookings/types";
import { BOOKING_STATUS } from "../../views/ReservationManagement/Summary/const";
import { BookingStatus } from "../../views/ReservationManagement/utils";
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";
import { DATE_TYPE } from "../utils";
import { IAppState } from "../../../store";
import { useSelector } from "react-redux";
interface ILicencePlateDetails {
  externalFleet: boolean;
  branchName: string;
  licencePlates: string[];
}

export const MobileBookingCard = ({
  id,
  referenceNumber,
  bookingStatus,
  customerName,
  pickupDateTime,
  dropoffDateTime,
  location,
  source,
  licencePlateDetails
}: {
  id: string,
  referenceNumber: string,
  bookingStatus: string,
  customerName: string,
  pickupDateTime: string,
  dropoffDateTime: string,
  location: string,
  source: string,
  licencePlateDetails: ILicencePlateDetails,
  imgUrls?: string[],
  bodyTypes?: string[]
}) => {
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  function routeTo() {
    if (bookingStatus === BOOKING_STATUS.QUOTE) {
      navigate(`/update-booking?booking=${id}&step=3`);
    } else {
      navigate(`/view-booking?booking=${id}`);
    }
  }

  const borderColour = bookingStatus === BOOKING_STATUS.CANCELLED ? "rgba(255, 0, 0, 1)" :
    bookingStatus === BOOKING_STATUS.CONFIRMED ? "rgba(0, 128, 0, 1)" :
      bookingStatus === BOOKING_STATUS.COMPLETED ? "rgba(0, 128, 0, 1)" :
        bookingStatus === BOOKING_STATUS.IN_PROGRESS ? "rgba(255, 128, 0, 1)" :
          "rgba(0, 0, 0, 1)";

  const getBookingSource = (value: string) => {
    if (value === BOOKING_SOURCE.B2B) {
      return "Web App"
    } else if (value === BOOKING_SOURCE.B2B2C) {
      return "Website"
    } else if (value === BOOKING_SOURCE.B2B2C_MOBILE) {
      return "Mobile App"
    }
  }

  const getBookingStatus = (value: string) => {
    if (value === BookingStatus.IN_PROGRESS) {
      return "IN PROGRESS"
    } else {
      return value;
    }
  }
  return (
    <Grid item xs={12}>
      <ButtonBase onClick={() => routeTo()}>
        <Card style={{
          border: "1px solid",
          borderColor: `${borderColour}`,
          borderRadius: "3%",
          minHeight: 300,
          minWidth: 'calc(100vw - 60px)',
          maxWidth: 'calc(100vw - 60px)'
        }}>
          <Box style={{ position: "relative" }}>
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                padding: "0px"
              }}
            >
              <CardContent>
                <Typography variant="h2" style={{ fontWeight: "bold" }}>
                  {customerName}
                </Typography>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Booking ID: {referenceNumber}
                </Typography>
                <hr
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    color: "rgba(0, 0, 0, 0.1)",
                    height: 1
                  }}
                />
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Pickup Date&Time:
                </Typography>
                <Typography variant="body2" gutterBottom style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  {getLocalizedDateFormat(country, pickupDateTime, DATE_TYPE.EXPANDED)}
                </Typography>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Drop off Date&Time:
                </Typography>
                <Typography variant="body2" gutterBottom style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  {getLocalizedDateFormat(country, dropoffDateTime, DATE_TYPE.EXPANDED)}
                </Typography>
                <Typography variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Pickup location:
                </Typography>
                <Typography variant="body2" gutterBottom style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  {location}
                </Typography>
                <Typography variant="body2" gutterBottom style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Channel:
                  &nbsp;
                  <Typography display="inline" variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                    {getBookingSource(source)}
                  </Typography>
                </Typography>
                <Typography gutterBottom variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                  Status:
                  &nbsp;
                  <Typography display="inline" variant="body2" style={{ color: `${borderColour}` }}>
                    {getBookingStatus(bookingStatus)}
                  </Typography>
                </Typography>
                {bookingStatus !== BookingStatus.CANCELLED && licencePlateDetails.licencePlates.length > 0 && (
                  <Typography gutterBottom variant="body2" style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                    LicensePlates:
                    &nbsp;
                    <Typography display="inline" variant="body2" style={{ color: `${borderColour}` }}>
                      {
                        licencePlateDetails.licencePlates.length > 0 &&
                        licencePlateDetails.licencePlates.join(", ")
                      }
                    </Typography>
                  </Typography>
                )}
                {licencePlateDetails && licencePlateDetails.externalFleet && (
                  <Typography display="inline" variant="body2" style={{ color: `${borderColour}` }}>
                    {`Shared Fleet - ${licencePlateDetails.branchName}`}
                  </Typography>
                )}
              </CardContent>
            </Box>
          </Box>
        </Card>
      </ButtonBase>
    </Grid>
  );
};

export default MobileBookingCard;
