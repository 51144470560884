import { gql } from "@apollo/client";

export const SEARCH_DEFLEET_VEHICLES = gql`
  query searchDeFleetVehicles($q: String!) {
    searchDeFleetVehicles(q: $q) {
      id
      licencePlate
      residualValue
      deFleetReason
      deFleetParameters {
        byDate 
        byDistanceDriven
        alertBeforeDistance
        alertBeforeDays
      }
      vehicleState {
        id
        odometerReading
      }  
    }
  }
`;