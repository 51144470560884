import { gql } from "@apollo/client";

export const GET_VEHICLE_SCHEDULES_BY_DATE = gql`
  query vehicleEvents(
    $startDate: String!
    $endDate: String!
    $limit: Int!
    $offset: Int!
    $status: String!
    $vehicleGroupId: String
    $searchText: String
    $filterType: String
    $filterStatus: String
  ) {
    vehicleEvents(
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      offset: $offset
      status: $status
      vehicleGroupId: $vehicleGroupId
      searchText: $searchText
      filterType: $filterType
      filterStatus: $filterStatus
    ) {
      id
      imageUrl
      bodyType
      licencePlate
      isGhostVehicle
      make
      model
      year
      status
      pcoNumber
      activeTransferMovement
      currentServiceLocation {
        id
        name
      }
      bookingSchedules {
        id
        vehicleGroup
        vehicleStatus
        tba
        booking {
          id
          customer {
            firstName
            lastName
          }
          businessCustomer {
            id
            businessName
          }
          rateTypeName
          rateTypeDuration
          currentBookingSchedules {
            id
          }
          referenceNumber
          pickupDateTime
          dropoffDateTime
          customerType
          status
          organisationId
          branchId
          pickupServiceLocation {
            id
          }
          longTermBooking
        }
        status
        startDate
        endDate
        vehicle {
          id
        }
      }
      services {
        id
        status
        startDate
        endDate
        completionDate
        serviceType
        branchId
      }
      holds {
        id
        startDate
        endDate
        notes {
          description
          dateCreated
          createdBy {
            firstName
            lastName
          }
        }
        dateCreated
        createdBy {
          firstName
          lastName
        }
      }
      branchId
      currentBranchId
    }
  }
`;
