import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Typography, Grid, Fab, CircularProgress } from "@mui/material";
import FlatPickerBar from "../../../common/FlatPicker";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { GENERATE_EOD_BANKING_REPORT_QUERY } from "../../../../graphql/reports/generateEODBankingReportQuery";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage } from "../../../common/utils";
import { ApolloError } from "@apollo/client";
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { DateTime as d } from "luxon";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";

interface IProps {
  active: boolean
}

const BankingReport: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address
  const [fromDate, setFromDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [reportId, setReportId] = useState("");
  const [intervalId, setIntervalId] = useState<any>();

  const [generateEODBankingReport] = useLazyQuery(GENERATE_EOD_BANKING_REPORT_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: async (data) => {
      if (data && data.generateBankingReport) {
        const { success, key, id } = data.generateBankingReport
        setReportId(id);
        if (success && key) {
          const fileName = `EOD-Banking-Report-${getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}.pdf`;
          const file = await getSignedUrl(key);
          const url: any = file;
          fetch(url, {
            method: "GET"
          })
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              a.remove();
              setLoading(false);
              setReportId("")
            });
        }
      } else {
        setLoading(false);
        setReportId("");
        snackbar({
          message: "No report is available for this day",
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onError: (error: ApolloError) => {
      setLoading(false);
      setReportId("");
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (reportId) {
        generateEODBankingReport({
          variables: {
            fromDate,
            id: reportId,
          }
        })
      }
    }, 15000);
    setIntervalId(interval);
    return () => clearInterval(interval);
  }, [reportId]);


  const generateReport = () => {
    setLoading(true)
    generateEODBankingReport({
      variables: {
        fromDate,
        reportId,
      }
    })
  }

  return (
    <div style={{ display: props.active ? 'block' : 'none' }}>
      <Grid container>
        <Typography variant={"h2"}>End of the Day Banking</Typography>
        <Grid container xs={12} spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={3}>
            <FlatPickerBar
              enableTime={false}
              handleDateChange={(value: Date) => {
                const timeZoneOffset = d.now().offset;
                const isPositiveOffset = timeZoneOffset >= 0;
                if (isPositiveOffset) {
                  setFromDate(d.fromJSDate(value).toUTC().plus({minutes: timeZoneOffset}).toISO());
                } else {
                  setFromDate(d.fromJSDate(value).toUTC().minus({minutes: timeZoneOffset}).toISO());
                }
              }}
              label={"Date"}
              placeholderValue={"Select From Date"}
              value={fromDate ? getLocalizedDateFormat(country, fromDate, DATE_TYPE.CONDENSED) : ""}
              maxDate={getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED)}
              disabled={loading}
              country={country}
            />
          </Grid>
          <Grid item xs={3}>
            <Fab
              variant={"extended"}
              size="medium"
              disabled={loading || !fromDate}
              onClick={generateReport}
            >
              Generate Report
            </Fab>
          </Grid>
        </Grid>
        {loading &&
          <><Grid container xs={12} style={{ marginTop: "1rem" }}>
            <Grid item><CircularProgress size={16} style={{ color: "white", marginRight: "10px" }} /></Grid>
            <Grid item xs={6}> <Typography variant={"h3"}>Generating Report, please wait </Typography> </Grid>
          </Grid>
            <div style={{ marginLeft: "26px" }}>Please do not refresh the page or click on browser back button</div>
          </>
        }
      </Grid>
    </div>
  )
}

export default BankingReport;

