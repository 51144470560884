import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import React from "react";

interface IProps {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  description: string;
  title: string;
}

export const ConfirmationDialog: React.FC<IProps> = (props) => {
  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Typography variant="h2" color="primary">
          {props.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant={"h4"}>{props.description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={props.onConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
