export default {
  styleOverrides: {
    root: {
      "&.MuiFab-extended.MuiFab-sizeMedium": {
        backgroundColor: "var(--theme-primary)",
        boxShadow: "none",
        color: "#fff",
        fontWeight: 500,
        letterSpacing: 1.25,
        padding: "10px 16px",
        fontSize: 14,
        "&:hover": {
          backgroundColor: "var(--theme-primary-dark)"
        },
        "&.blackBackButton": {
          background: "#404040",
          "&:hover": {
            background: "#292929"
          }
        },
        "&.interiorImageButton": {
          background: "#FFFFFF",
          border: "2px solid var(--theme-primary)",
          color: "var(--theme-primary)",
          opacity: "100%",
          fontWeight: "bold"
        },
        "&.imageUploadButton": {
          background: "#FFFFFF",
          border: "2px solid var(--theme-primary)",
          color: "var(--theme-primary)",
          opacity: "100%",
          fontWeight: "bold"
        },
        "&.documentUploadButton": {
          background: "#FFFFFF",
          border: "2px solid var(--theme-primary)",
          color: "var(--theme-primary)",
          opacity: "100%",
          fontWeight: "bold"
        },
        "&.greyButton": {
          color: "#000",
          background: "#D5D5D5",
          "&:hover": {
            background: "#C1C1C1",
            color: "#000"
          }
        },
        "&.Mui-disabled.Mui-disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.18)",
          color: "rgba(0, 0, 0, 0.26)"
        }
      },
      "&.MuiFab-extended.MuiFab-sizeSmall": {
        backgroundColor: "var(--theme-primary)",
        boxShadow: "none",
        color: "#fff",
        fontWeight: 500,
        letterSpacing: 1.25,
        padding: "10px 16px",
        fontSize: 14,
        textTransform: "initial",
        "&:hover": {
          backgroundColor: "var(--theme-primary-dark)"
        },
        "&.Mui-disabled.Mui-disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.18)",
          color: "rgba(0, 0, 0, 0.26)"
        },
        "&.greyButton": {
          color: "#000",
          background: "#D5D5D5",
          "&:hover": {
            background: "#C1C1C1",
            color: "#000"
          }
        },
        "&.blackBackButton": {
          background: "#404040",
          "&:hover": {
            background: "#292929"
          }
        },
        "&.redButton": {
          background: "#ffe3e2",
          color: "#da1616",
          "&:hover": {
            background: "#ffd9d7"
          }
        }
      },
      borderRadius: "4px !important"
    }
  }
};
