import { gql } from "@apollo/client";

export const CREATE_VEHICLE = gql`
  mutation createVehicle($vehicle: CreateVehicleInput!) {
    createVehicle(vehicle: $vehicle) {
      isGhostVehicle
      insurerCode
      airConditioner
      assetTag
      brakeHorsePower
      bluetooth
      bodyType
      vehicleUniqueCode
      vehicleUniqueId
      co2Emissions
      colour
      cylinderCapacity
      description
      fuelType
      imported
      infoSource
      insuranceGroup
      licencePlate
      v5c
      emmissionType
      ulezComplaint
      ukVehicle
      vin
      make
      model
      manufactureDate
      year
      registrationDate
      vehicleTest
      vehicleTestDetails
      vehicleTestDueDate
      numberOfDoors
      numberOfPreviousOwners
      numberOfSeats
      premium
      price
      msrp
      grossVehicleWeight
      teltonikaDeviceId
      telemetryDevicesData {
        status
        medium
        disabledAt
        enabledAt
        enabledBy
        disabledBy
        active
        telemetryDeviceId
        deviceId
        latitude
        longitude
      }
      satNav
      sixMonthRate
      status
      steering
      taxDetails
      taxed
      taxStatus
      telemetryDeviceId
      transmission
      twelveMonthRate
      typeApproval
      tyreFitments
      wheelPlan
      acrissCode
      branch
      canTransfer
      branchDetails {
        id
        name
      }
      imageUrl
      deleted
      id
      interiorColour
      pcoNumber
      notes {
        id
        description
        createdBy
        createdDate
      }
      batteries {
        voltage
        capacity
        coldTestCurrent
        length
        width
        height
        postPositions
        terminalType
        holdDownType
      }
      frontTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      rearTyres {
        width
        ratio
        rim
        speedRating
        psi
        loadIndex
      }
      vehicleTestReports {
        testDate
        expiryDate
        testResult
        odometerReading
        odometerUnit
        motTestNumber
        advisoryItems
        minorItems
        failureItems
      }
      vehicleDocuments {
        title
        documentName
        documentType
        expiryDate
        url
        createdAt
      }
      features
      vehicleState {
        exterior {
          front {
            description
            images
          }
          rear {
            description
            images
          }
          top {
            description
            images
          }
          right {
            description
            images
          }
          left {
            description
            images
          }
        }
        interior {
          frontSeat {
            clean
            controls
            seatBelt
          }
          rearSeat {
            clean
            controls
            seatBelt
          }
          lockAndSwitch {
            frontDoor
            rearDoor
            frontWindow
            rearWindow
            childLock
          }
          images
        }
        generalChecks {
          name
          status
          images
        }
        defaultAccesories {
          name
          status
          images
        }
        bookedAccesories {
          name
          type
          quantity
          description
        }
        tyre {
          frontLeft {
            condition
            pressure
            description
          }
          frontRight {
            condition
            pressure
            description
          }
          rearLeft {
            condition
            pressure
            description
          }
          rearRight {
            condition
            pressure
            description
          }
        }
        odometerReading
        fuelStatus
      }
      frogDiagram
      dtcAlertSupported
    }
  }
`;
