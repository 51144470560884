import { gql } from "@apollo/client";

export const ADD_PROCUREMENT_PURCHASE_ORDER = gql`
  mutation addProcurementPurchaseOrder($id: ID!, $purchaseOrder: ProcurementPurchaseOrderInput!) {
    addProcurementPurchaseOrder(id: $id, purchaseOrder: $purchaseOrder) {
      id
      purchaseOrderId
      date
      partnerId
      quoteId
      validUntilDate
      lastEmailSent
      note {
        description
        # createdBy {
        #   id
        # }
        createdAt
      }
      items {
        vehicleMake
        vehicleModel
        vehicleVarient
        vehicleFuel
        vehicleTransmission
        vehicleCondition
        vehicleCount
        vehicleExpectedByDate
        documents
      }
    }
  }
`;