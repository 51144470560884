import "./index.scss"
import { Box, CssBaseline, TextField as InputField, Grid, MenuItem, Paper, Theme, Typography, FormControlLabel, Fab, CircularProgress, Switch } from '@mui/material'
import { useLazyQuery, useMutation } from '@apollo/client';
import { createStyles, makeStyles } from "@mui/styles";
import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react'
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from "yup";
import { IInsurancePolicy, ISubscriptionInput } from '../../../../../reducers/Subscription/types';
import { subscriptionInitialState } from '../../../../../reducers/Subscription/const';
import { IAppState } from '../../../../../store';
import { GET_ADDONS_TYPES_FOR_SUBSCRIPTION } from '../../../../../graphql/SubscriptionPlans/getAddonsQuery';
import { IAddonType } from '../../../../../reducers/addonType/types';
import { GET_INSURANCE_POLICIES_FOR_SUBSCRIPTION } from '../../../../../graphql/SubscriptionPlans/getInsurancePoliciesQuery';
import Autocomplete from '@mui/material/Autocomplete';
import { GET_SUBSCRIPTION } from "../../../../../graphql/SubscriptionPlans/getSubscriptionQuery";
import { ApolloError } from "@apollo/client";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { CREATE_SUBSCRIPTION } from "../../../../../graphql/SubscriptionPlans/createSubscriptionMutation";
import { UPDATE_SUBSCRIPTION } from "../../../../../graphql/SubscriptionPlans/updateSubscriptionMutation";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { CHANGE_SUBSCRIPTION_STATUS } from "../../../../../graphql/SubscriptionPlans/changesSubscriptionStatusMutation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      flexGrow: 1,
      marginTop: theme.spacing(1)
    },
  })
);

const NewSubscriptionPlan: React.FC = () => {

  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const location = useLocation();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<ISubscriptionInput>(subscriptionInitialState);
  const [insurancePolicies, setInsurancePolicies] = useState<IInsurancePolicy[]>([]);
  const [addonTypes, setAddonTypes] = useState<IAddonType[] | string[]>([]);
  const [selectedAddons, setSelectedAddons] = useState<any[]>([]);
  const [toggleUpdateStatus, setToggleUpdateStatus] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const prevBranchRef = useRef(userState.currentBranch);

  const [getInsurancePolicies, { loading: insurancePoliciesLoading, data: insurancePoliciesData }] = useLazyQuery(
    GET_INSURANCE_POLICIES_FOR_SUBSCRIPTION,
    {
      fetchPolicy: "network-only"
    }
  );

  const [getAddonsTypes, { loading: addonTypesLoading, data: addonTypesData }] = useLazyQuery(
    GET_ADDONS_TYPES_FOR_SUBSCRIPTION,
    {
      fetchPolicy: "network-only"
    }
  );

  const [loadSubscription, { loading: subscriptionLoading, data: subscriptionData }] = useLazyQuery(GET_SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data.getVehicleSubscription) {
        navigate("/subscriptions");
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createSubscriptionMutation, { loading: createSubscriptionLoading }] = useMutation(CREATE_SUBSCRIPTION, {
    onCompleted: () => {
      snackbar({
        message: formatGraphQLErrorMessage("Subscription plan created"),
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/subscriptions");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });

    },
  });

  const [updateSubscriptionMutation] = useMutation(UPDATE_SUBSCRIPTION, {
    onCompleted: () => {
      snackbar({
        message: formatGraphQLErrorMessage("Subscription plan updated successfully"),
        variant: SnackBarVariant.SUCCESS
      });
      navigate("/subscriptions");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
  });

  const [changesSubscriptionStatus] = useMutation(CHANGE_SUBSCRIPTION_STATUS, {
    onCompleted: (data) => {
      if (data && data.updateSubscriptionStatus) {
        setSubscription({
          ...subscription,
          isActive: data.updateSubscriptionStatus.isActive
        })
        setToggleUpdateStatus(false)
      }
      snackbar({
        message: formatGraphQLErrorMessage("Subscription status changed successfully"),
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });

    },
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const subscriptionId = params.get("id");
      if (subscriptionId) {
        setIsUpdate(true)
        loadSubscription({
          variables: {
            id: subscriptionId
          }
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (prevBranchRef.current !== userState.currentBranch) {
      navigate("/subscriptions");
      prevBranchRef.current = userState.currentBranch;
    }
  }, [userState.currentBranch]);

  useEffect(() => {
    if (subscriptionData &&
      subscriptionData.getVehicleSubscription
    ) {
      if (subscriptionData.getVehicleSubscription.insurancePolicy && subscriptionData.getVehicleSubscription.insurancePolicy.id) {
        subscriptionData.getVehicleSubscription.insurancePolicy = subscriptionData.getVehicleSubscription.insurancePolicy.id
      }
      let pickedAddons = []
      let features = []
      if (subscriptionData.getVehicleSubscription.addons && subscriptionData.getVehicleSubscription.addons.length) {
        pickedAddons = subscriptionData.getVehicleSubscription.addons
      }
      if (subscriptionData.getVehicleSubscription.features && subscriptionData.getVehicleSubscription.features.length) {
        features = subscriptionData.getVehicleSubscription.features
      }
      if (pickedAddons.length || features.length) {
        setSelectedAddons([...pickedAddons, ...features])
      }
      setSubscription(subscriptionData.getVehicleSubscription)
    }
  }, [subscriptionData]);

  useEffect(() => {
    getInsurancePolicies({
      variables: { organisationId: String(userState.currentOrganisation.id) }
    });
  }, [userState]);

  useEffect(() => {
    getAddonsTypes();
  }, []);

  useEffect(() => {
    if (insurancePoliciesData && insurancePoliciesData.insurancePolicies) {
      const filterActivatedInsurancePolicies = insurancePoliciesData.insurancePolicies.filter((insurancePolicy: IInsurancePolicy) => insurancePolicy.isActivated)
      setInsurancePolicies(filterActivatedInsurancePolicies)
    }
  }, [insurancePoliciesData]);

  useEffect(() => {
    if (addonTypesData && addonTypesData.addonTypes) {
      const filterActivatedAddons = addonTypesData.addonTypes.filter((addon: IAddonType) => addon.isActivated)
      setAddonTypes(filterActivatedAddons)
    }
  }, [addonTypesData]);


  const detailsFormSchema = Yup.object().shape({
    name: Yup.string().required("Subscription Name is required.")
  });

  const onSubmitForm = (values: ISubscriptionInput) => {
    const { vehicles, ...rest } = values;
    if (values.id) {
      updateSubscriptionMutation({
        variables: {
          id: values.id,
          subscription: rest
        }
      })
    } else {
      createSubscriptionMutation({
        variables: {
          subscription: rest
        }
      })
    }
  }

  const onChangeSubscriptionStatus = (isActive: boolean) => {
    changesSubscriptionStatus({
      variables: {
        id: subscription.id,
        isActive
      }
    })
  }

  if (addonTypesLoading || insurancePoliciesLoading || subscriptionLoading) {
    return <CircularProgress />
  }

  return (
    <>
      <Grid container spacing={2}>
        <CssBaseline />
        <Grid container item xs={12} alignItems="center">
          <Typography variant="h1" color="primary">
            {" "}
            Subscription{"  "}
          </Typography>
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon />
          <Box color="white" sx={{ pl: 1 }}></Box>
          <Typography variant="h1" color="primary">
            &nbsp;{isUpdate ? "Update" : "New"} Subscription
          </Typography>
        </Grid>
      </Grid>
      <Paper elevation={0} className={classes.root}>
        <Formik
          enableReinitialize
          initialValues={subscription}
          onSubmit={(values, { setSubmitting }) => {
            const addons: string[] = [];
            const features: string[] = []
            if (selectedAddons.length) {
              for (let i = 0; i < selectedAddons.length; i++) {
                const el = selectedAddons[i];
                if (el.id) {
                  addons.push(el.id)
                } else if (typeof el === "string") {
                  features.push(el)
                }
              }
            }
            values.addons = addons
            values.features = features
            onSubmitForm(values)
            setSubmitting(false)
          }}
          validationSchema={detailsFormSchema}
        >
          {(props) => (
            <Form>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}><Typography variant='h2'>SUBSCRIPTION DETAILS</Typography></Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                  {
                    isUpdate && (
                      <FormControlLabel
                        style={{ marginLeft: "105px" }}
                        control={
                          <Switch
                            checked={props.values.isActive ? true : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setToggleUpdateStatus(true)
                            }}
                            name="isActive"
                            color="primary"
                          />
                        }
                        label={
                          <Typography variant="body1">
                            {props.values.isActive
                              ? "Active"
                              : "In-Active"}
                          </Typography>
                        }
                      />
                    )
                  }
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    component={TextField}
                    name="name"
                    label="Subscription Name"
                    fullWidth
                    required
                    inputProps={{
                      value: props.values.name,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setSubscription({
                          ...props.values,
                          name: e.target.value
                        })
                      }
                    }}
                  ></Field>
                </Grid>
                <Grid item xs={12} md={3}>
                <Field
                    component={TextField}
                    select
                    label={"Type"}
                    name={"rateTypeName"}
                    fullWidth
                    variant={"outlined"}
                    required
                    inputProps={{
                      value: props.values.rateTypeName,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setSubscription({
                          ...props.values,
                          rateTypeName: e.target.value
                        })
                      }
                    }}
                  >
                    <MenuItem key="weekly" value="weekly">
                      Weekly
                    </MenuItem>
                    <MenuItem key="monthly" value="monthly">
                      Monthly
                    </MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12}><Typography variant='h2'>SET SUBSCRIPTION  WITH RULES AND FEATURES</Typography></Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    component={TextField}
                    select
                    label={"Insurance"}
                    name={"insurancePolicy"}
                    fullWidth
                    variant={"outlined"}
                    inputProps={{
                      value: props.values.insurancePolicy,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setSubscription({
                          ...props.values,
                          insurancePolicy: e.target.value
                        })
                      }
                    }}
                  >
                    <MenuItem value="">
                      None
                    </MenuItem>
                    {
                      insurancePolicies.map(insurancePolicy => {
                        return (
                          <MenuItem key={insurancePolicy.id} value={insurancePolicy.id}>
                            {insurancePolicy.name}
                          </MenuItem>
                        )
                      })
                    }
                  </Field>
                </Grid>
                <Grid item xs={12} md={6} className="subscription-tag">
                  <Autocomplete
                    multiple
                    freeSolo
                    id="free-solo-addons-features-search"
                    disableClearable
                    options={[...addonTypes]}
                    getOptionLabel={(option: any) => {
                      if (option.displayName) {
                        return option.displayName
                      } else {
                        return option
                      }

                    }}
                    filterSelectedOptions
                    onChange={(_: any, newValues: any) => {
                      setSelectedAddons(newValues)
                    }}
                    value={selectedAddons}
                    defaultValue={[]}
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        label="Add Add-ons And Features "
                        variant="outlined"
                        placeholder={"Select add-ons"}
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    multiline
                    label="Subscription terms of service and privacy policy"
                    name="subscriptionTnC"
                    rows={4}
                    fullWidth
                    inputProps={{
                      value: props.values.subscriptionTnC,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setSubscription({
                          ...props.values,
                          subscriptionTnC: e.target.value
                        })
                      }
                    }}
                  >
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="submit"
                    type="submit"
                    disabled={createSubscriptionLoading}
                  >
                    {createSubscriptionLoading && (
                      <CircularProgress
                        size={14}
                        style={{ color: "white", marginRight: "10px" }}
                      />
                    )}
                    Save
                  </Fab>
                </Grid>
              </Grid>
              {toggleUpdateStatus && (
                <ConfirmationDialog
                  isOpen={toggleUpdateStatus}
                  onCancel={() => setToggleUpdateStatus(false)}
                  onConfirm={() => onChangeSubscriptionStatus(!props.values.isActive)}
                  title=""
                  description={`Are you sure, you want to ${props.values.isActive ? "de-activate" : "activate"} the subscription ?`}
                />
              )}
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  )
}

export default NewSubscriptionPlan