export enum NavBarActionType {
  GET_NAVBAR_ITEM = "GET_NAVBAR_ITEM",
  IS_NAVBAR_OPEN = "IS_NAVBAR_OPEN",
  UPDATE_LIST_EXPANDED = "UPDATE_LIST_EXPANDED"
}

interface GetNavbarItem {
  type: typeof NavBarActionType.GET_NAVBAR_ITEM;
  payload: string;
}

interface IsNavbarOpen {
  type: typeof NavBarActionType.IS_NAVBAR_OPEN;
  payload: boolean;
}
interface updateListItem {
  type: typeof NavBarActionType.UPDATE_LIST_EXPANDED;
  payload: string;
}

export function GetNavbarItem(payload: string): NavBarAction {
  return {
    payload,
    type: NavBarActionType.GET_NAVBAR_ITEM
  };
}

export function IsNavbarOpen(payload: boolean): NavBarAction {
  return {
    payload,
    type: NavBarActionType.IS_NAVBAR_OPEN
  };
}
export function updateListExpanded(payload: string): NavBarAction {
  return {
    payload,
    type: NavBarActionType.UPDATE_LIST_EXPANDED
  };
}

export type NavBarAction = GetNavbarItem | IsNavbarOpen | updateListItem;
