import React, { useState, ChangeEvent, KeyboardEvent, ClipboardEvent, useEffect } from "react";
import './index.scss';
import { Dialog, DialogContent, DialogContentText, Fab, Theme, IconButton, Typography, Chip } from "@mui/material";
import { constent } from "./Constent";
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import { useSnackBar } from "../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../SnackbarWrapper/SnackbarWrapper";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon className="notes-close-icon" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface IProps {
  documentType: string;
  title?: string;
  open: boolean;
  handleClose: () => void;
  mails: (emails: string[]) => void;
}

const MailSendBox: React.FC<IProps> = (props) => {
  const [emails, setMails] = useState<string[]>([]);
  const snackBar = useSnackBar();
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (emails.length > 4) {
      snackBar({
        message: "Max 4 emails allowed at a time",
        variant: SnackBarVariant.ERROR
      });
      setMails(emails.slice(0, 4));
    }
  }, [emails]);

  const handleKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      const trimmedValue = value.trim();
      if (trimmedValue && isEmailValid(trimmedValue)) {
        setMails([...emails, value]);
        setValue("");
      }
    }
  };

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
    setError(null);
  };

  const handleDelete = (item: string) => {
    setMails(emails.filter((i) => i !== item));
  };

  const handlePaste = (evt: ClipboardEvent<HTMLInputElement>) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData("text");
    const pastedEmails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (pastedEmails) {
      const toBeAdded = pastedEmails.filter((email) => !isInList(email));

      setMails([...emails, ...toBeAdded]);
    }
  };

  const isEmailValid = (email: string) => {
    let errorMessage: string | null = null;

    if (isInList(email)) {
      errorMessage = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      errorMessage = `${email} is not a valid email address.`;
    }

    if (errorMessage) {
      setError(errorMessage);
      return false;
    }

    return true;
  };

  const isInList = (email: string) => {
    return emails.includes(email);
  };

  const isEmail = (email: string) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose} >
          {" "}
          <Typography variant="h3" >
            {props.title ? props.title : `Send ${props.documentType} To Customer`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textTransform: "capitalize" }} >{constent.LEVEL} ( Max 4 )</DialogContentText>
          {emails.map((item) => {
            return (
              <Chip
                style={{ margin: "5px" }}
                key={item}
                color="primary"
                label={item}
                onDelete={(event) => {
                  handleDelete(item)
                }}
              />
            )
          })}
          <input
            type="email"
            className={`input ${error ? "has-error" : ""}`}
            value={value}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onPaste={handlePaste}
          />
          {error && <Typography color="error">{error}</Typography>}
          <br />
          <br />
          <Fab
            variant="extended"
            size="medium"
            aria-label="sendmail"
            className="createButton"
            style={{ marginRight: 10 }}
            onClick={() => {
              props.mails(emails);
              props.handleClose();
            }}
          >
            {constent.SEND_BUTTON}
          </Fab>
          <Fab
            variant="extended"
            size="medium"
            aria-label="close"
            className="greyButton"
            onClick={() => {
              setMails([]);
              setValue("")
            }}
          >
            {constent.CANCLE_BUTTON}
          </Fab>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MailSendBox;
