import React, { useEffect, useState } from 'react'
import { Grid, CssBaseline, Typography, Box, Fab, IconButton, CircularProgress } from '@mui/material';
import { NuvvenTable } from '../../common/NuvvenTable/NuvvenTable';
import { MUIDataTableOptions } from 'mui-datatables';
import { useNavigate } from "react-router-dom";
import { GET_EXPENSES_COUNT, GET_FUEL_EXPENSE_TABLE, SEARCH_EXPENSE } from '../../../graphql/fleet/getAllVehicleExpenses';
import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { SelectableRows } from '../../common/NuvvenTable/types';
import { DATE_TYPE, checkDeleteOrDownloadFileType, toCurrency, formatGraphQLErrorMessage } from '../../common/utils';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { getSignedUrl } from '../../../utils/getSignedUrl';
import { getLocalizedDateFormat } from '../../../utils/localized.syntex';
import { ApolloError } from '@apollo/client';
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import _ from 'lodash';
import { addTableState } from '../../../actions/tableState/actions';
import { ITable, TableNames } from '../../../reducers/tableState/types';
import { ExpenseAssetType, ExpenseAssetTypeValue, ExpenseType, ExpenseTypeValue, IExpense } from './expenses.types';

export const FuelExpenses: React.FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const dispatch = useDispatch();
  const [rows, setRows] = useState<IExpense[]>([]);
  const user = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = user.currentOrganisation;
  const fuelExpenseTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.fuelExpense
  );
  const [limit, setLimit] = useState(10);
  const [totalFuelExpensesCount, setTotalFuelExpensesCount] = useState<number>(0);
  const [fuelExpenseTable, setFuelExpensesTable] = useState<ITable>();

  const [loadExpenses, { loading, data: expenseData }] = useLazyQuery(GET_FUEL_EXPENSE_TABLE, {
    fetchPolicy: "network-only",
    onCompleted: (expenseData) => {
      if (expenseData?.fuelExpensesTable) {
        const newExpenses = expenseData.fuelExpensesTable.map((expense: any) => reshapeFuelExpensesIntoRows(expense))
        const oldExpenses = [...rows]
        newExpenses.forEach((expense: any) => {
          oldExpenses.push(expense)
        });
        setRows(oldExpenses)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [getfuelExpensesCount, { data: fuelExpensesCountData }] = useLazyQuery(
    GET_EXPENSES_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (fuelExpensesCountData) => setTotalFuelExpensesCount(fuelExpensesCountData.fuelExpensesCount)
    }
  );

  const [searchfuelExpenses, { loading: searchfuelExpensesLoading, data: searchfuelExpensesData }] = useLazyQuery(
    SEARCH_EXPENSE,
    {
      fetchPolicy: "network-only",
      onCompleted: (searchfuelExpensesData) => {
        if (searchfuelExpensesData && searchfuelExpensesData.searchFuelExpenses) {
          const newExpenses = searchfuelExpensesData.searchFuelExpenses.map((expense: any) => reshapeFuelExpensesIntoRows(expense))
          setTotalFuelExpensesCount(newExpenses.length)
          setRows(newExpenses)
        }
      }
    }
  );
  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchfuelExpenses({
        variables: {
          q: searchText
        }
      })
    }
  }, 1000);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;

  useEffect(() => {
    if (user.tenancy) {
      getfuelExpensesCount();
      loadExpenses({
        variables: {
          limit: fuelExpenseTableState?.rowsPerPage ? fuelExpenseTableState.rowsPerPage : limit,
          offset: 0
        }
      })
    }
  }, [user])

  function reshapeFuelExpensesIntoRows(expense: any) {
    let vehicleId = expense.vehicle && expense.vehicle.id ? expense.vehicle.id : "";
    let licencePlate = expense.vehicle && expense.vehicle.licencePlate ? expense.vehicle.licencePlate : "";
    let addonName = expense.addon && expense.addon.displayName ? expense.addon.displayName : "";
    return {
      ...expense,
      vehicleId,
      licencePlate,
      addonName
    }
  }

  useEffect(() => {
    if (fuelExpenseTableState) {
      setFuelExpensesTable(fuelExpenseTableState);
      loadExpenses({
        variables: {
          offset: rows.length,
          limit: fuelExpenseTableState.rowsPerPage || limit
        }
      })
    }
  }, [fuelExpenseTableState]);


  async function downloadDocument(documentUrl: string, title: string) {
    if (!userState.tenancy?.id) {
      return;
    }
    const { fileExtension } = checkDeleteOrDownloadFileType(
      documentUrl
    );
    const file = await getSignedUrl(documentUrl)
    const url: any = file;
    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileExtension ? `${title}.${fileExtension}` : title;;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: totalFuelExpensesCount,
    rowsPerPageOptions: [10, 20, 100],
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: (loading || searchfuelExpensesLoading) ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: any = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/update-expense?expense=${row.id}`);
      }
    },
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText)
      } else {
        setRows([])
        getfuelExpensesCount();
        loadExpenses({
          variables: {
            offset: 0,
            limit
          }
        });
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.FUEL_EXPENSES,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      fuelExpenseTable && fuelExpenseTable.rowsPerPage
        ? fuelExpenseTable.rowsPerPage
        : limit,

    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return; // Skip executing changePage if there is a search text
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length) {
            loadExpenses({
              variables: {
                offset: page * rowsPerPage,
                limit: rowsPerPage
              }
            });
          }
          break;
        case "changeRowsPerPage":
          setRows([])
          loadExpenses({
            variables: {
              offset: 0,
              limit: tableState.rowsPerPage || limit
            }
          });
          break;
        default:
          break;
      }
    },
  };
  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        print: false
      }
    },
    {
      label: "Asset Type",
      name: 'assetType',
      options: {
        customBodyRender: (value: ExpenseAssetType) => ExpenseAssetTypeValue[value]
      },
    },
    {
      label: "Registration Number",
      name: "licencePlate",
    },
    {
      label: "Expense Date",
      name: "expenseDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED)
      }
    },
    {
      label: "Expense Type",
      name: 'expenseType',
      options: {
        customBodyRender: (value: ExpenseType) => ExpenseTypeValue[value]
      },
    },
    {
      label: "Amount",
      name: "amount",
      options: {
        customBodyRender: (value: any) => toCurrency(value, currency, locale)
      }
    },
    {
      label: "Vendor",
      name: "vendor",
    },
    {
      label: "Document",
      name: "document",
      options: {
        filter: false,
        print: false,
        download: false,
        customBodyRender: (value: any) => {
          if (value?.length) {
            return <>
              <IconButton
                onClick={(e: any) => {
                  e.stopPropagation();
                  downloadDocument(value[0].url, value[0].documentName)
                }}
                size="large">
                <CloudDownloadIcon />
              </IconButton>
            </>;
          }
        }
      }
    }
  ];

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6} alignItems="center">
        <Typography variant="h1" color="primary">
          Expenses
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="add"
          className="addButton"
          onClick={() => {
            navigate("/new-expense")
          }}
        >
          Add New
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        <NuvvenTable
          title=""
          rows={rows}
          columns={columns}
          options={options}
          setSelection={() => { }}
        />
      </Grid>
    </Grid>
  )
}

export default FuelExpenses;