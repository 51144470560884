import { gql } from "@apollo/client";

export const GET_VEHICLE = gql`
  query Vehicle($id: String!) {
    vehicle(id: $id) {
        id
      bodyType
      make
      model
      year
      licencePlate
      transmission
      colour
      steering
      fuelType
      features
      wheelPlan
      cylinderCapacity
      numberOfSeats
      numberOfDoors
    }
  }
`;
