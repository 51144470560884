import React from "react";
import "./index.scss";

interface IProps {
    history: any;
}

export default class Renewals extends React.Component<any> {
    render() {
        return (
            <div className="renewals-container">
                <main className="content">
                    <h1> Renewals</h1>
                </main>
            </div>
        )
    }
}