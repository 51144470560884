import { Grid, Theme } from "@mui/material";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { createStyles, makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";

import { isWhitespaceString } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textArea: {
      "& .MuiOutlinedInput-notchedOutline": {
        minHeight: 120
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(15px, 1px) scale(0.75)"
      }
    },
    tagsChip: {
      borderRadius: 4,
      background: "#FAFAFA",
      border: "1px solid #E8E8E8",
      "&.MuiAutocomplete-tag": {
        margin: "10px 0px 10px 10px"
      }
    },
    hover: {
      padding: 2,
      "&:hover": {
        background: "red"
      }
    },
    root: {
      minWidth: 240,
      width: 375,
      maxWidth: 600,
      "& > * + *": {
        marginTop: theme.spacing(3)
      }
    }
  })
);
interface ITagsProps {
  label: string;
  handleTags(tags: string[]): void;
  customTags: boolean;
  values: string[];
  features: string[];
  columnLarge: any;
  placeHolder?: string;
  singleLine?: boolean;
  freeSoloDisabled?: boolean;
  onEnterPress?: string;
}
export const NuvvenAutoCompleteTags = (props: ITagsProps) => {
  const classes = useStyles();
  return (
    <Grid container item xs={12} md={6} lg={props.columnLarge} spacing={2}>
      <Grid item container>
        <div style={{ width: "100%", minHeight: props.singleLine ? 0 : 120 }}>
          <Autocomplete
            multiple
            clearIcon={true} // FIXME: fix this warning.
            id="fixed-tags-demo"
            value={props.values}
            freeSolo={props.freeSoloDisabled ? false : true}
            onChange={(event, newValue) => {
              if (!isWhitespaceString(newValue[newValue.length - 1])) {
                props.handleTags([...newValue]);
              }
            }}
            options={props.features}
            getOptionLabel={(option) => option}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  classes={{ root: classes.tagsChip }}
                  label={option}
                  deleteIcon={<ClearIcon style={{ fontSize: 14 }} />}
                  {...getTagProps({ index })}
                />
              ))
            }
            onKeyDown={(event) => {
              if (event.key === props.onEnterPress) {
                event.preventDefault();
              }
            }}
            renderInput={(params) => (
              <TextField
                className={!props.singleLine ? classes.textArea : ""}
                multiline={props.singleLine ? false : true}
                {...params}
                label={props.placeHolder || "Select Features"}
                variant="outlined"
                placeholder={props.placeHolder || "Select Features"}
              />
            )}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default NuvvenAutoCompleteTags;
