import { useLazyQuery, useMutation } from "@apollo/client";
import { AppBar, CircularProgress, Paper, Tab, Tabs, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { updateOrganisation } from "../../../../../../actions/organisation/actions";
import { GET_BRANCHES } from "../../../../../../graphql/organisation/getBranch";
import { GET_ORGANISATION } from "../../../../../../graphql/organisation/getOrganisationQuery";
import { DEFAULT_RENTAL_AGREEMENT_SETTINGS, DEFAULT_SUBSCRIPTION_AGREEMENT_SETTINGS, DepositCollection, initialState } from "../../../../../../reducers/organisation/const";
import {
  IBranch,
  IOrganisation,
  IServiceLocation
} from "../../../../../../reducers/organisation/types";
import { TabPanel } from "../../../../../common/TabPannel/TabPannel";
import Branch from "../Branch";
import Organisation from "../NewOrganisationStructure";
import ServiceLocation from "../ServiceLocation";
import { useSnackBar } from '../../../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../../common/SnackbarWrapper/SnackbarWrapper';
import { ApolloError } from '@apollo/client';
import { formatGraphQLErrorMessage } from '../../../../../common/utils';
import { AUTH_SAGE_ACCOUNTING } from '../../../../../../graphql/organisation/authSageAccounting';
import { IAppState } from '../../../../../../store';
import RefundsPolicy from "../RefundsPolicy";
import { getLocalizedOrganisationSyntex } from "../../../../../../utils/localized.syntex";
import { Value } from "react-phone-number-input";
import { BOOKING_CATEGORY } from "../../../../../../reducers/bookings/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      flexGrow: 1,
      [theme.breakpoints.down('md')]: {
        width: "calc(100vw - 45px)",
      },
      [theme.breakpoints.up('sm')]: {
        width: "calc(100vw - 245px)",
      },
      overflowX: "hidden"

    },
  })
);

const branch: IBranch = {
  address: {
    city: "",
    country: "",
    fullAddress: "",
    state: "",
    street: "",
    zipcode: ""
  },
  emailId: "",
  id: "",
  name: "",
  owner: {
    id: "",
    email: ""
  },
  phoneNumber: {
    phone: "" as Value,
    country: "GB"
  },
  timeZone: "Europe/London",
  operatingHours: [],
  minimumBookingAmount: {
    type: "FIXED",
    value: 0
  },
  overbookingAllowed: false,
  minBookingDurationOptIn: false,
  minBookingAmountOptIn: true,
  preBookingQuestions: [],
  deliveryQuestions: [],
  distanceUnit: "mile",
  netPaymentTerms: 0,
  dailyBillingCycle: 30,
  weeklyBillingCycle: 1,
  monthlyBillingCycle: 1,
  rentalAgreementSettings: DEFAULT_RENTAL_AGREEMENT_SETTINGS,
  subscriptionAgreementSettings: DEFAULT_SUBSCRIPTION_AGREEMENT_SETTINGS,
  vehiclePreparationTime: 15,
  carShareGracePeriodMins: 15,
  holidays: [],
  addTaxOptIn: false,
  taxes: [],
  depositCollection: DepositCollection.AT_BOOKING_CONFIRMATION,
  durationBasedPricingEnabled: false,
  corporateRateCardEnabled: false,
  isAdditionalDriverQuestionsEnabled: false,
  additionalDriverQuestions: [],
  dashboardReminder: 7,
  showPaymentMethodInvoice: false,
  paymentMethodString: "",
  subscriptionSettings: {
    creditCheckEnabled: false,
    dvlaVerificationEnabled: false,
    idvEnabled: false
  },
  autoPayEnabled: false,
  autoPayDays: [],
};

const serviceLocation: IServiceLocation = {
  address: {
    city: "",
    country: "",
    fullAddress: "",
    state: "",
    street: "",
    zipcode: ""
  },
  branch: "",
  id: "",
  name: "",
  addSurchargesOptIn: false,
  surcharges: [],
  isDisabled: false
};

interface IProps {
  isUpdate: boolean;
}

const TabView: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [organisation, setOrganisation] = useState<IOrganisation>(initialState.organisation);
  const [organisationId, setOrganisationId] = useState<string>('');
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address;
  const [branches, setBranches] = useState<IBranch[]>([]);
  const [value, setValue] = useState<Number>();

  const [getOrganisation, { data: organisationData }] = useLazyQuery(GET_ORGANISATION, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && !data.organisation) {
        navigate("/org-structure");
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [getBranches, { data: branchesData }] = useLazyQuery(GET_BRANCHES, {
    fetchPolicy: "network-only"
  });

  const [authSageAccounting] = useMutation(AUTH_SAGE_ACCOUNTING, {
    onCompleted: () => {
      snackbar({
        message: 'Sage account successfully connected',
        variant: SnackBarVariant.SUCCESS
      });
      navigate(`/update-organisation?organisation=${organisationId}&step=0`);
    },
    onError: () => {
      snackbar({
        message: 'Error connecting sage account, please try again.',
        variant: SnackBarVariant.ERROR
      })
      navigate(`/update-organisation?organisation=${organisationId}&step=0`);
    }
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const state = params.get('state');
      let organisationId = '';
      let step = '';
      if (state) {
        organisationId = state;
        step = '0';
      } else {
        organisationId = params.get("organisation") || '';
        step = params.get("step") || '';
      }
      setOrganisationId(organisationId);
      if (organisationId) {
        setValue(Number(step))
        getOrganisation({
          variables: {
            organisationId
          }
        });
      };
      const code = params.get('code');
      if (code && state) {
        authSageAccounting({
          variables: {
            tenantId: userReducer.tenancy.id,
            organisationId: state,
            code
          }
        });
      };
    } else {
      setValue(0);
    }
  }, [location]);

  useEffect(() => {
    if (organisationData && organisationData.organisation) {
      dispatch(updateOrganisation(organisationData.organisation));
      getBranches({
        variables: {
          tenancyId: userReducer.currentTenancy.id,
          organisationId: organisationData.organisation.id
        }
      });
      setOrganisation({
        address: organisationData.organisation.address
          ? organisationData.organisation.address
          : {
            city: "",
            country: "",
            fullAddress: "",
            state: "",
            street: "",
            zipcode: ""
          },
        branches: organisationData.organisation.branches ? organisationData.organisation.branches : [],
        currency: organisationData.organisation.currency ? organisationData.organisation.currency : "GBP",
        sepaEnabled: organisationData.organisation.sepaEnabled ? true : false,
        bacsEnabled: organisationData.organisation.bacsEnabled ? true : false,
        cashEnabled: organisationData.organisation.cashEnabled ? true : false,
        cardEnabled: organisationData.organisation.cardEnabled ? true : false,
        payOnCollectionEnabled: organisationData.organisation.payOnCollectionEnabled ? true : false,
        payByLinkEnabled: organisationData.organisation.payByLinkEnabled ? true : false,
        emailId: organisationData.organisation.emailId ? organisationData.organisation.emailId : "",
        id: organisationData.organisation.id ? organisationData.organisation.id : "",
        locale: organisationData.organisation.locale ? organisationData.organisation.locale : "en-GB",
        logoUrl: organisationData.organisation.logoUrl ? organisationData.organisation.logoUrl : "",
        name: organisationData.organisation.name ? organisationData.organisation.name : "",
        orgRegNumber: organisationData.organisation.orgRegNumber ? organisationData.organisation.orgRegNumber : "",
        orgVatNumber: organisationData.organisation.orgVatNumber ? organisationData.organisation.orgVatNumber : "",
        owner: organisationData.organisation.owner ? organisationData.organisation.owner.id : "",
        phoneNumber: organisationData.organisation.phoneNumber,
        rate: organisationData.organisation.taxes ? organisationData.organisation.taxes[0].rate : null,
        stripeAccountId: organisationData.organisation.stripeAccountId || "",
        termsAndConditionsUrl: organisationData.organisation.termsAndConditionsUrl
          ? organisationData.organisation.termsAndConditionsUrl
          : "",
        insuranceDeclaration: organisationData.organisation.insuranceDeclaration || "",
        cancellationPolicy: organisationData.organisation.cancellationPolicy || "",
        refundPolicy: organisationData.organisation.refundPolicy || {
          flatCharge: 0,
          refundRules: []
        },
        requiresCustomerVerification: organisationData.organisation.requiresCustomerVerification || false,
        requiresBookingApproval: organisationData.organisation.requiresBookingApproval || false,
        closeGroupSharingEnabled: organisationData.organisation.closeGroupSharingEnabled || false,
        allowCarSharing: organisationData.organisation.allowCarSharing || false,
        disableMapForCarShareVehicles: organisationData.organisation.disableMapForCarShareVehicles || false,
        inspectionCheckEnabled: organisationData.organisation.inspectionCheckEnabled || false,
        maxImagesPerDamage: organisationData.organisation.maxImagesPerDamage || 1,
        enableIdentityVerification: organisationData.organisation.enableIdentityVerification || false,
        enableAutoCreditCheck: organisationData.organisation.enableAutoCreditCheck || false,
        creditLimits: organisationData.organisation.creditLimits ||
          [
            { type: "AUTO_REJECT", lowerLimit: 0, upperLimit: 0 },
            { type: "APPROVAL_REQUIRED", lowerLimit: 0, upperLimit: 0 },
            { type: "AUTO_APPROVE", lowerLimit: 0, upperLimit: 0 }
          ],
        enableCostCenter: organisationData.organisation.enableCostCenter || false,
        costCenters: organisationData.organisation.costCenters || [],
        businessCustomerTags: organisationData.organisation.businessCustomerTags || [],
        allowPreSigningAgreement: organisationData.organisation.allowPreSigningAgreement || false,
        crossLocationBookingEnabled: organisationData.organisation.crossLocationBookingEnabled || false,
        convergeEnabled: organisationData.organisation.convergeEnabled || false,
        bookingCategories: organisationData.organisation.bookingCategories && organisationData.organisation.bookingCategories ||
          [
            { isActive: true, type: BOOKING_CATEGORY.RENTAL, default: true },
            { isActive: false, type: BOOKING_CATEGORY.SUBSCRIPTION, default: false },
            { isActive: false, type: BOOKING_CATEGORY.COURTESY_CAR, default: false },
            { isActive: false, type: BOOKING_CATEGORY.TEST_DRIVE, default: false }
          ]
      });
    }
  }, [organisationData]);

  useEffect(() => {
    if (branchesData && branchesData.branches) {
      setBranches(branchesData.branches);
    }
  }, [branchesData]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  const initiateSageOauth = () => {
    const url = `${process.env.REACT_APP_SAGE_ACCOUNTING_AUTH_URL || 'https://www.sageone.com/oauth2/auth/central?filter=apiv3.1'}&client_id=${process.env.REACT_APP_SAGE_ACCOUNTING_CLIENT_ID}&redirect_uri=${window.location.origin}/update-organisation&state=${organisation.id}&response_type=code&scopes=full_access`;
    window.location.href = url;
  }

  if (props.isUpdate && !organisation.name) {
    return <CircularProgress />;
  }

  return (
    <Paper className={classes.paper}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label={getLocalizedOrganisationSyntex(country)} />
          <Tab label="Branch" disabled={!organisation.id} />
          <Tab label="Service Location" disabled={!organisation.id} />
          <Tab label="Cancellation Policy" disabled={!organisation.id} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Organisation initiateSageOauth={initiateSageOauth} organisation={organisation} step={0} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Branch organisation={organisation} branch={branch} step={1} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ServiceLocation
          organisation={organisation}
          branches={branches}
          serviceLocation={serviceLocation}
          step={2}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <RefundsPolicy organisation={organisation} step={3} />
      </TabPanel>
    </Paper>
  );
};

export default TabView;
