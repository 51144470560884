import { gql } from "@apollo/client";

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation updateEmailTemplate(
    $templateType: String!
    $bodyHtmlString: String!
    $designJsonString: String!
    $disableAutoSend: Boolean
  ) {
    updateEmailTemplate(templateType: $templateType, bodyHtmlString: $bodyHtmlString, designJsonString: $designJsonString, disableAutoSend: $disableAutoSend) {
      templateType
      disableAutoSend
    }
  }
`;
