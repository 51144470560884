import { gql } from "@apollo/client";

export const GET_CUSTOMERS = gql`
    query limitCustomers($limit: Int!, $offset: Int!) {
      limitCustomers(limit: $limit, offset: $offset) {
        id
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
        status
        license {
          licenseNumber
          dateOfExpiry
        }
        approvedDrivers {
          id
          firstName
          lastName
          email
          phoneNumber {
            phone
            country
          }
          license {
            licenseNumber
            dateOfExpiry
            dateOfIssue
            authority
            images
          }
        }
        credasIdentityVerification {
          entityId
          processId
          lastUpdated
          verificationMethod
          verificationStatus
        }
        profilePicture
      }
  }
`;

export const GET_ALL_CUSTOMERS = gql`
    query customers {
      customers {
        id
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
        status
        license {
          licenseNumber
          dateOfExpiry
        }
        approvedDrivers {
          id
          firstName
          lastName
          email
          phoneNumber {
            phone
            country
          }
          license {
            licenseNumber
            dateOfExpiry
            dateOfIssue
            authority
            images
          }
        }
        credasIdentityVerification {
          entityId
          processId
          lastUpdated
          verificationMethod
          verificationStatus
        }
        profilePicture
      }
  }
`;
