import "./index.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Theme,
  Box,
  CircularProgress,
  CssBaseline,
  Fab,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  AppBar,
  InputBase,
  alpha,
  Button
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Display, FilterType, MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import papa from "papaparse";
import InfoIcon from "@mui/icons-material/Info";
import {
  clearBooking,
  updateBooking
} from "../../../../actions/bookings/actions";
import * as _ from "lodash";
import { addTableState } from "../../../../actions/tableState/actions";
import { GET_BOOKINGS } from "../../../../graphql/bookings/getBookingsQuery";
import {
  BookingStatus,
  BookingStatusText,
  BOOKING_SOURCE,
  IAddressLocation,
  IBooking,
  IBookingAdditionalInformation,
  IBookingAddonRequirement,
  IBookingBusinessCustomer,
  IBookingNote,
  IBookingQuestionInput,
  IBookingSchedule,
  IBookingVehicleGroup,
  ICreditNote,
  IDiscount,
  IInsurancePolicyBooking,
  ILocationSurchargeInput,
  INoteFromWebsite,
  IPaymentDetails,
  ITax,
  BOOKING_CATEGORY,
} from "../../../../reducers/bookings/types";
import { ITable, TableNames } from "../../../../reducers/tableState/types";
import { IAppState } from "../../../../store";
import { BOOKING_STATUS } from "../Summary/const";
import { NuvvenTable } from "./../../../common/NuvvenTable/NuvvenTable";
import {
  formatLicenceNumber,
  returnSortedOrder,
  setTableSortOrder,
  CsvUploadStatusType,
  ICsvUploadError,
  formatGraphQLErrorMessage,
  possibleCsvMimeTypes,
  bookingCsvHeaders,
  DATE_TYPE
} from "./../../../common/utils";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { ApolloError } from "@apollo/client";
import { UPLOAD_BOOKING } from "../../../../graphql/bookings/bookingUpload";
import { BulkCsvUploadDialog } from "../../../common/BulkCsvUploadDialog/BulkCsvUploadDialog";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { DateTime as d } from "luxon";
import RegistrationColumn from "./RegistrationColumn";
import { isArray } from "lodash";
import { IInvoice } from "../../../../reducers/invoices/types";
import { RATE_TYPES } from "../utils";
import { IAdminUser } from "../../../../reducers/adminUser/types";
import BookingVirtualScroller from "./BookingVirtualScroller";
import { GET_BOOKINGS_COUNT } from "../../../../graphql/invoices/getBookingsCountQuery";
import { SEARCH_BOOKINGS } from "../../../../graphql/bookings/searchBookingsQuery";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { GET_FILTERED_BOOKINGS } from "../../../../graphql/bookings/getFilteredBookingsQuery";
import { ErrorMessage } from "../../../../utils/getSignedUrl";
import { resetBranch } from "../../../../actions/organisation/actions";
import { captureErrorException } from "../../../../utils/sentry";
import { createStyles, makeStyles } from "@mui/styles";

const BOOKING_FILTER = {
  PICKUP: "pickup",
  RETURN: "return",
  BY_DATE: "by-date",
  VIRTUAL: "virtual",
  EXTERNAL_VEHICLE_BOOKINGS: "externalVehicleBookings"
};

const UseStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerMargin: {
      marginBottom: "120px"
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(100vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
      marginBottom: theme.spacing(1)
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    }
  })
);

interface IRegistrationNumberColumn {
  branchName: string;
  externalFleet: boolean;
  licencePlates: string[];
}
export interface IRows {
  id: string;
  licencePlateDetails: ILicencePlateDetails;
  dateReturned?: string;
  dateStarted?: string;
  firstInvoice?: IInvoice;
  currentInvoice?: IInvoice;
  finalInvoice?: IInvoice;
  pickupCheck?: any;
  dropoffCheck?: any;
  vehicle?: any;
  bodyTypes?: string[];
  imgUrls?: string[];
  rentalAgreementUrl?: string;
  vehicleConditionReportUrls?: string[];
  referenceNumber: string;
  dateCreated: string;
  dateUpdated: string;
  createdBy: string;
  updatedBy: IAdminUser;
  pickupServiceLocation: string;
  pickupOtherLocation?: IAddressLocation;
  pickupDateTime: string;
  dropoffServiceLocation: string;
  dropoffOtherLocation?: IAddressLocation;
  dropoffDateTime: string;
  extendedDropoffDateTime?: string;
  initialDropoffDateTime?: string;
  initialDropoffServiceLocation?: string;
  initialPickupDateTime?: string;
  initialPickupServiceLocation?: string;
  longTermBooking: boolean;
  flightNumber: string;
  insuranceRate: number;
  excess: number;
  insurancePolicy?: IInsurancePolicyBooking;
  customerType: string;
  discount: IDiscount;
  tax?: ITax[];
  rateTypeName: RATE_TYPES;
  rateTypeDuration: number;
  billingCycleName?: string;
  billingCycleDuration?: number;
  status: BookingStatus;
  organisationId: string;
  vehicleGroups: IBookingVehicleGroup[];
  addonRequirements: IBookingAddonRequirement[];
  customer: string;
  invoices: IInvoice[];
  activeInvoices?: IInvoice[];
  businessCustomer?: IBookingBusinessCustomer;
  authorizedSignatory?: string;
  approvedDrivers?: any;
  paymentDetails: IPaymentDetails;
  currentBookingSchedules: IBookingSchedule[];
  previousBookingSchedules: IBookingSchedule[];
  quoteUrl?: string;
  bookingConfirmationUrl?: string;
  notes: IBookingNote[];
  pcoNumber?: boolean;
  extensions?: [];
  latestExtension?: any;
  smartCarVehicle?: boolean;
  additionalInformation: IBookingAdditionalInformation;
  noteFromWebsite: INoteFromWebsite;
  creditNotes?: ICreditNote[];
  preBookingQuestions: IBookingQuestionInput[];
  deliveryQuestions: IBookingQuestionInput[];
  source: string;
  additionalTnc?: string;
  locationSurcharges: ILocationSurchargeInput[];
  poNumber?: string;
  branchId?: string;
  vehicleReplacement?: IBookingVehicleGroup[];
}

interface ILicencePlateDetails {
  externalFleet: boolean;
  branchName: string;
  licencePlates: string[];
}


interface IFilter {
  key: string;
  value: string;
}

export function Bookings() {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const organisation = userState.currentOrganisation;
  const { country } = organisation.address;
  const isCarSubscription = userState.currentTenancy.carSubscriptionEnabled
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const snackbar = useSnackBar();
  const [filtering, setIsFiltering] = useState<boolean>(false);
  const [currentFilters, setCurrentFilters] = useState<IFilter[]>([]);
  const [filterList, setFilterList] = useState<IFilter[]>([]);
  const [isVirtualVehicleBooking, setIsVirtualVehicleBooking] = useState<boolean>(false);
  const [externalVehicleBookingIds, setExternalVehicleBookingIds] = useState<string[]>([]);
  const [loadBookings, { loading, data }] = useLazyQuery(GET_BOOKINGS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.bookings) {
        let oldBookings = [...rows]
        let bookings = [...data.bookings];
        // FIXME: why not get sorted data from db itself
        [...bookings].sort((bookingX: IBooking, bookingY: IBooking) => {
          return d.fromISO(bookingY.dateUpdated).diff(d.fromISO(bookingX.dateUpdated)).valueOf();
        });

        if (day) {
          let filterDateTime = d.now()
          if (day === "tomorrow") {
            filterDateTime = filterDateTime.plus({ day: 1 });
          }
          else if (day !== "today") {
            filterDateTime = d.fromISO(day)
          }
          bookings = bookings.filter((booking: IBooking) => {
            if (type === BOOKING_FILTER.PICKUP || type === BOOKING_FILTER.RETURN) {
              return externalVehicleBookingIds.includes(booking.id);
            }
            else if (type === BOOKING_FILTER.EXTERNAL_VEHICLE_BOOKINGS) {
              return externalVehicleBookingIds.includes(booking.id);
            } else {
              return (
                booking && booking.dropoffDateTime && (d.fromISO(booking.dropoffDateTime).startOf('day').toUTC().toISO() === filterDateTime.startOf('day').toUTC().toISO()) &&
                booking.status === BOOKING_STATUS.IN_PROGRESS
              );
            }
          });
        }

        if (valet) {
          if (valetValue > 0) {
            if (type === BOOKING_FILTER.PICKUP) {
              bookings = bookings.filter((booking: IBooking) => {
                if (booking.pickupOtherLocation?.fullAddress) {
                  return true;
                }
              });
            }
            if (type === BOOKING_FILTER.RETURN) {
              bookings = bookings.filter((booking: IBooking) => {
                if (booking.dropoffOtherLocation?.fullAddress) {
                  return true;
                }
              });
            }
          } else {
            if (type === BOOKING_FILTER.PICKUP) {
              bookings = bookings.filter((booking: IBooking) => {
                if ((!booking.dropoffOtherLocation && !booking.pickupOtherLocation) || (booking.dropoffOtherLocation?.fullAddress)) {
                  return true;
                }
              });
            }
            if (type === BOOKING_FILTER.RETURN) {
              bookings = bookings.filter((booking: IBooking) => {
                if ((!booking.dropoffOtherLocation && !booking.pickupOtherLocation) || (booking.pickupOtherLocation?.fullAddress)) {
                  return true;
                }
              })
            }
          }
        }
        const bookingArr = bookings.map((booking: IBooking) =>
          reshapeBookingsIntoRows(booking)
        );
        bookingArr.forEach((booking: any) => {
          if (!oldBookings.some((item) => item.id === booking.id)) {
            oldBookings.push(booking)
          }
        });
        if (day) {
          setTotalBookingCount(oldBookings.length)
        }
        setRows(oldBookings);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [loadFilteredBookings, { loading: filterBookingsDataLoading, data: filteredBookingsData }] = useLazyQuery(GET_FILTERED_BOOKINGS, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (filteredBookingsData && filteredBookingsData?.filteredBookings) {

        let oldBookings = [...rows]
        const bookingsData = filteredBookingsData?.filteredBookings;
        let bookings = [];
        if (isVirtualVehicleBooking) {
          bookings = bookingsData.filter((booking: IBooking) => booking.currentBookingSchedules.some((schedule: IBookingSchedule) => schedule.vehicle.isGhostVehicle));
        } else {
          bookings = bookingsData;
        }

        // FIXME: why not get sorted data from db itself
        [...bookings].sort((bookingX: IBooking, bookingY: IBooking) => {
          return d.fromISO(bookingY.dateUpdated).diff(d.fromISO(bookingX.dateUpdated)).valueOf();
        });

        if (day) {
          let filterDateTime = d.now();
          if (day === "tomorrow") {
            filterDateTime = filterDateTime.plus({ day: 1 });
          }
          else if (day !== "today") {
            filterDateTime = d.fromISO(day);
          }
          bookings = bookings.filter((booking: IBooking) => {
            if (type === BOOKING_FILTER.BY_DATE) {
              return (
                (d.fromISO(booking.pickupDateTime).startOf('day').toUTC().toISO() === filterDateTime.startOf('day').toUTC().toISO()) &&
                (booking.status === BOOKING_STATUS.CONFIRMED || booking.status === BOOKING_STATUS.IN_PROGRESS || booking.status === BOOKING_STATUS.COMPLETED)
              );
            }
            if (type === BOOKING_FILTER.PICKUP || type === BOOKING_FILTER.RETURN) {
              return externalVehicleBookingIds.includes(booking.id);
            } else {
              return (
                booking && booking.dropoffDateTime && (d.fromISO(booking.dropoffDateTime).startOf('day').toUTC().toISO() === filterDateTime.startOf('day').toUTC().toISO()) &&
                booking.status === BOOKING_STATUS.IN_PROGRESS
              );
            }
          });
        }
        if (valet) {
          if (valetValue > 0) {
            if (type === BOOKING_FILTER.PICKUP) {
              bookings = bookings.filter((booking: IBooking) => {
                if (booking.pickupOtherLocation?.fullAddress) {
                  return true;
                }
              });
            }
            if (type === BOOKING_FILTER.RETURN) {
              bookings = bookings.filter((booking: IBooking) => {
                if (booking.dropoffOtherLocation?.fullAddress) {
                  return true;
                }
              });
            }
          } else {
            if (type === BOOKING_FILTER.PICKUP) {
              bookings = bookings.filter((booking: IBooking) => {
                if ((!booking.dropoffOtherLocation && !booking.pickupOtherLocation) || (booking.dropoffOtherLocation?.fullAddress)) {
                  return true;
                }
              });
            }
            if (type === BOOKING_FILTER.RETURN) {
              bookings = bookings.filter((booking: IBooking) => {
                if ((!booking.dropoffOtherLocation && !booking.pickupOtherLocation) || (booking.pickupOtherLocation?.fullAddress)) {
                  return true;
                }
              })
            }
          }
        }
        const bookingArr = bookings.map((booking: IBooking) =>
          reshapeBookingsIntoRows(booking)
        );
        bookingArr.forEach((booking: any) => {
          if (!oldBookings.some((item) => item.id === booking.id)) {
            oldBookings.push(booking)
          }
        });
        setRows(oldBookings);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  })


  const [day, setDay] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [valet, setValet] = useState<boolean>(false);
  const [valetValue, setValetValue] = useState<number>(0);
  const [selection, setSelection] = useState<any>([]);
  const [rows, setRows] = useState<IRows[]>([]);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [updateButtonVisible, setUpdateButtonVisible] = useState<boolean>(
    false
  );
  const [fileName, setFileName] = useState<string>("");
  const [fileValue, setFileValue] = useState<File | undefined>();
  const [uploadErrors, setUploadErrors] = useState<ICsvUploadError[]>([]);
  const [uploadStatus, setUploadStatus] = useState<number>(
    CsvUploadStatusType.DEFAULT
  );
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [bookingsTable, setbookingsTable] = useState<ITable>();
  const [totalBookingCount, setTotalBookingCount] = useState<number>(0);
  const [limit, setLimit] = useState(10);
  const prevBranchRef = useRef(userState.currentBranch);

  const bookingsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.bookings
  );

  const [getBookingsCount] = useLazyQuery(
    GET_BOOKINGS_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (bookingsCountData) => { setTotalBookingCount(bookingsCountData.bookingsCount) }
    }
  );

  const [searchBookings, { loading: searchBookingsLoading, data: searchBookingsData }] = useLazyQuery(
    SEARCH_BOOKINGS,
    {
      fetchPolicy: "network-only",
      onCompleted: (searchBookingsData) => {
        if (searchBookingsData && searchBookingsData.searchBookings) {
          const newBookings = searchBookingsData.searchBookings.map((booking: IBooking) =>
            reshapeBookingsIntoRows(booking)
          );
          setRows(newBookings);
        }
      }
    }
  );

  const filterVehicles: any = ["All"];
  const columns: any = (columnType: string, country: string, isCarSubscription?: boolean) => {
    return [
      {
        label: "ID",
        name: "referenceNumber",
        options: {
          filter: false
        }
      },
      {
        label: "Status",
        name: "status",
        options: {
          filter: true,
          customBodyRender: (value: BookingStatus) =>
            BookingStatusText[value],
          filterOptions: {
            names: [BookingStatusText.CANCELLED, BookingStatusText.COMPLETED, BookingStatusText.CONFIRMATION_IN_PROCESS, BookingStatusText.CONFIRMED, BookingStatusText.IN_PROGRESS, BookingStatusText.QUOTE, BookingStatusText.REQUIRES_APPROVAL]
          }
        }
      },
      {
        label: columnType === "bookings" ? "Customer" : "Signatory",
        name: columnType === "bookings" ? "customer" : "authorizedSignatoryName",
        options: {
          filter: false
        }
      },
      {
        label: "Pick-up Date & Time",
        name: "pickupDateTime",
        options: {
          filter: false,
          customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
        }
      },
      {
        label: "Pick-up Location",
        name: "pickupServiceLocation",
        options: {
          filter: false
        }
      },
      {
        label: "Drop-off Date & Time",
        name: "dropoffDateTime",
        options: {
          filter: false,
          customBodyRender: (value: string) =>
            value
              ? getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
              : ""
        }
      },
      {
        label: "Drop-off Location",
        name: "dropoffServiceLocation",
        options: {
          filter: false
        }
      },
      {
        label: "Channel",
        name: "source",
        options: {
          customBodyRender: (value: string) => {
            if (value === BOOKING_SOURCE.B2B) {
              return "Web App"
            } else if (value === BOOKING_SOURCE.B2B2C) {
              return "Website"
            } else if (value === BOOKING_SOURCE.B2B2C_MOBILE) {
              return "Mobile App"
            }
          },
          filterOptions: {
            names: ["Web App", "Website", "Mobile App"]
          }
        }
      },
      {
        label: "Booking Source",
        name: "bookingSource",
        options: {
          display: false,
          filter: false,
          viewColumns: false
        }
      },
      {
        label: "licencePlateStr",
        name: "licencePlateStr",
        options: {
          display: false,
          filter: false,
          viewColumns: false
        }
      },
      {
        label: "Customer Type",
        name: "customerType",
        options: {
          customBodyRender: (value: string) => {
            if (value) {
              return value.toUpperCase();
            }
          },
          filterOptions: {
            names: ["INDIVIDUAL", "BUSINESS"]
          }
        }
      }, {
        label: "TBA",
        name: "tbaData",
        options: {
          display: (bookingsTableState?.viewColumns?.tbaData === "true"),
          filterOptions: {
            names: ["-", "TBA"]
          }
        }
      },
      {
        label: `${getLocalizedBookingSyntex(country)} Category`,
        name: "bookingCategory",
        options: {
          display: (bookingsTableState?.viewColumns?.bookingCategory === "true"),
          filter: true,
          filterOptions: {
            names: ["RENTAL", "SUBSCRIPTION", "TEST DRIVE", "COURTESY CAR"]
          }
        }
      },
      {
        label: "Created By",
        name: "bookingUser",
        options: {
          filter: false,
          customBodyRender: (value: string) => {
            return value
          }
        }
      },
      {
        label: "Registration Number",
        name: "licencePlateDetails",
        options: {
          filterType: "custom",
          filter: false,
          names: ["Registration number"],
          customBodyRender: (value: any) => {
            if (value && value.licencePlates && value.licencePlates.length) {
              value.licencePlates.forEach((licencePlate: string) => {
                if (!filterVehicles.includes(licencePlate)) {
                  filterVehicles.push(licencePlate);
                }
              })
            }
            if (value?.externalFleet) {
              return <Grid container xs={12}>
                <Grid item xs={10}>
                  <RegistrationColumn licencePlates={value.licencePlates} />
                </Grid>
                {
                  value.externalFleet && (
                    <Grid item xs={1}>
                      <Tooltip title={`Shared Fleet - ${value.branchName}`}>
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                  )
                }
              </Grid>
            }
            return <RegistrationColumn licencePlates={value?.licencePlates || []} />
          },
          filterList: [],
          customFilterListOptions: {
            render: (item: string) => {
              return item;
            },
            update: (filterList: any[], filterPos: number, index: number) => {
              if (filterPos !== -1) {
                filterList[index] = [];
              } else if (filterPos === -1) {
                filterList[index] = [];
              }
              return filterList;
            }
          },
          filterOptions: {
            logic: (data: any[], filters: string[]) => {
              const newData =
                data &&
                Object.values(data).map((item: any) => {
                  if (isArray(item)) {
                    const licencePlates: string[] = item;
                    return licencePlates.join(",");
                  }
                  return item;
                });
              if (filters.length) {
                return !newData.includes(filters);
              }
              return false;
            },
            display: (
              filterList: any[],
              onChange: Function,
              index: number,
              column: any[]
            ) => {
              const optionValues =
                filterVehicles &&
                filterVehicles.filter((item: string, key: number) => {
                  return filterVehicles.indexOf(item) === key;
                });
              return (
                <FormControl>
                  <InputLabel htmlFor="select-multiple-chip">Registration Number</InputLabel>
                  <Select
                    value={filterList[index].length ? filterList[index] : "All"}
                    onChange={(event) => {
                      if (event.target.value === "All") {
                        filterList[index] = [];
                        onChange(filterList[index], index, column);
                      } else {
                        filterList[index] = event.target.value;
                        onChange(filterList[index], index, column);
                      }
                    }}
                  >
                    {optionValues &&
                      optionValues.map((item: string, key: number) => (
                        <MenuItem key={key} value={item}>
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              );
            }
          }
        }
      }
    ];
  }

  useEffect(() => {
    if (prevBranchRef.current !== userState.currentBranch) {
      getBookingsCount()
      loadBookings({
        variables: {
          limit,
          offset: 0
        }
      });
      prevBranchRef.current = userState.currentBranch;
    }
  }, [userState.currentBranch])

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const dayString = params.get("day");
      const typeString = params.get("type");
      const valetRequired = params.get("valet");
      const bookedVehicleType = params.get("vehicleType")

      if (bookedVehicleType === BOOKING_FILTER.VIRTUAL) {
        setIsVirtualVehicleBooking(true)
      }

      if (typeString == BOOKING_FILTER.BY_DATE) {
        loadFilteredBookings({
          variables: {
            selectedDate: dayString
          }
        })
      }
      else if ((typeString == BOOKING_FILTER.PICKUP || typeString == BOOKING_FILTER.RETURN)) {
        loadBookings({
          variables: {
            limit: 100,
            offset: 0,
            bookingIds: location.state || []
          }
        });
      } else if (dayString === 'today' && typeString === BOOKING_FILTER.EXTERNAL_VEHICLE_BOOKINGS) {
        loadBookings({
          variables: {
            limit: 100,
            offset: 0,
            bookingIds: location.state || []
          }
        });
      }
      else {
        getBookingsCount()
        loadBookings({
          variables: {
            limit: bookingsTableState?.rowsPerPage || limit,
            offset: 0
          }
        });
      }

      if (dayString) {
        setDay(dayString);
      }
      if (typeString) {
        setType(typeString);
      }
      if (valetRequired) {
        setValet(true);
        setValetValue(parseInt(valetRequired))
      }
      if (location.state) {
        setExternalVehicleBookingIds(location.state as string[]);
      }
    } else {
      setDay("")
      getBookingsCount()
      loadBookings({
        variables: {
          limit: bookingsTableState?.rowsPerPage || limit,
          offset: 0
        }
      });
    }
  }, [location]);

  useEffect(() => {
    setbookingsTable(bookingsTableState);
    if (bookingsTableState && !location) {
      let sortedOrder = returnSortedOrder(bookingsTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns("bookings", country), bookingsTableState, sortedOrder);
      }
      loadBookings({
        variables: {
          offset: rows.length,
          limit: bookingsTableState.rowsPerPage || 10
        }
      })
    }
  }, [bookingsTableState, location]);

  useEffect(() => {
    if (
      selection.length === 1 &&
      (selection[0].status === BookingStatus.QUOTE ||
        selection[0].status === BookingStatus.CONFIRMED)
    ) {
      setUpdateButtonVisible(true);
    } else {
      setUpdateButtonVisible(false);
    }
  }, [selection]);

  useEffect(() => {
    if (selection.length !== 1) {
      setUpdateButtonVisible(false);
    }
  }, [selection]);

  useEffect(() => {
    dispatch(clearBooking());
  }, []);

  useEffect(() => {
    if (!uploadModalOpen) {
      setUploadErrors([]);
    }
  }, [uploadModalOpen]);

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchBookings({
        variables: {
          q: searchText,
          limit,
          offset: 0
        }
      })
    }
  }, 1000);

  function reshapeBookingsIntoRows(booking: IBooking) {
    const customer = booking.businessCustomer
      ? booking.businessCustomer.businessName
      : booking.customer
        ? booking.customer.firstName + " " + booking.customer.lastName
        : "";
    let bookingUser = `${booking?.createdBy?.firstName || "-"} ${booking?.createdBy?.lastName || ""}`
    let licencePlateDetails: any = {
      externalFleet: false,
      branchName: "",
      licencePlates: []
    };
    let licencePlateStr = "";
    let imgUrls: string[] = [];
    let bodyTypes: string[] = [];
    if (
      booking.currentBookingSchedules &&
      booking.currentBookingSchedules.length
    ) {
      const licencePlateArr: string[] = [];
      const bodyTypeArr: string[] = [];
      const imgUrlArr: string[] = [];
      booking.currentBookingSchedules.forEach((schedule: IBookingSchedule) => {
        if (!licencePlateDetails.externalFleet && schedule?.vehicle?.branchId !== userState.currentBranch.id) {
          licencePlateDetails.externalFleet = true
          const branch = userState.currentOrganisation.branches.find((item => item.id === schedule?.vehicle?.branchId))
          if (branch) {
            licencePlateDetails.branchName = branch.name
          }
        }
        if (schedule?.vehicle?.licencePlate) {
          licencePlateArr.push(
            schedule.vehicle.licencePlate.toUpperCase()
          );
          licencePlateStr += formatLicenceNumber(schedule.vehicle.licencePlate);
        }
        imgUrlArr.push(
          schedule.vehicle.imageUrl
        );
        bodyTypeArr.push(
          schedule.vehicle.bodyType
        );
      });
      if (licencePlateArr.length) {
        licencePlateDetails.licencePlates = licencePlateArr;
        imgUrls = imgUrlArr;
        bodyTypes = bodyTypeArr;
      }
    }
    const pickupServiceLocation = booking.pickupServiceLocation?.name || "";
    const dropoffServiceLocation = booking.dropoffServiceLocation?.name || "";
    const source = booking.source || "";
    let bookingSource = "";
    if (booking.source === BOOKING_SOURCE.B2B) {
      bookingSource = "Web App";
    } else if (booking.source === BOOKING_SOURCE.B2B2C) {
      bookingSource = "Website";
    } else if (booking.source === BOOKING_SOURCE.B2B2C_MOBILE) {
      bookingSource = "Mobile App";
    }
    let tbaData = booking?.tba ? "TBA" : "-";
    let bookingCategory = BOOKING_CATEGORY.RENTAL;
    if (booking.bookingCategory) {
      bookingCategory = booking.bookingCategory.replaceAll("_", " ") as BOOKING_CATEGORY;
    } else if (booking.isSubscription) {
      bookingCategory = BOOKING_CATEGORY.SUBSCRIPTION;
    }
    return {
      ...booking,
      customer,
      tbaData,
      licencePlateDetails,
      dropoffServiceLocation,
      pickupServiceLocation,
      source,
      bookingSource,
      licencePlateStr,
      imgUrls,
      bodyTypes,
      bookingUser,
      bookingCategory
    };
  }

  const [uploadBooking] = useMutation(UPLOAD_BOOKING, {
    onError: (error: ApolloError) => {
      if (formatGraphQLErrorMessage(error.message) === ErrorMessage.ACCESS_DENIED) {
        navigate('/access-denied')
      } else {
        setFileName("");
        setFileValue(undefined);
        return snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    },
    onCompleted: (data) => {
      if (
        data.uploadBooking &&
        data.uploadBooking.errors &&
        data.uploadBooking.errors.length
      ) {
        setFileName("");
        setFileValue(undefined);
        setUploadErrors(data.uploadBooking.errors);
        return setUploadStatus(CsvUploadStatusType.ERROR);
      }
      snackbar({
        message: "Bookings uploaded successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setUploadModalOpen(false);
      loadBookings({
        variables: {
          limit,
          offset: 0
        }
      });
      setFileName("");
      setFileValue(undefined);
      setUploadStatus(CsvUploadStatusType.DEFAULT);
    }
  });

  function onSelectDocument(event: any) {
    const file: File = event.target.files[0];
    setUploadErrors([]);
    setUploadStatus(CsvUploadStatusType.DEFAULT);
    if (file) {
      setFileName(file.name);
      if (possibleCsvMimeTypes.includes(file.type)) {
        setFileValue(file);
      } else {
        return snackbar({
          message: "Invalid File Type. Allowed type: csv",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }
  function validateDate(date: string) {
    const dateTimePattern = new RegExp(
      /^^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4} [0-9]{2}[\:]{1}[0-9]{2}$/
    );
    return dateTimePattern.test(date);
  }
  function uploadCsv() {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        papa.parse(fileValue, {
          skipEmptyLines: true,
          complete(results: any) {
            const bookings = [];
            if (results.data.length) {
              if (results.data.length > 21) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: "Only 20 bookings can be uploaded in one batch",
                  variant: SnackBarVariant.ERROR
                });
              }
              for (let index = 0; index < results.data[0].length; index++) {
                const header = results.data[0][index];
                if (header !== bookingCsvHeaders[index]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid File Header.",
                    variant: SnackBarVariant.ERROR
                  });
                }
              }
              if (results.data.length === 2 && !results.data[1][0]) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: "No data found.",
                  variant: SnackBarVariant.ERROR
                });
              }
              const dataErrors = [];
              for (let index = 1; index < results.data.length; index++) {
                const element: any = results.data[index];
                if (element.length < 17) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid file",
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (!validateDate(element[6]) && !validateDate(element[7])) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid date/time format for Pickup or Dropoff",
                    variant: SnackBarVariant.ERROR
                  });
                }
                const data: any = {
                  customerName: element[0],
                  customerEmail: element[1],
                  customerType: element[2],
                  signatoryEmail: element[3],
                  licencePlates: element[4].split(","),
                  vehicleGroups: element[5].split(","),
                  pickupDateTime: d.fromFormat(element[6], 'dd/MM/yyyy hh:mm').toUTC().toISO() || "",
                  dropoffDateTime: d.fromFormat(element[7], 'dd/MM/yyyy hh:mm').toUTC().toISO() || "",
                  pickupServiceLocation: element[8],
                  dropoffServiceLocation: element[9],
                  bookingStatus: element[10],
                  insurancePolicyName: element[11],
                  discountAmount: element[12],
                  addons: element[13] ? element[13].split(",") : [],
                  addonQuantities: element[14] ? element[14].split(",") : [],
                  billingCycle: element[15],
                  paidAmount: element[16],
                };
                if (data.customerType.toLowerCase() === "individual" && organisation.enableCostCenter) {
                  if (!element[17]) {
                    dataErrors.push({
                      rowIdx: index + 1,
                      message: `Cost Center info missing on row no. ${index + 1}!`
                    })
                  } else {
                    data.costCenter = element[17]
                    data.projectId = element[18] || ""
                  }
                }
                bookings.push(data);
              }
              if (dataErrors.length) {
                for (let j = 0; j < dataErrors.length; j++) {
                  snackbar({
                    message: dataErrors[j].message,
                    variant: SnackBarVariant.ERROR
                  })
                }
                return;
              }
              if (bookings.length > 0) {
                uploadBooking({
                  variables: {
                    bookings
                  }
                });
              } else {
                setUploadStatus(CsvUploadStatusType.ERROR);
                snackbar({
                  message: "No data found.",
                  variant: SnackBarVariant.ERROR
                });
              }
            }
          }
        });
      }
    } catch (error) {
      captureErrorException(error)
      setUploadStatus(CsvUploadStatusType.ERROR);
    }
  }

  const options: MUIDataTableOptions = {
    count: totalBookingCount,
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: loading || filterBookingsDataLoading || searchBookingsLoading ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (rowData: string[]) => {
      if (rowData && rowData.length) {
        // FIXME: Idially the rows should change based on sorting and then find data based on rowindex
        const bookingClicked = rows.find(
          (row) => row.referenceNumber === rowData[0]
        );
        if (bookingClicked) {
          if (bookingClicked.status === BookingStatus.QUOTE) {
            navigate(`/update-booking?booking=${bookingClicked.id}&step=3`);
          } else {
            navigate(`/view-booking?booking=${bookingClicked.id}`);
          }
        }
      }
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      if (currentRowsSelected && !currentRowsSelected.length) {
        dispatch(resetBranch());
        setUpdateButtonVisible(false);
      } else {
        if (currentRowsSelected?.length) {
          setUpdateButtonVisible(true);
          const selectedRows = allRowsSelected.map(row => rows[row.dataIndex]);
          setSelection(selectedRows);
        }
      }
    },
    setRowProps: (row) => {
      const bookingRow: any = rows.find(
        (booking: IRows) => booking.referenceNumber === row[0]
      );
      if (
        bookingRow.status === BookingStatus.IN_PROGRESS &&
        !bookingRow.longTermBooking &&
        bookingRow.dropoffDateTime &&
        d.now() > d.fromISO(bookingRow.dropoffDateTime)
      ) {
        return {
          style: { backgroundColor: "#FFFDCA" }
        };
      } else {
        return {};
      }
    },
    onSearchChange: (searchText: string | null) => {
      const params = new URLSearchParams(location.search);
      const typeString = params.get("type");
      if (searchText) {
        handleSearch(searchText)
      } else {
        if (type === BOOKING_FILTER.BY_DATE) {
          setRows([]);
          loadFilteredBookings({
            variables: {
              selectedDate: day
            }
          });
        }
        else if ((typeString == BOOKING_FILTER.PICKUP || typeString == BOOKING_FILTER.RETURN)) {
          setRows([]);
          loadBookings({
            variables: {
              limit: 100,
              offset: 0,
              bookingIds: location.state || []
            }
          })
        }
        else {
          setRows([])
          loadBookings({
            variables: {
              limit: limit,
              offset: 0
            }
          });
        }
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.BOOKINGS,
          rowsPerPage: numberOfRows
        })
      );
      if (type == BOOKING_FILTER.BY_DATE) {
        loadFilteredBookings({
          variables: {
            selectedDate: day
          }
        });
      }
    },
    rowsPerPage:
      bookingsTable && bookingsTable.rowsPerPage
        ? bookingsTable.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.BOOKINGS,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: (_: string, tableState: MUIDataTableState) => {
      if (bookingsTableState && bookingsTableState.filters && !location.search) {
        let filterColumns: IFilter[] = [];
        bookingsTableState.filters.forEach((value, index) => {
          if (value.length) {
            filterColumns.push({
              key: columns("bookings", country, isCarSubscription)[index].name,
              value: value[0]
            });
          }
        });
        loadBookings({
          variables: {
            limit: bookingsTableState?.rowsPerPage || limit,
            offset: 0,
            filters: filterColumns
          }
        });
        tableState.filterList = bookingsTableState.filters;
      }
      if (bookingsTableState && bookingsTableState.viewColumns) {
        for (const [key, value] of Object.entries(bookingsTableState.viewColumns)) {
          let columnIndex = tableState.columns.findIndex(column => column.name === key);
          if (columnIndex > -1) {
            tableState.columns[columnIndex].display = value as Display;
          }
        }
      }
      if (bookingsTable && sortOrder) {
        setTableSortOrder(columns("bookings", country), bookingsTable, sortOrder);
      }
      if (bookingsTable && bookingsTable.rowsPerPage) {
        setLimit(bookingsTable.rowsPerPage);
      }
    },
    onColumnViewChange: (changedColumn, action) => {
      const updatedViewColumns = { ...bookingsTableState?.viewColumns, [changedColumn]: String(action === 'add') };
      dispatch(
        addTableState({
          tableName: TableNames.BOOKINGS,
          viewColumns: updatedViewColumns
        })
      );
    },
    onFilterChange(changedColumn, filterList, type, changedColumnIndex, displayData) {
      dispatch(
        addTableState({
          tableName: TableNames.BOOKINGS,
          filters: filterList
        })
      );
      let filterColumns: IFilter[] = [];
      filterList.forEach((value, index) => {
        if (value.length) {
          filterColumns.push({
            key: columns("bookings", country, isCarSubscription)[index].name,
            value: value[0]
          });
        }
      });
      setCurrentFilters(filterColumns);

      if (filterList) {
        setIsFiltering(true)
        loadBookings({
          variables: {
            limit: bookingsTableState?.rowsPerPage || limit,
            offset: 0,
            filters: filterColumns
          }
        })
      }
      else {
        setIsFiltering(false)
      }
    },
    onTableChange: (action: string, tableState: any) => {
      let filterColumns: IFilter[] = currentFilters;
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return;
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length || filtering) {
            if (filtering) {
              loadBookings({
                variables: {
                  offset: page * rowsPerPage,
                  limit: rowsPerPage,
                  filters: filterColumns,
                }
              });
            }
            else {
              loadBookings({
                variables: {
                  offset: page * rowsPerPage,
                  limit: rowsPerPage
                }
              });
            }
          }
          break;
        case "changeRowsPerPage":
          setRows([])
          if (type !== BOOKING_FILTER.BY_DATE) {
            loadBookings({
              variables: {
                offset: 0,
                limit: tableState.rowsPerPage || limit,
                bookingIds: externalVehicleBookingIds || []
              }
            });
          }
          break;
        default:
          break;
      }
    },

    onDownload: (buildHead, buildBody, columns, data: { data: any[]; }[]) => {
      data.forEach(row => {
        row.data[row.data.length - 1] = getRegistrationNumber(row.data[row.data.length - 1]);
      });
      return buildHead(columns) + buildBody(data)
    },
  };

  const getRegistrationNumber = (data: IRegistrationNumberColumn) => {
    if (data.licencePlates && data.licencePlates.length > 0) {
      return data.licencePlates.join(",");
    }
    else {
      return ""
    }
  }

  const classes = UseStyles();

  const onChange = (event: { target: { value: any; }; }) => {
    handleSearch(event.target.value);
    if (searchBookingsData && searchBookingsData.searchBookings) {
      const newBookings = searchBookingsData.searchBookings.map((booking: IBooking) =>
        reshapeBookingsIntoRows(booking)
      );
      setRows(newBookings)
    }
    if (!event.target.value) {
      setRows([])
      loadBookings({
        variables: {
          limit: 10,
          offset: 0
        }
      })
    }
  };

  const handleScroll = (e: any) => {
    const threshold = 15000
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < threshold
    ) {
      loadBookings({
        variables: {
          offset: rows.length,
          limit: rows.length
        }
      })
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [data]);



  return (
    <div>
      <Grid container spacing={2}>
        <CssBaseline />
        <Grid container item xs={12} sm={6}>
          <Typography variant="h1" color="primary">
            {`${getLocalizedBookingSyntex(country)}s`}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid container item xs={6} justifyContent="flex-end">
            <Box color="white" sx={{ pr: 2 }}></Box>
            <Fab
              variant={"extended"}
              size="medium"
              aria-label="Update"
              disabled={!updateButtonVisible}
              className={updateButtonVisible ? "" : "deleteButtonDisable"}
              onClick={() => {
                dispatch(updateBooking({ ...selection[0] }));
                if (selection[0].isSubscription) {
                  navigate(`/update-booking?booking=${selection[0].id}&step=0`);
                } else {
                  navigate(`/update-booking?booking=${selection[0].id}&step=0`);
                }
              }}
            >
              Update
            </Fab>
            <Box color="white" sx={{ pr: 2 }}></Box>
            <Fab
              variant="extended"
              size="medium"
              aria-label="Create"
              onClick={() => {
                navigate("/new-booking");
              }}
            >
              Create
            </Fab>
            <Box color="white" sx={{ pr: 2 }}></Box>
            <Fab
              variant="extended"
              size="medium"
              aria-label="Create Multiple"
              onClick={() => setUploadModalOpen(true)}
            >
              Create Multiple
            </Fab>
          </Grid>
        </Hidden>
        {!((loading || filterBookingsDataLoading) && !rows.length) ? (
          <Grid container item xs={12}>
            <Hidden smUp>
              {searchBookingsLoading ? (
                <CircularProgress />) : (
                <div onScroll={handleScroll}>
                  <BookingVirtualScroller data={rows} />
                </div>
              )}
            </Hidden>

            <Hidden smDown>
              <NuvvenTable
                title={""}
                rows={rows}
                columns={columns("bookings", country, isCarSubscription)}
                setSelection={setSelection}
                options={options}
              />
            </Hidden>
          </Grid>
        ) :
          <div style={{ padding: '1.125rem' }}>
            <CircularProgress />
          </div>
        }

        {
          uploadModalOpen && (
            <BulkCsvUploadDialog
              title={`Create Multiple ${getLocalizedBookingSyntex(country)}`}
              subTitle={`${getLocalizedBookingSyntex(country)}s`}
              csvTemplateLink={organisation.enableCostCenter ?
                "https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Bookings-Upload-CC.csv" :
                "https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Bookings-Upload.csv"}
              uploadErrors={uploadErrors}
              uploadStatus={uploadStatus}
              onDocumentSelect={onSelectDocument}
              fileName={fileName}
              uploadCsv={uploadCsv}
              handleClose={() => setUploadModalOpen(false)}
              message={`Only 20 ${getLocalizedBookingSyntex(country)}s can be uploaded in one batch`}
            />
          )
        }
      </Grid >
      <Hidden smUp>
        <AppBar position="fixed" className={classes.appBar}>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Button
                className={classes.bottomButton}
                variant="contained"
                aria-label="Create"
                onClick={() => {
                  navigate("/new-booking");
                }}
              >
                Create
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={onChange}
                />
              </div>
            </Grid>
          </Grid>
        </AppBar>
      </Hidden>
    </div >
  );
}