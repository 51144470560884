import {
  Box,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documents: {
      alignItems: "center",
      display: "flex"
    },
    download: {
      marginLeft: "10px"
    },
    picker: {
      width: "300px"
    },
    warning: {
      color: theme.palette.primary.dark
    },
    logoStyle: {
      width: "150px",
      height: "100px",
      objectFit: "contain"
    }
  })
);

interface IViewLogoUrlProps {
  logoUrl: any;
}

export const ViewLogo = (props: IViewLogoUrlProps) => {

  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid item>
        <img src={props.logoUrl} alt="Logo" className={classes.logoStyle} />
      </Grid>
    </React.Fragment>
  );
};

