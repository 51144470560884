import { Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

interface IProps {
  label: string;
  value: string;
  onClick?: () => void;
  color?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: "16px"
    },
    value: {
      fontWeight: "bold"
    }
  })
);

export const SummaryField = (props: IProps) => {
  const classes = useStyles();

  return (
    <Typography variant="body1" onClick={props.onClick} style={props.onClick ? { cursor: "pointer" } : {}}>
      {props.label}: <span className={classes.value} style={props.onClick ? { color: "#1875d2" } : props.color ? { backgroundColor: props.color, padding: '4px 10px', color: '#fff' } : {}}>
        <span style={{ verticalAlign: 'middle' }}>{props.value}</span>
      </span>
    </Typography>
  );
};
