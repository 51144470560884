import { gql } from "@apollo/client";

export const GET_USER_ACCESS_POLICIES = gql`
  query getUserAccessPolicies {
    getUserAccessPolicies{
      id
      name
      isActive
      policyType
      createdBy{
        id
        firstName
        lastName
      }
      updatedBy{
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_USER_POLICIES_FOR_ROLE = gql`
  query getUserAccessPolicies {
    getUserAccessPolicies{
      id
      name
      isActive
    }
  }
`;

export const GET_USER_ACCESS_POLICY = gql`
  query getUserAccessPolicy($userAccessPolicyId: String!, $policyType: String!) {
    getUserAccessPolicy(userAccessPolicyId: $userAccessPolicyId, policyType: $policyType){
      id
      name
      isActive
      policyType
      permissions
      selectedKeys
    }
  }
`;

export const GET_USER_ACCESS_ROLES = gql`
  query getUserAccessRoles {
    getUserAccessRoles{
      id
      name
      isActive
      roleType
      policies{
        id
        name
      }
      createdAt
      updatedAt
      createdBy{
        id
        firstName
        lastName
      }
      updatedBy{
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_USER_ROLES = gql`
  query getUserAccessRolesInOrganisation ($organisationId: String!){
    getUserAccessRolesInOrganisation(organisationId: $organisationId){
      id
      name
      key
      roleType
      isActive
    }
  }
`;

export const GET_USER_ACCESS_ROLE = gql`
  query getUserAccessRole($userAccessRoleId: String!, $roleType: String!) {
    getUserAccessRole(userAccessRoleId: $userAccessRoleId, roleType: $roleType){
      id
      name
      isActive
      roleType
      policies{
        id
        name
        permissions
      }

    }
  }
`;

export const CHECK_TENANCY_SUBSCRIPTION_FOR_VEHICLESUBSCRIPTION = gql`
  query checkTenancySubscribedForVehicleSubscription {
    checkTenancySubscribedForVehicleSubscription{
      success
      message
    }
  }
`;