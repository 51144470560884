import { gql } from "@apollo/client";

export const GET_PARTNER = gql`
  query partner($id: ID!) {
    partner(id: $id) {
      id
      isActive
      partnerId
      partnerName
      partnerType
      address {
        street
        city
        fullAddress
        state
        country
        zipcode
      }
      VAT
      email
      phoneNumber {
        phone
        country
      }
      companyRegistrationNo
      partnerContact {
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
			documents{
        id
        documentKey
        documentName
        expiryDate
        documentType
        createdAt
      }
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      billingContact {
        phoneNumber {
          phone
          country
        }
        email
      }
    }
  }
`;
