import { gql } from "@apollo/client";

export const UPLOAD_TRAILERS = gql`
  mutation uploadTrailers($vehicles: [UploadTrailerInput!]!) {
    uploadTrailers(vehicles: $vehicles) {
      errors {
        index
        message
      }
      vehicles {
        id
        insurerCode
        airConditioner
        assetTag
        isGhostVehicle
        brakeHorsePower
        bluetooth
        bodyType
        vehicleUniqueCode
        vehicleUniqueId
        co2Emissions
        colour
        cylinderCapacity
        description
        fuelType
        imported
        infoSource
        insuranceGroup
        licencePlate
        vin
        make
        model
        manufactureDate
        year
        registrationDate
        vehicleTest
        vehicleTestDetails
        vehicleTestDueDate
        numberOfDoors
        numberOfPreviousOwners
        numberOfSeats
        premium
        price
        msrp
        grossVehicleWeight
        satNav
        sixMonthRate
        status
        steering
        taxDetails
        taxed
        taxStatus
        telemetryDeviceId
        transmission
        twelveMonthRate
        typeApproval
        tyreFitments
        wheelPlan
        acrissCode
        branch
        imageUrl
        deleted
        id
        interiorColour
        pcoNumber
        
        vehicleTestReports {
          testDate
          expiryDate
          testResult
          odometerReading
          odometerUnit
          motTestNumber
          advisoryItems
          minorItems
          failureItems
        }        
      }
    }
  }
`;
