import {
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    cardStyle: {
      padding: "5px",
      borderRadius: "15px",
      minWidth: "calc(100vw - 60px)",
      maxWidth: "calc(100vw - 60px)"
    },
    itemPosition: {
      paddingBottom: "3px"
    }

  }));

export const MobileOrganisationCard = ({ id, name, country, email, contact, totalBranch, currency }: {
  id: string, name: string, country: string, email: string, contact: string, totalBranch: string, currency: string
}) => {
  const classes = useStyle();
  const navigate = useNavigate();

  function routeTo() {
    navigate(`/update-organisation?organisation=${id}&step=0`);
  }


  return (
    <ButtonBase onClick={() => routeTo()}>
      <Card className={classes.cardStyle}>
        <CardContent>
          <Grid container item xs={12}>
            <Grid className={classes.itemPosition} item xs={12}>
              <Typography style={{ fontWeight: "bold", fontSize: "20px", paddingBottom: "2px" }}>{name}</Typography>
            </Grid>
            <Grid className={classes.itemPosition} item xs={12}>
              <Typography>{country}</Typography>
              <Typography>{email}</Typography>
              <Typography>{contact}</Typography>
              <Typography>No. of branches: {totalBranch}</Typography>
              <Typography>Currency: {currency}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ButtonBase>

  );
};

export default MobileOrganisationCard;