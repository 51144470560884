import { IAsset, IAssetService } from '../../asset.types';
import {
  Grid,
  Theme,
  Typography,
  Box,
  Paper
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MUIDataTableOptions } from "mui-datatables";
import { IAppState } from '../../../../../store';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { DATE_TYPE, toCurrency } from '../../../../common/utils';
import { VehicleServiceStatus, VehicleServiceStatusValue, VehicleServiceType, VehicleServiceTypeValue } from '../../../../../reducers/fleet/types';
import { SelectableRows } from '../../../../common/NuvvenTable/types';
import { NuvvenTable } from '../../../../common/NuvvenTable/NuvvenTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    green: {
      color: "#00ff00"
    },
    header: {
      color: "rgb(45, 157, 184)",
      fontSize: 20
    },
    label: {
      color: "grey",
      fontSize: 12,
      fontWeight: 100,
      marginLeft: 20
    },
    paper: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      textAlign: "center"
    },
    red: {
      color: "#ff0000"
    },
    root: {
      padding: theme.spacing(3)
    }
  })
);
interface IProps {
  asset: IAsset | undefined
}
export const ViewAssetServices: FC<IProps> = ({ asset }) => {


  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const classes = useStyles();
  const [assetServices, setAssetServices] = useState<IAssetService[]>([]);

  useEffect(() => {
    if (asset && asset.services && asset.services.length) {
      setAssetServices(asset.services);
    }
  }, [asset]);

  const columns = [
    {
      label: "Service Start Date",
      name: "appointmentDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Service End Date",
      name: "completionDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Service Type",
      name: "serviceType",
      options: {
        customBodyRender: (value: VehicleServiceType) => VehicleServiceTypeValue[value]
      }
    },
    {
      label: "Total Expense",
      name: "totalExpense",
      options: {
        customBodyRender: (value: string) => toCurrency(parseInt(value || "0"), currency, locale)
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value: VehicleServiceStatus) => VehicleServiceStatusValue[value]
      }
    }
  ];

  function Spacer() {
    return (
      <Grid item xs={12}>
        <Box mt={1} />
      </Grid>
    )
  }

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    elevation: 0,
    onRowClick: (rowData: string[], rowMeta: { dataIndex: number }) => {
      if (rowData && rowData.length && asset && asset.services && asset.services.length) {
        const serviceDate = asset.services[rowMeta.dataIndex];
        if (serviceDate) {
          window.open(`/update-asset-services?id=${serviceDate.id}`, "_blank");
        }
      }
    },
  }


  return (
    <Grid container className={classes.root}>
      <Grid container item xs={12}>
        <Grid container item xs={12}>
          <Typography variant="subtitle1">ASSET SERVICES</Typography>
        </Grid>
        <Spacer />
        <Grid item xs={12}>
          {assetServices && assetServices.length > 0 ? (
            <Paper variant="outlined">
              <NuvvenTable
                title=""
                rows={assetServices}
                columns={columns}
                options={options}
              />
            </Paper>
          ) : (
            <Grid xs={12} item>
              <Typography variant="h4">
                No Service Data Found!
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}