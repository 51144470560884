import {
  Fab,
  Grid,
  MenuItem,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  IVehicle,
  VehicleTypes,
  VehicleFuelType
} from "../../../../../../reducers/fleet/types";
import { VehicleInfoSource, emmissionTypes, fuelTypes, ukVehicleLabel, ulezComplaints } from "../../../utils";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";

interface IProps {
  vehicle: IVehicle;
  updateFields(payload: any): void;
  updateVehicle(payload: any): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: "grey",
      fontSize: 12,
      fontWeight: 100,
      marginLeft: 20
    },
    saveButton: {
      backgroundColor: "#faaf40",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    saveButtonDisabled: {
      backgroundColor: "#d3d3d3",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      width: 150
    },
    root: {
      padding: theme.spacing(3)
    }
  })
);

export const Specification = (props: IProps) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const [values, setValues] = useState<IVehicle>(props.vehicle);
  useEffect(() => {
    if (props.vehicle.fuelType === "HYBRID ELECTRIC") {
      props.vehicle.fuelType = "HYBRID ELECTRIC (HEV)"
    }
    setValues({ ...values, ...props.vehicle });
  }, [props.vehicle]);

  const [isFourWheeler, setIsFourWheeler] = useState<boolean>(false);
  const [isBioFuelVehicle, setIsBioFuelVehicle] = useState<boolean>(false);
  const bioFuelTypes = ["DIESEL", "PETROL", "ELECTRIC DIESEL", "GAS BI-FUEL", "GASOLINE"];
  useEffect(() => {
    if (values.vehicleType) {
      setIsFourWheeler(![VehicleTypes.TWO_WHEELER, VehicleTypes.ATV].includes(values.vehicleType))
    }
  }, [values.vehicleType])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const isSelectedBioFuelVehicle = bioFuelTypes.includes(event.target.value);
    if (isSelectedBioFuelVehicle) {
      setIsBioFuelVehicle(isSelectedBioFuelVehicle)
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    props.updateFields({ [event.target.name]: event.target.value });
  }

  function onSubmit() {
    if (!values.licencePlate || !values.make || !values.model || !values.year || !values.transmission || !values.colour || !values.vin) {
      snackbar({
        message: 'Few mandatory details are missing',
        variant: SnackBarVariant.ERROR
      });
      return;
    }
    props.updateVehicle(values);
  }

  const detailsFormSchema = Yup.object().shape({
    fuelType: Yup.string().required("Fuel Type is required field"),
    ...(!props.vehicle.isGhostVehicle
      ? {
        cylinderCapacity: Yup.string().required("Engine size is a required field"),
      }
      : {}),
    bodyType: Yup.string().required("Body type is required field")
  });

  return (
    <Grid container className={classes.root}>
      <Formik
        enableReinitialize
        validationSchema={detailsFormSchema}
        initialValues={values}
        onSubmit={() => { }}
      >
        {() => (
          <React.Fragment>
            <Form style={{ flexGrow: 1 }}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h2">Vehicle Specification</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    component={TextField}
                    label="Fuel Type"
                    name={"fuelType"}
                    required
                    InputProps={{
                      onChange: handleChange,
                      value: values.fuelType
                    }}
                    select
                    disabled={
                      props.vehicle.isGhostVehicle ||
                      (props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                        props.vehicle.fuelType !== "")
                    }
                    inputProps={{ maxLength: 30 }}
                    fullWidth
                  >
                    {fuelTypes.map((fuelType) => {
                      return (
                        <MenuItem key={fuelType.value} value={fuelType.value}>
                          {fuelType.label}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </Grid>
                {!props.vehicle.isGhostVehicle &&
                  values.fuelType !== VehicleFuelType.ELECTRICITY &&
                  values.fuelType !== VehicleFuelType.ELECTRIC && (
                    <Grid item xs={12} sm={6} md={4}>
                      <Field
                        component={TextField}
                        label="Tank Capacity"
                        name={"tankCapacity"}
                        InputProps={{
                          onChange: handleChange,
                          value: values.tankCapacity
                        }}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                      ></Field>
                    </Grid>
                  )}
                {!props.vehicle.isGhostVehicle &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      component={TextField}
                      label="Co2 Emission"
                      name={"co2Emissions"}
                      InputProps={{
                        onChange: handleChange,
                        value: values.co2Emissions
                      }}
                      disabled={
                        props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                        props.vehicle.co2Emissions !== ""
                      }
                      inputProps={{ maxLength: 30 }}
                      fullWidth
                    ></Field>
                  </Grid>
                }
                {!props.vehicle.isGhostVehicle &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      component={TextField}
                      label="Engine Size"
                      name={"cylinderCapacity"}
                      required
                      InputProps={{
                        onChange: handleChange,
                        value: values.cylinderCapacity
                      }}
                      disabled={
                        props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                        props.vehicle.cylinderCapacity !== ""
                      }
                      inputProps={{ maxLength: 30 }}
                      fullWidth
                    ></Field>
                  </Grid>
                }
                {!props.vehicle.isGhostVehicle &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      component={TextField}
                      label="BHP"
                      name={"brakeHorsePower"}
                      InputProps={{
                        onChange: handleChange,
                        value: values.brakeHorsePower
                      }}
                      inputProps={{ maxLength: 30 }}
                      fullWidth
                    ></Field>
                  </Grid>}
                {!props.vehicle.isGhostVehicle &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      component={TextField}
                      label="ACRISS Code"
                      name={"vehicleUniqueCode"}
                      InputProps={{
                        onChange: handleChange,
                        value: values.vehicleUniqueCode
                      }}
                      disabled={
                        props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                        props.vehicle.vehicleUniqueCode !== ""
                      }
                      inputProps={{ maxLength: 20 }}
                      fullWidth
                    ></Field>
                  </Grid>
                }
                {!props.vehicle.isGhostVehicle && isFourWheeler &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      component={TextField}
                      label="Interior Colour"
                      name={"interiorColour"}
                      InputProps={{
                        onChange: handleChange,
                        value: values.interiorColour
                      }}
                      disabled={
                        props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                        props.vehicle.interiorColour !== ""
                      }
                      inputProps={{ maxLength: 10 }}
                      fullWidth
                    ></Field>
                  </Grid>
                }
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    component={TextField}
                    label="Body Type"
                    name={"bodyType"}
                    required
                    InputProps={{
                      onChange: handleChange,
                      value: values.bodyType.toUpperCase()
                    }}
                    disabled={
                      props.vehicle.isGhostVehicle ||
                      (props.vehicle.infoSource === VehicleInfoSource.DVLA &&
                        props.vehicle.bodyType !== "UNDEFINED" &&
                        props.vehicle.bodyType !== "")
                    }
                    inputProps={{ maxLength: 30 }}
                    fullWidth
                  ></Field>
                </Grid>
                {!props.vehicle.isGhostVehicle && isFourWheeler &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      component={TextField}
                      label="Steering"
                      name={"steering"}
                      InputProps={{
                        onChange: handleChange,
                        value: values.steering
                      }}
                      inputProps={{ maxLength: 30 }}
                      fullWidth
                    ></Field>
                  </Grid>
                }
                {!props.vehicle.isGhostVehicle &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      component={TextField}
                      label="Wheel Plan"
                      name={"wheelPlan"}
                      InputProps={{
                        onChange: handleChange,
                        value: values.wheelPlan
                      }}
                      inputProps={{ maxLength: 30 }}
                      fullWidth
                    ></Field>
                  </Grid>
                }
                {!props.vehicle.isGhostVehicle && isBioFuelVehicle &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      component={TextField}
                      label="Emmission Type"
                      name={"emmissionType"}
                      InputProps={{
                        onChange: handleChange,
                        value: values.emmissionType
                      }}
                      select
                      disabled={
                        props.vehicle.infoSource === VehicleInfoSource.DVLA
                      }
                      inputProps={{ maxLength: 30 }}
                      fullWidth
                    >
                      {emmissionTypes.map((emmissionType) => {
                        return (
                          <MenuItem key={emmissionType.value} value={emmissionType.value}>
                            {emmissionType.label}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                }
                {!props.vehicle.isGhostVehicle &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      component={TextField}
                      label="Ultra Low Emissions Zone"
                      name={"ulezComplaint"}
                      InputProps={{
                        onChange: handleChange,
                        value: values.ulezComplaint
                      }}
                      select
                      inputProps={{ maxLength: 30 }}
                      fullWidth
                    >
                      {ulezComplaints.map((ulezComplaint) => {
                        return (
                          <MenuItem key={ulezComplaint.value} value={ulezComplaint.value}>
                            {ulezComplaint.label}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                }
                {!props.vehicle.isGhostVehicle &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      component={TextField}
                      label="United Kingdom Vehicle"
                      name={"ukVehicle"}
                      InputProps={{
                        onChange: handleChange,
                        value: values.ukVehicle
                      }}
                      select
                      inputProps={{ maxLength: 30 }}
                      fullWidth
                    >
                      {ukVehicleLabel.map((item) => {
                        return (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                }
                <Grid item xs={12}>
                  {!(props.vehicle.id === "") && !props.vehicle.isGhostVehicle && (
                    <Fab
                      className={
                        classes.saveButton
                        // TODO : Show save button only when props.vehicle and values are different
                      }
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      type="button"
                      onClick={onSubmit}
                      disabled={!values.cylinderCapacity || !values.fuelType || !values.bodyType}
                    >
                      Save
                    </Fab>
                  )}
                </Grid>
              </Grid>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    </Grid>
  );
};
