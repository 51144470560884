import { gql } from "@apollo/client";

export const CREATE_ASSET = gql`
  mutation createAsset($asset: AssetInput!) {
    createAsset(asset: $asset) {
      id
      registrationNumber
      serialNumber
      identificationNumber
      assetTag
      make
      model
      color
      frogDiagram
      assetType
      isActive
      images
      documents{
        id
        title
        documentName
        expiryDate
        documentType
        reminderBefore
        url
      }
      size
      deFleetParameter{
        byDate
        condition
      }
      dimension{
        length
        width
        height
      }
      capacity
      capacityUnit
      yearOfManufacture
      yearOfRegistration
      issueDate
      grossWeight{
        maximumPermittedWeight
        maximumCountryPermittedWeight
        designWeight
      }
      trainWeight{
        maximumPermittedWeight
        maximumCountryPermittedWeight
        designWeight
      }
      axleWeights{
        maximumPermittedWeight
        maximumCountryPermittedWeight
        designWeight
      }
      description
    }
  }
`;

export const UPDATE_ASSET = gql`
  mutation updateAsset($id: String!,$asset: AssetInput!) {
    updateAsset(id: $id, asset: $asset) {
      id
      registrationNumber
      serialNumber
      identificationNumber
      assetTag
      make
      model
      color
      frogDiagram
      assetType
      isActive
      images
      documents{
        id
        title
        documentName
        expiryDate
        documentType
        reminderBefore
        url
      }
      size
      deFleetParameter{
        byDate
        condition
      }
      dimension{
        length
        width
        height
      }
      capacity
      capacityUnit
      yearOfManufacture
      yearOfRegistration
      issueDate
      grossWeight{
        maximumPermittedWeight
        maximumCountryPermittedWeight
        designWeight
      }
      trainWeight{
        maximumPermittedWeight
        maximumCountryPermittedWeight
        designWeight
      }
      axleWeights{
        maximumPermittedWeight
        maximumCountryPermittedWeight
        designWeight
      }
      description
    }
  }
`;


export const UPDATE_ASSET_STATUS = gql`
  mutation updateAssetStatus($id: String!,$isActive: Boolean!) {
    updateAssetStatus(id: $id, isActive: $isActive) {
      success
      isActive
      message
    }
  }
`;

export const ADD_ASSET_DOCUMENT = gql`
  mutation addAssetDocument($assetId: String!, $document: VehicleDocumentInput!){
    addAssetDocument(assetId: $assetId, document: $document){
      id
      title
      documentName
      documentType
      expiryDate
      url
      createdAt
      createdBy
    }
  }
 `

export const DELETE_ASSET_DOCUMENT = gql`
mutation deleteAssetDocument($assetId: String!, $documentUrl: String!){
  deleteAssetDocument(assetId: $assetId, documentUrl: $documentUrl){
    success
    message
  }
}
`   