import { IInsurancePolicyState } from "./types";
export const initialState: IInsurancePolicyState = {
  insurancePolicy: {
    name: "",
    description: "",
    excess: 0,
    documents: [],
    insuranceRate: [
      {
        rateName: "hourly",
        rateDuration: 60,
        rate: 0,
        longTerm: false
      },
      {
        rateName: "daily",
        rateDuration: 1440,
        rate: 0,
        longTerm: false
      },
      {
        rateName: "weekly",
        rateDuration: 10080,
        rate: 0,
        longTerm: false
      },
      {
        rateName: "monthly",
        rateDuration: 43200,
        rate: 0,
        longTerm: false
      }
    ],
    organisationId: "",
    isActivated: true
  }
};
