import {
  Box,
  CircularProgress,
  Fab,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ICheckInfoAddOnRequirement, ICheckInfoBooking, ICheckInfoVehicle } from "./types";

interface ICustomerCheckProps {
  currentBooking: ICheckInfoBooking;
  vehicle: ICheckInfoVehicle;
  onSubmit(): void;
}

export const CustomerInfo: React.FC<ICustomerCheckProps> = (props) => {
  const [booking, setBooking] = useState<ICheckInfoBooking>();
  const [vehicle, setVehicle] = useState<ICheckInfoVehicle>();
  const [addons, setAddons] = useState<string[]>([]);

  useEffect(() => {
    if (props.currentBooking) {
      setBooking(props.currentBooking);
      if (props.currentBooking.addonRequirements?.length) {
        const addonArr: string[] = [];
        props.currentBooking.addonRequirements.forEach((item: ICheckInfoAddOnRequirement) => {
          if (item.addon.displayName) {
            addonArr.push(item.addon.displayName.toUpperCase());
          } else {
            addonArr.push(item.name.toUpperCase());
          }
        });
        setAddons(addonArr);
      }
    }
  }, [props.currentBooking]);

  useEffect(() => {
    if (props.vehicle) {
      setVehicle(props.vehicle);
    }
  }, [props.vehicle]);

  return (
    <Grid container item xs={12}>
      {booking && booking.id ? (
        <React.Fragment>
          <Grid item container xs={12}>
            <Paper style={{ flexGrow: 1, padding: 30 }}>
              <Grid container item xs={12}>
                <Typography variant="h3">BOOKING INFORMATION</Typography>
              </Grid>
              <Grid container item xs={12}>
                <Box mt={1}></Box>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid container item xs={3}>
                  <Typography variant="h4">Booking Reference:</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Typography variant="body1">
                    {booking.referenceNumber}
                  </Typography>
                </Grid>
                <Grid container item xs={3}>
                  <Typography variant="h4">Booking Date:</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Typography variant="body1">
                    {booking.dateCreated}
                  </Typography>
                </Grid>
                <Grid container item xs={3}>
                  <Typography variant="h4">Status:</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Typography variant="body1">
                    {booking.status}
                  </Typography>
                </Grid>
                <Grid container item xs={3}>
                  <Typography variant="h4">Vehicle Model:</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Typography variant="body1">
                    {vehicle && vehicle.model}
                  </Typography>
                </Grid>
                <Grid container item xs={3}>
                  <Typography variant="h4">License Plate:</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Typography variant="body1">
                    {vehicle && vehicle.licencePlate.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid container item xs={3}>
                  <Typography variant="h4">Add ons:</Typography>
                </Grid>
                <Grid container item xs={9}>
                  <Typography variant="body1">
                    {addons.join(", ")}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid container item xs={2}>
            <Box mt={2}></Box>
          </Grid>
          <Grid container item xs={12}>
            <Paper style={{ flexGrow: 1, padding: 30 }}>
              <Grid container item xs={12}>
                <Grid container item xs={6}>
                  <Grid container item xs={12}>
                    <Typography variant="h3">CUSTOMER INFORMATION</Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <Box mt={1}></Box>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid container item xs={4}>
                      <Typography variant="h4">Name:</Typography>
                    </Grid>
                    <Grid container item xs={8}>
                      <Typography variant="body1">
                        {`${booking.customer?.firstName} ${booking.customer?.lastName} `}
                      </Typography>
                    </Grid>
                    <Grid container item xs={4}>
                      <Typography variant="h4">Date of Birth:</Typography>
                    </Grid>
                    <Grid container item xs={8}>
                      <Typography variant="body1">
                        {booking.customer?.dateOfBirth}
                      </Typography>
                    </Grid>
                    <Grid container item xs={4}>
                      <Typography variant="h4">Gender:</Typography>
                    </Grid>
                    <Grid container item xs={8}>
                      <Typography variant="body1">
                        {booking.customer?.gender}
                      </Typography>
                    </Grid>
                    <Grid container item xs={4}>
                      <Typography variant="h4">Phone:</Typography>
                    </Grid>
                    <Grid container item xs={8}>
                      <Typography variant="body1">
                        {booking.customer?.phoneNumber}
                      </Typography>
                    </Grid>
                    <Grid container item xs={4}>
                      <Typography variant="h4">Email ID:</Typography>
                    </Grid>
                    <Grid container item xs={8}>
                      <Typography variant="body1">
                        {booking.customer?.email}
                      </Typography>
                    </Grid>
                    <Grid container item xs={4}>
                      <Typography variant="h4">Address:</Typography>
                    </Grid>
                    <Grid container item xs={8}>
                      <Typography variant="body1">
                        {booking.customer?.location.fullAddress}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* </Grid> */}
              </Grid>
            </Paper>
          </Grid>
          <Grid container item xs={2}>
            <Box mt={2}></Box>
          </Grid>
          <Grid item xs={12} container>
            <Fab
              variant="extended"
              size="medium"
              aria-label="submit"
              type="submit"
              onClick={() => props.onSubmit()}
            >
              Proceed
            </Fab>
          </Grid>
        </React.Fragment>
      ) : (
        <CircularProgress />
      )}
    </Grid>
  );
};
