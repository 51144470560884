import { IconButton, Popover, Theme, Typography, Badge, CircularProgress } from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from "react-redux";
import NotificationsIcon from '@mui/icons-material/Notifications';
import DotIcon from '@mui/icons-material/FiberManualRecord';
import styles from "./index.module.css";
import api from "./api"
import { IAppState } from '../../../../store';
import { useQuery, useMutation } from '../../../../utils/axios-hooks';
import io from "socket.io-client";
import { DateTime as d } from "luxon";
import { socket } from '../../../../utils/socket';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "inherit !important",
      fontSize: 30
    },
    popoverMenu: {
      minWidth: 600,
      width: "50%",
      "& .MuiPopover-paper": {
        width: '35%',
        padding: theme.spacing(2),
        marginRight: 10,
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
      }
    },
    signOutButton: {
      border: "none",
      background: "none",
      fontSize: 14,
      padding: 0
    }
  })
);

export enum ReadStatus {
  READ = "READ",
  UNREAD = "UNREAD",
  PENDING = "PENDING"
}

export interface INotification {
  id: string;
  title: string;
  message: string;
  entity: INotificationEntity;
  actor: IActor;
  timeStamp: string;
  receiver: string;
  readAt?: string;
  status: ReadStatus;
  branchId: string;
  branch: string
}

export interface INotificationEntity {
  type: NotificationTrigger,
  id: string,
}

export interface IActor {
  id: string;
  displayName: string;
}

export enum NotificationTrigger {
  BOOKING_EXTENSION = "BOOKING_EXTENSION",
  EARLY_TERMINATION = "EARLY_TERMINATION",
  SUNDRY_INVOICE_ON_NEW_DAMAGE = "SUNDRY_INVOICE_ON_NEW_DAMAGE",
  EXTRA_MILEAGE = "EXTRA_MILEAGE",
  CHECK_IN_CHARGE = "CHECK_IN_CHARGE",
  BOOKING_END = "BOOKING_END",
  MOVEMENT_REQUEST = "MOVEMENT_REQUEST",
  SERVICE_COMPLETION = "SERVICE_COMPLETION",
  WEBSITE_BOOKING = "WEBSITE_BOOKING",
  CHECK_OUT = "CHECK_OUT",
  CHECK_IN = "CHECK_OUT",
}

export enum RedirectUrlMap {
  BOOKING_EXTENSION = "view-booking"
}

export const NotificationButton = () => {
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [badgeCount, setBadgeCount] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  useEffect(() => {
    socket.auth = {
      userId: userState.id,
    }; 
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useMemo(() => {
    socket.on("notification", (...args) => {
      setNotifications(prev => [args[0], ...prev])
    });
    socket.on("count", (...args) => {
      setBadgeCount(prev => prev + 1)
    });
  }, [])

  const { query: getNotifications, loading: loading } = useQuery<any, any>(api.getNotifications, {
    onSuccess: (data) => {
      setBadgeCount(0);
      setNotifications(data)
    }
  });
  const { query: getCount } = useQuery<any, any>(api.getCount, {
    onSuccess: (data) => {
      setBadgeCount(data.count)
    }
  });

  const { mutate: updateReadStatus } = useMutation<any, any>(api.updateReadStatus, {
    onSuccess: (data) => {
      const _notifications = [...notifications];
      const index = _notifications.findIndex(item => item.id === data.id);
      if (index >= 0) {
        _notifications[index] = data
        setNotifications(_notifications)
      }
    }
  });

  useEffect(() => {
    socket.emit("state", { state: Boolean(anchorEl) });
    if (anchorEl) {
      getNotifications(userState.id)
    }
    else {
      getCount(ReadStatus.PENDING)
    }
  }, [anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-menu" : undefined;

  const toggleReadStatus = (id: string, status: ReadStatus) => {
    updateReadStatus({
      notificationId: id,
      status
    })
  }

  const getTimeElapsed = (timeStamp: string) => {
    const minutes = Math.floor(Math.abs(d.fromISO(timeStamp).diffNow("minutes").minutes));
    const days = Math.floor(Math.abs(d.fromISO(timeStamp).diffNow("days").days));
    if (minutes === 0) {
      return "now"
    }
    else if (minutes > 0 && minutes < 60) {
      return `${Math.floor(minutes)} min`
    }
    else if (Math.abs(minutes) < 1440) {
      return `${Math.floor(minutes / 60)}h`
    } else {
      return `${days}d`
    }
  }

  return <>
    <IconButton
      edge="end"
      aria-label="account of current user"
      aria-haspopup="true"
      onClick={(event: any) => handleClick(event)}
      size="large">
      <Badge badgeContent={badgeCount} color="error">
        <NotificationsIcon className={classes.root} />
      </Badge>
    </IconButton>
    <Popover
      className={classes.popoverMenu}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <div className="container" style={{ height: 500 }}>
        <Typography variant="h3" className="bold">Notifications</Typography>
        {!loading && <Typography variant="body2" style={{ marginBottom: 20 }}>You have {notifications.filter(item => item.status === ReadStatus.UNREAD).length} unread notifications</Typography>}
        {loading ?
          <div className="margin-top">
            <CircularProgress style={{ height: 20, width: 20 }} />
          </div>
          : notifications.map((notification, index) => {
            return <div className={styles.item}>
              <div className={styles.status}>
                <IconButton
                  edge="start"
                  size="small"
                  onClick={() => {
                    toggleReadStatus(notification.id, notification.status === ReadStatus.READ ? ReadStatus.UNREAD : ReadStatus.READ)
                  }}
                  style={{ color: notification.status === ReadStatus.READ ? "#bababa" : "#0063ff" }}
                >
                  <DotIcon className={classes.root} color="inherit" style={{ fontSize: 12, color: "inherit !important" }} />
                </IconButton>
              </div>
              <div className={styles.body}>
                <Typography variant="body2" className="bold">
                  <span>{notification.title}</span>
                  <span style={{ fontWeight: "initial", opacity: 0.7, marginLeft: 10 }}>{getTimeElapsed(notification.timeStamp)}</span>
                </Typography>
                <Typography variant="body2">{notification.message}</Typography>
                {notification.branchId !== userState.currentBranch.id && <div>
                  <Typography variant="body2" className={styles.chip}>Branch: {notification.branch}</Typography>
                </div>}
              </div>
            </div>
          })}
      </div>
    </Popover>
  </>;
};
