import { IAdminUser } from "../../reducers/adminUser/types";
import { AdminUserAction, RESET_ADMIN_USER, UPDATE_ADMIN_USER } from "./types";

export function updateAdminUser(payload: IAdminUser): AdminUserAction {
    return {
        payload,
        type: UPDATE_ADMIN_USER
    };
}

export function resetAdminUser(): AdminUserAction {
    return {
        type: RESET_ADMIN_USER
    };
}
