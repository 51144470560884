import { gql } from "@apollo/client";

export const UPDATE_INVOICE = gql`
  mutation updateInvoice($invoiceId: String!, $invoiceItems: [InvoiceItemInput]!) {
    updateInvoice(invoiceId: $invoiceId, invoiceItems: $invoiceItems) {
      id
      dueAmount
      dueDate
      confirmedAt
      booking {
        id
        bookingType
        referenceNumber
        pickupDateTime
        dropoffDateTime
        taxExempted
        customerType
        autoChargeLater
        stripePaymentMethodId
        depositDetails {
          amount
          amountAdjusted
          payment {
            id
            status
            transactionId
            paymentMode
            paymentGateway
            dateCreated
            amount
            remainingAmount
          }
          adjustedAgainst {
            id
            invoiceRef
          }
          refund {
            amount
            refundRef
            status
            mode
            currency
            id
            paymentIntent
            paymentId
          }
        }
        currentBookingSchedules {
          id
          startDate
          endDate
          status
          vehicle {
            id
            licencePlate
          }
        }
        addonRequirements {
          addon {
            displayName
          }
          name
          hasFixedRate
        }
        rateTypeName
        billingFrequency
        isRecurringBilling
        paymentDetails {
          rentalTime
          isProrated
          standardBillingDays
          proratedBillingDays
        }
        status
        poNumber
      }
      invoiceItems {
        type
        description
        unitPrice
        totalAmount
        quantity
        duration
        taxRate
        taxAmount
        isProrated
        proratedBillingDays
        taxDescription
        rateTypeName
      }
      adjustedCreditNotes {
        amount
        adjustedAt
        creditNote {
          id
          creditNoteRef
          totalAmount
          refundedAmount
          availableAmount
        }
      }
      adjustedDeposit {
        amount
        adjustedAt
      }
      creditNotes {
        id
        creditNoteRef
        creditNoteType
        createdAt
        invoice {
          id
          invoiceRef
        }
        totalAmount
        refundedAmount
        availableAmount
        creditNoteItems {
          type
          description
          originalPrice
          quantity
          unitPrice
          totalAmount
          duration
        }
        refunds {
          mode
          amount
          status
          id
          refundRef
          currency
          paymentIntent
          paymentGateway
        }
        allocations {
          amount
          allocatedAt
          invoice {
            invoiceRef
          }
        }
      }
      invoiceUrl
      lastSent
      rentalAmount
      insuranceAmount
      damageAmount
      addonsAmount
      taxAmount
      rateTypeDuration
      rateTypeName
      valetAmount
      discountAmount
      balanceDueFromPreviousInvoice
      tax {
        appliedBy
        appliedDate
        code
        amount
        payableAmount
        type
        rate
        subdivisionOptIn
        subdivisionValue
        subdivisionType
        subdivisionRate
      }
      latestPayment {
        amount
        status
        createdBy
        updatedBy
      }
      latestRefund {
        amount
        status
        currency
        updatedBy
        expireBy
      }
      payments {
        amount
        status
        createdBy
        transactionId
        paymentMode
        paymentGateway
        id
        billingAddress {
          address1
          address2
          firstName
          lastName
        }
        dateCreated
        lastUpdated
        updatedBy
        note
        paymentType
        emailNotify
        expireBy
        currency
        description
        smsNotify
        invoice {
          id
        }
      }
      dateCreated
      dateUpdated
      createdBy
      updatedBy
      discount {
        appliedBy
        appliedDate
        isPercent
        code
        amount
      }
      invoiceRef
      status
      locked
      invoiceType
      refunds {
        amount
        status
        paymentMode
        paymentType
      }
      startDate
      endDate
      totalPayableAmount
      anyPaidAmount
      confirmedPaidAmount
      anyRefundAmount
      confirmedRefundAmount
      otherRefunds
      depositRefund
      balanceDueFromPreviousInvoice
      finesAmount
      valetAmount
      sageAccountingInvoiceId
      sageAccountingError
      otherCharges {
        amount
        name
      }
      id
      customer {
        id
        firstName
        lastName
        address
        email
        nationalInsuranceNumber
        phoneNumber {
          phone
          country
        }
        location {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      businessCustomer {
        id
        businessName
        registrationNumber
        VAT
        registeredAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        tradingAddressSame
        tradingAddress {
          street
          city
          state
          zipcode
          country
          fullAddress
        }
        approvedDrivers {
          id
          status
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        authorizedSignatories {
          id
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        billing {
          phoneNumber {
            phone
            country
          }
          email
        }
        contact {
          firstName
          lastName
          phoneNumber {
            phone
            country
          }
          email
        }
        documents {
          id
          name
          title
          documentType
          expiryDate
          url
        }
      }
      documents {
        title
        documentName
        documentType
        expiryDate
        documentUrl
        createdBy
        createdAt
      }
      invoices {
        id
        invoiceRef
      }
    }
  }
`;
