import { gql } from "@apollo/client";

export const CREATE_USER_ACCESS_POLICY = gql`
  mutation createUserAccessPolicy($userAccessPolicy: UserAccessPolicyInput) {
    createUserAccessPolicy(userAccessPolicy:$userAccessPolicy) {
      id
      name
      permissions
      selectedKeys
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_USER_ACCESS_POLICY = gql`
  mutation updateUserAccessPolicy($userAccessPolicyId: String!, $userAccessPolicy: UserAccessPolicyInput) {
    updateUserAccessPolicy(userAccessPolicyId: $userAccessPolicyId, userAccessPolicy: $userAccessPolicy) {
      id
      name
      permissions
      selectedKeys
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_USER_ACCESS_ROLE = gql`
  mutation createUserAccessRole($userAccessRole: UserRoleInput) {
    createUserAccessRole(userAccessRole:$userAccessRole) {
      id
      name
      key
      roleType
      policies{
        id
        permissions
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_USER_ACCESS_ROLE = gql`
  mutation updaeUserAccessRole($userAccessRoleId: String!, $userAccessRole: UserRoleInput) {
    updaeUserAccessRole(userAccessRoleId: $userAccessRoleId, userAccessRole: $userAccessRole) {
      id
      name
      policies{
        id
        permissions
      }
      createdAt
      updatedAt
    }
  }
`;