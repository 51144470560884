import { gql } from "@apollo/client";

export const UPDATE_VEHICLE_FINANCE = gql`
  mutation updateVehicleFinance(
    $id: String!
    $vehicleFinance: UpdateVehicleFinanceInput!
  ) {
    updateVehicleFinance(id: $id, vehicleFinance: $vehicleFinance) {
      vehicleId
      vehicleHoldingCost
      depreciationPercentage
      sourceType
      purchasePrice
      leasePrice
      paymentInterval
      leasedFrom
      purchasedFrom
      purchasedOn
      leasedOn
      leasedUntil
      financedFrom
      financedFromDate
      financedToDate
      financedPrice
      financedDeposit
      financedPaymentInterval
      rentedFrom
      rentedFromDate
      rentedToDate
      rentedPrice
      rentedPaymentInterval
      residualValue
      roadFundTax
    }
  }
`;
