import { gql } from "@apollo/client";

export const SEARCH_CONSOLIDATED_INVOICE = gql`
  query searchConsolidatedInvoice($q: String!, $limit: Int!, $offset: Int!) {
    searchConsolidatedInvoice(q: $q, limit: $limit, offset: $offset) {
      customerType
      dueDate
      dueAmount
      lastSent
      dateCreated
      dateUpdated
      createdBy
      updatedBy
      invoiceRef
      status
      locked
      invoiceType
      startDate
      endDate
      totalPayableAmount
      confirmedPaidAmount
      confirmedRefundAmount
      otherRefunds
      otherCharges {
        amount
        name
      }
      id
      businessCustomer {
        id
        businessName
        registrationNumber
        VAT
      }
    }
  }
`;
