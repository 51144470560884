import { Dialog, DialogContent, DialogTitle, Grid, Theme, Typography, Fab, FormControl, TextareaAutosize } from '@mui/material'
import { Field, Form, Formik } from 'formik';
import { createStyles, makeStyles } from "@mui/styles";
import { TextField } from 'formik-mui';
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from "uuid";
import { IPartner, IProposalRequest, IQuote } from '../../../../../reducers/Procurement/types'
import { NuvvenDivider } from '../../../ReservationManagement/Summary/Components/Divider';
import FlatPickerBar from "../../../../common/FlatPicker";
import { DateTime as d } from "luxon"
import { FloatInput } from '../../../../common/FloatInput/FloatInput';
import { GET_PARTNERS_LIST } from '../../../../../graphql/Procurement/getPartnersListQuery';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { DATE_TYPE, checkUploadFileFormat, uploadFileExtensionAndContentType } from '../../../../common/utils';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { DocumentDialog } from '../../../CustomerManagement/CustomerDetails/DocumentDialog';
import * as Yup from "yup";
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { captureErrorException } from '../../../../../utils/sentry';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      background: "#dee2e6"
    },
    formPaper: {
      padding: theme.spacing(3)
    },
    form: {
      flexGrow: 1,
      marginTop: 10
    }
  })
);

export const quoteInitials: IQuote = {
  proposalRequestId: "",
  date: "",
  amount: 0,
  validUntilDate: "",
  documents: [],
  lastEmailSent: "",
  note: {
    createdAt: "",
    createdBy: "",
    description: ""
  },
  partnerId: "",
  quoteId: "",
  status: ""
}

interface IProps {
  quote?: IQuote;
  open: boolean;
  onClose(): void;
  proposalRequests: IProposalRequest[];
  handelFormSubmit(quote: IQuote): void;
  procurementExpectedDate: string;
}


export const NewQuoteModel: React.FC<IProps> = (props) => {

  const { handelFormSubmit, procurementExpectedDate } = props

  const classes = useStyles();
  const snackbar = useSnackBar();

  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [values, setValues] = useState<IQuote>(quoteInitials);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [partner, setPartner] = useState<IPartner>()
  const [proposalRequests] = useState<IProposalRequest[]>(props.proposalRequests)
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [
    loadPartners,
    { loading: loadingPartners, data: partnersList }
  ] = useLazyQuery(GET_PARTNERS_LIST, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (userReducer.tenancy) {
      loadPartners()
    }
  }, [userReducer.tenancy])

  useEffect(() => {
    if (props.quote && props.quote.id) {
      setIsUpdate(true)
      setValues(props.quote)
    }
  }, [props.quote])

  useEffect(() => {
    if (partnersList && partnersList.partners) {
      setPartners(partnersList.partners)
      const partner = partnersList.partners.find((partner: IPartner) => {
        return partner.id === values.partnerId
      })
      setPartner(partner)
    }
  }, [partnersList])

  async function uploadDocument(
    file: File,
    values: any
  ) {
    try {
      if (!userReducer.tenancy?.id) {
        return;
      }
      // 5MB limit exceeded
      if (file.size > 5000000) {
        throw new Error("File size exceeded limit of 5MB");
      }
      setUploadInProgress(true);
      if (checkUploadFileFormat(file.type)) {
        const {
          fileExtension
        } = uploadFileExtensionAndContentType(file.type);
        const uniqueId = uuidv4();
        const key = `${userReducer.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                const updatedValues = {
                  ...values, documents: [...(values.documents || []), key]
                }
                setValues(prevValue => ({
                  ...prevValue,
                  ...updatedValues
                }))
                snackbar({
                  message: "Document Added",
                  variant: SnackBarVariant.SUCCESS
                });
                setUploadInProgress(false);
                setDocumentDialogVisible(false);
              }
            });
        }
      } else {
        setDocumentDialogVisible(false);
        setUploadInProgress(false);
        return snackbar({
          message: "Please only upload .pdf!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  const procurementValidationSchema = Yup.object().shape({
    quoteId: Yup.string().required("Quote ID is a required field."),
  });

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose()
        }}
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container item xs={12}>
            <Typography variant="h2">{isUpdate ? "Update Quote" : "New Quote"}</Typography>
          </Grid>
          <NuvvenDivider noMargin />
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Formik
              enableReinitialize
              validationSchema={procurementValidationSchema}
              initialValues={values}
              onSubmit={(values, { setSubmitting }) => {
                if (values.date && values.validUntilDate) {
                  if (values.amount) {
                    handelFormSubmit(values)
                  } else {
                    return snackbar({
                      message: "Amount can not be zero",
                      variant: SnackBarVariant.ERROR
                    });
                  }
                } else {
                  return snackbar({
                    message: "Please select the required dates.",
                    variant: SnackBarVariant.ERROR
                  });
                }
                setSubmitting(false)
              }}
            >
              {(props) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g TEST"
                        label="Quote Id"
                        name={"quoteId"}
                        value={props.values.quoteId}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                          setValues(prevValue=>({
                            ...prevValue,
                            quoteId: e.target.value
                          }))
                        }}
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    <Grid item xs={3}>
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          setValues({
                            ...props.values,
                            date: d.fromJSDate(value).toUTC().toISO()
                          })
                        }}
                        label={"Quote Date"}
                        identifier={"date"}
                        value={getLocalizedDateFormat(country, props.values.date, DATE_TYPE.CONDENSED)}
                        minDate={getLocalizedDateFormat(country, procurementExpectedDate, DATE_TYPE.CONDENSED)}
                        required
                        country={country}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          setValues({
                            ...props.values,
                            validUntilDate: d.fromJSDate(value).toUTC().toISO()
                          })
                        }}
                        label={"Valid Untill"}
                        identifier={"validUntilDate"}
                        value={getLocalizedDateFormat(country, props.values.validUntilDate, DATE_TYPE.CONDENSED)}
                        minDate={getLocalizedDateFormat(country, props.values.date, DATE_TYPE.CONDENSED)}
                        required
                        country={country}
                      />
                    </Grid>
                    <Grid item container xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g 10"
                        label="Quote Amount"
                        name={"amount"}
                        required
                        InputProps={{
                          value: props.values.amount,
                          inputComponent: FloatInput as any
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                        fullWidth
                        value={props.values.amount}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                          setValues(prevValue=>({
                            ...prevValue,
                            amount: Number(e.target.value)
                          }))
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Fab
                        className="blackBackButton"
                        variant="extended"
                        size="medium"
                        aria-label="Update"
                        onClick={() => {
                          setDocumentDialogVisible(true);
                        }}
                      >
                        Upload Quote
                      </Fab>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextareaAutosize
                          maxRows={10}
                          minRows={4}
                          placeholder="add your note here..."
                          value={props.values.note && props.values.note.description ? props.values.note.description : ""}
                          onChange={(e: any) => {
                            setValues({
                              ...props.values,
                              note: {
                                ...props.values.note,
                                description: e.target.value,
                                createdBy: userReducer.id || "",
                                createdAt: d.now().toUTC().toISO()
                              }
                            })
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-start">
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type="submit"
                      >
                        Save
                      </Fab>
                    </Grid>
                  </Grid>
                  <DocumentDialog
                    open={documentDialogVisible}
                    handleClose={() => {
                      setValues({
                        ...props.values
                      })
                      setDocumentDialogVisible(false)
                    }}
                    uploadInProgress={uploadInProgress}
                    onSubmit={uploadDocument}
                    accept="application/zip"
                    values={props.values}
                  />
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}
