import {
  Fab,
  Grid,
  IconButton,
  Typography,
  Paper
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  ICustomer,
  ICustomerDocument
} from "../../../../reducers/customer/types";
import { IAppState } from "../../../../store";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  checkDeleteOrDownloadFileType,
  checkUploadFileFormat,
  formatGraphQLErrorMessage,
  uploadFileExtensionAndContentType,
  returnSortedDocuments,
  DATE_TYPE,
  isImageFileType
} from "../../../common/utils";
import { DocumentDialog } from "./DocumentDialog";
import { UserRoles } from "../../../hoc/Authorization";
import { ConfirmationDialog } from "../../../common/ConfirmationDialog/ConfirmationDialog";
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";

interface ICustomerDocumentProps {
  customer: ICustomer;
  onChange(name: string, value: any): void;
}

interface IViewDocumentsProps {
  isOpen: boolean;
  deleteInProgress: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  customerDocuments: ICustomerDocument[];
  downloadDocument(documentKey: string, title: string): void;
  deleteDocument(documentKey: string, index: number | undefined): void;
}

export const CustomerDocuments = React.memo(
  (props: ICustomerDocumentProps) => {
    const snackbar = useSnackBar();
    // Get tenancy details
    const userState = useSelector((state: IAppState) => state.userReducer);
    const [documentDialogVisible, setDocumentDialogVisible] = React.useState<boolean>(false);
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
    const [documents, setDocuments] = useState<ICustomerDocument[]>([]);
    const { _e_ } = useSelector((state: IAppState) => state.authReducer);
    useEffect(() => {
      if (props.customer && props.customer.documents) {
        setDocuments(props.customer.documents);
      }
    }, [props.customer]);

    // Download the document from s3
    async function downloadDocument(documentKey: string, title: string) {
      if (!userState.tenancy?.id) {
        return;
      }
      const { fileExtension } = checkDeleteOrDownloadFileType(
        documentKey
      );
      const file = await getSignedUrl(documentKey)
      const url: any = file;

      fetch(url, {
        method: "GET"
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${title}.${fileExtension}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    }


    // Upload the document to s3
    async function uploadDocument(
      file: File,
      title: string,
      documentType: string,
      reminderBefore: number,
      expiryDate: string | undefined,
    ) {
      try {
        if (!userState.tenancy?.id) {
          return;
        }
        // 50MB limit exceeded
        if (file.type === "application/pdf" && file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        if (isImageFileType(file.type) && file.size > 5242880) {
          throw new Error("File size exceeded limit of 5MB");
        }
        const fileName = file.name.split(".")[0];
        setUploadInProgress(true);
        if (checkUploadFileFormat(file.type)) {
          const {
            fileExtension
          } = uploadFileExtensionAndContentType(file.type);
          const uniqueId = uuidv4();
          const key = `${userState.tenancy.id}/${uniqueId}.${fileExtension}`;
          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  let docs = props.customer.documents;
                  if (docs == null) {
                    docs = [];
                  }
                  props.onChange("customerDocuments", {
                    createdAt: new Date().toISOString(),
                    documentKey: key,
                    id: uniqueId,
                    title,
                    documentType,
                    reminderBefore,
                    expiryDate,
                    documentName: fileName
                  });
                  snackbar({
                    message: "Document Uploaded Successfully",
                    variant: SnackBarVariant.SUCCESS
                  });
                  setDocumentDialogVisible(false);
                  setUploadInProgress(false);
                }
              });
          }
        } else {
          setDocumentDialogVisible(false);
          setUploadInProgress(false);
          setTimeout(() => {
            return snackbar({
              message: "Please only upload PDF, PNG, JPG or JPEG files",
              variant: SnackBarVariant.ERROR
            });
          }, 2000);
        }
      } catch (err: any) {
        snackbar({
          message: formatGraphQLErrorMessage(err.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }

    // Remove the document
    function deleteDocument(documentKey: string, index: number) {
      setDeleteInProgress(true);
      // TODO: implement file deleteion from s3
      const filteredDoc = documents.find((document) => document.documentKey === documentKey);
      const documentId = filteredDoc?.id;
      snackbar({
        message: "Document Removed Successfully",
        variant: SnackBarVariant.SUCCESS
      });
      if (documentId) {
        props.onChange("delete", { documentId });
      }
      setDeleteInProgress(false);
      handleDialogClose();
    }

    const handleDialogOpen = () => {
      setOpenDeleteDialog(true)
    }

    const handleDialogClose = () => {
      setOpenDeleteDialog(false);
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
          <Typography variant="h2">ADDITIONAL DOCUMENTS</Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <Fab
            className="blackBackButton"
            variant="extended"
            size="medium"
            aria-label="Update"
            onClick={() => {
              setDocumentDialogVisible(true);
            }}
          >
            ADD DOCUMENT
          </Fab>
        </Grid>
        <div>
          <DocumentDialog
            open={documentDialogVisible}
            handleClose={() => setDocumentDialogVisible(false)}
            uploadInProgress={uploadInProgress}
            onSubmit={uploadDocument}
            accept="image/jpg, image/jpeg, image/png, application/pdf"
          />
        </div>
        <ViewDocuments
          isOpen={openDeleteDialog}
          deleteInProgress={deleteInProgress}
          handleOpen={handleDialogOpen}
          handleClose={handleDialogClose}
          customerDocuments={documents}
          downloadDocument={downloadDocument}
          deleteDocument={deleteDocument}
        />
      </Grid>
    );
  },
  (prevProps: ICustomerDocumentProps, nextProps: ICustomerDocumentProps) =>
    prevProps === nextProps
);

const ViewDocuments = (props: IViewDocumentsProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [deleteDocumentUrl, setDeleteDocumentUrl] = useState<string>("");
  const [documentIndex, setDocumentIndex] = useState<number>();

  const columns = [
    {
      label: "Document Type",
      name: "documentType",
    },
    {
      label: "Document Name/Title",
      name: "title"
    },
    {
      label: "Expiry Date",
      name: "expiryDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED) : "N/A"
      }
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <React.Fragment>
              <div>
                <IconButton
                  edge="end"
                  aria-label="download"
                  onClick={() =>
                    props.downloadDocument(value.documentKey, tableMeta.rowData[1])
                  }
                  size="large">
                  <CloudDownloadIcon />
                </IconButton>
                {(userState.role === UserRoles.SUPER_ADMIN || userState.role === UserRoles.ADMIN) && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      setDeleteDocumentUrl(value.documentKey);
                      setDocumentIndex(value.index);
                      props.handleOpen();
                    }}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </React.Fragment>
          );
        }
      }
    }
  ];

  const getRows = (customerDocuments: ICustomerDocument[]) => {
    if (!customerDocuments || customerDocuments === null) {
      return [];
    }
    const docs = [...customerDocuments]
    const sortedDocuments = returnSortedDocuments(docs)
    if (sortedDocuments && sortedDocuments.length > 0) {
      return sortedDocuments.map((customerDocument: ICustomerDocument, index: number) => {
        return {
          actions: { documentKey: customerDocument.documentKey, index },
          title: customerDocument.title || customerDocument.documentName,
          expiryDate: customerDocument.expiryDate,
          documentType: customerDocument.documentType
        };
      });
    } else {
      return []
    }
  };

  return (
    <Grid item xs={12}>
      <Paper variant='outlined'>
        <NuvvenTable
          key={new Date().getMilliseconds()}
          title={""}
          rows={getRows(props.customerDocuments)}
          columns={columns}
          setSelection={() => { }}
          options={{
            selectableRows: "none",
            download: false,
            filter: false,
            print: false,
            search: false,
            viewColumns: false,
            elevation: 0
          }}
        />
      </Paper>
      {props.isOpen && (
        <ConfirmationDialog
          isOpen={props.isOpen}
          onCancel={props.handleClose}
          onConfirm={() => {
            props.deleteDocument(deleteDocumentUrl, documentIndex);
          }}
          isInProgress={props.deleteInProgress}
          title=""
          description={"Are you sure, you want to delete this document?"}
        />
      )}
    </Grid>
  );
};
