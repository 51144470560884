import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Theme
} from "@mui/material";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";

import { history } from "./../../../routes";
import { useNavigate } from "react-router-dom";

interface IProps {
  title: string;
  icon: string;
  location: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      textAlign: "center"
    },
    img: {
      backgroundSize: "contain",
      height: 100,
      margin: 20,
      width: "auto"
    }
  })
);

export const SettingsCard = (props: IProps) => {
  const styles = useStyles();
  const navigate = useNavigate();

  function OnClick() {
    navigate(props.location);
  }
  return (
    <Card>
      <CardActionArea onClick={OnClick}>
        <CardMedia
          className={styles.img}
          image={props.icon}
          title={props.title}
        />
        <CardContent className={styles.center}>{props.title}</CardContent>
      </CardActionArea>
    </Card>
  );
};
