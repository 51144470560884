export const UPDATE_ADDON_TYPE = "UPDATE_ADDON_TYPE";
export const CLEAR_ADDON_TYPE = "CLEAR_ADDON_TYPE";

interface IUpdateAddonType {
  type: typeof UPDATE_ADDON_TYPE;
  payload: any;
}

interface IClearAddonType {
  type: typeof CLEAR_ADDON_TYPE;
}

export type AddonTypeAction = IUpdateAddonType | IClearAddonType;
