import { Box, Typography } from '@mui/material';
import React from 'react'

interface IProps {
  licencePlates: string[];
}
const RegistrationColumn: React.FC<IProps> = (props) => {

  return (
    <div>
      {
        props.licencePlates.length > 0 ? (
          <Box display="flex">
            <Typography variant={"body2"}>{props.licencePlates.slice(0, 1)}</Typography>
            {
              props.licencePlates.length > 1 && (
                <Typography color={"secondary"} variant={"body2"} style={{ marginLeft: 5 }}>
                  ... {props.licencePlates.slice(1, props.licencePlates.length).length} More
                </Typography>
              )
            }
          </Box>
        ) : "-"
      }
    </div>
  )
}

export default RegistrationColumn;
