import {
  Fab,
  FormControl,
  Grid,
  MenuItem,
  TextField
} from "@mui/material";
import { Field } from "formik";
import React from "react";
import { ITenancy } from "../../../reducers/user/types";

interface ITenancySelectorProps {
  tenancyId: string;
  tenancies: ITenancy[];
  handleTenancySelection: (id: string) => void;
}

export const TenancySelector: React.FC<ITenancySelectorProps> = (props) => {
  return (
    <Grid item container xs={12} sm={12}>
      <FormControl variant="outlined" fullWidth>
        <Field
          component={TextField}
          name={"tenancyId"}
          fullWidth
          type="text"
          select
          label="Tenancy"
          value={props.tenancyId}
          InputLabelProps={{
            shrink: true
          }}
          placeholder="Select Organisation"
          inputProps={{
            onChange: (e: any) =>
              props.handleTenancySelection(e.target.value)
          }}
        >
          {props.tenancies &&
            props.tenancies.map(
              (tenancy: ITenancy) => (
                <MenuItem
                  key={tenancy.id}
                  value={tenancy.id}
                >
                  {tenancy.name}
                </MenuItem>
              )
            )}
        </Field>
      </FormControl>
    </Grid>
  )
}