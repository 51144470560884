import { gql } from "@apollo/client";

export const GET_DTC_ALERTS = gql`
  query telematicDtcEvents($deviceEsn: [String!], $startDate: String!, $endDate: String!){
    telematicDtcEvents(deviceEsn: $deviceEsn, startDate: $startDate, endDate: $endDate){
      deviceEsn
      eventTime
      eventType
      latitude
      longitude
      description
      address{
        city
        street
      }
      gpsSpeed
      codeType
    }
  }
`

export const GET_CRASHBOX_EVENTS = gql`
  query telematicCrashboxEvents($deviceEsn: [String!], $startDate: String!, $endDate: String!){
    telematicCrashboxEvents(deviceEsn: $deviceEsn, startDate: $startDate, endDate: $endDate){
      deviceEsn
        crashes {
        id
        pdf
        pdof
        addressLine1
        addressLine2
        city
        state
        country
        severity
        crashDirection
        crashId
        datetime
      }
    }
}
`
