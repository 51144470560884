import { Box, Fab, Grid, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateBooking } from '../../../../../actions/bookings/actions';
import { IVehiclePriceGroup } from '../../../../../reducers/vehiclePriceGroups/types'

interface IDropEvent {
  startDate: string;
  endDate: string;
  bookingId: string;
  bookingScheduleId: string;
  vehicleGroupId: string;
  previousVehicle: string;
  currentVehicle: string;
  rateTypeName?: string;
  rateTypeDuration?: number;
  vehicleGropups?: IVehiclePriceGroup[];
}
interface IProps {
  dialogData?: IDropEvent;
  handleClose(type?: boolean): void;
}
export const ConfirmBookingDrop: React.FC<IProps> = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dialogData, handleClose } = props

  return (
    <Grid container item xs={12} spacing={2} style={{ marginBottom: 20 }}>
      <Grid item xs={12}>
        <Typography variant={"h3"}>
          Are you sure to want update booking
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Fab
          variant={"extended"}
          size={"medium"}
          onClick={() => {
            if (dialogData) {
              dispatch(updateBooking({
                dropoffDateTime: dialogData.endDate,
                pickupDateTime: dialogData.startDate
              }));
              navigate(`/update-booking?booking=${dialogData.bookingId}&step=0`);
            }
          }}
        >
          Yes
        </Fab>
        <Box pl={2}></Box>
        <Fab
          variant={"extended"}
          size={"medium"}
          className={"blackBackButton"}
          onClick={() => handleClose(true)}
        >
          No
        </Fab>
      </Grid>
    </Grid>
  )
}
