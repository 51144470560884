import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Theme, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from 'react'
import { IBooking, IBookingSubscription } from '../../../../../reducers/bookings/types';
import { SimpleDateTimePicker } from '../../../../common/SimpleDateTimePicker';
import { IDateTime } from '../../../../../reducers/jobSchedular/types';
import { DateTime as d } from "luxon"
import { useLazyQuery } from '@apollo/client';
import { GET_SUBSCRIPTION_TERMINATE_AMOUNT } from '../../../../../graphql/bookings/getSubscriptionEarlyTerminateAmountQuery';
import { DATE_TYPE, toCurrency } from '../../../../common/utils';
import { GET_SUBSCRIPTION_CREDIT_AMOUNT } from '../../../../../graphql/bookings/getSubscriptionCreditNoteAmountQuery';
import { TerminateOptionTypes, terminateOptions } from '../const';
import { TextField } from 'formik-mui';
import { FloatInput } from '../../../../common/FloatInput/FloatInput';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { RATE_TYPES } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      flexGrow: 1
    },
    flexDirectionRow: {
      flexDirection: "row"
    },
    typoGraphyMargin: {
      marginLeft: 15
    },
    dialogContentScrollBar: {
      overflow: "visible"
    }
  })
);


interface IProps {
  open: boolean;
  onCancel: () => void;
  subscription: IBookingSubscription;
  booking: IBooking;
  onConfirm: (vehicleIds: string[], endDate: string, amount?: number, terminationType?: string) => void;
}

const EarlyTerminateSubscriptionDialog: React.FC<IProps> = (props) => {

  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const { locale, currency } = userState.currentOrganisation;

  const [terminationType, setTerminationType] = useState<TerminateOptionTypes>();
  const [newEndDate, setNewEndDate] = useState<string>("");
  const [suggestTerminateAmount, setSuggestTerminateAmount] = useState<number>(0);
  const [suggestCreditNoteAmount, setSuggestCreditNoteAmount] = useState<number>(0);

  const [
    loadEarlyTerminateAmount,
    { data: earlyTerminateAmount, loading: earlyTerminateAmountLoading }
  ] = useLazyQuery(GET_SUBSCRIPTION_TERMINATE_AMOUNT, {
    fetchPolicy: "no-cache"
  });

  const [
    loadEarlyTerminateCreditNoteAmount,
    { data: earlyTerminateCreditAmount, loading: earlyTerminateCreditAmountLoading }
  ] = useLazyQuery(GET_SUBSCRIPTION_CREDIT_AMOUNT, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (terminationType === TerminateOptionTypes.TERMINATION_CHARGES) {
      loadEarlyTerminateAmount({
        variables: {
          bookingId: props.booking.id,
          newEndDate
        }
      })
    }
    if (terminationType === TerminateOptionTypes.CREATE_CREDIT_NOTE) {
      loadEarlyTerminateCreditNoteAmount({
        variables: {
          bookingId: props.booking.id,
          newEndDate
        }
      })
    }
  }, [terminationType])

  useEffect(() => {
    if (earlyTerminateAmount) {
      setSuggestTerminateAmount(earlyTerminateAmount.getSubscriptionEarlyTerminateAmount)
    }
  }, [earlyTerminateAmount])

  useEffect(() => {
    if (earlyTerminateCreditAmount) {
      setSuggestCreditNoteAmount(earlyTerminateCreditAmount.getSubscriptionCreditNoteAmount)
    }
  }, [earlyTerminateCreditAmount])


  return (
    <Dialog
      open={props.open}
      maxWidth={"md"}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        className: classes.dialogPaper
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h2">
          Early Terminate Subscription
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContentScrollBar}>
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
            }}
          >
            {(formikProps) => (
              <Form>
                <Grid container item xs={12} spacing={2}>
                  <Grid container item xs={12}>
                    <Typography variant='h4'>Subscription: </Typography><Typography variant='body1' className={classes.typoGraphyMargin}>{props.subscription.subscription.name}</Typography>
                    <Typography variant='h4' className={classes.typoGraphyMargin}>Duration: </Typography><Typography variant='body1' className={classes.typoGraphyMargin}>{`${props.subscription.duration} ${props.subscription.subscription.rateTypeName === RATE_TYPES.WEEKLY ? "Week(s)" : "Month(s)"}`}</Typography>
                    <Typography variant='h4' className={classes.typoGraphyMargin}>Subscription End Date: </Typography><Typography variant='body1' className={classes.typoGraphyMargin}>{getLocalizedDateFormat(country, props.booking.dropoffDateTime || "", DATE_TYPE.EXPANDED)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h3'>Date & Time</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleDateTimePicker
                      date={newEndDate}
                      handleChange={(date: IDateTime) => {
                        if (date && date.date) {
                          setNewEndDate(date.date)
                          if (terminationType && terminationType === TerminateOptionTypes.TERMINATION_CHARGES) {
                            loadEarlyTerminateAmount({
                              variables: {
                                bookingId: props.booking.id,
                                newEndDate: date.date
                              }
                            })
                          }
                          if (terminationType && terminationType === TerminateOptionTypes.CREATE_CREDIT_NOTE) {
                            loadEarlyTerminateCreditNoteAmount({
                              variables: {
                                bookingId: props.booking.id,
                                newEndDate: date.date
                              }
                            })
                          }
                        }
                      }}
                      classId={"early-termination"}
                      name={"dropOffDateTime"}
                      dateTitle={"New Drop-off Date"}
                      timeTitle={"New Drop-off Time"}
                      minDate={d.fromISO(props.booking.pickupDateTime || "").toUTC().toISO()}
                      maxDate={d.fromISO(props.booking.dropoffDateTime || "").toUTC().toISO()}
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <FormLabel component={"legend"}><Typography variant='h3'>Charges Type</Typography></FormLabel>
                    <FormControl fullWidth component="fieldset">
                      <RadioGroup
                        name="terminateOption"
                        value={terminationType}
                        onChange={(event) => {
                          setTerminationType(event.target.value as TerminateOptionTypes)
                        }}
                        classes={{
                          root: classes.flexDirectionRow
                        }}
                      >
                        {
                          terminateOptions.map(option => <FormControlLabel disabled={!newEndDate} key={option.value} value={option.value} control={<Radio color="primary" />} label={option.label} />)
                        }
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {
                    earlyTerminateAmountLoading ? (<CircularProgress />) :
                      (terminationType === TerminateOptionTypes.TERMINATION_CHARGES && !earlyTerminateAmountLoading) && (
                        <Grid item xs={6}>
                          <Typography variant='h3'>Suggested Termination amount: {toCurrency(suggestTerminateAmount, currency, locale)}</Typography>
                          <Box mt={2}></Box>
                          <Field
                            component={TextField}
                            fullWidth
                            placeholder="Enter amount"
                            label="Amount"
                            required
                            name={"terminationCharges"}
                            InputProps={{
                              value: suggestTerminateAmount,
                              inputComponent: FloatInput as any,
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = parseInt(e.target.value);
                                setSuggestTerminateAmount(value)
                              }
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                          ></Field>
                        </Grid>
                      )
                  }
                  {
                    earlyTerminateCreditAmountLoading ? (<CircularProgress />) :
                      (terminationType === TerminateOptionTypes.CREATE_CREDIT_NOTE && !earlyTerminateCreditAmountLoading) && (
                        <Grid item xs={6}>
                          <Typography variant='h3'>Suggested credit note amount: {toCurrency(suggestCreditNoteAmount, currency, locale)}</Typography>
                          <Box mt={2}></Box>
                          <Field
                            component={TextField}
                            fullWidth
                            placeholder="Enter amount"
                            label="Amount"
                            required
                            name={"creditNoteCharges"}
                            InputProps={{
                              value: suggestCreditNoteAmount,
                              inputComponent: FloatInput as any,
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = parseInt(e.target.value);
                                setSuggestCreditNoteAmount(value)
                              }
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                          ></Field>
                        </Grid>
                      )
                  }
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Fab
          variant="extended"
          size="medium"
          className="blackBackButton"
          style={{ marginRight: "20px" }}
          onClick={() => {
            props.onCancel()
          }}
        >
          Cancel
        </Fab>
        <Fab
          variant="extended"
          size="medium"
          disabled={!newEndDate && !terminationType}
          onClick={() => {
            const amount = terminationType === TerminateOptionTypes.TERMINATION_CHARGES ? suggestTerminateAmount : suggestCreditNoteAmount;
            const bookingSchedules = props.booking.currentBookingSchedules.map((bs) => bs.id)
            if (terminationType === TerminateOptionTypes.NONE) {
              props.onConfirm(bookingSchedules, newEndDate)
            } else {
              props.onConfirm(bookingSchedules, newEndDate, amount, terminationType)
            }
          }}
        >
          Save
        </Fab>
      </DialogActions>
    </Dialog >
  )
}

export default EarlyTerminateSubscriptionDialog
