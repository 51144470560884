import { gql } from "@apollo/client";

export const GET_MOT_DETAILS = gql`
 query getMOTDetails($licencePlate: String!){
   getMOTDetails(licencePlate: $licencePlate){
     id
     vehicleTestDetails
     vehicleTestReports {
        testDate
        expiryDate
        testResult
        odometerReading
        odometerUnit
        motTestNumber
        advisoryItems
        minorItems
        failureItems
      }
   }
 }
`

export const GET_VEHICLE_TAX_DETAILS = gql`
 query getVehicleTaxDetails($licencePlate: String!){
   getVehicleTaxDetails(licencePlate: $licencePlate){
     id
     licencePlate
     taxDetails
     taxStatus
     taxed
   }
 }
`