export const vehicleUploadImages = [
  {
    id: "front",
    class: "carFrontImg",
    title: "Front Side"
  },
  {
    id: "rear",
    class: "carRearImg",
    title: "Rear"
  },
  {
    id: "top",
    class: "carTopImg",
    title: "Top Side"
  },
  {
    id: "left",
    class: "carLeftImg",
    title: "Left Side"
  },
  {
    id: "right",
    class: "carRightImg",
    title: "Right Side"
  }
];

export const vehicleCheckTitles: { [key: string]: string } = {
  exterior: "EXTERIOR CHECKS",
  interior: "INTERIOR CHECKS",
  defaultAccesories: "DEFAULT ACCESSORIES",
  tyre: "TYRES",
  jack: "Jack",
  wrench: "Wrench",
  stepney: "Stepney",
  tyreOpener: "Tyre Opener",
  rearCamera: "Rear Camera",
  description: "Description",
  generalChecks: "GENERAL CHECKS",
  frontSeat: "Front Seat",
  rearSeat: "Rear Seat",
  lockAndSwitch: "LOCK AND SWITCH",
  frontLeft: "FRONT LEFT",
  frontRight: "FRONT RIGHT",
  rearLeft: "REAR LEFT",
  rearRight: "REAR RIGHT",
  odour: "Odour",
  carpet: "Carpet",
  mat: "Mat",
  seatCover: "Seat Cover",
  clean: "Clean",
  controls: "Controls",
  seatBelt: "Seat Belt",
  frontDoor: "Front Door",
  rearDoor: "Rear Door",
  frontWindow: "Front Window",
  rearWindow: "Rear Window",
  childLock: "Child Lock",
  condition: "Condition",
  pressure: "Pressure"
};
