import { Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Typography } from '@mui/material';
import React from 'react'

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isActive: boolean;
}
const ConfirmToggleAppSetting: React.FC<IProps> = (props) => {

  const { open, onClose, onConfirm, isActive } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="app-setting-toggle-dialog" open={open}>
      <DialogTitle style={{ padding: 15 }}><Typography variant={"h3"}>CONFIRM ACTION</Typography></DialogTitle>
      <DialogContent style={{ overflowY: "hidden" }}>
        {
          isActive ? (
            <Grid item xs={12}>
              <Typography variant={"h3"}>Are you sure you want to deactivate App Setting ?</Typography>
            </Grid>
          ) :
            (
              <Grid item xs={12}>
                <Typography variant={"h3"}>Are you sure you want to activate App Setting ?</Typography>
              </Grid>
            )
        }
      </DialogContent>
      <DialogActions>
        <Fab
          size="medium"
          variant="extended"
          className="blackBackButton"
          onClick={onClose}
        >
          Cancel
        </Fab>
        <Fab
          size="medium"
          variant="extended"
          onClick={onConfirm}
        >
          Confirm
        </Fab>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmToggleAppSetting
