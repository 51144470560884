import { useMutation } from "@apollo/client";
import {
  Fab,
  Grid,
  Typography,
} from "@mui/material";
import { ApolloError } from "@apollo/client";
import { Field, FieldProps, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import PhoneInput, { Value, isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { CircularProgress } from "@mui/material";
import {
  resetAuthorisedSignatory,
  setBusinessCustomer
} from "../../../../../../actions/customer/actions";
import {
  ADD_BUSINESS_AUTHORISEDSIGNATORY,
  UPDATE_BUSINESS_AUTHORISEDSIGNATORY
} from "../../../../../../graphql/businessCustomers/businessAuthorisedSignatory";
import {
  IBusinessCustomer,
  IBusinessCustomerCommonInput
} from "../../../../../../reducers/customer/types";
import { IAppState } from "../../../../../../store";
import { getDefaultCountryCode } from "../../../../../../utils/localized.syntex";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../../common/utils";
import {
  validateName
} from "../../../../../common/ValidationRules";
import { AuthorisedSignatoriesList } from "./AuthorisedSignagoriesList";

export const AuthorisedSignatories = (props: any) => {
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const businessCustomer: IBusinessCustomer = useSelector(
    (state: IAppState) => state.customerReducer.businessCustomer
  );
  const authorisedSignatory: IBusinessCustomerCommonInput = useSelector(
    (state: IAppState) => state.customerReducer.authorisedSignatory
  );
  const [values, setValues] = useState<IBusinessCustomerCommonInput>(
    authorisedSignatory
  );
  const [countryCode, setCountryCode] = useState<any>(getDefaultCountryCode(country));
  const [phoneError, setPhoneError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [authorisedSignatoryLoading, setauthorisedSignatoryLoading] = useState<boolean>(false);
  const [addAuthSignatory] = useMutation(ADD_BUSINESS_AUTHORISEDSIGNATORY, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      snackbar({
        message: "Authorised signatory added",
        variant: SnackBarVariant.SUCCESS
      });
      dispatch(setBusinessCustomer(data.addBusinessAuthorizedSignatory));
      dispatch(resetAuthorisedSignatory());
      setauthorisedSignatoryLoading(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setauthorisedSignatoryLoading(false);
    }
  });

  const [updateAuthSignatory] = useMutation(
    UPDATE_BUSINESS_AUTHORISEDSIGNATORY,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        snackbar({
          message: "Authorised signatory updated",
          variant: SnackBarVariant.SUCCESS
        });
        dispatch(setBusinessCustomer(data.updateBusinessAuthorizedSignatory));
        dispatch(resetAuthorisedSignatory());
        setLoading(false);
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        setLoading(false);
      }
    }
  );

  useEffect(() => {
    setValues(authorisedSignatory);
  }, [authorisedSignatory]);

  const submitFormHandler = (
    values: IBusinessCustomerCommonInput
  ) => {
    const authorizedSignatoryId = values.id;
    delete values.id;
    delete values.status;
    if (authorizedSignatoryId && businessCustomer && businessCustomer.id) {
      updateAuthSignatory({
        variables: {
          businessCustomerId: businessCustomer.id,
          authorizedSignatoryId,
          authorizedSignatory: values
        }
      });
      setLoading(true);
    } else {
      addAuthSignatory({
        variables: {
          businessCustomerId: businessCustomer.id,
          authorizedSignatory: values
        }
      });
      setauthorisedSignatoryLoading(true);
    }
    setValues({
      ...values,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: {
        phone: "" as Value,
        country: ""
      }
    })
  };

  const authorisedSignatorySchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string()
      .required("Email is required.")
      .email("Please enter a valid email address."),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-b-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" });
          }
          return true;
        })
        .required("Phone number is required.")
    }),
  });
  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={authorisedSignatorySchema}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          const updateValues = {
            ...values,
            phoneNumber: {
              ...values.phoneNumber,
              country: countryCode
            }
          };
          submitFormHandler(updateValues)
          setSubmitting(false)
        }}
      >
        {(props) => (
          <Form noValidate>
            <Grid container>
              <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12}>
                  <Typography variant="h2">AUTHORISED SIGNATORIES</Typography>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="firstName"
                    placeholder="First Name"
                    label="First Name"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          firstName: e.target.value
                        })
                      },
                      value: props.values.firstName
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="lastName"
                    placeholder="Last Name"
                    label="Last Name"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          lastName: e.target.value
                        })
                      },
                      value: props.values.lastName
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name={"phoneNumber.phone"}
                    required
                  >
                    {({ form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                      return (
                        <div className={`phone-input-container`}>
                          <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                            Phone Number <sup className={"phone-input-required"}>*</sup>
                          </label>
                          <PhoneInput
                            international={false}
                            defaultCountry={countryCode}
                            placeholder="Enter phone number"
                            className={phoneError ? "phone-input-error" : ""}
                            value={props.values.phoneNumber.phone}
                            name={"phoneNumber.phone"}
                            required
                            onChange={(val: any) => {
                              if (val && isValidPhoneNumber(val)) {
                                setPhoneError("");
                              }
                              setValues({
                                ...props.values,
                                phoneNumber: {
                                  ...props.values.phoneNumber,
                                  phone: val
                                }
                              });
                            }}
                            onBlur={() => {
                              if (!props.values.phoneNumber.phone) {
                                setPhoneError("Phone number is required.");
                              } else if (!isValidPhoneNumber(props.values.phoneNumber.phone)) {
                                setPhoneError("Enter a valid phone number.");
                              } else {
                                setPhoneError("");
                              }
                            }}
                            onCountryChange={(val) => {
                              setCountryCode(val);
                            }}
                          />
                          {
                            phoneError !== "" ?
                              <span className={"phone-error-message"}>{phoneError}</span>
                              : (meta.touched && meta.error) &&
                              <span className={"phone-error-message"}>
                                {phoneError || "Phone number is required."}
                              </span>
                          }
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Field
                    component={TextField}
                    name="email"
                    placeholder="Email Address"
                    label="Email Address"
                    inputProps={{
                      onchange: (e: any) => {
                        setValues({
                          ...props.values,
                          email: e.target.value
                        })
                      },
                      value: props.values.email
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item container xs={12} justifyContent="flex-start">
                  <Fab
                    className="blackBackButton"
                    variant="extended"
                    size="medium"
                    aria-label="add"
                    type="submit"
                    disabled={authorisedSignatoryLoading || loading}
                  > {authorisedSignatoryLoading && (
                    <CircularProgress
                      size={14}
                      style={{ color: "white", marginRight: "10px" }}
                    />
                  )}
                    {loading && (
                      <CircularProgress
                        size={14}
                        style={{ color: "white", marginRight: "10px" }}
                      />
                    )}

                    {authorisedSignatory.id ? "SAVE" : "ADD"}
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <AuthorisedSignatoriesList
        authorizedSignatories={businessCustomer.authorizedSignatories}
      />
    </div>
  );
};
