import { gql } from "@apollo/client";

export const GET_VEHICLES_EVENTS = gql`
  query vehicleEvents($startDate: String!, $endDate: String!,$limit: Int!, $offset: Int!, $status: String!) {
    vehicleEvents(startDate: $startDate, endDate: $endDate, limit:$limit, offset:$offset, status: $status) {
			id
			services {
				id
				status
				startDate
				endDate
				serviceType
			}
			holds {
				id
				startDate
				endDate
			}
    }
  }
`;
