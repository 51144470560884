import { useMutation } from "@apollo/client";
import { Box, CircularProgress, Fab, Grid, Typography } from "@mui/material";
import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CREATE_PAYMENT } from "../../../../../graphql/invoices/createPaymentMutation";
import { IInvoice, IPaymentInput, PaymentMode } from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage, PaymentGateway } from "../../../../common/utils";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { DateTime as d } from "luxon";
import FlatPickerBar from "../../../../common/FlatPicker";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";

interface IProps {
  bookingId: string;
  invoice: string;
  businessCustomer?: string;
  amount: number;
  longTermBooking?: boolean;
  redirectToInvoice?: boolean;
  invoiceData?: IInvoice;
  closeDialog(): void;
  minDate?: string;
  validAmount?: number;
  isConsolidated?: boolean;
}

export const OfflineBankTransaction: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const [loading, setLoading] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [amount, setAmount] = useState<number>(props.amount);
  const [transactionId, setTransactionId] = useState<string>("");
  const [paymentInput, setPaymentInput] = useState<IPaymentInput>();
  const [capturedAt, setCapturedAt] = useState<string>("");
  const { locale, currency, offlineBankTransferEnabled } = userState.currentOrganisation;

  const [
    createPayment
  ] = useMutation(CREATE_PAYMENT, {
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: async (data) => {
      if (data && data.createPayment) {
        setLoading(false);
        props.closeDialog();
      }
    }
  });

  useEffect(() => {
    setAmount(props.amount)
  }, [props.amount])

  const handleSubmit = () => {
    setLoading(true);
    const payment: IPaymentInput = {
      amount: amount,
      booking: props.bookingId || '',
      businessCustomer: props.businessCustomer,
      currency,
      description: PaymentMode.OFFLINE_BANK_TRANSFER,
      transactionId: transactionId,
      expireBy: "",
      invoice: props.invoice,
      paymentMode: PaymentMode.OFFLINE_BANK_TRANSFER,
      paymentGateway: PaymentGateway.OFFLINE,
      paymentType: "INWARD", 
      capturedAt: capturedAt
    };
    setPaymentInput(payment);
    createPayment({
      variables: {
        payment: {
          ...payment,
          booking: props.bookingId || ''
        },
        isConsolidated: props.isConsolidated
      }
    });
  };

  const AMOUNT_TOO_HIGH = `Amount must be less than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format((props.validAmount || 0) / 100)}`;

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan(0, `Amount must be more than 0`)
      .max((props.validAmount || 0), AMOUNT_TOO_HIGH),
    transactionId: Yup.string().required("Reference Number is required.")
  });

  return (
    <Grid container style={{ display: "inline-block", padding: "1rem" }}>
      {!offlineBankTransferEnabled ?
        <Grid container>
          <Typography variant="h4">
            To use this payment mode please enable offline bank transfer in Payment and Integration Settings.
          </Typography>
        </Grid> :
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              amount: amount, transactionId: transactionId
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit();
              setSubmitting(false);
            }}
          >
            {(formikProps) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid container item xs={12}>
                    <Box mt={0.2}></Box>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Field
                      component={TextField}
                      placeholder="Reference Number"
                      label="Reference Number"
                      name={"transactionId"}
                      fullWidth
                      required
                      InputProps={{
                        onChange: (
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setTransactionId(e.target.value)
                        },
                        value: transactionId
                      }}
                    />
                    <Typography variant={"body2"} color="textSecondary">
                      This could be anything like Receipt Number etc., and is
                      only for internal use.
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <Box mt={0.2}></Box>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FlatPickerBar
                      enableTime={false}
                      handleDateChange={(value: Date) => {
                        setCapturedAt(d.fromJSDate(value).toUTC().toISO())
                      }}
                      minDate={getLocalizedDateFormat(country, props.minDate || "today", DATE_TYPE.CONDENSED)}
                      maxDate={getLocalizedDateFormat(country, d.now().endOf("day").toUTC().toISO(), DATE_TYPE.CONDENSED)}
                      label={"Payment Date"}
                      placeholderValue={"Select Payment Date*"}
                      value={getLocalizedDateFormat(country, capturedAt, DATE_TYPE.CONDENSED)}
                      country={country}
                    />
                    <Grid container item xs={12}>
                      <Box mt={0.2}></Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      placeholder="Enter Amount"
                      label="Enter Amount"
                      name={"amount"}
                      fullWidth
                      required
                      InputProps={{
                        onChange: (
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setAmount(parseInt(e.target.value))
                        },
                        value: amount,
                        inputComponent: FloatInput as any
                      }}
                      inputProps={{
                        hasCurrencyPrefix: true,
                        allowNegative: false
                      }}
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <Box mt={0.2}></Box>
                  </Grid>
                  <Grid item xs={12} container justifyContent="flex-start">
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="add"
                      onClick={() => {
                        formikProps.setSubmitting(false);
                        formikProps.handleSubmit();
                      }}
                      disabled={!capturedAt || loading}
                    >
                      {loading && (
                        <CircularProgress
                          size={14}
                          style={{ color: "white", marginRight: "10px" }}
                        />
                      )}
                      Save
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      }
    </Grid>
  )
}