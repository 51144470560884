import {
  DELETE_VEHICLE,
  RESET_VEHICLE,
  RESET_VEHICLE_DAMAGE,
  RESET_VEHICLE_FINANCE,
  RESET_VEHICLE_SERVICE,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_DAMAGE,
  UPDATE_VEHICLE_FINANCE,
  UPDATE_VEHICLE_SERVICE
} from "../../actions/fleet/vehicle/types";
import { initialState } from "./const";
import { IFleetState } from "./types";

export default function(
  state: IFleetState = initialState,
  action: any
): IFleetState {
  switch (action.type) {
    case UPDATE_VEHICLE:
      return {
        ...state,
        vehicle: { ...action.payload }
      };
    case DELETE_VEHICLE:
      return {
        ...state
      };
    case RESET_VEHICLE:
      return {
        ...state,
        vehicle: { ...initialState.vehicle }
      };
    case UPDATE_VEHICLE_SERVICE:
      return {
        ...state,
        vehicleService: { ...action.payload }
      };
    case RESET_VEHICLE_SERVICE:
      return {
        ...state,
        vehicleService: { ...initialState.vehicleService }
      };
    case UPDATE_VEHICLE_DAMAGE:
      return {
        ...state,
        vehicleDamage: { ...action.payload }
      };
    case RESET_VEHICLE_DAMAGE:
      return {
        ...state,
        vehicleDamage: { ...initialState.vehicleDamage }
      };
    case UPDATE_VEHICLE_FINANCE:
      return {
        ...state,
        vehicleFinance: { ...action.payload }
      };
    case RESET_VEHICLE_FINANCE:
      return {
        ...state,
        vehicleFinance: { ...initialState.vehicleFinance }
      };
    default:
      return state;
  }
}
