import { gql } from "@apollo/client";

export const ADD_BUSINESS_CUSTOMER_DOCUMENTS = gql`
  mutation addBusinessCustomerDocument(
    $businessCustomerId: ID!
    $businessDocument: BusinessCustomerDocumentInput!
  ) {
    addBusinessCustomerDocument(
      businessCustomerId: $businessCustomerId
      businessDocument: $businessDocument
    ) {
      id
      businessName
      registrationNumber
      VAT
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone 
          country
        }
        email
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
      coiDetails {
        policyName
        policyNumber
        provider
        expiryDate
        documentUrl
      }
    }
  }
`;

export const UPDATE_BUSINESS_CUSTOMER_DOCUMENTS = gql`
  mutation updateBusinessCustomerDocument(
    $businessCustomerId: ID!
    $businessDocumentId: ID!
    $businessDocument: BusinessCustomerDocumentInput!
  ) {
    updateBusinessCustomerDocument(
      businessCustomerId: $businessCustomerId
      businessDocumentId: $businessDocumentId
      businessDocument: $businessDocument
    ) {
      id
      businessName
      accountManager {
        id
        firstName
        lastName
      }
      registrationNumber
      VAT
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone 
          country
        }
        email
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone 
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
      coiDetails {
        policyName
        policyNumber
        provider
        expiryDate
        documentUrl
      }
    }
  }
`;

export const DELETE_BUSINESS_CUSTOMER_DOCUMENTS = gql`
  mutation deleteBusinessCustomerDocument(
    $businessCustomerId: ID!
    $businessDocumentId: ID!
  ) {
    deleteBusinessCustomerDocument(
      businessCustomerId: $businessCustomerId
      businessDocumentId: $businessDocumentId
    ) {
      id
      businessName
      registrationNumber
      VAT
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone
          country
        }
        email
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
    }
  }
`;
