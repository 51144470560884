import { gql } from "@apollo/client";

export const GET_PRICE_RULE = gql`
  query priceRule($id: String!) {
    priceRule(id: $id) {
      id
      name
      vehicleGroups
      customers
      serviceLocations
      operator
      isActive
      rules {
        rateType
        value
        taxable
        valueType
        mileageLimitOptIn
        mileageLimit
        pricePerExtraMile
        unlimitedMileage
        dateRangeOptIn
        durationOptIn
        timeRangeOptIn
        pickupDropoffDaysOptIn
        ruleEffectiveFromDate
        ruleEffectiveToDate
        ruleEffectiveFromTime
        ruleEffectiveToTime
        minimumBookingDuration
        maximumBookingDuration
        pickupDays
        dropoffDays
      }
    }
  }
`;


export const GET_LOCATION_SURCHARGE_RULE = gql`
  query priceRule($id: String!) {
    priceRule(id: $id) {
      id
      name
      vehicleGroups
      customers
      serviceLocations
      operator
      isActive
      rules {
        rateType
        value
        taxable
        valueType
      }
    }
  }
`;
