import { captureErrorException } from "../../../utils/sentry";

export enum ResponseStatus {
  VEHICLE_EXISTS,
  VEHICLE_FOUND,
  VEHICLE_NOT_FOUND,
  INVALID_LICENCE_PLATE,
  DVLA_API_ERROR
}

export enum VehicleInfoSource {
  DVLA = "dvla",
  BULK_CSV = "bulk-csv",
  MANUAL = "manual"
}

export const vehicleNonNullableFields: string[] = [
  "bodyType",
  "colour",
  "cylinderCapacity",
  "fuelType",
  "licencePlate",
  "make",
  "model",
  "year",
  "transmission",
  "assetTag",
  "frogDiagram"
];

export enum VehicleStatus {
  ACTIVE = "Active",
  OUT_ON_HIRE = "Out on Hire",
  AVAILABLE_FOR_HIRE = "Available_for_hire",
  IN_SERVICE = "In Service",
  DEACTIVATED = "Deactivated",
  ON_HOLD = "hold"
}

export interface ICheckBox {
  checked: boolean;
  value: string;
}

export enum VehicleDriveHoursType {
  DRIVE_HOURS = "driveHours",
  STOP_HOURS = "stopHours",
  IDLE_HOURS = "idleHours"
}

export const secondToTime = (timeInSeconds: any) => {
  let hours = Math.floor(timeInSeconds / 3600);
  let minutes = Math.floor((timeInSeconds % 3600) / 60);
  if (hours > 24) {
    let extraHours = hours - 24;
    hours = hours - extraHours;
  }
  return `${hours}:${minutes}`;
};

export enum EmissionType {
  EURO_4 = "EURO 4",
  EURO_6 = "EURO 6"
}

export const fuelTypes = [
  {
    label: "Diesel",
    value: "DIESEL"
  },
  {
    label: "Petrol",
    value: "PETROL"
  },
  {
    label: "Hybrid Electric (HEV)",
    value: "HYBRID ELECTRIC (HEV)"
  },
  {
    label: "Electricity",
    value: "ELECTRICITY"
  },
  {
    label: "Electric Diesel",
    value: "ELECTRIC DIESEL"
  },
  {
    label: "Gas Bi-Fuel",
    value: "GAS BI-FUEL"
  },
  {
    label: "Hybrid Electric (Clean)",
    value: "HYBRID ELECTRIC (CLEAN)"
  },
  {
    label: "Electric (EV)",
    value: "ELECTRIC (EV)"
  },
  {
    label: "Gasoline",
    value: "GASOLINE"
  }
];

export const emmissionTypes = [
  {
    label: "Euro 4",
    value: "EURO 4"
  },
  {
    label: "Euro 6",
    value: "EURO 6"
  },
]

export const ulezComplaints = [
  {
    label: "Yes",
    value: "YES"
  },
  {
    label: "No",
    value: "NO"
  }
]

export const ukVehicleLabel = [
  {
    label: "Yes",
    value: "YES"
  },
  {
    label: "No",
    value: "NO"
  }
]

export const validFuelTypes = [
  "DIESEL",
  "PETROL",
  "HYBRID ELECTRIC",
  "ELECTRICITY",
  "ELECTRIC DIESEL",
  "GAS BI-FUEL",
  "HYBRID ELECTRIC (CLEAN)",
  "ELECTRIC",
  "GASOLINE"
];

export const mapFuelTypesAndDisplayType = (fuelType?: string) => {
  let fuelOnly = false, batteryOnly = false, fuelAndBattery = false;
  fuelType = fuelType?.toUpperCase();
  switch (fuelType) {
    case "DIESEL":
    case "PETROL":
    case "GASOLINE":
    case "GAS BI-FUEL":
      fuelOnly = true;
      break;
    case "ELECTRICITY":
    case "ELECTRIC":
      batteryOnly = true;
      break;
    case "HYBRID ELECTRIC":
    case "ELECTRIC DIESEL":
    case "HYBRID ELECTRIC (CLEAN)":
    default:
      fuelAndBattery = true;
      break;
  }
  return {
    fuelOnly,
    batteryOnly,
    fuelAndBattery
  }
}

export const reverseGeoCodeLatLang = (lat: number, lang: number) => {
  try {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(lat, lang);
      geocoder.geocode({ location: latlng }, (results: any, status: any) => {
        if (status === 'OK') {
          const required_address_component_types = ["neighborhood", "locality", "sublocality", "administrative_area_level_2", "administrative_area_level_1"];
          for (const result of results) {
            const address_components_types = result.address_components?.map((c: any) => {
              return { short_name: c.short_name, type: c.types[0] }
            });
            const filtered_types = address_components_types.filter((c: any) => required_address_component_types.includes(c.type));
            if (filtered_types.length >= 3) {
              const address = filtered_types.map((d: any) => d.short_name).join(", ");
              resolve(address);
              break;
            }
          }
          resolve("");
        } else {
          resolve("");
        }
      });
    });
  } catch (error) {
    captureErrorException(error)
    return "";
  }
}

export const getChargingStatusText = (status?: string) => {
  switch (status) {
    case 'CHARGING':
      return 'Charging';
    case 'FULLY_CHARGED':
      return 'Charged';
    case 'NOT_CHARGING':
      return 'Unplugged';
    default:
      return 'N/A';
  }
}
