import { FC } from "react"
import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  FormControl,
  Grid,
  Typography,
  Box,
  Paper,
  MenuItem,
  Theme,
  TextField as InputField,
  Tooltip,
  FormControlLabel,
  Switch
} from "@mui/material";

import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import Autocomplete from "@mui/material/Autocomplete";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import * as Yup from "yup";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { AssetInitialValue, AssetTypesMenu, IAsset, IAssetInput, TAssetType } from "../asset.types";
import { SEARCH_ALL_VEHICLES } from "../../Fleet/Graphql/getVehiclesQuery";
import { VehicleStatus } from "../../Fleet/utils";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import FlatPickerBar from "../../../common/FlatPicker"
import { DateTime as d } from "luxon"
import { formatGraphQLErrorMessage } from "../../../common/utils";
import { CREATE_ASSET, UPDATE_ASSET, UPDATE_ASSET_STATUS } from "../Graphql/assetMutations";
import DragDropImages from "../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";
import { getSignedUrl } from "../../../../utils/getSignedUrl";
import { assetFrogDiagramTypes, frogDiagramTypes } from "../../Fleet/VehicleInventory/ViewVehicle/Details/constants";
import { DamageTypes } from "../../../common/VehicleState/VehicleState";
import { getFrogDiagram, IFrogDiagram } from "../../../common/VehicleDamagePreview/frogDiagram";
import { svgHeight } from "../../Fleet/Issues&Damages/NewDamage";
import { FloatInput } from "../../../common/FloatInput/FloatInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    form: {
      flexGrow: 1
    },
    active: {
      fontWeight: "bold",
      fontSize: "initial !important"
    },
    saveButton: {
      backgroundColor: "#faaf40",
      border: 0,
      borderRadius: 26,
      fontSize: "14px",
      color: "white",
      minHeight: 43,
      minWidth: "auto",
      marginLeft: 40,
      marginTop: 20
    },
    img: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "320px"
    },
  })
);

interface IProps {
  asset?: IAsset | undefined
}

export enum FrogDiagramTypeEnum {
  EXTERIOR = 'exterior',
  INTERIOR = 'interior',
  OTHER = 'other',
}

export const NewAsset: FC<IProps> = ({ asset }) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;

  const [values, setValues] = useState<IAsset>(AssetInitialValue);
  const [isNewAsset, setIsNewAsset] = useState(true);
  const [licencePlate, setLicencePlate] = useState([]);
  const [query, setQuery] = useState<string>("");
  const [isImageUploadOpen, setImageUploadOpen] = useState(false);
  const [imageUploadInProgress, setImageUploadInProgress] = useState(false);
  const [assetImage, setAssetImage] = useState<any>("");
  const [diagramType, setDiagramType] = useState<string>(DamageTypes.EXTERIOR);
  const [svg, setSvg] = useState<IFrogDiagram>();
  const [selectedVehicleBranch, setSelectedVehicleBranch] = useState<{ id: string, name: string }>({ id: "", name: "" });

  const [searchOwnedAndExternalVehicles, { loading: searchOwnedAndExternalVehiclesLoading, data: searchOwnedAndExternalVehiclesData }] = useLazyQuery(SEARCH_ALL_VEHICLES, {
    fetchPolicy: "network-only"
  })

  const [createAsset, { loading: createAssetLoading }] = useMutation(CREATE_ASSET, {
    onCompleted: () => {
      snackbar({
        message: "Asset saved successfully",
        variant: SnackBarVariant.SUCCESS
      })
      navigate(`/asset-inventory`);
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateAsset, { loading: updateAssetLoading }] = useMutation(UPDATE_ASSET, {
    onCompleted: () => {
      snackbar({
        message: "Asset updated successfully",
        variant: SnackBarVariant.SUCCESS
      })
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const [updateAssetStatus, { loading: updateAssetStatusLoading }] = useMutation(UPDATE_ASSET_STATUS, {
    onCompleted: ({ updateAssetStatus }) => {
      snackbar({
        message: updateAssetStatus.message,
        variant: SnackBarVariant.SUCCESS
      })
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  useEffect(() => {
    if (asset) {
      setIsNewAsset(false);
      setValues({
        ...asset,
        licencePlate: asset.vehicle && typeof asset.vehicle !== "string" ? asset.vehicle.licencePlate : asset.licencePlate
      });
      if (asset.vehicle && typeof asset.vehicle !== 'string') {
        setSelectedVehicleBranch({ id: asset.vehicle.branchDetails?.id ?? "", name: asset.vehicle.branchDetails?.name ?? "" });
      }
      setSvg(getFrogDiagram(asset.frogDiagram))
      getAssetImage(asset.images[0]);
    }
  }, [asset])

  useEffect(() => {
    if (searchOwnedAndExternalVehiclesData && searchOwnedAndExternalVehiclesData.searchOwnedAndExternalVehicles) {
      const vehicles = searchOwnedAndExternalVehiclesData.searchOwnedAndExternalVehicles.filter(
        (vehicle: any) => vehicle.status !== VehicleStatus.DEACTIVATED
      );
      setLicencePlate(vehicles);
    }
  }, [searchOwnedAndExternalVehiclesData]);

  useEffect(() => {
    if (query) {
      handleSearch(query)
    } else {
      setLicencePlate([])
    }
  }, [query])

  const handleChange = _.debounce((searchText: string) => {
    setQuery(searchText);
  }, 500)

  const handleSearch = (searchText: string) => {
    if (searchText && searchText.trim().length > 2) {
      searchOwnedAndExternalVehicles({
        variables: {
          q: searchText.trim()
        }
      })
    } else {
      setLicencePlate([])
    }
  }

  const handleFormSubmit = (values: IAsset) => {
    const { id, licencePlate, source, services, damages, ...rest } = values
    if (values.vehicle && typeof values.vehicle !== 'string' && values.vehicle.id) {
      rest.vehicle = values.vehicle.id;
    }
    if (values.id) {
      updateAsset({
        variables: {
          id: values.id,
          asset: rest
        }
      })
    } else {
      createAsset({
        variables: {
          asset: rest
        }
      })
    }
  }

  async function uploadAssetImage(files: File[]) {
    try {
      if (imageUploadInProgress) {
        return snackbar({
          message: "Image upload is in progress",
          variant: SnackBarVariant.ERROR
        });
      }
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        // 5MB limit exceeded
        if (file.size > 5242880) {
          throw new Error("File size exceeded limit of 5MB");
        }
        setImageUploadInProgress(true);
        let key: string = "";
        const uniqueId = uuidv4();
        if (file.type === "image/jpeg") {
          key = `${userState.tenancy.id}/${uniqueId}-${values.assetType}-asset.jpeg`;
        } else if (file.type === "image/png") {
          key = `${userState.tenancy.id}/${uniqueId}-${values.assetType}-asset.png`;
        }
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                setValues({
                  ...values,
                  images: [key]
                });
                snackbar({
                  message: "Image Uploaded Successfully",
                  variant: SnackBarVariant.SUCCESS
                });
                getAssetImage(key);
                setImageUploadOpen(false);
                setImageUploadInProgress(false);
              }
            });
        }
      }
    } catch (err: any) {
      setImageUploadInProgress(false);
      snackbar({
        message: formatGraphQLErrorMessage(err?.message),
        variant: SnackBarVariant.ERROR
      });
    }
  }

  const getAssetImage = async (url: string) => {
    if (url) {
      try {
        const file = await getSignedUrl(url);
        setAssetImage(file);
      } catch (error: any) {
        snackbar({
          message: error,
          variant: SnackBarVariant.ERROR
        });
      }
    }
  };

  const validationSchema = Yup.object().shape({
    assetType: Yup.string().required("Asset type is required."),
    assetTag: Yup.string().required("Asset tag is required."),
  });

  const assetForm = (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={values}
      onSubmit={(values, { setSubmitting }) => {
        handleFormSubmit(values);
        setSubmitting(false);
      }}
    >
      {(props) => (
        <Form className={classes.form}>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={10}>
              <Typography variant="h2">BASIC DETAILS</Typography>{" "}
            </Grid>
            {values.id && (
              <Grid container item xs={2} justifyContent={"flex-end"}>
                <Tooltip title={props.values.isActive ? "Deactivate" : "Activate"}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.values.isActive}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...props.values,
                            isActive: event.target.checked
                          })
                          updateAssetStatus({
                            variables: {
                              id: props.values.id,
                              isActive: event.target.checked
                            }
                          })
                        }}
                        name="isActive"
                        color="primary"
                      />
                    }
                    label={props.values.isActive ? "Active" : "Deactivated"}
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl variant="outlined" fullWidth>
                <Field
                  component={TextField}
                  name={"assetType"}
                  fullWidth
                  type="text"
                  select
                  required
                  label="Asset Type"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    onChange: (
                      event: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      setValues({
                        ...props.values,
                        assetType: event.target.value
                      });
                    },
                    value: props.values.assetType
                  }}
                >
                  {AssetTypesMenu.map((item: TAssetType, index: number) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Field>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Asset Tag"
                label="Asset Tag"
                name={"assetTag"}
                value={props.values.assetTag}
                onChange={props.handleChange}
                required
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Registration Number"
                label="Registration Number"
                name={"registrationNumber"}
                value={props.values.registrationNumber}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>

            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Serial Number"
                label="Serial Number"
                name={"serialNumber"}
                value={props.values.serialNumber}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>

            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Identification Number"
                label="Identification Number"
                name={"identificationNumber"}
                value={props.values.identificationNumber}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="combo-box-asset-vehicle-id"
                onChange={(
                  _: React.ChangeEvent<{}>,
                  newVal: any
                ) => {
                  if (newVal && newVal.id) {
                    setValues({
                      ...props.values,
                      vehicle: newVal.id,
                      licencePlate: newVal.licencePlate
                    });
                    setSelectedVehicleBranch({ id: newVal.branchDetails.id, name: newVal.branchDetails.name });
                  } else {
                    setValues({
                      ...props.values,
                      vehicle: "",
                      licencePlate: ""
                    });
                    setSelectedVehicleBranch({ id: selectedVehicleBranch.id, name: (!newVal) ? "" : newVal.branchDetails.name });
                  }
                }}
                loading={searchOwnedAndExternalVehiclesLoading}
                loadingText={"Loading vehicles..."}
                noOptionsText={query.length > 2 && !licencePlate.length ?
                  "No vehicle found with search criteria" :
                  "Type to search vehicles"
                }
                options={licencePlate ?? []}
                getOptionLabel={(option: any) => {
                  if (selectedVehicleBranch.name) {
                    return (
                      `${option.licencePlate}${option.licencePlate
                        ? (userState.currentBranch.id === selectedVehicleBranch.id)
                          ? " (Own)"
                          : ` (${selectedVehicleBranch.name})` : ""}`
                    )
                  }
                  return (
                    `${option?.licencePlate}${option?.licencePlate
                      ? (userState.currentBranch?.id === option?.branchDetails?.id)
                        ? " (Own)"
                        : ` (${option?.branchDetails?.name})` : ""}`
                  )
                }
                }
                value={values}
                style={{ width: "100%" }}
                renderInput={(params: any) => (
                  <InputField
                    {...params}
                    label={"Search for vehicle"}
                    variant="outlined"
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
                    value={values.licencePlate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Make"
                label="Make"
                name={"make"}
                value={props.values.make}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>

            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Model"
                label="Model"
                name={"model"}
                value={props.values.model}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>

            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Colour"
                label="Colour"
                name={"color"}
                value={props.values.color}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Size"
                label="Size"
                name={"size"}
                value={props.values.size}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>

            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Year of Manufacture"
                label="Year of Manufacture"
                name={"yearOfManufacture"}
                value={props.values.yearOfManufacture}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>

            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Year of Registration"
                label="Year of Registration"
                name={"yearOfRegistration"}
                value={props.values.yearOfRegistration}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>

            <Grid item xs={6} md={4}>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {
                  setValues({
                    ...props.values,
                    issueDate: d.fromJSDate(value).toUTC().toISO(),
                  })
                }}

                label={"Issue Date"}
                identifier={`issueDate`}
                placeholderValue={"select date..."}
                value={props.values.issueDate}
                maxDate={d.now().toUTC().toISO()}
                country={country}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {
                  setValues({
                    ...props.values,
                    deFleetParameter: {
                      ...props.values.deFleetParameter,
                      byDate: d.fromJSDate(value).toUTC().toISO(),
                    }
                  })
                }}

                label={"DeFleet Date"}
                identifier={`defleetDate`}
                placeholderValue={"select date..."}
                value={props.values.deFleetParameter.byDate}
                minDate={'today'}
                country={country}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Field
                component={TextField}
                label="Purchase Cost"
                name={"purchaseCost"}
                placeholder={"e.g 100"}
                InputProps={{
                  inputComponent: FloatInput as any
                }}
                inputProps={{
                  hasCurrencyPrefix: true,
                  allowNegative: false
                }}
                value={props.values.purchaseCost}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Capacity"
                label="Capacity"
                name={"capacity"}
                type="number"
                value={props.values.capacity}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Capacity Unit"
                label="Capacity Unit"
                name={"capacityUnit"}
                value={props.values.capacityUnit}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2">DIMENSION DETAILS</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Length"
                label="Length(In MM)"
                name={"dimension.length"}
                type="number"
                value={props.values.dimension.length}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Width"
                label="Width(In MM)"
                name={"dimension.width"}
                type="number"
                value={props.values.dimension.width}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Height"
                label="Height(In MM)"
                name={"dimension.height"}
                type="number"
                value={props.values.dimension.height}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2">GROSS WEIGHT DETAILS</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Max Permitted Weight"
                label="Max Permitted Weight"
                name={"grossWeight.maximumPermittedWeight"}
                type="number"
                value={props.values.grossWeight.maximumPermittedWeight}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Max Country Permitted Weight"
                label="Max Country Permitted Weight"
                name={"grossWeight.maximumCountryPermittedWeight"}
                type="number"
                value={props.values.grossWeight.maximumCountryPermittedWeight}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Design Weight"
                label="Design Weight"
                name={"grossWeight.designWeight"}
                type="number"
                value={props.values.grossWeight.designWeight}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2">TRAIN WEIGHT DETAILS</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Max Permitted Weight"
                label="Max Permitted Weight"
                name={"trainWeight.maximumPermittedWeight"}
                type="number"
                value={props.values.trainWeight.maximumPermittedWeight}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Max Country Permitted Weight"
                label="Max Country Permitted Weight"
                name={"trainWeight.maximumCountryPermittedWeight"}
                type="number"
                value={props.values.trainWeight.maximumCountryPermittedWeight}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                component={TextField}
                placeholder="Design Weight"
                label="Design Weight"
                name={"trainWeight.designWeight"}
                type="number"
                value={props.values.trainWeight.designWeight}
                onChange={props.handleChange}
                fullWidth
              ></Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                label={"Description"}
                name={"description"}
                onChange={props.handleChange}
                value={props.values.description}
                inputProps={{ maxLength: 500 }}
                fullWidth
                multiline
                rows={"3"}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2">ADDITIONAL DETAILS</Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    component={TextField}
                    name={"frogDiagram"}
                    fullWidth
                    type="text"
                    select
                    label="Frog Diagram"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      onChange: (event: any) => {
                        if (event.target.name === "frogDiagram") {
                          setSvg(getFrogDiagram(event.target.value));
                          setValues({
                            ...props.values,
                            frogDiagram: event.target.value
                          })
                        }
                      },
                      value: props.values.frogDiagram
                    }}
                  >
                    {assetFrogDiagramTypes.map((item: any, index: number) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                {
                  props.values.frogDiagram && (
                    <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
                      {diagramType === DamageTypes.EXTERIOR && svg?.exterior.svg &&
                        (<svg xmlns="http://www.w3.org/2000/svg"
                          style={{ maxWidth: 'calc(100vw - 160px)', maxHeight: `${svgHeight}px`, marginBottom: "20px" }}
                          viewBox={`0 0 ${svg?.exterior.width} ${svg?.exterior.height}`}>
                          {svg?.exterior.svg}
                        </svg>)}
                      {diagramType === DamageTypes.INTERIOR && svg?.interior.svg &&
                        (<svg xmlns="http://www.w3.org/2000/svg"
                          style={{ maxWidth: 'calc(100vw - 160px)', maxHeight: `${svgHeight}px`, marginBottom: "20px" }}
                          viewBox={`0 0 ${svg?.interior.width} ${svg?.interior.height}`}>
                          {svg?.interior.svg}
                        </svg>)}
                      <Typography variant="h5" ></Typography>
                      <Typography variant="h5" ></Typography>
                      <Typography variant="h5" className={diagramType === DamageTypes.EXTERIOR ? classes.active : ""} style={{ cursor: "pointer", display: "inline", marginRight: "10px", textTransform: "uppercase" }} onClick={() => { setDiagramType(DamageTypes.EXTERIOR) }}>{"exterior"}</Typography>
                      <Typography variant="h5" className={diagramType === DamageTypes.INTERIOR ? classes.active : ""} style={{ cursor: "pointer", display: "inline", textTransform: "uppercase" }} onClick={() => { setDiagramType(DamageTypes.INTERIOR) }}>{"interior"}</Typography>
                    </Paper>
                  )
                }
              </Grid>
            </Grid>
            <Grid xs={4}>
              <Fab
                className={
                  `${classes.saveButton} imageUploadButton`
                }
                variant="extended"
                size="medium"
                aria-label="Upload Vehicle Image"
                type="button"
                onClick={() => setImageUploadOpen(true)}
              >
                Upload Image
              </Fab>
              {assetImage && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                  style={{
                    marginTop: 20,
                    marginLeft: 40
                  }}
                >
                  <img
                    src={assetImage}
                    alt="Vehicle-img"
                    style={{ width: "85%", height: "auto", borderRadius: 4 }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12}>
              <Fab
                variant="extended"
                size="medium"
                aria-label="add"
                type="submit"
              >
                {(createAssetLoading || updateAssetLoading) && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                Save
              </Fab>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      {isNewAsset && (
        <Grid container item xs={12} alignItems="center">
          <Typography variant="h1" color="primary">
            Asset{"  "}
          </Typography>
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon />
          <Box color="white" sx={{ pl: 1 }}></Box>
          <Typography variant="h1" color="primary">
            New
          </Typography>
          <Typography variant="h1" color="primary">
            &nbsp;Asset
          </Typography>
        </Grid>
      )}
      <Grid container item xs={12}>
        {asset?.id ? (
          <div style={{ padding: 20 }}>
            {assetForm}
          </div>
        ) : (
          <Paper className={classes.root}>
            {assetForm}
          </Paper>
        )}
      </Grid>
      {isImageUploadOpen && (
        <DragDropImages
          open={isImageUploadOpen}
          handleCloseDragDrop={() => setImageUploadOpen(!isImageUploadOpen)}
          handleSave={uploadAssetImage}
          filesLimit={1}
        />
      )}
    </Grid>
  )
}