import {
  Dialog,
  DialogContent,
  Typography,
  Theme,
  Grid,
  Fab,
  TextField,
  CircularProgress,
  DialogContentText,
} from "@mui/material";
import React, { useState } from "react";
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CustomerVerificationStatus } from "../../../../reducers/customer/types";
import { WithStyles, createStyles, withStyles } from "@mui/styles";

interface IDialogProps {
  open: boolean,
  handleClose: () => void;
  inProgress: boolean;
  status: CustomerVerificationStatus,
  handleSubmit: (note: string) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const CustomerVerificationDialog: React.FC<IDialogProps> = (props) => {
  const { open, handleClose, handleSubmit, inProgress, status } = props;
  const [note, setNote] = useState<string>("");

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      className={"select-holidays-dialog"}
    >
      <React.Fragment>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p style={{ fontSize: 18, color: "rgba(0, 0, 0, 0.54)", padding: 0, margin: 0 }}>
            {status === CustomerVerificationStatus.APPROVED ? "Approve customer verification" : "Decline customer verification"}
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {status === CustomerVerificationStatus.APPROVED ? (
              <DialogContentText id="alert-dialog-description">
                {"Are you sure you want to approve this customer?"}
              </DialogContentText>
            ) : (
              <Grid item xs={12} sm={12} md={12} style={{ padding: 0 }}>
                <TextField
                  value={note}
                  style={{ width: "100%" }}
                  id="outlined-textarea"
                  label="Add note for customer(optional)"
                  multiline
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNote(event.target.value);
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ marginTop: 10, paddingLeft: 0 }}>
              <Fab
                variant="extended"
                size="medium"
                aria-label="submit"
                style={status === CustomerVerificationStatus.APPROVED ? { backgroundColor: "#009951", color: "white" } :
                  { backgroundColor: "red", color: "white" }}
                onClick={() => {
                  handleSubmit(note)
                }}
              >
                {inProgress && (
                  <CircularProgress
                    size={14}
                    style={{ color: "white", marginRight: "10px" }}
                  />
                )}
                {status === CustomerVerificationStatus.APPROVED ? "APPROVE" : "DECLINE"}
              </Fab>
            </Grid>
          </Grid>
        </DialogContent>
      </React.Fragment>
    </Dialog >
  )
}