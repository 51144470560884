import { gql } from "@apollo/client";

export const GET_PARTNERS_LIST = gql`
  {
    partners {
      id 
      partnerName
      isActive
    }
  }
`;
