import { Checkbox, FormControlLabel, Grid, Hidden, TextField, Typography } from "@mui/material";
import React from "react";
import { ANSWER_TYPES, IBookingQuestionInput } from "../../../../../reducers/bookings/types";
import { makeStyles } from "@mui/styles";

interface IViewBookingQuestionsProps {
  preBookingQuestions: IBookingQuestionInput[],
  deliveryQuestions: IBookingQuestionInput[]
}

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-multiline": {
      padding: 5
    }
  }
});

export const ViewBookingQuestions: React.FC<IViewBookingQuestionsProps> = (props) => {
  const style = useStyles();
  const { preBookingQuestions } = props;
  return (
    <Grid container spacing={1}>
      {preBookingQuestions && preBookingQuestions.length > 0 && (
        <>
          <Hidden smDown>
            {preBookingQuestions.map((preBookingQuestion: IBookingQuestionInput, index: number) => {
              switch (preBookingQuestion.answerType) {
                case ANSWER_TYPES.YES_NO:
                  return (
                    <Grid item xs={6} md={12}>
                      <Grid container
                        key={index}
                        style={{
                          background: "#f5f5f5",
                          borderRadius: 5,
                          padding: 20
                        }}>
                        <Grid item xs={8}>
                          <Typography variant="subtitle2">{preBookingQuestion.question}</Typography>
                        </Grid>
                        <Grid container item xs={4} justifyContent="flex-end">
                          <Typography variant="subtitle2" style={{ textTransform: "capitalize" }}>{preBookingQuestion.answer ? preBookingQuestion.answer.toString().toLowerCase() : "---"}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                  break;
                case ANSWER_TYPES.DROP_DOWN:
                  return (
                    <Grid item xs={6} md={12}>
                      <Grid container
                        key={index}
                        style={{
                          background: "#f5f5f5",
                          borderRadius: 5,
                          padding: 20
                        }}>
                        <Grid item xs={8}>
                          <Typography variant="subtitle2">{preBookingQuestion.question}</Typography>
                        </Grid>
                        <Grid container item xs={4} justifyContent="flex-end">
                          <Typography variant="subtitle2">{preBookingQuestion.answer || "---"}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                  break;
                case ANSWER_TYPES.CHECKBOX:
                  return (
                    <Grid item xs={6} md={12}>
                      <Grid container
                        key={index}
                        style={{
                          background: "#f5f5f5",
                          borderRadius: 5,
                          padding: 10
                        }}>
                        <Grid item xs={11} md={10}>
                          <span style={{
                            fontSize: 14,
                            fontFamily: "Noto Sans JP, sans-serif",
                            fontWeight: 400,
                            lineHeight: 3,
                            letterSpacing: -0.05,
                          }}>{preBookingQuestion.question}</span>
                        </Grid>
                        <Grid item xs={1} md={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={preBookingQuestion.answer === "true" ? true : false}
                                onChange={() => { }}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            style={{ marginLeft: 10 }}
                            label=""
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                  break;
                case ANSWER_TYPES.TEXT:
                  return (
                    <Grid item xs={12}>
                      <Grid container
                        key={index}
                        style={{
                          background: "#f5f5f5",
                          borderRadius: 10,
                          padding: "10px 14px"
                        }}>
                        <Grid item xs={12}>
                          <Typography variant="body1">{preBookingQuestion.question}</Typography>
                          <TextField
                            className={style.root}
                            style={{ width: "100%", padding: 0, marginTop: 10 }}
                            id="outlined-multiline-static"
                            multiline
                            value={preBookingQuestion.answer}
                            variant="outlined"
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                  break;
              }
            })}
          </Hidden>

          <Hidden smUp>
            {preBookingQuestions.map((preBookingQuestion: IBookingQuestionInput, index: number) => {
              switch (preBookingQuestion.answerType) {
                case ANSWER_TYPES.YES_NO:
                  return (
                    <Grid item xs={12} md={12}>
                      <Grid container
                        key={index}
                        style={{
                          background: "#f5f5f5",
                          borderRadius: 5,
                          padding: 20
                        }}>
                        <Grid item xs={8}>
                          <Typography variant="subtitle2">{preBookingQuestion.question}</Typography>
                        </Grid>
                        <Grid container item xs={4} justifyContent="flex-end">
                          <Typography variant="subtitle2" style={{ textTransform: "capitalize" }}>{preBookingQuestion.answer ? preBookingQuestion.answer.toString().toLowerCase() : "---"}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                  break;
                case ANSWER_TYPES.DROP_DOWN:
                  return (
                    <Grid item xs={12} md={12}>
                      <Grid container
                        key={index}
                        style={{
                          background: "#f5f5f5",
                          borderRadius: 5,
                          padding: 20
                        }}>
                        <Grid item xs={8}>
                          <Typography variant="subtitle2">{preBookingQuestion.question}</Typography>
                        </Grid>
                        <Grid container item xs={4} justifyContent="flex-end">
                          <Typography variant="subtitle2">{preBookingQuestion.answer || "---"}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                  break;
                case ANSWER_TYPES.CHECKBOX:
                  return (
                    <Grid item xs={12} md={12}>
                      <Grid container
                        key={index}
                        style={{
                          background: "#f5f5f5",
                          borderRadius: 5,
                          padding: 10
                        }}
                        item
                        xs={12}
                        justifyContent="space-between"
                      >
                        <Grid item xs={11} md={10}>
                          <span style={{
                            fontSize: 14,
                            fontFamily: "Noto Sans JP, sans-serif",
                            fontWeight: 400,
                            lineHeight: 3,
                            letterSpacing: -0.05,
                          }}>{preBookingQuestion.question}</span>
                        </Grid>
                        <Grid item xs={1} md={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={preBookingQuestion.answer === "true" ? true : false}
                                onChange={() => { }}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label=""
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                  break;
                case ANSWER_TYPES.TEXT:
                  return (
                    <Grid item xs={12}>
                      <Grid container
                        key={index}
                        style={{
                          background: "#f5f5f5",
                          borderRadius: 5,
                          padding: 5
                        }}>
                        <Grid item xs={12}>
                          <Typography variant="body1">{preBookingQuestion.question}</Typography>
                          <TextField
                            className={style.root}
                            style={{ width: "100%", padding: 0 }}
                            id="outlined-multiline-static"
                            multiline
                            value={preBookingQuestion.answer}
                            variant="outlined"
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                  break;
              }
            })}
          </Hidden>
        </>
      )}
    </Grid>
  );
}
