import { gql } from "@apollo/client";

export const GET_BUSINESS_CREDIT_SCORE = gql`
  mutation getBusinessCreditScore($businessCustomerId: String!) {
    getBusinessCreditScore(businessCustomerId: $businessCustomerId){
      businessCreditData {
          creditScore
          creditDescription
          lastChecked
        }
    }
  }
`;