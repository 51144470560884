import {
  Step,
  StepLabel,
  Stepper,
  Hidden
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  stepper: {
    backgroundColor: "unset"
  }
}));

interface IProps {
  currentStep: number;
  steps: string[];
}

const StepperHeader: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<number>(props.currentStep);

  useEffect(() => {
    if (props.currentStep) {
      setActiveStep(props.currentStep);
    }
  }, [props.currentStep]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
        {props.steps.map((label) => (
          <Step key={label}>
            <StepLabel><Hidden smDown>{label}</Hidden></StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default StepperHeader;
