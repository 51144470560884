import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-mui";
import React, { useState, useEffect } from "react";
import { DateTime as d } from "luxon";
import { useSelector } from "react-redux";
import { ICreateCreditNoteInput, ICreditNoteItem } from "../../../../../reducers/bookings/types";
import { IInvoice, IPayment } from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { getRentalTaxAmount, toCurrency } from "../../../../common/utils";
import { validateString } from "../../../../common/ValidationRules";
import { CreditNoteTypes, getTaxAmount } from "../../utils";
import * as Yup from "yup";

interface IProps {
  isOpen: boolean;
  invoice: IInvoice;
  availableCredit: number;
  onCancel: () => void;
  onSubmit: (data: ICreateCreditNoteInput) => void;
}

const NewCreditNote: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const [open, setOpen] = useState<boolean>(props.isOpen);
  const [loading, setLoading] = useState<boolean>(false);
  const [creditNoteType, setCreditNoteType] = useState<string>(CreditNoteTypes.REFUNDABLE);
  const [creditNoteItems, setCreditNoteItems] = useState<ICreditNoteItem[]>([])
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { taxEnabledCreditNotes } = userState.currentTenancy;
  const organisation = userState.currentOrganisation;
  const { locale, currency } = organisation;
  const [bookingDurationInDays, setBookingDurationInDays] = useState<number>(0);

  useEffect(() => {
    if (props.isOpen !== open) {
      setOpen(props.isOpen);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props.invoice) {
      const duration = d.fromISO(props.invoice.endDate).diff(d.fromISO(props.invoice.startDate), ['days']).days;
      setBookingDurationInDays(duration)
      if (props.invoice.invoiceItems?.length) {
        const arr = [...creditNoteItems];
        props.invoice.invoiceItems.forEach((item) => {
          const ele: ICreditNoteItem = {
            type: item.type,
            description: item.description,
            originalTaxAmount: item.taxAmount || 0,
            originalPrice: item.totalAmount + (item.taxAmount || 0),
            unitPrice: 0,
            quantity: item.quantity,
            totalAmount: 0,
            subTotal: 0,
            taxRate: item.taxRate,
            taxAmount: 0,
            duration: item.duration,
            taxDescription: item.taxDescription || []
          }
          if (!arr.some((arrItem) => arrItem.description === ele.description)) {
            arr.push(ele)
          }
        })
        setCreditNoteItems(arr);
      }
    }

  }, [props.invoice])

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    props.onCancel();
  };

  const calculateTotalRefundAmount = () => {
    let refundAmount = 0;
    creditNoteItems.forEach((item) => {
      refundAmount += item.totalAmount
    })
    return refundAmount
  }

  const handleSubmit = () => {
    if (creditNoteItems.some((item: ICreditNoteItem) => !item.description)) {
      return snackbar({
        message: "Refund item description cannot be empty!",
        variant: SnackBarVariant.ERROR
      });
    }
    setLoading(true)
    const data: ICreateCreditNoteInput = {
      booking: props.invoice.booking ? props.invoice.booking.id : '',
      invoice: props.invoice.id,
      creditNoteInput: {
        invoiceRef: props.invoice.invoiceRef,
        invoiceId: props.invoice.id,
        paidAmount: props.invoice.confirmedPaidAmount,
        totalAmount: props.invoice.totalPayableAmount,
        creditNoteType: creditNoteType,
        creditNoteItems: creditNoteItems.filter((item) => item.totalAmount > 0)
      }
    }
    props.onSubmit(data)
  }

  const validationSchema = Yup.object().shape({

  });

  return (
    <Dialog
      open={open}
      maxWidth={taxEnabledCreditNotes ? "lg" : "md"}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container xs={12}>
          <Grid item xs={6}>
            <Typography variant="h2">
              {"New Credit Note"}
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Typography variant="h4">
              {"Max Amount Available: "}
              <Typography variant="body1" style={{ display: "inline" }}>
                {toCurrency(props.availableCredit, currency, locale)}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <FormControl>
            <RadioGroup
              row
              name="creditNoteType"
              value={creditNoteType}
              onChange={(event: any) => {
                setCreditNoteType(event.target.value)
              }}
            >
              <FormControlLabel
                value={CreditNoteTypes.REFUNDABLE}
                control={<Radio />}
                label={<Typography variant="body1">Issue a Refund</Typography>}
              />
              <FormControlLabel
                value={CreditNoteTypes.ADJUSTABLE}
                control={<Radio />}
                label={<Typography variant="body1">Issue Refundable Credits</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item container xs={12}>
          <Typography variant="subtitle1">
            Refund Items
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Formik
            initialValues={props.invoice}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit();
              setSubmitting(false);
            }}
          >
            {(formikProps) => (
              <Form style={{ width: "100%" }}>
                <Grid item container xs={12}>
                  {creditNoteItems.map((row: ICreditNoteItem, idx: number) => {
                    return (
                      <Grid item container xs={12} key={idx}>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ marginTop: "20px" }}
                          spacing={2}
                        >
                          <Grid
                            item
                            container
                            xs={taxEnabledCreditNotes ? 3 : 4}
                            style={{ paddingRight: "10px" }}
                          >
                            <TextField
                              placeholder="Item Description"
                              label="Item Description"
                              name={"description"}
                              required
                              inputProps={{
                                value: row.description,
                                onChange: (
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  if (validateString(e.target.value)) {
                                    const item = creditNoteItems[idx];
                                    const rowsArr = [...creditNoteItems];
                                    item.description = e.target.value;
                                    rowsArr.splice(idx, 1, item);
                                    setCreditNoteItems(rowsArr);
                                  }
                                }
                              }}
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid item container xs={taxEnabledCreditNotes ? 2 : 3}>
                            <Field
                              component={InputField}
                              fullWidth
                              placeholder="Original Price"
                              label="Original Price"
                              name={"originalPrice"}
                              disabled
                              InputProps={{
                                value: row.originalPrice,
                                inputComponent: FloatInput as any,
                              }}
                              inputProps={{
                                hasCurrencyPrefix: true,
                                allowNegative: false
                              }}
                            ></Field>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={taxEnabledCreditNotes ? 3 : 4}
                            style={{ paddingRight: "10px" }}
                          >
                            <Field
                              component={InputField}
                              fullWidth
                              placeholder="Refund Amount"
                              label="Refund Amount"
                              name={`totalAmount${idx}`}
                              InputProps={{
                                value: row.totalAmount,
                                inputComponent: FloatInput as any,
                                onChange: (
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  const val = e.target.value || "0";
                                  const item: ICreditNoteItem = creditNoteItems[idx];
                                  const rowsArr = [...creditNoteItems];
                                  item.totalAmount = parseInt(val);
                                  if (!item.totalAmount) {
                                    item.unitPrice = 0;
                                    item.taxAmount = 0;
                                    item.subTotal = 0;
                                  }
                                  item.taxAmount = getTaxAmount({
                                    currentAmount: item.totalAmount,
                                    originalAmount: item.originalPrice,
                                    taxAmount: item.originalTaxAmount || 0
                                  })
                                  item.subTotal = item.totalAmount - item.taxAmount;
                                  item.unitPrice = Math.round(item.subTotal / ((item.duration || 1) * item.quantity))
                                  rowsArr.splice(idx, 1, item);
                                  setCreditNoteItems(rowsArr);
                                }
                              }}
                              inputProps={{
                                hasCurrencyPrefix: true,
                                allowNegative: false
                              }}
                              validate={() => {
                                const item: ICreditNoteItem = creditNoteItems[idx];
                                if (item.totalAmount > item.originalPrice) {
                                  return `Should be less than or equal to ${toCurrency(item.originalPrice, locale, currency)}.`
                                }
                              }}
                            ></Field>
                          </Grid>
                          {taxEnabledCreditNotes &&
                            <>
                              <Grid item container xs={2}>
                                <Field
                                  component={InputField}
                                  fullWidth
                                  placeholder="Refund Amount without tax"
                                  label="Refund Amount without tax"
                                  name={"subTotal"}
                                  disabled
                                  InputProps={{
                                    value: row.subTotal,
                                    inputComponent: FloatInput as any,
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                ></Field>
                              </Grid>
                              <Grid item container xs={2}>
                                <Field
                                  component={InputField}
                                  fullWidth
                                  placeholder="Refund Tax Amount"
                                  label="Refund Tax Amount"
                                  name={"taxAmount"}
                                  disabled
                                  InputProps={{
                                    value: row.taxAmount,
                                    inputComponent: FloatInput as any,
                                  }}
                                  inputProps={{
                                    hasCurrencyPrefix: true,
                                    allowNegative: false
                                  }}
                                ></Field>
                              </Grid>
                            </>
                          }
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid container xs={12} style={{ marginTop: "20px" }}>
                    <Grid container item xs={6}>
                      <Typography variant="h4">
                        {"Total Refund Amount: "}
                        <Typography variant="body1" style={{ display: "inline" }}>
                          {toCurrency(
                            calculateTotalRefundAmount(),
                            currency,
                            locale
                          )}
                        </Typography>
                      </Typography>
                      {(calculateTotalRefundAmount() > props.availableCredit) && <Grid item xs={12}>
                        <Typography variant="body1" style={{ color: "red" }}>
                          Note: Refund amount can't be greater than available credit!
                        </Typography>
                      </Grid>}
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                      <Fab
                        className="blackBackButton"
                        style={{ marginRight: "20px" }}
                        variant="extended"
                        size="medium"
                        onClick={() => handleClose()}
                      >
                        {"Cancel"}
                      </Fab>
                      <Fab
                        variant="extended"
                        disabled={!creditNoteItems.length || !calculateTotalRefundAmount() || (calculateTotalRefundAmount() > props.availableCredit) || creditNoteItems.some((item) => item.totalAmount > item.originalPrice)}
                        size="medium"
                        type="submit"
                      >
                        {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                        {"Create"}
                      </Fab>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  )
};

export default NewCreditNote;