import { Grid, Typography } from "@mui/material";
import React from "react";
import { useVirtualizer } from '@tanstack/react-virtual';
import { IBusinessCustomer } from "../../../../../reducers/customer/types";
import MobileBusinessCustomerCard from "../../../../common/MobileBusinessCustomerCard";


export const BusinessCustomerVirtualScroller = ({ data }: { data: IBusinessCustomer[] }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 182
  })

  if (!data.length) {
    return <Typography>Sorry no matching record found</Typography>
  }

  return (
    <>
      {/* Scrollable Parent List  */}
      <Grid item xs={12}
        ref={parentRef}
        className="List"
        style={{
          height: `calc(100vh - 250px)`,
          width: 'calc(100vw - 40px)',
          overflow: 'auto',
        }}
      >
        {/* Large inner element for items */}
        <Grid container item xs={12} spacing={2}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            top: 10,
            left: 10,
            position: 'relative',
          }}
        >
          {/* Virtualized Items */}
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <Grid container item xs={12} spacing={2}
              key={virtualItem.key}
              style={{
                position: 'absolute',
                width: '100%',
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <MobileBusinessCustomerCard
                id={data[virtualItem.index].id}
                businessName={data[virtualItem.index].businessName}
                contactName={data[virtualItem.index].contact.firstName + " " + data[virtualItem.index].contact.lastName}
                contactEmail={data[virtualItem.index].contact.email}
                contactNumber={data[virtualItem.index].contact.phoneNumber.phone}
                VAT={data[virtualItem.index].VAT} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default BusinessCustomerVirtualScroller;