import { Box, Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import ImageGallery from "../../../../common/ImageGallery";

interface IImageProps {
  images: any
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageStyle: {
      height: 70,
      width: 70,
      borderRadius: 5
    }
  })
);
export const ImageView = (props: IImageProps) => {
  const styles = useStyles();
  const { images } = props;
  const [titleFlag, setTitleFlag] = useState("");
  const handleOpen = (title: string) => {
    if (title === "interiorImages") {
      setTitleFlag(title);
    }
  };
  const handleClose = () => {
    setTitleFlag("");
  };
  return (
    <React.Fragment>
      <Box mt={1}></Box>
      <Grid container spacing={2}>
        {images &&
          images.length > 0 &&
          images.map((img: string, index: number) => (
            <React.Fragment key={index}>
              <button
                type="button"
                style={{ background: "none", border: "none" }}
                onClick={() => handleOpen("interiorImages")}
              >
                <img src={img} className={styles.imageStyle} alt="" />
              </button>
              <ImageGallery
                title={"Vehicle Damage Images"}
                open={titleFlag === "interiorImages" ? true : false}
                handleClose={() => {
                  handleClose();
                }}
                images={images}
              />
            </React.Fragment>
          ))}
      </Grid>
    </React.Fragment>
  );
};
