import { useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { CREATE_PAYMENT_FOR_EXTENSiON } from "../../../../../graphql/invoices/createPaymentForExtensionMutation";
import {
  IPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  PaymentGateway,
  formatGraphQLErrorMessage
} from "../../../../common/utils";
import FlatPickerBar from "../../../../common/FlatPicker";
import { DateTime as d } from "luxon";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { loadConvergeUS } from "../../../../../utils/converge";

interface IFormValues {
  amount: number;
  transactionId: string;
}
interface IProps {
  bookingId: string;
  extensionId: string;
  amount: number;
  handleClose(type?: boolean): void;
}

const CardTransactionExtension: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency, stripeAccountId, cardEnabled, convergeEnabled } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address
  const snackbar = useSnackBar();
  const [values, setValues] = useState<IFormValues>({
    amount: props.amount,
    transactionId: ""
  });
  const [paymentGateway, setPaymentGateway] = useState<string>(
    PaymentGateway.OFFLINE
  );
  const [paymentInput, setPaymentInput] = useState<IPaymentInput>();
  const [capturedAt, setCapturedAt] = useState<string>("");

  // Make sure to call `loadStripe` outside of a component's render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise: any = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
    {
      stripeAccount: stripeAccountId
    }
  );

  const [
    createPaymentForExtension,
    { loading: sessionLoading, data: createPaymentData }
  ] = useMutation(CREATE_PAYMENT_FOR_EXTENSiON, {
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: async (data) => {
      if (paymentGateway === PaymentGateway.OFFLINE) {
        if (data && data.createPaymentForExtension) {
          setLoading(false);
          navigate(`/view-booking?booking=${props.bookingId}`);
          props.handleClose()
          snackbar({
            message: "Booking extended successfully",
            variant: SnackBarVariant.SUCCESS
          })
        }
      } else if (paymentGateway === PaymentGateway.STRIPE) {
        if (
          data?.createPaymentForExtension?.payment?.id
        ) {
          // Get Stripe.js instance
          const stripe = await stripePromise;
          // When the customer clicks on the button, redirect them to Checkout.
          if (stripe) {
            setLoading(false);
            navigate(`/payment?booking=${props.bookingId}&payment=${data.createPaymentForExtension.payment.id}`)
          }
        }
      } else if (paymentGateway === PaymentGateway.CONVERGE) {
        let paymentResponse = data.createPaymentForExtension.payment;
        if (paymentResponse.convergePaymentSessionUrl) {
          window.location = paymentResponse.convergePaymentSessionUrl;
        } else if (paymentResponse.convergeUSSessionToken) {
          loadConvergeUS({
            paymentSessionToken: paymentResponse.convergeUSSessionToken,
            onloadFunction() {
              setLoading(false);
            },
            redirectFunction() {
              navigate(`/view-booking?booking=${props.bookingId}`)
            }
          });
        }
      }
    }
  });

  const submitStripeForm = () => {
    setLoading(true);
    const payment: IPaymentInput = {
      amount: values.amount,
      booking: props.bookingId,
      bookingExtension: props.extensionId,
      currency,
      description: PaymentMode.CARD,
      expireBy: "",
      invoice: "",
      paymentMode: PaymentMode.CARD,
      paymentGateway,
      paymentType: "INWARD",
      successUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.bookingId}&status=success`,
      cancelUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.bookingId}&status=failure`
    };
    setPaymentInput(payment);
    createPaymentForExtension({
      variables: {
        payment
      }
    });
  };

  const submitOfflineForm = () => {
    setLoading(true);
    const payment: IPaymentInput = {
      amount: values.amount,
      booking: props.bookingId,
      bookingExtension: props.extensionId,
      currency,
      description: PaymentMode.CARD,
      transactionId: values.transactionId,
      expireBy: "",
      invoice: "",
      paymentMode: PaymentMode.CARD,
      paymentGateway: PaymentGateway.OFFLINE,
      paymentType: "INWARD",
      capturedAt: capturedAt
    };
    setPaymentInput(payment);
    createPaymentForExtension({
      variables: {
        payment
      }
    });
  };

  const AMOUNT_TOO_HIGH = `Amount must be less than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format(props.amount / 100)}`;

  const stripeFormSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan(0)
      .max(props.amount, AMOUNT_TOO_HIGH)
  });

  const offlineFormSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan(0)
      .max(props.amount, AMOUNT_TOO_HIGH),
    transactionId: Yup.string().required("Reference Number is required.")
  });

  return (
    <Grid container style={{ padding: 30 }}>
      {!cardEnabled ?
        <Grid container>
          <Typography variant="h4">
            To use this payment mode please enable card payments in Payment and Integration Settings.
          </Typography>
        </Grid> :
        <>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                row
                name="paymentGateway"
                value={paymentGateway}
                onChange={(event) => {
                  setPaymentGateway(event.target.value);
                }}
              >
                <FormControlLabel
                  value={PaymentGateway.OFFLINE}
                  control={<Radio />}
                  disabled={loading}
                  label={<Typography variant="body1">OTHER</Typography>}
                />
                <FormControlLabel
                  value={PaymentGateway.STRIPE}
                  control={<Radio />}
                  disabled={loading}
                  label={<Typography variant="body1">STRIPE</Typography>}
                />
                <FormControlLabel
                  value={PaymentGateway.CONVERGE}
                  control={<Radio />}
                  disabled={loading}
                  label={<Typography variant="body1">CONVERGE</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {paymentGateway === PaymentGateway.OFFLINE && (
            <>
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  validationSchema={offlineFormSchema}
                  initialValues={values}
                  onSubmit={(values, { setSubmitting }) => {
                    submitOfflineForm();
                    setSubmitting(false);
                  }}
                >
                  {(formikProps) => (
                    <Form>
                      <Grid container spacing={1}>
                        <Grid container item xs={12}>
                          <Box mt={0.2}></Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            placeholder="Reference Number"
                            label="Reference Number"
                            name={"transactionId"}
                            fullWidth
                            required
                            InputProps={{
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setValues({
                                  ...values,
                                  transactionId: e.target.value
                                });
                              },
                              value: values.transactionId
                            }}
                          />
                          <Typography variant={"body2"} color="textSecondary">
                            This could be anything like Receipt Number etc., and is
                            only for internal use.
                          </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                          <Box mt={0.2}></Box>
                        </Grid>
                        <Grid item xs={12}>
                          <FlatPickerBar
                            enableTime={false}
                            handleDateChange={(value: Date) => {
                              setCapturedAt(d.fromJSDate(value).startOf("day").toUTC().toISO())
                            }}
                            label={"Payment Date"}
                            placeholderValue={"Select Payment Date*"}
                            value={getLocalizedDateFormat(country, capturedAt, DATE_TYPE.CONDENSED)}
                            minDate={getLocalizedDateFormat(country, d.now().startOf("day").toUTC().toISO(), DATE_TYPE.CONDENSED)}
                            maxDate={getLocalizedDateFormat(country, d.now().endOf("day").toUTC().toISO(), DATE_TYPE.CONDENSED)}
                            disabled={loading}
                            country={country}
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <Box mt={0.2}></Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            placeholder="Enter Amount"
                            label="Enter Amount"
                            name={"amount"}
                            disabled
                            fullWidth
                            required
                            InputProps={{
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setValues({
                                  ...values,
                                  amount: parseInt(e.target.value)
                                });
                              },
                              value: values.amount,
                              inputComponent: FloatInput as any
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <Box mt={0.2}></Box>
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-start">
                          <Fab
                            variant="extended"
                            size="medium"
                            aria-label="add"
                            type="submit"
                            disabled={!capturedAt || loading}
                          >
                            {loading && (
                              <CircularProgress
                                size={14}
                                style={{ color: "white", marginRight: "10px" }}
                              />
                            )}
                            Save
                          </Fab>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </>
          )}
          {paymentGateway === PaymentGateway.STRIPE &&
            (stripeAccountId ? (
              <Grid item container xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={values}
                  validationSchema={stripeFormSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    submitStripeForm();
                    setSubmitting(false);
                  }}
                >
                  {(formikProps) => (
                    <Form>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          placeholder="Enter Amount"
                          label="Enter Amount"
                          name={"amount"}
                          disabled
                          fullWidth
                          required
                          InputProps={{
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setValues({
                                ...values,
                                amount: parseInt(e.target.value)
                              });
                            },
                            value: values.amount,
                            inputComponent: FloatInput as any
                          }}
                          inputProps={{
                            hasCurrencyPrefix: true,
                            allowNegative: false
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item container>
                          <Box mt={2}></Box>
                        </Grid>
                        <Grid item container>
                          <Fab
                            variant="extended"
                            size="medium"
                            aria-label="add"
                            type="submit"
                            disabled={loading}
                          >
                            {loading && (
                              <CircularProgress
                                size={14}
                                style={{ color: "white", marginRight: "10px" }}
                              />
                            )}
                            Proceed To Pay
                          </Fab>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            ) : (
              <Grid container xs={12}>
                <Typography style={{ margin: "1rem" }}>
                  Please add Stripe Account Id in organisation settings to use this
                  feature.
                </Typography>
              </Grid>
            ))}
          {paymentGateway === PaymentGateway.CONVERGE &&
            (convergeEnabled ? (
              <Grid item container xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={values}
                  validationSchema={stripeFormSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    submitStripeForm();
                    setSubmitting(false);
                  }}
                >
                  {(formikProps) => (
                    <Form>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          placeholder="Enter Amount"
                          label="Enter Amount"
                          name={"amount"}
                          disabled
                          fullWidth
                          required
                          InputProps={{
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setValues({
                                ...values,
                                amount: parseInt(e.target.value)
                              });
                            },
                            value: values.amount,
                            inputComponent: FloatInput as any
                          }}
                          inputProps={{
                            hasCurrencyPrefix: true,
                            allowNegative: false
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item container>
                          <Box mt={2}></Box>
                        </Grid>
                        <Grid item container>
                          <Fab
                            variant="extended"
                            size="medium"
                            aria-label="add"
                            type="submit"
                            disabled={loading}
                          >
                            {loading && (
                              <CircularProgress
                                size={14}
                                style={{ color: "white", marginRight: "10px" }}
                              />
                            )}
                            Proceed To Pay
                          </Fab>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            ) : (
              <Grid container xs={12}>
                <Typography style={{ margin: "1rem" }}>
                  Please add Converge credentials in Payments & Integrations settings to use this
                  feature.
                </Typography>
              </Grid>
            ))}
        </>
      }
    </Grid>
  );
};

export default CardTransactionExtension;