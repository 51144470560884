import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Fab,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Theme,
  Typography,
  Hidden,
  AppBar,
  Button,
  InputBase,
  alpha
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { DVLA_SEARCH } from "../../../../../graphql/fleet/dvlaSearchQuery";
import {
  IVehicle
} from "../../../../../reducers/fleet/types";
import InputField from "../../../../common/InputField";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { ResponseStatus } from "../../utils";
import { useNavigate } from "react-router-dom";

interface IDVLASearchProps {
  history: any;
  saveButton: boolean;
  onSubmit(data: IVehicle): void;
  onAddSubmit(): void;
  setMobileOpen(conditon: boolean): void;
}

const initialState = {
  licencePlate: ""
};

interface IFormValues {
  licencePlate: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingTop: "50px"
    },
    root: {
      padding: theme.spacing(3)
    },
    searchBtn: {
      backgroundColor: "black",
      border: 0,
      borderRadius: 26,
      color: "white",
      minHeight: 54,
      width: 180
    },
    viewItHere: {
      marginLeft: "5px"
    },
    save: {
      background: "#faaf40",
      color: "#fff",
      border: "none",
      width: "124px",
      height: "40px",
      fontSize: "14px",
      cursor: "pointer"
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(50vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    addBottomButton: {
      backgroundColor: "var(--ion-color-success)",
      "&:hover": {
        backgroundColor: "var(--ion-color-success-shade)"
      },
      position: 'relative',
      width: 'calc(50vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    text: {
      position: 'relative',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    }
  })
);

export function DVLASearch(props: IDVLASearchProps) {

  const snackbar = useSnackBar();
  const classes = useStyles();
  const navigate = useNavigate();

  const [values, setValues] = useState<IFormValues>(initialState);

  const [searchDvla, { loading, data }] = useLazyQuery(DVLA_SEARCH, {
    onCompleted: (data) => {
      if (
        data &&
        data.dvlaSearch &&
        data.dvlaSearch.length &&
        data.dvlaSearch[0] &&
        data.dvlaSearch[0].responseStatus != null
      ) {
        switch (data.dvlaSearch[0].responseStatus) {
          case ResponseStatus.VEHICLE_FOUND:
            const vehicle = data.dvlaSearch[0];
            const { responseStatus, ...rest } = vehicle
            props.onSubmit(rest);
            break;
          case ResponseStatus.VEHICLE_NOT_FOUND:
            snackbar({
              message: "Vehicle Not Found In DVLA Database",
              variant: SnackBarVariant.ERROR
            });
            break;
          case ResponseStatus.INVALID_LICENCE_PLATE:
            snackbar({
              message: "Invalid Registration Number",
              variant: SnackBarVariant.ERROR
            });
            break;
          case ResponseStatus.DVLA_API_ERROR:
            snackbar({
              message: "Dvla Api Error",
              variant: SnackBarVariant.ERROR
            });
            break;
        }
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
    fetchPolicy: "network-only"
  });

  function updateValues(event: any) {
    setValues({
      ...values,
      [event.target.name]: event.target.value.toUpperCase()
    });
  }

  function viewExistingVehicle(event: React.SyntheticEvent) {
    event.preventDefault();
    navigate(`/update-vehicle?vehicle=${data.dvlaSearch[0].id}`)
  }

  function onFormSubmit(event: any) {
    event.preventDefault();
    searchDvla({
      variables: {
        dvlaVehicles: [{ registrationNo: values.licencePlate }]
      }
    });
  }

  return <>
    <Hidden smDown>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2">SEARCH VEHICLE</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}></Box>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={onFormSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Enter vehicle Registration number to search DVLA
                  </Typography>
                </Grid>
                {loading && (
                  <Grid item xs={12}>
                    <LinearProgress />
                  </Grid>
                )}
                <Grid container item xs={5}>
                  <Grid item xs={12}>
                    <InputField
                      handleInput={updateValues}
                      name={"licencePlate"}
                      value={values.licencePlate}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {data &&
                      data.dvlaSearch &&
                      data.dvlaSearch.length &&
                      data.dvlaSearch[0] &&
                      data.dvlaSearch[0].responseStatus ===
                      ResponseStatus.VEHICLE_EXISTS ? (
                      <Typography>
                        The requested vehicle already exists in the inventory.
                        <Link
                          href="#"
                          onClick={viewExistingVehicle}
                          variant="body2"
                          className={classes.viewItHere}
                        >
                          View it here
                        </Link>
                      </Typography>
                    ) : (
                      <br />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Fab variant="extended" size="medium" aria-label="add" type="submit">
                    Search
                  </Fab>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Paper>
    </Hidden>
    <Hidden smUp>
      <AppBar position="fixed" className={classes.appBar}>
        <Grid container xs={12} spacing={1}>
          {(data &&
            data.dvlaSearch &&
            data.dvlaSearch.length &&
            data.dvlaSearch[0] &&
            data.dvlaSearch[0].responseStatus ===
            ResponseStatus.VEHICLE_EXISTS) &&
            (
              <Grid container item className={classes.text} justifyContent='center' xs={12}>
                <Grid item>
                  <Typography variant="body2" display="inline">
                    The requested vehicle already exists in the inventory.
                    <Typography
                      style={{
                        color: "var(--theme-primary)",
                        cursor: "pointer"
                      }}
                      onClick={viewExistingVehicle}
                      variant="body2"
                      className={classes.viewItHere}
                      display="inline"
                    >
                      View it here
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            )
          }
          {loading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
          <Grid item xs={12}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="DVLA Reg. No. Lookup"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={updateValues}
                name={"licencePlate"}
                value={values.licencePlate}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.bottomButton}
              variant="contained"
              aria-label="add"
              type="submit"
              onClick={(event) => {
                props.setMobileOpen(true);
                onFormSubmit(event);
              }
              }
            >
              Lookup
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.addBottomButton}
              variant="contained"
              aria-label="submit"
              disabled={props.saveButton ? false : true}
              onClick={props.onAddSubmit}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </AppBar>
    </Hidden>
  </>;
}
