import React, { useEffect, useState, useRef } from "react";
import { CssBaseline, Grid, Typography, createStyles, makeStyles, Theme, Box, Fab, Switch, FormControlLabel, CircularProgress } from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import EmailEditor from 'react-email-editor';
import { Buffer } from 'buffer';
import { CREATE_EMAIL_TEMPLATE } from "../../../../../graphql/communications/createEmailTemplate";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { ApolloError } from "@apollo/client";
import { templateVariables } from "./templateVariables";
import { GET_EMAIL_TEMPLATE_BY_TYPE } from "../../../../../graphql/communications/getEmailTemplateByType";
import { IEmailTemplate } from "./types";
import { UPDATE_EMAIL_TEMPLATE } from "../../../../../graphql/communications/editEmailTemplate";
import { RESET_EMAIL_TEMPLATE } from "../../../../../graphql/communications/resetEmailTemplate";

export const EditEmailTemplate: React.FC<any> = (props) => {

  const emailEditorRef: any = useRef(null);
  const snackbar = useSnackBar();
  const [emailTemplate, setEmailTemplate] = useState<IEmailTemplate>();
  const [disableAutoSend, setDisableAutoSend] = useState<boolean>(false);
  const options = ["Booking_Invoice", "Booking_Confirmation", "Booking_Cancellation", "Booking_Declined"]
  const [templateType, setTemplateType] = useState<string>("");

  const [
    getTemplateByEmailType,
    { loading: loadingTemplate, data: emailTemplateData }
  ] = useLazyQuery(GET_EMAIL_TEMPLATE_BY_TYPE, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (location && location.search) {
      const template = location?.search.split("=")[1]
      setTemplateType(template)
      getTemplateByEmailType({
        variables: {
          templateType: template?.toUpperCase()
        }
      })
    }
  }, [location])

  useEffect(() => {
    if (emailTemplateData) {
      setEmailTemplate(emailTemplateData.getEmailTemplateByType)
      setDisableAutoSend(emailTemplateData.getEmailTemplateByType.disableAutoSend)
    }
  }, [emailTemplateData])

  const [createEmailTemplate] = useMutation(CREATE_EMAIL_TEMPLATE, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      snackbar({
        message: "Email Template has been created",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      getTemplateByEmailType({
        variables: {
          templateType: templateType?.toUpperCase()
        }
      });
      snackbar({
        message: "Email Template has been updated",
        variant: SnackBarVariant.SUCCESS
      })
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [resetEmailTemplate] = useMutation(RESET_EMAIL_TEMPLATE, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      getTemplateByEmailType({
        variables: {
          templateType: templateType?.toUpperCase()
        }
      });
      snackbar({
        message: "Email Template has been changed to the default template.",
        variant: SnackBarVariant.SUCCESS
      })
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });


  const onReady = () => {
    // editor is ready
    // you can load your template here;
    // const templateJson = {};

    emailEditorRef.current?.editor?.loadDesign(JSON.parse(emailTemplate?.designJsonString || ""));

    const mergeTags: any = {}

    if (emailTemplate) {
      emailTemplate.variables.forEach((variable) => {
        const name: any = variable.name
        mergeTags[name] = {
          name,
          value: variable.value
        }
      })
    } else {
      const variableArr: any = templateVariables(templateType.toUpperCase());
      variableArr?.forEach((variable: any) => {
        const name: any = variable.name
        mergeTags[name] = {
          name,
          value: variable.value
        }
      })
    }

    emailEditorRef.current?.editor?.setMergeTags(mergeTags)
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data: any) => {
      const { design, html } = data;
      // createTemplate(JSON.stringify(design), JSON.stringify(html));
      updateTemplate(JSON.stringify(design), JSON.stringify(html), disableAutoSend);
    });
  };

  const encodeBase64 = (data: any) => {
    return Buffer.from(data).toString('base64');
  }

  const updateTemplate = (design: string, html: string, disableAutoSend: boolean) => {
    updateEmailTemplate({
      variables: {
        templateType: templateType.toUpperCase(),
        bodyHtmlString: encodeBase64(html),
        designJsonString: design,
        disableAutoSend
      }
    })
  }

  const resetTemplate = () => {
    resetEmailTemplate({
      variables: {
        templateType: templateType.toUpperCase()
      }
    })
  }

  if (loadingTemplate) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center" wrap="nowrap">
        <Typography variant="h1" color="primary">
          {" "}
          Email Templates
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>

        <Typography variant="h1" color="primary">
          {templateType.split("_").join(" ")} Email
        </Typography>
        <Grid container item xs justifyContent="flex-end" alignItems="center" style={{ padding: 10 }}>
          <FormControlLabel
            control={
              <Switch
                checked={disableAutoSend}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDisableAutoSend(e.target.checked)
                }}
                disabled={!options.includes(templateType)}
              />
            }
            labelPlacement="end"
            label="Disable Auto Send"
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} alignItems="center" style={{ height: "450px" }}>
        <EmailEditor ref={emailEditorRef} onReady={onReady} style={{ height: "430px" }} />
      </Grid>
      <Grid item xs={12}>
        <Grid container item xs={12} spacing={2}>
          <Grid item>
            <Fab variant={"extended"} size="medium" onClick={exportHtml}>SAVE CHANGES</Fab>
          </Grid>
          {emailTemplate && !emailTemplate.isDefault &&
            <Grid item>
              <Fab variant={"extended"} size="medium" onClick={resetTemplate}>RESET TO DEFAULT </Fab>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EditEmailTemplate;

