export interface IServiceOptionsMenu {
  label: string;
  value: string | number;
}

export enum ServiceType {
  REGULAR = "REGULAR",
  ADHOC = "ADHOC",
  EMERGENCY = "EMERGENCY",
}

export const serviceTypes: IServiceOptionsMenu[] = [
  {
    label: "Regular",
    value: ServiceType.REGULAR,
  },
  {
    label: "Ad-hoc",
    value: ServiceType.ADHOC,
  },
  {
    label: "Emergency",
    value: ServiceType.EMERGENCY,
  },
];

export const serviceTemplate: IServiceOptionsMenu[] = [
  {
    label: "One Time",
    value: "ONE_TIME"
  },
  {
    label: "Recurring",
    value: "RECURRING"
  }
]

export const serviceAnniversary: IServiceOptionsMenu[] = [
  {
    label: "Every month",
    value: 1
  },
  {
    label: "Every 3 month",
    value: 3
  },
  {
    label: "Every 6 month",
    value: 6
  },
  {
    label: "Every year",
    value: 12
  },
  {
    label: "Every 2 year",
    value: 24
  }
];
export const documentColumn: any = [
  {
    name: "documentName",
    label: "DOCUMENT NAME"
  },
  {
    name: "description",
    label: "DESCRIPTION"
  }
];