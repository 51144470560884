import { gql } from "@apollo/client";

export const UPDATE_BRANCH = gql`
  mutation updateBranch(
    $organisationId: ID!
    $branchId: ID!
    $branch: BranchUpdateInput!
  ) {
    updateBranch(
      organisationId: $organisationId
      branchId: $branchId
      branch: $branch
    ) {
      id
      name
      emailId
      overbookingAllowed
      depositCollection
      phoneNumber {
        phone
        country
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      timeZone
      owner {
        id
        email
      }
      operatingHours {
        day
        dayIndex
        workingFromTime
        workingToTime
        pickupFromTime
        pickupToTime
        returnFromTime
        returnToTime
        closed
      }
      minBookingDuration
      minimumBookingAmount {
        type
        value
      }
      minBookingAmountOptIn
      minBookingDurationOptIn
      preBookingQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
      isAdditionalDriverQuestionsEnabled
      additionalDriverQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
      }
      deliveryQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
      vehiclePreparationTime
      carShareGracePeriodMins
      durationBasedPricingEnabled
      useThresholds
      offenceThreshold
      pointThreshold
      holidays {
        id
        name
        startDate
        endDate
        isActive
      }
      addTaxOptIn
      taxes {
        id
        title
        type
        value
        rate
        subdivisionOptIn
        subdivisionType
        subdivisionValue
        subdivisionRate
      }
      epyxAccountNumber
      epyxSchemeReferenceNumber
      distanceUnit
      dashboardReminder
      showPaymentMethodInvoice
      paymentMethodString
      subscriptionSettings {
        dvlaVerificationEnabled
        creditCheckEnabled
        idvEnabled
      }
      autoPayEnabled
      autoPayDays
    }
  }
`;
