export const UPDATE_INSURANCE_POLICY = "UPDATE_INSURANCE_POLICY";
export const RESET_INSURANCE = "RESET_INSURANCE";

interface IUpdateInsurancePolicy {
  type: typeof UPDATE_INSURANCE_POLICY;
  payload: any;
}

interface IResetInsurance {
  type: typeof RESET_INSURANCE;
}

export type InsurancePolicyAction = IUpdateInsurancePolicy | IResetInsurance;
