export const assetTypes = [
	{
		value: "Vehicle",
		name: "vehicle"
	},
	// {
	// 	value: "Add-ons",
	// 	name: "add-ons"
	// },
	// {
	// 	value: "Driver",
	// 	name: "driver"
	// },
	// {
	// 	value: "3rd party",
	// 	name: "thireParty"
	// }
]

export const fuelTypes = [
	{
		value: "Petrol",
		name: "petrol"
	},
	{
		value: "Diesel",
		name: "diesel"
	},
	{
		value: "EV",
		name: "ev"
	},
	{
		value: "Hybrid",
		name: "hybrid"
	}
]

export const transmissionTypes = [
	{
		value: "Auto",
		name: "auto"
	},
	{
		value: "Manual",
		name: "manual"
	},
	{
		value: "Continuously variable transmission (CVT)",
		name: "cvt"
	},
	{
		value: "Semi-automatic and dual-clutch transmissions.",
		name: "automaticAndDualClutch"
	}
]

export const conditionTypes = [
	{
		value: "New",
		name: "new"
	},
	{
		value: "Used",
		name: "used"
	}
]


export const paymentSources = [
	{
		value: "OWNED",
		label: "Owned"
	},
]

export enum DialogType {
	APPROVE_REQUEST = "Approve Request",
	REJECT_REQUEST = "Reject Request",
	APPROVE_QUOTE = "Approve Quote",
	REJECT_QUOTE = "Reject Quote"
}

export enum DescriptionType {
	APPROVE_REQUEST_ITEM = "Are you sure you want to approve request item?",
	REJECT_REQUEST_ITEM = "Are you sure you want to reject request item?",
	APPROVE_QUOTE = "Are you sure you want to Approve Quote?",
	REJECT_QUOTE = "Are you sure you want to Reject Quote?"
}