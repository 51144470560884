import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Radio,
  RadioGroup,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { loadStripe } from "@stripe/stripe-js";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { DateTime as d } from "luxon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { CHECK_BOOKING_CONFIRMATION_ELIGIBILITY } from "../../../../../graphql/bookings/checkBookingConfirmationEligibility";
import { CHECK_FOR_TBA_WARNINGS } from "../../../../../graphql/bookings/CheckForTbaWarnings";
import { CREATE_PAYMENT } from "../../../../../graphql/invoices/createPaymentMutation";
import {
  IInvoice,
  IPaymentInput,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { loadConvergeUS } from "../../../../../utils/converge";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import FlatPickerBar from "../../../../common/FlatPicker";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  DATE_TYPE,
  PaymentGateway,
  formatGraphQLErrorMessage
} from "../../../../common/utils";
import { ICreditNotesToAdjust } from "./PaymentView";

interface IProps {
  bookingId: string;
  invoice: string;
  businessCustomer?: string;
  amount: number;
  creditAmount: number;
  longTermBooking?: boolean;
  redirectToInvoice?: boolean;
  invoiceData?: IInvoice;
  closeDialog?(): void;
  collectDeposit?: boolean;
  depositAmount?: number;
  tba?: boolean;
  minDate?: string;
  validAmount?: number;
  creditNotesToAdjust: ICreditNotesToAdjust[];
  isConsolidated?: boolean;
  isAutochargeSetup?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    basicButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
    },
  })
);

export const CardTransaction: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency, stripeAccountId, cardEnabled, autoChargeEnabled, convergeEnabled, convergeAutoChargeAvailable } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const snackbar = useSnackBar();
  const [capturedAt, setCapturedAt] = useState<string>("");
  const [amount, setAmount] = useState<number>(props.amount);
  const [transactionId, setTransactionId] = useState<string>("");
  const [paymentType, setPaymentType] = useState<string>("fullAmount");
  const [tbaWarning, setTbaWarning] = useState<boolean>(false);
  const [saveDetails, setSaveDetails] = useState<boolean>(false);
  const [paymentGateway, setPaymentGateway] = useState<string>(
    PaymentGateway.OFFLINE
  );
  const [paymentInput, setPaymentInput] = useState<IPaymentInput>();
  const [minimumPayableAmount, setMinimumPayableAmount] = useState<number>(0);

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise: any = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
    {
      stripeAccount: stripeAccountId
    }
  );

  useEffect(() => {
    if (
      autoChargeEnabled &&
      (paymentGateway === PaymentGateway.STRIPE ||
        (convergeAutoChargeAvailable &&
          paymentGateway === PaymentGateway.CONVERGE)) &&
      !props.isConsolidated &&
      !props.isAutochargeSetup
    ) {
      setSaveDetails(true);
    }
  }, [autoChargeEnabled, paymentGateway]);

  useEffect(() => {
    if (props.collectDeposit && props.depositAmount) {
      setMinimumPayableAmount(props.depositAmount)
    }
  }, [props.collectDeposit, props.depositAmount])

  const [checkForTbaWarnings] = useLazyQuery(CHECK_FOR_TBA_WARNINGS,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        setLoading(false);
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        navigate(`/update-booking?booking=${props.bookingId}&step=3`);
      },
      onCompleted: (data) => {
        if (data.checkForTbaWarnings) {
          setTbaWarning(true)
        } else {
          if (paymentInput) {
            createPayment({
              variables: {
                payment: {
                  ...paymentInput,
                  booking: props.bookingId
                },
                saveDetails,
                collectDeposit: props.collectDeposit || false,
                creditNotesToAdjust: props.creditNotesToAdjust
              }
            });
          }
        }
      }
    }
  )

  const [
    createPayment,
    { loading: sessionLoading }
  ] = useMutation(CREATE_PAYMENT, {
    onError: (error: ApolloError) => {
      setLoading(false);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: async (data) => {
      if (paymentGateway === PaymentGateway.OFFLINE) {
        if (data && data.createPayment) {
          setLoading(false);
          if (props.redirectToInvoice && props.closeDialog) {
            props.closeDialog();
          } else {
            navigate(`/view-booking?booking=${props.bookingId}`);
          }
        }
      } else if (paymentGateway === PaymentGateway.STRIPE) {
        if (
          data?.createPayment?.payment?.id
        ) {
          // Get Stripe.js instance
          const stripe = await stripePromise;
          // When the customer clicks on the button, redirect them to Checkout.
          if (stripe) {
            setLoading(false);
            if (props.redirectToInvoice) {
              navigate(`/payment?invoice=${props.invoice}&payment=${data.createPayment.payment.id}`)
            } else {
              navigate(`/payment?booking=${props.bookingId}&payment=${data.createPayment.payment.id}`)
            }
          }
        }
      } else if (paymentGateway === PaymentGateway.CONVERGE) {
        let paymentResponse = data.createPayment.payment;
        if (paymentResponse.convergePaymentSessionUrl) {
          window.location = paymentResponse.convergePaymentSessionUrl;
        } else if (paymentResponse.convergeUSSessionToken) { 
          loadConvergeUS({
            paymentSessionToken: paymentResponse.convergeUSSessionToken,
            onloadFunction() {
              if (props.closeDialog) {
                props.closeDialog();
              }
              setLoading(false);
            },
            redirectFunction() {
              if (props.invoiceData) {
                navigate(`/update-billing?invoice=${props.invoiceData.id}`);
              } else {
                navigate(`/view-booking?booking=${props.bookingId}`);
              }
            }
          });
        }
      }
    }
  });

  const [checkBookingConfirmationEligibility] = useLazyQuery(
    CHECK_BOOKING_CONFIRMATION_ELIGIBILITY,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        setLoading(false);
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        navigate(`/update-booking?booking=${props.bookingId}&step=3`);
      },
      onCompleted: () => {
        if (paymentInput) {
          if (props.tba) {
            checkForTbaWarnings({
              variables: {
                bookingId: props.bookingId
              }
            })
          } else {
            createPayment({
              variables: {
                payment: {
                  ...paymentInput,
                  booking: props.bookingId
                },
                saveDetails,
                collectDeposit: props.collectDeposit || false,
                creditNotesToAdjust: props.creditNotesToAdjust
              }
            });
          }
        }
      }
    }
  );

  useEffect(() => {
    setAmount(props.amount)
  }, [props.amount])

  const submitStripeForm = () => {
    setLoading(true);
    const payment: IPaymentInput = {
      amount: amount,
      booking: props.bookingId || "",
      businessCustomer: props.businessCustomer,
      currency,
      description: PaymentMode.CARD,
      expireBy: "",
      invoice: props.invoice,
      paymentGateway,
      paymentMode: PaymentMode.CARD,
      paymentType: "INWARD", 
      successUrl: `${window.location.protocol}//${window.location.host}/view-booking?booking=${props.bookingId}&status=success`,
      cancelUrl: `${window.location.protocol}//${window.location.host}/update-booking?booking=${props.bookingId}&step=3`
    };
    if (props.redirectToInvoice) {
      payment.successUrl = `${window.location.protocol}//${window.location.host}/update-billing?invoice=${props.invoice}&status=success`;
      payment.cancelUrl = `${window.location.protocol}//${window.location.host}/update-billing?invoice=${props.invoice}&status=failure`;
    }
    setPaymentInput(payment);
    if (!props.redirectToInvoice) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: props.bookingId
        }
      });
    } else {
      createPayment({
        variables: {
          payment: {
            ...payment,
            booking: props.bookingId || ""
          },
          saveDetails,
          creditNotesToAdjust: props.creditNotesToAdjust,
          isConsolidated: props.isConsolidated,
        }
      });
    }
  };

  const submitOfflineForm = () => {
    setLoading(true);
    const payment: IPaymentInput = {
      amount: amount,
      booking: props.bookingId || "",
      businessCustomer: props.businessCustomer,
      currency,
      description: PaymentMode.CARD,
      transactionId: transactionId,
      expireBy: "",
      invoice: props.invoice,
      paymentMode: PaymentMode.CARD,
      paymentGateway: PaymentGateway.OFFLINE,
      paymentType: "INWARD",
      capturedAt: capturedAt
    };
    setPaymentInput(payment);
    if (!props.redirectToInvoice) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: props.bookingId
        }
      });
    } else {
      createPayment({
        variables: {
          payment: {
            ...payment,
            booking: props.bookingId || ""
          },
          creditNotesToAdjust: props.creditNotesToAdjust,
          isConsolidated: props.isConsolidated,
        }
      });
    }
  };

  const AMOUNT_TOO_HIGH = `Amount must be less than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format((props.amount ? props.amount : props.validAmount || 0) / 100)}`;

  const AMOUNT_TOO_LOW = `Amount must be more than or equal to ${new Intl.NumberFormat(
    locale,
    {
      currency,
      style: "currency"
    }
  ).format(minimumPayableAmount / 100)}`;

  const stripeFormSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan((minimumPayableAmount - 1), AMOUNT_TOO_LOW)
      .max(props.amount, AMOUNT_TOO_HIGH)
  });

  const offlineFormSchema = Yup.object().shape({
    amount: Yup.number()
      .required()
      .moreThan((minimumPayableAmount - 1), AMOUNT_TOO_LOW)
      .max(props.amount ? props.amount : (props.validAmount || 0), AMOUNT_TOO_HIGH),
    transactionId: Yup.string().required("Reference Number is required.")
  });
  return (
    <Grid container style={{ display: "inline-block", padding: "1rem" }}>
      {!cardEnabled ?
        <Grid container>
          <Typography variant="h4">
            To use this payment mode please enable card payments in Payment and Integration Settings.
          </Typography>
        </Grid> :
        <>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                row
                name="paymentGateway"
                value={paymentGateway}
                onChange={(event) => {
                  setPaymentGateway(event.target.value);
                  setAmount(props.amount);
                }}
              >
                <FormControlLabel
                  value={PaymentGateway.OFFLINE}
                  control={<Radio />}
                  disabled={loading}
                  label={<Typography variant="body1">OTHER</Typography>}
                />
                <FormControlLabel
                  value={PaymentGateway.STRIPE}
                  control={<Radio />}
                  disabled={loading}
                  label={<Typography variant="body1">STRIPE</Typography>}
                />
                <FormControlLabel
                  value={PaymentGateway.CONVERGE}
                  control={<Radio />}
                  disabled={loading}
                  label={<Typography variant="body1">CONVERGE</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {paymentGateway === PaymentGateway.OFFLINE && (
            <>
              <Grid item xs={12}>
                <Formik
                  initialValues={{
                    amount: amount, transactionId: transactionId
                  }}
                  enableReinitialize
                  validationSchema={offlineFormSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    submitOfflineForm();
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    setSubmitting,
                    handleSubmit
                  }) => (
                    <Form>
                      <Grid container spacing={1}>
                        <Grid container item xs={12}>
                          <Box mt={0.2}></Box>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <Field
                            component={TextField}
                            placeholder="Reference Number"
                            label="Reference Number"
                            name={"transactionId"}
                            fullWidth
                            required
                            InputProps={{
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setTransactionId(e.target.value)
                              },
                              value: transactionId
                            }}
                          />
                          <Typography variant={"body2"} color="textSecondary">
                            This could be anything like Receipt Number etc., and is
                            only for internal use.
                          </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                          <Box mt={0.2}></Box>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <FlatPickerBar
                            enableTime={false}
                            handleDateChange={(value: Date) => {
                              setCapturedAt(d.fromJSDate(value).toUTC().toISO())
                            }}
                            minDate={props.minDate ? getLocalizedDateFormat(country, props.minDate, DATE_TYPE.CONDENSED) : getLocalizedDateFormat(country, d.now().startOf("day").toUTC().toISO(), DATE_TYPE.CONDENSED)}
                            maxDate={getLocalizedDateFormat(country, d.now().endOf("day").toUTC().toISO(), DATE_TYPE.CONDENSED)}
                            label={"Payment Date"}
                            placeholderValue={"Select Payment Date*"}
                            value={getLocalizedDateFormat(country, capturedAt, DATE_TYPE.CONDENSED)}
                            disabled={loading}
                            country={country}
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <Box mt={0.2}></Box>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <Field
                            component={TextField}
                            placeholder="Enter Amount"
                            label="Enter Amount"
                            name={"amount"}
                            fullWidth
                            required
                            InputProps={{
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setAmount(parseInt(e.target.value))
                              },
                              value: amount,
                              inputComponent: FloatInput as any
                            }}
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <Box mt={0.2}></Box>
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-start">
                          <Fab
                            variant="extended"
                            size="medium"
                            aria-label="add"
                            disabled={!capturedAt || loading}
                            onClick={() => {
                              setSubmitting(false)
                              handleSubmit()
                            }}
                          >
                            {loading && (
                              <CircularProgress
                                size={14}
                                style={{ color: "white", marginRight: "10px" }}
                              />
                            )}
                            Save
                          </Fab>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </>
          )}
          {paymentGateway === PaymentGateway.STRIPE &&
            (stripeAccountId ? (
              <>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      name="isPayingFull"
                      value={paymentType}
                      onChange={(event) => {
                        if (event.target.value === "fullAmount") {
                          setPaymentType("fullAmount");
                          setAmount(props.amount)
                        } else {
                          setPaymentType("customAmount");
                        }
                      }}
                    >
                      <FormControlLabel
                        value="fullAmount"
                        control={<Radio />}
                        disabled={loading}
                        label={
                          <Typography variant="body1">
                            Pay Total Due Amount
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="customAmount"
                        control={<Radio />}
                        disabled={loading}
                        label={
                          <Typography variant="body1">Pay Custom Amount</Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item container xs={12}>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      amount: amount,
                      transactionId: transactionId
                    }}
                    validationSchema={stripeFormSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      submitStripeForm();
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      setSubmitting,
                      handleSubmit
                    }) => (
                      <Form>
                        <Grid item xs={12}>
                          {paymentType === "customAmount" && (
                            <Grid item xs={12}>
                              <Field
                                component={TextField}
                                placeholder="Enter Amount to pay"
                                label="Custom Amount"
                                name={"amount"}
                                fullWidth
                                required
                                disabled={loading}
                                InputProps={{
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setAmount(parseInt(e.target.value))
                                  },
                                  value: amount,
                                  inputComponent: FloatInput as any
                                }}
                                inputProps={{
                                  hasCurrencyPrefix: true,
                                  allowNegative: false
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                        {!props.isConsolidated && !props.isAutochargeSetup && (
                          autoChargeEnabled ? 
                          <Grid item xs={12}>
                            <Typography>
                              Future charges related to the ongoing booking will be charged to this payment method
                            </Typography> 
                          </Grid> :
                           stripeAccountId && <Grid item xs={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={saveDetails}
                                      onChange={(e: any) => {
                                        setSaveDetails(e.target.checked)
                                      }}
                                      value={saveDetails}
                                      color="primary"
                                      name={"agreed"}
                                      disabled={sessionLoading || loading}
                                    />
                                  }
                                  label={
                                    <Typography variant="body1">
                                      Save this card to make payment for future invoices on this booking.
                                    </Typography>
                                  }
                                />
                              </FormGroup>
                            </Grid>
                          )}
                        <Grid item xs={12}>
                          <Grid item container>
                            <Box mt={2}></Box>
                          </Grid>
                          <Grid item container>
                            <Hidden smDown>
                              <Fab
                                variant="extended"
                                size="medium"
                                aria-label="add"
                                onClick={() => {
                                  setSubmitting(false)
                                  handleSubmit()
                                }}
                                disabled={sessionLoading || loading}
                              >
                                {sessionLoading && (
                                  <CircularProgress
                                    size={14}
                                    style={{ color: "white", marginRight: "10px" }}
                                  />
                                )}
                                Proceed To Pay
                              </Fab>
                            </Hidden>
                            <Hidden smUp>
                              <Button
                                style={{ width: 'calc(100vw - 90px)' }}
                                className={classes.basicButton}
                                variant="contained"
                                aria-label="add"
                                disabled={sessionLoading || loading}
                                onClick={() => {
                                  setSubmitting(false)
                                  handleSubmit()
                                }}
                              >
                                {sessionLoading && (
                                  <CircularProgress
                                    size={14}
                                    style={{ color: "white", marginRight: "10px" }}
                                  />
                                )}
                                Proceed To Pay
                              </Button>
                            </Hidden>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </>
            ) : (
              <Grid container xs={12}>
                <Typography style={{ margin: "1rem" }}>
                  Please add Stripe Account Id in organisation settings to use this
                  feature.
                </Typography>
              </Grid>
            ))}
          {paymentGateway === PaymentGateway.CONVERGE &&
            (convergeEnabled ? (
              <>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      name="isPayingFull"
                      value={paymentType}
                      onChange={(event) => {
                        if (event.target.value === "fullAmount") {
                          setPaymentType("fullAmount");
                          setAmount(props.amount)
                        } else {
                          setPaymentType("customAmount");
                        }
                      }}
                    >
                      <FormControlLabel
                        value="fullAmount"
                        control={<Radio />}
                        disabled={loading}
                        label={
                          <Typography variant="body1">
                            Pay Total Due Amount
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="customAmount"
                        control={<Radio />}
                        disabled={loading}
                        label={
                          <Typography variant="body1">Pay Custom Amount</Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item container xs={12}>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      amount: amount,
                      transactionId: transactionId
                    }}
                    validationSchema={stripeFormSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      submitStripeForm();
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      setSubmitting,
                      handleSubmit
                    }) => (
                      <Form>
                        <Grid item xs={12}>
                          {paymentType === "customAmount" && (
                            <Grid item xs={12}>
                              <Field
                                component={TextField}
                                placeholder="Enter Amount to pay"
                                label="Custom Amount"
                                name={"amount"}
                                fullWidth
                                required
                                disabled={loading}
                                InputProps={{
                                  onChange: (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setAmount(parseInt(e.target.value))
                                  },
                                  value: amount,
                                  inputComponent: FloatInput as any
                                }}
                                inputProps={{
                                  hasCurrencyPrefix: true,
                                  allowNegative: false
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                        {!props.isConsolidated && !props.isAutochargeSetup && convergeAutoChargeAvailable && (
                          autoChargeEnabled ? 
                          <Grid item xs={12}>
                            <Typography>
                              Future charges related to the ongoing booking will be charged to this payment method
                            </Typography> 
                          </Grid> :
                           convergeEnabled && <Grid item xs={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={saveDetails}
                                      onChange={(e: any) => {
                                        setSaveDetails(e.target.checked)
                                      }}
                                      value={saveDetails}
                                      color="primary"
                                      name={"agreed"}
                                      disabled={sessionLoading || loading}
                                    />
                                  }
                                  label={
                                    <Typography variant="body1">
                                      Save this card to make payment for future invoices on this booking.
                                    </Typography>
                                  }
                                />
                              </FormGroup>
                            </Grid>
                          )}
                        <Grid item xs={12}>
                          <Grid item container>
                            <Box mt={2}></Box>
                          </Grid>
                          <Grid item container>
                            <Hidden smDown>
                              <Fab
                                variant="extended"
                                size="medium"
                                aria-label="add"
                                onClick={() => {
                                  setSubmitting(false)
                                  handleSubmit()
                                }}
                                disabled={sessionLoading || loading}
                              >
                                {(sessionLoading || loading) && (
                                  <CircularProgress
                                    size={14}
                                    style={{ color: "white", marginRight: "10px" }}
                                  />
                                )}
                                Proceed To Pay
                              </Fab>
                            </Hidden>
                            <Hidden smUp>
                              <Button
                                style={{ width: 'calc(100vw - 90px)' }}
                                className={classes.basicButton}
                                variant="contained"
                                aria-label="add"
                                disabled={sessionLoading || loading}
                                onClick={() => {
                                  setSubmitting(false)
                                  handleSubmit()
                                }}
                              >
                                {(sessionLoading || loading) && (
                                  <CircularProgress
                                    size={14}
                                    style={{ color: "white", marginRight: "10px" }}
                                  />
                                )}
                                Proceed To Pay
                              </Button>
                            </Hidden>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </>
            ) : (
              <Grid container xs={12}>
                <Typography style={{ margin: "1rem" }}>
                  Please add Converge credentials in Payments & Integrations settings to use this
                  feature.
                </Typography>
              </Grid>
            ))}
        </>}
      {tbaWarning && (
        <ConfirmationDialog
          isOpen={tbaWarning}
          onCancel={() => {
            setTbaWarning(false)
            navigate(`/update-booking?booking=${props.bookingId}&step=3`);
          }}
          onConfirm={() => {
            if (paymentInput) {
              setTbaWarning(false)
              createPayment({
                variables: {
                  payment: {
                    ...paymentInput,
                    booking: props.bookingId
                  },
                  saveDetails,
                  collectDeposit: props.collectDeposit || false,
                  creditNotesToAdjust: props.creditNotesToAdjust
                }
              });
            }
          }}
          title=""
          description={"The vehicles in this booking have some future bookings. Do you wish to continue to with this TBA booking?"}
        />
      )}
    </Grid>
  );
};
