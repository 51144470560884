import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line'
import styles from '../index.module.css'
import { filteredDropOffTiming, filteredPickupTiming, SelectButton, monthDataTemplate } from '../../utils';
import MenuItem from '@mui/material/MenuItem';
import Flatpickr from "react-flatpickr";
import CircularProgress from '@mui/material/CircularProgress';
import { DateTime as d } from 'luxon';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import api from "../../api/Reports"
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { DATE_TYPE, formatGraphQLErrorMessage } from '../../../../common/utils';
import { captureErrorException } from '../../../../../utils/sentry';
import { GET_ALL_TIME_BOOKINGS } from '../../../../../graphql/reports/getInvoiceFinanceHistory';
import { ApolloError, useLazyQuery } from '@apollo/client';

interface IProps {
  chartFor: "PICKUP" | "DROPOFF"
}

const initialChartData = [{
  "id": "Pickup",
  "color": "hsl(35, 70%, 50%)",
  "data": monthDataTemplate
}]

const PickupFrequencyChart: React.FC<IProps> = ({ chartFor }) => {
  const snackbar = useSnackBar()
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const tenantId = userState && userState.currentTenancy ? userState.currentTenancy?.id : userState.tenancy.id;
  const [filters, setfilters] = useState<{ fromDate: string, toDate: string, basis: string }>({ fromDate: d.now().minus({ days: 7 }).toUTC().toISO(), toDate: d.now().toUTC().toISO(), basis: "DAY" });
  const [rawData, setRawData] = useState<any>();
  const [chartData, setChartData] = useState<any[]>(initialChartData);
  const [loader, setLoader] = useState<boolean>(false);

  const [getAllTimeBookings, { loading, data }] = useLazyQuery(GET_ALL_TIME_BOOKINGS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setLoader(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (rawData && filters.toDate) {
      const tempData = chartFor === "PICKUP" ? filteredPickupTiming(filters.fromDate, filters.toDate, filters.basis, rawData) : filteredDropOffTiming(filters.fromDate, filters.toDate, filters.basis, rawData);
      setChartData(tempData);
    }
  }, [filters.toDate, filters.basis, rawData]);

  useEffect(() => {
    setLoader(true);
    getAllTimeBookings();
  }, [])

  useEffect(() => {
    if (data && data.getAllTimeBookings) {
      setRawData(data.getAllTimeBookings);
    }
  }, [data]);

  const color = chartFor === "PICKUP" ? "#147AD6" : "#88C35C"

  return (
    <div className={styles.module}>
      <div className={styles.chartHeadline}>
        <span className="font-medium bold ">{chartFor === "PICKUP" ? "Pickup Freq" : "Drop Off Freq"}</span>
        <div className="flex cross-center">
          {loader ? <div className="margin-right flex cross-center">
            <CircularProgress size={20} thickness={5} />
          </div> :
            <>
              <div style={{ position: "relative" }}>
                <label htmlFor={`${chartFor}_FREQ_DATE_RANGE`} style={{ minWidth: 140 }} className={styles.filterInput}>{filters.fromDate ? getLocalizedDateFormat(country, filters.fromDate, DATE_TYPE.CONDENSED) : "From date"} - {filters.toDate ? getLocalizedDateFormat(country, filters.toDate, DATE_TYPE.CONDENSED) : "To date"}</label>
                <div style={{ position: "absolute", left: 0, right: 0, }}>
                  <Flatpickr
                    options={{
                      mode: "range",
                      static: true,
                      minuteIncrement: 1,
                      dateFormat: country === "United States" ? "m/d/Y" : "d/m/Y",
                      maxDate: d.now().toUTC().toISO()
                    }}
                    onChange={(dates) => setfilters({ ...filters, fromDate: d.fromFormat(`${dates[0].getFullYear()}/${dates[0].getMonth() + 1}/${dates[0].getDate()}`, "yyyy/M/d").toUTC().toISO(), toDate: dates[1] ? d.fromFormat(`${dates[1].getFullYear()}/${dates[1].getMonth() + 1}/${dates[1].getDate()}`, "yyyy/M/d").toUTC().toISO() : "" })
                    }
                    render={
                      ({ defaultValue, value, ...props }: any, ref: any) => (
                        <input id={`${chartFor}_FREQ_DATE_RANGE`} {...props} defaultValue={defaultValue} ref={ref} style={{ height: 0, width: 0, overflow: "hidden", opacity: 0, position: "absolute", left: -100, top: 30 }} />
                      )
                    }
                  />
                </div>
              </div>
              <SelectButton
                onChange={(basis) => setfilters({ ...filters, basis })}
                id={`${chartFor}_FREQ_BASIS_INPUT`}
                initialValue="DAY"
              >
                <MenuItem value="MONTH">
                  By Months
                </MenuItem>
                <MenuItem value="DAY">
                  By days of week
                </MenuItem>
                <MenuItem value="TIME">
                  By time of day
                </MenuItem>
              </SelectButton>
            </>}
        </div>
      </div>
      <ResponsiveLine
        data={chartData}
        margin={{ top: 50, right: 20, bottom: 30, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        yFormat=" >-.2f"
        curve="monotoneX"
        axisTop={null}
        axisRight={null}
        lineWidth={2}
        theme={{
          grid: {
            line: {
              stroke: "#f2f2f2",
              strokeWidth: 1
            }
          }
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          tickValues: 5,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: `No of ${getLocalizedBookingSyntex(country).toLowerCase()}s`,
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        enableArea={true}
        areaOpacity={0.1}
        tooltip={({ point }) => {
          return (<div
            style={{
              background: 'white',
              padding: '9px 12px',
              border: '1px solid #ccc',
              color: "#000",
              fontSize: 14,
            }}
          >
            <strong>{point.data.x} </strong> - {point.data.yFormatted}
          </div>)
        }}
        colors={[color]}
        pointSize={7}
        pointColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </div>
  )
}

export default PickupFrequencyChart
