import { gql } from "@apollo/client";

export const SEND_PROPOSAL_REQUEST_EMAIL = gql`
  mutation sendProposalRequestEmail($id: ID!, $proposalRequestId: ID!, $additionalMails: [String]) {
    sendProposalRequestEmail(id: $id, proposalRequestId: $proposalRequestId, additionalMails: $additionalMails)
  }
`;

export const SEND_PURCHASE_ORDER_EMAIL = gql`
  mutation sendPurchaseOrderEmail($id: ID!, $purchaseOrderId: ID!, $additionalMails: [String]) {
    sendPurchaseOrderEmail(id: $id, purchaseOrderId: $purchaseOrderId, additionalMails: $additionalMails)
  }
`;