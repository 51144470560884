import "../index.scss"
import {
  Tab,
  Tabs,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { IBooking } from "../../../../../reducers/bookings/types";
import { PaymentMode } from "../../../../../reducers/invoices/types";
import { TabPanel } from "../../../../common/TabPannel/TabPannel";
import CardTransactionDeposit from "./CardTransactionDeposit";
import CashTransactionDeposit from "./CashTransactionDeposit";
import PayByLinkDeposit from "./PayByLinkDeposit";

interface IProps {
  booking: IBooking;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexGrow: 1
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "unset"
  }
}));

const DepositPaymentView: React.FC<IProps> = (props) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={`${classes.root} payment-view-responsive`}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Payment tabs"
        className={classes.tabs}
      >
        <Tab label={PaymentMode.CARD} />
        <Tab label={PaymentMode.CASH} />
        <Tab label={PaymentMode.PAY_BY_LINK} />
        {/* {currency === "EUR" && <Tab label={PaymentMode.SEPA_BANK_DEBIT} />} */}
        {/* {currency === "GBP" && <Tab label={PaymentMode.BACS_BANK_DEBIT} />} */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <CardTransactionDeposit
          booking={props.booking}
          amount={props.booking.depositDetails?.amount || 0}
          handleClose={props.handleClose}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CashTransactionDeposit
          booking={props.booking}
          amount={props.booking.depositDetails?.amount || 0}
          handleClose={props.handleClose}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PayByLinkDeposit
          booking={props.booking}
          amount={props.booking.depositDetails?.amount || 0}
          handleClose={props.handleClose}
        />
      </TabPanel>
      {/* {currency === "EUR" && <TabPanel value={value} index={2}>
        <SepaTransactionExtension
          bookingId={props.bookingData.id}
          extensionId={props.extensionData.id}
          amount={props.amountToPay}
        />
      </TabPanel>} */}
      {/* {currency === "GBP" && <TabPanel value={value} index={2}>
        <BacsTransactionExtension
          bookingId={props.bookingData.id}
          extensionId={props.extensionData.id}
          amount={props.amountToPay}
        />
      </TabPanel>} */}
    </div>
  );
};

export default DepositPaymentView;
