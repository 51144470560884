import "./Customer.module.scss";
import "../../../common/style/phoneNumner.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  CssBaseline,
  Fab,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Theme,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Select
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik, FieldProps } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";
import { DateTime as d } from "luxon";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import {
  resetCustomer,
  updateCustomer
} from "../../../../actions/customer/actions";
import { CREATE_CUSTOMER } from "../../../../graphql/customers/createCustomerMutation";
import { GET_CUSTOMER } from "../../../../graphql/customers/getCustomerQuery";
import { GET_CUSTOMERS } from "../../../../graphql/customers/getCustomers";
import { UPDATE_CUSTOMER } from "../../../../graphql/customers/updateCustomerMutation";
import { getCustomer } from "../../../../reducers/customer/selectors";
import {
  CustomerPayloadType,
  Gender,
  ICustomer
} from "../../../../reducers/customer/types";
import { IAction, IAppState } from "../../../../store";
import FlatPickerBar from "../../../common/FlatPicker";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { v4 as uuidv4 } from "uuid";
import { DATE_TYPE, formatGraphQLErrorMessage, getUploadedImageByKey, returnfileContentTypeAndExtention } from "../../../common/utils";
import { IAddress } from "../../../../reducers/user/types";
import AddressPicker from "./AddressPicker/AddressPicker";
import { getDefaultCountryCode, getLocalizedDateFormat, getLocalizedTaxSyntex } from "../../../../utils/localized.syntex";
import { ICostCenter } from "../../../../reducers/organisation/types";
import NuvvenAutoCompleteTags from "../../../common/NuvvenAutoCompleteTags";
import { captureErrorException } from "../../../../utils/sentry";
import { useNavigate } from "react-router-dom";
import DragDropImages from "../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#f4f4fa",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    phoneNumber: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    root: {
      borderBlock: "10px",
      borderColor: "f4f4fa",
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    },
    save: {
      background: "#faaf40",
      border: "none",
      color: "#fff",
      cursor: "pointer",
      fontSize: "14px",
      height: "40px",
      width: "124px"
    }
  })
);

interface ICustomerProps {
  history: any;
  customer: ICustomer;
  updateCustomer(payload: CustomerPayloadType): void;
  resetCustomer(): void;
}

const Customer = (props: any) => {

  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate()
  const userState = useSelector((state: IAppState) => state.userReducer);
  const organisation = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [values, setValues] = useState(props.customer);
  const [licenseImages, setLicenseImages] = useState<string[]>([]);
  const [profilePicture, setProfilePicture] = useState<string[]>([]);
  const [uploadedImages, setUploadedImages] = useState<object[]>([]);
  const [uploadedProfile, setUploadedProfile] = useState<object[]>([]);
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(
    false
  );
  const [profileDialogVisible, setProfileDialogVisible] = useState<boolean>(
    false
  );
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<string>("");
  const [secPhoneError, setSecPhoneError] = useState<string>("");
  const [countryCode, setCountry] = useState<any>({
    primary: getDefaultCountryCode(country),
    secondary: getDefaultCountryCode(country)
  });
  const [getCustomer, { data }] = useLazyQuery(GET_CUSTOMER);
  const [minDate, setMinDate] = useState<string>(getLocalizedDateFormat(country, d.now().toUTC().toISO(), DATE_TYPE.CONDENSED));
  const [integrations, setIntegrations] = useState<string[]>([]);
  const [availableCostCenters, setAvailableCostCenters] = useState<ICostCenter[]>([])
  const [costCenters, setCostCenters] = useState<ICostCenter[]>([{
    name: "",
    projectIds: []
  }]);

  const [createCustomer, { loading: Saveloading }] = useMutation(CREATE_CUSTOMER, {
    onCompleted: () => {
      snackbar({
        message: "Customer saved successfully",
        variant: SnackBarVariant.SUCCESS
      })
      navigate("/individual-customer")
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
    update: (proxy, { data: { createCustomer } }) => {
      try {
        const result: any = proxy.readQuery({ query: GET_CUSTOMERS });
        proxy.writeQuery({
          data: { customers: result.customers.concat([createCustomer]) },
          query: GET_CUSTOMERS
        });
      } catch (err) {
        captureErrorException(err)
        return;
      }
    }
  });

  const [updateCustomer, { loading: updateLoading }] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: () => {
      snackbar({
        message: "Customer updated successfully!",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
    update: (proxy, { data: { updateCustomer } }) => {
      try {
        const result: any = proxy.readQuery({ query: GET_CUSTOMERS });
        const withoutCustomer = result.customers.filter(
          (customer: ICustomer) => customer.id !== props.customer.id
        );
        proxy.writeQuery({
          data: { customers: withoutCustomer.concat([updateCustomer]) },
          query: GET_CUSTOMERS
        });
        proxy.writeQuery({
          data: { customer: updateCustomer },
          query: GET_CUSTOMERS,
          variables: { id: props.customer.id }
        });
      } catch {
        return;
      }
    }
  });

  useEffect(() => {
    if (organisation && organisation.enableCostCenter && organisation.costCenters) {
      setAvailableCostCenters(organisation.costCenters)
    }
  }, [organisation])

  useEffect(() => {
    if (data) {
      setValues(props.customer);
      props.updateCustomer(props.customer);
      if (data.customer.license.dateOfExpiry && minDate >= (data.customer.license.dateOfExpiry)) {
        setMinDate(data.customer.license.dateOfExpiry)
      }
      if (data.customer.costCenters) {
        setCostCenters(data.customer.costCenters)
      }
    }
  }, [data]);

  useEffect(() => {
    if (props.customer.id !== "") {
      getCustomer({
        variables: {
          customerId: props.customer.id
        }
      });
    }
  }, []);

  function isUpdateScreen() {
    return props.customer.id === "" ? false : true;
  }

  const uploadDocument = async (
    files: File[]
  ) => {
    try {
      if (!userState.tenancy?.id) {
        return;
      }
      if (uploadInProgress) {
        return snackbar({
          message: "Please wait image upload in progress!",
          variant: SnackBarVariant.ERROR
        });
      }
      const licenceImageArray: any[] = [];
      const showUploadedImages: any[] = [];
      if (licenseImages && licenseImages.length >= 2) {
        return snackbar({
          message: "Maximum two images can be uploaded for licence!",
          variant: SnackBarVariant.ERROR
        });
      }
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        // 50MB limit exceeded
        if (file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        setUploadInProgress(true);
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
          const { fileExtension } = returnfileContentTypeAndExtention(file);
          const uniqueId = uuidv4();
          const key = `${uniqueId}.${fileExtension}`;
          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  licenceImageArray.push(key);
                  snackbar({
                    message: "Licence Image uploaded successfully",
                    variant: SnackBarVariant.SUCCESS
                  });
                  const uploadedFile = await getUploadedImageByKey(key);
                  if (uploadedFile) {
                    showUploadedImages.push(uploadedFile);
                  }
                  setUploadInProgress(false);
                  setDocumentDialogVisible(false);
                }
              });
          }
        } else {
          setDocumentDialogVisible(true);
          return snackbar({
            message: "Please only upload .jpeg/.jpg/.png image format!",
            variant: SnackBarVariant.ERROR
          });
        }
      }
      setLicenseImages(licenceImageArray);
      setUploadedImages(showUploadedImages);
    } catch (err: any) {
      snackbar({
        message: formatGraphQLErrorMessage(err?.message),
        variant: SnackBarVariant.ERROR
      });
    }
  };
  const uploadProfilePic = async (
    files: File[]
  ) => {
    try {
      if (!userState.tenancy?.id) {
        return;
      }
      if (uploadInProgress) {
        return snackbar({
          message: "Please wait image upload in progress!",
          variant: SnackBarVariant.ERROR
        });
      }
      const profilePicArr: any[] = [];
      const showUploadedImages: any[] = [];
      if (profilePicture && profilePicture.length > 1) {
        return snackbar({
          message: "Maximum one images can be uploaded for profile!",
          variant: SnackBarVariant.ERROR
        });
      }
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        // 50MB limit exceeded
        if (file.size > 52428800) {
          throw new Error("File size exceeded limit of 50MB");
        }
        setUploadInProgress(true);
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
          const { fileExtension } = returnfileContentTypeAndExtention(file);
          const uniqueId = uuidv4();
          const key = `${uniqueId}.${fileExtension}`;
          if (_e_) {
            await _e_
              .add({
                name: key,
                file: file,
                complete: async () => {
                  profilePicArr.push(key);
                  snackbar({
                    message: "Profile Image uploaded successfully",
                    variant: SnackBarVariant.SUCCESS
                  });
                  const uploadedFile = await getUploadedImageByKey(key);
                  if (uploadedFile) {
                    showUploadedImages.push(uploadedFile);
                  }
                  setUploadInProgress(false);
                  setProfileDialogVisible(false);
                  setValues({
                    ...values,
                    profilePicture: key
                  });
                }
              });
          }
        } else {
          setProfileDialogVisible(true);
          return snackbar({
            message: "Please only upload .jpeg/.jpg/.png image format!",
            variant: SnackBarVariant.ERROR
          });
        }
      }
      setProfilePicture(profilePicArr);
      setUploadedProfile(showUploadedImages);
    } catch (err: any) {
      snackbar({
        message: formatGraphQLErrorMessage(err?.message),
        variant: SnackBarVariant.ERROR
      });
    }
  };
  const onDeleteSelectedImage = (deletedFileObject: File) => {
    const images = licenseImages;
    const previewImages = uploadedImages;
    const deletedImageIndex = images.findIndex((image) => image === deletedFileObject.name);
    if (deletedImageIndex) {
      images.splice(deletedImageIndex, 1);
      previewImages.splice(deletedImageIndex, 1);
    }
    setLicenseImages([...images]);
    setUploadedImages([...previewImages]);
  }
  const onDeleteSelectedProfileImage = (deletedFileObject: File) => {
    const images = licenseImages;
    const previewImages = uploadedImages;
    const deletedImageIndex = images.findIndex((image) => image === deletedFileObject.name);
    if (deletedImageIndex) {
      images.splice(deletedImageIndex, 1);
      previewImages.splice(deletedImageIndex, 1);
    }
    setLicenseImages([...images]);
    setUploadedImages([...previewImages]);
  }

  const addRows = () => {
    const rowsArr = [...costCenters];
    rowsArr.push({
      name: "",
      projectIds: []
    });
    setCostCenters(rowsArr);
  };

  const getFeatures = (ccName: string) => {
    const ele = availableCostCenters.find((cc) => cc.name === ccName);
    if (ele) {
      return ele.projectIds
    }
    return []
  }

  function handleFormSubmit(values: any) {
    delete values.id;
    delete values.notes;
    delete values.status;
    delete values.verified;
    delete values.deleted;
    delete values.costCenterName;
    if (licenseImages && licenseImages.length) {
      values.license.images = [...values.license.images, ...licenseImages];
    }
    if (organisation.enableCostCenter) {
      if (costCenters.length) {
        if (costCenters.some((cc) => !cc.name)) {
          snackbar({
            message: "Cost Center Details can't be empty! Please remove empty rows.",
            variant: SnackBarVariant.ERROR
          })
          return
        }
        values.costCenters = costCenters
      } else {
        snackbar({
          message: "Cost Center Details can't be empty!",
          variant: SnackBarVariant.ERROR
        })
        return
      }
    }
    if (data) {
      updateCustomer({
        variables: {
          customer: {
            ...values,
            address: values.location && values.location.fullAddress,
          },
          customerId: props.customer.id
        }
      });
    } else {
      const customer = {
        ...values,
        address: values.location && values.location.fullAddress
      };
      createCustomer({
        variables: { customer }
      })
        .then(() => {
          props.resetCustomer();
          props.navigate("/individual-customer");
        })
        .catch(() => {
          return;
        });
    }
  }
  const eighteenYearsOldDate = getLocalizedDateFormat(country, d.now().minus({ year: 18 }).toUTC().toISO(), DATE_TYPE.CONDENSED)
  const hyearsold = getLocalizedDateFormat(country, d.now().minus({ year: 95 }).toUTC().toISO(), DATE_TYPE.CONDENSED)

  const customerSchema = Yup.object().shape({
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .test("test-is-valid-phone-number", "", function (value) {
          if (value && !isValidPhoneNumber(value)) {
            return this.createError({ message: "Invalid phone number" })
          }
          return true;
        })
        .required("Phone number is required.")
    }),
    dateOfBirth: Yup.date(),
    email: Yup.string()
      .required("Email is required.")
      .email("Please enter a valid email address."),
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    location: Yup.object().shape({
      fullAddress: Yup.string().required("Address is required"),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      street: Yup.string().required("Street is required"),
    }).required("Location is required"),
    secondaryEmail: Yup.string().email("Please enter a valid email address."),

  });

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        <Typography variant="h1" color="primary">
          Customers
        </Typography>
        <Box color="white" sx={{ pr: 1 }}></Box>
        <DoubleArrowIcon />
        <Box color="white" sx={{ pl: 1 }}></Box>
        {isUpdateScreen() ? (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            New
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Customer
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            validationSchema={customerSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              const updateValues = {
                ...values,
                phoneNumber: {
                  ...values.phoneNumber,
                  country: countryCode.primary
                },
                secondaryPhoneNumber: {
                  ...values.secondaryPhoneNumber,
                  country: countryCode.secondary
                }
              };
              handleFormSubmit(updateValues);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <Grid container item xs={12}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item container xs={12}>
                      <Typography variant="h2">NEW CUSTOMER DETAILS</Typography>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="First Name"
                        label="First Name"
                        name={"firstName"}
                        value={props.values.firstName}
                        onChange={props.handleChange}
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="Last Name"
                        label="Last Name"
                        name={"lastName"}
                        value={props.values.lastName}
                        onChange={props.handleChange}
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name={"phoneNumber.phone"}
                        required
                      >
                        {({ field, form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                          return (
                            <div className={`phone-input-container`}>
                              <label className={`${phoneError ? "input-error-label" : ""} phone-input-label`}>
                                Phone Number <sup className={"phone-input-required"}>*</sup>
                              </label>
                              <PhoneInput
                                {...field}
                                international={false}
                                defaultCountry={countryCode.primary}
                                placeholder="Enter phone number"
                                className={phoneError || (meta.touched && meta.error) ? "phone-input-error" : ""}
                                value={props.values.phoneNumber.phone}
                                name={"phoneNumber.phone"}
                                required
                                onChange={(val: any) => {
                                  if (val && isValidPhoneNumber(val)) {
                                    setPhoneError("");
                                  }
                                  setValues({
                                    ...props.values,
                                    phoneNumber: {
                                      ...props.values.phoneNumber,
                                      phone: val
                                    }
                                  });
                                }}
                                onBlur={() => {
                                  if (!props.values.phoneNumber.phone) {
                                    setPhoneError("Phone number is required.");
                                  } else if (!isValidPhoneNumber(props.values.phoneNumber.phone)) {
                                    setPhoneError("Enter a valid phone number.");
                                  } else {
                                    setPhoneError("");
                                  }
                                }}
                                onCountryChange={(val) => {
                                  setCountry({
                                    ...countryCode,
                                    primary: val
                                  });
                                }}
                              />
                              {
                                phoneError &&
                                <span className={"phone-error-message"}>{phoneError}</span>
                              }
                            </div>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name={"secondaryPhoneNumber.phone"}
                      >
                        {({ form: { setFieldValue, setTouched, touched, errors } }: FieldProps) => {
                          return (
                            <div className={`phone-input-container`}>
                              <label className={`${secPhoneError ? "input-error-label" : ""} phone-input-label`}>
                                Secondary Phone Number
                              </label>
                              <PhoneInput
                                international={false}
                                defaultCountry={countryCode.secondary}
                                placeholder="Enter secondary phone number"
                                value={props.values.secondaryPhoneNumber.phone}
                                name={"secondaryPhoneNumber.phone"}
                                onChange={(val: any) => {
                                  if (val && isValidPhoneNumber(val)) {
                                    setSecPhoneError("");
                                  } else if (val === undefined) {
                                    setSecPhoneError("");
                                  } else {
                                    setSecPhoneError("Enter a valid phone number.");
                                  }
                                  setValues({
                                    ...props.values,
                                    secondaryPhoneNumber: {
                                      ...props.values.secondaryPhoneNumber,
                                      phone: val
                                    }
                                  });
                                }}
                                onCountryChange={(val) => {
                                  setCountry({
                                    ...countryCode,
                                    secondary: val
                                  });
                                }}
                              />
                              {
                                secPhoneError &&
                                <span className={"phone-error-message"}>{secPhoneError}</span>
                              }
                            </div>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="Email Address"
                        label="Email Address"
                        name={"email"}
                        value={props.values.email}
                        onChange={props.handleChange}
                        fullWidth
                        required
                        inputProps={{ maxLength: 50 }}
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="Secondary Email Address"
                        label="Secondary Email Address"
                        name={"secondaryEmail"}
                        value={props.values?.secondaryEmail}
                        onChange={props.handleChange}
                        fullWidth
                        inputProps={{ maxLength: 50 }}
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name={"gender"}
                          fullWidth
                          type="text"
                          select
                          label="Gender"
                          InputProps={{
                            value: props.values.gender,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                              setValues({
                                ...props.values,
                                gender: event.target.value
                              })
                            }
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        >
                          {Object.values(Gender).map(
                            (element: string, key: number) => {
                              if (
                                element === Gender.MALE ||
                                element === Gender.FEMALE ||
                                element === Gender.OTHER ||
                                element === Gender.NOTTODISCLOSE ||
                                integrations.includes(element)
                              ) {
                                return (
                                  <MenuItem value={element} key={key}>
                                    {element.replace(/_/g, " ")}
                                  </MenuItem>
                                );
                              }
                            }
                          )}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="National Insurance Number"
                        label="National Insurance Number"
                        name={"nationalInsuranceNumber"}
                        value={props.values?.nationalInsuranceNumber}
                        onChange={props.handleChange}
                        inputProps={{ maxLength: 20 }}
                        fullWidth
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="Profession"
                        label="Profession"
                        name={"profession"}
                        value={props.values?.profession}
                        onChange={props.handleChange}
                        inputProps={{ maxLength: 30 }}
                        fullWidth
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="Driving Licence Number"
                        label="Driving Licence Number"
                        name={"license.licenseNumber"}
                        values={props.values?.license?.licenseNumber}
                        onChange={props.handleChange}
                        inputProps={{ maxLength: 20 }}
                        fullWidth
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="Private Hires No"
                        label="PCO/Private Hire DL No"
                        name={"customerPCONumber"}
                        values={props.values.customerPCONumber}
                        onChange={props.handleChange}
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        placeholder="Licence Issued By"
                        label="Licence Issued By"
                        name={"license.authority"}
                        values={props.values?.license?.authority}
                        onChange={props.handleChange}
                        inputProps={{ maxLength: 100 }}
                        fullWidth
                      ></Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl variant="outlined" fullWidth>
                        <FlatPickerBar
                          enableTime={false}
                          handleDateChange={(value: Date) => {
                            const license = {
                              ...props.values.license,
                              dateOfExpiry: d.now().endOf("day").toUTC().toISO()
                            };
                            setValues({
                              ...props.values,
                              license
                            })
                          }}
                          label={"Driving Licence Expiry Date"}
                          identifier={"dateOfExpiry"}
                          placeholderValue={" Driving Licence Expiry Date"}
                          value={getLocalizedDateFormat(country, props.values?.license?.dateOfExpiry, DATE_TYPE.CONDENSED)}
                          minDate={minDate}
                          country={country}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl variant="outlined" fullWidth>
                        <FlatPickerBar
                          enableTime={false}
                          handleDateChange={(value: Date) => {
                            setValues({
                              ...props.values,
                              dateOfBirth: d.fromJSDate(value).toUTC().toISO()
                            })
                          }}
                          label={"Date of Birth"}
                          identifier={"dateOfBirth"}
                          placeholderValue={"Date of Birth"}
                          value={getLocalizedDateFormat(country, props.values.dateOfBirth, DATE_TYPE.CONDENSED)}
                          maxDate={eighteenYearsOldDate}
                          minDate={hyearsold}
                          country={country}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <AddressPicker
                        fieldName="location"
                        required
                        onChange={(address: IAddress) => {
                          if (address) {
                            setValues({ ...props.values, location: address })
                          }
                        }}
                      />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name="accountNumber"
                        placeholder="Account Number"
                        label="Account Number"
                        value={props.values.accountNumber || ""}
                        onChange={props.handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <FormControl variant="outlined" fullWidth>
                        <FlatPickerBar
                          enableTime={false}
                          handleDateChange={(value: Date) => {
                            const license = {
                              ...props.values.license,
                              dateOfIssue: d.fromJSDate(value).toUTC().toISO()
                            };
                            props.setFieldValue("license", license);
                          }}
                          label={"Driving Licence Issue Date"}
                          identifier={"dateOfIssue"}
                          placeholderValue={"Driving Licence Issue Date"}
                          now={d.fromISO(props.values?.license?.dateOfIssue).toUTC().toISO()}
                          value={d.fromISO(props.values?.license?.dateOfIssue).toUTC().toISO()}
                          maxDate={d.now().toUTC().toISO()}
                          country={country}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.taxExempted}
                              onChange={() => {
                                setValues({
                                  ...props.values,
                                  taxExempted:
                                    !props.values.taxExempted
                                });
                              }}
                              value="taxExempted"
                              color="secondary"
                            />
                          }
                          label={
                            <Typography variant={"h5"}>
                              {`Check this for ${getLocalizedTaxSyntex(country)} Exempt Customer`}
                            </Typography>
                          }
                        />
                      </FormControl>
                    </Grid>
                    {organisation.enableCostCenter && availableCostCenters.length > 0 &&
                      <Grid container xs={12} style={{ margin: "0.8rem" }}>
                        <Grid container xs={12}>
                          <Grid item xs={11}>
                            <Typography
                              variant={"subtitle1"}
                            >
                              Cost Center Details
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              variant={"subtitle1"}
                              onClick={addRows}
                              style={{ cursor: "pointer" }}
                            >
                              + Add New
                            </Typography>
                          </Grid>
                        </Grid>
                        {costCenters.map((item, idx) => {
                          return (
                            <Grid container xs={12} style={{ marginTop: "1rem" }}>
                              <Grid container xs={11} spacing={2}>
                                <Grid item xs={12} md={4}>
                                  <Field
                                    component={TextField}
                                    required
                                    label={"Cost Center"}
                                    select
                                    inputProps={{
                                      onChange: (event: any) => {
                                        if (costCenters.some((cc) => cc.name === event.target.value)) {
                                          return snackbar({
                                            message: "Cost center already selected!",
                                            variant: SnackBarVariant.ERROR
                                          })
                                        }
                                        const item = costCenters[idx];
                                        const rowsArr = [...costCenters];
                                        const ele = availableCostCenters.find((element) => element.name === event.target.value)
                                        if (ele) {
                                          item.name = ele.name;
                                        }
                                        rowsArr.splice(idx, 1, item);
                                        setCostCenters(rowsArr);
                                      },
                                      value: item.name
                                    }}
                                    name={"costCenterName"}
                                    fullWidth
                                  >
                                    {availableCostCenters.map((cc) => (
                                      <MenuItem
                                        key={cc.name}
                                        value={cc.name}
                                      >
                                        {cc.name}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                  {/* <FormControl variant="outlined" fullWidth>
                                    <Autocomplete
                                      id="select-cost-center"
                                      options={availableCostCenters}
                                      getOptionLabel={(option: ICostCenter) => {
                                        console.log(option)
                                        if (option) {
                                          return option.name;
                                        }
                                        return ""
                                      }}
                                      onInputChange={(e, newval, reason) => console.log(newval)}
                                      style={{ width: "100%" }}
                                      renderInput={(params: any) => (
                                        <InputField
                                          {...params}
                                          value={item ? item.name : ""}
                                          label={"Cost Center"}
                                          variant="outlined"
                                          onChange={(val: any) => {console.log(val)}}
                                          // required
                                        />
                                      )}
                                      // value={item.name}
                                      // disabled={!!values.id}
                                      // onChange={(event: any, newValue: any) => {
                                      //   console.log(newValue)
                                      //   // setRequiredFieldUpdated(true);
                                      //   // if (newValue && newValue.id && businessCustomers) {
                                      //   //   const businessCustomerData = businessCustomers.find(
                                      //   //     (customer: IBookingBusinessCustomer) => {
                                      //   //       return customer.id === newValue.id;
                                      //   //     }
                                      //   //   );
                                      //   //   if (businessCustomerData) {
                                      //   //     setBusinessCustomer(businessCustomerData);
                                      //   //     setValues({
                                      //   //       ...values,
                                      //   //       businessCustomer: businessCustomerData.id
                                      //   //     });
                                      //   //   }
                                      //   // }
                                      // }}
                                    />
                                  </FormControl> */}
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <NuvvenAutoCompleteTags
                                    label="Project Ids"
                                    placeHolder="Project Ids"
                                    singleLine={true}
                                    freeSoloDisabled={true}
                                    handleTags={(tags: string[]) => {
                                      const item = costCenters[idx];
                                      const rowsArr = [...costCenters];
                                      item.projectIds = tags;
                                      rowsArr.splice(idx, 1, item);
                                      setCostCenters(rowsArr);
                                    }}
                                    customTags={true}
                                    values={item.projectIds}
                                    features={
                                      getFeatures(item.name)
                                    }
                                    columnLarge={12}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={1}>
                                <Typography
                                  variant={"subtitle1"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const rowsArr = [...costCenters];
                                    rowsArr.splice(idx, 1);
                                    setCostCenters(rowsArr);
                                  }}
                                >
                                  X
                                </Typography>
                              </Grid>
                            </Grid>
                          )
                        })}
                      </Grid>
                    }
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Fab
                        className="blackBackButton"
                        variant="extended"
                        size="medium"
                        aria-label="Update"
                        onClick={() => {
                          setDocumentDialogVisible(true);
                          setValues({ ...props.values });
                        }}
                      >
                        UPLOAD LICENCE IMAGE
                      </Fab>
                      <Grid item>
                        <Typography variant="h4">
                          Upload front and back image of Licence. Supported file format:
                          .png, .jpeg, .jpg!.
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Fab
                        className="blackBackButton"
                        variant="extended"
                        size="medium"
                        aria-label="Update"
                        onClick={() => {
                          setProfileDialogVisible(true);
                          setValues({ ...props.values });
                        }}
                      >
                        UPLOAD CUSTOMER IMAGE
                      </Fab>
                      <Grid item>
                        <Typography variant="h4">
                          Upload a image of the customer. Supported file format:
                          .png, .jpeg, .jpg!.
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type="submit"
                        disabled={Saveloading || updateLoading}
                      >
                        {Saveloading || updateLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                        Save
                      </Fab>
                    </Grid>
                  </Grid>
                </Grid>

              </Form>
            )}
          </Formik>
          <div>
            <DragDropImages
              title={"Upload Licence Images"}
              open={documentDialogVisible}
              handleCloseDragDrop={() => setDocumentDialogVisible(false)}
              handleSave={uploadDocument}
              filesLimit={2}
              initialFiles={uploadedImages}
            />
            <DragDropImages
              title={"Upload Profile Images"}
              open={profileDialogVisible}
              handleCloseDragDrop={() => setProfileDialogVisible(false)}
              handleSave={uploadProfilePic}
              filesLimit={1}
              initialFiles={uploadedProfile}
            />
          </div>
        </Paper>
      </Grid>
    </Grid >
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    customer: getCustomer(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IAction>) => {
  return {
    resetCustomer: () => {
      dispatch(resetCustomer());
    },
    updateCustomer: (payload: CustomerPayloadType) => {
      dispatch(updateCustomer(payload));
    }
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Customer);
