import "../index.scss";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IBooking, IBookingSchedule } from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { DateTime as d } from "luxon";
import { SimpleDateTimePicker } from "../../../../common/SimpleDateTimePicker";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../../common/utils";

interface IProps {
  booking: IBooking;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (vehicleIds: string[], endDate: string) => void;
}

interface IDateTime {
  date: string;
  time: string | number;
}

export const EarlyTerminationDialog = (props: IProps) => {
  const [open, setOpen] = useState<boolean>(props.isOpen);
  const [loading, setLoading] = useState<boolean>(false);
  const [bookingSchedules, setBookingSchedules] = useState<IBookingSchedule[]>([]);
  const [endDate, setEndDate] = useState<string>(props.booking.dropoffDateTime || "");
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const isOpen = props.isOpen;

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    props.onCancel();
  };

  useEffect(() => {
    if (props.booking && props.booking.activeSubscription) {
      setBookingSchedules(props.booking.currentBookingSchedules)
    }
  }, [props.booking])

  const handleConfirm = () => {
    setOpen(false);
    props.onConfirm(bookingSchedules.map((bs) => bs.id), endDate);
  };

  const handleSelect = (bookingSchedule?: IBookingSchedule) => {
    let schedules = [...bookingSchedules]
    if (bookingSchedule) {
      if (!schedules.some(schedule => schedule.id === bookingSchedule.id)) {
        schedules.push(bookingSchedule)
        setBookingSchedules(schedules)
      } else {
        schedules = schedules.filter(schedule => schedule.id !== bookingSchedule.id)
        setBookingSchedules(schedules)
      }
    } else {

    }
  }

  const ifSelected = (bookingSchedule: IBookingSchedule) => {
    if (bookingSchedules.some((bs) => bs.id === bookingSchedule.id)) {
      return true
    }
    return false
  }

  const validationSchema = Yup.object().shape({

  });

  return (
    <Dialog
      open={open}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        className: "early-termination-subscription-dialog"
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h2">
          Early Terminate Booking
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{}}
            onSubmit={(extensionData, { setSubmitting }) => {
              handleConfirm();
              setSubmitting(false);
            }}
          >
            {() => (
              <Form>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <Box mt={2}></Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h4">
                      {"Booking End Date: "}
                      <Typography variant="body1" style={{ display: "inline" }}>
                        {getLocalizedDateFormat(country, props.booking.dropoffDateTime || "", DATE_TYPE.EXPANDED)}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid container xs={8} style={{ paddingTop: "5px", paddingLeft: "5px" }}>
                    <SimpleDateTimePicker
                      date={endDate}
                      handleChange={(date: IDateTime) => {
                        const newEndDate = d.fromISO(date.date).toUTC().toISO();
                        if (date && date.date) {
                          setEndDate(newEndDate)
                        }
                      }}
                      classId={"early-termination"}
                      name={"dropOffDateTime"}
                      dateTitle={"New Drop-off Date"}
                      timeTitle={"New Drop-off Time"}
                      minDate={d.fromISO(props.booking.pickupDateTime).toUTC().toISO()}
                      maxDate={d.fromISO(props.booking.dropoffDateTime || "").toUTC().toISO()}
                    />
                  </Grid>
                </Grid>
                <Box mt={2}></Box>
                {
                  props.booking.isSubscription ? (
                    <Grid container xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="h4">
                          Subscription Details.
                        </Typography>
                      </Grid>
                      <Typography variant="body1" style={{ display: "inline-block" }}>
                        {props.booking?.activeSubscription?.subscription?.name}
                      </Typography>
                      <Typography variant="h4" style={{ display: "inline-block", marginLeft: "2rem" }}>
                        {props.booking?.activeSubscription?.vehicle?.licencePlate}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid container xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="h4">
                          Please select the vehicles you want to early terminate.
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={false}
                            onChange={(e) =>
                              handleSelect()
                            }
                            color="secondary"
                            name={"premium"}
                          />
                        }
                        label={"Select All"}
                      />
                    </FormGroup>
                  </Grid> */}
                      <Grid item xs={12}>
                        {props.booking.vehicleGroups.map((vg) => {
                          return vg.activeVehicleSchedules?.map((bs) => {
                            if (bs.endDate > d.now().toUTC().toISO() && bs.status === 0) {
                              return (
                                <Grid container xs={12}>
                                  <FormGroup row>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={ifSelected(bs)}
                                          onChange={(e) =>
                                            handleSelect(bs)
                                          }
                                          color="secondary"
                                          name={"premium"}
                                        />
                                      }
                                      disabled={endDate >= bs.endDate}
                                      label={
                                        <>
                                          <Typography variant="body1" style={{ display: "inline-block" }}>
                                            {vg.name?.toUpperCase()}
                                          </Typography>
                                          <Typography variant="h4" style={{ display: "inline-block", marginLeft: "2rem" }}>
                                            {bs.vehicle?.licencePlate?.toUpperCase()}
                                          </Typography>
                                        </>
                                      }
                                    />
                                  </FormGroup>
                                </Grid>
                              )
                            }
                          })
                        })}
                      </Grid>
                    </Grid>
                  )
                }
                <Grid container xs={12}>
                  <Grid container item xs={12} justifyContent="flex-end">
                    <Fab
                      size="medium"
                      variant="extended"
                      type="submit"
                      disabled={!bookingSchedules.length || (endDate < d.now().toUTC().toISO()) ||
                        ((props.booking.dropoffDateTime && (endDate >= props.booking.dropoffDateTime)) ? true : false)
                      }
                    >
                      {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                      Proceed
                    </Fab>
                    <Box pl={2}></Box>
                    <Fab
                      size="medium"
                      variant="extended"
                      className="blackBackButton"
                      onClick={handleClose}
                    >
                      Cancel
                    </Fab>
                  </Grid>
                </Grid>

              </Form>
            )}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
