import { gql } from "@apollo/client";

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($customer: CustomerInput!) {
    createCustomer(customer: $customer) {
      id
      status
      firstName
      lastName
      taxExempted
      phoneNumber {
        phone
        country
      }
      email
      address
      dateOfBirth
      gender
      secondaryPhoneNumber {
        phone
        country
      }
      location {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      license {
        licenseNumber
        dateOfExpiry
        authority
        endorsement
        images
      }
      verified
      verificationMethod
      deleted
      notes {
        id
        description
        createdBy
        createdDate
      }
      accountNumber
      overrideInvoiceDate
      overrideWeeklyInvoiceDate
      weeklyInvoicingDay
      invoicingDay
    }
  }
`;

export const CREATE_CUSTOMER_SIMPLE = gql`
  mutation createCustomer($customer: CustomerInput!) {
    createCustomer(customer: $customer) {
      id
      firstName
      lastName
      phoneNumber {
        phone
        country
      }
      email
      license {
        licenseNumber
        dateOfExpiry
        authority
        endorsement
        images
      }
      approvedDrivers {
        id
      }
    }
  }
`;

export const ADD_DRIVERS = gql`
  mutation addDrivers($customerId: String!, $drivers: [CustomerInput]!) {
    addDrivers(customerId: $customerId, drivers: $drivers) {
      id
      firstName
      lastName
      email
      phoneNumber {
        phone
        country
      }
      license {
        licenseNumber
        dateOfExpiry
        authority
        images
      }
    }
  }
`;
