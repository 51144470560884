import { gql } from "@apollo/client";

export const ADD_BUSINESS_CUSTOMERDRIVER = gql`
  mutation addBusinessCustomerDriver(
    $businessCustomerId: ID!
    $driver: BusinessCustomerApprovedDriverInput!
  ) {
    addBusinessCustomerDriver(
      businessCustomerId: $businessCustomerId
      driver: $driver
    ) {
        id
        status
        firstName
        lastName
        nationalInsuranceNumber
        profession
        phoneNumber {
          phone
          country
        }
        email
        license{
          licenseNumber
          dateOfExpiry
          authority
          images
        }
        location {
          city
          fullAddress
          country
          street
          zipcode
          state
        }
    }
  }
`;

export const UPDATE_BUSINESS_CUSTOMERDRIVER = gql`
  mutation updateBusinessCustomerDriver(
    $businessCustomerId: ID!
    $driverId: ID!
    $driver: BusinessCustomerApprovedDriverInput!
  ) {
    updateBusinessCustomerDriver(
      businessCustomerId: $businessCustomerId
      driverId: $driverId
      driver: $driver
    ) {
        id
        status
        firstName
        lastName
        nationalInsuranceNumber
        profession
        phoneNumber {
          phone
          country
        }
        email
        location{
          city
          country
          state
          fullAddress
          zipcode
          street
        }
        license{
          licenseNumber
          dateOfExpiry
          authority
          images
        }
    }
  }
`;

export const DELETE_BUSINESS_CUSTOMER_DRIVER = gql`
  mutation deleteBusinessCustomerDriver(
    $businessCustomerId: ID!
    $driverId: ID!
  ) {
    deleteBusinessCustomerDriver(
      businessCustomerId: $businessCustomerId
      driverId: $driverId
    ) {
      id
      businessName
      registrationNumber
      VAT
      registeredAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      tradingAddressSame
      tradingAddress {
        street
        city
        state
        zipcode
        country
        fullAddress
      }
      approvedDrivers {
        id
        status
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      authorizedSignatories {
        id
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      billing {
        phoneNumber {
          phone
          country
        }
        email
      }
      contact {
        firstName
        lastName
        phoneNumber {
          phone
          country
        }
        email
      }
      documents {
        id
        name
        title
        documentType
        expiryDate
        url
        createdAt
      }
    }
  }
`;