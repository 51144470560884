import { Field, FieldProps } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react'
import { IAddress } from '../../../../../reducers/user/types';
import { AddressPickerInput } from './AddressPickerInput';

interface IProps {
  fieldName: string;
  required?: boolean;
  addGeometry?: boolean;
  onChange(address?: IAddress): void;
  disabled?: boolean
}

const AddressPicker = (props: IProps) => {
  return (
    <Field
      component={TextField}
      name={props.fieldName}
      required={props.required || false}
      disabled={props.disabled || false}
    >
      {({ field, form: { setTouched, touched }, meta }: FieldProps) => {
        return (
          <AddressPickerInput
            address={field.value}
            placeholder="Type address"
            required={props.required || false}
            setAddress={(address?: IAddress) => {
              setTouched({ ...touched, [props.fieldName]: true });
              const fieldValue = address ? address : field.value;
              props.onChange(fieldValue)
            }}
            validationError={meta.error}
            touched={meta.touched}
            addGeometry={props.addGeometry}
          />
        )
      }}
    </Field>
  )
}
export default AddressPicker