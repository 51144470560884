import {
  createStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react";
import { constent } from "./Constent";
import { WithStyles, withStyles } from "@mui/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon className="notes-close-icon" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface IProps {
  open: boolean;
  sentMails: string[];
  handleClose: () => void;
}
export default function ShowMessage(props: IProps) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.open) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [props.open])

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose} >
          {" "}
          <p>
            {`${constent.SHOW_MESSAGE_TITLE}${props.sentMails.length > 1 ? 's' : ''}`}
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{[props.sentMails.map((mail: any) => (` ${mail}`))].toString()}</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
