import {
  Grid,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";

import ImageGallery from "../../../../../common/ImageGallery";

interface IImageView {
  interiorImages?: any;
  exteriorImages?: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageStyle: {
      height: 70,
      width: 70,
      borderRadius: 5
    }
  })
);
export const ImagesView = (props: IImageView) => {
  const styles = useStyles();
  const { exteriorImages, interiorImages } = props;
  const [open, setOpen] = React.useState(false);
  const [titleFlag, setTitleFlag] = useState("");
  const handleOpen = (title: string) => {
    if (title === "interiorImages") {
      setTitleFlag(title);
    } else {
      Object.keys(exteriorImages).map((key: string) => {
        if (title === key) {
          setTitleFlag(title);
        }
      });
    }
  };
  const handleClose = () => {
    setTitleFlag("");
  };
  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        {exteriorImages && (
          <React.Fragment>
            {exteriorImages.front &&
              exteriorImages.front.images.length > 0 &&
              exteriorImages.front.images.slice(0, 1).map(
                (img: string, index: number) =>
                  img && (
                    <Grid item xs={2} container key={index}>
                      <Grid item xs={12} container justifyContent="center">
                        <button
                          type="button"
                          onClick={() => handleOpen("front")}
                        >
                          <img src={img} className={styles.imageStyle} alt="" />
                        </button>
                        <Grid item xs={12} container justifyContent="center">
                          <Typography variant={"h4"}>
                            Front {exteriorImages.front.images.length}
                          </Typography>
                        </Grid>
                      </Grid>
                      <ImageGallery
                        title={"Vehicle front images"}
                        open={titleFlag === "front" ? true : false}
                        handleClose={() => {
                          handleClose();
                        }}
                        images={exteriorImages.front.images}
                      />
                    </Grid>
                  )
              )}
            {exteriorImages.top &&
              exteriorImages.top.images.length > 0 &&
              exteriorImages.top.images.slice(0, 1).map(
                (img: string, index: number) =>
                  img && (
                    <Grid item xs={2} container key={index}>
                      <Grid container item xs={12} justifyContent="center">
                        <button type="button" onClick={() => handleOpen("top")}>
                          <img src={img} className={styles.imageStyle} alt="" />
                        </button>
                      </Grid>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="h4">
                          Top {exteriorImages.top.images.length}
                        </Typography>
                      </Grid>
                      <ImageGallery
                        title={"Vehicle top images"}
                        open={titleFlag === "top" ? true : false}
                        handleClose={() => {
                          handleClose();
                        }}
                        images={exteriorImages.top.images}
                      />
                    </Grid>
                  )
              )}
            {exteriorImages.left &&
              exteriorImages.left.images.length > 0 &&
              exteriorImages.left.images.slice(0, 1).map(
                (img: string, index: number) =>
                  img && (
                    <Grid item xs={2} container key={index}>
                      <Grid container item xs={12} justifyContent="center">
                        <button
                          type="button"
                          onClick={() => handleOpen("left")}
                        >
                          <img src={img} className={styles.imageStyle} alt="" />
                        </button>
                      </Grid>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="h4">
                          Left {exteriorImages.left.images.length}
                        </Typography>
                      </Grid>
                      <ImageGallery
                        title={"Vehicle left images"}
                        open={titleFlag === "left" ? true : false}
                        handleClose={() => {
                          handleClose();
                        }}
                        images={exteriorImages.left.images}
                      />
                    </Grid>
                  )
              )}
            {exteriorImages.right &&
              exteriorImages.right.images.length > 0 &&
              exteriorImages.right.images.slice(0, 1).map(
                (img: string, index: number) =>
                  img && (
                    <Grid item xs={2} container key={index}>
                      <Grid container item xs={12} justifyContent="center">
                        <button
                          type="button"
                          onClick={() => handleOpen("right")}
                        >
                          <img src={img} className={styles.imageStyle} alt="" />
                        </button>
                      </Grid>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="h4">
                          Right {exteriorImages.right.images.length}
                        </Typography>
                      </Grid>
                      <ImageGallery
                        title={"Vehicle right images"}
                        open={titleFlag === "right" ? true : false}
                        handleClose={() => {
                          handleClose();
                        }}
                        images={exteriorImages.right.images}
                      />
                    </Grid>
                  )
              )}
            {exteriorImages.rear &&
              exteriorImages.rear.images.length > 0 &&
              exteriorImages.rear.images.slice(0, 1).map(
                (img: string, index: number) =>
                  img && (
                    <Grid item xs={2} container key={index}>
                      <Grid item xs={12} container justifyContent="center">
                        <button
                          type="button"
                          onClick={() => handleOpen("rear")}
                        >
                          <img src={img} className={styles.imageStyle} alt="" />
                        </button>
                      </Grid>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="h4">
                          Rear {exteriorImages.rear.images.length}
                        </Typography>
                      </Grid>
                      <ImageGallery
                        title={"Vehicle rear images"}
                        open={titleFlag === "rear" ? true : false}
                        handleClose={() => {
                          handleClose();
                        }}
                        images={exteriorImages.rear.images}
                      />
                    </Grid>
                  )
              )}
          </React.Fragment>
        )}
      </Grid>
      <Grid container item xs={12}>
        {interiorImages &&
          interiorImages.length > 0 &&
          interiorImages.map((img: string, index: number) => (
            <Grid container item xs={2} key={index}>
              <Grid container item xs={2}>
                <button
                  type="button"
                  onClick={() => handleOpen("interiorImages")}
                >
                  <img src={img} className={styles.imageStyle} alt="" />
                </button>
              </Grid>

              <ImageGallery
                title={"Vehicle Interior Damage Images"}
                open={titleFlag === "interiorImages" ? true : false}
                handleClose={() => {
                  handleClose();
                }}
                images={interiorImages}
              />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};
