import { gql } from "@apollo/client";

export const CREATE_EMAIL_TEMPLATE = gql`
  mutation createEmailTemplate(
    $templateType: String!, 
    $bodyHtmlString: String!,
    $designJsonString: String!, 
    $variables: [TemplateVariablesInput], 
    $subject: String,
    $isDefault: Boolean
  ) {
    createEmailTemplate(templateType: $templateType, bodyHtmlString: $bodyHtmlString, designJsonString: $designJsonString, variables:$variables, subject: $subject, isDefault: $isDefault ) {
      templateType
    }
  }
`;
