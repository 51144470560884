import { gql } from "@apollo/client";

export const TRANSFER_LIABILITY = gql`
  mutation transferLiability($customerId: String!, $noticeId: String!, $shareUrl: Boolean, $url: String) {
    transferLiability(customerId: $customerId, noticeId: $noticeId, shareUrl: $shareUrl , url: $url) {
        id
        referenceNumber
        status
        isPaid
    }
  }
`;