import {
  Dialog,
  DialogContent,
  Typography,
  Theme,
  Grid,
  Fab,
  TextField,
  CircularProgress,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";

import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { useMutation } from "@apollo/client";
import { GENERATE_API_KEY } from "../../../../../graphql/tenancy/updateTenancyMutation";
import { ApolloError } from "@apollo/client";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { WithStyles, createStyles, withStyles } from "@mui/styles";

interface IDialogProps {
  open: boolean,
  tenantId: string,
  handleClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const CreateApiKeyModal: React.FC<IDialogProps> = (props) => {
  const { open, handleClose, tenantId } = props;
  const snackbar = useSnackBar();
  const [name, setName] = useState("");
  const [secretKey, setSecretkey] = useState("");
  const [isKeyGenerated, setIsKeyGenerated] = useState(false);

  const [generateApiKey, { loading: generateApikeyLoading }] = useMutation(GENERATE_API_KEY, {
    onCompleted: (data: any) => {
      setSecretkey(data.generateApiKey);
      snackbar({
        message: "API Key generated successfully",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      className={"select-holidays-dialog"}
    >
      <React.Fragment>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p style={{ fontSize: 18, color: "var(--theme-accent)", padding: 0, margin: 0 }}>
            Create new secret key
          </p>
        </DialogTitle>
        <DialogContent dividers>
          {!isKeyGenerated ? (
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                <TextField
                  name="name"
                  label="Name"
                  placeholder="Name"
                  value={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setName(e.target.value)
                  }}
                  fullWidth
                  required
                ></TextField>
              </Grid>
              <Grid item container xs={12} style={{ marginTop: 20 }}>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="submit"
                  onClick={() => {
                    generateApiKey({
                      variables: {
                        tenancyId: tenantId,
                        name
                      }
                    })
                    setIsKeyGenerated(true);
                  }}
                  disabled={!name}
                >
                  Create
                </Fab>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Please save this secret key somewhere safe. For security reason, <b>You will not be able to view it again</b>.If you lose this secret key, you will need to regenerate a new one.
                </Typography>
              </Grid>
              <Grid item xs={11} md={11} style={{ marginTop: 10 }}>
                <TextField
                  name="secretKey"
                  label="Secret Key"
                  placeholder="Secret Key"
                  value={secretKey}
                  disabled
                  fullWidth
                  required
                ></TextField>
              </Grid>
              <Grid item xs={1} md={1} style={{ marginTop: 16 }}>
                <span style={{ cursor: "pointer", marginTop: 6 }} title="Copy">
                  {generateApikeyLoading ?
                    <CircularProgress size={14} /> : (
                      <FileCopyIcon style={{ color: "blue" }} onClick={() => {
                        navigator.clipboard.writeText(secretKey);
                        snackbar({
                          message: "Copied",
                          variant: SnackBarVariant.SUCCESS
                        })
                      }} />
                    )}
                </span>
              </Grid>
              <Grid item container xs={12} style={{ marginTop: 20 }}>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="submit"
                  onClick={() => {
                    handleClose()
                  }}
                >
                  Done
                </Fab>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </React.Fragment>
    </Dialog >
  )
}