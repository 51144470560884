import { gql } from "@apollo/client";

export const GET_CREDIT_NOTE = gql`
  query creditNote($id: String!){
    creditNote(id: $id) {
      id
      creditNoteRef
      creditNoteType
      totalAmount
      availableAmount
      refundedAmount
      createdAt
      versionNumber
      sentToXero
      xeroCreditNoteStatus
      allocations {
        amount
        allocatedAt
        invoice {
          id
          invoiceRef
        }
      }
      refunds {
        amount
        refundRef
        status
        mode
        currency
        id
        paymentIntent
        paymentId
        paymentGateway
        dateCreated
      }
      paymentsLinked {
        amount
        id
        status
      }
      invoice {
        invoiceRef
        id
      }
      booking {
        referenceNumber
        id
      }
      customer {
        firstName
        lastName
        id
        email
      }
      businessCustomer {
        businessName
        id
        billing {
          email
        }
        contact {
          firstName
          lastName
        }
      }
      creditNoteItems {
        type
        description
        totalAmount
        quantity
        taxAmount
        subTotal
        unitPrice
        taxAmount
      }
    }
  }
`