import { useLazyQuery, ApolloError } from "@apollo/client";
import { CircularProgress, Grid, CssBaseline, Typography, Fab } from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addTableState } from "../../../../actions/tableState/actions";
import { GET_PARTNERS } from "../../../../graphql/partners/getPartnersQuery";
import { IPartner } from "../../../../reducers/Procurement/types";
import { ITable, TableNames } from "../../../../reducers/tableState/types";
import { IAppState } from "../../../../store";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from "../../../common/utils";
import { GET_ASSET_COUNT, GET_ASSETS } from "../Graphql/assetQueries";
import { AssetTypes, AssetTypesValue } from "../asset.types";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";

export const Assets: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(10);
  const [assetCount, setAssetCount] = useState<number>(0);

  const [
    loadAssets,
    { loading, data }
  ] = useLazyQuery(GET_ASSETS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [getAssetCount] = useLazyQuery(
    GET_ASSET_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (assetCountData) => { setAssetCount(assetCountData.getAssetCount) }
    }
  );

  useEffect(() => {
    if (userState.tenancy) {
      getAssetCount()
      loadAssets({
        variables: {
          limit,
          offset: 0
        }
      })
    }
  }, [userState.currentOrganisation])

  useEffect(() => {
    if (data && data.assets) {
      const reShapeRows = data.assets.map((asset: any) => {
        const reshapeAsset = {
          ...asset
        }
        return reshapeAsset;
      })
      setRows(reShapeRows)
    }
  }, [data]);

  const options: MUIDataTableOptions = {
    count: assetCount,
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: IPartner = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/update-asset?asset=${row.id}`);
      }
    },
    rowsPerPage: 10,

    // onChangeRowsPerPage: (numberOfRows: number) => {
    //   dispatch(
    //     addTableState({
    //       tableName: TableNames.PARTNERS,
    //       rowsPerPage: numberOfRows
    //     })
    //   );
    // },
    // rowsPerPage:
    //   partnersTable && partnersTable.rowsPerPage
    //     ? partnersTable.rowsPerPage
    //     : 10,
    // onColumnSortChange: (changedColumn: string, direction: string) => {
    //   dispatch(
    //     addTableState({
    //       tableName: TableNames.PARTNERS,
    //       columnName: changedColumn,
    //       direction
    //     })
    //   );
    // },
    // onTableInit: () => {
    //   if (partnersTable && sortOrder) {
    //     setTableSortOrder(partnerTableColumn, partnersTable, sortOrder);
    //   }
    // }
  };

  // const columns: any = [
  //   {
  //     label: "Asset Tag",
  //     name: "assetTag"
  //   },
  //   {
  //     label: "Asset Type",
  //     name: "assetType",
  //     options: {
  //       customBodyRender: (value: AssetTypes) => AssetTypesValue[value]
  //     }
  //   },
  //   {
  //     label: "Vehicle",
  //     name: "vehicle.licencePlate",
  //   },
  //   {
  //     label: "Created Date",
  //     name: "dateCreated",
  //     options: {
  //       customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
  //     }
  //   },
  //   {
  //     label: "Status",
  //     name: "isActive",
  //     options: {
  //       customBodyRender: (value: boolean) => value === true ? "Active" : "Deactivated"
  //     }
  //   },
  // ];

  
  const columns: any = [
    {
      label: "Asset Tag",
      name: "assetTag"
    },
    {
      label: "Registration Number",
      name: "registrationNumber"
    },
    {
      label: "Serial Number",
      name: "serialNumber"
    },
    {
      label: "Asset Type",
      name: "assetType",
      options: {
        customBodyRender: (value: AssetTypes) => AssetTypesValue[value]
      }
    },
    {
      label: "Make",
      name: "make"
    },
    {
      label: "Model",
      name: "model"
    },
    {
      label: "Color",
      name: "color"
    },
    {
      label: "Status",
      name: "isActive",
      options: {
        customBodyRender: (value: boolean) => value === true ? "Active" : "Deactivated"
      }
    },
  ];

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Assets
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          className="createButton"
          onClick={() => {
            navigate("/new-asset");
          }}
        >
          Create
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        <NuvvenTable
          title=""
          rows={rows}
          columns={columns}
          options={options}
          setSelection={() => { }}
        />
      </Grid>
    </Grid>
  )
}
