import React from "react";
import { DamageTypes } from "../../../reducers/damageVehicle/types";
import { coordinateOffset } from "../utils";
import { getFrogDiagram } from "./frogDiagram";
// Fixme for every function using vehicle Add interfaces for vehicle and damages.

export const successLoadDamage = (damage: any, count: number, markerRadius: number, markerFont: number, refNo: string) => {
  let xVal = coordinateOffset + parseInt(damage.location?.x);
  let yVal = coordinateOffset + parseInt(damage.location?.y);
  const DamageCoordinateItem = (<g id={`damage ${count}`} style={{ cursor: "pointer" }} >
    <circle cx={`${xVal}`} cy={`${yVal}`} r={`${markerRadius}`} fill="red" stroke-width="0.5" />
    <text text-anchor="middle" x={`${xVal}`} y={`${yVal}`} dy="0.30em" font-size={`${markerFont}em`} stroke-width="0.8" stroke="white" fill="white" >
      {count}
    </text>
  </g>);
  const DamageTableItem = {
    id: count,
    damageArea: damage.damageArea || "",
    damageKind: damage.damageKind || "",
    description: damage.descriptions || ""
  };
  return {
    DamageTableItem,
    DamageCoordinateItem
  }
}
export const wrongLoadDamage = (damage: any, count: number, markerRadius: number, markerFont: number, refNo: string) => {
  let xVal = coordinateOffset + parseInt(damage.location?.x);
  let yVal = coordinateOffset + parseInt(damage.location?.y);
  const DamageCoordinateItem = (<g id={`damage ${count}`} style={{ cursor: "pointer" }}>
    <circle cx={`${xVal}`} cy={`${yVal}`} r={`${markerRadius}`} fill="yellow" stroke-width="0.5" />
    <text text-anchor="middle" x={`${xVal}`} y={`${yVal}`} dy="0.30em" font-size={`${markerFont}em`} stroke-width="0.8" stroke="black" fill="black">
      {count}
    </text>
  </g>);
  const DamageTableItem = {
    id: count,
    damageArea: damage.damageArea || "",
    damageKind: damage.damageKind || "",
    description: damage.descriptions || ""
  };
  return {
    DamageTableItem,
    DamageCoordinateItem
  }
}
export const markDamages = (data: any, frogDiagram: string) => {
  const { interior, exterior } = getFrogDiagram(frogDiagram);
  const interiorCoordinates: any = [];
  const exteriorCoordinates: any = [];
  data.map((damage: any) => {
    if (damage.damageType === DamageTypes.EXTERIOR && frogDiagram) {
      const damageData = frogDiagram === damage.frogDiagram ? successLoadDamage(damage, damage.id, exterior.markerRadius, exterior.markerFont, damage.referenceNumber) : wrongLoadDamage(damage, damage.id, exterior.markerRadius, exterior.markerFont, damage.referenceNumber);
      exteriorCoordinates.push(damageData.DamageCoordinateItem)
    }
    if (damage.damageType === DamageTypes.INTERIOR && frogDiagram) {
      const damageData = frogDiagram === damage.frogDiagram ? successLoadDamage(damage, damage.id, interior.markerRadius, interior.markerFont, damage.referenceNumber) : wrongLoadDamage(damage, damage.id, interior.markerRadius, interior.markerFont, damage.referenceNumber);
      interiorCoordinates.push(damageData.DamageCoordinateItem)
    }
  });
  return {
    exterior: exteriorCoordinates,
    interior: interiorCoordinates,
    svg: { interior, exterior }
  };
};