import { UPDATE_INVOICE } from "../../actions/invoices/types";
import { initialState } from "./consts";
import { IInvoicesState } from "./types";

export default function (
  state: IInvoicesState = initialState,
  action: any
): IInvoicesState {
  switch (action.type) {
    case UPDATE_INVOICE:
      return { ...state, invoice: action.payload };
    default:
      return state;
  }
}
