import "./index.scss";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Tab,
  Tabs,
  Typography,
  styled
} from "@mui/material";
import Smartcar from "@smartcar/auth";
import { ApolloError } from "@apollo/client";
import { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import SmartcarImage from "../../../../assets/images/smartcar.png";
import { EXCHANGE_CODE } from "../../../../graphql/smartcar/exchangeCode";
import { GET_ALL_SMART_CARS } from "../../../../graphql/smartcar/getAllVehicles";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../../common/NuvvenTable/types";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { IVehicle } from "../../../../reducers/fleet/types";
import { IAppState } from "../../../../store";
import { withStyles } from "@mui/styles";

interface ISmartCarProps { }

const SmartcarConnectView = styled("div")({
  backgroundColor: "white",
  borderRadius: 4,
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 20,
  paddingBottom: 20,
  justifyContent: 'center',
  alignItems: 'center'
});

const TabCont = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: 20
});

const HeaderTitle = styled("h2")({
  fontSize: 18,
  fontWeight: 700,
  margin: 0,
  marginRight: 40
})
export enum SmartConnectionStatus {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED"
}

const StyledTabs = withStyles({
  root: {
    backgroundColor: "transparent"
  },
  indicator: {
    height: 3,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: "var(--theme-accent)"
  }
})(Tabs);

const StyledTab = withStyles({
  root: {
    textTransform: "none",
    minWidth: "80px !important",
    color: "#333",
    "&:hover": {
      color: "var(--theme-accent)",
      opacity: 1
    },
    "&$selected": {
      color: "var(--theme-accent)"
    }
  },
  selected: {}
})(Tab);

export const SmartCarOnboarding = (props: ISmartCarProps) => {
  const snackbar = useSnackBar();

  const [missingVINs, setMissingVINs] = useState("");
  const [smartcarRows, setSmartcarRows] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const [tab, setTab] = useState(0);
  const userState = useSelector((state: IAppState) => state.userReducer);


  const [
    getAllSmartVehicles,
    { loading: loadingVehicles, data: vehiclesData }
  ] = useLazyQuery(GET_ALL_SMART_CARS, {
    fetchPolicy: "cache-and-network",
    onError: (error) => {
      snackbar({
        message: error.message,
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    getAllSmartVehicles();
  }, []);


  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const tab = params.get("tab");
      if (tab) {
        setTab(parseInt(tab));
      }
    }
  }, [location]);


  useEffect(() => {

    function reshapeVehicleIntoRow({ vehicle }: { vehicle: IVehicle }) {
      return {
        id: vehicle.id,
        make: vehicle.make,
        model: vehicle.model,
        licencePlate: vehicle.licencePlate,
        year: vehicle.year,
        branchId: vehicle.branchId
      };
    }

    if (vehiclesData && vehiclesData.getAllSmartCars) {
      setSmartcarRows(
        vehiclesData.getAllSmartCars.map((vehicle: any) => reshapeVehicleIntoRow(vehicle))
      );
    }
  }, [vehiclesData]);

  const smartcar = new Smartcar({
    clientId: process.env.REACT_APP_SMARTCAR_CLIENT_ID,
    redirectUri: process.env.REACT_APP_SMARTCAR_REDIRECT_URI,
    scope: [
      "required:control_security",
      "required:read_vin",
      "required:read_location",
      "read_vehicle_info",
      "read_odometer",
      "read_tires",
      "read_fuel",
      "read_charge",
      "read_battery",
      "read_engine_oil",
      "read_security"
    ],
    mode: process.env.REACT_APP_NODE_ENV?.toUpperCase() === 'PRODUCTION' ? 'live' : 'simulated',
    onComplete: function (err: string, code: string) {
      if (err) {
        // handle errors from Connect (i.e. user denies access)
        snackbar({
          message: err,
          variant: SnackBarVariant.ERROR
        });
      }
      exchangeCodeMutation({
        variables: {
          code: code
        }
      });
    }
  });

  function openSmartCar() {
    try {
      smartcar.openDialog({
        forcePrompt: true,
        flags: ["country:GB", "account_sync:true"]
      });
    } catch (err: any) {
      // Handle error
    }
  }

  const [
    exchangeCodeMutation,
    { loading: loadingExchangeCode }
  ] = useMutation(EXCHANGE_CODE, {
    onCompleted: ({ exchangeCode }) => {
      if (exchangeCode) {
        const missingVinsArr = exchangeCode.missingVINs;
        if (missingVinsArr && missingVinsArr.length > 0) {
          const missingVINsString =
            missingVinsArr.slice(0, missingVinsArr.length - 1).join(", ") +
            ", and " +
            missingVinsArr.slice(-1);
          setMissingVINs(missingVINsString + " ");
        }
      }
      snackbar({
        message: `${exchangeCode.message}`,
        variant: SnackBarVariant.INFORMATION
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: `Error while exchanging smartcar code: ${error.message}`,
        variant: SnackBarVariant.ERROR
      });
    }
  });

  // TODO Table of all the broken smartcar vehicles that need to reconnecting
  // TODO Table of connected vehicles

  const smartcarColumns: MUIDataTableColumnDef[] = [
    {
      label: "Vehicle",
      name: "licencePlate"
    },
    {
      label: "Make",
      name: "make"
    },
    {
      label: "Model",
      name: "model"
    },
    {
      label: "Year",
      name: "year"
    }
  ];

  const options: MUIDataTableOptions = {
    // custom search for license plate search
    selectableRows: SelectableRows.NONE,
    customSearch: (searchQuery: any, currentRow: any, columns: any) => {
      let isFound = false;
      currentRow.forEach((col: any) => {
        let value;
        if (col) {
          if (col.licencePlate) {
            Object.keys(col).forEach((key: string) => {
              if (col[key] != null) {
                value = col[key].toString().toLowerCase();
                if (value.indexOf(searchQuery.toLowerCase()) >= 0) {
                  isFound = true;
                }
              }
            });
          } else {
            value = col.toString().toLowerCase();
            if (value.indexOf(searchQuery.toLowerCase()) >= 0) {
              isFound = true;
            }
          }
        }
      });
      return isFound;
    },
    onRowClick: (
      rowData: any,
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: any = smartcarRows[rowMeta.dataIndex];
      if (
        row && row?.id && row?.branchId
      ) {
        if (row?.branchId !== userState.currentBranch.id) {
          return (
            snackbar({
              message: "Unable to view vehicle, please confirm vehicle is in current branch and try again",
              variant: SnackBarVariant.ERROR
            })
          )
        }
        navigate(`/update-vehicle?vehicle=${row.id}`);
      }
    },
    download: false
  };

  const handleChange = (event: any, newValue: any) => {
    setTab(newValue);
    navigate(`/smartcar?tab=${newValue}`);
  };

  return (
    <>
      <TabCont>
        <CssBaseline />

        <HeaderTitle>
          Smartcar
        </HeaderTitle>

        <div>
          <StyledTabs value={tab} onChange={handleChange}>
            <StyledTab disableRipple label="Connect Vehicle" />
            <StyledTab disableRipple label="Existing Vehicles" />
          </StyledTabs>
        </div>
      </TabCont>

      {tab === 0 && (
        <>
          {loadingExchangeCode ? (
            <CircularProgress />
          ) : (
            <SmartcarConnectView>
              {missingVINs && (
                <Typography variant="body1" align="left" color="error">
                  Sync completed, however the vehicles with the following VIN
                  numbers could not be found in your inventory: {missingVINs}!
                  Please check they are present and sync again.
                </Typography>
              )}

              <img
                src={SmartcarImage}
                alt={"Smartcar Coastr"}
                className="smartCarImage"
              />

              <Typography variant="body1" paragraph>
                Coastr offers keyless entry so you (vehicle rental operators)
                can offer your customers a truly digital & fully contactless car
                rental experience. For this, Coastr integrates with Smartcar to
                connect to your car instantly and effortlessly in 4 simple steps
                without any additional software installation. Once connected,
                you can track a vehicle's location, remotely lock or unlock your
                vehicle, view odometer readings, fuel tank levels, EV battery &
                charging status, and much more.
              </Typography>

              <div className="flex fill main-center">
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Create"
                  onClick={openSmartCar}
                >
                  Connect Vehicles
                </Fab>
              </div>
            </SmartcarConnectView>
          )}
        </>
      )}

      {tab === 1 && (
        <Grid item container xs={12} style={{ marginTop: 15 }}>
          {loadingVehicles ? (
            <CircularProgress />
          ) : (
            <NuvvenTable
              title={""}
              rows={smartcarRows}
              columns={smartcarColumns}
              options={options}
              setSelection={(item: any) => item}
            />
          )}
        </Grid>
      )}
    </>
  );
};
