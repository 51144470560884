import { gql } from "@apollo/client";

export const IMMOBILISE_VEHICLE = gql`
  mutation immobiliseVehicle($deviceEsn: String!, $vehicleId: String) {
    immobiliseVehicle(deviceEsn: $deviceEsn, vehicleId: $vehicleId) {
      success
      message
    }
  }
`;
