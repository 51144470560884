import "./index.scss";

import {
  Grid,
  Typography,
  ButtonBase,
  Card,
  CardContent,
  Box
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  setServiceLocation
} from "../../../../../actions/organisation/actions";
import {
  IServiceLocation
} from "../../../../../reducers/organisation/types";

const useStyles = makeStyles(() =>
  createStyles({
    cardStyle: {
      padding: "5px",
      height: "180px",
      borderRadius: "12px",
    },
  })
);


interface IProps {
  serviceLocations: any
}



const MobileServiceLocationCards = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (props.serviceLocations) {
      setRows(props.serviceLocations);
    }
  }, [props.serviceLocations]);


  return (
    <>
      <Grid container item xs={12}>
        <Typography variant="h2">Service Location List</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Typography variant="h4">Tap Location to Edit</Typography>
      </Grid>
      <Grid container item xs={12}>
        {rows.map((location) => {
          return (
            <Grid style={{ marginBottom: '8px' }} item xs={12}>
              <ButtonBase
                onClick={() => {
                  dispatch(
                    setServiceLocation({
                      ...props.serviceLocations.filter(
                        (element: IServiceLocation) => element.id === location.id
                      )[0]
                    })
                  );
                  window.scrollTo(0, 0);
                }}
              >
                <Card className={classes.cardStyle}>
                  <CardContent>
                    <Grid container item xs={12}>
                      <Grid container item xs={6}>
                        <Grid item xs={12}>
                          <Typography>
                            Location:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{ fontWeight: "bold", fontSize: "16px", paddingBottom: "2px" }}>
                            {location.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={6}>
                        <Grid item xs={12}>
                          <Typography>
                            Branch:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography style={{ fontWeight: "bold", fontSize: "16px", paddingBottom: "2px" }}>
                            {location.branch.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mt={1} />
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={12}>
                          <Typography>
                            Address:
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {location.address.fullAddress || location.address.street}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </ButtonBase>
            </Grid>
          )
        })}
      </Grid >
    </>
  );
};

export default MobileServiceLocationCards;
