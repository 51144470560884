import { gql } from "@apollo/client";

export const CANCEL_PAYMENT_LINK = gql`
  mutation cancelPaymentLink($paymentId: String!) {
    cancelPaymentLink(paymentId: $paymentId) {
      id
      status
    }
  }
`;
