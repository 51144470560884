
import {
  DELETE_CUSTOMER,
  RESET_APPROVED_DRIVER,
  RESET_AUTHORISED_SIGNATORY,
  RESET_BUSINESS_CUSTOMER,
  RESET_CUSTOMER,
  SET_APPROVED_DRIVER,
  SET_AUTHORISED_SIGNATORY,
  SET_BUSINESS_APPROVED_DRIVERS,
  SET_BUSINESS_AUTHORISED_SIGNATORY,
  SET_BUSINESS_CUSTOMER,
  SET_BUSINESS_CUSTOMER_BILLING,
  SET_BUSINESS_CUSTOMER_DOCUMENT,
  SET_BUSSINESS_CUSTOMERINFO,
  SKIP_BUSINESS_APPROVED_DRIVERS,
  UPDATE_BUSINESS_APPROVED_DRIVER,
  UPDATE_CUSTOMER,
  VIEW_CUSTOMER
} from "../../actions/customer/types";
import { initialState } from "./const";
import { ICustomerState } from "./types";

export default function (
  state: ICustomerState = initialState,
  action: any
): ICustomerState {
  switch (action.type) {
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customer: { ...state.customer, ...action.payload }
      };
    case VIEW_CUSTOMER:
      return {
        ...state,
        customer: { ...state.customer, ...action.payload }
      };
    case DELETE_CUSTOMER:
      return {
        ...state
      };
    case RESET_CUSTOMER:
      return {
        ...state,
        customer: { ...initialState.customer }
      };
    case SET_BUSINESS_CUSTOMER:
      return {
        ...state,
        businessCustomer: { ...state.businessCustomer, ...action.payload }
      };
    case RESET_BUSINESS_CUSTOMER:
      return {
        ...state,
        businessCustomer: { ...initialState.businessCustomer }
      };
    case SET_BUSSINESS_CUSTOMERINFO:
      return {
        ...state,
        businessCustomer: { ...state.businessCustomer, ...action.payload }
      };
    case SET_BUSINESS_CUSTOMER_BILLING:
      return {
        ...state,
        businessCustomer: {
          ...state.businessCustomer,
          billing: {
            ...state.businessCustomer.billing,
            ...action.payload.billing
          },
          contact: {
            ...state.businessCustomer.contact,
            ...action.payload.contact
          }
        }
      };
    case SET_BUSINESS_AUTHORISED_SIGNATORY:
      return {
        ...state,
        businessCustomer: {
          ...state.businessCustomer,
          authorizedSignatories: [
            ...state.businessCustomer.authorizedSignatories,
            action.payload
          ]
        }
      };
    case SET_BUSINESS_APPROVED_DRIVERS:
      return {
        ...state,
        businessCustomer: {
          ...state.businessCustomer,
          approvedDrivers: [
            ...state.businessCustomer.approvedDrivers,
            action.payload
          ]
        }
      };
    case SKIP_BUSINESS_APPROVED_DRIVERS:
      return {
        ...state,
        businessCustomer: {
          ...state.businessCustomer,
          approvedDrivers: []
        }
      };
    case SET_BUSINESS_CUSTOMER_DOCUMENT:
      state.businessCustomer.documents.unshift(action.payload);
      return {
        ...state,
        businessCustomer: {
          ...state.businessCustomer,
          documents: [...state.businessCustomer.documents]
        }
      };
    case SET_AUTHORISED_SIGNATORY:
      return {
        ...state,
        authorisedSignatory: { ...state.authorisedSignatory, ...action.payload }
      };
    case RESET_AUTHORISED_SIGNATORY:
      return {
        ...state,
        authorisedSignatory: { ...initialState.authorisedSignatory }
      };
    case SET_APPROVED_DRIVER:
      return {
        ...state,
        approvedDriver: { ...state.approvedDriver, ...action.payload }
      };
    case RESET_APPROVED_DRIVER:
      return {
        ...state,
        approvedDriver: { ...initialState.approvedDriver }
      };
    case UPDATE_BUSINESS_APPROVED_DRIVER:
      const updatedApprovedDriver = state.businessCustomer.approvedDrivers.map((driver) => (driver.id === action.payload.id) ?
        { ...driver, ...action.payload } : driver);
      return {
        ...state,
        businessCustomer: { ...state.businessCustomer, approvedDrivers: [...updatedApprovedDriver] }
      };
    default:
      return state;
  }
}
