import {
  CircularProgress,
  Grid,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography
} from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import React from 'react';
import { useSelector } from 'react-redux';
import { IBooking } from '../../../../../reducers/bookings/types';
import { IInvoice } from '../../../../../reducers/invoices/types';
import { IAppState } from '../../../../../store';
import { DATE_TYPE, toCurrency } from '../../../../common/utils';
import { InvoiceType } from '../../../Invoices/utils';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';

interface IPaymentInfoProps {
  booking: IBooking;
  handlerInvoiceClick: (invoiceId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCellBorder: {
      borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
    tableCellPadding: {
      padding: 8
    },
    summaryLeftMargin: {
      marginLeft: 5
    },
    paymentInfoTitle: {
      margin: 0,
      fontSize: 13,
      fontWeight: "bolder"
    },
    paymentInfoAmount: {
      margin: "0px",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: 3
    }
  })
);

export const PaymentInfo: React.FC<IPaymentInfoProps> = (props) => {
  const { booking } = props;
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;

  return (
    <>
      <Hidden xsDown>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography variant="h3">
              Payment Information
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            {props.booking.updateInProgress ? 
              <Grid container>
                <CircularProgress size={14} style={{ color: "white", margin: "0 10px" }} />
                <Typography variant='body1'>Fetching...</Typography>
              </Grid>
              :<TableContainer>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className={classes.tableCellPadding}>
                        <Typography>Invoice number</Typography>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellPadding}>
                        <Typography>Invoice Type</Typography>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellPadding}>
                        <Typography >Creation Date</Typography>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellPadding}>
                        <Typography >Billing Period</Typography>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellPadding}>
                        <Typography >Amount</Typography>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellPadding}>
                        <Typography >Status</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {booking && booking.invoices && booking.invoices.map(
                      (invoice: IInvoice, index: number) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className={classes.tableCellPadding}>
                              <Typography variant="body1">
                                <a
                                  onClick={() => props.handlerInvoiceClick(invoice.id)}
                                  style={{
                                    color: "rgb(24, 117, 210)",
                                    textDecoration: "none",
                                    cursor: "pointer"
                                  }}>{invoice.invoiceRef}</a>
                              </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCellPadding}>
                              {invoice.invoiceType === InvoiceType.SUNDRY ? InvoiceType.SUNDRY : "REGULAR"}
                            </TableCell>
                            <TableCell className={classes.tableCellPadding}>
                              {getLocalizedDateFormat(country, (invoice.confirmedAt) ? invoice.confirmedAt : invoice.dateCreated, DATE_TYPE.CONDENSED)}
                            </TableCell>
                            <TableCell className={classes.tableCellPadding}>{`${getLocalizedDateFormat(country, invoice.startDate, DATE_TYPE.CONDENSED)}-${getLocalizedDateFormat(country, invoice.endDate, DATE_TYPE.CONDENSED)}`}</TableCell>
                            <TableCell className={classes.tableCellPadding}>{toCurrency(invoice.totalPayableAmount, currency, locale)}</TableCell>
                            <TableCell className={classes.tableCellPadding}>{invoice.status}</TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        {!props.booking.updateInProgress && <Grid container xs={12} spacing={1}>
          {booking && booking.invoices && booking.invoices.map(
            (invoice: IInvoice, index: number) => {
              return (
                <Grid item xs={12}>
                  <Grid container
                    key={index}
                    style={{
                      background: "#f5f5f5",
                      borderRadius: 5,
                      padding: 20
                    }}>
                    <Grid container item xs={12}>
                      <Grid item>
                        <Typography variant="h6">
                          Invoice Number:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography variant="body1" onClick={() => props.handlerInvoiceClick(invoice.id)}
                          style={{
                            color: "rgb(24, 117, 210)",
                            textDecoration: "none",
                            cursor: "pointer"
                          }} >
                          {invoice.invoiceRef}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography variant="h6" >
                          Invoice Type:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography variant="body1">
                          {invoice.invoiceType === InvoiceType.SUNDRY ? InvoiceType.SUNDRY : "REGULAR"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography variant="h6">
                          Creation Date:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography variant="body1">
                          {getLocalizedDateFormat(country, (invoice.confirmedAt) ? invoice.confirmedAt : invoice.dateCreated, DATE_TYPE.CONDENSED)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography variant="h6">
                          Billing Period:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography variant="body1">
                          {`${getLocalizedDateFormat(country, invoice.startDate, DATE_TYPE.CONDENSED)}-${getLocalizedDateFormat(country, invoice.endDate, DATE_TYPE.CONDENSED)}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography variant="h6">
                          Amount:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography variant="body1">
                          {toCurrency(invoice.totalPayableAmount, currency, locale)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography variant="h6">
                          Status:
                        </Typography>
                      </Grid>
                      <Grid item className={classes.summaryLeftMargin}>
                        <Typography variant="body1">
                          {invoice.status}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
          )}
        </Grid>}
      </Hidden>
    </>
  )
}

