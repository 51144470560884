import "../index.scss";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { DateTime as d } from "luxon";
import {
  IVehicle,
  IVehicleState,
  IVehicleStateCheck,
  VehicleTypes,
  VehicleFuelType
} from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { markDamages } from "../../../../../common/VehicleDamagePreview/damages";
import {
  DamagesApprovalStatus,
  IFrogDiagram
} from "../../../../../common/VehicleDamagePreview/frogDiagram";
import { IDamage } from "../../../Issues&Damages/NewDamage/AddDamage";
import { DamgeImageView } from "./DamageImageview";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../../../common/utils";

interface IProps {
  vehicle: IVehicle;
  updateFields(payload: any): void;
  updateVehicle(payload: any): void;
}
export enum DamageTypes {
  EXTERIOR = "exterior damage",
  INTERIOR = "interior damage",
  OTHER = "other damage"
}

interface IDamageCoordinates {
  exterior: string[];
  interior: string[];
}

interface IUpdatedBy {
  firstName: string;
  lastName: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: "30px"
    },
    table: {
      // minWidth: 650,
    },
    checkBox: {},
    checkBoxTitle: {
      fontSize: "14px",
      fontWeight: "normal",
      margin: "auto"
    },
    defaultFont: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "normal"
    },
    title: {
      fontWeight: 600
    },
    button: {
      paddingTop: "30px"
    }
  })
);
const fuelStatus = [
  { label: "E", value: "e" },
  { label: "1/8", value: "1/8" },
  { label: "1/4", value: "1/4" },
  { label: "3/8", value: "3/8" },
  { label: "1/2", value: "1/2" },
  { label: "5/8", value: "5/8" },
  { label: "3/4", value: "3/4" },
  { label: "7/8", value: "7/8" },
  { label: "F", value: "f" }
];
const svgHeight = 400;

export const VehicleCheck = (props: IProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { currentBranch, currentOrganisation } = useSelector(
    (state: IAppState) => state.userReducer
  );
  const { country } = currentOrganisation.address;
  const { vehicleState } = props.vehicle;
  const [values, setValues] = useState<IVehicleState>(
    props.vehicle.vehicleState
  );
  const [svg, setSvg] = useState<IFrogDiagram>();
  const [damageCoordinates, setDamageCoordinates] = useState<
    IDamageCoordinates
  >({
    exterior: [],
    interior: []
  });
  const [exteriorDamages, setExteriorDamages] = useState<IDamage[]>([]);
  const [interiorDamages, setInteriorDamages] = useState<IDamage[]>([]);
  const [lastUpdated, setLastUpdated] = useState<string>("N/A");
  const [isFourWheeler, setIsFourWheeler] = useState<boolean>(false);
  useEffect(() => {if(props.vehicle.vehicleType){
    setIsFourWheeler(![VehicleTypes.TWO_WHEELER, VehicleTypes.ATV].includes(props.vehicle.vehicleType))
  }},[props.vehicle.vehicleType])

  useEffect(() => {
    setValues({ ...props.vehicle.vehicleState });
  }, [props.vehicle.vehicleState]);

  useEffect(() => {
    if (
      props.vehicle &&
      props.vehicle.frogDiagram &&
      props.vehicle.activeVehicleDamages
    ) {
      let exteriorDamagesData: any[] = [];
      let interiorDamagesData: any[] = [];
      for (const damage of props.vehicle.activeVehicleDamages) {
        if (damage.approvalStatus !== DamagesApprovalStatus.PENDING) {
          damage.damages.filter((item: IDamage) => {
            if (item.damageType === "exterior damage") {
              exteriorDamagesData.push({
                ...item,
                frogDiagram: damage.frogDiagram,
                referenceNumber: damage.referenceNumber
                  ? damage.referenceNumber
                  : "",
                damageId: damage.id,
                branchId: damage.branchId
              });
            } else if (item.damageType === "interior damage") {
              interiorDamagesData.push({
                ...item,
                frogDiagram: damage.frogDiagram,
                referenceNumber: damage.referenceNumber,
                damageId: damage.id,
                branchId: damage.branchId
              });
            }
          });
        }
      }
      exteriorDamagesData = exteriorDamagesData.map(
        (value: IDamage, index: number) => ({ ...value, id: index + 1 })
      );
      interiorDamagesData = interiorDamagesData.map(
        (value: IDamage, index: number) => ({ ...value, id: index + 1 })
      );
      setExteriorDamages(exteriorDamagesData);
      setInteriorDamages(interiorDamagesData);

      const markData = markDamages(
        [...exteriorDamagesData, ...interiorDamagesData],
        props.vehicle.frogDiagram
      );
      if (markData) {
        setSvg(markData.svg);
        setDamageCoordinates({
          exterior: markData.exterior,
          interior: markData.interior
        });
      }
      handleLatestUpdatedAt();
    }
  }, [props.vehicle]);

  const handleLatestUpdatedAt = () => {
    const { vehicleState = null, activeVehicleDamages = [] } = props.vehicle;

    const vehicleStateLastUpdatedAt = vehicleState?.updatedAt || vehicleState?.createdAt;
    const allDamageReportedDates = activeVehicleDamages.map((d) => d.reportDate);

    const allDates = [vehicleStateLastUpdatedAt, ...allDamageReportedDates].filter(d => d);

    allDates.sort((a: any, b: any) => Date.parse(b) - Date.parse(a));

    if (allDates?.[0]) {
      setLastUpdated(getLocalizedDateFormat(country, d.fromISO(allDates?.[0]).toUTC().toISO(), DATE_TYPE.EXPANDED))
    }
  };


  const handlerDamageClick = (vehicleDamageId: string | undefined) => {
    if (vehicleDamageId) {
      navigate(`/update-damage?damage=${vehicleDamageId}`);
    }
  };
  const returnUserString = (user: IUpdatedBy) => {
    if (user && user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    } else {
      return "N/A";
    }
  }

  const getFuelLabel = (fuelLevel: string) => {
    if (fuelLevel === "e") return "E";
    else if (fuelLevel === "E") return "E";
    else if (fuelLevel === "f") return "F";
    else if (fuelLevel === "F") return "F";
    else return fuelLevel;
  };

  return (
    <Grid container className={classes.root}>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h4">
            Last updated :{" "}
            {lastUpdated}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h4">
            Updated By:{" "}
            {vehicleState.updatedBy
              ? returnUserString(vehicleState.updatedBy)
              : vehicleState.createdBy
                ? returnUserString(vehicleState.createdBy)
                : "N/A"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={Yup.object().shape({
            odometerReading: Yup.number().required(
              "Odometer Reading is required"
            ),
            fuelStatus: Yup.string().required("Fuel Status is required")
          })}
          onSubmit={() => { }}
        >
          {(FormikProps) => (
            <Form>
              <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12} spacing={2}>
                  <Grid container item xs={12}>
                    <Box>
                      <Typography variant="h3">{`MILEAGE`}</Typography>
                    </Box>
                  </Grid>
                  <Grid container item xs={12}>
                    {(props.vehicle.fuelType === VehicleFuelType.PETROL ||
                      props.vehicle.fuelType === VehicleFuelType.DIESEL ||
                      props.vehicle.fuelType ===
                      VehicleFuelType.GAS_BI_FUEL) && (
                        <Grid item xs={3}>
                          <Typography variant="h4">
                            Fuel Status:{" "}
                            {values.fuelStatus ? getFuelLabel(values.fuelStatus) : "NA"}
                          </Typography>
                        </Grid>
                      )}
                    {
                      props.vehicle.fuelType === VehicleFuelType.GASOLINE && (
                        <Grid item xs={3}>
                          <Typography variant="h4">
                            Fuel Status:{" "}
                            {values.fuelStatus ? values.fuelStatus : "NA"}
                          </Typography>
                        </Grid>
                      )
                    }
                    {(props.vehicle.fuelType ===
                      VehicleFuelType.HYBRID_ELECTRIC ||
                      props.vehicle.fuelType ===
                      VehicleFuelType.HYBRID_ELECTRIC_CLEAN ||
                      props.vehicle.fuelType ===
                      VehicleFuelType.ELECTRIC_DIESEL) && (
                        <>
                          <Grid item xs={3}>
                            <Typography variant="h4">
                              Fuel Status:{" "}
                              {values.fuelStatus ? values.fuelStatus : "NA"}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="h4">
                              Battery Status:{" "}
                              {values.batteryStatus
                                ? `${values.batteryStatus}%`
                                : "NA"}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    {(props.vehicle.fuelType === VehicleFuelType.ELECTRICITY ||
                      props.vehicle.fuelType === VehicleFuelType.ELECTRIC) && (
                        <Grid item xs={3}>
                          <Typography variant="h4">
                            Battery Status:{" "}
                            {values.batteryStatus
                              ? `${values.batteryStatus}%`
                              : "NA"}
                          </Typography>
                        </Grid>
                      )}
                    <Grid item xs={3}>
                      <Typography variant="h4">
                        Odometer Reading:{" "}
                        {values.odometerReading ? values.odometerReading : "NA"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Paper
                      style={{ padding: "20px", width: "100%", boxShadow: "none", border: "1px solid #ddd" }}
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        style={{ textAlign: "center" }}
                      >
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="subtitle1"
                            style={{ margin: "10px 0px" }}
                          >
                            {props.vehicle.vehicleType === VehicleTypes.ATV.toString() ? "Vehicle Diagram" : "Exterior Damages"}
                          </Typography>
                          {svg?.exterior.svg && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                height: `${svgHeight}px`,
                                marginBottom: "20px",
                                paddingRight: 30
                              }}
                              viewBox={`0 0 ${svg?.exterior.width} ${svg?.exterior.height}`}
                            >
                              {svg?.exterior.svg}
                              {damageCoordinates.exterior.length > 0 &&
                                damageCoordinates.exterior}
                            </svg>
                          )}
                        </Grid>
                        {isFourWheeler && <Grid item xs={12} sm={6}>
                          <Typography
                            variant="subtitle1"
                            style={{ margin: "10px 0px" }}
                          >
                            Interior Damages
                          </Typography>
                          {svg?.interior.svg && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                height: `${svgHeight}px`,
                                marginBottom: "20px"
                              }}
                              viewBox={`0 0 ${svg?.interior.width} ${svg?.interior.height}`}
                            >
                              {svg?.interior.svg}
                              {damageCoordinates.interior.length > 0 &&
                                damageCoordinates.interior}
                            </svg>
                          )}
                        </Grid>}
                      </Grid>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                          {exteriorDamages && exteriorDamages.length > 0 && (
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Reference No</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Images</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {exteriorDamages.map(
                                    (damageTableItem: any, index: number) => (
                                      <React.Fragment key={index}>
                                        <TableRow key={damageTableItem.id}>
                                          <TableCell component="th" scope="row">
                                            {damageTableItem.id}
                                          </TableCell>
                                          <TableCell>
                                            {damageTableItem.referenceNumber ? (
                                              <a
                                                onClick={
                                                  damageTableItem.branchId ===
                                                    currentBranch.id
                                                    ? () =>
                                                      handlerDamageClick(
                                                        damageTableItem.damageId
                                                      )
                                                    : undefined
                                                }
                                                title={
                                                  damageTableItem.branchId !==
                                                    currentBranch.id
                                                    ? "external branch damage is not clickable"
                                                    : ""
                                                }
                                                style={{
                                                  color: "rgb(24, 117, 210)",
                                                  textDecoration: "none",
                                                  cursor:
                                                    damageTableItem.branchId ===
                                                      currentBranch.id
                                                      ? "pointer"
                                                      : "not-allowed"
                                                }}
                                              >
                                                {" "}
                                                {
                                                  damageTableItem.referenceNumber
                                                }{" "}
                                              </a>
                                            ) : (
                                              "N/A"
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {damageTableItem.damageKind ||
                                              "N/A"}
                                          </TableCell>
                                          <TableCell>
                                            {damageTableItem.images &&
                                              damageTableItem.images.length >
                                              0 ? (
                                              <Grid item xs={12}>
                                                <DamgeImageView
                                                  damageImages={
                                                    damageTableItem.images
                                                  }
                                                />
                                              </Grid>
                                            ) : (
                                              "NA"
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {isFourWheeler && interiorDamages && interiorDamages.length > 0 && (
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Reference Number</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Images</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {interiorDamages.map(
                                    (damageTableItem: any) => (
                                      <React.Fragment>
                                        <TableRow key={damageTableItem.id}>
                                          <TableCell component="th" scope="row">
                                            {damageTableItem.id}
                                          </TableCell>
                                          <TableCell>
                                            {damageTableItem.referenceNumber ? (
                                              <a
                                                onClick={
                                                  damageTableItem.branchId ===
                                                    currentBranch.id
                                                    ? () =>
                                                      handlerDamageClick(
                                                        damageTableItem.damageId
                                                      )
                                                    : undefined
                                                }
                                                title={
                                                  damageTableItem.branchId !==
                                                    currentBranch.id
                                                    ? "external branch damage is not clickable"
                                                    : ""
                                                }
                                                style={{
                                                  color: "rgb(24, 117, 210)",
                                                  textDecoration: "none",
                                                  cursor:
                                                    damageTableItem.branchId ===
                                                      currentBranch.id
                                                      ? "pointer"
                                                      : "not-allowed"
                                                }}
                                              >
                                                {" "}
                                                {
                                                  damageTableItem.referenceNumber
                                                }{" "}
                                              </a>
                                            ) : (
                                              "N/A"
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {damageTableItem.damageKind ||
                                              "N/A"}
                                          </TableCell>
                                          <TableCell>
                                            {damageTableItem.images &&
                                              damageTableItem.images.length >
                                              0 ? (
                                              <Grid item xs={12}>
                                                <DamgeImageView
                                                  damageImages={
                                                    damageTableItem.images
                                                  }
                                                />
                                              </Grid>
                                            ) : (
                                              "NA"
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                    <Grid container item sm={12}>
                      <Box mt={1}></Box>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        General checks
                      </Typography>
                      {values.generalChecks &&
                        values.generalChecks.length > 0 ? (
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Images</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.generalChecks.map(
                                (accessory: IVehicleStateCheck) => (
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      {accessory.name}
                                    </TableCell>
                                    <TableCell>
                                      {accessory.status
                                        ? accessory.status
                                        : "NA"}
                                    </TableCell>
                                    <TableCell>
                                      {accessory.images &&
                                        accessory.images.length > 0 ? (
                                        <Grid item xs={12}>
                                          <DamgeImageView
                                            damageImages={accessory.images}
                                            title={
                                              "Vehicle General Check Images"
                                            }
                                          />
                                        </Grid>
                                      ) : (
                                        "NA"
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: "normal",
                            fontSize: 15,
                            paddingTop: 6
                          }}
                        >
                          No data available
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        Accessories checks
                      </Typography>
                      {values.defaultAccesories &&
                        values.defaultAccesories.length > 0 ? (
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Images</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.defaultAccesories.map(
                                (accessory: IVehicleStateCheck) => (
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      {accessory.name}
                                    </TableCell>
                                    <TableCell>
                                      {accessory.status
                                        ? accessory.status
                                        : "NA"}
                                    </TableCell>
                                    <TableCell>
                                      {accessory.images &&
                                        accessory.images.length > 0 ? (
                                        <Grid item xs={12}>
                                          <DamgeImageView
                                            damageImages={accessory.images}
                                            title={"Vehicle Accessories Images"}
                                          />
                                        </Grid>
                                      ) : (
                                        "NA"
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: "normal",
                            fontSize: 15,
                            paddingTop: 6
                          }}
                        >
                          No data available
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
