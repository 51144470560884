import { gql } from "@apollo/client";

export const GET_EMAIL_TEMPLATE_BY_TYPE = gql`
  query getEmailTemplateByType($templateType: String!) {
    getEmailTemplateByType(templateType : $templateType) {
      templateType
      bodyHtmlString
      designJsonString
      subject
      isDefault
      disableAutoSend
      variables
        {
        name
        value
        key
      }
    }
  }`