import { gql } from "@apollo/client";

export const UPDATE_JOB_SHEET_STATUS = gql`
  mutation updateServiceJobSheetStatus($vehicleServiceId: String!,$status: String!) {
    updateServiceJobSheetStatus(vehicleServiceId: $vehicleServiceId, status: $status){
        success
        status
        message
    }
  }
`;

export const SEND_JOB_SHEET_EMAIL = gql`
  mutation sendJobSheetEmail($vehicleServiceId: String!) {
    sendJobSheetEmail(vehicleServiceId: $vehicleServiceId){
        success
        message
        key
    }
  }
`;

export const MARK_VEHICLE_IN_SERVICE = gql`
  mutation updateVehicleServiceStatus($vehicleServiceId: String!,$status: String!) {
    updateVehicleServiceStatus(vehicleServiceId: $vehicleServiceId, status: $status){
        success
        message
    }
  }
`;