import {
  Theme,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IInvoice } from "../../../../reducers/invoices/types";
import { IAppState } from "../../../../store";
import { getTaxesDescription, toCurrency } from "../../../common/utils";
import { IInvoiceItem } from "../UpdateInvoice/InvoiceComponents/types";

interface IProps {
  invoice: IInvoice;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      border: "solid 1px rgba(224, 224, 224, 1)",
      marginTop: "1rem"
    }
  })
);

const columns = ["#", "Particulars", "Unit", "Price/Rate", "VAT/TAX", "Amount"];

const SundryInvoiceDetails: React.FC<IProps> = (props) => {
  const styles = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const [values, setValues] = useState<IInvoice>();

  useEffect(() => {
    if (props.invoice) {
      setValues(props.invoice);
    }
  }, [props.invoice]);

  const getTaxTitles = (item: IInvoiceItem, values: IInvoice) => {
    if (country === "United States")
      return getTaxesDescription(values.tax, currency, locale);
    else
      return [`Vat ${item.taxRate && item.taxRate > 100 ? item.taxRate / 100 : item.taxRate}%`];
  }

  if (!values) {
    return <CircularProgress />;
  }

  return (
    <Grid container item xs={12}>
      <TableContainer className={styles.tableContainer}>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              {
                columns.map((column: string) => {
                  if (country === "United States" && column === "VAT/TAX") {
                    column = "Taxes & Fees";
                  }
                  return (
                    <TableCell>
                      <Typography variant={"h4"}>{column}</Typography>
                    </TableCell>
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {values?.invoiceItems?.map((item: IInvoiceItem, idx: number) => {
              const taxAmount = item.taxAmount ? item.taxAmount : 0;
              const rentalTaxDetails = getTaxTitles(item, values);
              return (
                <TableRow>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>
                    {item.description}
                    <br />
                    {item && item.taxDescription && item.taxDescription.length > 0 ? item.taxDescription.map(title => {
                      return (
                        <>
                          <span>({title})</span><br></br>
                        </>
                      )
                    }) : (
                      <>
                        {rentalTaxDetails && rentalTaxDetails.length > 0 && rentalTaxDetails.map(title => {
                          return (
                            <>
                              <span>({title})</span><br></br>
                            </>
                          )
                        })}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.quantity}
                  </TableCell>
                  <TableCell>{toCurrency(item.unitPrice, currency, locale)}</TableCell>
                  <TableCell>
                    {toCurrency(taxAmount, currency, locale)}
                  </TableCell>
                  <TableCell>{toCurrency((item.unitPrice * item.quantity) + (item && item.taxAmount ? item.taxAmount : 0), currency, locale)}</TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell colSpan={4} style={{ borderBottom: "none" }}></TableCell>
              <TableCell style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}>
                <Typography variant="h4">{"Sub Total"}</Typography>
              </TableCell>
              <TableCell>
                {toCurrency(
                  values.totalPayableAmount,
                  currency,
                  locale
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} style={{ borderBottom: "none" }}></TableCell>
              <TableCell style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}>
                <Typography variant="h4">{"Paid"}</Typography>
              </TableCell>
              <TableCell>
                {toCurrency(
                  values.confirmedPaidAmount,
                  currency,
                  locale
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3} style={{ borderBottom: "none" }}>
                {values.booking.taxExempted && <Typography variant="h4">
                  *This is a VAT/TAX exempted booking.
                </Typography>}
              </TableCell>
              <TableCell style={{ borderBottom: "none" }}></TableCell>
              <TableCell style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}>
                <Typography variant="h4">{"Balance Due"}</Typography>
              </TableCell>
              <TableCell>
                {toCurrency(
                  values.dueAmount,
                  currency,
                  locale
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default SundryInvoiceDetails;