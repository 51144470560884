import { gql } from "@apollo/client";

export const GET_CREDIT_NOTE_DATA = gql`
  query creditNoteData($bookingId: String!) {
    creditNoteData(bookingId: $bookingId) {
      invoiceRef
      invoiceId
      paidAmount
      totalAmount
      creditNoteType
      creditNoteItems {
        type
        quantity
        description
        originalPrice
        totalAmount
        unitPrice
        duration
        taxRate
        taxAmount
        taxDescription
        subTotal
        originalTaxAmount
      }
    }
  }
`;