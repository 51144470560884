export const initialBasePrices = [
  {
    rateTypeName: "hourly",
    rate: 0,
    rateTypeDuration: 60,
    longTerm: false,
    mileageLimit: 0,
    pricePerExtraMile: 0,
    unlimitedMileage:false
  },
  {
    rateTypeName: "daily",
    rate: 0,
    rateTypeDuration: 1440,
    longTerm: false,
    mileageLimit: 0,
    pricePerExtraMile: 0,
    unlimitedMileage:false
  },
  {
    rateTypeName: "weekly",
    rate: 0,
    rateTypeDuration: 10080,
    longTerm: false,
    mileageLimit: 0,
    pricePerExtraMile: 0,
    unlimitedMileage:false
  },
  {
    rateTypeName: "monthly",
    rate: 0,
    rateTypeDuration: 43200,
    longTerm: false,
    mileageLimit: 0,
    pricePerExtraMile: 0,
    unlimitedMileage:false
  }
];
