import { Fab, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface IDialogData {
  vehicleId?: string;
}
interface IProps {
  dialogData: IDialogData,
  isSharedFleetEvent?: boolean
}
export const DeactivatedVehicleError: React.FC<IProps> = (props) => {

  const { dialogData, isSharedFleetEvent } = props

  const navigate = useNavigate()
  return (
    <Grid container item xs={12} spacing={2} style={{ marginBottom: 20 }}>
      <Grid item xs={12}>
        <Typography variant={"h3"}>
          {
            !isSharedFleetEvent ? "Please visit the vehicle details page for details" : "This vehicle has been deactivated by source branch. Please communicate with the source branch to make any action related to booking etc."
          }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {
          !isSharedFleetEvent && (
            <Fab
              size="medium"
              variant="extended"
              onClick={() => {
                if (dialogData.vehicleId) {
                  navigate(`/update-vehicle?vehicle=${dialogData.vehicleId}`);
                }
              }}
            >
              VIEW DETAILS
            </Fab>
          )
        }
      </Grid>
    </Grid>
  )
}
