import { gql } from "@apollo/client";

export const GET_EXPENSES = gql`
  query expenses {
    expenses {
      id
      vehicle {
        id
        licencePlate
      }
      expenseDate
      fuelType
      vendor
      odometerReading
      fuelQuantity
      reference
      referenceNumber
      amount
      driver
      document {
        documentName
        url
      }
    }
  }
`;

export const GET_FUEL_EXPENSE_TABLE = gql`
  query fuelExpensesTable($limit: Int!, $offset: Int!) {
    fuelExpensesTable(limit: $limit, offset: $offset) {
      id
      assetType
      vehicle {
        id
        licencePlate
      }
      addon{
        id
        name
        displayName
      }
      expenseType
      expenseDate
      fuelType
      vendor
      odometerReading
      fuelQuantity
      reference
      referenceNumber
      amount
      driver
      document {
        documentName
        url
      }
      branch{
        id
        name
      }
    }
  }
`;

export const SEARCH_EXPENSE = gql`
  query searchExpenses($q: String!) {
    searchExpenses(q: $q) {
      id
      vehicle {
        id
        licencePlate
      }
      expenseDate
      fuelType
      vendor
      odometerReading
      fuelQuantity
      reference
      referenceNumber
      amount
      driver
      document {
        documentName
        url
      }
    }
  }
`;

export const GET_EXPENSES_COUNT = gql`
  {
    expensesCount
  }
`;
