import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  IconButton,
  Step,
  StepButton,
  Stepper,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ApolloError } from "@apollo/client";
import { DateTime as d } from "luxon";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { CREATE_BOOKING } from "../../../../graphql/bookings/createBookingMutation";
import { GET_AVAILABLE_VEHICLE_GROUPS } from "../../../../graphql/bookings/getAvailableVehicleGroups";
import { GET_BOOKING } from "../../../../graphql/bookings/getBookingQuery";
import { UPDATE_BOOKING } from "../../../../graphql/bookings/updateBookingMutation";
import {
  BookingStatus,
  BOOKING_SOURCE,
  IAddonRequirementInput,
  IBooking,
  IBookingAddonRequirement,
  IBookingCreateInput,
  IBookingVehicleGroup,
  IInsurancePolicyBooking,
  BOOKING_CATEGORY,
  bookingCategoryLabels
} from "../../../../reducers/bookings/types";
import {
  IBookingVehiclePriceGroup,
  IVehiclePriceGroup
} from "../../../../reducers/vehiclePriceGroups/types";
import { IAppState } from "../../../../store";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import StepperHeader from "../../../common/StepperHeader/StepperHeader";
import { formatGraphQLErrorMessage } from "../../../common/utils";
import { BookingFlowStage, SubscriptionFlowStage } from "../utils";
import BaseDetails from "./BaseDetails/BaseDetails";
import { PaymentView } from "./PaymentView/PaymentView";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import QuoteView from "./Quote/QuoteView";
import { AddonsSelection } from "./SelectAddOns/AddonsSelection";
import { IPriceRule } from "../../../../reducers/priceRule/types";
import { GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS } from "../../../../graphql/bookings/getExternalAvailableVehicleGroups";
import { IBookingCategory, ILocationSurchargeInput } from "../../../../reducers/organisation/types";
import { getLocalizedBookingSyntex } from "../../../../utils/localized.syntex";
import { IVehicle } from "../../../../reducers/fleet/types";
import { IDriverRow } from "../../../../reducers/user/types";
import _, { set } from "lodash";
import { IOneWayRentalFee } from "../../VehicleMovement/types";
import { GET_AVAILABLE_SUBSCRIPTION_VEHICLES } from "../../../../graphql/bookings/getVailableSubscriptionVehiclesQuery";
import theme from "../../../common/Theme";
import { TabPanel } from "../../../common/TabPannel/TabPannel";
import { createStyles, makeStyles } from "@mui/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as RentalIcon } from "../../../../assets/icons/rental-icon.svg"
import { ReactComponent as CourtesyCarIcon } from "../../../../assets/icons/courtesy-car-icon.svg"
import { ReactComponent as TesDriveIcon } from "../../../../assets/icons/test-drive-icon.svg"
import { ReactComponent as SubscriptionIcon } from "../../../../assets/icons/subscription-icon.svg"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      color: theme.palette.primary.light,
      "& .MuiStepLabel-label": {
        color: theme.palette.primary.light
      }
    },

  })
);

export const initialValues: IBookingCreateInput = {
  customerType: "individual",
  pickupServiceLocation: "",
  dropoffServiceLocation: "",
  pickupDateTime: "",
  dropoffDateTime: "",
  flightNumber: "",
  longTermBooking: false,
  rateTypeName: "daily",
  billingCycleDuration: 1440,
  rateTypeDuration: 1440,
  vehicleGroups: [],
  insuranceRate: 0,
  excess: 0,
  addonRequirements: [],
  approvedDrivers: [],
  pcoNumber: false,
  locationSurcharges: [],
  isRecurringBilling: false,
  billingFrequency: 0,
  zeroCostBooking: false,
  tba: false,
  isSubscription: false,
  bookingCategory: ""
};

const initialInsurance: IInsurancePolicyBooking = {
  id: "",
  name: "",
  description: ""
}

const stepsCompletedMapInitial = [false, false, false, false, false];
const subscriptionStepsCompletedMapInitial = [false, false, false, false, false];

export const NewBooking: React.FC = () => {

  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { bookingCategories } = userState.currentOrganisation
  const { country } = userState.currentOrganisation.address;
  const [bookingData, setBookingData] = useState<IBookingCreateInput>(
    initialValues
  );
  const [selectedInsurancePolicy, setSelectedInsurancePolicy] = useState<IInsurancePolicyBooking>(initialInsurance)
  const [selectedInsuranceRate, setSelectedInsuranceRate] = useState<number>(0)
  const [vehicleGroups, setVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [ownVehicleGroups, setOwnVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [externalVehicleGroups, setExternalVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [locationSurchargeRules, setLocationSurchargeRules] = useState<ILocationSurchargeInput[]>([]);
  const booking = useSelector((state: IAppState) => state.bookingReducer);
  const [bookingCreationFailed, setBookingCreationFailed] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [bookingStatus, setBookingStatus] = useState<string>("");
  const [valetAddonType, setValetAddonType] = useState<string>("");
  const [newAddedDrivers, setNewAddedDrivers] = useState<IDriverRow[]>([]);
  const [oneWayRentalFee, setOneWayRentalFee] = useState<IOneWayRentalFee>();
  const [subscriptionVehicles, setSubscriptionVehicles] = useState<IVehicle[]>()
  const [bookingTypeTabIndex, setBookingTypeTabIndex] = useState<number>(0)
  const [bookingTypes, setBookingTypes] = useState<IBookingCategory[]>([])
  const [isBackButtonEnabled, setIsBackButtonEnabled] = useState<boolean>(false)
  const [rentalStepsCompletedMap, setRentalStepsCompletedMap] = useState<boolean[]>(stepsCompletedMapInitial);
  const [isSingleBookingCategory, setIsSingleBookingCategory] = useState<boolean>(false)

  const [
    loadBooking,
    { loading: bookingLoading, data: bookingInfoData }
  ] = useLazyQuery(GET_BOOKING, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data.booking) {
        navigate("/bookings");
      }
      if (data.booking && data.booking.status) {
        setBookingStatus(data.booking.status)
      }
      if (data.booking && data.booking.insurancePolicy) {
        setSelectedInsurancePolicy(data.booking.insurancePolicy)
      }
      if (data.booking && data.booking.insuranceRate) {
        setSelectedInsuranceRate(data.booking.insuranceRate)
      }
      if (data.booking && data.booking.approvedDrivers.length) {
        setNewAddedDrivers(data.booking.approvedDrivers)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [createBookingMutation, { data: bookingCreateData, loading: createBookingLoading }] = useMutation(
    CREATE_BOOKING,
    {
      onCompleted: (data) => {
        if ([BOOKING_CATEGORY.COURTESY_CAR.toString(), BOOKING_CATEGORY.TEST_DRIVE.toString()].includes(bookingData.bookingCategory)) {
          navigate(`/view-booking?booking=${data.createBooking.id}`);
        } else {
          const tempSteps = rentalStepsCompletedMap.map((val, index) => index <= BookingFlowStage.QUOTE_VIEW ? true : val)
          setRentalStepsCompletedMap(tempSteps)
          setCurrentStep(BookingFlowStage.QUOTE_VIEW);
          if (data.createBooking.status === BookingStatus.QUOTE) {
            snackbar({
              message: "Quote created",
              variant: SnackBarVariant.SUCCESS
            });
          }
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        setBookingCreationFailed(true);
      }
    }
  );

  const [
    updateBookingMutation,
    { loading: updateBookingLoading }
  ] = useMutation(UPDATE_BOOKING, {
    onCompleted: ({ updateBooking }) => {
      if (updateBooking.status === BookingStatus.CONFIRMED) {
        navigate(`/view-booking?booking=${updateBooking.id}`);
      } else {
        const tempSteps = rentalStepsCompletedMap.map((val, index) => index <= BookingFlowStage.QUOTE_VIEW ? true : val)
        setRentalStepsCompletedMap(tempSteps)
        setCurrentStep(BookingFlowStage.QUOTE_VIEW);
      }
      snackbar({
        message: "Booking updated",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
      navigate("/bookings");
    }
  });

  const [
    loadVehicleGroups,
    { loading: vehicleGroupsLoading, data: availableVehicleGroupsData }
  ] = useLazyQuery(GET_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "no-cache"
  });

  const [
    loadExternalVehicleGroups,
    { loading: externalVehicleGroupsLoading, data: externalAvailableVehicleGroupsData }
  ] = useLazyQuery(GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "no-cache"
  });

  const [
    loadSubscriptionVehicles,
    { loading: subscriptionVehiclesLoading, data: availableSubscriptionVehiclesData }
  ] = useLazyQuery(GET_AVAILABLE_SUBSCRIPTION_VEHICLES, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (bookingCategories) {
      const sortedTypes = bookingCategories
        .filter((type) => type.isActive)
        .sort((a, b) => Number(b.default) - Number(a.default));

      if (sortedTypes.length > 0) {
        const isActiveCount = sortedTypes.filter(item => item.isActive).length;
        if (isActiveCount > 1) {
          setBookingTypes(sortedTypes)
          setBookingData({
            ...bookingData,
            bookingCategory: sortedTypes[0].type
          });
        } else {
          setIsBackButtonEnabled(false)
          setIsSingleBookingCategory(true)
          setBookingData({
            ...bookingData,
            bookingCategory: sortedTypes[0].type
          });
        }
      }
    }
  }, bookingCategories)

  useEffect(() => {
    if (booking.createBookingInput) {
      setBookingData(booking.createBookingInput);
    }
  }, [booking.createBookingInput]);

  useEffect(() => {
    if (bookingCreateData && bookingCreateData.createBooking) {
      formatAndSetBookingData(bookingCreateData.createBooking);
    }
  }, [bookingCreateData]);

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const bookingId = params.get("booking");
      const step = params.get("step");
      if (bookingId && step) {
        setIsUpdate(true);
        setIsBackButtonEnabled(false)
        const tempSteps = rentalStepsCompletedMap.map((val, index) => index <= parseInt(step) ? true : val)
        setRentalStepsCompletedMap(tempSteps)
        const parsedStep = parseInt(step);
        setCurrentStep(parsedStep);
        loadBooking({
          variables: {
            id: bookingId
          }
        });
      }
      if (step && booking.createBookingInput) {
        return setCurrentStep(parseInt(step));
      }
    }
  }, [location]);

  useEffect(() => {
    if (bookingInfoData && bookingInfoData.booking) {
      const data = { ...bookingInfoData.booking }
      if (booking && booking.booking.pickupDateTime) {
        data.pickupDateTime = booking.booking.pickupDateTime
        data.dropoffDateTime = booking.booking.dropoffDateTime
      }
      formatAndSetBookingData(data);
      if (!data.isSubscription) {
        const businessCustomerId = data.businessCustomer
          ? data.businessCustomer.id
            ? data.businessCustomer.id
            : data.businessCustomer
          : "";
        if (currentStep != 3) {
          loadVehicleGroups({
            variables: {
              args: {
                endDate: d.fromISO(data.dropoffDateTime).toUTC().toISO(),
                startDate: d.fromISO(data.pickupDateTime).toUTC().toISO(),
                rateTypeDuration: data.rateTypeDuration,
                longTerm: data.longTermBooking,
                businessCustomer: businessCustomerId,
                bookingId: data.id,
                serviceLocation: data.pickupServiceLocation.id,
                dropoffServiceLocation: data.dropoffServiceLocation.id
              }
            }
          });
          loadExternalVehicleGroups({
            variables: {
              args: {
                endDate: d.fromISO(data.dropoffDateTime).toUTC().toISO(),
                startDate: d.fromISO(data.pickupDateTime).toUTC().toISO(),
                rateTypeDuration: data.rateTypeDuration,
                longTerm: data.longTermBooking,
                businessCustomer: businessCustomerId,
                bookingId: data.id,
                serviceLocation: data.pickupServiceLocation.id
              }
            }
          });
        }
      } else {
        loadSubscriptionVehicles()
      }
    }
  }, [bookingInfoData]);

  useEffect(() => {
    if (availableVehicleGroupsData && externalAvailableVehicleGroupsData && !vehicleGroupsLoading && !externalVehicleGroupsLoading) {
      let arr: IVehiclePriceGroup[] = [];
      if (availableVehicleGroupsData.availableVehicleGroups?.vehicleGroups?.length) {
        const vgArr = availableVehicleGroupsData.availableVehicleGroups.vehicleGroups
        for (let i = 0; i < vgArr.length; i++) {
          const vg = vgArr[i];
          const idx = arr.findIndex((item) => item.id === vg.id);
          if (idx > -1) {
            vg.vehicles.forEach((vehicle: IVehicle) => {
              if (!arr[idx].vehicles.some(v => v.id === vehicle.id)) {
                arr[idx].vehicles.push(vehicle)
              }
            })
          } else {
            arr.push(vg)
          }
        }
      }
      if (externalAvailableVehicleGroupsData.externalAvailableVehicleGroups?.vehicleGroups?.length) {
        const eVgArr = externalAvailableVehicleGroupsData.externalAvailableVehicleGroups.vehicleGroups
        for (let i = 0; i < eVgArr.length; i++) {
          const eVg = eVgArr[i];
          const idx = arr.findIndex((item) => item.id === eVg.id);
          if (idx > -1) {
            eVg.vehicles.forEach((vehicle: IVehicle) => {
              if (!arr[idx].vehicles.some(v => v.id === vehicle.id)) {
                arr[idx].vehicles.push({
                  ...vehicle,
                  shared: true
                })
              }
            })
          } else {
            eVg.vehicles = eVg.vehicles.map((ele: IVehicle) => {
              return {
                ...ele,
                shared: true
              }
            })
            arr.push(eVg)
          }
        }
      }
      if (userState.currentOrganisation.crossLocationBookingEnabled) {
        const vehiclesDistanceArr: any[] = _.unionBy(availableVehicleGroupsData.availableVehicleGroups.vehiclesDistance, externalAvailableVehicleGroupsData.externalAvailableVehicleGroups.vehiclesDistance, 'vehicleId');
        arr = arr.map((vg) => {
          for (let index = 0; index < vg.vehicles.length; index++) {
            const vehicle = vg.vehicles[index];
            for (let j = 0; j < vehiclesDistanceArr.length; j++) {
              const vd = vehiclesDistanceArr[j];
              if (vehicle.id === vd.vehicleId) {
                vg.vehicles[index] = {
                  ...vg.vehicles[index],
                  awayDistance: vd.distance
                }
              }
            }
          }
          vg.vehicles = _.sortBy(vg.vehicles, (v) => v.awayDistance);
          return vg;
        })
      }
      setOneWayRentalFee(availableVehicleGroupsData.availableVehicleGroups.oneWayRentalFee);
      setLocationSurchargeRules(availableVehicleGroupsData.availableVehicleGroups.locationSurcharges);
      setVehicleGroups(arr)
    }
  }, [availableVehicleGroupsData, externalAvailableVehicleGroupsData, vehicleGroupsLoading, externalVehicleGroupsLoading])

  useEffect(() => {
    if (availableSubscriptionVehiclesData && availableSubscriptionVehiclesData.getSubscriptionCatalogue) {
      setSubscriptionVehicles(availableSubscriptionVehiclesData.getSubscriptionCatalogue)
    }
  }, [availableSubscriptionVehiclesData])

  const formatAndSetBookingData = (booking: IBooking) => {
    const vehicleGroupsArr: IBookingVehicleGroup[] = booking.vehicleGroups.map(
      (vg: IBookingVehicleGroup) => {
        let item = { ...vg }
        delete item.activeVehicleSchedules;
        let dataItem = {
          ...item,
          unlimitedMileage: vg.unlimitedMileage || false,
          vehicleGroup: (vg.vehicleGroup as IBookingVehiclePriceGroup).id
        }
        return dataItem
      }
    );
    const addonRequirements: IAddonRequirementInput[] = booking.addonRequirements.map(
      (addonR: IBookingAddonRequirement) => {
        return {
          addon: addonR.addon.id,
          quantity: addonR.quantity,
          name: addonR.name,
          hasFixedRate: addonR.hasFixedRate,
          rate: addonR.rate,
          displayName: addonR.name == 'VALET' ? valetAddonType : addonR.displayName,
          tax: {
            title: addonR.tax && addonR.tax.title ? addonR.tax.title : "",
            value: addonR.tax && addonR.tax.value ? addonR.tax.value : 0
          }
        };
      }
    );
    const bookingObj: IBookingCreateInput = {
      id: booking.id,
      pickupServiceLocation: booking.pickupServiceLocation?.id,
      dropoffServiceLocation: booking.dropoffServiceLocation?.id,
      pickupOtherLocation: booking.pickupOtherLocation,
      dropoffOtherLocation: booking.dropoffOtherLocation,
      pickupDateTime: booking.pickupDateTime,
      dropoffDateTime: booking.dropoffDateTime,
      longTermBooking: booking.longTermBooking,
      flightNumber: booking.flightNumber,
      vehicleGroups: vehicleGroupsArr,
      insuranceRate: booking.insuranceRate,
      excess: booking.excess,
      insurancePolicy: booking.insurancePolicy?.id,
      addonRequirements,
      customer: booking.customer?.id,
      customerType: booking.customerType,
      approvedDrivers: booking.approvedDrivers,
      rateTypeName: booking.rateTypeName,
      rateTypeDuration: booking.rateTypeDuration,
      billingCycleName: booking.billingCycleName,
      billingCycleDuration: booking.billingCycleDuration,
      businessCustomer: booking.businessCustomer?.id,
      authorizedSignatory: booking.authorizedSignatory,
      pcoNumber: booking.pcoNumber,
      preBookingQuestions: booking.preBookingQuestions,
      deliveryQuestions: booking.deliveryQuestions,
      source: booking.source ? booking.source : BOOKING_SOURCE.B2B,
      locationSurcharges: booking.locationSurcharges,
      smartCarVehicle: booking.smartCarVehicle,
      isCarShare: booking.isCarShare || false,
      status: booking.status,
      poNumber: booking.poNumber,
      isRecurringBilling: booking.isRecurringBilling,
      billingFrequency: booking.billingFrequency,
      zeroCostBooking: booking.zeroCostBooking || false,
      isSubscription: booking.isSubscription || false,
      activeSubscriptionExpanded: booking.activeSubscription,
      isCoi: booking.isCoi || false,
      costCenter: booking.costCenter,
      projectId: booking.projectId,
      bookingCategory: booking.bookingCategory
    };
    if (booking.isCoi && booking.coiDetails) {
      bookingObj.coiDetails = booking.coiDetails
    }
    if (booking.activeSubscription) {
      bookingObj.activeSubscription = {
        startDate: booking.activeSubscription.startDate,
        endDate: booking.activeSubscription.endDate,
        subscription: booking.activeSubscription.subscription.id,
        vehicle: booking.activeSubscription.vehicle.id || "",
        duration: booking.activeSubscription.duration,
        subscriptionAmount: booking.activeSubscription.subscriptionAmount,
        enrollmentAmount: booking.activeSubscription.enrollmentAmount,
        unlimitedMileage: booking.activeSubscription.unlimitedMileage ? true : false,
        mileageLimit: booking.activeSubscription.mileageLimit || 0,
        pricePerExtraMile: booking.activeSubscription.pricePerExtraMile || 0,
        swapsAllowed: booking.activeSubscription.swapsAllowed || 0
      }
    }
    setBookingData(bookingObj);
  };
  const updateParentState = (newValue: string) => {
    setValetAddonType(newValue);
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const nextStep = (data: IBookingCreateInput, updateOnly?: boolean) => {
    if (data) {
      setBookingData(data);
    }
    if (currentStep > 0 && !isSingleBookingCategory && !isUpdate) {
      setIsBackButtonEnabled(true)
    }
    const tempStepsCompMap = [...rentalStepsCompletedMap];
    if (!updateOnly) {
      if (currentStep < Object.keys(stepMapper).length - 1) {
        if (currentStep === BookingFlowStage.PERSONAL_DETAILS) {
          const booking = {
            ...bookingData,
            ...data
          };
          delete booking.source;
          booking.vehicleGroups = booking.vehicleGroups.filter((item) => item.vehicleIds.length > 0);
          if (bookingStatus === BookingStatus.QUOTE) {
            tempStepsCompMap[currentStep] = true;
            setRentalStepsCompletedMap(tempStepsCompMap);
            updateBooking(booking);
          } else {
            createBooking({ ...booking, ...(booking.id && { bookingId: booking.id }) });
          }
        } else if (currentStep === BookingFlowStage.PAYMENT_VIEW) {
          if (!data) {
            snackbar({
              message: "Payment already done",
              variant: SnackBarVariant.INFORMATION
            });
          }
        } else if (currentStep === BookingFlowStage.BASE_DETAILS && ([BOOKING_CATEGORY.COURTESY_CAR.toString(), BOOKING_CATEGORY.TEST_DRIVE.toString()].includes(bookingData.bookingCategory))) {
          tempStepsCompMap[currentStep] = true;
          setRentalStepsCompletedMap(tempStepsCompMap);
          setCurrentStep(2);
        } else {
          tempStepsCompMap[currentStep] = true;
          setRentalStepsCompletedMap(tempStepsCompMap);
          setCurrentStep(currentStep + 1);
          if (data.bookingCategory === BOOKING_CATEGORY.SUBSCRIPTION) {
            setIsBackButtonEnabled(true)
          }
        }
      }
    }
  };

  const createBooking = (bookingInput: IBookingCreateInput) => {
    const vehicleGroupsArr = bookingInput.vehicleGroups.map((vg) => {
      delete vg.branchId
      return vg
    })
    const approvedDrivers: string[] = []
    if (bookingInput.approvedDrivers?.length) {
      bookingInput.approvedDrivers.forEach((driver: any) => {
        if (driver.id) {
          approvedDrivers.push(driver.id);
        } else if (typeof driver === "string") {
          approvedDrivers.push(driver);
        }
      })
    }
    const addonRequirements = bookingInput.addonRequirements.map(
      (addonReq: IAddonRequirementInput) => {
        return {
          addon: addonReq.addon,
          hasFixedRate: addonReq.hasFixedRate,
          name: addonReq.name,
          quantity: addonReq.quantity,
          rate: addonReq.rate,
          displayName: addonReq.displayName,
          tax: {
            title: addonReq.tax && addonReq.tax.title ? addonReq.tax?.title : "",
            value: addonReq.tax && addonReq.tax.value ? addonReq.tax?.value : 0
          }
        };
      }
    );
    bookingInput.addonRequirements = addonRequirements;
    bookingInput.vehicleGroups = bookingInput.isSubscription ? [] : vehicleGroupsArr;
    bookingInput.approvedDrivers = approvedDrivers;

    const booking: IBookingCreateInput = {
      ...(bookingInput.bookingId && { bookingId: bookingInput.bookingId }),
      bookingCategory: bookingInput.bookingCategory,
      pickupServiceLocation: bookingInput.pickupServiceLocation,
      pickupDateTime: d.fromISO(bookingInput.pickupDateTime).toUTC().toISO(),
      pickupOtherLocation: bookingInput.pickupOtherLocation,
      dropoffServiceLocation: bookingInput.dropoffServiceLocation,
      dropoffDateTime: bookingInput.dropoffDateTime
        ? d.fromISO(bookingInput.dropoffDateTime).toUTC().toISO()
        : bookingInput.dropoffDateTime,
      dropoffOtherLocation: bookingInput.dropoffOtherLocation,
      longTermBooking: bookingInput.longTermBooking,
      flightNumber: bookingInput.flightNumber,
      vehicleGroups: bookingInput.vehicleGroups,
      insuranceRate: bookingInput.insuranceRate,
      excess: bookingInput.excess,
      insurancePolicy: bookingInput.insurancePolicy || "",
      addonRequirements: bookingInput.addonRequirements,
      customer: bookingInput.customer,
      customerType: bookingInput.customerType,
      approvedDrivers: bookingInput.approvedDrivers,
      rateTypeName: bookingInput.rateTypeName,
      rateTypeDuration: bookingInput.rateTypeDuration,
      billingCycleName: bookingInput.billingCycleName,
      billingCycleDuration: bookingInput.billingCycleDuration,
      businessCustomer: bookingInput.businessCustomer,
      authorizedSignatory: bookingInput.authorizedSignatory,
      pcoNumber: bookingInput.pcoNumber,
      smartCarVehicle: bookingInput.smartCarVehicle,
      isCarShare: bookingInput.isCarShare || false,
      preBookingQuestions: bookingInput.preBookingQuestions,
      locationSurcharges: bookingInput.locationSurcharges,
      poNumber: bookingInput.poNumber,
      isRecurringBilling: bookingInput.isRecurringBilling,
      billingFrequency: bookingInput.billingFrequency,
      zeroCostBooking: bookingInput.zeroCostBooking || false,
      tba: bookingInput.tba || false,
      autoRenewal: bookingInput.autoRenewal || false,
      isSubscription: bookingInput.isSubscription || false,
      isCoi: bookingInput.isCoi || false,
    };
    if (bookingInput.costCenter) {
      booking.costCenter = bookingInput.costCenter;
      if (bookingInput.projectId) {
        booking.projectId = bookingInput.projectId;
      }
    }
    if (bookingInput.isSubscription) {
      booking.activeSubscription = bookingInput.activeSubscription
      booking.locationSurcharges = []
    }
    setBookingCreationFailed(false);
    if (newAddedDrivers.length) {
      const newAddedDriversId = newAddedDrivers.map((val) => val.id)
      const isNewDriverAdded = newAddedDriversId.some((id: any) => !booking.approvedDrivers.includes(id))
      if (isNewDriverAdded) {
        return snackbar({
          message: "Save Drivers before creating quote.",
          variant: SnackBarVariant.ERROR
        })
      }
    }

    createBookingMutation({
      variables: {
        booking
      }
    });
  };

  const updateBooking = (bookingInput: IBookingCreateInput) => {
    const vehicleGroupsArr = bookingInput.vehicleGroups.map((vg) => {
      delete vg.branchId
      return vg
    })
    const approvedDrivers: string[] = []
    if (bookingInput.approvedDrivers?.length) {
      bookingInput.approvedDrivers.forEach((driver: any) => {
        if (driver.id) {
          approvedDrivers.push(driver.id);
        } else if (typeof driver === "string") {
          approvedDrivers.push(driver);
        }
      })
    }
    const addonRequirements = bookingInput.addonRequirements.map(
      (addonReq: IAddonRequirementInput) => {
        return {
          addon: addonReq.addon,
          hasFixedRate: addonReq.hasFixedRate,
          name: addonReq.name,
          quantity: addonReq.quantity,
          rate: addonReq.rate,
          displayName: addonReq.displayName,
          tax: {
            title: addonReq.tax && addonReq.tax.title ? addonReq.tax?.title : "",
            value: addonReq.tax && addonReq.tax.value ? addonReq.tax?.value : 0
          }
        };
      }
    );
    bookingInput.addonRequirements = addonRequirements;
    bookingInput.vehicleGroups = vehicleGroupsArr;
    bookingInput.approvedDrivers = approvedDrivers;
    const booking: any = {
      id: bookingInput.id ? bookingInput.id : "",
      ...(bookingInput.bookingId && { bookingId: bookingInput.bookingId }),
      pickupServiceLocation: bookingInput.pickupServiceLocation,
      pickupDateTime: new Date(bookingInput.pickupDateTime).toISOString(),
      pickupOtherLocation: bookingInput.pickupOtherLocation,
      dropoffServiceLocation: bookingInput.dropoffServiceLocation,
      dropoffDateTime: bookingInput.dropoffDateTime
        ? new Date(bookingInput.dropoffDateTime).toISOString()
        : bookingInput.dropoffDateTime,
      dropoffOtherLocation: bookingInput.dropoffOtherLocation,
      flightNumber: bookingInput.flightNumber,
      vehicleGroups: bookingInput.vehicleGroups,
      longTermBooking: bookingInput.longTermBooking,
      insuranceRate: bookingInput.insuranceRate,
      excess: bookingInput.excess,
      insurancePolicy: bookingInput.insurancePolicy || "",
      addonRequirements: bookingInput.addonRequirements,
      approvedDrivers: bookingInput.approvedDrivers,
      rateTypeName: bookingInput.rateTypeName,
      rateTypeDuration: bookingInput.rateTypeDuration,
      billingCycleName: bookingInput.billingCycleName,
      billingCycleDuration: bookingInput.billingCycleDuration,
      authorizedSignatory: bookingInput.authorizedSignatory,
      pcoNumber: bookingInput.pcoNumber,
      preBookingQuestions: bookingInput.preBookingQuestions,
      locationSurcharges: bookingInput.locationSurcharges,
      isRecurringBilling: bookingInput.isRecurringBilling,
      billingFrequency: bookingInput.billingFrequency,
      zeroCostBooking: bookingInput.zeroCostBooking,
      isSubscription: bookingInput.isSubscription || false,
      isCoi: bookingInput.isCoi || false,
      oneWayRentalFee: bookingInput.oneWayRentalFee || null,
      retainDepositDetails: bookingInput.retainDepositDetails || false
    };
    if (bookingInput.costCenter) {
      booking.costCenter = bookingInput.costCenter;
      if (bookingInput.projectId) {
        booking.projectId = bookingInput.projectId;
      }
    }
    if (booking.isCoi && booking.coiDetails) {
      booking.coiDetails = bookingInput.coiDetails
    }
    if (bookingInput.isSubscription && bookingInput.activeSubscription) {
      booking.activeSubscription = {
        ...bookingInput.activeSubscription,
        subscription: typeof bookingInput.activeSubscription.subscription === "string" ? bookingInput.activeSubscription.subscription : bookingInput.activeSubscription.subscription.id,
        vehicle: typeof bookingInput.activeSubscription.vehicle === "string" ? bookingInput.activeSubscription.vehicle : bookingInput.activeSubscription.vehicle.id
      }
      booking.locationSurcharges = []
    }
    updateBookingMutation({
      variables: {
        booking
      }
    });
  };

  const getBookingTypeIcon = (type: string) => {
    switch (type) {
      case BOOKING_CATEGORY.RENTAL:
        return <RentalIcon width={50} height={50} fill="secondary" />

      case BOOKING_CATEGORY.SUBSCRIPTION:
        return <SubscriptionIcon width={50} height={50} fill="secondary" />

      case BOOKING_CATEGORY.TEST_DRIVE:
        return <TesDriveIcon width={50} height={50} fill="red" />

      case BOOKING_CATEGORY.COURTESY_CAR:
        return <CourtesyCarIcon width={50} height={50} fill="secondary" />

      default:
        return <RentalIcon width={50} height={50} fill="secondary" />
    }
  }

  const handleRentalStep = (index: number) => {
    if (index < currentStep) {
      setCurrentStep(index);
      const tempStepsCompMap = [...rentalStepsCompletedMap];
      for (var i = index + 1; i < rentalStepsCompletedMap.length; i++) {
        tempStepsCompMap[i] = false
      }
      setRentalStepsCompletedMap(tempStepsCompMap);
    }
  }
  const stepMapper: any = {
    0: (
      <BaseDetails
        bookingData={bookingData}
        vehicleGroupsData={vehicleGroups}
        locationSurchargesData={locationSurchargeRules}
        oneWayRentalFee={oneWayRentalFee}
        updateLocationSurcharge={setLocationSurchargeRules}
        updateOneWayRentalFee={setOneWayRentalFee}
        updateVehicleGroups={setVehicleGroups}
        isUpdate={isUpdate}
        onSubmit={nextStep}
        updateSubscriptionVehicles={setSubscriptionVehicles}
        subscriptionVehicles={subscriptionVehicles || []}
        setIsBackButtonEnabled={setIsBackButtonEnabled}
      />
    ),
    1: (
      <AddonsSelection
        bookingData={bookingData}
        selectedInsurancePolicy={selectedInsurancePolicy}
        selectedInsuranceRate={selectedInsuranceRate}
        isUpdate={isUpdate}
        onSubmit={nextStep}
        onPrevious={previousStep}
        updateParentState={updateParentState}
      />
    ),
    2: (
      <PersonalDetails
        bookingData={bookingData}
        onSubmit={nextStep}
        onPrevious={previousStep}
        bookingCreationFailed={bookingCreationFailed}
        addedDrivers={newAddedDrivers}
        newAddedDrivers={(drivers: IDriverRow[]) => {
          setNewAddedDrivers(drivers)
        }}
        bookingLoading={createBookingLoading}
      />
    ),
    3: (
      <QuoteView
        onSubmit={nextStep}
        bookingData={bookingData}
        updateBooking={updateBooking}
      />
    ),
    4: <PaymentView bookingData={bookingData} />
  };

  if (updateBookingLoading) {
    return <CircularProgress />;
  }


  return (
    <Grid container spacing={2}>
      {
        isBackButtonEnabled && !isSingleBookingCategory && !isUpdate && (
          <Grid item xs={12} style={{ position: "absolute", top: 40 }}>
            <Tooltip title="Go back to booking categories selection.">
              <IconButton
                onClick={() => {
                  setIsBackButtonEnabled(false)
                  setVehicleGroups([])
                  setExternalVehicleGroups([])
                  setCurrentStep(0)
                  setRentalStepsCompletedMap(stepsCompletedMapInitial)
                  setBookingTypeTabIndex(0)
                  setBookingData({
                    ...initialValues,
                    bookingCategory: bookingTypes[0].type
                  })
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )
      }
      <CssBaseline />
      <Grid item xs={12}>
        {
          (!isBackButtonEnabled && !isUpdate && !isSingleBookingCategory) && (
            <Tabs
              value={bookingTypeTabIndex}
              onChange={(e: React.SyntheticEvent, newValue: number) => {
                const selectedBookingType = bookingTypes[newValue];

                const categoryMap = {
                  [BOOKING_CATEGORY.RENTAL]: BOOKING_CATEGORY.RENTAL,
                  [BOOKING_CATEGORY.SUBSCRIPTION]: BOOKING_CATEGORY.SUBSCRIPTION,
                  [BOOKING_CATEGORY.COURTESY_CAR]: BOOKING_CATEGORY.COURTESY_CAR,
                  [BOOKING_CATEGORY.TEST_DRIVE]: BOOKING_CATEGORY.TEST_DRIVE
                };

                const bookingCategory = categoryMap[selectedBookingType.type];
                if (bookingCategory) {
                  setBookingData({
                    ...initialValues,
                    bookingCategory
                  });
                }
                setBookingTypeTabIndex(newValue)
              }}
              aria-label="icon label tabs example"
              centered
              style={{
                width: "80%",
                margin: "0 auto",
                background: "transparent"
              }}
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              {
                bookingTypes.length > 0 && bookingTypes.map((type, index) => {
                  return (
                    <Tab
                      style={{
                        marginRight: 10, borderRadius: 6,
                        color: theme.palette.secondary.main,
                        border: bookingTypeTabIndex === index ? `3px solid ${theme.palette.primary.main}` : "",
                        fontWeight: 600,
                        background: "#FFFFFF"
                      }}
                      key={type.type}
                      icon={getBookingTypeIcon(type.type)}
                      label={bookingCategoryLabels[type.type]}
                    />
                  )
                })
              }
            </Tabs>
          )
        }
        {
          (
            <Grid item xs={12}>
              <Box pt={2} sx={{ width: "70%", margin: "0 auto", paddingTop: isBackButtonEnabled ? 0 : 2 }}>
                <Stepper nonLinear activeStep={currentStep}>
                  {
                    // Filter steps based on the booking category
                    ([BOOKING_CATEGORY.TEST_DRIVE.toString(), BOOKING_CATEGORY.COURTESY_CAR.toString()].includes(bookingData.bookingCategory)
                      ? ["Select Vehicle", "Confirm"] // Show only these steps for TEST_DRIVE or COURTESY
                      : ["Select Vehicle", "Select Add-Ons", "Add Driver", "Quote", "Confirm"] // Default steps
                    ).map((label, index) => {
                      const stepProps = {};
                      return (
                        <Step
                          key={label}
                          {...stepProps}
                          completed={rentalStepsCompletedMap[index]}
                          disabled={currentStep === BookingFlowStage.QUOTE_VIEW || currentStep === BookingFlowStage.PAYMENT_VIEW}
                          className={rentalStepsCompletedMap[index] ? classes.stepper : ""}
                        >
                          <StepButton
                            onClick={() => {
                              handleRentalStep(index);
                            }}
                          >
                            {label}
                          </StepButton>
                        </Step>
                      );
                    })}
                </Stepper>
              </Box>
            </Grid>
          )
        }

        <>
          <TabPanel value={bookingTypeTabIndex} index={0} >
            {(bookingLoading || vehicleGroupsLoading || externalVehicleGroupsLoading || subscriptionVehiclesLoading) ?
              (
                <Grid item xs={12} container justifyContent={"center"} alignItems={"center"} style={{ margin: "0 auto", marginTop: "20px", height: "50vh" }}>
                  <CircularProgress />
                </Grid>
              ) : stepMapper[currentStep]
            }
          </TabPanel>
          <TabPanel value={bookingTypeTabIndex} index={1} >
            {(bookingLoading || vehicleGroupsLoading || externalVehicleGroupsLoading || subscriptionVehiclesLoading) ?
              (
                <Grid item xs={12} container justifyContent={"center"} alignItems={"center"} style={{ margin: "0 auto", marginTop: "20px", height: "50vh" }}>
                  <CircularProgress />
                </Grid>
              ) : stepMapper[currentStep]
            }
          </TabPanel>
          <TabPanel value={bookingTypeTabIndex} index={2} >
            {(bookingLoading || vehicleGroupsLoading || externalVehicleGroupsLoading || subscriptionVehiclesLoading) ?
              (
                <Grid item xs={12} container justifyContent={"center"} alignItems={"center"} style={{ margin: "0 auto", marginTop: "20px", height: "50vh" }}>
                  <CircularProgress />
                </Grid>
              ) : stepMapper[currentStep]
            }
          </TabPanel>
        </>
        <TabPanel value={bookingTypeTabIndex} index={3} >
          {(bookingLoading || vehicleGroupsLoading || externalVehicleGroupsLoading || subscriptionVehiclesLoading) ?
            (
              <Grid item xs={12} container justifyContent={"center"} alignItems={"center"} style={{ margin: "0 auto", marginTop: "20px", height: "50vh" }}>
                <CircularProgress />
              </Grid>
            ) : stepMapper[currentStep]
          }
        </TabPanel>
      </Grid>

      {/* <Grid item container sm={12}>
        <Grid item container sm={4} alignItems="center">
          <Typography variant="h1" color="primary">
            {`${getLocalizedBookingSyntex(country)}s`}
          </Typography>
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon />
          <Box color="white" sx={{ pl: 1 }}></Box>
          {location.pathname.includes("update") ? (
            <Typography variant="h1" color="primary">
              Update
            </Typography>
          ) : (
            <Typography variant="h1" color="primary">
              New
            </Typography>
          )}
          <Typography variant="h1" color="primary">
            &nbsp; {`${getLocalizedBookingSyntex(country)}`}
          </Typography>
        </Grid>
        <Grid item container sm={8} alignContent="flex-end">
          <StepperHeader
            currentStep={currentStep}
            steps={[
              "Select Vehicle",
              "Select Add-Ons",
              "Add Driver",
              "Quote",
              "Confirm"
            ]}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {(bookingLoading || vehicleGroupsLoading || externalVehicleGroupsLoading || subscriptionVehiclesLoading) ? <CircularProgress /> : stepMapper[currentStep]}
      </Grid> */}
    </Grid >
  );
};

export default NewBooking;