
import "./index.scss";
import InfiniteScroll from "react-infinite-scroll-component";

import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, TextField, Theme, Tooltip, Typography, FormControl, InputAdornment, Select, MenuItem, ListItemIcon, ListItemText, SelectChangeEvent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import { useLazyQuery, useMutation } from "@apollo/client";
import _, { set } from "lodash";
import { DateTime as d } from "luxon";
import FlatPickerBar from "../../../common/FlatPicker";
import { DATE_TYPE, DateTimeFormat, formatGraphQLErrorMessage } from "../../../common/utils";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { IVehicle, IVehicleService } from "../../../../reducers/fleet/types";
import { BookingStatus, IBooking } from "../../../../reducers/bookings/types";
import { DialogTypes, getVehicleStatusClass, IVehicleReplacementArgs, IVehicleSubscriptionReplacementArgs, VehicleEventType, VehicleStatus, vehicleStatuses } from "./utils";
import { GET_VEHICLE_SCHEDULES_BY_DATE } from "../../../../graphql/fleet/getVehicleScheduleByDate";
import { IHoldInput, IHoldNote } from "./SchedulerModels/OnHoldSummary";
import { CREATE_VEHICLE_ON_HOLD_MUTATION } from "../../../../graphql/fleet/createVehicleOnHold";
import { UPDATE_VEHICLE_ON_HOLD_MUTATION } from "../../../../graphql/fleet/updateVehicleOnholdMutation";
import { REMOVE_VEHICLE_ON_HOLD_MUTATION } from "../../../../graphql/fleet/removeVehicleHoldMutation";
import { SchedulerDialog } from "./SchedulerModels";
import { START_BOOKING } from "../../../../graphql/bookings/startBookingMutation";
import { ApolloError } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { updateBooking, updateInvoice } from "../../../../actions/bookings/actions";
import { updateInvoiceAction } from "../../../../actions/invoices/actions";
import { GET_BOOKINGS } from "../../../../graphql/bookings/getBookingsQuery";
import { CANCEL_BOOKING } from "../../../../graphql/bookings/cancelBookingMutation";
import { COMPLETE_BOOKING } from "../../../../graphql/bookings/completeBookingMutation";
import { GET_VEHICLE_GROUPS } from '../../../../graphql/vehiclePriceGroup/getVehicleGroupsQuery';
import { IAppState } from '../../../../store';
import { IVehiclePriceGroup } from '../../../../reducers/vehiclePriceGroups/types';
import { UPDATE_BOOKING_SCHEDULE } from "../../../../graphql/bookings/updateBookingScheduleMutation";
import { SHIFT_BOOKING_SCHEDULE } from "../../../../graphql/bookings/shiftBookingScheduleMutation";
import Autocomplete from "@mui/material/Autocomplete";
import { GET_EXTERNAL_VEHICLE_SCHEDULES_BY_DATE } from "../../../../graphql/fleet/vehicleServices/getExternalVehicleScheduleByDate";
import { REPLACE_IN_PROGRESS_BOOKING_VEHICLE } from "../../../../graphql/fleet/vehicleScheduler/replaceInProgressBookingVehicle";
import { IUserState } from "../../../../reducers/user/types";
import { REPLACE_IN_PROGRESS_SUBSCRIPTION_VEHICLE } from "../../../../graphql/fleet/vehicleScheduler/subscriptionVehicleReplacementMutation";
import { getLocalizedBookingSummarySyntex, getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { captureErrorException } from "../../../../utils/sentry";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import TbaIcon from "../../../../assets/icons/icon-tba-booking.svg"
import VirtualVehicleIcon from "../../../../assets/icons/icon-virtual-vehicle.svg"
import { GET_VEHICLE_SCHEDULES_BY_SEARCH } from "../../../../graphql/fleet/vehicleServices/vehicleEventsSearchQuery";

export interface IHoldInfo {
  id: string;
  vehicleId?: string;
  startDate: string;
  endDate: string;
  branchId?: string;
  createdBy?: IUserState;
  dateCreated?: string;
  notes?: IHoldNote[];
  isSharedFleetEvent?: boolean;
}
interface IBookingSchedule {
  booking: IBooking;
  vehicle: IVehicle;
  vehicleStatus: string;
  startDate: string;
  endDate: string;
  id?: string;
  tba?: boolean;
}
interface IVehicleEvent {
  type: VehicleEventType;
  status: string;
  resourceId?: string;
  bookingSchedule?: IBookingSchedule;
  service?: IVehicleService;
  hold?: IHoldInfo;
  start: string;
  end: string;
  title: string;
  allDay: boolean;
  classNames: string[];
  vehicleId?: string;
  isSharedFleetEvent?: boolean;
  isGhostVehicle?: boolean;
}
interface IClickInfo {
  el: HTMLElement;
  event: any;
  jsEvent: MouseEvent;
  view: any;
}

interface IDropEvent {
  startDate: string;
  endDate: string;
  bookingId: string;
  bookingScheduleId: string;
  vehicleGroupId: string;
  previousVehicle: string;
  currentVehicle: string;
  rateTypeDuration?: number;
  rateTypeName?: string;
  vehicleGropups?: IVehiclePriceGroup[];
}
interface IDialogData {
  bookingSchedule?: IBookingSchedule;
  service?: IVehicleService;
  hold?: IHoldInfo;
  startDate?: string;
  endDate?: string;
  resource?: IVehicle;
  vehicleId?: string;
  bookingId?: string;
  onConfirm?(): void;
  onCancel?(): void;
  dropEvent?: IDropEvent;
  isSharedFleetEvent?: boolean
  vehicleGroup?: string;
}

enum FilterType {
  EVENT = "EVENT",
  VEHICLE_STATUS = "VEHICLE_STATUS",
  BOOKING_SCHEDULE = "BOOKING_SCHEDULE",
  VEHICLE_GROUP = "VEHICLE_GROUP"
}

interface IUpdateBookingSchedule {
  startDate: string;
  endDate: string;
  bookingId: string;
  bookingSchedules: string[];
}

interface IResource {
  id: string;
  fleet: IVehicle;
  title: string;
  isSharedFleetEvent?: boolean
}
interface IFilter {
  label: string;
  active: boolean;
  value: string;
  type?: FilterType;
  color: string;
}

const filtersVehicle: IFilter[] = [
  { label: "Out on Hire", active: false, value: "IN_PROGRESS", color: "#9d6eaf" },
  // { label: "Immobilised", active: false, value: "IMMOBILIZED", color: "" },
  { label: "On-Hold", active: false, value: "ON_HOLD", color: "#002f6e" },
  // { label: "Deactivated", active: false, value: "Deactivated", type: FilterType.VEHICLE_STATUS, color: "" },
  { label: "In-Transit", active: false, value: "In Transit", color: "#ffc700" },
  { label: "In-Service", active: false, value: "IN_SERVICE", color: "#003da7" },
  { label: "Confirmed", active: false, value: "CONFIRMED", color: "#009990" },
  { label: "Completed", active: false, value: "COMPLETED", color: "#4caf50" },
];

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  popoverMenu: {
    minWidth: 600,
    width: "50%",
    "& .MuiPopover-paper": {
      width: '40%',
      padding: theme.spacing(2),
      marginRight: 10,
      boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
    }
  },
  selectEvent: {
    "& .MuiOutlinedInput-input": {
      background: "#fff",
      display: "flex",
      alignItems: "center",
      "& .MuiListItemIcon-root": {
        minWidth: 30,
      },
      "& .MuiListItemText-root": {
        marginTop: 0
      }
    }
  },
  filterToolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  filterIcon: {
    padding: 5,
    boxShadow: `${theme.palette.primary.main} 0px 30px 90px`
  }
}));

export const VehicleScheduler = () => {

  const classes = useStyles();
  const fullCalendar = React.createRef<FullCalendar>();
  const snackbar = useSnackBar();
  const dispatch = useDispatch();
  const enableVirtualVehicleFilter = useRef(false);
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address
  // region local states
  const [filters, setFilters] = useState<IFilter[]>(_.cloneDeep(filtersVehicle));

  // events states 
  const [resources, setResources] = useState<IResource[]>([]);
  const [activeResources, setActiveResources] = useState<IResource[]>([]);
  const [vehicleEvents, setVehicleEvents] = useState<IVehicleEvent[]>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<DialogTypes>();
  const [dialogData, setDialogData] = useState<IDialogData>({});
  const [bookingId, setBookingId] = useState<string>("");
  const [vehicleGroups, setVehicleGroups] = useState<IVehiclePriceGroup[]>([]);
  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState<string>("");
  const [searchVal, setSearchVal] = useState<string>("");
  const [filterDate, setFilterDate] = useState<string>(d.now().toUTC().toISO());
  const [isTba, setIsTba] = useState<boolean>(false);
  const [isVirtualVehicleToggle, setIsVirtualVehicleToggle] = useState<boolean>(false);
  const [selectedEventFilter, setSelectedFilterEvent] = useState<string>("")
  const [eventOffset, setEventOffset] = useState<number>(0)
  const [externalEventOffset, setExternalEventOffset] = useState<number>(0)
  const [eventLimitForViewChange, setEventLimitForViewChange] = useState<number>(20)
  const [externalEventLimitForViewChange, setExternalEventLimitForViewChange] = useState<number>(20)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [fullCalendarScrollAppId, setFullCalendarScrollAppId] = useState<string>("")
  const [selectedEventFilterValue, setSelectedEventFilterValue] = useState<string>("all")
  const [selectedEventFilterStatus, setSelectedEventFilterStatus] = useState<string>("")
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: "", endDate: "" })
  const [hasMoreLoadExternalVehicleEvents, setHasMoreLoadExternalVehicleEvents] = useState<boolean>(true)
  const [hasMoreLoadVehicleEvents, setHasMoreLoadVehicleEvents] = useState<boolean>(true)

  //#region Graphql queries, mutations
  const [getVehicleScheduleByDate,
    { loading: vehicleSchedulesLoading, data: vehicleScheduleData
    }] = useLazyQuery(GET_VEHICLE_SCHEDULES_BY_DATE, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data && data.vehicleEvents.length) {
          reShapeEvents(data.vehicleEvents)
        }
        if (data.vehicleEvents.length < 20) {
          setHasMoreLoadVehicleEvents(false)
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
      }
    });
  const [getExternalVehicleScheduleByDate,
    { loading: externalVehicleSchedulesLoading, data: externalVehicleScheduleData
    }] = useLazyQuery(GET_EXTERNAL_VEHICLE_SCHEDULES_BY_DATE, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data && data.externalVehicleEvents) {
          reShapeEvents(data.externalVehicleEvents)
        }
        if (data.externalVehicleEvents.length < 20) {
          setHasMoreLoadExternalVehicleEvents(false)
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        })
      }
    });

  const [loadVehicleGroups,
    { data: vehicleGroupsData }
  ] = useLazyQuery(
    GET_VEHICLE_GROUPS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });


  const [createVehicleHold] = useMutation(CREATE_VEHICLE_ON_HOLD_MUTATION);

  const [updateVehicleHold] = useMutation(UPDATE_VEHICLE_ON_HOLD_MUTATION);

  const [removeVehicleHold] = useMutation(REMOVE_VEHICLE_ON_HOLD_MUTATION);

  const [startBookingMutation] = useMutation(START_BOOKING, {
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    update: (proxy, { data: { startBooking } }) => {
      dispatch(updateBooking(startBooking));
      dispatch(updateInvoice(startBooking.firstInvoice.id));
      dispatch(updateInvoiceAction(startBooking.firstInvoice));
      try {
        const result: any = proxy.readQuery({ query: GET_BOOKINGS });
        proxy.writeQuery({
          data: {
            bookings: result.bookings.concat([startBooking])
          },
          query: GET_BOOKINGS
        });
      } catch {
        return;
      }
    }
  });

  const [cancelBookingMutation] = useMutation(CANCEL_BOOKING, {
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: () => {
      snackbar({
        message: `${getLocalizedBookingSyntex(country)} cancelled successfully`,
        variant: SnackBarVariant.SUCCESS
      });
    }
  });

  const [completeBookingMutation] = useMutation(COMPLETE_BOOKING, {
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: () => {
      snackbar({
        message: "Booking completed successfully",
        variant: SnackBarVariant.SUCCESS
      });
    }
  });

  const [updateBookingSchedule] = useMutation(UPDATE_BOOKING_SCHEDULE);
  const [shiftBookingSchedule] = useMutation(SHIFT_BOOKING_SCHEDULE);
  const [replaceInProgressBookingVehicle, { loading: replaceVehicleLoading }] = useMutation(REPLACE_IN_PROGRESS_BOOKING_VEHICLE);
  const [replaceInProgressSubscriptionVehicle, { loading: replaceSubscriptionVehicleLoading }] = useMutation(REPLACE_IN_PROGRESS_SUBSCRIPTION_VEHICLE);

  useEffect(() => {
    if (!open) {
      setDialogData({ startDate: "", endDate: "" })
    }
  }, [open])

  useEffect(() => {
    loadVehicleGroups();
  }, [userReducer]);

  useEffect(() => {
    if (fullCalendar && fullCalendar.current) {
      let calendarScrollElement = document.getElementsByClassName("fc-scroller-liquid-absolute")[1];
      if (calendarScrollElement) {
        calendarScrollElement.id = "myFullCalendarAppId"
        setFullCalendarScrollAppId("myFullCalendarAppId")
      }
    }
  }, [fullCalendar])

  useEffect(() => {
    if (vehicleGroupsData && vehicleGroupsData.vehicleGroups) {
      const sortGroups = [...vehicleGroupsData.vehicleGroups].sort((firstGroup: IVehiclePriceGroup, secondGroup: IVehiclePriceGroup) => {
        return firstGroup.name.toLowerCase().localeCompare(secondGroup.name.toLowerCase());
      })
      if (sortGroups) {
        setVehicleGroups(sortGroups);
      }
    }
  }, [vehicleGroupsData])

  const reShapeEvents = (vehicleBookingScheduleData: any[]) => {
    const newResources: IResource[] = [];
    const vehicleEventList: IVehicleEvent[] = [];
    const promises: Promise<IVehicle>[] = [];
    vehicleBookingScheduleData.forEach((vehicle: IVehicle, index: number) => {
      promises.push((async () => {
        try {
          if (vehicle.id) {
            const isSharedFleetEvent = vehicle.branchId !== userReducer.currentBranch.id ? true : false
            newResources.push({ id: vehicle.id, fleet: vehicle, title: vehicle.assetTag, isSharedFleetEvent });
          }
          if (vehicle.services && vehicle.services.length > 0) {
            vehicle.services.forEach((service: IVehicleService) => {
              const timeZoneOffset = d.now().offset
              const isPositiveOffset = timeZoneOffset >= 0;
              let startDate;
              let endDate;
              if (isPositiveOffset) {
                startDate = d.fromISO(service.startDate).plus({ minutes: timeZoneOffset }).toUTC().toISO()
                endDate = d.fromISO(service.completionDate).plus({ minutes: timeZoneOffset }).toUTC().toISO()
              } else {
                startDate = d.fromISO(service.startDate).minus({ minutes: timeZoneOffset }).toUTC().toISO()
                endDate = d.fromISO(service.completionDate).minus({ minutes: timeZoneOffset }).toUTC().toISO()
              }
              const isSharedFleetEvent = service.branchId !== userReducer.currentBranch.id ? true : false
              vehicleEventList.push({
                resourceId: vehicle.id,
                allDay: false,
                service,
                isSharedFleetEvent,
                type: VehicleEventType.IN_SERVICE,
                status: service.status,
                vehicleId: vehicle.id,
                title: "",
                start: startDate,
                end: endDate,
                classNames: getVehicleStatusClass(service.status, isSharedFleetEvent)
              });
            });
          }
          if (vehicle.holds && vehicle.holds.length > 0) {
            vehicle.holds.forEach((hold: IHoldInfo) => {
              const timeZoneOffset = d.now().offset
              const isPositiveOffset = timeZoneOffset >= 0;
              let startDate
              let endDate;
              if (isPositiveOffset) {
                startDate = d.fromISO(hold.startDate).plus({ minutes: timeZoneOffset }).toUTC().toISO()
                endDate = d.fromISO(hold.endDate).plus({ minutes: timeZoneOffset }).toUTC().toISO()
              } else {
                startDate = d.fromISO(hold.startDate).minus({ minutes: timeZoneOffset }).toUTC().toISO()
                endDate = d.fromISO(hold.endDate).minus({ minutes: timeZoneOffset }).toUTC().toISO()
              }
              const isSharedFleetEvent = vehicle.branchId !== userReducer.currentBranch.id ? true : false
              vehicleEventList.push({
                resourceId: vehicle.id,
                allDay: false,
                hold,
                isSharedFleetEvent,
                type: VehicleEventType.ON_HOLD,
                status: VehicleStatus.ON_HOLD,
                vehicleId: vehicle.id,
                title: "",
                start: startDate,
                end: endDate,
                classNames: getVehicleStatusClass(VehicleStatus.ON_HOLD, isSharedFleetEvent)
              });
            });
          }
          if (!isVirtualVehicleToggle && vehicle.bookingSchedules && vehicle.bookingSchedules.length) {
            vehicle.bookingSchedules.forEach((bookingSchedule: any) => {
              if (bookingSchedule.booking.status && bookingSchedule.booking.status !== VehicleStatus.CANCELLED) {
                const timeZoneOffset = d.now().offset
                const isPositiveOffset = timeZoneOffset >= 0;
                let startDate
                let endDate;
                if (isPositiveOffset) {
                  startDate = d.fromISO(bookingSchedule.startDate).plus({ minutes: timeZoneOffset }).toUTC().toISO()
                  endDate = d.fromISO(bookingSchedule.endDate).plus({ minutes: timeZoneOffset }).toUTC().toISO()
                } else {
                  startDate = d.fromISO(bookingSchedule.startDate).minus({ minutes: timeZoneOffset }).toUTC().toISO()
                  endDate = d.fromISO(bookingSchedule.endDate).minus({ minutes: timeZoneOffset }).toUTC().toISO()
                }
                const isSharedFleetEvent = bookingSchedule.booking.branchId !== userReducer.currentBranch.id ? true : false
                vehicleEventList.push({
                  resourceId: vehicle.id,
                  allDay: false,
                  bookingSchedule,
                  isSharedFleetEvent,
                  type: VehicleEventType.BOOKING_SCHEDULE,
                  status: bookingSchedule.booking.status,
                  vehicleId: vehicle.id,
                  title: bookingSchedule.booking.customerType === "individual" ?
                    `${bookingSchedule.booking.customer.firstName.charAt(0)}.${bookingSchedule.booking.customer.lastName}${bookingSchedule.tba ? " *TBA" : ""}` :
                    `${bookingSchedule.booking.businessCustomer.businessName}${bookingSchedule.tba ? " *TBA" : ""}`,
                  start: startDate,
                  end: endDate,
                  classNames: getVehicleStatusClass(bookingSchedule.vehicleStatus, isSharedFleetEvent)
                });
              }
            });
          }
          if (isVirtualVehicleToggle && vehicle.isGhostVehicle && vehicle.bookingSchedules && vehicle.bookingSchedules.length > 0) {
            vehicle.bookingSchedules.forEach((bookingSchedule: any) => {
              if (bookingSchedule.booking.status && bookingSchedule.booking.status !== VehicleStatus.CANCELLED) {
                const isSharedFleetEvent = bookingSchedule.booking.branchId !== userReducer.currentBranch.id ? true : false
                vehicleEventList.push({
                  resourceId: vehicle.id,
                  allDay: false,
                  bookingSchedule,
                  isSharedFleetEvent,
                  type: VehicleEventType.BOOKING_SCHEDULE,
                  status: bookingSchedule.booking.status,
                  vehicleId: vehicle.id,
                  title: bookingSchedule.booking.customerType === "individual" ?
                    `${bookingSchedule.booking.customer.firstName.charAt(0)}.${bookingSchedule.booking.customer.lastName}${bookingSchedule.tba ? " *TBA" : ""}` :
                    `${bookingSchedule.booking.businessCustomer.businessName}${bookingSchedule.tba ? " *TBA" : ""}`,
                  start: d.fromISO(bookingSchedule.startDate).toUTC().toISO(),
                  end: d.fromISO(bookingSchedule.endDate).toUTC().toISO(),
                  classNames: getVehicleStatusClass(bookingSchedule.vehicleStatus, isSharedFleetEvent),
                  isGhostVehicle: vehicle.isGhostVehicle
                });
              }
            });
          }
          return vehicle;
        } catch (error) {
          captureErrorException(error)
          return vehicle;
        }
      })());
    });
    Promise.all(promises).then(() => {
      const allResources = [...resources, ...newResources];
      const allActiveResources = [...activeResources, ...newResources];
      if (isVirtualVehicleToggle) {
        const virtualVehicleResources = allResources.filter(obj => obj.fleet.isGhostVehicle && obj.fleet.bookingSchedules?.length)
        const virtualVehicleActiveResources = allActiveResources.filter(resource => resource.fleet.isGhostVehicle && resource.fleet.bookingSchedules?.length)
        setResources(virtualVehicleResources)
        setActiveResources(virtualVehicleActiveResources)
      } else {
        setResources(allResources);
        setActiveResources(allActiveResources);
      }
      if (vehicleEvents.length > 0) {
        const filteredBookingEvents = vehicleEventList.filter((oldEvents) => !vehicleEvents.some((newEvents) => newEvents.bookingSchedule?.id === oldEvents.bookingSchedule?.id));
        const filteredServiceEvents = vehicleEventList.filter((oldEvents) => !vehicleEvents.some((newEvents) => newEvents.service?.id === oldEvents.service?.id));
        const filteredHoldEvents = vehicleEventList.filter((oldEvents) => !vehicleEvents.some((newEvents) => newEvents.hold?.id === oldEvents.hold?.id));
        const uniqueBookingAndServiceEvents = _.uniq([...filteredBookingEvents, ...filteredServiceEvents]);
        setVehicleEvents([...vehicleEvents, ...uniqueBookingAndServiceEvents, ...filteredHoldEvents]);
      } else {
        setVehicleEvents([...vehicleEvents, ...vehicleEventList]);
      }
    })

  }

  const handleCloseDialog = (type?: boolean) => {
    if (type) {
      setVehicleEvents([...vehicleEvents]);
    }
    setOpen(false);
  };

  const handleSwitchChange = () => {
    clearFilters()
    setIsTba(true)
    tbaEventFilter(true);
  };

  const handleVirtaulVehicleChange = (checked: boolean) => {
    clearFilters()
    setIsVirtualVehicleToggle(checked)
    enableVirtualVehicleFilter.current = checked;
    if (checked) {
      setSelectedEventFilterValue("ghostVehicle")
      getVehicleScheduleByDate({
        variables: {
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          limit: 20,
          offset: 0,
          status: VehicleStatus.ACTIVE,
          filterType: "ghostVehicle"
        }
      })
    } else {
      clearFilters(true)
    }
  }

  const reloadScheduler = () => {
    setIsVirtualVehicleToggle(false)
    setVehicleEvents([])
    setActiveResources([])
    setResources([])
    if (enableVirtualVehicleFilter.current) {
      setIsVirtualVehicleToggle(true)
    }
    setTimeout(() => {
      getVehicleScheduleByDate({
        variables: {
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          limit: 20,
          offset: 0,
          status: VehicleStatus.ACTIVE
        }
      })
      getExternalVehicleScheduleByDate({
        variables: {
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          limit: 20,
          offset: 0,
          status: VehicleStatus.ACTIVE
        }
      })
    }, 2000);
  }

  const tbaEventFilter = (isChecked: boolean) => {
    if (isChecked) {
      setEventLimitForViewChange(20)
      setExternalEventLimitForViewChange(20)
      let selectedVal = "bookingSchedule";
      let selectedStatus = "TBA";
      setSelectedEventFilterValue(selectedVal)
      setSelectedEventFilterStatus(selectedStatus)
      getVehicleScheduleByDate({
        variables: {
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          limit: 20,
          offset: 0,
          status: VehicleStatus.ACTIVE,
          vehicleGroupId: selectedVehicleGroup,
          searchText: selectedVehicleGroup ? "" : searchVal,
          filterType: selectedVal,
          filterStatus: selectedStatus
        }
      })
      if (!selectedVehicleGroup) {
        getExternalVehicleScheduleByDate({
          variables: {
            startDate: selectedDateRange.startDate,
            endDate: selectedDateRange.endDate,
            limit: 20,
            offset: 0,
            status: VehicleStatus.ACTIVE,
            searchText: searchVal,
            filterType: selectedVal,
            filterStatus: selectedStatus
          }
        })
      }
    }
  }

  const handleEventClick = (vehicleSchedule: any) => {
    if (vehicleSchedule.hasOwnProperty(vehicleStatuses.service)) {
      setOpen(true);
      setDialogType(DialogTypes.SERVICE_SUMMARY);
      setDialogData({
        service: vehicleSchedule.service,
        isSharedFleetEvent: vehicleSchedule.isSharedFleetEvent
      });
    } else if (vehicleSchedule.hasOwnProperty(vehicleStatuses.hold)) {
      setOpen(true);
      setDialogType(DialogTypes.HOLD_SUMMARY);
      setDialogData({
        hold: {
          ...vehicleSchedule.hold,
          vehicleId: vehicleSchedule.vehicleId,
          isSharedFleetEvent: vehicleSchedule.isSharedFleetEvent
        }
      });
    } else if (vehicleSchedule.type === VehicleEventType.BOOKING_SCHEDULE && vehicleSchedule.hasOwnProperty(vehicleStatuses.bookingSchedule)) {
      setOpen(true);
      setDialogType(getLocalizedBookingSummarySyntex(country));
      setDialogData({ bookingSchedule: vehicleSchedule.bookingSchedule, isSharedFleetEvent: vehicleSchedule.isSharedFleetEvent });
    } else if (vehicleSchedule.type === VehicleEventType.VEHICLE_PREPARATION_TIME) {
      setOpen(true)
      setDialogType(DialogTypes.VEHICLE_PREPARATION_TIME)
      setDialogData({
        startDate: d.fromISO(vehicleSchedule.bookingSchedule.endDate).toFormat(DateTimeFormat.DEFAULT_DATE_EXPANDED),
        endDate: d.fromISO(vehicleSchedule.bookingSchedule.endDate).plus({ minutes: userReducer.currentBranch.vehiclePreparationTime }).toFormat(DateTimeFormat.DEFAULT_DATE_EXPANDED)
      })
    }
  };

  const onCreateHold = (vehicleId: string, holdId?: string, holdInfo?: IHoldInput) => {
    if (holdId && holdInfo) {
      updateVehicleHold({
        variables: {
          vehicleId,
          holdId,
          holdInfo
        }
      }).then(({ data }) => {
        if (data && data.updateVehicleHold) {
          const eventIndex = vehicleEvents?.findIndex((event) => event.hold?.id === holdId)
          if (eventIndex >= 0) {
            const timeZoneOffset = d.now().offset
            const isPositiveOffset = timeZoneOffset >= 0;
            let startDate;
            let endDate;
            if (isPositiveOffset) {
              startDate = d.fromISO(holdInfo.startDate).plus({ minutes: timeZoneOffset }).toUTC().toISO()
              endDate = d.fromISO(holdInfo.endDate).plus({ minutes: timeZoneOffset }).toUTC().toISO()
            } else {
              startDate = d.fromISO(holdInfo.startDate).minus({ minutes: timeZoneOffset }).toUTC().toISO()
              endDate = d.fromISO(holdInfo.endDate).minus({ minutes: timeZoneOffset }).toUTC().toISO()
            }
            const newEventList = [
              ...vehicleEvents.slice(0, eventIndex),
              {
                ...vehicleEvents[eventIndex],
                start: startDate,
                end: endDate,
                hold: {
                  ...vehicleEvents[eventIndex].hold,
                  ...data.updateVehicleHold
                }
              },
              ...vehicleEvents.slice(eventIndex + 1)
            ];
            setVehicleEvents(newEventList);
          }
          snackbar({
            message: "Vehicle On Hold updated",
            variant: SnackBarVariant.SUCCESS
          });
          handleCloseDialog();
        }
      }).catch((error) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      });
    } else if (holdId && !holdInfo) {
      removeVehicleHold({
        variables: {
          vehicleId,
          holdId
        }
      }).then(() => {
        const eventIndex = vehicleEvents?.findIndex((event) => event.hold?.id === holdId)
        if (eventIndex >= 0) {
          const newEventList = [
            ...vehicleEvents.slice(0, eventIndex),
            ...vehicleEvents.slice(eventIndex + 1)
          ]
          setVehicleEvents(newEventList);
        }
        snackbar({
          message: "Vehicle Hold removed",
          variant: SnackBarVariant.SUCCESS
        });
        handleCloseDialog()
      }).catch((error) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      });
    } else {
      createVehicleHold({
        variables: {
          vehicleId,
          holdInfo
        }
      }).then(({ data }) => {
        if (data && data.createVehicleHold) {
          const timeZoneOffset = d.now().offset
          const isPositiveOffset = timeZoneOffset >= 0;
          let startDate;
          let endDate;
          if (isPositiveOffset) {
            startDate = d.fromISO(holdInfo?.startDate || "").plus({ minutes: timeZoneOffset }).toUTC().toISO()
            endDate = d.fromISO(holdInfo?.endDate || "").plus({ minutes: timeZoneOffset }).toUTC().toISO()
          } else {
            startDate = d.fromISO(holdInfo?.startDate || "").minus({ minutes: timeZoneOffset }).toUTC().toISO()
            endDate = d.fromISO(holdInfo?.endDate || "").minus({ minutes: timeZoneOffset }).toUTC().toISO()
          }
          const newEventList = [...vehicleEvents, {
            resourceId: vehicleId,
            allDay: false,
            hold: data.createVehicleHold,
            vehicleId,
            type: VehicleEventType.ON_HOLD,
            status: VehicleStatus.ON_HOLD,
            title: "",
            start: startDate,
            end: endDate,
            classNames: getVehicleStatusClass(VehicleStatus.ON_HOLD)
          }]
          setVehicleEvents(newEventList);
          snackbar({
            message: "Vehicle successfully put on hold",
            variant: SnackBarVariant.SUCCESS
          })
        }
        handleCloseDialog();
      }).catch((error: any) => {
        snackbar({
          message: formatGraphQLErrorMessage(error?.message),
          variant: SnackBarVariant.ERROR
        });
      })
    }
  };

  const startBooking = (id: string) => {
    handleCloseDialog();
    if (id) {
      startBookingMutation({
        variables: {
          id
        }
      });
    }
  };

  const confirmCancelBookingDialog = (id: string) => {
    if (id) {
      setBookingId(id)
    }
  };

  const cancelBooking = () => {
    handleCloseDialog();
    if (bookingId) {
      cancelBookingMutation({
        variables: {
          id: bookingId
        }
      }).then(() => {
        const updatedEvents = vehicleEvents.filter(event => {
          return event.bookingSchedule?.booking.id != bookingId;
        });
        if (updatedEvents) {
          setVehicleEvents(updatedEvents);
        }
        snackbar({
          message: "Booking has been Cancelled",
          variant: SnackBarVariant.SUCCESS
        });
      });
    }
  };

  const completeBooking = () => {
    handleCloseDialog();
    if (bookingId) {
      completeBookingMutation({
        variables: {
          id: bookingId
        }
      }).then(() => {
        const eventIndex = vehicleEvents?.findIndex((event) => event.bookingSchedule?.booking.id === bookingId)
        if (eventIndex >= 0) {
          const newEventList = [
            ...vehicleEvents.slice(0, eventIndex),
            ...vehicleEvents.slice(eventIndex + 1)
          ]
          setVehicleEvents(newEventList)
        }
        snackbar({
          message: "Booking has been Completed",
          variant: SnackBarVariant.SUCCESS
        });
      });
    }
  }

  const updateBookingSchedules = (values: IUpdateBookingSchedule) => {
    if (values.bookingSchedules) {
      updateBookingSchedule({
        variables: {
          data: values
        }
      }).then(() => {
        const modifiedEvents = vehicleEvents.map((event: IVehicleEvent) => {
          if (event.bookingSchedule?.id && values.bookingSchedules.includes(event.bookingSchedule.id)) {
            return {
              ...event,
              end: values.endDate,
              bookingSchedule: {
                ...event.bookingSchedule,
                booking: {
                  ...event.bookingSchedule.booking,
                  pickupDateTime: d.fromISO(values.startDate).toUTC().toISO(),
                  dropoffDateTime: d.fromISO(values.endDate).toUTC().toISO()
                }
              }
            };
          } else {
            return event;
          }
        })
        setVehicleEvents(modifiedEvents)
        return snackbar({
          message: "Booking schedule updated successfully",
          variant: SnackBarVariant.SUCCESS
        })
      }).catch(() => {
        return snackbar({
          message: "Error updating booking schedule",
          variant: SnackBarVariant.ERROR
        });
      });
    }
  };

  const shiftBookingSchedules = (values: IDropEvent) => {
    delete values.rateTypeDuration;
    delete values.rateTypeName;
    delete values.vehicleGropups;
    handleCloseDialog();
    shiftBookingSchedule({
      variables: {
        data: values
      }
    }).then((res) => {
      if (res && res.data && res.data.shiftBookingSchedule) {
        const updatedEvents = vehicleEvents.map((event: IVehicleEvent) => {
          if (event.bookingSchedule?.booking.id === values.bookingId && event.vehicleId === values.previousVehicle) {
            return {
              ...event,
              start: d.fromISO(values.startDate).toUTC().toISO(),
              end: d.fromISO(values.endDate).toUTC().toISO(),
              bookingSchedule: {
                ...event.bookingSchedule,
                id: res.data.shiftBookingSchedule.id,
                startDate: d.fromISO(values.startDate).toUTC().toISO(),
                endDate: d.fromISO(values.endDate).toUTC().toISO(),
                booking: {
                  ...event.bookingSchedule.booking,
                  pickupDateTime: d.fromISO(values.startDate).toUTC().toISO(),
                  dropoffDateTime: d.fromISO(values.endDate).toUTC().toISO()
                }
              },
              resourceId: values.currentVehicle,
              vehicleId: values.currentVehicle,
            };
          }
          return event;
        });
        setVehicleEvents(updatedEvents);
      }
      return snackbar({
        message: "Booking schedule updated successfully",
        variant: SnackBarVariant.SUCCESS
      }).catch((error) => {

        captureErrorException(error)

        setVehicleEvents([...vehicleEvents]);
        return snackbar({
          message: "Error updating booking schedule",
          variant: SnackBarVariant.ERROR
        });
      });
    });
  };

  const handleAutoCompleteOnChange = ((newVal: any, reason: any) => {
    setSearchVal("")
    clearSchedulerData()
    if (newVal && newVal.id) {
      setSelectedVehicleGroup(newVal.id)
      setSearchVal(newVal.name);
      getVehicleScheduleByDate({
        variables: {
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          limit: 20,
          offset: 0,
          status: VehicleStatus.ACTIVE,
          vehicleGroupId: newVal.id,
          searchText: "",
          filterType: selectedEventFilterValue,
          filterStatus: selectedEventFilterStatus
        }
      })
    }
  });

  const handleAutoCompleteInputChange = ((value: string, reason: any) => {
    clearSchedulerData()
    if (reason === "input") {
      setSelectedVehicleGroup("")
      if (!value) {
        setSearchVal("");
        clearFilters(true)
      } else {
        setSearchVal(value);
        getVehicleScheduleByDate({
          variables: {
            startDate: selectedDateRange.startDate,
            endDate: selectedDateRange.endDate,
            limit: 20,
            offset: 0,
            status: VehicleStatus.ACTIVE,
            searchText: value,
            filterType: selectedEventFilterValue,
            filterStatus: selectedEventFilterStatus
          }
        })
        getExternalVehicleScheduleByDate({
          variables: {
            startDate: selectedDateRange.startDate,
            endDate: selectedDateRange.endDate,
            limit: 20,
            offset: 0,
            status: VehicleStatus.ACTIVE,
            searchText: value,
            filterType: selectedEventFilterValue,
            filterStatus: selectedEventFilterStatus
          }
        })

      }
    }
  });

  const replaceVehicleForInProgressBooking = (data: IVehicleReplacementArgs) => {
    const replacedEvent = vehicleEvents.findIndex(e => e.bookingSchedule?.booking.id === data.bookingId)
    let customerName = "";
    if (replacedEvent >= 0) {
      const event = vehicleEvents[replacedEvent]
      if (event.bookingSchedule?.booking.customer) {
        customerName = `${event.bookingSchedule?.booking.customer.firstName.charAt(0)}.${event.bookingSchedule?.booking.customer.lastName}` || ""
      }
      if (event.bookingSchedule?.booking.businessCustomer) {
        customerName = event.bookingSchedule?.booking.businessCustomer.businessName
      }
    }
    if (data) {
      const { startDate, endDate, ...rest } = data
      replaceInProgressBookingVehicle({
        variables: {
          vehicleReplacementInput: rest
        }
      }).then((res) => {
        if (res && res.data && res.data.replaceVehicleForInProgressBooking) {
          const updateEvents: IVehicleEvent[] = vehicleEvents
          if (!data.putVehicleOnHold) {
            const newOldEvent = {
              resourceId: data.previousVehicleId,
              allDay: false,
              isSharedFleetEvent: false,
              type: VehicleEventType.ON_HOLD,
              status: VehicleStatus.ON_HOLD,
              vehicleId: data.previousVehicleId,
              title: res.data.replaceVehicleForInProgressBooking.tba ? " *TBA" : "", //bookingSchedule.booking.customerType === "individual" ?
              //`${bookingSchedule.booking.customer.firstName.charAt(0)}.${bookingSchedule.booking.customer.lastName}` :
              //bookingSchedule.booking.businessCustomer.businessName,
              start: d.fromISO(startDate || "").toUTC().toISO(),
              end: d.fromISO(endDate || "").toUTC().toISO(),
              classNames: getVehicleStatusClass(VehicleStatus.ON_HOLD, false)
            }
            updateEvents.push(newOldEvent)
          }
          const newEvent = {
            resourceId: res.data.replaceVehicleForInProgressBooking.vehicle,
            allDay: false,
            bookingSchedule: res.data.replaceVehicleForInProgressBooking,
            isSharedFleetEvent: false,
            type: VehicleEventType.BOOKING_SCHEDULE,
            status: VehicleStatus.CONFIRMED,
            vehicleId: res.data.replaceVehicleForInProgressBooking.vehicle,
            title: `${customerName}${res.data.replaceVehicleForInProgressBooking.tba ? " *TBA" : ""}`,
            start: d.fromISO(res.data.replaceVehicleForInProgressBooking.startDate).toUTC().toISO(),
            end: d.fromISO(res.data.replaceVehicleForInProgressBooking.endDate).toUTC().toISO(),
            classNames: getVehicleStatusClass(VehicleStatus.IN_PROGRESS, false)
          }
          updateEvents.push(newEvent)
          const eventIndex = updateEvents?.findIndex((event) => event.bookingSchedule?.booking.id === res.data.replaceVehicleForInProgressBooking.booking)
          if (eventIndex >= 0) {
            const newEventList = [
              ...updateEvents.slice(0, eventIndex),
              {
                ...updateEvents[eventIndex],
                end: d.fromISO(res.data.replaceVehicleForInProgressBooking.startDate).toUTC().toISO(),

              },
              ...updateEvents.slice(eventIndex + 1)
            ];
            setVehicleEvents(newEventList);
          }
          setOpen(false)
          return snackbar({
            message: "Replacement for vehicle Confirmed.",
            variant: SnackBarVariant.SUCCESS
          })
        }
      }).catch((error) => {
        return snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      });
    }
  }

  const replaceVehicleForInProgressSubscription = (data: IVehicleSubscriptionReplacementArgs) => {
    const replacedEvent = vehicleEvents.findIndex(e => e.bookingSchedule?.booking.id === data.bookingId)
    let customerName = "";
    if (replacedEvent >= 0) {
      const event = vehicleEvents[replacedEvent]
      if (event.bookingSchedule?.booking.customer) {
        customerName = `${event.bookingSchedule?.booking.customer.firstName.charAt(0)}.${event.bookingSchedule?.booking.customer.lastName}` || ""
      }
      if (event.bookingSchedule?.booking.businessCustomer) {
        customerName = event.bookingSchedule?.booking.businessCustomer.businessName
      }
    }
    if (data) {
      const { startDate, endDate, ...rest } = data
      replaceInProgressSubscriptionVehicle({
        variables: {
          replacementInput: rest
        }
      }).then((res) => {
        if (res && res.data && res.data.replaceSubscriptionVehicleForInProgressBooking) {
          const updateEvents: IVehicleEvent[] = vehicleEvents
          if (!data.putVehicleOnHold) {
            const newOldEvent = {
              resourceId: data.previousVehicleId,
              allDay: false,
              isSharedFleetEvent: false,
              type: VehicleEventType.ON_HOLD,
              status: VehicleStatus.ON_HOLD,
              vehicleId: data.previousVehicleId,
              title: res.data.replaceSubscriptionVehicleForInProgressBooking.tba ? " *TBA" : "", //bookingSchedule.booking.customerType === "individual" ?
              //`${bookingSchedule.booking.customer.firstName.charAt(0)}.${bookingSchedule.booking.customer.lastName}` :
              //bookingSchedule.booking.businessCustomer.businessName,
              start: d.fromISO(startDate || "").toUTC().toISO(),
              end: d.fromISO(endDate || "").toUTC().toISO(),
              classNames: getVehicleStatusClass(VehicleStatus.ON_HOLD, false)
            }
            updateEvents.push(newOldEvent)
          }
          const newEvent = {
            resourceId: res.data.replaceSubscriptionVehicleForInProgressBooking.vehicle,
            allDay: false,
            bookingSchedule: res.data.replaceSubscriptionVehicleForInProgressBooking,
            isSharedFleetEvent: false,
            type: VehicleEventType.BOOKING_SCHEDULE,
            status: VehicleStatus.CONFIRMED,
            vehicleId: res.data.replaceSubscriptionVehicleForInProgressBooking.vehicle,
            title: `${customerName}${res.data.replaceSubscriptionVehicleForInProgressBooking.tba ? " *TBA" : ""}`,
            start: d.fromISO(res.data.replaceSubscriptionVehicleForInProgressBooking.startDate).toUTC().toISO(),
            end: d.fromISO(res.data.replaceSubscriptionVehicleForInProgressBooking.endDate).toUTC().toISO(),
            classNames: getVehicleStatusClass(VehicleStatus.IN_PROGRESS, false)
          }
          updateEvents.push(newEvent)
          const eventIndex = updateEvents?.findIndex((event) => event.bookingSchedule?.booking.id === res.data.replaceSubscriptionVehicleForInProgressBooking.booking)
          if (eventIndex >= 0) {
            const newEventList = [
              ...updateEvents.slice(0, eventIndex),
              {
                ...updateEvents[eventIndex],
                end: d.fromISO(res.data.replaceSubscriptionVehicleForInProgressBooking.startDate).toUTC().toISO(),

              },
              ...updateEvents.slice(eventIndex + 1)
            ];
            setVehicleEvents(newEventList);
          }
          setOpen(false)
        }
        return snackbar({
          message: "Replacement of vehicle for subscription is Confirmed.",
          variant: SnackBarVariant.SUCCESS
        }).catch((error: any) => {
          return snackbar({
            message: formatGraphQLErrorMessage(error?.message),
            variant: SnackBarVariant.ERROR
          });
        });
      })
    }
  }

  const reRenderEvents = (startStr: string, endStr: string) => {
    clearSchedulerData()
    let startDate = d.fromISO(startStr).toUTC().toISO()
    let endDate = d.fromISO(endStr).toUTC().toISO()
    setSelectedDateRange({
      startDate,
      endDate
    })
    getVehicleScheduleByDate({
      variables: {
        startDate,
        endDate,
        limit: eventLimitForViewChange,
        offset: 0,
        status: VehicleStatus.ACTIVE,
        vehicleGroupId: selectedVehicleGroup,
        searchText: searchVal,
        filterType: selectedEventFilterValue,
        filterStatus: selectedEventFilterStatus
      }
    })
    if (!selectedVehicleGroup || selectedEventFilterValue === "ghostVehicle") {
      getExternalVehicleScheduleByDate({
        variables: {
          startDate,
          endDate,
          limit: externalEventLimitForViewChange,
          offset: 0,
          status: VehicleStatus.ACTIVE,
          searchText: searchVal,
          filterType: selectedEventFilterValue,
          filterStatus: selectedEventFilterStatus
        }
      })
    }
  }

  const clearSchedulerData = () => {
    setVehicleEvents([]);
    setActiveResources([]);
    setResources([])
  }

  const clearFilters = (isLoadData?: boolean) => {
    setVehicleEvents([]);
    setActiveResources([]);
    setResources([])
    setSelectedEventFilterValue("")
    setSelectedEventFilterStatus("")
    setSelectedEventFilterValue("")
    setSelectedEventFilterStatus("")
    setSearchVal("");
    setSelectedFilterEvent("")
    setSelectedVehicleGroup("")
    setEventOffset(0)
    setExternalEventOffset(0)
    setHasMore(true)
    setIsTba(false)
    setIsVirtualVehicleToggle(false)
    setHasMoreLoadExternalVehicleEvents(true)
    setHasMoreLoadVehicleEvents(true)
    if (isLoadData) {
      getVehicleScheduleByDate({
        variables: {
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          limit: 20,
          offset: 0,
          status: VehicleStatus.ACTIVE
        }
      })
      getExternalVehicleScheduleByDate({
        variables: {
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          limit: 20,
          offset: 0,
          status: VehicleStatus.ACTIVE
        }
      })
    }
  }

  return (
    <>
      <Grid container item xs={12} spacing={1}>
        <Grid container item className="scheduler-heading" xs={6}>
          <Grid item >
            <Typography variant={"h2"} style={{ fontSize: "1.5em" }} color={"primary"}>{"Scheduler"}</Typography>
          </Grid>
          <Box pl={2}></Box>

          <Box pl={2}></Box>
          <Grid item>
            <Tooltip title={<Typography variant="body1"><strong>Note</strong>: The vehicle preparation time is not shown on this scheduler. Expected that Rental Operator is aware of that before making another booking. </Typography>}>
              <IconButton style={{ padding: 0 }} aria-label="info">
                <InfoIcon className="info-icon-vs" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <div className={classes.filterToolbar}>
          <div style={{ display: "flex" }}>
            <div className="select-date-container">
              <label htmlFor="selectGoToDate" role="button">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 109.3 86.1" width={80} height={55}>
                  <rect x="3.4" y="3.1" width="103.5" height="80.2" fill="#fff" stroke="#58595b" stroke-miterlimit="10" stroke-width=".5" />
                  <rect x="3.7" y="3.3" width="103" height="23.3" fill="#002F6E" stroke-width="0" />
                  <text transform="translate(40 19.8)" fill="#fff" font-family="monospace" font-size="14" font-weight="700"><tspan x="0" y="0">{d.fromISO(filterDate).toFormat("MMM")}</tspan></text>
                  <text transform="translate(18.6 74.1)" fill="#000" font-family="NunitoSans-Bold, &apos;Nunito Sans&apos;" font-size="57" font-weight="700"><tspan x="0" y="0">{d.fromISO(filterDate).toFormat("dd")}</tspan></text>
                </svg>
              </label>
              <div className="select-flat-picker">
                <FlatPickerBar
                  enableTime={false}
                  handleDateChange={(value: Date) => {
                    if (value) {
                      const timeZoneOffset = d.now().offset
                      const isPositiveOffset = timeZoneOffset >= 0;
                      let dateToFilter = ""
                      if (isPositiveOffset) {
                        dateToFilter = d.fromJSDate(value).plus({ minutes: timeZoneOffset }).toUTC().toISO()
                      } else {
                        d.fromJSDate(value).minus({ minutes: timeZoneOffset }).toUTC().toISO()
                      }
                      const calendarApi = fullCalendar && fullCalendar?.current && fullCalendar?.current.getApi();
                      if (calendarApi) {
                        calendarApi.gotoDate(dateToFilter);
                        calendarApi.unselect();
                      }
                      setFilterDate(d.fromISO(dateToFilter).toUTC().toISO())
                    }
                  }}
                  identifier={"selectGoToDate"}
                  name={"selectGoToDate"}
                  value={getLocalizedDateFormat(country, filterDate, DATE_TYPE.CONDENSED)}
                  render={
                    ({ defaultValue, value, ...props }: any, ref: any) => (
                      <input id="selectGoToDate" {...props} defaultValue={defaultValue} ref={ref} style={{
                        border: 0,
                        outline: "none",
                        cursor: "pointer",
                        opacity: 0
                      }} />
                    )
                  }
                  country={country}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="filter-search-box">
              <Autocomplete
                freeSolo
                id="free-solo-fc-search"
                className="filter-vehicle-group-licence-plate"
                disableClearable
                options={vehicleGroups}
                getOptionLabel={(option: any) =>
                  option.name
                }
                fullWidth
                value={selectedVehicleGroup}
                inputValue={searchVal}
                onChange={(_: any, newVal: any, reason) => {
                  handleAutoCompleteOnChange(newVal, reason);
                }}
                onInputChange={(_, value, reason) => {
                  handleAutoCompleteInputChange(value, reason);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={"Reg., make, model, vehicle group"}
                    style={{ maxWidth: 300, width: 200 }}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      startAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
                style={{ background: "#fff" }}
              />
            </div>
            <div className="filter-event-dropdown">
              <FormControl variant="outlined" fullWidth>
                <Select
                  labelId="vg-simple-select-label"
                  id="select-event-filter"
                  value={selectedEventFilter}
                  onChange={(e: SelectChangeEvent<typeof selectedEventFilter>) => {
                    clearSchedulerData()
                    setIsTba(false)
                    setIsVirtualVehicleToggle(false)
                    setEventLimitForViewChange(20)
                    setExternalEventLimitForViewChange(20)
                    const filterVal = e.target.value
                    let selectedVal
                    let selectedStatus
                    if (filterVal !== VehicleEventType.IN_SERVICE && filterVal !== VehicleEventType.ON_HOLD) {
                      setSelectedEventFilterValue("bookingSchedule")
                      setSelectedEventFilterStatus(filterVal)
                      selectedVal = "bookingSchedule"
                      selectedStatus = filterVal
                    }
                    if (filterVal === VehicleEventType.IN_SERVICE) {
                      setSelectedEventFilterValue("service")
                      setSelectedEventFilterStatus("")
                      selectedVal = "service"
                      selectedStatus = ""
                    }
                    if (filterVal === VehicleEventType.ON_HOLD) {
                      setSelectedEventFilterValue("hold")
                      setSelectedEventFilterStatus("")
                      selectedVal = "hold"
                      selectedStatus = ""
                    }
                    setSelectedFilterEvent(e.target.value)
                    if (selectedVal) {
                      getVehicleScheduleByDate({
                        variables: {
                          startDate: selectedDateRange.startDate,
                          endDate: selectedDateRange.endDate,
                          limit: 20,
                          offset: 0,
                          status: VehicleStatus.ACTIVE,
                          vehicleGroupId: selectedVehicleGroup,
                          searchText: selectedVehicleGroup ? "" : searchVal,
                          filterType: selectedVal,
                          filterStatus: selectedStatus
                        }
                      })
                      if (!selectedVehicleGroup) {
                        getExternalVehicleScheduleByDate({
                          variables: {
                            startDate: selectedDateRange.startDate,
                            endDate: selectedDateRange.endDate,
                            limit: 20,
                            offset: 0,
                            status: VehicleStatus.ACTIVE,
                            searchText: searchVal,
                            filterType: selectedVal,
                            filterStatus: selectedStatus
                          }
                        })
                      }
                    }
                  }}
                  displayEmpty={true}
                  name="select-event"
                  className={classes.selectEvent}
                >
                  <MenuItem disabled value="">
                    Select Event
                  </MenuItem>
                  {
                    filters.map((item: IFilter, index: number) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          <ListItemIcon style={{ minWidth: 30 }}>
                            <CheckBoxOutlineBlankIcon color="primary" style={{ fill: item.color, background: item.color, fontSize: 16, borderRadius: 4 }} />
                          </ListItemIcon>
                          <ListItemText primary={item.label} />
                        </MenuItem>
                      );
                    })
                  }

                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: 'center' }}>
            <div className="filter-tba-button">
              <Tooltip title="TBA">
                <IconButton
                  style={{ padding: 5 }}
                  className={isTba ? classes.filterIcon : "none"}
                  onClick={(e: any) => {
                    if (!isTba) {
                      setIsTba(false)
                      setIsVirtualVehicleToggle(false)
                      handleSwitchChange()
                    } else {
                      clearFilters(true)
                    }
                  }}
                  role="checkbox">
                  <img src={TbaIcon} height={35} width={35} alt="tba" />
                </IconButton>
              </Tooltip>
            </div>
            <div className="filter-virtual-vehicles">
              <Tooltip title="Virtual Vehicles">
                <IconButton
                  style={{ padding: 5 }}
                  className={isVirtualVehicleToggle ? classes.filterIcon : "none"}
                  onClick={() => {
                    if (!isVirtualVehicleToggle) {
                      handleVirtaulVehicleChange(true)
                    } else {
                      handleVirtaulVehicleChange(false)
                    }
                  }}
                  role="checkbox">
                  <img src={VirtualVehicleIcon} height={35} width={35} alt="tba" />
                </IconButton>
              </Tooltip>
            </div>
            <div className="filter-reset-button">
              <Button
                style={{ padding: "7px 0px 7px" }}
                variant="outlined"
                onClick={() => {
                  clearFilters(true)
                }}
                disabled={searchVal || selectedVehicleGroup || selectedEventFilter || isVirtualVehicleToggle ? false : true}
              >
                <RefreshIcon />
              </Button>
            </div>
          </div>
        </div>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          {
            fullCalendarScrollAppId &&
            <InfiniteScroll
              dataLength={activeResources.length}
              next={() => {
                if (activeResources.length > 0) {
                  if (hasMoreLoadVehicleEvents) {
                    const offsetVal = eventOffset + 20;
                    setEventLimitForViewChange(prev => prev + 20)
                    setEventOffset(offsetVal)
                    getVehicleScheduleByDate({
                      variables: {
                        startDate: selectedDateRange.startDate,
                        endDate: selectedDateRange.endDate,
                        limit: 20,
                        offset: offsetVal,
                        status: VehicleStatus.ACTIVE,
                        vehicleGroupId: selectedVehicleGroup,
                        searchText: searchVal,
                        filterType: selectedEventFilterValue,
                        filterStatus: selectedEventFilterStatus
                      }
                    })
                  }
                  if (hasMoreLoadExternalVehicleEvents) {
                    const externalOffsetVal = externalEventOffset + 20
                    setExternalEventLimitForViewChange(prev => prev + 20)
                    setExternalEventOffset(prev => prev + 20)
                    getExternalVehicleScheduleByDate({
                      variables: {
                        startDate: selectedDateRange.startDate,
                        endDate: selectedDateRange.endDate,
                        limit: 20,
                        offset: externalOffsetVal,
                        status: VehicleStatus.ACTIVE,
                        searchText: searchVal,
                        filterType: selectedEventFilterValue,
                        filterStatus: selectedEventFilterStatus
                      }
                    })
                  }
                }
              }}
              scrollThreshold={0.9}
              inverse={false}
              hasMore={hasMore}
              loader={<></>}
              scrollableTarget={fullCalendarScrollAppId}
            >
              <div>
              </div>
            </InfiniteScroll>
          }
          <FullCalendar
            timeZone={userReducer.currentBranch.timeZone}
            schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_LICENCE_KEY}
            plugins={[resourceTimelinePlugin, interactionPlugin]}
            ref={fullCalendar}
            viewClassNames={"fc-containerdateToFilter"}
            headerToolbar={
              {
                start: "today prev next",
                center: "title",
                end: "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,resourceTimelineThreeMonth"
              }
            }
            windowResizeDelay={250}
            firstDay={1}
            displayEventTime={false}
            height={800}
            initialView="resourceTimelineWeek"
            resources={activeResources ? activeResources : []}
            resourceAreaWidth={"20%"}
            editable={true}
            locale="en"
            events={vehicleEvents ? vehicleEvents : []}
            eventClick={
              (info: IClickInfo) => {
                const vehicleSchedule = info.event._def.extendedProps;
                handleEventClick(vehicleSchedule)
              }
            }
            selectable={true}
            select={({ start, end, startStr, endStr, resource, view }) => {
              const isSingleDaySelected = d.fromJSDate(start).hasSame(d.fromJSDate(end), "day");
              if (resource?._resource.extendedProps.fleet.activeTransferMovement) {
                setDialogType(DialogTypes.VEHICLE_IN_TRANSFER_PROCESS);
                setOpen(true);
                return;
              }
              const eventExist = vehicleEvents.find(event => {
                if (resource?._resource.id === event.vehicleId &&
                  (view.type !== "resourceTimelineDay" &&
                    (
                      (d.fromISO(d.fromJSDate(start).toUTC().toISO()).toUTC().toISO() >= d.fromISO(event.start).toUTC().toISO() &&
                        d.fromISO(d.fromJSDate(start).toUTC().toISO()).toUTC().toISO() <= d.fromISO(event.end).toUTC().toISO()) ||
                      d.fromISO(d.fromJSDate(start).toUTC().toISO())
                        .hasSame(d.fromISO(event.start), "day")
                    )
                  )
                ) {
                  return event;
                }
                if (resource?._resource.id === event.vehicleId &&
                  (
                    view.type === "resourceTimeLineDay" ||
                    (d.fromISO(d.fromJSDate(start).toUTC().toISO()).toUTC().toISO() >= d.fromISO(event.start).toUTC().toISO() &&
                      d.fromISO(d.fromJSDate(start).toUTC().toISO()).toUTC().toISO() <= d.fromISO(event.end).toUTC().toISO())
                  )
                ) {
                  return event;
                }
              });
              if (eventExist) {
                setDialogType(DialogTypes.EVENT_EXISTS_ALREADY_ERROR);
                setOpen(true);
              } else {
                if (d.fromJSDate(start).toUTC().toISO() > d.now().toUTC().toISO()) {
                  setOpen(true)
                  setDialogType(DialogTypes.NEW_EVENT);
                  if (view.type === "resourceTimelineDay") {
                    setDialogData({
                      startDate: d.fromISO(startStr).toUTC().toISO(),
                      endDate: d.fromISO(endStr).toUTC().toISO(),
                      vehicleId: resource?._resource.extendedProps.fleet.id,
                      resource: resource?._resource.extendedProps.fleet
                    });
                  } else {
                    setDialogData({
                      startDate: d.fromISO(startStr).plus({ minutes: 480 }).toUTC().toISO(),
                      endDate: d.fromISO(endStr).plus({ minutes: isSingleDaySelected ? 1425 : 480 }).toUTC().toISO(),
                      vehicleId: resource?._resource.extendedProps.fleet.id,
                      resource: resource?._resource.extendedProps.fleet
                    });
                  }
                };
              }
              if (d.now().toUTC().toISO() < d.fromJSDate(start).toUTC().toISO()) {
                return false;
              }
              if (d.now().startOf("day").toUTC().toISO() === d.fromJSDate(start).startOf("day").toUTC().toISO() && (view.type === "resourceTimelineMonth" || view.type === "resourceTimelineWeek")) {
                setOpen(true);
                setDialogType(DialogTypes.SAME_DAY_CREATE_EVENT);
              }
              if (resource?._resource.extendedProps.fleet.status === VehicleStatus.DEACTIVATED) {
                setOpen(true)
                setDialogType(DialogTypes.DEACTIVATED_VEHICLE);
                setDialogData({ vehicleId: resource?._resource.extendedProps.fleet.id, isSharedFleetEvent: resource?._resource.extendedProps.isSharedFleetEvent });
              }
            }}
            eventResize={({ event, startDelta, endDelta }) => {
              const { extendedProps } = event;
              const isBookingSchedule = extendedProps.type.indexOf(VehicleEventType.BOOKING_SCHEDULE) >= 0
              if (extendedProps.bookingSchedule.booking.currentBookingSchedules.length > 1) {
                setVehicleEvents([...vehicleEvents]);
                return snackbar({
                  message: "Booking with multiple vehicles extension is not allowed at this moment.",
                  variant: SnackBarVariant.ERROR
                });
              }
              if (isBookingSchedule) {
                setDialogData({
                  ...dialogData,
                  startDate: event.start?.toISOString(),
                  endDate: event.end?.toISOString(),
                  bookingId: event.extendedProps.bookingSchedule.booking.id
                });
                if (extendedProps.status === BookingStatus.IN_PROGRESS && !startDelta.days && endDelta.days > 0) {
                  setDialogType(DialogTypes.EXTEND_BOOKING);
                  if (extendedProps.bookingSchedule.booking.currentBookingSchedules.length > 1) {
                    setDialogType(DialogTypes.EXTEND_MULTIPLE_EVENT_ERROR);
                  }
                } else {
                  setVehicleEvents([...vehicleEvents]);
                  return snackbar({
                    message: "Event modification is not allowed at this moment.",
                    variant: SnackBarVariant.ERROR
                  });
                }
                setOpen(true);
              } else {
                setVehicleEvents([...vehicleEvents]);
                setOpen(true);
                setDialogType(DialogTypes.EXTEND_EVENT_ERROR);
              }
            }}
            eventOverlap={() => {
              return false;
            }}
            droppable={true}
            eventResourceEditable={true}
            eventDrop={(info: any) => {
              const { extendedProps } = info.event;
              if (info.view.type === "resourceTimelineDay") {
                setOpen(true)
                setDialogType(DialogTypes.DAY_VIEW_DRAG_DROP_ERROR)
              } else {
                const isBookingSchedule = extendedProps.type.indexOf(VehicleEventType.BOOKING_SCHEDULE) >= 0;
                const isInProgressBooking = extendedProps.status.indexOf(VehicleStatus.IN_PROGRESS) >= 0 || extendedProps.status.indexOf(VehicleStatus.COMPLETED) >= 0;
                if (!isBookingSchedule) {
                  setVehicleEvents([...vehicleEvents]);
                  setOpen(true);
                  setDialogType(DialogTypes.DRAG_DROP_EVENT_ERROR);
                }
                if (isInProgressBooking) {
                  setVehicleEvents([...vehicleEvents]);
                  setOpen(true);
                  setDialogType(DialogTypes.DRAG_IN_PROGRESS_ERROR);
                }
                if (isBookingSchedule && info.newResource && info.oldResource && !isInProgressBooking) {
                  setVehicleEvents([...vehicleEvents]);
                  return snackbar({
                    message: "Vertical drag and drop of booking event is not allowed at this moment.",
                    variant: SnackBarVariant.ERROR
                  });
                }
                if (isBookingSchedule &&
                  !info.newResource &&
                  info.event.start &&
                  info.event.end &&
                  !isInProgressBooking
                ) {
                  const dropStart = getLocalizedDateFormat(country, info.event.start, DATE_TYPE.CONDENSED)
                  const eventStart = getLocalizedDateFormat(country, info.event._def.extendedProps.bookingSchedule.startDate, DATE_TYPE.CONDENSED);
                  if (!(d.fromISO(dropStart).day <= d.fromISO(eventStart).day) && info.event._def.extendedProps.bookingSchedule.vehicle) {
                    const bookingSchedule = info.event._def.extendedProps.bookingSchedule;
                    setOpen(true);
                    setDialogType(DialogTypes.CONFIRM_BOOKING_UPDATE);
                    setDialogData({
                      dropEvent: {
                        ...dialogData.dropEvent,
                        startDate: d.fromISO(info.event.start).toUTC().toISO(),
                        endDate: d.fromISO(info.event.end).toUTC().toISO(),
                        bookingId: bookingSchedule.booking.id,
                        bookingScheduleId: bookingSchedule.id,
                        previousVehicle: bookingSchedule.vehicle.id,
                        currentVehicle: bookingSchedule.vehicle.id,
                        vehicleGroupId: bookingSchedule.vehicleGroup
                      }
                    });
                  } else {
                    setVehicleEvents([...vehicleEvents]);
                    setOpen(true);
                    setDialogType(DialogTypes.DROP_ERROR);
                  }
                }
              }

            }}
            resourceOrder={".fleet.licencePlate"}
            resourceAreaColumns={[
              {
                field: "registration",
                headerContent: "REG. NO. ",
                cellContent: (arg: any) => {
                  const fleet: IVehicle = arg.resource._resource.extendedProps.fleet;
                  return (
                    <Typography variant={"body1"}>{fleet.licencePlate.toUpperCase()}</Typography>
                  );
                }
              },
              {
                field: "model",
                headerContent: "MODEL",
                cellContent: (arg: any) => {
                  const fleet: IVehicle = arg.resource._resource.extendedProps.fleet;
                  return (
                    <Typography variant={"body1"}>{fleet.model.toUpperCase()}</Typography>
                  );
                }
              },
              {
                field: "current location",
                headerContent: "CURRENT LOCATION",
                cellContent: (arg: any) => {
                  const fleet: IVehicle = arg.resource._resource.extendedProps.fleet;
                  return (
                    <Typography style={{ textTransform: "capitalize" }} variant={"body1"}>
                      {fleet.currentServiceLocation?.name}
                    </Typography>
                  );
                }
              },
            ]}
            slotLabelContent={({ text }) => {
              const dayNumber = text.split(" ")[0];
              const dayText = text.split(" ")[1];
              return (
                <div className={"fc-resource-column-days"}>
                  <span className={"fc-resource-day-title"}>{dayText}</span>
                  <br />
                  <span>{dayNumber}</span>
                </div>
              );
            }}
            datesSet={({ startStr, endStr, view, }) => {
              if (view.type === "resourceTimelineWeek" && d.now().toUTC().toISO() >= d.fromISO(startStr).toUTC().toISO() && d.now().toUTC().toISO() <= d.fromISO(endStr).toUTC().toISO()) {
                setFilterDate(d.fromISO(startStr).plus({ days: d.now().weekday - 1 }).toUTC().toISO())
              } else {
                setFilterDate(startStr)
              }
              reRenderEvents(startStr, endStr)
            }}
            views={{
              resourceTimelineMonth: {
                type: "resourceTimeline",
                duration: { month: 1 },
                buttonText: "Month",
              },
              resourceTimelineWeek: {
                type: "resourceTimeline",
                duration: { weeks: 1 },
                slotDuration: { days: 1 },
                buttonText: "Week",
              },
              resourceTimelineDay: {
                type: "resourceTimeline",
                duration: { days: 1 },
                slotDuration: "01:00:00",
                buttonText: "Day",
                titleFormat: {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                  weekday: "long"
                }
              },
              resourceTimelineThreeMonth: {
                type: "resourceTimeline",
                duration: { months: 3 },
                buttonText: "3 Months"
              }
            }}
          />
        </Grid>
        {
          open && (
            <SchedulerDialog
              openDialog={open}
              handleClose={handleCloseDialog}
              type={dialogType}
              setDialogType={setDialogType}
              dialogData={dialogData}
              onCreateHold={onCreateHold}
              startBooking={startBooking}
              confirmCancelBookingDialog={confirmCancelBookingDialog}
              cancelBooking={cancelBooking}
              completeBooking={completeBooking}
              updateBookingSchedules={updateBookingSchedules}
              shiftBookingSchedule={shiftBookingSchedules}
              setDialogData={setDialogData}
              replaceVehicleForInProgressBooking={replaceVehicleForInProgressBooking}
              replaceVehicleForInProgressSubscription={replaceVehicleForInProgressSubscription}
              replaceVehicleLoading={replaceVehicleLoading}
              replaceSubscriptionVehicleLoading={replaceSubscriptionVehicleLoading}
              reloadScheduler={reloadScheduler}
            />
          )
        }
      </Grid>
      {(vehicleSchedulesLoading || externalVehicleSchedulesLoading) && (
        <Backdrop invisible={true} className={classes.backdrop} open={(vehicleSchedulesLoading || externalVehicleSchedulesLoading)} >
          <CircularProgress />
        </Backdrop>
      )
      }
    </>
  );
};

export default VehicleScheduler;


