import "./index.scss";
import { useLazyQuery } from "@apollo/client";
import {
  alpha,
  AppBar,
  Theme,
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Hidden,
  InputBase,
  Typography,
  Button
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addTableState } from "../../../../../actions/tableState/actions";
import { GET_ORGANISATIONS } from "../../../../../graphql/organisation/getOrganisationQuery";
import { ITable, TableNames } from "../../../../../reducers/tableState/types";
import { IAppState } from "../../../../../store";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import { formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from "../../../../common/utils";
import { UserRoles } from "../../../../hoc/Authorization";
import OrganisationStructureVirtualScroller from "./OrganisationStructureVirtualScroller";
import { SelectableRows } from "../../../../common/NuvvenTable/types";
import { getLocalizedOrganisationSyntex } from "../../../../../utils/localized.syntex";
import { ApolloError } from "@apollo/client";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerMargin: {
      marginBottom: "120px"
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(100vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
      marginBottom: theme.spacing(1)
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    },
    verified: {
      fill: "green"
    }
  })
);

export const columns: any = [
  {
    label: "ID",
    name: "id",
    options: {
      display: false,
      filter: false,
      viewColumns: false
    }
  },
  {
    label: "Organisation Name",
    name: "name",
  },
  {
    label: "Country",
    name: "countryName"
  },
  {
    label: "Email",
    name: "emailId"
  },
  {
    label: "Phone",
    name: "contact"
  },
  {
    label: "No. Of Branches",
    name: "totalBranches"
  },
  {
    label: "Currency",
    name: "currency"
  }
];


export const VerifiedFormatter = (value: any) => {
  const classes = useStyles();
  return value.value && <CheckCircleOutlineIcon className={classes.verified} />;
};

const OrganisationStructure: React.FC = () => {
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const navigate = useNavigate();

  const organisationsTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.organisations
  );
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [rows, setRows] = useState<any[]>([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [organisationsTable, setOrganisationsTable] = useState<ITable>();

  const [getOrganisations, { loading, data }] = useLazyQuery(GET_ORGANISATIONS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (organisationsTableState) {
      setOrganisationsTable(organisationsTableState);
      let sortedOrder = returnSortedOrder(organisationsTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, organisationsTableState, sortedOrder)
      }
    }
  }, [organisationsTableState])

  useEffect(() => {
    if (userState && userState.id) {
      getOrganisations();
      if (userState.currentOrganisation.address.country === "United States") {
        columns[1].label = "Location Name"
      }
    }
  }, [userState]);

  useEffect(() => {
    if (data && data.organisations) {
      const reshapRows = data.organisations.map((organisation: any) => {
        return {
          ...organisation,
          contact: organisation.phoneNumber.phone,
          countryName: organisation.address.country,
          totalBranches: organisation.branches.length,
        };
      })
      setRows(reshapRows);
    }
  }, [data]);

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    onRowClick: (rowData: string[]) => {
      if (rowData && rowData.length) {
        const rowClicked = rows.find(
          (row) => row.id === rowData[0]
        );
        if (rowClicked) {
          navigate(`/update-organisation?organisation=${rowClicked.id}&step=0`);
        }
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      dispatch(
        addTableState({
          tableName: TableNames.ORGANISATIONS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      organisationsTable && organisationsTable.rowsPerPage
        ? organisationsTable.rowsPerPage
        : 10,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.ORGANISATIONS,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: () => {
      if (organisationsTable && sortOrder) {
        setTableSortOrder(columns, organisationsTable, sortOrder);
      }
    }
  };

  const classes = useStyles();

  //store search data for mobile search
  const [search, setSearch] = useState<RegExp>(new RegExp('', "i"));
  const onChange = (event: { target: { value: any; }; }) => {
    setSearch(new RegExp(event.target.value, "i"));
  };


  return (
    <div>
      <Grid container spacing={2}>
        <CssBaseline />
        <Grid container item xs={6} alignItems="center">
          <Typography variant="h1" color="primary">
            &nbsp;{`${getLocalizedOrganisationSyntex(country)} Structure`}
          </Typography>
        </Grid>
        <Hidden smDown>
          {userState && (userState.role === UserRoles.ADMIN || userState.role === UserRoles.SUPER_ADMIN) && (
            <Grid container item xs={6} justifyContent="flex-end">
              <Fab
                variant="extended"
                size="medium"
                aria-label="Create"
                className="createButton"
                onClick={() => {
                  navigate("/new-organisation");
                }}
              >
                Create
              </Fab>
            </Grid>
          )}
        </Hidden>
        <Grid container item xs={12}>
          {!loading ? (
            <Grid container xs={12}>
              <Hidden smUp>
                <Grid container item xs={12} spacing={2}>
                  <OrganisationStructureVirtualScroller data={rows.filter(organisation =>
                    organisation.name.match(search) ||
                    organisation.email?.match(search)
                  )} />
                </Grid>
              </Hidden>
              <Hidden smDown>
                <NuvvenTable
                  title={""}
                  rows={rows}
                  columns={columns}
                  options={options}
                />
              </Hidden>
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
      <Hidden smUp>
        <AppBar position="fixed" className={classes.appBar}>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Button
                className={classes.bottomButton}
                variant="contained"
                aria-label="Create"
                onClick={() => {
                  navigate("/new-organisation");
                }}
              >
                Create
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={onChange}
                />
              </div>
            </Grid>
          </Grid>
        </AppBar>
      </Hidden>
    </div>
  );
}

export default OrganisationStructure;
