import { gql } from "@apollo/client";

export const GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS = gql`
  query externalAvailableVehicleGroups($args: AvailableVehicleGroupsInput!) {
    externalAvailableVehicleGroups(args: $args) {
      vehicleGroups{
        id
        fleetType
        name
        branchId
        criteria {
          type
          values
        }
        vehicles {
          id
          branchDetails {
            name
          }
          bodyType
          make
          model
          year
          licencePlate
          transmission
          colour
          fuelType
          features
          numberOfSeats
          numberOfDoors
          imageUrl
        }
        deposit
        basePrices {
          rateTypeName
          rateTypeDuration
          rate
          longTerm
          mileageLimit
          pricePerExtraMile
          applicableAmount
          priceRuleAmount
          priceRuleName
          priceRuleOperator
          priceRuleValueType
          priceRuleTriggerType
          priceRule
          unlimitedMileage
        }
        isActivated
        imageUrl
      }
      locationSurcharges{
       id
       title
       value
       type
       rate
       taxable
       tax {
          title
          value
          type
       }
      }
      vehiclesDistance{
        vehicleId
        distance
      }
    }
  }
`;
