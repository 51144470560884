import {
  Checkbox,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField as InputField,
  Paper,
  Theme,
  Typography,
  Switch,
  FormControl,
  MenuItem,
  Hidden
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, FieldProps, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { setBusinessCustomerInfo } from "../../../../../../actions/customer/actions";
import { IBusinessCustomer } from "../../../../../../reducers/customer/types";
import { IAddress } from "../../../../../../reducers/user/types";
import { IAppState } from "../../../../../../store";
import { getDefaultCountryCode, getLocalizedBookingSyntex, getLocalizedTaxSyntex } from "../../../../../../utils/localized.syntex";
import AddressPicker from "../../../NewCustomer/AddressPicker/AddressPicker";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage, monthDates, weekdays } from "../../../../../common/utils";
import { IServiceOptionsMenu, serviceAnniversary } from "../../../../Fleet/ServicesAndRepair/constants";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { GET_USERS_BY_ROLES } from "../../../../../../graphql/organisation/getUsersQuery";
import { UserRoles } from "../../../../../hoc/Authorization";
import { IAccountManager } from "../../../../../../reducers/bookings/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxLabel: {
      fontSize: "16px !important"
    },
    container: {
      backgroundColor: "#f4f4fa",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    content: {
      height: "auto",
      marginLeft: "50px",
      marginRight: "50px",
      marginTop: "100px"
    },
    root: {
      padding: theme.spacing(3)
    },
    section: {
      marginBottom: "25px"
    },
    invoiceDays: {
      paddingTop: 10,
      paddingBottom: 10
    }
  })
);

interface ICustomerInfoProps {
  businessCustomer: IBusinessCustomer;
  onSubmit(): void;
}

export const NewCustomerInfo = (props: ICustomerInfoProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [values, setValues] = useState(props.businessCustomer);
  const [countryCode, setCountry] = useState<any>({
    billing: getDefaultCountryCode(country),
    contact: getDefaultCountryCode(country),
  });
  const [billingPhoneError, setBillingPhoneError] = useState<string>("");
  const [contactPhoneError, setContactPhoneError] = useState<string>("");
  const snackbar = useSnackBar();
  const [accountManagersOptions, setAccountManagersOptions] = useState<IServiceOptionsMenu[]>([]);

  const [getUsersByRoles, { data: usersData }] = useLazyQuery(GET_USERS_BY_ROLES, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  function handleCheckbox(
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) {
    if (event.target.checked) {
      setValues({
        ...data,
        tradingAddress: data.registeredAddress,
        tradingAddressSame: true
      });
    } else {
      setValues({
        ...data,
        tradingAddressSame: false
      });
    }
  }

  const formSubmitHandler = (values: any) => {
    if (values.creditLimitEnabled && (!values.creditLimit || values.creditLimit <= 0)) {
      snackbar({
        message: 'Please enter valid Credit limit.',
        variant: SnackBarVariant.ERROR
      });
      return;
    }
    dispatch(setBusinessCustomerInfo(values));
    props.onSubmit();
  };

  const customerInfoSchema = Yup.object().shape({
    billing: Yup.object().shape({
      email: Yup.string()
        .required("Billing email is required.")
        .email("Please enter a valid Billing email."),
      phoneNumber: Yup.object().shape({
        phone: Yup.string()
          .test("test-is-b-valid-phone-number", "", function (value) {
            if (value && !isValidPhoneNumber(value)) {
              return this.createError({ message: "Invalid phone number" });
            }
            return true;
          })
          .required("Phone number is required.")
      })
    }),
    businessName: Yup.string().required("Business name is required."),
    contact: Yup.object().shape({
      email: Yup.string()
        .required("Contact email is required.")
        .email("Please enter a valid Contact email."),
      firstName: Yup.string().required("First name is required."),
      lastName: Yup.string().required("Last name is required."),
      phoneNumber: Yup.object().shape({
        phone: Yup.string()
          .test("test-c-is-valid-phone-number", "", function (value) {
            if (value && !isValidPhoneNumber(value)) {
              return this.createError({ message: "Invalid phone number" });
            }
            return true;
          })
          .required("Phone number is required.")
      })
    }),
    registeredAddress: Yup.object().shape({
        fullAddress: Yup.string().required("Registered Address is required"),
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required")
    }).required("Registered Address is required"),
    tradingAddress: Yup.object().shape({
        fullAddress: Yup.string().required("Trading Address is required"),
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        street: Yup.string().required("Street is required")
    }).required("Trading Address is required"),
    weeklyInvoicingDay: Yup.string().when("overrideWeeklyInvoiceDate", {
      is: true,
      then: schema => schema.required("Weekly Invoicing Day is required.").nullable(),
      otherwise: schema => schema.notRequired().nullable()
    }),
    invoicingDay: Yup.string().when("overrideInvoiceDate", {
      is: true,
      then: schema => schema.required("Monthly Invoicing Date is required.").nullable(),
      otherwise: schema => schema.notRequired().nullable()
    }),
    netPaymentTerms: Yup.number().when("overrideNetPaymentTerms", {
      is: true,
      then: (schema) =>
        schema
          .required("Net Payment Terms is required.")
          .test(
            "must-be-greater-than-0",
            "Net Payment terms must be greater than 0",
            function () {
              return this.parent.netPaymentTerms > 0;
            }
          ),
      otherwise: (schema) => schema.notRequired().nullable()
    })
  });

   useEffect(() => {
    getUsersByRoles({
      variables: {
        roles: [UserRoles.ACCOUNT_MANAGER]
      }
    })
  }, [props.businessCustomer]);

  useEffect(() => {
    if (usersData?.getUsersByRoles?.length > 0) {  
      const formattedAccountManagerOptions = usersData?.getUsersByRoles.map(({ id, firstName, lastName }: IAccountManager) => ({
        label: `${firstName} ${lastName}`,
        value: id,
      }));
      setAccountManagersOptions(formattedAccountManagerOptions)
    }
  }, [usersData])

  return (
    <React.Fragment>
      <div className="customer-info-body">
        <Paper className={classes.root}>
          <Formik
            enableReinitialize
            validationSchema={customerInfoSchema}
            initialValues={values}
            onSubmit={(values, { setSubmitting }) => {
              const updateValues = {
                ...values,
                billing: {
                  ...values.billing,
                  phoneNumber: {
                    ...values.billing.phoneNumber,
                    country: countryCode.billing
                  }
                },
                contact: {
                  ...values.contact,
                  phoneNumber: {
                    ...values.contact.phoneNumber,
                    country: countryCode.contact
                  }
                },
              };
              formSubmitHandler(updateValues);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form noValidate>
                <Grid container>
                  <Grid spacing={2} item container xs={12}>
                    <Grid item container xs={12}>
                      <Typography variant="h2">COMPANY INFORMATION</Typography>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name="businessName"
                        placeholder="Business Name"
                        label="Business Name"
                        onChange={props.handleChange}
                        value={props.values.businessName}
                        inputProps={{ maxLength: 75 }}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name="registrationNumber"
                        placeholder="Company Reg. Number"
                        label="Company Reg. Number"
                        value={props.values.registrationNumber}
                        onChange={props.handleChange}
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name="VAT"
                        placeholder={`${getLocalizedTaxSyntex(country)} Registration Number`}
                        label={`${getLocalizedTaxSyntex(country)} Registration Number`}
                        value={props.values.VAT}
                        onChange={props.handleChange}
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Typography variant="h3">REGISTERED ADDRESS</Typography>
                      </Grid>
                      <Hidden xsDown>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="h3">TRADING ADDRESS</Typography>
                        </Grid>
                      </Hidden>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                        <AddressPicker
                          fieldName={"registeredAddress"}
                          required={true}
                          onChange={(address: IAddress) => {
                            if (address) {
                              setValues({ ...props.values, registeredAddress: address })
                            }
                          }}
                        />
                      </Grid>
                      <Hidden smUp>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Typography variant="h3">TRADING ADDRESS</Typography>
                        </Grid>
                      </Hidden>
                      <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                        {!props.values.tradingAddressSame ? (
                          <AddressPicker
                            fieldName={"tradingAddress"}
                            required={true}
                            onChange={(address: IAddress) => {
                              if (address) {
                                setValues({ ...props.values, tradingAddress: address })
                              }
                            }}
                          />
                        ) : (
                          <InputField
                            placeholder="Trading Address"
                            label="Trading Address"
                            disabled
                            fullWidth
                            value={props.values.registeredAddress.fullAddress}
                          ></InputField>
                        )}
                      </Grid>
                      <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormGroup row className={classes.checkboxLabel}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={props.values.tradingAddressSame}
                                onChange={(e) =>
                                  handleCheckbox(e, props.values)
                                }
                                color="secondary"
                                name={"premium"}
                              />
                            }
                            label={
                              <Typography variant="body1">
                                {"Use Registered Address"}
                              </Typography>
                            }
                            classes={{ label: classes.checkboxLabel }}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item container xs={6}>
                        <Typography variant="h3" gutterBottom>
                          BILLING INFORMATION
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name={"billing.phoneNumber.phone"}
                        required
                      >
                        {({ form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                          return (
                            <div className={`phone-input-container`}>
                              <label className={`${billingPhoneError ? "input-error-label" : ""} phone-input-label`}>
                                Billing Phone Number <sup className={"phone-input-required"}>*</sup>
                              </label>
                              <PhoneInput
                                international={false}
                                defaultCountry={countryCode.billing}
                                placeholder="Enter phone number"
                                className={billingPhoneError ? "phone-input-error" : ""}
                                value={props.values.billing.phoneNumber.phone}
                                name={"billing.phoneNumber.phone"}
                                required
                                onChange={(val: any) => {
                                  if (val && isValidPhoneNumber(val)) {
                                    setBillingPhoneError("");
                                  }
                                  setValues({
                                    ...props.values,
                                    billing: {
                                      ...props.values.billing,
                                      phoneNumber: {
                                        ...props.values.billing.phoneNumber,
                                        phone: val
                                      }
                                    }
                                  });
                                }}
                                onBlur={(val) => {
                                  if (!props.values.billing.phoneNumber.phone) {
                                    setBillingPhoneError("Phone number is required.");
                                  } else if (!isValidPhoneNumber(props.values.billing.phoneNumber.phone)) {
                                    setBillingPhoneError("Enter a valid phone number.");
                                  } else {
                                    setBillingPhoneError("");
                                  }
                                }}
                                onCountryChange={(val) => {
                                  setCountry({
                                    ...countryCode,
                                    billing: val
                                  });
                                }}
                              />
                              {
                                billingPhoneError !== "" ?
                                  <span className={"phone-error-message"}>{billingPhoneError}</span>
                                  : (meta.touched && meta.error) &&
                                <span className={"phone-error-message"}>
                                    {billingPhoneError || "Phone number is required."}
                                </span>
                              }
                            </div>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name="billing.email"
                        placeholder="Billing Email Address"
                        label="Billing Email Address"
                        value={props.values.billing.email}
                        onChange={props.handleChange}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name="billing.accountNumber"
                        placeholder="Account Number"
                        label="Account Number"
                        value={props.values.billing.accountNumber || ""}
                        onChange={props.handleChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item container xs={12}>
                      <Typography variant="h3" gutterBottom>
                        CUSTOMER CONTACT
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name="contact.firstName"
                        placeholder="First Name"
                        label="First Name"
                        inputProps={{ maxLength: 50 }}
                        onChange={props.handleChange}
                        value={props.values.contact.firstName}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name="contact.lastName"
                        placeholder="Last Name"
                        label="Last Name"
                        onChange={props.handleChange}
                        value={props.values.contact.lastName}
                        inputProps={{ maxLength: 50 }}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name={"contact.phoneNumber.phone"}
                        required
                      >
                        {({ form: { setFieldValue, setTouched, touched, errors }, meta }: FieldProps) => {
                          return (
                            <div className={`phone-input-container`}>
                              <label className={`${contactPhoneError ? "input-error-label" : ""} phone-input-label`}>
                                Contact Phone Number <sup className={"phone-input-required"}>*</sup>
                              </label>
                              <PhoneInput
                                international={false}
                                defaultCountry={countryCode.contact}
                                placeholder="Enter phone number"
                                className={contactPhoneError ? "phone-input-error" : ""}
                                value={props.values.contact.phoneNumber.phone}
                                name={"contact.phoneNumber.phone"}
                                required
                                onChange={(val: any) => {
                                  if (val && isValidPhoneNumber(val)) {
                                    setContactPhoneError("");
                                  }
                                  setValues({
                                    ...props.values,
                                    contact: {
                                      ...props.values.contact,
                                      phoneNumber: {
                                        ...props.values.contact.phoneNumber,
                                        phone: val
                                      }
                                    }
                                  });
                                }}
                                onBlur={() => {
                                  if (!props.values.contact.phoneNumber.phone) {
                                    setContactPhoneError("Phone number is required.");
                                  } else if (!isValidPhoneNumber(props.values.contact.phoneNumber.phone)) {
                                    setContactPhoneError("Enter a valid phone number.");
                                  } else {
                                    setContactPhoneError("");
                                  }
                                }}
                                onCountryChange={(val) => {
                                  setCountry({
                                    ...countryCode,
                                    contact: val
                                  });
                                }}
                              />
                              {
                                contactPhoneError !== "" ?
                                  <span className={"phone-error-message"}>{contactPhoneError}</span>
                                  : (meta.touched && meta.error) &&
                                <span className={"phone-error-message"}>
                                    {contactPhoneError || "Phone number is required."}
                                </span>
                              }
                            </div>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Field
                        component={TextField}
                        name="contact.email"
                        placeholder="Email Address"
                        label="Email Address"
                        value={props.values.contact.email}
                        onChange={props.handleChange}
                        fullWidth
                        required
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.taxExempted}
                              onChange={() => {
                                setValues({
                                  ...props.values,
                                  taxExempted:
                                    !props.values.taxExempted
                                });
                              }}
                              value="taxExempted"
                              color="secondary"
                            />
                          }
                          label={
                            <Typography variant={"h5"}>
                              {"Check this for VAT/TAX Exempt Business Customer"}
                            </Typography>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12}>
                      <Typography variant="h3" gutterBottom>
                        ACCOUNT MANAGER
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name={"accountManager"}
                          fullWidth
                          type="text"
                          select
                          label="Account Manager"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={typeof props.values.accountManager === 'string'
                            ? props.values.accountManager
                            : props.values.accountManager?.id || ""}
                          InputProps={{
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setValues({
                                ...values,
                                accountManager: event.target.value
                              });
                            },
                          }}
                        >
                          {accountManagersOptions.map(
                            (
                              item: IServiceOptionsMenu,
                              index: number
                            ) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            }
                          )}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item container xs={6}>
                        <Grid item container xs={12}>
                          <Typography variant="subtitle1" gutterBottom>
                            PAYMENT TERMS
                          </Typography>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={6}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={props.values.overrideNetPaymentTerms}
                                  onClick={props.handleChange}
                                  value="overrideNetPaymentTerms"
                                  name="overrideNetPaymentTerms"
                                />
                              }
                              label="Override Net Payment Terms"
                            />
                          </Grid>
                          {props.values.overrideNetPaymentTerms &&
                            <Grid item xs={5}>
                              <FormControl variant="outlined" fullWidth>
                                <Field
                                  component={InputField}
                                  name="netPaymentTerms"
                                  type="number"
                                  placeholder="Enter Net Payment Terms in days"
                                  label="Net Payment Terms (days)"
                                  inputProps={{
                                    name: "netPaymentTerms",
                                    value: props.values.netPaymentTerms,
                                    onChange: props.handleChange
                                  }}
                                  fullWidth
                                  value={props.values.netPaymentTerms}
                                  required={props.values.overrideNetPaymentTerms}
                                  error={props.errors.netPaymentTerms}
                                  helperText={props.errors.netPaymentTerms}
                                />
                              </FormControl>
                            </Grid>
                          }
                        </Grid>
                        <Grid item container xs={12} className={classes.invoiceDays} justifyContent="space-between">
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={props.values.overrideInvoiceDate}
                                  onClick={props.handleChange}
                                  name="overrideInvoiceDate"
                                  value="overrideInvoiceDate"
                                />
                              }
                              label={`Preferred Invoicing Date for Monthly ${getLocalizedBookingSyntex(country)}s`}
                            />
                          </Grid>
                          {props.values.overrideInvoiceDate && (
                            <Grid item xs={4} justifyContent="flex-end">
                              <FormControl variant="outlined" fullWidth>
                                <Field
                                  component={InputField}
                                  name={"invoicingDay"}
                                  fullWidth
                                  type="number"
                                  select
                                  label="Select Date"
                                  required={props.values.overrideInvoiceDate}
                                  error={props.errors.invoicingDay}
                                  inputProps={{
                                    name: "invoicingDay",
                                    value: props.values.invoicingDay,
                                    onChange: props.handleChange
                                  }}
                                  helperText={props.errors.invoicingDay}
                                  value={props.values.invoicingDay || ""}
                                  placeholder="Select Date"
                                >
                                  {monthDates.map((item: number) => (
                                    <MenuItem key={item} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item container xs={12} className={classes.invoiceDays} justifyContent="space-between">
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={props.values.overrideWeeklyInvoiceDate}
                                  onClick={props.handleChange}
                                  name="overrideWeeklyInvoiceDate"
                                  value="overrideWeeklyInvoiceDate"
                                />
                              }
                              label={`Preferred Invoicing Day for Weekly ${getLocalizedBookingSyntex(country)}s`}
                            />
                          </Grid>
                          {props.values.overrideWeeklyInvoiceDate && (
                            <Grid item xs={4} justifyContent="flex-end">
                              <FormControl variant="outlined" fullWidth>
                                <Field
                                  component={InputField}
                                  name={"weeklyInvoicingDay"}
                                  fullWidth
                                  type="number"
                                  select
                                  label="Select Day"
                                  value={props.values.weeklyInvoicingDay || ""}
                                  error={props.errors.weeklyInvoicingDay}
                                  required={props.values.overrideWeeklyInvoiceDate}
                                  inputProps={{
                                    name: "weeklyInvoicingDay",
                                    value: props.values.weeklyInvoicingDay,
                                    onChange: props.handleChange
                                  }}
                                  placeholder="Select Day"
                                  helperText={props.errors.weeklyInvoicingDay}
                                >
                                  {weekdays.map((item: string, index: number) => (
                                      <MenuItem key={item} value={index + 1}>
                                        {item}
                                      </MenuItem>
                                  ))}
                                </Field>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {userState.currentTenancy.loginEnabled && <Grid item container xs={6}>
                          <Grid item container xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                              CUSTOMER PORTAL TERMS
                            </Typography>
                          </Grid>
                          <Grid item container xs={12}>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Switch
                                  checked={values.requiresBookingApproval ? true : false}
                                    onClick={(e: any) => {
                                      setValues({
                                        ...props.values,
                                      requiresBookingApproval: e.target.checked
                                    })
                                    }}
                                    value="requiresBookingApproval"
                                  />
                                }
                              label={`${getLocalizedBookingSyntex(country)}s by this customer from Customer Portal requires approval`}
                              />
                            </Grid>
                          </Grid>
                      </Grid>}
                    </Grid>
                    <Grid item container xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        CREDIT LIMIT
                      </Typography>
                    </Grid>
                    <Grid item container xs={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.creditLimitEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setValues({
                                ...values,
                                creditLimit: 0,
                                creditLimitEnabled: e.target.checked
                              });
                            }}
                          />
                        }
                        labelPlacement="end"
                        label="Enable Credit Limit"
                      />
                    </Grid>
                    <Grid item container xs={4}>
                      {values?.creditLimitEnabled && (
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            name="creditLimit"
                            placeholder="Enter Credit Limit"
                            label="Credit Limit"
                            value={props.values.creditLimit}
                            InputProps={{
                              onChange: (
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                setValues({
                                  ...values,
                                  creditLimit: parseInt(e.target.value)
                                }),
                              value: values.creditLimit
                            }}
                            type="number"
                            inputProps={{
                              hasCurrencyPrefix: true,
                              allowNegative: false
                            }}
                            fullWidth
                            required
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item container xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        OVERRIDE SERVICE SCHEDULE
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name={"overrideServiceScheduleByMonth"}
                          fullWidth
                          type="text"
                          select
                          label="By Duration"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={props.values.overrideServiceScheduleByMonth || ""}
                          InputProps={{
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setValues({
                                ...values,
                                overrideServiceScheduleByMonth: parseInt(event.target.value)
                              });
                            },
                          }}
                        >
                          {serviceAnniversary.map(
                            (
                              item: IServiceOptionsMenu,
                              index: number
                            ) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            }
                          )}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type="submit"
                      >
                        PROCEED
                      </Fab>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </div>
    </React.Fragment>
  );
};
