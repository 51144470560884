

export function templateVariables(templateType: string) {

  switch (templateType) {
    case "BOOKING_CONFIRMATION":
      return [
        {
          name: "Customer Name",
          value: "{{customerName}}",
          key: "customerName"
        },
        {
          name: "Booking Reference",
          value: "{{bookingRef}}",
          key: "bookingRef"
        },
        {
          name: "Organisation Name",
          value: "{{organisation}}",
          key: "organisation"
        },
        {
          name: "Organisation EmailId",
          value: "{{emailId}}",
          key: "emailId"
        },
        {
          name: "Logo Url",
          value: "{{logoUrl}}",
          key: "logoUrl"
        }
      ]
    case "BOOKING_DECLINED":
      return [
        {
          name: "Customer Name",
          value: "{{customerName}}",
          key: "customerName"
        },
        {
          name: "Booking Reference",
          value: "{{bookingRef}}",
          key: "bookingRef"
        },
        {
          name: "Organisation Name",
          value: "{{organisation}}",
          key: "organisation"
        },
        {
          name: "Organisation EmailId",
          value: "{{emailId}}",
          key: "emailId"
        },
        {
          name: "Logo Url",
          value: "{{logoUrl}}",
          key: "logoUrl"
        }
      ]
    case "BOOKING_CANCELLED":
      return [
        {
          name: "Customer Name",
          value: "{{customerName}}",
          key: "customerName"
        },
        {
          name: "Booking Reference",
          value: "{{bookingRef}}",
          key: "bookingRef"
        },
        {
          name: "Organisation Name",
          value: "{{organisation}}",
          key: "organisation"
        },
        {
          name: "Organisation EmailId",
          value: "{{emailId}}",
          key: "emailId"
        },
        {
          name: "Logo Url",
          value: "{{logoUrl}}",
          key: "logoUrl"
        }
      ]
    case "BOOKING_UPDATED":
      return [
        {
          name: "Customer Name",
          value: "{{customerName}}",
          key: "customerName"
        },
        {
          name: "Booking Reference",
          value: "{{bookingRef}}",
          key: "bookingRef"
        },
        {
          name: "Organisation Name",
          value: "{{organisation}}",
          key: "organisation"
        },
        {
          name: "Organisation EmailId",
          value: "{{emailId}}",
          key: "emailId"
        },
        {
          name: "Logo Url",
          value: "{{logoUrl}}",
          key: "logoUrl"
        }
      ]
    case "BOOKING_APPROVAL_REQUIRED":
      return [
        {
          name: "Customer Name",
          value: "{{customerName}}",
          key: "customerName"
        },
        {
          name: "Booking Reference",
          value: "{{bookingRef}}",
          key: "bookingRef"
        },
        {
          name: "Organisation Name",
          value: "{{organisation}}",
          key: "organisation"
        },
        {
          name: "Logo Url",
          value: "{{logoUrl}}",
          key: "logoUrl"
        }
      ]
    case "BOOKING_INVOICE":
      return [
        {
          name: "Customer Name",
          value: "{{customerName}}",
          key: "customerName"
        },
        {
          name: "Booking Reference",
          value: "{{bookingRef}}",
          key: "bookingRef"
        },
        {
          name: "Invoice Reference",
          value: "{{invoiceRef}}",
          key: "invoiceRef"
        },
        {
          name: "Organisation Name",
          value: "{{organisation}}",
          key: "organisation"
        },
        {
          name: "Organisation EmailId",
          value: "{{emailId}}",
          key: "emailId"
        },
        {
          name: "Logo Url",
          value: "{{logoUrl}}",
          key: "logoUrl"
        }
      ]
    case "SUNDRY_INVOICE":
      return [
        {
          name: "Customer Name",
          value: "{{customerName}}",
          key: "customerName"
        },
        {
          name: "Booking Reference",
          value: "{{bookingRef}}",
          key: "bookingRef"
        },
        {
          name: "Invoice Reference",
          value: "{{invoiceRef}}",
          key: "invoiceRef"
        },
        {
          name: "Organisation Name",
          value: "{{organisation}}",
          key: "organisation"
        },
        {
          name: "Organisation EmailId",
          value: "{{emailId}}",
          key: "emailId"
        },
        {
          name: "Logo Url",
          value: "{{logoUrl}}",
          key: "logoUrl"
        }
      ]
    case "BOOKING_FINAL_INVOICE":
      return [
        {
          name: "Customer Name",
          value: "{{customerName}}",
          key: "customerName"
        },
        {
          name: "Invoice Reference",
          value: "{{invoiceRef}}",
          key: "invoiceRef"
        },
        {
          name: "Organisation Name",
          value: "{{organisation}}",
          key: "organisation"
        },
        {
          name: "Organisation EmailId",
          value: "{{emailId}}",
          key: "emailId"
        },
        {
          name: "Logo Url",
          value: "{{logoUrl}}",
          key: "logoUrl"
        }
      ]
    case "CUSTOMER_APPROVAL":
      return [
        {
          name: "Customer Name",
          value: "{{customerName}}",
          key: "customerName"
        },
        {
          name: "Organisation Name",
          value: "{{organisation}}",
          key: "organisation"
        },
        {
          name: "Organisation EmailId",
          value: "{{emailId}}",
          key: "emailId"
        },
        {
          name: "Status",
          value: "{{status}}",
          key: "status"
        },
        {
          name: "Logo Url",
          value: "{{logoUrl}}",
          key: "logoUrl"
        }
      ]
    case "CUSTOMER_VERIFICATION":
      return [
        {
          name: "Customer Name",
          value: "{{customerName}}",
          key: "customerName"
        },
        {
          name: "Organisation Name",
          value: "{{organisation}}",
          key: "organisation"
        },
        {
          name: "Organisation EmailId",
          value: "{{emailId}}",
          key: "emailId"
        },
        {
          name: "Logo Url",
          value: "{{logoUrl}}",
          key: "logoUrl"
        }
      ]
    case "CUSTOMER_APPROVAL_REQUEST":
      return [
        {
          name: "Customer Name",
          value: "{{customerName}}",
          key: "customerName"
        },
        {
          name: "Organisation Name",
          value: "{{organisation}}",
          key: "organisation"
        },
        {
          name: "Organisation EmailId",
          value: "{{emailId}}",
          key: "emailId"
        },
        {
          name: "Logo Url",
          value: "{{logoUrl}}",
          key: "logoUrl"
        }
      ]
  }

}
