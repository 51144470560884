import { gql } from "@apollo/client";

export const GET_BRANCHES = gql`
  query branches($tenancyId: ID!, $organisationId: ID!) {
    branches(tenancyId: $tenancyId, organisationId: $organisationId) {
      id
      name
      emailId
      corporateRateCardEnabled
      overbookingAllowed
      depositCollection
      netPaymentTerms
      dailyBillingCycle
      weeklyBillingCycle
      monthlyBillingCycle
      phoneNumber {
        phone
        country
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      timeZone
      owner {
        id
        email
      }
      operatingHours {
        day
        dayIndex
        workingFromTime
        workingToTime
        pickupFromTime
        pickupToTime
        returnFromTime
        returnToTime
        closed
      }
      minBookingDuration
      minimumBookingAmount {
        type
        value
      }
      minBookingAmountOptIn
      minBookingDurationOptIn
      distanceUnit
      preBookingQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
      additionalDriverQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
      }
      isAdditionalDriverQuestionsEnabled
      deliveryQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
      }
      locations {
        id
        name
        address {
          street
          city
          fullAddress
          state
          country
          zipcode
          geometry {
            type
            latitude
            longitude
          }
        }
      }
      vehiclePreparationTime
      carShareGracePeriodMins
      durationBasedPricingEnabled
      useThresholds
      offenceThreshold
      pointThreshold
      signatureUrl
      holidays {
        id
        name
        startDate
        endDate
        isActive
      }
      addTaxOptIn
      taxes {
        id
        title
        type
        value
        rate
        subdivisionOptIn
        subdivisionType
        subdivisionValue
        subdivisionRate
      }
      epyxAccountNumber
      epyxSchemeReferenceNumber
      dashboardReminder
      showPaymentMethodInvoice
      paymentMethodString
      subscriptionSettings {
        dvlaVerificationEnabled
        creditCheckEnabled
        idvEnabled
      }
      autoPayEnabled
      autoPayDays
    }
  }
`;

export const GET_BRANCHES_FOR_XERO_CODES_MAPPING = gql`
  query branches($tenancyId: ID!, $organisationId: ID!) {
    branches(tenancyId: $tenancyId, organisationId: $organisationId) {
      id
      name
    }
  }
`;

export const GET_BRANCH = gql`
  query branch($branchId: ID!) {
    branch(branchId: $branchId) {
      id
      name
      emailId
      corporateRateCardEnabled
      overbookingAllowed
      netPaymentTerms
      dailyBillingCycle
      weeklyBillingCycle
      monthlyBillingCycle
      locations {
        id
        name
        address {
          fullAddress
        }
      }
      activeLocations {
        id
        name
      }
      phoneNumber {
        phone
        country
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      timeZone
      owner {
        id
        email
      }
      preBookingQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
      additionalDriverQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
      isAdditionalDriverQuestionsEnabled
      minBookingAmountOptIn
      minBookingDurationOptIn
      minBookingDuration
      distanceUnit
      deliveryQuestions {
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
      }
      vehiclePreparationTime
      carShareGracePeriodMins
      durationBasedPricingEnabled
      useThresholds
      offenceThreshold
      pointThreshold
      signatureUrl
      holidays {
        id
        name
        startDate
        endDate
        isActive
      }
      addTaxOptIn
      taxes {
        id
        title
        type
        value
        rate
        subdivisionOptIn
        subdivisionType
        subdivisionValue
        subdivisionRate
      }
      epyxAccountNumber
      epyxSchemeReferenceNumber
      dashboardReminder
      showPaymentMethodInvoice
      paymentMethodString
      autoPayEnabled
      autoPayDays
    }
  }
`;
