import { gql } from "@apollo/client";

export const GET_VEHICLES = gql`
  {
    vehicles {
      insurerCode
      airConditioner
      assetTag
      bodyType
      colour
      fuelType
      licencePlate
      vin
      make
      model
      year
      numberOfDoors
      numberOfPreviousOwners
      numberOfSeats
      grossVehicleWeight
      satNav
      sixMonthRate
      status
      id
    }
  }
`;

export const GET_VEHICLES_FOR_EMISSIONS = gql`
  {
    vehicles {
      telemetryDeviceId
      licencePlate
      id
    }
  }
`;
