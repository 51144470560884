
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CssBaseline,
  Fab,
  Grid,
  TextField as InputField,
  Paper,
  Theme,
  Typography,
  CircularProgress,
  Tooltip,
  FormControlLabel,
  Switch
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { ApolloError } from "@apollo/client";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import _ from "lodash";
import React, { ChangeEvent, useEffect, useState, FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { IAppState } from "../../../../../store";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { CREATE_USER_ACCESS_ROLE, UPDATE_USER_ACCESS_ROLE } from "../../../../../graphql/userPermissions/userAccessMutations";
import { GET_USER_ACCESS_ROLE, GET_USER_POLICIES_FOR_ROLE } from "../../../../../graphql/userPermissions/userAccessQuery";
import { IUserAccessPolicy } from "./NewPolicy";
import { IRowUserAccessPolicy, UserAccessType } from "./Policies";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      width: "100%"
    }
  })
);

export interface IUserAccessRole {
  id?: string;
  name: string;
  policies: (string | IUserAccessPolicy)[];
  key?: string;
  roleType?: UserAccessType,
  isActive: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export const NewRole = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const [userAccessPolicies, setUserAccessPolicies] = useState<Partial<IRowUserAccessPolicy[]>>([]);
  const [selectedUserPolicies, setSelectedUserPolicies] = useState<Partial<IRowUserAccessPolicy[]>>([]);
  const [values, setValues] = useState<IUserAccessRole>({
    name: "",
    isActive: true,
    policies: []
  });

  const [getUserAccessPolicies, { loading, data }] = useLazyQuery(GET_USER_POLICIES_FOR_ROLE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {

    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [getUserAccessRole, { loading: loadingUserRole, data: userAccessRoleData }] =
    useLazyQuery(GET_USER_ACCESS_ROLE, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data.getUserAccessRole) {
          navigate("/roles")
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    });

  const [createUserAccessRoleMutation] = useMutation(CREATE_USER_ACCESS_ROLE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.createUserAccessPolicy) {
        snackbar({
          message: "User role has been created",
          variant: SnackBarVariant.SUCCESS
        });
      }
      navigate("/roles");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [updateUserAccessRoleMutation] = useMutation(UPDATE_USER_ACCESS_ROLE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.updateUserAccessPolicy) {
        snackbar({
          message: "User policy has been updated",
          variant: SnackBarVariant.SUCCESS
        });
      }
      navigate("/roles");
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const roleId = params.get("role");
      if (roleId) {
        getUserAccessRole({
          variables: {
            userAccessRoleId: roleId,
            roleType: state.roleType
          }
        })
      }
    }
  }, [location]);

  useEffect(() => {
    getUserAccessPolicies();
  }, [userReducer.tenancy]);

  useEffect(() => {
    if (userAccessRoleData &&
      userAccessRoleData.getUserAccessRole &&
      data && data.getUserAccessPolicies) {
      const userAccessPolicies = data.getUserAccessPolicies;
      const selectedPolicies = userAccessPolicies.filter((policy: any) =>
        userAccessRoleData.getUserAccessRole.policies.some((_policy: any) => _policy.id === policy.id));
      setSelectedUserPolicies(selectedPolicies);
      setValues(userAccessRoleData.getUserAccessRole);
    }
  }, [userAccessRoleData, data]);

  useEffect(() => {
    if (data && data.getUserAccessPolicies) {
      const activeUserPolicies = data.getUserAccessPolicies.filter((policy: IUserAccessPolicy) => policy.isActive);
      setUserAccessPolicies(activeUserPolicies);
    }
  }, [data]);

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12} alignItems="center">
        {values && values.id ? (
          <Typography variant="h1" color="primary">
            Update
          </Typography>
        ) : (
          <Typography variant="h1" color="primary">
            Create
          </Typography>
        )}
        <Typography variant="h1" color="primary">
          &nbsp;Role
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Paper className={classes.root}>
          {loading && loadingUserRole ? <CircularProgress /> : (
            <Formik
              enableReinitialize
              initialValues={values}
              onSubmit={(values) => {
                const { id, roleType, key, ...rest } = values;
                const policies = values.policies.map((_policy) => {
                  if (typeof _policy !== 'string') {
                    return _policy.id;
                  }
                  return _policy;
                });
                if (values.id) {
                  updateUserAccessRoleMutation({
                    variables: {
                      userAccessRoleId: values.id,
                      userAccessRole: { ...rest, policies }
                    }
                  })
                } else {
                  createUserAccessRoleMutation({
                    variables: {
                      userAccessRole: {
                        ...rest
                      }
                    }
                  })
                }
              }}
            >
              {(props) => (
                <Form noValidate>
                  <Grid container spacing={2}>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item container xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Field
                          component={TextField}
                          name="name"
                          placeholder="Role Name"
                          label="Role Name"
                          value={values.name}
                          disabled={values.roleType === UserAccessType.SYSTEM_DEFINED}
                          inputProps={{ maxLength: 50 }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => setValues({
                            ...values,
                            name: event.target.value
                          })}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Autocomplete
                          multiple
                          freeSolo
                          id="free-solo-policy-search"
                          disableClearable
                          disabled={values.roleType === UserAccessType.SYSTEM_DEFINED}
                          options={userAccessPolicies}
                          getOptionLabel={(option: any) =>
                            option.name
                          }
                          filterSelectedOptions={true}
                          onChange={(_: any, newValues: any, reason: any) => {
                            console.log(reason)
                            if (reason === "selectOption") {
                              setValues({
                                ...props.values,
                                policies: [
                                  ...props.values.policies,
                                  newValues[newValues.length - 1].id
                                ]
                              })
                            }
                            if (reason === "removeOption") {
                              const updatedPolicies = newValues.map((value: Partial<IRowUserAccessPolicy>) => value.id)
                              setValues({
                                ...props.values,
                                policies: updatedPolicies
                              })
                            }
                            setSelectedUserPolicies(newValues)
                          }}
                          value={selectedUserPolicies}
                          defaultValue={[]}
                          renderInput={(params) => (
                            <InputField
                              {...params}
                              label="Select user policies"
                              variant="outlined"
                              placeholder={"Select user policies"}
                              InputProps={{ ...params.InputProps, type: "search" }}
                            />
                          )}
                        />
                      </Grid>
                      {values.id && (
                        <Grid container item xs={12} sm={12} md={4} lg={4} style={{ paddingLeft: 100 }} justifyContent="flex-end">
                          <Tooltip title={props.values.isActive ? "Deactivate" : "Activate"}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={props.values.isActive}
                                  onChange={(event: any) => {
                                    setValues({
                                      ...props.values,
                                      isActive: event.target.checked
                                    })
                                  }
                                  }
                                  name="isActive"
                                  color="primary"
                                  disabled={values.roleType === UserAccessType.SYSTEM_DEFINED}
                                />
                              }
                              label={"Active"}
                              disabled={values.roleType === UserAccessType.SYSTEM_DEFINED}
                            />
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-start">
                      <Fab
                        variant="extended"
                        size="medium"
                        type="submit"
                        aria-label="add"
                        disabled={props.values.roleType === UserAccessType.SYSTEM_DEFINED || !props.values.name || !props.values.policies.length}
                      >
                        SAVE
                      </Fab>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}