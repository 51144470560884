import { gql } from "@apollo/client";

export const GET_CREDIT_SCORE = gql`
  mutation getCreditScore($customerId: String!) {
    getCreditScore(customerId: $customerId){
        creditSafeData {
          creditScore
          lastChecked
        }
    }
  }
`;