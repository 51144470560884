import { gql } from "@apollo/client";

export const GET_SUBSCRIPTION = gql`
  query getVehicleSubscription($id: ID!) {
    getVehicleSubscription(id: $id) {
      id
      name
      rateTypeName
      insurancePolicy {
        id
        name
      }
      addons {
        id
        name
        displayName
      }
      features
      swapsTnC
      serviceIncluded
      serviceTnC
      subscriptionTnC
      vehicles {
        id
        make
        model
      }
      isActive
    }
  }
`;
