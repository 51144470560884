import { gql } from "@apollo/client";

export const GET_VEHICLE_GROUPS_TRACKING = gql`
  query vehicleGroups {
    vehicleGroups {
      id
      name
      vehicles {
        id
        licencePlate
        telemetryDeviceId
        telematicCoreData {
          latitude
          longitude
          address {
            street
            city
          }
          odometer
          speed
          eventTime
        }
        smartCarVehicleData {
        vehicleLocation {
          latitude
          longitude
        }
        odometer {
          distance
        }
        eVChargeStatus {
          isPluggedIn
          state
        }
        eVBatteryLevel {
          percentRemaining
          range
        }
        fuelLevel {
          amountRemaining
          range
          percentRemaining
        }
        engineOilLife {
          lifeRemaining
        }
        tirePressure {
            backLeft
            backRight
            frontLeft
            frontRight
          }
        eVBatteryCapacity {
          capacity
        }
      }
      }
    }
  }
`;

export const GET_VEHICLES = gql`
  {
    vehicles {
      id
      licencePlate
      telemetryDeviceId
      telematicCoreData {
        latitude
        longitude
        eventTime
        address {
          street
          city
        }
      }
      smartCarVehicleData {
        unitSystem
        vehicleLocation {
          latitude
          longitude
        }
        odometer {
          distance
        }
        eVChargeStatus {
          isPluggedIn
          state
        }
        eVBatteryLevel {
          percentRemaining
          range
        }
        fuelLevel {
          amountRemaining
          range
          percentRemaining
        }
        engineOilLife {
          lifeRemaining
        }
        tirePressure {
            backLeft
            backRight
            frontLeft
            frontRight
          }
        eVBatteryCapacity {
          capacity
        }
      }
    }
  }
`;

export const GET_VEHICLES_FOR_TRACKING_HISTORY = gql`
  {
    vehicles {
      id
      licencePlate
      telemetryDeviceId
    }
  }
`;

export const GET_VEHICLE_DATA = gql`
  query Vehicle($id: String!) {
    vehicle(id: $id) {
      cylinderCapacity
      fuelType
      bodyType
      licencePlate
      telemetryDeviceId
      imageUrl
      id
      model
      make
      teltonikaDeviceId
      immobiliser
      telemetryDevicesData {
        status
        medium
        disabledAt
        enabledAt
        enabledBy
        disabledBy
        active
        telemetryDeviceId
        deviceId
      }
      vehicleStarter {
        status
        latestActionTime
        lastestActionBy
      }
    }
  }
`;

export const GET_SMART_VEHICLE_DATA = gql`
  query getSmartCarDataForVehicleId($vehicleId: ID!, $trackingHours: Int) {
    getSmartCarDataForVehicleId(vehicleId: $vehicleId, trackingHours: $trackingHours) {
      vehicleId
      odometer {
        distance
      }
      mapRoutesData {
        latlong
        eventTime
      }
      dataAge
      eVBatteryCapacity {
        capacity
      }
      eVBatteryLevel {
        percentRemaining
        range
      }
      eVChargeStatus {
        isPluggedIn
        state
      }
      engineOilLife {
        lifeRemaining
      }
      fuelLevel {
        amountRemaining
        percentRemaining
        range
      }
      requestId
      tirePressure {
        backLeft
        backRight
        frontLeft
        frontRight
      }
      unitSystem
      vehicleLocation {
        latitude
        longitude
      }
      lastLocation
    }
  }
`;
