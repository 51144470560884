import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import { CircularProgress } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { GET_PAYMENT } from "../../../../../../graphql/invoices/getPaymentQuery";

const StripePayment: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [clientSecret, setClientSecret] = useState<string>("");
  const [bookingId, setBookingId] = useState<string>("");
  const [invoiceId, setInvoiceId] = useState<string>("");
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { stripeAccountId } = userState.currentOrganisation;

  const stripePromise: any = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
    {
      stripeAccount: stripeAccountId
    }
  );

  const [
    loadPayment,
    { loading: paymentLoading, data: paymentData }
  ] = useLazyQuery(GET_PAYMENT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data?.payment) {
        navigate('/bookings')
      } else {
        if (data?.payment?.stripePaymentIntentSecret) {
          setClientSecret(data.payment.stripePaymentIntentSecret)
        }
      }      
    }
  });

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const paymentId = params.get("payment");
      const booking = params.get("booking");
      const invoice = params.get("invoice");
      if (paymentId) {
        loadPayment({
          variables: {
            id: paymentId
          }
        })
      }
      if (booking) {
        setBookingId(booking)
      }
      if (invoice) {
        setInvoiceId(invoice)
      }
    }
  }, [location]);

  const options: any = {
    clientSecret
  }

  if (!clientSecret) {
    return <CircularProgress />
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm clientSecret={clientSecret} bookingId={bookingId} invoiceId={invoiceId} />
    </Elements>
  )
}

export default StripePayment;