import React, { FC, useEffect, useState } from 'react';
import { SelectableRows } from "./../../../common/NuvvenTable/types";
import { useLazyQuery } from "@apollo/client";
import {
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Typography
} from "@mui/material";
import { MUIDataTableOptions } from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetVehicleService,
} from "../../../../actions/fleet/vehicle/actions";
import { addTableState } from "../../../../actions/tableState/actions";
import { IVehicleService, VehicleServiceStatus, VehicleServiceStatusValue, VehicleServiceType, VehicleServiceTypeValue } from "../../../../reducers/fleet/types";
import { TableNames } from "../../../../reducers/tableState/types";
import { IAppState } from "../../../../store";
import { NuvvenTable } from "../../../common/NuvvenTable/NuvvenTable";
import { DATE_TYPE, formatGraphQLErrorMessage, toCurrency } from "../../../common/utils";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { ApolloError } from "@apollo/client";
import { getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { GET_ASSET_SERVICE_COUNT, GET_ASSET_SERVICES } from "../Graphql/assetServiceQueries";
import { IAssetDamage, IAssetService } from "../asset.types";
import { GET_ASSET_DAMAGE_COUNT, GET_ASSET_DAMAGES } from '../Graphql/assetDamageQueries';
import { VehicleDamageCircumstance } from '../../Fleet/Issues&Damages/constants';
import { VehicleDamageCircumstancesValue, VehicleDamageStatus, VehicleDamageStatusValue } from '../../../../reducers/damageVehicle/types';

export const AssetDamages: FC = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [rows, setRows] = useState<IAssetDamage[]>([]);
  const [limit, setLimit] = useState(10);
  const { locale, currency } = userState.currentOrganisation;
  // const [assetService, setAssetService] = useState<IAssetService[]>([]);
  const [assetDamageCount, setAssetDamageCount] = useState<number>(0);

  const [loadAssetDamages, { loading, data }] = useLazyQuery(GET_ASSET_DAMAGES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.getAssetDamages) { }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
    }
  });

  const [getAssetServiceCount] = useLazyQuery(GET_ASSET_DAMAGE_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (assetCountData) => { setAssetDamageCount(assetCountData.getAssetCount) }
    }
  );

  useEffect(() => {
    if (userState.tenancy) {
      getAssetServiceCount();
      loadAssetDamages({
        variables: {
          limit,
          offset: 0
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    if (data && data.getAssetDamages) {
      setRows(data.getAssetDamages)
    }
  }, [data])

  const assetDamageTableColumn: any = [
    {
      label: "Reference Number",
      name: "referenceNumber"
    },
    {
      label: "Asset",
      name: "asset.assetTag"
    },
    {
      label: "Circumstance",
      name: "circumstance",
      options: {
        customBodyRender: (value: VehicleDamageCircumstance) => VehicleDamageCircumstancesValue[value]
      }
    },
    {
      label: "Incident Date",
      name: "incidentDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Est. Repair Date",
      name: "estimatedRepairDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED) : "NA"
      }
    },
    {
      label: "Total Expense",
      name: "totalExpense",
      options: {
        customBodyRender: (value: string) => toCurrency(parseInt(value), currency, locale)
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value: VehicleDamageStatus) => VehicleDamageStatusValue[value]
      }
    }
  ];

  const options: MUIDataTableOptions = {
    // count: assetServiceCount,
    selectableRows: SelectableRows.NONE,
    rowHover: true,
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const row: IAssetDamage = rows[rowMeta.dataIndex];
      if (row && row.id) {
        navigate(`/update-asset-damage?id=${row?.id}`);
      }
    },

    onChangeRowsPerPage: (numberOfRows: number) => {
      dispatch(
        addTableState({
          tableName: TableNames.ASSET_SERVICE,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage: 10,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.ASSET_SERVICE,
          columnName: changedColumn,
          direction
        })
      );
    }
  };

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={6}>
        <Typography variant="h1" color="primary">
          Asset Damage
        </Typography>
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end">
        <Fab
          variant="extended"
          size="medium"
          aria-label="Create"
          className="createButton"
          onClick={() => {
            navigate("/new-asset-damage");
          }}
        >
          Create
        </Fab>
      </Grid>
      <Grid container item xs={12}>
        {!loading ? (
          <NuvvenTable
            title=""
            rows={rows}
            columns={assetDamageTableColumn}
            options={options}
            setSelection={() => { }}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  )
}