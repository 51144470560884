import React from "react";

export const straightFrametrailerSvg = {
  interior: {
    width: 174.6,
    height: 500.9,
    svg: (
      <>
        <rect
          x="16.3"
          y="11.5"
          width="142.5"
          height="332.9"
          fill="none"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
        <rect
          x="16.3"
          y="365.2"
          width="142.5"
          height="121.8"
          fill="none"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
        <rect
          x="32.3"
          y="376.8"
          width="110.3"
          height="73.9"
          fill="none"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
        <text
          transform="translate(47.3 471.7)"
          fill="#231f20"
          font-family="MyriadPro-Regular, 'Myriad Pro'"
          font-size="12"
        >
          <tspan x="0" y="0">
            Refrigerator Unit
          </tspan>
        </text>
      </>
    )
  },
  exterior: {
    width: 560.7,
    height: 919.1,
    svg: (
      <>
        <rect
          x="196.8"
          y="246.8"
          width="163.4"
          height="438.1"
          fill="none"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
        <g>
          <rect
            x="197.8"
            y="21.3"
            width="161.4"
            height="178.1"
            fill="none"
            stroke="#231f20"
            stroke-miterlimit="10"
          />
          <rect
            x="197.8"
            y="199.4"
            width="18.2"
            height="20.5"
            fill="none"
            stroke="#231f20"
            stroke-miterlimit="10"
          />
          <rect
            x="341.1"
            y="199.4"
            width="18.2"
            height="20.5"
            fill="none"
            stroke="#231f20"
            stroke-miterlimit="10"
          />
        </g>
        <rect
          x="196.8"
          y="704.1"
          width="163.4"
          height="180.4"
          fill="none"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
        <line
          x1="278.5"
          y1="704.8"
          x2="278.5"
          y2="884.4"
          fill="none"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
        <polygon
          points="256.4 884.4 256.4 892.8 197.4 892.8 197.4 902.4 359.7 902.4 359.7 892.8 301.5 892.8 301.5 884.4 256.4 884.4"
          fill="none"
          stroke="#231f20"
          stroke-miterlimit="10"
        />
        <g>
          <rect
            x="-176.5"
            y="464.5"
            width="436.3"
            height="3.4"
            transform="translate(507.6 421.7) rotate(89.6)"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
          <path
            d="M39.9,368.1l-17.3,2v123.4s14.3.1,17.7,6.4v18.1s-2.3,7.1-17.7,6.8v12.9s13.2-1.1,17.2,5.3v19s-1.6,8-16.1,6.5v13.2s13.2-1.9,16.6,5.6v19.4s-5.5,6.1-16.1,5.5l8.9,69.4h8"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
          <g>
            <ellipse
              cx="26"
              cy="509.5"
              rx="13.2"
              ry="13.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
            <circle
              cx="26"
              cy="509.5"
              r="6.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
          </g>
          <g>
            <ellipse
              cx="25.2"
              cy="553.3"
              rx="13.2"
              ry="13.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
            <circle
              cx="25.2"
              cy="553.3"
              r="6.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
          </g>
          <g>
            <ellipse
              cx="26"
              cy="596.9"
              rx="13.2"
              ry="13.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
            <circle
              cx="26"
              cy="596.9"
              r="6.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
          </g>
          <rect
            x="28.7"
            y="301.9"
            width="6.1"
            height="14.1"
            transform="translate(340.7 277.2) rotate(90)"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
          <rect
            x="19.8"
            y="306"
            width="3.5"
            height="6.2"
            transform="translate(330.7 287.5) rotate(90)"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
          <polygon
            points="16 312.7 16 305.5 18.5 306.8 18.5 311.2 16 312.7"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
          <polygon
            points="43.8 684.4 42.9 385.6 42.8 376.4 42 248 167.7 247.4 167.7 684.4 43.8 684.4"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
        </g>
        <g>
          <rect
            x="295.9"
            y="464.5"
            width="436.3"
            height="3.4"
            transform="translate(983.4 -45) rotate(90.4)"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
          <path
            d="M515.8,368.1l17.3,2v123.4s-14.3.1-17.7,6.4v18.1s2.3,7.1,17.7,6.8v12.9s-13.2-1.1-17.2,5.3v19s1.6,8,16.1,6.5v13.2s-13.2-1.9-16.6,5.6v19.4s5.5,6.1,16.1,5.5l-8.9,69.4h-8"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
          <g>
            <ellipse
              cx="529.7"
              cy="509.5"
              rx="13.2"
              ry="13.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
            <circle
              cx="529.7"
              cy="509.5"
              r="6.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
          </g>
          <g>
            <ellipse
              cx="530.5"
              cy="553.3"
              rx="13.2"
              ry="13.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
            <circle
              cx="530.5"
              cy="553.3"
              r="6.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
          </g>
          <g>
            <ellipse
              cx="529.7"
              cy="596.9"
              rx="13.2"
              ry="13.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
            <circle
              cx="529.7"
              cy="596.9"
              r="6.2"
              fill="none"
              stroke="#221f1f"
              stroke-miterlimit="10"
            />
          </g>
          <rect
            x="520.9"
            y="301.9"
            width="6.1"
            height="14.1"
            transform="translate(832.9 -215) rotate(90)"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
          <rect
            x="532.4"
            y="306"
            width="3.5"
            height="6.2"
            transform="translate(843.2 -225) rotate(90)"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
          <polygon
            points="539.7 312.7 539.7 305.5 537.2 306.8 537.2 311.2 539.7 312.7"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
          <polygon
            points="511.9 684.4 512.8 385.6 512.9 376.4 513.7 248 388 247.4 388 684.4 511.9 684.4"
            fill="none"
            stroke="#221f1f"
            stroke-miterlimit="10"
          />
        </g>
      </>
    )
  }
};
