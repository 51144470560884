import { gql } from "@apollo/client";

export const ADD_VEHICLE_SERVICE_NOTE = gql`
  mutation addVehicleServiceNote($serviceId: ID! $note:VehicleServiceNoteInput!) {
    addVehicleServiceNote(serviceId: $serviceId,note:$note) {
      description
      createdBy {
          firstName
          lastName
      }
      createdDate
    }
  }
`;
