import { gql } from "@apollo/client";

export const GET_VEHICLES_LICENCE_PLATE = gql`
  query getOwnedAndExternalVehicles {
    getOwnedAndExternalVehicles {
      id
      licencePlate
      frogDiagram
      status
      isGhostVehicle
      branch
      vehicleType
      branchDetails{
        id
        name
      }
      fuelType
    }
  }
`;

export const GET_OWNED_VEHICLES = gql`
  query vehicles {
    vehicles {
      id
      licencePlate
      frogDiagram
      status
      isGhostVehicle
      branch
      vehicleType
      branchDetails{
        id
        name
      }
      fuelType
    }
  }
`;

export const GET_ADDONS_FOR_EXPENSE = gql`
  query addonTypes {
    addonTypes {
      id
      name
      displayName
      category
    }
  }
`;