import { useLazyQuery } from "@apollo/client";
import { CircularProgress, Grid, Theme, Typography } from "@mui/material";
import { ApolloError } from "@apollo/client";
import { createStyles, makeStyles } from "@mui/styles";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { GET_ALL_NOTICES } from "../../../graphql/pcn/getAllNoticesQuery";
import { IAppState } from "../../../store";
import { NuvvenTable } from "../../common/NuvvenTable/NuvvenTable";
import { SelectableRows } from "../../common/NuvvenTable/types";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { DATE_TYPE, formatGraphQLErrorMessage, returnSortedOrder, setTableSortOrder } from "../../common/utils";
import { addTableState } from "../../../actions/tableState/actions";
import { ITable, TableNames } from "../../../reducers/tableState/types";
import { GET_PCN_COUNT } from "../../../graphql/pcn/getPCNCount";
import { SEARCH_PCN } from "../../../graphql/pcn/searchPCNQuery";
import _ from "lodash";
import { getLocalizedDateFormat } from "../../../utils/localized.syntex";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: "100%",
  },
  tableContainer: {
    border: "solid 1px rgba(224, 224, 224, 1)",
    marginTop: "1rem"
  }
}))


const PenaltyChargeNotice: React.FC = () => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [rows, setRows] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [pcnCount, setPcnCount] = useState(0);
  const [pcnTable, setPcnTable] = useState<ITable>();
  const pcnTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.pcn
  );
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const dispatch = useDispatch();
  const [selection, setSelection] = useState<any[]>([]);
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;

  const [loadNotices, { loading: noticesLoading, data }] = useLazyQuery(
    GET_ALL_NOTICES,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [getPCNCount, { data: pcnCountData }] = useLazyQuery(
    GET_PCN_COUNT,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [searchPCN, { loading: searchPcnLoading, data: searchPcnData }] = useLazyQuery(
    SEARCH_PCN,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  useEffect(() => {
    if (userState.tenancy) {
      getPCNCount()
      loadNotices({
        variables: {
          limit,
          offset: 0
        }
      })
    }
  }, [userState])

  useEffect(() => {
    if (data && data.getAllPCN) {
      const pcns = [...rows, ...data.getAllPCN]
      setRows(pcns);
    }
  }, [data])

  useEffect(() => {
    if (pcnCountData && pcnCountData.pcnCount) {
      setPcnCount(pcnCountData.pcnCount)
    }
  }, [pcnCountData])

  useEffect(() => {
    if (pcnTableState) {
      setPcnTable(pcnTableState);
      let sortedOrder = returnSortedOrder(pcnTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, pcnTableState, sortedOrder)
      }
    }
  }, [pcnTableState]);

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchPCN({
        variables: {
          q: searchText
        }
      })
    }
  }, 1000);

  const columns = [
    {
      label: "Notice Number",
      name: "noticeRef"
    },
    {
      label: "Location",
      name: "location"
    },
    {
      label: "VRN",
      name: "vehicle.licencePlate"
    },
    {
      label: "Penalty Type",
      name: "noticeType",
    },
    {
      label: "Offence Date",
      name: "contraventionDate",
      options: {
        customBodyRender: (value: string) => getLocalizedDateFormat(country, value, DATE_TYPE.EXPANDED)
      }
    },
    {
      label: "Due Charge",
      name: "totalDue",
    }
  ]

  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: pcnCount,
    rowsPerPageOptions: [10, 20, 100],
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return; // Skip executing changePage if there is a search text
          }
          const { page, rowsPerPage } = tableState
          if (page * rowsPerPage >= rows.length) {
            loadNotices({
              variables: {
                limit: rowsPerPage,
                offset: page * rowsPerPage
              }
            })
          }
          break;
        case "changeRowsPerPage":
          setRows([])
          loadNotices({
            variables: {
              offset: 0,
              limit: tableState.rowsPerPage || limit
            }
          })
          break;
        default:
          break;
      }
    },
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: (noticesLoading) ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (rowData: string[]) => {
      if (rowData && rowData.length) {
        const noticeClicked = data?.getAllPCN.find(
          (row: any) => row.noticeRef === rowData[0]
        );
        if (noticeClicked) {
          navigate(`/notice-summary?noticeId=${noticeClicked.id}`)
        }
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.PCN,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      pcnTable && pcnTable.rowsPerPage
        ? pcnTable.rowsPerPage
        : limit,
    onTableInit: (action: string) => {
      if (pcnTable && sortOrder) {
        setTableSortOrder(columns, pcnTable, sortOrder);
      }
    },
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText)
      } else {
        getPCNCount()
        loadNotices({
          variables: {
            limit,
            offset: 0
          }
        })
      }
    },
  };

  return (
    <Grid container spacing={2} xs={12}>
      <Grid container item xs={4}>
        <Typography variant="h1" color="primary">
          Penalty  Charge Notice
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        {!(noticesLoading && !rows.length) ? (
          <NuvvenTable
            title={""}
            rows={rows}
            columns={columns}
            setSelection={setSelection}
            options={options}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  )
}

export default PenaltyChargeNotice;