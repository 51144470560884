import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { DateTime as d } from "luxon";
import { SimpleDateTimePicker } from "../../../../common/SimpleDateTimePicker";

interface IProps {
  open: boolean;
  completionDate: string;
  appointmentDate: string;
  handleClose: () => void;
  handleconfirm: (value: string) => void;
}

interface IDateTime {
  date: string;
  time: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiDialog-paper.MuiDialog-paperWidthSm": {
        maxWidth: 450
      }
    }
  })
);

export const ConfirmationModel: React.FC<IProps> = (props) => {

  const classes = useStyles()

  const [value, setValue] = useState<string>(props.completionDate)

  return (
    <Dialog
      fullWidth={true}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      className={`${classes.root} confirmation-dialog-service`}
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "20px 24px 10px" }}>
        <Typography variant="h2">
          Please confirm service completion date before completing the service</Typography>
      </DialogTitle>
      <DialogContent >
        <Grid container item xs={12} sx={{ marginTop: 1 }}>
          <SimpleDateTimePicker
            date={value || props.completionDate}
            handleChange={(date: IDateTime) => {
              const completionDate = d.fromISO(date.date).toUTC().toISO();
              setValue(completionDate)
            }}
            required={true}
            disabled={false}
            name={"completionDate"}
            dateTitle={"Completion Date"}
            timeTitle={"Completion Time"}
            minDate={d.fromISO(props.appointmentDate).plus({ minutes: 15 }).toUTC().toISO()}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleconfirm(value || props.completionDate)} color="primary">
          Confirm
        </Button>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
