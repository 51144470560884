import { gql } from "@apollo/client";

export const GET_UNPAID_INVOICES_FOR_BUSINESS_CUSTOMER = gql`
  query getUnpaidInvoicesForBusinessCustomer(
    $businessCustomerId: String!
    $fromDate: String
    $toDate: String
  ) {
    getUnpaidInvoicesForBusinessCustomer(
      businessCustomerId: $businessCustomerId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      customerType
      dueDate
      dueAmount
      booking {
        referenceNumber
      }
      tax {
        amount
        appliedBy
        appliedDate
        code
      }
      invoiceRef
      status
      locked
      invoiceType
      startDate
      endDate
      totalPayableAmount
      id
      customer {
        id
        firstName
        lastName
      }
      businessCustomer {
        id
        businessName
        registrationNumber
        VAT
      }
    }
  }
`;
