import { gql } from "@apollo/client";

export const GET_ADJUSTABLE_CREDIT_NOTES_FOR_BOOKING = gql`
  query adjustableCreditNotesForBooking($bookingId: String!){
    adjustableCreditNotesForBooking(bookingId: $bookingId) {
      id
      creditNoteRef
      creditNoteType
      totalAmount
      availableAmount
      refundedAmount
      createdAt
      versionNumber
      allocations {
        amount
        allocatedAt
        invoice {
          id
          invoiceRef
        }
      }
      refunds {
        amount
        refundRef
        status
        mode
        currency
        id
        paymentIntent
        paymentId
        paymentGateway
      }
      paymentsLinked {
        amount
        id
        status
      }
      invoice {
        invoiceRef
        id
      }
      booking {
        referenceNumber
        id
      }
      customer {
        firstName
        lastName
        id
        email
      }
      businessCustomer {
        businessName
        id
        billing {
          email
        }
        contact {
          firstName
          lastName
        }
      }
      creditNoteItems {
        type
        description
        totalAmount
        quantity
      }
    }
  }
`