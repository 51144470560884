import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { ApolloError } from "@apollo/client";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBranch } from "../../../../../actions/organisation/actions";
import { updateUserCurrentBranch } from "../../../../../actions/user/actions";
import { GET_ORGANISATIONS } from "../../../../../graphql/organisation/getOrganisationQuery";
import { IBranch, IOrganisation, ISubscriptionAgreementSettings } from "../../../../../reducers/organisation/types";
import { IAppState } from "../../../../../store";
import { getLocalizedBookingSyntex, getLocalizedOrganisationSyntex } from "../../../../../utils/localized.syntex";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from "../../../../common/utils";
import { NuvvenDivider } from "../../../ReservationManagement/Summary/Components/Divider";
import { CustomerType } from "../../../ReservationManagement/utils";
import { UserRoles } from "../../../../hoc/Authorization";
import PreviewSubscriptionAgreement from "./PreviewSubscriptionAgreement";
import { UPDATE_SUBSCRIPTION_AGREEMENT_SETTINGS } from "../../../../../graphql/organisation/updateSubscriptionAgreement";
import RowItemsSelector from "./RowItemSelector";
import { SettingsMap, CUSTOMER_INFO_LIST, SUBSCRIPTION_DETAILS, ADDON_DETAILS_LIST, INSURANCE_DETAILS_LIST, SUBSCRIPTION_EXTENSION_DETAILS_LIST, SUBSCRIPTION_VEHICLE_DETAILS_LIST, SUBSCRIPTION_OLD_VEHICLE_DETAILS, SUBSCRIPTION_PAYMENT_INFO_LIST, SUBSCRIPTION_DRIVER_DETAILS_LIST, SubscriptionAgreementSettingsMap } from "./utils";

interface IProps {
  customerType: string;
}

const CustomerBasedSettings: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const dispatch = useDispatch();
  const [organisations, setOrganisations] = useState<IOrganisation[]>([]);
  const [branches, setBranches] = useState<IBranch[]>([]);
  const [selectedOrganisationId, setSelectedOrganisationId] = useState<string>("");
  const [selectedBranchId, setSelectedBranchId] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<IBranch | any>();
  const [openPreview, setOpenPreview] = useState<boolean>(false)
  const [selectedOrgainisation, setSelectedOrganisation] = useState<IOrganisation>()
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;

  const [getOrganisations, { loading: organisationLoading, data: organisationsData }] = useLazyQuery(GET_ORGANISATIONS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (userState) {
      getOrganisations();
    }
  }, [userState])

  useEffect(() => {
    if (organisationsData && organisationsData.organisations) {
      setOrganisations(organisationsData.organisations)
    }
  }, [organisationsData])

  const updateSelected = (selected: string[], itemName: string, subscriptionAgreementSettingsMap?: any) => {
    const item = SettingsMap[itemName];
    const subscriptionAgreementSettings = { ...selectedBranch?.subscriptionAgreementSettings };
    let sASettingsMap = {...selectedBranch.subscriptionAgreementSettingsMap};
    if (subscriptionAgreementSettingsMap) {
      if (props.customerType === CustomerType.INDIVIDUAL) {
        sASettingsMap = {
          individual: _.merge(SubscriptionAgreementSettingsMap, sASettingsMap.individual, subscriptionAgreementSettingsMap),
          business: {
            ...SubscriptionAgreementSettingsMap,
            ...sASettingsMap.business
          }
        }
      } else {
        sASettingsMap = {
          business: _.merge(SubscriptionAgreementSettingsMap, sASettingsMap.business, subscriptionAgreementSettingsMap),
          individual: {
            ...SubscriptionAgreementSettingsMap,
            ...sASettingsMap.individual
          }
        }
      }
    }
    if (subscriptionAgreementSettings) {
      if (props.customerType === CustomerType.INDIVIDUAL) {
        subscriptionAgreementSettings.individual = {
          ...subscriptionAgreementSettings.individual,
          [item]: selected
        }
      } else {
        subscriptionAgreementSettings.business = {
          ...subscriptionAgreementSettings.business,
          [item]: selected
        }
      }
    }
    setSelectedBranch({
      ...selectedBranch,
      subscriptionAgreementSettings,
      subscriptionAgreementSettingsMap: sASettingsMap
    })
  }

  const [updateSubscriptionAgreementMutation, { loading: subscriptionAgreementLoading }] = useMutation(UPDATE_SUBSCRIPTION_AGREEMENT_SETTINGS, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      snackbar({
        message: "Subscription Agreement Settings updated",
        variant: SnackBarVariant.SUCCESS
      });
      dispatch(updateBranch(data.updateSubscriptionAgreementSettings));
      if (data.updateSubscriptionAgreementSettings.id === userState.currentBranch.id) {
        dispatch(updateUserCurrentBranch(data.updateSubscriptionAgreementSettings));
      }
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const handleSubmit = () => {
    const updateSubscriptionAgreementSettingsMap = Boolean(selectedBranch.subscriptionAgreementSettingsMap && Object.keys(selectedBranch.subscriptionAgreementSettingsMap).length);
    updateSubscriptionAgreementMutation({
      variables: {
        organisationId: selectedOrganisationId,
        branchId: selectedBranchId,
        subscriptionAgreementSettings: selectedBranch.subscriptionAgreementSettings,
        ...(updateSubscriptionAgreementSettingsMap && {
          subscriptionAgreementSettingsMap: selectedBranch.subscriptionAgreementSettingsMap
        }),
      }
    });
  }

  if (organisationLoading) {
    return <CircularProgress />
  }

  return (
    <Grid container>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="organisation-label">{getLocalizedOrganisationSyntex(country)}</InputLabel>
            <Select
              labelId="organisation-label"
              id="organisation-select-outlined"
              fullWidth
              value={selectedOrganisationId}
              onChange={(e: any) => {
                setSelectedOrganisationId(e.target.value)
                const org = organisations.find((item) => item.id === e.target.value);
                setSelectedOrganisation(org)
                if (org && org.branches) {
                  setBranches(org.branches);
                }
              }}
              label={getLocalizedOrganisationSyntex(country)}
            >
              {_.sortBy(organisations, (organisation) => organisation.name.toLowerCase()).map((organisation) => {
                return (
                  <MenuItem value={organisation.id}>
                    {organisation.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        {selectedOrganisationId && <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="branch-label">Branch</InputLabel>
            <Select
              labelId="branch-label"
              id="branch-select-outlined"
              fullWidth
              value={selectedBranchId}
              onChange={(e: any) => {
                setSelectedBranchId(e.target.value)
                const item = branches.find((branch) => branch.id === e.target.value);
                if (item) {
                  setSelectedBranch(item)
                }
              }}
              label="Branch"
            >
              {userState.role === UserRoles.BRANCH_MANAGER && userState.currentBranch.id ? (
                <MenuItem value={userState.currentBranch.id}>
                  {userState.currentBranch.name}
                </MenuItem>
              ) : (
                _.sortBy(branches, (branch) => branch.name.toLowerCase()).map((branch) => (
                  <MenuItem value={branch.id} key={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>}
      </Grid>
      {
        selectedOrganisationId && selectedBranchId && <Grid container xs={12}>
          <NuvvenDivider />
          <RowItemsSelector
            itemName="Customer Details"
            availableList={CUSTOMER_INFO_LIST}
            selectedList={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettings?.individual.customerDetails || [] :
              selectedBranch?.subscriptionAgreementSettings?.business.customerDetails || []}
            customFields={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettingsMap?.individual?.customerDetails || {} :
              selectedBranch?.subscriptionAgreementSettingsMap?.business?.customerDetails || {}}
            updateSelected={updateSelected}
          />
          <NuvvenDivider />
          <RowItemsSelector
            itemName={`Subscription Details`}
            availableList={SUBSCRIPTION_DETAILS}
            selectedList={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettings?.individual.subscriptionDetails || [] :
              selectedBranch?.subscriptionAgreementSettings?.business.subscriptionDetails || []}
            customFields={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettingsMap?.individual?.subscriptionDetails || {} :
              selectedBranch?.subscriptionAgreementSettingsMap?.business?.subscriptionDetails || {}}
            updateSelected={updateSelected}
          />
          <NuvvenDivider />
          <RowItemsSelector
            itemName="Extension Details"
            availableList={SUBSCRIPTION_EXTENSION_DETAILS_LIST}
            selectedList={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettings?.individual.bookingExtension || [] :
              selectedBranch?.subscriptionAgreementSettings?.business.bookingExtension || []}
            customFields={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettingsMap?.individual?.bookingExtension || {} :
              selectedBranch?.subscriptionAgreementSettingsMap?.business?.bookingExtension || {}}
            updateSelected={updateSelected}
          />
          <NuvvenDivider />
          <RowItemsSelector
            itemName="Previous Vehicle Details"
            availableList={SUBSCRIPTION_OLD_VEHICLE_DETAILS}
            selectedList={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettings?.individual.oldVehicleDetails || [] :
              selectedBranch?.subscriptionAgreementSettings?.business.oldVehicleDetails || []}
            customFields={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettingsMap?.individual?.oldVehicleDetails || {} :
              selectedBranch?.subscriptionAgreementSettingsMap?.business?.oldVehicleDetails || {}}
            updateSelected={updateSelected}
          />
          <NuvvenDivider />
          <RowItemsSelector
            itemName="Current Vehicle Details"
            availableList={SUBSCRIPTION_VEHICLE_DETAILS_LIST}
            selectedList={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettings?.individual.vehicleDetails || [] :
              selectedBranch?.subscriptionAgreementSettings?.business.vehicleDetails || []}
            customFields={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettingsMap?.individual?.vehicleDetails || {} :
              selectedBranch?.subscriptionAgreementSettingsMap?.business?.vehicleDetails || {}}
            updateSelected={updateSelected}
          />
          <NuvvenDivider />
          <RowItemsSelector
            itemName="Driver Details"
            availableList={SUBSCRIPTION_DRIVER_DETAILS_LIST}
            selectedList={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettings?.individual.driverDetails || [] :
              selectedBranch?.subscriptionAgreementSettings?.business.driverDetails || []}
            customFields={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettingsMap?.individual?.driverDetails || {} :
              selectedBranch?.subscriptionAgreementSettingsMap?.business?.driverDetails || {}}
            updateSelected={updateSelected}
          />
          <NuvvenDivider />
          <RowItemsSelector
            itemName="Add-on Details"
            availableList={ADDON_DETAILS_LIST}
            selectedList={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettings?.individual.addonDetails || [] :
              selectedBranch?.subscriptionAgreementSettings?.business.addonDetails || []}
            customFields={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettingsMap?.individual?.addonDetails || {} :
              selectedBranch?.subscriptionAgreementSettingsMap?.business?.addonDetails || {}}
            updateSelected={updateSelected}
          />
          <NuvvenDivider />
          <RowItemsSelector
            itemName="Insurance Details"
            availableList={INSURANCE_DETAILS_LIST}
            selectedList={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettings?.individual.insuranceDetails || [] :
              selectedBranch?.subscriptionAgreementSettings?.business.insuranceDetails || []}
            customFields={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettingsMap?.individual?.insuranceDetails || {} :
              selectedBranch?.subscriptionAgreementSettingsMap?.business?.insuranceDetails || {}}
            updateSelected={updateSelected}
          />
          <NuvvenDivider />
          <RowItemsSelector
            itemName="Payment Information"
            availableList={SUBSCRIPTION_PAYMENT_INFO_LIST}
            selectedList={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettings?.individual.paymentInfo || [] :
              selectedBranch?.subscriptionAgreementSettings?.business.paymentInfo || []}
            customFields={props.customerType === CustomerType.INDIVIDUAL ?
              selectedBranch?.subscriptionAgreementSettingsMap?.individual?.paymentInfo || {} :
              selectedBranch?.subscriptionAgreementSettingsMap?.business?.paymentInfo || {}}
            updateSelected={updateSelected}
          />
          <NuvvenDivider />
          <TextField
            multiline
            rows={5}
            fullWidth
            placeholder="Key Terms and Conditions"
            label="Key Terms and Conditions"
            name="tnc"
            inputProps={{
              maxLength: 5000,
              value: props.customerType === CustomerType.INDIVIDUAL ?
                selectedBranch?.subscriptionAgreementSettings?.individual.tnc || "" :
                selectedBranch?.subscriptionAgreementSettings?.business.tnc || "",
              onChange: (e: any) => {
                let subscriptionAgreementSettings = selectedBranch?.subscriptionAgreementSettings;
                if (subscriptionAgreementSettings) {
                  if (props.customerType === CustomerType.INDIVIDUAL) {
                    subscriptionAgreementSettings = {
                      ...subscriptionAgreementSettings,
                      individual: {
                        ...subscriptionAgreementSettings.individual,
                        tnc: e.target.value
                      }
                    }
                  } else {
                    subscriptionAgreementSettings = {
                      ...subscriptionAgreementSettings,
                      business: {
                        ...subscriptionAgreementSettings.business,
                        tnc: e.target.value
                      }
                    }
                  }
                  setSelectedBranch({
                    ...selectedBranch,
                    subscriptionAgreementSettings
                  })
                }
              }
            }}
          />
          <Grid container xs={12} justifyContent="flex-end">
            <Typography>
              (5000 characters max)
            </Typography>
          </Grid>
          <NuvvenDivider />
          <TextField
            multiline
            rows={5}
            fullWidth
            placeholder="Insurance Declaration"
            label="Insurance Declaration"
            name="insuranceDeclaration"
            inputProps={{
              maxLength: 3000,
              value: props.customerType === CustomerType.INDIVIDUAL ?
                selectedBranch?.subscriptionAgreementSettings?.individual.insuranceDeclaration || "" :
                selectedBranch?.subscriptionAgreementSettings?.business.insuranceDeclaration || "",
              onChange: (e: any) => {
                let subscriptionAgreementSettings = selectedBranch?.subscriptionAgreementSettings;
                if (subscriptionAgreementSettings) {
                  if (props.customerType === CustomerType.INDIVIDUAL) {
                    subscriptionAgreementSettings = {
                      ...subscriptionAgreementSettings,
                      individual: {
                        ...subscriptionAgreementSettings.individual,
                        insuranceDeclaration: e.target.value
                      }
                    }
                  } else {
                    subscriptionAgreementSettings = {
                      ...subscriptionAgreementSettings,
                      business: {
                        ...subscriptionAgreementSettings.business,
                        insuranceDeclaration: e.target.value
                      }
                    }
                  }
                  setSelectedBranch({
                    ...selectedBranch,
                    subscriptionAgreementSettings
                  })
                }
              }
            }}
          />
          <Grid container xs={12} justifyContent="flex-end">
            <Typography>
              (3000 characters max)
            </Typography>
          </Grid>
          <NuvvenDivider />
          <TextField
            multiline
            rows={5}
            fullWidth
            placeholder="Cancellation Policy"
            label="Cancellation Policy"
            name="cancellationPolicy"
            inputProps={{
              maxLength: 3000,
              value: props.customerType === CustomerType.INDIVIDUAL ?
                selectedBranch?.subscriptionAgreementSettings?.individual.cancellationPolicy || "" :
                selectedBranch?.subscriptionAgreementSettings?.business.cancellationPolicy || "",
              onChange: (e: any) => {
                let subscriptionAgreementSettings: ISubscriptionAgreementSettings = { ...selectedBranch?.subscriptionAgreementSettings };
                if (subscriptionAgreementSettings) {
                  if (props.customerType === CustomerType.INDIVIDUAL) {
                    subscriptionAgreementSettings = {
                      ...subscriptionAgreementSettings,
                      individual: {
                        ...subscriptionAgreementSettings.individual,
                        cancellationPolicy: e.target.value
                      }
                    }
                  } else {
                    subscriptionAgreementSettings = {
                      ...subscriptionAgreementSettings,
                      business: {
                        ...subscriptionAgreementSettings.business,
                        cancellationPolicy: e.target.value
                      }
                    }
                  }
                  setSelectedBranch({
                    ...selectedBranch,
                    subscriptionAgreementSettings
                  })
                }
              }
            }}
          />
          <Grid container xs={12} justifyContent="flex-end">
            <Typography>
              (3000 characters max)
            </Typography>
          </Grid>
          <NuvvenDivider />
          <Grid container xs={12} spacing={1}>
            <Grid item xs={3}>
              <Paper variant="outlined">
                <Box p={2}>
                  <Typography variant="body1">
                    <Grid item container xs={12}>
                      <Grid xs={10}>
                        <Typography variant="h4">
                          Security Deposit
                        </Typography>
                      </Grid>
                      <Grid xs={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.customerType === CustomerType.INDIVIDUAL ?
                                selectedBranch?.subscriptionAgreementSettings?.individual.securityDeposit || false :
                                selectedBranch?.subscriptionAgreementSettings?.business.securityDeposit || false}
                              onClick={() => {
                                let subscriptionAgreementSettings: ISubscriptionAgreementSettings = { ...selectedBranch?.subscriptionAgreementSettings };
                                if (subscriptionAgreementSettings) {
                                  if (props.customerType === CustomerType.INDIVIDUAL) {
                                    subscriptionAgreementSettings.individual = {
                                      ...subscriptionAgreementSettings.individual,
                                      securityDeposit: !subscriptionAgreementSettings.individual.securityDeposit
                                    }
                                  } else {
                                    subscriptionAgreementSettings.business = {
                                      ...subscriptionAgreementSettings.business,
                                      securityDeposit: !subscriptionAgreementSettings.business.securityDeposit
                                    }
                                  }
                                  setSelectedBranch({
                                    ...selectedBranch,
                                    subscriptionAgreementSettings
                                  })
                                }
                              }}
                              value="checkedSwitch"
                            />
                          }
                          label=""
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper variant="outlined">
                <Box p={2}>
                  <Typography variant="body1">

                    <Grid item container xs={12}>
                      <Grid xs={10}>
                        <Typography variant="h4">
                          {`${getLocalizedBookingSyntex(country)} Questions`}
                        </Typography>
                      </Grid>
                      <Grid xs={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.customerType === CustomerType.INDIVIDUAL ?
                                selectedBranch?.subscriptionAgreementSettings?.individual.preBookingQuestions || false :
                                selectedBranch?.subscriptionAgreementSettings?.business.preBookingQuestions || false}
                              onClick={() => {
                                let subscriptionAgreementSettings: ISubscriptionAgreementSettings = { ...selectedBranch?.subscriptionAgreementSettings };
                                if (subscriptionAgreementSettings) {
                                  if (props.customerType === CustomerType.INDIVIDUAL) {
                                    subscriptionAgreementSettings.individual = {
                                      ...subscriptionAgreementSettings.individual,
                                      preBookingQuestions: !subscriptionAgreementSettings.individual.preBookingQuestions
                                    }
                                  } else {
                                    subscriptionAgreementSettings.business = {
                                      ...subscriptionAgreementSettings.business,
                                      preBookingQuestions: !subscriptionAgreementSettings.business.preBookingQuestions
                                    }
                                  }
                                  setSelectedBranch({
                                    ...selectedBranch,
                                    subscriptionAgreementSettings
                                  })
                                }
                              }}
                              value="checkedSwitch"
                            />
                          }
                          label=""
                        />
                      </Grid>

                    </Grid>
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper variant="outlined">
                <Box p={2}>
                  <Typography variant="body1">

                    <Grid item container xs={12}>
                      <Grid xs={10}>
                        <Typography variant="h4">
                          {"Additional Driver Questions And Signature"}
                        </Typography>
                      </Grid>
                      <Grid xs={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                props.customerType === CustomerType.INDIVIDUAL
                                  ? selectedBranch?.subscriptionAgreementSettings?.individual.additionalDriverQuestions || false
                                  : selectedBranch?.subscriptionAgreementSettings?.business.additionalDriverQuestions || false
                              }
                              onClick={() => {
                                let subscriptionAgreementSettings: ISubscriptionAgreementSettings = { ...selectedBranch?.subscriptionAgreementSettings };
                                if (subscriptionAgreementSettings) {
                                  const newSubscriptionAgreementSettings = {
                                    ...subscriptionAgreementSettings,
                                    individual: {
                                      ...subscriptionAgreementSettings.individual,
                                      additionalDriverQuestions: props.customerType === CustomerType.INDIVIDUAL
                                        ? !subscriptionAgreementSettings.individual.additionalDriverQuestions
                                        : subscriptionAgreementSettings.individual.additionalDriverQuestions
                                    },
                                    business: {
                                      ...subscriptionAgreementSettings.business,
                                      additionalDriverQuestions: props.customerType === CustomerType.BUSINESS
                                        ? !subscriptionAgreementSettings.business.additionalDriverQuestions
                                        : subscriptionAgreementSettings.business.additionalDriverQuestions
                                    }
                                  };

                                  setSelectedBranch({
                                    ...selectedBranch,
                                    subscriptionAgreementSettings: newSubscriptionAgreementSettings
                                  });
                                }
                              }}
                              value="checkedSwitch"
                            />

                          }
                          label=""
                        />
                      </Grid>

                    </Grid>
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper variant="outlined">
                <Box p={2}>
                  <Typography variant="body1">
                    <Grid item container xs={12}>
                      <Grid xs={10}>
                        <Typography variant="h4">
                          Vehicle Handover Questions
                        </Typography>
                      </Grid>
                      <Grid xs={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.customerType === CustomerType.INDIVIDUAL ?
                                selectedBranch?.subscriptionAgreementSettings?.individual.deliveryQuestions || false :
                                selectedBranch?.subscriptionAgreementSettings?.business.deliveryQuestions || false}
                              onClick={() => {
                                const subscriptionAgreementSettings: ISubscriptionAgreementSettings = { ...selectedBranch?.subscriptionAgreementSettings };
                                if (subscriptionAgreementSettings) {
                                  if (props.customerType === CustomerType.INDIVIDUAL) {
                                    subscriptionAgreementSettings.individual = {
                                      ...subscriptionAgreementSettings.individual,
                                      deliveryQuestions: !subscriptionAgreementSettings.individual.deliveryQuestions
                                    }
                                  } else {
                                    subscriptionAgreementSettings.business = {
                                      ...subscriptionAgreementSettings.business,
                                      deliveryQuestions: !subscriptionAgreementSettings.business.deliveryQuestions
                                    }
                                  }
                                  setSelectedBranch({
                                    ...selectedBranch,
                                    subscriptionAgreementSettings
                                  })
                                }
                              }}
                              value="checkedSwitch"
                            />
                          }
                          label=""
                        />
                      </Grid>

                    </Grid>
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper variant="outlined">
                <Box p={2}>
                  <Typography variant="body1">
                    <Grid item container xs={12}>
                      <Grid xs={10}>
                        <Typography variant="h4">
                          Additional Customer Information
                        </Typography>
                      </Grid>
                      <Grid xs={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.customerType === CustomerType.INDIVIDUAL ?
                                selectedBranch?.subscriptionAgreementSettings?.individual.additionalInfo || false :
                                selectedBranch?.subscriptionAgreementSettings?.business.additionalInfo || false}
                              onClick={() => {
                                let subscriptionAgreementSettings: ISubscriptionAgreementSettings = { ...selectedBranch?.subscriptionAgreementSettings };
                                if (subscriptionAgreementSettings) {
                                  if (props.customerType === CustomerType.INDIVIDUAL) {
                                    subscriptionAgreementSettings.individual = {
                                      ...subscriptionAgreementSettings.individual,
                                      additionalInfo: !subscriptionAgreementSettings.individual.additionalInfo
                                    }
                                  } else {
                                    subscriptionAgreementSettings.business = {
                                      ...subscriptionAgreementSettings.business,
                                      additionalInfo: !subscriptionAgreementSettings.business.additionalInfo
                                    }
                                  }
                                  setSelectedBranch({
                                    ...selectedBranch,
                                    subscriptionAgreementSettings
                                  })
                                }
                              }}
                              value="checkedSwitch"
                            />
                          }
                          label=""
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper variant="outlined">
                <Box p={2}>
                  <Typography variant="body1">
                    <Grid item container xs={12}>
                      <Grid xs={10}>
                        <Typography variant="h4">
                          {"Additional T&C"}
                        </Typography>
                      </Grid>
                      <Grid xs={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.customerType === CustomerType.INDIVIDUAL ?
                                selectedBranch?.subscriptionAgreementSettings?.individual.additonalTnc || false :
                                selectedBranch?.subscriptionAgreementSettings?.business.additonalTnc || false}
                              onClick={() => {
                                let subscriptionAgreementSettings: ISubscriptionAgreementSettings = { ...selectedBranch?.subscriptionAgreementSettings };
                                if (subscriptionAgreementSettings) {
                                  if (props.customerType === CustomerType.INDIVIDUAL) {
                                    subscriptionAgreementSettings.individual = {
                                      ...subscriptionAgreementSettings.individual,
                                      additonalTnc: !subscriptionAgreementSettings.individual.additonalTnc
                                    }
                                  } else {
                                    subscriptionAgreementSettings.business = {
                                      ...subscriptionAgreementSettings.business,
                                      additonalTnc: !subscriptionAgreementSettings.business.additonalTnc
                                    }
                                  }
                                  setSelectedBranch({
                                    ...selectedBranch,
                                    subscriptionAgreementSettings
                                  })
                                }
                              }}
                              value="checkedSwitch"
                            />
                          }
                          label=""
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <NuvvenDivider />
          <Grid container item xs={12} >
            <Fab
              variant="extended"
              size="medium"
              onClick={() => setOpenPreview(true)}
            >
              Preview
            </Fab>
            <Box ml={2}></Box>
            <Fab
              className="blackBackButton"
              variant="extended"
              size="medium"
              onClick={() => {
                setSelectedBranchId("");
                setSelectedOrganisationId("");
                getOrganisations();
              }}
            >
              CANCEL
            </Fab>
            <Box ml={2}></Box>
            <Fab
              variant="extended"
              size="medium"
              onClick={handleSubmit}
              disabled={subscriptionAgreementLoading}
            >
              {subscriptionAgreementLoading && (
                <CircularProgress
                  size={14}
                  style={{ color: "white", marginRight: "10px" }}
                />
              )}
              SAVE
            </Fab>
          </Grid>
          {
            openPreview && (
              <PreviewSubscriptionAgreement
                open={openPreview}
                onClose={() => setOpenPreview(false)}
                customerType={props.customerType}
                selectedBranch={props.customerType === CustomerType.INDIVIDUAL ?
                  selectedBranch?.subscriptionAgreementSettings?.individual :
                  selectedBranch?.subscriptionAgreementSettings?.business
                }
                organisation={selectedOrgainisation}
                customSettings={selectedBranch.subscriptionAgreementSettingsMap ? selectedBranch.subscriptionAgreementSettingsMap[props.customerType] : {}}
              />
            )
          }
        </Grid>
      }
    </Grid>
  )
};

export default CustomerBasedSettings;