import _ from "lodash";

import {
  CLEAR_VEHICLE_PRICE_GROUP,
  UPDATE_VEHICLE_PRICE_GROUP,
  VehiclePriceGroupAction
} from "../../actions/vehiclePriceGroup/types";
import { vehiclePriceGroupInitialState } from "./consts";
import { IVehiclePriceGroupState } from "./types";

export default function(
  state: IVehiclePriceGroupState = vehiclePriceGroupInitialState,
  action: VehiclePriceGroupAction
): IVehiclePriceGroupState {
  switch (action.type) {
    case UPDATE_VEHICLE_PRICE_GROUP:
      return {
        ...state,
        vehiclePriceGroup: {
          ...state.vehiclePriceGroup,
          ...action.payload
        }
      };
    case CLEAR_VEHICLE_PRICE_GROUP:
      return {
        ...vehiclePriceGroupInitialState
      };
    default:
      return state;
  }
}
