import { gql } from "@apollo/client";

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $customerId: String!
    $customer: CustomerUpdateInput!
  ) {
    updateCustomer(customerId: $customerId, customer: $customer) {
      id
      status
      firstName
      lastName
      taxExempted
      phoneNumber {
        phone
        country
      }
      email
      dateOfBirth
      gender
      address
      secondaryPhoneNumber {
        phone
        country
      }
      secondaryEmail
      location {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      license {
        licenseNumber
        dateOfExpiry
        dateOfIssue
        authority
        endorsement
        offences
        points
        images
      }
      verified
      verificationMethod
      deleted
      profilePicture
      nationalInsuranceNumber
      profession
      documents {
        id
        documentKey
        documentName
        title
        expiryDate
        documentType
        createdAt
      }
      notes {
        id
        description
        createdBy
        createdDate
      }
      accountNumber
      overrideInvoiceDate
      overrideWeeklyInvoiceDate
      weeklyInvoicingDay
      invoicingDay
      credentialSent
      credentialSentOn
      autoPayDisabled
    }
  }
`;
