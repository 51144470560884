import { createTheme } from '@mui/material';
import './common.css';
import overrides from "./overrides";
import palette from "./palette";
import typography from "./typography";

const theme = createTheme({
  palette,
  typography,
  components: overrides,
  spacing: 10,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
