import { gql } from "@apollo/client";

export const ADD_SUBSCRIPTION_TO_VEHICLE = gql`
  mutation addSubscriptionToVehicle(
    $branchId: String!
    $vehicleId: String!
    $subscriptions: [SubcriptionInfoInput]
  ) {
    addSubscriptionToVehicle(
      branchId: $branchId
      vehicleId: $vehicleId
      subscriptions: $subscriptions
    ) {
      id
      subscriptions {
        subscription {
          id
          name
        }
        pricings {
          duration
          enrollmentAmount
          mileage
          subscriptionAmount
          pricePerExtraMile
          unlimitedMileage
        }
      }
    }
  }
`;
