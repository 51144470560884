import { gql } from "@apollo/client";

export const GET_ADDONS_TYPES_FOR_SUBSCRIPTION = gql`
  {
    addonTypes {
      id
      name
      displayName
      isActivated
    }
  }
`;
