import {
  Grid,
  ButtonBase,
  Card,
  Box,
  Typography,
  Collapse
} from "@mui/material";
import CardContent from '@mui/material/CardContent';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../../store";
import { INewVehicleDamage } from "../../../Issues&Damages/NewDamage";
import { DamageRowDetail } from "./DamageRowDetail";
import { DATE_TYPE } from "../../../../../common/utils";
import { getLocalizedDateFormat } from "../../../../../../utils/localized.syntex";

interface IProps {
  row: INewVehicleDamage;
  index: number;
}

export const MobileDamageCard = (props: IProps) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [expanded, setExpanded] = useState<boolean>(false);

  const borderColour = "rgba(0, 0, 0, 1)"

  return (
    <ButtonBase style={{ marginTop: 8, marginBottom: 8 }} onClick={() => {
      if (expanded) {
        setExpanded(false);
      } else {
        setExpanded(true);
      }
    }} >
      <Card style={{
        width: 'calc(100vw - 120px)',
        border: "2px solid",
        borderColor: `${borderColour}`,
        borderRadius: "3%",
      }}>
        <CardContent>
          <Grid container item xs={12} justifyContent='center'>
            <Grid item xs={6}>
              <Typography>Report Date:</Typography>
              <Typography>{getLocalizedDateFormat(country, props.row.reportDate || "", DATE_TYPE.CONDENSED)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Incident Date:</Typography>
              <Typography>{getLocalizedDateFormat(country, props.row.incidentDate || "", DATE_TYPE.CONDENSED)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mt={1} />
            </Grid>
            <Grid item xs={6}>
              <Typography>Reference Number:</Typography>
              <Typography>{props.row.referenceNumber}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Cost:</Typography>
              <Typography>{props.row.estimatedCost}</Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Collapse in={expanded} unmountOnExit>
          <DamageRowDetail row={props.row} />
        </Collapse>
      </Card>
    </ButtonBase>
  );
};
