import { gql } from "@apollo/client";

export const MARK_INVOICE_VOID = gql`
  mutation markInvoiceAsVoid($invoiceId: String!) {
    markInvoiceAsVoid(invoiceId: $invoiceId) {
      id
      status
      dueAmount
      confirmedAt
    }
  }
`;