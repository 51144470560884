import {
  AppBar,
  CssBaseline,
  Grid,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { getLocalizedRASyntex } from "../../../../../utils/localized.syntex";
import { TabPanel } from "../../../../common/TabPannel/TabPannel";
import { CustomerType } from "../../../ReservationManagement/utils";
import CustomerBasedSettings from "./CustomerBasedSettings";
import { BOOKING_TYPE } from "../../../../../reducers/organisation/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    }
  })
);

const SubscriptionAgreementSettings: React.FC = () => {
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid container item xs={12}>
        <Typography variant="h1" color="primary">
          {`${getLocalizedRASyntex(country, BOOKING_TYPE.SUBSCRIPTION)} Settings`}
        </Typography>
      </Grid>
      <Grid container>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons allowScrollButtonsMobile
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            <Tab label="Individual Customers" />
            <Tab label="Corporate Customers" />
          </Tabs>
        </AppBar>
        <Paper className={classes.root}>
          <TabPanel value={value} index={0}>
            <CustomerBasedSettings customerType={CustomerType.INDIVIDUAL} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CustomerBasedSettings customerType={CustomerType.BUSINESS} />
          </TabPanel>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SubscriptionAgreementSettings;