import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Typography } from '@mui/material'
import React from 'react'

interface IProps {
	open: boolean;
	onClose(): void;
	onConfirm(): void;
	description: string;
	title: string;
}

export const ConfirmationDialog: React.FC<IProps> = (props) => {
	return (
		<Dialog
			maxWidth="xs"
			aria-labelledby="confirmation-dialog-title"
			open={props.open}
		>
			<DialogTitle id="confirmation-dialog-title"><Typography variant="h2" color='primary'>{props.title}</Typography></DialogTitle>
			<DialogContent>
				<Typography variant={"h4"}>{props.description}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onConfirm} color='primary' >
					Confirm
				</Button>
				<Button onClick={props.onClose} color='primary'>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	)
}
