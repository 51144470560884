import { InvoicesPayloadType } from "../../reducers/invoices/types";

export const UPDATE_INVOICE = "UPDATE_INVOICE";

interface IUpdateInvoice {
  payload: InvoicesPayloadType;
  type: typeof UPDATE_INVOICE;
}

export type InvoicesAction = IUpdateInvoice;
