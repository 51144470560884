import React from "react";

import AccountSettings from "./../../../../assets/icons/AccountSettings.svg";
// import Insurance from "./../../../../assets/icons/Insurance.svg";
import Addons from "./../../../../assets/icons/addons-settings.svg";
import OrgStructure from "./../../../../assets/icons/organization1.svg";
import PaymentConfig from "./../../../../assets/icons/payment-config.svg";
import { SettingsGrid } from "./../../../common/SettingsGrid/SettingsGrid";

const options = [
  {
    icon: AccountSettings,
    location: "/account-settings",
    title: "Account Settings"
  },
  // {
  //   icon: Insurance,
  //   location: "/insurance-settings",
  //   title: "Insurance"
  // },
  {
    icon: PaymentConfig,
    location: "/integrations",
    title: "Payment Configuration"
  },
  {
    icon: Addons,
    location: "/addons",
    title: "Add-ons & Accessories"
  },
  {
    icon: OrgStructure,
    location: "/org-structure",
    title: "Organisation Structure"
  },
  {
    icon: AccountSettings,
    location: "/users",
    title: "Users"
  }
];

export function GeneralSettings() {
  return <SettingsGrid options={options} />;
}
