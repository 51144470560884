import { gql } from "@apollo/client";

export const GET_VEHICLES_TO_DEFLEET = gql`
  {
    getVehiclesToDefleet {  
      id
      licencePlate
      residualValue
      deFleetReason
      deFleetParameters {
        byDate 
        byDistanceDriven
        alertBeforeDistance
        alertBeforeDays
      }
      vehicleState {
        id
        odometerReading
      }
    }
  }
`;