
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Fab,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
import { IBooking } from "../../../reducers/bookings/types";
import { getSignedUrl } from "../../../utils/getSignedUrl";
import { BookingStatus } from "../../views/ReservationManagement/utils";


import { checkDeleteOrDownloadFileType } from "../utils";

interface IProps {
  open: boolean;
  error?: string;
  document: string | undefined;
  fileName: string;
  title: string;
  raLoading: boolean;
  handleClose: () => void;
  handleMail: () => void;
  regeneratePdf?: () => void;
  booking?: IBooking;
  sendEmail: boolean;
  renderMode?: "canvas" | "svg";
}

const options = {
  cMapUrl: '/cmaps/',
};

const PdfViewer = (props: IProps) => {
  const [numPages, setNumPages] = useState<number>(1);
  const [pdf, setPdf] = useState<string>("");
  const [pdfLoadError, setPDFLoadError] = useState<boolean>(false);
  const documentId = props.document;
  const [openPdf, setOpenPdf] = useState<boolean>(false);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (documentId) {
      getPdf(documentId);
      setPDFLoadError(false);
    } else {
      setPDFLoadError(true);
    }
  }, [documentId]);

  async function getPdf(conditionsUrl: string) {
    const { contentType } = checkDeleteOrDownloadFileType(conditionsUrl);
    const file = await getSignedUrl(conditionsUrl);
    setPdf(file);
  }
  const [open, setOpen] = useState(props?.open ? true : false);

  async function download(url: any) {
    setOpenPdf(true);
    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        console.log(blob)
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = props.fileName ? props.fileName : `Rental_agreement.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setOpenPdf(false);
      });
  }
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container xs={12} justifyContent="flex-end">
              {props.regeneratePdf && (
                <Fab
                  size={"medium"}
                  variant={"extended"}
                  style={{ marginRight: 10 }}
                  onClick={props.regeneratePdf}
                  disabled={props.raLoading}
                >
                  {props.raLoading && (
                    <CircularProgress
                      size={14}
                      style={{ color: "white", marginRight: "10px" }}
                    />
                  )}
                  {"REGENERATE"}
                </Fab>
              )}
              {props.sendEmail &&
                props.booking &&
                props.booking.status === BookingStatus.IN_PROGRESS && (
                  <Fab
                    size={"medium"}
                    variant={"extended"}
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      props.handleMail();
                      props.handleClose();
                    }}
                  >
                    {"Send E-Mail"}
                  </Fab>
                )}
              <Fab
                size={"medium"}
                variant={"extended"}
                onClick={() => download(pdf)}
              >
                {openPdf && (
                  <CircularProgress
                    size={14}
                    style={{ color: "white", marginRight: "10px" }}
                  />
                )}
                {"DOWNLOAD"}
              </Fab>
            </Grid>
          </Grid>

          {pdf && !pdfLoadError ? (
            <div className="pd-viewer-dialog-page">
              <Document
                file={pdf}
                options={options}
                error={props.error || "Failed to load PDF file."}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<CircularProgress size={26} thickness={5} />}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    width={845}
                    scale={0.99}
                    pageNumber={index + 1}
                    renderAnnotationLayer={true}
                    renderMode={props.renderMode || "canvas"}
                  />
                ))}
              </Document>
            </div>
          ) : pdfLoadError ? (
            <p>{props.error}</p>
          ) : (
            <CircularProgress size={26} thickness={5} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default PdfViewer;
