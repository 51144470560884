export const vehicleDamageImages = [
  {
    id: "carFrontImg",
    class: "carFrontImg",
    title: "Front Side"
  },
  {
    id: "carFrontMirrorImg",
    class: "carFrontMirrorImg",
    title: "Front Mirror"
  },
  {
    id: "carTopImg",
    class: "carTopImg",
    title: "Top Side"
  },
  {
    id: "carBackMirrorImg",
    class: "carBackMirrorImg",
    title: "Back Morror"
  },
  {
    id: "carBackImg",
    class: "carBackImg",
    title: "Back Side"
  },
  {
    id: "carLeftImg",
    class: "carLeftImg",
    title: "Left Side"
  },
  {
    id: "carRightImg",
    class: "carRightImg",
    title: "Right Side"
  }
];
export const viewColumns = [
  {
    name: "reportDate",
    label: "Reported Date"
  },
  {
    name: "incidentDate",
    label: "Incident Date"
  },
  {
    name: "referenceNumber",
    label: "Reference No."
  },
  {
    name: "estimatedCost",
    label: "Cost"
  }
];

export const damageTableColoumn = [
  {
    name: "id",
    label: "ID"
  },
  {
    label: "Reported",
    name: "reported"
  },
  {
    label: "Reported By",
    name: "reportedBy"
  },
  {
    label: "Updated",
    name: "updated"
  },
  {
    label: "Updated By",
    name: "updatedBy"
  },
  {
    label: "Status",
    name: "status"
  }
];

export const damageTableRows = [
  {
    id: "NUVV120",
    reported: "12/10/2010",
    reportedBy: "John Doe",
    updated: "12/10/2020",
    updatedBy: "Alex",
    status: "Reported"
  },
  {
    id: "NUVV120",
    reported: "12/10/2010",
    reportedBy: "John Doe",
    updated: "12/10/2020",
    updatedBy: "Alex",
    status: "Reported"
  }
];

export const damageReportsConstants = [
  {
    label: "DOCUMENT NAME",
    name: "documentName"
  },
  {
    label: "DESCRIPTION",
    name: "description"
  }
];

export const bookingReference = [
  {
    label: "None",
    name: "none"
  }
];

export const generalConditions = [
  {
    label: "No Odors",
    name: "noOrders"
  },
  {
    label: "Front Seat Area Clean",
    name: "frontSeatAreaClean"
  },
  {
    label: "Rear Seat Area Clean",
    name: "rearSeatAreaClean"
  },
  {
    label: "Front & Rear Carpets Clean",
    name: "forntAndCarpetsClean"
  },
  {
    label: "Front & Rear Mats Installed Properly",
    name: "forntAndRearMatsInstalled"
  }
];

export const frontSeatOptions = [
  {
    label: "Clean",
    name: "frontSeatClean"
  },
  {
    label: "Controls in Good Working Order",
    name: "controlsInGoodWorkingOrder"
  },
  {
    label: "Seat Belts in Good Working Order",
    name: "seatBeltsInGoodWorkingOrder"
  }
];

export const rearSeatOptions = [
  {
    label: "Clean",
    name: "rearSeatClean"
  },
  {
    label: "Controls in Good Working Order",
    name: "rearControlsInGoodWorkingOrder"
  },
  {
    label: "Seat Belts in Good Working Order",
    name: "rearSeatBeltsInGoodWorkingOrder"
  }
];

export const lockAndSwitchOptions = [
  {
    label: "Front Door Lock and Switch",
    name: "frontDoorLockAndSwitch"
  },
  {
    label: "Rear Door Lock and Switch",
    name: "rearDoorLockAndSwitch"
  },
  {
    label: "Front Window Switch",
    name: "frontWindowSwitch"
  },
  {
    label: "Rear Window Switch",
    name: "rearWindowSwitch"
  },
  {
    label: "Child Lock Working",
    name: "childLockWorking"
  }
];

export const defaultAccessoriesCheck = [
  {
    label: "Jack",
    name: "accJack"
  },
  {
    label: "Wrench",
    name: "assWrench"
  },
  {
    label: "Stepney",
    name: "accStepney"
  },
  {
    label: "Tyre Opener",
    name: "accTyreOpener"
  },
  {
    label: "Rear Camera",
    name: "accRearCamera"
  }
];

export const tyresAndAlloys = [
  {
    label: "Front Left",
    name: "tyresFrontLeft"
  },
  {
    label: "Front Right",
    name: "tyresFrontRight"
  },
  {
    label: "Rear Left",
    name: "tyresRearLeft"
  },
  {
    label: "Rear Right",
    name: "tyresRearRight"
  }
];
