import { gql } from "@apollo/client";

export const MOBILISE_VEHICLE = gql`
  mutation mobiliseVehicle($deviceEsn: String!, $vehicleId: String) {
    mobiliseVehicle(deviceEsn: $deviceEsn, vehicleId: $vehicleId) {
      success
      message
    }
  }
`;
