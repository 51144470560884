import { gql } from "@apollo/client";

export const GET_VEHICLES_STATUS = gql`
  {
    vehicles {
      status
      vehicleTestDueDate
    }
  }
`;
