export default {
  styleOverrides: {
    root: {
      backgroundColor: "#FFFF",
      "& .MuiTab-root": {
        minWidth: 140
      }
    }
  }
};
