import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ICreditNote, ICreditNoteAllocation, ICreditNoteItem, IRefund } from "../../../../../reducers/bookings/types";
import { IAppState } from "../../../../../store";
import { DATE_TYPE, toCurrency } from "../../../../common/utils";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RefundStatus } from "../const";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";

interface IProps {
  isOpen: boolean;
  refundLoading: boolean;
  creditNote: ICreditNote;
  onCancel: () => void;
  updateRefund: (refund: IRefund, creditNote: ICreditNote) => void;
}


export const RefundDialog: React.FC<IProps> = (props) => {
  const [open, setOpen] = useState<boolean>(props.isOpen);
  const [selectedRefund, setSelectedRefund] = useState<IRefund>();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const organisation = userState.currentOrganisation;
  const { locale, currency } = organisation;
  const isOpen = props.isOpen;

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    setSelectedRefund(undefined);
    props.onCancel();
  };


  return (
    <Dialog
      open={open}
      maxWidth={"lg"}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h2">
          {`Refund`}
          {props.refundLoading && <CircularProgress size={18} style={{ marginLeft: "1rem" }} />}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography variant="h4">
              {"Date Created: "}
              <Typography variant="body1" style={{ display: "inline" }}>
                {props.creditNote.createdAt ? getLocalizedDateFormat(country, props.creditNote.createdAt || "", DATE_TYPE.CONDENSED) : ""}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                    >
                      <Typography variant={"h4"}>Item</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography variant={"h4"}>Quantity</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography variant={"h4"}>Unit Price</Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                    >
                      <Typography variant={"h4"}>Amount</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.creditNote.creditNoteItems.map((creditNoteItem: ICreditNoteItem) => {
                    return <TableRow>
                      <TableCell>
                        {creditNoteItem.description}
                      </TableCell>
                      <TableCell>
                        {creditNoteItem.quantity}
                      </TableCell>
                      <TableCell>
                        {toCurrency(creditNoteItem.unitPrice, currency, locale)}
                      </TableCell>
                      <TableCell>
                        {toCurrency(creditNoteItem.totalAmount, currency, locale)}
                      </TableCell>
                    </TableRow>
                  })}
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell>
                      Total
                    </TableCell>
                    <TableCell>
                      {toCurrency(props.creditNote.totalAmount, currency, locale)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell>
                      Amount Refunded
                    </TableCell>
                    <TableCell>
                      {"- "}{toCurrency(props.creditNote.refundedAmount, currency, locale)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {props.creditNote.allocations?.length > 0 ?
            <>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
                  Allocations Summary
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table aria-label="spanning table">
                    <TableHead>
                      <TableCell
                        align="left"
                      >
                        <Typography variant={"h4"}>Invoice Ref</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography variant={"h4"}>Allocated At</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography variant={"h4"}>Amount</Typography>
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {props.creditNote.allocations.map((alloation: ICreditNoteAllocation, idx: number) => {
                        return <TableRow>
                          <TableCell>
                            {alloation.invoice.invoiceRef}
                          </TableCell>
                          <TableCell>
                            {getLocalizedDateFormat(country, alloation.allocatedAt, DATE_TYPE.CONDENSED)}
                          </TableCell>
                          <TableCell>
                            {toCurrency(alloation.amount, currency, locale)}
                          </TableCell>
                        </TableRow>
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </> :
            <></>
          }
          {props.creditNote.refunds?.length > 0 ?
            <>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
                  Refund Summary
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table aria-label="spanning table">
                    <TableHead>
                      <TableCell
                        align="left"
                      >
                        <Typography variant={"h4"}>Reference Number</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography variant={"h4"}>Payment Method</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography variant={"h4"}>Amount</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Typography variant={"h4"}>Status</Typography>
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {props.creditNote.refunds.map((refund: IRefund, idx: number) => {
                        return <TableRow>
                          <TableCell>
                            {refund.refundRef}
                          </TableCell>
                          <TableCell>
                            {refund.mode} ({refund.paymentGateway})
                          </TableCell>
                          <TableCell>
                            {toCurrency(refund.amount, currency, locale)}
                          </TableCell>
                          <TableCell>
                            {selectedRefund && selectedRefund.id === refund.id && refund.status !== RefundStatus.COMPLETED ?
                              <Grid container xs={12}>
                                <Grid item xs={9}>
                                  <FormControl fullWidth>
                                    <InputLabel id="status-update-select-label">Status</InputLabel>
                                    <Select
                                      labelId="status-update-select-label"
                                      id="status-update-select"
                                      value={selectedRefund.status}
                                      onChange={(e) => {
                                        if (e.target.value && typeof e.target.value === "string") {
                                          setSelectedRefund({
                                            ...selectedRefund,
                                            status: e.target.value
                                          })
                                        }
                                      }}
                                    >
                                      <MenuItem value={refund.status} disabled>{refund.status}</MenuItem>
                                      <MenuItem value={RefundStatus.COMPLETED}>COMPLETED</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                  {selectedRefund.status === RefundStatus.COMPLETED && <CheckCircleIcon
                                    onClick={() => {
                                      props.updateRefund(selectedRefund, props.creditNote);
                                      setSelectedRefund(undefined)
                                    }}
                                    style={{
                                      display: "inline",
                                      cursor: "pointer",
                                      marginLeft: "1rem",
                                    }}
                                  />}
                                </Grid>
                              </Grid> :
                              <Typography variant="body2">
                                {refund.status}
                                {refund.status !== RefundStatus.COMPLETED && <EditIcon
                                  onClick={() => setSelectedRefund(refund)}
                                  style={{
                                    marginLeft: "0.5rem",
                                    cursor: "pointer",
                                    fontSize: "1rem"
                                  }}
                                />}
                              </Typography>
                            }
                          </TableCell>
                        </TableRow>
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </> :
            <></>
          }

        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container item xs={12} justifyContent="flex-end">
          <Fab
            size="medium"
            variant="extended"
            className="blackBackButton"
            onClick={handleClose}
          >
            Close
          </Fab>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
