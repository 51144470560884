import {
  Dialog,
  DialogContent,
  Typography,
  Theme,
  Grid,
  Fab,
  TextField
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";

import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FlatPickerBar from "../../../../common/FlatPicker"
import { IHoliday } from "../../../../../reducers/organisation/types";
import { DateTime as d } from "luxon"
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../../common/utils";

interface IDialogProps {
  open: boolean,
  holidayData: IHoliday | undefined;
  handleClose: () => void;
  handleSubmit: (holiday: IHoliday) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const AddHolidaysDialog: React.FC<IDialogProps> = (props) => {
  const userState = useSelector((state: IAppState) => state.userReducer)
  const { country } = userState.currentOrganisation.address;

  const { open, handleClose, handleSubmit, holidayData } = props;
  const [holiday, setHoliday] = useState<IHoliday>(holidayData || {
    name: "",
    startDate: "",
    endDate: "",
    isActive: true
  });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      className={"select-holidays-dialog"}
    >
      <React.Fragment>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p style={{ fontSize: 18, color: "var(--theme-accent)", padding: 0, margin: 0 }}>
            Add holiday
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} style={{ marginTop: 10 }}>
              <TextField
                name="holidayName"
                label="Holiday Name"
                placeholder="Holiday Name"
                value={holiday.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e && e.target && e.target.value) {
                    setHoliday({
                      ...holiday,
                      name: e.target.value
                    })
                  } else {
                    setHoliday({
                      ...holiday,
                      name: ""
                    })
                  }
                }}
                fullWidth
                required
              ></TextField>
            </Grid>
            <Grid item xs={6} md={4} style={{ marginTop: 10 }}>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {
                  setHoliday({
                    ...holiday,
                    startDate: getLocalizedDateFormat(country, d.fromJSDate(value).toUTC().toISO(), DATE_TYPE.CONDENSED),
                    endDate: ""
                  })
                }}

                label={"Start Date"}
                identifier={`startDate`}
                placeholderValue={"select date..."}
                value={holiday.startDate}
                minDate={holiday.startDate ? holiday.startDate : "today"}
                country={country}
              />
            </Grid>
            <Grid item xs={6} md={4} style={{ marginTop: 10 }}>
              <FlatPickerBar
                enableTime={false}
                handleDateChange={(value: Date) => {
                  setHoliday({
                    ...holiday,
                    endDate: getLocalizedDateFormat(country, d.fromJSDate(value).toUTC().toISO(), DATE_TYPE.CONDENSED)
                  })
                }}
                disabled={!holiday.startDate}
                label={"End Date"}
                identifier={`endDate`}
                placeholderValue={"select date..."}
                value={holiday.endDate}
                minDate={holiday.startDate ? holiday.startDate : "today"}
                country={country}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Fab
                variant="extended"
                size="medium"
                aria-label="submit"
                onClick={() => {
                  handleSubmit(holiday)
                }}
                disabled={!holiday.name || !holiday.startDate || !holiday.endDate}
              >
                Add
              </Fab>
            </Grid>
          </Grid>
        </DialogContent>
      </React.Fragment>
    </Dialog >
  )
}



