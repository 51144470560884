export const columns = [
  {
    name: "testDate",
    title: "TEST DATE"
  },
  {
    name: "expiryDate",
    title: "EXPIRY DATE"
  },
  {
    name: "testResult",
    title: "RESULT"
  },
  {
    name: "odometerReading",
    title: "ODOMETER READING"
  }
];
