import { Dialog, DialogTitle, Typography, DialogContent, Grid, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IBooking } from "../../../../../reducers/bookings/types";
import CloseIcon from '@mui/icons-material/Close';
import ExtendInProgressBooking from "../../../Fleet/VehicleScheduler/SchedulerModels/ExtendInProgressBooking";
import { DialogTypes } from "../../../Fleet/VehicleScheduler/utils";
import { getLocalizedBookingSyntex } from "../../../../../utils/localized.syntex";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";

interface IProps {
  booking: IBooking;
  handleClose: () => void;
}

interface IDialogData {
  startDate: string;
  endDate: string;
  bookingId: string;
}

export const ExtendBookingDialog: React.FC<IProps> = (props) => {
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [booking, setBooking] = useState<IBooking>();
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<IDialogData>()

  useEffect(() => {
    if (props.booking && !dataFetched) {
      setBooking(props.booking)
      setDataFetched(true)
      setDialogData({
        startDate: props.booking.pickupDateTime,
        endDate: props.booking.dropoffDateTime || "",
        bookingId: props.booking.id
      })
    }
  }, [props.booking])

  if (!booking) {
    return <Grid container justifyContent="center" style={{ paddingBottom: "20px" }}>
      <CircularProgress />
    </Grid>
  }

  return (
    <Dialog
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "20px 24px 10px" }}>
        <Grid container item xs={12}>
          <Grid container item xs={6}><Typography variant="h2">{`Extend ${getLocalizedBookingSyntex(country)}`}</Typography></Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <CloseIcon className="close-icon" onClick={props.handleClose} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {dialogData && <ExtendInProgressBooking
          handleClose={props.handleClose}
          type={DialogTypes.EXTEND_BOOKING}
          dialogData={dialogData}
          updateBookingSchedules={() => { }}
        />}
      </DialogContent>
    </Dialog>
  )
}