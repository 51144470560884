import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { IVehicle } from "../../../../reducers/fleet/types";
import { getVehicleTypeIcon } from "../../../common/utils";
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { captureErrorException } from "../../../../utils/sentry";

interface IProps {
  vehicle: IVehicle;
}

const useStyles = makeStyles(() => ({
  img: {
    maxWidth: "150px"
  }
}));

export const VehicleColumn = (props: IProps) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const [vehicleImage, setVehicleImage] = useState<any>("");

  useEffect(() => {
    if (props.vehicle && props.vehicle.imageUrl) {
      getVehicleImage(props.vehicle.imageUrl);
    }
  }, [props.vehicle.imageUrl])

  const getVehicleImage = async (url: string) => {
    if (url) {
      try {
        const file = await getSignedUrl(url);
        setVehicleImage(file);
      } catch (err: any) {
        captureErrorException(err)
        snackbar({
          message: err,
          variant: SnackBarVariant.ERROR
        });
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        {vehicleImage ? (
          <img
            className={classes.img}
            src={vehicleImage}
            alt="vehicle body type"
            height="80"
            width="150"
          />
        ) : (
          <img
            className={classes.img}
            src={getVehicleTypeIcon(props.vehicle.bodyType)}
            alt="vehicle body type"
          />
        )}
      </Grid>
      <Grid item container md={6}>
        <Grid item md={12}>
          {props.vehicle.assetTag}
        </Grid>
        <Grid item md={12}>
          {props.vehicle.make.toUpperCase()} {props.vehicle.model.toUpperCase()}
        </Grid>
        <Grid item md={12}>
          {props.vehicle.year}
        </Grid>
        <Grid item md={12}>
          {props.vehicle.licencePlate.toUpperCase()}
        </Grid>
      </Grid>
    </Grid>
  );
};
