import { gql } from "@apollo/client";

export const GET_ADDON_RATE_CARD_BY_ID = gql`
  query getAddonRateCardById($id: String!) {
    getAddonRateCardById(id: $id) {
      id
      name
      isActive
      isDefault
      displayName
      dateUpdated
      lastUpdatedBy {
        firstName
        lastName
      }
      businessCustomers {
        businessName
        id
      }
      addons {
        name
        pricing {
          rangeStart
          rangeEnd
          unitPrice
        }
        addon {
          id
          name
        }
      }
    }
  }
`;
