import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import CssBaseline from '@mui/material/CssBaseline';
import { Grid, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router';
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useLazyQuery } from '@apollo/client';
import { GET_FLEET_MOVEMENT } from '../../../../graphql/vehicleMovement/queries';
import { DATE_TYPE, formatGraphQLErrorMessage } from '../../../common/utils';
import { IFleetMovement } from '../types';
import { CustomerType } from '../../ReservationManagement/utils';
import { IAppState } from '../../../../store';
import { useSelector } from 'react-redux';
import { getLocalizedDateFormat } from '../../../../utils/localized.syntex';
import { SnackBarVariant } from '../../../common/SnackbarWrapper/SnackbarWrapper';
import { useSnackBar } from '../../../common/SnackBarContext/SnackBarContext';

const MovementLifecycle = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address;
  const [movementData, setMovementData] = useState<IFleetMovement>();

  const [
    fetchMovement,
    { data: fetchedMovementData }
  ] = useLazyQuery(GET_FLEET_MOVEMENT, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data?.getFleetMovementById) {
        navigate("/movements");
      }
    },
    onError: (error: any) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    if (fetchedMovementData && fetchedMovementData.getFleetMovementById) {
      setMovementData(fetchedMovementData.getFleetMovementById)
    }
  }, [fetchedMovementData])

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const movementId = params.get("movementId");
      if (movementId) {
        fetchMovement({
          variables: {
            movementId
          }
        })
      }
    }
  }, [location, userState])

  useEffect(() => {
    if (fetchedMovementData) {

    }
  }, [fetchedMovementData]);

  if (!movementData) {
    return <></>
  }

  return (
    <>
      <div>
        <CssBaseline />
        <h2 style={{ margin: 0 }} className="flex cross-center">
          <span className="margin-right">Vehicle movement</span>
          <DoubleArrowIcon style={{ fontSize: 16, opacity: 0.5 }} />
          <span className="margin-left" style={{ fontWeight: "initial" }}>Movement Details</span>
        </h2>
      </div>
      <Grid container spacing={3} style={{ marginTop: 15 }}>
        <Grid item xs={12} md={4} lg={3}>
          <div className={styles.module}>
            <Typography variant="h4" style={{ marginBottom: 12 }}>Vehicle Info</Typography>
            <Typography className={styles.info}>
              <span>Licence plate no:</span>
              <div>{movementData.vehicle.licencePlate}</div>
            </Typography>
            <Typography className={styles.info}>
              <span>Status:</span>
              <div>{movementData.vehicle.status}</div>
            </Typography>
            <Typography className={styles.info}>
              <span>Details:</span>
              <div>{movementData.vehicle.make} | {movementData.vehicle.model} | {movementData.vehicle.year}</div>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <div className={styles.module}>
            <div className="flex">
              <div className="flex col-flex" style={{ flex: 2 }}>
                <Typography variant="h4" style={{ marginBottom: 12 }}>Movement Info</Typography>
                <div className="flex">
                  <div className="flex fill col-flex">
                    <Typography className={styles.info}>
                      <span>Status:</span>
                      <strong>{movementData.status}</strong>
                    </Typography>
                    <Typography className={styles.info}>
                      <span>From branch:</span>
                      <div>{movementData.branch.name}</div>
                    </Typography>
                    <Typography className={styles.info}>
                      <span>To branch:</span>
                      <div>{movementData.externalBranch.name}</div>
                    </Typography>
                    <Typography className={styles.info}>
                      <span>From location:</span>
                      <div>{movementData.source && movementData.source.name ? movementData.source.name : "NA"}</div>
                    </Typography>
                    <Typography className={styles.info}>
                      <span>To location:</span>
                      <div>{movementData.destination && movementData.destination.name ? movementData.destination.name : "NA"}</div>
                    </Typography>
                  </div>
                  <div className="flex fill col-flex">
                    <Typography className={styles.info}>
                      <span>Reason:</span>
                      <div>{movementData.movementSource}</div>
                    </Typography>
                    <Typography className={styles.info}>
                      <span>Estimated Checkout:</span>
                      <div>{getLocalizedDateFormat(country, movementData.estimatedCheckout, DATE_TYPE.EXPANDED)}</div>
                    </Typography>
                    <Typography className={styles.info}>
                      <span>Estimated Arrival:</span>
                      <div>{getLocalizedDateFormat(country, movementData.estimatedDelivery, DATE_TYPE.EXPANDED)}</div>
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="flex col-flex" style={{ flex: 1, borderLeft: "1px solid #f0f0f0", paddingLeft: 20 }}>
                <Typography variant="h4" style={{ marginBottom: 12 }}>Driver Info</Typography>
                <div className="flex fill col-flex">
                  <Typography className={styles.info}>
                    <span>Name:</span>
                    <div>
                      {movementData?.driver
                        ? `${movementData.driver.firstName} ${movementData.driver.lastName}`
                        : ""}
                    </div>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        {movementData.booking && <Grid item xs={12}>
          <div className={styles.module}>
            <Typography variant="h4" style={{ marginBottom: 12 }}>Booking</Typography>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Booking Reference No.</th>
                  <th>Status</th>
                  <th>Booked from</th>
                  <th>Pickup date and time</th>
                  <th>Customer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{movementData.booking.referenceNumber}</td>
                  <td>{movementData.booking.status}</td>
                  <td>{movementData.externalBranch.name}</td>
                  <td>{getLocalizedDateFormat(country, movementData.booking.pickupDateTime || "", DATE_TYPE.EXPANDED)}</td>
                  <td>{movementData.booking.customerType === CustomerType.BUSINESS ? movementData.booking.businessCustomer?.businessName || "" : `${movementData.booking.customer?.firstName || ""} ${movementData.booking.customer?.lastName || ""}`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>}
      </Grid>
    </>
  );
}

export default MovementLifecycle

