import React from 'react';
import styles from './index.module.css';
import { MapModule } from "./MapModule";

const Reports = (props: any) => {

  return (
    <div className={styles.screen}>
      <MapModule />
    </div>
  );
}

export default Reports

