import { gql } from "@apollo/client";

export const GET_VEHICLE_GROUPS_PRICE = gql`
  query vehicleGroups {
    vehicleGroups {
      id
      name
      vehicles {
        id
      }
      basePrices {
        rateTypeName
        rateTypeDuration
        rate
        longTerm
        mileageLimit
        pricePerExtraMile
      }
    }
  }
`;
