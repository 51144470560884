import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import ImageGallery from "../../common/ImageGallery";
import { getSignedUrl } from '../../../utils/getSignedUrl';
import { captureErrorException } from "../../../utils/sentry";

interface IImageProps {
  damageImages: string[];
  title?: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageStyle: {
      height: 30,
      width: 30,
      borderRadius: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 15
    }
  })
);

export const DamgeImageView: React.FC<IImageProps> = (props) => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    if (props.damageImages && props.damageImages.length) {
      setImages([]);
      const promises: any[] = [];
      const imageUrls: string[] = [];
      props.damageImages.map(
        (id: string, index: number) => {
          promises.push(
            (async () => {
              try {
                const data: any = await getImages(id);
                if (data) {
                  imageUrls.push(data);
                }
              } catch (error) {
                captureErrorException(error)
              }
            })()
          );
        });
      Promise.all(promises).then(() => {
        setImages(imageUrls);
      });
    }
  }, [props.damageImages]);

  const getImages = async (key: string) => {
    const file = await getSignedUrl(key)
    return file;
  };
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {images && images.length > 0 && images.slice(0, 2).map((img: any, index1: number) => (
          <React.Fragment key={`inner-${index1}`}>
            <button
              type="button"
              style={{ background: "none", border: "none", marginLeft: images.length === 1 ? 20 : 0 }}
              onClick={() => setOpen(true)}
            >
              <img src={img} className={styles.imageStyle} alt="" />
            </button>
          </React.Fragment>
        ))}

        {images && images.length > 0 && (<ImageGallery
          title={props.title ? props.title : "Vehicle Damage Images"}
          open={open}
          handleClose={() => setOpen(false)}
          images={images}
        />)}
      </Grid>
    </React.Fragment>
  );
};

