import { gql } from "@apollo/client";

export const UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($userId: String!, $isActive: Boolean!) {
    updateUserStatus(userId: $userId, isActive: $isActive) {
      id
      email
      firstName
      lastName
      phoneNumber {
        phone
        country
      }
      active
      role
    }
  }
`;
