import { gql } from "@apollo/client";

export const GET_PARTNERS = gql`
  {
    partners {
			id
			isActive
			partnerId
			partnerName
			partnerType
			address {
				street
				city
				fullAddress
				state
				country
				zipcode
			}
			email
			phoneNumber {
				phone 
				country
			}
			companyRegistrationNo
			partnerContact {
				firstName
    		lastName
				phoneNumber {
					phone 
					country
				}
    		email
			}
			# documents
			# tenantId
			# organisationId
			# branchId
			createdAt
			createdBy
			updatedAt
			updatedBy
    }
  }
`;
