import Evaporate from "evaporate";
export interface IAuthState {
  state: AuthStates;
  user?: IUser;
  token?: string;
  message?: string;
  _e_?: Evaporate;
}

export interface IUser {
  _id: string;
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  active?: boolean;
}

export enum AuthStates {
  SIGNED_IN = "SIGNED_IN",
  SET_PASSWORD = "SET_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  SIGNED_OUT = "SIGNED_OUT",
  OTP_SENT = "OTP_SENT"
}
