import { gql } from "@apollo/client";

export const UPDATE_TENANCY = gql`
  mutation updateTenancy($tenancyId: ID!, $tenancy: TenancyUpdateInput!) {
    updateTenancy(tenancyId: $tenancyId, tenancy: $tenancy) {
      id
      name
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      billing {
        billingAddressSame
        address {
          street
          city
          state
          country
          zipcode
          fullAddress
        }
      }
      logoUri
      emailId
      phoneNumber {
        phone
        country
      }
      organisations {
        id
        locale
        emailId
        name
        phoneNumber {
          phone
          country
        }
        address {
          city
          state
          street
          country
          zipcode
          fullAddress
        }
      }
    }
  }
`;

export const UPDATE_EPYX_DETAILS = gql`
  mutation updateEpyxDetails($tenancyId: String!, $EpyxDetails: EpyxDetailsInput!) {
    updateEpyxDetails(tenancyId: $tenancyId, EpyxDetails: $EpyxDetails) {
      id
      name
      enableEpyxIntegration
      epyxAccountNumber
      epyxSchemeReferenceNumber
    }
  }
`;

export const GENERATE_API_KEY = gql`
  mutation generateApiKey($tenancyId: String!, $name:String!) {
    generateApiKey(tenancyId: $tenancyId, name:$name)
  }
`;

export const DELETE_API_KEY = gql`
  mutation deleteApiKey($tenancyId: String!, $apiKey:String!) {
    deleteApiKey(tenancyId: $tenancyId, apiKey:$apiKey)
  }
`;

export const UPDATE_TENANCY_INTEGRATION = gql`
  mutation updateTenancyIntegration($tenancyId: String!, $organisationId: String!, $integrationName: String!, $value: Boolean!) {
    updateTenancyIntegration(tenancyId: $tenancyId, organisationId : $organisationId, integrationName: $integrationName, value: $value)
  }
`;
