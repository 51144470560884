import { gql } from "@apollo/client";

export const GET_EXPENSE_BY_VEHICLE_ID = gql`
  query expensesByVehicleId($vehicleId: String!) {
    expensesByVehicleId(vehicleId: $vehicleId) {
      id
      referenceNumber
      expenseType
      expenseDate
      fuelType
      vendor
      odometerReading
      fuelQuantity
      reference
      amount
      driver
      document {
        documentName
        url
      }
    }
  }
`;