import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  Typography
} from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { ApolloError } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { updateVehicleChecks } from "../../../../../../actions/bookings/actions";
import { CREATE_CHECK_INFO } from "../../../../../../graphql/checkInfo/CreateCheckInfoQuery";
import { GET_CHECK_INFO_BOOKING } from "../../../../../../graphql/checkInfo/getBookingInfoForChecks";
import { IAppState } from "../../../../../../store";
import { useSnackBar } from "../../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../../common/SnackbarWrapper/SnackbarWrapper";
import StepperHeader from "../../../../../common/StepperHeader/StepperHeader";
import { formatGraphQLErrorMessage } from "../../../../../common/utils";
import { AccessoriesChecks } from "../common/Accessories";
import { CustomerInfo } from "../common/CustomerInfo";
import { Payment } from "../common/Payment";
import { VehicleChecks } from "../common/VehicleChecks";
import { CheckInfoBooking, CheckInfoVehicle } from "../common/constants";
import { ICheckInfoBooking, ICheckInfoVehicle } from "../common/types";
import { captureErrorException } from "../../../../../../utils/sentry";

export const DeliveryChecks: React.FC = () => {
  const snackbar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const vehicleChecks = useSelector((state: IAppState) => state.bookingReducer);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const dispatch = useDispatch();
  const [bookingScheduleId, setBookingSCheduleId] = useState<string>("");
  const [booking, setBooking] = useState<ICheckInfoBooking>(CheckInfoBooking);
  const [vehicle, setVehicle] = useState<ICheckInfoVehicle>(CheckInfoVehicle);

  const [
    loadBookingSchedule,
    { data: bookingScheduleData, loading }
  ] = useLazyQuery(GET_CHECK_INFO_BOOKING, {
    fetchPolicy: "network-only"
  });

  const [createCheckInfo] = useMutation(CREATE_CHECK_INFO, {
    onCompleted: () => {
      navigate(`/view-booking?booking=${booking.id}`);
      snackbar({
        message: "Check Info saved successfully",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
    update: (proxy, { data: { createCheckInfo } }) => {
      try {
        proxy.writeQuery({
          data: { checkInfo: createCheckInfo },
          query: CREATE_CHECK_INFO
        });
      } catch (err) {
        captureErrorException(err)
        return;
      }
    }
  });

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const bookingSchedule = params.get("bookingschedule");
      if (bookingSchedule) {
        setBookingSCheduleId(bookingSchedule);
        if (bookingSchedule) {
          loadBookingSchedule({
            variables: {
              id: bookingSchedule
            }
          });
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (bookingScheduleData && bookingScheduleData.bookingSchedule.booking) {
      setBooking(bookingScheduleData.bookingSchedule.booking);
      if (bookingScheduleData.bookingSchedule.vehicle) {
        setVehicle(bookingScheduleData.bookingSchedule.vehicle);
        dispatch(
          updateVehicleChecks({
            vehicleState: {
              ...bookingScheduleData.bookingSchedule.vehicle.vehicleState,
              ...vehicleChecks.checkInfo.vehicleState
            }
          })
        );
      }
    }
  }, [bookingScheduleData]);

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const nextStep = (data?: any) => {
    if (currentStep <= Object.keys(stepMapper).length - 1) {
      if (currentStep === 3) {
        const vehicleState = { ...vehicleChecks.checkInfo.vehicleState };
        delete vehicleState.id;
        delete vehicleState.type;
        createCheckInfo({
          variables: {
            bookingScheduleId,
            checkInfo: {
              ...vehicleChecks.checkInfo,
              vehicleState: { ...vehicleState }
            },
            type: "DROPOFF"
          }
        });
      } else {
        dispatch(updateVehicleChecks(data));
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const stepMapper: any = {
    0: (
      <CustomerInfo
        onSubmit={nextStep}
        currentBooking={booking}
        vehicle={vehicle}
      />
    ),
    1: (
      <VehicleChecks
        onSubmit={nextStep}
        onPrevious={previousStep}
        vehicleId={vehicle ? vehicle.id : ""}
      />
    ),
    2: <AccessoriesChecks onSubmit={nextStep} onPrevious={previousStep} />,
    3: (
      <Payment
        onSubmit={nextStep}
        onPrevious={previousStep}
        invoice={booking.currentInvoice}
      />
    )
  };

  return (
    <Grid container item xs={12} spacing={2}>
      <CssBaseline />
      <Grid item container xs={12}>
        <Grid container item xs={4} alignItems="center">
          <Typography variant="h1" color="primary">
            Delivery Checks {"  "}
          </Typography>
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon />
          <Box color="white" sx={{ pl: 1 }}></Box>

          <Typography variant="h1" color="primary">
            {booking && booking.referenceNumber}
          </Typography>
        </Grid>
        <Grid item container sm={8} alignContent="flex-end">
          <StepperHeader
            currentStep={currentStep}
            steps={["GENERAL", "VEHICLE CHECK", "ACCESSORIES CHECK", "PAYMENT"]}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {loading ? <CircularProgress /> : stepMapper[currentStep]}
      </Grid>
    </Grid>
  );
};
