import { Grid, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from 'react'
import EditSubscriptionPricingModal from '../Finance/EditSubscriptionPricingModal'
import { ISubscription, ISubscriptionPricing } from '../../../../../../reducers/Subscription/types'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_SUBSCRIPTIONS } from '../../../../../../graphql/SubscriptionPlans/getSubscriptionsQuery'
import { useSelector } from 'react-redux'
import { IAppState } from '../../../../../../store'
import { ISubscriptionInfo, IVehicle } from '../../../../../../reducers/fleet/types'
import { useSnackBar } from '../../../../../common/SnackBarContext/SnackBarContext'
import { useNavigate } from 'react-router-dom'
import { ApolloError } from '@apollo/client'
import { formatGraphQLErrorMessage } from '../../../../../common/utils'
import { SnackBarVariant } from '../../../../../common/SnackbarWrapper/SnackbarWrapper'
import { REMOVE_VEHICLE_SUBSCRIPTION_PRICING, UPDATE_VEHICLE_SUBSCRIPTION_PRICINGS } from '../../../../../../graphql/SubscriptionPlans/updateVehicleSubscriptionPricingMutation'
import Autocomplete from '@mui/material/Autocomplete'
import SubscriptionCard from '../../../../Settings/Pricing/SubscriptionPlans/SubscriptionCard'
import { ConfirmationDialog } from '../../../../../common/ConfirmationDialog/ConfirmationDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3)
    }
  })
);

interface IProps {
  vehicle: IVehicle;
  updateVehicle(payload: any): void;
}

interface IAddSubscriptionInput {
  subscription: string;
  pricings: ISubscriptionPricing[];
}

const Subscription: React.FC<IProps> = (props) => {
  const styles = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [editDialog, setEditDialog] = useState<boolean>(false)
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([])
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<ISubscription[]>([]);
  const [subscriptionToAdd, setSubscriptionToAdd] = useState<ISubscription | null>(null);
  const [subscriptionToRemove, setSubscriptionToRemove] = useState<ISubscription | null>(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [removeInProgress, setRemoveInProgress] = useState<boolean>(false);

  const [loadSubscriptions, { data }] = useLazyQuery(GET_SUBSCRIPTIONS, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (userState.tenancy) {
      loadSubscriptions();
    }
  }, [userState]);

  useEffect(() => {
    if (data?.getVehicleSubscriptions?.length) {
      const selectedSubscription: ISubscription[] = []
      const availableSubscription: ISubscription[] = []
      const vehicleSubscriptions: ISubscriptionInfo[] = props.vehicle?.subscriptions || [];

      data.getVehicleSubscriptions.forEach((subscription: ISubscription) => {
        if (subscription.isActive) {
          availableSubscription.push(subscription);
        }
        if (
          vehicleSubscriptions?.some(
            (vs) => vs?.subscription?.id === subscription.id
          )
        ) {
          selectedSubscription.push(subscription);
        }
      });

      setSelectedSubscriptions(selectedSubscription);
      setSubscriptions(availableSubscription);
    }
  }, [data]);

  const [updateVehicleSubscriptionPricings] = useMutation(UPDATE_VEHICLE_SUBSCRIPTION_PRICINGS, {
      onCompleted: () => {
        snackbar({
          message: "Pricings updated successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setEditDialog(false);
        navigate(`/update-vehicle?vehicle=${props.vehicle.id}`);
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [removeVehicleSubscriptionPricing,{ loading: removeVehicleSubscriptionPricingLoading }] = useMutation(REMOVE_VEHICLE_SUBSCRIPTION_PRICING, {
    onCompleted: () => {
      snackbar({
        message: "Subscription plan removed successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setIsConfirmDialogOpen(false);
      setRemoveInProgress(false);
      setSubscriptionToRemove(null);
      navigate(`/update-vehicle?vehicle=${props.vehicle.id}`);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setIsConfirmDialogOpen(false);
      setRemoveInProgress(false);
    },
  })

  const updateSubscriptionPricings = (pricings: ISubscriptionPricing[], deposit: number) => {
    if (subscriptionToAdd?.id) {
      updateVehicleSubscriptionPricings({
        variables: {
          subscriptionId: subscriptionToAdd.id,
          vehicleId: props.vehicle.id,
          pricings,
          deposit
        }
      })
    }
  };

  const handleRemoveSubscription = () => {
    if (subscriptionToRemove) {
      setRemoveInProgress(true);
      removeVehicleSubscriptionPricing({
        variables: {
          vehicleId: props.vehicle.id,
          subscriptionId: subscriptionToRemove.id
        }
      });
    }
  };

  const handleAutocompleteChange = (_: any, newValues: any, reason: any) => {
    if (reason === "selectOption") {
      setEditDialog(true);
      setSubscriptionToAdd(newValues[newValues.length - 1]);
    } else if (reason === "removeOption") {
      const removedSubscription = selectedSubscriptions.find(
        (val) => !newValues.some((obj: any) => obj.id === val.id)
      );
      if (removedSubscription) {
        setSubscriptionToRemove(removedSubscription);
        setIsConfirmDialogOpen(true);
      }
    }
  };

  return (
    <Grid container className={styles.root} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">VEHICLE SUBSCRIPTIONS</Typography>
      </Grid>
      <Grid item xs={12} md={6} className="subscription-tag">
        <Autocomplete
          multiple
          freeSolo
          id="free-solo-subscription-search"
          disableClearable
          options={subscriptions}
          getOptionLabel={(option: any) =>
            option.name
          }
          filterSelectedOptions
          onChange={handleAutocompleteChange}
          value={selectedSubscriptions}
          defaultValue={[]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add Subscription"
              variant="outlined"
              placeholder={"select subscription"}
              InputProps={{ ...params.InputProps, type: "search" }}
            />
          )}
        />
      </Grid>
      {selectedSubscriptions.length > 0 &&
        selectedSubscriptions.map((subscription) => (
          <SubscriptionCard
            key={subscription.id}
            subscription={subscription}
            pricings={props.vehicle.subscriptions}
            vehicleId={props.vehicle.id}
            isUpdateSubscription={false}
          />
        ))}
      {
        editDialog && subscriptionToAdd &&
        <EditSubscriptionPricingModal
          open={editDialog}
          subscription={subscriptionToAdd}
          pricings={[]}
          onClose={() => setEditDialog(false)}
          handlePricingUpdate={updateSubscriptionPricings}
        />
      }
      <ConfirmationDialog
        isOpen={isConfirmDialogOpen}
        onCancel={() => setIsConfirmDialogOpen(false)}
        onConfirm={handleRemoveSubscription}
        description="Are you sure you want to remove this Subscription Plan?"
        title=""
        confirmText="Yes"
        cancelText="No"
        isInProgress={removeInProgress}
        isLoading={removeVehicleSubscriptionPricingLoading}
      />
    </Grid>
  )
}

export default Subscription