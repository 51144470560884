import { gql } from "@apollo/client";

export const CREATE_VEHICLE_ON_HOLD_MUTATION = gql`
  mutation createVehicleHold($vehicleId: ID!, $holdInfo: HoldInfoInput!) {
    createVehicleHold(vehicleId: $vehicleId, holdInfo: $holdInfo) {
      id
      startDate
      endDate
      notes {
        description
        dateCreated
        createdBy {
          firstName
          lastName
        }
      }
      dateCreated
      createdBy {
        firstName
        lastName
      }
    }
  }
`;
