import { Dialog, DialogContent, DialogTitle, Grid, Theme, Typography, Fab, MenuItem } from '@mui/material'
import { Field, Form, Formik } from 'formik';
import { createStyles, makeStyles } from "@mui/styles";
import { TextField } from 'formik-mui';
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from "uuid";
import { IFinanceInformation, IPartner, IPurchaseOrder } from '../../../../../reducers/Procurement/types'
import { NuvvenDivider } from '../../../ReservationManagement/Summary/Components/Divider';
import FlatPickerBar from "../../../../common/FlatPicker";
import { DateTime as d } from "luxon"
import { FloatInput } from '../../../../common/FloatInput/FloatInput';
import { GET_PARTNERS_LIST } from '../../../../../graphql/Procurement/getPartnersListQuery';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { financeInfoInitials } from '../FinanceInformation';
import { paymentSources } from '../../const';
import { DocumentDialog } from '../../../CustomerManagement/CustomerDetails/DocumentDialog';
import { useSnackBar } from '../../../../common/SnackBarContext/SnackBarContext';
import { DATE_TYPE, checkUploadFileFormat, uploadFileExtensionAndContentType } from '../../../../common/utils';
import { SnackBarVariant } from '../../../../common/SnackbarWrapper/SnackbarWrapper';
import * as Yup from "yup";
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { captureErrorException } from '../../../../../utils/sentry';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      background: "#dee2e6"
    },
    formPaper: {
      padding: theme.spacing(3)
    },
    form: {
      flexGrow: 1
    }
  })
);

interface IProps {
  fiancne?: IFinanceInformation;
  open: boolean;
  onClose(): void;
  handelFormSubmit(fiancne: IFinanceInformation): void;
  purchaseOrders: IPurchaseOrder[];
  procurementExpectedDate: string;
}


export const NewFinanceInfoModel: React.FC<IProps> = (props) => {

  const { handelFormSubmit, procurementExpectedDate } = props
  const classes = useStyles();
  const snackbar = useSnackBar();
  const userReducer = useSelector((state: IAppState) => state.userReducer);
  const { country } = userReducer.currentOrganisation.address
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [values, setValues] = useState<IFinanceInformation>(financeInfoInitials);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [partner, setPartner] = useState<IPartner>()
  const [purchaseOrders, setPurchaseOrders] = useState<IPurchaseOrder[]>(props.purchaseOrders)
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [
    loadPartners,
    { data: partnersList }
  ] = useLazyQuery(GET_PARTNERS_LIST, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (userReducer.tenancy) {
      loadPartners()
    }
  }, [userReducer.tenancy])

  useEffect(() => {
    if (props.fiancne && props.fiancne.id) {
      setIsUpdate(true)
      setValues(props.fiancne)
    }
  }, [props.fiancne])

  useEffect(() => {
    if (props.purchaseOrders) {
      setPurchaseOrders(props.purchaseOrders)
    }
  }, [props.purchaseOrders])

  useEffect(() => {
    if (partnersList && partnersList.partners) {
      setPartners(partnersList.partners)
      const partner = partnersList.partners.find((partner: IPartner) => {
        return partner.id === values.partnerId
      })
      setPartner(partner)
    }
  }, [partnersList])

  async function uploadDocument(
    file: File
  ) {
    try {
      if (!userReducer.tenancy?.id) {
        return;
      }
      // 5MB limit exceeded
      if (file.size > 5000000) {
        throw new Error("File size exceeded limit of 5MB");
      }
      setUploadInProgress(true);
      if (checkUploadFileFormat(file.type)) {
        const {
          fileExtension
        } = uploadFileExtensionAndContentType(file.type);
        const uniqueId = uuidv4();
        const key = `${userReducer.tenancy.id}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                const updatedValues = {
                  ...values, documents: [...values.documents, key]
                }
                setValues(updatedValues)
                snackbar({
                  message: "Document Added",
                  variant: SnackBarVariant.SUCCESS
                });
                setUploadInProgress(false);
                setDocumentDialogVisible(false);
              }
            });
        }
      } else {
        setDocumentDialogVisible(false);
        setUploadInProgress(false);
        return snackbar({
          message: "Please only upload .pdf!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  const procurementValidationSchema = Yup.object().shape({
    invoiceId: Yup.string().required("Invoice ID is a required field."),
    paymentSource: Yup.string().required("Please select Payment Scource."),
    totalAmount: Yup.number().integer().min(1, "Amount can not be zero").required("Amount is a required field"),
    advancedPayment: Yup.number().integer().nullable().min(1, "Advance Payment can not be zero.").max(Yup.ref("totalAmount"), "Advance Payment can not be more that Invoice Total")
  });

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose()
        }}
        maxWidth={"lg"}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container item xs={12}>
            <Typography variant="h2">{isUpdate ? "Update Finance Info" : "New Finance Info"}</Typography>
          </Grid>
          <NuvvenDivider noMargin />
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} mt={1}>
            <Formik
              enableReinitialize
              validationSchema={procurementValidationSchema}
              initialValues={values}
              onSubmit={(values, { setSubmitting }) => {
                if (values.invoiceDate && values.purchaseOrderDate) {
                  handelFormSubmit(values)
                  setSubmitting(false)
                } else {
                  return snackbar({
                    message: "Please select required dates.",
                    variant: SnackBarVariant.ERROR
                  });
                }
              }}
            >
              {(props) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          setValues({
                            ...props.values,
                            purchaseOrderDate: d.fromJSDate(value).toUTC().toISO()
                          })
                        }}
                        label={"PO Date"}
                        identifier={"purchaseOrderDate"}
                        value={getLocalizedDateFormat(country, props.values.purchaseOrderDate, DATE_TYPE.CONDENSED)}
                        minDate={getLocalizedDateFormat(country, procurementExpectedDate, DATE_TYPE.CONDENSED)}
                        disabled
                        country={country}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g TEST"
                        label="Invoice ID"
                        name={"invoiceId"}
                        value={props.values.invoiceId}
                        onChange={props.handleChange}
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    <Grid item xs={3}>
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          setValues({
                            ...props.values,
                            invoiceDate: d.fromJSDate(value).toUTC().toISO()
                          })
                        }}
                        label={"Invoice Date"}
                        identifier={"invoiceDate"}
                        value={getLocalizedDateFormat(country, props.values.invoiceDate, DATE_TYPE.CONDENSED)}
                        minDate={getLocalizedDateFormat(country, procurementExpectedDate, DATE_TYPE.CONDENSED)}
                        required
                        country={country}
                      />
                    </Grid>
                    <Grid item container xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g 10"
                        label="Invoice Total"
                        name={"totalAmount"}
                        required
                        InputProps={{
                          value: props.values.totalAmount,
                          inputComponent: FloatInput as any
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          setValues({
                            ...props.values,
                            expectedArrivalDate: d.fromJSDate(value).toUTC().toISO()
                          })
                        }}
                        label={"ETA"}
                        identifier={"expectedArrivalDate"}
                        value={getLocalizedDateFormat(country, props.values.expectedArrivalDate, DATE_TYPE.CONDENSED)}
                        required
                        country={country}
                      />
                    </Grid>
                    <Grid item container xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g 10"
                        label="Advance Payment"
                        name={"advancedPayment"}
                        required
                        InputProps={{
                          value: props.values.advancedPayment,
                          inputComponent: FloatInput as any
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        placeholder="e.g petrol"
                        label="Payment Source"
                        name={"paymentSource"}
                        select
                        inputProps={{
                          onChange: (event: any) => {
                            setValues({
                              ...props.values,
                              paymentSource: event.target.value
                            })
                          },
                          value: values.paymentSource
                        }}
                        required
                        fullWidth
                      >
                        {
                          paymentSources &&
                          paymentSources.map(
                            (item) => {
                              return (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            }
                          )
                        }
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Fab
                        className="blackBackButton"
                        variant="extended"
                        size="medium"
                        aria-label="Update"
                        onClick={() => {
                          setDocumentDialogVisible(true);
                        }}
                      >
                        Upload Documents
                      </Fab>
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-start">
                      <Fab
                        variant="extended"
                        size="medium"
                        aria-label="add"
                        type="submit"
                      >
                        Save
                      </Fab>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
        <DocumentDialog
          open={documentDialogVisible}
          handleClose={() => setDocumentDialogVisible(false)}
          uploadInProgress={uploadInProgress}
          onSubmit={uploadDocument}
          accept="application/zip"
        />
      </Dialog>
    </>
  )
}
