import { gql } from "@apollo/client";

export const GET_VEHICLE_BOOKINGS = gql`
  query getVehicleBookings($licencePlate: String!) {
    getVehicleBookings(licencePlate: $licencePlate) {
      booking
      initialPickupDateTime
      initialDropoffDateTime
      bookingReferenceNumber
      licencePlate
      vehicleId
    }
  }
`;
