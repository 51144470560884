import {
  Box,
  Fab,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  IVehicleState,
  VehicleStateElementType,
  VehicleStateType
} from "../../../../../../reducers/fleet/types";
import { IAppState } from "../../../../../../store";
import { vehicleCheckTitles } from "../../../../Fleet/VehicleInventory/ViewVehicle/VehicleCheck/constants";

interface IVehicleAccessoriesChecks {
  onSubmit(payload: any): void;
  onPrevious(): void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      flexGrow: 1
    }
  })
);
export const AccessoriesChecks = (props: IVehicleAccessoriesChecks) => {
  const classes = useStyles();
  const vehicleChecks = useSelector((state: IAppState) => state.bookingReducer);
  const [values, setValues] = useState<IVehicleState>(
    vehicleChecks.checkInfo.vehicleState
  );

  const handlePrevious = () => {
    props.onPrevious();
  };

  return (
    <Grid container item xs={12}>
      <Formik
        enableReinitialize
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit({
            ...vehicleChecks.checkInfo,
            vehicleState: { ...values }
          });
        }}
      >
        {(formValues) => (
          <Form>
            <Grid container item xs={12} spacing={2}>
              <Grid container item xs={12}>
                {Object.keys(values).map((key: string) => {
                  const vehicleChecks = ((values as unknown) as {
                    [key: string]: VehicleStateType;
                  })[key];
                  return (
                    <Grid container item xs={12}>
                      {typeof vehicleChecks === "object" && (
                        <React.Fragment>
                          {key === "defaultAccesories" && (
                            <Grid container item xs={12}>
                              <Paper className={classes.root} elevation={0}>
                                <Grid container item xs={12}>
                                  <Grid container item xs={12}>
                                    <Typography variant={"h2"}>
                                      {vehicleCheckTitles[key]}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Box mt={2}></Box>
                                  </Grid>
                                  <Grid container item xs={12}>
                                    <Paper
                                      className={classes.root}
                                      elevation={3}
                                    >
                                      {Object.keys(vehicleChecks).map(
                                        (elementKey: string) => {
                                          const vehicleChecksElement = ((vehicleChecks as unknown) as {
                                            [key: string]: VehicleStateElementType;
                                          })[elementKey];
                                          return (
                                            <Grid container item xs={12}>
                                              {typeof vehicleChecksElement ===
                                                "boolean" && (
                                                  <Grid container item xs={12}>
                                                    <Grid container item xs={3}>
                                                      <Typography variant="h4">
                                                        {
                                                          vehicleCheckTitles[
                                                          elementKey
                                                          ]
                                                        }
                                                      </Typography>
                                                    </Grid>

                                                    <Grid container item xs={9}>
                                                      <Field
                                                        component={RadioGroup}
                                                        name={elementKey}
                                                        row
                                                        required
                                                        value={
                                                          vehicleChecksElement
                                                            ? "yes"
                                                            : "no"
                                                        }
                                                        onChange={(
                                                          event: React.ChangeEvent<
                                                            HTMLInputElement
                                                          >
                                                        ) => {
                                                          const check =
                                                            event.target.value ===
                                                              "yes"
                                                              ? true
                                                              : false;
                                                          setValues({
                                                            ...values,
                                                            [key]: {
                                                              ...values[
                                                              key
                                                              ],
                                                              [event.target.name]: check
                                                            }
                                                          });
                                                        }}
                                                      >
                                                        <FormControlLabel
                                                          value="yes"
                                                          name={elementKey}
                                                          control={
                                                            <Radio required />
                                                          }
                                                          label="Yes"
                                                        />
                                                        <FormControlLabel
                                                          value="no"
                                                          name={elementKey}
                                                          control={
                                                            <Radio required />
                                                          }
                                                          label="No"
                                                        />
                                                      </Field>
                                                    </Grid>
                                                  </Grid>
                                                )}
                                            </Grid>
                                          );
                                        }
                                      )}
                                    </Paper>
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          )}

                          {key === "tyre" && (
                            <Grid container item xs={12}>
                              <Grid container item xs={12}>
                                <Box mt={2}></Box>
                              </Grid>
                              <Paper className={classes.root} elevation={0}>
                                <Grid item xs={12}>
                                  <Typography variant={"h2"}>
                                    {vehicleCheckTitles[key]}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box mt={2}></Box>
                                </Grid>
                                <Paper className={classes.root} elevation={3}>
                                  {Object.keys(vehicleChecks).map(
                                    (elementKey: string) => {
                                      const vehicleChecksElement = ((vehicleChecks as unknown) as {
                                        [key: string]: VehicleStateElementType;
                                      })[elementKey];
                                      return (
                                        <Grid container item xs={12}>
                                          {typeof vehicleChecksElement ===
                                            "object" && (
                                              <Grid container item xs={12}>
                                                <Grid container item xs={12}>
                                                  <Box mb={1}></Box>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                  <Typography variant="h4">
                                                    {
                                                      vehicleCheckTitles[
                                                      elementKey
                                                      ]
                                                    }
                                                  </Typography>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                  {Object.keys(
                                                    vehicleChecksElement
                                                  ).map(
                                                    (elementChildKey: string) => {
                                                      const vehicleChecksElementChild = ((vehicleChecksElement as unknown) as {
                                                        [key: string]:
                                                        | boolean
                                                        | string
                                                        | string[];
                                                      })[elementChildKey];
                                                      if (
                                                        typeof vehicleChecksElementChild ===
                                                        "boolean"
                                                      ) {
                                                        return (
                                                          <Grid
                                                            container
                                                            item
                                                            xs={3}
                                                          >
                                                            <Grid
                                                              container
                                                              item
                                                              xs={4}
                                                              alignItems="center"
                                                            >
                                                              <Typography variant="h4">
                                                                {
                                                                  vehicleCheckTitles[
                                                                  elementChildKey
                                                                  ]
                                                                }
                                                              </Typography>
                                                            </Grid>
                                                            <Grid
                                                              container
                                                              item
                                                              xs={8}
                                                            >
                                                              <Field
                                                                component={
                                                                  RadioGroup
                                                                }
                                                                name={
                                                                  elementChildKey
                                                                }
                                                                row
                                                                required
                                                                value={
                                                                  vehicleChecksElementChild
                                                                    ? "yes"
                                                                    : "no"
                                                                }
                                                                onChange={(
                                                                  event: React.ChangeEvent<
                                                                    HTMLInputElement
                                                                  >
                                                                ) => {
                                                                  const check =
                                                                    event.target
                                                                      .value ===
                                                                      "yes"
                                                                      ? true
                                                                      : false;

                                                                  setValues({
                                                                    ...values,
                                                                    [key]: {
                                                                      ...values[
                                                                      key
                                                                      ],
                                                                      [elementKey]: {
                                                                        ...vehicleChecksElement,
                                                                        [event
                                                                          .target
                                                                          .name]: check
                                                                      }
                                                                    }
                                                                  });
                                                                }}
                                                              >
                                                                <FormControlLabel
                                                                  value="yes"
                                                                  name={
                                                                    elementChildKey
                                                                  }
                                                                  control={
                                                                    <Radio
                                                                      required
                                                                    />
                                                                  }
                                                                  label="Yes"
                                                                />
                                                                <FormControlLabel
                                                                  value="no"
                                                                  name={
                                                                    elementChildKey
                                                                  }
                                                                  control={
                                                                    <Radio
                                                                      required
                                                                    />
                                                                  }
                                                                  label="No"
                                                                />
                                                              </Field>
                                                            </Grid>
                                                          </Grid>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                </Grid>
                                              </Grid>
                                            )}{" "}
                                        </Grid>
                                      );
                                    }
                                  )}
                                </Paper>
                              </Paper>
                            </Grid>
                          )}
                        </React.Fragment>
                      )}
                    </Grid>
                  );
                })}
              </Grid>

              <Grid container item xs={12}>
                <Fab
                  className="blackBackButton"
                  variant="extended"
                  size="medium"
                  onClick={handlePrevious}
                >
                  BACK
                </Fab>
                <Box ml={2}></Box>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="Create"
                  type="submit"
                >
                  Proceed
                </Fab>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
