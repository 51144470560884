import { gql } from "@apollo/client";

export const DEACTIVATE_RATE_CARD = gql`
  mutation deactivateRateCard($id: String!, $activate: Boolean) {
    deactivateRateCard(id: $id, activate: $activate) {
      id
      name
      isActive
      isDefault
      displayName
      dateUpdated
      lastUpdatedBy {
        firstName
        lastName
      }
      businessCustomers {
        businessName
        id
      }
      vehicleGroups {
        name
        unlimitedMileage
        mileageLimit
        pricePerExtraMile
        rateTypeName
        rateTypeDuration
        pricing {
          rangeStart
          rangeEnd
          unitPrice
        }
        vehicleGroup {
          id
          name
        }
      }
    }
  }
`;
