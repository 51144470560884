import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { DateTime as d } from "luxon";
import FlatPickerBar from "../../../../common/FlatPicker";

import {
  IInvoice,
  IPaymentForm,
  PaymentMode
} from "../../../../../reducers/invoices/types";
import { IAppState } from "../../../../../store";
import { FloatInput } from "../../../../common/FloatInput/FloatInput";
import BacsTransaction from "../../../ReservationManagement/NewBooking/PaymentView/BacsTransaction";
import { CardTransaction } from "../../../ReservationManagement/NewBooking/PaymentView/CardTransaction";
import { OfflineBankTransaction } from "../../../ReservationManagement/NewBooking/PaymentView/OfflineBankTransaction";
import SepaTransaction from "../../../ReservationManagement/NewBooking/PaymentView/SepaTransaction";
import { InvoiceType } from "../../utils";
import PayByLink from "../../../ReservationManagement/NewBooking/PaymentView/PayByLink";
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { DATE_TYPE } from "../../../../common/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    payBtn: {
      backgroundColor: "var(--theme-primary)",
      border: 0,
      borderRadius: 26,
      color: "white",
      fontSize: "14px",
      minHeight: 43,
      minWidth: 130
    }
  })
);
interface IProps {
  amount: number;
  invoice: string;
  booking: string;
  businessCustomer?: string;
  open: boolean;
  handleClose(): void;
  onSubmit(payload: IPaymentForm): void;
  invoiceData: IInvoice;
  isConsolidated?: boolean;
  isAutochargeSetup?: boolean;
}
export const PaymentDialog = (props: IProps) => {
  const [capturedAt, setCapturedAt] = useState<string>("");
  const [amount, setAmount] = useState<number>(props.amount);
  const booking = useSelector(
    (state: IAppState) => state.bookingReducer.booking
  );
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address
  const { sepaEnabled, bacsEnabled, cashEnabled, cardEnabled, offlineBankTransferEnabled, payByLinkEnabled, currency } = userState.currentOrganisation;

  const { invoiceData } = props;
  const isBusinessCustomerBooking =
    invoiceData.businessCustomer &&
      invoiceData.businessCustomer.id !== ""
      ? true
      : false;
  const businessCustomer = invoiceData.businessCustomer;
  const customer = invoiceData.customer;
  const email = isBusinessCustomerBooking
    ? businessCustomer?.billing.email
    : customer
      ? customer.email
      : "";
  const classes = useStyles();
  const [integrations, setIntegrations] = useState<string[]>([]);

  const defaultValues: IPaymentForm = {
    amount: amount,
    note: "",
    paymentMode: integrations.includes(PaymentMode.PAY_BY_LINK)
      ? PaymentMode.PAY_BY_LINK
      : PaymentMode.CASH,
    capturedAt: capturedAt
  };

  useEffect(() => {
    if (props.amount)
      setAmount(props.amount)
  }, [props.amount])

  const paymentFormSchema = Yup.object().shape({
    amount: Yup.number().required("Payment amount is required."),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .when("$paymentMode", {
        is: (paymentMode: string) => paymentMode === PaymentMode.PAY_BY_LINK,
        then: schema => schema
          .email("Please enter a valid email address.")
          .required()
      }),
    note: Yup.string(),
    paymentMode: Yup.string().required("Please select payment mode."),
    transactionId: Yup.string()
  });

  return (
    <Dialog
      fullWidth={true}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add New Payment</DialogTitle>
      <DialogContent style={{ padding: 20 }}>
        <Formik
          initialValues={{ ...defaultValues, email }}
          enableReinitialize
          validationSchema={paymentFormSchema}
          onSubmit={(values, { setSubmitting }) => {
            props.onSubmit(values);
            setSubmitting(false);
          }}
        >
          {(formikProps) => {
            return (
              <Form>
                <Grid item container xs={12}>
                  {![PaymentMode.CARD, PaymentMode.SEPA_BANK_DEBIT, PaymentMode.BACS_BANK_DEBIT, PaymentMode.OFFLINE_BANK_TRANSFER, PaymentMode.PAY_BY_LINK].includes(formikProps.values.paymentMode) && (
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        label={"Amount"}
                        name={"amount"}
                        value={amount}
                        fullWidth
                        InputProps={{
                          inputComponent: FloatInput as any
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false,
                          onChange: (e: any) => setAmount(e.target.value)
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <br />
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="paymentMode">
                        Payment Mode
                      </InputLabel>
                      <Select
                        label="Payment Mode"
                        name={"paymentMode"}
                        value={formikProps.values.paymentMode}
                        onChange={formikProps.handleChange}
                        required
                      >
                        {Object.values(PaymentMode).map(
                          (element: string, key: number) => {
                            if (
                              (element === PaymentMode.CASH && cashEnabled) ||
                              (element === PaymentMode.CARD && cardEnabled) ||
                              (element === PaymentMode.PAY_BY_LINK && payByLinkEnabled) ||
                              (element === PaymentMode.OFFLINE_BANK_TRANSFER && offlineBankTransferEnabled) ||
                              (element === PaymentMode.SEPA_BANK_DEBIT && sepaEnabled && currency === "EUR") ||
                              (element === PaymentMode.BACS_BANK_DEBIT && bacsEnabled && currency === "GBP") ||
                              integrations.includes(element)
                            ) {
                              return (
                                <MenuItem value={element} key={key}>
                                  {element.replace(/_/g, " ")}
                                </MenuItem>
                              );
                            }
                          }
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {formikProps.values.paymentMode === PaymentMode.CASH && (
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <FlatPickerBar
                          enableTime={false}
                          handleDateChange={(value: Date) => {
                            if (value) {
                              setCapturedAt(d.fromJSDate(value).toUTC().toISO())
                            }
                          }}
                          label={"Payment Date"}
                          placeholderValue={"Select Payment Date*"}
                          value={getLocalizedDateFormat(country, capturedAt, DATE_TYPE.CONDENSED)}
                          minDate={getLocalizedDateFormat(country, d.fromISO(invoiceData.dateCreated).startOf("day").toUTC().toISO(), DATE_TYPE.CONDENSED)}
                          maxDate={getLocalizedDateFormat(country, d.now().startOf("day").set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toUTC().toISO(), DATE_TYPE.CONDENSED)}
                          country={country}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {formikProps.values.paymentMode === PaymentMode.CARD && (
                      <CardTransaction
                        bookingId={props.booking}
                        invoice={props.invoice}
                        amount={formikProps.values.amount}
                        creditNotesToAdjust={[]}
                        creditAmount={0}
                        redirectToInvoice={true}
                        closeDialog={props.handleClose}
                        invoiceData={props.invoiceData}
                        businessCustomer={props.businessCustomer}
                        minDate={invoiceData.dateCreated}
                        isConsolidated={props.isConsolidated}
                        validAmount={invoiceData.dueAmount}
                        isAutochargeSetup={props.isAutochargeSetup}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {formikProps.values.paymentMode === PaymentMode.PAY_BY_LINK && (
                      <PayByLink
                        bookingId={props.booking}
                        invoice={props.invoice}
                        amount={formikProps.values.amount}
                        creditNotesToAdjust={[]}
                        creditAmount={0}
                        redirectToInvoice={true}
                        closeDialog={props.handleClose}
                        businessCustomer={props.businessCustomer}
                        invoiceData={props.invoiceData}
                        isConsolidated={props.isConsolidated}
                        validAmount={invoiceData.dueAmount}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {formikProps.values.paymentMode === PaymentMode.OFFLINE_BANK_TRANSFER && (
                      <OfflineBankTransaction
                        bookingId={props.booking}
                        invoice={props.invoice}
                        amount={formikProps.values.amount}
                        redirectToInvoice={true}
                        closeDialog={props.handleClose}
                        businessCustomer={props.businessCustomer}
                        invoiceData={props.invoiceData}
                        minDate={invoiceData.dateCreated}
                        isConsolidated={props.isConsolidated}
                        validAmount={invoiceData.dueAmount}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {formikProps.values.paymentMode === PaymentMode.SEPA_BANK_DEBIT && (
                      <SepaTransaction
                        bookingId={props.booking}
                        invoice={props.invoice}
                        amount={formikProps.values.amount}
                        creditNotesToAdjust={[]}
                        creditAmount={0}
                        businessCustomer={props.businessCustomer}
                        redirectToInvoice={true}
                        closeDialog={props.handleClose}
                        isConsolidated={props.isConsolidated}
                        invoiceData={props.invoiceData}
                        isAutochargeSetup={props.isAutochargeSetup}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {formikProps.values.paymentMode === PaymentMode.BACS_BANK_DEBIT && (
                      <BacsTransaction
                        bookingId={props.booking}
                        invoice={props.invoice}
                        amount={formikProps.values.amount}
                        creditNotesToAdjust={[]}
                        creditAmount={0}
                        businessCustomer={props.businessCustomer}
                        redirectToInvoice={true}
                        isConsolidated={props.isConsolidated}
                        closeDialog={props.handleClose}
                        invoiceData={props.invoiceData}
                        isAutochargeSetup={props.isAutochargeSetup}
                      />
                    )}
                  </Grid>
                  {![PaymentMode.CARD, PaymentMode.SEPA_BANK_DEBIT, PaymentMode.BACS_BANK_DEBIT, PaymentMode.OFFLINE_BANK_TRANSFER, PaymentMode.PAY_BY_LINK].includes(formikProps.values.paymentMode) && (
                    <Grid item xs={12}>
                      <br />
                      <Field
                        component={TextField}
                        label={"Note"}
                        name={"note"}
                        fullWidth
                        multiline
                        rows={"6"}
                        inputProps={{ maxLength: 150 }}
                      />
                    </Grid>
                  )}

                  {![PaymentMode.CARD, PaymentMode.SEPA_BANK_DEBIT, PaymentMode.BACS_BANK_DEBIT, PaymentMode.OFFLINE_BANK_TRANSFER, PaymentMode.PAY_BY_LINK].includes(formikProps.values.paymentMode) && (
                    <Grid item xs={12}>
                      <br />
                      <Fab
                        variant="extended"
                        color="primary"
                        aria-label="addPayment"
                        className={classes.payBtn}
                        type="submit"
                        disabled={
                          !((formikProps.values.paymentMode === PaymentMode.CASH && cashEnabled && capturedAt) ||
                            (formikProps.values.paymentMode === PaymentMode.OFFLINE_BANK_TRANSFER && offlineBankTransferEnabled) ||
                            (formikProps.values.paymentMode === PaymentMode.PAY_BY_LINK && payByLinkEnabled) ||
                            (formikProps.values.paymentMode === PaymentMode.CARD && cardEnabled) ||
                            (formikProps.values.paymentMode === PaymentMode.SEPA_BANK_DEBIT && sepaEnabled && currency === "EUR") ||
                            (formikProps.values.paymentMode === PaymentMode.BACS_BANK_DEBIT && bacsEnabled && currency === "GBP") ||
                            integrations.includes(formikProps.values.paymentMode))
                        }
                      >
                        SAVE PAYMENT
                      </Fab>
                    </Grid>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
