import { gql } from "@apollo/client";

export const ADD_VEHICLE_NOTE = gql`
  mutation addVehicleNote($vehicleId: ID! $note:VehicleNoteInput!) {
    addVehicleNote(vehicleId: $vehicleId,note:$note) {
      id
      description
      createdBy
      createdDate
    }
  }
`;