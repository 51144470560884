import React from 'react';
import styles from './index.module.css';
import { Typography } from '@mui/material';
import { toCurrency } from '../../common/utils';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { useNavigate } from 'react-router-dom';

export interface IDailyBalance {
  sundryInvoiceAmountDue: number
  totalAmountDue: number
  totalInvoicesDue: number
  unpaidSundryInvoices: number
}

const DailyBalanceCard = ({ data }: { data?: IDailyBalance }) => {
  const navigate = useNavigate()
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;

  return (
    <div className={styles.balanceCard}>
      <Typography variant="h4">Daily Balance</Typography>
      {data && <>
        <div>
          <Typography variant="body1" style={{ color: "#A83434", marginTop: 25 }}>Total outstanding</Typography>
          <Typography variant="h1" className="bold" style={{ color: "#A83434" }}>{toCurrency(data.totalAmountDue, currency, locale)}</Typography>
        </div>
        <Typography variant="body1">No. of unpaid invoices: <span style={{ color: "#167ad6", cursor: "pointer" }} onClick={() => navigate("/invoices?type=UNPAID")}>{data.totalInvoicesDue}</span></Typography>
        <div className="flex fill cross-end">
          <div className="padding-right">
            <Typography variant="body1" className="opacity50">Regular invoices</Typography>
            <Typography variant="h3" className="bold">{toCurrency(data.totalAmountDue - data.sundryInvoiceAmountDue, currency, locale)}</Typography>
          </div>
          <div className="margin-left padding-left">
            <Typography variant="body1" className="opacity50">Sundry invoices</Typography>
            <Typography variant="h3" className="bold" >{toCurrency(data.sundryInvoiceAmountDue, currency, locale)}</Typography>
          </div>
        </div>
      </>}
    </div>
  );
}

export default DailyBalanceCard

