import { gql } from "@apollo/client";

export const UPDATE_SUBSCRIPTION_AGREEMENT_SETTINGS = gql`
  mutation updateSubscriptionAgreementSettings(
    $organisationId: ID!
    $branchId: ID!
    $subscriptionAgreementSettings: SubscriptionAgreementSettingsInput!
    $subscriptionAgreementSettingsMap: AgreementSettingsMapInput
  ) {
    updateSubscriptionAgreementSettings(
      organisationId: $organisationId
      branchId: $branchId
      subscriptionAgreementSettings: $subscriptionAgreementSettings
      subscriptionAgreementSettingsMap: $subscriptionAgreementSettingsMap
    ) {
      id
      name
      emailId
      depositCollection
      phoneNumber {
        phone
        country
      }
      address {
        street
        city
        state
        country
        zipcode
        fullAddress
      }
      timeZone
      owner {
        id
        email
      }
      operatingHours{
        day
        dayIndex
        workingFromTime
        workingToTime
        pickupFromTime
        pickupToTime
        returnFromTime
        returnToTime
        closed
      }
      minBookingDuration
      minimumBookingAmount{
        type
        value
      }
      minBookingAmountOptIn
      minBookingDurationOptIn
      preBookingQuestions{
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
        additionalDriverQuestions{
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
      deliveryQuestions{
        question
        answerType
        required
        expectedAnswer
        options
        errorMessage
        B2B
        B2B2C
      }
    }
  }
`;
