
import { getLocalizedBookingSyntex } from "../../../../utils/localized.syntex";
import { IDamageSeverity } from "./NewDamage";
import dayjs from 'dayjs'
//Interval of 15 mins
export const _formatNearestDateTime = (date: string) => {
  const ROUNDING = 15 * 60 * 1000; /*ms*/
  const dateTime = dayjs(date);
  return dayjs(Math.round(+dateTime / ROUNDING) * ROUNDING).toISOString();
};

export const vehicleDamageImages = [
  {
    id: "carFrontImg",
    class: "carFrontImg",
    title: "Front Side"
  },
  {
    id: "carFrontMirrorImg",
    class: "carFrontMirrorImg",
    title: "Front Mirror"
  },
  {
    id: "carTopImg",
    class: "carTopImg",
    title: "Top Side"
  },
  {
    id: "carBackMirrorImg",
    class: "carBackMirrorImg",
    title: "Back Morror"
  },
  {
    id: "carBackImg",
    class: "carBackImg",
    title: "Back Side"
  },
  {
    id: "carLeftImg",
    class: "carLeftImg",
    title: "Left Side"
  },
  {
    id: "carRightImg",
    class: "carRightImg",
    title: "Right Side"
  }
];

export const getVehicleDamageCircumstances = (country: string): any[] => {
  const circumstances = [
    {
      value: "BOOKING",
      label: `During ${getLocalizedBookingSyntex(country)}`
    },
    {
      value: "TRANSIT",
      label: "During Transit"
    },
    {
      value: "MOVEMENT",
      label: "During Movement"
    }
  ];
  return circumstances;
};


export const damageReportsConstants = [
  {
    label: "Document Name",
    name: "documentName"
  },
  {
    label: "Description",
    name: "description"
  }
];

export const damageType = [
  {
    label: "Interior",
    value: "interior damage"
  },
  {
    label: "Exterior",
    value: "exterior damage"
  },
  {
    label: "Other",
    value: "other damage"
  }
];

export const damageSeverity: IDamageSeverity[] = [
  {
    label: "Minor",
    value: "minor"
  },
  {
    label: "Major",
    value: "major"
  }
];

export enum VehicleDamageCircumstance {
  BOOKING = "BOOKING",
  TRANSIT = "TRANSIT",
  MOVEMENT = "MOVEMENT"
}
