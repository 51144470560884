import { Button, CircularProgress, Fab, Grid, IconButton, Paper, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from "@mui/styles";
import { Field, Form, Formik } from 'formik';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { IJobSheet, JobSheetStatus, JobsheetStatusValue, VehicleServiceStatus } from '../../../../../reducers/fleet/types';
import { TextField } from "formik-mui";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { FloatInput } from '../../../../common/FloatInput/FloatInput';
import { ConfirmationDialog } from '../../../../common/ConfirmationDialog/ConfirmationDialog';
import AddNewServiceTaskDialog from './AddNewServiceTaskDialog';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SEND_JOB_SHEET_EMAIL, UPDATE_JOB_SHEET_STATUS } from '../../../../../graphql/fleet/vehicleServices/updateJobSheetStatusMutation';
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { ApolloError } from '@apollo/client';
import { formatGraphQLErrorMessage } from '../../../../common/utils';
import { DOWNLOAD_JOB_SHEET } from '../../../../../graphql/fleet/vehicleServices/getActiveDamagesQuery';
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { captureErrorException } from '../../../../../utils/sentry';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';

interface IProps {
  vehicleServiceId: string;
  serviceBranchId: string;
  serviceStatus: string;
  jobSheet: IJobSheet[];
  jobSheetStatus: JobSheetStatus;
  taxPercent: number;
  onSubmit: (values: { jobSheet: IJobSheet[], taxPercent: number, totalCost: number }) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBlock: "10px",
      borderColor: "f4f4fa",
      padding: theme.spacing(3),
      width: "100%"
    }
  })
);

const ServiceJobSheet: FC<IProps> = ({
  vehicleServiceId,
  serviceBranchId,
  serviceStatus,
  jobSheetStatus,
  jobSheet,
  onSubmit,
  taxPercent,
}) => {
  const styles = useStyles();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [jobSheetData, setJobSheetData] = useState({ jobSheet, subtotal: 0, taxPercent: 0, totalCost: 0 });
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [subtotal, setSubTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<any>(0);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);
  const [_jobSheetStatus, setJobSheetStatus] = useState<JobSheetStatus>(jobSheetStatus);
  const [openUpdateJobSheetStatusDialog, setOpenUpdateJobSheetStatusDialog] = useState(false);
  const [saveStatusLoading, setSaveStatusLoading] = useState(false);
  const [updateJobSheetStatus, { loading: jobLoading }] = useMutation(UPDATE_JOB_SHEET_STATUS, {
    onCompleted: ({ updateServiceJobSheetStatus }) => {
      snackbar({
        message: "Job Sheet status has been updated.",
        variant: SnackBarVariant.SUCCESS
      });
      setJobSheetStatus(updateServiceJobSheetStatus.status);
      setOpenUpdateJobSheetStatusDialog(false);
      setSaveStatusLoading(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
      setOpenUpdateJobSheetStatusDialog(false);
      setSaveStatusLoading(false);
    }
  });

  const [sendJobSheetEmailMutation, { loading: sentJobSheetEmailLoading }] = useMutation(SEND_JOB_SHEET_EMAIL, {
    onCompleted: () => {
      snackbar({
        message: "Job Sheet has been sent.",
        variant: SnackBarVariant.SUCCESS
      });
      setOpenSendEmailDialog(false);
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
      setOpenSendEmailDialog(false);
    }
  });


  const [downloadJobSheetPdf, { loading: lodingDownloadJobsheet }
  ] = useLazyQuery(
    DOWNLOAD_JOB_SHEET, {
    fetchPolicy: "network-only",
    onCompleted: async ({ downloadJobSheet }) => {
      try {
        if (downloadJobSheet.key) {
          setLoading(true);
          const file = await getSignedUrl(downloadJobSheet.key);
          const url: any = file;
          fetch(url, {
            method: "GET"
          })
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = `JobSheet.pdf`;
              document.body.appendChild(a);
              a.click();
              a.remove();
              setLoading(false);
            });
        } else {
          snackbar({
            message: "File being generated, Please try after sometime",
            variant: SnackBarVariant.ERROR
          });
        }
      } catch (err: any) {
        captureErrorException(err)
        snackbar({
          message: err?.message,
          variant: SnackBarVariant.ERROR
        });
      }
    }
  });

  useEffect(() => {
    setJobSheetData({
      ...jobSheetData,
      jobSheet: jobSheet && jobSheet.length ? jobSheet : [],
      taxPercent: taxPercent ? taxPercent : 0
    })
  }, [jobSheet, taxPercent]);

  useEffect(() => {
    setJobSheetStatus(jobSheetStatus)
  }, [jobSheetStatus])

  return (
    <Paper className={styles.root} elevation={0}>
      <Formik
        initialValues={jobSheetData}
        onSubmit={() => { }}
      >
        {() => {
          let total = 0;
          jobSheetData.jobSheet.forEach((item) => {
            total += item.cost;
          });
          let _grandTotal = Math.round((total + total * jobSheetData.taxPercent / 10000));
          setSubTotal(total);
          setGrandTotal(_grandTotal);
          return (
            <Form>
              <Grid container xs={12} style={{ border: "1px solid #e0e0e0", padding: "2rem" }}>
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid item xs={8}>
                    <Typography variant="h3" gutterBottom>
                      Job Sheet
                    </Typography>
                  </Grid>
                  {jobSheetData.jobSheet.length > 0 &&
                    <Grid item xs={8}>
                      Job Sheet Status : <span style={{
                        color: _jobSheetStatus === JobSheetStatus.APPROVED ? "green" :
                          _jobSheetStatus === JobSheetStatus.REJECTED ? "red" : "orange"
                      }}>{JobsheetStatusValue[_jobSheetStatus]} </span>
                    </Grid>
                  }
                  <Grid container item xs={4} justifyContent="flex-end">
                    <Button
                      onClick={() => {
                        setOpenAddDialog(true);
                      }}
                      disabled={(serviceBranchId && userState.currentBranch.id !== serviceBranchId) || serviceStatus === VehicleServiceStatus.COMPLETED || serviceStatus === VehicleServiceStatus.CANCELLED}
                    >
                      + Add Task
                    </Button>
                  </Grid>
                </Grid>

                {jobSheetData.jobSheet && jobSheetData.jobSheet.length > 0 &&
                  jobSheetData.jobSheet.map((jobSheetItem, index) => (
                    <Grid container xs={12} md={12} lg={12} spacing={2} key={index}>
                      <Grid item xs={3} style={{ marginTop: 8 }}>
                        <Field
                          component={TextField}
                          label="Name"
                          fullWidth
                          required
                          name={`${jobSheetData.jobSheet[index]}.name`}
                          InputProps={{
                            value: jobSheetItem.name,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setJobSheetData({
                                ...jobSheetData,
                                jobSheet: [
                                  ...jobSheetData.jobSheet.slice(0, index),
                                  { ...jobSheetData.jobSheet[index], name: e.target.value },
                                  ...jobSheetData.jobSheet.slice(index + 1)
                                ]
                              })
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} style={{ marginTop: 8 }}>
                        <Field
                          component={TextField}
                          label="Description"
                          fullWidth
                          name={`${jobSheetData.jobSheet[index]}.description`}
                          InputProps={{
                            value: jobSheetItem.description,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setJobSheetData({
                                ...jobSheetData,
                                jobSheet: [
                                  ...jobSheetData.jobSheet.slice(0, index),
                                  { ...jobSheetData.jobSheet[index], description: e.target.value },
                                  ...jobSheetData.jobSheet.slice(index + 1)
                                ]
                              })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: 8 }}>
                        <Field
                          component={TextField}
                          fullWidth
                          placeholder="Cost"
                          label="Cost"
                          required
                          name={`${jobSheetData.jobSheet[index]}.cost`}
                          InputProps={{
                            value: jobSheetItem.cost,
                            inputComponent: FloatInput as any,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setJobSheetData({
                                ...jobSheetData,
                                jobSheet: [
                                  ...jobSheetData.jobSheet.slice(0, index),
                                  { ...jobSheetData.jobSheet[index], cost: parseInt(e.target.value) },
                                  ...jobSheetData.jobSheet.slice(index + 1)
                                ]
                              })
                            }
                          }}
                          inputProps={{
                            hasCurrencyPrefix: true,
                            allowNegative: false
                          }}
                        ></Field>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setSelectedItemIndex(index);
                            setOpenDialog(true)
                          }}
                          disabled={(
                            (serviceBranchId && userState.currentBranch.id !== serviceBranchId) ||
                            serviceStatus === VehicleServiceStatus.COMPLETED ||
                            serviceStatus === VehicleServiceStatus.CANCELLED ||
                            jobSheetData.jobSheet.length === 1) ? true : false
                          }
                          size="large">
                          <HighlightOffIcon style={{ paddingLeft: 1 }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}

                {openDialog && <ConfirmationDialog
                  isOpen={openDialog}
                  description="Are you sure you want to delete this item from the Job Sheet?"
                  onCancel={() => setOpenDialog(false)}
                  onConfirm={() => {
                    const updatedJobSheet = [...jobSheetData.jobSheet];
                    updatedJobSheet.splice(selectedItemIndex, 1);
                    setJobSheetData({
                      ...jobSheetData,
                      jobSheet: [...updatedJobSheet]
                    })
                    setOpenDialog(false);
                  }}
                  title=""
                  confirmText='Yes'
                  cancelText='No'
                />}
                {openAddDialog && (
                  <AddNewServiceTaskDialog
                    open={openAddDialog}
                    handleClose={() => setOpenAddDialog(false)}
                    handleSubmit={(value) => {
                      setJobSheetData({
                        ...jobSheetData,
                        jobSheet: [
                          { ...value },
                          ...jobSheetData.jobSheet
                        ]
                      })
                      setOpenAddDialog(false);
                    }}
                    inProgress={false}
                  />
                )}
              </Grid>

              {jobSheetData.jobSheet && jobSheetData.jobSheet.length > 0 ? (
                <Fragment>
                  <Grid container xs={11} justifyContent="flex-end" spacing={1} style={{ marginTop: 10 }}>
                    <Grid item xs={1}>
                      <Typography variant="subtitle2" gutterBottom>
                        Sub Total
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={TextField}
                        name={"subTotal"}
                        placeholder={"e.g 100"}
                        disabled={true}
                        InputProps={{
                          value: subtotal,
                          inputComponent: FloatInput as any
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                        value={jobSheetData.subtotal}
                        onChange={(event: any) => { }}
                        fullWidth
                      ></Field>
                    </Grid>
                  </Grid>
                  <Grid container xs={11} justifyContent="flex-end" spacing={1} style={{ marginTop: 10 }}>
                    <Grid item xs={1}>
                      <Typography variant="subtitle2" gutterBottom>
                        VAT/TAX(%)
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={TextField}
                        name={"taxPercent"}
                        placeholder={"VAT/TAX(%)"}
                        InputProps={{
                          value: jobSheetData.taxPercent,
                          inputComponent: FloatInput as any,
                          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            if (event.target.value) {
                              const val = parseFloat(event.target.value) ? parseFloat(event.target.value) : 0;
                              if (val > 10000) {
                                snackbar({
                                  message:
                                    "Tax value can't be more than 100%",
                                  variant: SnackBarVariant.ERROR
                                });
                              }
                              else if (val < 0) {
                                snackbar({
                                  message:
                                    "Tax value can't be less than 0%",
                                  variant: SnackBarVariant.ERROR
                                });
                              }
                              setJobSheetData({
                                ...jobSheetData,
                                taxPercent: val
                              })
                            }
                          }
                        }}
                        inputProps={{
                          allowNegative: false
                        }}
                        fullWidth
                        disabled={serviceStatus === VehicleServiceStatus.COMPLETED}
                      ></Field>
                    </Grid>
                  </Grid>
                  <Grid container xs={11} justifyContent="flex-end" spacing={1} style={{ marginTop: 10 }}>
                    <Grid item xs={1}>
                      <Typography variant="subtitle2" gutterBottom>
                        Grand Total
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={TextField}
                        name={"totalCost"}
                        placeholder={"e.g 100"}
                        disabled={true}
                        InputProps={{
                          value: grandTotal,
                          inputComponent: FloatInput as any
                        }}
                        inputProps={{
                          hasCurrencyPrefix: true,
                          allowNegative: false
                        }}
                        fullWidth
                      ></Field>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Fab
                      variant="extended"
                      size="medium"
                      aria-label="Save"
                      className="createButton"
                      disabled={saveStatusLoading || (serviceBranchId && userState.currentBranch.id !== serviceBranchId) || serviceStatus === VehicleServiceStatus.COMPLETED ||
                        serviceStatus === VehicleServiceStatus.CANCELLED ||
                        jobSheetData.jobSheet.some((item) => !item.name)}
                      onClick={() => {
                        jobSheetData.subtotal = subtotal;
                        jobSheetData.totalCost = grandTotal;
                        if (jobSheetData.taxPercent > 10000) {
                          return snackbar({
                            message:
                              "Tax value can't be more than 100%",
                            variant: SnackBarVariant.ERROR
                          });
                        }
                        if (jobSheetData.taxPercent < 0) {
                          return snackbar({
                            message:
                              "Tax value can't be less than 0%",
                            variant: SnackBarVariant.ERROR
                          });
                        }
                        if (vehicleServiceId) {
                          updateJobSheetStatus({
                            variables: {
                              vehicleServiceId,
                              status: JobSheetStatus.PENDING
                            }
                          });
                          setSaveStatusLoading(true);
                        }
                        onSubmit({
                          jobSheet: jobSheetData.jobSheet,
                          taxPercent: jobSheetData.taxPercent,
                          totalCost: jobSheetData.totalCost
                        });
                      }}
                    >
                      {saveStatusLoading && (
                        <CircularProgress
                          size={14}
                          style={{ color: "white", marginRight: "10px" }}
                        />
                      )}
                      Save
                    </Fab>
                    {vehicleServiceId && (
                      <>
                        <Fab
                          variant="extended"
                          size="medium"
                          aria-label="Approve"
                          className="createButton"
                          style={{ marginLeft: 8 }}
                          disabled={
                            _jobSheetStatus === JobSheetStatus.APPROVED ||
                              (serviceBranchId && userState.currentBranch.id !== serviceBranchId) ||
                              serviceStatus === VehicleServiceStatus.CANCELLED ||
                              serviceStatus === VehicleServiceStatus.COMPLETED ? true : false
                          }
                          onClick={() => {
                            setOpenUpdateJobSheetStatusDialog(true);
                            setStatus(JobSheetStatus.APPROVED);
                          }}
                        >
                          Approve
                        </Fab>
                        <Fab
                          variant="extended"
                          size="medium"
                          aria-label="Reject"
                          className="createButton"
                          style={{ marginLeft: 8 }}
                          disabled={
                            _jobSheetStatus === JobSheetStatus.REJECTED ||
                              (serviceBranchId && userState.currentBranch.id !== serviceBranchId) ||
                              serviceStatus === VehicleServiceStatus.CANCELLED ||
                              serviceStatus === VehicleServiceStatus.COMPLETED ? true : false
                          }
                          onClick={() => {
                            setOpenUpdateJobSheetStatusDialog(true);
                            setStatus(JobSheetStatus.REJECTED);
                          }}
                        >
                          Reject
                        </Fab>
                        <Fab
                          variant="extended"
                          size="medium"
                          aria-label="Send Email"
                          className="createButton"
                          style={{ marginLeft: 8 }}
                          disabled={
                            serviceBranchId && userState.currentBranch.id !== serviceBranchId ? true : false
                          }
                          onClick={() => {
                            setOpenSendEmailDialog(true);
                          }}
                        >
                          Send Email
                        </Fab>
                        <Fab
                          variant="extended"
                          size="medium"
                          aria-label="download"
                          className="createButton"
                          style={{ marginLeft: 8 }}
                          onClick={() => {
                            downloadJobSheetPdf({
                              variables: {
                                vehicleServiceId
                              }
                            })
                          }}
                          disabled={(serviceBranchId && userState.currentBranch.id !== serviceBranchId) || loading || lodingDownloadJobsheet}
                        >
                          {loading || lodingDownloadJobsheet && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                          {"Download"}
                        </Fab>
                      </>
                    )}
                    {vehicleServiceId &&
                      <Typography style={{ marginTop: 10 }}>
                        Clicking on save button without modifying anything on the JobSheet, Will update jobsheet status to Pending
                      </Typography>
                    }
                  </Grid>
                  {openUpdateJobSheetStatusDialog && <ConfirmationDialog
                    isOpen={openUpdateJobSheetStatusDialog}
                    description={`Are you sure you want to ${status === JobSheetStatus.APPROVED ? "Approve" : "Reject"} this Job Sheet?`}
                    onCancel={() => setOpenUpdateJobSheetStatusDialog(false)}
                    onConfirm={() => {
                      updateJobSheetStatus({
                        variables: {
                          vehicleServiceId,
                          status
                        }
                      });
                    }}
                    title=""
                    confirmText='Yes'
                    cancelText='No'
                    isSendButtonClicked={jobLoading}
                  />}
                  {openSendEmailDialog && <ConfirmationDialog
                    isOpen={openSendEmailDialog}
                    isInProgress={sentJobSheetEmailLoading}
                    title="Send JobSheet"
                    description={`The email be will sent on the Garage contact person email address`}
                    onCancel={() => setOpenSendEmailDialog(false)}
                    onConfirm={() => {
                      sendJobSheetEmailMutation({
                        variables: {
                          vehicleServiceId
                        }
                      });
                    }}
                    confirmText='Send'
                    cancelText='Cancel'
                    isSendButtonClicked={sentJobSheetEmailLoading}
                  />}
                </Fragment>
              ) : (
                <div style={{ marginTop: 20 }}>
                  <p>No Task found</p>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}

export default ServiceJobSheet;
