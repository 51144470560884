import {
  Fab,
  Grid,
  IconButton,
  Paper,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import {
  checkDeleteOrDownloadFileType,
  checkUploadFileFormat,
  returnSortedDocuments,
  uploadFileExtensionAndContentType,
  formatGraphQLErrorMessage,
  DATE_TYPE
} from "../../../../common/utils";
import { DocumentDialog } from "../../../CustomerManagement/CustomerDetails/DocumentDialog";
import { v4 as uuidv4 } from "uuid";
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../../store";
import { IInvoice, IInvoiceDocument } from "../../../../../reducers/invoices/types";
import { NuvvenTable } from "../../../../common/NuvvenTable/NuvvenTable";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "@apollo/client";
import { ADD_SUNDRY_INVOICE_DOCUMENT, REMOVE_SUNDRY_INVOICE_DOCUMENT } from "../../../../../graphql/invoices/sundryInvoiceDocument";
import { ApolloError } from "@apollo/client";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog/ConfirmationDialog";
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { getLocalizedDateFormat } from "../../../../../utils/localized.syntex";
import { captureErrorException } from "../../../../../utils/sentry";

interface IProps {
  invoice: IInvoice;
}

interface IViewDocumentProps {
  invoice: IInvoice;
  isOpen: boolean;
  deleteInProgress: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  downloadDocument(documentUrl: string, documentName: string): void;
  deleteDocument(url: string, index: number | undefined): void;
}

export const UploadSundryDocument: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const [documentDialogVisible, setDocumentDialogVisible] = useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);

  const [addSundryInvoiceDocument] = useMutation(ADD_SUNDRY_INVOICE_DOCUMENT, {
    onCompleted: ({ addSundryInvoiceDocument }) => {
      if (addSundryInvoiceDocument) {
        snackbar({
          message: "Document added sucessfully",
          variant: SnackBarVariant.SUCCESS
        });
        if (!props.invoice.documents || (props.invoice.documents && !props.invoice.documents.length)) {
          props.invoice.documents = [];
        }
        props.invoice.documents?.unshift({
          title: addSundryInvoiceDocument.tittle,
          documentName: addSundryInvoiceDocument.documentName,
          documentUrl: addSundryInvoiceDocument.documentUrl,
          documentType: addSundryInvoiceDocument.documentType,
          expiryDate: addSundryInvoiceDocument.expiryDate,
        })
        setDocumentDialogVisible(false);
        setUploadInProgress(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setDocumentDialogVisible(false);
      setUploadInProgress(false);
    }
  });

  const [removeSundryInvoiceDocument] = useMutation(REMOVE_SUNDRY_INVOICE_DOCUMENT, {
    onCompleted: () => { },
    onError: () => { }
  })

  async function handleDocumentSave(file: File, title: string, documentType: string, reminderBefore: number, expiryDate: string) {
    try {
      // 50MB limit exceeded
      if (file.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      const fileName = file.name.split(".")[0];
      setUploadInProgress(true);
      if (checkUploadFileFormat(file.type)) {
        const uniqueId = uuidv4();
        const {
          fileExtension
        } = uploadFileExtensionAndContentType(file.type);
        const key = `${uniqueId}-sundry-invoice-document.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                addSundryInvoiceDocument({
                  variables: {
                    invoiceId: props.invoice.id,
                    // document: newDocuments
                    document: {
                      documentUrl: key,
                      title,
                      documentName: fileName,
                      expiryDate,
                      documentType,
                      reminderBefore,
                    }
                  }
                })
              }
            });
        }
      } else {
        setDocumentDialogVisible(false);
        setUploadInProgress(false);
        return snackbar({
          message: "Please only upload .pdf!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      captureErrorException(err)
      snackbar({ message: err?.message, variant: SnackBarVariant.ERROR });
    }
  }

  const downloadDocument = async (
    documentUrl: string,
    documentName: string
  ) => {
    const { fileExtension } = checkDeleteOrDownloadFileType(
      documentUrl
    );
    const file = await getSignedUrl(documentUrl)
    const url: any = file;
    fetch(url, {
      method: "GET"
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${documentName}.${fileExtension}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const deleteDocument = (
    url: string,
    index: number,
  ) => {
    setDeleteInProgress(true);
    const { contentType } = checkDeleteOrDownloadFileType(url);
    // TODO: implement file deleteion from s3
    removeSundryInvoiceDocument({
      variables: {
        invoiceId: props.invoice.id,
        documentUrl: url
      }
    }).then((result) => {
      if (index >= 0) {
        props.invoice.documents?.splice(index, 1);
      }
      snackbar({
        message: "Document removed sucessfully!.",
        variant: SnackBarVariant.SUCCESS
      });
      setDeleteInProgress(false);
      handleDialogClose();
    })
  };

  const handleDialogOpen = () => {
    setOpenDeleteDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDeleteDialog(false);
  }

  return (
    <Grid container spacing={2}>
      <Grid item container xs={10}>
        <Typography variant="h2" gutterBottom>
          Additional Documents
        </Typography>
      </Grid>
      <Grid item container xs={2} justifyContent="flex-end">
        <Fab
          className="blackBackButton"
          variant="extended"
          size="medium"
          aria-label="Update"
          onClick={() => {
            setDocumentDialogVisible(true);
          }}
        >
          ADD DOCUMENT
        </Fab>
      </Grid>
      <DocumentDialog
        open={documentDialogVisible}
        handleClose={() => setDocumentDialogVisible(false)}
        uploadInProgress={uploadInProgress}
        onSubmit={handleDocumentSave}
        accept="application/pdf"
      />
      <Grid item xs={12}>
        <Paper variant="outlined">
          <ViewDocuments
            isOpen={openDeleteDialog}
            deleteInProgress={deleteInProgress}
            handleOpen={handleDialogOpen}
            handleClose={handleDialogClose}
            invoice={props.invoice}
            downloadDocument={downloadDocument}
            deleteDocument={deleteDocument}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}


const ViewDocuments = (props: IViewDocumentProps) => {

  const userState = useSelector((state: IAppState) => state.userReducer);
  const { country } = userState.currentOrganisation.address

  const [deleteDocumentUrl, setDeleteDocumentUrl] = useState<string>("");
  const [documentIndex, setDocumentIndex] = useState<number>();

  const columns = [
    {
      label: "Document Type",
      name: "documentType",
    },
    {
      label: "Document Name/Title",
      name: "title"
    },
    {
      label: "Expiry Date",
      name: "expiryDate",
      options: {
        customBodyRender: (value: string) => value ? getLocalizedDateFormat(country, value, DATE_TYPE.CONDENSED) : "N/A"
      }
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <React.Fragment>
              <div>
                <IconButton
                  edge="end"
                  aria-label="download"
                  onClick={() =>
                    props.downloadDocument(value.url, value.name)
                  }
                >
                  <CloudDownloadIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    setDeleteDocumentUrl(value.url);
                    setDocumentIndex(value.index);
                    props.handleOpen();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </React.Fragment>
          );
        }
      }
    }
  ];

  const getRows = (invoiceDocuments: IInvoiceDocument[]) => {
    if (!invoiceDocuments || invoiceDocuments === null) {
      return [];
    }
    const docs = returnSortedDocuments(invoiceDocuments)
    if (docs && docs.length > 0) {
      return docs.map((invoiceDocuments: IInvoiceDocument, index: number) => {
        return {
          ...invoiceDocuments,
          actions: { url: invoiceDocuments.documentUrl, name: invoiceDocuments.documentName, index },
          title: invoiceDocuments.title || invoiceDocuments.documentName,
          expiryDate: invoiceDocuments.expiryDate,
          documentType: invoiceDocuments.documentType
        };
      });
    } else {
      return []
    }
  };

  return (
    <Grid item xs={12}>
      <NuvvenTable
        key={new Date().getMilliseconds()}
        title={""}
        rows={props.invoice && props.invoice.documents ? getRows(props.invoice.documents) : []}
        columns={columns}
        options={{
          selectableRows: "none",
          download: false,
          filter: false,
          print: false,
          search: false,
          viewColumns: false,
          elevation: 0
        }}
      />
      {props.isOpen && (
        <ConfirmationDialog
          isOpen={props.isOpen}
          onCancel={props.handleClose}
          onConfirm={() => {
            props.deleteDocument(deleteDocumentUrl, documentIndex);
          }}
          isInProgress={props.deleteInProgress}
          title=""
          description={"Are you sure, you want to delete this document?"}
        />
      )}
    </Grid>
  );
};


