import { Grid, Typography } from '@mui/material'
import React from 'react'

interface IProps {
  handleClose(): void
}

export const DropEventError: React.FC<IProps> = (props) => {

  return (
    <Grid container item xs={12}>
      <Typography variant="h4" style={{ marginBottom: 20 }}>
        You can't drop event into past date or same date.
      </Typography>
    </Grid>
  )
}
