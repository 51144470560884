import { Box, Fab, Grid, Typography } from '@mui/material'
import React from 'react'

interface IProps {
  handleClose(): void
  completeBooking(): void
}
export const ConfirmCompleteBooking: React.FC<IProps> = (props) => {

  const { handleClose, completeBooking } = props
  return (
    <Grid container item xs={12} spacing={2} style={{ marginBottom: 20 }}>
      <Grid item xs={12}>
        <Typography variant={"body1"}>Are you sure you want to Complete this booking</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Fab
          variant={"extended"}
          size="medium"
          onClick={() => completeBooking()}
        >
          YES
        </Fab>
        <Box pl={2}></Box>
        <Fab
          variant={"extended"}
          size="medium"
          className="blackBackButton"
          onClick={() => {
            handleClose()
          }}
        >
          NO
        </Fab>
      </Grid>
    </Grid>
  )
}
