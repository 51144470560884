import "./index.scss";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions
} from "mui-datatables";
import React from "react";
import { SelectableRows } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      marginRight: "0px"
    }
  })
);

interface IProps {
  columns: MUIDataTableColumnDef[];
  rows: (object | number[] | string[])[];
  title: string;
  options?: MUIDataTableOptions;
  setSelection?(items: any): void;
  hideSelectableRows?: boolean;
}

export const NuvvenTable = (props: IProps) => {
  // FIXME: Add option to hide checkboxes
  const classes = useStyles();
  const onRowSelectionChange = (currentRowsSelected: any[], allRowsSelected: any[]) => {
    if (props.setSelection) {
      const selection = allRowsSelected.map(
        (item: any) => props.rows[item.dataIndex]
      );
      props.setSelection(selection);
    }
  };

  // default options
  const options: MUIDataTableOptions = {
    customToolbarSelect: () => <></>,
    rowsPerPageOptions: [10, 20, 100],
    onRowSelectionChange,
    search: true,
    rowsPerPage: 10,
    selectableRows: props.hideSelectableRows ? "none" : SelectableRows.SINGLE,
    // custom search for making search case-insensitive
    customSearch: (searchQuery: any, currentRow: any, columns: any) => {
      let isFound = false;
      currentRow.forEach((col: any) => {
        if (col) {
          const value = col.toString().toLowerCase();
          if (searchQuery && value.indexOf(searchQuery.toLowerCase()) >= 0) {
            isFound = true;
          }
        }
      });
      return isFound;
    },
    downloadOptions: {
      filename: "tableDownload.csv",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    },
    enableNestedDataAccess: ".",
    responsive: "simple"
  };

  const myOptions = props.options ? { ...options, ...props.options } : options;
  return (
    <div className={["table-container", classes.tableContainer].join(" ")}>
      <MUIDataTable
        title={props.title}
        data={props.rows}
        columns={props.columns}
        options={myOptions}
      />
    </div>
  );
};
