import { gql } from "@apollo/client";

export const GET_VEHICLE_CHECKLIST = gql`
  query getVehicleCheckList($frogDiagram: String!){
    getVehicleCheckList(frogDiagram: $frogDiagram){
      id
      generalCheckListItems {
        name
        isMandatory
      }
      accessoriesCheckListItems {
        name
        isMandatory
      }
      frogDiagram
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;