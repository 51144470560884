import { gql } from "@apollo/client";

export const GET_DASHBOARD_DATA = gql`
{
 dashboardData
  {
		vehiclePickUpReturnData{
			pickups{
				date
        total
        withValet
        bookingIds
        bookingIdsWithValet
    	}
    	returns{
				date
        total
        withValet
        bookingIds
        bookingIdsWithValet
    	}
  	}
  	vehicleAvailabilityData{
			activeVehicles
	    outOnHire
  	  onService
	    onHold
  		booked
  	}
    upcomingEvents {
      pickups{
        id
        licencePlate
        eventTime
      }
      deliveries{
        id
        licencePlate
        eventTime
      }
      returns{
        id
        licencePlate
        eventTime
      }
      collections{
        id
        licencePlate
        eventTime
      }
      services{
        id
        licencePlate
        eventTime
      }
    }
    complianceReminders{
      name
      numbers{
        soon
        overdue
        soonIds
        overdueIds
      }
    }
    dailyBalance{
      sundryInvoiceAmountDue
      totalAmountDue
      totalInvoicesDue
      unpaidSundryInvoices
    }
    externalVehicleBookings{
      bookingId
      movementId
    }
	}
}
`;

export const GET_BOOKINGS_BY_DATES = gql`
  query getBookingsByDates($year: Int) {
    getBookingsByDates(year: $year)
  }
`;

export const GET_VIRTUAL_VEHICLE_BOOKINGS_BY_DATES = gql`
  query getVirtualVehicleBookingsByDates($year: Int) {
    getVirtualVehicleBookingsByDates(year: $year)
  }
`;


