import { VEHICLE_DAMAGE_INITIALSTATE } from "./const";
import { IVehicleDamageState } from "./types";
export const UPDATE_VEHICLE_DAMAGE_INFO = "UPDATE_VEHICLE_DAMAGE_INFO";

export default function (
  state: IVehicleDamageState = VEHICLE_DAMAGE_INITIALSTATE,
  action: any
): IVehicleDamageState {
  switch (action.type) {
    case UPDATE_VEHICLE_DAMAGE_INFO:
      return {
        ...state
      };
    default:
      return state;
  }
}
