import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  Typography,
  Theme,
  Grid,
  Fab,
  TextField,
  MenuItem
} from "@mui/material";

import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DateTime as d } from "luxon"
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import { assetDamageKinds, assetDamageTypes, IAssetDamageItem } from '../asset.types';
import { DamageTypes } from '../../../common/VehicleState/VehicleState';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { captureErrorException } from '../../../../utils/sentry';
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { ErrorMessage } from 'formik';
import { ImageView } from '../../Fleet/Issues&Damages/NewDamage/ImagesView';
import DragDropImages from '../../../common/DragAndDropFiles/DragAndDropImage/DragDropImages';
import { v4 as uuidv4 } from "uuid";

interface IProps {
  open: boolean;
  assetDamageItem: IAssetDamageItem;
  images: (images: string[]) => void;
  handleClose: () => void;
  handleSubmit: (assetDamageItem: IAssetDamageItem) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const AssetDamageItems: FC<IProps> = (props) => {
  const { open, assetDamageItem, handleClose, handleSubmit } = props;
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const [openDragDrop, setOpenDragDrop] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>([]);
  const [damageItem, setDamageItem] = useState<IAssetDamageItem>(assetDamageItem);

  useEffect(() => {
    if (
      assetDamageItem &&
      assetDamageItem.images &&
      assetDamageItem.images.length > 0
    ) {
      const promises: any[] = [];
      const modifyImages = JSON.parse(
        JSON.stringify(assetDamageItem.images)
      );
      assetDamageItem.images.map(
        (id: string, index: number) => {
          promises.push(
            (async () => {
              try {
                const data: any = await getImages(id);
                modifyImages[index] = data;
              } catch (error) {
                captureErrorException(error)
              }
            })()
          );
        }
      );
      Promise.all(promises).then(() => {
        setImages(modifyImages);
      });
    }
  }, [assetDamageItem]);

  const getImages = async (key: string) => {
    const file = await getSignedUrl(key)
    return file;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    // className={"select-holidays-dialog"}
    >
      <React.Fragment>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <p style={{ fontSize: 18, color: "var(--theme-accent)", padding: 0, margin: 0 }}>
            Add Damage Items
          </p>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} style={{ marginTop: 10 }}>
              <TextField
                name="title"
                label="Damage Title"
                placeholder="Damage Title"
                value={damageItem.title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setDamageItem({
                    ...damageItem,
                    title: e.target.value
                  })
                }}
                fullWidth
                required
              ></TextField>
            </Grid>
            <Grid item xs={6} md={4} style={{ marginTop: 10 }}>
              <TextField
                name="damageType"
                label="Damage Type"
                placeholder="Damage Type"
                value={damageItem.damageType}
                select
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setDamageItem({
                    ...damageItem,
                    damageType: e.target.value
                  })
                }}
                fullWidth
                required
              >
                {assetDamageTypes.map((item: any, index: number) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={6} md={4} style={{ marginTop: 10 }}>
              <TextField
                name="damageKind"
                label="Damage Kind"
                placeholder="Damage Kind"
                value={damageItem.damageKind}
                select
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setDamageItem({
                    ...damageItem,
                    damageKind: e.target.value
                  })
                }}
                fullWidth
                required
              >
                {assetDamageKinds.map((item: any, index: number) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={6} md={4} style={{ marginTop: 10 }}>
              <TextField
                name="damageArea"
                label="Damage Area"
                placeholder="Damage Area"
                value={damageItem.damageArea}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setDamageItem({
                    ...damageItem,
                    damageArea: e.target.value
                  })
                }}
                fullWidth
              >
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
              <TextField
                name="descriptions"
                label="Description"
                multiline
                placeholder="Description"
                rows={4}
                value={damageItem.descriptions}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setDamageItem({
                    ...damageItem,
                    descriptions: e.target.value
                  })
                }}
                fullWidth
              >
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid xs={12} sm={12}>
                <Fab
                  className="interiorImageButton"
                  variant="extended"
                  size="medium"
                  onClick={() => setOpenDragDrop(true)}
                >
                  {userState.currentOrganisation.maxImagesPerDamage && userState.currentOrganisation.maxImagesPerDamage > 1 ? 'Add Image(s)' : 'Add Image'}
                </Fab>
              </Grid>
              <Grid item xs={12} sm={12} style={{ marginTop: "45px", marginLeft: "18px" }}>
                {images && images.length > 0 && (
                  <ImageView images={images} />
                )}
              </Grid>
            </Grid>
            <DragDropImages
              open={openDragDrop}
              handleCloseDragDrop={() => setOpenDragDrop(false)}
              filesLimit={userState.currentOrganisation.maxImagesPerDamage || 1}
              handleSave={async (files: any) => {
                if (_e_) {
                  const client = _e_;
                  const filePromises = [];
                  const newImages: any = [];
                  const imagesView: any[] = [];
                  if (files) {
                    for (var i = 0; i < files.length; i++) {
                      const name = files[i].name;
                      const lastDot = name.lastIndexOf('.');
                      const ext = name.substring(lastDot + 1);
                      const key = `${userState.tenancy.id}/${uuidv4()}.${ext}`;
                      const promise = client
                        .add({
                          name: key,
                          file: files[i],
                        });
                      newImages.push(key);
                      const file = await getSignedUrl(key);
                      imagesView.push(file);
                      filePromises.push(promise);
                    }
                    setOpenDragDrop(false);
                    Promise.all(filePromises).then(() => {
                      setDamageItem({
                        ...damageItem,
                        images: [...newImages]
                      });
                      setImages([...imagesView]);
                      props.images([...imagesView]);
                    }).catch((err) => {
                      captureErrorException(err)
                    });
                  }
                }
              }}
            />
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Fab
                variant="extended"
                size="medium"
                aria-label="submit"
                onClick={() => {
                  handleSubmit(damageItem)
                }}
                disabled={!damageItem.title || !damageItem.damageType || !damageItem.damageKind || !damageItem.images?.length}
              >
                Add
              </Fab>
            </Grid>
          </Grid>
        </DialogContent>
      </React.Fragment>
    </Dialog >
  )
}
