import { gql } from "@apollo/client";

export const ADD_BUSINESS_CUSTOMER_COI_DETAILS = gql`
  mutation addBusinessCustomerCoiDetails(
    $businessCustomerId: ID!
    $coiDetails: CoiInput!
  ) {
    addBusinessCustomerCoiDetails(
      businessCustomerId: $businessCustomerId
      coiDetails: $coiDetails
    ) {
      policyName
      policyNumber
      provider
      expiryDate
      documentUrl
    }
  }
`;