import { useLazyQuery, useMutation } from "@apollo/client";
import { SelectableRows } from "./../../../common/NuvvenTable/types";
import {
  Theme,
  AppBar,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Fab,
  Grid,
  Hidden,
  Typography,
  InputBase,
  alpha
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import SearchIcon from '@mui/icons-material/Search';
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import papa from "papaparse";
import { ApolloError } from "@apollo/client";
import {
  resetBusinessCustomer,
} from "../../../../actions/customer/actions";
import { addTableState } from "../../../../actions/tableState/actions";
import { GET_LIMIT_BUSINESS_CUSTOMERS } from "../../../../graphql/businessCustomers/businessCustomer";
import { UPLOAD_BUSINESS_CUSTOMER } from "../../../../graphql/businessCustomers/uploadBusinessCustomer";
import { BusinessCustomerStatus, IBusinessCustomer } from "../../../../reducers/customer/types";
import { ITable, TableNames } from "../../../../reducers/tableState/types";
import { IAppState } from "../../../../store";
import { BulkCsvUploadDialog } from "../../../common/BulkCsvUploadDialog/BulkCsvUploadDialog";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import {
  CsvUploadStatusType,
  ICsvUploadError,
  businessCustomerCsvHeaders,
  formatGraphQLErrorMessage,
  possibleCsvMimeTypes,
  returnSortedOrder,
  setTableSortOrder,
} from "../../../common/utils";
import { NuvvenTable } from "./../../../common/NuvvenTable/NuvvenTable";
import { useNavigate } from "react-router-dom";
import BusinessCustomerVirtualScroller from "./BusinessCustomerVirtualScroller";
import { GET_BUSINESS_CUSTOMER_COUNT } from "../../../../graphql/businessCustomers/getBusinessCustomersCount";
import { SEARCH_BUSINESS_CUSTOMER } from "../../../../graphql/businessCustomers/searchBusinessCustomer";
import _ from "lodash";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { captureErrorException } from "../../../../utils/sentry";

export const columns: any = [
  {
    label: "Business Name",
    name: "businessName"
  },
  {
    label: "Contact Name",
    name: "contact",
    options: {
      customBodyRender: (value: any, tableMeta: any) => {
        const { rowData } = tableMeta;
        const firstName = rowData[1].firstName;
        const lastName = rowData[1].lastName;
        const fullName = `${firstName} ${lastName}`;
        return fullName;
      }
    }
  },
  {
    label: "Email",
    name: "contact.email"
  },
  {
    label: "Phone Number",
    name: "contact.phoneNumber.phone",
  },
  {
    label: "VAT/TAX No.",
    name: "VAT"
  },
  {
    label: "Status",
    name: "status",
    options: {
      customBodyRender: (value: BusinessCustomerStatus) => (value) ? value : "N/A",
    }
  }
];

const UseStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerMargin: {
      marginBottom: "120px"
    },
    bottomButton: {
      backgroundColor: "var(--theme-primary)",
      "&:hover": {
        backgroundColor: "var(--theme-primary-dark)"
      },
      position: 'relative',
      width: 'calc(100vw - 40px)',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2),
      width: 'auto',
      marginBottom: theme.spacing(1)
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    appBar: {
      bottom: 0,
      left: 0,
      top: 'auto',
      position: 'fixed',
      backgroundColor: 'var(--theme-accent)'
    }
  })
);

export const BusinessCustomers = (props: any) => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.userReducer);
  const [selection, setSelection] = useState<any[]>([]);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [fileValue, setFileValue] = useState<File | undefined>();
  const [fileName, setFileName] = useState<string>("");
  const [uploadStatus, setUploadStatus] = useState<number>(
    CsvUploadStatusType.DEFAULT
  );
  const [uploadErrors, setUploadErrors] = useState<ICsvUploadError[]>([]);
  const [sortOrder, setSortOrder] = useState<string | undefined>();
  const [businessCustomersTable, setbusinessCustomersTable] = useState<ITable>();
  const [rows, setRows] = useState<IBusinessCustomer[]>([]);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [totalBusinessCustomerCount, setTotalBusinessCustomerCount] = useState<number>(0);

  const [loadBusinessCustomers, { loading, data }] = useLazyQuery(
    GET_LIMIT_BUSINESS_CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.limitBusinessCustomers) {
        const newCustomers = [...data.limitBusinessCustomers]
        const oldCustomers = [...rows]
        newCustomers.forEach((customer: IBusinessCustomer) => {
          if (!oldCustomers.some((item) => item.id === customer.id)) {
            oldCustomers.push(customer)
          }
        });
        setRows(oldCustomers)
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  },
  );

  const [getBusinessCustomersCount, { data: businessCustomersCountData }] = useLazyQuery(
    GET_BUSINESS_CUSTOMER_COUNT,
    {
      fetchPolicy: "network-only",
      onCompleted: (businessCustomersCountData) => setTotalBusinessCustomerCount(businessCustomersCountData.businessCustomersCount)
    }
  );

  const [searchBusinessCustomer, { loading: searchBusinessCustomerLoading, data: searchBusinessCustomerData }] = useLazyQuery(
    SEARCH_BUSINESS_CUSTOMER,
    {
      fetchPolicy: "network-only",
      onCompleted: (searchBusinessCustomerData) => {
        if (searchBusinessCustomerData && searchBusinessCustomerData.searchBusinessCustomer) {
          const newCustomers = [...searchBusinessCustomerData.searchBusinessCustomer]
          setTotalBusinessCustomerCount(newCustomers.length)
          setRows(newCustomers)
        }
      }
    }
  );

  const businessCustomerTableState = useSelector(
    (state: IAppState) => state.tableStateReducer.businessCustomers
  );

  const handleSearch = _.debounce((searchText: string) => {
    if (searchText) {
      searchBusinessCustomer({
        variables: {
          q: searchText
        }
      })
    }
  }, 1000);

  useEffect(() => {
    if (businessCustomerTableState) {
      setbusinessCustomersTable(businessCustomerTableState);
      let sortedOrder = returnSortedOrder(businessCustomerTableState);
      if (sortedOrder) {
        setSortOrder(sortedOrder);
        setTableSortOrder(columns, businessCustomerTableState, sortedOrder);
      }
      getBusinessCustomersCount();
      loadBusinessCustomers({
        variables: {
          offset: rows.length,
          limit: businessCustomerTableState.rowsPerPage || 10
        }
      })
    }
  }, [businessCustomerTableState]);

  useEffect(() => {
    if (userState.tenancy) {
      getBusinessCustomersCount();
      loadBusinessCustomers({
        variables: {
          limit: businessCustomerTableState?.rowsPerPage ? businessCustomerTableState.rowsPerPage : limit,
          offset: 0
        }
      });
    }
  }, [userState]);

  useEffect(() => {
    if (!uploadModalOpen) {
      setUploadErrors([]);
    }
  }, [uploadModalOpen]);

  const [uploadBusinessCustomer] = useMutation(UPLOAD_BUSINESS_CUSTOMER, {
    onError: (error: ApolloError) => {
      setFileName("");
      setFileValue(undefined);
      return snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: (data) => {
      if (
        data.uploadBusinessCustomer &&
        data.uploadBusinessCustomer.errors &&
        data.uploadBusinessCustomer.errors.length
      ) {
        setFileName("");
        setFileValue(undefined);
        setUploadErrors(data.uploadBusinessCustomer.errors);
        return setUploadStatus(CsvUploadStatusType.ERROR);
      }
      snackbar({
        message: "Business Customers uploaded successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setUploadModalOpen(false);
      loadBusinessCustomers({
        variables: {
          offset: 0,
          limit
        }
      });
      setFileName("");
      setFileValue(undefined);
      setUploadStatus(CsvUploadStatusType.DEFAULT);
    }
  });

  function onSelectDocument(event: any) {
    const file: File = event.target.files[0];
    setUploadErrors([]);
    setUploadStatus(CsvUploadStatusType.DEFAULT);
    if (file) {
      setFileName(file.name);
      if (possibleCsvMimeTypes.includes(file.type)) {
        setFileValue(file);
      } else {
        return snackbar({
          message: "Invalid File Type. Allowed type: csv",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  function uploadCsv() {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        papa.parse(fileValue, {
          skipEmptyLines: true,
          complete(results: any) {
            const businessCustomers = [];
            if (results.data.length) {
              for (let index = 0; index < results.data[0].length; index++) {
                const header = results.data[0][index];
                if (header !== businessCustomerCsvHeaders[index]) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid File Header.",
                    variant: SnackBarVariant.ERROR
                  });
                }
              }
              if (results.data.length === 2 && !results.data[1][0]) {
                setUploadStatus(CsvUploadStatusType.ERROR);
                setFileName("");
                setFileValue(undefined);
                return snackbar({
                  message: "No data found.",
                  variant: SnackBarVariant.ERROR
                });
              }
              for (let index = 1; index < results.data.length; index++) {
                const element: any = results.data[index];
                if (element.length < 18) {
                  setUploadStatus(CsvUploadStatusType.ERROR);
                  setFileName("");
                  setFileValue(undefined);
                  return snackbar({
                    message: "Invalid file",
                    variant: SnackBarVariant.ERROR
                  });
                }
                const data = {
                  firstName: element[0],
                  lastName: element[1],
                  email: element[2].trim(),
                  phoneNumber: element[3],
                  businessName: element[4],
                  registrationNumber: element[5] ? element[5].trim() : "",
                  VAT: element[6] ? element[6] : "",
                  billingPhoneNumber: element[7],
                  billingEmail: element[8].trim(),
                  street: element[9],
                  city: element[10],
                  state: element[11],
                  country: element[12],
                  zipcode: element[13],
                  signatoryFirstName: element[14],
                  signatoryLastName: element[15],
                  signatoryEmail: element[16].trim(),
                  signatoryPhoneNumber: element[17],
                };
                businessCustomers.push(data);
              }
              if (businessCustomers.length > 0) {
                uploadBusinessCustomer({
                  variables: {
                    businessCustomers
                  }
                });
              } else {
                setUploadStatus(CsvUploadStatusType.ERROR);
                snackbar({
                  message: "No data found.",
                  variant: SnackBarVariant.ERROR
                });
              }
            }
          }
        });
      }
    } catch (error) {
      captureErrorException(error)
      setUploadStatus(CsvUploadStatusType.ERROR);
      console.log(error);
    }
  }


  const options: MUIDataTableOptions = {
    selectableRows: SelectableRows.NONE,
    count: totalBusinessCustomerCount,
    textLabels: {
      body: {
        toolTip: "Sort",
        noMatch: (loading || searchBusinessCustomerLoading) ?
          'Loading...' :
          'Sorry, there is no matching data to display',
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "row(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    onRowClick: (rowData: any, rowMeta: any) => {
      const item: any = rows[rowMeta.dataIndex];
      if (item.id) {
        navigate(`/update-business-customer?businesscustomer=${item.id}`);
      }
    },
    onSearchChange: (searchText: string | null) => {
      if (searchText) {
        handleSearch(searchText)
      } else {
        setRows([])
        getBusinessCustomersCount();
        loadBusinessCustomers({
          variables: {
            offset: 0,
            limit
          }
        });
      }
    },

    onChangeRowsPerPage: (numberOfRows: number) => {
      setLimit(numberOfRows)
      dispatch(
        addTableState({
          tableName: TableNames.BUSINESS_CUSTOMERS,
          rowsPerPage: numberOfRows
        })
      );
    },
    rowsPerPage:
      businessCustomersTable && businessCustomersTable.rowsPerPage
        ? businessCustomersTable.rowsPerPage
        : limit,
    onColumnSortChange: (changedColumn: string, direction: string) => {
      dispatch(
        addTableState({
          tableName: TableNames.BUSINESS_CUSTOMERS,
          columnName: changedColumn,
          direction
        })
      );
    },
    onTableInit: () => {
      if (businessCustomersTable && sortOrder) {
        setTableSortOrder(columns, businessCustomersTable, sortOrder);
      }
    },
    onTableChange: (action: string, tableState: any) => {
      switch (action) {
        case "changePage":
          if (tableState.searchText) {
            return;
          }
          const { page, rowsPerPage } = tableState;
          if (page * rowsPerPage >= rows.length) {
            loadBusinessCustomers({
              variables: {
                offset: page * rowsPerPage,
                limit: rowsPerPage
              }
            });
          }
          break;
        case "changeRowsPerPage":
          setRows([])
          loadBusinessCustomers({
            variables: {
              offset: 0,
              limit: tableState.rowsPerPage || limit
            }
          });
          break;
        default:
          break;
      }
    },
  };

  const classes = UseStyles();

  const handleScroll = (e: any) => {
    const threshold = 15000
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < threshold
    ) {
      loadBusinessCustomers({
        variables: {
          offset: rows.length,
          limit: rows.length
        }
      })
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [data]);

  const disabledRoles = ["FINANCE_MANAGER", "FLEET_MANAGER"];

  return <>
    <Grid container spacing={2}>
      <CssBaseline />
      <Hidden smUp>
        <Grid container item xs={12} sm={6} alignItems="center">
          <Typography variant="h1" color="primary">
            Business Customers
          </Typography>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid container item xs={12} sm={6} alignItems="center">
          <Typography variant="h1" color="primary">
            Customers
          </Typography>
          <Box color="white" sx={{ pr: 1 }}></Box>
          <DoubleArrowIcon />
          <Box color="white" sx={{ pl: 1 }}></Box>
          <Typography variant="h1" color="primary">
            &nbsp;Business Customers
          </Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          <Fab
            variant="extended"
            size="medium"
            aria-label="Create"
            className="createButton"
            onClick={() => {
              dispatch(resetBusinessCustomer());
              navigate("/new-business-customer");
            }}
            disabled={disabledRoles.includes(userState.role)}
          >
            Create
          </Fab>
          <Box color="white" sx={{ pr: 2 }}></Box>
          <Fab
            variant="extended"
            size="medium"
            aria-label="Create Multiple"
            onClick={() => setUploadModalOpen(true)}
            disabled={disabledRoles.includes(userState.role)}
          >
            Create Multiple
          </Fab>
        </Grid>
      </Hidden>
      <Grid container item xs={12}>
        {!(loading && !rows.length) ? (
          <Grid container xs={12}>
            <Hidden smUp>
              {searchBusinessCustomerLoading ? (
                <CircularProgress />
              ) : (
                <div onScroll={handleScroll}>
                  <BusinessCustomerVirtualScroller data={rows} />
                </div>
              )}
            </Hidden>
            <Hidden smDown>
              <NuvvenTable
                title={""}
                rows={rows}
                columns={columns}
                setSelection={setSelection}
                options={options}
              />
            </Hidden>
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </Grid>
      {uploadModalOpen && (
        <BulkCsvUploadDialog
          title={"Create Multiple Customers"}
          subTitle={"Business Customers"}
          csvTemplateLink={"https://nuvvenassets.s3.eu-west-2.amazonaws.com/downloads/Business-customer-Upload.csv"}
          uploadErrors={uploadErrors}
          uploadStatus={uploadStatus}
          onDocumentSelect={onSelectDocument}
          fileName={fileName}
          uploadCsv={uploadCsv}
          handleClose={() => setUploadModalOpen(false)}
        />
      )}
    </Grid>
    <Hidden smUp>
      <AppBar position="fixed" className={classes.appBar}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Button
              className={classes.bottomButton}
              variant="contained"
              aria-label="Create"
              onClick={() => {
                dispatch(resetBusinessCustomer());
                navigate("/new-business-customer");
              }}
            >
              Create
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(event: { target: { value: string; }; }) => {
                  handleSearch(event.target.value);
                  if (!event.target.value) {
                    setRows([])
                    loadBusinessCustomers({
                      variables: {
                        limit: 10,
                        offset: 0
                      }
                    })
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </AppBar>
    </Hidden>
  </>;
};
