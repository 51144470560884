export const UPDATE_VEHICLE_PRICE_GROUP = "UPDATE_VEHICLE_PRICE_GROUP";
export const CLEAR_VEHICLE_PRICE_GROUP = "CLEAR_VEHICLE_PRICE_GROUP";

interface IUpdateVehiclePriceGroup {
  type: typeof UPDATE_VEHICLE_PRICE_GROUP;
  payload: any;
}

interface IClearVehiclePriceGroup {
  type: typeof CLEAR_VEHICLE_PRICE_GROUP;
}

export type VehiclePriceGroupAction =
  | IUpdateVehiclePriceGroup
  | IClearVehiclePriceGroup;
