import { gql } from "@apollo/client";

export const CREATE_CUSTOM_SERVICE_TASK = gql`
  mutation createCustomVehicleServiceTask(
    $vehicleServiceTaskId: String!
    $vehicleServiceTask: VehicleServiceTaskInput!
  ) {
    createCustomVehicleServiceTask (vehicleServiceTaskId:$vehicleServiceTaskId,vehicleServiceTask: $vehicleServiceTask) {
      id
      name
      description
      taskType
  }
}
`;

export const UPDATE_VEHICLE_SERVICE_TASK = gql`
  mutation updateVehicleServiceTask(
    $vehicleServiceTaskId: String!
    $serviceTaskId: String!
    $taskType: String!
    $vehicleServiceTask: VehicleServiceTaskInput!
  ) {
    updateVehicleServiceTask (
      vehicleServiceTaskId:$vehicleServiceTaskId,
      serviceTaskId:$serviceTaskId,
      taskType:$taskType
      vehicleServiceTask: $vehicleServiceTask
    ) {
      id
      name
      description
      taskType
  }
}
`;

export const DELETE_VEHICLE_SERVICE_TASK = gql`
  mutation deleteVehicleServiceTask(
    $serviceTaskId: String!
  ) {
    deleteVehicleServiceTask (serviceTaskId:$serviceTaskId) {
      success
      message
  }
}
`;