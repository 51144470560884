import { Grid, Typography } from '@mui/material'
import React from 'react'

interface IVehicleData {
  startDate?: string;
  endDate?: string;
}
interface IProps {
  vehicleData?: IVehicleData;
}
export const VehiclePreparationTime: React.FC<IProps> = (props) => {

  const { vehicleData } = props
  return (
    <Grid container item xs={12} spacing={2} style={{ marginBottom: 20 }}>
      <Grid item xs={12}>
        <Typography variant={"body1"}>The vehicle is in preparation from {`${vehicleData?.startDate} to ${vehicleData?.endDate}`}</Typography>
      </Grid>
    </Grid>
  )
}
