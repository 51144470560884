export default {
  styleOverrides: {
    root: {
      flex: "1 1 auto",
      minWidth: 0,
      marginTop: " 4px",
      marginBottom: 0
    }
  }
};
